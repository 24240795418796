import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import swal from '@sweetalert/with-react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const iconMenuPontos = require('@images/icone_fab_menu.png');

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const baixar = messagesLinguage['PDFButtonFab.baixar'];
const enviarEmail = messagesLinguage['PDFButtonFab.enviarEmail'];
const baixarPDF = messagesLinguage['PDFButtonFab.baixarPDF'];
const baixarXLS = messagesLinguage['PDFButtonFab.baixarXLS'];
const baixando = messagesLinguage['PDFButtonFab.baixando'];

const intlProvider = new IntlProvider({
  locale: getUserLang(),
  messages: {
    baixar,
    enviarEmail,
    baixarPDF,
    baixarXLS,
    baixando
  }
});

provider = intlProvider.getChildContext();

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

async function baixarArquivo(arquivo) {
  swal(
    <div>
      <MuiThemeProvider theme={theme}>
        <CircularProgress />
      </MuiThemeProvider>
    </div>,
    {
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: true,
      title: provider.intl.formatMessage({
        id: 'baixando'
      })
    }
  );
  var res = await fetch(arquivo);
  var data = await res.blob();

  const anchor = document.createElement('a');
  anchor.href = window.URL.createObjectURL(data);
  anchor.download = arquivo.split('com/')[1];
  anchor.click();

  swal.close();
}

function PDFButtonFab(props) {
  const { classes } = props;
  const actions = [
    {
      icon: (
        <FontAwesomeIcon
          style={{ color: 'rgb(255, 255, 255)', width: 18, height: 18 }}
          icon={faFileExcel}
        />
      ),
      name: provider.intl.formatMessage({ id: 'baixarXLS' }),
      onClickIcon: () => baixarArquivo(props.fileXLS),
      color: '#42ADE8'
    },
    {
      icon: (
        <FontAwesomeIcon
          style={{ color: 'rgb(255, 255, 255)', width: 18, height: 18 }}
          icon={faFilePdf}
        />
      ),
      name: provider.intl.formatMessage({ id: 'baixarPDF' }),
      onClickIcon: () => baixarArquivo(props.filePDF),
      color: '#42ADE8'
    }
  ];

  if (props.additionalActions) {
    props.additionalActions.forEach(item => {
      item.onClickIcon = () => baixarArquivo(item.file);
      actions.push(item);
    });
  }

  return (
    <div
      className={classes.fab}
      style={{ height: props.additionalActions ? 56 * props.additionalActions.length : 0 }}
    >
      <ButtonFABMenu disabled={props.disabled} icon={iconMenuPontos} actions={actions} />
    </div>
  );
}

PDFButtonFab.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PDFButtonFab);
