//Autor: Kátia miglioli

import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import green from '@material-ui/core/colors/green';
import Cultura from '@resources/Cultura';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});

const style = () => ({
  parentDiv: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  topDiv: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  identif_Div: {
    flex: 1,
    position: 'relative',
    maxHeight: '180px',
    minHeight: '170px'
  },
  font: {
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 34.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  root: {
    color: green[700],
    '&$checked': {
      color: green[700]
    }
  },
  checked: {},
  label: {
    fontSize: 14,
    width: '100%',
    height: 20,
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#666666'
  },
  rootControl: {
    marginTop: '11px'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class EditarCultura extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const culturaCadastradaComSucesso = messagesLinguage['cultura.culturaCadastradaComSucesso'];
    const culturaEditadaComSucesso = messagesLinguage['cultura.culturaEditadaComSucesso'];
    const falhaAoEditarCultura = messagesLinguage['cultura.falhaAoEditarCultura'];
    const falhaAoCadastrarCultura = messagesLinguage['cultura.falhaAoCadastrarCultura'];
    const falhaAoCarregarCultura = messagesLinguage['cultura.falhaAoCarregarCultura'];
    const salvar = messagesLinguage['cultura.salvar'];
    const cancelar = messagesLinguage['cultura.cancelar'];
    const salvando = messagesLinguage['cultura.salvando'];
    const atualizando = messagesLinguage['cultura.atualizando'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          culturaCadastradaComSucesso,
          culturaEditadaComSucesso,
          falhaAoCadastrarCultura,
          falhaAoEditarCultura,
          falhaAoCarregarCultura,
          salvar,
          cancelar,
          salvando,
          atualizando
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      descricao: '',
      key: '',
      loading: this.props.match.params.id !== undefined,
      idCultura: ''
    };

    const title = 'cultura.cadastroDeCultura';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.iniciarAtributosEdicao = this.iniciarAtributosEdicao.bind(this);
    this.clickCadastrarEditar = this.clickCadastrarEditar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
  }

  componentDidMount() {
    this.iniciarAtributosEdicao();
  }

  iniciarAtributosEdicao() {
    if (this.props.match.params.id !== undefined) {
      Cultura.findCulturaById(this.props.match.params.id)
        .then(query => {
          this.setState({
            descricao: query.data.nmCultura,
            loading: false,
            idCultura: query.data.idCultura
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal(provider.intl.formatMessage({ id: 'falhaAoCarregarCultura' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    }
  }

  clickCadastrarEditar(values) {
    if (!this.state.validarInputs) {
      swal(
        <div>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>,
        {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          title: provider.intl.formatMessage({
            id: this.props.match.params.id === undefined ? 'salvando' : 'atualizando'
          })
        }
      );

      const data = {
        nmCultura: values.descricao,
        nrAplicacao: 0
      };

      if (this.props.match.params.id === undefined) {
        Cultura.addCultura(data)
          .then(query => {
            swal(
              provider.intl.formatMessage({
                id: 'culturaCadastradaComSucesso'
              }),
              {
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                }
              }
            ).then(() => {
              this.clickCancelar();
            });
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarCultura' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      } else {
        data.idCultura = this.props.match.params.id;

        Cultura.editCultura(data)
          .then(query => {
            swal(provider.intl.formatMessage({ id: 'culturaEditadaComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              this.clickCancelar();
            });
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: 'falhaAoEditarCultura' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    }
  }

  clickCancelar() {
    this.props.history.push('/app/culturas');
  }

  render() {
    const { classes } = this.props;
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Formik
          key={this.state.key}
          initialValues={{
            descricao: this.state.descricao
          }}
          onSubmit={this.clickCadastrarEditar}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            descricao: Yup.string().required(<FormattedMessage id='cultura.campoObrigatorio' />)
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleChange
          }) => (
            <MuiThemeProvider theme={theme}>
              <form autoComplete='off' className={classes.parentDiv} onSubmit={handleSubmit}>
                <div className={classes.topDiv}>
                  {this.state.loading && <Loading />}
                  <div className={classes.identif_Div}>
                    <CardIcon titulo={<FormattedMessage id='cultura.identificacaoDaCultura' />}>
                      <Grid container spacing={16} style={{ marginTop: '15px' }}>
                        <Grid item xs={4}>
                          <TextField
                            label={<FormattedMessage id='cultura.descricao' />}
                            name='descricao'
                            value={values.descricao}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.descricao && touched.descricao}
                            helperText={errors.descricao && touched.descricao ? errors.descricao : null}
                            className={classes.textField}
                            style={{ width: '100%', height: 70 }}
                            InputLabelProps={{ className: classes.font }}
                          />
                        </Grid>
                      </Grid>
                    </CardIcon>
                  </div>
                </div>
                <div className={classes.fab}>
                  <ButtonFABMenu
                    icon={iconMenuPontos}
                    actions={[
                      {
                        icon: <Check style={{ color: 'white' }} />,
                        name: provider.intl.formatMessage({ id: 'salvar' }),
                        onClickIcon: handleSubmit,
                        color: '#42ADE8'
                      },
                      {
                        icon: <Close style={{ color: 'white' }} />,
                        name: provider.intl.formatMessage({ id: 'cancelar' }),
                        onClickIcon: this.clickCancelar,
                        color: '#F33A30'
                      }
                    ]}
                    onClickButton={this.clickAdicionar}
                  />
                </div>
              </form>
            </MuiThemeProvider>
          )}
        />
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarCultura);
export default withRouter(connect(mapStateToProps)(enhaced));
