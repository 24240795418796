import React, { Component } from 'react';
import $ from 'jquery';
import './Calendar.css';

import 'fullcalendar';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar/dist/fullcalendar.js';
import Card from '@components/Card/Card';
import { withStyles } from '@material-ui/core/styles';
import { getUserLang } from '@utils/localeUtils';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import ptBR from './i18n/pt-BR';
import esES from './i18n/es-ES';
import enUS from './i18n/en-US';
import Schedule from '@material-ui/icons/Schedule';
import Event from '@material-ui/icons/Event';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';

import * as moment from 'moment';
const iconMenuPontos = require('@images/icone_fab_menu.png');

var { provider } = new IntlProvider({});

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = theme => ({
  div: {
    borderWidth: '1px 1px 1px 1px',
    borderRadius: '5px'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

let viewName = '';
let viewDate = '';

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const hoje = messagesLinguage['calendar.hoje'];
const mes = messagesLinguage['calendar.mes'];
const semana = messagesLinguage['calendar.semana'];
const dia = messagesLinguage['calendar.dia'];
const mais = messagesLinguage['calendar.mais'];
const agenda = messagesLinguage['calendar.agenda'];
const diaTodo = messagesLinguage['calendar.diaTodo'];
const hora = messagesLinguage['calendar.hora'];
const data = messagesLinguage['calendar.data'];
const evento = messagesLinguage['calendar.evento'];
const noEvents = messagesLinguage['calendar.noEvents'];
const formatDate = messagesLinguage['calendar.formatDate'];
const formatTime = messagesLinguage['calendar.formatTime'];
const formatDatePopUp = messagesLinguage['calendar.formatDatePopUp'];
const adicionar = messagesLinguage['calendar.adicionar'];
const list = messagesLinguage['calendar.list'];
const formatMonth = messagesLinguage['calendar.formatMonth'];
const adicionarEvento = messagesLinguage['calendar.adicionarEvento'];
const adicionarVisita = messagesLinguage['calendar.adicionarVisita'];

const intlProvider = new IntlProvider(
  {
    locale: getUserLang(),
    messages: {
      hoje,
      mes,
      semana,
      dia,
      mais,
      agenda,
      data,
      evento,
      hora,
      diaTodo,
      noEvents,
      formatDate,
      formatTime,
      formatDatePopUp,
      adicionar,
      list,
      formatMonth,
      adicionarEvento,
      adicionarVisita
    }
  },
  {}
);

provider = intlProvider.getChildContext();

class CalendarComponent extends Component {
  constructor(props) {
    super(props);

    this.changeMonth = this.changeMonth.bind(this);
  }

  changeMonth(view, element) {
    viewName = view.name;
    viewDate = moment(view.calendar.eventManager.calendar.currentDate)
      .utc()
      .format('YYYY-MM-DD');

    if (view.name === 'agendaDay') {
      element.css('background-color', '#FFFFFF');
    }

    this.props.changeMonth(viewDate);
  }

  componentDidMount() {
    const { calendar } = this.refs;

    this.props.eventCalendar(calendar);

    $(calendar).fullCalendar({
      editable: false,
      eventColor: '#FFFFFF',
      eventClick: this.props.eventClick,

      height: 'parent',
      header: {
        left: 'prev, today, next',
        center: 'title',
        right: 'month, agendaWeek, agendaDay, listMonth'
      },
      locale: getUserLang(),
      eventLimitText: provider.intl.formatMessage({ id: 'mais' }),
      noEventsMessage: provider.intl.formatMessage({ id: 'noEvents' }),
      buttonText: {
        today: provider.intl.formatMessage({ id: 'hoje' })
      },
      slotLabelFormat: provider.intl.formatMessage({ id: 'formatTime' }),
      timeFormat: provider.intl.formatMessage({ id: 'formatTime' }),
      dayPopoverFormat: provider.intl.formatMessage({ id: 'formatDatePopUp' }),
      defaultView: 'agendaWeek',
      listDayAltFormat: false,
      listDayFormat: 'dddd, DD',
      navLinks: true,
      views: {
        agendaDay: {
          buttonText: provider.intl.formatMessage({ id: 'dia' }),
          titleFormat: provider.intl.formatMessage({ id: 'formatMonth' }),
          columnFormat: 'dddd - DD'
        },
        agendaWeek: {
          buttonText: provider.intl.formatMessage({ id: 'semana' }),
          titleFormat: provider.intl.formatMessage({ id: 'formatMonth' }),
          columnFormat: 'ddd - DD'
        },
        month: {
          buttonText: provider.intl.formatMessage({ id: 'mes' }),
          columnFormat: 'dddd',
          titleFormat: provider.intl.formatMessage({ id: 'formatMonth' })
        },
        listMonth: {
          buttonText: provider.intl.formatMessage({ id: 'list' }),
          columnHeaderFormat: 'dddd, DD',
          titleFormat: provider.intl.formatMessage({ id: 'formatMonth' })
        }
      },
      eventLimit: true,
      events: this.props.events,
      displayEventEnd: false,
      dayRender: function(date, el, view) {
        if (date.isSame(new Date(), 'days')) {
          el.css('border', '2px solid #00622B !important');
          el.append('<div class="today" />');
        }
      },
      viewRender: this.changeMonth,
      eventRender: function(event, element, view) {
        const diference = moment(event.end).diff(event.start);

        element.find('.fc-title').css('display', 'inline-block');
        element.find('.fc-title').css('flex-direction', 'row-reverse');
        element.find('.fc-title').css('justify-content', 'flex-end');
        element.find('.fc-title').css('white-space', 'nowrap');
        element.find('.fc-title').css('overflow', 'hidden');
        element.find('.fc-title').css('text-overflow', 'ellipsis');
        element.find('.fc-title').css('margin-left', '23px');

        if (view.name !== 'listMonth') {
          if (event.tpRegistro === 1) {
            element.css('color', 'rgb(255, 152, 33)');
            element.css('border-left-color', 'rgb(255, 152, 33)');
            element.css('background-color', 'rgb(255, 152, 33, 0.5)');
          } else {
            element.css('color', '#46A770');
            element.css('border-left-color', '#46A770');
            element.css('background-color', 'rgba(70, 167, 112, 0.5)');
          }

          element.css('display', 'flex');
          element.css('align-items', 'flex-start');
          element.css('padding-top', '10px');
          element.css('margin-top', '2px');
        }

        if (view.name === 'listMonth') {
          element.find('.fc-list-item-time').css('width', '60px');
          element.find('.fc-list-item-time').css('flex-direction', 'row-reverse');
          element.find('.fc-list-item-time').css('padding-left', '30px');

          element.find('.fc-list-item-title').css('justify-content', 'space-between');
          element.find('.fc-list-item-title').css('font-size', '14px');
          element.find('.fc-list-item-title').css('display', 'flex');
          element.find('.fc-list-item-title').css('height', '49px');

          if (event.description !== '' && event.description !== undefined) {
            if (event.tpRegistro === 1) {
              element
                .find('.fc-list-item-title')
                .append(
                  '<div class="title-lista-evento"> <i class="material-icons">comment</i>  <p class="descrip"> ' +
                    event.description +
                    ' </p> </div>'
                );
            } else {
              element
                .find('.fc-list-item-title')
                .append(
                  '<div class="title-lista"> <i class="material-icons">comment</i>  <p class="descrip"> ' +
                    event.description +
                    ' </p> </div>'
                );
            }
          }
          element.find('.fc-event-dot').css('background-color', 'transparent');
          element.find('.fc-event-dot').css('padding-left', '80px');
          if (event.tpRegistro === 1) {
            element
              .find('.fc-list-item-time')
              .append('<i class="material-icons icon-color-evento style-time">access_time</i>');
            element
              .find('.fc-event-dot')
              .append(
                '<div class="title-lista-evento"> <i class="material-icons">nature_people</i> </div>'
              );
          } else {
            element
              .find('.fc-list-item-time')
              .append('<i class="material-icons icon-color-visita style-time">access_time</i></div>');
            element
              .find('.fc-event-dot')
              .append('<div class="title-lista"> <i class="material-icons">nature_people</i> </div>');
          }
        }

        if (view.name === 'agendaWeek') {
          element.css('margin-right', '0');
          if (event.tpRegistro === 1) {
            element.css('background-color', 'rgb(255, 152, 33, 0.5)');
          } else {
            element.css('background-color', 'rgba(70, 167, 112, 0.5)');
          }

          if (moment.duration(diference).asMinutes() < 45) {
            element.css('padding-top', '0px');
          } else {
            element.find('.fc-content').css('flex-direction', 'column');
            element.find('.fc-content').css('width', 'auto');
            element.find('.fc-content').css('justify-content', 'space-between');

            if (event.description !== '' && event.description !== undefined) {
              element
                .find('.fc-content')
                .append(
                  '<div class="style-descricao" > <i class="material-icons">comment</i> <p class="style-p" >' +
                    event.description +
                    '</p> </div>'
                );
              if (event.nota !== '' && event.nota !== null && event.nota !== undefined) {
                element
                  .find('.fc-content')
                  .append(
                    '<div class="style-descricao" > <i class="material-icons">comment</i> <p class="style-p" >' +
                      event.nota +
                      '</p> </div>'
                  );
              }
            }
            element.find('div.fc-hora').css('margin-top', '4px');
          }
        }

        if (view.name === 'agendaDay') {
          element.css('width', 'auto');
          if (event.tpRegistro === 1) {
            element.css('background-color', 'rgb(255, 152, 33, 0.5)');
          } else {
            element.css('background-color', 'rgba(70, 167, 112, 0.5)');
          }
          element.css('margin-right', '1px');
          element.find('div.fc-hora').css('padding-left', '0');
          element.find('.fc-content').css('flex-direction', 'column');
          element.find('.fc-content').css('width', 'auto');
          element.find('.fc-content').css('justify-content', 'space-between');

          if (moment.duration(diference).asMinutes() < 45) {
            element.css('padding-top', '0px');
          } else {
            if (event.description !== '' && event.description !== undefined) {
              element
                .find('.fc-content')
                .append(
                  '<div class="style-descricao" > <i class="material-icons">comment</i> <p class="style-p" >' +
                    event.description +
                    '</p> </div>'
                );
              if (event.nota !== '' && event.nota !== null && event.nota !== undefined) {
                element
                  .find('.fc-content')
                  .append(
                    '<div class="style-descricao" > <i class="material-icons">comment</i> <p class="style-p" >' +
                      event.nota +
                      '</p> </div>'
                  );
              }
            }
            element.find('div.fc-hora').css('margin-top', '4px');
          }
        }

        element.find('span.fc-day-number').css('margin-right', '20px');
        element
          .find('.fc-title')
          .append('<div class="style-image-icon"> <i class="material-icons">nature_people</i> </div>');
      }
    });
  }

  render() {
    return <div ref='calendar' />;
  }
}

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.clickAdd = this.clickAdd.bind(this);
    this.clickAddEvento = this.clickAddEvento.bind(this);
    this.retornarAddEvento = this.retornarAddEvento.bind(this);
    this.retornarAddVisita = this.retornarAddVisita.bind(this);
  }

  clickAdd(event) {
    this.props.addCalendar(event, viewName, viewDate, 1);
  }

  clickAddEvento(event) {
    this.props.addCalendar(event, viewName, viewDate, 2);
  }

  retornarAddEvento() {
    return provider.intl.formatMessage({ id: 'adicionarEvento' });
  }

  retornarAddVisita() {
    return provider.intl.formatMessage({ id: 'adicionarVisita' });
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Card
          withoutColor={true}
          withOutBorderRadius={true}
          childrenStyle={{ display: 'contents' }}
          disablePadding
          styleHeader={{ minHeight: 0 }}
        >
          <div
            className={classes.div}
            style={{
              height: this.props.showAutoComplete ? 'calc(100% - 100px)' : '100%',
              padding: 30
            }}
          >
            {this.props.children}

            <CalendarComponent
              changeMonth={this.props.changeMonth}
              events={this.props.events}
              eventClick={this.props.eventClick}
              eventCalendar={this.props.eventCalendar}
            />
          </div>

          <div className={classes.fab}>
            <ButtonFABMenu
              icon={iconMenuPontos}
              actions={[
                {
                  icon: <Schedule style={{ color: 'white' }} />,
                  name: this.retornarAddVisita(),
                  onClickIcon: this.clickAdd,
                  color: '#42ADE8',
                  disabled: this.props.disableButton
                },
                {
                  icon: <Event style={{ color: 'white' }} />,
                  name: this.retornarAddEvento(),
                  onClickIcon: this.clickAddEvento,
                  color: '#42ADE8',
                  disabled: this.props.disableButton
                }
              ]}
            />
          </div>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(Calendar);
