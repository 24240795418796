export default {
  formaAplicacao: {
    formaAplicacao: 'Forma de aplicação',
    nao: 'Não',
    desejaExcluir: 'Deseja excluir?',
    excluir: 'Excluir',
    formaAplicacaoRemovidoComSucesso: 'Forma de aplicação removida com sucesso',
    falhaAoExcluirFormaAplicacao: 'Falha ao excluir forma de aplicação',
    excluindo: 'Excluindo',
    cultura: 'Cultura',
    tipo: 'Tipo',
    situacao: 'Situação',
    nome: 'Nome',
    formaAplicacaoCadastradoComSucesso: 'Forma de aplicação cadastrada com sucesso',
    formaAplicacaoEditadoComSucesso: 'Forma de aplicação editada com sucesso',
    falhaAoCadastrarFormaAplicacao: 'Falha ao cadastrar forma de aplicação',
    falhaAoEditarFormaAplicacao: 'Falha ao editar forma de aplicação',
    falhaAoCarregarFormaAplicacao: 'Falha ao carregar forma de aplicação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    cadastroDeFormaAplicacao: 'Cadastro de forma de aplicação',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    doencaSolo: 'Doença de solo',
    doencaFoliar: 'Doença foliar',
    nematoide: 'Nematóide',
    descricao: 'Descrição',
    ativo: 'Ativo',
    inativo: 'Inativo',
    pragas: 'Pragas'
  }
};
