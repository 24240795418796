import URL from './URL';

const axios = require('axios');
const api = URL.API + '/classificacao';

function findAllClassificacao() {
  return axios.get(api);
}

function findClassificacaoById(idClassificacao) {
  return axios.get(`${api}/${idClassificacao}`);
}

function findActivesClassificacao() {
  return axios.get(`${api}/actives`);
}

function deleteById(idClassificacao) {
  return axios.delete(`${api}/${idClassificacao}`);
}

function saveClassificacao(values) {
  return axios.post(api, values);
}

function updateClassificacao(values) {
  return axios.put(api, values);
}

export {
  findAllClassificacao,
  findClassificacaoById,
  deleteById,
  saveClassificacao,
  updateClassificacao,
  findActivesClassificacao
};
