import React, { useState, useEffect } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import Usuario from '@resources/Usuario';
import { FormattedMessage } from 'react-intl';
import CardsWithFilters from '@components/CardFilters/CardsWithFilters';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {
  card: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 15px)'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  page: {
    marginBottom: 15
  },
  document: {
    width: '100%'
  },
  divDocument: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
};

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const relatorio = messagesLinguage['relatorio.relatorio'];
const regiao = messagesLinguage['relatorio.regiao'];
const municipio = messagesLinguage['relatorio.municipio'];
const carregando = messagesLinguage['relatorio.carregando'];
const gerandoRelatorio = messagesLinguage['relatorio.gerandoRelatorio'];
const falhaAoGerarRelatorio = messagesLinguage['relatorio.falhaAoGerarRelatorio'];
const relatorioListaProdutores = messagesLinguage['relatorio.relatorioListaProdutores'];
const tecnicoResponsavel = messagesLinguage['relatorio.tecnicoResponsavel'];
const title = messagesLinguage['relatorio.title'];
const crescente = messagesLinguage['relatorio.crescente'];
const decrescente = messagesLinguage['relatorio.decrescente'];
const produtor = messagesLinguage['relatorio.produtor'];
const uf = messagesLinguage['relatorio.uf'];
const situacao = messagesLinguage['relatorio.situacao'];
const ativo = messagesLinguage['relatorio.ativo'];
const inativo = messagesLinguage['relatorio.inativo'];

const intlProvider = new IntlProvider({
  locale: getUserLang(),
  messages: {
    relatorio,
    regiao,
    municipio,
    carregando,
    gerandoRelatorio,
    falhaAoGerarRelatorio,
    relatorioListaProdutores,
    tecnicoResponsavel,
    title,
    crescente,
    decrescente,
    produtor,
    uf,
    situacao,
    ativo,
    inativo
  }
});

provider = intlProvider.getChildContext();

const options = [
  {
    label: provider.intl.formatMessage({ id: 'crescente' }),
    field: 'asc',
    value: 0
  },
  {
    label: provider.intl.formatMessage({ id: 'decrescente' }),
    field: 'desc',
    value: 1
  }
];

const operationTypeStringOrdenacao = [
  {
    label: provider.intl.formatMessage({ id: 'produtor' }),
    field: 'nmProdutor',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'municipio' }),
    field: 'nmMunicipio',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'uf' }),
    field: 'sgUf',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'regiao' }),
    field: 'nmRegiao',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'tecnicoResponsavel' }),
    field: 'nmTecnico',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'situacao' }),
    field: 'stProdutor',
    type: 'enum',
    options: options
  }
];

const operationTypeString = [
  {
    label: provider.intl.formatMessage({ id: 'municipio' }),
    field: 'nmMunicipio',
    list: 'listMunicipio',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'regiao' }),
    field: 'nmRegiao',
    list: 'listRegiao',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'tecnicoResponsavel' }),
    field: 'nmTecnico',
    list: 'listTecnicos',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'situacao' }),
    field: 'stProdutor',
    type: 'enum',
    options: [
      {
        label: provider.intl.formatMessage({ id: 'ativo' }),
        field: 'ativo',
        value: 1
      },
      {
        label: provider.intl.formatMessage({ id: 'inativo' }),
        field: 'inativo',
        value: 0
      }
    ]
  }
];

/**
 * Tela de Relatório de lista de Prodotores
 *
 * @author Gabriela Farias
 * @class RelatorioListaProdutores
 * @extends {Component} - Componente React
 */
function RelatorioListaProdutores(props) {
  const [filePDF, setFilePDF] = useState(null);
  const [fileXLS, setFileXLS] = useState(null);
  const [closeCard, setCloseCard] = useState(false);
  const [errorCard, setErrorCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDados, setLoadingDados] = useState(true);
  const [listTecnicos, setListTecnicos] = useState([]);
  const [listMunicipio, setListMunicipio] = useState([]);
  const [listRegiao, setListRegiao] = useState([]);
  const { classes } = props;

  useEffect(() => {
    const title = 'relatorio.relatorioListaProdutores';
    if (props.itensState.name !== 'relatorio.relatorioListaProdutores') {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    Promise.all([
      Usuario.findAllUsuarios()
        .then(query => {
          let data = [];
          query.data.forEach(doc => {
            if (doc.tpPerfil === 2) {
              data.push(doc);
            }
          });

          setListTecnicos(data);
        })
        .catch(err => {
          setListTecnicos([]);
        }),
      Regioes.findAllRegiao()
        .then(query => {
          setListRegiao(query.data);
        })
        .catch(() => {
          setListRegiao([]);
        }),
      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          setListMunicipio(query.data);
        })
        .catch(err => {
          setListMunicipio([]);
        })
    ])
      .then(() => {
        setLoadingDados(false);
      })
      .catch(() => {
        setLoadingDados(false);
      });
  }, []);

  const clickGerarRelatorio = (value, secondValue) => {
    setErrorCard(false);
    setCloseCard(true);
    setLoading(true);
    setFilePDF(null);
    setFileXLS(null);

    let order = '';
    value.forEach(doc => {
      order = order + doc.filter.field + ':' + doc.value.field + ';';
    });

    const chips = secondValue;

    let municipio = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    if (municipio !== undefined) {
      municipio = 'idMunicipio:' + municipio.value.value + ';';
    } else {
      municipio = '';
    }

    let regiao = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    if (regiao !== undefined) {
      regiao = 'idRegiao:' + regiao.value.value + ';';
    } else {
      regiao = '';
    }

    let nmTecnico = chips.find(item => {
      return item.filter.field === 'nmTecnico';
    });

    if (nmTecnico !== undefined) {
      nmTecnico = 'idTecnico:' + nmTecnico.value.value + ';';
    } else {
      nmTecnico = '';
    }

    let stProdutor = chips.find(item => {
      return item.filter.field === 'stProdutor';
    });

    if (stProdutor !== undefined) {
      stProdutor = 'stProdutor:' + stProdutor.value.value + ';';
    } else {
      stProdutor = '';
    }

    const filters = municipio + regiao + nmTecnico + stProdutor + '&order=' + order;

    Relatorios.gerarListaProdutores(filters)
      .then(doc => {
        setCloseCard(false);
        setFileXLS(doc.data.dsLinkXls);
        setFilePDF(doc.data.dsLinkPdf);
      })
      .catch(err => {
        modalErrorRelatorio();
      });
  };

  const modalErrorRelatorio = () => {
    setErrorCard(true);
    setCloseCard(false);
    setLoading(false);
    setFileXLS(null);
    setFilePDF(null);
    setErrorCard(false);

    swal(provider.intl.formatMessage({ id: 'falhaAoGerarRelatorio' }), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  };

  return (
    <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
      <div className={classes.card}>
        {(loading || loadingDados) && <Loading />}
        <CardsWithFilters
          errorCard={errorCard}
          closeCard={closeCard}
          usePaddings={false}
          relatorio
          viewKey={'relatorio-lista-produtores'}
          itens={[
            {
              autoComplete: {
                listTecnicos: {
                  list: listTecnicos,
                  campoOp: 'nmUsuario',
                  campoChave: 'idUsuario'
                },
                listMunicipio: {
                  list: listMunicipio,
                  campoOp: 'nmMunicipio',
                  campoChave: 'idMunicipio'
                },
                listRegiao: {
                  list: listRegiao,
                  campoOp: 'nmRegiao',
                  campoChave: 'idRegiao'
                }
              },
              allViewKey: 'ordenacao-lista-produtores',
              onClickButton: clickGerarRelatorio,
              filtros: operationTypeString,
              viewKey: 'relatorio-lista-produtores',
              keyCard: 1,
              onFilter: () => {},
              onFilterChanged: () => {},
              relatorio: true,
              withoutButtonFiltrar: true,
              contain: props.chipData.find(data => {
                return data.id === 'relatorio-lista-produtores';
              }).content
            },
            {
              allViewKey: 'relatorio-lista-produtores',
              onClickButton: clickGerarRelatorio,
              filtros: operationTypeStringOrdenacao,
              viewKey: 'ordenacao-lista-produtores',
              keyCard: 2,
              onFilter: () => {},
              onFilterChanged: () => {},
              relatorio: true,
              withoutLabelTipo: true,
              contain: props.chipData.find(data => {
                return data.id === 'ordenacao-lista-produtores';
              }).content
            }
          ]}
        />

        {loading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 20,
              justifyContent: 'center'
            }}
          >
            {provider.intl.formatMessage({ id: 'gerandoRelatorio' })}
          </div>
        )}

        <PDFViewer
          withoutPosition={true}
          loadSucess={() => {
            setLoading(false);
          }}
          errorCard={errorCard}
          fileXLS={fileXLS}
          filePDF={filePDF}
        />
      </div>
    </IntlProvider>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'relatorio-lista-produtores' || date.id === 'ordenacao-lista-produtores';
  })
});

RelatorioListaProdutores.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(RelatorioListaProdutores));
export default withRouter(connect(mapStateToProps)(enhaced));
