export default {
  fungicida: {
    fungicidaRemovidoComSucesso: 'Fungicida quitado con éxito',
    falhaAoExcluirFungicida: 'Fallo al excluir fungicida',
    desejaExcluir: '¿Desea eliminar la fungicida seleccionada?',
    identificacaoDaFungicida: 'Identificación',
    fungicidaCadastradoComSucesso: 'Fungicida registrada con éxito',
    fungicidaEditadoComSucesso: 'Fungicida editado con éxito',
    falhaAoEditarFungicida: 'Falla al editar fungicida',
    falhaAoCadastrarFungicida: 'Error al editar fungicida',
    falhaAoCarregarFungicida: 'Error al cargar fungicida',
    fungicida: 'Fungicida',
    cadastroDeFungicida: 'Registro de fungicidas'
  }
};
