import React, { useState, useEffect } from 'react';
import DatePicker from '@components/DatePicker/DatePicker';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import { getUserLang } from '@utils/localeUtils';
import formatMessage from './i18n/formatMessage';
import * as Yup from 'yup';
import BorderColor from '@material-ui/icons/BorderColor';
import Grid from '@material-ui/core/Grid';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import CardIcon from '@components/CardIcon/CardIcon';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import * as moment from 'moment';
import Loading from '@components/Loading/Loading';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import SwipeableViews from 'react-swipeable-views';
import Produtor from '@resources/Produtores';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import Card from '@components/Card/Card';
import EditarMonitoramento from './Cadastros/EditarMonitoramento';
import AcompanhamentoProdutor from '@resources/AcompanhamentoProdutor';
import { style, theme } from './AcompanhamentoProdutor.styles';
import { MonitoramentoData } from './Header/DataTableHeader';
import SeveridadeEnum from './enumerations/SeveridadeEnum';
import { swalError, swalLoading, swalOptions, swalSucess } from './utils/SwalOptions';
import DateByLanguage from './i18n/DateByLanguage';
import { montaStringsToScreen, trataDadosLabelPlantio } from './utils/TratarDadosCard';
import swal from '@sweetalert/with-react';

const iconMenuPontos = require('@images/icone_fab_menu.png');

function Monitoramento(props) {
  const { classes } = props;
  const idMonitoramento = parseInt(props.match.params.idMonitoramento);
  const idProdutor = parseInt(props.match.params.id);
  const [lastNumber, setLastNumber] = useState(0);
  const [amostraList, setAmostraList] = useState([]);
  const [monitoramento, setMonitoramento] = useState({
    atividadeAmostraQuantidadeList: [],
    dsAdicional: ''
  });
  const [disabledEdit, setDisabledEdit] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [lancamentoAplicacao, setLancamentoAplicacao] = useState({});
  const [canLeave, setCanLeave] = useState(false);
  const [itemSelectProdutos, setItemSelectProdutos] = useState({});
  const [index, setIndex] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const [plantioList, setPlantioList] = useState([]);
  const [dtAtividade, setDtAtividade] = useState(null);
  const [keyUpdate, setKeyUpdate] = useState('');
  const [produtor, setProdutor] = useState('');
  const [error, setError] = useState(false);
  const [itemSelect, setItemSelect] = useState({});
  const [loading, setLoading] = useState(true);
  const [talhaoList, setTalhaoList] = useState([]);
  const [areaCultivo, setAreaCultivo] = useState([]);
  const [plantio, setPlantio] = useState([]);
  const [valueAreaCultivo, setValueAreaCultivo] = useState(null);
  const [valuePlantio, setValuePlantio] = useState({});
  const containerStyle = { flex: 1, height: index === 0 ? 'auto' : '100%' };
  const slidestyle = { display: 'flex', overflowY: 'hidden', height: index === 0 ? 'auto' : '100%' };
  const childrenStyleCard = { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' };
  let handleSubmit = {};

  useEffect(() => {
    mountTitleDashboard();
    buscarProdutor();

    if (idMonitoramento) {
      buscaAcompanhamentoById();
    }
  }, []);

  useEffect(() => {
    if (idMonitoramento && plantio.length === 0 && valuePlantio) {
      validarInicializacaoPlantio();
    }
  }, [produtor, valuePlantio]);

  /**
   * Trata os valores iniciais do plantio
   */
  function validarInicializacaoPlantio(areaCultivoNew = valueAreaCultivo) {
    setPlantio(trataDadosLabelPlantio(plantioList, areaCultivoNew));
  }

  /**
   * Monta o titulo da tela
   */
  function mountTitleDashboard() {
    const dashboardTitle = 'acompanhamentoProdutor.lancamentoMonitoramento';

    if (props.itensState.name !== dashboardTitle) {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(dashboardTitle),
        name: dashboardTitle
      });
    }
  }

  /**
   * Busca as informaçoes do produtor que está sendo editado
   */
  function buscarProdutor() {
    Produtor.findProdutorById(idProdutor).then(query => {
      setProdutor(query.data);
      setAreaCultivo(query.data.areaCultivoList);
      setPlantioList(query.data.plantioList);
      setLoading(idMonitoramento ? loading : false);
    });
  }

  /**
   * Busca a lista de acompanhamento para editar
   */
  function buscaAcompanhamentoById() {
    AcompanhamentoProdutor.findAllAcompanhamento(idProdutor)
      .then(query => {
        const monitoramentoToEdit = query.data.find(doc => {
          return doc.data.idAtividade === parseInt(idMonitoramento);
        });

        tratarListaToEdit(monitoramentoToEdit);
      })
      .catch(() => {
        swalError('acompanhamentoProdutor.erroAoCarregarDados');

        setLoading(false);
        setError(true);
      });
  }

  /**
   * Trata os dados para editar
   */
  function tratarListaToEdit(monitoramentoToEdit) {
    valuePlantio.dsCultura = monitoramentoToEdit.plantioDTO.cultura.nmCultura;
    valuePlantio.idCultura = monitoramentoToEdit.plantioDTO.cultura.nmCultura;
    montaListaTalhao(monitoramentoToEdit.plantioDTO.plantioTalhaoList);
    montaStringsToScreen(monitoramentoToEdit);
    montaListaAmostra(monitoramentoToEdit.data.atividadeAmostraList);

    setValueAreaCultivo(monitoramentoToEdit.data.areaCultivo);
    setValuePlantio(monitoramentoToEdit.plantioDTO);
    setLancamentoAplicacao(monitoramentoToEdit);
    setInitialValues({ ...monitoramentoToEdit });
    setDtAtividade(moment(monitoramentoToEdit.data.dtAtividade).utc());
    setMonitoramento(monitoramentoToEdit.data);
    setKeyUpdate(Math.random());
    setLoading(false);
  }

  /**
   * Monta a lista de amostras severidade para mostra na grid
   * @param {*} atividadeAmostraList - lista de amostra severidade
   */
  function montaListaAmostra(atividadeAmostraList) {
    let cont = 0;
    atividadeAmostraList.forEach(doc => {
      doc.qtValoresAmostra = doc.atividadeAmostraQuantidadeList.length;
      doc.atividadeAmostraQuantidadeList.forEach(ativ => {
        const tipo = SeveridadeEnum.find(severidade => {
          return severidade.id === ativ.severidade.tpSeveridade;
        });
        ativ.nmTipo = tipo.name;
        ativ.tpSeveridade = tipo.id;
      });
      doc.idAtividadeAmostra = cont;
      doc.number = '#' + (cont + 1);
      cont = cont + 1;
    });

    setLastNumber(cont);
    setAmostraList(atividadeAmostraList);
  }

  /**
   * Monta a lista de talhões para apresentar na grid
   *
   * @param {*} plantioTalhaoList - lista de plantio talhao
   */
  function montaListaTalhao(plantioTalhaoList) {
    const talhaoListAux = plantioTalhaoList.map(doc => {
      doc.id = doc.talhao.idTalhao;
      return doc.talhao;
    });

    setTalhaoList(talhaoListAux);
  }

  return (
    <>
      <Prompt when={!canLeave} message={onTryExit} />
      <MuiThemeProvider theme={theme}>
        <div className={classes.talhoesTab}>
          {loading && <Loading />}
          <SwipeableViews
            className={classes.swipeableViews}
            containerStyle={containerStyle}
            index={index}
            slideStyle={slidestyle}
            ignoreNativeScroll={false}
          >
            {[
              <div key={0} className={classes.swipeableViews}>
                <CardIcon
                  titulo={getTitleCard()}
                  logoUrl={<BorderColor />}
                  icon={true}
                  usePaddings={false}
                >
                  <Formik
                    initialValues={{
                      dtAtividade: dtAtividade,
                      dsAdicional: monitoramento.dsAdicional,
                      areaCultivo: valueAreaCultivo,
                      plantio: valuePlantio
                    }}
                    validateOnBlur
                    validateOnChange={false}
                    enableReinitialize={true}
                    onSubmit={onClickSalvar}
                    validationSchema={Yup.object().shape({
                      dtAtividade: Yup.string()
                        .test(
                          '',
                          formatMessage('acompanhamentoProdutor.dataMinima'),
                          validationFormikDtAtividade
                        )
                        .required(formatMessage('acompanhamentoProdutor.campoObrigatorio')),
                      areaCultivo: Yup.object()
                        .nullable()
                        .required(formatMessage('acompanhamentoProdutor.campoObrigatorio')),
                      plantio: Yup.object()
                        .nullable()
                        .required(formatMessage('acompanhamentoProdutor.campoObrigatorio'))
                    })}
                    render={({ values, errors, touched, handleBlur, setFieldValue, submitForm }) => {
                      handleSubmit = submitForm;

                      return (
                        <div style={{ marginTop: 15 }}>
                          <form autoComplete='off'>
                            <Grid container spacing={16}>
                              <Grid item xs={3}>
                                <Autocomplete
                                  itens={areaCultivo}
                                  campoOp={'dsAreaCultivo'}
                                  campoChave={'idAreaCultivo'}
                                  label={formatMessage('acompanhamentoProdutor.areaDeCultivo')}
                                  name='areaCultivo'
                                  error={!valueAreaCultivo && touched.areaCultivo}
                                  helperText={
                                    touched.areaCultivo && !valueAreaCultivo
                                      ? formatMessage('acompanhamentoProdutor.campoObrigatorio')
                                      : null
                                  }
                                  onChangeOwn={event => {
                                    onChangeAutoCompleteAreaCultivo(event, setFieldValue);
                                  }}
                                  onChangeAutoComplete={validarInicializacaoAreaCultivo}
                                  value={tratarAutoComplete(valueAreaCultivo, 'dsAreaCultivo')}
                                  key={keyUpdate}
                                  valueAutoComplete={tratarAutoComplete(
                                    valueAreaCultivo,
                                    'dsAreaCultivo'
                                  )}
                                  valueSelect={
                                    tratarAutoComplete(valueAreaCultivo, 'dsAreaCultivo').dsAreaCultivo
                                  }
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <Autocomplete
                                  disabled={!valueAreaCultivo}
                                  itens={plantio}
                                  campoOp={'dsPlantioSelected'}
                                  campoChave={'idPlantio'}
                                  titleSecondLine={'titleSubLine'}
                                  titleFirstLine={'dsPlantioCustom'}
                                  descriptionFirstLine={'dtPlantioFormat'}
                                  descriptionSecondLine={'dsSubLineCustom'}
                                  label={formatMessage('acompanhamentoProdutor.plantio')}
                                  name='plantio'
                                  error={!valuePlantio && touched.plantio}
                                  helperText={
                                    touched.plantio && !valuePlantio
                                      ? formatMessage('acompanhamentoProdutor.campoObrigatorio')
                                      : null
                                  }
                                  onChangeOwn={event =>
                                    onChangeAutoCompletePlantio(event, setFieldValue)
                                  }
                                  onChangeAutoComplete={validarInicializacaoPlantio}
                                  value={tratarAutoComplete(valuePlantio, 'nmCultura')}
                                  key={keyUpdate}
                                  valueAutoComplete={tratarAutoComplete(valuePlantio, 'nmCultura')}
                                  valueSelect={
                                    tratarAutoComplete(valuePlantio, 'nmCultura').dsPlantioSelected
                                  }
                                />
                              </Grid>

                              <Grid item xs={2}>
                                <DatePicker
                                  disabled={valuePlantio === '' || valuePlantio === null}
                                  minDate={condicaoDataMinima()}
                                  maxDate={condicaoDataMaxima()}
                                  locale={getUserLang()}
                                  style={{ height: 70 }}
                                  className={classes.font}
                                  label={formatMessage('acompanhamentoProdutor.data')}
                                  valueDate={values.dtAtividade}
                                  errorTouched={errors.dtAtividade && errorDtAtividade(touched)}
                                  helperText={
                                    errorDtAtividade(touched) &&
                                    (values.dtAtividade === null
                                      ? formatMessage('acompanhamentoProdutor.campoObrigatorio')
                                      : errors.dtAtividade)
                                  }
                                  onBlurDatePicker={date =>
                                    setDtAtividade(moment(date.target.value, DateByLanguage()))
                                  }
                                  onChangeDatePicker={event => changeData(event, setFieldValue)}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={16}>
                              <Grid item xs={12}>
                                <TextField
                                  id='dsAdicional'
                                  name='dsAdicional'
                                  value={values.dsAdicional}
                                  label={formatMessage('acompanhamentoProdutor.observacao')}
                                  className={classes.textField}
                                  InputLabelProps={{
                                    className: classes.font
                                  }}
                                  onBlur={e => onChangeLancamento('dsAdicional', e, handleBlur)}
                                  onChange={event => setFieldValue('dsAdicional', event.target.value)}
                                  error={errors.dsAdicional && touched.dsAdicional}
                                  helperText={touched.dsAdicional ? errors.dsAdicional : ''}
                                />
                              </Grid>
                            </Grid>

                            {showButton && (
                              <div className={classes.fab} style={{ bottom: 220 }}>
                                <ButtonFABMenu
                                  icon={iconMenuPontos}
                                  actions={[
                                    {
                                      icon: <Check style={{ color: 'white' }} />,
                                      name: formatMessage('acompanhamentoProdutor.salvar'),
                                      onClickIcon: handleSubmit,
                                      color: '#42ADE8',
                                      disabled: error
                                    },
                                    {
                                      icon: <Close style={{ color: 'white' }} />,
                                      name: formatMessage('acompanhamentoProdutor.cancelar'),
                                      onClickIcon: onCancelarOrBack,
                                      color: '#F33A30'
                                    }
                                  ]}
                                />
                              </div>
                            )}
                          </form>
                        </div>
                      );
                    }}
                  />
                </CardIcon>

                <div className={classes.allCardSwipeableMonitoramento}>
                  <GroupButtonGrid
                    showAdd={true}
                    showEdit={true}
                    showDelete={true}
                    disableEdit={disabledEdit}
                    disableDelete={disabledEdit}
                    onClickAdd={() => clickShowButton(false)}
                    onClickEdit={() => clickShowButton(false, itemSelect)}
                    onClickDelete={clickExcluir}
                  />

                  <Card
                    title={formatMessage('acompanhamentoProdutor.amostras')}
                    cabecalho={true}
                    withOutBorderRadius={true}
                    disablePadding
                    childrenStyle={childrenStyleCard}
                  >
                    <DataTable
                      noMargin={true}
                      noBox={true}
                      usePaddings={false}
                      data={amostraList}
                      columns={MonitoramentoData}
                      selectChange={selectChange}
                      sortable={true}
                      showPagination={false}
                    />
                  </Card>
                </div>
              </div>,

              index === 1 ? (
                <div key={1} className={classes.cardEditarMonitoramento}>
                  <EditarMonitoramento
                    lastNumber={lastNumber}
                    itemSelectProdutos={itemSelectProdutos}
                    plantio={valuePlantio}
                    clickShowButton={clickShowButton}
                    talhaoList={talhaoList}
                    onSalvarAmostras={onSalvarAmostras}
                  />
                </div>
              ) : (
                <div key={1} className={classes.cardEditarMonitoramento} />
              )
            ]}
          </SwipeableViews>
        </div>
      </MuiThemeProvider>
    </>
  );

  /**
   * Busca o titulo do card
   *
   * @returns - titulo do card
   */
  function getTitleCard() {
    if (produtor) {
      const nmProdutor = produtor.nmProdutor;
      const nmMunicipio = produtor.municipio.nmMunicipio;
      return formatMessage('acompanhamentoProdutor.produtor') + ': ' + nmProdutor + ', ' + nmMunicipio;
    }

    return '';
  }

  /**
   * Valida data para salvar o formik
   */
  function validationFormikDtAtividade() {
    const newDtAtividade = moment(dtAtividade).format('YYYY-MM-DD');
    var validation = true;
    if (valuePlantio.dtColheita !== undefined) {
      validation = valuePlantio.dtColheita >= newDtAtividade;
    }

    return valuePlantio.dtPlantio <= newDtAtividade && validation;
  }

  /**
   * Açao ao editar autocomplete de área de cultivo
   */
  function onChangeAutoCompleteAreaCultivo(event, setFieldValue) {
    setFieldValue('areaCultivo', event);
    setValueAreaCultivo(event);
    setValuePlantio('');
    setFieldValue('plantio', event);
    setFieldValue('dtAtividade', null);
    const aux = lancamentoAplicacao;
    aux['areaCultivo'] = event;

    setLancamentoAplicacao(aux);
    setDtAtividade(null);
    setKeyUpdate(Math.random());
    setTalhaoList([]);

    if (event !== null) {
      validarInicializacaoPlantio(event);
    }
  }

  function errorDtAtividade(touched) {
    return touched.dtAtividade && valuePlantio !== '' && valuePlantio !== null;
  }

  /**
   * Condicao de data minima
   */
  function condicaoDataMinima() {
    if (valuePlantio !== '' && valuePlantio !== null) return valuePlantio.dtPlantio;
  }

  /**
   * Condicao de data máxima
   */
  function condicaoDataMaxima() {
    if (valuePlantio !== '' && valuePlantio !== null && valuePlantio.dtColheita !== '')
      return valuePlantio.dtColheita;

    return '2100-01-01';
  }

  /**
   * Altera o campo de data
   */
  function changeData(event, setFieldValue) {
    const aux = lancamentoAplicacao;
    aux['dtAtividade'] = event;

    setFieldValue('dtAtividade', event);
    setLancamentoAplicacao(aux);
    setDtAtividade(event);
  }

  /**
   * Açao ao editar autocomplete de plantio
   */
  function onChangeAutoCompletePlantio(event, setFieldValue) {
    setFieldValue('plantio', event);
    setFieldValue('dtAtividade', null);
    const aux = lancamentoAplicacao;
    aux['plantio'] = event;

    setLancamentoAplicacao(aux);
    setDtAtividade(null);
    setValuePlantio(event);

    if (event !== null) {
      buscarTalhoes(event);
    }
  }

  /**
   * Busca lista de talhões
   */
  function buscarTalhoes(valuePlantioNew) {
    const talhaoNewList = valuePlantioNew.plantioTalhaoList.map(doc => {
      doc.talhao.id = doc.talhao.idTalhao;
      return doc.talhao;
    });

    setTalhaoList(talhaoNewList);
  }

  /**
   * Inicializa autocomplete de área de cultivo
   */
  function validarInicializacaoAreaCultivo() {
    buscarProdutor();
  }

  /**
   * Trata alteraçoes no autocomplete
   */
  function tratarAutoComplete(dataNew, nmVariavel) {
    if (!dataNew || dataNew[nmVariavel] === undefined) {
      return '';
    }

    return dataNew;
  }

  /**
   * Volta para a tela anterior da aplicação
   */
  function onCancelarOrBack() {
    props.history.push('/app/acompanhamento-produtor/' + idProdutor);
  }

  /**
   * Altera campos
   */
  function onChangeLancamento(campo, e, callback, convertToNum = false) {
    callback(e);
    const aux = lancamentoAplicacao;
    aux[campo] = convertToNum ? +e.target.value : e.target.value;
    setLancamentoAplicacao(aux);
  }

  /**
   * Seleciona item na grid
   * @param {*} select item selecionado
   */
  function selectChange(select) {
    if (select) {
      setItemSelect(select);
      setDisabledEdit(false);
    } else {
      setItemSelect('');
      setDisabledEdit(true);
    }
  }

  /**
   * Trata apresentar o FAB na tela
   */
  function clickShowButton(show, item) {
    setShowButton(show);
    setIndex(show ? 0 : 1);
    setItemSelectProdutos(item);
  }

  /**
   * Mostra confirmação para deletar item
   */
  function clickExcluir() {
    swalOptions('acompanhamentoProdutor.desejaExcluirAmostra')
      .then(willDelete => {
        if (willDelete) {
          trataValorDeletado();
        } else {
          setDisabledEdit(false);
        }
      })
      .catch(() => {
        swalError('acompanhamentoProdutor.falhaAoExcluirAmostra');
      });
  }

  /**
   * Trata item deletado
   */
  function trataValorDeletado() {
    const position = amostraList.indexOf(itemSelect);

    let newData = amostraList;
    newData.splice(position, 1);
    newData = [...newData];

    var cont = 0;
    newData.forEach(doc => {
      doc.idAtividadeAmostra = cont;
      doc.number = '#' + (cont + 1);
      cont = cont + 1;
    });

    setAmostraList(newData);
    setLastNumber(cont);
    swalSucess('acompanhamentoProdutor.amostraRemovidaComSucesso');
  }

  /**
   * Salva uma nova amostra
   */
  function onSalvarAmostras(values, edit) {
    var position = '';
    var lastNumberAux = lastNumber;

    if (edit) {
      position = amostraList.indexOf(itemSelectProdutos);
    }

    values.qtValoresAmostra = values.atividadeAmostraQuantidadeList.length;

    var amostraListAux = [];

    if (position !== '') {
      amostraListAux = [...amostraList];
      values.idAtividadeAmostra = itemSelectProdutos.idAtividadeAmostra;
      values.number = itemSelectProdutos.number;
      values.nrAtividadeAmostra = lastNumberAux;
      amostraListAux[position] = values;
    } else {
      values.idAtividadeAmostra = lastNumberAux;
      values.number = '#' + (lastNumberAux + 1);
      values.nrAtividadeAmostra = lastNumberAux + 1;
      lastNumberAux = lastNumberAux + 1;
      amostraListAux = amostraList.concat(values);
    }

    clickShowButton(true);
    setLastNumber(lastNumberAux);
    setAmostraList(amostraListAux);
  }

  /**
   * Salva o monitoramento
   * @param {*} values
   */
  function onClickSalvar(values) {
    swalLoading();

    const monitoramentoToSave = {
      areaCultivo: values.areaCultivo,
      dtAtividade: moment(values.dtAtividade).format('YYYY-MM-DDTHH:mm:ssZZ'),
      plantio: values.plantio,
      nrQuantidade: 0,
      tpAtividade: 3,
      tpUnidade: 0,
      dsAdicional: values.dsAdicional,
      atividadeAmostraList: amostraList,
      produtor: produtor,
      stInvasoras: 0
    };

    let saveOrUpdate = null;
    let mensagemToSave = '';
    if (idMonitoramento) {
      monitoramentoToSave.idAtividade = idMonitoramento;
      mensagemToSave = 'acompanhamentoProdutor.monitoramentoAtualizado';
      saveOrUpdate = AcompanhamentoProdutor.editAcompanhamento(monitoramentoToSave);
    } else {
      mensagemToSave = 'acompanhamentoProdutor.monitoramentoCadastrado';
      saveOrUpdate = AcompanhamentoProdutor.addAcompanhamento(monitoramentoToSave);
    }

    saveOrUpdate
      .then(() => {
        swalSucess(mensagemToSave);
        setCanLeave(true);

        if (!idMonitoramento) {
          criarRecomendacao(monitoramentoToSave);
        } else {
          onCancelarOrBack();
        }
      })
      .catch(() => {
        swalError('acompanhamentoProdutor.falhaAoCadastrarMonitoramento');
      });
  }

  /**
   * Redireciona para a tela de recomendação com os dados de monitoramento
   * @param {*} monitoramentoToSave
   */
  function criarRecomendacao(monitoramentoToSave) {
    swalOptions('acompanhamentoProdutor.desejaCriarRecomendacao').then(res => {
      setCanLeave(true);

      if (!res) {
        swalSucess('acompanhamentoProdutor.monitoramentoCadastrado');
        onCancelarOrBack();
      } else {
        swal.close();
        props.history.push({
          pathname: '/app/acompanhamento-produtor/editar/' + idProdutor + '/0/novo',
          state: { initialDados: monitoramentoToSave }
        });
      }
    });
  }

  function onTryExit(location) {
    if (isDirty()) {
      swalOptions('acompanhamentoProdutor.salvarLancamentoSair').then(res => {
        swal.close();
        if (res) {
          handleSubmit && handleSubmit();
        } else {
          setCanLeave(true);
          props.history.push(location.pathname);
        }
      });
      return false;
    }
  }

  function isDirty() {
    return JSON.stringify(initialValues) !== JSON.stringify(lancamentoAplicacao);
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(Monitoramento);
export default withRouter(connect(mapStateToProps)(enhaced));
