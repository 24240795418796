import React, { Component } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardFilters from '@components/CardFilters/CardFilters';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Usuario from '@resources/Usuario';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import Produtores from '@resources/Produtores';
import { findAllActivesCertificacoes } from '@resources/CertificacaoOrganica';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {
  card: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 15px)'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  page: {
    marginBottom: 15
  },
  document: {
    width: '100%'
  },
  divDocument: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
};

/**
 * Tela de Relatório de relação de talhões
 *
 * @author Gabriela Farias
 * @class RelatorioRelacaoTalhao
 * @extends {Component} - Componente React
 */
class RelatorioRelacaoTalhao extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const relatorio = messagesLinguage['relacaoTalhao.relatorio'];
    const regiao = messagesLinguage['relacaoTalhao.regiao'];
    const municipio = messagesLinguage['relacaoTalhao.municipio'];
    const carregando = messagesLinguage['relacaoTalhao.carregando'];
    const gerandoRelatorio = messagesLinguage['relacaoTalhao.gerandoRelatorio'];
    const falhaAoGerarRelatorio = messagesLinguage['relacaoTalhao.falhaAoGerarRelatorio'];
    const relatorioRelacaoTalhao = messagesLinguage['relacaoTalhao.relatorioRelacaoTalhao'];
    const tecnicoResponsavel = messagesLinguage['relacaoTalhao.tecnicoResponsavel'];
    const naoPossui = messagesLinguage['relacaoTalhao.naoPossui'];
    const produtor = messagesLinguage['relacaoTalhao.produtor'];
    const sim = messagesLinguage['relacaoTalhao.sim'];
    const nao = messagesLinguage['relacaoTalhao.nao'];
    const statusCertificacaoOrganica = messagesLinguage['relacaoTalhao.statusCertificacaoOrganica'];
    const convencional = messagesLinguage['relacaoTalhao.convencional'];
    const emConversao = messagesLinguage['relacaoTalhao.emConversao'];
    const brasil = messagesLinguage['relacaoTalhao.brasil'];
    const eua = messagesLinguage['relacaoTalhao.eua'];
    const comunidadeEuropeia = messagesLinguage['relacaoTalhao.comunidadeEuropeia'];
    const baixarCSV = messagesLinguage['relacaoTalhao.baixarCSV'];
    const status = messagesLinguage['relacaoTalhao.status'];
    const ativo = messagesLinguage['relacaoTalhao.ativo'];
    const inativo = messagesLinguage['relacaoTalhao.inativo'];

    const intlProvider = new IntlProvider({
      locale: getUserLang(),
      messages: {
        relatorio,
        regiao,
        municipio,
        carregando,
        gerandoRelatorio,
        falhaAoGerarRelatorio,
        relatorioRelacaoTalhao,
        tecnicoResponsavel,
        naoPossui,
        produtor,
        nao,
        sim,
        statusCertificacaoOrganica,
        convencional,
        emConversao,
        brasil,
        eua,
        comunidadeEuropeia,
        baixarCSV,
        status,
        ativo,
        inativo
      }
    });

    provider = intlProvider.getChildContext();

    this.state = {
      filePDF: null,
      fileXLS: null,
      fileCSV: null,
      closeCard: false,
      errorCard: false,
      loading: false,
      loadingDados: true,
      listTecnicos: [],
      listMunicipio: [],
      listRegiao: [],
      listProdutor: [],
      certificaoOrganicaList: [],
      operationTypeString: [
        {
          label: provider.intl.formatMessage({ id: 'municipio' }),
          field: 'nmMunicipio',
          list: 'listMunicipio',
          type: 'autocomplete'
        },
        {
          label: provider.intl.formatMessage({ id: 'regiao' }),
          field: 'nmRegiao',
          list: 'listRegiao',
          type: 'autocomplete'
        },
        {
          label: provider.intl.formatMessage({ id: 'tecnicoResponsavel' }),
          field: 'nmTecnico',
          list: 'listTecnicos',
          type: 'autocomplete'
        },
        {
          label: provider.intl.formatMessage({ id: 'produtor' }),
          field: 'nmProdutor',
          list: 'listProdutor',
          type: 'autocomplete'
        },
        {
          label: provider.intl.formatMessage({ id: 'statusCertificacaoOrganica' }),
          field: 'tpCertificacaoOrganica',
          type: 'autocomplete',
          list: 'certificaoOrganicaList'
        },
        {
          label: provider.intl.formatMessage({ id: 'status' }),
          field: 'stTalhao',
          type: 'enum',
          options: [
            {
              label: provider.intl.formatMessage({ id: 'ativo' }),
              field: 'ativo',
              value: '1'
            },
            {
              label: provider.intl.formatMessage({ id: 'inativo' }),
              field: 'inativo',
              value: '0'
            }
          ]
        }
      ]
    };

    const title = 'relacaoTalhao.relatorioRelacaoTalhao';
    if (this.props.itensState.name !== 'relacaoTalhao.relatorioRelacaoTalhao') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.clickGerarRelatorio = this.clickGerarRelatorio.bind(this);
    this.modalErrorRelatorio = this.modalErrorRelatorio.bind(this);
  }

  componentDidMount() {
    Promise.all([
      Usuario.findAllUsuarios()
        .then(query => {
          let data = [];
          query.data.forEach(doc => {
            if (doc.tpPerfil === 2) {
              data.push(doc);
            }
          });

          this.setState({
            listTecnicos: data
          });
        })
        .catch(err => {
          this.setState({
            listTecnicos: []
          });
        }),

      Regioes.findAllRegiao()
        .then(query => {
          this.setState({
            listRegiao: query.data
          });
        })
        .catch(() => {
          this.setState({
            listRegiao: []
          });
        }),

      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          this.setState({
            listMunicipio: query.data
          });
        })
        .catch(err => {
          this.setState({
            listMunicipio: []
          });
        }),

      Produtores.findProdutor()
        .then(query => {
          this.setState({
            listProdutor: query.data
          });
        })
        .catch(err => {
          this.setState({
            listProdutor: []
          });
        }),

      findAllActivesCertificacoes()
        .then(query => {
          this.setState({
            certificaoOrganicaList: query.data
          });
        })
        .catch(err => {
          this.setState({
            certificaoOrganicaList: []
          });
        })
    ])
      .then(() => {
        this.setState({
          loadingDados: false
        });
      })
      .catch(() => {
        this.setState({
          loadingDados: false
        });
      });
  }

  clickGerarRelatorio(chips) {
    this.setState({
      errorCard: false,
      closeCard: true,
      loading: true,
      fileXLS: null,
      fileCSV: null,
      filePDF: null
    });

    let municipio = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    if (municipio !== undefined) {
      municipio = 'idMunicipio:' + municipio.value.value + ';';
    } else {
      municipio = '';
    }

    let regiao = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    if (regiao !== undefined) {
      regiao = 'idRegiao:' + regiao.value.value + ';';
    } else {
      regiao = '';
    }

    let nmTecnico = chips.find(item => {
      return item.filter.field === 'nmTecnico';
    });

    if (nmTecnico !== undefined) {
      nmTecnico = 'idTecnico:' + nmTecnico.value.value + ';';
    } else {
      nmTecnico = '';
    }

    let nmProdutor = chips.find(item => {
      return item.filter.field === 'nmProdutor';
    });

    if (nmProdutor !== undefined) {
      nmProdutor = 'idProdutor:' + nmProdutor.value.value + ';';
    } else {
      nmProdutor = '';
    }

    let tpCertificacaoOrganica = chips.find(item => {
      return item.filter.field === 'tpCertificacaoOrganica';
    });
    if (tpCertificacaoOrganica !== undefined) {
      tpCertificacaoOrganica = 'tpCertificacaoOrganica:' + tpCertificacaoOrganica.value.value + ';';
    } else {
      tpCertificacaoOrganica = '';
    }

    let stTalhao = chips.find(item => {
      return item.filter.field === 'stTalhao';
    });
    if (stTalhao !== undefined) {
      stTalhao = 'stTalhao:' + stTalhao.value.value + ';';
    } else {
      stTalhao = '';
    }

    const filters = municipio + regiao + nmTecnico + tpCertificacaoOrganica + nmProdutor + stTalhao;

    Relatorios.gerarRegistroRelacaoTalhao(filters)
      .then(doc => {
        this.setState({
          closeCard: false,
          fileXLS: doc.data.dsLinkXls,
          filePDF: doc.data.dsLinkPdf,
          fileCSV: doc.data.dsLinkCsv
        });
      })
      .catch(err => {
        this.modalErrorRelatorio();
      });
  }

  modalErrorRelatorio() {
    this.setState(
      {
        closeCard: false,
        errorCard: true,
        loading: false,
        fileXLS: null,
        fileCSV: null,
        filePDF: null
      },
      this.setState({ errorCard: false })
    );

    swal(provider.intl.formatMessage({ id: 'falhaAoGerarRelatorio' }), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div className={classes.card}>
          {(this.state.loading || this.state.loadingDados) && <Loading />}
          <CardFilters
            autoComplete={{
              listTecnicos: {
                list: this.state.listTecnicos,
                campoOp: 'nmUsuario',
                campoChave: 'idUsuario'
              },
              listMunicipio: {
                list: this.state.listMunicipio,
                campoOp: 'nmMunicipio',
                campoChave: 'idMunicipio'
              },
              listRegiao: {
                list: this.state.listRegiao,
                campoOp: 'nmRegiao',
                campoChave: 'idRegiao'
              },
              listProdutor: {
                list: this.state.listProdutor,
                campoOp: 'nmProdutor',
                campoChave: 'idProdutor'
              },
              certificaoOrganicaList: {
                list: this.state.certificaoOrganicaList,
                campoOp: 'nmTipoCertificacao',
                campoChave: 'idTipoCertificacao'
              }
            }}
            errorCard={this.state.errorCard}
            closeCard={this.state.closeCard}
            relatorio={true}
            onClickButton={this.clickGerarRelatorio}
            filtros={this.state.operationTypeString}
            usePaddings={false}
            onFilter={() => {}}
            onFilterChanged={() => {}}
            viewKey='relatorio-relacao-talhao'
          />

          {this.state.loading && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginTop: 20,
                justifyContent: 'center'
              }}
            >
              {provider.intl.formatMessage({ id: 'gerandoRelatorio' })}
            </div>
          )}

          <PDFViewer
            loadSucess={() => {
              this.setState({
                loading: false
              });
            }}
            errorCard={this.state.errorCard}
            fileXLS={this.state.fileXLS}
            filePDF={this.state.filePDF}
            additionalActions={[
              {
                icon: (
                  <FontAwesomeIcon
                    style={{ color: 'rgb(255, 255, 255)', width: 18, height: 18 }}
                    icon={faFileCsv}
                  />
                ),
                name: provider.intl.formatMessage({ id: 'baixarCSV' }),
                file: this.state.fileCSV,
                color: '#42ADE8'
              }
            ]}
          />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'relatorio-relacao-talhao';
  })[0].content
});

RelatorioRelacaoTalhao.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(RelatorioRelacaoTalhao));
export default withRouter(connect(mapStateToProps)(enhaced));
