import formatMessage from './i18n/formatMessage';

const headDatatable = [
  {
    field: 'nmMedidaCorretiva',
    headerName: formatMessage('medidaCorretiva.nome'),
    col: 6
  },
  {
    valueGetter: args => {
      if (args.node.data.stGeraAtividade === 1) {
        return formatMessage('medidaCorretiva.sim');
      } else {
        return formatMessage('medidaCorretiva.nao');
      }
    },
    headerName: formatMessage('medidaCorretiva.geraAtividade'),
    col: 4
  }
];

const filtersConfig = [
  {
    label: formatMessage('medidaCorretiva.nome'),
    field: 'nmMedidaCorretiva',
    type: 'string'
  },
  {
    label: formatMessage('medidaCorretiva.geraAtividade'),
    field: 'stGeraAtividade',
    type: 'enum',
    options: [
      {
        label: formatMessage('medidaCorretiva.nao'),
        field: 'inativo',
        value: '0'
      },
      {
        label: formatMessage('medidaCorretiva.sim'),
        field: 'ativo',
        value: '1'
      }
    ]
  }
];

export { headDatatable, filtersConfig };
