import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { getUserLang } from '@utils/localeUtils';
import * as Yup from 'yup';
import BorderColor from '@material-ui/icons/BorderColor';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import CardIcon from '@components/CardIcon/CardIcon';
import ProdutosAcompanhamento from './ProdutosAcompanhamento';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import * as moment from 'moment';
import Loading from '@components/Loading/Loading';
import swal from '@sweetalert/with-react';
import AcompanhamentoProdutor from '@resources/AcompanhamentoProdutor';
import Navigation from '@components/Navigation/Navigation';
import Produtor from '@resources/Produtores';
import SeveridadeEnum from './enumerations/SeveridadeEnum';
import Talhoes from './Cadastros/Talhoes';
import CardTitle from '@components/CardTitle/CardTitle';
import { style, theme } from './AcompanhamentoProdutor.styles';
import formatMessage from './i18n/formatMessage';
import InsumoEnum from './enumerations/InsumosEnum';
import UnidadeMedidaEnum from './enumerations/UnidadeMedidaEnum';
import { TabLateralList } from './Header/TabLateralList';
import { swalLoading, swalSucess, swalError, swalOptions } from './utils/SwalOptions';
import TitleDashboard from './Header/TitleDashboard';
import InsumosEnum from '../../../resources/InsumosEnum';
import AtividadeUrlEnum from './enumerations/AtividadeUrlEnum';
import Identificacao from './Cadastros/Identificacao';
import { montaStringsToScreen, trataDadosLabelPlantio } from './utils/TratarDadosCard';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Tela de cadastro de acompanhamento
 */
function LancamentoAplicacao(props) {
  const dataInicial = {
    plantioSeveridadeList: [],
    atividadeInsumoList: []
  };
  const tpLancamento = parseInt(props.match.params.lancamento);
  const [tabButtons, setTabButtons] = useState(TabLateralList(tpLancamento));
  const [allFotos, setAllFotos] = useState([]);
  const [allDeleteFotos, setAllDeleteFotos] = useState([]);
  const [typeFertilizante, setTypeFertilizante] = useState();
  const [checked, setChecked] = useState(false);
  const [canLeave, setCanLeave] = useState(false);
  const [index, setIndex] = useState(0);
  const [indexTab, setIndexTab] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const [dtAtividade, setDtAtividade] = useState(null);
  const [nrQuantidade, setNrQuantidade] = useState('');
  const [tpUnidade, setTpUnidade] = useState('');
  const [dsAdicional, setDsAdicional] = useState('');
  const [produtor, setProdutor] = useState({ plantioList: [] });
  const [dataAppSync, setDataAppSync] = useState(dataInicial);
  const [initialValues, setInitialValues] = useState({ ...dataInicial });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listTalhoes, setListTalhoes] = useState([]);
  const [keyUpdate, setKeyUpdate] = useState('');
  const [plantio, setPlantio] = useState([]);
  const [valuePlantio, setValuePlantio] = useState({});
  const [valueAreaCultivo, setValueAreaCultivo] = useState('');
  const [itensTalhoes, setItensTalhoes] = useState([]);
  const [loadWithoutPlantio, setLoadWithoutPlantio] = useState(false);
  let handleSubmit = {};
  const { classes } = props;

  useEffect(() => {
    mountTitleDashboard();
    buscarProdutor();

    if (props.match.params.idAplicacao !== undefined) {
      buscaInformacoesAcompanhamento();
    }
  }, []);

  useEffect(() => {
    if (props.match.params.idAplicacao !== undefined && plantio.length === 0 && valueAreaCultivo) {
      validarInicializacaoPlantio(valueAreaCultivo);
    }
  }, [produtor, valueAreaCultivo]);

  useEffect(() => {
    if (produtor && valueAreaCultivo && loadWithoutPlantio) {
      const plantioList = trataDadosLabelPlantio(produtor.plantioList, valueAreaCultivo);
      findAllTalhaoByPlantioList(plantioList);
      setLoadWithoutPlantio(false);
    }
  }, [produtor, valueAreaCultivo, loadWithoutPlantio]);

  /**
   * Monta o titulo da tela
   */
  function mountTitleDashboard() {
    const dashboardTitle = TitleDashboard(tpLancamento);

    if (props.itensState.name !== dashboardTitle) {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(dashboardTitle),
        name: dashboardTitle
      });
    }
  }

  /**
   * Busca as informações do produtor correspondente
   */
  function buscarProdutor() {
    Produtor.findProdutorById(props.match.params.id)
      .then(query => {
        setProdutor(query.data);
        if (props.match.params.idAplicacao === undefined) setLoading(false);
      })
      .catch(() => {
        swalErrorCarregar();
      })
      .finally(() => {
        dadosPreCadastrados();
      });
  }

  /**
   * Mostra o swal e trata a tela para erro
   */
  function swalErrorCarregar() {
    swalError('acompanhamentoProdutor.erroAoCarregarDados');
    setLoading(false);
    setError(true);
  }

  /**
   * Preenche os dados nos casos que foi criado uma recomendação apartir de um monitoramento de pragas
   */
  function dadosPreCadastrados() {
    if (props.location.state && props.location.state.initialDados) {
      const initialDados = props.location.state.initialDados;

      setValueAreaCultivo(initialDados.areaCultivo);
      setValuePlantio(initialDados.plantio);

      const dataLancamentoAplicacao = {
        areaCultivo: initialDados.areaCultivo,
        plantio: initialDados.plantio,
        dtAtividade: initialDados.dtAtividade,
        dsAdicional: initialDados.dsAdicional,
        atividadeInsumoList: []
      };

      validarInicializacaoPlantio(valueAreaCultivo);
      buscarTalhoes(initialDados.plantio.plantioTalhaoList);

      initialDados.atividadeAmostraList.forEach(doc => {
        const newTalhao = itensTalhoes.find(talhao => talhao.idTalhao === doc.talhao.idTalhao);

        if (!newTalhao) {
          doc.talhao.id = doc.talhao.idTalhao;
          const itensTalhoesAux = [...itensTalhoes];
          itensTalhoesAux.push(doc.talhao);
          setItensTalhoes(itensTalhoesAux);
        }
      });

      setDataAppSync(dataLancamentoAplicacao);
      setLoading(false);
      setKeyUpdate(Math.random());
      setDtAtividade(initialDados.dtAtividade);
      setDsAdicional(initialDados.dsAdicional);
    }
  }

  /**
   * Busca a lista de acompanhamento para editar
   */
  function buscaInformacoesAcompanhamento() {
    AcompanhamentoProdutor.findAllAcompanhamento(props.match.params.id)
      .then(query => {
        const acompanhamentoToEdit = query.data.find(doc => {
          return doc.data.idAtividade === parseInt(props.match.params.idAplicacao);
        });

        mountInfoToEdit(acompanhamentoToEdit);
      })
      .catch(() => swalErrorCarregar());
  }

  /**
   * Monta os dados para apresentar na tela
   *
   * @param {*} acompanhamentoToEdit - acompanhamento que será editado
   */
  function mountInfoToEdit(value) {
    buscaListaTalhoes(value);
    if (value.plantioDTO) {
      montaStringsToScreen(value);
      buscarTalhoes(value.plantioDTO.plantioTalhaoList);
    } else {
      setLoadWithoutPlantio(true);
    }

    organizaListaSeveridades(value);
    organizaListaInsumos(value.data.atividadeInsumoList);

    setValueAreaCultivo(value.data.areaCultivo);
    setValuePlantio(value.plantioDTO);
    value.data.dtAtividade = moment(value.data.dtAtividade).utc();

    organizaListaFotos(value.data.atividadeFotoList);
    setLoading(false);
    setDtAtividade(value.data.dtAtividade);
    setDataAppSync(value.data);
    setInitialValues({ ...value.data });
    setKeyUpdate(Math.random());
    setChecked(value.data.stInvasoras === 1);
  }

  /**
   * Busca a lista de talhões correspondente
   */
  function buscaListaTalhoes(acompanhamentoToEdit) {
    const itensTalhoesAux = acompanhamentoToEdit.data.atividadeTalhaoList.map(doc => {
      doc.talhao.id = doc.talhao.idTalhao;
      return doc.talhao;
    });
    setItensTalhoes(itensTalhoesAux);
  }

  /**
   * Organiza a lista de severidades
   *
   * @param {*} acompanhamentoToEdit - acompanhamento correspondente
   */
  function organizaListaSeveridades(acompanhamentoToEdit) {
    acompanhamentoToEdit.data.plantioSeveridadeList.forEach(doc => {
      doc.tipoSeveridade = SeveridadeEnum[doc.severidade.tpSeveridade];
    });
  }

  /**
   * Organiza os dados da lista de insumos
   *
   * @param {*} acompanhamentoToEdit  - acompanhamento para editar
   */
  function organizaListaInsumos(atividadeInsumoList) {
    atividadeInsumoList.forEach(doc => {
      doc.nrOrdem = '';
      doc.marca = doc.insumo.marca;
      doc.tpPesticida = InsumoEnum[doc.insumo.tpInsumo];
      if (getTypeFertilizante(doc)) {
        const tpUnidadeMedida = UnidadeMedidaEnum.find(unMedida => unMedida.id === doc.tpUnidadeMedida);

        setTypeFertilizante(true);
        doc.vlDoseFertilizante =
          Intl.NumberFormat(getUserLang()).format(doc.vlDose) + '/' + tpUnidadeMedida.name;
      } else {
        doc.nmVlDose = Intl.NumberFormat(getUserLang()).format(doc.vlDose) + ' L/kg';
      }
    });
  }

  /**
   * Organiza lista de plantios do autocomplete
   */
  function validarInicializacaoPlantio(valueAreaCultivoAux, byAreaCultivo) {
    const plantioList = trataDadosLabelPlantio(produtor.plantioList, valueAreaCultivoAux);
    if (byAreaCultivo) findAllTalhaoByPlantioList(plantioList);
    setPlantio(plantioList);
  }

  /**
   * Busca a lista de talhões quando não há plantio selecionado
   * @param {*} plantioList
   */
  function findAllTalhaoByPlantioList(plantioList) {
    const allTalhoes = [];

    plantioList.forEach(plantioNew => {
      plantioNew.plantioTalhaoList.forEach(newPlantioTalhaoList => {
        const exists = allTalhoes.find(
          talhaoPlantio => talhaoPlantio.talhao.idTalhao === newPlantioTalhaoList.talhao.idTalhao
        );

        if (!exists) allTalhoes.push(newPlantioTalhaoList);
      });
    });

    buscarTalhoes(allTalhoes);
  }

  /**
   * Organiza a lista de fotos para a tela de registro fotografico
   *
   * @param {*} atividadeInsumoList
   */
  function organizaListaFotos(atividadeFotoList) {
    let atividadeFotoListAux = [];
    if (atividadeFotoList) {
      atividadeFotoListAux = atividadeFotoList.map(doc => {
        doc.stRegistro = 1;
        doc.dsFotoBase64 = null;
        return doc;
      });
    }

    setAllFotos(atividadeFotoListAux);
  }

  /**
   * busca a lista de talhões para mostrar na grid
   */
  function buscarTalhoes(talhaoList) {
    const data = talhaoList
      .filter(plantioTalhao => plantioTalhao.talhao.stTalhao === 1)
      .map(doc => {
        doc.talhao.id = doc.talhao.idTalhao;
        return doc.talhao;
      });

    setListTalhoes(data);
    setKeyUpdate(Math.random());
  }

  return (
    <>
      <Prompt when={!canLeave} message={onTryExit} />
      <MuiThemeProvider theme={theme}>
        <div className={classes.containerAll}>
          {loading && <Loading />}
          <Navigation
            index={indexTab}
            handleChangeIndex={indexChanged => handleChangeIndex(indexChanged)}
            buttons={tabButtons}
          >
            {[
              <div className={classes.cardIdentificacao}>
                <CardIcon
                  titulo={getTitleCard()}
                  logoUrl={<BorderColor />}
                  icon={true}
                  usePaddings={false}
                >
                  <Formik
                    initialValues={{
                      dtAtividade: dtAtividade,
                      nrQuantidade: typeFertilizante ? '' : dataAppSync.nrQuantidade || nrQuantidade,
                      tpUnidade: dataAppSync.tpUnidade !== undefined ? dataAppSync.tpUnidade : tpUnidade,
                      dsAdicional: dataAppSync.dsAdicional || dsAdicional,
                      areaCultivo: valueAreaCultivo,
                      plantio: valuePlantio
                    }}
                    validateOnBlur
                    validateOnChange={false}
                    enableReinitialize={true}
                    onSubmit={onClickSalvar}
                    validationSchema={Yup.object().shape({
                      dtAtividade: Yup.string()
                        .nullable()
                        .required(formatMessage('acompanhamentoProdutor.campoObrigatorio'))
                        .test(
                          '',
                          formatMessage('acompanhamentoProdutor.dataMinima'),
                          validationFormikData
                        ),
                      nrQuantidade:
                        lancamentoIsAlertaClimaticoFotogratico() || typeFertilizante
                          ? Yup.string()
                          : Yup.number().required(
                              formatMessage('acompanhamentoProdutor.campoObrigatorio')
                            ),
                      tpUnidade: lancamentoIsAlertaClimaticoFotogratico()
                        ? Yup.string()
                        : Yup.string().required(
                            formatMessage('acompanhamentoProdutor.campoObrigatorio')
                          ),
                      areaCultivo: Yup.string().required(
                        formatMessage('acompanhamentoProdutor.campoObrigatorio')
                      ),
                      plantio: Yup.string()
                    })}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      submitForm
                    }) => {
                      handleSubmit = submitForm;

                      return (
                        <Identificacao
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          valueAreaCultivo={valueAreaCultivo}
                          buscarProdutor={buscarProdutor}
                          valuePlantio={valuePlantio}
                          produtor={produtor}
                          keyUpdate={keyUpdate}
                          setKeyUpdate={setKeyUpdate}
                          plantio={plantio}
                          dataAppSync={dataAppSync}
                          validarInicializacaoPlantio={(valueAreaCultivoTemp, byAreaCultivo) =>
                            validarInicializacaoPlantio(
                              valueAreaCultivoTemp ? valueAreaCultivoTemp : valueAreaCultivo,
                              byAreaCultivo
                            )
                          }
                          setDtAtividade={setDtAtividade}
                          typeFertilizante={typeFertilizante}
                          tpLancamento={tpLancamento}
                          changeProdutos={changeProdutos}
                          checked={checked}
                          changeCheked={() => setChecked(!checked)}
                          setNrQuantidade={setNrQuantidade}
                          setTpUnidade={setTpUnidade}
                          setValueAreaCultivo={setValueAreaCultivo}
                          setValuePlantio={setValuePlantio}
                          buscarTalhoes={buscarTalhoes}
                        />
                      );
                    }}
                  />
                </CardIcon>
              </div>,

              <ProdutosAcompanhamento
                filesDelete={filesDelete}
                allFotos={allFotos}
                saveListFoto={allFotosAux => setAllFotos(allFotosAux)}
                keyDataTable={keyUpdate}
                typeFertilizante={typeFertilizante}
                tpLancamento={tpLancamento}
                clickShowButton={clickShowButton}
                itensGridList={
                  tpLancamento === AtividadeUrlEnum.ALERTA_FITOSSANITARIO
                    ? dataAppSync.plantioSeveridadeList
                    : dataAppSync.atividadeInsumoList
                }
                onRowDragEnd={onRowDragEnd}
                onClickDeleteProduto={onClickDeleteProduto}
                listTalhoes={listTalhoes}
                rowsSelect={rowsSelect}
                itensTalhoes={itensTalhoes}
                onSelectTalhoes={() => {}}
                valuePlantio={valuePlantio}
                onClickAddProdutoSeveridade={onClickAddProdutoSeveridade}
                onClickAddProduto={onClickAddProduto}
                index={index}
              />,

              validaTpLancamento()
            ]}
          </Navigation>
          {showButton && (
            <div className={classes.fab} style={{ bottom: 220 }}>
              <ButtonFABMenu
                icon={iconMenuPontos}
                actions={[
                  {
                    icon: <Check style={{ color: 'white' }} />,
                    name: formatMessage('acompanhamentoProdutor.salvar'),
                    onClickIcon: () => handleChangeIndex(0, true),
                    color: '#42ADE8',
                    disabled: error
                  },
                  {
                    icon: <Close style={{ color: 'white' }} />,
                    name: formatMessage('acompanhamentoProdutor.cancelar'),
                    onClickIcon: onCancelar,
                    color: '#F33A30'
                  }
                ]}
              />
            </div>
          )}
        </div>
      </MuiThemeProvider>
    </>
  );

  function validaTpLancamento() {
    if (tpLancamento !== AtividadeUrlEnum.REGISTRO_CLIMATICO) {
      return (
        <div className={classes.talhoesTab}>
          <CardTitle title={formatMessage('acompanhamentoProdutor.talhoes')} />
          <Talhoes
            key={keyUpdate}
            validationGrid={false}
            listTalhoes={listTalhoes}
            rowsSelect={rowsSelect}
            itensTalhoes={itensTalhoes}
            onSelectTalhoes={rowsSelect}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }

  /**
   * Monta o titulo do card
   * @returns label de titulo
   */
  function getTitleCard() {
    const nmProdutor = produtor.nmProdutor || '';
    const municipio = produtor.municipio !== undefined ? produtor.municipio.nmMunicipio || '' : '';

    return `${formatMessage('acompanhamentoProdutor.produtor')}: ${nmProdutor}, ${municipio}`;
  }

  /**
   * Formik de validação de data
   *
   * @returns se é valido
   */
  function validationFormikData() {
    if (!dtAtividade) return false;
    const values = moment(dtAtividade).format('YYYY-MM-DD');
    let validation = true;
    if (!valuePlantio) return validation;

    if (valuePlantio.dtColheita !== undefined) {
      validation = valuePlantio.dtColheita >= values;
    }

    return valuePlantio.dtPlantio <= values && validation;
  }

  /**
   * Valida se é do tipo alerta, climatico ou fotografico
   * @returns - validação
   */
  function lancamentoIsAlertaClimaticoFotogratico() {
    return (
      tpLancamento === AtividadeUrlEnum.ALERTA_FITOSSANITARIO ||
      tpLancamento === AtividadeUrlEnum.REGISTRO_CLIMATICO ||
      tpLancamento === AtividadeUrlEnum.REGISTRO_FOTOGRAFICO
    );
  }

  /**
   * Altera a lista de produtos
   */
  function changeProdutos(newList, dtAtividadeTemp) {
    setDataAppSync(newList);
    if (dtAtividadeTemp) setDtAtividade(dtAtividadeTemp);
  }

  /**
   * Click em cancelar
   */
  function onCancelar() {
    props.history.push('/app/acompanhamento-produtor/' + props.match.params.id);
  }

  /**
   * Ação após drag in drop da grid
   * @param {*} event
   */
  function onRowDragEnd(event) {
    const data = dataAppSync;
    data.atividadeInsumoList = event;

    setDataAppSync(data);
  }

  /**
   * Ação ao adicionar item na lista de produto severidade
   *
   * @param {*} values - novo valor
   * @param {*} position - posiçõa
   */
  function onClickAddProdutoSeveridade(values, position) {
    var data = dataAppSync;

    if (position !== null) {
      data.plantioSeveridadeList[position] = values;
      data.plantioSeveridadeList = [...data.plantioSeveridadeList];
    } else {
      data.plantioSeveridadeList.push(values);
    }

    setDataAppSync(data);
    clickShowButton(true);
  }

  /**
   * Ação ao adicionar item na lista de produto
   *
   * @param {*} values - novo valor
   * @param {*} position - posiçõa
   */
  function onClickAddProduto(values, position) {
    let data = { ...dataAppSync };

    if (position !== null) {
      data.atividadeInsumoList[position] = values;
      data.atividadeInsumoList = [...data.atividadeInsumoList];
    } else {
      data.atividadeInsumoList.push(values);
    }

    setDataAppSync(data);
    setTypeFertilizante(getTypeFertilizante(values));
    setKeyUpdate(Math.random());
    clickShowButton(true);
  }

  function getTypeFertilizante(values) {
    return (
      values.tpPesticida.id === InsumosEnum.FERTILIZANTE_QUIMICO ||
      values.tpPesticida.id === InsumosEnum.FERTILIZANTE_ORGANICO
    );
  }

  /**
   * Açao ao deletar produto da listagem
   * @param {*} position - posição
   * @param {*} tpList - tipo de lista
   */
  function onClickDeleteProduto(position, tpList) {
    const data = { ...dataAppSync };

    if (tpList === AtividadeUrlEnum.ALERTA_FITOSSANITARIO) {
      data.plantioSeveridadeList.splice(position, 1);
      data.plantioSeveridadeList = [...data.plantioSeveridadeList];
    } else {
      data.atividadeInsumoList.splice(position, 1);
      data.atividadeInsumoList = [...data.atividadeInsumoList];
    }

    setDataAppSync(data);
    clickShowButton(true);
  }

  /**
   * row selecionada
   */
  function rowsSelect(select) {
    if (!loading) setItensTalhoes(select);
  }

  /**
   * mostrar ou retirar FAB da tela
   */
  function clickShowButton(show) {
    setShowButton(show);
    setIndex(show ? 0 : 1);
  }

  function onTryExit(location) {
    if (isDirty()) {
      swalOptions('acompanhamentoProdutor.salvarLancamentoSair').then(res => {
        swal.close();
        if (res) {
          handleSubmit && handleSubmit();
        } else {
          setCanLeave(true);
          props.history.push(location.pathname);
        }
      });
      return false;
    }
  }

  /**
   * Deleta arquivo
   *
   * @param {*} fotoToDelete - arquivo para ser deletado
   */
  function filesDelete(fotoToDelete) {
    var allDeleteFotosAux = allDeleteFotos;
    allDeleteFotosAux.push(fotoToDelete);
    setAllDeleteFotos(allDeleteFotosAux);
  }

  /**
   * Change index da sweapleviews
   *
   * @param {*} newIndex - novo index
   * @param {*} changeTab - tipo submit
   */
  function handleChangeIndex(newIndex, changeTab) {
    const tabButtonsAux = [...tabButtons];
    tabButtonsAux.forEach(doc => {
      if (newIndex === doc.key) {
        doc.select = true;
      } else {
        doc.select = false;
      }
    });
    setTabButtons(tabButtonsAux);

    clickShowButton(true);
    setIndexTab(newIndex);
    setIndex(0);
    if (changeTab) {
      handleSubmit();
    }
  }

  function isDirty() {
    return JSON.stringify(initialValues) !== JSON.stringify(dataAppSync);
  }

  /**
   * Açao para salvar novo acompanhamento
   * @param {*} values
   */
  function onClickSalvar(values) {
    swalLoading();

    if (tpLancamento === AtividadeUrlEnum.ALERTA_FITOSSANITARIO) {
      values.plantioSeveridadeList = tratarSeveridadeList();
    } else if (tpLancamento === AtividadeUrlEnum.REGISTRO_FOTOGRAFICO) {
      tratarListaRegistroFotografico(values);
    } else {
      values.atividadeInsumoList = tratarInsumoList();
    }

    values.atividadeTalhaoList = tratarTalhaoList();

    const data = {
      areaCultivo: values.areaCultivo,
      dtAtividade: moment(values.dtAtividade).format('YYYY-MM-DDTHH:mm:ssZZ'),
      plantio: valuePlantio && valuePlantio.idPlantio ? valuePlantio : null,
      nrQuantidade: typeFertilizante ? 0 : parseFloat(nrQuantidade) || 0,
      tpAtividade: tpLancamento,
      tpUnidade: values.tpUnidade || 0,
      dsAdicional: values.dsAdicional,
      atividadeInsumoList: values.atividadeInsumoList,
      atividadeTalhaoList: values.atividadeTalhaoList,
      atividadeFotoList: values.atividadeFotoList,
      produtor: produtor,
      stInvasoras: checked ? 1 : 0,
      plantioSeveridadeList: values.plantioSeveridadeList
    };

    let saveOrEdit = null;
    let titleSwal = '';

    if (props.match.params.idAplicacao !== undefined) {
      data.idAtividade = dataAppSync.idAtividade;
      saveOrEdit = AcompanhamentoProdutor.editAcompanhamento(data);
      titleSwal = 'acompanhamentoProdutor.lancamentoAtualizado';
    } else {
      saveOrEdit = AcompanhamentoProdutor.addAcompanhamento(data);
      titleSwal = 'acompanhamentoProdutor.lancamentoCadastrado';
    }

    saveOrEdit
      .then(() => {
        swalSucess(titleSwal);
        setCanLeave(true);

        props.history.push('/app/acompanhamento-produtor/' + props.match.params.id);
      })
      .catch(() => {
        swalError('acompanhamentoProdutor.falhaAoAtualizarLancamento');
      });
  }

  /**
   * Trata a lista de severidade para ser salva
   * @returns nova lista
   */
  function tratarSeveridadeList() {
    return dataAppSync.plantioSeveridadeList.map(sev => {
      return {
        nrLatitude: sev.nrLatitude,
        nrLongitude: sev.nrLongitude,
        dtSeveridade: sev.dtSeveridade,
        severidade: sev.severidade
      };
    });
  }

  /**
   * trata lista de registro fotografico
   */
  function tratarListaRegistroFotografico(values) {
    values.atividadeFotoList = allFotos;
    allDeleteFotos.forEach(item => {
      item.stRegistro = 2;
      values.atividadeFotoList.push(item);
    });
  }
  /**
   * Trata a lista de insumos para ser salvar
   * @returns
   */
  function tratarInsumoList() {
    let contInsumo = 0;

    return dataAppSync.atividadeInsumoList.map(doc => {
      contInsumo = contInsumo + 1;
      return {
        idAtividadeInsumo: contInsumo,
        insumo: doc.insumo,
        vlDose: doc.vlDose,
        tpUnidadeMedida: doc.tpUnidadeMedida
      };
    });
  }

  /**
   * Trata lista de talhoes para ser salva
   * @returns - lista a ser salva
   */
  function tratarTalhaoList() {
    let contTalhao = 0;

    return itensTalhoes.map(doc => {
      contTalhao = contTalhao + 1;
      return {
        idAtividadeTalhao: contTalhao,
        talhao: doc
      };
    });
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(LancamentoAplicacao);
export default withRouter(connect(mapStateToProps)(enhaced));
