import formatMessage from './i18n/formatMessage';
import moment from 'moment';
import { getUserLang } from '@utils/localeUtils';

function formatTextField() {
  switch (getUserLang()) {
    case 'pt-BR':
      return 'DD/MM/YYYY';
    case 'es-ES':
      return 'DD-MM-YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}

const headMedidaCorretiva = [
  {
    field: 'medidaCorretiva.nmMedidaCorretiva',
    headerName: formatMessage('naoConformidade.medidaCorretiva'),
    col: 4
  },
  {
    valueGetter: args => moment(args.node.data.dhInicio).format(formatTextField()),
    headerName: formatMessage('naoConformidade.dataInicial'),
    col: 3
  },
  {
    valueGetter: args =>
      args.node.data.dhTermino ? moment(args.node.data.dhTermino).format(formatTextField()) : '',
    headerName: formatMessage('naoConformidade.dataFinal'),
    col: 3
  },
  {
    valueGetter: args =>
      args.node.data.stSancaoMedidaCorretiva === 1
        ? formatMessage('naoConformidade.ativo')
        : formatMessage('naoConformidade.inativo'),
    headerName: formatMessage('naoConformidade.status'),
    col: 2
  }
];

const headDatatable = [
  {
    field: 'nmNaoConformidade',
    headerName: formatMessage('naoConformidade.nome'),
    col: 3
  },
  {
    headerName: formatMessage('naoConformidade.dataInicial'),
    col: 3,
    valueGetter: args => moment(args.node.data.dhInicio).format(formatTextField())
  },
  {
    headerName: formatMessage('naoConformidade.dataFinal'),
    col: 3,
    valueGetter: args =>
      args.node.data.dhTermino ? moment(args.node.data.dhTermino).format(formatTextField()) : ''
  },
  {
    headerName: formatMessage('naoConformidade.status'),
    col: 3,
    valueGetter: args =>
      args.node.data.stNaoConformidade === 1
        ? formatMessage('naoConformidade.ativo')
        : formatMessage('naoConformidade.inativo')
  }
];

const filtersConfig = [
  {
    label: formatMessage('naoConformidade.nome'),
    field: 'nmNaoConformidade',
    type: 'string'
  },
  {
    label: formatMessage('naoConformidade.dataInicial'),
    field: 'dhInicio',
    type: 'date'
  },
  {
    label: formatMessage('naoConformidade.dataFinal'),
    field: 'dhTermino',
    type: 'date'
  },
  {
    label: formatMessage('naoConformidade.status'),
    field: 'stNaoConformidade',
    type: 'enum',
    options: [
      {
        label: formatMessage('naoConformidade.inativo'),
        field: 'inativo',
        value: '0'
      },
      {
        label: formatMessage('naoConformidade.ativo'),
        field: 'ativo',
        value: '1'
      }
    ]
  }
];

export { headDatatable, filtersConfig, headMedidaCorretiva };
