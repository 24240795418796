import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import FirstPage from '@material-ui/icons/FirstPage';
import Button from '@material-ui/core/Button';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const pagina = messagesLinguage['pdfPageNavigator.pagina'];
const de = messagesLinguage['pdfPageNavigator.de'];

const intlProvider = new IntlProvider({
  locale: getUserLang(),
  messages: {
    pagina,
    de
  }
});

provider = intlProvider.getChildContext();

const styles = {
  card: {
    marginTop: 15,
    padding: 15,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    justifyContent: 'space-between'
  },
  button: {
    padding: 0,
    height: 32,
    minWidth: 32
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  zoom: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end'
  },
  labelZoom: {
    justifyContent: 'center',
    width: 50,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 15,
    marginRight: 15
  }
};

const allZoom = [
  {
    value: 0.1,
    name: 50
  },
  {
    value: 0.3,
    name: 75
  },
  {
    value: 0.5,
    name: 80
  },
  {
    value: 0.7,
    name: 90
  },
  {
    value: 0.8,
    name: 100
  },
  {
    value: 1.0,
    name: 110
  },
  {
    value: 1.1,
    name: 125
  },
  {
    value: 1.25,
    name: 150
  },
  {
    value: 1.5,
    name: 175
  },
  {
    value: 1.75,
    name: 190
  },
  {
    value: 1.9,
    name: 200
  }
];

let zoom = allZoom[4];

function PDFPageNavigator(props) {
  const { classes } = props;

  if (props.height !== zoom.value && props.height) {
    var item = allZoom.find(doc => {
      return props.height === doc.value;
    });
    zoom = item;
  }
  return (
    <div className={classes.card}>
      <div className={classes.pagination}>
        {provider.intl.formatMessage({ id: 'pagina' })} {props.pageNumber}{' '}
        {provider.intl.formatMessage({ id: 'de' })} {props.allPages}
        <Button
          disabled={props.pageNumber === 1 || props.pageNumber === 0}
          className={classes.button}
          style={{ transform: 'rotate(90deg)', marginLeft: 10 }}
          onClick={props.clickFirst}
        >
          <FirstPage />
        </Button>
        <Button
          disabled={props.pageNumber === 1 || props.pageNumber === 0}
          className={classes.button}
          style={{ marginLeft: 10 }}
          onClick={props.clickBack}
        >
          <ExpandLess />
        </Button>
        <Button
          disabled={props.pageNumber === props.allPages}
          className={classes.button}
          style={{ marginLeft: 10 }}
          onClick={props.clickNext}
        >
          <ExpandMore />
        </Button>
        <Button
          disabled={props.pageNumber === props.allPages}
          className={classes.button}
          style={{ transform: 'rotate(270deg)', marginLeft: 10 }}
          onClick={props.clickLast}
        >
          <FirstPage />
        </Button>
      </div>

      <div className={classes.zoom}>
        <Button
          className={classes.button}
          onClick={() => {
            var position = allZoom.indexOf(zoom);
            zoom = allZoom[position - 1];
            props.changeZoom(zoom.value);
          }}
          disabled={zoom.name === 50 || !props.pageNumber}
        >
          <ZoomOut />
        </Button>
        <div className={classes.labelZoom}>{zoom.name + '%'}</div>
        <Button
          className={classes.button}
          onClick={() => {
            var position = allZoom.indexOf(zoom);
            zoom = allZoom[position + 1];
            props.changeZoom(zoom.value);
          }}
          disabled={zoom.name === 200 || !props.pageNumber}
        >
          <ZoomIn />
        </Button>
      </div>
    </div>
  );
}

PDFPageNavigator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PDFPageNavigator);
