export default {
  clientes: {
    clientes: 'Customers',
    razaoSocial: 'Social name',
    nomeFantasia: 'Fantasy name',
    cnpj: 'CNPJ',
    telefone: 'Telephone',
    email: 'E-mail',
    planosDeAcesso: 'Access plan',
    produtoresAtivos: 'Active producers',
    motivo: 'Reason for inactivation',
    dataInativacao: 'Inactivation date',
    dataCadastro: 'Registration date',
    ativo: 'Active',
    inativo: 'Inactive',
    endereco: 'Address',
    contatosAdicionais: 'Additional contacts',
    usuarios: 'User',
    identificacao: 'Identification',
    cep: 'Zip code',
    estado: 'Country',
    cidade: 'City',
    rua: 'Street',
    numero: 'Number',
    complemento: 'Complement',
    bairro: 'District',
    departamento: 'Department',
    nome: 'Name',
    perfil: 'Profile',
    statusConta: 'Account status',
    situacao: 'Situation',
    identificacaoCliente: 'Identification',
    cadastroCliente: 'Customer base',
    gravar: 'Save',
    salvar: 'Save',
    cancelar: 'Cancel',
    reenviarCodigo: 'Resend activation email',
    principal: 'Main',
    financeiro: 'Financial',
    entregas: 'Deliveries',
    administrativo: 'Administrative',
    gerencia: 'Management',
    ti: 'T.I.',
    tecnico: 'Technician',
    revenda: 'Resale',
    campoObrigatorio: 'Required field',
    emailInvalido: 'Invalid Email',
    carregando: 'Loading',
    clienteCadastrado: 'Customer successfully registered',
    clienteEditado: 'Customer updated successfully',
    sucesso: 'Success',
    erro: 'Error',
    usuarioJaCadastrado: 'User already registered',
    registrado: 'Registered',
    confirmado: 'Confirmed',
    desabilitado: 'Disabled',
    admin: 'Administrator',
    salvando: 'saving',
    atualizando: 'Updating',
    usuarioJaCadastradoTabela: 'This user has already been added to the user list',
    naoFoiPossivelInserirUsuario: 'Unable to insert user',
    naoFoiPossivelInserirEditar: 'Unable to update user',
    erroCadastroUsuario: 'Error registering user',
    usuarioCadastradoComSucesso: 'User successfully registered',
    clienteCadastradoComSucesso: 'Customer successfully registered',
    usuariosNaoCadastrados: 'Sorry, we were unable to register the following users:',
    clienteEditadoComSucesso: 'Customer edited successfully',
    preRegistro: 'before registration',
    produtor: 'Producer',
    falhaAoCarregarClientes: 'Failed to load client',
    numeroMaximoUsuarios: 'User limit for this plan has been reached',
    excluir: 'Delete',
    excluindo: 'Deleting',
    desejaExcluir: 'Do you want to delete?',
    nao: 'Not',
    clienteExcluidoComSucesso: 'Customer deleted successfully',
    falhaAoExcluirCliente: 'Failed to delete client',
    usuarioExcluidoComSucesso: 'User deleted successfully',
    plano: 'Plan',
    telefoneInvalido: 'Invalid phone number',
    cnpjInvalido: 'Invalid CNPJ',
    cepInvalido: 'Invalid CEP',
    desejaExcluirEndereco: 'Do you want to delete address?',
    desejaExcluirContatosAdicionais: 'Do you want to delete additional contact?',
    erroAoReenviarEmail: 'Failed to resend email',
    codigoEnviadoComSucesso: 'Code successfully sent',
    pais: 'Country',
    cadastrarPais: 'First sign up country',
    cnpjDuplicado: 'CNPJ already registered',
    erroAoEditar: 'There was an error editing client',
    erroAoCadastrar: 'Error registering customer',
    erroBuscarCliente: 'There was an error fetching customers',
    emailDuplicado: 'Email is already registered',
    gerente: 'Manager',
    representante: 'Representative',
    confirmar: 'Confirm',
    salvarClienteSair: 'There is unsaved information, do you want to save the client?',
    descartar: 'To discard',
    agriculturaOrganica: 'Organic agriculture',
    cooperativa: 'Cooperative',
    cooperado: 'Cooperative',
    tipoCooperativa: 'Cooperative type',
    central: 'Central',
    produtores: 'Producers',
    produtoresComAcesso: 'Producers with access to the system',
    erroAnexarFoto: 'Error attaching photo'
  }
};
