export default {
  fitossanidade: {
    fitossanidade: "Fitossanidade",
    nao: "Não",
    desejaExcluir: "Deseja excluir?",
    excluir: "Excluir",
    fitossanidadeRemovidoComSucesso: "Fitossanidade removida com sucesso",
    falhaAoExcluirFitossanidade: "Falha ao excluir fitossanidade",
    excluindo: "Excluindo",
    cultura: "Cultura",
    tipo: "Tipo",
    situacao: "Situação",
    nome: "Nome",
    fitossanidadeCadastradoComSucesso: "Fitossanidade cadastrada com sucesso",
    fitossanidadeEditadoComSucesso: "Fitossanidade editada com sucesso",
    falhaAoCadastrarFitossanidade: "Falha ao cadastrar fitossanidade",
    falhaAoEditarFitossanidade: "Falha ao editar fitossanidade",
    falhaAoCarregarFitossanidade: "Falha ao carregar fitossanidade",
    salvar: "Salvar",
    cancelar: "Cancelar",
    salvando: "Salvando",
    atualizando: "Atualizando",
    cadastroDeFitossanidade: "Cadastro de fitossanidade",
    campoObrigatorio: "Campo obrigatório",
    identificacaoDeFitossanidade: "Identificação de fitossanidade",
    doencaSolo: "Doença de solo",
    doencaFoliar: "Doença foliar",
    nematoide: "Nematóide",
    descricao: "Descrição",
    ativo: "Ativo",
    inativo: "Inativo",
    pragas: "Pragas"
  }
};
