export default {
  listDetalhes: {
    produtosProibidos: 'Productos prohibidos',
    areaDeCultivo: 'Área de cultivo',
    cultura: 'Cultura',
    detentor: 'Poseedor',
    cultivarUtilizadas: 'Cultivar utilizado',
    talhoesAdicionado: 'Taladros usado',
    areaTanque: 'Área por tanque',
    nrQuantidade: 'Cantidad',
    dsUnMedida: 'Un. de medida',
    dsRecomendacaoAdicional: 'Recomendación adicional',
    produtos: 'Produtos',
    tecnicoResponsavel: 'Responsable tecnico',
    produtividade: 'Productividad',
    espacamento: 'Espaciamiento',
    semeadura: 'Siembra',
    invasoras: 'Invasivo',
    alertasFitossanitarios: 'Alertas Fitosanitarias',
    nao: 'No',
    sim: 'Si',
    talhoes: 'Campos',
    observacao: 'Nota',
    amostras: 'Muestras',
    fitossanidadesEncontradas: 'Plagas encontradas',
    quantidadeAmostras: 'Cantidad de muestra',
    standFinal: 'Stand final',
    diasEstimados: 'Días estimados',
    dataPrevista: 'Fecha prevista',
    produtividadeEstimada: 'Productividad estimada',
    previsaoColheita: 'Pronóstico de cosecha',
    colheita: 'Cosecha',
    previsto: 'Esperado',
    imagens: 'Imágenes',
    descricaoRegistroClimatico: 'Descripción del registro climático',
    tipoAuditoria: 'Tipo de auditoría',
    interna: 'Interno',
    externa: 'Externo',
    situacao: 'Situación',
    emAndamento: 'En proceso',
    finalizadaSemNaoConformidade: 'Terminado sin incumplimiento',
    finalizadaComNaoConformidade: 'Terminado con el incumplimiento',
    suspensa: 'Suspendido',
    naoConformidades: 'Unconformities',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    sancao: 'Sanción',
    limiteComprimento: 'Límite para el cumplimiento'
  }
};
