/*
Autor: Kátia miglioli
*/
import React, { Component } from "react";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { flattenMessages } from "@utils/languageUtils";
import { IntlProvider } from "react-intl";
import { getUserLang } from "@utils/localeUtils";
import ptBR from "./i18n/ptBR";
import enUS from "./i18n/enUs";
import esES from "./i18n/esES";
import CardIcon from "@components/CardIcon/CardIcon";
import Grid from "@material-ui/core/Grid";
import TextField from "@components/TextFieldOwn/TextFieldOwn";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ButtonFABMenu from "@components/ButtonFABMenu/ButtonFABMenu";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "@sweetalert/with-react";
import Autocomplete from "@components/Autocomplete/Autocomplete";
import _ from "lodash";
import Loading from "@components/Loading/Loading";
import DatePicker from "@components/DatePicker/DatePicker";
import * as moment from "moment";
import NumberFormat from "react-number-format";
import Cultura from "@resources/Cultura";
import Cotacao from "@resources/Cotacao";

let messages = { "pt-BR": ptBR, "en-US": enUS, "es-ES": esES };

const iconMenuPontos = require("@images/icone_fab_menu.png");

let { provider } = new IntlProvider({});

const style = () => ({
  parentDiv: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  bottomDiv: {
    flex: 2
  },
  topDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  identif_Div: {
    flex: 1,
    position: "relative",
    maxHeight: "180px",
    minHeight: "170px"
  },
  limite_Div: {
    flex: 2,
    display: "flex",
    marginTop: "20px"
  },
  limit_Usuario_Div: {
    flex: 1
  },
  limit_Produtores_Div: {
    flex: 1
  },
  font: {
    fontSize: 14,
    width: "100%",
    height: 15,
    writeSpace: "noWrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  fab: {
    position: "relative",
    bottom: theme.spacing.unit * 34.5,
    right: theme.spacing.unit * 3.7,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    zIndex: 1,
    marginBottom: 0
  },
  font_numberformat: {
    fontSize: 14,
    width: "85%",
    writeSpace: "noWrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#00622B",
      main: "#00622B",
      dark: "#00622B",
      contrastText: "#fff"
    }
  },
  typography: {
    useNextVariants: true
  }
});

class EditarCotacao extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const cotacaoCadastradaComSucesso =
      messagesLinguage["cotacao.cotacaoCadastradaComSucesso"];
    const cotacaoEditadaComSucesso =
      messagesLinguage["cotacao.cotacaoEditadaComSucesso"];
    const falhaAoEditarCotacao =
      messagesLinguage["cotacao.falhaAoEditarCotacao"];
    const falhaAoCadastrarCotacao =
      messagesLinguage["cotacao.falhaAoCadastrarCotacao"];
    const falhaAoCarregarCotacao =
      messagesLinguage["cotacao.falhaAoCarregarCotacao"];
    const salvar = messagesLinguage["cotacao.salvar"];
    const cancelar = messagesLinguage["cotacao.cancelar"];
    const salvando = messagesLinguage["cotacao.salvando"];
    const atualizando = messagesLinguage["cotacao.atualizando"];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          cotacaoCadastradaComSucesso,
          cotacaoEditadaComSucesso,
          falhaAoCadastrarCotacao,
          falhaAoEditarCotacao,
          falhaAoCarregarCotacao,
          salvar,
          cancelar,
          salvando,
          atualizando
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      culturas: [],
      valorAutoComplete: "",
      key: "",
      validarInputs: false,
      loading: this.props.match.params.id !== undefined,
      valueDate: moment(),
      auxValor: ""
    };

    const title = "cotacao.cadastroDeCotacoes";
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: "UPDATE_TOOLBAR",
        toolbar: (
          <IntlProvider
            locale={getUserLang()}
            messages={flattenMessages(messages[getUserLang()])}
          >
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.onChangeOwn = this.onChangeOwn.bind(this);
    this.iniciarAtributosEdicao = this.iniciarAtributosEdicao.bind(this);
    this.clickCadastroEdicao = this.clickCadastroEdicao.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.handleBlurOwn = this.handleBlurOwn.bind(this);
    this.validarInicializacaoAutoComplete = _.debounce(
      this.validarInicializacaoAutoComplete.bind(this),
      200
    );
  }

  componentDidMount() {
    this.iniciarAtributosEdicao();
  }

  getCulturas() {
    Cultura.findAllCultura()
      .then(querySnapshot => {
        this.setState({
          culturas: querySnapshot.data
        });
      })
      .catch(error => {
        this.setState({
          culturas: []
        });
      });
  }

  validarInicializacaoAutoComplete() {
    this.getCulturas();
  }

  iniciarAtributosEdicao() {
    this.validarInicializacaoAutoComplete();
    if (this.props.match.params.id !== undefined) {
      Cotacao.findCotacaoById(this.props.match.params.id)
        .then(querySnapshot => {
          var data = querySnapshot.data;

          this.setState({
            valueDate: data.dtCotacao,
            valorAutoComplete: data.cultura,
            auxValor: data.vlCotacao,
            key: Math.random(),
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal(provider.intl.formatMessage({ id: "falhaAoCarregarCotacao" }), {
            icon: "error",
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                closeModal: true,
                className: "swal2-error"
              }
            }
          });
        });
    }
  }

  onChangeOwn(dsMunicipio) {
    this.setState({
      valorAutoComplete: dsMunicipio
    });
  }

  removeMunicipioEstado(municipioEstado) {
    var objMunicipio = { ...municipioEstado };
    delete objMunicipio.municipioEstado;
    return objMunicipio;
  }

  clickCadastroEdicao(values) {
    if (!this.state.validarInputs) {
      swal(
        <div>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>,
        {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          title: provider.intl.formatMessage({
            id:
              this.props.match.params.id === undefined
                ? "salvando"
                : "atualizando"
          })
        }
      );

      const data = {
        cultura: values.cultura,
        dtCotacao: moment(values.data).format("YYYY-MM-DDTHH:mm:ssZZ"),
        vlCotacao: values.valor
      };

      if (this.props.match.params.id === undefined) {
        Cotacao.addCotacao(data)
          .then(sucess => {
            swal(
              provider.intl.formatMessage({
                id: "cotacaoCadastradaComSucesso"
              }),
              {
                icon: "success",
                buttons: {
                  confirm: {
                    text: "Ok",
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: "swal2-Ok"
                  }
                }
              }
            ).then(() => {
              this.clickCancelar();
            });
          })
          .catch(error => {
            swal(
              provider.intl.formatMessage({ id: "falhaAoCadastrarCotacao" }),
              {
                icon: "error",
                buttons: {
                  confirm: {
                    text: "Ok",
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: "swal2-error"
                  }
                }
              }
            );
          });
      } else {
        data.idCotacao = this.props.match.params.id;

        Cotacao.editCotacao(data)
          .then(sucess => {
            swal(
              provider.intl.formatMessage({ id: "cotacaoEditadaComSucesso" }),
              {
                icon: "success",
                buttons: {
                  confirm: {
                    text: "Ok",
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: "swal2-Ok"
                  }
                }
              }
            ).then(() => {
              this.clickCancelar();
            });
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: "falhaAoEditarCotacao" }), {
              icon: "error",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-error"
                }
              }
            });
          });
      }
    }
  }

  clickCancelar() {
    this.props.history.push("/app/pesquisar-cotacoes");
  }

  handleBlurOwn(valorAutoComplete) {
    var value = false;
    if (valorAutoComplete === null) {
      value = true;
    }
    this.setState({
      validarInputs: value
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <IntlProvider
        locale={getUserLang()}
        messages={flattenMessages(messages[getUserLang()])}
      >
        <Formik
          initialValues={{
            data: this.state.valueDate,
            cultura: this.state.valorAutoComplete,
            valor: this.state.auxValor
          }}
          onSubmit={this.clickCadastroEdicao}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            data: Yup.string().required(
              <FormattedMessage id="cotacao.campoObrigatorio" />
            ),
            cultura: Yup.string().required(
              <FormattedMessage id="cotacao.campoObrigatorio" />
            ),
            valor: Yup.string().required(
              <FormattedMessage id="cotacao.campoObrigatorio" />
            )
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleChange
          }) => (
            <MuiThemeProvider theme={theme}>
              <form
                autoComplete="off"
                className={classes.parentDiv}
                onSubmit={handleSubmit}
              >
                <div className={classes.topDiv}>
                  {this.state.loading && <Loading />}
                  <div className={classes.identif_Div}>
                    <CardIcon
                      titulo={
                        <FormattedMessage id="cotacao.identificacaoDaCotacao" />
                      }
                    >
                      <Grid
                        container
                        spacing={16}
                        style={{ marginTop: "15px" }}
                      >
                        <Grid item xs={2} style={{ height: "75px" }}>
                          <DatePicker
                            locale={getUserLang()}
                            label={<FormattedMessage id="cotacao.data" />}
                            valueDate={this.state.valueDate}
                            name="data"
                            errorTouched={errors.data && touched.data}
                            helperText={
                              <FormattedMessage id="cotacao.campoObrigatorio" />
                            }
                            onBlurDatePicker={date => {
                              let validation = moment(
                                date.target.value,
                                "DD/MM/YYYY"
                              ).isValid();

                              if (!validation) {
                                setFieldValue("value", null);
                              }
                            }}
                            onChangeDatePicker={date => {
                              setFieldValue("value", date);
                              this.setState(() => ({
                                valueDate: date
                              }));
                            }}
                          />
                          )
                        </Grid>
                        <Grid item xs={3} style={{ height: "75px" }}>
                          <Autocomplete
                            itens={this.state.culturas}
                            campoOp={"nmCultura"}
                            campoChave={"idCultura"}
                            label={<FormattedMessage id="cotacao.cultura" />}
                            name="cultura"
                            onBlurOwn={this.handleBlurOwn}
                            error={
                              this.state.validarInputs
                                ? true
                                : errors.cultura && touched.cultura
                            }
                            helperText={
                              (errors.cultura && touched.cultura) ||
                              this.state.validarInputs ? (
                                <FormattedMessage id="cotacao.campoObrigatorio" />
                              ) : null
                            }
                            onChangeOwn={this.onChangeOwn}
                            onChangeAutoComplete={
                              this.validarInicializacaoAutoComplete
                            }
                            value={this.state.valorAutoComplete}
                            key={this.state.key}
                            valueAutoComplete={this.state.valorAutoComplete}
                          />
                        </Grid>
                        <Grid item xs={2} style={{ height: "75px" }}>
                          <NumberFormat
                            value={values.valor}
                            thousandSeparator={
                              getUserLang() === "en-US" ? "," : "."
                            }
                            decimalSeparator={
                              getUserLang() === "en-US" ? "." : ","
                            }
                            fixedDecimalScale={true}
                            decimalScale={2}
                            label={<FormattedMessage id="cotacao.valor" />}
                            name="valor"
                            onChange={handleChange}
                            onBlur={event => {
                              handleBlur(event);
                              setFieldValue("valor", this.state.auxValor);
                            }}
                            error={errors.valor && touched.valor}
                            helperText={
                              errors.valor && touched.valor ? (
                                <FormattedMessage id="cotacao.campoObrigatorio" />
                              ) : null
                            }
                            className={classes.textField}
                            InputProps={{
                              className: classes.font_numberformat
                            }}
                            InputLabelProps={{
                              className: classes.font_numberformat
                            }}
                            customInput={TextField}
                            onValueChange={values => {
                              const { floatValue } = values;
                              this.setState({ auxValor: floatValue });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardIcon>
                  </div>
                </div>
                <div className={classes.fab}>
                  <ButtonFABMenu
                    icon={iconMenuPontos}
                    actions={[
                      {
                        icon: <Check style={{ color: "white" }} />,
                        name: provider.intl.formatMessage({ id: "salvar" }),
                        onClickIcon: handleSubmit,
                        color: "#42ADE8"
                      },
                      {
                        icon: <Close style={{ color: "white" }} />,
                        name: provider.intl.formatMessage({ id: "cancelar" }),
                        onClickIcon: this.clickCancelar,
                        color: "#F33A30"
                      }
                    ]}
                  />
                </div>
              </form>
            </MuiThemeProvider>
          )}
        />
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarCotacao);
export default withRouter(connect(mapStateToProps)(enhaced));
