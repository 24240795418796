export default {
  classificacao: {
    descricao: 'Descripción',
    situacao: 'Situación',
    ativo: 'Activo',
    inativo: 'Inactivo',
    preservacaoAmbiental: 'pPreservación del medio',
    sim: 'Si',
    nao: 'No',
    produtivo: 'Productivo',
    classificacao: 'Uso y ocupación de la tierra',
    erroBuscarListaClassificacao: 'Error al buscar la lista de ocupación y uso del suelo',
    erroDeletarClassificacao: 'Error al eliminar el uso y la ocupación de la tierra',
    classificacaoRemovidoComSucesso: 'Eliminado con éxito el uso y la ocupación de la tierra',
    cadastroClassificacao: 'Registro de uso y ocupación del suelo',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    erroBuscarClassificacao: 'Error al buscar uso y ocupación del suelo',
    classificacaoEditadaComSucesso: 'Uso y ocupación del suelo editado con éxito',
    classificacaoSalvaComSucesso: 'Uso y ocupación de suelo salvado con éxito',
    erroEditarClassificacao: 'Error al editar el uso y ocupación de la tierra',
    erroSalvarClassificacao: 'Error al guardar el uso y la ocupación de la tierra',
    salvando: 'Ahorro',
    atualizando: 'Actualizando',
    desejaExcluir: '¿Quiere eliminar el uso y la ocupación de la tierra?',
    excluir: 'Eliminar',
    excluindo: 'Eliminando'
  }
};
