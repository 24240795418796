import React from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import CardIcon from '@components/CardIcon/CardIcon';
import { styles, theme } from './stylesCardWithTabs';

/**
 * Componente Card com Tabs no Header
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {Object} props - Props
 * @returns {JSX.Element} Componente React
 */
function CardWithTabs(props) {
  const {
    classes,
    tabs,
    selectedIndex = 0,
    onChangeTabIndex,
    componentBelow,
    title,
    containerSwipeableStyles,
    disableHeader,
    cardProps,
    subtitle
  } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <div
        className={classes.tabsRoot}
        style={{ display: cardProps && cardProps.filtros ? 'none' : null }}
      >
        {!disableHeader && (
          <div className={classes.title}>
            {title}
            <div className={classes.subtitle}>{subtitle}</div>
          </div>
        )}

        <Tabs
          value={selectedIndex}
          indicatorColor='primary'
          textColor='primary'
          className={classes.tabsContainer}
          onChange={onChangeTabIndex}
        >
          {tabs.map((item, i) => (
            <Tab label={item.label} className={classes.tab} key={i} />
          ))}
        </Tabs>
      </div>

      <CardIcon
        disableHeader
        filtros
        stateCardblock
        withOutBorderRadius
        expandedButton={cardProps && cardProps.expandedButton}
        tabs={
          cardProps && cardProps.filtros ? (
            <Tabs
              value={selectedIndex}
              indicatorColor='primary'
              textColor='primary'
              className={classes.tabsContainer}
              onChange={onChangeTabIndex}
            >
              {tabs.map((item, i) => (
                <Tab label={item.label} className={classes.tab} key={i} />
              ))}
            </Tabs>
          ) : null
        }
        cardFilter
        {...cardProps}
      >
        <SwipeableViews
          className={classes.swipeable}
          index={selectedIndex}
          ignoreNativeScroll
          containerStyle={{ height: '100%', ...containerSwipeableStyles }}
          slideStyle={{ overflow: props.withoutHidden ? '' : 'hidden' }}
        >
          {tabs.map((item, i) =>
            i === selectedIndex ? (
              <div key={i} style={{ height: '100%', ...item.style }}>
                {item.component}
              </div>
            ) : (
              <div key={i} />
            )
          )}
        </SwipeableViews>

        {componentBelow}
      </CardIcon>
    </MuiThemeProvider>
  );
}

export default withStyles(styles)(CardWithTabs);
