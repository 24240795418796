export default {
  relatorio: {
    relatorio: 'Relatório',
    relatorioListaProdutores: 'Lista de produtores',
    regiao: 'Região',
    municipio: 'Município',
    ativo: 'Ativo',
    inativo: 'Inativo',
    carregando: 'Carregando...',
    gerandoRelatorio: 'Gerando relatório...',
    falhaAoGerarRelatorio: 'Falha ao gerar relatório',
    tecnicoResponsavel: 'Técnico responsável',
    produtor: 'Produtor',
    situacao: 'Situação',
    uf: 'UF',
    decrescente: 'Decrescente',
    crescente: 'Crescente'
  }
};
