export default {
  mapa: {
    filtro: 'Filtrar por productor',
    campoObrigatorio: 'Campo obligatorio',
    produtor: 'Productor',
    talhao: 'Parcela',
    area: 'Area',
    produtorDuplicado: 'Productor duplicado',
    talhaoDuplicado: 'Parcela duplicado',
    mapa: 'Mapas'
  }
};
