export default {
  inseticida: {
    inseticidaRemovidoComSucesso: 'Inseticida removido com sucesso',
    falhaAoExcluirInseticida: 'Falha ao excluir inseticida',
    desejaExcluir: 'Deseja excluir o inseticida selecionado?',
    inseticida: 'Inseticida',
    identificacaoDaInseticida: 'Identificação',
    inseticidaCadastradoComSucesso: 'Inseticida cadastrado com sucesso',
    inseticidaEditadoComSucesso: 'Inseticida editado com sucesso',
    falhaAoEditarInseticida: 'Falha ao editar inseticida',
    falhaAoCadastrarInseticida: 'Falha ao cadastrar inseticida',
    falhaAoCarregarInseticida: 'Falha ao carregar inseticida',
    cadastroDeInseticida: 'Cadastro de inseticidas'
  }
};
