import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import swal from '@sweetalert/with-react';
import EditarAreaCultivo from './EditarAreaCultivo';
import Agenda from '@resources/Agenda';
import ButtonGrid from '@components/ButtonGrid/ButtonGrid';
import IconMap from '@material-ui/icons/Map';
import SwipeableViews from 'react-swipeable-views';
import Map from '@components/Map/Map';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import Button from '@material-ui/core/Button';
import CardTitle from '@components/CardTitle/CardTitle';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const style = theme => ({
  swipeable: {
    width: '100%',
    height: '100%'
  },
  root: {
    overflowY: 'scroll',
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 95px)',
    marginBottom: 15
  }
});

class AreasCultivo extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const descricao = messagesLinguage['produtores.descricao'];
    const areaCultivo = messagesLinguage['produtores.areaCultivo'];
    const desejaExcluirAreaCultivo = messagesLinguage['produtores.desejaExcluirAreaCultivo'];
    const nao = messagesLinguage['produtores.nao'];
    const excluir = messagesLinguage['produtores.excluir'];
    const areaCultivoRemovidoComSucesso = messagesLinguage['produtores.areaCultivoRemovidoComSucesso'];
    const talhaoPlantioAssociado = messagesLinguage['produtores.talhaoPlantioAssociado'];
    const exclusaoAreaDeCultivoNaoPermitida =
      messagesLinguage['produtores.exclusaoAreaDeCultivoNaoPermitida'];
    const plantioAssociado = messagesLinguage['produtores.plantioAssociado'];
    const area = messagesLinguage['produtores.area'];
    const mapa = messagesLinguage['produtores.mapa'];
    const cancelar = messagesLinguage['produtores.cancelar'];
    const nenhumTalhaoDemarcado = messagesLinguage['produtores.nenhumTalhaoDemarcado'];
    const areaDeCultivo = messagesLinguage['produtores.areaDeCultivo'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          areaDeCultivo,
          descricao,
          areaCultivo,
          desejaExcluirAreaCultivo,
          nao,
          excluir,
          areaCultivoRemovidoComSucesso,
          talhaoPlantioAssociado,
          exclusaoAreaDeCultivoNaoPermitida,
          plantioAssociado,
          area,
          mapa,
          cancelar,
          nenhumTalhaoDemarcado
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    const itensUniMedida = ['acre', 'alq.', 'ha', 'm2'];

    this.state = {
      loading: false,
      key: Math.random(),
      statusEdit: false,
      talhoesList: [],
      navigatorPosition: {},
      index: 0,
      disabledEdit: true,
      itemSelect: [],
      itemEditAreaCultivo: [],
      headAreasCultivo: [
        {
          field: 'dsAreaCultivo',
          headerName: provider.intl.formatMessage({ id: 'descricao' }),
          col: 5
        },
        {
          valueGetter: args =>
            `${args.node.data.nrAreaCultivo.toLocaleString('pt-br', { minimumFractionDigits: 0 })} ${
              itensUniMedida[args.node.data.tpUnidade]
            }`,
          headerName: provider.intl.formatMessage({ id: 'areaCultivo' }),
          col: 7,
          valueFormatter: valueFormatter
        }
      ]
    };

    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onClickAddAreaCultivo = this.onClickAddAreaCultivo.bind(this);
    this.onClickDeleteAreaCultivo = this.onClickDeleteAreaCultivo.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.renderIfIndex = this.renderIfIndex.bind(this);
  }

  clickExcluir() {
    const position = this.props.data.areaCultivoList.indexOf(this.state.itemSelect);
    var data = this.props.data;
    var plantio, talhao, mensagem;

    if (data.plantioList && data.plantioList.length > 0) {
      plantio = this.AreaDePlantioPossuiAssociados(data.plantioList);
    }
    if (data.talhaoList.length > 0) {
      talhao = this.AreaDePlantioPossuiAssociados(data.talhaoList);
    }

    if (!talhao && !plantio) {
      this.excluirAreaDeCultivo(position);
    } else {
      mensagem = provider.intl.formatMessage({ id: 'talhaoPlantioAssociado' });
      this.mensagemNaoPodeDeletarAreaDeCultivo(mensagem);
    }
  }

  componentWillUnmount() {
    if (this.state.index === 1 || this.state.index === 2) {
      this.props.changeFAB();
    }
  }

  onClickDeleteAreaCultivo(position) {
    this.props.onClickDeleteAreaCultivo(position);
  }

  mensagemNaoPodeDeletarAreaDeCultivo(mensagem) {
    var mensagemGeral = provider.intl.formatMessage({
      id: 'exclusaoAreaDeCultivoNaoPermitida'
    });
    swal(mensagemGeral, mensagem, {
      icon: 'warning',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    });
  }

  AreaDePlantioPossuiAssociados(dados) {
    var i, dLeng, areaDePlantio, count;
    dLeng = dados.length;
    areaDePlantio = this.state.itemSelect;
    count = false;
    for (i = 0; i < dLeng; i++) {
      if (dados[i].areaCultivo.idAreaCultivo === areaDePlantio.idAreaCultivo) {
        count = true;
        break;
      }
    }
    return count;
  }

  excluirAreaDeCultivo(position) {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluirAreaCultivo' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        this.props.onClickDeleteAreaCultivo(position);

        swal(provider.intl.formatMessage({ id: 'areaCultivoRemovidoComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      } else {
        this.setState({
          disabledEdit: false
        });
      }
    });
  }

  clickEditar(map) {
    this.setState(
      {
        loading: false
      },
      () => {
        this.props.clickAdicionarAreaCultivo(this.state.itemSelect);

        if (this.props.idProdutor !== undefined) {
          Agenda.findUltimaAgendaProdutor(this.props.idProdutor)
            .then(doc => {
              if (doc.data && doc.data.nrLongitude !== undefined && doc.data.nrLatitude !== undefined) {
                this.setState({
                  loading: true,
                  navigatorPosition: {
                    latitude: doc.data.nrLatitude,
                    longitude: doc.data.nrLongitude
                  }
                });
              } else {
                this.getPosition();
              }
            })
            .catch(() => {
              this.getPosition();
            });
        } else {
          this.getPosition();
        }

        var listTalhoes = [];
        this.props.talhoesList.forEach(doc => {
          if (doc.areaCultivo.idAreaCultivo === this.state.itemSelect.idAreaCultivo) {
            listTalhoes.push(doc);
          }
        });

        this.setState({
          itemEditAreaCultivo: this.state.itemSelect,
          statusEdit: map,
          talhoesList: listTalhoes,
          loading: listTalhoes.length === 0 && !map
        });

        if (!map) {
          if (listTalhoes.length === 0) {
            swal({
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              },
              icon: 'warning',
              text: provider.intl.formatMessage({ id: 'nenhumTalhaoDemarcado' })
            });
          } else {
            this.setState({
              index: 1
            });
          }
        } else {
          this.setState({ index: 2 });
        }

        if (listTalhoes.length > 0 || map) {
          this.props.changeFAB();
        }
      }
    );
  }

  getPosition() {
    if (this.props.municipio !== null && this.props.municipio !== '') {
      var geoLocation = new window.google.maps.Geocoder();
      geoLocation.geocode(
        {
          address:
            this.props.municipio.nmMunicipio +
            ', ' +
            this.props.municipio.uf.nmUf +
            ', ' +
            this.props.pais.nmPais
        },
        event => {
          this.setState({
            loading: true,
            navigatorPosition: {
              latitude: event[0].geometry.location.lat(),
              longitude: event[0].geometry.location.lng()
            }
          });
        }
      );
    }
  }

  onCancel() {
    this.setState({
      index: 0,
      itemEditAreaCultivo: {},
      statusEdit: false,
      itemSelect: '',
      disabledEdit: true,
      key: Math.random()
    });

    this.props.changeFAB();
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  onClickAddAreaCultivo(values, position) {
    this.setState(
      {
        statusEdit: false,
        index: 0
      },
      () => this.props.onClickAddAreaCultivo(values, position) || this.props.changeFAB()
    );
  }

  handleChangeIndex(index) {
    this.setState({
      index: index
    });
  }

  /**
   * Retorna o Componente se o index da tab selecionada for igual ao index passado por parâmetro
   *
   * @author Bruno Eduardo <bruno.soares@kepha.com.br>
   * @param {Number} index - Index pra ser validado
   * @param {JSX.Element} Component - Componente que vai retornar se passar pela validação
   * @returns {JSX.Element} O componente passado por parâmetro ou uma div "vazia"
   */
  renderIfIndex(index, Component) {
    return index === this.state.index ? (
      <div key={index} style={{ height: '100%' }}>
        {Component}
      </div>
    ) : (
      <div key={index} />
    );
  }

  render() {
    const { classes } = this.props;
    const paginacao = {
      length: this.props.data.areaCultivoList !== undefined ? this.props.data.areaCultivoList.length : 0,
      page: 0
    };

    return (
      <div className={classes.root}>
        <SwipeableViews
          className={classes.swipeable}
          index={this.state.index}
          onChangeIndex={this.handleChangeIndex}
          ignoreNativeScroll={true}
          containerStyle={{ height: '100%' }}
          slideStyle={{ overflow: 'hidden' }}
        >
          {[
            this.renderIfIndex(
              0,
              <div key={0} className={classes.card}>
                <CardTitle
                  containerStyle={{ marginBottom: this.props.tpPerfil ? 0 : 'none' }}
                  title={provider.intl.formatMessage({ id: 'areaDeCultivo' })}
                />

                {!this.props.tpPerfil && (
                  <GroupButtonGrid
                    hiddenMargin
                    showAdd
                    showEdit={true}
                    showDelete={true}
                    customButtons={[
                      <ButtonGrid
                        hiddenMargin
                        withoutMargin={false}
                        show={true}
                        iconSVG={true}
                        icon={<IconMap style={{ color: '#FFFFFF' }} />}
                        color='#463766'
                        disabled={this.state.disabledEdit}
                        onClick={() => {
                          this.clickEditar(false);
                        }}
                      />
                    ]}
                    disableEdit={this.state.disabledEdit}
                    disableDelete={this.state.disabledEdit}
                    onClickEdit={() => this.clickEditar(true)}
                    onClickDelete={this.clickExcluir}
                    onClickAdd={() => this.setState({ index: 2 }, this.props.changeFAB)}
                  />
                )}

                <DataTable
                  key={this.state.key}
                  noMargin={true}
                  data={
                    this.props.data.areaCultivoList !== undefined ? this.props.data.areaCultivoList : []
                  }
                  columns={this.state.headAreasCultivo}
                  selectChange={this.selectChange}
                  showPagination={true}
                  pagination={paginacao}
                />
              </div>
            ),
            this.renderIfIndex(
              1,
              <div key={1} className={classes.card} style={{ height: '100%' }}>
                <CardCadastro
                  height='100%'
                  borderRadius={false}
                  hiddenTitle={false}
                  titulo={provider.intl.formatMessage({ id: 'mapa' })}
                  botoes={[
                    <Button color='inherit' variant='contained' onClick={this.onCancel}>
                      {provider.intl.formatMessage({ id: 'cancelar' })}
                    </Button>
                  ]}
                >
                  {this.state.loading && this.state.talhoesList.length && (
                    <div style={{ width: '100%', height: '100%', marginBottom: 10 }}>
                      <Map
                        latLong={{
                          nrLatitude: this.state.itemSelect.nrLatitude,
                          nrLongitude: this.state.itemSelect.nrLongitude
                        }}
                        label={provider.intl.formatMessage({
                          id: 'area'
                        })}
                        selectAreCultivo={true}
                        selectTalhao={{}}
                        data={this.state.talhoesList || []}
                        navigatorPosition={this.state.navigatorPosition}
                        valueCoords={[]}
                        valueCoordsAreaCultivo={this.state.talhoesList || []}
                        onClickMap={() => {}}
                      />
                    </div>
                  )}
                </CardCadastro>
              </div>
            ),
            this.renderIfIndex(
              2,
              <EditarAreaCultivo
                onClickDeleteAreaCultivo={this.onClickDeleteAreaCultivo}
                onClickAddAreaCultivo={this.onClickAddAreaCultivo}
                clickAdicionarAreaCultivo={() =>
                  this.props.clickAdicionarAreaCultivo() || this.props.changeFAB()
                }
                maxIdAreaCultivo={this.props.maxIdAreaCultivo}
                talhoesList={this.state.talhoesList || []}
                data={this.props.data || []}
                itemEditAreaCultivo={this.state.itemEditAreaCultivo}
                statusEdit={this.state.statusEdit}
                onCancel={this.onCancel}
                tpPerfil={this.props.tpPerfil}
                estadoList={this.props.estadoList}
                municipioList={this.props.municipioList}
                comunidadeList={this.props.comunidadeList}
                tratarValorComunidade={this.props.tratarValorComunidade}
                validarInicializacaoAutoCompleteEstado={
                  this.props.validarInicializacaoAutoCompleteEstado
                }
                validarInicializacaoAutoCompleteMunicipio={
                  this.props.validarInicializacaoAutoCompleteMunicipio
                }
              />
            )
          ]}
        </SwipeableViews>
      </div>
    );
  }
}

function valueFormatter(params) {
  return params.value.toLocaleString(getUserLang(), {});
}

export default withStyles(style)(AreasCultivo);
