//@author Katia Miglioli
import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  labelEntrar: {
    color: 'white',
    fontWeight: 'lighter',
    fontSize: 14,
    cursor: 'pointer'
  },
  marginBotao: {
    margin: theme.spacing.unit,
    background: '#00622B'
  },
  botaoProgresso: {
    color: green[500],
    position: 'absolute',
    marginTop: 12,
    marginLeft: -80
  },
  divBotao: {
    margin: theme.spacing.unit,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'center'
  }
});
const theme = createMuiTheme({
  palette: {
    primary: green
  },
  typography: {
    useNextVariants: true
  }
});

class Botao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingButton: false
    };
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.divBotao}>
            <Button
              variant='contained'
              color='primary'
              className={classes.marginBotao}
              style={{
                width: this.props.statusConfirmarCadatro ? null : 126
              }}
              onClick={this.props.onClickButton}
              disabled={this.props.loadingButton}
            >
              <label className={classes.labelEntrar}>{this.props.text}</label>
            </Button>
            {this.props.loadingButton && (
              <CircularProgress
                size={24}
                style={{ marginLeft: this.props.marginLeft }}
                className={classes.botaoProgresso}
              />
            )}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(Botao);
