import React from "react";

class PanelHeader extends React.Component {
  render() {
    return (
      <div
        style={{
          height: this.props.height ? this.props.height : "85%",
          width: this.props.width ? this.props.width : "98%"
        }}
        className={
          "panel-header " +
          (this.props.size !== undefined
            ? "panel-header-" + this.props.size
            : "")
        }
      >
        {this.props.content}
      </div>
    );
  }
}

export default PanelHeader;
