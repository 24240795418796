import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import Comunidade from '@resources/Comunidade';
import Produtor from '@resources/Produtores';
import Usuario from '@resources/Usuario';
import CardFilters from '@components/CardFilters/CardFilters';
import * as moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { styles } from './RelatorioVisitas.styles';
import formatMessage from './i18n/formatMessage';

const tecnicosFilter = {
  label: formatMessage('visitas.tecnicos'),
  field: 'nmUsuario',
  list: 'listTecnicos',
  type: 'autocomplete'
};

const produtorFilter = {
  label: formatMessage('visitas.produtor'),
  field: 'nmProdutor',
  list: 'listProdutor',
  type: 'autocomplete'
};

const regiaoFilter = {
  label: formatMessage('visitas.regiao'),
  field: 'nmRegiao',
  list: 'regiaoList',
  type: 'autocomplete'
};

const estadoFilter = {
  label: formatMessage('visitas.estado'),
  field: 'nmUf',
  list: 'estadoList',
  type: 'autocomplete'
};

const municipioFilter = {
  label: formatMessage('visitas.municipio'),
  field: 'nmMunicipio',
  list: 'municipioList',
  type: 'autocomplete'
};

const comunidadeFilter = {
  label: formatMessage('visitas.comunidade'),
  field: 'nmComunidade',
  list: 'comunidadeList',
  type: 'autocomplete'
};

const periodoFilter = {
  label: formatMessage('visitas.periodo'),
  field: 'dtPeriodo',
  type: 'date'
};

/**
 * Tela de Relatório de visitas
 *
 * @author Gabriela Farias
 * @class Relatorio
 * @extends {Component} - Componente React
 */
function RelatorioVisitas(props) {
  const [filePDF, setFilePDF] = useState(null);
  const [fileXLS, setFileXLS] = useState(null);
  const [fileCSV, setFileCSV] = useState(null);
  const [closeCard, setCloseCard] = useState(false);
  const [errorCard, setErrorCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDados, setLoadingDados] = useState(true);
  const [municipioList, setMunicipioList] = useState([]);
  const [estadoList, setEstadoList] = useState([]);
  const [comunidadeList, setComunidadeList] = useState([]);
  const [tecnicoList, setTecnicoList] = useState([]);
  const [regiaoList, setRegiaoList] = useState([]);
  const [produtorList, setProdutorList] = useState([]);
  const [operationTypeString, setOperationTypeString] = useState([]);
  const { classes } = props;

  useEffect(() => {
    const title = 'visitas.relatorioVisitas';
    if (props.itensState.name !== 'visitas.relatorioVisitas') {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(title),
        name: title
      });
    }

    let listFilters = [
      produtorFilter,
      periodoFilter,
      regiaoFilter,
      estadoFilter,
      municipioFilter,
      comunidadeFilter
    ];
    if (props.infoUsuario.tpPerfil === 1) {
      listFilters.push(tecnicosFilter);
    }

    setOperationTypeString(listFilters);

    Promise.all([
      Regioes.findAllRegiao()
        .then(query => {
          setRegiaoList(query.data);
        })
        .catch(() => {
          setRegiaoList([]);
        }),
      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          setMunicipioList(query.data);
        })
        .catch(err => {
          setMunicipioList([]);
        }),
      Localizacao.findAllEstados()
        .then(query => {
          setEstadoList(query.data);
        })
        .catch(err => {
          setEstadoList([]);
        }),
      Comunidade.findAllComunidades()
        .then(query => {
          setComunidadeList(query.data);
        })
        .catch(err => {
          setComunidadeList([]);
        }),
      Usuario.findAllUsuarios()
        .then(query => {
          setTecnicoList(query.data.filter(usuario => usuario.tpPerfil === 2));
        })
        .catch(err => {
          setTecnicoList([]);
        }),
      Produtor.findProdutor()
        .then(query => {
          setProdutorList(query.data);
        })
        .catch(err => {
          setProdutorList([]);
        })
    ])
      .then(() => {
        setLoadingDados(false);
      })
      .catch(() => {
        setLoadingDados(false);
      });
  }, []);

  function clickGerarRelatorio(value) {
    if (value.length === 0) {
      swal({
        title: formatMessage('visitas.mensagemSemFiltro'),
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          cancel: {
            text: formatMessage('visitas.nao'),
            value: false,
            visible: true,
            closeModal: true,
            className: 'swal2-cancel'
          },
          confirm: {
            text: formatMessage('visitas.sim'),
            value: true,
            visible: true,
            closeModal: false,
            className: 'swal2-confirm'
          }
        }
      }).then(willDelete => {
        if (willDelete) {
          gerarRelatorio(value);
          swal.close();
        }
      });
    } else {
      gerarRelatorio(value);
    }
  }

  function gerarRelatorio(chips) {
    setErrorCard(false);
    setCloseCard(true);
    setLoading(true);
    setFilePDF(null);
    setFileXLS(null);
    setFileCSV(null);

    let municipioChip = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    if (municipioChip !== undefined) {
      municipioChip = 'idMunicipio:' + municipioChip.value.value + ';';
    } else {
      municipioChip = '';
    }

    let regiaoChip = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    if (regiaoChip !== undefined) {
      regiaoChip = 'idRegiao:' + regiaoChip.value.value + ';';
    } else {
      regiaoChip = '';
    }

    let estadoChip = chips.find(item => {
      return item.filter.field === 'nmUf';
    });

    if (estadoChip !== undefined) {
      estadoChip = 'idUf:' + estadoChip.value.value + ';';
    } else {
      estadoChip = '';
    }

    let comunidadeChip = chips.find(item => {
      return item.filter.field === 'nmComunidade';
    });

    if (comunidadeChip !== undefined) {
      comunidadeChip = 'idComunidade:' + comunidadeChip.value.value + ';';
    } else {
      comunidadeChip = '';
    }

    let tecnicoChip = chips.find(item => {
      return item.filter.field === 'nmUsuario';
    });

    if (tecnicoChip !== undefined) {
      tecnicoChip = 'idUsuario:' + tecnicoChip.value.value + ';';
    } else {
      tecnicoChip = '';
    }

    let produtorChip = chips.find(item => {
      return item.filter.field === 'nmProdutor';
    });

    if (produtorChip !== undefined) {
      produtorChip = 'idProdutor:' + produtorChip.value.value + ';';
    } else {
      produtorChip = '';
    }

    let dtPeriodo = '';
    chips.forEach(item => {
      if (item.filter.field === 'dtPeriodo') {
        let itemOperacao = item.operation;
        if (itemOperacao === '==') {
          itemOperacao = ':';
        } else if (itemOperacao === '!=') {
          itemOperacao = '<>';
        }

        dtPeriodo = dtPeriodo + 'dtPeriodo' + itemOperacao + moment(item.value).format('YYYY-MM-DD') + ';';
      }
    });

    let filters =
      municipioChip + regiaoChip + estadoChip + comunidadeChip + tecnicoChip + produtorChip + dtPeriodo;

    Relatorios.gerarRelatorioVisitas(filters)
      .then(doc => {
        setCloseCard(false);
        setFileXLS(doc.data.dsLinkXls);
        setFilePDF(doc.data.dsLinkPdf);
        setFileCSV(doc.data.dsLinkCsv);
      })
      .catch(err => {
        modalErrorRelatorio();
      });
  }

  function modalErrorRelatorio() {
    setErrorCard(true);
    setCloseCard(false);
    setLoading(false);
    setFileXLS(null);
    setFilePDF(null);
    setFileCSV(null);
    setErrorCard(false);

    swal(formatMessage('visitas.falhaAoGerarRelatorio'), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  return (
    <div className={classes.card}>
      {(loading || loadingDados) && <Loading />}
      <CardFilters
        errorCard={errorCard}
        closeCard={closeCard}
        usePaddings={false}
        relatorio
        viewKey={'relatorio-visitas'}
        autoComplete={{
          listTecnicos: {
            list: tecnicoList,
            campoOp: 'nmUsuario',
            campoChave: 'idUsuario'
          },
          listProdutor: {
            list: produtorList,
            campoOp: 'nmProdutor',
            campoChave: 'idProdutor'
          },
          regiaoList: {
            list: regiaoList,
            campoOp: 'nmRegiao',
            campoChave: 'idRegiao'
          },
          municipioList: {
            list: municipioList,
            campoOp: 'nmMunicipio',
            campoChave: 'idMunicipio'
          },
          estadoList: {
            list: estadoList,
            campoOp: 'nmUf',
            campoChave: 'idUf'
          },
          comunidadeList: {
            list: comunidadeList,
            campoOp: 'nmComunidade',
            campoChave: 'idComunidade'
          }
        }}
        onClickButton={clickGerarRelatorio}
        filtros={operationTypeString}
        onFilter={() => {}}
        onFilterChanged={() => {}}
      />

      {loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: 20,
            justifyContent: 'center'
          }}
        >
          {formatMessage('visitas.gerandoRelatorio')}
        </div>
      )}

      <PDFViewer
        withoutPosition={true}
        loadSucess={() => {
          setLoading(false);
        }}
        errorCard={errorCard}
        fileXLS={fileXLS}
        filePDF={filePDF}
        additionalActions={[
          {
            icon: (
              <FontAwesomeIcon
                style={{ color: 'rgb(255, 255, 255)', width: 18, height: 18 }}
                icon={faFileCsv}
              />
            ),
            name: formatMessage('visitas.baixarCSV'),
            file: fileCSV,
            color: '#42ADE8'
          }
        ]}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'relatorio-visitas';
  })
});

let enhaced = withStyles(styles)(RelatorioVisitas);
export default withRouter(connect(mapStateToProps)(enhaced));
