import formatMessage from '../i18n/formatMessage';

const AlertaData = [
  {
    headerName: formatMessage('acompanhamentoProdutor.tipo'),
    field: 'tipoSeveridade.name',
    col: 4
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.agenteCausal'),
    field: 'severidade.nmSeveridade',
    col: 8
  }
];

const ProdutoData = [
  {
    headerName: formatMessage('acompanhamentoProdutor.ordem'),
    field: 'nrOrdem',
    col: 1,
    rowDrag: true
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.tipo'),
    field: 'tpPesticida.name',
    col: 2
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.marca'),
    field: 'marca.nmMarca',
    col: 3
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.produto'),
    field: 'insumo.nmInsumo',
    col: 3
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.dose'),
    field: 'nmVlDose',
    col: 2
  }
];

const FertilizanteData = [
  {
    headerName: formatMessage('acompanhamentoProdutor.ordem'),
    field: 'nrOrdem',
    col: 1,
    rowDrag: true
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.tipo'),
    field: 'tpPesticida.name',
    col: 2
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.marca'),
    field: 'marca.nmMarca',
    col: 3
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.produto'),
    field: 'insumo.nmInsumo',
    col: 3
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.nrQuantidade'),
    field: 'vlDoseFertilizante',
    col: 2
  }
];

const TalhaoData = [
  {
    headerName: '',
    width: 40,
    headerCheckboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.descricao'),
    field: 'dsTalhao',
    col: 3
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.areaTotal'),
    field: 'nrAreaTalhao',
    col: 3
  }
];

const MonitoramentoData = [
  {
    headerName: formatMessage('acompanhamentoProdutor.numero'),
    field: 'number',
    col: 3
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.talhao'),
    field: 'talhao.dsTalhao',
    col: 5
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.especiesIdentificadas'),
    field: 'qtValoresAmostra',
    col: 4
  }
];

const PragasData = [
  {
    headerName: formatMessage('acompanhamentoProdutor.tipo'),
    field: 'nmTipo',
    col: 3
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.fitossanidade'),
    field: 'severidade.nmSeveridade',
    col: 5
  },
  {
    headerName: formatMessage('acompanhamentoProdutor.nrQuantidade'),
    field: 'nrQuantidade',
    col: 4
  }
]

export { AlertaData, ProdutoData, FertilizanteData, TalhaoData, MonitoramentoData, PragasData };
