/*
    Autor: Kátia miglioli
    */
import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import _ from 'lodash';
import Loading from '@components/Loading/Loading';
import Localizacao from '@resources/Localizacao';
import Comunidade from '@resources/Comunidade';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});

const style = () => ({
  bottomDiv: {
    flex: 2
  },
  topDiv: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 90
  },
  limite_Div: {
    flex: 2,
    display: 'flex',
    marginTop: '20px'
  },
  limit_Usuario_Div: {
    flex: 1
  },
  limit_Produtores_Div: {
    flex: 1
  },
  font: {
    fontSize: 14,
    width: '100%',
    height: 15,
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  }
});

class EditarComunidade extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const comunidadeCadastradaComSucesso = messagesLinguage['comunidade.comunidadeCadastradaComSucesso'];
    const comunidadeEditadaComSucesso = messagesLinguage['comunidade.comunidadeEditadaComSucesso'];
    const falhaAoEditarComunidade = messagesLinguage['comunidade.falhaAoEditarComunidade'];
    const falhaAoCadastrarComunidade = messagesLinguage['comunidade.falhaAoCadastrarComunidade'];
    const carregando = messagesLinguage['comunidade.carregando'];
    const falhaAoCarregarComunidades = messagesLinguage['comunidade.falhaAoCarregarComunidades'];
    const salvar = messagesLinguage['comunidade.salvar'];
    const cancelar = messagesLinguage['comunidade.cancelar'];
    const salvando = messagesLinguage['comunidade.salvando'];
    const atualizando = messagesLinguage['comunidade.atualizando'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          comunidadeCadastradaComSucesso,
          comunidadeEditadaComSucesso,
          falhaAoCadastrarComunidade,
          falhaAoEditarComunidade,
          carregando,
          falhaAoCarregarComunidades,
          salvar,
          cancelar,
          salvando,
          atualizando
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      estados: [],
      keyUf: '',
      municipios: [],
      descricao: '',
      key: '',
      comunidadeEditar: '',
      validarInputs: false,
      loading: this.props.match.params.id !== undefined
    };

    this.valorAutoComplete = '';
    this.valorAutoCompleteUf = '';
    this.descricao = '';

    const title = 'comunidade.cadastroDeComunidades';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.iniciarAtributosEdicao = this.iniciarAtributosEdicao.bind(this);
    this.clickCadastroEdicao = this.clickCadastroEdicao.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.validarInicializacaoAutoComplete = _.debounce(
      this.validarInicializacaoAutoComplete.bind(this),
      200
    );
    this.validarInicializacaoAutoCompleteUf = _.debounce(
      this.validarInicializacaoAutoCompleteUf.bind(this),
      200
    );
    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.getMunicipios = this.getMunicipios.bind(this);
    this.getUf = this.getUf.bind(this);
    this.tratarValorSelectInitialUf = this.tratarValorSelectInitialUf.bind(this);
    this.tratarValorInicialUf = this.tratarValorInicialUf.bind(this);
    this.tratarValorInicialMunicipio = this.tratarValorInicialMunicipio.bind(this);
  }

  componentDidMount() {
    this.iniciarAtributosEdicao();
  }

  getUf() {
    Localizacao.findAllEstados()
      .then(querySnapshot => {
        this.setState({
          estados: querySnapshot.data
        });
      })
      .catch(err => {
        this.setState({
          estados: []
        });
      });
  }

  getMunicipios() {
    if (this.valorAutoCompleteUf) {
      Localizacao.findMunicipiosByUf(this.valorAutoCompleteUf.idUf)
        .then(querySnapshot => {
          this.setState({
            municipios: querySnapshot.data
          });
        })
        .catch(error => {
          this.setState({
            municipios: []
          });
        });
    } else {
      this.setState({
        municipios: []
      });
    }
  }

  validarInicializacaoAutoComplete() {
    this.getMunicipios();
  }

  validarInicializacaoAutoCompleteUf() {
    this.getUf();
  }

  iniciarAtributosEdicao() {
    this.validarInicializacaoAutoCompleteUf();
    if (this.props.match.params.id !== undefined) {
      Comunidade.findComunidadeById(this.props.match.params.id)
        .then(query => {
          this.valorAutoCompleteUf = query.data.municipio.uf;
          this.valorAutoComplete = query.data.municipio;

          this.setState({
            descricao: query.data.nmComunidade,
            key: Math.random(),
            keyUf: Math.random(),
            comunidadeEditar: query.data,
            loading: false
          });
        })
        .catch(err => {
          this.setState({
            loading: false
          });
          swal(provider.intl.formatMessage({ id: 'falhaAoCarregarComunidades' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    }
  }

  clickCadastroEdicao(values) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: true,
        title: provider.intl.formatMessage({
          id: this.props.match.params.id === undefined ? 'salvando' : 'atualizando'
        })
      }
    );

    const data = {
      municipio: this.valorAutoComplete,
      nmComunidade: values.descricao,
      uf: this.valorAutoCompleteUf
    };

    if (this.props.match.params.id === undefined) {
      Comunidade.addComunidade(data)
        .then(query => {
          swal(
            provider.intl.formatMessage({
              id: 'comunidadeCadastradaComSucesso'
            }),
            {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }
          ).then(() => {
            this.clickCancelar();
          });
        })
        .catch(err => {
          swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarComunidade' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    } else {
      data.idComunidade = this.props.match.params.id;
      Comunidade.editComunidade(data)
        .then(query => {
          swal(
            provider.intl.formatMessage({
              id: 'comunidadeEditadaComSucesso'
            }),
            {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }
          ).then(() => {
            this.clickCancelar();
          });
        })
        .catch(err => {
          swal(provider.intl.formatMessage({ id: 'falhaAoEditarComunidade' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    }
  }

  clickCancelar() {
    this.props.history.push('/app/comunidades');
  }

  tratarValorSelectInitial(data) {
    if (!data || (data.nmMunicipio === undefined && data.uf === undefined)) {
      return '';
    }

    return data;
  }

  tratarValorSelectInitialUf(data) {
    if (!data || data.nmUf === undefined) {
      return '';
    }
    return data;
  }

  tratarValorInicialUf(data) {
    if (!data || data.dsUf === undefined) {
      return '';
    }

    return data;
  }

  tratarValorInicialMunicipio(data) {
    if (!data || (data.dsMunicipio === undefined && data.dsEstado === undefined)) {
      return '';
    }

    return `${data.dsMunicipio} - ${data.dsEstado}`;
  }
  render() {
    const { classes } = this.props;
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Formik
          initialValues={
            this.state.comunidadeEditar
              ? {
                  descricao: this.state.comunidadeEditar.nmComunidade,
                  estado: this.valorAutoCompleteUf ? this.valorAutoCompleteUf.nmUf : '',
                  municipio: this.valorAutoComplete ? this.valorAutoComplete.nmMunicipio : ''
                }
              : {
                  descricao: '',
                  estado: '',
                  municipio: ''
                }
          }
          enableReinitialize={true}
          validateOnChange={false}
          onSubmit={this.clickCadastroEdicao}
          validateOnBlur
          validationSchema={Yup.object().shape({
            descricao: Yup.string().required(<FormattedMessage id='comunidade.campoObrigatorio' />),
            municipio: Yup.string().required(<FormattedMessage id='comunidade.campoObrigatorio' />),
            estado: Yup.string().required(<FormattedMessage id='comunidade.campoObrigatorio' />)
          })}
          render={({ values, handleSubmit, errors, touched, handleBlur, setFieldValue }) => (
            <MuiThemeProvider theme={theme}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                {this.state.loading && <Loading />}
                <CardIcon titulo={<FormattedMessage id='comunidade.identificacaoDaComunidade' />}>
                  <form autoComplete='off'>
                    <Grid container spacing={16} style={{ marginTop: '15px', height: 80 }}>
                      <Grid item xs={4} style={{ minHeight: '75px' }}>
                        <TextField
                          label={<FormattedMessage id='comunidade.descricao' />}
                          name='descricao'
                          value={values.descricao}
                          onBlur={handleBlur}
                          onChange={event => {
                            this.descricao = event.target.value;

                            setFieldValue('descricao', event.target.value);
                          }}
                          error={errors.descricao && touched.descricao}
                          helperText={
                            errors.descricao && touched.descricao ? (
                              <FormattedMessage id='comunidade.campoObrigatorio' />
                            ) : null
                          }
                          className={classes.textField}
                          style={{ width: '100%' }}
                          InputLabelProps={{ className: classes.font }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          itens={this.state.estados}
                          campoOp={'nmUf'}
                          campoChave={'idUf'}
                          label={<FormattedMessage id='comunidade.uf' />}
                          name='estados'
                          error={
                            errors.estado &&
                            touched.estado &&
                            (this.valorAutoCompleteUf === '' || this.valorAutoCompleteUf === null)
                          }
                          helperText={
                            errors.estado &&
                            touched.estado &&
                            (this.valorAutoCompleteUf === '' || this.valorAutoCompleteUf === null) ? (
                              <FormattedMessage id='comunidade.campoObrigatorio' />
                            ) : null
                          }
                          onChangeOwn={value => {
                            this.valorAutoCompleteUf = value;
                            this.valorAutoComplete = '';
                            setFieldValue('municipio', '');

                            if (value) {
                              setFieldValue('estado', value.nmUf);
                            } else {
                              setFieldValue('estado', null);
                            }
                            this.getMunicipios();
                            this.setState({
                              key: Math.random()
                            });
                          }}
                          onChangeAutoComplete={this.validarInicializacaoAutoCompleteUf}
                          value={this.tratarValorInicialUf(this.valorAutoCompleteUf)}
                          key={this.state.keyUf}
                          valueAutoComplete={this.tratarValorSelectInitialUf(this.valorAutoCompleteUf)}
                          valueSelect={this.tratarValorSelectInitialUf(this.valorAutoCompleteUf).nmUf}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Autocomplete
                          disabled={this.valorAutoCompleteUf === '' || this.valorAutoCompleteUf === null}
                          itens={this.state.municipios}
                          campoOp={'nmMunicipio'}
                          campoChave={'idMunicipio'}
                          label={<FormattedMessage id='comunidade.municipio' />}
                          name='municipio'
                          error={errors.municipio && touched.municipio && this.valorAutoComplete === ''}
                          helperText={
                            errors.municipio && touched.municipio && this.valorAutoComplete === '' ? (
                              <FormattedMessage id='comunidade.campoObrigatorio' />
                            ) : null
                          }
                          onChangeOwn={value => {
                            this.valorAutoComplete = value;

                            if (value) {
                              setFieldValue('municipio', value.nmMunicipio);
                            } else {
                              setFieldValue('municipio', '');
                            }
                          }}
                          onChangeAutoComplete={this.validarInicializacaoAutoComplete}
                          value={this.tratarValorInicialMunicipio(this.valorAutoComplete)}
                          key={this.state.key}
                          valueAutoComplete={this.tratarValorSelectInitial(this.valorAutoComplete)}
                          valueSelect={this.tratarValorSelectInitial(this.valorAutoComplete).nmMunicipio}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </CardIcon>
              </div>

              <div className={classes.fab}>
                <ButtonFABMenu
                  icon={iconMenuPontos}
                  actions={[
                    {
                      icon: <Check style={{ color: 'white' }} />,
                      name: provider.intl.formatMessage({ id: 'salvar' }),
                      onClickIcon: handleSubmit,
                      color: '#42ADE8'
                    },
                    {
                      icon: <Close style={{ color: 'white' }} />,
                      name: provider.intl.formatMessage({ id: 'cancelar' }),
                      onClickIcon: this.clickCancelar,
                      color: '#F33A30'
                    }
                  ]}
                  onClickButton={this.clickAdicionar}
                />
              </div>
            </MuiThemeProvider>
          )}
        />
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarComunidade);
export default withRouter(connect(mapStateToProps)(enhaced));
