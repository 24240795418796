/* Autor: Gabriela Farias */
import React, { Component, createRef } from 'react';
import { getUserLang } from '@utils/localeUtils';

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clickMarker: [],
      itensUniMedida: ['acre', 'alq.', 'ha', 'm2']
    };
  }

  componentDidMount() {
    var center = {
      lat: -25.744472026704535,
      lng: -53.046541213989265
    };
    var zoom = 3;

    var map = new window.google.maps.Map(this.googleMapRef.current, {
      zoom: zoom,
      streetViewControl: false,
      center: center
    });

    var drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: false,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER,
        drawingModes: ['polygon']
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: 'rgb(105, 204, 174, 1)',
        strokeColor: '#000000',
        fillOpacity: 0.6
      }
    });

    drawingManager.setMap(map);
    map.setMapTypeId('hybrid');

    var clickMarker = [];
    var bounds = new window.google.maps.LatLngBounds();

    this.props.listItensMapa.forEach(doc => {
      doc.talhaoGpsList.forEach(talhao => {
        var item = {
          lat: parseFloat(talhao.nrLatitude),
          lng: parseFloat(talhao.nrLongitude)
        };
        bounds.extend(item);
      });

      var valuesLatLng = new window.google.maps.Polygon({
        paths: doc.talhaoGpsList.map(doc => {
          return {
            lat: doc.nrLatitude,
            lng: doc.nrLongitude
          };
        }),
        draggable: false,
        editable: false,
        fillColor: 'rgb(105, 204, 174, 1)',
        strokeColor: '#000000',
        fillOpacity: 0.6
      });

      valuesLatLng.setMap(map);

      var valores = {
        lat: 0,
        lng: 0
      };

      var cont = 0;

      let media = 0;
      if (doc.talhaoGpsList.length > 0) {
        doc.talhaoGpsList.forEach(marker => {
          valores = {
            lat: valores.lat + marker.nrLatitude,
            lng: valores.lng + marker.nrLongitude
          };
          cont = cont + 1;
        });

        media = {
          lat: valores.lat / cont,
          lng: valores.lng / cont
        };
      } else {
        media = {
          lat: doc.nrLatitude,
          lng: doc.nrLongitude
        };
      }

      var marker = new window.google.maps.Marker({
        position: media
      });

      var stringText =
        '<p><b>' +
        doc.produtor +
        '<p><b>' +
        doc.areaCultivo +
        '<p><b>' +
        doc.talhao +
        '</b></p> ' +
        '<p> ' +
        this.props.label +
        ': ' +
        Intl.NumberFormat(getUserLang()).format(doc.areaTalhao) +
        ' Ha </p>';

      var infowindow = new window.google.maps.InfoWindow({
        content: stringText
      });

      var itemClickMarker = null;
      itemClickMarker = marker.addListener('click', () => {
        infowindow.open(map, marker);
      });

      clickMarker.push(itemClickMarker);

      marker.setMap(map);
    });

    if (this.props.listItensMapa.length > 0) {
      map.fitBounds(bounds);
    }

    this.setState({
      clickMarker: clickMarker
    });
  }

  componentWillUnmount() {
    var clickMarker = this.state.clickMarker;

    clickMarker.forEach(doc => {
      if (doc !== null) {
        doc.remove();
      }
    });
  }

  get googleMapDiv() {
    return document.getElementById('google-map');
  }

  googleMapRef = createRef();

  render() {
    return (
      <div
        style={{
          marginTop: !this.props.showCard ? 0 : 15,
          marginBottom: 15,
          height: '100%',
          width: '100%'
        }}
      >
        <div
          id='google-map'
          ref={this.googleMapRef}
          style={{
            width: 'calc(100% - 50px)',
            height: !this.props.showCard ? 'calc(100% - 95px)' : 'calc(100% - 310px)',
            position: 'absolute'
          }}
        />
      </div>
    );
  }
}
export default Map;
