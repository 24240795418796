export default {
  relatorio: {
    relatorio: 'Report',
    sintetico: 'Synthetic',
    regiao: 'Region',
    municipio: 'City',
    sim: 'Yes',
    nao: 'Not',
    carregando: 'Loading...',
    gerandoRelatorio: 'Generating report ...',
    falhaAoGerarRelatorio: 'Failed to generate report',
    dataLancamento: 'Release date of',
    tecnicoResponsavel: 'Responsible technician',
    produtor: 'Producer',
    uf: 'UF',
    decrescente: 'Decreasing',
    crescente: 'Crescent',
    media: 'Average'
  }
};
