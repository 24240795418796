import * as moment from 'moment';

function sortPlantios(plantioAutoComplete) {
  return plantioAutoComplete.sort(function(a, b) {
    if (!a.dtColheita && !b.dtColheita) {
      return moment(a.dtPlantio).isAfter(moment(b.dtPlantio)) ? -1 : 1;
    }

    if (!a.dtColheita) {
      return -1;
    }

    if (b.dtColheita) {
      return 1;
    }

    return moment(a.dtPlantio).isAfter(moment(b.dtPlantio)) ? -1 : 1;
  });
}

export default sortPlantios;