export default {
  marcas: {
    marcas: 'Marcas',
    descricao: 'Descripción',
    desejaExcluir: '¿Desea eliminar el marca seleccionado?',
    nao: 'No',
    excluir: 'Borrar',
    excluindo: 'Excluyendo',
    marcaRemovidaComSucesso: 'Marca excluido con éxito',
    falhaAoExcluirMarca: 'No se puede eliminar el marca',
    marcaEditadoComSucesso: 'Marca editado con éxito',
    falhaAoEditarMarca: 'Falha ao editar marca',
    falhaAoCadastrarMarca: 'Error al editar marca',
    falhaAoCarregarMarca: 'Error al cargar marca',
    cadastroDeMarca: 'Registro de marcas',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Guardalización',
    atualizando: 'Actualización',
    identificacaoDaMarca: 'Identificación',
    campoObrigatorio: 'Campo obligatorio',
    marcaCadastradaComSucesso: 'Marca registrada con éxito',
    situacao: 'Situación',
    ativo: 'Activo',
    inativo: 'Inactivo'
  }
};
