export default {
  sancaoCategoria: {
    descricao: 'Description',
    dataCadastro: 'Registration date',
    erroAoBuscarDados: 'Error to fetch data',
    sancaoCategoria: 'Samson',
    desejaExcluirSancaoCategoria: 'Do you want to delete this samson',
    nao: 'No',
    excluir: 'Delete',
    sancaoCategoriaExcluida: 'Samson successfully deleted',
    erroAoExcluir: 'Error to delete samson',
    cadastroDeSancaoCategoria: 'Organic samson registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    salvar: 'Salve',
    cancelar: 'Cancel',
    sancaoCategoriaSalvoComSucesso: 'Samson successfully saved',
    erroAoSalvar: 'Error to save samson',
    salvando: 'Saving',
    atualizando: 'Updating',
    produtorRelacionado:
      'It was not possible to exclude this samson because there is a producer using it',
    status: 'Status',
    ativo: 'Active',
    inativo: 'Inative',
    nome: 'Name',
    diasResolucao: 'Days for resolution',
    sigla: 'Initials',
    tipoPenalidade: 'Penalty type',
    nenhum: 'None',
    desclassificacaoSafra: 'Crop disqualification',
    desclassificacaoTalhao: 'Field declassification'
  }
};
