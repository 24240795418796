import formatMessage from './i18n/formatMessage';

const headDatatable = [
  {
    field: 'nmSancaoCategoria',
    headerName: formatMessage('sancaoCategoria.nome'),
    col: 3
  },
  {
    field: 'sgSancaoCategoria',
    headerName: formatMessage('sancaoCategoria.sigla'),
    col: 3
  },
  {
    field: 'nrDiasMedidaCorretiva',
    headerName: formatMessage('sancaoCategoria.diasResolucao'),
    col: 3
  },
  {
    valueGetter: args => {
      switch (args.node.data.tpPenalidade) {
        case 0:
          return formatMessage('sancaoCategoria.nenhum');
        case 1:
          return formatMessage('sancaoCategoria.desclassificacaoSafra');
        case 2:
          return formatMessage('sancaoCategoria.desclassificacaoTalhao');
        default:
          return '';
      }
    },
    headerName: formatMessage('sancaoCategoria.tipoPenalidade'),
    col: 3
  }
];

const filtersConfig = [
  {
    label: formatMessage('sancaoCategoria.descricao'),
    field: 'nmSancaoCategoria',
    type: 'string'
  },
  {
    label: formatMessage('sancaoCategoria.sigla'),
    field: 'sgSancaoCategoria',
    type: 'string'
  },
  {
    label: formatMessage('sancaoCategoria.diasResolucao'),
    field: 'nrDiasMedidaCorretiva',
    type: 'number'
  },
  {
    label: formatMessage('sancaoCategoria.tipoPenalidade'),
    field: 'tpPenalidade',
    type: 'enum',
    options: [
      {
        label: formatMessage('sancaoCategoria.nenhum'),
        field: 'nenhum',
        value: '0'
      },
      {
        label: formatMessage('sancaoCategoria.desclassificacaoSafra'),
        field: 'desclassificacaoSafra',
        value: '1'
      },
      {
        label: formatMessage('sancaoCategoria.desclassificacaoTalhao'),
        field: 'desclassificacaoTalhao',
        value: '2'
      }
    ]
  }
];

export { headDatatable, filtersConfig };
