import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Localizacao from '@resources/Localizacao';
import formatMessage from './i18n/formatMessage';
import { style, theme } from './Municipios.styles';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import { Close, Check } from '@material-ui/icons/';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Editar tela de municipio
 */
function EditarMunicipios(props) {
  const { classes, itensState, dispatch } = props;
  const [nomeMunicipio, setNomeMunicipio] = useState('');
  const [estadoMunicipio, setEstadoMunicipio] = useState('');
  const [loading, setLoading] = useState(true);
  const [nrModuloFiscal, setNrModuloFiscal] = useState('');
  const idMunicipio = props.match.params.id;

  useEffect(() => {
    const title = 'municipio.municipio';
    if (itensState.name !== title) {
      dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    findMunicipio();
  }, []);

  function findMunicipio() {
    Localizacao.findMunicipioById(idMunicipio)
      .then(querySnapshot => {
        setNomeMunicipio(querySnapshot.data.nmMunicipio);
        setEstadoMunicipio(querySnapshot.data.uf.nmUf);
        setNrModuloFiscal(querySnapshot.data.nrModuloFiscal);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        swal(formatMessage('municipio.falha'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  return (
    <div className={classes.topDiv}>
      {loading && <Loading />}

      <Formik
        initialValues={{
          idMunicipio: idMunicipio,
          nmMunicipio: nomeMunicipio,
          nmUf: estadoMunicipio,
          nrModuloFiscal: nrModuloFiscal
        }}
        onSubmit={clickCadastrarEditar}
        validateOnBlur
        enableReinitialize={true}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          nmMunicipio: Yup.string().required(formatMessage('municipio.campoObrigatorio')),
          nmUf: Yup.string().required(formatMessage('municipio.campoObrigatorio')),
          nrModuloFiscal: Yup.string().required(formatMessage('municipio.campoObrigatorio'))
        })}
        render={({ values, handleSubmit, errors, touched, setFieldValue, handleBlur }) => (
          <>
            <div className={classes.identif_Div}>
              <CardIcon titulo={formatMessage('municipio.identificacaoDoMunicipio')}>
                <form autoComplete='off'>
                  <Grid container spacing={16} className={classes.cardCadastro}>
                    <Grid item xs={4}>
                      <TextField
                        label={formatMessage('municipio.nome')}
                        value={values.nmMunicipio}
                        disabled
                        className={classes.textField}
                        inputProps={{ className: classes.font }}
                        InputLabelProps={{ className: classes.font }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label={formatMessage('municipio.uf')}
                        value={values.nmUf}
                        disabled
                        className={classes.textField}
                        inputProps={{ className: classes.fontMensalidade }}
                        InputLabelProps={{ className: classes.fontMensalidade }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextFieldWithMask
                        disabled={props.infoUsuario.tpPerfil !== 0}
                        tipoMascara={3}
                        separator={false}
                        numberDecimalScale={3}
                        showThousandSeparator={true}
                        name='nrModuloFiscal'
                        id='nrModuloFiscal'
                        onChange={e => setFieldValue('nrModuloFiscal', e.target.value)}
                        onBlur={handleBlur}
                        value={values.nrModuloFiscal}
                        label={formatMessage('municipio.moduloFiscal')}
                        className={classes.textField}
                        InputLabelProps={{
                          className: classes.fontMensalidade
                        }}
                        error={errors.nrModuloFiscal && touched.nrModuloFiscal}
                        helperText={touched.nrModuloFiscal ? errors.nrModuloFiscal : ''}
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardIcon>
            </div>
            <div className={classes.fab}>
              <ButtonFABMenu
                icon={iconMenuPontos}
                actions={[
                  {
                    icon: <Check style={{ color: 'white' }} />,
                    name: formatMessage('municipio.salvar'),
                    onClickIcon: handleSubmit,
                    color: '#42ADE8',
                    disabled: props.infoUsuario.tpPerfil !== 0
                  },
                  {
                    icon: <Close style={{ color: 'white' }} />,
                    name: formatMessage('municipio.cancelar'),
                    onClickIcon: () => props.history.push('/app/municipios'),
                    color: '#F33A30'
                  }
                ]}
              />
            </div>
          </>
        )}
      />
    </div>
  );

  function clickCadastrarEditar(values) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage('municipio.salvando')
      }
    );

    Localizacao.updateMunicipio(values)
      .then(() => {
        swalMessagem('municipio.editadoComSucesso', false).then(() => {
          props.history.push('/app/municipios');
        });
      })
      .catch(() => {
        swalMessagem('municipio.naoFoiPossivelEditar');
      });
  }

  function swalMessagem(title, typeError = true) {
    return swal(formatMessage(title), {
      icon: typeError ? 'error' : 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    });
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarMunicipios);
export default withRouter(connect(mapStateToProps)(enhaced));
