export default {
  cultura: {
    culturas: 'Culturas',
    descricao: 'Descripción',
    numeroDeAplicacoes: 'Número de aplicacionesNúmero de aplicações',
    nao: 'No',
    sim: 'Sí',
    culturaRemovidaComSucesso: 'Cultura eliminada con éxito',
    falhaAoExcluirCultura: 'Fallo al excluir el cultivo',
    desejaExcluir: '¿Desea eliminar la cultura seleccionada?',
    excluir: 'Borrar',
    excluindo: 'Borrado',
    cultura: 'Cultura',
    identificacaoDaCultura: 'identificación',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'actualización',
    culturaCadastradaComSucesso: 'Cultura registrada con éxito',
    culturaEditadaComSucesso: 'Cultura editada con éxito',
    falhaAoCadastrarCultura: 'Falla al registrar cultura',
    falhaAoEditarCultura: 'Error al editar la cultura',
    falhaAoCarregarCultura: 'Falla al cargar la cultura',
    campoObrigatorio: 'Campo obligatorio',
    cadastroDeCultura: 'Registro de cultivos',
    safraRelacionada: 'Hay un cultivo relacionado con la cultura.'
  }
};
