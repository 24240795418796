import React from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import TextFieldAcompanhamento from './TextFieldAcompanhamento';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import formatMessage from './i18n/formatMessage';
import moment from 'moment';
import { getUserLang } from '@utils/localeUtils';

const styles = {
  cardLine: {
    height: 13,
    width: '100%',
    display: 'flex',
    color: 'rgb(0, 0, 0, 0.54)',
    marginTop: 15,
    marginBottom: 20,
    alignItems: 'center'
  },
  label: {
    margin: 0,
    marginBottom: 3,
    whiteSpace: 'nowrap',
    fontWeight: '400 !important',
    color: 'rgb(0, 0, 0, 1)'
  },
  lineRight: {
    borderBottom: '1px solid rgb(0, 0, 0, 1)',
    marginRight: 10,
    width: 20
  },
  lineLeft: {
    borderBottom: '1px solid rgb(0, 0, 0, 1)',
    width: '100%',
    marginLeft: 10
  },
  naoConformidadeCard: {
    paddingTop: 5,
    paddingBottom: '5px',
    borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
    width: '100%'
  },
  labelNaoConformidade: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  cardLabelAdicional: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 40,
    justifyContent: 'space-around',
    marginTop: 10
  },
  chip: {
    backgroundColor: '#E1E1E1',
    border: 'none',
    borderRadius: 10,
    fontSize: 12,
    color: '#676767',
    marginRight: 5,
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 0
  }
};

/**
 * Componente para lateral de cronograma no acompanhamento produtor em casos de auditoria
 */
function ListDetalhesAuditoria(props) {
  const { classes } = props;
  const auditoria = props.itemTimeline.info;

  function chipsTalhoes(listaChip) {
    var chips = props.limpar ? [] : listaChip;

    return (
      <div>
        {chips.map((tal, i) => (
          <Chip
            label={tal.plantioTalhao.talhao.dsTalhao}
            variant='outlined'
            key={i}
            className={classes.chip}
          />
        ))}
      </div>
    );
  }

  function optionTpAuditoria(tpAuditoria) {
    if (tpAuditoria === 1) {
      return formatMessage('listDetalhes.externa');
    } else {
      return formatMessage('listDetalhes.interna');
    }
  }

  function optionStAuditoria(stAuditoria) {
    switch (stAuditoria) {
      case 0:
        return formatMessage('listDetalhes.emAndamento');
      case 1:
        return formatMessage('listDetalhes.finalizadaSemNaoConformidade');
      case 2:
        return formatMessage('listDetalhes.finalizadaComNaoConformidade');
      case 3:
        return formatMessage('listDetalhes.suspensa');
      default:
        return '';
    }
  }

  function formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YY';
      case 'es-ES':
        return 'DD-MM-YY';
      default:
        return 'MM/DD/YY';
    }
  }

  function findDtComprimento(naoConformidadeSancao) {
    const historico = naoConformidadeSancao.historicoAuditoriaList.find(
      historicoAux => historicoAux.stHistoricoAuditoria === 1
    );

    if (historico) {
      return moment(historico.dhHistoricoAuditoria).format(formatTextField());
    } else {
      return '--/--/--';
    }
  }

  function listaNaoConformidades(naoConformidade) {
    return (
      <div
        key={naoConformidade.idAuditoriaPlantioNaoConformidade}
        className={classes.naoConformidadeCard}
      >
        <div className={classes.labelNaoConformidade}>
          {naoConformidade.naoConformidadeSancao.naoConformidade.nmNaoConformidade}
        </div>
        <div className={classes.cardLabelAdicional}>
          <div style={{display: 'flex'}}>
            <Tooltip title={naoConformidade.naoConformidadeSancao.sancaoCategoria.dsSancaoCategoria}>
              <Typography style={{width: 124}}  className={classes.listaProdutos} noWrap={true}>
                {formatMessage('listDetalhes.sancao') +
                  ': ' +
                  naoConformidade.naoConformidadeSancao.sancaoCategoria.nmSancaoCategoria}
              </Typography>
            </Tooltip>
            <Typography style={{marginLeft: 20}} className={classes.listaProdutos} noWrap={true}>
              {findDtComprimento(naoConformidade)}
            </Typography>
          </div>
          <Typography className={classes.listaProdutos}>
            {formatMessage('listDetalhes.limiteComprimento') +
              ': ' +
              moment(naoConformidade.dhResolucaoMedidaCorretiva).format(formatTextField())}
          </Typography>
        </div>

        {chipsTalhoes(naoConformidade.talhaoNaoConformidadeList)}
      </div>
    );
  }

  return (
    <Grid container style={{ padding: 15 }} spancing={24}>
      <Grid item xs={12}>
        <TextFieldAcompanhamento
          limpar={props.limpar}
          value={optionTpAuditoria(auditoria.tpAuditoria)}
          label={formatMessage('listDetalhes.tipoAuditoria')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldAcompanhamento
          limpar={props.limpar}
          value={optionStAuditoria(auditoria.stAuditoriaPlantio)}
          label={formatMessage('listDetalhes.situacao')}
        />
      </Grid>
      <Grid item xs={6} style={{ paddingRight: 15 }}>
        <TextFieldAcompanhamento
          limpar={props.limpar}
          value={moment(auditoria.dtInicio).format(formatTextField())}
          label={formatMessage('listDetalhes.dataInicial')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldAcompanhamento
          limpar={props.limpar}
          value={moment(auditoria.dtTermino).format(formatTextField())}
          label={formatMessage('listDetalhes.dataFinal')}
        />
      </Grid>
      <div className={classes.cardLine}>
        <div className={classes.lineRight} />
        <p className={classes.label}>{formatMessage('listDetalhes.naoConformidades')}</p>
        <div className={classes.lineLeft} />
      </div>

      {auditoria.auditoriaPlantioNaoConformidadeList.map(naoConformidade =>
        listaNaoConformidades(naoConformidade)
      )}
    </Grid>
  );
}

export default withStyles(styles)(ListDetalhesAuditoria);
