import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  card: {
    display: 'flex',
    height: '100%'
  },
  cardSwipeable: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
    minWidth: 100
  },
  buttonsLaterias: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgb(139, 200, 166, 0.3)'
    },
    backgroundColor: 'transparent',
    width: 170,
    height: 36,
    display: 'flex',
    fontSize: 13,
    alignItems: 'center',
    paddingRight: 15,
    justifyContent: 'flex-end',
    color: 'rgb(0, 0, 0, 0.7)'
  },
  buttonDisabled: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  swipeable: {
    display: 'flex',
    width: '100%',
    height: '100%'
  }
};

const Navigation = props => {
  const { classes, handleChangeIndex, buttons, index, children, disabled } = props;

  return (
    <div className={classes.card}>
      <div className={classes.buttonsLaterias}>
        {buttons.map(doc => (
          <div
            onClick={() => !disabled && handleChangeIndex(doc.key)}
            className={`${classes.button} ${disabled ? classes.buttonDisabled : ''}`}
            key={doc.key}
            style={{
              backgroundColor: doc.select ? '#8bc8a6' : '',
              fontWeight: doc.select ? 400 : 300,
              borderRight: doc.select ? '5px solid #00622B' : '',
              cursor: disabled ? 'default' : 'pointer'
            }}
          >
            {doc.name}
          </div>
        ))}
      </div>

      <div className={classes.cardSwipeable}>
        {children.map((item, i) =>
          i === index ? (
            item
          ) : (
            <div key={i} style={{ display: 'none', height: 0, width: 0 }}>
              {item}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(Navigation);
