export default {
  fungicida: {
    fungicidaRemovidoComSucesso: 'Fungicide successfully removed',
    falhaAoExcluirFungicida: 'Failed to exclude fungicide',
    desejaExcluir: 'Do you want to delete the selected fungicide?',
    identificacaoDaFungicida: 'Identification',
    fungicidaCadastradoComSucesso: 'Fungicide successfully registered',
    fungicidaEditadoComSucesso: 'Fungicide edited successfully',
    falhaAoEditarFungicida: 'Failed to edit fungicide',
    falhaAoCadastrarFungicida: 'Failed to register fungicide',
    falhaAoCarregarFungicida: 'Failed to load fungicide',
    fungicida: 'Fungicide',
    cadastroDeFungicida: 'Fungicides register'
  }
};
