import React from 'react';
import { Typography } from '@material-ui/core';
import { style } from './../AcompanhamentoProdutor.styles';
import { withStyles } from '@material-ui/core/styles';
import formatMessage from './../i18n/formatMessage';
import SeveridadeEnum from './../enumerations/SeveridadeEnum';

/**
 * Lista os produtos na descrição de cada alerta fitossanitário
 */
function AlertaDescricao(props) {
  const { produtoList, classes } = props;
  return (
    <div>
      {produtoList.map((prod, key) => (
        <div key={key} className={classes.cardAlertas}>
          <div className={classes.fontLabelCardsCronograma}>{prod.severidade.nmSeveridade}</div>
          <div style={{ display: 'flex' }}>
            <Typography className={classes.listaAlertas} noWrap={true}>
              {`${formatMessage('acompanhamentoProdutor.tipo')}
                  : 
                 ${SeveridadeEnum[prod.severidade.tpSeveridade].name}`}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
}

export default withStyles(style)(AlertaDescricao);
