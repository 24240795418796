import React, { useState, useEffect } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconAdd from '@images/icone_add.png';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import '@styles/Alerts.css';
import FilterUtil from '../../views/filterUtil';
import Loading from '@components/Loading/Loading';
import Pesticida from '@resources/Pesticidas';
import { style, theme } from './InsumoRecord.styles';
import formatMessage from './i18n/formatMessage';
import { head, headOrganico, operationValues, operationValuesOrganic } from './head';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

/**
 * Componente para tela de listagem de insumos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function InsumoRecord(props) {
  const {
    classes,
    insumoEnum,
    insumoURL,
    viewKey,
    deleteMessageSucess,
    mensagemFalhaDeletar,
    infoUsuario,
    mensagemDelete,
    chipData
  } = props;
  const [dataInTable, setDataInTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataAppSync, setDataAppSync] = useState([]);
  const [itemSelect, setItemSelect] = useState(null);
  const [disabledEdit, setDisabledEdit] = useState(true);
  const [disableDelete, setDisableDelete] = useState(true);
  const [key, setKey] = useState(0);

  useEffect(() => {
    onFilter(chipData);
  }, []);

  function selectChange(select) {
    if (select) {
      setItemSelect(select);
      setDisabledEdit(false);
      setDisableDelete(select.idCliente === null);
    } else {
      setItemSelect(null);
      setDisabledEdit(true);
      setDisableDelete(true);
    }
  }

  function onFilterChanged(dataChips) {
    setDataInTable(FilterUtil.applyFilters(dataAppSync, dataChips));
  }

  function onFilter(dataChips) {
    dataChips = dataChips || [];

    Pesticida.findPesticidasByTpPesticida(insumoEnum)
      .then(query => {
        var data = query.data;
        data.forEach(doc => {
          doc.nmMarca = doc.marca.nmMarca;
          let nmOrganica = doc.insumoTipoCertificacaoList
            .filter(
              item =>
                item.stInsumoTipoCertificacao === 1 &&
                (item.dtTermino === null ||
                  moment(item.dtTermino).startOf('day') <= moment().startOf('day'))
            )
            .map(organica => organica.tipoCertificacao.nmTipoCertificacao);
          doc.nmOrganica = nmOrganica.length > 0 ? nmOrganica + ';' : '';
        });

        setDataAppSync(data);
        setDataInTable(FilterUtil.applyFilters(data, dataChips));
        setLoading(false);
      })
      .catch(() => {
        setDataAppSync([]);
        setLoading(false);
      });
  }

  function clickAdicionar() {
    props.history.push(`/app/${insumoURL}/novo`);
  }
  function clickEditar() {
    props.history.push(`/app/${insumoURL}/editar/${itemSelect.idInsumo}`);
  }

  function clickExcluir() {
    swal({
      title: mensagemDelete,
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('insumo.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('insumo.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            title: formatMessage('insumo.excluindo')
          }
        );
        Pesticida.deletePesticidaById(itemSelect.idInsumo)
          .then(data => {
            swal(deleteMessageSucess, {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });
            atualizarRegistros(itemSelect.idInsumo);
          })
          .catch(error => {
            swal(mensagemFalhaDeletar, {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    });
  }

  function atualizarRegistros(data) {
    var index = -1;
    for (var i = 0; i < dataAppSync.length; i++) {
      if (dataAppSync[i].idInsumo === data) {
        index = i;
        break;
      }
    }
    dataAppSync.splice(index, 1);

    setDataAppSync(dataAppSync);
    setDataInTable(dataInTable);
    setKey(Math.random());

    onFilterChanged(chipData);
  }

  const pagination = {
    length: dataInTable.length,
    page: 0
  };

  return (
    <div className={classes.grid}>
      {loading && <Loading />}
      <CardFilters
        filtros={infoUsuario.stOrganica === 1 ? operationValuesOrganic : operationValues}
        onFilter={onFilter}
        onFilterChanged={onFilterChanged}
        usePaddings={false}
        viewKey={viewKey}
      />
      <GroupButtonGrid
        showAdd={false}
        showEdit={true}
        showDelete={true}
        disableEdit={disabledEdit}
        disableDelete={disableDelete}
        onClickEdit={clickEditar}
        onClickDelete={clickExcluir}
      />

      <div className={classes.dataTable}>
        <DataTable
          usePaddings={false}
          data={dataInTable}
          columns={infoUsuario.stOrganica === 1 ? headOrganico : head}
          selectChange={selectChange}
          showPagination={true}
          pagination={pagination}
          key={key}
        />
      </div>

      <ButtonFAB icon={iconAdd} positionDefault={true} onClick={() => clickAdicionar()} />
    </div>
  );
}

export default withRouter(withStyles(style)(InsumoRecord));
