export default {
  herbicida: {
    herbicida: 'Herbicida',
    cadastroDeHerbicida: 'Registro de herbicida',
    herbicidaRemovidoComSucesso: 'Herbicida quitado con éxito',
    falhaAoExcluirHerbicida: 'Fallo la excluir herbicida',
    desejaExcluir: '¿Desea eliminar el herbicida seleccionada?',
    identificacaoDaHerbicida: 'Identificación',
    herbicidaCadastradoComSucesso: 'Herbicida registrada con éxito',
    herbicidaEditadoComSucesso: 'Herbicida editado con éxito',
    falhaAoEditarHerbicida: 'Falla al editar herbicida',
    falhaAoCadastrarHerbicida: 'Error al editar herbicida',
    falhaAoCarregarHerbicida: 'Error al cargar herbicida'
  }
};
