export default {
  acaricidaMicroBio: {
    acaricidaMicroBioRemovidoComSucesso: 'Acaricida microbiológico removido com sucesso',
    falhaAoExcluirAcaricidaMicroBio: 'Falha ao excluir acaricida microbiológico',
    desejaExcluir: 'Deseja excluir o acaricida microbiológico selecionado?',
    acaricidaMicroBio: 'Acaricida microbiológico',
    cadastroDeAcaricidaMicroBio: 'Cadastro de acaricida microbiológico',
    identificacaoDaAcaricidaMicroBio: 'Identificação',
    acaricidaMicroBioCadastradoComSucesso: 'Acaricida microbiológico cadastrado com sucesso',
    acaricidaMicroBioEditadoComSucesso: 'Acaricida microbiológico editado com sucesso',
    falhaAoEditarAcaricidaMicroBio: 'Falha ao editar acaricida microbiológico',
    falhaAoCadastrarAcaricidaMicroBio: 'Falha ao cadastrar acaricida microbiológico ',
    falhaAoCarregarAcaricidaMicroBio: 'Falha ao carregar acaricida microbiológico'
  }
};
