import formatMessage from './i18n/formatMessage';

const operationTypeString = [
  {
    label: formatMessage('analiseOGM.descricao'),
    field: 'dsTipoAnaliseOgm',
    type: 'string'
  },
  {
    label: formatMessage('analiseOGM.situacao'),
    field: 'stTipoAnaliseOgm',
    type: 'enum',
    options: [
      {
        label: formatMessage('analiseOGM.ativo'),
        field: 'Ativo',
        value: '1'
      },
      {
        label: formatMessage('analiseOGM.inativo'),
        field: 'Inativo',
        value: '0'
      }
    ]
  }
];

const head = [
  {
    field: 'dsTipoAnaliseOgm',
    headerName: formatMessage('analiseOGM.descricao'),
    col: 6
  },
  {
    valueGetter: args =>
      [formatMessage('analiseOGM.inativo'), formatMessage('analiseOGM.ativo')][
        args.node.data.stTipoAnaliseOgm
      ],
    headerName: formatMessage('analiseOGM.situacao'),
    col: 6
  }
];

export { head, operationTypeString };
