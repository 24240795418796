export default {
  perfil: {
    atualizando: "Atualizando",
    salvar: "Salvar",
    erroAoEditarPerfil: "Falha ao editar usuário",
    perfilEditadoComSucesso: "Perfil editado com sucesso",
    problemaInesperado: "Ocorreu um problema inesperado",
    identificacao: "Identificação",
    nome: "Nome",
    tipoDePerfil: "Tipo de perfil",
    email: "E-mail",
    situacaoDaConta: "Situação da conta",
    inativo: "Inativo",
    ativo: "Ativo",
    administrador: "Administrador",
    revenda: "Revenda",
    tecnico: "Técnico",
    produtor: "Produtor",
    campoObrigatorio: "Campo Obrigatório",
    cancelar: "Cancelar",
    perfilDoUsuario: "Perfil do usuário",
    trocarSenha: "Trocar senha",
    senhaAtual: "Senha atual",
    novaSenha: "Nova senha",
    confNovaSenha: "Confirmação da nova senha",
    senhaMinino: "A senha deve ter no mínimo 6 caracteres",
    senhaIncompativel: "Senhas incompatíveis",
    senhaIncorreta: "Senha incorreta",
    gerente: "Gerente",
    representante: "Representante"
  }
};
