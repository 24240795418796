export default {
  raca: {
    racas: 'Raças',
    descricao: 'Descrição',
    desejaExcluir: 'Deseja excluir raça?',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    falhaAoExcluirRaca: 'Falha ao excluir raça',
    racaRemovidoComSucesso: 'Raça removida com sucesso',
    racaCadastradaComSucesso: 'Raça cadastrada com sucesso',
    racaEditadaComSucesso: 'Raça editada com sucesso',
    falhaAoEditarRaca: 'Falha ao editar raça',
    falhaAoCadastrarRaca: 'Falha ao cadastrar raça',
    falhaAoCarregarRaca: 'Falha ao carregar raça',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    cadastroDeRaca: 'Cadastro de raça',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    especie: 'Espécie',
    ativo: 'Ativo',
    situacao: 'Situação',
    inativo: 'Inativo'
  }
};
