import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import formatMessage from './../i18n/formatMessage';
import { style, theme } from './../NaoConformidade.styles';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CardIcon from '@components/CardIcon/CardIcon';
import Button from '@material-ui/core/Button';
import MedidaCorretiva from './../MedidaCorretiva/MedidaCorretiva';
import EditarMedidaCorretiva from './../MedidaCorretiva/EditarMedidaCorretiva';
import swal from '@sweetalert/with-react';

/**
 * Screen de listagem de sanção
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarSancao(props) {
  const {
    classes,
    sancaoCategoriaList,
    tipoCertificacaoList,
    clickToCancel,
    clickSubmit,
    medidaCorretivaList,
    swalWarning
  } = props;
  const [sancaoCategoria, setSancaoCategoria] = useState(null);
  const [tipoCertificacao, setTipoCertificacao] = useState(null);
  const [keyAutoCompleteSancao, setKeyAutoCompleteSancao] = useState(0);
  const [keyAutoCompleteTipoCertificacao, setKeyAutoCompleteTipoCertificaca] = useState(0);
  const [keyMedidaCorretiva, setKeyMedidaCorretiva] = useState(0);
  const [initialValues, setInitialValues] = useState({
    sancaoCategoria: props.initialValues.sancaoCategoria,
    tipoCertificacao: props.initialValues.tipoCertificacao,
    sancaoMedidaCorretivaList: []
  });
  const [selectMedidaCorretiva, setSelectMedidaCorretiva] = useState({
    medidaCorretiva: null,
    dhInicio: null,
    dhTermino: null
  });

  useEffect(() => {
    setInitialValues(JSON.parse(JSON.stringify(props.initialValues)));
    setSancaoCategoria(props.initialValues.sancaoCategoria);
    setTipoCertificacao(props.initialValues.tipoCertificacao);
    setKeyAutoCompleteSancao(Math.random());
    setKeyAutoCompleteTipoCertificaca(Math.random());
    setKeyMedidaCorretiva(Math.random());
  }, []);

  function tratarValorSancaoCategoria() {
    if (!sancaoCategoria) {
      return '';
    }

    return sancaoCategoria;
  }

  function loadSancaoCategoria() {
    return sancaoCategoriaList;
  }

  function tratarValorTipoCertificacao() {
    if (!tipoCertificacao) {
      return '';
    }

    return tipoCertificacao;
  }

  function loadTipoCertificacao() {
    return tipoCertificacao;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        sancaoCategoria: Yup.object()
          .nullable()
          .required(formatMessage('naoConformidade.campoObrigatorio')),
        tipoCertificacao: Yup.object()
          .nullable()
          .required(formatMessage('naoConformidade.campoObrigatorio'))
      })}
      onSubmit={values =>
        clickSubmit({
          ...initialValues,
          ...values,
          sancaoMedidaCorretivaList: initialValues.sancaoMedidaCorretivaList
        })
      }
      render={({ handleSubmit, touched, setFieldValue }) => (
        <MuiThemeProvider theme={theme}>
          <div style={{ width: '100%', overflowY: 'scroll' }}>
            <CardIcon titulo={formatMessage('naoConformidade.sancao')}>
              <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                <Grid item xs={4} style={{ paddingRight: 15 }}>
                  <Autocomplete
                    itens={sancaoCategoriaList}
                    campoOp='nmSancaoCategoria'
                    campoChave='idSancaoCategoria'
                    label={formatMessage('naoConformidade.sancao')}
                    name='sancao'
                    error={!sancaoCategoria && touched.sancaoCategoria}
                    helperText={
                      touched.sancaoCategoria && !sancaoCategoria
                        ? formatMessage('naoConformidade.campoObrigatorio')
                        : null
                    }
                    onChangeOwn={value => {
                      setFieldValue('sancaoCategoria', value);
                      setSancaoCategoria(value);
                    }}
                    onChangeAutoComplete={loadSancaoCategoria}
                    value={tratarValorSancaoCategoria()}
                    key={keyAutoCompleteSancao}
                    valueAutoComplete={tratarValorSancaoCategoria()}
                    valueSelect={tratarValorSancaoCategoria().nmSancaoCategoria}
                    maxHeight='calc(100vh - 250px)'
                  />
                </Grid>

                <Grid item xs={4} style={{ paddingRight: 15 }}>
                  <Autocomplete
                    itens={tipoCertificacaoList}
                    campoOp='nmTipoCertificacao'
                    campoChave='idTipoCertificacao'
                    label={formatMessage('naoConformidade.certificacao')}
                    name='tipoCertificacao'
                    error={!tipoCertificacao && touched.tipoCertificacao}
                    helperText={
                      touched.tipoCertificacao && !tipoCertificacao
                        ? formatMessage('naoConformidade.campoObrigatorio')
                        : null
                    }
                    onChangeOwn={value => {
                      setFieldValue('tipoCertificacao', value);
                      setTipoCertificacao(value);
                    }}
                    onChangeAutoComplete={loadTipoCertificacao}
                    value={tratarValorTipoCertificacao()}
                    key={keyAutoCompleteTipoCertificacao}
                    valueAutoComplete={tratarValorTipoCertificacao()}
                    valueSelect={tratarValorTipoCertificacao().nmTipoCertificacao}
                    maxHeight='calc(100vh - 250px)'
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs className={classes.containerButtons}>
                  <div style={{ color: '#463766' }}>
                    <Button variant='contained' color='inherit' onClick={clickToCancel}>
                      {formatMessage('naoConformidade.cancelar')}
                    </Button>
                  </div>

                  <div>
                    <Button color='secondary' variant='contained' onClick={handleSubmit}>
                      {formatMessage('naoConformidade.salvar')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardIcon>

            <div style={{ marginTop: 30 }} />

            <EditarMedidaCorretiva
              clearSelect={clearSelect}
              classes={classes}
              medidaCorretivaList={medidaCorretivaList}
              valuesEditing={selectMedidaCorretiva}
              addEditMedidaCorretiva={addEditMedidaCorretiva}
            />

            <div style={{ marginTop: 30 }} />

            <div key={keyMedidaCorretiva}>
              <MedidaCorretiva
                data={initialValues.sancaoMedidaCorretivaList.filter(item => item.stRegistro !== 2)}
                classes={classes}
                onDelete={deleteMedidaCorretiva}
                keyMedidaCorretiva={keyMedidaCorretiva}
                setSelectMedidaCorretiva={clickEditar}
                selectMedidaCorretiva={selectMedidaCorretiva}
              />
            </div>
          </div>
        </MuiThemeProvider>
      )}
    />
  );

  /**
   * Trata objeto a ser editado
   * @param {*} values - valores
   */
  function clickEditar(value) {
    setSelectMedidaCorretiva({ ...value });
  }

  function clearSelect() {
    setSelectMedidaCorretiva(null);
  }

  /**
   * Deleta uma medida corretiva
   */
  function deleteMedidaCorretiva(values) {
    swalWarning(
      'naoConformidade.desejaExcluirMedidaCorretiva',
      'naoConformidade.excluir',
      'naoConformidade.nao'
    ).then(res => {
      swal.close();
      if (res) {
        const medidaCorretivaListAux = [...initialValues.sancaoMedidaCorretivaList];

        const medidaCorretiva = medidaCorretivaListAux.find(
          agencia => agencia.idSancaoMedidaCorretiva === values.idSancaoMedidaCorretiva
        );
        const position = medidaCorretivaListAux.indexOf(medidaCorretiva);

        if (values.stRegistro === 0) {
          medidaCorretivaListAux.splice(position, 1);
        } else {
          values.stRegistro = 2;
          medidaCorretivaListAux[position] = values;
        }

        initialValues.sancaoMedidaCorretivaList = medidaCorretivaListAux;
        setKeyMedidaCorretiva(Math.random());
      }
    });
  }

  /**
   * Insere novo item na lista de medida corretiva ou atualiza item existente
   * @param {*} values - valores
   * @param {*} isEditing - status de editar
   */
  function addEditMedidaCorretiva(values, isEditing) {
    const medidaCorretivaListAux = [...initialValues.sancaoMedidaCorretivaList];

    if (isEditing) {
      const medidaCorretiva = medidaCorretivaListAux.find(
        agencia => agencia.idSancaoMedidaCorretiva === values.idSancaoMedidaCorretiva
      );
      const position = medidaCorretivaListAux.indexOf(medidaCorretiva);
      medidaCorretivaListAux[position] = values;
    } else {
      values.stRegistro = 0;
      values.idSancaoMedidaCorretiva = Math.random();

      medidaCorretivaListAux.push(values);
    }

    initialValues.sancaoMedidaCorretivaList = medidaCorretivaListAux;
    setKeyMedidaCorretiva(Math.random());
    setSelectMedidaCorretiva({
      medidaCorretiva: null,
      dhInicio: null,
      dhTermino: null
    });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarSancao)));
