import React, { Component } from 'react';
import TextFieldAcompanhamento from './TextFieldAcompanhamento';
import Grid from '@material-ui/core/Grid';
import { getUserLang } from '@utils/localeUtils';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

class ListDetalhesAplicacao extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const areaTanque = messagesLinguage['listDetalhes.areaTanque'];
    const dsUnMedida = messagesLinguage['listDetalhes.dsUnMedida'];
    const dsRecomendacaoAdicional = messagesLinguage['listDetalhes.dsRecomendacaoAdicional'];
    const produtos = messagesLinguage['listDetalhes.produtos'];
    const talhoesAdicionado = messagesLinguage['listDetalhes.talhoesAdicionado'];
    const tecnicoResponsavel = messagesLinguage['listDetalhes.tecnicoResponsavel'];
    const invasoras = messagesLinguage['listDetalhes.invasoras'];
    const alertasFitossanitarios = messagesLinguage['listDetalhes.alertasFitossanitarios'];
    const sim = messagesLinguage['listDetalhes.sim'];
    const nao = messagesLinguage['listDetalhes.nao'];
    const observacao = messagesLinguage['listDetalhes.observacao'];
    const talhoes = messagesLinguage['listDetalhes.talhoes'];
    const amostras = messagesLinguage['listDetalhes.amostras'];
    const fitossanidadesEncontradas = messagesLinguage['listDetalhes.fitossanidadesEncontradas'];
    const quantidadeAmostras = messagesLinguage['listDetalhes.quantidadeAmostras'];
    const produtosProibidos = messagesLinguage['listDetalhes.produtosProibidos'];
    const areaDeCultivo = messagesLinguage['listDetalhes.areaDeCultivo'];
    const cultura = messagesLinguage['listDetalhes.cultura'];
    const cultivarUtilizadas = messagesLinguage['listDetalhes.cultivarUtilizadas'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          produtosProibidos,
          dsUnMedida,
          dsRecomendacaoAdicional,
          produtos,
          talhoesAdicionado,
          tecnicoResponsavel,
          areaTanque,
          invasoras,
          alertasFitossanitarios,
          sim,
          nao,
          observacao,
          talhoes,
          amostras,
          fitossanidadesEncontradas,
          quantidadeAmostras,
          areaDeCultivo,
          cultura,
          cultivarUtilizadas
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();
  }

  render() {
    var timeline = this.props.timeLine[this.props.position];
    var info = timeline.info;
    var type = timeline.type;

    return (
      <Grid container style={{ padding: 15 }} spancing={24}>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.tecnico.nmUsuario}
            label={provider.intl.formatMessage({ id: 'tecnicoResponsavel' })}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.areaCultivo ? info.areaCultivo.dsAreaCultivo : ''}
            label={provider.intl.formatMessage({ id: 'areaDeCultivo' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.plantio ? info.plantio.cultura.nmCultura : ''}
            label={provider.intl.formatMessage({ id: 'cultura' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.plantio ? info.plantio.cultivarEspec.nmCultivarEspec : ''}
            label={provider.intl.formatMessage({ id: 'cultivarUtilizadas' })}
          />
        </Grid>

        {(type < 2 || type === 8) && !info.typeInsumo && (
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={Intl.NumberFormat(getUserLang()).format(info.nrQuantidade)}
              label={provider.intl.formatMessage({ id: 'areaTanque' })}
            />
          </Grid>
        )}
        {(type < 2 || type === 8) && (
          <Grid item xs={6} style={{ paddingLeft: !info.typeInsumo ? 10 : 0 }}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={info.tpUnidade}
              label={provider.intl.formatMessage({ id: 'dsUnMedida' })}
            />
          </Grid>
        )}
        {type === 2 && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={
                info.stInvasoras === 1
                  ? provider.intl.formatMessage({ id: 'sim' })
                  : provider.intl.formatMessage({ id: 'nao' })
              }
              label={provider.intl.formatMessage({ id: 'invasoras' })}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.dsAdicional}
            label={
              type === 2 || type === 5
                ? provider.intl.formatMessage({ id: 'observacao' })
                : provider.intl.formatMessage({ id: 'dsRecomendacaoAdicional' })
            }
          />
        </Grid>
        {type === 5 && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={info.qtAmostras}
              label={provider.intl.formatMessage({ id: 'quantidadeAmostras' })}
            />
          </Grid>
        )}
        {(type === 0 || type === 1 || type === 8) && (
          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <div style={{ fontSize: 12, color: 'rgb(0, 0, 0, 0.54)' }}>
              {provider.intl.formatMessage({ id: type === 8 ? 'produtosProibidos' : 'produtos' })}
            </div>
            {this.props.listaProdutos(info.atividadeInsumoList)}
          </Grid>
        )}
        {type === 2 && (
          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <div style={{ fontSize: 12, color: 'rgb(0, 0, 0, 0.54)' }}>
              {provider.intl.formatMessage({ id: 'alertasFitossanitarios' })}
            </div>
            {this.props.listAlertas(info.plantioSeveridadeList)}
          </Grid>
        )}
        {type === 5 && (
          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <div style={{ fontSize: 12, color: 'rgb(0, 0, 0, 0.54)' }}>
              {provider.intl.formatMessage({
                id: 'fitossanidadesEncontradas'
              }) +
                ' - ' +
                info.severidades.length}
            </div>
            {this.props.listMonitoramento(info.severidades, info.atividadeAmostraList)}
          </Grid>
        )}
        {type !== 5 && (
          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <div
              style={{
                fontSize: 12,
                color: 'rgb(0, 0, 0, 0.54)',
                marginTop: 15
              }}
            >
              {type === 2
                ? provider.intl.formatMessage({ id: 'talhoes' })
                : provider.intl.formatMessage({ id: 'talhoesAdicionado' })}
            </div>
            {this.props.chipsTalhoes(info.atividadeTalhaoList)}
          </Grid>
        )}
        {type === 5 && (
          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <div
              style={{
                fontSize: 12,
                color: 'rgb(0, 0, 0, 0.54)',
                marginTop: 15
              }}
            >
              {provider.intl.formatMessage({ id: 'talhoes' })}
            </div>
            {this.props.chipsTalhoesMonitoramento(info.talhoesUtilizados)}
          </Grid>
        )}
      </Grid>
    );
  }
}

export default ListDetalhesAplicacao;
