import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllEspeciesHandler() {
  return axios.get(api + '/especie-animal');
}

function findAllEspeciesAtivoHandler() {
  return axios.get(api + '/especie-animal/ativo');
}

function findEspecieById(idEspecie) {
  return axios.get(api + '/especie-animal/' + idEspecie);
}

function deleteEspecieByIdHandler(idEspecie) {
  return axios.delete(api + '/especie-animal/' + idEspecie);
}

function addEspecieHandler(values) {
  return axios.post(api + '/especie-animal', values);
}

function editEspecieHandler(values) {
  return axios.put(api + '/especie-animal', values);
}

export default {
  findAllEspecie: findAllEspeciesHandler,
  findEspecieById: findEspecieById,
  deleteEspecieById: deleteEspecieByIdHandler,
  addEspecie: addEspecieHandler,
  editEspecie: editEspecieHandler,
  findAllEspeciesAtivo: findAllEspeciesAtivoHandler
};
