import formatMessage from './i18n/formatMessage';

const operationTypeString = [
  {
    label: formatMessage('classificacao.descricao'),
    field: 'dsClassificacao',
    type: 'string'
  },
  {
    label: formatMessage('classificacao.produtivo'),
    field: 'stProdutivo',
    type: 'enum',
    options: [
      {
        label: formatMessage('classificacao.sim'),
        field: 'sim',
        value: '1'
      },
      {
        label: formatMessage('classificacao.nao'),
        field: 'nao',
        value: '0'
      }
    ]
  },
  {
    label: formatMessage('classificacao.preservacaoAmbiental'),
    field: 'stPreservacaoAmbiental',
    type: 'enum',
    options: [
      {
        label: formatMessage('classificacao.sim'),
        field: 'sim',
        value: '1'
      },
      {
        label: formatMessage('classificacao.nao'),
        field: 'nao',
        value: '0'
      }
    ]
  },
  {
    label: formatMessage('classificacao.situacao'),
    field: 'stClassificacao',
    type: 'enum',
    options: [
      {
        label: formatMessage('classificacao.ativo'),
        field: 'Ativo',
        value: '1'
      },
      {
        label: formatMessage('classificacao.inativo'),
        field: 'Inativo',
        value: '0'
      }
    ]
  }
];

const headDataGrid = [
  {
    field: 'dsClassificacao',
    headerName: formatMessage('classificacao.descricao'),
    col: 4
  },
  {
    valueGetter: args =>
      [formatMessage('classificacao.nao'), formatMessage('classificacao.sim')][
        args.node.data.stProdutivo
      ],
    headerName: formatMessage('classificacao.produtivo'),
    col: 3
  },
  {
    valueGetter: args =>
      [formatMessage('classificacao.nao'), formatMessage('classificacao.sim')][
        args.node.data.stPreservacaoAmbiental
      ],
    headerName: formatMessage('classificacao.preservacaoAmbiental'),
    col: 3
  },
  {
    valueGetter: args =>
      [formatMessage('classificacao.inativo'), formatMessage('classificacao.ativo')][
        args.node.data.stClassificacao
      ],
    headerName: formatMessage('classificacao.situacao'),
    col: 2
  }
];

export { operationTypeString, headDataGrid };
