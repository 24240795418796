export default {
  agendaHeader: {
    hoje: "Today",
    mes: "Month",
    dia: "Day",
    semana: "Week",
    janeiro: "January",
    fevereiro: "February",
    marco: "March",
    abril: "April",
    maio: "May",
    junho: "June",
    julho: "July",
    agosto: "August",
    setembro: "September",
    outubro: "October",
    novembro: "November",
    dezembro: "December",
    jan: "Jan",
    fev: "Feb",
    mar: "Mar",
    abr: "Apr",
    mai: "May",
    jun: "Jun",
    jul: "Jul",
    ago: "Aug",
    set: "Sept",
    out: "Oct",
    nov: "Nov",
    dez: "Dec"
  }
};
