export default {
  produtores: {
    areaTotalNdviExcedida: 'Se ha superado el área total disponible para la monitorización del NDVI',
    erroCriarPoligono: 'Se produjo un error al crear el polígono',
    tamanhoMinino: 'El área mínima de un polígono es 1 ',
    emailInvalido: 'Email inválido',
    ecosocialObrigatorio: 'Agregue al menos un tipo de ecosistema',
    descFertilidade: 'Fertilidad',
    selecioneUmTalhaoPeloMenos: 'Agregar al menos un campo',
    edicaoDeTalhao: 'Edición de campo',
    cadastroDeTalhao: 'Registro de campo',
    detinacaoAdequadaResiduos: 'Eliminación adecuada de residuos',
    nomeCompleto: 'Ingrese el nombre completo',
    nascimento: 'Nacimiento',
    idade: 'Edad',
    cadastroAmbiental: 'Registro ambiental',
    edicaoAmbiental: 'Edición ambiental',
    cadastroProducaoAnimal: 'Registro de ganado',
    edicaoProducaoAnimal: 'Edición ganadera',
    cadastroColaborador: 'Registro de empleados',
    edicaoColaborador: 'Edición del contribuidor',
    cadastroFamiliar: 'Registro familiar',
    edicaoFamiliar: 'Edición familiar',
    cadastroAreaCultivo: 'Registro de área de cultivo',
    edicaoAreaCultivo: 'Edición del área de cultivo',
    cadastroProduto: 'Registro de producto',
    edicaoProduto: 'Edición de producto',
    cadastroAnaliseRisco: 'Datos maestros de análisis de riesgos',
    edicaoAnaliseRisco: 'Edición de análisis de riesgos',
    solo: 'Solo',
    agua: 'Agua',
    residual: 'Residual',
    desejaRemoverLancamento: '¿Quieres eliminar esta versión?',
    lancamentoExcluido: 'Lanzamiento eliminado',
    dataLancamento: 'Fecha de lanzamiento',
    destinacaoAdequadaResiduos: 'Eliminación adecuada de residuos',
    producaoFamiliar: 'Producción familiar',
    riscosAdicionaisRelevantes: 'Riesgos adicionales relevantes',
    analiseDeRisco: 'Análisis de riesgo',
    experienciaProdutor: 'Experiencia de productor de granjero orgánico',
    resultadosAnteriores: 'Historia, resultados pasados',
    pressaoUsoDePesticidas: 'Presión del uso de pesticidas en la región.',
    proximidadesLavouras: 'Cercano/Barreras con labranza convencional',
    suspeitaAplicacaoDireta: 'Sospecha de aplicación directa',
    tamanhoSafra: 'Tamaño de cultivo',
    riscoTrocaSafra: 'Riesgo de sustitución de cultivos por producto convencional.',
    somaRiscos: 'Suma de riesgos',
    derivaVentoOuAgua: 'Viento o agua/lluvia a la deriva',
    transitoVeiculos: 'Tráfico vehicular (carretera)',
    cultivosTransgenicos: 'Cultivos GM de vecinos',
    utilizacaoImplementosAgricolas: 'Uso de implementos agrícolas compartidos',
    comentariosGerais: 'Comentarios generales',
    nota: 'Nota',
    desejaExcluirFamiliar: '¿Quieres eliminar a miembro de la familia?',
    familiarExcluido: '¡Familia eliminada con éxito!',
    desejaExcluirProduto: '¿Quieres eliminar producto?',
    produtoExcluido: '¡Producto eliminado con éxito!',
    categoria: 'Categoría',
    acaros: 'Ácaros',
    bacterias: 'Bacterias',
    ervasDaninhas: 'Malas hierbas',
    fungos: 'Hongos',
    insetos: 'Insectos',
    nematoides: 'Nematodos',
    foliaresAdjuvantes: 'Hoja y adyuvante',
    fertilizantes: 'Fertilizantes',
    fertilizanteQuimico: 'Fertilizantes químico',
    fertilizanteOrganico: 'Fertilizantes orgánico',
    naoSeAplica: 'No se aplica',
    oleosAdjuvantes: 'Aceites adyuvantes',
    segregacaoInsumos: 'Segregación de entradas',
    cadastroDeProdutor: 'Cadastro de Procdutor',
    produtores: 'Produtores',
    identificacao: ' Identificación',
    nome: 'Nombre',
    sexo: 'Sexo',
    dataNascimento: 'Fecha de nacimiento',
    escolaridade: 'Escolaridad',
    familiares: 'Miembros de la familia',
    editarFamiliar: 'Editar familiar',
    novoFamiliar: 'Registro familiar',
    infraestruturas: 'Infraestructura',
    tpArea: 'Tipo da área',
    anexoContrato: 'Anexo do contrato e registro',
    validadeContrato: 'Validade do contrato ',
    termoCompromisso: 'Termo de compromisso de manejo orgânico',
    areaPropria: 'Área propria',
    arrendada: 'Arrendada',
    ambas: 'Los dos',
    aspectoLegal: 'Aspectos legales',
    masculino: 'Masculino',
    feminino: 'Hembra',
    relacaoPredial: 'Construyendo relaciones',
    localArmazenamentoInsumos: 'Ubicación de almacenamiento de entrada',
    maquinarioUtensilios: 'Maquinaria y utensilios',
    propriedade: 'Administración de propiedad',
    tomadaDecisao: '',
    atividadesDesenvolvidas: 'Actividades desarrolladas',
    telefone: 'Teléfono',
    comunidade: 'Comunidad',
    municipio: 'Condado',
    uf: 'UF',
    email: 'E-mail',
    nivel: 'Nível tecnológico',
    tecnicoResponsavel: 'Tecnico responsable',
    ativo: 'Activo',
    codigo: 'Código',
    cidade: 'Country',
    estado: 'Estado',
    descricao: 'Description',
    areaCultivo: 'Area total de cultivo',
    areaTotaldeTalhoes: 'Superficie total de terreno',
    dataVisita: 'Fecha de la visita',
    hora: 'Tiempo',
    tecnico: 'Técnico',
    dataPlantio: 'Fecha de la siembra',
    culturas: 'Cultura',
    dataColheita: 'Fecha de cosecha',
    produtividade: 'Productividad(Bolsas/Ha)',
    areaDeCultivo: 'Área de cultivo',
    talhoes: 'Parcelas',
    visitas: 'Visitas',
    plantios: 'Plantaciones',
    campoObrigatorio: 'Campo obligatorio',
    status: 'Estatus',
    inativo: 'Inactivo',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    gravar: 'Registro',
    desejaExcluirAreaCultivo: '¿Desea eliminar el área de cultivo seleccionado?',
    desejaExcluirTalhao: '¿Desea eliminar el parcela seleccionado?',
    nao: 'No',
    excluir: 'Borrar',
    excluindo: 'Excluyendo',
    areaCultivoRemovidoComSucesso: 'Área de cultivo removido con éxito',
    talhaoRemovidoComSucesso: 'Parcelas removido con éxito',
    medio: 'Promedio',
    alto: 'Alto',
    baixo: 'Bajo',
    salvando: 'Verano',
    atualizando: 'Actualizando',
    produtorCadastradoComSucesso: 'Productor registrado correctamente',
    produtorEditadoComSucesso: 'Productor editado con éxito',
    falhaAoCadastrarProdutor: 'Error al registrar productor',
    falhaAoEditarProdutor: 'Error al editar productor',
    emailJaCadastrado: 'Este correo electrónico ya está en uso',
    erroAoCarregarDados: 'Error al cargar los datos',
    aplicacoes: 'Aplicaciones',
    dataAplicacao: 'Fecha de la aplicación',
    tipo: 'Tipo',
    marca: 'Marca',
    produto: 'Producto',
    talhao: 'Parcela',
    hectares: 'Hectáreas',
    acaricida: 'Acaricidas',
    acaricidaMicrobiologico: 'Acaricidas microbiológicas',
    agenteBiologicoControle: 'Agentes biológicos',
    bactericida: 'Agentes biológicos',
    bactericidaMicrobiologico: 'Bactericidas microbiológicos',
    herbicida: 'Herbicidas',
    fungicida: 'Fungicidas',
    fungicidaMicrobiologico: 'Fungicidas Microbiológicos',
    inseticida: 'Inseticidas',
    inseticidaMicrobiologico: 'Inseticidas microbiológicos',
    nematicida: 'Nematicidas',
    nematicidaMicrobiologico: 'Nematicidas microbiológicas',
    fertilizanteFoliar: 'Fertilizantes hoja',
    talhaoDuplicado: 'de la madera',
    plantioCadastradoComSucesso: 'plantación registrada con éxito',
    desejaExcluirAplicacao: 'desea excluir la aplicación?',
    aplicacaoRemovidaComSucesso: 'Aplicación removida con éxito',
    naoFoiPossivelEditar: 'No se pudo editar la plantación',
    naoFoiPossivelInserir: 'No se pudo insertar plantación',
    plantioEditadoComSucesso: 'Plantación editado con éxito',
    plantioRemovidoComSucesso: 'Plantación registrada con éxito',
    erroAoRemoverPlantio: 'Error al quitar plantación',
    desejaExcluirPlantio: 'Desea eliminar la plantación?',
    areaTotalSelecionada: 'Área total seleccionada(Ha)',
    talhoesAdicionados: 'Taladros añadidos',
    selecionarTodos: 'Select all',
    areaPlantio: 'Área de plantación',
    acompanhamentoNaoCadastrado: 'Acompañamiento de plantío sin registrar todavía',
    acompanhamentoPlantio: 'Acompañamiento de plantación',
    acompanhamentoProdutor: 'Acompañamiento al productor',
    nrQuantidade: 'Cantidad',
    produtos: 'Produtos',
    lancamentoAplicacao: 'Lanzamiento de aplicación',
    dsUnMedida: 'Un. de medida',
    dsRecomendacaoAdicional: 'Recomendación adicional',
    ordem: 'Orden',
    dose: 'Dosificar(L)',
    adicionarProdutos: 'Añadir productos',
    areaTotal: 'Área total',
    lancamentoCadastrado: 'Lanzamiento registrado con éxito',
    lancamentoAtualizado: 'Lanzamiento editado con éxito',
    falhaAoCadastrarLancamento: 'Error al registrar la contabilización',
    falhaAoAtualizarLancamento: 'Error al actualizar la versión',
    unMedida: 'Unidad de medida',
    recomendacoesAdicionais: 'Recomendaciones adicionales',
    visualizarRecomendacoes: 'Ver recomendaciones',
    fechar: 'Cerca',
    aplicarNosTalhoes: 'Aplicar en los lotes',
    talhoesUtilizados: 'Taladros utilizados',
    falhaAoCarregarAcompanhamento: 'Error al cargar el seguimiento',
    produtoRemovidoComSucesso: 'Producto eliminado con éxito',
    lancamentoRecomendacao: 'Lanzamiento de recomendación',
    cadastrarAplicacao: 'Inscripción de aplicación',
    cadastrarRecomendacao: 'Inscripción recomendada',
    acre: 'Acre',
    hectare: 'Hectárea',
    alqueire: 'Alqueire',
    numeroMaximoProdutores: 'Se ha alcanzado el límite de producción de su plan.',
    desejaExcluir: '¿Desea eliminar ',
    falhaAoExcluirProdutor: 'Fallo al excluir el productor',
    ocorreuErroExcluirProdutor: 'No se pudo eliminar al productor',
    produtorExcluidoComSucesso: 'Productor excluido con éxito',
    telefoneInvalido: 'Teléfono no válido',
    cadastroPlantio: 'Registro de plantación',
    cadastrarAreaCultivo: 'Primer registro de área de cultivo',
    cadastrarTalhao: 'Primer registro área de cultivo y lote',
    talhaoPlantioAssociado: 'Talón y siembra asociados al área de cultivo',
    exclusaoAreaDeCultivoNaoPermitida: 'La exclusión del área de cultivo no está permitida',
    talhaoAssociado: 'Taladro asociado al área de cultivo',
    plantioAssociado: 'Plantio asociado al área de cultivo',
    produtor: 'Productor',
    adicionarTalhao: 'Agregar parcelas',
    editarTalhao: 'Editar campo',
    exclusaoTalhaoNaoPermitida: 'Exclusión de talón no permitido',
    adicionarAreaCultivo: 'Añadir área de cultivo',
    editarAreaCultivo: 'Editar área de cultivo',
    adicionarPlantio: 'Añadir siembra',
    editarPlantio: 'Editar plantación',
    alertasFitossanitarios: 'Alertas Fitosanitarias',
    doencaSolo: 'Enfermedad del suelo',
    doencaFoliar: 'Enfermedad de la hoja',
    nematoide: 'Nematodo',
    pragas: 'Plagas',
    agenteCausal: 'Agente causal',
    invasoras: 'Invasor',
    agenteCausalAdicionadas: 'Agentes causais añadida',
    minimoUm: '1 campo mínimo',
    dataMinima: 'Fecha inválida',
    voltar: 'Volver',
    agendaRelacionada: 'Hay uno o más calendarios relacionados con el productor',
    atividadeRelacionada: 'Hay actividades relacionadas con el productor',
    registrosRelacionados: 'Hay registros relacionados con el campo.',
    confirmar: 'Confirmar',
    salvarProdutorSair: 'Hay información no guardada, ¿quieres salvar al productor?',
    descartar: 'To discard',
    produtividades: 'Productividad',
    area: 'Area',
    sacas: 'Sacos',
    toneladas: 'Toneladas',
    semeadura: 'Siembra',
    unidade: 'Unidad',
    espacamento: 'Espaciamiento (cm)',
    areaSemeadura: 'Área de siembra',
    m2: 'm²',
    metro: 'Metro',
    standFinal: 'Stand final',
    previsaoColheita: 'Pronóstico de cosecha',
    diasEstimados: 'Días estimados',
    produtividadeEstimada: 'Productividad estimada',
    quantidadePlantas: 'Numero de plantas',
    tonelada: 'Tonelada',
    colheita: 'Cosecha',
    localPlantio: 'Sitio de plantación',
    sementes: 'Semillas',
    data: 'Fecha',
    certificacaoOrganica: 'Estado de certificación orgánica',
    convencional: 'Convencional',
    emConversao: 'En conversión',
    brasil: 'BR (Brazil)',
    comunidadeEuropeia: 'CE (Comunidad Europea)',
    eua: 'NOP (Estados Unidos)',
    producaoParalela: 'Producción paralela',
    ecosocial: 'Certificación ecosocial',
    naoPossui: 'No tiene',
    dataPrevista: 'Fecha prevista',
    informacoes: 'Informaciones',
    mapas: 'Mapas',
    mapa: 'Mapa',
    excluirMarcacao: 'Etiqueta clara',
    desejaExcluirMarcacao: '¿Quieres borrar las etiquetas?',
    sim: 'Si',
    minino: 'El área total de campo debe ser mayor que 0',
    cpf: 'Documento',
    conhecimentoOrganica: 'Conocimiento de producción orgánica.',
    atividadeGraos: 'Actividad de granos',
    areaProdutiva: 'Área de producción de granos',
    cadastroCompleto: 'Complete Registration',
    completo: 'Completa',
    preCadastro: 'Preinscripción',
    cadastroPreProdutor: 'Preinscripción de productor',
    cpfInvalido: 'Documento inválido',
    efetivarProdutor: 'Hacer productor',
    efetivarPreCadastro: 'Preinscribirse',
    contrato: 'Contrato',
    colaboradores: 'Colaboradores',
    efetivo: 'Eficaz',
    temporario: 'Temporal',
    desejaExcluirColaborador: '¿Quieres eliminar colaborador?',
    colaboradorRemovidoComSucesso: 'Colaborador eliminado con éxito',
    dadosSociais: 'Datos sociales',
    participacaoAssociacoes: 'Únete a asociaciones o cooperativas',
    conflitoLimites: 'Conflicto con propiedades limítrofes',
    ambiental: 'Ambiental',
    localizacaoSede: 'Sede central',
    informacoesClima: 'Informacion del tiempo',
    informacoesSolo: 'Información solo',
    terracentamento: 'Terrazas',
    ruim: 'Mal',
    satisfatorio: 'Satisfactorio',
    razoavel: 'Justo',
    plantioNivel: 'Plantación a nivel',
    usoPlantasCobertura: 'Uso de plantas de cobertura.',
    plantioDireto: 'Sin labranza',
    protecaoFontes: 'Protección de la fuente',
    protecaoLeitosAgua: 'Protección de camas de agua.',
    analisePortabilidadeAgua: 'Análisis de potabilidad del agua',
    usoIrrigacao: 'Uso de riego',
    organicosDomisanitarios: 'Hogar Orgánico',
    organicosAnimais: 'Animales organicos',
    reciclaveis: 'Reciclable',
    embalagensAgrotoxicos: 'Envasado de plaguicidas',
    especie: 'Especie',
    organico: 'Orgánico',
    producaoAnimalRemovidoComSucesso: 'Producción animal eliminada con éxito',
    desejaExcluirProducaoAnimal: '¿Quieres excluir la producción animal?',
    frango: 'Pollo',
    gado: 'Ganado',
    adicionarFamiliar: 'Agregar familia',
    adicionarColaborador: 'Añadir colaborador',
    editarColaborador: 'Editar colaborador',
    editarPMO: 'Editar PMO',
    adicionarPMO: 'Añadir PMO',
    producaoAnimal: 'producción animal',
    editarProducaoAnimal: 'Editar producción animal',
    adicionarProducaoAnimal: 'Añadir producción animal',
    nenhumMapaCadastrado: 'Ningún mapa registrado',
    propriedades: 'Propiedade',
    dataCadastro: 'Fecha de registro',
    dataAdmissao: 'Fecha de ingreso',
    dataDemissao: 'Fecha de renuncia',
    raca: 'Carrera',
    qtda: 'Cantidad',
    analiseRiscoRemovidoComSucesso: 'Análisis de riesgos eliminado con éxito',
    desejaExcluirAnaliseRisco: '¿Quieres eliminar el análisis de riesgos?',
    funcaoExercida: 'Función realizada',
    conhecimentoNormas: 'Conocimiento de las normas laborales.',
    nenhumTalhaoDemarcado: 'No hay parcela demarcada',
    riscosAdicionais: 'Riesgos adicionales',
    fundamentalIncompleto: 'Elemental incompleto',
    fundamentalCompleto: 'Elemental completo',
    medioIncompleto: 'Escuela secundaria incompleta',
    medioCompleto: 'Complete high school',
    superiorIncompleto: 'Educación superior incompleta',
    superiorCompleto: 'Educación superior',
    longitude: 'Longitud',
    latitude: 'Latitud',
    selecioneArquivo: 'Selecciona el archivo',
    nenhumArquivoSelecionado: 'No hay archivos seleccionados',
    baixar: 'Descargar',
    colaboradoresResponsaveis: 'Empleados responsables de la toma de decisiones.',
    valorInvalido: 'Valor inválido',
    motivoAlteraçao: 'Motivo de cambio',
    mininoCampo: 'Mínimo 10 letras',
    erroAnexarFoto: 'Se produjo un error al adjuntar la foto',
    numeroRegistroCLT: 'Número de registro (CLT)',
    numeroContrato: 'Número de contacto',
    moraPropriedade: 'Vive en la propiedad',
    possuiRendaExtra: 'Tiene un ingreso extra',
    descricaoRendaExtra: 'Descripción del ingreso extra',
    car: 'CAR',
    dap: 'DAP',
    descricaoDejetoAnimal: 'Descripción de los desechos animales.',
    dejetoAnimal: 'Desechos animales',
    descricaoCarcacaAnimal: 'Descripción de los cadáveres de animales.',
    carcacaAnimal: 'Cadáveres de animales',
    periodoCompostagem: 'Compostaje',
    descricaoPeriodoCompostagem: 'Periodo de compostaje',
    descricaoPeriodoArmazenamentoUrina: 'Período de almacenamiento previo',
    periodoArmazenamentoUrina: 'Orina animal',
    descricaoOrganicosDomissanitarios: 'Descripción de productos de limpieza domésticos.',
    descricaoReciclaveis: 'Descripción de reciclables',
    descricaoEmbalagensAgrotoxicas: 'Descripción del empaque de pesticidas',
    abastecimentoPulverizacao: 'Suministro de pulverizadores.',
    descricaoAbastecimentoPulverizacao: 'Descripción del suministro de spray',
    fontes: 'Fuentes',
    abastecimentoComunitario: 'Suministro comunitario',
    rioLagos: 'Río/Lagos',
    codigoCertificadora: 'Código de certificación orgánica',
    homeopaticos: 'Productos homeopáticos',
    inoculantes: 'Inoculantes',
    dataPrimeiraVisita: 'Fecha de la primera visita',
    cnpjInvalido: 'CNPJ no válido',
    certificacoes: 'Certificaciones',
    classificacao: 'Uso y ocupación del suelo',
    certificacao: 'Certificación',
    statusCertificacaoOrganica: 'Estado de certificación orgánica',
    agencia: 'Agencia',
    temporarios: 'Temporario'
  }
};
