import { createMuiTheme } from '@material-ui/core/styles';

const style = () => ({
  cardAll: {
    width: '100%',
    height: '100%'
  },
  cardFiltros: {
    height: 90,
    width: '100%',
    backgroundColor: 'rgb(225, 225, 225, 0.8)',
    position: 'absolute',
    zIndex: 1000
  },
  gridFiltros: {
    padding: '15px 60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 43px)'
  },
  cardMap: {
    width: '100%',
    height: 'calc(100% - 170px)'
  },
  mapa: {
    width: 'calc(100% - 51px)',
    height: 'calc(100% - 260px)',
    position: 'absolute',
    zIndex: 1
  },
  cardCheckFiltros: {
    padding: '15px 60px',
    height: 150,
    width: '100%',
    backgroundColor: '#FFFFFF',
    marginTop: -10,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1
  },
  checkBox: {
    padding: '0px 15px 0px 0px'
  },
  textField: {
    width: '100%',
    height: 70
  },
  cardCarregando: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'absolute',
    height: '100vh'
  },
  search: {
    marginTop: 5,
    marginLeft: 15,
    color: 'rgba(0, 0, 0)',
    cursor: 'pointer',
    height: 26,
    width: 26
  },
  labelCheck: {
    color: '#040404',
    width: 180,
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardCheck: {
    display: 'flex',
    alignItems: 'center'
  },
  cardImageData: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  image: {
    cursor: 'pointer'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 350px)',
    minHeight: 250,
    marginTop: 90
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    default: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { style, theme };
