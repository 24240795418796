export default {
  agendaVisitas: {
    agendaVisitas: 'Acompanhamento de visitas',
    visitado: 'Visitado',
    situacao: 'Situação',
    aplicProdutoProibido: 'Aplic. produto proibido',
    monitoramentoPragas: 'Monitoramento de praga',
    atividadeAtribuida: 'Atividade atribuída a visita',
    agendado: 'Agendado',
    aplicProduto: 'Aplic. produto',
    recomendacaoAplic: 'Recomendação de aplic.',
    diasSemVisita: '15 dias sem Visita',
    registroClimatico: 'Registro climático',
    alertaFitossanitario: 'Alerta Fitossanitário',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    tecnico: 'Técnico',
    registroFotografico: 'Registro fotográfico',
    tecnicoResponsavel: 'Técnico Responsável',
    produtor: 'Produtor',
    qtVisitas: 'Qnt. de Visitas',
    data: 'Data',
    semAtividade: 'Sem atividade',
    itemSemCoordenadas: 'Existem registros sem coordenadas (Latitude / Longitude)'
  }
};
