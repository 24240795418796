/* Autor: Gabriela Farias */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Card from '@components/Card/Card';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Produtor from '@resources/Produtores';
import Maps from './Maps';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import iconeClip from '@images/icone_chip.png';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const icone_adicionar = require('@images/adicionar_talhao.png');

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const style = () => ({
  cardCarregando: {
    marginTop: 15,
    marginBottom: 15,
    width: '100%',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  view: {
    width: '100%',
    height: '100%'
  },
  card: {
    height: 200,
    borderRadius: 0,
    flex: 'none'
  },
  gridContainer: {
    width: '100%',
    height: '100%'
  },
  button: {
    height: 24,
    width: 24,
    marginTop: 15
  },
  cardChips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    backgroundColor: '#EAEAEA',
    borderRadius: 10,
    padding: 10,
    display: 'inline-flex',
    height: 17,
    marginRight: 10,
    position: 'relative',
    marginTop: 5
  },
  chipContent: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  chipFiltro: {
    fontSize: 13,
    color: '#727272',
    marginLeft: '2px',
    whiteSpace: 'nowrap',
    display: 'flex'
  },
  stylesIcon: {
    cursor: 'pointer',
    marginLeft: '5px',
    position: 'relative'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class TelaMapa extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const filtro = messagesLinguage['mapa.filtro'];
    const produtor = messagesLinguage['mapa.produtor'];
    const campoObrigatorio = messagesLinguage['mapa.campoObrigatorio'];
    const area = messagesLinguage['mapa.area'];
    const produtorDuplicado = messagesLinguage['mapa.produtorDuplicado'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          filtro,
          produtor,
          campoObrigatorio,
          area,
          produtorDuplicado
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      listProdutoresSelect: [],
      listProdutores: [],
      key: Math.random(),
      valueAutoComplete: '',
      listItensMapa: [],
      loading: true,
      errors: false
    };

    const title = 'mapa.mapa';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.onChangeOwn = this.onChangeOwn.bind(this);
    this.clickSalvar = this.clickSalvar.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.findAllProdutores = this.findAllProdutores.bind(this);
  }

  componentDidMount() {
    this.findAllProdutores();
  }

  findAllProdutores() {
    Produtor.findAllProdutorByUsuario()
      .then(doc => {
        var listItensMapa = [];
        doc.data.forEach(doc => {
          doc.talhaoList.forEach(talhao => {
            if (talhao.talhaoGpsList.length > 0 || (talhao.nrLatitude && talhao.nrLongitude)) {
              listItensMapa.push({
                idProdutor: doc.idProdutor,
                produtor: doc.nmProdutor,
                areaCultivo: talhao.areaCultivo.dsAreaCultivo,
                talhao: talhao.dsTalhao,
                tpUnidade: talhao.tpUnidade,
                areaTalhao: talhao.nrAreaTalhao,
                talhaoGpsList: talhao.talhaoGpsList,
                nrLatitude: talhao.nrLatitude,
                nrLongitude: talhao.nrLongitude
              });
            }
          });
        });
        this.setState({
          listProdutores: doc.data,
          listItensMapa: listItensMapa,
          loading: false
        });
      })
      .catch(() => {
        this.setState({
          listProdutores: [],
          loading: false
        });
      });
  }

  tratarValorSelectInitial(data) {
    if (!data) {
      return '';
    }

    return data;
  }

  onChangeOwn(nmProdutor) {
    this.setState({
      valueAutoComplete: nmProdutor,
      errors: false
    });
  }

  clickSalvar() {
    const validation = this.state.listProdutoresSelect.find(doc => {
      return doc.idProdutor === this.state.valueAutoComplete.idProdutor;
    });

    if (validation === undefined) {
      this.setState({
        loading: true
      });

      var listItensMapa = [];

      this.state.valueAutoComplete.talhaoList.forEach(talhao => {
        if (talhao.talhaoGpsList.length > 0 || (talhao.nrLatitude && talhao.nrLongitude)) {
          listItensMapa.push({
            idProdutor: this.state.valueAutoComplete.idProdutor,
            produtor: this.state.valueAutoComplete.nmProdutor,
            areaCultivo: talhao.areaCultivo.dsAreaCultivo,
            talhao: talhao.dsTalhao,
            areaTalhao: talhao.nrAreaTalhao,
            talhaoGpsList: talhao.talhaoGpsList,
            nrLatitude: talhao.nrLatitude,
            nrLongitude: talhao.nrLongitude
          });
        }
      });

      var listAll = [];
      var listProdutoresSelect = this.state.listProdutoresSelect;
      if (listProdutoresSelect.length === 0) {
        listAll = listItensMapa;
      } else {
        listAll = this.state.listItensMapa;
        listItensMapa.forEach(doc => {
          listAll.push(doc);
        });
      }

      listProdutoresSelect.push(this.state.valueAutoComplete);

      this.setState({
        listProdutoresSelect: listProdutoresSelect,
        listItensMapa: listAll,
        loading: false,
        valueAutoComplete: '',
        key: Math.random()
      });
    } else {
      this.setState({
        errors: true
      });
    }
  }

  handleDelete(chip) {
    this.setState(
      {
        loading: true
      },
      () => {
        var chipList = this.state.listProdutoresSelect;
        var listItensMapa = this.state.listItensMapa;

        const position = [];
        listItensMapa.forEach(doc => {
          if (doc.idProdutor === chip.idProdutor) {
            position.push(listItensMapa.indexOf(doc));
          }
        });

        listItensMapa.splice(position[0], position.length);

        const positionChip = chipList.indexOf(chip);
        chipList.splice(positionChip, 1);

        if (chipList.length === 0) {
          this.setState({
            chipList: []
          });

          this.findAllProdutores();
        } else {
          this.setState({
            listItensMapa: listItensMapa,
            listProdutoresSelect: chipList,
            loading: false
          });
        }
      }
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Formik
          initialValues={{
            produtor: this.state.valueAutoComplete
          }}
          onSubmit={this.clickSalvar}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            produtor: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
          })}
          render={({ values, handleSubmit, errors, touched }) => (
            <div className={classes.view}>
              {this.props.infoUsuario.tpPerfil !== 3 && (
                <Card
                  withOutBorderRadius={true}
                  className={classes.card}
                  title={provider.intl.formatMessage({ id: 'filtro' })}
                  height='auto'
                  style={{ height: 'auto' }}
                  styleHeader={{ minHeight: 60 }}
                >
                  <div className={classes.gridContainer}>
                    <Grid container spacing={16}>
                      <Grid item xs={2} style={{ height: 80 }}>
                        <Autocomplete
                          itens={this.state.listProdutores}
                          campoOp={'nmProdutor'}
                          campoChave={'idProdutor'}
                          name='produtor'
                          label={provider.intl.formatMessage({
                            id: 'produtor'
                          })}
                          error={(errors.produtor && touched.produtor) || this.state.errors}
                          helperText={
                            (errors.produtor && touched.produtor) || this.state.errors
                              ? this.state.errors
                                ? provider.intl.formatMessage({
                                    id: 'produtorDuplicado'
                                  })
                                : errors.produtor
                              : ''
                          }
                          onChangeOwn={this.onChangeOwn}
                          value={this.tratarValorSelectInitial(this.state.valueAutoComplete)}
                          valueAutoComplete={this.tratarValorSelectInitial(this.state.valueAutoComplete)}
                          valueSelect={
                            this.tratarValorSelectInitial(this.state.valueAutoComplete).nmProdutor
                          }
                          key={this.state.key}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button className={classes.button} id='button' onClick={handleSubmit}>
                          <img src={icone_adicionar} alt=' ' />
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                      <div className={classes.cardChips}>
                        {this.state.listProdutoresSelect.map(chip => {
                          return (
                            <div
                              className={classes.chip}
                              key={this.state.listProdutoresSelect.indexOf(chip)}
                            >
                              <div className={classes.chipContent}>
                                <div className={classes.chipFiltro}>{chip.nmProdutor}</div>
                                <div>
                                  <img
                                    src={iconeClip}
                                    alt=' '
                                    onClick={() => {
                                      this.handleDelete(chip);
                                    }}
                                    className={classes.stylesIcon}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Grid>
                  </div>
                </Card>
              )}
              {this.state.loading && (
                <div
                  style={{
                    height:
                      this.props.infoUsuario.tpPerfil === 3 ? 'calc(100% - 25px)' : 'calc(100% - 230px)'
                  }}
                  className={classes.cardCarregando}
                >
                  <MuiThemeProvider theme={theme}>
                    <CircularProgress />
                  </MuiThemeProvider>
                </div>
              )}
              {!this.state.loading && (
                <Maps
                  showCard={this.props.infoUsuario.tpPerfil !== 3}
                  listItensMapa={this.state.listItensMapa}
                  label={provider.intl.formatMessage({ id: 'area' })}
                />
              )}
            </div>
          )}
        />
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(TelaMapa);
export default withRouter(connect(mapStateToProps)(enhaced));
