import URL from "./URL";

const axios = require("axios");
const api = URL.API;

function findAgendaCardHandler(dtInicio, dtFinal) {
  return axios.get(
    api + "/dashboard/agendas/sintese?inicio=" + dtInicio + "&fim=" + dtFinal
  );
}

function findVisitasAgendadasHandler(dtInicio, dtFinal) {
  return axios.get(
    api + "/dashboard/agendas/agendadas?inicio=" + dtInicio + "&fim=" + dtFinal
  );
}

function findVisitasSemAgendasHandler(dtInicio, dtFinal) {
  return axios.get(
    api +
      "/dashboard/agendas/visitas-nao-agendadas?inicio=" +
      dtInicio +
      "&fim=" +
      dtFinal
  );
}

function findProdutoresVisitasPendentesHandler(dtInicio, dtFinal) {
  return axios.get(
    api +
      "/dashboard/agendas/produtores-pendentes?inicio=" +
      dtInicio +
      "&fim=" +
      dtFinal
  );
}

export default {
  findAgendaCard: findAgendaCardHandler,
  findVisitasAgendadas: findVisitasAgendadasHandler,
  findVisitasSemAgendas: findVisitasSemAgendasHandler,
  findProdutoresVisitasPendentes: findProdutoresVisitasPendentesHandler
};
