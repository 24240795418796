import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllUsuariosHandler() {
  return axios.get(api + '/usuario');
}

function findUsuariosByIdHandler(idUsuario) {
  return axios.get(api + '/usuario/' + idUsuario);
}

function addUsuarioHandler(values) {
  return axios.post(api + '/usuario', values);
}

function editUsuarioHandler(values) {
  return axios.put(api + '/usuario', values);
}

function deleteUsuarioHandler(idUsuario) {
  return axios.delete(api + '/usuario/' + idUsuario);
}

function findAllAtivosHandler() {
  return axios.get(api + '/usuario/ativos');
}

export default {
  findAllUsuarios: findAllUsuariosHandler,
  addUsuario: addUsuarioHandler,
  editusuario: editUsuarioHandler,
  findUsuariosById: findUsuariosByIdHandler,
  deleteUsuario: deleteUsuarioHandler,
  findAllAtivos: findAllAtivosHandler
};
