import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findImagesByTalhoes(talhoes) {
  return axios.post(api + '/ndvi/image/search', talhoes);
}

export default {
  findImagesByTalhoes: findImagesByTalhoes
};
