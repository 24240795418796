export default {
  fungicidaMicroBio: {
    fungicidaMicroBioRemovidoComSucesso: 'Fungicida microbiológico quitado con éxito',
    falhaAoExcluirFungicidaMicroBio: 'Fallo al excluir fungicida microbiológico',
    desejaExcluir: '¿Desea eliminar la fungicida microbiológico seleccionada?',
    identificacaoDaFungicidaMicroBio: 'Identificación',
    fungicidaMicroBioCadastradoComSucesso: 'Fungicida microbiológico registrada con éxito',
    fungicidaMicroBioEditadoComSucesso: 'Fungicida microbiológico editado con éxito',
    falhaAoEditarFungicidaMicroBio: 'Falla al editar fungicida microbiológico',
    falhaAoCadastrarFungicidaMicroBio: 'Error al editar fungicida microbiológico ',
    falhaAoCarregarFungicidaMicroBio: 'Error al cargar fungicida microbiológico',
    fungicidaMicroBio: 'Fungicida microbiológico',
    cadastroDeFungicidaMicroBio: 'Registro de fungicidas microbiológico'
  }
};
