export default {
  acaricidaMicrobio: {
    acaricidaMicroBio: 'Acaricida microbiológico',
    cadastroDeAcaricidaMicroBio: 'Registro de acaricida microbiológico',
    acaricidaMicroBioRemovidoComSucesso: 'Acaricida microbiológico quitado con éxito',
    falhaAoExcluirAcaricidaMicroBio: 'Fallo la excluir acaricida microbiológico',
    desejaExcluir: '¿Desea eliminar el acaricida microbiológico seleccionada?',
    identificacaoDaAcaricidaMicroBio: 'Identificación',
    acaricidaMicroBioCadastradoComSucesso: 'Acaricida microbiológico registrada con éxito',
    acaricidaMicroBioEditadoComSucesso: 'Acaricida microbiológico editado con éxito',
    falhaAoEditarAcaricidaMicroBio: 'Falla al editar acaricida microbiológico',
    falhaAoCadastrarAcaricidaMicroBio: 'Error al editar acaricida microbiológico',
    falhaAoCarregarAcaricidaMicroBio: 'Error al cargar acaricida microbiológico'
  }
};
