import React, { useState, useEffect } from 'react';
import formatMessage from './../i18n/formatMessage';
import { connect } from 'react-redux';
import CardIcon from '@components/CardIcon/CardIcon';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { formatTextField } from './../header';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import EditarObservacao from './EditarObservacao';
import { Add, Edit } from '@material-ui/icons';
import Chip from '@components/Chip/Chip';

/**
 * Grid de cadastro de auditories
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const AuditoriaUsuario = props => {
  const {
    classes,
    usuarioList,
    loadUsuarioList,
    changeHistoricoAuditoriaList,
    dtPlantio,
    isTecnico
  } = props;
  const [index, setIndex] = useState(0);
  const [historicoAuditoriaList, setHistoricoAuditoriaList] = useState([]);
  const [valueToEdit, setValueToEdit] = useState(null);

  useEffect(() => {
    setHistoricoAuditoriaList([...props.historicoAuditoriaList]);
  }, []);

  function addObservacaoInList(value) {
    const historicoAuditoriaListAux = [...historicoAuditoriaList];

    if (value.idHistoricoAuditoria) {
      const historicoAuditoriaAux = historicoAuditoriaListAux.find(
        item => item.idHistoricoAuditoria === value.idHistoricoAuditoria
      );
      const position = historicoAuditoriaListAux.indexOf(historicoAuditoriaAux);
      historicoAuditoriaListAux[position] = value;
    } else {
      value.stRegistro = 0;
      value.idHistoricoAuditoria = Math.random();

      historicoAuditoriaListAux.push(value);
    }

    setHistoricoAuditoriaList(historicoAuditoriaListAux);
    clickToCancel();
    changeHistoricoAuditoriaList(historicoAuditoriaListAux);
  }

  async function uploadImage(dsLink, dsNomeArquivo) {
    const indexNameWithTimestamp = dsLink.indexOf('observacao-anexo-');
    if (indexNameWithTimestamp === -1) {
      return;
    }

    let res = await fetch(dsLink);
    let data = await res.blob();

    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(data);
    anchor.download = dsNomeArquivo;
    anchor.click();
  }

  function clickToCancel() {
    setIndex(0);
    setValueToEdit(null);
  }

  function clickEditar(historicoAuditoria) {
    setValueToEdit(historicoAuditoria);
    setIndex(1);
  }

  function converterName(fileName) {
    const indexNameWithTimestamp = fileName.indexOf('observacao-anexo-');
    if (indexNameWithTimestamp === -1) {
      return fileName;
    }
    const nameWithTimestamp = fileName.slice(indexNameWithTimestamp + 17, fileName.length);
    const indexName = nameWithTimestamp.lastIndexOf('-');
    const indexExtensao = nameWithTimestamp.lastIndexOf('.');
    return (
      nameWithTimestamp.slice(0, indexName) +
      nameWithTimestamp.slice(indexExtensao, nameWithTimestamp.length)
    );
  }

  return (
    <SwipeableViewsOwn index={index} onChangeIndex={indexAux => setIndex(indexAux)}>
      {[
        <div key={0} className={classes.cardScroll}>
          <CardIcon titulo={formatMessage('auditoria.observacoes')}>
            {!isTecnico && (
              <div className={classes.cardOutsideButtom}>
                <div className={classes.cardButtom} style={{ width: 190 }} onClick={() => setIndex(1)}>
                  <Add />
                  {formatMessage('auditoria.adicionarObservacao')}
                </div>
              </div>
            )}

            {historicoAuditoriaList.map(historicoAuditoria => (
              <div
                key={historicoAuditoria.idHistoricoAuditoria}
                className={classes.cardEdit}
              >
                <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                  <Grid item xs={6}>
                    <div className={classes.cardCertificacao}>
                      <div className={classes.labelTitle}>{formatMessage('auditoria.nomeAuditor')}</div>
                      <div className={classes.labelInfo}>{historicoAuditoria.usuario.nmUsuario}</div>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    <div className={classes.cardCertificacao}>
                      <div className={classes.labelTitle}>{formatMessage('auditoria.situacao')}</div>
                      <div className={classes.labelInfo}>
                        {historicoAuditoria.stHistoricoAuditoria === 1
                          ? formatMessage('auditoria.fechado')
                          : formatMessage('auditoria.aberto')}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <div className={classes.cardCertificacao}>
                      <div className={classes.labelTitle}>{formatMessage('auditoria.situacao')}</div>
                      <div className={classes.labelInfo}>
                        {moment(historicoAuditoria.dhHistoricoAuditoria).format(formatTextField())}
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                  <Grid item xs={12}>
                    <div className={classes.cardCertificacao}>
                      <div className={classes.labelTitle}>{formatMessage('auditoria.situacao')}</div>
                      <div className={classes.labelInfo}>{historicoAuditoria.dsHistoricoAuditoria}</div>
                    </div>
                  </Grid>
                </Grid>

                <div className={classes.cardChips}>
                  {historicoAuditoria.anexoAuditoriaList
                    .filter(item => item.stRegistro !== 2)
                    .map((anexoAuditoria, i) => (
                      <div key={anexoAuditoria.idAnexoAuditoria} style={{ cursor: 'pointer' }}>
                        <Chip
                          hiddenClose={true}
                          onClickLabel={() =>
                            uploadImage(anexoAuditoria.dsAnexo, converterName(anexoAuditoria.dsAnexo))
                          }
                          key={i}
                          text={converterName(anexoAuditoria.dsAnexo)}
                        />
                      </div>
                    ))}
                </div>

                {!isTecnico && (
                  <div style={{ marginTop: 30 }}>
                    <div
                      style={{ backgroundColor: '#463766', width: 100 }}
                      className={classes.cardButtom}
                      onClick={() => clickEditar(historicoAuditoria)}
                    >
                      <Edit className={classes.iconEdit} />
                      {formatMessage('auditoria.editar')}
                    </div>
                  </div>
                )}

                {historicoAuditoriaList.length > 1 && <div className={classes.boxShadow} />}
              </div>
            ))}
          </CardIcon>
        </div>,
        <div key={1}>
          <EditarObservacao
            dtPlantio={dtPlantio}
            classes={classes}
            usuarioList={usuarioList}
            loadUsuarioList={loadUsuarioList}
            addObservacaoInList={addObservacaoInList}
            clickToCancel={clickToCancel}
            valueToEdit={valueToEdit}
            historicoAuditoriaList={historicoAuditoriaList}
            converterName={converterName}
            uploadImage={uploadImage}
          />
        </div>
      ]}
    </SwipeableViewsOwn>
  );
};

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(AuditoriaUsuario);
