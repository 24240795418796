import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import EditarFamiliar from './EditarFamiliar';
import moment from 'moment';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import CardTitle from '@components/CardTitle/CardTitle';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = theme => ({
  root: {
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  swipeable: {
    width: '100%',
    height: '100%'
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 80px)'
  }
});

function formatTextField() {
  switch (getUserLang()) {
    case 'pt-BR':
      return 'DD/MM/YYYY';
    case 'es-ES':
      return 'DD-MM-YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}

/**
 * Componente para listagem da tab Familiares
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
class Familiares extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const nome = messagesLinguage['produtores.nome'];
    const sexo = messagesLinguage['produtores.sexo'];
    const dataNascimento = messagesLinguage['produtores.dataNascimento'];
    const escolaridade = messagesLinguage['produtores.escolaridade'];
    const masculino = messagesLinguage['produtores.masculino'];
    const feminino = messagesLinguage['produtores.feminino'];
    const familiares = messagesLinguage['produtores.familiares'];
    const dataCadastro = messagesLinguage['produtores.dataCadastro'];
    const idade = messagesLinguage['produtores.idade'];
    const fundamentalIncompleto = messagesLinguage['produtores.fundamentalIncompleto'];
    const fundamentalCompleto = messagesLinguage['produtores.fundamentalCompleto'];
    const medioIncompleto = messagesLinguage['produtores.medioIncompleto'];
    const medioCompleto = messagesLinguage['produtores.medioCompleto'];
    const superiorIncompleto = messagesLinguage['produtores.superiorIncompleto'];
    const superiorCompleto = messagesLinguage['produtores.superiorCompleto'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          idade,
          nome,
          sexo,
          dataNascimento,
          escolaridade,
          masculino,
          feminino,
          familiares,
          dataCadastro,
          fundamentalIncompleto,
          fundamentalCompleto,
          medioIncompleto,
          medioCompleto,
          superiorIncompleto,
          superiorCompleto
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.headDatatable = [
      {
        field: 'nmFamiliar',
        headerName: provider.intl.formatMessage({ id: 'nome' }),
        col: 3
      },
      {
        valueGetter: args =>
          [
            provider.intl.formatMessage({ id: 'feminino' }),
            provider.intl.formatMessage({ id: 'masculino' })
          ][args.node.data.tpSexo],
        headerName: provider.intl.formatMessage({ id: 'sexo' }),
        col: 1
      },
      {
        valueGetter: args => moment(args.node.data.dtNascimento).format(formatTextField()),
        headerName: provider.intl.formatMessage({ id: 'dataNascimento' }),
        col: 2
      },
      {
        valueGetter: args => moment().diff(moment(args.node.data.dtNascimento), 'years'),
        headerName: provider.intl.formatMessage({ id: 'idade' }),
        col: 1
      },
      {
        valueGetter: args =>
          [
            provider.intl.formatMessage({ id: 'fundamentalIncompleto' }),
            provider.intl.formatMessage({ id: 'fundamentalCompleto' }),
            provider.intl.formatMessage({ id: 'medioIncompleto' }),
            provider.intl.formatMessage({ id: 'medioCompleto' }),
            provider.intl.formatMessage({ id: 'superiorIncompleto' }),
            provider.intl.formatMessage({ id: 'superiorCompleto' })
          ][args.node.data.tpEscolaridade], // TODO: futuramente adicionar intercionalização quando tiver as opções definidas
        headerName: provider.intl.formatMessage({ id: 'escolaridade' }),
        col: 3
      },
      {
        valueGetter: args => moment(args.node.data.dtCadastro).format(formatTextField()),
        headerName: provider.intl.formatMessage({ id: 'dataCadastro' }),
        col: 2
      }
    ];

    this.state = {
      disabledButtons: true,
      itemSelect: '',
      atualizando: false,
      data: this.props.data.filter(item => item.stRegistro !== 2),
      keyGrid: '',
      index: 0
    };

    this.onNew = this.onNew.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.updateList = this.updateList.bind(this);
  }

  /**
   * Mantem a lista do componente pai e do filho sincronizadas
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.state.data)) {
      this.setState({
        data: nextProps.data.filter(item => item.stRegistro !== 2)
      });
    }
  }

  componentWillUnmount() {
    if (this.state.index === 1) {
      this.props.changeFAB();
    }
  }

  /**
   * Executa quando é clickado numa linha da lista
   *
   * @param {Object} selected - Linha selecionada
   */
  selectChange(selected) {
    if (this.state.atualizando) return;

    if (selected) {
      this.setState({ itemSelect: selected, disabledButtons: false });
    } else {
      this.setState({ itemSelect: '', disabledButtons: true });
    }
  }

  /**
   * Executa ao adicionar um novo Familiar
   *
   * @param {Object} values - Objeto Familiar novo
   */
  onNew(values) {
    this.setState({ atualizando: false, index: 0 }, () => {
      this.props.onNew(values);
      this.selectChange('');
      this.props.changeFAB();
      this.setState({
        keyGrid: Math.random()
      });
    });
  }

  /**
   * Executa ao atualizar um Familiar
   *
   * @param {Object} values - Objeto Familiar atualizado
   */
  onUpdate(values) {
    var data = this.state.data;

    const index = data.indexOf(this.state.itemSelect);
    data[index] = values;
    data = [...data];

    this.setState({ atualizando: false, index: 0, data: data }, () => {
      this.props.onUpdate(data);
      this.selectChange('');
      this.setState({
        keyGrid: Math.random()
      });
    });
  }

  updateList() {
    this.setState({
      data: this.props.data.filter(item => item.stRegistro !== 2)
    });
  }

  render() {
    const { classes, tpPerfil, onDelete } = this.props;

    return (
      <div className={classes.root}>
        <SwipeableViewsOwn index={this.state.index} onChangeIndex={index => this.setState({ index })}>
          {[
            <div className={classes.tab} key={0}>
              <CardTitle
                containerStyle={{ marginBottom: tpPerfil ? 0 : 'none' }}
                title={provider.intl.formatMessage({ id: 'familiares' })}
              />

              {!tpPerfil && (
                <GroupButtonGrid
                  hiddenMargin
                  withoutMargin
                  showEdit
                  showDelete
                  showAdd
                  disableEdit={this.state.disabledButtons || this.state.atualizando}
                  disableDelete={this.state.disabledButtons || this.state.atualizando}
                  onClickEdit={() =>
                    this.setState({ atualizando: true, index: 1 }, this.props.changeFAB)
                  }
                  onClickDelete={() => {
                    this.state.itemSelect && onDelete(this.state.itemSelect, this.updateList);
                  }}
                  onClickAdd={() => this.setState({ index: 1 }, this.props.changeFAB)}
                />
              )}

              <DataTable
                key={this.state.keyGrid}
                data={this.state.data}
                borderRadius={false}
                columns={this.headDatatable}
                selectChange={this.selectChange}
                showPagination={false}
              />
            </div>,

            <EditarFamiliar
              key={1}
              familiar={this.state.atualizando ? this.state.itemSelect : ''}
              onNew={this.onNew}
              onUpdate={this.onUpdate}
              onCancel={() =>
                this.setState(
                  { atualizando: false, keyGrid: Math.random(), index: 0 },
                  () => this.selectChange('') || this.props.changeFAB()
                )
              }
              atualizando={this.state.atualizando}
            />
          ]}
        </SwipeableViewsOwn>
      </div>
    );
  }
}

export default withStyles(styles)(Familiares);
