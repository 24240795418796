export default {
    recuperarSenha: {
        email: 'Email',
        codigo: 'Código',
        senha: 'Senha',
        confirmacaoSenha: 'Confirmação de Senha',
        voltarLogin: 'Voltar para o Login',
        confirmar: 'CONFIRMAR',
        campoObrigatorio: 'Campo obrigatório',
        emailInvalido: 'Email inválido',
        senhaMinimo: 'A senha deve ter no mínimo 6 caracteres',
        falhaRecuperarSenha: "Falha ao recuperar senha",
        senhaTrocada: 'Senha Já trocada',
        codigoExpirado: 'Código expirado',
        codigoInvalido: 'Código inválido',
        usuarioNaoEncontrado: "Usuário não encontrado",
        falhaConectar: 'Não foi possível trocar a senha, tente novamente',
        sucesso: 'Sucesso',
        reencaminhar: 'Você será reencaminhada para realizar seu login'
    }
}