//Autor: Kátia miglioli
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { flattenMessages } from "@utils/languageUtils";
import { IntlProvider } from "react-intl";
import { getUserLang } from "@utils/localeUtils";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import ButtonFABMenu from "@components/ButtonFABMenu/ButtonFABMenu";
import ptBR from "./i18n/ptBR";
import enUS from "./i18n/enUS";
import esES from "./i18n/esES";
import CardIcon from "@components/CardIcon/CardIcon";
import TextField from "@components/TextFieldOwn/TextFieldOwn";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import MoreVert from "@material-ui/icons/MoreVert";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "@sweetalert/with-react";
import Grid from "@material-ui/core/Grid";
import Authentication from "@resources/oauth/Authentication";
import Admin from "@resources/oauth/Admin";

let messages = { "pt-BR": ptBR, "en-US": enUS, "es-ES": esES };
let { provider } = new IntlProvider({});

const style = () => ({
  fab: {
    position: "fixed",
    right: "30px",
    bottom: "210px",
    zIndex: "2000"
  },
  card: {
    height: 70,
    width: "100%",
    marginTop: "30px"
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#00622B",
      main: "#00622B",
      dark: "#00622B",
      contrastText: "#fff"
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiCardHeader: {
      title: {
        fontSize: 18,
        fontWeight: 500,
        color: "rgb(0,0,0, 0.6)"
      }
    },
    MuiCardContent: {
      root: {
        paddingTop: "0px"
      }
    }
  }
});

class Perfil extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const atualizando = messagesLinguage["perfil.atualizando"];
    const salvar = messagesLinguage["perfil.salvar"];
    const erroAoEditarPerfil = messagesLinguage["perfil.erroAoEditarPerfil"];
    const perfilEditadoComSucesso =
      messagesLinguage["perfil.perfilEditadoComSucesso"];
    const problemaInesperado = messagesLinguage["perfil.problemaInesperado"];
    const identificacao = messagesLinguage["perfil.identificacao"];
    const nome = messagesLinguage["perfil.nome"];
    const tipoDePerfil = messagesLinguage["perfil.tipoDePerfil"];
    const email = messagesLinguage["perfil.email"];
    const situacaoDaConta = messagesLinguage["perfil.situacaoDaConta"];
    const ativo = messagesLinguage["perfil.ativo"];
    const inativo = messagesLinguage["perfil.inativo"];
    const administrador = messagesLinguage["perfil.administrador"];
    const revenda = messagesLinguage["perfil.revenda"];
    const tecnico = messagesLinguage["perfil.tecnico"];
    const produtor = messagesLinguage["perfil.produtor"];
    const campoObrigatorio = messagesLinguage["perfil.campoObrigatorio"];
    const cancelar = messagesLinguage["perfil.cancelar"];
    const trocarSenha = messagesLinguage["perfil.trocarSenha"];
    const confNovaSenha = messagesLinguage["perfil.confNovaSenha"];
    const novaSenha = messagesLinguage["perfil.novaSenha"];
    const senhaAtual = messagesLinguage["perfil.senhaAtual"];
    const senhaIncorreta = messagesLinguage["perfil.senhaIncorreta"];
    const gerente = messagesLinguage["perfil.gerente"];
    const representante = messagesLinguage["perfil.representante"];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          atualizando,
          salvar,
          erroAoEditarPerfil,
          perfilEditadoComSucesso,
          problemaInesperado,
          identificacao,
          nome,
          tipoDePerfil,
          email,
          situacaoDaConta,
          inativo,
          ativo,
          administrador,
          revenda,
          tecnico,
          produtor,
          campoObrigatorio,
          cancelar,
          trocarSenha,
          confNovaSenha,
          novaSenha,
          senhaAtual,
          senhaIncorreta,
          gerente,
          representante
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      infoExtra: [],
      validarSenha: false
    };

    const title = "perfil.perfilDoUsuario";
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: "UPDATE_TOOLBAR",
        toolbar: (
          <IntlProvider
            locale={getUserLang()}
            messages={flattenMessages(messages[getUserLang()])}
          >
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.editarPerfil = this.editarPerfil.bind(this);
    this.cancelarEdicao = this.cancelarEdicao.bind(this);
  }

  editarPerfil(values) {
    let { infoUsuario } = this.props;

    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: provider.intl.formatMessage({ id: "atualizando" })
      }
    );

    const data = {
      nmUsuario: values.nmUsuario,
      stUsuario: infoUsuario.stUsuario,
      idUsuario: infoUsuario.idUsuario
    };

    Admin.editUsuarios(data)
      .then(() => {
        const infoUsuarioGeral = {
          dsEmail: infoUsuario.dsEmail,
          idCliente: infoUsuario.idCliente,
          idUsuario: infoUsuario.idUsuario,
          nmUsuario: values.nmUsuario,
          stContaStatus: infoUsuario.stContaStatus,
          stUsuario: infoUsuario.stUsuario,
          tpPerfil: infoUsuario.tpPerfil,
          idPais: infoUsuario.idPais
        };
        this.props.dispatch({
          type: "ADD_INFOUSUARIO",
          info: infoUsuarioGeral
        });
        if (this.state.validarSenha) {
          const changeSenha = {
            dsNovaSenha: values.novaSenha,
            dsSenhaAntiga: values.senha
          };

          Authentication.alterarSenha(changeSenha)
            .then(query => {
              this.mensagemPerfilEditadoComSucesso();
            })
            .catch(err => {
              if (
                err.response !== undefined &&
                err.response.data.codigo === "SENHA_INCORRETA"
              ) {
                swal(provider.intl.formatMessage({ id: "senhaIncorreta" }), {
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "Ok",
                      value: true,
                      visible: true,
                      closeModal: true,
                      className: "swal2-error"
                    }
                  }
                });
              } else {
                this.mensagemErroAoEditarPerfil();
              }
            });
        } else {
          this.mensagemPerfilEditadoComSucesso();
        }
      })
      .catch(err => {
        this.mensagemErroAoEditarPerfil();
      });
  }

  mensagemPerfilEditadoComSucesso() {
    swal(provider.intl.formatMessage({ id: "perfilEditadoComSucesso" }), {
      icon: "success",
      buttons: {
        confirm: {
          text: "Ok",
          value: true,
          visible: true,
          closeModal: true,
          className: "swal2-Ok"
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  mensagemErroAoEditarPerfil() {
    swal(provider.intl.formatMessage({ id: "erroAoEditarPerfil" }), {
      icon: "error",
      buttons: {
        confirm: {
          text: "Ok",
          value: true,
          visible: true,
          closeModal: true,
          className: "swal2-error"
        }
      }
    });
  }

  mensagemProblemaInesperado() {
    swal(provider.intl.formatMessage({ id: "problemaInesperado" }), {
      icon: "error",
      buttons: {
        confirm: {
          text: "Ok",
          value: true,
          visible: true,
          closeModal: true,
          className: "swal2-error"
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  cancelarEdicao() {
    this.props.history.push("/app");
  }

  tratarSituacao(situacao) {
    switch (parseInt(situacao)) {
      case 0:
        return provider.intl.formatMessage({ id: "inativo" });
      case 1:
        return provider.intl.formatMessage({ id: "ativo" });
      default:
        break;
    }
  }

  tratarPerfil(perfil) {
    switch (parseInt(perfil)) {
      case 0:
        return provider.intl.formatMessage({ id: "representante" });
      case 1:
        return provider.intl.formatMessage({ id: "gerente" });
      case 2:
        return provider.intl.formatMessage({ id: "tecnico" });
      case 3:
        return provider.intl.formatMessage({ id: "produtor" });
      default:
        break;
    }
  }

  render() {
    const { classes, infoUsuario } = this.props;

    return (
      <IntlProvider
        locale={getUserLang()}
        messages={flattenMessages(messages[getUserLang()])}
      >
        <div style={{ width: "100%", marginTop: 15 }}>
          <Formik
            initialValues={{
              nmUsuario: infoUsuario.nmUsuario,
              dsEmail: infoUsuario.dsEmail,
              stUsuario: this.tratarSituacao(infoUsuario.stUsuario),
              tpPerfil: this.tratarPerfil(infoUsuario.tpPerfil),
              senha: "",
              novaSenha: "",
              confirmacaoSenha: ""
            }}
            onSubmit={this.editarPerfil}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              nmUsuario: Yup.string().required(
                <FormattedMessage id="perfil.campoObrigatorio" />
              ),
              senha: this.state.validarSenha
                ? Yup.string().required(
                    <FormattedMessage id="perfil.campoObrigatorio" />
                  )
                : Yup.string(),
              novaSenha: this.state.validarSenha
                ? Yup.string()
                    .min(6, <FormattedMessage id={"perfil.senhaMinino"} />)
                    .required(<FormattedMessage id="perfil.campoObrigatorio" />)
                : Yup.string(),
              confirmacaoSenha: this.state.validarSenha
                ? Yup.string()
                    .min(6, <FormattedMessage id={"perfil.senhaMinino"} />)
                    .oneOf(
                      [Yup.ref("novaSenha")],
                      <FormattedMessage id={"perfil.senhaIncompativel"} />
                    )
                    .required(<FormattedMessage id="perfil.campoObrigatorio" />)
                : Yup.string()
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue
            }) => (
              <MuiThemeProvider theme={theme}>
                <CardIcon
                  titulo={provider.intl.formatMessage({ id: "identificacao" })}
                >
                  <form autoComplete="off">
                    <div className={classes.card}>
                      <Grid container spacing={16}>
                        <Grid item xs={3}>
                          <TextField
                            id="tpPerfil"
                            label={provider.intl.formatMessage({
                              id: "tipoDePerfil"
                            })}
                            value={values.tpPerfil}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="nmUsuario"
                            label={provider.intl.formatMessage({ id: "nome" })}
                            value={values.nmUsuario}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.nmUsuario && touched.nmUsuario}
                            helperText={
                              errors.nmUsuario &&
                              touched.nmUsuario &&
                              provider.intl.formatMessage({
                                id: "campoObrigatorio"
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="dsEmail"
                            label={provider.intl.formatMessage({ id: "email" })}
                            value={values.dsEmail}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="stUsuario"
                            label={provider.intl.formatMessage({
                              id: "situacaoDaConta"
                            })}
                            value={values.stUsuario}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                </CardIcon>

                <div className={classes.fab}>
                  <ButtonFABMenu
                    icon={<MoreVert />}
                    actions={[
                      {
                        icon: <Check style={{ color: "white" }} />,
                        name: provider.intl.formatMessage({ id: "salvar" }),
                        onClickIcon: handleSubmit,
                        color: "#42ADE8"
                      },
                      {
                        icon: <Close style={{ color: "white" }} />,
                        name: provider.intl.formatMessage({ id: "cancelar" }),
                        onClickIcon: this.cancelarEdicao,
                        color: "#F33A30"
                      }
                    ]}
                  />
                </div>

                <div style={{ marginTop: 15 }}>
                  <CardIcon
                    titulo={provider.intl.formatMessage({ id: "trocarSenha" })}
                  >
                    <form autoComplete="off">
                      <div className={classes.card}>
                        <Grid container spacing={16}>
                          <Grid item xs={3}>
                            <TextField
                              id="senha"
                              type={"password"}
                              label={provider.intl.formatMessage({
                                id: "senhaAtual"
                              })}
                              onChange={value => {
                                if (value.target.value !== "") {
                                  this.setState({
                                    validarSenha: true
                                  });
                                } else {
                                  this.setState({
                                    validarSenha: false
                                  });
                                }

                                setFieldValue("senha", value.target.value);
                              }}
                              error={errors.senha && touched.senha}
                              helperText={
                                errors.senha &&
                                touched.senha &&
                                provider.intl.formatMessage({
                                  id: "campoObrigatorio"
                                })
                              }
                              value={values.senha}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <TextField
                              id="novaSenha"
                              type={"password"}
                              label={provider.intl.formatMessage({
                                id: "novaSenha"
                              })}
                              error={errors.novaSenha && touched.novaSenha}
                              helperText={
                                errors.novaSenha && touched.novaSenha
                                  ? errors.novaSenha
                                  : ""
                              }
                              value={values.novaSenha}
                              onChange={value => {
                                if (value.target.value !== "") {
                                  this.setState({
                                    validarSenha: true
                                  });
                                } else {
                                  this.setState({
                                    validarSenha: false
                                  });
                                }

                                setFieldValue("novaSenha", value.target.value);
                              }}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <TextField
                              id="confirmacaoSenha"
                              type={"password"}
                              label={provider.intl.formatMessage({
                                id: "confNovaSenha"
                              })}
                              error={
                                errors.confirmacaoSenha &&
                                touched.confirmacaoSenha
                              }
                              helperText={
                                errors.confirmacaoSenha &&
                                touched.confirmacaoSenha
                                  ? errors.confirmacaoSenha
                                  : ""
                              }
                              value={values.confirmacaoSenha}
                              onChange={value => {
                                if (value.target.value !== "") {
                                  this.setState({
                                    validarSenha: true
                                  });
                                } else {
                                  this.setState({
                                    validarSenha: false
                                  });
                                }

                                setFieldValue(
                                  "confirmacaoSenha",
                                  value.target.value
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </CardIcon>
                </div>
              </MuiThemeProvider>
            )}
          />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info,
  itensState: state.toolbar
});

let enhaced = withStyles(style)(Perfil);
export default withRouter(connect(mapStateToProps)(enhaced));
