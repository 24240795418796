import React, { Component } from 'react';
import enUS from './i18n/enUS';
import ptBR from './i18n/ptBR';
import esES from './i18n/esES';
import { IntlProvider } from 'react-intl';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import CardIcon from '@components/CardIcon/CardIcon';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import DatePicker from '@components/DatePicker/DatePicker';
import _ from 'lodash';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import SwipeableViews from 'react-swipeable-views';
import DataTable from '@components/DataTable/DataTable';
import * as moment from 'moment';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import AcompanhamentoTecnicos from '@resources/AcompanhamentoTecnico';
import Tecnico from '@resources/Usuario';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import Visita from './../../Visita/Visita';
import ContainerVisitas from '@components/ContainerVisitas/ContainerVisitas';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
var { provider } = new IntlProvider({});

const style = {
  textField: {
    width: '100%',
    height: 70
  },
  button: {
    height: 24,
    width: 24,
    marginTop: 10
  },
  iconButton: {
    width: 15
  },
  font: {
    fontSize: 14,
    width: '100%',
    height: 70,
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  buttonConsultar: {
    color: '#00622B',
    fontWeight: '400 !important',
    fontSize: 14,
    height: 36
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 70,
    justifyContent: 'flex-end'
  },
  card: {
    marginTop: 15
  },
  visitas: {
    height: 110,
    justifyContent: 'center',
    borderRadius: 4,
    display: 'block',
    fontSize: 20
  },
  cardVisitasPendentes: {
    minHeight: 350,
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    height: '100%',
    marginBottom: 15
  },
  labelVisitas: {
    width: '100%',
    height: '50%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 400
  },
  collapse: {
    minHeight: '185px !important'
  }
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '1px solid green'
        }
      }
    }
  }
});

class AcompanhamentoTecnico extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const visitasTecnico = messagesLinguage['acompanhamento.visitasTecnico'];
    const dataInicial = messagesLinguage['acompanhamento.dataInicial'];
    const dataFinal = messagesLinguage['acompanhamento.dataFinal'];
    const tecnico = messagesLinguage['acompanhamento.tecnico'];
    const filtros = messagesLinguage['acompanhamento.filtros'];
    const campoObrigatorio = messagesLinguage['acompanhamento.campoObrigatorio'];
    const consultar = messagesLinguage['acompanhamento.consultar'];
    const visitasPendentes = messagesLinguage['acompanhamento.visitasPendentes'];
    const visitasAgendadas = messagesLinguage['acompanhamento.visitasAgendadas'];
    const visitasRealizadas = messagesLinguage['acompanhamento.visitasRealizadas'];
    const ultimaVisita = messagesLinguage['acompanhamento.ultimaVisita'];
    const produtor = messagesLinguage['acompanhamento.produtor'];
    const falhaAoPesquisarVisita = messagesLinguage['acompanhamento.falhaAoPesquisarVisita'];
    const falhaAoCarregar = messagesLinguage['acompanhamento.falhaAoCarregar'];
    const dataMinima = messagesLinguage['acompanhamento.dataMinima'];
    const pendentes = messagesLinguage['acompanhamento.pendentes'];
    const agendadas = messagesLinguage['acompanhamento.agendadas'];
    const realizadas = messagesLinguage['acompanhamento.realizadas'];
    const horaFinal = messagesLinguage['acompanhamento.horaFinal'];
    const horaInicio = messagesLinguage['acompanhamento.horaInicio'];
    const data = messagesLinguage['acompanhamento.data'];
    const dadosAgenda = messagesLinguage['acompanhamento.dadosAgenda'];
    const dadosVisita = messagesLinguage['acompanhamento.dadosVisita'];
    const semVisitas = messagesLinguage['acompanhamento.semVisitas'];
    const a = messagesLinguage['acompanhamento.a'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          visitasTecnico,
          dataInicial,
          dataFinal,
          tecnico,
          filtros,
          campoObrigatorio,
          consultar,
          visitasPendentes,
          visitasAgendadas,
          visitasRealizadas,
          ultimaVisita,
          produtor,
          falhaAoPesquisarVisita,
          falhaAoCarregar,
          dataMinima,
          pendentes,
          agendadas,
          realizadas,
          horaFinal,
          horaInicio,
          data,
          dadosAgenda,
          dadosVisita,
          semVisitas,
          a
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    const dtFinal = new Date();
    dtFinal.setDate(dtFinal.getDate() - dtFinal.getDay());
    var today = new Date();
    today.setDate(dtFinal.getDate() + 6);

    this.state = {
      expanded: true,
      itemSelect: {},
      dataAgendadas: [],
      dataRealizadas: [],
      indexSwipeable: 0,
      tabIndex: 0,
      tecnicos: [],
      dataValues: { dtInicial: dtFinal, dtFinal: today },
      autoComplete: null,
      key: '',
      keyData: '',
      dataVisitas: [],
      dataVisitasRevenda: [],
      loading: true,
      itensDataVisitasWithTecnico: [
        {
          field: 'tecnico.nmUsuario',
          headerName: provider.intl.formatMessage({ id: 'tecnico' }),
          col: 4
        },
        {
          field: 'produtor.nmProdutor',
          headerName: provider.intl.formatMessage({ id: 'produtor' }),
          col: 2
        },
        {
          valueGetter: args => moment(args.node.data.dhInicioVisita).format(this.formatGridData()),
          headerName: provider.intl.formatMessage({ id: 'data' }),
          col: 2
        },
        {
          valueGetter: args => moment(args.node.data.dhInicioVisita).format(this.formatGridHora()),
          headerName: provider.intl.formatMessage({ id: 'horaInicio' }),
          col: 2
        },
        {
          valueGetter: args => moment(args.node.data.dhTerminoVisita).format(this.formatGridHora()),
          headerName: provider.intl.formatMessage({ id: 'horaFinal' }),
          col: 2
        }
      ],
      itensDataVisitas: [
        {
          field: 'produtor.nmProdutor',
          headerName: provider.intl.formatMessage({ id: 'produtor' }),
          col: 3
        },
        {
          valueGetter: args => moment(args.node.data.dhInicioVisita).format(this.formatGridData()),
          headerName: provider.intl.formatMessage({ id: 'data' }),
          col: 3
        },
        {
          valueGetter: args => moment(args.node.data.dhInicioVisita).format(this.formatGridHora()),
          headerName: provider.intl.formatMessage({ id: 'horaInicio' }),
          col: 3
        },
        {
          valueGetter: args => moment(args.node.data.dhTerminoVisita).format(this.formatGridHora()),
          headerName: provider.intl.formatMessage({ id: 'horaFinal' }),
          col: 3
        }
      ],
      itensVisitasWithTecnico: [
        {
          field: 'tecnico.nmUsuario',
          headerName: provider.intl.formatMessage({ id: 'tecnico' }),
          col: 4
        },
        {
          field: 'produtor.nmProdutor',
          headerName: provider.intl.formatMessage({ id: 'produtor' }),
          col: 4
        },
        {
          field: 'dtUltimaVisita',
          headerName: provider.intl.formatMessage({ id: 'ultimaVisita' }),
          col: 4
        }
      ],
      itensVisitas: [
        {
          field: 'produtor.nmProdutor',
          headerName: provider.intl.formatMessage({ id: 'produtor' }),
          col: 4
        },
        {
          field: 'dtUltimaVisita',
          headerName: provider.intl.formatMessage({ id: 'ultimaVisita' }),
          col: 8
        }
      ],
      vlVisitasAgendadas: 0,
      vlVisitasRealizadas: 0,
      vlVisitasPendentes: 0,
      dtInicial: dtFinal,
      dtFinal: today
    };

    this.valueAutoComplete = '';

    const title = provider.intl.formatMessage({ id: 'visitasTecnico' });
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: title,
        name: title
      });
    }

    this.clickPesquisar = this.clickPesquisar.bind(this);
    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.validarInicializacaoAutoComplete = _.debounce(
      this.validarInicializacaoAutoComplete.bind(this),
      200
    );
    this.pesquisarVisitas = this.pesquisarVisitas.bind(this);
    this.buscarVisitasPendentes = this.buscarVisitasPendentes.bind(this);
    this.formatTextField = this.formatTextField.bind(this);
    this.formatGridData = this.formatGridData.bind(this);
    this.formatGridHora = this.formatGridHora.bind(this);
  }

  componentDidMount() {
    this.validarInicializacaoAutoComplete();

    this.buscarVisitasPendentes();

    this.pesquisarVisitas(
      moment(this.state.dtInicial).format('YYYY-MM-DD'),
      moment(this.state.dtFinal).format('YYYY-MM-DD'),
      false,
      this.props.infoUsuario.idUsuario,
      true
    );
  }

  formatGridData() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  formatGridHora() {
    return 'HH:mm';
  }

  validarInicializacaoAutoComplete() {
    Tecnico.findAllUsuarios().then(query => {
      const data = [];

      query.data.forEach(doc => {
        if (doc.tpPerfil === 2) {
          data.push(doc);
        }
      });

      this.setState({
        tecnicos: data
      });
    });
  }

  buscarVisitasPendentes() {
    AcompanhamentoTecnicos.findVisitasPendentes()
      .then(resultado => {
        let data = [];
        if (this.props.infoUsuario.tpPerfil === 2) {
          resultado.data.forEach(doc => {
            if (doc.tecnico.dsEmail === this.props.infoUsuario.dsEmail) {
              data.push(doc);
            }
          });
        } else {
          data = resultado.data;
        }

        data.forEach(doc => {
          if (doc.dhUltimaVisita !== undefined) {
            doc.dtUltimaVisita = moment(doc.dhUltimaVisita).format('DD/MM/YYYY');
          } else {
            doc.dtUltimaVisita = '-';
          }
        });

        this.setState({
          vlVisitasPendentes: data.length,
          dataVisitas: data,
          dataVisitasRevenda: data,
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          vlVisitasPendentes: 0,
          dataVisitas: [],
          dataVisitasRevenda: [],
          loading: false
        });
      });
  }

  pesquisarVisitas(dtInicial, dtFinal, validacao, idUsuario, didMount) {
    var ref = '';
    if (validacao) {
      ref = AcompanhamentoTecnicos.findVisitasPendentesByUsuario(dtInicial, dtFinal, idUsuario);
    } else {
      ref = AcompanhamentoTecnicos.findVisitas(dtInicial, dtFinal);
    }

    ref
      .then(query => {
        const visitasAgendadasList = query.data.visitasAgendadasList.map(doc => {
          doc.dtVisita = moment(doc.dhInicioVisita).format('YYYY-MM-DD');
          doc.hrInicio = moment(doc.dhInicioVisita).format('HH:mm:ss');
          doc.hrTermino = moment(doc.dhTerminoVisita).format('HH:mm:ss');
          doc.observacao = doc.dsObservacao;
          return doc;
        });

        const visitasRealizadasList = query.data.visitasRealizadasList.map(doc => {
          doc.dtVisita = moment(doc.dhInicioVisita).format('YYYY-MM-DD');
          doc.hrInicio = moment(doc.dhInicioVisita).format('HH:mm:ss');
          doc.hrTermino = moment(doc.dhTerminoVisita).format('HH:mm:ss');
          doc.observacao = doc.dsObservacao;
          return doc;
        });

        this.setState({
          loading: didMount && this.state.loading,
          dataAgendadas: visitasAgendadasList,
          dataRealizadas: visitasRealizadasList,
          vlVisitasAgendadas: query.data.visitasAgendadasList.length,
          vlVisitasRealizadas: query.data.visitasRealizadasList.length
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          dataRealizadas: [],
          dataAgendadas: [],
          vlVisitasAgendadas: 0,
          vlVisitasRealizadas: 0
        });

        swal(provider.intl.formatMessage({ id: 'falhaAoCarregar' }), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      });
  }

  clickPesquisar(values) {
    this.setState({
      loading: true,
      keyData: Math.random(),
      autoComplete: this.valueAutoComplete,
      dataValues: { dtInicial: values.dtInicial, dtFinal: values.dtFinal },
      dataAgendadas: [],
      dataRealizadas: [],
      vlVisitasAgendadas: 0,
      vlVisitasRealizadas: 0
    });

    if (this.valueAutoComplete === null) {
      this.setState({
        keyData: Math.random(),
        dataVisitas: this.state.dataVisitasRevenda,
        vlVisitasPendentes: this.state.dataVisitasRevenda.length
      });
    } else if (this.valueAutoComplete !== '') {
      var dataVisita = [];

      this.state.dataVisitasRevenda.forEach(dados => {
        if (dados.tecnico.idUsuario === this.valueAutoComplete.idUsuario) {
          dataVisita.push(dados);
        }
      });

      this.setState({
        keyData: Math.random(),
        vlVisitasPendentes: dataVisita.length,
        dataVisitas: dataVisita
      });
    }

    this.pesquisarVisitas(
      moment(values.dtInicial).format('YYYY-MM-DD'),
      moment(values.dtFinal).format('YYYY-MM-DD'),
      this.valueAutoComplete !== '' && this.valueAutoComplete !== null,
      this.valueAutoComplete !== null
        ? this.valueAutoComplete.idUsuario
        : this.props.infoUsuario.idUsuario
    );
  }

  tratarValorSelectInitial(data) {
    if (!data || data.nmUsuario === undefined) {
      return '';
    }

    return data;
  }

  formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  render() {
    const { classes } = this.props;
    const tecnico =
      this.state.autoComplete !== null && this.state.autoComplete !== ''
        ? provider.intl.formatMessage({ id: 'tecnico' }) + ': ' + this.state.autoComplete.nmUsuario
        : '';

    return (
      <SwipeableViews
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginBottom: 15
        }}
        containerStyle={{ height: '100%' }}
        index={this.state.indexSwipeable}
        onChangeIndex={index => {
          this.setState({
            indexSwipeable: index
          });
        }}
        slideStyle={{ display: 'flex', overflow: 'hidden' }}
        ignoreNativeScroll={false}
      >
        {[
          <div
            key={0}
            style={{
              width: '100%',
              overflowX: 'hidden',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Formik
              initialValues={{
                dtInicial: this.state.dtInicial,
                dtFinal: this.state.dtFinal,
                tecnico: ''
              }}
              onSubmit={this.clickPesquisar}
              validateOnBlur
              enableReinitialize={true}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                dtInicial: Yup.string().required(
                  provider.intl.formatMessage({ id: 'campoObrigatorio' })
                ),
                dtFinal: Yup.string()
                  .test('', provider.intl.formatMessage({ id: 'dataMinima' }), value => {
                    return (
                      moment(this.state.dtInicial).format('DD/MM/YYYY') ===
                        moment(this.state.dtFinal).format('DD/MM/YYYY') ||
                      moment(this.state.dtInicial) < moment(this.state.dtFinal)
                    );
                  })
                  .required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
              })}
              render={({
                values,
                handleSubmit,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                handleChange
              }) => (
                <MuiThemeProvider theme={theme}>
                  <form autoComplete='off' style={{ width: '100%' }} onSubmit={handleSubmit}>
                    {this.state.loading && <Loading />}
                    <div style={{ width: '100%' }}>
                      <CardIcon
                        onClickExpanded={expanded => {
                          this.setState({
                            expanded: expanded
                          });
                        }}
                        stateCardblock
                        filtros
                        header={
                          provider.intl.formatMessage({
                            id: 'filtros'
                          }) +
                          ': ' +
                          moment(this.state.dataValues.dtInicial).format(this.formatGridData()) +
                          ' ' +
                          provider.intl.formatMessage({ id: 'a' }) +
                          ' ' +
                          moment(this.state.dataValues.dtFinal).format(this.formatGridData()) +
                          '; ' +
                          tecnico
                        }
                        titulo={provider.intl.formatMessage({
                          id: 'filtros'
                        })}
                      >
                        <Grid container spacing={16} style={{ marginTop: '15px' }}>
                          <Grid item xs={2} style={{ minHeight: '75px' }}>
                            <DatePicker
                              locale={getUserLang()}
                              style={{ height: 70 }}
                              className={classes.font}
                              label={provider.intl.formatMessage({
                                id: 'dataInicial'
                              })}
                              valueDate={values.dtInicial}
                              errorTouched={errors.dtInicial && touched.dtInicial}
                              helperText={provider.intl.formatMessage({
                                id: 'campoObrigatorio'
                              })}
                              onBlurDatePicker={date => {
                                let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                                if (!validation) {
                                  setFieldValue('dtInicial', null);
                                  setFieldValue('dtFinal', null);
                                }
                              }}
                              onChangeDatePicker={date => {
                                setFieldValue('dtInicial', date);
                                setFieldValue('dtFinal', null);
                                this.setState({
                                  dtInicial: date
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={2} style={{ minHeight: '75px' }}>
                            <DatePicker
                              minDate={values.dtInicial}
                              locale={getUserLang()}
                              style={{ height: 70 }}
                              className={classes.font}
                              label={provider.intl.formatMessage({
                                id: 'dataFinal'
                              })}
                              valueDate={values.dtFinal}
                              errorTouched={errors.dtFinal && touched.dtFinal ? true : false}
                              helperText={
                                values.dtFinal === null
                                  ? provider.intl.formatMessage({
                                      id: 'campoObrigatorio'
                                    })
                                  : errors.dtFinal
                              }
                              onChangeDatePicker={date => {
                                setFieldValue('dtFinal', date);
                                this.setState({
                                  dtFinal: date
                                });
                              }}
                              onBlurDatePicker={date => {
                                let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                                if (!validation) {
                                  setFieldValue('dtFinal', null);
                                } else {
                                  const dtFinal = moment(date.target.value, this.formatTextField());
                                  this.setState({
                                    dtFinal: dtFinal
                                  });
                                }
                              }}
                            />
                          </Grid>

                          <Grid item xs={4} style={{ height: 70 }}>
                            <div
                              style={{
                                width: '100%',
                                display: this.props.infoUsuario.tpPerfil === 2 ? 'none' : 'flex'
                              }}
                            >
                              <Autocomplete
                                itens={this.state.tecnicos}
                                campoOp={'nmUsuario'}
                                campoChave={'idUsuario'}
                                label={provider.intl.formatMessage({
                                  id: 'tecnico'
                                })}
                                name='tecnico'
                                onChangeOwn={event => {
                                  this.setState({
                                    key: Math.random()
                                  });
                                  setFieldValue('tecnico', event);
                                  this.valueAutoComplete = event;
                                }}
                                onChangeAutoComplete={this.validarInicializacaoAutoComplete}
                                value={this.tratarValorSelectInitial(this.valueAutoComplete)}
                                key={this.state.key}
                                valueAutoComplete={this.tratarValorSelectInitial(this.valueAutoComplete)}
                                valueSelect={
                                  this.tratarValorSelectInitial(this.valueAutoComplete).nmUsuario
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item xs={2} />

                          <Grid item xs={2} className={classes.buttonGrid}>
                            <Button onClick={handleSubmit} className={classes.buttonConsultar}>
                              {provider.intl.formatMessage({ id: 'consultar' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </CardIcon>
                    </div>
                  </form>
                </MuiThemeProvider>
              )}
            />

            <ContainerVisitas
              vlVisitasRealizadas={this.state.vlVisitasRealizadas}
              vlVisitasAgendadas={this.state.vlVisitasAgendadas}
              vlVisitasPendentes={this.state.vlVisitasPendentes}
              expanded={this.state.expanded}
            />

            <div className={classes.cardVisitasPendentes}>
              <CardWithTabs
                cardProps={{ disablePadding: true, paddingLaterais: true, height: true }}
                selectedIndex={this.state.tabIndex}
                onChangeTabIndex={(e, tabIndex) => {
                  this.setState({ tabIndex });
                }}
                disableHeader
                tabs={[
                  {
                    label: provider.intl.formatMessage({ id: 'semVisitas' }),
                    style: {
                      height: '100%',
                      minHeight: 270,
                      display: 'flex'
                    },
                    component: (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          height: 'calc(100% + 28px)'
                        }}
                      >
                        <DataTable
                          noMargin
                          noBox
                          usePaddings={false}
                          key={this.state.keyData}
                          data={this.state.dataVisitas}
                          columns={
                            this.props.infoUsuario.tpPerfil !== 2 &&
                            (this.state.autoComplete === '' || this.state.autoComplete === null)
                              ? this.state.itensVisitasWithTecnico
                              : this.state.itensVisitas
                          }
                          selectChange={() => {}}
                          showPagination={false}
                          noSelection
                          style={{ paddingBottom: 15 }}
                        />
                      </div>
                    )
                  },
                  {
                    label: provider.intl.formatMessage({ id: 'agendadas' }),
                    style: {
                      height: '100%',
                      minHeight: 270,
                      display: 'flex'
                    },
                    component: (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          height: 'calc(100% + 28px)'
                        }}
                      >
                        <DataTable
                          noMargin
                          noBox
                          usePaddings={false}
                          data={this.state.dataAgendadas}
                          key={this.state.keyData}
                          columns={
                            this.props.infoUsuario.tpPerfil !== 2 &&
                            (this.state.autoComplete === '' || this.state.autoComplete === null)
                              ? this.state.itensDataVisitasWithTecnico
                              : this.state.itensDataVisitas
                          }
                          selectChange={select => {
                            if (select) {
                              this.setState(
                                {
                                  itemSelect: select
                                },
                                () => {
                                  this.setState({
                                    indexSwipeable: 1
                                  });
                                }
                              );
                            } else {
                              this.setState({
                                itemSelect: ''
                              });
                            }
                          }}
                          showPagination={false}
                          style={{ paddingBottom: 15 }}
                        />
                      </div>
                    )
                  },
                  {
                    label: provider.intl.formatMessage({ id: 'realizadas' }),
                    style: {
                      height: '100%',
                      minHeight: 270,
                      display: 'flex'
                    },
                    component: (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          height: 'calc(100% + 28px)'
                        }}
                      >
                        <DataTable
                          noMargin
                          noBox
                          usePaddings={false}
                          data={this.state.dataRealizadas}
                          key={this.state.keyData}
                          columns={
                            this.props.infoUsuario.tpPerfil !== 2 &&
                            (this.state.autoComplete === '' || this.state.autoComplete === null)
                              ? this.state.itensDataVisitasWithTecnico
                              : this.state.itensDataVisitas
                          }
                          selectChange={select => {
                            if (select) {
                              this.setState(
                                {
                                  itemSelect: select
                                },
                                () => {
                                  this.setState({
                                    indexSwipeable: 1
                                  });
                                }
                              );
                            } else {
                              this.setState({
                                itemSelect: ''
                              });
                            }
                          }}
                          showPagination={false}
                          style={{ paddingBottom: 15 }}
                        />
                      </div>
                    )
                  }
                ]}
              />
            </div>
          </div>,
          <div
            key={1}
            style={{
              width: '100%',
              flex: 1,
              height: '100%',
              display: 'flex'
            }}
          >
            <Visita
              title={provider.intl.formatMessage({
                id: this.state.itemSelect.stAgendaPrevia ? 'dadosAgenda' : 'dadosVisita'
              })}
              check={this.state.itemSelect.stAgenda === 2}
              produtores={[]}
              typeGerente={true}
              provider={provider}
              classes={classes}
              openModal={true}
              statusEditar={true}
              itemSelect={this.state.itemSelect}
              initialData={null}
              typeProdutor={this.state.typeProdutor === 3}
              onSubmit={values => {}}
              clickExcluir={() => {}}
              onClose={() => {
                this.setState({
                  indexSwipeable: 0
                });
              }}
              onChangeDatePicker={() => {}}
              valueAutoComplete={''}
              onChangeOwn={() => {}}
              value={''}
              valueAutoCompleteTratado={() => {}}
              valueSelect={() => {}}
              onChangeCheck={() => {}}
              tpPerfil={this.props.infoUsuario.tpPerfil === 3}
              acompanhamentoTecnico={true}
            />
          </div>
        ]}
      </SwipeableViews>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(AcompanhamentoTecnico);
export default withRouter(connect(mapStateToProps)(enhaced));
