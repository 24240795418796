import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import EditarAnaliseDeRisco from './EditarAnaliseDeRisco';
import swal from '@sweetalert/with-react';
import CardTitle from '@components/CardTitle/CardTitle';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = theme => ({
  rodaPe: {
    padding: 15,
    paddingRight: 30,
    justifyContent: 'flex-end',
    display: 'flex',
    height: 60,
    borderTopColor: 'rgb(0, 0, 0, 0.1)',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    alignItems: 'center'
  },
  root: {
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  }
});

/**
 * Tab de relatório geral de avaliação de riscos
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
class AnaliseDeRiscos extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const sim = messagesLinguage['produtores.sim'];
    const nao = messagesLinguage['produtores.nao'];
    const somaRiscos = messagesLinguage['produtores.somaRiscos'];
    const talhao = messagesLinguage['produtores.talhao'];
    const riscosAdicionaisRelevantes = messagesLinguage['produtores.riscosAdicionaisRelevantes'];
    const dataLancamento = messagesLinguage['produtores.dataLancamento'];
    const analiseRiscoRemovidoComSucesso = messagesLinguage['produtores.analiseRiscoRemovidoComSucesso'];
    const desejaExcluirAnaliseRisco = messagesLinguage['produtores.desejaExcluirAnaliseRisco'];
    const excluir = messagesLinguage['produtores.excluir'];
    const analiseDeRisco = messagesLinguage['produtores.analiseDeRisco'];
    const tecnico = messagesLinguage['produtores.tecnico'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          riscosAdicionaisRelevantes,
          talhao,
          sim,
          nao,
          somaRiscos,
          dataLancamento,
          analiseRiscoRemovidoComSucesso,
          desejaExcluirAnaliseRisco,
          excluir,
          analiseDeRisco,
          tecnico
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      index: 0,
      cardOpen: false,
      statusEditar: false,
      disabledButtons: true,
      itemSelect: {},
      keyData: Math.random(),
      dataAnalise: [],
      headDatatable: [
        {
          field: 'dataLancamento',
          headerName: provider.intl.formatMessage({ id: 'dataLancamento' }),
          col: 4
        },
        {
          field: 'tecnico.nmUsuario',
          headerName: provider.intl.formatMessage({ id: 'tecnico' }),
          col: 4
        },
        {
          field: 'nrRiscos',
          headerName: provider.intl.formatMessage({ id: 'somaRiscos' }),
          col: 4
        }
      ]
    };

    this.notas = [0, 1, 2, 3, 4, 5];
    this.simNaoArray = [
      provider.intl.formatMessage({ id: 'sim' }),
      provider.intl.formatMessage({ id: 'nao' })
    ];

    this.onClose = this.onClose.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.formatTextField = this.formatTextField.bind(this);
  }

  componentDidMount() {
    this.setState({
      dataAnalise: this.props.data
    });
  }

  componentWillUnmount() {
    if (this.state.index === 1) {
      this.props.changeFAB();
    }
  }

  formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  onClose() {
    this.setState({
      index: 0,
      cardOpen: false,
      itemSelect: '',
      disabledButtons: true,
      statusEditar: false,
      keyData: Math.random()
    });

    this.props.changeFAB();
  }

  saveValues(values, position) {
    var data = this.state.dataAnalise;

    if (position !== undefined) {
      data[position] = values;
      data = [...data];
    } else {
      data.push(values);
    }

    this.setState({
      index: 0,
      dataAnalise: data,
      keyData: Math.random(),
      itemSelect: [],
      statusEditar: false,
      cardOpen: false,
      disabledButtons: true
    });

    this.props.saveAnaliseRisco(data, 'analiseRiscoList');
  }

  clickEditar() {
    this.setState({ statusEditar: true, cardOpen: true, index: 1, disabledButtons: true });
    this.props.changeFAB();
  }

  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluirAnaliseRisco' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(res => {
      if (res) {
        this.props.analiseRiscoDeleted(this.state.itemSelect, this.state.dataAnalise);
        const position = this.state.dataAnalise.indexOf(this.state.itemSelect);
        this.state.dataAnalise.splice(position, 1);

        this.setState({
          keyData: Math.random(),
          disabledButtons: true,
          itemSelect: {},
          cardOpen: false
        });

        swal(provider.intl.formatMessage({ id: 'analiseRiscoRemovidoComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      }
    });
  }

  selectChange(select) {
    if (select) {
      this.setState({ itemSelect: select, disabledButtons: false });
    } else {
      this.setState({ itemSelect: '', disabledButtons: true });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <SwipeableViewsOwn index={this.state.index} onChangeIndex={index => this.setState({ index })}>
          {[
            <div key={0} className={classes.card}>
              <CardTitle
                containerStyle={{ marginBottom: this.props.tpPerfil ? 0 : 'none' }}
                title={provider.intl.formatMessage({ id: 'analiseDeRisco' })}
              />

              {!this.props.tpPerfil && (
                <GroupButtonGrid
                  withoutMargin
                  hiddenMargin
                  showAdd
                  showEdit
                  showDelete
                  disableEdit={this.state.disabledButtons}
                  disableDelete={this.state.disabledButtons}
                  onClickAdd={() => this.setState({ index: 1 }, this.props.changeFAB)}
                  onClickEdit={this.clickEditar}
                  onClickDelete={this.clickExcluir}
                />
              )}

              <DataTable
                key={this.state.keyData}
                data={this.state.dataAnalise}
                borderRadius={false}
                columns={this.state.headDatatable}
                selectChange={this.selectChange}
                showPagination={false}
              />
            </div>,
            <EditarAnaliseDeRisco
              infoUsuario={this.props.infoUsuario}
              tpPerfilTecnico={this.props.tpPerfilTecnico}
              newProdutor={this.props.newProdutor}
              key={1}
              onClose={this.onClose}
              statusEditar={this.state.statusEditar}
              itemSelect={this.state.itemSelect}
              saveValues={this.saveValues}
              talhoes={this.props.talhoes || []}
              data={this.state.dataAnalise}
              tpPerfil={this.props.tpPerfil}
              cardOpen={this.state.cardOpen}
              onClickExpandButton={() => this.setState(state => ({ cardOpen: !state.cardOpen }))}
            />
          ]}
        </SwipeableViewsOwn>
      </div>
    );
  }
}

export default withStyles(styles)(AnaliseDeRiscos);
