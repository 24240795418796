import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@components/Chip/Chip';
import DatePicker from '@components/DatePicker/DatePicker';
import moment from 'moment';
import Usuarios from '@resources/Usuario';
import Autocomplete from '@components/Autocomplete/Autocomplete';

const icone_adicionarTalhao = require('@images/adicionar_talhao.png');

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = {
  root: {
    overflowY: 'scroll',
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  button: {
    height: 24,
    width: 24,
    marginTop: 10
  },
  iconButton: {
    width: 15
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    paddingRight: 30,
    marginTop: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  }
};

const theme = createMuiTheme({
  overrides: {
    MuiCollapse: {
      container: {
        overflow: 'inherit !important'
      }
    },
    MuiFormLabel: {
      root: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 'calc(100% - 20px)'
      }
    },
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#008000'
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Tab de relatório geral de avaliação de riscos
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
class EditarAnaliseDeRiscos extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const campoObrigatorio = messagesLinguage['produtores.campoObrigatorio'];
    const sim = messagesLinguage['produtores.sim'];
    const nao = messagesLinguage['produtores.nao'];
    const experienciaProdutor = messagesLinguage['produtores.experienciaProdutor'];
    const resultadosAnteriores = messagesLinguage['produtores.resultadosAnteriores'];
    const pressaoUsoDePesticidas = messagesLinguage['produtores.pressaoUsoDePesticidas'];
    const proximidadesLavouras = messagesLinguage['produtores.proximidadesLavouras'];
    const suspeitaAplicacaoDireta = messagesLinguage['produtores.suspeitaAplicacaoDireta'];
    const tamanhoSafra = messagesLinguage['produtores.tamanhoSafra'];
    const riscoTrocaSafra = messagesLinguage['produtores.riscoTrocaSafra'];
    const somaRiscos = messagesLinguage['produtores.somaRiscos'];
    const derivaVentoOuAgua = messagesLinguage['produtores.derivaVentoOuAgua'];
    const transitoVeiculos = messagesLinguage['produtores.transitoVeiculos'];
    const cultivosTransgenicos = messagesLinguage['produtores.cultivosTransgenicos'];
    const utilizacaoImplementosAgricolas = messagesLinguage['produtores.utilizacaoImplementosAgricolas'];
    const comentariosGerais = messagesLinguage['produtores.comentariosGerais'];
    const nota = messagesLinguage['produtores.nota'];
    const talhao = messagesLinguage['produtores.talhao'];
    const analiseDeRisco = messagesLinguage['produtores.analiseDeRisco'];
    const cancelar = messagesLinguage['produtores.cancelar'];
    const confirmar = messagesLinguage['produtores.confirmar'];
    const dataLancamento = messagesLinguage['produtores.dataLancamento'];
    const riscosAdicionais = messagesLinguage['produtores.riscosAdicionais'];
    const identificacao = messagesLinguage['produtores.identificacao'];
    const cadastroAnaliseRisco = messagesLinguage['produtores.cadastroAnaliseRisco'];
    const edicaoAnaliseRisco = messagesLinguage['produtores.edicaoAnaliseRisco'];
    const salvar = messagesLinguage['produtores.salvar'];
    const tecnicoResponsavel = messagesLinguage['produtores.tecnicoResponsavel'];
    const selecioneUmTalhaoPeloMenos = messagesLinguage['produtores.selecioneUmTalhaoPeloMenos'];
    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          selecioneUmTalhaoPeloMenos,
          talhao,
          campoObrigatorio,
          sim,
          nao,
          experienciaProdutor,
          resultadosAnteriores,
          pressaoUsoDePesticidas,
          proximidadesLavouras,
          suspeitaAplicacaoDireta,
          tamanhoSafra,
          riscoTrocaSafra,
          somaRiscos,
          derivaVentoOuAgua,
          transitoVeiculos,
          cultivosTransgenicos,
          utilizacaoImplementosAgricolas,
          comentariosGerais,
          nota,
          analiseDeRisco,
          confirmar,
          cancelar,
          dataLancamento,
          riscosAdicionais,
          identificacao,
          cadastroAnaliseRisco,
          edicaoAnaliseRisco,
          salvar,
          tecnicoResponsavel
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.somaRiscos = 0;

    this.state = {
      itemSelect: {
        tecnico: '',
        dtLancamento: null,
        nrExperienciaOrganica: '',
        nrResultadoAnterior: '',
        nrPressaoPesticida: '',
        nrBarreiraLavoura: '',
        nrSuspeitaAplicacao: '',
        nrSafraColheita: '',
        nrRiscoTrocaProduto: '',
        stDerivaPorVentoOuAgua: '',
        talhaoDerivaPorVentoOuAgua: '',
        stTransitoVeiculos: '',
        talhaoTransitoVeiculos: '',
        stCultivosTransgenicos: '',
        talhaoCultivosTransgenicos: '',
        stUtilizacaoImplementos: '',
        dsComentariosGerais: ''
      },
      keyTecnico: Math.random(),
      tecnicos: [],
      tecnico: '',
      chipsDerivaPorVentoOuAgua: [],
      chipsTransitoVeiculos: [],
      chipsCultivosTransgenicos: []
    };

    this.notas = ['0', '1', '2', '3', '4', '5'];
    this.simNaoArray = [
      { key: 1, label: provider.intl.formatMessage({ id: 'sim' }) },
      { key: 0, label: provider.intl.formatMessage({ id: 'nao' }) }
    ];

    /**
     * Gera uma função para validação do mínimo de Talhões selecionados
     * se o select do risco adicional estiver selecionado
     *
     * @param {String} propStateName - Nome da prop com a lista de Talhões selecionados no state
     * @returns {Function} Todas as notas somadas
     */
    this.minimoTalhoesValidationGenerator = propStateName => {
      const chipsArray = this.state[propStateName];

      return function(value) {
        if (value && +value === 1 && chipsArray.length === 0) {
          const { path, createError } = this;
          return createError({
            path,
            message: provider.intl.formatMessage({ id: 'selecioneUmTalhaoPeloMenos' })
          });
        } else {
          return true;
        }
      };
    };

    this.somaNotas = this.somaNotas.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.handleAddChip = this.handleAddChip.bind(this);
    this.filterTalhoesToSelect = this.filterTalhoesToSelect.bind(this);
    this.onSave = this.onSave.bind(this);
    this.formatTextField = this.formatTextField.bind(this);
    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.onChangeTecnico = this.onChangeTecnico.bind(this);
    this.validarInicializacaoAutoComplete = this.validarInicializacaoAutoComplete.bind(this);
  }

  componentDidMount() {
    this.validarInicializacaoAutoComplete();

    if (this.props.statusEditar) {
      const chipsDerivaPorVentoOuAgua = this.props.itemSelect.chipsDerivaPorVentoOuAgua.map(doc => {
        return { ...doc };
      });

      const chipsTransitoVeiculos = this.props.itemSelect.chipsTransitoVeiculos.map(doc => {
        return { ...doc };
      });

      const chipsCultivosTransgenicos = this.props.itemSelect.chipsCultivosTransgenicos.map(doc => {
        return { ...doc };
      });

      const chipsUtilizacaoImplementos = this.props.itemSelect.chipsUtilizacaoImplementos;

      const itemSelect = this.props.itemSelect;
      itemSelect.stUtilizacaoImplementos = chipsUtilizacaoImplementos.length > 0 ? 1 : 0;
      itemSelect.stCultivosTransgenicos = chipsCultivosTransgenicos.length > 0 ? 1 : 0;
      itemSelect.stTransitoVeiculos = chipsTransitoVeiculos.length > 0 ? 1 : 0;
      itemSelect.stDerivaPorVentoOuAgua = chipsDerivaPorVentoOuAgua.length > 0 ? 1 : 0;

      this.setState({
        tecnico: this.props.itemSelect.tecnico,
        itemSelect: itemSelect,
        keyTecnico: Math.random(),
        chipsDerivaPorVentoOuAgua: chipsDerivaPorVentoOuAgua,
        chipsTransitoVeiculos: chipsTransitoVeiculos,
        chipsCultivosTransgenicos: chipsCultivosTransgenicos
      });
    }
  }

  /**
   * Soma todas as notas
   *
   * @returns {Number} Todas as notas somadas
   */
  somaNotas() {
    const {
      nrExperienciaOrganica,
      nrResultadoAnterior,
      nrPressaoPesticida,
      nrBarreiraLavoura,
      nrSuspeitaAplicacao,
      nrSafraColheita,
      nrRiscoTrocaProduto
    } = this.values;
    const notas = [
      nrExperienciaOrganica,
      nrResultadoAnterior,
      nrPressaoPesticida,
      nrBarreiraLavoura,
      nrSuspeitaAplicacao,
      nrSafraColheita,
      nrRiscoTrocaProduto
    ];

    let retorno = 0;

    notas.forEach(nota => {
      if (nota) {
        retorno += +nota;
      }
    });

    this.somaRiscos = retorno;

    return retorno;
  }

  /**
   * Remove o chip de uma das listas do state
   *
   * @param {String} arrayName - Nome da lista no state
   * @param {Number} chipIndex - Index do chip que vai ser removido
   */
  handleDeleteChip(arrayName, chipIndex) {
    const auxChips = this.state[arrayName];

    auxChips.splice(chipIndex, 1);

    this.setState({ [arrayName]: auxChips });
  }

  /**
   * Adiciona um novo chip em uma das listas do state
   *
   * @param {String} arrayName - Nome da lista no state
   * @param {String} propTalhaoName - Nome da prop do Talhão no Formik
   */
  handleAddChip(arrayName, propTalhaoName) {
    const auxChips = this.state[arrayName];

    const talhao = this.props.talhoes.find(item => item.idTalhao === this.values[propTalhaoName]);
    if (!talhao) return;

    var itemTalhao = { ...talhao };

    itemTalhao.stRegistro = 0;

    auxChips.push(itemTalhao);

    this.setState({ [arrayName]: auxChips });
  }

  /**
   * Filtra a lista de Talhões para os Selects
   *
   * @param {String} arrayName - Nome da lista no state
   * @returns {Talhao[]} - Lista de Talhões filtrada
   */
  filterTalhoesToSelect(arrayName) {
    const chipsSelecteds = this.state[arrayName];

    return this.props.talhoes.filter(
      item =>
        !chipsSelecteds.find(subItem => subItem.idTalhao === item.idTalhao && subItem.stRegistro !== 2)
    );
  }

  onSave(values, { resetForm }) {
    values.tecnico = this.state.tecnico;
    values.nrRiscos = this.somaRiscos;
    values.dataLancamento = moment(values.dtLancamento).format(this.formatTextField());
    values.chipsDerivaPorVentoOuAgua = this.state.chipsDerivaPorVentoOuAgua;
    values.chipsTransitoVeiculos = this.state.chipsTransitoVeiculos;
    values.chipsCultivosTransgenicos = this.state.chipsCultivosTransgenicos;

    const riscosAdicionais = [];
    this.state.chipsDerivaPorVentoOuAgua.forEach(item => {
      riscosAdicionais.push({
        tpRiscoAdicional: 0,
        talhao: { idTalhao: item.idTalhao }
      });
    });

    this.state.chipsTransitoVeiculos.forEach(item => {
      riscosAdicionais.push({
        tpRiscoAdicional: 1,
        talhao: { idTalhao: item.idTalhao }
      });
    });

    this.state.chipsCultivosTransgenicos.forEach(item => {
      riscosAdicionais.push({
        tpRiscoAdicional: 2,
        talhao: { idTalhao: item.idTalhao }
      });
    });

    if (+values.stUtilizacaoImplementos === 1) {
      values.chipsUtilizacaoImplementos = [null];

      riscosAdicionais.push({
        tpRiscoAdicional: 3,
        talhao: {}
      });
    } else {
      values.chipsUtilizacaoImplementos = [];
    }

    values.dtLancamento = moment(values.dtLancamento).format('YYYY-MM-DD');
    values.riscosAdicionaisList = riscosAdicionais;
    if (this.props.statusEditar) {
      const position = this.props.data.indexOf(this.props.itemSelect);
      values.stRegistro = this.props.itemSelect.stRegistro;
      values.idAnaliseRisco = this.props.itemSelect.idAnaliseRisco;
      this.props.saveValues(values, position);
    } else {
      values.stRegistro = 0;
      this.props.saveValues(values);
    }
  }

  formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  validarInicializacaoAutoComplete() {
    Usuarios.findAllUsuarios()
      .then(query => {
        let data = [];

        query.data.forEach(doc => {
          if (doc.tpPerfil === 2) {
            data.push(doc);
          }
        });

        this.setState({ tecnicos: data }, () => {
          if (parseInt(this.props.tpPerfilTecnico.tpPerfil) === 2) {
            var tecnico = this.state.tecnicos.find(tec => {
              return this.props.infoUsuario.dsEmail === tec.dsEmail;
            });

            this.setState({
              tecnico: tecnico,
              keyTecnico: Math.random()
            });
          }
        });
      })
      .catch(err => {
        this.setState({ tecnicos: [] });
      });
  }

  tratarValorSelectInitial() {
    if (!this.state.tecnico || this.state.tecnico === undefined) {
      return '';
    }
    return this.state.tecnico;
  }

  onChangeTecnico(value = '') {
    this.setState({
      keyTecnico: Math.random(),
      tecnico: value
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Formik
            initialValues={{
              tecnico: this.state.tecnico,
              dtLancamento: this.state.itemSelect.dtLancamento,
              nrExperienciaOrganica: this.state.itemSelect.nrExperienciaOrganica.toString(),
              nrResultadoAnterior: this.state.itemSelect.nrResultadoAnterior.toString(),
              nrPressaoPesticida: this.state.itemSelect.nrPressaoPesticida.toString(),
              nrBarreiraLavoura: this.state.itemSelect.nrBarreiraLavoura.toString(),
              nrSuspeitaAplicacao: this.state.itemSelect.nrSuspeitaAplicacao.toString(),
              nrSafraColheita: this.state.itemSelect.nrSafraColheita.toString(),
              nrRiscoTrocaProduto: this.state.itemSelect.nrRiscoTrocaProduto.toString(),
              stDerivaPorVentoOuAgua: this.state.itemSelect.stDerivaPorVentoOuAgua,
              talhaoDerivaPorVentoOuAgua: '',
              stTransitoVeiculos: this.state.itemSelect.stTransitoVeiculos,
              talhaoTransitoVeiculos: '',
              stCultivosTransgenicos: this.state.itemSelect.stCultivosTransgenicos,
              talhaoCultivosTransgenicos: '',
              stUtilizacaoImplementos: this.state.itemSelect.stUtilizacaoImplementos,
              dsComentariosGerais: this.state.itemSelect.dsComentariosGerais
            }}
            validateOnBlur
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={this.onSave}
            validationSchema={Yup.object().shape({
              dtLancamento: Yup.string()
                .nullable()
                .required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
              tecnico: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
              stDerivaPorVentoOuAgua: Yup.number().test(
                'test-name',
                ' ',
                this.minimoTalhoesValidationGenerator('chipsDerivaPorVentoOuAgua')
              ),
              stTransitoVeiculos: Yup.number().test(
                'test-name',
                ' ',
                this.minimoTalhoesValidationGenerator('chipsTransitoVeiculos')
              ),
              stCultivosTransgenicos: Yup.number().test(
                'test-name',
                ' ',
                this.minimoTalhoesValidationGenerator('chipsCultivosTransgenicos')
              )
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue
            }) => {
              this.setFieldValue = setFieldValue;
              this.values = values;

              return (
                <form autoComplete='off'>
                  <CardWithTabs
                    className={{ overflowY: 'auto', height: 'calc()' }}
                    selectedIndex={this.state.tabIndex}
                    onChangeTabIndex={(e, tabIndex) => this.setState({ tabIndex })}
                    cardProps={{ styleCard: { paddingRight: 0 } }}
                    containerSwipeableStyles={{ overflow: 'inherit' }}
                    title={provider.intl.formatMessage({
                      id: this.props.statusEdit ? 'edicaoAnaliseRisco' : 'cadastroAnaliseRisco'
                    })}
                    tabs={[
                      {
                        label: provider.intl.formatMessage({ id: 'identificacao' }),
                        style: {
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          maxHeight: 'calc(100vh - 360px)'
                        },
                        component: (
                          <MuiThemeProvider theme={theme}>
                            <Grid container spacing={16}>
                              <Grid item xs={2} style={{ height: 70 }}>
                                <DatePicker
                                  locale={getUserLang()}
                                  className={classes.textField}
                                  label={provider.intl.formatMessage({
                                    id: 'dataLancamento'
                                  })}
                                  valueDate={values.dtLancamento}
                                  onBlurDatePicker={e => {
                                    const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                                    !validation && setFieldValue('dtLancamento', null);
                                    handleBlur('dtLancamento')(e);
                                  }}
                                  onChangeDatePicker={value => {
                                    setFieldValue('dtLancamento', value);
                                    var data = this.state.itemSelect;
                                    data.dtLancamento = value;
                                  }}
                                  errorTouched={
                                    errors.dtLancamento && touched.dtLancamento
                                      ? touched.dtLancamento
                                      : false
                                  }
                                  helperText={
                                    errors.dtLancamento && touched.dtLancamento
                                      ? errors.dtLancamento
                                      : false
                                  }
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <Autocomplete
                                  disabled={this.props.tpPerfilTecnico.tpPerfil === 2}
                                  itens={this.state.tecnicos}
                                  campoOp='nmUsuario'
                                  campoChave='idUsuario'
                                  label={provider.intl.formatMessage({ id: 'tecnicoResponsavel' })}
                                  name='tecnico'
                                  error={!this.state.tecnico && touched.tecnico}
                                  helperText={
                                    touched.tecnico && !this.state.tecnico
                                      ? provider.intl.formatMessage({
                                          id: 'campoObrigatorio'
                                        })
                                      : null
                                  }
                                  onChangeOwn={this.onChangeTecnico}
                                  onChangeAutoComplete={this.validarInicializacaoAutoComplete}
                                  value={this.tratarValorSelectInitial()}
                                  key={this.state.keyTecnico}
                                  valueAutoComplete={this.tratarValorSelectInitial()}
                                  valueSelect={this.tratarValorSelectInitial().nmUsuario}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={1} style={{ height: 70 }}>
                                <FormControl
                                  error={errors.nrExperienciaOrganica && touched.nrExperienciaOrganica}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='nrExperienciaOrganica'
                                    error={errors.nrExperienciaOrganica && touched.nrExperienciaOrganica}
                                  >
                                    {provider.intl.formatMessage({ id: 'nota' })}
                                  </InputLabel>

                                  <Select
                                    value={values.nrExperienciaOrganica}
                                    error={errors.nrExperienciaOrganica && touched.nrExperienciaOrganica}
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('nrExperienciaOrganica', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.nrExperienciaOrganica = value.target.value;
                                    }}
                                    name='nrExperienciaOrganica'
                                    id='nrExperienciaOrganica'
                                  >
                                    {this.notas.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.nrExperienciaOrganica && touched.nrExperienciaOrganica && (
                                    <FormHelperText>{errors.nrExperienciaOrganica}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                              >
                                <Typography variant='body1' component='label'>
                                  {provider.intl.formatMessage({ id: 'experienciaProdutor' })}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={1} style={{ height: 70 }}>
                                <FormControl
                                  error={errors.nrResultadoAnterior && touched.nrResultadoAnterior}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='nrResultadoAnterior'
                                    error={errors.nrResultadoAnterior && touched.nrResultadoAnterior}
                                  >
                                    {provider.intl.formatMessage({ id: 'nota' })}
                                  </InputLabel>

                                  <Select
                                    value={values.nrResultadoAnterior}
                                    error={errors.nrResultadoAnterior && touched.nrResultadoAnterior}
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('nrResultadoAnterior', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.nrResultadoAnterior = value.target.value;
                                    }}
                                    name='nrResultadoAnterior'
                                    id='nrResultadoAnterior'
                                  >
                                    {this.notas.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.nrResultadoAnterior && touched.nrResultadoAnterior && (
                                    <FormHelperText>{errors.nrResultadoAnterior}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                              >
                                <Typography variant='body1' component='label'>
                                  {provider.intl.formatMessage({ id: 'resultadosAnteriores' })}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={1} style={{ height: 70 }}>
                                <FormControl
                                  error={errors.nrPressaoPesticida && touched.nrPressaoPesticida}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='nrPressaoPesticida'
                                    error={errors.nrPressaoPesticida && touched.nrPressaoPesticida}
                                  >
                                    {provider.intl.formatMessage({ id: 'nota' })}
                                  </InputLabel>

                                  <Select
                                    value={values.nrPressaoPesticida}
                                    error={errors.nrPressaoPesticida && touched.nrPressaoPesticida}
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('nrPressaoPesticida', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.nrPressaoPesticida = value.target.value;
                                    }}
                                    name='nrPressaoPesticida'
                                    id='nrPressaoPesticida'
                                  >
                                    {this.notas.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.nrPressaoPesticida && touched.nrPressaoPesticida && (
                                    <FormHelperText>{errors.nrPressaoPesticida}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                              >
                                <Typography variant='body1' component='label'>
                                  {provider.intl.formatMessage({ id: 'pressaoUsoDePesticidas' })}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={1} style={{ height: 70 }}>
                                <FormControl
                                  error={errors.nrBarreiraLavoura && touched.nrBarreiraLavoura}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='nrBarreiraLavoura'
                                    error={errors.nrBarreiraLavoura && touched.nrBarreiraLavoura}
                                  >
                                    {provider.intl.formatMessage({ id: 'nota' })}
                                  </InputLabel>

                                  <Select
                                    value={values.nrBarreiraLavoura}
                                    error={errors.nrBarreiraLavoura && touched.nrBarreiraLavoura}
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('nrBarreiraLavoura', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.nrBarreiraLavoura = value.target.value;
                                    }}
                                    name='nrBarreiraLavoura'
                                    id='nrBarreiraLavoura'
                                  >
                                    {this.notas.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.nrBarreiraLavoura && touched.nrBarreiraLavoura && (
                                    <FormHelperText>{errors.nrBarreiraLavoura}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                              >
                                <Typography variant='body1' component='label'>
                                  {provider.intl.formatMessage({ id: 'proximidadesLavouras' })}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={1} style={{ height: 70 }}>
                                <FormControl
                                  error={errors.nrSuspeitaAplicacao && touched.nrSuspeitaAplicacao}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='nrSuspeitaAplicacao'
                                    error={errors.nrSuspeitaAplicacao && touched.nrSuspeitaAplicacao}
                                  >
                                    {provider.intl.formatMessage({ id: 'nota' })}
                                  </InputLabel>

                                  <Select
                                    value={values.nrSuspeitaAplicacao}
                                    error={errors.nrSuspeitaAplicacao && touched.nrSuspeitaAplicacao}
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('nrSuspeitaAplicacao', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.nrSuspeitaAplicacao = value.target.value;
                                    }}
                                    name='nrSuspeitaAplicacao'
                                    id='nrSuspeitaAplicacao'
                                  >
                                    {this.notas.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.nrSuspeitaAplicacao && touched.nrSuspeitaAplicacao && (
                                    <FormHelperText>{errors.nrSuspeitaAplicacao}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                              >
                                <Typography variant='body1' component='label'>
                                  {provider.intl.formatMessage({ id: 'suspeitaAplicacaoDireta' })}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={1} style={{ height: 70 }}>
                                <FormControl
                                  error={errors.nrSafraColheita && touched.nrSafraColheita}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='nrSafraColheita'
                                    error={errors.nrSafraColheita && touched.nrSafraColheita}
                                  >
                                    {provider.intl.formatMessage({ id: 'nota' })}
                                  </InputLabel>

                                  <Select
                                    value={values.nrSafraColheita}
                                    error={errors.nrSafraColheita && touched.nrSafraColheita}
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('nrSafraColheita', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.nrSafraColheita = value.target.value;
                                    }}
                                    name='nrSafraColheita'
                                    id='nrSafraColheita'
                                  >
                                    {this.notas.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.nrSafraColheita && touched.nrSafraColheita && (
                                    <FormHelperText>{errors.nrSafraColheita}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                              >
                                <Typography variant='body1' component='label'>
                                  {provider.intl.formatMessage({ id: 'tamanhoSafra' })}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={1} style={{ height: 70 }}>
                                <FormControl
                                  error={errors.nrRiscoTrocaProduto && touched.nrRiscoTrocaProduto}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='nrRiscoTrocaProduto'
                                    error={errors.nrRiscoTrocaProduto && touched.nrRiscoTrocaProduto}
                                  >
                                    {provider.intl.formatMessage({ id: 'nota' })}
                                  </InputLabel>

                                  <Select
                                    value={values.nrRiscoTrocaProduto}
                                    error={errors.nrRiscoTrocaProduto && touched.nrRiscoTrocaProduto}
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('nrRiscoTrocaProduto', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.nrRiscoTrocaProduto = value.target.value;
                                    }}
                                    name='nrRiscoTrocaProduto'
                                    id='nrRiscoTrocaProduto'
                                  >
                                    {this.notas.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.nrRiscoTrocaProduto && touched.nrRiscoTrocaProduto && (
                                    <FormHelperText>{errors.nrRiscoTrocaProduto}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                              >
                                <Typography variant='body1' component='label'>
                                  {provider.intl.formatMessage({ id: 'riscoTrocaSafra' })}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: 15 }}>
                              <Grid item xs>
                                <Typography variant='subtitle1' component='label'>
                                  {`${provider.intl.formatMessage({
                                    id: 'somaRiscos'
                                  })}: ${this.somaNotas()}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </MuiThemeProvider>
                        )
                      },
                      {
                        label: provider.intl.formatMessage({ id: 'riscosAdicionais' }),
                        style: {
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          maxHeight: 'calc(100vh - 360px)'
                        },
                        component: (
                          <MuiThemeProvider theme={theme}>
                            <Grid container>
                              <Grid item xs={5} style={{ height: 70, paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stDerivaPorVentoOuAgua && touched.stDerivaPorVentoOuAgua}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='stDerivaPorVentoOuAgua'
                                    error={
                                      errors.stDerivaPorVentoOuAgua && touched.stDerivaPorVentoOuAgua
                                    }
                                  >
                                    {provider.intl.formatMessage({ id: 'derivaVentoOuAgua' })}
                                  </InputLabel>

                                  <Select
                                    value={values.stDerivaPorVentoOuAgua}
                                    error={
                                      errors.stDerivaPorVentoOuAgua && touched.stDerivaPorVentoOuAgua
                                    }
                                    disabled={
                                      this.props.talhoes.length -
                                        this.filterTalhoesToSelect('chipsDerivaPorVentoOuAgua').length >
                                      0
                                    }
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('stDerivaPorVentoOuAgua', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.stDerivaPorVentoOuAgua = value.target.value;
                                    }}
                                    name='stDerivaPorVentoOuAgua'
                                    id='stDerivaPorVentoOuAgua'
                                  >
                                    {this.simNaoArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.stDerivaPorVentoOuAgua && touched.stDerivaPorVentoOuAgua && (
                                    <FormHelperText>{errors.stDerivaPorVentoOuAgua}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                <FormControl
                                  error={
                                    errors.talhaoDerivaPorVentoOuAgua &&
                                    touched.talhaoDerivaPorVentoOuAgua
                                  }
                                  style={{ width: '100%' }}
                                  disabled={
                                    !!(
                                      values.stDerivaPorVentoOuAgua === '' ||
                                      +values.stDerivaPorVentoOuAgua !== 1
                                    )
                                  }
                                >
                                  <InputLabel
                                    htmlFor='talhaoDerivaPorVentoOuAgua'
                                    error={
                                      errors.talhaoDerivaPorVentoOuAgua &&
                                      touched.talhaoDerivaPorVentoOuAgua
                                    }
                                  >
                                    {provider.intl.formatMessage({ id: 'talhao' })}
                                  </InputLabel>

                                  <Select
                                    value={values.talhaoDerivaPorVentoOuAgua}
                                    error={
                                      errors.talhaoDerivaPorVentoOuAgua &&
                                      touched.talhaoDerivaPorVentoOuAgua
                                    }
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('talhaoDerivaPorVentoOuAgua', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.talhaoDerivaPorVentoOuAgua = value.target.value;
                                    }}
                                    name='talhaoDerivaPorVentoOuAgua'
                                    id='talhaoDerivaPorVentoOuAgua'
                                    disabled={
                                      !!(
                                        values.stDerivaPorVentoOuAgua === '' ||
                                        +values.stDerivaPorVentoOuAgua !== 1
                                      )
                                    }
                                  >
                                    {this.filterTalhoesToSelect('chipsDerivaPorVentoOuAgua').map(
                                      (talhao, i) => (
                                        <MenuItem key={i} value={talhao.idTalhao}>
                                          {talhao.dsTalhao}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>

                                  {errors.talhaoDerivaPorVentoOuAgua &&
                                    touched.talhaoDerivaPorVentoOuAgua && (
                                      <FormHelperText>
                                        {errors.talhaoDerivaPorVentoOuAgua}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>

                              <Grid item xs={1}>
                                <Button
                                  disabled={
                                    !!(
                                      this.props.tpPerfil ||
                                      values.stDerivaPorVentoOuAgua === '' ||
                                      +values.stDerivaPorVentoOuAgua !== 1
                                    )
                                  }
                                  className={classes.button}
                                  onClick={e => {
                                    this.handleAddChip(
                                      'chipsDerivaPorVentoOuAgua',
                                      'talhaoDerivaPorVentoOuAgua'
                                    );
                                    setFieldValue('talhaoDerivaPorVentoOuAgua', '');
                                  }}
                                >
                                  <img
                                    className={classes.iconButton}
                                    src={icone_adicionarTalhao}
                                    alt=' '
                                  />
                                </Button>
                              </Grid>
                            </Grid>

                            <Grid container style={{ marginBottom: 30 }}>
                              <Grid item xs>
                                {this.state.chipsDerivaPorVentoOuAgua.map(
                                  (chip, i) =>
                                    chip.stRegistro !== 2 && (
                                      <Chip
                                        key={i}
                                        text={chip.dsTalhao}
                                        tpPerfil={this.props.tpPerfil}
                                        onRemove={() =>
                                          !this.props.tpPerfil &&
                                          this.handleDeleteChip('chipsDerivaPorVentoOuAgua', i)
                                        }
                                      />
                                    )
                                )}
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={5} style={{ height: 70, paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stTransitoVeiculos && touched.stTransitoVeiculos}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='stTransitoVeiculos'
                                    error={errors.stTransitoVeiculos && touched.stTransitoVeiculos}
                                  >
                                    {provider.intl.formatMessage({ id: 'transitoVeiculos' })}
                                  </InputLabel>

                                  <Select
                                    value={values.stTransitoVeiculos}
                                    error={errors.stTransitoVeiculos && touched.stTransitoVeiculos}
                                    disabled={
                                      this.props.talhoes.length -
                                        this.filterTalhoesToSelect('chipsTransitoVeiculos').length >
                                      0
                                    }
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('stTransitoVeiculos', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.stTransitoVeiculos = value.target.value;
                                    }}
                                    name='stTransitoVeiculos'
                                    id='stTransitoVeiculos'
                                  >
                                    {this.simNaoArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.stTransitoVeiculos && touched.stTransitoVeiculos && (
                                    <FormHelperText>{errors.stTransitoVeiculos}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                <FormControl
                                  error={errors.talhaoTransitoVeiculos && touched.talhaoTransitoVeiculos}
                                  style={{ width: '100%' }}
                                  disabled={
                                    values.stTransitoVeiculos === '' || +values.stTransitoVeiculos !== 1
                                  }
                                >
                                  <InputLabel
                                    htmlFor='talhaoTransitoVeiculos'
                                    error={
                                      errors.talhaoTransitoVeiculos && touched.talhaoTransitoVeiculos
                                    }
                                  >
                                    {provider.intl.formatMessage({ id: 'talhao' })}
                                  </InputLabel>

                                  <Select
                                    value={values.talhaoTransitoVeiculos}
                                    error={
                                      errors.talhaoTransitoVeiculos && touched.talhaoTransitoVeiculos
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='talhaoTransitoVeiculos'
                                    id='talhaoTransitoVeiculos'
                                    disabled={
                                      values.stTransitoVeiculos === '' ||
                                      +values.stTransitoVeiculos !== 1
                                    }
                                  >
                                    {this.filterTalhoesToSelect('chipsTransitoVeiculos').map(
                                      (talhao, i) => (
                                        <MenuItem key={i} value={talhao.idTalhao}>
                                          {talhao.dsTalhao}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>

                                  {errors.talhaoTransitoVeiculos && touched.talhaoTransitoVeiculos && (
                                    <FormHelperText>{errors.talhaoTransitoVeiculos}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid item xs={1}>
                                <Button
                                  disabled={
                                    this.props.tpPerfil ||
                                    values.stTransitoVeiculos === '' ||
                                    +values.stTransitoVeiculos !== 1
                                  }
                                  className={classes.button}
                                  onClick={e => {
                                    this.handleAddChip(
                                      'chipsTransitoVeiculos',
                                      'talhaoTransitoVeiculos'
                                    );
                                    setFieldValue('talhaoTransitoVeiculos', '');
                                  }}
                                >
                                  <img
                                    className={classes.iconButton}
                                    src={icone_adicionarTalhao}
                                    alt=' '
                                  />
                                </Button>
                              </Grid>
                            </Grid>

                            <Grid container style={{ marginBottom: 30 }}>
                              <Grid item xs>
                                {this.state.chipsTransitoVeiculos.map(
                                  (chip, i) =>
                                    chip.stRegistro !== 2 && (
                                      <Chip
                                        key={i}
                                        text={chip.dsTalhao}
                                        tpPerfil={this.props.tpPerfil}
                                        onRemove={() =>
                                          !this.props.tpPerfil &&
                                          this.handleDeleteChip('chipsTransitoVeiculos', i)
                                        }
                                      />
                                    )
                                )}
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={5} style={{ height: 70, paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stCultivosTransgenicos && touched.stCultivosTransgenicos}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='stCultivosTransgenicos'
                                    error={
                                      errors.stCultivosTransgenicos && touched.stCultivosTransgenicos
                                    }
                                  >
                                    {provider.intl.formatMessage({ id: 'cultivosTransgenicos' })}
                                  </InputLabel>

                                  <Select
                                    value={values.stCultivosTransgenicos}
                                    error={
                                      errors.stCultivosTransgenicos && touched.stCultivosTransgenicos
                                    }
                                    disabled={
                                      this.props.talhoes.length -
                                        this.filterTalhoesToSelect('chipsCultivosTransgenicos').length >
                                      0
                                    }
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('stCultivosTransgenicos', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.stCultivosTransgenicos = value.target.value;
                                    }}
                                    name='stCultivosTransgenicos'
                                    id='stCultivosTransgenicos'
                                  >
                                    {this.simNaoArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.stCultivosTransgenicos && touched.stCultivosTransgenicos && (
                                    <FormHelperText>{errors.stCultivosTransgenicos}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                <FormControl
                                  error={
                                    errors.talhaoCultivosTransgenicos &&
                                    touched.talhaoCultivosTransgenicos
                                  }
                                  style={{ width: '100%' }}
                                  disabled={
                                    values.stCultivosTransgenicos === '' ||
                                    +values.stCultivosTransgenicos !== 1
                                  }
                                >
                                  <InputLabel
                                    htmlFor='talhaoCultivosTransgenicos'
                                    error={
                                      errors.talhaoCultivosTransgenicos &&
                                      touched.talhaoCultivosTransgenicos
                                    }
                                  >
                                    {provider.intl.formatMessage({ id: 'talhao' })}
                                  </InputLabel>

                                  <Select
                                    value={values.talhaoCultivosTransgenicos}
                                    error={
                                      errors.talhaoCultivosTransgenicos &&
                                      touched.talhaoCultivosTransgenicos
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='talhaoCultivosTransgenicos'
                                    id='talhaoCultivosTransgenicos'
                                    disabled={
                                      values.stCultivosTransgenicos === '' ||
                                      +values.stCultivosTransgenicos !== 1
                                    }
                                  >
                                    {this.filterTalhoesToSelect('chipsCultivosTransgenicos').map(
                                      (talhao, i) => (
                                        <MenuItem key={i} value={talhao.idTalhao}>
                                          {talhao.dsTalhao}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>

                                  {errors.talhaoCultivosTransgenicos &&
                                    touched.talhaoCultivosTransgenicos && (
                                      <FormHelperText>
                                        {errors.talhaoCultivosTransgenicos}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>

                              <Grid item xs={1}>
                                <Button
                                  disabled={
                                    this.props.tpPerfil ||
                                    values.stCultivosTransgenicos === '' ||
                                    +values.stCultivosTransgenicos !== 1
                                  }
                                  className={classes.button}
                                  onClick={e => {
                                    this.handleAddChip(
                                      'chipsCultivosTransgenicos',
                                      'talhaoCultivosTransgenicos'
                                    );
                                    setFieldValue('talhaoCultivosTransgenicos', '');
                                  }}
                                >
                                  <img
                                    className={classes.iconButton}
                                    src={icone_adicionarTalhao}
                                    alt=' '
                                  />
                                </Button>
                              </Grid>
                            </Grid>

                            <Grid container style={{ marginBottom: 30 }}>
                              <Grid item xs>
                                {this.state.chipsCultivosTransgenicos.map(
                                  (chip, i) =>
                                    chip.stRegistro !== 2 && (
                                      <Chip
                                        key={i}
                                        text={chip.dsTalhao}
                                        tpPerfil={this.props.tpPerfil}
                                        onRemove={() =>
                                          !this.props.tpPerfil &&
                                          this.handleDeleteChip('chipsCultivosTransgenicos', i)
                                        }
                                      />
                                    )
                                )}
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid
                                item
                                xs={5}
                                style={{ height: 70, paddingRight: 15, marginBottom: 30 }}
                              >
                                <FormControl
                                  error={
                                    errors.stUtilizacaoImplementos && touched.stUtilizacaoImplementos
                                  }
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    htmlFor='stUtilizacaoImplementos'
                                    error={
                                      errors.stUtilizacaoImplementos && touched.stUtilizacaoImplementos
                                    }
                                  >
                                    {provider.intl.formatMessage({
                                      id: 'utilizacaoImplementosAgricolas'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stUtilizacaoImplementos}
                                    error={
                                      errors.stUtilizacaoImplementos && touched.stUtilizacaoImplementos
                                    }
                                    onChange={handleChange}
                                    onBlur={value => {
                                      setFieldValue('stUtilizacaoImplementos', value.target.value);
                                      var data = this.state.itemSelect;
                                      data.stUtilizacaoImplementos = value.target.value;
                                    }}
                                    name='stUtilizacaoImplementos'
                                    id='stUtilizacaoImplementos'
                                  >
                                    {this.simNaoArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {errors.stUtilizacaoImplementos && touched.stUtilizacaoImplementos && (
                                    <FormHelperText>{errors.stUtilizacaoImplementos}</FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid container style={{ marginBottom: 15 }}>
                              <Grid item xs={9} style={{ height: 70, paddingRight: 15 }}>
                                <TextField
                                  id='dsComentariosGerais'
                                  name='dsComentariosGerais'
                                  onBlur={value => {
                                    setFieldValue('dsComentariosGerais', value.target.value);
                                    var data = this.state.itemSelect;
                                    data.dsComentariosGerais = value.target.value;
                                  }}
                                  onChange={handleChange}
                                  value={values.dsComentariosGerais}
                                  label={provider.intl.formatMessage({ id: 'comentariosGerais' })}
                                  error={errors.dsComentariosGerais && touched.dsComentariosGerais}
                                  helperText={
                                    touched.dsComentariosGerais ? errors.dsComentariosGerais : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                          </MuiThemeProvider>
                        )
                      }
                    ]}
                    componentBelow={
                      <MuiThemeProvider theme={theme}>
                        <Grid container>
                          <Grid item xs className={classes.containerButtons}>
                            <div style={{ color: '#463766' }}>
                              <Button variant='contained' color='inherit' onClick={this.props.onClose}>
                                {provider.intl.formatMessage({ id: 'cancelar' })}
                              </Button>
                            </div>

                            <div>
                              <Button color='secondary' variant='contained' onClick={handleSubmit}>
                                {provider.intl.formatMessage({ id: 'salvar' })}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </MuiThemeProvider>
                    }
                  />
                </form>
              );
            }}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(EditarAnaliseDeRiscos);
