import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, Grow } from '@material-ui/core';
import { Close, ArrowForward, CloudDownload } from '@material-ui/icons';
import formatMessage from './i18n/formatMessage';
import { style } from './Galeria.styles';
import CardFilters from '@components/CardFilters/CardFilters';
import Loading from '@components/Loading/Loading';
import FilterUtil from '../../filterUtil';
import { findAllImagens, downloadZip } from '@resources/AtividadeFoto';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Chip from '@material-ui/core/Chip';
import Produtores from '@resources/Produtores';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import Comunidade from '@resources/Comunidade';
import Cultura from '@resources/Cultura';
import Safra from '@resources/Safra';
import swal from '@sweetalert/with-react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as moment from 'moment';
import Usuario from '@resources/Usuario';
import { getUserLang } from '@utils/localeUtils';
import ButtonGrid from '@components/ButtonGrid/ButtonGrid';
import Tooltip from '@material-ui/core/Tooltip';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const operationProdutorValues = [
  {
    label: formatMessage('galeria.produtor'),
    field: 'produtor',
    id: 'idProdutor',
    list: 'produtorList',
    type: 'autocomplete'
  },
  {
    label: formatMessage('galeria.cidade'),
    field: 'municipio',
    id: 'idMunicipio',
    list: 'municipioList',
    type: 'autocomplete'
  },
  {
    label: formatMessage('galeria.regiao'),
    field: 'regiao',
    id: 'idRegiao',
    list: 'regiaoList',
    type: 'autocomplete'
  },
  {
    label: formatMessage('galeria.comunidade'),
    field: 'comunidade',
    id: 'idComunidade',
    list: 'comunidadeList',
    type: 'autocomplete'
  },
  {
    label: formatMessage('galeria.safra'),
    field: 'safra',
    id: 'idSafra',
    list: 'safraList',
    type: 'autocomplete'
  },
  {
    label: formatMessage('galeria.data'),
    field: 'dtAtividade',
    type: 'date'
  }
];

const operationValuesTecnico = [
  {
    label: formatMessage('galeria.tecnico'),
    field: 'tecnico',
    id: 'idUsuario',
    list: 'tecnicoList',
    type: 'autocomplete'
  }
];

let operationValues = [
  {
    label: formatMessage('galeria.resumo'),
    field: 'dsAtividadeFoto',
    type: 'string'
  },
  {
    label: formatMessage('galeria.cultura'),
    field: 'cultura',
    id: 'idCultura',
    list: 'culturaList',
    type: 'autocomplete'
  },
  {
    label: formatMessage('galeria.cultivar'),
    field: 'nmCultivarEspec',
    type: 'string'
  }
];

/**
 * Tela de Galeria
 *
 * @author Gabriela Farias
 * @class Galeria
 * @extends {Component} - Componente React
 */
function Galeria(props) {
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fotoSelected, setFotoSelected] = useState(null);
  const [produtorList, setProdutorList] = useState([]);
  const [municipioList, setMunicipioList] = useState([]);
  const [regiaoList, setRegiaoList] = useState([]);
  const [culturaList, setCulturaList] = useState([]);
  const [comunidadeList, setComunidadeList] = useState([]);
  const [safraList, setSafraList] = useState([]);
  const [tecnicoList, setTecnicoList] = useState([]);
  const [filters, setFilters] = useState([]);
  const { classes } = props;

  useEffect(() => {
    const title = 'galeria.galeria';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    setFilters(filtrosValidation());

    Promise.all([
      Regioes.findAllRegiao()
        .then(query => {
          setRegiaoList(query.data);
        })
        .catch(() => {
          setRegiaoList([]);
        }),
      Produtores.findProdutor()
        .then(query => {
          setProdutorList(query.data);
        })
        .catch(() => {
          setProdutorList([]);
        }),
      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          setMunicipioList(query.data);
        })
        .catch(err => {
          setMunicipioList([]);
        }),
      Cultura.findAllCultura()
        .then(query => {
          setCulturaList(query.data);
        })
        .catch(err => {
          setCulturaList([]);
        }),
      Safra.findAllSafras()
        .then(query => {
          setSafraList(query.data);
        })
        .catch(err => {
          setSafraList([]);
        }),
      Comunidade.findAllComunidades()
        .then(query => {
          setComunidadeList(query.data);
        })
        .catch(err => {
          setComunidadeList([]);
        }),
      Usuario.findAllUsuarios()
        .then(query => {
          setTecnicoList(query.data.filter(usuario => usuario.tpPerfil === 2));
        })
        .catch(err => {
          setTecnicoList([]);
        }),
      onFilter(props.chipData[0].content)
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  function clickDownloadZip() {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: true,
        title: formatMessage('galeria.baixando')
      }
    );

    const chips = props.chipData[0].content;

    let idProdutor = chips.find(item => {
      return item.filter.field === 'produtor';
    });

    let idMunicipio = chips.find(item => {
      return item.filter.field === 'municipio';
    });

    let idRegiao = chips.find(item => {
      return item.filter.field === 'regiao';
    });

    let idSafra = chips.find(item => {
      return item.filter.field === 'safra';
    });

    let idComunidade = chips.find(item => {
      return item.filter.field === 'comunidade';
    });

    let dsResumo = chips.find(item => {
      return item.filter.field === 'dsAtividadeFoto';
    });

    let idCultura = chips.find(item => {
      return item.filter.field === 'cultura';
    });

    let nmCultivar = chips.find(item => {
      return item.filter.field === 'nmCultivarEspec';
    });

    let idUsuario = chips.find(item => {
      return item.filter.field === 'tecnico';
    });

    let dtData = '';
    chips.forEach(item => {
      if (item.filter.field === 'dtAtividade') {
        let itemOperacao = item.operation;
        if (itemOperacao === '==') {
          itemOperacao = ':';
        } else if (itemOperacao === '!=') {
          itemOperacao = '<>';
        }

        dtData = dtData + 'dtData' + itemOperacao + moment(item.value).format('YYYY-MM-DD') + ';';
      }
    });

    idProdutor = idProdutor !== undefined ? 'idProdutor:' + idProdutor.value.value + ';' : '';
    idMunicipio = idMunicipio !== undefined ? 'idMunicipio:' + idMunicipio.value.value + ';' : '';
    idRegiao = idRegiao !== undefined ? 'idRegiao:' + idRegiao.value.value + ';' : '';
    idComunidade = idComunidade !== undefined ? 'idComunidade:' + idComunidade.value.value + ';' : '';
    idSafra = idSafra !== undefined ? 'idSafra:' + idSafra.value.value + ';' : '';
    dsResumo = dsResumo !== undefined ? 'dsResumo:' + dsResumo.value + ';' : '';
    idCultura = idCultura !== undefined ? 'idCultura:' + idCultura.value.value + ';' : '';
    nmCultivar = nmCultivar !== undefined ? 'idCultivar:' + nmCultivar.value + ';' : '';
    idUsuario = idUsuario !== undefined ? 'idUsuario:' + idUsuario.value.value + ';' : '';

    const filtersZip =
      idProdutor +
      idMunicipio +
      idRegiao +
      idComunidade +
      idSafra +
      dtData +
      dsResumo +
      idCultura +
      nmCultivar +
      idUsuario;

    return downloadZip(filtersZip)
      .then(async data => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'galeria.zip');
        document.body.appendChild(link);
        link.click();
        link.remove();

        swal.close();
      })
      .catch(err => {
        swal.close();

        swal(formatMessage('galeria.erroDownloadArquivos'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  function onFilter(dataChips) {
    return findAllImagens()
      .then(query => {
        const dataFormated = query.data.map(item => {
          item.produtor = item.atividade.produtor;
          item.municipio = item.atividade.produtor.municipio;
          item.cultura = item.atividade.plantio.cultura;
          item.nmCultivarEspec = item.atividade.plantio.cultivarEspec.nmCultivarEspec;
          item.comunidade = item.atividade.produtor.comunidade;
          item.dsAtividadeFoto = item.dsAtividadeFoto || '';
          item.dtAtividade = item.atividade.dtAtividade;
          item.tecnico = item.atividade.produtor.tecnico;

          if (!item.atividade.produtor.comunidade) {
            item.comunidade = {
              idComunidade: ''
            };
          }

          if (!item.regiao.idRegiao) {
            item.regiao.idRegiao = '';
          }

          if (!item.safra) {
            item.safra = {
              idSafra: ''
            };
          }

          return item;
        });

        setAllData(dataFormated);
        setFotoSelected(null);
        setFilteredData(FilterUtil.applyFilters(dataFormated, dataChips || []));
      })
      .catch(() => {
        swal(formatMessage('galeria.erroBuscarGaleria'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  function onFilterChanged(dataChips) {
    setFotoSelected(null);
    setFilteredData(FilterUtil.applyFilters(allData, dataChips || []));
  }

  function filtrosValidation() {
    if (props.infoUsuario.tpPerfil === 3) {
      return operationValues;
    }

    if (props.infoUsuario.tpPerfil === 1) {
      return [...operationProdutorValues, ...operationValuesTecnico, ...operationValues];
    }

    return [...operationProdutorValues, ...operationValues];
  }

  return (
    <div className={classes.filters}>
      {loading && <Loading />}

      <div style={{ marginBottom: 35 }}>
        <CardFilters
          autoComplete={{
            produtorList: {
              list: produtorList,
              campoOp: 'nmProdutor',
              campoChave: 'idProdutor'
            },
            regiaoList: {
              list: regiaoList,
              campoOp: 'nmRegiao',
              campoChave: 'idRegiao'
            },
            municipioList: {
              list: municipioList,
              campoOp: 'nmMunicipio',
              campoChave: 'idMunicipio'
            },
            culturaList: {
              list: culturaList,
              campoOp: 'nmCultura',
              campoChave: 'idCultura'
            },
            comunidadeList: {
              list: comunidadeList,
              campoOp: 'nmComunidade',
              campoChave: 'idComunidade'
            },
            safraList: {
              list: safraList,
              campoOp: 'dsSafra',
              campoChave: 'idSafra'
            },
            tecnicoList: {
              list: tecnicoList,
              campoOp: 'nmUsuario',
              campoChave: 'idUsuario'
            }
          }}
          filtros={filters}
          onFilter={onFilter}
          onFilterChanged={onFilterChanged}
          usePaddings={false}
          viewKey='galeria'
        />
      </div>

      <div className={classes.cardCollapse}>
        <div className={classes.iconZip}>
          <ButtonGrid
            iconSVG
            show={fotoSelected === null}
            icon={
              <Tooltip title={formatMessage('galeria.baixarImagens')}>
              <CloudDownload
                style={{ color: 'rgb(255, 255, 255)', width: 18, height: 18 }}
                
              />
              </Tooltip>
            }
            color='rgb(66, 173, 232)'
            onClick={() => clickDownloadZip()}
          />
        </div>
        <div className={classes.cardAllFotos} style={fotoSelected && { filter: 'blur(2px)' }}>
          {filteredData.map(foto => (
            <div key={foto.idAtividadeFoto} className={classes.cardFoto}>
              <img
                className={classes.image}
                src={foto.dsThumb}
                alt='Imagem'
                onClick={() =>
                  fotoSelected && fotoSelected.idAtividadeFoto === foto.idAtividadeFoto
                    ? setFotoSelected(null)
                    : setFotoSelected(foto)
                }
              />
              <div className={classes.descricaoFoto}>{foto.dsAtividadeFoto}</div>
            </div>
          ))}
        </div>

        <Grow in={fotoSelected !== null} className={fotoSelected && classes.fotoSelected}>
          <div>
            {fotoSelected && (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div className={classes.closeFotoDestaque}>
                    <a href={fotoSelected.dsFoto} download target='blank'>
                      <ArrowForward style={{ transform: 'rotate(-45deg)' }} className={classes.icon} />
                    </a>
                  </div>
                  <div className={classes.closeFotoDestaque}>
                    <Close className={classes.icon} onClick={() => setFotoSelected(null)} />
                  </div>
                </div>
                <div className={classes.cardFotoSelected}>
                  <img src={fotoSelected.dsFoto} alt='Imagem' className={classes.fotoDestaque} />
                </div>
              </>
            )}
          </div>
        </Grow>

        <div className={fotoSelected ? classes.cardDetalhesOpen : classes.cardDetalhesClose}>
          {fotoSelected && (
            <div className={classes.cardDetalhes}>
              <TextField
                value={fotoSelected && fotoSelected.atividade.produtor.nmProdutor}
                label={formatMessage('galeria.produtor')}
                className={classes.textField}
              />
              <div className={classes.textDetalhes}>
                <div className={classes.textLabel}>{formatMessage('galeria.cidade') + ':'}</div>
                <div className={classes.textInformation}>
                  {fotoSelected.atividade.produtor.municipio.nmMunicipio}
                </div>
              </div>

              <div className={classes.textDetalhes}>
                <div className={classes.textLabel}>{formatMessage('galeria.comunidade') + ':'}</div>
                <div className={classes.textInformation}>
                  {fotoSelected.atividade.produtor.comunidade &&
                    fotoSelected.atividade.produtor.comunidade.nmComunidade}
                </div>
              </div>

              <div className={classes.textDetalhes}>
                <div className={classes.textLabel}>{formatMessage('galeria.regiao') + ':'}</div>
                <div className={classes.textInformation}>
                  {fotoSelected.regiao && fotoSelected.regiao.nmRegiao}
                </div>
              </div>

              {props.infoUsuario.tpPerfil !== 2 && (
                <div className={classes.textDetalhes}>
                  <div className={classes.textLabel}>{formatMessage('galeria.tecnico') + ':'}</div>
                  <div className={classes.textInformation}>
                    {fotoSelected.atividade.produtor.tecnico.nmUsuario}
                  </div>
                </div>
              )}

              <div className={classes.textDetalhes}>
                <div className={classes.textLabel}>{formatMessage('galeria.areaCultivo') + ':'}</div>
                <div className={classes.textInformation}>
                  {fotoSelected.atividade.plantio.areaCultivo.dsAreaCultivo}
                </div>
              </div>

              <div className={classes.textDetalhes}>
                <div className={classes.textLabel}>{formatMessage('galeria.cultura') + ':'}</div>
                <div className={classes.textInformation}>
                  {fotoSelected.atividade.plantio.cultura.nmCultura}
                </div>
              </div>

              <div className={classes.textDetalhes}>
                <div className={classes.textLabel}>{formatMessage('galeria.cultivar') + ':'}</div>
                <div className={classes.textInformation}>
                  {fotoSelected.atividade.plantio.cultivarEspec.nmCultivarEspec}
                </div>
              </div>

              <div className={classes.textDetalhes}>
                <div className={classes.textLabel}>{formatMessage('galeria.safra') + ':'}</div>
                <div className={classes.textInformation}>
                  {fotoSelected.safra && fotoSelected.safra.dsSafra}
                </div>
              </div>

              <div className={classes.textDetalhes}>
                <div className={classes.textLabel}>{formatMessage('galeria.data') + ':'}</div>
                <div className={classes.textInformation}>
                  {moment(fotoSelected.atividade.dtAtividade).format(
                    getUserLang() === 'en-US' ? 'MM-DD-YY' : 'DD/MM/YY'
                  )}
                </div>
              </div>

              <div className={classes.textDetalhesDescricao}>
                <div className={classes.textLabel}>{formatMessage('galeria.descricao') + ':'}</div>
                <div className={classes.detalhesImage}>{fotoSelected.dsAtividadeFoto}</div>
              </div>

              <div className={classes.cardImageDetalhes}>
                <img src={fotoSelected.dsThumb} alt='Imagem' className={classes.fotoDetalhes} />
              </div>

              <div className={classes.cardLabelChips}>
                <div className={classes.textLabel} style={{ marginTop: 15 }}>
                  {formatMessage('galeria.talhoesUtilizados')}
                </div>
                <div className={classes.cardChips}>
                  {fotoSelected.atividade.atividadeTalhaoList.map((tal, i) => (
                    <Chip
                      label={tal.talhao.dsTalhao}
                      variant='outlined'
                      key={i}
                      className={classes.chip}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'galeria';
  })
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(Galeria)));
