import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import DatePicker from '@components/DatePicker/DatePicker';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment';
import Card from '@components/Card/Card';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Raca from '@resources/Raca';
import Especie from '@resources/Especie';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = theme => ({
  textField: {
    width: '100%',
    height: 70
  },
  root: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    marginTop: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiTypography: {
      headline: {
        color: '#555555',
        fontSize: 24
      },
      h5: {
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.7)'
      }
    },
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#008000'
        }
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    default: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente para cadastro de produção animal
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
class EditarProducaoAnimal extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const especie = messagesLinguage['produtores.especie'];
    const quantidade = messagesLinguage['produtores.nrQuantidade'];
    const tipo = messagesLinguage['produtores.tipo'];
    const organico = messagesLinguage['produtores.organico'];
    const convencional = messagesLinguage['produtores.convencional'];
    const excluir = messagesLinguage['produtores.excluir'];
    const cancelar = messagesLinguage['produtores.cancelar'];
    const confirmar = messagesLinguage['produtores.confirmar'];
    const campoObrigatorio = messagesLinguage['produtores.campoObrigatorio'];
    const gado = messagesLinguage['produtores.gado'];
    const frango = messagesLinguage['produtores.frango'];
    const editarProducaoAnimal = messagesLinguage['produtores.editarProducaoAnimal'];
    const adicionarProducaoAnimal = messagesLinguage['produtores.adicionarProducaoAnimal'];
    const salvar = messagesLinguage['produtores.salvar'];
    const raca = messagesLinguage['produtores.raca'];
    const qtda = messagesLinguage['produtores.qtda'];
    const dataLancamento = messagesLinguage['produtores.dataLancamento'];
    const producaoAnimal = messagesLinguage['produtores.producaoAnimal'];
    const cadastroProducaoAnimal = messagesLinguage['produtores.cadastroProducaoAnimal'];
    const edicaoProducaoAnimal = messagesLinguage['produtores.edicaoProducaoAnimal'];
    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          edicaoProducaoAnimal,
          cadastroProducaoAnimal,
          producaoAnimal,
          especie,
          quantidade,
          tipo,
          organico,
          convencional,
          excluir,
          cancelar,
          confirmar,
          campoObrigatorio,
          frango,
          gado,
          editarProducaoAnimal,
          adicionarProducaoAnimal,
          salvar,
          raca,
          qtda,
          dataLancamento
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      itemSelect: {},
      especie: '',
      raca: '',
      listEspecies: [],
      listRaca: [],
      keyAutoCompleteEspecie: Math.random(),
      keyAutoCompleteRaca: Math.random()
    };

    this.onClose = this.onClose.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChangeEspecie = this.onChangeEspecie.bind(this);
    this.loadRaca = this.loadRaca.bind(this);
    this.loadEspecie = this.loadEspecie.bind(this);
    this.tratarValorEspecie = this.tratarValorEspecie.bind(this);
    this.tratarValorRaca = this.tratarValorRaca.bind(this);
    this.onChangeRaca = this.onChangeRaca.bind(this);
  }

  componentDidMount() {
    if (this.props.statusEdit) {
      this.setState({
        itemSelect: this.props.itemSelectProducao,
        raca: this.props.itemSelectProducao.racaAnimal,
        especie: this.props.itemSelectProducao.racaAnimal.especieAnimal,
        keyAutoCompleteEspecie: Math.random(),
        keyAutoCompleteRaca: Math.random()
      });
      this.loadRaca(this.props.itemSelectProducao.racaAnimal.especieAnimal);
    }

    this.loadEspecie();
  }

  onClose() {
    this.props.onClose();
  }

  clickExcluir() {
    this.props.clickExcluir(this.state.itemSelect);
  }

  onSave(values, { resetForm }) {
    values.dtLancamento = moment(values.dtLancamento).format('YYYY-MM-DD');
    values.nmTipo =
      values.tpProducao === 0
        ? provider.intl.formatMessage({
            id: 'organico'
          })
        : provider.intl.formatMessage({
            id: 'convencional'
          });
    values.racaAnimal = this.state.raca;

    if (this.props.statusEdit) {
      var position = this.props.dataProducaoAnimal.indexOf(this.props.itemSelectProducao);
      values.stRegistro = this.state.itemSelect.stRegistro;
      values.idProducaoAnimal = this.state.itemSelect.idProducaoAnimal;
      this.props.onSaveProducao(values, position);
    } else {
      values.stRegistro = 0;
      this.props.onSaveProducao(values);
    }
  }

  onChangeEspecie(value = '') {
    value && this.loadRaca(value);

    this.setState({
      keyAutoCompleteEspecie: Math.random(),
      keyAutoCompleteRaca: Math.random(),
      especie: value
    });
  }

  onChangeRaca(value = '') {
    this.setState({
      keyAutoCompleteRaca: Math.random(),
      raca: value
    });
  }

  loadEspecie() {
    Especie.findAllEspecie()
      .then(res =>
        this.setState({
          listEspecies: res.data
        })
      )
      .catch(() =>
        this.setState({
          listEspecies: []
        })
      );
  }

  loadRaca(value) {
    if (value) {
      Raca.findRacaByEspecie(value.idEspecieAnimal)
        .then(res =>
          this.setState({
            listRaca: res.data
          })
        )
        .catch(() =>
          this.setState({
            listRaca: []
          })
        );
    } else {
      this.setState({
        listEspecies: []
      });
    }
  }

  tratarValorEspecie() {
    if (!this.state.especie || this.state.especie === undefined) {
      return '';
    }

    return this.state.especie;
  }

  tratarValorRaca() {
    if (!this.state.raca || this.state.raca === undefined) {
      return '';
    }

    return this.state.raca;
  }

  render() {
    const { classes } = this.props;

    return (
      <Formik
        initialValues={{
          dtLancamento: this.state.itemSelect.dtLancamento || moment(),
          tpEspecie: this.state.especie,
          nrQuantidade: this.state.itemSelect.nrQuantidade || '',
          tpProducao:
            this.state.itemSelect.tpProducao !== undefined ? this.state.itemSelect.tpProducao : '',
          nmRaca: this.state.raca
        }}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={this.onSave}
        validationSchema={Yup.object().shape({
          tpEspecie: Yup.string().required(
            provider.intl.formatMessage({
              id: 'campoObrigatorio'
            })
          ),
          nrQuantidade: Yup.string().required(
            provider.intl.formatMessage({
              id: 'campoObrigatorio'
            })
          ),
          tpProducao: Yup.string().required(
            provider.intl.formatMessage({
              id: 'campoObrigatorio'
            })
          ),
          nmRaca: Yup.string().required(
            provider.intl.formatMessage({
              id: 'campoObrigatorio'
            })
          ),
          dtLancamento: Yup.string()
            .nullable()
            .required(
              provider.intl.formatMessage({
                id: 'campoObrigatorio'
              })
            )
        })}
        render={({ values, handleSubmit, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <MuiThemeProvider theme={theme}>
            <Card
              withOutBorderRadius
              title={provider.intl.formatMessage({
                id: this.props.statusEdit ? 'edicaoProducaoAnimal' : 'cadastroProducaoAnimal'
              })}
              height='auto'
              childrenStyle={{}}
              style={{ marginBottom: 35, height: '302px' }}
            >
              <div
                style={{
                  marginBottom: 15,
                  display: 'contents',
                  width: '100%',
                  height: '100%'
                }}
              >
                <form autoComplete='off'>
                  <Grid container spacing={16}>
                    <Grid item xs={2}>
                      <DatePicker
                        locale={getUserLang()}
                        className={classes.textField}
                        label={provider.intl.formatMessage({ id: 'dataLancamento' })}
                        valueDate={values.dtLancamento}
                        onBlurDatePicker={value => {
                          setFieldValue('dtLancamento', value.target.value);
                          var item = this.state.itemSelect;
                          item.dtLancamento = value.target.value;

                          this.setState({
                            itemSelect: item
                          });
                        }}
                        onChangeDatePicker={value => {
                          setFieldValue('dtLancamento', value);

                          var item = this.state.itemSelect;
                          item.dtLancamento = value;

                          this.setState({
                            itemSelect: item
                          });
                        }}
                        errorTouched={errors.dtLancamento && touched.dtLancamento ? true : false}
                        helperText={
                          errors.dtLancamento && touched.dtLancamento ? errors.dtLancamento : ''
                        }
                      />
                    </Grid>

                    <Grid item xs={2} style={{ paddingRight: 15 }}>
                      <Autocomplete
                        itens={this.state.listEspecies}
                        campoOp='dsEspecieAnimal'
                        campoChave='idEspecieAnimal'
                        label={provider.intl.formatMessage({ id: 'especie' })}
                        name='especie'
                        error={!this.state.especie && touched.tpEspecie}
                        helperText={
                          touched.tpEspecie && !this.state.especie
                            ? provider.intl.formatMessage({
                                id: 'campoObrigatorio'
                              })
                            : null
                        }
                        onChangeOwn={this.onChangeEspecie}
                        onChangeAutoComplete={this.loadEspecie}
                        value={this.tratarValorEspecie()}
                        key={this.state.keyAutoCompleteEspecie}
                        valueAutoComplete={this.tratarValorEspecie()}
                        valueSelect={this.tratarValorEspecie().dsEspecieAnimal}
                        maxHeight='calc(100vh - 250px)'
                      />
                    </Grid>

                    <Grid item xs={2} style={{ paddingRight: 15 }}>
                      <Autocomplete
                        disabled={this.state.especie === '' || this.state.especie === null}
                        itens={this.state.listRaca}
                        campoOp='dsRacaAnimal'
                        campoChave='idRacaAnimal'
                        label={provider.intl.formatMessage({ id: 'raca' })}
                        name='raca'
                        error={!this.state.raca && touched.nmRaca}
                        helperText={
                          touched.nmRaca && !this.state.raca
                            ? provider.intl.formatMessage({
                                id: 'campoObrigatorio'
                              })
                            : null
                        }
                        onChangeOwn={this.onChangeRaca}
                        onChangeAutoComplete={this.loadRaca}
                        value={this.tratarValorRaca()}
                        key={this.state.keyAutoCompleteRaca}
                        valueAutoComplete={this.tratarValorRaca()}
                        valueSelect={this.tratarValorRaca().dsRacaAnimal}
                        maxHeight='calc(100vh - 250px)'
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <TextFieldWithMask
                        tipoMascara={3}
                        numberDecimalScale={0}
                        showThousandSeparator={true}
                        id='nrQuantidade'
                        name='nrQuantidade'
                        onChange={value => {
                          setFieldValue('nrQuantidade', value.target.value);

                          var item = this.state.itemSelect;
                          item.nrQuantidade = value.target.value;

                          this.setState({
                            itemSelect: item
                          });
                        }}
                        onBlur={value => {
                          if (value.target.value !== '') {
                            setFieldValue('nrQuantidade', value.target.value);
                          } else {
                            setFieldValue('nrQuantidade', '');
                          }

                          var item = this.state.itemSelect;
                          item.nrQuantidade = value.target.value;

                          this.setState({
                            itemSelect: item
                          });
                        }}
                        value={values.nrQuantidade}
                        label={provider.intl.formatMessage({
                          id: 'qtda'
                        })}
                        className={classes.textField}
                        InputLabelProps={{
                          className: classes.font
                        }}
                        error={errors.nrQuantidade && touched.nrQuantidade}
                        helperText={touched.nrQuantidade ? errors.nrQuantidade : ''}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <MuiThemeProvider theme={theme}>
                        <FormControl
                          error={errors.tpProducao && touched.tpProducao}
                          style={{ width: '100%' }}
                        >
                          <InputLabel
                            className={classes.textField}
                            htmlFor='tpProducao'
                            error={errors.tpProducao && touched.tpProducao}
                          >
                            {provider.intl.formatMessage({
                              id: 'tipo'
                            })}
                          </InputLabel>

                          <Select
                            value={values.tpProducao}
                            error={errors.tpProducao && touched.tpProducao}
                            onChange={value => {
                              setFieldValue('tpProducao', value.target.value);
                              var item = this.state.itemSelect;
                              item.tpProducao = value.target.value;

                              this.setState({
                                itemSelect: item
                              });
                            }}
                            onBlur={value => {
                              setFieldValue('tpProducao', value.target.value);
                              var item = this.state.itemSelect;
                              item.tpProducao = value.target.value;

                              this.setState({
                                itemSelect: item
                              });
                            }}
                            name='tpProducao'
                            id='tpProducao'
                          >
                            {[
                              provider.intl.formatMessage({
                                id: 'organico'
                              }),
                              provider.intl.formatMessage({
                                id: 'convencional'
                              })
                            ].map((item, i) => (
                              <MenuItem key={i} value={i}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.tpProducao && touched.tpProducao && (
                            <FormHelperText>{errors.tpProducao}</FormHelperText>
                          )}
                        </FormControl>
                      </MuiThemeProvider>
                    </Grid>
                  </Grid>
                </form>

                <Grid container>
                  <Grid item xs className={classes.containerButtons}>
                    <div style={{ color: '#463766' }}>
                      <Button variant='contained' color='inherit' onClick={this.props.onClose}>
                        {provider.intl.formatMessage({ id: 'cancelar' })}
                      </Button>
                    </div>

                    <div>
                      <Button color='secondary' variant='contained' onClick={handleSubmit}>
                        {provider.intl.formatMessage({ id: 'salvar' })}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </MuiThemeProvider>
        )}
      />
    );
  }
}

export default withStyles(styles)(EditarProducaoAnimal);
