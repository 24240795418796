export default {
  certificacao: {
    erroAoBuscarDados: 'Error to fetch data',
    agencia: 'Office',
    desejaExcluirAgencia: 'Do you want to delete this office?',
    nao: 'Not',
    excluir: 'Delete',
    agenciaExcluida: 'Office successfully deleted',
    erroAoExcluir: 'There was an error deleting the agency',
    cadastroDeAgencia: 'Agency registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    salvar: 'Salve',
    cancelar: 'Cancel',
    agenciaSalvoComSucesso: 'Office successfully saved',
    erroAoSalvar: 'Error saving office',
    salvando: 'Saving',
    atualizando: 'Updating',
    nome: 'Name',
    codigo: 'Code',
    estado: 'State',
    cidade: 'City',
    ativo: 'Active',
    inativo: 'Inactive',
    status: 'Status',
    codigoExistente: 'Existing Agency Code',
    ocorreuErroSalvar: 'There was an error saving'
  }
};
