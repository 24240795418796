import React, { useState, useEffect } from 'react';
import CardFiltersOpen from './CardFiltersOpen';
import CardFiltersClosed from './CardFiltersClosed';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import { connect } from 'react-redux';
import './CardFilters.css';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

/**
 * author: Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {CardsWithFilters} props
 */
function CardsWithFilters(props) {
  const [index, setIndex] = useState(0);
  const [listChecks, setListChecks] = useState([]);

  useEffect(() => {
    if (props.abaWithChecks) {
      setListChecks(props.itens[1].listChecks);
    }
  }, []);

  return (
    <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
      <CardWithTabs
        cardProps={{
          expandedButton: value => {
            if (!value) {
              setIndex(0);
            }
          },
          paddingLaterais: true,
          disableHeader: false,
          errorCard: props.errorCard,
          closeCard: props.closeCard,
          withoutMarginTop: true,
          usePaddings: props.usePaddings,
          stateCardblock: true,
          logoUrl: props.logoURL,
          filtros: true,
          header: (
            <div style={{ width: '100%' }}>
              {(props.itens[0].contain.length > 0 || props.itens[1].contain.length === 0) && (
                <CardFiltersClosed {...props.itens[0]} />
              )}
              {props.itens[1].contain.length > 0 && <CardFiltersClosed {...props.itens[1]} />}
            </div>
          ),
          titulo: props.relatorio ? (
            <FormattedMessage id='filtrosDeListagem.gerarRelatorio' />
          ) : (
            <FormattedMessage id='filtrosDeListagem.filtros' />
          )
        }}
        withoutHidden
        containerSwipeableStyles={{ overflow: 'inherit' }}
        selectedIndex={index}
        onChangeTabIndex={(e, tabIndex) => setIndex(tabIndex)}
        tabs={[
          {
            label: <FormattedMessage id='filtrosDeListagem.filtros' />,
            component: (
              <div style={{ paddingRight: 30, paddingLeft: 30 }}>
                <CardFilter
                  key={props.itens[0].keyCard}
                  expandedButton={value => {
                    if (!value) {
                      setIndex(0);
                    }
                  }}
                  {...props.itens[0]}
                />
              </div>
            )
          },
          abaComponent(props)
        ]}
      />
    </IntlProvider>
  );

  function abaComponent(props) {
    if (props.abaRadioGroup) {
      const item = props.itens[1];

      return {
        label: <FormattedMessage id='filtrosDeListagem.impressao' />,
        component: (
          <div style={{ paddingRight: 30, paddingLeft: 30, height: 137 }}>
            <CardFiltersOpen
              radioButton
              expandedButton={value => {
                if (!value) {
                  setIndex(0);
                }
              }}
              withoutLabelTipo={item.withoutLabelTipo}
              key={item.keyCard}
              allViewKey={item.allViewKey}
              keyCard={item.keyCard}
              withoutButtonFiltrar={item.withoutButtonFiltrar}
              autoComplete={item.autoComplete || []}
              onClickButton={item.onClickButton}
              relatorio={item.relatorio}
              filtros={item.filtros}
              onFilter={item.onFilter}
              viewKey={item.viewKey}
              onFilterChanged={item.onFilterChanged}
            >
              <div style={{ width: '100%', paddingTop: 35 }}>{props.abaRadioGroup}</div>
            </CardFiltersOpen>
          </div>
        )
      };
    } else if (props.abaWithChecks) {
      return {
        label: <FormattedMessage id='filtrosDeListagem.impressao' />,
        component: (
          <div style={{ paddingRight: 30, paddingLeft: 30, height: 137 }}>
            <CardCheck
              key={props.itens[1].keyCard}
              expandedButton={value => {
                if (!value) {
                  setIndex(0);
                }
              }}
              setListChecks={setListChecks}
              listChecks={listChecks}
              {...props.itens[1]}
            />
          </div>
        )
      };
    } else {
      return {
        label: <FormattedMessage id='filtrosDeListagem.ordenacao' />,
        component: (
          <div style={{ paddingRight: 30, paddingLeft: 30 }}>
            <CardFilter
              key={props.itens[1].keyCard}
              expandedButton={value => {
                if (!value) {
                  setIndex(0);
                }
              }}
              {...props.itens[1]}
            />
          </div>
        )
      };
    }
  }
}

function CardFilter(props) {
  return (
    <CardFiltersOpen
      radioButton={props.radioButton}
      expandedButton={props.expandedButton}
      withoutLabelTipo={props.withoutLabelTipo}
      key={props.keyCard}
      allViewKey={props.allViewKey}
      keyCard={props.keyCard}
      withoutButtonFiltrar={props.withoutButtonFiltrar}
      autoComplete={props.autoComplete || []}
      onClickButton={props.onClickButton}
      relatorio={props.relatorio}
      filtros={props.filtros}
      onFilter={props.onFilter}
      viewKey={props.viewKey}
      onFilterChanged={props.onFilterChanged}
    />
  );
}

function CardCheck(props) {
  const clickCheck = doc => {
    doc.check = !doc.check;
    if (doc.key === 1) {
      if (!doc.notDepends) {
        props.listChecks[1].disabled = !doc.check;
      }

      if (!doc.check && !doc.notDepends) {
        props.listChecks[1].check = false;
      }
    }
    props.setListChecks({ ...props.listChecks });
  };

  return (
    <CardFiltersOpen
      expandedButton={props.expandedButton}
      withoutLabelTipo={props.withoutLabelTipo}
      key={props.keyCard}
      allViewKey={props.allViewKey}
      keyCard={props.keyCard}
      withoutButtonFiltrar={props.withoutButtonFiltrar}
      autoComplete={props.autoComplete || []}
      onClickButton={props.onClickButton}
      relatorio={props.relatorio}
      filtros={props.filtros}
      onFilter={props.onFilter}
      viewKey={props.viewKey}
      onFilterChanged={props.onFilterChanged}
    >
      <div style={{ width: '100%', paddingTop: 30 }}>
        <Grid container spacing={16}>
          {props.listChecks.map(doc => (
            <Grid
              key={doc.key}
              item
              xs={doc.grid || 4}
              style={{
                padding: 0,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Checkbox
                disabled={doc.disabled}
                onChange={() => clickCheck(doc)}
                checked={doc.check}
                value='checkedB'
                color='secondary'
              />
              <label
                disabled={doc.disabled}
                onClick={() => {
                  if (!doc.disabled) clickCheck(doc);
                }}
                style={{
                  color: doc.disabled ? 'rgb(0, 0, 0, 0.6)' : '#000000',
                  cursor: doc.disabled ? 'default' : 'pointer',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {doc.title}
              </label>
            </Grid>
          ))}
        </Grid>
      </div>
    </CardFiltersOpen>
  );
}

const mapStateToProps = state => ({
  chipDataInt: state.chips.chipData
});

export default connect(mapStateToProps)(CardsWithFilters);
