export default {
  fertilizantesQuimicos: {
    fertilizantesQuimicos: 'Fertilizantes quimicos',
    cadastroDeFertilizantesQuimicos: 'Registro fertilizantes quimicos',
    fertilizantesQuimicosRemovidoComSucesso: 'Fertilizante quimico quitado con éxito',
    falhaAoExcluirFertilizantesQuimicos: 'Fallo la excluir fertilizante quimico',
    desejaExcluir: '¿Desea eliminar el fertilizante quimico seleccionada?',
    identificacaoDaFertilizantesQuimicos: 'Identificación',
    fertilizantesQuimicosadastradoComSucesso: 'Fertilizante quimico registrada con éxito',
    fertilizantesQuimicosEditadoComSucesso: 'Fertilizante quimico editado con éxito',
    falhaAoEditarFertilizantesQuimicos: 'Falha ao editar fertilizante quimico',
    falhaAoCadastrarFertilizantesQuimicos: 'Error al editar fertilizante quimico',
    falhaAoCarregarFertilizantesQuimicos: 'Error al cargar fertilizante quimico'
  }
};
