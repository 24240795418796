export default {
  mapandvi: {
    filtro: 'Filtrar por productor',
    campoObrigatorio: 'Campo obligatorio',
    produtor: 'Productor',
    talhao: 'Parcela',
    area: 'Area',
    produtorDuplicado: 'Productor duplicado',
    talhaoDuplicado: 'Parcela duplicado',
    mapa: 'Mapas',
    mapandvi: 'Mapas NDVI',
    falhaBuscarImagens: 'Error al buscar imágenes',
    dia: 'Fecha',
    imagem: 'Imaje',
    formatoData: 'DD/MM/YYYY',
    talhoesSemImagemProdutor:
      'Las tramas de este productor no tienen imágenes.',
    talhaoSemImagem: 'Esta trama no tiene imagen.'
  }
};
