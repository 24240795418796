export default {
  fungicidaMicroBio: {
    fungicidaMicroBioRemovidoComSucesso: 'Microbiological fungicides successfully removed',
    falhaAoExcluirFungicidaMicroBio: 'Failed to exclude microbiological fungicides',
    desejaExcluir: 'Do you want to delete the selected microbiological fungicides?',
    identificacaoDaFungicidaMicroBio: 'Identification',
    fungicidaMicroBioCadastradoComSucesso: 'Microbiological fungicides successfully registered',
    fungicidaMicroBioEditadoComSucesso: 'Microbiological fungicides edited successfully',
    falhaAoEditarFungicidaMicroBio: 'Failed to edit microbiological fungicides',
    falhaAoCadastrarFungicidaMicroBioM: 'Failed to register microbiological fungicides',
    falhaAoCarregarFungicidaMicroBio: 'Failed to load microbiological fungicides',
    fungicidaMicroBio: 'Microbiological fungicides',
    cadastroDeFungicidaMicroBio: 'Microbiological fungicides register'
  }
};
