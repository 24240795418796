import React from 'react';
import { style } from './../AcompanhamentoProdutor.styles';
import { withStyles } from '@material-ui/core/styles';
import ListDetalhesAuditoria from '@components/ListDetalhes/ListDetalhesAuditoria';
import ListDetalhesRegistros from '@components/ListDetalhes/ListDetalhesRegistros';
import ListDetalhesPlantios from '@components/ListDetalhes/ListDetalhesPlantios';
import ListDetalhesAplicacao from '@components/ListDetalhes/ListDetalhesAplicacao';
import ChipsTalhao from './ChipsTalhao';
import ChipTalhaoMonitoramento from './ChipTalhaoMonitoramento';
import ProdutoDescricaoMonitoramento from './ProdutoDescricaoMonitoramento';
import ProdutoDescricao from './ProdutoDescricao';
import AlertaDescricao from './AlertaDescricao';
import TipoDetalhesEnum from './../enumerations/TipoDetalhesEnum';
import formatMessage from './../i18n/formatMessage';
import TipoItemTimelineEnum from './../enumerations/TipoItemTimelineEnum';

/**
 * Busca o card de descrição de acordo com o tipo selecionado
 *
 * @returns - card de descrição
 */
function CardDescricao(props) {
  const { tipoInfo, limpar, timelineList, position, stProdutor } = props;

  switch (tipoInfo) {
    case TipoDetalhesEnum.APLICACAO:
      return (
        <ListDetalhesAplicacao
          listMonitoramento={produtoDescricaoMonitoramentoComponent}
          listAlertas={alertaDescricaoComponent}
          listaProdutos={produtoDescricaoComponent}
          limpar={limpar}
          timeLine={timelineList}
          chipsTalhoes={chipTalhaoComponent}
          chipsTalhoesMonitoramento={chipTalhaoMonitoramentoComponent}
          position={position}
        />
      );
    case TipoDetalhesEnum.PLANTIO:
      return (
        <ListDetalhesPlantios
          limpar={limpar}
          timeLine={timelineList}
          chipsTalhoes={chipTalhaoComponent}
          position={position}
        />
      );
    case TipoDetalhesEnum.REGISTROS:
      return (
        <ListDetalhesRegistros
          limpar={limpar}
          timeLine={timelineList}
          chipsTalhoes={chipTalhaoComponent}
          position={position}
          labelTextFieldAcompanhamento={formatMessage(getLabelAcompanhamento())}
        />
      );
    case TipoDetalhesEnum.AUDITORIA:
      return <ListDetalhesAuditoria itemTimeline={timelineList[position]} limpar={limpar} />;
    default:
      return <div />;
  }

  /**
   * Chama o componente de lista de alertas
   *
   * @param {*} listaProduto - lista de produtos
   * @returns - componente montado
   */
  function alertaDescricaoComponent(listaProduto) {
    return <AlertaDescricao produtoList={limpar ? [] : listaProduto} />;
  }

  /**
   * Chama o componente de lista de produtos
   *
   * @param {*} produtosList - lista de produtos
   * @returns - componente montado
   */
  function produtoDescricaoComponent(produtosList) {
    return <ProdutoDescricao produtoList={limpar ? [] : produtosList} stProdutor={stProdutor} />;
  }

  /**
   * Chama o componente de lista de produtos de monitoramnento
   *
   * @param {*} produtosList - lista de produtos
   * @returns - componente montado
   */
  function produtoDescricaoMonitoramentoComponent(produtosList, atividadeAmostraList) {
    return (
      <ProdutoDescricaoMonitoramento
        produtosList={limpar ? [] : produtosList}
        atividadeAmostraList={atividadeAmostraList}
      />
    );
  }

  /**
   * Chama o componente de lista de chips de talhões
   *
   * @param {*} listaChip - lista de chips
   * @returns - componente montado
   */
  function chipTalhaoComponent(listaChip) {
    return <ChipsTalhao chipsList={limpar ? [] : listaChip} />;
  }

  /**
   * Chama o componente de lista de chips de talhões de monitoramento
   *
   * @param {*} listaChip - lista de chips
   * @returns - componente montado
   */
  function chipTalhaoMonitoramentoComponent(listaChip) {
    return <ChipTalhaoMonitoramento chipsList={limpar ? [] : listaChip} />;
  }

  /**
   * Monta o label de detalhes de registros fotografico ou climático
   *
   * @returns - label
   */
  function getLabelAcompanhamento() {
    const itemTimeline = timelineList[position];

    if (itemTimeline.type === TipoItemTimelineEnum.REGISTRO_FOTOGRAFICO) {
      return 'acompanhamentoProdutor.observacao';
    } else if (itemTimeline.type === TipoItemTimelineEnum.REGISTRO_CLIMATICO) {
      return 'acompanhamentoProdutor.descricaoRegistroClimatico';
    }

    return '';
  }
}

export default withStyles(style)(CardDescricao);
