import URL from "./URL";

const axios = require("axios");
const adm = URL.ADM;

function findFinanceiroByClienteHandler(idCliente) {
  return axios.get(adm + "/clientefinanceiro/" + idCliente);
}

function editFinanceiroHandler(values) {
  return axios.put(adm + "/clientefinanceiro", values);
}

export default {
  findFinanceiroByCliente: findFinanceiroByClienteHandler,
  editFinanceiro: editFinanceiroHandler
};
