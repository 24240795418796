export default {
  classificacao: {
    descricao: 'Description',
    situacao: 'Situation',
    ativo: 'Active',
    inativo: 'Inactive',
    preservacaoAmbiental: 'Environmental preservation',
    sim: 'Yes',
    nao: 'No',
    produtivo: 'Productive',
    classificacao: 'Land Use and Occupation',
    erroBuscarListaClassificacao: 'Error searching land use and occupation list',
    erroDeletarClassificacao: 'Error deleting land use and occupation',
    classificacaoRemovidoComSucesso: 'Use and occupation of successfully removed land',
    cadastroClassificacao: 'Registration of land use and occupation',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    salvar: 'Save',
    cancelar: 'Cancel',
    erroBuscarClassificacao: 'Error searching land use and occupation',
    classificacaoEditadaComSucesso: 'Use and occupation of the land edited successfully',
    classificacaoSalvaComSucesso: 'Use and occupation of land saved successfully',
    erroEditarClassificacao: 'Error editing land use and occupation',
    erroSalvarClassificacao: 'Error editing land use and occupation',
    salvando: 'Saving',
    atualizando: 'Updating',
    desejaExcluir: 'Do you want to delete land use and occupation?',
    excluir: 'Delete',
    excluindo: 'Deleting'
  }
};
