import { getUserLang } from '@utils/localeUtils';

function DateByLanguage() {
  switch (getUserLang()) {
    case 'pt-BR':
      return 'DD/MM/YYYY';
    case 'es-ES':
      return 'DD-MM-YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}


export default DateByLanguage;