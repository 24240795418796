export default {
  plantios: {
    descartar: 'Descartar',
    salvarPlantioSair: 'Existem informações não salvas, deseja salvar o plantio?',
    atividadeRelacionadaDeletePlantio:
      'Não é possível excluír o plantio pois existe uma atividade relacionada a ele',
    falhaoAoExcluir: 'Ocorreu um erro ao excluír o plantio, tente novamente.',
    minimoTalhoes: 'Adicione pelo menos 1 talhão',
    falhaAoSalvarPlantio: 'Ocorreu um erro ao salvar o plantio, tente novamente.',
    plantioSalvoComSucesso: 'Plantio salvo com sucesso',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    alertasFitossanitarios: 'Alertas fitossanitários',
    invasoras: 'Invasoras',
    tipo: 'Tipo',
    agenteCausal: 'Agente causal',
    data: 'Data',
    doencaSolo: 'Doença de solo',
    doencaFoliar: 'Doença foliar',
    nematoide: 'Nematóide',
    pragas: 'Pragas',
    desejaLimparTalhoes:
      'Se você alterar a área de cultivo os talhões selecionados serão excluídos, deseja continuar?',
    sim: 'Sim',
    produtorSemAreaCultivo:
      'O produtor precisa ter pelo menos uma área de cultivo para criar um plantio',
    produtorSemTalhao: 'O produtor precisa ter pelo menos um talhão para criar um plantio',
    convencional: 'Convencional',
    emConversao: 'Em conversão',
    brasil: 'BR (Brasil)',
    comunidadeEuropeia: 'CE (Comunidade Européia)',
    eua: 'NOP (Estados Unidos)',
    certificacaoOrganica: 'Status da cert. orgânica',
    selecionarTodos: 'Selecionar todos',
    talhao: 'Talhão',
    area: 'Área',
    produtividade: 'Produtividade',
    diasEstimados: 'Dias estimados',
    dataPrevista: 'Data prevista',
    produtividadeEstimada: 'Produtividade estimada',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    quantidadePlantas: 'Quantidade de plantas',
    metro: 'Metro',
    hectare: 'Hectare',
    alqueire: 'Alqueire',
    acre: 'Acre',
    tonelada: 'Tonelada',
    sacas: 'Sacas',
    sementes: 'Sementes',
    nrQuantidade: 'Quantidade',
    unidade: 'Unidade',
    unMedida: 'Un. de medida',
    espacamento: 'Espaçamento (cm)',
    semeadura: 'Semeadura',
    standFinal: 'Stand final',
    previsaoColheita: 'Previsão de colheita',
    colheita: 'Colheita',
    localPlantio: 'Local de plantio',
    campoObrigatorio: 'Campo obrigatório',
    cadastroPlantio: 'Cadastro de plantio',
    plantioExcluido: 'Plantio excluído com sucesso',
    nao: 'Não',
    excluir: 'Excluír',
    desejaExcluirPlantio: 'Você deseja mesmo excluír este plantio?',
    dataPlantio: 'Data do plantio',
    areaDeCultivo: 'Área de cultivo',
    cultura: 'Cultura',
    detentor: 'Detentor',
    cultivar: 'Cultivar',
    dataColheita: 'Data da colheita',
    produtor: 'Produtor',
    plantios: 'Plantios',
    identificacao: 'Identificação',
    barreiraVegetalSul: 'Barreira vegetal sul',
    barreiraVegetalNorte: 'Barreira vegetal norte',
    barreiraVegetalLeste: 'Barreira vegetal leste',
    barreiraVegetalOeste: 'Barreira vegetal oeste',
    barreiraVegSul: 'Barreira veg. sul',
    barreiraVegNorte: 'Barreira veg. norte',
    barreiraVegLeste: 'Barreira veg. leste',
    barreiraVegOeste: 'Barreira veg. oeste',
    vizinho: 'Vizinho',
    entregaEfetiva: 'Entrega efetiva',
    causa: 'Causa',
    insumos: 'Insumos',
    ogm: 'OGM',
    analiseOGM: 'Análise OGM',
    materalPropagacao: 'Material de propagação',
    laudos: 'Laudos',
    descricao: 'Descrição',
    origemSemente: 'Origem da semente',
    descricaoOrigem: 'Descrição da origem',
    sementeTratada: 'Semente tratada',
    categoria: 'Categoria',
    marca: 'Marca',
    produto: 'Produto',
    acaricida: 'Acaricida',
    acaricidaMicrobiologico: 'Acaricida microbiológico',
    agenteBiologicoControle: 'Agente biológico',
    bactericida: 'Bactericida',
    bactericidaMicrobiologico: 'Bactericida microbiológico',
    herbicida: 'Herbicida',
    fungicida: 'Fungicida',
    fungicidaMicrobiologico: 'Fungicida microbiológico',
    inseticida: 'Inseticida',
    inseticidaMicrobiologico: 'Inseticida microbiológico',
    nematicida: 'Nematicida',
    nematicidaMicrobiologico: 'Nematicida microbiológico',
    fertilizanteFoliar: 'Fertilizante foliar',
    acaros: 'Ácaros',
    bacterias: 'Bactérias',
    ervasDaninhas: 'Ervas daninhas',
    fungos: 'Fungos',
    insetos: 'Insetos',
    nematoides: 'Nematóides',
    foliaresAdjuvantes: 'Foliares e Adjuvantes',
    fertilizantes: 'Fertilizantes',
    fertilizanteQuimico: 'Fertilizantes químico',
    fertilizanteOrganico: 'Fertilizantes orgânico',
    oleosAdjuvantes: 'Óleos e adjuvantes',
    desejaExcluirInsumo: 'Deseja excluir insumo?',
    insumoRemovidaComSucesso: 'Insumo removido com sucesso',
    falhaAoExcluirInsumo: 'Falha ao excluir insumo',
    operadora: 'Operadora',
    propria: 'Própria',
    outros: 'Outros',
    excluirTalhao: 'Deseja excluir o local de plantio?',
    homeopaticos: 'Produtos Homeopáticos',
    inoculantesHomeopatia: 'Inoculantes e Homeopatia',
    inoculantes: 'Inoculantes',
    m2: 'm2',
    kg: 'Kg',
    mudas: 'Mudas',
    safra: 'Safra',
    adicionarLaudo: 'Adicionar laudo',
    erroAnexarFoto: 'Erro ao anexar arquivo',
    nenhumLaudo: 'Nenhum laudo, você pode inserir até 3 laudos',
    removeArquivo: 'Deseja remover arquivo?',
    insumoMaterialProgapagacao: 'Insumos e mat. de propagação',
    insumosPlantio: 'Insumos de plantio',
    possuiLaudo: 'Possui laudo OGM',
    formaAplic: 'Forma de aplic.',
    talhaoRelacionado: 'Talhão não pode ser excluído por conter uma auditoria'
  }
};
