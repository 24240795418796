export default {
  certificado: {
    dataFinal: 'Fecha final',
    dataInicial: 'La fecha de inicio',
    adicionarCertificacao: 'Agregar certificación',
    certificacaoOrganica: 'Certificación orgánica',
    campoObrigatorio: 'Campo obligatorio',
    desejaExcluirCertificacao: '¿Quieres eliminar la certificación?',
    nao: 'No',
    excluir: 'Borrar',
    certificacaoExcluidaSucesso: 'Certificación eliminada correctamente',
    historicoCertificacao: 'Historial de certificación',
    indefinida: 'indefinido',
    certificadoExistente: 'Ya existe un certificado en este período.'
  }
};
