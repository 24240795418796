export default {
  acaricida: {
    acaricidaRemovidoComSucesso: 'Acaricida removido com sucesso',
    falhaAoExcluirAcaricida: 'Falha ao excluir acaricida',
    desejaExcluir: 'Deseja excluir o acaricida selecionado?',
    acaricida: 'Acaricida',
    cadastroDeAcaricida: 'Cadastro de acaricida',
    identificacaoDaAcaricida: 'Identificação',
    acaricidaCadastradoComSucesso: 'Acaricida cadastrado com sucesso',
    acaricidaEditadoComSucesso: 'Acaricida editado com sucesso',
    falhaAoEditarAcaricida: 'Falha ao editar acaricida',
    falhaAoCadastrarAcaricida: 'Falha ao cadastrar acaricida',
    falhaAoCarregarAcaricida: 'Falha ao carregar acaricida'
  }
};
