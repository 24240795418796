export default {
  mapa: {
    filtro: 'Filtrar por produtor',
    campoObrigatorio: 'Campo obrigatório',
    produtor: 'Produtor',
    talhao: 'Talhão',
    area: 'Área',
    produtorDuplicado: 'Produtor duplicado',
    talhaoDuplicado: 'Talhão duplicado',
    mapa: 'Mapas'
  }
};
