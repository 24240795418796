import { createMuiTheme } from '@material-ui/core/styles';

const styles = theme => ({
  tabsRoot: {
    backgroundColor: 'white'
  },
  tabsContainer: {
    backgroundColor: 'rgba(139, 200, 165, 0.1)',
    height: 36
  },
  tab: {
    height: 36,
    textTransform: 'none',
    fontSize: 13,
    fontWeight: '400 !important'
  },
  title: {
    backgroundColor: 'rgba(139, 200, 165, 0.1)',
    fontSize: 20,
    fontWeight: '500 !important',
    color: 'rgb( 0, 0, 0, 0.7)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30
  },
  subtitle: {
    fontWeight: '300 !important',
    marginLeft: 5
  },
  swipeable: {
    width: '100%',
    height: '100%',
    overflowX: 'inherit !important'
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiCollapse: {
      wrapper: {
        height: '100%'
      },
      container: {
        overflow: 'inherit !important'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiTypography: {
      headline: {
        color: '#555555',
        fontSize: 24
      },
      h5: {
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.7)'
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    default: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { styles, theme };
