/* Autor: Alisson Stopassole */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Produtor from '@resources/Produtores';
import Agromonitoring from '@resources/Agromonitoring';
import swal from '@sweetalert/with-react';
import Leaflet from './LeafletOverlay';
import logo from '@images/logo_azagros_branca.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { moment } from 'fullcalendar';
import { Modal, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Loading from '@components/Loading/Loading';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const style = () => ({
  cardCarregando: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  gridFather: {
    width: 'calc(100% - 90px)',
    height: 100,
    marginTop: 15,
    marginLeft: 15,
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'rgb(225, 225, 225, 0.8)',
    color: '#FFFFFF',
    borderRadius: 8
  },
  view: {
    width: '100%',
    height: '100%'
  },
  gridContainer: {
    marginTop: 18,
    width: '100%',
    height: '100%'
  },
  logo: {
    width: 120,
    marginLeft: '15px',
    zIndex: 1000,
    position: 'absolute',
    bottom: 5
  },
  modal: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginTop: -10,
    position: 'absolute',
    zIndex: 1000,
    right: -60,
    color: '#FFFFFF'
  },
  iconButtonTop: {
    marginTop: 8
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    type: 'light'
  },
  typography: {
    useNextVariants: true
  }
});

class MapaNDVI extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const filtro = messagesLinguage['mapandvi.filtro'];
    const produtor = messagesLinguage['mapandvi.produtor'];
    const talhao = messagesLinguage['mapandvi.talhao'];
    const campoObrigatorio = messagesLinguage['mapandvi.campoObrigatorio'];
    const area = messagesLinguage['mapandvi.area'];
    const falhaBuscarImagens = messagesLinguage['mapandvi.falhaBuscarImagens'];
    const dia = messagesLinguage['mapandvi.dia'];
    const imagem = messagesLinguage['mapandvi.imagem'];
    const formatoData = messagesLinguage['mapandvi.formatoData'];
    const talhoesSemImagemProdutor = messagesLinguage['mapandvi.talhoesSemImagemProdutor'];
    const talhaoSemImagem = messagesLinguage['mapandvi.talhaoSemImagem'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          filtro,
          produtor,
          talhao,
          dia,
          imagem,
          formatoData,
          campoObrigatorio,
          falhaBuscarImagens,
          talhoesSemImagemProdutor,
          talhaoSemImagem,
          area
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      listProdutores: [],
      modalShow: true
    };

    const title = 'mapandvi.mapandvi';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.clickBuscar = this.clickBuscar.bind(this);
    this.findAllProdutores = this.findAllProdutores.bind(this);
    this.resetValues = this.resetValues.bind(this);
    this.onClickReset = this.onClickReset.bind(this);
  }

  resetValues() {
    this.setState({
      listTalhoes: [],
      listImages: [],
      listDias: [],
      produtor: {},
      talhao: {},
      dia: {},
      imagem: {},
      keyProdutor: Math.random(),
      keyTalhao: Math.random(),
      keyDia: Math.random(),
      keyImagem: Math.random(),
      valueAutoComplete: '',
      listItensMapa: [],
      loading: true,
      errors: true,
      modalShow: true,
      loadingProdutor: false
    });

    this.colors = [];
    this.valueAutoCompleteProdutor = '';
    this.valueAutoCompleteTalhao = '';
    this.valueAutoCompleteImagem = '';
    this.valueAutoCompleteDia = '';
    this.setFieldValue = {};
  }

  componentDidMount() {
    this.resetValues();
    this.findAllProdutores();
  }

  generateHexadecimal() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  findAllProdutores() {
    Produtor.findAllProdutoresForMap()
      .then(doc => {
        this.getProdutores(doc.data);
      })
      .catch(() => {
        this.setState({
          listProdutores: [],
          loading: false
        });
      });
  }

  getProdutores(produtores) {
    var listItensMapa = [];

    produtores.forEach(doc => {
      var color = this.generateHexadecimal();
      this.colors.push({ color: color, idProdutor: doc.idProdutor });
      listItensMapa = this.ajustaTalhaoGps(listItensMapa, doc, color);
    });

    if (this.props.infoUsuario.tpPerfil === 3) {
      this.valueAutoCompleteProdutor = produtores[0];
      this.setState({
        produtor: produtores[0],
        listTalhoes: produtores[0].talhaoList,
        loadingProdutor: true,
        listProdutores: produtores,
        listItensMapa: listItensMapa,
        loading: false
      });

      this.clickBuscar(this.valueAutoCompleteProdutor, this.setFieldValue);
    } else {
      this.setState({
        listProdutores: produtores,
        listItensMapa: listItensMapa,
        loading: false
      });
    }
  }

  ajustaTalhaoGps(listItensMapa, produtor, color, dataSelect, talhao) {
    var list = [];
    if (talhao) {
      list = [talhao];
    } else {
      list = produtor.talhaoList;
    }

    list.forEach(talhao => {
      var listGps = [];

      if (!dataSelect && this.state.dia.dia) {
        dataSelect = this.state.dia;
      }

      talhao.talhaoGpsList.forEach(gps => {
        if (dataSelect && this.state.listDias[0] !== dataSelect) {
          let dtSelect = new Date(
            dataSelect.dia.substring(6),
            dataSelect.dia.substring(3, 5) - 1,
            dataSelect.dia.substring(0, 2)
          );

          let dtAlteracao = null;
          if (gps.dhAlteracao !== undefined) {
            dtAlteracao = new Date(
              gps.dhAlteracao.substring(0, 4),
              gps.dhAlteracao.substring(5, 7) - 1,
              gps.dhAlteracao.substring(8, 10)
            );
          }

          let dtCadastro = null;
          if (gps.dhCadastro !== undefined) {
            dtCadastro = new Date(
              gps.dhCadastro.substring(0, 4),
              gps.dhCadastro.substring(5, 7) - 1,
              gps.dhCadastro.substring(8, 10)
            );
          }

          if (dtCadastro <= dtSelect && dtAlteracao >= dtSelect && gps.stTalhaoGps === 0) {
            listGps.push(gps);
          } else if (dtCadastro <= dtSelect && gps.stTalhaoGps === 1) {
            listGps.push(gps);
          }
        } else if (gps.stTalhaoGps === 1) {
          listGps.push(gps);
        }
      });

      listItensMapa.push({
        idProdutor: produtor.idProdutor,
        produtor: produtor.nmProdutor,
        areaCultivo: talhao.areaCultivo.dsAreaCultivo,
        talhao: talhao.dsTalhao,
        areaTalhao: talhao.nrAreaTalhao,
        tpUnidade: talhao.tpUnidade,
        talhaoGpsList: listGps,
        idTalhao: talhao.idTalhao,
        color: color ? color : this.getColor(produtor)
      });
    });

    this.setState({
      listItensMapa: listItensMapa
    });

    return listItensMapa;
  }

  onClickReset() {
    if (this.state.produtor && this.state.produtor.idProdutor) {
      this.resetValues();
      this.findAllProdutores();
    }
  }

  //Busca as images de todos os talhoes do produtor selecionado
  clickBuscar(produtor, setFieldValue) {
    var listItensMapa = [];
    var idsTalhoes = [];

    listItensMapa = this.ajustaTalhaoGps(listItensMapa, produtor);

    produtor.talhaoList.forEach(talhao => {
      if (talhao.talhaoGpsList.length > 0) {
        idsTalhoes.push(talhao.idTalhao);
      }
    });

    //Busca as images
    Agromonitoring.findImagesByTalhoes(idsTalhoes)
      .then(doc => {
        if (doc.data.length) {
          this.ajustarDados(doc.data, setFieldValue);
        } else {
          this.setState({
            loadingProdutor: false
          });
          swal(
            provider.intl.formatMessage({
              id: 'talhoesSemImagemProdutor'
            }),
            {
              icon: 'warning',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            }
          );
        }
      })
      .catch(() => {
        swal(
          provider.intl.formatMessage({
            id: 'falhaBuscarImagens'
          }),
          {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          }
        );
      });

    this.setState({
      listItensMapa: listItensMapa
    });
  }

  //Ajusta os dados de retorno do back para os selects
  ajustarDados(dados, setFieldValue) {
    var datas = [];
    dados.forEach(dado => {
      dado.forEach(result => {
        var date = moment(result.dt * 1000).format(
          provider.intl.formatMessage({
            id: 'formatoData'
          })
        );
        let item = { dia: date, dt: result.dt, imagens: [] };

        if (!datas.length) {
          datas.push(item);
        }
        var aux = false;
        datas.forEach(data => {
          if (String(data.dia) === String(date)) {
            aux = true;
          }
        });
        if (!aux) {
          datas.push(item);
        }
      });

      dado.forEach(result => {
        var date = moment(result.dt * 1000).format(
          provider.intl.formatMessage({
            id: 'formatoData'
          })
        );

        datas.forEach(data => {
          if (String(data.dia) === String(date)) {
            data.imagens.push([
              {
                tipo: 'EVI',
                img: result.image.evi
              },
              {
                tipo: 'FALSECOLOR',
                img: result.image.falsecolor
              },
              {
                tipo: 'TRUECOLOR',
                img: result.image.truecolor
              },
              {
                tipo: 'NDVI',
                img: result.image.ndvi
              }
            ]);
          }
        });
      });
    });

    datas.sort(function(first, second) {
      return first.dt < second.dt ? 1 : -1;
    });

    this.valueAutoCompleteDia = datas[0];
    setFieldValue('dia', this.valueAutoCompleteDia);
    this.setState({
      listDias: datas,
      dia: this.valueAutoCompleteDia,
      loadingProdutor: false,
      listImages: datas.length
        ? [
            {
              tipo: 'EVI'
            },
            {
              tipo: 'FALSECOLOR'
            },
            {
              tipo: 'TRUECOLOR'
            },
            {
              tipo: 'NDVI'
            }
          ]
        : [],
      keyDia: Math.random(),
      keyImagem: Math.random()
    });
  }

  tratarValorSelectInitial(data) {
    if (!data) {
      return '';
    }

    return data;
  }
  tratarValorSelectInitialTalhao(data) {
    if (!data || data.dsTalhao === undefined) {
      return '';
    }

    return data;
  }
  tratarValorSelectInitialDia(data) {
    if (!data || data.dia === undefined) {
      return '';
    }

    return data;
  }
  tratarValorSelectInitialImagem(data) {
    if (!data || data.tipo === undefined) {
      return '';
    }

    return data;
  }

  //Valida para substituir o list de itens no mapa
  validaIf(event) {
    if (this.state.listItensMapa.length > 1) {
      return true;
    } else {
      if (this.state.talhao && this.state.talhao !== event) {
        return true;
      }
      return false;
    }
  }

  getColor(produtor) {
    for (let i = 0; i < this.colors.length; i++) {
      if (this.colors[i].idProdutor === produtor.idProdutor) {
        return this.colors[i].color;
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal
        open={this.state.modalShow}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby='server-modal-title'
        aria-describedby='server-modal-description'
        className={classes.modal}
      >
        <Formik
          initialValues={{
            produtor: this.valueAutoCompleteProdutor,
            talhao: this.valueAutoCompleteTalhao,
            imagem: this.valueAutoCompleteImagem,
            dia: this.valueAutoCompleteDia
          }}
          onSubmit={this.clickBuscar}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            produtor: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
            talhao: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
          })}
          render={({ handleSubmit, errors, setFieldValue, touched }) => {
            this.setFieldValue = setFieldValue;
            return (
              <div className={classes.view}>
                {!this.state.loading && (
                  <Grid className={classes.gridFather} height={'auto'}>
                    <IconButton
                      className={classes.button}
                      onClick={() => {
                        this.props.history.push('/app');
                        this.setState({
                          modalShow: false
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <div className={classes.gridContainer}>
                      {
                        <Grid container spacing={32}>
                          <Grid
                            item
                            xs={3}
                            style={{
                              minHeight: '75px'
                            }}
                          >
                            <Autocomplete
                              style={{
                                minHeight: '75px',
                                marginLeft: 20,
                                marginRight: -10
                              }}
                              itens={this.state.listProdutores}
                              campoOp={'nmProdutor'}
                              campoChave={'idProdutor'}
                              name='produtor'
                              label={provider.intl.formatMessage({
                                id: 'produtor'
                              })}
                              color={'black'}
                              error={errors.produtor && touched.produtor}
                              helperText={touched.produtor ? errors.produtor : ''}
                              onChangeOwn={event => {
                                if (event) {
                                  setFieldValue('produtor', event);

                                  this.valueAutoCompleteProdutor = event;
                                  this.valueAutoCompleteDia = '';
                                  this.valueAutoCompleteImagem = '';
                                  this.valueAutoCompleteTalhao = '';

                                  this.setState({
                                    errors: {
                                      produtor: false,
                                      talhao: true
                                    },
                                    listDias: [],
                                    listImages: [],
                                    listTalhoes: event.talhaoList,
                                    produtor: event,
                                    imagem: {},
                                    talhao: null,
                                    loadingProdutor: true,
                                    keyTalhao: Math.random(),
                                    keyDia: Math.random(),
                                    keyImagem: Math.random()
                                  });

                                  this.clickBuscar(event, setFieldValue);
                                } else {
                                  setFieldValue('produtor', '');
                                  this.setState({
                                    listTalhoes: []
                                  });
                                }
                              }}
                              disabled={this.props.infoUsuario.tpPerfil === 3}
                              value={this.tratarValorSelectInitial(this.valueAutoCompleteProdutor)}
                              valueAutoComplete={this.tratarValorSelectInitial(
                                this.valueAutoCompleteProdutor
                              )}
                              valueSelect={
                                this.tratarValorSelectInitial(this.valueAutoCompleteProdutor).nmProdutor
                              }
                              key={this.state.keyProdutor}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              minHeight: '75px',
                              marginRight: -10
                            }}
                          >
                            <Autocomplete
                              itens={this.state.listTalhoes}
                              campoOp={'dsTalhao'}
                              campoChave={'idTalhao'}
                              name='talhao'
                              label={provider.intl.formatMessage({
                                id: 'talhao'
                              })}
                              color={'black'}
                              error={errors.talhao && this.state.produtor}
                              helperText={errors.talhao}
                              onChangeOwn={event => {
                                if (event) {
                                  this.valueAutoCompleteTalhao = event;

                                  setFieldValue('talhao', event);

                                  if (this.validaIf(event)) {
                                    this.ajustaTalhaoGps([], this.state.produtor, null, null, event);
                                  }
                                  this.setState({
                                    errors: {
                                      produtor: this.state.errors.produtor,
                                      talhao: false
                                    },
                                    talhao: event
                                  });

                                  var possui = false;

                                  if (this.state.dia && this.state.dia.imagens) {
                                    this.state.dia.imagens.forEach(imagens => {
                                      imagens.forEach(img => {
                                        if (event.idTalhao === Number(img.img.split('-')[1])) {
                                          possui = true;
                                        }
                                      });
                                    });
                                  }

                                  if (!possui) {
                                    swal(
                                      provider.intl.formatMessage({
                                        id: 'talhaoSemImagem'
                                      }),
                                      {
                                        icon: 'warning',
                                        buttons: {
                                          confirm: {
                                            text: 'Ok',
                                            value: true,
                                            visible: true,
                                            closeModal: true,
                                            className: 'swal2-error'
                                          }
                                        }
                                      }
                                    );
                                  }
                                } else {
                                  setFieldValue('talhao', '');
                                }
                              }}
                              value={this.tratarValorSelectInitialTalhao(this.valueAutoCompleteTalhao)}
                              valueAutoComplete={this.tratarValorSelectInitialTalhao(
                                this.valueAutoCompleteTalhao
                              )}
                              valueSelect={
                                this.tratarValorSelectInitialTalhao(this.valueAutoCompleteTalhao)
                                  .dsTalhao
                              }
                              key={this.state.keyTalhao}
                            />
                          </Grid>{' '}
                          <Grid
                            item
                            xs={3}
                            style={{
                              minHeight: '75px',
                              marginRight: -10
                            }}
                          >
                            <Autocomplete
                              itens={this.state.listDias}
                              campoOp={'dia'}
                              campoChave={'idDia'}
                              name='dia'
                              label={provider.intl.formatMessage({
                                id: 'dia'
                              })}
                              color={'black'}
                              onChangeOwn={event => {
                                if (event) {
                                  this.valueAutoCompleteDia = event;

                                  this.setState({
                                    dia: event,
                                    listImages: [
                                      {
                                        tipo: 'EVI'
                                      },
                                      {
                                        tipo: 'FALSECOLOR'
                                      },
                                      {
                                        tipo: 'TRUECOLOR'
                                      },
                                      {
                                        tipo: 'NDVI'
                                      }
                                    ]
                                  });
                                } else {
                                  setFieldValue('dia', '');
                                }

                                this.ajustaTalhaoGps(
                                  [],
                                  this.state.produtor,
                                  null,
                                  event,
                                  this.state.talhao
                                );
                              }}
                              value={this.tratarValorSelectInitialDia(this.valueAutoCompleteDia)}
                              valueAutoComplete={this.tratarValorSelectInitialDia(
                                this.valueAutoCompleteDia
                              )}
                              valueSelect={
                                this.tratarValorSelectInitialDia(this.valueAutoCompleteDia).dia
                              }
                              key={this.state.keyDia}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              minHeight: '75px',
                              marginRight: 10
                            }}
                          >
                            <Autocomplete
                              itens={this.state.listImages}
                              campoOp={'tipo'}
                              campoChave={'idImagem'}
                              name='imagem'
                              label={provider.intl.formatMessage({
                                id: 'imagem'
                              })}
                              color={'black'}
                              onChangeOwn={event => {
                                if (event) {
                                  setFieldValue('imagem', event);
                                  this.valueAutoCompleteImagem = event;

                                  this.setState({
                                    imagem: event
                                  });
                                } else {
                                  setFieldValue('imagem', '');
                                }
                              }}
                              value={this.tratarValorSelectInitialImagem(this.valueAutoCompleteImagem)}
                              valueAutoComplete={this.tratarValorSelectInitialImagem(
                                this.valueAutoCompleteImagem
                              )}
                              valueSelect={
                                this.tratarValorSelectInitialImagem(this.valueAutoCompleteImagem).tipo
                              }
                              key={this.state.keyImagem}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              minHeight: '75px'
                            }}
                          >
                            <IconButton className={classes.iconButtonTop} onClick={this.onClickReset}>
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                          {this.state.loadingProdutor && <Loading />}
                        </Grid>
                      }
                    </div>
                  </Grid>
                )}
                {this.state.loading && (
                  <div
                    style={{
                      height: 'calc(100% - -20px)'
                    }}
                    className={classes.cardCarregando}
                  >
                    <MuiThemeProvider theme={theme}>
                      <CircularProgress />
                    </MuiThemeProvider>
                  </div>
                )}
                {!this.state.loading && (
                  <Leaflet
                    imgOverlay={this.state.imagem}
                    data={this.state.dia}
                    listItensMapa={this.state.listItensMapa}
                    produtor={this.state.produtor}
                  />
                )}
                {!this.state.loading && <img src={logo} className={classes.logo} alt='logo' />}
              </div>
            );
          }}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(MapaNDVI);
export default withRouter(connect(mapStateToProps)(enhaced));
