export default {
  acompanhamento: {
    visitasTecnico: 'Visitas por técnico',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    tecnico: 'Técnico',
    identificacao: 'Identificação',
    campoObrigatorio: 'Campo obrigatório',
    consultar: 'Consultar',
    visitasPendentes: 'Visitas pendentes',
    visitasRealizadas: 'Visitas realizadas',
    visitasAgendadas: 'Visitas agendadas',
    produtor: 'Produtor',
    ultimaVisita: 'Última visita',
    falhaAoPesquisarVisita: 'Falha ao pesquisar acompanhamento',
    falhaAoCarregar: 'Falha ao carregar visitas',
    dataMinima: 'Data inválida',
    horaFinal: 'Hora final',
    horaInicio: 'Hora início',
    pendentes: 'Pendentes',
    agendadas: 'Agendadas',
    realizadas: 'Realizadas',
    filtros: 'Filtros',
    data: 'Data',
    dadosAgenda: 'Dados da agenda',
    dadosVisita: 'Dados da visita',
    semVisitas: 'Sem visitas em 14 dias',
    a: 'a'
  }
};
