import React, { Component } from 'react';
import Calendar from '@components/Calendar/Calendar';
import { withStyles, Grid, MuiThemeProvider } from '@material-ui/core';
import formatMessage from './i18n/formatMessage';
import * as moment from 'moment';
import _ from 'lodash';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import $ from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Agendas from '@resources/Agenda';
import Produtor from '@resources/Produtores';
import SwipeableViews from 'react-swipeable-views';
import Usuario from '@resources/Usuario';
import Visita from '../Visita/Visita';
import Close from '@material-ui/icons/Close';
import CadastrarEvento from './CadastrarEvento';
import { styles, theme } from './Agenda.styles';

class Agenda extends Component {
  constructor(props) {
    super(props);

    const title = 'agenda.agenda';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage('agenda.agenda'),
        name: title
      });
    }

    this.state = {
      listAllParticipantes: [],
      listParticipantes: [],
      dataInicio: '',
      dataFinal: '',
      typeProdutor: this.props.infoUsuario.tpPerfil === 3,
      typeGerente: false,
      openModal: false,
      event: [],
      produtores: [],
      key: '',
      keyTecnico: '',
      keyParticipante: '',
      statusEditar: false,
      tecnicos: [],
      itemSelect: {
        tecnico: {
          nmUsuario: ''
        }
      },
      loading: true,
      error: false,
      agendaDay: false,
      viewDate: '',
      actualMonth: 0,
      index: 0,
      check: false,
      loadingDados: true
    };

    this.valueAutoComplete = '';
    this.valueAutoCompleteTecnico = '';
    this.valueAutoCompleteParticipante = '';
    this.calendar = null;

    this.eventClick = this.eventClick.bind(this);
    this.eventCalendar = this.eventCalendar.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onSalvarEvento = this.onSalvarEvento.bind(this);
    this.onClose = this.onClose.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.addCalendar = this.addCalendar.bind(this);
    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.clickClearTecnico = this.clickClearTecnico.bind(this);
    this.validarInicializacaoAutoComplete = _.debounce(
      this.validarInicializacaoAutoComplete.bind(this),
      200
    );
    this.onGravar = this.onGravar.bind(this);
    this.findAgendas = this.findAgendas.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.modalCalendar = this.modalCalendar.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
    this.tratarValorSelectInitialTecnico = this.tratarValorSelectInitialTecnico.bind(this);
    this.validarInicializacaoAutoCompleteTecnico = _.debounce(
      this.validarInicializacaoAutoCompleteTecnico.bind(this),
      200
    );
    this.findAgendaTecnicos = this.findAgendaTecnicos.bind(this);
    this.trataValores = this.trataValores.bind(this);
    this.validarInicializacaoAutoCompleteParticipantes = this.validarInicializacaoAutoCompleteParticipantes.bind(
      this
    );
    this.tratarValorSelectInitialParticipante = this.tratarValorSelectInitialParticipante.bind(this);
    this.handleAddChip = this.handleAddChip.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.filterParticipantesToSelect = this.filterParticipantesToSelect.bind(this);
    this.swalConfirmError = this.swalConfirmError.bind(this);
    this.swalLoading = this.swalLoading.bind(this);
  }

  componentDidMount() {
    this.validarInicializacaoAutoComplete();
    this.validarInicializacaoAutoCompleteParticipantes();
    if (this.props.infoUsuario.tpPerfil === 1) {
      this.validarInicializacaoAutoCompleteTecnico();
    } else {
      this.setState({
        loadingDados: false
      });
    }
  }

  swalConfirmError(label, tpError) {
    return swal(label, {
      icon: tpError ? 'error' : 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: tpError ? 'swal2-error' : 'swal2-Ok'
        }
      }
    });
  }

  swalLoading(title) {
    swal.stopLoading();
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        title: title
      }
    );
  }

  findAgendas(dateInicial, dateFinal) {
    if (this.props.infoUsuario.tpPerfil === 1 && this.valueAutoCompleteTecnico) {
      this.findAgendaTecnicos(dateInicial, dateFinal);
    } else {
      Agendas.findAgendaByData(dateInicial, dateFinal)
        .then(query => {
          this.trataValores(query);
        })
        .catch(err => {
          this.swalConfirmError(formatMessage('agenda.falhaAoCarregarAgenda'), true);

          this.setState({
            error: true,
            event: [],
            loading: false
          });
        });
    }
  }

  trataValores(query) {
    const data = [];
    query.data.forEach(item => {
      item.dhInicioVisita = moment(item.dhInicioVisita).format('YYYY-MM-DDTHH:mm:ssZZ');
      item.dhTerminoVisita = moment(item.dhTerminoVisita).format('YYYY-MM-DDTHH:mm:ssZZ');

      const dataAppSync = {
        tecnico: item.tecnico,
        participanteList: item.participanteList,
        nrLatitude: item.nrLatitude,
        nrLongitude: item.nrLongitude,
        produtor: item.produtor,
        dtVisita: moment(item.dhInicioVisita).format('YYYY-MM-DD'),
        hrInicio: moment(item.dhInicioVisita).format('HH:mm'),
        hrTermino: moment(item.dhTerminoVisita).format('HH:mm'),
        stAgenda: item.stAgenda || 0,
        dsResultadoVisita: item.dsResultadoVisita || '',
        dsEvento: item.dsEvento,
        dsLocal: item.dsLocal,
        dsObjetivo: item.dsObjetivo,
        idAgenda: item.idAgenda,
        tpRegistro: item.tpRegistro,
        observacao: item.tpRegistro === 1 ? item.dsEvento : item.dsObservacao,
        title:
          this.props.infoUsuario.tpPerfil === 3 || item.tpRegistro === 1
            ? item.tecnico.nmUsuario
            : item.produtor.nmProdutor,
        description: item.tpRegistro === 1 ? item.dsEvento : item.dsObservacao,
        nota: item.tpRegistro === 1 ? item.dsLocal : item.dsResultadoVisita,
        start: item.dhInicioVisita,
        id: item.idAgenda,
        hora: item.dhInicioVisita,
        end: item.dhTerminoVisita
      };

      data.push(dataAppSync);
    });

    $(this.calendar).fullCalendar('removeEvents');
    $(this.calendar).fullCalendar('renderEvents', data, true);

    this.setState({
      event: data,
      loading: false
    });
  }

  changeMonth(date) {
    const atualDate = moment(date);
    const numDate = moment(date).month();

    var dateInicial = moment(atualDate)
      .startOf('month')
      .month(numDate - 1)
      .format('YYYY-MM-DD');
    var dateFinal = moment(atualDate)
      .endOf('month')
      .month(numDate + 1)
      .format('YYYY-MM-DD');

    this.findAgendas(dateInicial, dateFinal);

    this.setState({
      actualMonth: numDate
    });
  }

  eventCalendar(component) {
    this.calendar = component;
  }

  validarInicializacaoAutoComplete() {
    Produtor.findProdutor().then(query => {
      this.setState({
        produtores: query.data,
        loading: false
      });
    });
  }

  validarInicializacaoAutoCompleteParticipantes() {
    Usuario.findAllUsuarios()
      .then(query => {
        let data = [];
        query.data.forEach(doc => {
          if (doc.tpPerfil !== 3 && doc.dsEmail !== this.props.infoUsuario.dsEmail) {
            data.push(doc);
          }
        });

        this.setState({
          listAllParticipantes: data
        });
      })
      .catch(err => {
        this.setState({
          listAllParticipantes: []
        });
      });
  }

  validarInicializacaoAutoCompleteTecnico() {
    Usuario.findAllUsuarios()
      .then(query => {
        let data = [];
        query.data.forEach(doc => {
          if (doc.tpPerfil === 2 || doc.tpPerfil === 1) {
            if (doc.stUsuario === 0) {
              doc.nmUsuario += ` - ${formatMessage('agenda.inativo')}`;
            }
            data.push(doc);
          }
        });

        const tecnicoLogado = query.data.find(
          usuario => usuario.dsEmail === this.props.infoUsuario.dsEmail
        );

        this.valueAutoCompleteTecnico = tecnicoLogado;

        data.sort(function(first, second) {
          if (first.stUsuario > second.stUsuario) return -1;
          if (first.stUsuario < second.stUsuario) return 1;
          return first.nmUsuario.localeCompare(second.nmUsuario);
        });

        this.setState({
          tecnicos: data,
          keyTecnico: Math.random(),
          loadingDados: false
        });
      })
      .catch(err => {
        this.setState({
          tecnicos: []
        });
      });
  }

  findAgendaTecnicos(dataInicio, dataFinal) {
    if (this.valueAutoCompleteTecnico && this.valueAutoCompleteTecnico.idUsuario !== undefined) {
      Agendas.findAgendaByTecnico(this.valueAutoCompleteTecnico.idUsuario, dataInicio, dataFinal)
        .then(query => {
          this.trataValores(query);
        })
        .catch(err => {
          this.setState({
            loading: false
          });
        });

      if (this.state.dataInicio !== dataInicio && this.state.dataFinal !== dataFinal) {
        this.setState({
          dataInicio: dataInicio,
          dataFinal: dataFinal
        });
      }
    } else {
      this.setState({
        loading: false,
        dataInicio: dataInicio,
        dataFinal: dataFinal
      });
    }
  }

  tratarValorSelectInitialParticipante(data) {
    if (!data || data.nmUsuario === undefined) {
      return '';
    }

    return data;
  }

  tratarValorSelectInitialTecnico(data) {
    if (!data) {
      return '';
    }

    return data;
  }

  tratarValorSelectInitial(data) {
    if (!data || data.nmProdutor === undefined) {
      return '';
    }

    return data;
  }

  handleAddChip(arrayName) {
    const auxChips = this.state[arrayName];

    const participantes = this.state.listAllParticipantes.find(
      item => item.idUsuario === this.valueAutoCompleteParticipante.idUsuario
    );
    if (!participantes) return;

    var itemParticipante = { ...participantes };

    auxChips.push(itemParticipante);
    this.valueAutoCompleteParticipante = '';

    this.setState({ [arrayName]: auxChips, keyParticipante: Math.random() });
  }

  filterParticipantesToSelect() {
    const chipsSelecteds = this.state.listParticipantes;

    return this.state.listAllParticipantes.filter(item => {
      return !chipsSelecteds.find(subItem => subItem.idUsuario === item.idUsuario);
    });
  }

  handleDeleteChip(arrayName, chipIndex) {
    const auxChips = this.state[arrayName];

    auxChips.splice(chipIndex, 1);

    this.setState({ [arrayName]: auxChips });
  }

  onClose() {
    this.setState({
      typeGerente: false,
      statusEditar: false,
      check: false,
      openModal: false,
      agendaDay: false,
      index: 0,
      itemSelect: {
        tecnico: {
          dsEmail: ''
        },
        produtor: {
          tecnico: {
            nmUsuario: '',
            dsEmail: ''
          }
        }
      }
    });
  }

  clickExcluir() {
    swal({
      title: formatMessage('agenda.desejaExcluir'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('agenda.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('agenda.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        this.swalLoading(formatMessage('agenda.excluindo'));

        Agendas.deleteAgendaById(this.state.itemSelect.idAgenda)
          .then(() => {
            let label = 'visita';
            if (this.state.itemSelect.tpRegistro === 1) {
              label = 'evento';
            }

            this.swalConfirmError(formatMessage(`agenda.${label}RemovidoComSucesso`), false).then(() => {
              let event = this.state.event;
              const position = event.indexOf(this.state.itemSelect);
              event.slice(position, 1);
              event = [...event];

              $(this.calendar).fullCalendar('removeEvents', this.state.itemSelect.idAgenda);

              this.valueAutoComplete = '';
              this.setState({
                itemSelect: {},
                event: event,
                openModal: false,
                index: 0,
                check: false
              });
            });
          })
          .catch(() => {
            let label = 'Visita';
            if (this.state.itemSelect.tpRegistro === 1) {
              label = 'Evento';
            }

            this.swalConfirmError(formatMessage('agenda.falhaAoExcluir' + label), true).then(() => {
              this.valueAutoComplete = '';
            });
          });
      }
    });
  }

  onSalvarEvento(values) {
    this.swalLoading(formatMessage(this.state.statusEditar ? 'agenda.atualizando' : 'agenda.salvando'));

    var operation = '';
    values.participanteList = this.state.listParticipantes;
    values.tecnico = {
      dsEmail: this.props.infoUsuario.dsEmail
    };
    values.tpRegistro = 1;

    const time = {
      timeInicio: moment(values.horario).format('HH:mm'),
      timeFim: moment(values.horarioFinal).format('HH:mm'),
      dtVisita: moment(values.data).format('YYYY-MM-DD')
    };
    values.dhInicioVisita = moment(time.dtVisita + 'T' + time.timeInicio).format(
      'YYYY-MM-DDTHH:mm:ssZZ'
    );
    values.dhTerminoVisita = moment(time.dtVisita + 'T' + time.timeFim).format('YYYY-MM-DDTHH:mm:ssZZ');

    if (this.state.statusEditar) {
      values.idAgenda = this.state.itemSelect.idAgenda;
      operation = Agendas.updateEvento(values);
    } else {
      operation = Agendas.addEvento(values);
    }

    operation
      .then(query => {
        if (this.state.statusEditar) {
          $(this.calendar).fullCalendar('removeEvents', this.state.itemSelect.idAgenda);
        }

        $(this.calendar).fullCalendar(
          'renderEvent',
          {
            id: query.data.idAgenda,
            title: this.props.infoUsuario.nmUsuario,
            start: values.dhInicioVisita,
            description: values.dsEvento,
            tpRegistro: 1,
            nota: values.dsLocal,
            hora: values.dhInicioVisita,
            end: values.dhTerminoVisita
          },
          true
        );

        swal(
          formatMessage(
            this.state.statusEditar
              ? 'agenda.eventoEditadaComSucesso'
              : 'agenda.eventoCadastradaComSucesso'
          ),
          {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }
        ).then(() => {
          let event = this.state.event;
          values.dtVisita = moment(values.dhInicioVisita).format('YYYY-MM-DD');
          values.hrInicio = moment(values.dhInicioVisita).format('HH:mm');
          values.hrTermino = moment(values.dhTerminoVisita).format('HH:mm');
          values.idAgenda = query.data.idAgenda;

          if (this.state.statusEditar) {
            const item = event.find(doc => {
              return this.state.itemSelect.idAgenda === doc.idAgenda;
            });
            const position = event.indexOf(item);
            event[position] = values;
            event = [...event];
          } else {
            event.push(values);
          }

          this.setState({
            event: [...event],
            agendaDay: false,
            index: 0,
            openModal: false,
            itemSelect: {
              tecnico: {
                dsEmail: ''
              },
              produtor: {
                tecnico: {
                  nmUsuario: ''
                }
              }
            }
          });
        });
      })
      .catch(erro => {
        this.swalConfirmError(
          formatMessage(
            this.state.statusEditar ? 'agenda.falhaAoEditarEvento' : 'agenda.falhaAoCadastrarEvento'
          ),
          true
        );
      });
  }

  onSalvar(values, evento) {
    this.swalLoading(formatMessage(this.state.statusEditar ? 'agenda.atualizando' : 'agenda.salvando'));

    const time = {
      timeInicio: moment(values.horario).format('HH:mm'),
      timeFim: moment(values.horarioFinal).format('HH:mm'),
      dtVisita: moment(values.data).format('YYYY-MM-DD')
    };

    const dado = {
      dsObservacao: values.observacao,
      dhInicioVisita: moment(time.dtVisita + 'T' + time.timeInicio).format('YYYY-MM-DDTHH:mm:ssZZ'),
      dhTerminoVisita: moment(time.dtVisita + 'T' + time.timeFim).format('YYYY-MM-DDTHH:mm:ssZZ'),
      stAgenda: this.state.itemSelect.stAgenda || 0,
      dsResultadoVisita: values.dsResultadoVisita || null,
      produtor: values.produtor
    };

    if (this.state.statusEditar) {
      dado.idAgenda = this.state.itemSelect.idAgenda;
    }

    dado.tecnico = this.state.statusEditar ? this.state.itemSelect.tecnico : this.props.infoUsuario;

    let shortTime = [];

    const dataInicio = dado.dhInicioVisita;
    const dataFinal = dado.dhTerminoVisita;

    Agendas.findAgendaByData(
      moment(dado.dhInicioVisita).format('YYYY-MM-DD'),
      moment(dado.dhTerminoVisita).format('YYYY-MM-DD')
    )
      .then(query => {
        query.data.forEach(doc => {
          var dataItem = doc;

          if (dataItem.tecnico.dsEmail === dado.tecnico.dsEmail || doc.tpRegistro === 1) {
            const itemHorarioInicio = moment(dataItem.dhInicioVisita).format('YYYY-MM-DDTHH:mm:ssZZ');
            const itemHorarioFim = moment(dataItem.dhTerminoVisita).format('YYYY-MM-DDTHH:mm:ssZZ');

            if (
              (dataInicio > itemHorarioInicio &&
                dataInicio < itemHorarioFim &&
                this.state.itemSelect.idAgenda !== dataItem.idAgenda) ||
              (dataFinal > itemHorarioInicio &&
                dataFinal < itemHorarioFim &&
                this.state.itemSelect.idAgenda !== dataItem.idAgenda) ||
              (dataInicio < itemHorarioInicio &&
                dataFinal > itemHorarioFim &&
                this.state.itemSelect.idAgenda !== dataItem.idAgenda)
            ) {
              shortTime.push(dataItem);
            }
          }
        });

        if (shortTime.length > 0) {
          swal(
            <div>
              {shortTime[0].tpRegistro === 0
                ? formatMessage('agenda.mesmoHorario')
                : formatMessage('agenda.mesmoHorarioEvento')}
              {shortTime.map(
                item =>
                  item.tpRegistro === 0 && <div key={item.idAgenda}> {item.produtor.nmProdutor} </div>
              )}
              {shortTime.map(
                item =>
                  item.tpRegistro === 1 && <div key={item.idAgenda}> {item.tecnico.nmUsuario} </div>
              )}
            </div>,
            {
              title: formatMessage('agenda.desejaMarcar'),
              icon: 'warning',
              closeOnClickOutside: false,
              closeOnEsc: true,
              buttons: {
                cancel: {
                  text: formatMessage('agenda.nao'),
                  value: false,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-cancel'
                },
                confirm: {
                  text: formatMessage('agenda.sim'),
                  value: true,
                  visible: true,
                  closeModal: false,
                  className: 'swal2-confirm'
                }
              }
            }
          ).then(willDelete => {
            if (willDelete) {
              if (evento) {
                this.onSalvarEvento(values, true);
              } else {
                this.onGravar(values, dado);
              }
            }
          });
        } else {
          if (evento) {
            this.onSalvarEvento(values, true);
          } else {
            this.onGravar(values, dado);
          }
        }
      })
      .catch(() => {
        let label = 'Visita';
        if (evento) {
          label = 'Evento';
        }

        this.swalConfirmError(
          formatMessage(
            this.state.statusEditar ? 'agenda.falhaAoEditar' + label : 'agenda.falhaAoCadastrar' + label
          ),
          true
        );
      });
  }

  onGravar(values, dado) {
    let observable = '';
    dado.tpRegistro = 0;
    if (this.state.statusEditar) {
      dado.nrLatitude = this.state.itemSelect.nrLatitude;
      dado.nrLongitude = this.state.itemSelect.nrLongitude;
      values.stAgenda = this.state.itemSelect.stAgenda;
      if (this.state.itemSelect.stAgenda === 0) {
        dado.stAgenda = this.state.check ? 2 : 0;
      }
      observable =
        this.state.itemSelect.dsResultadoVisita === '' ||
        this.state.itemSelect.dsResultadoVisita === null
          ? dado.dsObservacao
          : this.state.itemSelect.dsResultadoVisita;
      $(this.calendar).fullCalendar('removeEvents', this.state.itemSelect.idAgenda);
    } else {
      values.stAgenda = 0;
      values.dsResultadoVisita = '';
      observable = dado.dsObservacao;
      dado.stAgenda = this.state.check ? 2 : 0;
    }

    var operation = '';

    if (this.state.statusEditar) {
      operation = Agendas.updateAgenda(dado);
    } else {
      dado.stAgendaPrevia = this.state.check ? 0 : 1;
      operation = Agendas.addAgenda(dado);
    }

    operation
      .then(query => {
        $(this.calendar).fullCalendar(
          'renderEvent',
          {
            id: query.data.idAgenda,
            title:
              this.props.infoUsuario.tpPerfil === 3 ? dado.tecnico.nmUsuario : dado.produtor.nmProdutor,
            start: dado.dhInicioVisita,
            nota: dado.dsResultadoVisita,
            hora: dado.dhInicioVisita,
            end: dado.dhTerminoVisita,
            description: observable,
            observacao: observable
          },
          true
        );

        swal(
          formatMessage(
            this.state.statusEditar
              ? 'agenda.visitaEditadaComSucesso'
              : 'agenda.visitaCadastradaComSucesso'
          ),
          {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }
        ).then(() => {
          let event = this.state.event;
          dado.dtVisita = moment(dado.dhInicioVisita).format('YYYY-MM-DD');
          dado.hrInicio = moment(dado.dhInicioVisita).format('HH:mm');
          dado.hrTermino = moment(dado.dhTerminoVisita).format('HH:mm');
          dado.observacao = dado.dsObservacao;
          dado.idAgenda = query.data.idAgenda;

          if (this.state.statusEditar) {
            const item = event.find(doc => {
              return doc.idAgenda === this.state.itemSelect.idAgenda;
            });
            const position = event.indexOf(item);
            event[position] = dado;
            event = [...event];
          } else {
            event.push(dado);
          }

          this.setState({
            event: event,
            agendaDay: false,
            index: 0,
            openModal: false,
            itemSelect: {
              tecnico: {
                dsEmail: ''
              },
              produtor: {
                tecnico: {
                  nmUsuario: ''
                }
              }
            },
            check: false
          });
        });
      })
      .catch(erro => {
        this.swalConfirmError(
          formatMessage(
            this.state.statusEditar ? 'agenda.falhaAoEditarVisita' : 'agenda.falhaAoCadastrarVisita'
          ),
          true
        );
      });
  }

  eventClick(eventObj) {
    const item = this.state.event.find(data => {
      return data.idAgenda === eventObj.id;
    });

    if (item.tpRegistro === 0) {
      this.valueAutoComplete = item.produtor;
    }

    var index = item.tpRegistro === 1 ? 2 : 1;

    this.setState({
      typeGerente:
        this.props.infoUsuario.tpPerfil === 1 && item.stAgenda !== 2
          ? item.tecnico.dsEmail !== this.props.infoUsuario.dsEmail
          : this.props.infoUsuario.tpPerfil === 1,
      check: item.stAgenda >= 1,
      openModal: true,
      statusEditar: true,
      itemSelect: { ...item },
      index: index,
      listParticipantes: item.tpRegistro === 1 ? [...item.participanteList] : []
    });
  }

  addCalendar(event, viewName, viewDate, index) {
    this.valueAutoComplete = '';

    this.setState({
      openModal: true,
      statusEditar: false,
      agendaDay: viewName === 'agendaDay',
      viewDate: viewDate,
      index: index,
      listParticipantes: []
    });
  }

  handleChangeIndex(index) {
    this.setState({
      index: index
    });
  }

  handleChangeChecked(event) {
    this.setState({
      check: !this.state.check
    });
  }

  modalCalendar(classes) {
    return (
      <div style={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF' }} key={0}>
        {!this.state.loadingDados && (
          <Calendar
            showAutoComplete={this.props.infoUsuario.tpPerfil === 1}
            showButton={this.props.infoUsuario.tpPerfil === 2}
            showButtonProdutor={this.props.infoUsuario.tpPerfil === 3}
            changeMonth={this.changeMonth}
            disableButton={this.state.error}
            events={this.state.event}
            eventClick={this.eventClick}
            addCalendar={this.addCalendar}
            eventCalendar={this.eventCalendar}
          >
            {this.props.infoUsuario.tpPerfil === 1 && (
              <Grid container spacing={16} style={{ marginBottom: 15 }}>
                <Grid item xs={4}>
                  <Autocomplete
                    itens={this.state.tecnicos}
                    campoOp={'nmUsuario'}
                    campoChave={'idUsuario'}
                    label={formatMessage('agenda.selecioneColaborador')}
                    name='tecnico'
                    onBlurAutoComplete={event => {
                      this.setState({
                        keyTecnico: Math.random()
                      });
                    }}
                    onChangeOwn={event => {
                      if (event) {
                        this.setState({
                          loading: true,
                          keyTecnico: Math.random()
                        });
                        this.valueAutoCompleteTecnico = event;
                        this.findAgendaTecnicos(this.state.dataInicio, this.state.dataFinal);
                      }
                    }}
                    value={this.tratarValorSelectInitialTecnico(this.valueAutoCompleteTecnico)}
                    key={this.state.keyTecnico}
                    valueAutoComplete={this.tratarValorSelectInitialTecnico(
                      this.valueAutoCompleteTecnico
                    )}
                    valueSelect={
                      this.tratarValorSelectInitialTecnico(this.valueAutoCompleteTecnico).nmUsuario
                    }
                  />
                </Grid>

                <Grid item xs={2} className={classes.gridIconClose}>
                  <Close className={classes.iconClose} onClick={() => this.clickClearTecnico()} />
                </Grid>
              </Grid>
            )}
          </Calendar>
        )}
      </div>
    );
  }

  clickClearTecnico() {
    this.setState({
      loading: true,
      keyTecnico: Math.random()
    });
    this.valueAutoCompleteTecnico = null;
    this.findAgendas(this.state.dataInicio, this.state.dataFinal);
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        {this.state.loading && <Loading />}
        <SwipeableViews
          className={classes.swipeable}
          containerStyle={{ height: '100%' }}
          index={this.state.index}
          onChangeIndex={this.handleChangeIndex}
          slideStyle={{ display: 'flex', overflow: 'hidden' }}
          ignoreNativeScroll={false}
        >
          {[
            this.modalCalendar(classes),
            <Visita
              check={this.state.check}
              key={1}
              keyAutoComplete={this.state.key}
              produtores={this.state.produtores}
              typeGerente={this.state.typeGerente}
              classes={classes}
              openModal={this.state.openModal}
              statusEditar={this.state.statusEditar}
              itemSelect={this.state.itemSelect}
              initialData={this.state.agendaDay ? this.state.viewDate : null}
              typeProdutor={this.state.typeProdutor}
              onSubmit={values => this.onSalvar(values, false)}
              clickExcluir={this.clickExcluir}
              onClose={this.onClose}
              onChangeDatePicker={date => {
                this.setState({
                  check: false
                });
              }}
              valueAutoComplete={this.valueAutoComplete}
              onChangeOwn={event => {
                this.valueAutoComplete = event;
                this.setState({
                  key: Math.random()
                });
              }}
              value={this.tratarValorSelectInitial(this.valueAutoComplete)}
              valueAutoCompleteTratado={this.tratarValorSelectInitial(this.valueAutoComplete)}
              onChangeAutoComplete={this.validarInicializacaoAutoComplete}
              valueSelect={this.tratarValorSelectInitial(this.valueAutoComplete).nmProdutor}
              onChangeCheck={event => {
                this.handleChangeChecked(event);
              }}
              infoUsuario={this.props.infoUsuario}
              tpPerfil={this.props.infoUsuario.tpPerfil === 3}
            />,
            <CadastrarEvento
              listParticipantes={this.state.listParticipantes}
              classes={classes}
              openModal={this.state.openModal}
              index={this.state.index}
              statusEditar={this.state.statusEditar}
              itemSelect={this.state.itemSelect}
              agendaDay={this.state.agendaDay}
              viewDate={this.state.viewDate}
              typeProdutor={this.state.typeProdutor}
              keyParticipante={this.state.keyParticipante}
              onSalvar={this.onSalvar}
              infoUsuario={this.props.infoUsuario}
              clickExcluir={this.clickExcluir}
              onClose={this.onClose}
              filterParticipantesToSelect={this.filterParticipantesToSelect}
              handleAddChip={this.handleAddChip}
              valueAutoCompleteParticipante={this.valueAutoCompleteParticipante}
              handleDeleteChip={this.handleDeleteChip}
              onChangeParticipante={event => {
                this.setState({
                  keyParticipante: Math.random()
                });
                this.valueAutoCompleteParticipante = event;
              }}
              tratarValorSelectInitialParticipante={this.tratarValorSelectInitialParticipante}
            />
          ]}
        </SwipeableViews>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(styles)(Agenda);
export default withRouter(connect(mapStateToProps)(enhaced));
