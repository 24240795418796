/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import CardFilters from '@components/CardFilters/CardFilters';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import swal from '@sweetalert/with-react';
import Cliente from '@resources/Clientes';
import edit from '@images/icone_editar.png';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {};

/*
    Tela de Listagem de Financeiro customizada
*/
class Financeiro extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const razaoSocial = messagesLinguage['financeiro.razaoSocial'];
    const nomeFantasia = messagesLinguage['financeiro.nomeFantasia'];
    const cnpj = messagesLinguage['financeiro.cnpj'];
    const telefone = messagesLinguage['financeiro.telefone'];
    const desejaExcluir = messagesLinguage['financeiro.desejaExcluir'];
    const excluir = messagesLinguage['financeiro.excluir'];
    const nao = messagesLinguage['financeiro.nao'];
    const excluindo = messagesLinguage['financeiro.excluindo'];
    const financeiroExcluidoComSucesso = messagesLinguage['financeiro.financeiroExcluidoComSucesso'];
    const falhaAoExcluirFinanceiro = messagesLinguage['financeiro.falhaAoExcluirFinanceiro'];
    const plano = messagesLinguage['financeiro.plano'];
    const erroBuscarFinanceiro = messagesLinguage['financeiro.erroBuscarFinanceiro'];

    const intlProvider = new IntlProvider({
      locale: getUserLang(),
      messages: {
        razaoSocial,
        nomeFantasia,
        cnpj,
        telefone,
        desejaExcluir,
        excluir,
        nao,
        financeiroExcluidoComSucesso,
        falhaAoExcluirFinanceiro,
        excluindo,
        plano,
        erroBuscarFinanceiro
      }
    });

    provider = intlProvider.getChildContext();

    this.state = {
      operationTypeString: [
        {
          label: provider.intl.formatMessage({ id: 'razaoSocial' }),
          field: 'dsRazaoSocial',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'nomeFantasia' }),
          field: 'nmCliente',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'cnpj' }),
          field: 'nrDocumento',
          type: 'string'
        }
      ],
      disabledEdit: true,
      itemSelect: '',
      dataAppSync: [],
      dataInTable: [],
      loading: true,
      head: [
        {
          field: 'dsRazaoSocial',
          headerName: provider.intl.formatMessage({ id: 'razaoSocial' }),
          sort: 'asc',
          col: 3
        },
        {
          field: 'nmCliente',
          headerName: provider.intl.formatMessage({ id: 'nomeFantasia' }),
          col: 3
        },
        {
          field: 'nrDocumento',
          headerName: provider.intl.formatMessage({ id: 'cnpj' }),
          col: 2
        },
        {
          field: 'nrTelefone',
          headerName: provider.intl.formatMessage({ id: 'telefone' }),
          col: 2
        }
      ]
    };

    const title = 'financeiro.financeiro';
    if (this.props.itensState.name !== 'financeiro.financeiro') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.onFilter = this.onFilter.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
  }
  componentWillUnmount() {
    this.isCancelled = true;
  }
  componentDidMount() {
    this.onFilter(this.props.chipData);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    Cliente.findCliente()
      .then(response => {
        !this.isCancelled &&
          this.setState({
            dataAppSync: response.data,
            dataInTable: FilterUtil.applyFilters(response.data, this.props.chipData),
            loading: false
          });
      })
      .catch(err => {
        swal(provider.intl.formatMessage({ id: 'erroBuscarFinanceiro' }), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  clickEditar() {
    this.props.history.push('/app/financeiro/editar/' + this.state.itemSelect.idCliente);
  }

  clickExcluir() {}

  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'scroll'
          }}
        >
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationTypeString}
            usePaddings={false}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            viewKey='financeiro'
          />

          <div
            style={{
              display: 'contents',
              width: '100%',
              flex: 1
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              marginTop={true}
            />
          </div>

          <ButtonFAB
            icon={edit}
            positionDefault={true}
            disableFAB={this.state.disabledEdit}
            onClick={this.clickEditar}
          />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'financeiro';
  })[0].content
});

Financeiro.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(Financeiro));
export default withRouter(connect(mapStateToProps)(enhaced));
