export default {
  RECOMENDACAO: 0,
  APLICACAO: 1,
  ALERTA_FITOSSANITARIO: 2,
  PLANTIO: 3,
  COLHEITA: 4,
  MONITORAMENTO: 5,
  REGISTRO_FOTOGRAFICO: 6,
  REGISTRO_CLIMATICO: 7,
  APLICACAO_PRODUTO_PROIBIDO: 8,
  AUDITORIA_PLANTIO: 9,
  SAZONALIDADE: 10
};
