export default {
  marcas: {
    marcas: 'Marcas',
    descricao: 'Descrição',
    desejaExcluir: 'Deseja excluir a marca selecionada?',
    nao: 'Não',
    excluir: 'Excluir',
    falhaAoExcluirMarca: 'Falha ao excluir marca',
    excluindo: 'Excluindo',
    marcaRemovidaComSucesso: 'Marca excluida com sucesso',
    marcaEditadoComSucesso: 'Marca editada com sucesso',
    falhaAoEditarMarca: 'Falha ao editar marca',
    falhaAoCadastrarMarca: 'Falha ao cadastrar marca',
    falhaAoCarregarMarca: 'Falha ao carregar marca',
    cadastroDeMarca: 'Cadastro de marcas',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    identificacaoDaMarca: 'Identificação',
    campoObrigatorio: 'Campo Obrigatório',
    marcaCadastradaComSucesso: 'Marca cadastrada com sucesso',
    situacao: 'Situação',
    ativo: 'Ativo',
    inativo: 'Inativo'
  }
};
