import React from 'react';
import { style } from './../InsumoRecord.styles';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import DataTable from '@components/DataTable/DataTable';
import formatMessage from './../i18n/formatMessage';
import moment from 'moment';

function HistoricoCertificacao(props) {
  const { classes, selected, clickUpdate, certificacaoList, setSelected, formatTextField } = props;

  const headDatatable = [
    {
      valueGetter: args => args.node.data.tipoCertificacao.nmTipoCertificacao,
      headerName: formatMessage('insumo.certificacaoOrganica'),
      col: 3
    },
    {
      valueGetter: args =>
        args.node.data.dtInicio
          ? moment(args.node.data.dtInicio).format(formatTextField())
          : formatMessage('insumo.indefinida'),
      headerName: formatMessage('insumo.dataInicial'),
      col: 3
    },
    {
      valueGetter: args =>
        args.node.data.dtTermino
          ? moment(args.node.data.dtTermino).format(formatTextField())
          : formatMessage('insumo.indefinida'),
      headerName: formatMessage('insumo.dataFinal'),
      col: 3
    },
    {
      valueGetter: args =>
        args.node.data.stInsumoTipoCertificacao === 1
          ? formatMessage('insumo.ativo')
          : formatMessage('insumo.inativo'),
      headerName: formatMessage('insumo.status'),
      col: 3
    }
  ];

  return (
    <div className={classes.marginGrid}>
      <div className={classes.cardDataTable}>
        <div style={{ marginTop: -47 }}>
          <GroupButtonGrid
            hiddenMargin
            withoutMargin={true}
            showDelete={false}
            showEdit
            disableEdit={!selected}
            onClickEdit={() => clickUpdate(selected)}
          />
        </div>

        <DataTable
          style={{ boxShadow: 'none' }}
          noMargin={true}
          borderRadius={false}
          data={certificacaoList}
          columns={headDatatable}
          selectChange={select => setSelected(select)}
          showPagination={false}
        />
      </div>
    </div>
  );
}

export default withStyles(style)(HistoricoCertificacao);
