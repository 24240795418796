export default {
  insumo: {
    descricao: 'Description',
    nao: 'No',
    excluir: 'Delete',
    excluindo: 'Deleting',
    salvar: 'Save',
    cancelar: 'Cancel',
    salvando: 'Saving',
    atualizando: 'Updating',
    campoObrigatorio: 'Required field',
    marca: 'Brand',
    naoOrganico: 'Non-organic',
    certificacaoOrganica: 'Kind of organic certification',
    identificacao: 'Identification',
    certificacao: 'Certification',
    historico: 'Historic',
    historicoCertificacao: 'Certification history',
    validadeDe: 'Validity of',
    validadeAte: 'Validity until',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    cadastrar: 'Register',
    certificadoExistente: 'There is already a certificate in that period',
    indefinida: 'Undefined',
    ativo: 'Active',
    inativo: 'Inactive',
    status: 'Situation',
    inativarDataFinal: 'Inactive certificates must contain an end date',
    nenhumaCertificacaoAtiva: 'No active certification'
  }
};
