export default {
  agenda: {
    agenda: 'Agenda',
    agendarVisita: 'Agendar visita',
    agendarEvento: 'Agendar evento',
    data: 'Data',
    horario: 'Horário Inicial',
    produtor: 'Produtor',
    observacao: 'Observação',
    gravar: 'Gravar',
    cancelar: 'Cancelar',
    campoObrigatorio: 'Campo obrigatório',
    atualizando: 'Atualizando',
    salvando: 'Salvando',
    visitaCadastradaComSucesso: 'Visita cadastrada com sucesso',
    visitaEditadaComSucesso: 'Visita editada com sucesso',
    falhaAoCadastrarVisita: 'Falha ao cadastrar visita',
    falhaAoEditarVisita: 'Falha ao editar visita',
    excluir: 'Excluir',
    desejaExcluir: 'Deseja excluir?',
    nao: 'Não',
    visitaRemovidoComSucesso: 'Visita excluída com sucesso',
    eventoRemovidoComSucesso: 'Evento excluída com sucesso',
    falhaAoExcluirVisita: 'Falha ao excluir visita',
    falhaAoExcluirEvento: 'Falha ao excluir evento',
    excluindo: 'Excluindo',
    resumoVisita: 'Resumo da visita',
    desejaMarcar: 'Deseja agendar a visita?',
    sim: 'Sim',
    mesmoHorario: 'Existe uma visita agendada no mesmo horário para ',
    mesmoHorarioEvento: 'Existe um evento agendada no mesmo horário para ',
    falhaAoCarregarAgenda: 'Falha ao carregar agenda',
    horarioFinal: 'Horário final',
    horarioInvalido: 'Horário final deve ser maior que horário inicial',
    confirmar: 'Confirmar',
    visitaRealizada: 'Visita realizada',
    visitasAgendadas: 'Visitas agendadas',
    visitasSemAgenda: 'Visitas realizadas sem agenda',
    produtoresPendentesVisita: 'Produtores pendentes de visita',
    totalVisitasAgendadas: 'Total de visitas agendadas',
    totalVisitasSemAgenda: 'Total de visitas realizadas sem agenda',
    produtoresMaiorIntervalo: 'Produtores com maior intervalo entre visitas',
    visitasAgendadasTecnico: 'Visitas agendadas por técnico',
    visitasRealizadasTecnico: 'Visitas realizadas sem agenda por técnico',
    visitasMaisProdutoresPendentes: 'Técnicos com mais produtores pendentes',
    tecnico: 'Técnico',
    selecioneColaborador: 'Selecione um colaborador',
    descricao: 'Descrição',
    local: 'Local',
    objetivo: 'Objetivo',
    participante: 'Participante',
    eventoEditadaComSucesso: 'Evento editado com sucesso',
    eventoCadastradaComSucesso: 'Evento cadastrado com sucesso',
    falhaAoEditarEvento: 'Falha ao editar evento',
    falhaAoCadastrarEvento: 'Falha ao cadastrar evento',
    inativo: 'Inativo'
  }
};
