import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Localizacao from '@resources/Localizacao';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import swal from '@sweetalert/with-react';
import Card from '@components/Card/Card';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const style = theme => ({
  textField: {
    width: '100%',
    height: '70px'
  },
  font: {
    height: 15,
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    textOverflow: 'ellipsis'
  },
  button: {
    width: 35,
    minWidth: 10,
    marginTop: 1,
    height: 30,
    padding: 0,
    marginRight: 10
  },
  cardButton: {
    display: 'flex',
    marginTop: 10
  },
  iconButton: {
    height: 30,
    width: 30,
    color: 'rgba(0, 0, 0, 0.6)'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  }
});

class EditarMunicipio extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const municipio = messagesLinguage['regiao.municipio'];
    const estado = messagesLinguage['regiao.estado'];
    const campoObrigatorio = messagesLinguage['regiao.campoObrigatorio'];
    const cancelar = messagesLinguage['regiao.cancelar'];
    const confirmar = messagesLinguage['regiao.confirmar'];
    const municipioJaCadastrado = messagesLinguage['regiao.municipioJaCadastrado'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          municipio,
          estado,
          campoObrigatorio,
          cancelar,
          confirmar,
          municipioJaCadastrado
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      allEstados: [],
      allMunicipios: [],
      estado: {},
      municipio: {},
      keyEstado: Math.random(),
      keyMunicipio: Math.random()
    };

    this.onSave = this.onSave.bind(this);
    this.validarInicializacaoAutoCompleteEstado = this.validarInicializacaoAutoCompleteEstado.bind(this);
    this.validarInicializacaoAutoCompleteMunicipio = this.validarInicializacaoAutoCompleteMunicipio.bind(
      this
    );
    this.tratarValorMunicipio = this.tratarValorMunicipio.bind(this);
    this.tratarValorEstado = this.tratarValorEstado.bind(this);
  }

  componentDidMount() {
    this.validarInicializacaoAutoCompleteEstado();
  }

  onSave(values, { resetForm }) {
    var itemDuplicado = this.props.data.find(doc => {
      return values.municipio.idMunicipio === doc.idMunicipio;
    });

    if (itemDuplicado === undefined) {
      let dados = {
        idUf: values.uf.idUf,
        nmUf: values.uf.nmUf,
        sgUf: values.uf.sgUf,
        idMunicipio: values.municipio.idMunicipio,
        nmMunicipio: values.municipio.nmMunicipio
      };

      this.props.clickSalvarMunicipio(dados);

      this.setState({
        municipio: {},
        estado: {},
        keyEstado: Math.random(),
        keyMunicipio: Math.random()
      });

      resetForm({
        municipio: '',
        uf: ''
      });
    } else {
      swal(provider.intl.formatMessage({ id: 'municipioJaCadastrado' }), {
        icon: 'warning',
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        }
      });
    }
  }

  validarInicializacaoAutoCompleteEstado() {
    Localizacao.findAllEstados()
      .then(query => {
        this.setState({
          allEstados: query.data
        });
      })
      .catch(error => {
        this.setState({
          allEstados: []
        });
      });
  }

  validarInicializacaoAutoCompleteMunicipio(estado) {
    Localizacao.findMunicipiosByUf(estado.idUf)
      .then(query => {
        this.setState({
          allMunicipios: query.data
        });
      })
      .catch(error => {
        this.setState({
          allMunicipios: []
        });
      });
  }

  tratarValorMunicipio() {
    if (!this.state.municipio || this.state.municipio.idMunicipio === undefined) {
      return '';
    }

    return this.state.municipio;
  }

  tratarValorEstado() {
    if (!this.state.estado || this.state.estado.idUf === undefined) {
      return '';
    }

    return this.state.estado;
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={{
            municipio: '',
            uf: ''
          }}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={this.onSave}
          validationSchema={Yup.object().shape({
            municipio: Yup.string().required(<FormattedMessage id='cultivares.campoObrigatorio' />),
            uf: Yup.string().required(<FormattedMessage id='cultivares.campoObrigatorio' />)
          })}
          render={({ values, handleSubmit, errors, touched, setFieldValue, handleBlur }) => (
            <Card
              withOutBorderRadius
              title={provider.intl.formatMessage({ id: 'municipio' })}
              height='auto'
              style={{ minHeight: 190, maxHeight: 190, marginTop: 15 }}
            >
              <div>
                <form autoComplete='off'>
                  <Grid container spacing={16}>
                    <Grid item xs={4}>
                      <Autocomplete
                        itens={this.state.allEstados}
                        campoOp='nmUf'
                        campoChave='idUf'
                        label={provider.intl.formatMessage({ id: 'estado' })}
                        name='estado'
                        error={errors.uf && touched.uf}
                        helperText={
                          touched.uf && errors.uf
                            ? provider.intl.formatMessage({ id: 'campoObrigatorio' })
                            : null
                        }
                        onChangeOwn={value => {
                          setFieldValue('uf', value);
                          if (value) {
                            this.setState({
                              estado: value
                            });

                            this.validarInicializacaoAutoCompleteMunicipio(value);
                          } else {
                            this.setState({ estado: [], municipio: [] });
                          }
                        }}
                        value={this.tratarValorEstado()}
                        key={this.state.keyEstado}
                        valueAutoComplete={this.tratarValorEstado()}
                        valueSelect={this.tratarValorEstado().nmUf}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Autocomplete
                        disabled={this.state.estado.idUf === undefined}
                        itens={this.state.allMunicipios}
                        campoOp='nmMunicipio'
                        campoChave='idMunicipio'
                        label={provider.intl.formatMessage({ id: 'municipio' })}
                        name='municipio'
                        error={errors.municipio && touched.municipio}
                        helperText={
                          touched.municipio && errors.municipio
                            ? provider.intl.formatMessage({ id: 'campoObrigatorio' })
                            : null
                        }
                        onChangeOwn={value => {
                          setFieldValue('municipio', value);
                          if (value) {
                            this.setState({
                              municipio: value
                            });
                          } else {
                            this.setState({ municipio: [] });
                          }
                        }}
                        value={this.tratarValorMunicipio()}
                        key={this.state.keyMunicipio}
                        valueAutoComplete={this.tratarValorMunicipio()}
                        valueSelect={this.tratarValorMunicipio().nmMunicipio}
                      />
                    </Grid>

                    <Grid item xs={1} className={classes.cardButton}>
                      <Button className={classes.button} onClick={handleSubmit}>
                        <Add className={classes.iconButton} />
                      </Button>
                      <Button
                        className={classes.button}
                        onClick={() => {
                          setFieldValue('municipio', '');
                          setFieldValue('uf', '');

                          this.setState({
                            municipio: {},
                            estado: {},
                            keyEstado: Math.random(),
                            keyMunicipio: Math.random()
                          });
                        }}
                      >
                        <Close className={classes.iconButton} />
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Card>
          )}
        />
      </MuiThemeProvider>
    );
  }
}

EditarMunicipio.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(EditarMunicipio);
