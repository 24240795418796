import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getUserLang } from '@utils/localeUtils';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Checkbox from '@material-ui/core/Checkbox';
import DatePicker from '@components/DatePicker/DatePicker';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import { FormattedMessage } from 'react-intl';
import Usuarios from '@resources/Usuario';
import Autocomplete from '@components/Autocomplete/Autocomplete';

const styles = {
  root: {
    overflowY: 'scroll',
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  gridActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  checkBox: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: -15,
    width: 'fit-content',
    cursor: 'pointer'
  },
  cardLine: {
    height: 13,
    width: '100%',
    display: 'flex',
    color: 'rgb(0, 0, 0, 0.54)',
    marginTop: 45,
    marginBottom: 15,
    alignItems: 'center'
  },
  label: {
    margin: 0,
    marginBottom: 3,
    whiteSpace: 'nowrap',
    fontWeight: '400 !important',
    color: 'rgb(0, 0, 0, 1)'
  },
  lineRight: {
    borderBottom: '1px solid rgb(0, 0, 0, 1)',
    marginRight: 10,
    width: 20
  },
  lineLeft: {
    borderBottom: '1px solid rgb(0, 0, 0, 1)',
    width: '100%',
    marginLeft: 10
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    marginTop: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  },
  subTituloResiduos: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 15,
    color: 'rgb(0,0,0,.6)'
  }
};

const theme = createMuiTheme({
  overrides: {
    MuiCollapse: {
      container: {
        overflow: 'inherit !important'
      }
    },
    MuiFormLabel: {
      root: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: 'calc(100% - 20px)'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiTypography: {
      headline: {
        color: '#555555',
        fontSize: 24
      },
      h5: {
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.7)'
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    default: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente para editar o Ambiental
 *
 * @author Bruno Eduardo
 * @class EditarAmbiental
 * @extends {Component} - Componente React
 */
class EditarAmbiental extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyTecnico: Math.random(),
      tecnicos: [],
      tecnico: '',
      ambiental: {
        tecnico: '',
        idAmbiental: null,
        dsInfoClima: '',
        dsInfoSolo: '',
        tpTerraceamento: '',
        dsTerraceamento: '',
        stPlantioNivel: '',
        stPlantasCobertura: '',
        stPlantioDireto: '',
        stProtecaoFontes: '',
        stProtecaoLeitos: '',
        stAnalisePotabilidade: '',
        stAbastecimentoPulverizador: '',
        dsAbastecimentoPulverizador: '',
        dsPeriodoArmazenamentoUrina: '',
        dsPeriodoCompostagem: '',
        dsCarcacaAnimal: '',
        dsDejetoAnimal: '',
        dsOrganicosDomissanitarios: '',
        dsReciclaveis: '',
        dsEmbalagemAgrotoxico: '',
        dtPotabilidadeAgua: null,
        stReciclaveis: '',
        stEmbalagemAgrotoxico: '',
        stEmbalagens: '',
        stOrganicosDomissanitarios: '',
        stDejetoAnimal: '',
        stCarcacaAnimal: '',
        stCompostagem: '',
        stUrinaAnimal: '',
        dtLancamento: null
      },
      tabIndex: 0
    };

    this.naoSimArray = [
      { key: 1, label: this.props.provider.intl.formatMessage({ id: 'sim' }) },
      { key: 0, label: this.props.provider.intl.formatMessage({ id: 'nao' }) }
    ];

    this.naoSeAplicaNaoSimArray = [
      { key: 1, label: this.props.provider.intl.formatMessage({ id: 'sim' }) },
      { key: 0, label: this.props.provider.intl.formatMessage({ id: 'nao' }) },
      { key: 2, label: this.props.provider.intl.formatMessage({ id: 'naoSeAplica' }) }
    ];

    this.abastecimentoPulverizador = [
      { key: 0, label: this.props.provider.intl.formatMessage({ id: 'fontes' }) },
      { key: 1, label: this.props.provider.intl.formatMessage({ id: 'abastecimentoComunitario' }) },
      { key: 2, label: this.props.provider.intl.formatMessage({ id: 'rioLagos' }) }
    ];

    this.onSave = this.onSave.bind(this);
    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.onChangeTecnico = this.onChangeTecnico.bind(this);
    this.validarInicializacaoAutoComplete = this.validarInicializacaoAutoComplete.bind(this);
  }

  componentDidMount() {
    this.validarInicializacaoAutoComplete();

    if (this.props.atualizando) {
      this.setState({
        tecnico: this.props.ambiental.tecnico,
        keyTecnico: Math.random()
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.atualizando === true && prevProps.atualizando === false) {
      this.setState({ ambiental: this.props.ambiental });
    }
  }

  /**
   * Submit do formulário
   *
   * @param {Object} values - Valores dos Inputs
   */
  onSave(values) {
    values.dtLancamento = moment(values.dtLancamento).format('YYYY-MM-DD');
    values.stPlantioDireto =
      values.stPlantioDireto === '' || values.stPlantioDireto === undefined ? 0 : values.stPlantioDireto;
    values.stPlantioNivel =
      values.stPlantioNivel === '' || values.stPlantioNivel === undefined ? 0 : values.stPlantioNivel;
    values.stUsaIrrigacao =
      values.stUsaIrrigacao === '' || values.stUsaIrrigacao === undefined ? 0 : values.stUsaIrrigacao;
    values.stPlantasCobertura =
      values.stPlantasCobertura === '' || values.stPlantasCobertura === undefined
        ? 0
        : values.stPlantasCobertura;
    values.tecnico = this.state.tecnico;

    values.stOrganicosDomissanitarios =
      values.stOrganicosDomissanitarios === '' ? 0 : values.stOrganicosDomissanitarios;
    values.stDejetoAnimal = values.stDejetoAnimal === '' ? 0 : values.stDejetoAnimal;
    values.stCarcacaAnimal = values.stCarcacaAnimal === '' ? 0 : values.stCarcacaAnimal;
    values.stCompostagem = values.stCompostagem === '' ? 0 : values.stCompostagem;
    values.stUrinaAnimal = values.stUrinaAnimal === '' ? 0 : values.stUrinaAnimal;
    values.stReciclaveis = values.stReciclaveis === '' ? 0 : values.stReciclaveis;
    values.stEmbalagemAgrotoxico =
      values.stEmbalagemAgrotoxico === '' ? 0 : values.stEmbalagemAgrotoxico;

    values.stAnalisePotabilidade =
      values.stAnalisePotabilidade === '' ? 0 : values.stAnalisePotabilidade;
    values.stProtecaoLeitos = values.stProtecaoLeitos === '' ? 0 : values.stProtecaoLeitos;
    values.stAnalisePotabilidade =
      values.stAnalisePotabilidade === '' ? 0 : values.stAnalisePotabilidade;
    values.stAbastecimentoPulverizador =
      values.stAbastecimentoPulverizador === '' ? null : values.stAbastecimentoPulverizador;

    values.tpTerraceamento = values.tpTerraceamento === '' ? 0 : values.tpTerraceamento;
    values.stProtecaoFontes = values.stProtecaoFontes === '' ? 0 : values.stProtecaoFontes;

    values.dtPotabilidadeAgua =
      values.dtPotabilidadeAgua == null
        ? values.dtPotabilidadeAgua
        : moment(values.dtPotabilidadeAgua).format('YYYY-MM-DD');

    if (this.props.atualizando) {
      values.idCadastroAmbiental = this.props.ambiental.idCadastroAmbiental;
      values.stRegistro = this.props.ambiental.stRegistro;
      this.props.onUpdate(values);
    } else {
      values.stRegistro = 0;
      this.props.onNew(values);
    }
  }

  validarInicializacaoAutoComplete() {
    Usuarios.findAllUsuarios()
      .then(query => {
        let data = [];

        query.data.forEach(doc => {
          if (doc.tpPerfil === 2) {
            data.push(doc);
          }
        });

        this.setState({ tecnicos: data }, () => {
          if (parseInt(this.props.tpPerfilTecnico.tpPerfil) === 2) {
            var tecnico = this.state.tecnicos.find(tec => {
              return this.props.infoUsuario.dsEmail === tec.dsEmail;
            });

            this.setState({
              tecnico: tecnico,
              keyTecnico: Math.random()
            });
          }
        });
      })
      .catch(err => {
        this.setState({ tecnicos: [] });
      });
  }

  tratarValorSelectInitial() {
    if (!this.state.tecnico || this.state.tecnico === undefined) {
      return '';
    }
    return this.state.tecnico;
  }

  onChangeTecnico(value = '') {
    this.setState({
      keyTecnico: Math.random(),
      tecnico: value
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Formik
          initialValues={this.props.atualizando ? this.props.ambiental : this.state.ambiental}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={values => {
            if (this.state.tecnico && this.state.tecnico !== '') {
              this.onSave(values);
            }
          }}
          validationSchema={Yup.object().shape({
            dtLancamento: Yup.string()
              .nullable()
              .required(this.props.provider.intl.formatMessage({ id: 'campoObrigatorio' }))
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleReset
          }) => {
            this.setFieldValue = setFieldValue;
            this.values = values;
            this.handleReset = handleReset;

            return (
              <form autoComplete='off'>
                <MuiThemeProvider theme={theme}>
                  <CardWithTabs
                    withoutHidden
                    containerSwipeableStyles={{ overflow: 'inherit' }}
                    selectedIndex={this.state.tabIndex}
                    onChangeTabIndex={(e, tabIndex) => this.setState({ tabIndex })}
                    title={this.props.provider.intl.formatMessage({
                      id: this.props.atualizando ? 'edicaoAmbiental' : 'cadastroAmbiental'
                    })}
                    tabs={[
                      {
                        label: this.props.provider.intl.formatMessage({ id: 'identificacao' }),
                        component: (
                          <>
                            <Grid container spacing={16}>
                              <Grid item xs={2}>
                                <DatePicker
                                  locale={getUserLang()}
                                  style={{ height: 70 }}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'dataLancamento'
                                  })}
                                  valueDate={values.dtLancamento}
                                  errorTouched={!values.dtLancamento && touched.dtLancamento}
                                  helperText={this.props.provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })}
                                  onChangeDatePicker={date => setFieldValue('dtLancamento', date)}
                                  onBlurDatePicker={e => {
                                    let validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                                    !validation && setFieldValue('dtLancamento', null);
                                  }}
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <Autocomplete
                                  disabled={this.props.tpPerfilTecnico.tpPerfil === 2}
                                  itens={this.state.tecnicos}
                                  campoOp='nmUsuario'
                                  campoChave='idUsuario'
                                  label={<FormattedMessage id='produtores.tecnicoResponsavel' />}
                                  name='tecnico'
                                  error={!this.state.tecnico && touched.tecnico}
                                  helperText={
                                    touched.tecnico && !this.state.tecnico
                                      ? this.props.provider.intl.formatMessage({
                                          id: 'campoObrigatorio'
                                        })
                                      : null
                                  }
                                  onChangeOwn={this.onChangeTecnico}
                                  onChangeAutoComplete={this.validarInicializacaoAutoComplete}
                                  value={this.tratarValorSelectInitial()}
                                  key={this.state.keyTecnico}
                                  valueAutoComplete={this.tratarValorSelectInitial()}
                                  valueSelect={this.tratarValorSelectInitial().nmUsuario}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={16}>
                              <Grid item xs={3}>
                                <div
                                  onClick={() =>
                                    setFieldValue('stUsaIrrigacao', +values.stUsaIrrigacao === 1 ? 0 : 1)
                                  }
                                  className={classes.checkBox}
                                >
                                  <Checkbox
                                    checked={+values.stUsaIrrigacao === 1}
                                    value='checkedB'
                                    color='primary'
                                  />
                                  <label style={{ color: '#000000', cursor: 'pointer' }}>
                                    {this.props.provider.intl.formatMessage({ id: 'usoIrrigacao' })}
                                  </label>
                                </div>
                              </Grid>

                              <Grid item xs={3}>
                                <div
                                  onClick={() =>
                                    setFieldValue(
                                      'stPlantasCobertura',
                                      +values.stPlantasCobertura === 1 ? 0 : 1
                                    )
                                  }
                                  className={classes.checkBox}
                                >
                                  <Checkbox
                                    checked={+values.stPlantasCobertura === 1}
                                    value='checkedB'
                                    color='primary'
                                  />
                                  <label style={{ color: '#000000', cursor: 'pointer' }}>
                                    {this.props.provider.intl.formatMessage({
                                      id: 'usoPlantasCobertura'
                                    })}
                                  </label>
                                </div>
                              </Grid>

                              <Grid item xs={3} style={{ paddingRight: 15 }}>
                                <div
                                  className={classes.checkBox}
                                  onClick={() =>
                                    setFieldValue(
                                      'stPlantioDireto',
                                      +values.stPlantioDireto === 1 ? 0 : 1
                                    )
                                  }
                                >
                                  <Checkbox
                                    checked={+values.stPlantioDireto === 1}
                                    value='checkedB'
                                    color='primary'
                                    size='small'
                                  />
                                  <label style={{ color: '#000000', cursor: 'pointer' }}>
                                    {this.props.provider.intl.formatMessage({ id: 'plantioDireto' })}
                                  </label>
                                </div>
                              </Grid>

                              <Grid item xs={3} style={{ paddingRight: 15 }}>
                                <div
                                  onClick={() =>
                                    setFieldValue('stPlantioNivel', +values.stPlantioNivel === 1 ? 0 : 1)
                                  }
                                  className={classes.checkBox}
                                >
                                  <Checkbox
                                    checked={+values.stPlantioNivel === 1}
                                    value='checkedB'
                                    color='primary'
                                  />
                                  <label style={{ color: '#000000', cursor: 'pointer' }}>
                                    {this.props.provider.intl.formatMessage({ id: 'plantioNivel' })}
                                  </label>
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        )
                      },
                      {
                        label: this.props.provider.intl.formatMessage({ id: 'solo' }),
                        component: (
                          <>
                            <Grid container>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <TextField
                                  id='dsInfoClima'
                                  name='dsInfoClima'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.dsInfoClima}
                                  inputProps={{
                                    maxLength: 100
                                  }}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'informacoesClima'
                                  })}
                                  className={classes.textField}
                                  error={errors.dsInfoClima && touched.dsInfoClima}
                                  helperText={touched.dsInfoClima ? errors.dsInfoClima : ''}
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <TextField
                                  id='dsInfoSolo'
                                  name='dsInfoSolo'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  inputProps={{
                                    maxLength: 100
                                  }}
                                  value={values.dsInfoSolo}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'informacoesSolo'
                                  })}
                                  className={classes.textField}
                                  error={errors.dsInfoSolo && touched.dsInfoSolo}
                                  helperText={touched.dsInfoSolo ? errors.dsInfoSolo : ''}
                                />
                              </Grid>
                            </Grid>

                            <Grid container style={{ height: 70 }}>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.tpTerraceamento && touched.tpTerraceamento}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='tpTerraceamento'
                                    error={errors.tpTerraceamento && touched.tpTerraceamento}
                                  >
                                    {this.props.provider.intl.formatMessage({ id: 'terracentamento' })}
                                  </InputLabel>

                                  <Select
                                    value={values.tpTerraceamento}
                                    error={errors.tpTerraceamento && touched.tpTerraceamento}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='tpTerraceamento'
                                    id='tpTerraceamento'
                                  >
                                    {[
                                      {
                                        key: 2,
                                        label: this.props.provider.intl.formatMessage({
                                          id: 'satisfatorio'
                                        })
                                      },
                                      {
                                        key: 1,
                                        label: this.props.provider.intl.formatMessage({ id: 'razoavel' })
                                      },
                                      {
                                        key: 0,
                                        label: this.props.provider.intl.formatMessage({ id: 'ruim' })
                                      }
                                    ].map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <TextField
                                  disabled={values.tpTerraceamento === '' || errors.tpTerraceamento}
                                  id='dsTerraceamento'
                                  name='dsTerraceamento'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  inputProps={{
                                    maxLength: 100
                                  }}
                                  value={values.dsTerraceamento}
                                  label={this.props.provider.intl.formatMessage({ id: 'descricao' })}
                                  className={classes.textField}
                                  error={errors.dsTerraceamento && touched.dsTerraceamento}
                                  helperText={touched.dsTerraceamento ? errors.dsTerraceamento : ''}
                                />
                              </Grid>
                            </Grid>
                          </>
                        )
                      },
                      {
                        label: this.props.provider.intl.formatMessage({ id: 'agua' }),
                        component: (
                          <>
                            <Grid container style={{ height: 70 }}>
                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stProtecaoFontes && touched.stProtecaoFontes}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stProtecaoFontes'
                                    error={errors.stProtecaoFontes && touched.stProtecaoFontes}
                                  >
                                    {this.props.provider.intl.formatMessage({ id: 'protecaoFontes' })}
                                  </InputLabel>

                                  <Select
                                    value={values.stProtecaoFontes}
                                    error={errors.stProtecaoFontes && touched.stProtecaoFontes}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='stProtecaoFontes'
                                    id='stProtecaoFontes'
                                  >
                                    {this.naoSeAplicaNaoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stProtecaoLeitos && touched.stProtecaoLeitos}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stProtecaoLeitos'
                                    error={errors.stProtecaoLeitos && touched.stProtecaoLeitos}
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'protecaoLeitosAgua'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stProtecaoLeitos}
                                    error={errors.stProtecaoLeitos && touched.stProtecaoLeitos}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='stProtecaoLeitos'
                                    id='stProtecaoLeitos'
                                  >
                                    {this.naoSeAplicaNaoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid container style={{ height: 70 }}>
                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stAnalisePotabilidade && touched.stAnalisePotabilidade}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stAnalisePotabilidade'
                                    error={errors.stAnalisePotabilidade && touched.stAnalisePotabilidade}
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'analisePotabilidadeAgua'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stAnalisePotabilidade}
                                    error={errors.stAnalisePotabilidade && touched.stAnalisePotabilidade}
                                    onChange={e => {
                                      if (e.target.value !== values.stAnalisePotabilidade) {
                                        setFieldValue('dtPotabilidadeAgua', null);
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stAnalisePotabilidade'
                                    id='stAnalisePotabilidade'
                                  >
                                    {this.naoSeAplicaNaoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={2} style={{ paddingRight: 15 }}>
                                <DatePicker
                                  locale={getUserLang()}
                                  style={{ height: 70 }}
                                  disabled={
                                    values.stAnalisePotabilidade === '' ||
                                    +values.stAnalisePotabilidade !== 1
                                  }
                                  label={this.props.provider.intl.formatMessage({ id: 'data' })}
                                  valueDate={values.dtPotabilidadeAgua}
                                  errorTouched={errors.dtPotabilidadeAgua && touched.dtPotabilidadeAgua}
                                  helperText={this.props.provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })}
                                  onChangeDatePicker={date => setFieldValue('dtPotabilidadeAgua', date)}
                                  onBlurDatePicker={e => {
                                    let validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                                    !validation && setFieldValue('dtPotabilidadeAgua', null);
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid container style={{ height: 70 }}>
                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={
                                    errors.stAbastecimentoPulverizador &&
                                    touched.stAbastecimentoPulverizador
                                  }
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stAbastecimentoPulverizador'
                                    error={
                                      errors.stAbastecimentoPulverizador &&
                                      touched.stAbastecimentoPulverizador
                                    }
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'abastecimentoPulverizacao'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={
                                      values.stAbastecimentoPulverizador !== null &&
                                      values.stAbastecimentoPulverizador !== undefined
                                        ? values.stAbastecimentoPulverizador
                                        : ''
                                    }
                                    error={
                                      errors.stAbastecimentoPulverizador &&
                                      touched.stAbastecimentoPulverizador
                                    }
                                    onChange={e => {
                                      setFieldValue('dsAbastecimentoPulverizador', '');

                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stAbastecimentoPulverizador'
                                    id='stAbastecimentoPulverizador'
                                  >
                                    {this.abastecimentoPulverizador.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <TextField
                                  id='dsAbastecimentoPulverizador'
                                  name='dsAbastecimentoPulverizador'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  inputProps={{
                                    maxLength: 20
                                  }}
                                  value={values.dsAbastecimentoPulverizador}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'descricaoAbastecimentoPulverizacao'
                                  })}
                                  className={classes.textField}
                                  error={
                                    errors.dsAbastecimentoPulverizador &&
                                    touched.dsAbastecimentoPulverizador
                                  }
                                  helperText={
                                    touched.dsAbastecimentoPulverizador
                                      ? errors.dsAbastecimentoPulverizador
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                          </>
                        )
                      },
                      {
                        label: this.props.provider.intl.formatMessage({ id: 'residual' }),
                        style: {
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          maxHeight: 'calc(100vh - 360px)'
                        },
                        component: (
                          <>
                            <div className={classes.subTituloResiduos}>
                              {this.props.provider.intl.formatMessage({
                                id: 'detinacaoAdequadaResiduos'
                              })}
                            </div>

                            <Grid container>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stDejetoAnimal && touched.stDejetoAnimal}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stDejetoAnimal'
                                    error={errors.stDejetoAnimal && touched.stDejetoAnimal}
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'dejetoAnimal'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stDejetoAnimal}
                                    error={errors.stDejetoAnimal && touched.stDejetoAnimal}
                                    onChange={e => {
                                      if (e.target.value !== values.stDejetoAnimal) {
                                        setFieldValue('dsDejetoAnimal', '');
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stDejetoAnimal'
                                    id='stDejetoAnimal'
                                  >
                                    {this.naoSeAplicaNaoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <TextField
                                  disabled={values.stDejetoAnimal === '' || +values.stDejetoAnimal !== 1}
                                  id='dsDejetoAnimal'
                                  name='dsDejetoAnimal'
                                  onBlur={handleBlur}
                                  inputProps={{
                                    maxLength: 100
                                  }}
                                  onChange={handleChange}
                                  value={values.dsDejetoAnimal}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'descricaoDejetoAnimal'
                                  })}
                                  className={classes.textField}
                                  error={errors.dsDejetoAnimal && touched.dsDejetoAnimal}
                                  helperText={touched.dsDejetoAnimal ? errors.dsDejetoAnimal : ''}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={16}>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stCarcacaAnimal && touched.stCarcacaAnimal}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stCarcacaAnimal'
                                    error={errors.stCarcacaAnimal && touched.stCarcacaAnimal}
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'carcacaAnimal'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stCarcacaAnimal}
                                    error={errors.stCarcacaAnimal && touched.stCarcacaAnimal}
                                    onChange={e => {
                                      if (e.target.value !== values.stCarcacaAnimal) {
                                        setFieldValue('dsCarcacaAnimal', '');
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stCarcacaAnimal'
                                    id='stCarcacaAnimal'
                                  >
                                    {this.naoSeAplicaNaoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <TextField
                                  disabled={
                                    values.stCarcacaAnimal === '' || +values.stCarcacaAnimal !== 1
                                  }
                                  id='dsCarcacaAnimal'
                                  name='dsCarcacaAnimal'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.dsCarcacaAnimal}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'descricaoCarcacaAnimal'
                                  })}
                                  className={classes.textField}
                                  error={errors.dsCarcacaAnimal && touched.dsCarcacaAnimal}
                                  helperText={touched.dsCarcacaAnimal ? errors.dsCarcacaAnimal : ''}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={16}>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stCompostagem && touched.stCompostagem}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stCompostagem'
                                    error={errors.stCompostagem && touched.stCompostagem}
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'periodoCompostagem'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stCompostagem}
                                    error={errors.stCompostagem && touched.stCompostagem}
                                    onChange={e => {
                                      if (e.target.value !== values.stCompostagem) {
                                        setFieldValue('dsPeriodoCompostagem', '');
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stCompostagem'
                                    id='stCompostagem'
                                  >
                                    {this.naoSeAplicaNaoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <TextField
                                  disabled={values.stCompostagem === '' || +values.stCompostagem !== 1}
                                  id='dsPeriodoCompostagem'
                                  name='dsPeriodoCompostagem'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  inputProps={{
                                    maxLength: 20
                                  }}
                                  value={values.dsPeriodoCompostagem}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'descricaoPeriodoCompostagem'
                                  })}
                                  className={classes.textField}
                                  error={errors.dsPeriodoCompostagem && touched.dsPeriodoCompostagem}
                                  helperText={
                                    touched.dsPeriodoCompostagem ? errors.dsPeriodoCompostagem : ''
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={16}>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stUrinaAnimal && touched.stUrinaAnimal}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stUrinaAnimal'
                                    error={errors.stUrinaAnimal && touched.stUrinaAnimal}
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'periodoArmazenamentoUrina'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stUrinaAnimal}
                                    error={errors.stUrinaAnimal && touched.stUrinaAnimal}
                                    onChange={e => {
                                      if (e.target.value !== values.stUrinaAnimal) {
                                        setFieldValue('dsPeriodoArmazenamentoUrina', '');
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stUrinaAnimal'
                                    id='stUrinaAnimal'
                                  >
                                    {this.naoSeAplicaNaoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <TextField
                                  disabled={values.stUrinaAnimal === '' || +values.stUrinaAnimal !== 1}
                                  id='dsPeriodoArmazenamentoUrina'
                                  name='dsPeriodoArmazenamentoUrina'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.dsPeriodoArmazenamentoUrina}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'descricaoPeriodoArmazenamentoUrina'
                                  })}
                                  inputProps={{
                                    maxLength: 20
                                  }}
                                  className={classes.textField}
                                  error={
                                    errors.dsPeriodoArmazenamentoUrina &&
                                    touched.dsPeriodoArmazenamentoUrina
                                  }
                                  helperText={
                                    touched.dsPeriodoArmazenamentoUrina
                                      ? errors.dsPeriodoArmazenamentoUrina
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={16}>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={
                                    errors.stOrganicosDomissanitarios &&
                                    touched.stOrganicosDomissanitarios
                                  }
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stOrganicosDomissanitarios'
                                    error={
                                      errors.stOrganicosDomissanitarios &&
                                      touched.stOrganicosDomissanitarios
                                    }
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'organicosDomisanitarios'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stOrganicosDomissanitarios}
                                    error={
                                      errors.stOrganicosDomissanitarios &&
                                      touched.stOrganicosDomissanitarios
                                    }
                                    onChange={e => {
                                      if (e.target.value !== values.stOrganicosDomissanitarios) {
                                        setFieldValue('dsOrganicosDomissanitarios', '');
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stOrganicosDomissanitarios'
                                    id='stOrganicosDomissanitarios'
                                  >
                                    {this.naoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <TextField
                                  disabled={
                                    values.stOrganicosDomissanitarios === '' ||
                                    +values.stOrganicosDomissanitarios !== 1
                                  }
                                  id='dsOrganicosDomissanitarios'
                                  name='dsOrganicosDomissanitarios'
                                  inputProps={{
                                    maxLength: 100
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.dsOrganicosDomissanitarios}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'descricaoOrganicosDomissanitarios'
                                  })}
                                  className={classes.textField}
                                  error={
                                    errors.dsOrganicosDomissanitarios &&
                                    touched.dsOrganicosDomissanitarios
                                  }
                                  helperText={
                                    touched.dsOrganicosDomissanitarios
                                      ? errors.dsOrganicosDomissanitarios
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stReciclaveis && touched.stReciclaveis}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stReciclaveis'
                                    error={errors.stReciclaveis && touched.stReciclaveis}
                                  >
                                    {this.props.provider.intl.formatMessage({ id: 'reciclaveis' })}
                                  </InputLabel>

                                  <Select
                                    value={values.stReciclaveis}
                                    error={errors.stReciclaveis && touched.stReciclaveis}
                                    onChange={e => {
                                      if (e.target.value !== values.stReciclaveis) {
                                        setFieldValue('dsReciclaveis', '');
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stReciclaveis'
                                    id='stReciclaveis'
                                  >
                                    {this.naoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={5} style={{ paddingRight: 15 }}>
                                <TextField
                                  disabled={values.stReciclaveis === '' || +values.stReciclaveis !== 1}
                                  id='dsReciclaveis'
                                  name='dsReciclaveis'
                                  onBlur={handleBlur}
                                  inputProps={{
                                    maxLength: 100
                                  }}
                                  onChange={handleChange}
                                  value={values.dsReciclaveis}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'descricaoReciclaveis'
                                  })}
                                  className={classes.textField}
                                  error={errors.dsReciclaveis && touched.dsReciclaveis}
                                  helperText={touched.dsReciclaveis ? errors.dsReciclaveis : ''}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={16}>
                              <Grid item xs={4} style={{ paddingRight: 15 }}>
                                <FormControl
                                  error={errors.stEmbalagemAgrotoxico && touched.stEmbalagemAgrotoxico}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel
                                    className={classes.textField}
                                    htmlFor='stEmbalagemAgrotoxico'
                                    error={errors.stEmbalagemAgrotoxico && touched.stEmbalagemAgrotoxico}
                                  >
                                    {this.props.provider.intl.formatMessage({
                                      id: 'embalagensAgrotoxicos'
                                    })}
                                  </InputLabel>

                                  <Select
                                    value={values.stEmbalagemAgrotoxico}
                                    error={errors.stEmbalagemAgrotoxico && touched.stEmbalagemAgrotoxico}
                                    onChange={e => {
                                      if (e.target.value !== values.stEmbalagemAgrotoxico) {
                                        setFieldValue('dsEmbalagemAgrotoxico', '');
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name='stEmbalagemAgrotoxico'
                                    id='stEmbalagemAgrotoxico'
                                  >
                                    {this.naoSimArray.map((item, i) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={5}>
                                <TextField
                                  disabled={
                                    values.stEmbalagemAgrotoxico === '' ||
                                    +values.stEmbalagemAgrotoxico !== 1
                                  }
                                  id='dsEmbalagemAgrotoxico'
                                  name='dsEmbalagemAgrotoxico'
                                  onBlur={handleBlur}
                                  inputProps={{
                                    maxLength: 100
                                  }}
                                  onChange={handleChange}
                                  value={values.dsEmbalagemAgrotoxico}
                                  label={this.props.provider.intl.formatMessage({
                                    id: 'descricaoEmbalagensAgrotoxicas'
                                  })}
                                  className={classes.textField}
                                  error={errors.dsEmbalagemAgrotoxico && touched.dsEmbalagemAgrotoxico}
                                  helperText={
                                    touched.dsEmbalagemAgrotoxico ? errors.dsEmbalagemAgrotoxico : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                          </>
                        )
                      }
                    ]}
                    componentBelow={
                      <MuiThemeProvider theme={theme}>
                        <Grid container>
                          <Grid item xs className={classes.containerButtons}>
                            <div style={{ color: '#463766' }}>
                              <Button variant='contained' color='inherit' onClick={this.props.onCancel}>
                                {this.props.provider.intl.formatMessage({ id: 'cancelar' })}
                              </Button>
                            </div>

                            <div>
                              <Button color='secondary' variant='contained' onClick={handleSubmit}>
                                {this.props.provider.intl.formatMessage({ id: 'salvar' })}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </MuiThemeProvider>
                    }
                  />
                </MuiThemeProvider>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(EditarAmbiental);
