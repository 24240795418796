import React, { Component } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Enderecos from './../endereco/Enderecos';
import ContatosAdicionais from './../contato-adicional/ContatosAdicionais';
import Usuarios from './../usuarios/Usuarios';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loading from '@components/Loading/Loading';
import * as moment from 'moment';
import ClientesHandler from '@resources/Clientes';
import formatMessage from '../i18n/formatMessage';
import { style, theme } from './../Clientes.styles';
import Navigation from '@components/Navigation/Navigation';
import Identificacao from './Identificacao';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Produtor from '../produtor/Produtor';

const iconMenuPontos = require('@images/icone_fab_menu.png');

class EditarClientes extends Component {
  constructor(props) {
    super(props);

    this.initialCliente = {
      dsRazaoSocial: '',
      dsNomeFantasia: '',
      dsCnpj: '',
      dsEmail: '',
      nrTelefone: '',
      dsMotivoInativacao: '',
      dhInativacao: null,
      clienteContatoList: [],
      clienteEnderecoList: [],
      clienteUsuarioList: [],
      produtorUsuarioList: []
    };

    this.state = {
      keyData: 0,
      organica: false,
      cooperativa: false,
      cliente: { ...this.initialCliente },
      canLeave: false,
      selectContatosAdicionais: {},
      selectEnderecos: {},
      selectUsuarios: {},
      clienteDeletadoList: [],
      modalCadastrar: true,
      index: 0,
      dataAppSync: [],
      usuariosDelete: [],
      check: true,
      plano: '',
      itensPlanosAcesso: [],
      newProps: {},
      newState: {},
      error: false,
      editar: false,
      enableReinitialize: true,
      usuariosNaoSalvos: [],
      loading: this.props.match.params.id !== undefined,
      key: 100,
      mensagemUsuariosNaoSalvos: false,
      listProdutores: [],
      itemFoto: {},
      itensButtons: [
        {
          name: formatMessage('clientes.identificacao'),
          key: 0,
          select: true
        },
        {
          name: formatMessage('clientes.endereco'),
          key: 1,
          select: false
        },
        {
          name: formatMessage('clientes.contatosAdicionais'),
          key: 2,
          select: false
        },
        {
          name: formatMessage('clientes.usuarios'),
          key: 3,
          select: false
        },
        {
          name: formatMessage('clientes.produtores'),
          key: 4,
          select: false
        }
      ],
      itensIdentificacao: [
        {
          id: 2,
          field: 'Principal',
          name: formatMessage('clientes.principal')
        },
        {
          id: 0,
          field: 'Entregas',
          name: formatMessage('clientes.entregas')
        },
        {
          id: 1,
          field: 'Financeiro',
          name: formatMessage('clientes.financeiro')
        }
      ],
      itensDepartamento: [
        {
          id: 0,
          field: 'Administrativo',
          name: formatMessage('clientes.representante')
        },
        {
          id: 1,
          field: 'Financeiro',
          name: formatMessage('clientes.financeiro')
        },
        {
          id: 2,
          field: 'Gerência',
          name: formatMessage('clientes.gerencia')
        },
        {
          id: 3,
          field: 'T.I.',
          name: formatMessage('clientes.ti')
        }
      ],
      itensPerfil: [
        {
          id: 0,
          field: 'Administrador',
          name: formatMessage('clientes.representante')
        },
        {
          id: 1,
          field: 'Revenda',
          name: formatMessage('clientes.gerente')
        },
        {
          id: 2,
          field: 'Técnico',
          name: formatMessage('clientes.tecnico')
        }
      ],
      statusUsuarioEnum: {
        REGISTRADO: 1,
        CONFIRMADO: 2,
        properties: {
          1: {
            name: formatMessage('clientes.registrado'),
            value: 'UNCONFIRMED'
          },
          2: {
            name: formatMessage('clientes.confirmado'),
            value: 'CONFIRMED'
          }
        }
      }
    };

    this.isDirty = function() {
      return JSON.stringify(this.initialCliente) !== JSON.stringify(this.state.cliente);
    }.bind(this);

    window.onbeforeunload = function() {
      if (this.isDirty()) {
        return true;
      }
    }.bind(this);

    const title = 'clientes.cadastroCliente';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(title),
        name: title
      });
    }

    this.locale = '';
    this.handleSubmit = null;

    this.checkSwitch = this.checkSwitch.bind(this);
    this.clickSalvar = this.clickSalvar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.onClickEnderecos = this.onClickEnderecos.bind(this);
    this.onClickContatosAdicionais = this.onClickContatosAdicionais.bind(this);
    this.onClickDeleteEndereco = this.onClickDeleteEndereco.bind(this);
    this.onClickDeleteContatosAdicionais = this.onClickDeleteContatosAdicionais.bind(this);
    this.retornaSalvarLinguagem = this.retornaSalvarLinguagem.bind(this);
    this.retornaCancelarLinguagem = this.retornaCancelarLinguagem.bind(this);
    this.onClickUsuarios = this.onClickUsuarios.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.mensagemClienteCadastradoComSucesso = this.mensagemClienteCadastradoComSucesso.bind(this);
    this.onClickDeleteUsuarios = this.onClickDeleteUsuarios.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.onClickCancelar = this.onClickCancelar.bind(this);
    this.clickAdicionarEndereco = this.clickAdicionarEndereco.bind(this);
    this.clickAdicionarContatos = this.clickAdicionarContatos.bind(this);
    this.clickAdicionarUsuarios = this.clickAdicionarUsuarios.bind(this);
    this.onTryExit = this.onTryExit.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
    this.onChangeCampo = this.onChangeCampo.bind(this);
    this.saveFoto = this.saveFoto.bind(this);
  }

  usuariosCliente = [];
  createNewUsuario = [];
  resetFormGlobal;

  retornaSalvarLinguagem() {
    return formatMessage('clientes.salvar');
  }

  retornaCancelarLinguagem() {
    return formatMessage('clientes.cancelar');
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      ClientesHandler.findClienteById(this.props.match.params.id)
        .then(response => {
          const data = response.data;
          data.tpCooperativa = data.stCooperativa === 1 ? data.tpCooperativa : '';

          data.clienteEnderecoList.forEach(element => {
            element.dsBairro = element.nmBairro;
            const value = this.state.itensIdentificacao.find(doc => {
              return element.tpEndereco === doc.id;
            });
            element.nmIdentificacao = value.name;
          });

          data.clienteContatoList.forEach(element => {
            element.nmDepartamento = this.state.itensDepartamento[element.tpContato].name;
          });

          var listProdutores = [];
          var dataUsuarios = [];
          data.clienteUsuarioList.forEach(element => {
            if (element.tpPerfil === 3) {
              listProdutores.push(element);
            } else {
              element.nmPerfil = this.state.itensPerfil[element.tpPerfil].name;
              dataUsuarios.push(element);
            }
            element.nmStUsuario =
              element.stUsuario === 0
                ? formatMessage('clientes.inativo')
                : formatMessage('clientes.ativo');
          });

          data.clienteUsuarioList = dataUsuarios;
          data.dsNomeFantasia = data.nmCliente;
          data.dsCnpj = data.nrDocumento;

          if (data.clienteInativacao) {
            data.dhInativacao = data.clienteInativacao.dhInativacao;
            data.dsMotivoInativacao = data.clienteInativacao.dsMotivo;
          }

          const dataCliente = {
            dsRazaoSocial: data.dsRazaoSocial,
            dsNomeFantasia: data.nmCliente,
            dsCnpj: data.nrDocumento,
            dsEmail: data.dsEmail,
            nrTelefone: data.nrTelefone,
            dsMotivoInativacao: data.dsMotivoInativacao,
            dhInativacao: data.dhInativacao,
            clienteContatoList: data.clienteContatoList,
            clienteEnderecoList: data.clienteEnderecoList,
            clienteUsuarioList: data.clienteUsuarioList,
            produtorUsuarioList: data.produtorUsuarioList,
            tpCooperativa: data.tpCooperativa
          };

          this.initialCliente = { ...dataCliente };

          this.setState({
            organica: data.stOrganica === 1,
            cooperativa: data.stCooperativa === 1,
            cliente: dataCliente,
            dataAppSync: data,
            check: response.data.stCliente === 1 ? true : false,
            loading: false,
            editar: true,
            listProdutores: listProdutores,
            itemFoto: {
              dsFotoBase64: data.dsLogo,
              dsFoto: data.dsLogo,
              stRegistro: 1
            }
          });
        })
        .catch(err => {
          swal({
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
            icon: 'error',
            title: formatMessage('clientes.falhaAoCarregarClientes')
          });
          this.setState({
            dataAppSync: [],
            error: true,
            loading: false
          });
        });
    } else {
      var date = [];
      date.dtCadastro = new Date();
      date.dhInativacao = null;
      date.clienteEnderecoList = [];
      date.clienteContatoList = [];
      date.clienteUsuarioList = [];
      date.produtorUsuarioList = [];
      date.dsMotivoInativacao = '';

      this.setState({
        dataAppSync: date
      });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  checkSwitch() {
    this.setState({
      check: !this.state.check
    });
  }

  onClickContatosAdicionais(values, position) {
    this.setState({
      enableReinitialize: false
    });

    var data = this.state.dataAppSync;
    this.state.itensDepartamento.forEach(departamento => {
      if (departamento.id === values.tpContato) {
        values.nmDepartamento = departamento.name;
      }
    });
    if (position !== undefined) {
      data.clienteContatoList[position] = values;
      data.clienteContatoList = [...data.clienteContatoList];
    } else {
      data.clienteContatoList = data.clienteContatoList.concat(values);
    }

    this.setState(
      {
        cliente: data,
        dataAppSync: data,
        modalCadastrar: true,
        enableReinitialize: true
      },
      () => {
        this.setState({
          keyData: Math.random()
        });
      }
    );
  }

  onClickEnderecos(values, position) {
    this.setState({
      enableReinitialize: false
    });
    var data = this.state.dataAppSync;
    this.state.itensIdentificacao.map(identificacao => {
      if (identificacao.id === values.tpEndereco) {
        values.nmIdentificacao = identificacao.name;
      }
      return '';
    });

    if (position !== undefined) {
      data.clienteEnderecoList[position] = values;
      data.clienteEnderecoList = [...data.clienteEnderecoList];
    } else {
      data.clienteEnderecoList = data.clienteEnderecoList.concat(values);
    }

    this.onClickCancelar(0);

    this.setState(
      {
        cliente: data,
        dataAppSync: data,
        enableReinitialize: true
      },
      () => {
        this.setState({
          keyData: Math.random()
        });
      }
    );
  }

  onClickUsuarios(values, position) {
    this.setState({
      enableReinitialize: false
    });

    var data = this.state.dataAppSync;
    this.state.itensPerfil.forEach(perfil => {
      if (perfil.id === values.tpPerfil) {
        values.nmPerfil = perfil.name;
      }
    });

    values.nmStUsuario =
      values.stUsuario === 0 ? formatMessage('clientes.inativo') : formatMessage('clientes.ativo');

    if (position !== undefined) {
      data.clienteUsuarioList[position] = values;
      data.clienteUsuarioList = [...data.clienteUsuarioList];
    } else {
      data.clienteUsuarioList = data.clienteUsuarioList.concat(values);
    }

    this.onClickCancelar(0);

    this.setState(
      {
        cliente: data,
        dataAppSync: data,
        enableReinitialize: true
      },
      () => {
        this.setState({
          keyData: Math.random()
        });
      }
    );
  }

  onClickDeleteEndereco(position) {
    swal({
      title: formatMessage('clientes.desejaExcluirEndereco'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('clientes.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('clientes.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(excluir => {
      if (excluir) {
        this.excluirEndereco(position);
      } else {
        swal.close();
      }
    });
  }

  excluirEndereco(position) {
    const data = this.state.dataAppSync;
    data.clienteEnderecoList.splice(position, 1);
    data.clienteEnderecoList = [...data.clienteEnderecoList];

    this.setState({
      dataAppSync: data,
      cliente: data
    });

    swal.close();
    this.onClickCancelar(0);
  }

  onClickDeleteContatosAdicionais(position) {
    swal({
      title: formatMessage('clientes.desejaExcluirContatosAdicionais'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('clientes.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('clientes.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(excluir => {
      if (excluir) {
        this.excluirContatoAdicional(position);
      } else {
        swal.close();
      }
    });
  }

  excluirContatoAdicional(position) {
    const data = this.state.dataAppSync;
    data.clienteContatoList.splice(position, 1);
    data.clienteContatoList = [...data.clienteContatoList];

    this.setState({
      dataAppSync: data,
      cliente: data
    });

    swal.close();
    this.onClickCancelar(0);
  }

  clickSalvar(values, { resetForm }) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(this.state.editar ? 'clientes.atualizando' : 'clientes.salvando')
      }
    );
    values.clienteEnderecoList = this.state.dataAppSync.clienteEnderecoList.map(doc => {
      const data = {
        tpEndereco: doc.tpEndereco,
        municipio: doc.municipio,
        uf: doc.uf,
        nrCep: doc.nrCep,
        dsRua: doc.dsRua,
        nrEndereco: doc.nrEndereco
      };

      if (doc.dsComplemento !== '' && doc.dsComplemento !== undefined) {
        data.dsComplemento = doc.dsComplemento;
      }

      if (doc.nmBairro !== '' && doc.nmBairro !== undefined) {
        data.nmBairro = doc.dsBairro;
      }

      return data;
    });
    values.clienteContatoList = this.state.dataAppSync.clienteContatoList.map(doc => {
      return {
        dsEmail: doc.dsEmail,
        nmContato: doc.nmContato,
        tpContato: doc.tpContato,
        nrTelefone: doc.nrTelefone
      };
    });

    values.usuarioList = this.state.dataAppSync.clienteUsuarioList.map(doc => {
      if (doc.idClienteUsuario !== undefined) {
        return {
          idClienteUsuario: doc.idClienteUsuario,
          tpPerfil: doc.tpPerfil,
          nmUsuario: doc.nmUsuario,
          dsEmail: doc.dsEmail,
          stUsuario: doc.stUsuario,
          stRegistro: doc.stRegistro
        };
      } else {
        return {
          tpPerfil: doc.tpPerfil,
          nmUsuario: doc.nmUsuario,
          dsEmail: doc.dsEmail,
          stUsuario: doc.stUsuario,
          stRegistro: doc.stRegistro
        };
      }
    });

    this.state.clienteDeletadoList.forEach(item => {
      item.stRegistro = 2;
      values.usuarioList.push(item);
    });

    values.dhInativacao = values.dhInativacao
      ? moment(values.dhInativacao)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZZ')
      : null;
    values.dtCadastro = moment(values.dtCadastro).format();
    values.stCliente = this.state.check ? 1 : 0;
    values.idCliente = this.props.match.params.id;
    values.nmSchema = this.state.dataAppSync.nmSchema;
    values.stOrganica = this.state.organica ? 1 : 0;
    values.stCooperativa = this.state.cooperativa ? 1 : 0;
    values.dsLogoBase64 = this.state.itemFoto.stRegistro === 0 ? this.state.itemFoto.dsFotoBase64 : null;
    values.dsLogo = this.state.itemFoto.dsFoto;

    if (this.props.match.params.id !== undefined) {
      this.state.listProdutores.forEach(doc => {
        values.usuarioList.push(doc);
      });

      ClientesHandler.updateCliente(values)
        .then(result => {
          this.mensagemClienteCadastradoComSucesso();
        })
        .catch(err => {
          this.mensagemUsuariosNaoCadastrados(err.response);
        });
    } else {
      ClientesHandler.addCliente(values)
        .then(result => {
          this.mensagemClienteCadastradoComSucesso();
        })
        .catch(err => {
          this.mensagemUsuariosNaoCadastrados(err.response);
        });
    }
  }

  mensagemUsuariosNaoCadastrados(err) {
    if (err !== undefined && err.data.codigo === 'CNPJ_DUPLICADO') {
      swal({
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        },
        icon: 'error',
        text: formatMessage('clientes.cnpjDuplicado')
      });
    } else {
      swal({
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        },
        icon: 'error',
        text: formatMessage(this.state.editar ? 'clientes.erroAoEditar' : 'clientes.erroAoCadastrar')
      });
    }
  }

  mensagemClienteCadastradoComSucesso() {
    swal(
      formatMessage(
        this.state.editar ? 'clientes.clienteEditadoComSucesso' : 'clientes.clienteCadastradoComSucesso'
      ),
      {
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        }
      }
    ).then(result => {
      this.setState({ canLeave: true }, () => {
        if (this.locale !== '') {
          this.props.history.push(this.locale);
        } else {
          this.props.history.push('/app/clientes');
        }
      });
    });
  }

  resetForm() {
    this.resetFormGlobal({
      dsRazaoSocial: '',
      dsNomeFantasia: '',
      dsCnpj: '',
      dsEmail: '',
      nrTelefone: '',
      plano: [],
      nrProdutoresAtivos: 0,
      dsMotivoInativacao: '',
      dhInativacao: null
    });
  }

  clickCancelar() {
    this.props.history.push('/app/clientes');
  }

  onClickDeleteUsuarios(values) {
    swal({
      title: formatMessage('clientes.desejaExcluir'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('clientes.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('clientes.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        let listUsuarios = this.state.usuariosDelete;
        listUsuarios.push(values);

        let data = this.state.dataAppSync;
        const position = data.clienteUsuarioList.indexOf(values);
        let clienteDeletadoList = [...this.state.clienteDeletadoList];
        clienteDeletadoList.push({ ...data.clienteUsuarioList[position] });

        data.clienteUsuarioList.splice(position, 1);
        data.clienteUsuarioList = [...data.clienteUsuarioList];

        swal(formatMessage('clientes.usuarioExcluidoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        }).then(() => {
          this.setState({
            cliente: data,
            dataAppSync: data,
            usuariosDelete: listUsuarios,
            clienteDeletadoList: clienteDeletadoList
          });

          this.onClickCancelar(0);
        });
      }
    });
  }

  onClickCancelar() {
    this.setState({
      modalCadastrar: true
    });
  }

  handleChangeIndex(index) {
    this.state.itensButtons.forEach(doc => {
      if (index === doc.key) {
        doc.select = true;
      } else {
        doc.select = false;
      }
    });

    this.setState({ index: index, modalCadastrar: true });
  }

  clickAdicionarEndereco(item) {
    if (item !== undefined) {
      this.setState({
        modalCadastrar: false,
        selectEnderecos: item
      });
    } else {
      this.setState({
        modalCadastrar: false,
        selectEnderecos: {}
      });
    }
  }

  clickAdicionarContatos(item) {
    if (item !== undefined) {
      this.setState({
        modalCadastrar: false,
        selectContatosAdicionais: item,
        index: 2
      });
    } else {
      this.setState({
        modalCadastrar: false,
        selectContatosAdicionais: {},
        index: 2
      });
    }
  }

  clickAdicionarUsuarios(item) {
    if (item !== undefined) {
      this.setState({
        modalCadastrar: false,
        selectUsuarios: item,
        index: 3
      });
    } else {
      this.setState({
        modalCadastrar: false,
        selectUsuarios: {},
        index: 3
      });
    }
  }

  onChangeCampo(campo, e, callback, convertToNum = false) {
    callback(e);
    const aux = this.state.cliente;
    aux[campo] = convertToNum ? +e.target.value : e.target.value;
    this.setState({ cliente: aux, dataAppSync: aux });
  }

  onTryExit(location) {
    if (this.isDirty()) {
      swal({
        title: formatMessage('clientes.salvarClienteSair'),
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          cancel: {
            text: formatMessage('clientes.descartar'),
            value: false,
            visible: true,
            closeModal: true,
            className: 'swal2-cancel'
          },
          confirm: {
            text: formatMessage('clientes.salvar'),
            value: true,
            visible: true,
            closeModal: false,
            className: 'swal2-confirm'
          }
        }
      }).then(res => {
        swal.close();
        if (res) {
          this.locale = location.pathname;
          this.handleChangeIndex(0);
          this.setState(
            {
              inde: 0
            },
            () => {
              this.handleSubmit && this.handleSubmit();
            }
          );
        } else {
          this.setState(
            { canLeave: true },
            () => this.state.canLeave && this.props.history.push(location.pathname)
          );
        }
      });
      return false;
    }

    this.setState(
      { canLeave: true },
      () => this.state.canLeave && this.props.history.push(location.pathname)
    );
  }

  handleChangeChecked(event) {
    this.setState({
      [event]: !this.state[event]
    });
  }

  saveFoto(item) {
    this.setState({
      itemFoto: item
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.cardTabs}>
          {this.state.loading && <Loading />}
          <Navigation
            index={this.state.index}
            handleChangeIndex={this.handleChangeIndex}
            buttons={this.state.itensButtons}
          >
            {[
              <div key={0} className={classes.cardTabs}>
                <Identificacao
                  key={this.state.key}
                  dataAppSync={this.state.dataAppSync}
                  check={this.state.check}
                  enableReinitialize={this.state.enableReinitialize}
                  cooperativa={this.state.cooperativa}
                  organica={this.state.organica}
                  classes={classes}
                  clickSalvar={this.clickSalvar}
                  onTryExit={this.onTryExit}
                  canLeave={this.state.canLeave}
                  tratarSubmit={submitForm => (this.handleSubmit = submitForm)}
                  onChangeCampo={this.onChangeCampo}
                  onChangeDate={(campo, date) => {
                    const aux = this.state.cliente;
                    aux[campo] = date;
                    this.setState({ cliente: aux, dataAppSync: aux });
                  }}
                  checkSwitch={this.checkSwitch}
                  handleChangeChecked={this.handleChangeChecked}
                  changeReinitialize={() => {
                    this.setState({
                      enableReinitialize: false
                    });
                  }}
                  changeCooperativa={() => {
                    this.setState({
                      cooperativa: false
                    });
                  }}
                  itemFoto={this.state.itemFoto}
                  saveFoto={this.saveFoto}
                />
              </div>,
              <div key={1} className={classes.cardTabs}>
                <Enderecos
                  keyData={this.state.keyData}
                  onClickCancelar={this.onClickCancelar}
                  dataAppSync={this.state.dataAppSync.clienteEnderecoList || []}
                  status={this.state.check}
                  selectEnderecos={this.state.selectEnderecos}
                  clickAdicionarEndereco={this.clickAdicionarEndereco}
                  onClickEndereco={this.onClickEnderecos}
                  itensIdentificacao={this.state.itensIdentificacao}
                  onClickDeleteEndereco={this.onClickDeleteEndereco}
                />
              </div>,
              <div key={2} className={classes.cardTabs}>
                <ContatosAdicionais
                  keyData={this.state.keyData}
                  clickAdicionarContatos={this.clickAdicionarContatos}
                  status={this.state.check}
                  onClickContatosAdicionais={this.onClickContatosAdicionais}
                  itensDepartamento={this.state.itensDepartamento}
                  onClickDeleteContatosAdicionais={this.onClickDeleteContatosAdicionais}
                  selectContatosAdicionais={this.state.selectContatosAdicionais}
                  onClickCancelar={this.onClickCancelar}
                  clienteContatoList={this.state.dataAppSync.clienteContatoList || []}
                />
              </div>,
              <div key={3} className={classes.cardTabs}>
                <Usuarios
                  keyData={this.state.keyData}
                  clickAdicionarUsuarios={this.clickAdicionarUsuarios}
                  data={this.state.dataAppSync.clienteUsuarioList || []}
                  status={this.state.check}
                  onClickUsuarios={this.onClickUsuarios}
                  itensPerfil={this.state.itensPerfil}
                  onClickDeleteUsuarios={this.onClickDeleteUsuarios}
                  idCliente={this.state.dataAppSync.idCliente}
                  selectUsuarios={this.state.selectUsuarios}
                  onClickCancelar={this.onClickCancelar}
                />
              </div>,
              <div key={4} className={classes.cardTabs}>
                <Produtor produtorList={this.state.dataAppSync.produtorUsuarioList || []} />
              </div>
            ]}
          </Navigation>
          {this.state.modalCadastrar && (
            <div className={classes.fab}>
              <ButtonFABMenu
                icon={iconMenuPontos}
                actions={[
                  {
                    icon: <Check style={{ color: 'white' }} />,
                    name: this.retornaSalvarLinguagem(),
                    onClickIcon: () => {
                      this.handleChangeIndex(0);
                      this.setState({ index: 0 }, () => this.handleSubmit());
                    },
                    color: '#42ADE8',
                    disabled: this.state.error
                  },
                  {
                    icon: <Close style={{ color: 'white' }} />,
                    name: this.retornaCancelarLinguagem(),
                    onClickIcon: this.clickCancelar,
                    color: '#F33A30'
                  }
                ]}
                onClick={this.clickAdicionar}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarClientes);
export default withRouter(connect(mapStateToProps)(enhaced));
