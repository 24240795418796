export default {
  insumo: {
    descricao: 'Descrição',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    campoObrigatorio: 'Campo obrigatório',
    marca: 'Marca',
    naoOrganico: 'Não orgânico',
    certificacaoOrganica: 'Certificação orgânica',
    identificacao: 'Identificação',
    certificacao: 'Certificação',
    historico: 'Histórico',
    historicoCertificacao: 'Histórico de certificação',
    validadeDe: 'Validade de',
    validadeAte: 'Validade até',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    cadastrar: 'Cadastrar',
    certificadoExistente: 'Já existe um certificado nesse período',
    indefinida: 'Indefinida',
    ativo: 'Ativo',
    inativo: 'Inativo',
    status: 'Situação',
    inativarDataFinal: 'Certificados inativos precisam conter data final',
    nenhumaCertificacaoAtiva: 'Nenhuma certificação ativa'
  }
};
