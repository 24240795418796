//@author Katia Miglioli
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  actions: {
    display: 'flex',
    padding: 0
  },
  expand: {
    marginTop: 5,
    marginRight: 33,
    opacity: 1,
    float: 'right',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  div: {
    position: 'relative',
    paddingTop: 100,
    display: 'grid',
    flex: 1
  },
  avatar: {
    borderRadius: 5,
    backgroundColor: '#42ADE8',
    width: 54,
    height: 54,
    left: 15,
    margin: 0,
    marginRight: '15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
  },
  divTitulo: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(139, 200, 165, 0.1)',
    minHeight: 60,
    marginRight: -30,
    marginTop: -30,
    marginLeft: -30,
    alignItems: 'flex-start',
    display: 'flex'
  },
  divTituloExpandBotton: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
    padding: 0,
    height: 'auto'
  },
  titulo: {
    minHeight: 60,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 30,
    fontWeight: 500,
    fontSize: 20,
    color: 'rgb( 0, 0, 0, 0.7)',
    width: '100%'
  },
  rodaPe: {
    marginRight: -30,
    marginLeft: -30,
    marginBottom: -30,
    padding: 15,
    paddingRight: 30,
    justifyContent: 'flex-end',
    display: 'flex',
    height: 60,
    borderTopColor: 'rgb(0, 0, 0, 0.1)',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    alignItems: 'center'
  }
});

class CardIcon extends React.Component {
  state = {
    expanded: this.props.expanded !== undefined ? this.props.expanded : true,
    overflow: 'visible'
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.expanded !== undefined && nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }

    if (nextProps.closeCard && this.state.expanded) {
      this.setState({ expanded: false });
    }

    if (nextProps.errorCard && !this.state.expanded) {
      this.setState({ expanded: true });
    }
  }

  handleExpandClick = () => {
    this.props.onClickExpanded && this.props.onClickExpanded(!this.state.expanded);
    this.setState(
      state => ({
        expanded: !state.expanded,
        overflow: !state.expand ? 'hidden' : 'visible'
      }),
      () => {
        this.props.onClickExpandButton && this.props.onClickExpandButton();
        this.state.expanded &&
          setTimeout(() => {
            this.setState({
              overflow: 'visible'
            });
          }, 1000);
        this.props.expandedButton && this.props.expandedButton(this.state.expanded);
      }
    );
  };

  render() {
    const { classes } = this.props;
    const stylesDiv = {
      paddingLeft: this.props.usePaddings ? 15 : 0,
      paddingRight: this.props.usePaddings ? 15 : 0,
      paddingTop: 0
    };
    const stylesCard = {
      flex: 1,
      borderRadius: this.props.usePaddings || this.props.userMargins ? 5 : 0,
      overflow: this.state.overflow,
      padding: this.props.disablePadding ? 0 : 30,
      paddingBottom: this.state.expanded ? 30 : 0,
      paddingLeft: this.props.paddingLaterais ? 0 : 30,
      paddingRight: this.props.paddingLaterais ? 0 : 30,
      boxSizing: 'border-box',
      ...this.props.styleCard
    };

    return (
      <div className={classes.div} style={stylesDiv}>
        <Card style={stylesCard}>
          {!this.props.disableHeader && (
            <CardContent
              className={classes.divTituloExpandBotton}
              style={{
                paddingLeft: this.props.paddingLaterais ? 30 : 0,
                paddingRight: this.props.paddingLaterais ? 30 : 0
              }}
            >
              <div className={classes.divTitulo}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded
                    })}
                    style={{ display: this.props.filtros ? null : 'none' }}
                    onClick={this.handleExpandClick}
                    aria-expanded={this.state.expanded}
                  >
                    {this.props.stateCardblock ? <ExpandMoreIcon /> : ''}
                  </IconButton>
                </div>
                {this.state.expanded ? (
                  <div className={classes.titulo}>{this.props.titulo}</div>
                ) : (
                  <div className={classes.titulo}>{this.props.header}</div>
                )}
                {/* header:Componentes do card fechado*/}
              </div>
            </CardContent>
          )}
          <CardActions className={classes.actions} disableActionSpacing />
          <Collapse
            in={this.state.expanded}
            timeout='auto'
            unmountOnExit
            style={{
              overflow: this.props.filtros ? 'initial' : 'visible',
              width: '100%',
              height: '100%'
            }}
            className={this.state.expanded ? 'run-animation-open' : 'run-animation-close'}
          >
            <CardContent style={{ padding: 0, height: this.props.height ? '100%' : null }}>
              <div style={{ marginRight: -30, marginLeft: -30 }}>{this.props.tabs}</div>
              {this.props.children}
            </CardContent>
          </Collapse>

          {this.props.buttons && this.props.buttons.length > 0 && this.state.expanded && (
            <div className={classes.rodaPe}>
              {this.props.buttons.map((botao, key) => (
                <div key={key}>{botao}</div>
              ))}
            </div>
          )}
        </Card>
      </div>
    );
  }
}

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardIcon);
