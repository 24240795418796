export default {
  agencia: {
    erroAoBuscarDados: 'Erro ao buscar dados',
    agencia: 'Agência',
    desejaExcluirAgencia: 'Deseja excluir esta agência?',
    nao: 'Não',
    excluir: 'Excluir',
    agenciaExcluida: 'Agência excluída com sucesso',
    erroAoExcluir: 'Ocorreu um erro ao excluir a agência',
    cadastroDeAgencia: 'Cadastro de agência',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    agenciaSalvoComSucesso: 'Agência salva com sucesso',
    erroAoSalvar: 'Erro ao salvar agência',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    nome: 'Nome',
    codigo: 'Código',
    estado: 'Estado',
    cidade: 'Cidade',
    ativo: 'Ativo',
    inativo: 'Inativo',
    status: 'Situação',
    codigoExistente: 'Código de agência já existente',
    ocorreuErroSalvar: 'Ocorreu um erro ao salvar'
  }
};
