import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { getUserLang } from '@utils/localeUtils';
import * as Yup from 'yup';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Pesticida from '@resources/Pesticidas';
import Marca from '@resources/Marcas';
import InsumosDescricaoEnum from '../enumerations/InsumosEnum';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import { style, theme } from '../AcompanhamentoProdutor.styles';
import { swalOptions, swalSucess } from '../utils/SwalOptions';
import CategoriaInsumoEnum from '../enumerations/CategoriaInsumoEnum';
import UnidadeMedidaEnum from '../enumerations/UnidadeMedidaEnum';
import formatMessage from '../i18n/formatMessage';

function EditarProdutosAcompanhamento(props) {
  const {
    classes,
    clickShowButton,
    itensGridList,
    typeFertilizante,
    itemSelectProdutos,
    onClickDeleteProduto,
    onClickAddProduto
  } = props;
  const [tpPesticida, setTpPesticida] = useState('');
  const [tpUnidadeMedida, setTpUnidadeMedida] = useState('');
  const [tpCategoria, setTpCategoria] = useState('');
  const [vlDose, setVlDose] = useState(0);
  const [insumo, setInsumo] = useState([]);
  const [itemSelect, setItemSelect] = useState(null);
  const [key, setKey] = useState('');
  const [marcaList, setMarcaList] = useState([]);
  const [insumoList, setInsumoList] = useState([]);
  const [valueAutoCompleteMarca, setValueAutoCompleteMarca] = useState(null);
  const [valueAutoCompleteDescricao, setValueAutoCompleteDescricao] = useState(null);
  const cardSelectStyle = { width: '100%', backgroundColor: 'transparent' };

  useEffect(() => {
    if (itemSelectProdutos !== null) {
      trataValoresToEdit();
    } else {
      findPesticidasList();
    }
  }, []);

  useEffect(() => {
    findAllMarcaList();
  }, [tpPesticida]);

  /**
   * Filtra a lista caso seja do tipo fertilizante
   */
  function filterCategoriaInsumo() {
    let categoriaInsumoListAux = [...CategoriaInsumoEnum];
    let categoria = CategoriaInsumoEnum.find(doc => {
      return doc.id === 7;
    });

    if (typeFertilizante) {
      categoriaInsumoListAux = [categoria];
    } else if (itensGridList.length > 0) {
      let position = categoriaInsumoListAux.indexOf(categoria);
      categoriaInsumoListAux.splice(position, 1);
    }

    return categoriaInsumoListAux;
  }

  /**
   * Trata os valores para editar
   */
  function trataValoresToEdit() {
    setValueAutoCompleteMarca(itemSelectProdutos.marca);
    setValueAutoCompleteDescricao(itemSelectProdutos.insumo);

    findPesticidasList(valueAutoCompleteMarca, itemSelectProdutos.tpPesticida.id);

    const tpCategoriaAux = findCategoriaCorrespondente(itemSelectProdutos.tpPesticida.id);
    findInsumoByCategoria(tpCategoriaAux);

    setTpUnidadeMedida(itemSelectProdutos.tpUnidadeMedida);
    setTpCategoria(tpCategoriaAux);
    setTpPesticida(itemSelectProdutos.tpPesticida.id);
    setVlDose(itemSelectProdutos.vlDose);
    setItemSelect(itemSelectProdutos);
    setKey(Math.random());
  }

  /**
   * Busca os pesticidas validando se tiver filtro
   * @param {*} marca - marca selecionada
   * @param {*} pesticida - filtro selecionado
   */
  function findPesticidasList(marca, pesticida) {
    let findPesticidas;
    if (!pesticida) {
      findPesticidas = Pesticida.findAllAtivosPesticidas();
    } else {
      findPesticidas = Pesticida.findAtivosPesticidasByTpPesticida(pesticida);
    }

    findPesticidas.then(query => {
      let pesticidasList = query.data;
      if (marca) {
        pesticidasList = [];
        query.data.forEach(doc => {
          if (doc.marca.idMarca === marca.idMarca) {
            pesticidasList.push(doc);
          }
        });
      }

      setInsumoList(pesticidasList);
    });
  }

  /**
   * Busca a categoria correspondente ao enum
   */
  function findCategoriaCorrespondente(categoriaToInsumoEnum) {
    return CategoriaInsumoEnum.find(categoraInsumo =>
      categoraInsumo.insumo.includes(categoriaToInsumoEnum)
    ).id;
  }

  /**
   * Busca o insumo correspondente a categoria
   */
  function findInsumoByCategoria(value) {
    var insumoAux = [];

    const categoriaInsumoListAux = CategoriaInsumoEnum.find(
      categoriaInsumo => categoriaInsumo.id === value
    ).insumo;
    categoriaInsumoListAux.forEach(categoriaInsumo => {
      insumoAux.push(InsumosDescricaoEnum[categoriaInsumo]);
    });

    setVlDose(tpCategoria === 7 ? '' : vlDose);
    setInsumo(insumoAux);
    setTpUnidadeMedida(tpCategoria === 7 ? tpUnidadeMedida : '');
  }

  /**
   * Busca valores para listagem de marca
   */
  function findAllMarcaList() {
    let findAllMarca = null;
    if (tpPesticida !== undefined && tpPesticida !== '') {
      findAllMarca = Marca.findMarcaByTpPesticida(tpPesticida);
    } else {
      findAllMarca = Marca.findAllMarca();
    }

    findAllMarca.then(query => {
      setMarcaList(query.data);
    });
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Formik
        initialValues={{
          tpCategoria: tpCategoria !== '' ? tpCategoria : '',
          tpPesticida: tpPesticida !== '' ? tpPesticida : '',
          marca: valueAutoCompleteMarca || '',
          descricao: valueAutoCompleteDescricao || '',
          vlDose: vlDose || '',
          tpUnidadeMedida: tpUnidadeMedida !== '' ? tpUnidadeMedida : ''
        }}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={onSave}
        validationSchema={Yup.object().shape({
          tpCategoria: Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio')),
          tpPesticida:
            tpCategoria === ''
              ? Yup.string()
              : Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio')),
          marca: Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio')),
          descricao: Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio')),
          vlDose: Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio')),
          tpUnidadeMedida:
            tpCategoria === 7
              ? Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio'))
              : Yup.string()
        })}
        render={({ values, handleSubmit, errors, touched, setFieldValue }) => (
          <div
            className={classes.cardEditarAcompanhamento}
            style={{ height: values.tpCategoria === 7 ? 380 : 280 }}
          >
            <CardCadastro
              titulo={formatMessage('acompanhamentoProdutor.adicionarProdutos')}
              marginLeft={30}
              botoes={[
                itemSelectProdutos !== null && (
                  <Button color='primary' variant='contained' onClick={clickExcluir}>
                    {formatMessage('acompanhamentoProdutor.excluir')}
                  </Button>
                ),
                <Button variant='contained' color='inherit' onClick={onClose}>
                  {formatMessage('acompanhamentoProdutor.cancelar')}
                </Button>,
                <Button color='secondary' variant='contained' onClick={handleSubmit}>
                  {formatMessage('acompanhamentoProdutor.confirmar')}
                </Button>
              ]}
            >
              <form autoComplete='off'>
                <div style={{ padding: 15 }}>
                  <Grid container spacing={16} style={{ height: 80 }}>
                    <Grid item xs={3}>
                      <FormControl
                        className={classes.form}
                        error={errors.tpCategoria && touched.tpCategoria}
                      >
                        <InputLabel
                          htmlFor='tpCategoria-helper'
                          error={errors.tpCategoria && touched.tpCategoria}
                          className={classes.font}
                        >
                          {formatMessage('acompanhamentoProdutor.categoria')}
                        </InputLabel>
                        <Select
                          className={classes.fontUniMedida}
                          SelectDisplayProps={{
                            style: cardSelectStyle
                          }}
                          value={values.tpCategoria}
                          onChange={event => onChangeSelectCategoria(event, setFieldValue)}
                          error={errors.tpCategoria && touched.tpCategoria}
                          input={<Input id='tpCategoria-error' />}
                          inputProps={{
                            className: classes.font,
                            name: 'tpCategoria',
                            id: 'tpCategoria-helper'
                          }}
                        >
                          {filterCategoriaInsumo().map(pesticida => (
                            <MenuItem
                              value={pesticida.id}
                              key={filterCategoriaInsumo().indexOf(pesticida)}
                              className={classes.font}
                            >
                              {pesticida.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.tpCategoria && touched.tpCategoria && (
                          <FormHelperText>{errors.tpCategoria}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl
                        disabled={values.tpCategoria === ''}
                        className={classes.form}
                        error={errors.tpPesticida && touched.tpPesticida}
                      >
                        <InputLabel
                          htmlFor='tpPesticida-helper'
                          error={errors.tpPesticida && touched.tpPesticida}
                          className={classes.font}
                        >
                          {formatMessage('acompanhamentoProdutor.tipo')}
                        </InputLabel>
                        <Select
                          className={classes.fontUniMedida}
                          SelectDisplayProps={{
                            style: cardSelectStyle
                          }}
                          value={values.tpPesticida}
                          onChange={event => onChangeSelectTipo(event, setFieldValue)}
                          error={errors.tpPesticida && touched.tpPesticida}
                          input={<Input id='tpPesticida-error' />}
                          inputProps={{
                            className: classes.font,
                            name: 'tpPesticida',
                            id: 'tpPesticida-helper'
                          }}
                        >
                          {insumo.map(pesticida => (
                            <MenuItem
                              value={pesticida.id}
                              key={insumo.indexOf(pesticida)}
                              className={classes.font}
                            >
                              {pesticida.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.tpPesticida && touched.tpPesticida && (
                          <FormHelperText> {errors.tpPesticida} </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <Autocomplete
                        disabled={values.tpPesticida === ''}
                        itens={marcaList}
                        campoOp={'nmMarca'}
                        campoChave={'idMarca'}
                        label={formatMessage('acompanhamentoProdutor.marca')}
                        name='marca'
                        error={valueAutoCompleteMarca === '' && touched.marca}
                        helperText={
                          touched.marca && valueAutoCompleteMarca === ''
                            ? formatMessage('acompanhamentoProdutor.campoObrigatorio')
                            : null
                        }
                        onChangeOwn={event => onChangeAutoCompleteMarca(event, setFieldValue, values)}
                        onChangeAutoComplete={findAllMarcaList}
                        value={tratarAutoComplete(valueAutoCompleteMarca, 'nmMarca')}
                        key={key}
                        valueAutoComplete={tratarAutoComplete(valueAutoCompleteMarca, 'nmMarca')}
                        valueSelect={tratarAutoComplete(valueAutoCompleteMarca, 'nmMarca').nmMarca}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Autocomplete
                        itens={insumoList}
                        campoOp={'nmInsumo'}
                        campoChave={'idInsumo'}
                        label={formatMessage('acompanhamentoProdutor.produto')}
                        name='nmInsumo'
                        error={valueAutoCompleteDescricao === '' && touched.descricao}
                        helperText={
                          touched.descricao && valueAutoCompleteDescricao === ''
                            ? formatMessage('acompanhamentoProdutor.campoObrigatorio')
                            : null
                        }
                        onChangeOwn={event => onChangeAutoCompleteProduto(event, setFieldValue)}
                        onChangeAutoComplete={() => {}}
                        value={tratarAutoComplete(valueAutoCompleteDescricao, 'nmInsumo')}
                        key={key}
                        valueAutoComplete={tratarAutoComplete(valueAutoCompleteDescricao, 'nmInsumo')}
                        valueSelect={tratarAutoComplete(valueAutoCompleteDescricao, 'nmInsumo').nmInsumo}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextFieldWithMask
                        tipoMascara={3}
                        numberDecimalScale={3}
                        showThousandSeparator={true}
                        id='vlDose'
                        name='vlDose'
                        onChange={value => {
                          setFieldValue('vlDose', value.target.value);
                          setVlDose(parseFloat(value.target.value));
                        }}
                        onBlur={value => {
                          const valuePtBr = value.target.value.replace('.', '').replace(',', '.');
                          setFieldValue('vlDose', valuePtBr);
                        }}
                        value={values.vlDose}
                        label={
                          values.tpCategoria === 7
                            ? formatMessage('acompanhamentoProdutor.nrQuantidade')
                            : formatMessage('acompanhamentoProdutor.dose') + ' (L/kg)'
                        }
                        className={classes.textField}
                        InputLabelProps={{
                          className: classes.font
                        }}
                        error={errors.vlDose && touched.vlDose}
                        helperText={touched.vlDose ? errors.vlDose : ''}
                      />
                    </Grid>
                  </Grid>
                  {values.tpCategoria === 7 && (
                    <Grid container spacing={16} style={{ height: 80 }}>
                      <Grid item xs={3}>
                        <FormControl
                          style={
                            values.tpCategoria === 7
                              ? {
                                  transition: 'opacity 0.3s linear',
                                  transitionDelay: '0.3s',
                                  opacity: 1
                                }
                              : {
                                  transition: 'opacity 0.3s linear',
                                  opacity: 0
                                }
                          }
                          className={classes.form}
                          error={errors.tpUnidadeMedida && touched.tpUnidadeMedida}
                        >
                          <InputLabel
                            htmlFor='tpUnidadeMedida-helper'
                            error={errors.tpUnidadeMedida && touched.tpUnidadeMedida}
                            className={classes.font}
                          >
                            {formatMessage('acompanhamentoProdutor.unMedida')}
                          </InputLabel>
                          <Select
                            className={classes.fontUniMedida}
                            SelectDisplayProps={{
                              style: cardSelectStyle
                            }}
                            value={values.tpUnidadeMedida}
                            onChange={event => {
                              setFieldValue('tpUnidadeMedida', event.target.value);
                              setTpUnidadeMedida(event.target.value);
                            }}
                            error={errors.tpUnidadeMedida && touched.tpUnidadeMedida}
                            input={<Input id='tpUnidadeMedida-error' />}
                            inputProps={{
                              className: classes.font,
                              name: 'tpUnidadeMedida',
                              id: 'tpUnidadeMedida-helper'
                            }}
                          >
                            {UnidadeMedidaEnum.map(doc => (
                              <MenuItem
                                value={doc.id}
                                key={UnidadeMedidaEnum.indexOf(doc)}
                                className={classes.font}
                              >
                                {doc.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.tpUnidadeMedida && touched.tpUnidadeMedida && (
                            <FormHelperText>{errors.tpUnidadeMedida}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </form>
            </CardCadastro>
          </div>
        )}
      />
    </MuiThemeProvider>
  );

  /**
   * Alterar select de categoria
   */
  function onChangeSelectCategoria(event, setFieldValue) {
    setFieldValue('tpCategoria', event.target.value);
    setFieldValue('tpPesticida', '');
    setFieldValue('marca', '');
    setFieldValue('descricao', '');

    setValueAutoCompleteDescricao(null);
    setValueAutoCompleteMarca(null);

    setTpCategoria(event.target.value);
    setTpPesticida('');
    setKey(Math.random());

    findInsumoByCategoria(event.target.value);
  }

  /**
   * Alterar select de tipo
   */
  function onChangeSelectTipo(event, setFieldValue) {
    setFieldValue('tpPesticida', event.target.value);
    setFieldValue('marca', '');
    setFieldValue('descricao', '');
    setValueAutoCompleteMarca(null);
    setValueAutoCompleteDescricao(null);
    findPesticidasList(null, event.target.value);
    setTpPesticida(event.target.value);
    setKey(Math.random());
  }

  /**
   * Alterar autocomplete de marca
   */
  function onChangeAutoCompleteMarca(event, setFieldValue, values) {
    setKey(Math.random());
    setFieldValue('marca', event);
    setFieldValue('descricao', '');
    findPesticidasList(event, values.tpPesticida);

    if (!event) {
      setFieldValue('descricao', '');
      setValueAutoCompleteMarca(null);
      setValueAutoCompleteDescricao(null);
    } else {
      setValueAutoCompleteDescricao(null);
      setValueAutoCompleteMarca(event);
    }
  }

  /**
   * Alterar autocomplete de produto
   */
  function onChangeAutoCompleteProduto(event, setFieldValue) {
    setFieldValue('descricao', event);
    if (event) {
      setValueAutoCompleteMarca(event.marca);
      setFieldValue('marca', event.marca);

      setFieldValue('tpPesticida', event.tpInsumo);
      const tpCategoriaAux = findCategoriaCorrespondente(event.tpInsumo);
      setFieldValue('tpCategoria', tpCategoriaAux);

      findInsumoByCategoria(tpCategoriaAux);
      setTpCategoria(tpCategoriaAux);
      setTpPesticida(event.tpInsumo);
    }
    setValueAutoCompleteDescricao(event);
    setValueAutoCompleteDescricao(event);
    setKey(Math.random());
  }

  /**
   * Trata o autocomplete quando é alterado
   */
  function tratarAutoComplete(data, nmVariable) {
    if (!data || data[nmVariable] === undefined) {
      return '';
    }

    return data;
  }

  /**
   * Clicar em voltar
   */
  function onClose() {
    setValueAutoCompleteMarca(null);
    setValueAutoCompleteDescricao(null);
    clickShowButton(true);
  }

  /**
   * Clicar em excluir
   */
  function clickExcluir() {
    const position = itensGridList.indexOf(itemSelect);
    swalOptions('acompanhamentoProdutor.desejaExcluirProduto').then(willDelete => {
      if (willDelete) {
        onClickDeleteProduto(position);
        swalSucess('acompanhamentoProdutor.produtoRemovidoComSucesso');
      }
    });
  }

  function onSave(values) {
    const valuePesticida = insumo.find(doc => {
      return doc.id === values.tpPesticida;
    });

    let tpUnidadeMedidaAux = UnidadeMedidaEnum.find(doc => {
      return doc.id === values.tpUnidadeMedida;
    });

    let vlDoseFertilizante = values.vlDose;
    if (typeFertilizante) vlDoseFertilizante = values.vlDose + '/' + tpUnidadeMedidaAux.name;

    const valueFinal = {
      nrOrdem: '',
      vlDoseFertilizante: vlDoseFertilizante,
      nmVlDose: Intl.NumberFormat(getUserLang()).format(values.vlDose) + ' L/kg',
      vlDose: values.vlDose,
      marca: values.marca,
      insumo: values.descricao,
      tpPesticida: valuePesticida,
      tpUnidadeMedida: values.tpCategoria === 7 ? values.tpUnidadeMedida : 0
    };

    let position = null;
    if (itemSelectProdutos !== null) {
      position = itensGridList.indexOf(itemSelect);
    }

    onClickAddProduto(valueFinal, position);
    setItemSelect(null);
    setValueAutoCompleteMarca(null);
    setValueAutoCompleteDescricao(null);
  }
}

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarProdutosAcompanhamento);
export default withRouter(connect(mapStateToProps)(enhaced));
