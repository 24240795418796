import React, { Component } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { flattenMessages } from '@utils/languageUtils';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUs';
import esES from './i18n/esES';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Switch from '@material-ui/core/Switch';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import swal from '@sweetalert/with-react';
import { style, theme } from './Cultivares.styles';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

class EditarEspecificacoes extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const cultivar = messagesLinguage['cultivares.cultivar'];
    const descricao = messagesLinguage['cultivares.descricao'];
    const situacao = messagesLinguage['cultivares.situacao'];
    const campoObrigatorio = messagesLinguage['cultivares.campoObrigatorio'];
    const cancelar = messagesLinguage['cultivares.cancelar'];
    const confirmar = messagesLinguage['cultivares.confirmar'];
    const ativo = messagesLinguage['cultivares.ativo'];
    const inativo = messagesLinguage['cultivares.inativo'];
    const desejaExcluirCultivar = messagesLinguage['cultivares.desejaExcluirCultivar'];
    const nao = messagesLinguage['cultivares.nao'];
    const excluir = messagesLinguage['cultivares.excluir'];
    const cultivarRemovidoComSucesso = messagesLinguage['cultivares.cultivarRemovidoComSucesso'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          cultivar,
          descricao,
          situacao,
          campoObrigatorio,
          confirmar,
          cancelar,
          ativo,
          inativo,
          desejaExcluirCultivar,
          nao,
          excluir,
          cultivarRemovidoComSucesso
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      check: true,
      statusEdit: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
  }

  componentDidMount() {
    if (this.props.selectEspecificacao.nmCultivarEspec !== undefined) {
      this.setState({
        itemSelect: this.props.selectEspecificacao,
        statusEdit: true,
        check: parseInt(this.props.selectEspecificacao.stCultivarEspec) === 1
      });
    } else {
      this.setState({
        itemSelect: {},
        statusEdit: false,
        check: true
      });
    }
  }

  onSave(values) {
    values.stCultivarEspec = this.state.check ? '1' : '0';
    values.situacao = this.state.check
      ? provider.intl.formatMessage({ id: 'ativo' })
      : provider.intl.formatMessage({ id: 'inativo' });
    values.idCultivar = this.state.itemSelect.idCultivar;

    if (this.state.statusEdit) {
      values.idCultivarEspec = this.state.itemSelect.idCultivarEspec;
      const position = this.props.data.indexOf(this.state.itemSelect);
      this.setState({
        openModal: false,
        itemSelect: '',
        disabledEdit: true,
        statusEdit: false
      });

      this.props.onClickEspecificacao(values, position);
    } else {
      this.setState({
        openModal: false,
        itemSelect: {},
        statusEdit: false
      });

      this.props.onClickEspecificacao(values);
    }
  }

  handleChangeChecked() {
    this.setState({
      check: !this.state.check
    });
  }

  onClose() {
    this.setState({
      itemSelect: {},
      statusEdit: false,
      check: true
    });

    this.props.onClickCancelar(0);
  }

  handleClose() {
    this.setState({
      openModal: false
    });
  }

  clickExcluir() {
    const position = this.props.data.indexOf(this.state.itemSelect);

    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluirCultivar' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        this.props.onClickDeleteEspecificacoes(position);

        swal(provider.intl.formatMessage({ id: 'cultivarRemovidoComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      } else {
        this.setState({
          disabledEdit: false
        });
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }}
      >
        <MuiThemeProvider theme={theme}>
          <Formik
            initialValues={
              this.state.statusEdit
                ? {
                    nmCultivarEspec: this.state.itemSelect.nmCultivarEspec,
                    stCultivarEspec: ''
                  }
                : {
                    nmCultivarEspec: '',
                    stCultivarEspec: ''
                  }
            }
            validateOnBlur
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={this.onSave}
            validationSchema={Yup.object().shape({
              nmCultivarEspec: Yup.string().required(
                <FormattedMessage id='cultivares.campoObrigatorio' />
              )
            })}
            render={({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
              <CardCadastro
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex'
                }}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                open={this.state.openModal}
                onClose={this.handleClose}
                disableAutoFocus={true}
                titulo={provider.intl.formatMessage({
                  id: 'cultivar'
                })}
                botoes={[
                  this.state.statusEdit && (
                    <Button color='primary' variant='contained' onClick={this.clickExcluir}>
                      {provider.intl.formatMessage({ id: 'excluir' })}
                    </Button>
                  ),
                  <Button variant='contained' color='inherit' onClick={this.onClose}>
                    {provider.intl.formatMessage({ id: 'cancelar' })}
                  </Button>,
                  <Button color='secondary' variant='contained' onClick={handleSubmit}>
                    {provider.intl.formatMessage({ id: 'confirmar' })}
                  </Button>
                ]}
                disableBackdropClick={true}
              >
                <div>
                  <form autoComplete='off'>
                    <Grid container spacing={16}>
                      <Grid item xs={7}>
                        <TextField
                          disabled={this.props.itemGlobal}
                          id='nmCultivarEspec'
                          name='nmCultivarEspec'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.nmCultivarEspec}
                          label={provider.intl.formatMessage({
                            id: 'descricao'
                          })}
                          className={classes.textField}
                          error={errors.nmCultivarEspec && touched.nmCultivarEspec}
                          inputProps={{ className: classes.font }}
                          InputLabelProps={{ className: classes.font }}
                          helperText={touched.nmCultivarEspec ? errors.nmCultivarEspec : ''}
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <div style={{ marginTop: '10px', marginLeft: '30px' }}>
                          <label style={{ color: '#000000' }}>
                            <FormattedMessage id='cultivares.ativo' />
                          </label>
                          <Switch
                            value='checkedAtivo'
                            name='checkedAtivo'
                            color='primary'
                            checked={this.state.check}
                            onChange={this.handleChangeChecked}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </CardCadastro>
            )}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

EditarEspecificacoes.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(EditarEspecificacoes);
