export default {
  agentesControle: {
    agentesControle: 'Agentes biológicos',
    cadastroDaAgentesControle: 'Registro de agentes biológicos',
    agentesControleRemovidoComSucesso: 'Agente biológico quitado con éxito',
    falhaAoExcluirAgentesControle: 'Fallo la excluir agente biológico',
    desejaExcluir: '¿Desea eliminar el agente biológico seleccionada?',
    identificacaoDaAgentesControle: 'Identificación',
    agentesControleCadastradoComSucesso: 'Agente biológico registrada con éxito',
    agentesControleEditadoComSucesso: 'Agente biológico editado con éxito',
    falhaAoEditarAgentesControle: 'Falha ao editar agente biológico',
    falhaAoCadastrarAgentesControle: 'Error al editar agente biológico',
    falhaAoCarregarAgentesControle: 'Error al cargar agentes biológicos'
  }
};
