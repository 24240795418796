import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DatePicker from '@components/DatePicker/DatePicker';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Card from '@components/Card/Card';
import Checkbox from '@material-ui/core/Checkbox';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = theme => ({
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    width: 'fit-content'
  },
  container: {
    overflow: 'hidden'
  },
  button: {
    height: 24,
    width: 24,
    marginTop: 10,
    marginLeft: 15
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    marginTop: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiTypography: {
      headline: {
        color: '#555555',
        fontSize: 24
      },
      h5: {
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.7)'
      }
    },
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#008000'
        }
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    default: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente para editar familiar
 *
 * @author Bruno Eduardo
 * @class EditarFamiliar
 * @extends {Component} - Componente React
 */
class EditarFamiliar extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const nome = messagesLinguage['produtores.nome'];
    const sexo = messagesLinguage['produtores.sexo'];
    const dataNascimento = messagesLinguage['produtores.dataNascimento'];
    const escolaridade = messagesLinguage['produtores.escolaridade'];
    const cancelar = messagesLinguage['produtores.cancelar'];
    const confirmar = messagesLinguage['produtores.confirmar'];
    const campoObrigatorio = messagesLinguage['produtores.campoObrigatorio'];
    const excluir = messagesLinguage['produtores.excluir'];
    const masculino = messagesLinguage['produtores.masculino'];
    const feminino = messagesLinguage['produtores.feminino'];
    const adicionarFamiliar = messagesLinguage['produtores.adicionarFamiliar'];
    const editarFamiliar = messagesLinguage['produtores.editarFamiliar'];
    const salvar = messagesLinguage['produtores.salvar'];
    const dataCadastro = messagesLinguage['produtores.dataCadastro'];
    const familiares = messagesLinguage['produtores.familiares'];
    const cadastroFamiliar = messagesLinguage['produtores.cadastroFamiliar'];
    const edicaoFamiliar = messagesLinguage['produtores.edicaoFamiliar'];
    const nomeCompleto = messagesLinguage['produtores.nomeCompleto'];
    const fundamentalIncompleto = messagesLinguage['produtores.fundamentalIncompleto'];
    const fundamentalCompleto = messagesLinguage['produtores.fundamentalCompleto'];
    const medioIncompleto = messagesLinguage['produtores.medioIncompleto'];
    const medioCompleto = messagesLinguage['produtores.medioCompleto'];
    const superiorIncompleto = messagesLinguage['produtores.superiorIncompleto'];
    const superiorCompleto = messagesLinguage['produtores.superiorCompleto'];
    const moraPropriedade = messagesLinguage['produtores.moraPropriedade'];
    const possuiRendaExtra = messagesLinguage['produtores.possuiRendaExtra'];
    const descricaoRendaExtra = messagesLinguage['produtores.descricaoRendaExtra'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          nomeCompleto,
          edicaoFamiliar,
          cadastroFamiliar,
          familiares,
          nome,
          sexo,
          dataNascimento,
          escolaridade,
          cancelar,
          confirmar,
          campoObrigatorio,
          excluir,
          masculino,
          feminino,
          adicionarFamiliar,
          editarFamiliar,
          salvar,
          dataCadastro,
          fundamentalIncompleto,
          fundamentalCompleto,
          medioIncompleto,
          medioCompleto,
          superiorIncompleto,
          superiorCompleto,
          moraPropriedade,
          possuiRendaExtra,
          descricaoRendaExtra
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      stMoraPropriedade: false,
      stRendaExterna: false,
      familiar: {
        idFamiliarProdutor: null,
        nmFamiliar: '',
        tpSexo: '',
        dtNascimento: null,
        tpEscolaridade: '',
        dsRendaExterna: ''
      }
    };

    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.setState({
      stMoraPropriedade: this.props.familiar.stMoraPropriedade === 1,
      stRendaExterna: this.props.familiar.stRendaExterna === 1
    });
  }

  /**
   * Submit do formulário
   *
   * @param {Object} values - Valores dos Inputs
   */
  onSave(values) {
    values.dtNascimento = moment(values.dtNascimento).format('YYYY-MM-DD');
    values.stRendaExterna = this.state.stRendaExterna ? 1 : 0;
    values.stMoraPropriedade = this.state.stMoraPropriedade ? 1 : 0;

    if (this.props.atualizando) {
      values.dtCadastro = this.props.familiar.dtCadastro;
      values.stRegistro = this.props.familiar.stRegistro;
      values.idFamiliarProdutor = this.props.familiar.idFamiliarProdutor;
      this.props.onUpdate(values);
    } else {
      values.stRegistro = 0;
      values.dtCadastro = moment().format('YYYY-MM-DDTHH:mm:ssZZ');
      this.props.onNew(values);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={this.props.atualizando ? this.props.familiar : this.state.familiar}
          validateOnBlur8
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={this.onSave}
          validationSchema={Yup.object().shape({
            nmFamiliar: Yup.string()
              .required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
              .test('test-name', ' ', function(value) {
                if (value && value.trim() && value.trim().split(' ').length < 2) {
                  const { path, createError } = this;
                  return createError({
                    path,
                    message: provider.intl.formatMessage({ id: 'nomeCompleto' })
                  });
                } else {
                  return true;
                }
              }),
            dsRendaExterna: this.state.stRendaExterna
              ? Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
              : Yup.string(),
            tpSexo: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
            tpEscolaridade: Yup.string().required(
              provider.intl.formatMessage({ id: 'campoObrigatorio' })
            ),
            dtNascimento: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleReset
          }) => {
            this.handleReset = handleReset;

            return (
              <Card
                withOutBorderRadius
                title={provider.intl.formatMessage({
                  id: this.props.atualizando ? 'edicaoFamiliar' : 'cadastroFamiliar'
                })}
                height='388px'
                childrenStyle={{ overflow: 'hidden' }}
              >
                <div className={classes.container}>
                  <form autoComplete='off'>
                    <Grid container spacing={16}>
                      <Grid item xs={4}>
                        <TextField
                          id='nmFamiliar'
                          name='nmFamiliar'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 100
                          }}
                          value={values.nmFamiliar}
                          label={provider.intl.formatMessage({ id: 'nome' })}
                          error={errors.nmFamiliar && touched.nmFamiliar}
                          helperText={touched.nmFamiliar ? errors.nmFamiliar : ''}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <DatePicker
                          locale={getUserLang()}
                          style={{ height: 70 }}
                          label={provider.intl.formatMessage({ id: 'dataNascimento' })}
                          maxDate={moment()}
                          valueDate={values.dtNascimento}
                          errorTouched={errors.dtNascimento && touched.dtNascimento ? true : false}
                          helperText={provider.intl.formatMessage({ id: 'campoObrigatorio' })}
                          onChangeDatePicker={date => {
                            setFieldValue('dtNascimento', date);
                          }}
                          onBlurDatePicker={e => {
                            const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                            !validation && setFieldValue('dtNascimento', null);
                            handleBlur('dtNascimento')(e);
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <FormControl error={errors.tpSexo && touched.tpSexo} style={{ width: '100%' }}>
                          <InputLabel htmlFor='tpSexo' error={errors.tpSexo && touched.tpSexo}>
                            {provider.intl.formatMessage({ id: 'sexo' })}
                          </InputLabel>

                          <Select
                            value={values.tpSexo}
                            error={errors.tpSexo && touched.tpSexo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='tpSexo'
                            id='tpSexo'
                          >
                            {[
                              provider.intl.formatMessage({ id: 'feminino' }),
                              provider.intl.formatMessage({ id: 'masculino' })
                            ].map((item, i) => (
                              <MenuItem key={i} value={i}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors.tpSexo && touched.tpSexo && (
                            <FormHelperText>{errors.tpSexo}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          error={errors.tpEscolaridade && touched.tpEscolaridade}
                          style={{ width: '100%' }}
                        >
                          <InputLabel
                            htmlFor='tpEscolaridade'
                            error={errors.tpEscolaridade && touched.tpEscolaridade}
                          >
                            {provider.intl.formatMessage({ id: 'escolaridade' })}
                          </InputLabel>

                          <Select
                            value={values.tpEscolaridade}
                            error={errors.tpEscolaridade && touched.tpEscolaridade}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='tpEscolaridade'
                            id='tpEscolaridade'
                          >
                            {[
                              provider.intl.formatMessage({ id: 'fundamentalIncompleto' }),
                              provider.intl.formatMessage({ id: 'fundamentalCompleto' }),
                              provider.intl.formatMessage({ id: 'medioIncompleto' }),
                              provider.intl.formatMessage({ id: 'medioCompleto' }),
                              provider.intl.formatMessage({ id: 'superiorIncompleto' }),
                              provider.intl.formatMessage({ id: 'superiorCompleto' })
                            ].map((item, i) => (
                              <MenuItem key={i} value={i}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors.tpEscolaridade && touched.tpEscolaridade && (
                            <FormHelperText>{errors.tpEscolaridade}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={16}>
                      <Grid item xs={3}>
                        <div
                          className={classes.switch}
                          onClick={() => {
                            this.setState({
                              stRendaExterna: !this.state.stRendaExterna
                            });

                            setFieldValue('dsRendaExterna', '');
                            setFieldTouched('dsRendaExterna', false);
                          }}
                        >
                          <Checkbox
                            checked={this.state.stRendaExterna}
                            value='checkedB'
                            color='primary'
                          />
                          <label style={{ color: '#000000', cursor: 'pointer' }}>
                            {provider.intl.formatMessage({ id: 'possuiRendaExtra' })}
                          </label>
                        </div>
                      </Grid>

                      <Grid item xs={5}>
                        <TextField
                          disabled={!this.state.stRendaExterna}
                          id='dsRendaExterna'
                          name='dsRendaExterna'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 100
                          }}
                          value={values.dsRendaExterna}
                          label={provider.intl.formatMessage({ id: 'descricaoRendaExtra' })}
                          error={errors.dsRendaExterna && touched.dsRendaExterna}
                          helperText={touched.dsRendaExterna ? errors.dsRendaExterna : ''}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <div
                          className={classes.switch}
                          onClick={() => {
                            this.setState({
                              stMoraPropriedade: !this.state.stMoraPropriedade
                            });
                          }}
                        >
                          <Checkbox
                            checked={this.state.stMoraPropriedade}
                            value='checkedB'
                            color='primary'
                          />
                          <label style={{ color: '#000000', cursor: 'pointer' }}>
                            {provider.intl.formatMessage({ id: 'moraPropriedade' })}
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  </form>

                  <Grid container>
                    <Grid item xs className={classes.containerButtons}>
                      <div style={{ color: '#463766' }}>
                        <Button variant='contained' color='inherit' onClick={this.props.onCancel}>
                          {provider.intl.formatMessage({ id: 'cancelar' })}
                        </Button>
                      </div>

                      <div>
                        <Button color='secondary' variant='contained' onClick={handleSubmit}>
                          {provider.intl.formatMessage({ id: 'salvar' })}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            );
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(EditarFamiliar);
