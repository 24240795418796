export default {
  calendar: {
    hoje: 'Hoy',
    mes: 'Mes',
    semana: 'Semana',
    dia: 'Día',
    mais: 'más',
    agenda: 'orden del día',
    diaTodo: 'Todo el día',
    data: 'Fecha',
    hora: 'Tiempo',
    evento: 'Evento',
    noEvents: 'No hay eventos en estas fechas',
    formatDate: 'ddd D/M',
    formatTime: 'H:mm',
    formatDatePopUp: 'dddd, D - MMMM',
    adicionar: 'Añadir',
    list: 'Lista',
    formatMonth: 'MMMM [de] YYYY',
    adicionarEvento: 'Agregar evento',
    adicionarVisita: 'Añadir visita'
  }
};
