export default {
  agendaVisitas: {
    agendaVisitas: 'Visita de seguimiento',
    visitado: 'Visitado',
    situacao: 'Situación ',
    aplicProdutoProibido: 'Aplicar producto prohibido',
    monitoramentoPragas: 'Monitoreo de plagas',
    atividadeAtribuida: 'Actividad atribuida a la visita',
    agendado: 'Programado',
    aplicProduto: 'Aplicar producto',
    recomendacaoAplic: 'Recomendación de aplicación',
    diasSemVisita: '15 días sin Visita',
    registroClimatico: 'Récord climático',
    alertaFitossanitario: 'Alerta Fitosanitaria',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    tecnico: 'Técnico',
    registroFotografico: 'Registro fotográfico',
    tecnicoResponsavel: 'Técnico Responsable',
    produtor: 'Productor',
    qtVisitas: 'Cuánto de Visitas',
    data: 'Fecha',
    semAtividade: 'Ninguna actividad',
    itemSemCoordenadas: 'Hay registros sin coordenadas (Latitud / Longitud)'
  }
};
