export default {
  cultura: {
    existeUm: 'Existe um ',
    existeUma: 'Existe uma ',
    relacionadaComCultura: ' relacionada com a cultura',
    relacionadoComCultura: ' relacionado com a cultura',
    detentor: 'Detentor',
    plantio: 'plantio',
    fitossanidade: 'fitossanidade',
    cotacao: 'cotação',
    safra: 'safra',
    cadastro: 'cadastro'
  }
};
