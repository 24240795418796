export default {
  nematicidas: {
    nematicidas: 'Nematicidas',
    cadastroDeNematicidas: 'Registro nematicidas',
    nematicidasRemovidoComSucesso: 'Nematicida quitado con éxito',
    falhaAoExcluirnematicidas: 'Fallo la excluir nematicida',
    desejaExcluir: '¿Desea eliminar el nematicida seleccionada?',
    identificacaoDaNematicidas: 'Identificación de nematicidas',
    nematicidasadastradoComSucesso: 'Nematicida registrada con éxito',
    nematicidasEditadoComSucesso: 'Nematicida editado con éxito',
    falhaAoEditarNematicidas: 'Falha ao editar nematicida',
    falhaAoCadastrarNematicidas: 'Error al editar nematicida',
    falhaAoCarregarNematicidas: 'Error al cargar nematicida'
  }
};
