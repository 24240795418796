import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import formatMessage from './i18n/formatMessage';
import { style, theme } from './Classificacao.styles';
import { findClassificacaoById, saveClassificacao, updateClassificacao } from '@resources/Classificacao';
import swal from '@sweetalert/with-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '@components/Loading/Loading';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Tela de cadastrar e editar classificação
 *
 * @author Gabriela Farias
 * @class EditarClassificacao
 * @extends {Component} - Componente React
 */
function EditarClassificacao(props) {
  const [loading, setLoading] = useState(props.match.params.id !== undefined);
  const [initialValues, setInitialValues] = useState({});
  const [stClassificacao, setStClassificacao] = useState(true);
  const [stPreservacaoAmbiental, setStPreservacaoAmbiental] = useState(false);
  const [stProdutivo, setStProdutivo] = useState(false);

  const { classes } = props;

  useEffect(() => {
    const title = 'classificacao.cadastroClassificacao';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    if (props.match.params.id !== undefined) {
      findClassificacaoById(props.match.params.id)
        .then(doc => {
          setStClassificacao(doc.data.stClassificacao === 1);
          setStPreservacaoAmbiental(doc.data.stPreservacaoAmbiental === 1);
          setStProdutivo(doc.data.stProdutivo === 1);
          setInitialValues(doc.data);
        })
        .catch(() => {
          errorMensagem('classificacao.erroBuscarClassificacao');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  function errorMensagem(title) {
    swal(formatMessage(title), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  function clickCadastrarEditar(values) {
    const updateOperation = props.match.params.id !== undefined;

    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(!updateOperation ? 'classificacao.salvando' : 'classificacao.atualizando')
      }
    );

    values.stClassificacao = stClassificacao ? 1 : 0;
    values.stPreservacaoAmbiental = stPreservacaoAmbiental ? 1 : 0;
    values.stProdutivo = stProdutivo ? 1 : 0;

    let updateOrSave = null;

    if (updateOperation) {
      values.idClassificacao = props.match.params.id;
      updateOrSave = updateClassificacao(values);
    } else {
      updateOrSave = saveClassificacao(values);
    }

    updateOrSave
      .then(() => {
        swal(
          formatMessage(
            updateOperation
              ? 'classificacao.classificacaoEditadaComSucesso'
              : 'classificacao.classificacaoSalvaComSucesso'
          ),
          {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }
        ).then(() => {
          props.history.push('/app/classificacao');
        });
      })
      .catch(() => {
        errorMensagem(
          updateOperation
            ? 'classificacao.erroEditarClassificacao'
            : 'classificacao.erroSalvarClassificacao'
        );
      });
  }

  function clickCancelar() {
    props.history.push('/app/classificacao');
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Formik
        initialValues={{ dsClassificacao: initialValues.dsClassificacao || '' }}
        onSubmit={clickCadastrarEditar}
        validateOnBlur
        enableReinitialize={true}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          dsClassificacao: Yup.string().required(formatMessage('classificacao.campoObrigatorio'))
        })}
        render={({ values, handleSubmit, errors, touched, handleBlur, setFieldValue, handleChange }) => (
          <form className={classes.card} autoComplete='off'>
            {loading && <Loading />}
            <CardIcon titulo={formatMessage('classificacao.identificacao')}>
              <div className={classes.cardInside}>
                <Grid container spacing={16}>
                  <Grid item xs={6}>
                    <TextField
                      label={formatMessage('classificacao.descricao')}
                      name='dsClassificacao'
                      value={values.dsClassificacao}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.dsClassificacao && touched.dsClassificacao}
                      helperText={
                        errors.dsClassificacao && touched.dsClassificacao ? errors.dsClassificacao : null
                      }
                      style={{ width: '100%', height: 70 }}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <div className={classes.switch} onClick={() => setStClassificacao(!stClassificacao)}>
                      <Checkbox checked={stClassificacao} value='stClassificacao' color='primary' />
                      <label className={classes.label}>{formatMessage('classificacao.ativo')}</label>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={16}>
                <Grid item xs={2}>
                    <div
                      className={classes.switch}
                      onClick={() => {
                        if (stPreservacaoAmbiental) {
                          setStPreservacaoAmbiental(false);
                        }
                        setStProdutivo(!stProdutivo);
                      }}
                    >
                      <Checkbox checked={stProdutivo} value='stProdutivo' color='primary' />
                      <label className={classes.label}>{formatMessage('classificacao.produtivo')}</label>
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div
                      className={classes.switch}
                      onClick={() => {
                        if (stProdutivo) {
                          setStProdutivo(false);
                        }
                        setStPreservacaoAmbiental(!stPreservacaoAmbiental);
                      }}
                    >
                      <Checkbox
                        checked={stPreservacaoAmbiental}
                        value='stPreservacaoAmbiental'
                        color='primary'
                      />
                      <label className={classes.label}>
                        {formatMessage('classificacao.preservacaoAmbiental')}
                      </label>
                    </div>
                  </Grid>
                  
                </Grid>
              </div>
            </CardIcon>
            <div className={classes.fab}>
              <ButtonFABMenu
                icon={iconMenuPontos}
                actions={[
                  {
                    icon: <Check style={{ color: '#FFFFFF' }} />,
                    name: formatMessage('classificacao.salvar'),
                    onClickIcon: handleSubmit,
                    color: '#42ADE8'
                  },
                  {
                    icon: <Close style={{ color: '#FFFFFF' }} />,
                    name: formatMessage('classificacao.cancelar'),
                    onClickIcon: clickCancelar,
                    color: '#F33A30'
                  }
                ]}
              />
            </div>
          </form>
        )}
      />
    </MuiThemeProvider>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'classificacao';
  })
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarClassificacao)));
