import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InsumoRecord from '@components/InsumoRecord/InsumoRecord';
import formatMessage from './i18n/formatMessage';
import Insumo from '@resources/InsumosEnum';

/**
 * Componente para tela de agentes biologicos de controle
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function AgentesBiologicos(props) {
  useEffect(() => {
    const title = 'agentesControle.agentesControle';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }
  }, []);

  return (
    <InsumoRecord
      chipData={props.chipData}
      infoUsuario={props.infoUsuario}
      insumoEnum={Insumo.AGENTE_BIOLOGICO_CONTROLE}
      insumoURL='agentes-biologicos-controle'
      viewKey='agentesBiologicosControle'
      deleteMessageSucess={formatMessage(
        'agentesControle.agentesControleRemovidoComSucesso'
      )}
      mensagemFalhaDeletar={formatMessage('agentesControle.falhaAoExcluirAgentesControle')}
      mensagemDelete={formatMessage('agentesControle.desejaExcluir')}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'agentesBiologicosControle';
  })[0].content
});

export default connect(mapStateToProps)(AgentesBiologicos);