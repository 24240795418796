export default {
  menu: {
    ecosocial: 'Ecosocial',
    certificacoes: 'Certifications',
    sazonalidades: 'Seasonality',
    animal: 'Animal',
    plantios: 'Plantings',
    municipio: 'City',
    comunidades: 'Community',
    nivel: 'Technological level',
    tecnicos: 'Technical',
    tecnico: 'Technical',
    produtores: 'Producers',
    nematicidasMicrobiologicos: 'Microbiological nematicides',
    foliares: 'Foliar',
    comparacao: 'Comparison between technicians',
    acompanhamento: 'monitoring',
    culturas: 'Cultures',
    cultivares: 'Cultivars',
    fungicidas: 'Fungicides',
    fungicidasMicroBio: 'Microbiological fungicides',
    inseticidas: 'Insecticides',
    inseticidasMicroBio: 'Microbiological insecticides',
    cotacoes: 'Quotation',
    bactericidasMicrobiologicos: 'Microbiological bactericides',
    nematicidas: 'Nematicides',
    agentesBiologicosControle: 'Biological agents',
    clientes: 'Customers',
    herbicidas: 'Herbicides',
    acaricidas: 'Acaricides',
    bactericidas: 'Bactericidal',
    acaricidasMicrobio: 'Microbiological acaricides',
    marcas: 'Brands',
    cadastro: 'Register',
    fertilizantes: 'Leaf and Adjuvant',
    analise: 'Analyzes',
    pessoas: 'People',
    localizacao: 'Location',
    produtos: 'Products',
    acaros: 'Mites',
    bacterias: 'Bacteria',
    ervasDaninhas: 'Weeds',
    fungos: 'Fungi',
    insetos: 'Insects',
    nematoides: 'Nematodes',
    agenda: 'Schedule',
    admin: 'Administration',
    pesticidas: 'Pesticides',
    acompanhamentoProdutor: 'Follow-up to the producer',
    produtor: 'Producer',
    financeiro: 'Financial',
    revenda: 'Manager',
    oleosAdjuvantes: 'Oils and Adjuvants',
    agendaVisitas: 'Visits Schedule',
    fitossanidade: 'Plant health',
    fertilizantesFoliares: 'Fertilizers',
    quimico: 'Chemical',
    organico: 'Organic',
    mapa: 'Maps',
    mapandvi: 'Maps NDVI',
    especies: 'Species',
    racas: 'Breeds',
    naoConformidade: 'Non-compliance',
    regiao: 'Region',
    relatorio: 'Reports',
    produtorPotencial: 'Potential producers',
    relacaoTalhao: 'Plot ratio',
    analisesRiscos: 'Risk analysis',
    analitico: 'Analytical',
    sintetico: 'Synthetic',
    cadastroDeProdutor: 'Producer Registration',
    listaProdutores: 'List of producers',
    resumoVisitas: ' by producer',
    visitas: 'Visits',
    safras: 'Vintages',
    produtividade: 'Productivity',
    graosProdutor: 'Grains by producer',
    inoculantesHomeopatia: 'Inoculants and Homeopathy',
    homeopaticos: 'Homeopathic Products',
    inoculantes: 'Inoculants',
    historicoProdutividade: 'Productivity history',
    galeria: 'Gallery',
    classificacao: 'Land use and occupation',
    certificacaoOrganica: 'Organic certifications',
    agencia: 'Office',
    sancaoCategoria: 'Sanction',
    medidaCorretiva: 'Corrective measure',
    producao: 'Production',
    adversidades: 'Adversities',
    cooperativismo: 'Cooperativism',
    auditoria: 'Audit',
    acompanhamentoVisitas: 'Visit follow-up',
    analiseOGM: 'Analyze GMO',
    formaAplicacao: 'Application form'
  }
};
