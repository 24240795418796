//@author Katia Miglioli
// positionDefault = {true/false} - atributo que aplica ou nao posicao comum( canto inferior direito ) ao botao do FAB
import React from 'react';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import '../../App.css';

const styles = {
  fab: {
    position: 'fixed',
    right: '30px',
    bottom: '30px',
    zIndex: '2000'
  }
};
function ButtonFAB(props) {
  const { classes } = props;
  return (
    <div>
      <Fab
        disabled={props.disableFAB}
        style={{ backgroundColor: props.disableFAB ? '#95989A' : '#00622B' }}
        className={props.positionDefault ? classes.fab : ''}
        onClick={props.onClick}
      >
        <img src={props.icon} alt=' ' />
      </Fab>
    </div>
  );
}

export default withStyles(styles)(ButtonFAB);
