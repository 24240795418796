import URL from './URL';

const axios = require('axios');
const api = URL.API + '/auditoria-plantio';

function findAllAuditoria() {
  return axios.get(api);
}

function findAuditoriaById(idAuditoria) {
  return axios.get(api + '/' + idAuditoria);
}

function deleteAuditoriaById(idAuditoria) {
  return axios.delete(api + '/' + idAuditoria);
}

function addAuditoria(values) {
  return axios.post(api, values);
}

function editAuditoria(values) {
  return axios.put(api, values);
}

export { findAllAuditoria, findAuditoriaById, deleteAuditoriaById, addAuditoria, editAuditoria };
