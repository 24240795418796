export default {
  sancaoCategoria: {
    descricao: 'Descrição',
    dataCadastro: 'Data de cadastro',
    erroAoBuscarDados: 'Erro ao buscar dados',
    sancaoCategoria: 'Sanção',
    desejaExcluirSancaoCategoria: 'Deseja excluir esta sanção',
    nao: 'Não',
    excluir: 'Excluir',
    sancaoCategoriaExcluida: 'Sanção excluída com sucesso',
    erroAoExcluir: 'Ocorreu um erro ao excluir a sanção',
    cadastroDeSancaoCategoria: 'Cadastro de sanção',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    sancaoCategoriaSalvoComSucesso: 'Sanção salva com sucesso',
    erroAoSalvar: 'Erro ao salvar sanção',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    produtorRelacionado: 'Não foi possível excluír esta sanção pois existe um produtor usando ela',
    status: 'Situação',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nome',
    diasResolucao: 'Dias para resolução',
    sigla: 'Sigla',
    tipoPenalidade: 'Tipo de penalidade',
    nenhum: 'Nenhum',
    desclassificacaoSafra: 'Desclassificação da safra',
    desclassificacaoTalhao: 'Desclassificação do talhão'
  }
};
