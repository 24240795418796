/* 
    Autor: Gabriela Farias
*/
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { flattenMessages } from "@utils/languageUtils";
import { IntlProvider } from "react-intl";
import ptBR from "./i18n/ptBR";
import enUS from "./i18n/enUS";
import esES from "./i18n/esES";
import { getUserLang } from "@utils/localeUtils";

import logo from "@images/logo_azagros.png";

let messages = { "pt-BR": ptBR, "en-US": enUS, "es-ES": esES };

const styles = {
  div: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    zIndex: 1,
    marginBottom: 0,
    flexDirection: "column"
  },
  image: {
    width: 150,
    marginRight: 5
  },
  label: {
    color: "#26B767"
  }
};

/**
 * Tela com mensagem de Carregando custimizada
 */
class Carregando extends Component {
  render() {
    const { classes } = this.props;

    return (
      <IntlProvider
        locale={getUserLang()}
        messages={flattenMessages(messages[getUserLang()])}
      >
        <div className={classes.div}>
          <img src={logo} className={classes.image} alt="municipios" />
          <p className={classes.label}>
            {" "}
            <FormattedMessage id={"layout.carregando"} />{" "}
          </p>
        </div>
      </IntlProvider>
    );
  }
}

export default withRouter(withStyles(styles)(Carregando));
