export default {
  cultura: {
    culturas: 'Tilth',
    descricao: 'Description',
    numeroDeAplicacoes: 'Number of applications',
    nao: 'No',
    sim: 'Yes',
    culturaRemovidaComSucesso: 'Tilth successfully removed',
    falhaAoExcluirCultura: 'Failed to exclude tilth',
    desejaExcluir: 'Do you want to delete the selected tilth?',
    excluir: 'Delete',
    excluindo: 'Deleting',
    cultura: 'Tilth',
    identificacaoDaCultura: 'identification',
    salvar: 'Save',
    cancelar: 'Cancel',
    salvando: 'Saving',
    atualizando: 'Updating',
    culturaCadastradaComSucesso: 'Tilth successfully registered',
    culturaEditadaComSucesso: 'Tilth edited successfully',
    falhaAoCadastrarCultura: 'Failed to register tilth',
    falhaAoEditarCultura: 'Failed to edit tilth',
    falhaAoCarregarCultura: 'Failed to load tilth',
    campoObrigatorio: 'Required field',
    cadastroDeCultura: 'Tilth registration',
    safraRelacionada: 'There is a crop related to the culture'
  }
};
