import React, { useState, useEffect } from 'react';
import { FormControlLabel, Radio, Grid } from '@material-ui/core';

function RadioGroupComponent(props) {
  const [value, setValue] = useState();

  useEffect(() => {
    props.itensRadio.forEach(item => {
      if (item.check) {
        setValue(item.value);
      }
    });
  }, []);

  const handleChange = event => {
    props.itensRadio.forEach(item => {
      if (item.value === event.target.value && value !== event.target.value) {
        item.check = true;
      } else {
        item.check = false;
      }
    });

    if (value === event.target.value) {
      setValue();
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <Grid container spacing={16}>
      {props.itensRadio.map(doc => (
        <Grid key={doc.value} item xs={props.grid} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <FormControlLabel
            disabled={doc.disabled}
            checked={value === doc.value}
            key={doc.value}
            name='radio'
            value={doc.value}
            control={<Radio onClick={handleChange} required={false} />}
            label={doc.label}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default RadioGroupComponent;
