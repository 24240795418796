import formatMessage from './i18n/formatMessage';

const headDatatable = [
  {
    field: 'nmTipoCertificacao',
    headerName: formatMessage('certificacao.descricao'),
    col: 10,
    sort: 'asc'
  },
  {
    valueGetter: args =>
      args.node.data.stTipoCertificacao === 1
        ? formatMessage('certificacao.ativo')
        : formatMessage('certificacao.inativo'),
    headerName: formatMessage('certificacao.status'),
    col: 2
  }
];

const filtersConfig = [
  {
    label: formatMessage('certificacao.descricao'),
    field: 'nmTipoCertificacao',
    type: 'string'
  },
  {
    label: formatMessage('certificacao.status'),
    field: 'stTipoCertificacao',
    type: 'enum',
    options: [
      {
        label: formatMessage('certificacao.ativo'),
        field: 'Ativo',
        value: '1'
      },
      {
        label: formatMessage('certificacao.inativo'),
        field: 'Inativo',
        value: '0'
      }
    ]
  }
];

export { headDatatable, filtersConfig };
