import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import CardIcon from '@components/CardIcon/CardIcon';
import Loading from '@components/Loading/Loading';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import DoneAll from '@material-ui/icons/DoneAll';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import Comunidade from '@resources/Comunidade';
import Localizacao from '@resources/Localizacao';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Produtor from '@resources/Produtores';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import CPFValidator from '@validators/CPFValidator';
import CNPJValidator from '../../../validators/CNPJValidator';
import Usuarios from '@resources/Usuario';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});

const style = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 34.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  cadastro: {
    width: '100%',
    marginTop: '15px'
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  },
  font: {
    fontSize: 14,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.8)'
      }
    },
    MuiInput: {
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        writeSpace: 'noWrap',
        textOverflow: 'ellipsis',
        fontStyle: 'normal !important'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.4
        }
      }
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        fontStyle: 'normal !important'
      }
    },
    MuiInputLabel: {
      animated: {
        fontSize: 14,
        fontStyle: 'normal !important'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0
      }
    },
    MuiSwitch: {
      root: {
        marginLeft: -13
      }
    }
  }
});

class EditarPreCadastro extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const nome = messagesLinguage['produtores.nome'];
    const telefone = messagesLinguage['produtores.telefone'];
    const email = messagesLinguage['produtores.email'];
    const cpf = messagesLinguage['produtores.cpf'];
    const estado = messagesLinguage['produtores.estado'];
    const municipio = messagesLinguage['produtores.municipio'];
    const comunidade = messagesLinguage['produtores.comunidade'];
    const campoObrigatorio = messagesLinguage['produtores.campoObrigatorio'];
    const salvar = messagesLinguage['produtores.salvar'];
    const cancelar = messagesLinguage['produtores.cancelar'];
    const conhecimentoOrganica = messagesLinguage['produtores.conhecimentoOrganica'];
    const atividadeGraos = messagesLinguage['produtores.atividadeGraos'];
    const areaProdutiva = messagesLinguage['produtores.areaProdutiva'];
    const falhaAoEditarProdutor = messagesLinguage['produtores.falhaAoEditarProdutor'];
    const produtorEditadoComSucesso = messagesLinguage['produtores.produtorEditadoComSucesso'];
    const produtorCadastradoComSucesso = messagesLinguage['produtores.produtorCadastradoComSucesso'];
    const salvando = messagesLinguage['produtores.salvando'];
    const atualizando = messagesLinguage['produtores.atualizando'];
    const falhaAoCadastrarProdutor = messagesLinguage['produtores.falhaAoCadastrarProdutor'];
    const cpfInvalido = messagesLinguage['produtores.cpfInvalido'];
    const cnpjInvalido = messagesLinguage['produtores.cnpjInvalido'];
    const efetivarProdutor = messagesLinguage['produtores.efetivarProdutor'];
    const erroAoCarregarDados = messagesLinguage['produtores.erroAoCarregarDados'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          nome,
          telefone,
          email,
          cpf,
          estado,
          municipio,
          comunidade,
          campoObrigatorio,
          salvar,
          cancelar,
          conhecimentoOrganica,
          atividadeGraos,
          areaProdutiva,
          falhaAoEditarProdutor,
          produtorEditadoComSucesso,
          salvando,
          atualizando,
          produtorCadastradoComSucesso,
          falhaAoCadastrarProdutor,
          cpfInvalido,
          efetivarProdutor,
          erroAoCarregarDados,
          cnpjInvalido
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();
    this.valueAutoCompleteEstado = '';
    this.valueAutoCompleteMunicipio = '';
    this.valueAutoCompleteComunidade = '';
    this.valueAutoCompleteTecnico = '';

    this.state = {
      keyTecnico: '',
      tecnicosList: [],
      error: false,
      loading: true,
      municipios: [],
      estados: [],
      itensComunidades: [],
      keyEstado: Math.random(),
      keyMunicipio: Math.random(),
      keyComunidade: Math.random(),
      conhecimentoOrganica: false,
      atividadeGraos: false,
      dadosUsuario: {
        nmProdutor: '',
        nrTelefone: '',
        dsEmail: '',
        nrDocumento: '',
        estado: '',
        municipio: '',
        comunidade: '',
        nrAreaProdutivaGrao: ''
      }
    };

    const title = 'produtores.cadastroPreProdutor';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.retornaCancelarLinguagem = this.retornaCancelarLinguagem.bind(this);
    this.retornaSalvarLinguagem = this.retornaSalvarLinguagem.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.validarInicializacaoAutoCompleteEstado = this.validarInicializacaoAutoCompleteEstado.bind(this);
    this.validarInicializacaoAutoCompleteMunicipio = this.validarInicializacaoAutoCompleteMunicipio.bind(
      this
    );
    this.tratarValorComunidade = this.tratarValorComunidade.bind(this);
    this.tratarValorSelectInitialEstado = this.tratarValorSelectInitialEstado.bind(this);
    this.onChangeOwnMunicipio = this.onChangeOwnMunicipio.bind(this);
    this.tratarValorSelectInitialComunidade = this.tratarValorSelectInitialComunidade.bind(this);
    this.tratarValorSelectInitialMunicipio = this.tratarValorSelectInitialMunicipio.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
    this.handleChangeCheckedAtividade = this.handleChangeCheckedAtividade.bind(this);
    this.clickSalvar = this.clickSalvar.bind(this);
    this.retornaEfetivarProdutor = this.retornaEfetivarProdutor.bind(this);
    this.clickEfetivar = this.clickEfetivar.bind(this);
    this.validarInicializacaoAutoComplete = this.validarInicializacaoAutoComplete.bind(this);
    this.onChangeOwn = this.onChangeOwn.bind(this);
  }

  componentDidMount() {
    this.validarInicializacaoAutoComplete();

    this.validarInicializacaoAutoCompleteEstado();

    if (this.props.match.params.id !== undefined) {
      Produtor.findProdutorById(this.props.match.params.id)
        .then(doc => {
          this.valueAutoCompleteEstado = doc.data.municipio.uf;
          this.valueAutoCompleteMunicipio = doc.data.municipio;
          this.valueAutoCompleteComunidade = doc.data.comunidade;
          this.valueAutoCompleteTecnico = doc.data.tecnico;

          this.setState({
            keyComunidade: Math.random(),
            keyEstado: Math.random(),
            keyMunicipio: Math.random(),
            keyTecnico: Math.random(),
            dadosUsuario: doc.data,
            atividadeGraos: doc.data.tpAtividadeGrao === 1,
            conhecimentoOrganica: doc.data.tpProducaoOrganica === 1,
            loading: false
          });

          this.validarInicializacaoAutoCompleteMunicipio(doc.data.municipio.uf.idUf);
          this.tratarValorComunidade(doc.data.municipio);
        })
        .catch(() => {
          swal({
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
            icon: 'error',
            title: provider.intl.formatMessage({
              id: 'erroAoCarregarDados'
            })
          });

          this.setState({
            loading: false,
            error: true
          });
        });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  /**
   * Insere o técnico selecionado no objeto de produtor
   * @param dsTecnico - técnico selecionado
   */
  onChangeOwn(dsTecnico) {
    const aux = this.state.dadosUsuario;
    aux['tecnico'] = dsTecnico;

    this.valueAutoCompleteTecnico = dsTecnico ? dsTecnico : '';
    this.setState({ dadosUsuario: aux, keyTecnico: Math.random() });
  }

  /**
   * Busca os valores iniciais do auto complete
   */
  validarInicializacaoAutoComplete() {
    Usuarios.findAllAtivos()
      .then(query => {
        let data = [];

        query.data.forEach(doc => {
          if (doc.tpPerfil === 2) {
            data.push(doc);
          }
        });

        this.setState({ tecnicosList: data });
      })
      .catch(err => {
        this.setState({ tecnicosList: [] });
      });
  }

  clickEfetivar() {
    if (this.props.infoUsuario.stOrganica === 1) {
      this.props.history.push(
        '/app/produtores/editar/' + this.props.match.params.id + '/pre-cadastro/1'
      );
    } else {
      this.props.history.push(
        '/app/produtores/editar/' + this.props.match.params.id + '/pre-cadastro/1'
      );
    }
  }

  retornaEfetivarProdutor() {
    return provider.intl.formatMessage({ id: 'efetivarProdutor' });
  }

  retornaSalvarLinguagem() {
    return provider.intl.formatMessage({ id: 'salvar' });
  }

  retornaCancelarLinguagem() {
    return provider.intl.formatMessage({ id: 'cancelar' });
  }

  clickCancelar() {
    if (this.props.infoUsuario.stOrganica === 1) {
      this.props.history.push('/app/produtores');
    } else {
      this.props.history.push('/app/produtores');
    }
  }

  validarInicializacaoAutoCompleteMunicipio(idUf) {
    if (idUf !== undefined) {
      Localizacao.findMunicipiosByUf(idUf)
        .then(query => {
          this.setState({
            municipios: query.data
          });
        })
        .catch(() => {
          this.setState({
            municipios: []
          });
        });
    }
  }

  validarInicializacaoAutoCompleteEstado() {
    Localizacao.findAllEstados()
      .then(query => {
        this.setState({
          estados: query.data
        });
      })
      .catch(() => {
        this.setState({
          estados: []
        });
      });
  }

  tratarValorComunidade(dado) {
    if (dado !== undefined) {
      Comunidade.findComunidadeByMunicipio(dado.idMunicipio)
        .then(query => {
          const data = [];

          query.data.forEach(doc => {
            if (doc.municipio.idMunicipio === dado.idMunicipio) {
              data.push(doc);
            }
          });

          this.setState({
            itensComunidades: data
          });
        })
        .catch(err => {
          this.setState({
            itensComunidades: []
          });
        });
    }
  }

  tratarValorSelectInitialEstado(data) {
    if (!data || data.nmUf === undefined) {
      return '';
    }
    return data;
  }

  onChangeOwnMunicipio(nmMunicipio) {
    if (nmMunicipio) {
      this.valueAutoCompleteComunidade = '';
      this.valueAutoCompleteMunicipio = nmMunicipio;

      this.tratarValorComunidade(nmMunicipio);
    } else {
      this.valueAutoCompleteMunicipio = '';
    }

    this.setState({
      keyMunicipio: Math.random()
    });
  }

  tratarValorSelectInitialComunidade(data) {
    if (!data || data.nmComunidade === undefined) {
      return '';
    }
    return data;
  }

  tratarValorSelectInitialMunicipio(data) {
    if (!data || (data.nmMunicipio === undefined && data.uf === undefined)) {
      return '';
    }
    return data;
  }

  handleChangeChecked() {
    this.setState({
      conhecimentoOrganica: !this.state.conhecimentoOrganica
    });
  }

  handleChangeCheckedAtividade() {
    this.setState({
      atividadeGraos: !this.state.atividadeGraos
    });
  }

  clickSalvar(values) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: true,
        title: provider.intl.formatMessage({
          id: this.props.match.params.id === undefined ? 'salvando' : 'atualizando'
        })
      }
    );

    values.tpAtividadeGrao = this.state.atividadeGraos ? 1 : 0;
    values.tpProducaoOrganica = this.state.conhecimentoOrganica ? 1 : 0;
    values.tpCadastro = 1;
    values.municipio = this.valueAutoCompleteMunicipio;
    values.comunidade =
      this.valueAutoCompleteComunidade !== '' ? this.valueAutoCompleteComunidade : null;
    values.estado = this.valueAutoCompleteEstado;
    values.stProdutor = 1;
    values.tpTecnologico = 0;
    values.stProducaoParalela = 0;
    values.tpEcosocial = 0;

    if (!values.dsEmail) {
      values.dsEmail = null;
    }

    if (!this.state.atividadeGraos) {
      values.nrAreaProdutivaGrao = 0;
    } else {
      values.nrAreaProdutivaGrao = parseFloat(values.nrAreaProdutivaGrao.replace(',', '.'));
    }

    if (this.props.infoUsuario.tpPerfil === 2) {
      values.tecnico = this.state.tecnicosList.find(tecnico => {
        return this.props.infoUsuario.dsEmail === tecnico.dsEmail;
      });
    }

    if (this.props.match.params.id === undefined) {
      Produtor.addPreCadastro(values)
        .then(doc => {
          swal(provider.intl.formatMessage({ id: 'produtorCadastradoComSucesso' }), {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }).then(() => {
            this.props.history.push('/app/produtores');
          });
        })
        .catch(err => {
          swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarProdutor' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    } else {
      values.idProdutor = this.props.match.params.id;
      Produtor.updatePreCadastro(values)
        .then(doc => {
          swal(provider.intl.formatMessage({ id: 'produtorEditadoComSucesso' }), {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }).then(() => {
            this.props.history.push('/app/produtores');
          });
        })
        .catch(err => {
          swal(provider.intl.formatMessage({ id: 'falhaAoEditarProdutor' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {this.state.loading && <Loading />}
          <CardIcon
            styleCard={{ height: 315 }}
            withoutMarginTop={true}
            titulo={<FormattedMessage id='produtores.identificacao' />}
            usePaddings={false}
          >
            <Formik
              initialValues={{
                nmProdutor: this.state.dadosUsuario.nmProdutor,
                nrTelefone: this.state.dadosUsuario.nrTelefone,
                dsEmail: this.state.dadosUsuario.dsEmail || '',
                nrDocumento: this.state.dadosUsuario.nrDocumento,
                estado: this.valueAutoCompleteEstado,
                municipio: this.valueAutoCompleteMunicipio,
                comunidade: this.valueAutoCompleteComunidade,
                nrAreaProdutivaGrao: this.state.atividadeGraos
                  ? this.state.dadosUsuario.nrAreaProdutivaGrao.toString()
                  : '',
                tecnico: this.state.dadosUsuario.tecnico
              }}
              validateOnBlur
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={this.clickSalvar}
              validationSchema={Yup.object().shape({
                tecnico:
                  parseInt(this.props.infoUsuario.tpPerfil) === 1
                    ? Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
                    : Yup.string(),
                nmProdutor: Yup.string().required(
                  provider.intl.formatMessage({ id: 'campoObrigatorio' })
                ),
                nrTelefone: Yup.string().required(
                  provider.intl.formatMessage({ id: 'campoObrigatorio' })
                ),
                dsEmail: Yup.string(),
                nrDocumento:
                  getUserLang() === 'pt-BR'
                    ? Yup.string().test(
                        '',
                        provider.intl.formatMessage({
                          id:
                            this.state.dadosUsuario.nrDocumento.length <= 14
                              ? 'cpfInvalido'
                              : 'cnpjInvalido'
                        }),
                        function(value) {
                          if (value !== undefined) {
                            if (value.length <= 14) {
                              return CPFValidator.validate(value);
                            } else {
                              return CNPJValidator.validate(value);
                            }
                          }

                          return true;
                        }
                      )
                    : Yup.string(),
                estado: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
                municipio: Yup.string().required(
                  provider.intl.formatMessage({ id: 'campoObrigatorio' })
                ),
                nrAreaProdutivaGrao: this.state.atividadeGraos
                  ? Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
                  : Yup.string()
              })}
              render={({
                values,
                handleSubmit,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                submitForm
              }) => (
                <MuiThemeProvider theme={theme}>
                  <form autoComplete='off'>
                    <div className={classes.cadastro}>
                      <Grid container spacing={16}>
                        <Grid item xs={4}>
                          <TextField
                            id='nmProdutor'
                            name='nmProdutor'
                            value={values.nmProdutor}
                            label={provider.intl.formatMessage({ id: 'nome' })}
                            className={classes.textField}
                            InputLabelProps={{
                              className: classes.font
                            }}
                            error={errors.nmProdutor && touched.nmProdutor}
                            helperText={touched.nmProdutor ? errors.nmProdutor : ''}
                            onBlur={event => {
                              var data = this.state.dadosUsuario;

                              if (event) {
                                setFieldValue('nmProdutor', event.target.value);
                                data.nmProdutor = event.target.value;
                              } else {
                                setFieldValue('nmProdutor', '');
                                data.nmProdutor = '';
                              }
                            }}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextFieldWithMask
                            tipoMascara={6}
                            id='nrDocumento'
                            name='nrDocumento'
                            value={values.nrDocumento}
                            label={
                              getUserLang() === 'pt-BR'
                                ? 'CPF/CNPJ'
                                : provider.intl.formatMessage({
                                    id: 'cpf'
                                  })
                            }
                            className={classes.textField}
                            InputLabelProps={{
                              className: classes.font
                            }}
                            error={errors.nrDocumento && touched.nrDocumento}
                            helperText={touched.nrDocumento ? errors.nrDocumento : ''}
                            onBlur={handleBlur}
                            onChange={event => {
                              var data = this.state.dadosUsuario;

                              if (event) {
                                setFieldValue('nrDocumento', event.target.value);
                                data.nrDocumento = event.target.value;
                              } else {
                                setFieldValue('nrDocumento', '');
                                data.nrDocumento = '';
                              }

                              this.setState({
                                dadosUsuario: data
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Autocomplete
                            itens={this.state.estados}
                            campoOp={'nmUf'}
                            campoInput={'nmUf'}
                            campoChave={'idUf'}
                            label={provider.intl.formatMessage({
                              id: 'estado'
                            })}
                            name='estado'
                            error={errors.estado && touched.estado}
                            helperText={touched.estado ? errors.estado : ''}
                            onChangeOwn={event => {
                              if (event) {
                                setFieldValue('estado', event.nmUf);
                              } else {
                                setFieldValue('estado', '');
                              }

                              this.valueAutoCompleteEstado = event;

                              if (event) {
                                this.validarInicializacaoAutoCompleteMunicipio(event.idUf);
                              }

                              this.setState({
                                keyEstado: Math.random(),
                                keyMunicipio: Math.random(),
                                keyComunidade: Math.random()
                              });
                              this.valueAutoCompleteMunicipio = '';
                              this.valueAutoCompleteComunidade = '';
                            }}
                            value={this.tratarValorSelectInitialEstado(this.valueAutoCompleteEstado)}
                            key={this.state.keyEstado}
                            valueAutoComplete={this.tratarValorSelectInitialEstado(
                              this.valueAutoCompleteEstado
                            )}
                            valueSelect={
                              this.tratarValorSelectInitialEstado(this.valueAutoCompleteEstado).nmUf
                            }
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Autocomplete
                            disabled={
                              this.valueAutoCompleteEstado === '' ||
                              this.valueAutoCompleteEstado === null
                            }
                            itens={this.state.municipios}
                            campoOp={'nmMunicipio'}
                            campoInput={'nmMunicipio'}
                            campoChave={'idMunicipio'}
                            label={provider.intl.formatMessage({
                              id: 'municipio'
                            })}
                            name='municipio'
                            error={errors.municipio && touched.municipio}
                            helperText={touched.municipio ? errors.municipio : ''}
                            onChangeOwn={event => {
                              setFieldValue('comunidade', '');
                              this.valueAutoCompleteComunidade = '';

                              this.setState({
                                keyComunidade: Math.random(),
                                itensComunidades: []
                              });

                              if (event) {
                                setFieldValue('municipio', event.nmMunicipio);
                                this.onChangeOwnMunicipio(event);
                              } else {
                                this.valueAutoCompleteMunicipio = '';
                                setFieldValue('municipio', '');
                              }
                            }}
                            value={this.tratarValorSelectInitialMunicipio(
                              this.valueAutoCompleteMunicipio
                            )}
                            key={this.state.keyMunicipio}
                            valueAutoComplete={this.tratarValorSelectInitialMunicipio(
                              this.valueAutoCompleteMunicipio
                            )}
                            valueSelect={
                              this.tratarValorSelectInitialMunicipio(this.valueAutoCompleteMunicipio)
                                .nmMunicipio
                            }
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Autocomplete
                            disabled={
                              this.valueAutoCompleteMunicipio === '' ||
                              this.valueAutoCompleteMunicipio === null
                            }
                            itens={this.state.itensComunidades}
                            campoOp={'nmComunidade'}
                            campoInput={'nmComunidade'}
                            campoChave={'idComunidade'}
                            label={provider.intl.formatMessage({
                              id: 'comunidade'
                            })}
                            name='comunidade'
                            onChangeOwn={event => {
                              if (event) {
                                setFieldValue('comunidade', event.nmComunidade);
                              } else {
                                setFieldValue('comunidade', '');
                              }

                              this.valueAutoCompleteComunidade = event;

                              this.setState({
                                keyComunidade: Math.random()
                              });
                            }}
                            value={this.tratarValorSelectInitialComunidade(
                              this.valueAutoCompleteComunidade
                            )}
                            key={this.state.keyComunidade}
                            valueAutoComplete={this.tratarValorSelectInitialComunidade(
                              this.valueAutoCompleteComunidade
                            )}
                            valueSelect={
                              this.tratarValorSelectInitialComunidade(this.valueAutoCompleteComunidade)
                                .nmComunidade
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={16}>
                        <Grid item xs={4}>
                          <TextField
                            id='dsEmail'
                            name='dsEmail'
                            value={values.dsEmail}
                            label={provider.intl.formatMessage({ id: 'email' })}
                            className={classes.textField}
                            margin='normal'
                            InputLabelProps={{
                              className: classes.font
                            }}
                            multiline={false}
                            error={errors.dsEmail && touched.dsEmail}
                            helperText={touched.dsEmail ? errors.dsEmail : ''}
                            onBlur={event => {
                              var data = this.state.dadosUsuario;

                              if (event) {
                                setFieldValue('dsEmail', event.target.value);
                                data.dsEmail = event.target.value;
                              } else {
                                setFieldValue('dsEmail', '');
                                data.dsEmail = '';
                              }
                            }}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextFieldWithMask
                            tipoMascara={1}
                            id='nrTelefone'
                            name='nrTelefone'
                            value={values.nrTelefone}
                            label={provider.intl.formatMessage({
                              id: 'telefone'
                            })}
                            className={classes.textField}
                            InputLabelProps={{
                              className: classes.font
                            }}
                            error={errors.nrTelefone && touched.nrTelefone}
                            helperText={touched.nrTelefone ? errors.nrTelefone : ''}
                            onBlur={event => {
                              var data = this.state.dadosUsuario;

                              if (event) {
                                setFieldValue('nrTelefone', event.target.value);
                                data.nrTelefone = event.target.value;
                              } else {
                                setFieldValue('nrTelefone', '');
                                data.nrTelefone = '';
                              }
                            }}
                            onChange={value => {
                              setFieldValue('nrTelefone', value.target.value);
                            }}
                          />
                        </Grid>

                        {parseInt(this.props.infoUsuario.tpPerfil) === 1 && (
                          <Grid item xs={4}>
                            <Autocomplete
                              itens={this.state.tecnicosList}
                              campoOp='nmUsuario'
                              campoChave='idUsuario'
                              label={<FormattedMessage id='produtores.tecnicoResponsavel' />}
                              name='tecnico'
                              error={this.valueAutoCompleteTecnico === '' && touched.tecnico}
                              helperText={
                                touched.tecnico && this.valueAutoCompleteTecnico === '' ? (
                                  <FormattedMessage id='produtores.campoObrigatorio' />
                                ) : null
                              }
                              onChangeOwn={e => {
                                setFieldValue('tecnico', e);
                                this.onChangeOwn(e);
                              }}
                              onChangeAutoComplete={this.validarInicializacaoAutoComplete}
                              value={this.valueAutoCompleteTecnico}
                              key={this.state.keyTecnico}
                              valueAutoComplete={this.valueAutoCompleteTecnico}
                              valueSelect={
                                this.valueAutoCompleteTecnico && this.valueAutoCompleteTecnico.nmUsuario
                              }
                            />
                          </Grid>
                        )}
                      </Grid>

                      <Grid container spacing={16}>
                        <Grid item xs={4}>
                          <div style={{ marginTop: 10 }}>
                            <Typography noWrap>
                              <Switch
                                color='primary'
                                checked={this.state.atividadeGraos}
                                onChange={this.handleChangeCheckedAtividade}
                              />
                              <label style={{ color: '#000000' }}>
                                {provider.intl.formatMessage({
                                  id: 'atividadeGraos'
                                })}
                              </label>
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item xs={2}>
                          <TextFieldWithMask
                            disabled={!this.state.atividadeGraos}
                            tipoMascara={3}
                            numberDecimalScale={3}
                            id='nrAreaProdutivaGrao'
                            name='nrAreaProdutivaGrao'
                            value={values.nrAreaProdutivaGrao}
                            label={provider.intl.formatMessage({
                              id: 'areaProdutiva'
                            })}
                            className={classes.textField}
                            InputLabelProps={{
                              className: classes.font
                            }}
                            error={errors.nrAreaProdutivaGrao && touched.nrAreaProdutivaGrao}
                            helperText={touched.nrAreaProdutivaGrao ? errors.nrAreaProdutivaGrao : ''}
                            onBlur={event => {
                              var data = this.state.dadosUsuario;

                              if (event) {
                                setFieldValue('nrAreaProdutivaGrao', event.target.value);
                                data.nrAreaProdutivaGrao = event.target.value;
                              } else {
                                setFieldValue('nrAreaProdutivaGrao', '');
                                data.nrAreaProdutivaGrao = '';
                              }
                            }}
                            onChange={value => {
                              setFieldValue('nrAreaProdutivaGrao', value.target.value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <div style={{ marginTop: 10 }}>
                            <Typography noWrap>
                              <Switch
                                color='primary'
                                checked={this.state.conhecimentoOrganica}
                                onChange={this.handleChangeChecked}
                              />
                              <label style={{ color: '#000000' }}>
                                {provider.intl.formatMessage({
                                  id: 'conhecimentoOrganica'
                                })}
                              </label>
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </form>

                  <div
                    className={classes.fab}
                    style={{
                      bottom: this.props.match.params.id === undefined ? 220 : 276
                    }}
                  >
                    <ButtonFABMenu
                      icon={iconMenuPontos}
                      actions={[
                        {
                          icon: <Check style={{ color: 'white' }} />,
                          name: this.retornaSalvarLinguagem(),
                          onClickIcon: handleSubmit,
                          color: '#42ADE8',
                          disabled: this.state.error
                        },
                        {
                          icon: <DoneAll style={{ color: 'white' }} />,
                          name: this.retornaEfetivarProdutor(),
                          onClickIcon: this.clickEfetivar,
                          color: '#463766',
                          hide: this.props.match.params.id === undefined,
                          disabled: this.state.error
                        },
                        {
                          icon: <Close style={{ color: 'white' }} />,
                          name: this.retornaCancelarLinguagem(),
                          onClickIcon: this.clickCancelar,
                          color: '#F33A30'
                        }
                      ]}
                    />
                  </div>
                </MuiThemeProvider>
              )}
            />
          </CardIcon>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarPreCadastro);
export default withRouter(connect(mapStateToProps)(enhaced));
