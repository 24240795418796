/*
    Autor: Gabriela Farias 
*/
import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconAdd from '@images/icone_add.png';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import '@styles/Alerts.css';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import Fitossanidades from '@resources/Severidade';
import SeveridadeEnum from '@resources/SeveridadeEnum';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const style = theme => ({
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 9.5,
    right: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '10%',
    zIndex: 1,
    height: '10%',
    marginBottom: 0,
    marginLeft: '90%'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  }
});

class Fitossanidade extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const nao = messagesLinguage['fitossanidade.nao'];
    const desejaExcluir = messagesLinguage['fitossanidade.desejaExcluir'];
    const excluir = messagesLinguage['fitossanidade.excluir'];
    const fitossanidadeRemovidoComSucesso =
      messagesLinguage['fitossanidade.fitossanidadeRemovidoComSucesso'];
    const falhaAoExcluirFitossanidade = messagesLinguage['fitossanidade.falhaAoExcluirFitossanidade'];
    const excluindo = messagesLinguage['fitossanidade.excluindo'];
    const cultura = messagesLinguage['fitossanidade.cultura'];
    const tipo = messagesLinguage['fitossanidade.tipo'];
    const situacao = messagesLinguage['fitossanidade.situacao'];
    const nome = messagesLinguage['fitossanidade.nome'];
    const ativo = messagesLinguage['fitossanidade.ativo'];
    const inativo = messagesLinguage['fitossanidade.inativo'];
    const doencaSolo = messagesLinguage['fitossanidade.doencaSolo'];
    const doencaFoliar = messagesLinguage['fitossanidade.doencaFoliar'];
    const nematoide = messagesLinguage['fitossanidade.nematoide'];
    const fitossanidade = messagesLinguage['fitossanidade.fitossanidade'];
    const pragas = messagesLinguage['fitossanidade.pragas'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          nao,
          desejaExcluir,
          excluir,
          fitossanidadeRemovidoComSucesso,
          falhaAoExcluirFitossanidade,
          excluindo,
          cultura,
          tipo,
          situacao,
          nome,
          ativo,
          inativo,
          doencaSolo,
          doencaFoliar,
          nematoide,
          fitossanidade,
          pragas
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      itemSelect: '',
      disabledEdit: true,
      disableDelete: true,
      key: '',
      dataInTable: [],
      dataAppSync: [],
      statusAlert: false,
      loading: true,
      tipos: [
        {
          id: SeveridadeEnum.DOENCA_SOLO,
          name: provider.intl.formatMessage({ id: 'doencaSolo' })
        },
        {
          id: SeveridadeEnum.DOENCA_FOLIAR,
          name: provider.intl.formatMessage({ id: 'doencaFoliar' })
        },
        {
          id: SeveridadeEnum.NEMATOIDE,
          name: provider.intl.formatMessage({ id: 'nematoide' })
        },
        {
          id: SeveridadeEnum.PRAGAS,
          name: provider.intl.formatMessage({ id: 'pragas' })
        }
      ],
      head: [
        {
          field: 'nmCultura',
          headerName: provider.intl.formatMessage({ id: 'cultura' }),
          sort: 'asc',
          col: 3
        },
        {
          field: 'nmTipo',
          headerName: provider.intl.formatMessage({ id: 'tipo' }),
          col: 3
        },
        {
          field: 'nmSeveridade',
          headerName: provider.intl.formatMessage({ id: 'nome' }),
          col: 3
        },
        {
          field: 'nmStatus',
          headerName: provider.intl.formatMessage({ id: 'situacao' }),
          col: 3
        }
      ],
      operationValues: [
        {
          label: provider.intl.formatMessage({ id: 'cultura' }),
          field: 'nmCultura',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'tipo' }),
          field: 'nmTipo',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'nome' }),
          field: 'nmSeveridade',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'situacao' }),
          field: 'stSeveridade',
          type: 'enum',
          options: [
            {
              label: provider.intl.formatMessage({ id: 'ativo' }),
              field: 'ativo',
              value: '1'
            },
            {
              label: provider.intl.formatMessage({ id: 'inativo' }),
              field: 'inativo',
              value: '0'
            }
          ]
        }
      ]
    };

    const title = 'fitossanidade.fitossanidade';
    if (this.props.itensState.name !== 'fitossanidade.fitossanidade') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false,
        disableDelete: select.idCliente === null
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true,
        disableDelete: true
      });
    }
  }

  clickAdicionar() {
    this.props.history.push('/app/fitossanidade/novo');
  }

  clickEditar() {
    this.props.history.push('/app/fitossanidade/editar/' + this.state.itemSelect.idSeveridade);
  }

  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            title: provider.intl.formatMessage({ id: 'excluindo' })
          }
        );
        Fitossanidades.deleteSeveridadeById(this.state.itemSelect.idSeveridade)
          .then(data => {
            swal(
              provider.intl.formatMessage({
                id: 'fitossanidadeRemovidoComSucesso'
              }),
              {
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                }
              }
            );
            this.atualizarRegistros(this.state.itemSelect.idSeveridade);
          })
          .catch(error => {
            swal(
              provider.intl.formatMessage({
                id: 'falhaAoExcluirFitossanidade'
              }),
              {
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-error'
                  }
                }
              }
            );
          });
      }
    });
  }

  atualizarRegistros(data) {
    var index = -1;
    for (var i = 0; i < this.state.dataAppSync.length; i++) {
      if (this.state.dataAppSync[i].idSeveridade === data) {
        index = i;
        break;
      }
    }
    this.state.dataAppSync.splice(index, 1);
    this.setState({
      dataAppSync: this.state.dataAppSync,
      dataInTable: this.state.dataAppSync,
      key: Math.random()
    });

    this.onFilterChanged(this.props.chipData);
  }

  componentDidMount() {
    this.onFilter(this.props.chipData);
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    Fitossanidades.findAllSeveridade()
      .then(query => {
        var data = query.data;
        data.forEach(doc => {
          doc.tipo = this.state.tipos.find(tipo => {
            return tipo.id === doc.tpSeveridade;
          });
          doc.nmTipo = doc.tipo.name;
          doc.nmCultura = doc.cultura.nmCultura;
          doc.nmStatus =
            doc.stSeveridade === 1
              ? provider.intl.formatMessage({ id: 'ativo' })
              : provider.intl.formatMessage({ id: 'inativo' });
        });

        this.setState({
          dataAppSync: data,
          dataInTable: FilterUtil.applyFilters(data, dataChips),
          loading: false
        });
      })
      .catch(() => {
        this.setState({
          dataAppSync: [],
          loading: false
        });
      });
  }

  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'scroll'
          }}
        >
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='fitossanidade'
          />
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disableDelete}
            onClickEdit={this.clickEditar}
            onClickDelete={this.clickExcluir}
          />

          <div
            style={{
              marginTop: 30,
              marginBottom: 15,
              display: 'contents',
              width: '100%',
              height: '100%'
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              key={this.state.key}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={() => this.clickAdicionar()} />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'fitossanidade';
  })[0].content
});

let enhaced = withStyles(style)(Fitossanidade);
export default withRouter(connect(mapStateToProps)(enhaced));
