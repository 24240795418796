import React from "react";
import TextField from "@material-ui/core/TextField";

function TextFieldAcompanhamento(props) {
  return (
    <TextField
      disabled
      multiline
      id="standard-disabled"
      label={props.label}
      value={props.limpar ? "" : props.value}
      margin="normal"
      style={{ width: "100%", height: 70, fontSize: 14 }}
    />
  );
}

export default TextFieldAcompanhamento;
