import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const styles = {
  divTitulo: {
    display: 'flex',
    fontSize: 20,
    color: 'rgb( 0, 0, 0, 0.7)',
    fontWeight: 500,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30
  },
  divTituloExpandBotton: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
    padding: 0
  },
  avatar: {
    borderRadius: 5,
    backgroundColor: '#42ADE8',
    width: 54,
    height: 54,
    left: 15,
    margin: 0,
    marginRight: '15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
  },
  div: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-15px'
  },
  card: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flex: 1,
    height: 54,
    marginTop: 15,
    flexDirection: 'column'
  }
};

class CardIcon extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div
        className={classes.div}
        style={{ height: this.props.height ? this.props.height : '100%', ...this.props.style }}
      >
        <Card
          className={classNames(classes.card, this.props.className)}
          style={{
            overflow: 'visible',
            borderRadius: this.props.withOutBorderRadius ? 0 : 5,
            marginBottom: this.props.marginBottom ? 15 : 0,
            ...this.props.styleCard
          }}
        >
          {!this.props.disableHeader && (
            <div
              className={classes.divTitulo}
              style={{
                backgroundColor: this.props.withoutColor ? 'none' : 'rgb(139, 200, 165, 0.1)',
                height: this.props.withoutColor ? 0 : 60,
                minHeight: 60,
                ...this.props.styleHeader
              }}
            >
              <div
                style={{
                  height: 60,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {this.props.title}
              </div>
              <div
                onClick={() => {
                  this.props.clickSeta();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: this.props.button ? 'pointer' : 'default'
                }}
              >
                {this.props.arrowComponent ? (
                  this.props.arrowComponent
                ) : (
                  <ArrowUpward
                    style={{
                      display: this.props.button ? 'inline-block' : 'none',
                      width: 21,
                      height: 24,
                      transform: this.props.directionSeta ? 'rotate(0deg)' : 'rotate(180deg)'
                    }}
                  />
                )}
                <p
                  style={{
                    display: this.props.showLabel ? 'flex' : 'none',
                    fontWeight: '500 !important',
                    width: 40,
                    alignItems: 'center'
                  }}
                >
                  {this.props.labelButton}
                </p>
              </div>
            </div>
          )}

          <div style={{ padding: this.props.disablePadding ? 0 : 30, ...this.props.childrenStyle }}>
            {this.props.children}
          </div>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(CardIcon);
