import React, { useState, useEffect } from 'react';
import formatMessage from './../i18n/formatMessage';
import { connect } from 'react-redux';
import CardIcon from '@components/CardIcon/CardIcon';
import { Grid, Checkbox } from '@material-ui/core';
import DatePicker from '@components/DatePicker/DatePicker';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getUserLang } from '@utils/localeUtils';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import moment from 'moment';

/**
 * Grid de  cadastro de medida corretiva não conformidade
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const EditarMedidaCorretiva = props => {
  const { classes, medidaCorretivaList, valuesEditing, addEditMedidaCorretiva, clearSelect } = props;
  const [medidaCorretiva, setMedidaCorretiva] = useState(null);
  const [keyAutoCompleteMedidaCorretiva, setKeyAutoCompleteMedidaCorretiva] = useState(0);
  const [stMedidaCorretiva, setStMedidaCorretiva] = useState(true);
  const [initialValues, setInitialValues] = useState({
    medidaCorretiva: null,
    dhInicio: null,
    dhTermino: null
  });

  useEffect(() => {
    if (valuesEditing) {
      setInitialValues(valuesEditing);
      setMedidaCorretiva(valuesEditing.medidaCorretiva);
      setStMedidaCorretiva(valuesEditing.stSancaoMedidaCorretiva === 1)
      setKeyAutoCompleteMedidaCorretiva(Math.random());
    }
  }, [valuesEditing]);

  function loadMedidaCorretivaList() {
    return medidaCorretivaList;
  }

  function tratarValorMedidaCorretiva() {
    if (!medidaCorretiva) {
      return '';
    }

    return medidaCorretiva;
  }

  function submit(values, { resetForm }) {
    values.stSancaoMedidaCorretiva = stMedidaCorretiva ? 1 : 0;
    values.dhInicio = moment(values.dhInicio).format('YYYY-MM-DDTHH:mm:ssZZ');
    if (values.dhTermino) {
      values.dhTermino = moment(values.dhTermino).format('YYYY-MM-DDTHH:mm:ssZZ');
    }

    addEditMedidaCorretiva(values, initialValues.idSancaoMedidaCorretiva);

    clearSelect();
    resetForm({
      medidaCorretiva: null,
      dhInicio: null,
      dhTermino: null
    });

    setStMedidaCorretiva(true);
    setMedidaCorretiva(null);
    setKeyAutoCompleteMedidaCorretiva(Math.random());
  }

  function clearFormik(setFieldValue) {
    setFieldValue('medidaCorretiva', null);
    setFieldValue('dhInicio', null);
    setFieldValue('dhTermino', null);
    setMedidaCorretiva(null);

    clearSelect();

    setStMedidaCorretiva(true);
    setKeyAutoCompleteMedidaCorretiva(Math.random());
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        medidaCorretiva: Yup.object()
          .nullable()
          .required(formatMessage('naoConformidade.campoObrigatorio')),
        dhInicio: Yup.string()
          .nullable()
          .required(formatMessage('naoConformidade.campoObrigatorio')),
        dhTermino: Yup.string().nullable()
      })}
      onSubmit={submit}
      render={({ values, handleSubmit, errors, touched, setFieldValue }) => (
        <div style={{ width: '100%' }}>
          <CardIcon titulo={formatMessage('naoConformidade.medidaCorretiva')}>
            <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
              <Grid item xs={4}>
                <Autocomplete
                  itens={medidaCorretivaList}
                  campoOp='nmMedidaCorretiva'
                  campoChave='idMedidaCorretiva'
                  label={formatMessage('naoConformidade.medidaCorretiva')}
                  name='medidaCorretiva'
                  error={!medidaCorretiva && touched.medidaCorretiva}
                  helperText={
                    touched.medidaCorretiva && !medidaCorretiva
                      ? formatMessage('naoConformidade.campoObrigatorio')
                      : null
                  }
                  onChangeOwn={value => {
                    setFieldValue('medidaCorretiva', value);
                    setMedidaCorretiva(value);
                  }}
                  onChangeAutoComplete={loadMedidaCorretivaList}
                  value={tratarValorMedidaCorretiva()}
                  key={keyAutoCompleteMedidaCorretiva}
                  valueAutoComplete={tratarValorMedidaCorretiva()}
                  valueSelect={tratarValorMedidaCorretiva().nmMedidaCorretiva}
                  maxHeight='calc(100vh - 250px)'
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  locale={getUserLang()}
                  style={{ height: 70 }}
                  className={classes.font}
                  label={formatMessage('naoConformidade.dataInicial')}
                  valueDate={values.dhInicio}
                  errorTouched={errors.dhInicio && touched.dhInicio}
                  helperText={errors.dhInicio && touched.dhInicio ? errors.dhInicio : null}
                  onChangeDatePicker={value => {
                    setFieldValue('dhInicio', value);
                    setFieldValue('dhTermino', null);
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  minDate={values.dhInicio ? values.dhInicio : '2100-01-01'}
                  locale={getUserLang()}
                  style={{ height: 70 }}
                  className={classes.font}
                  label={formatMessage('naoConformidade.dataFinal')}
                  valueDate={values.dhTermino}
                  errorTouched={errors.dhTermino && touched.dhTermino}
                  helperText={errors.dhTermino && touched.dhTermino ? errors.dhTermino : null}
                  onChangeDatePicker={value => setFieldValue('dhTermino', value)}
                />
              </Grid>

              <Grid item xs={1} style={{ marginRight: 20, marginLeft: 20 }}>
                <div className={classes.switch} onClick={() => setStMedidaCorretiva(!stMedidaCorretiva)}>
                  <Checkbox checked={stMedidaCorretiva} value='stMedidaCorretiva' color='primary' />
                  <label className={classes.switchAtivo}>{formatMessage('naoConformidade.ativo')}</label>
                </div>
              </Grid>

              <Grid item xs={1} className={classes.cardButton}>
                <Button className={classes.button} onClick={handleSubmit}>
                  <Add className={classes.iconButton} />
                </Button>
                <div style={{ width: 20 }} />
                <Button className={classes.button} onClick={() => clearFormik(setFieldValue)}>
                  <Close className={classes.iconButton} />
                </Button>
              </Grid>
            </Grid>
          </CardIcon>
        </div>
      )}
    />
  );
};

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(EditarMedidaCorretiva);
