import React, { useState, useEffect } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardFilters from '@components/CardFilters/CardFilters';
import { withStyles } from '@material-ui/core';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Regioes from '@resources/Regiao';
import Produtores from '@resources/Produtores';
import Localizacao from '@resources/Localizacao';
import * as moment from 'moment';
import Usuario from '@resources/Usuario';
import { FormattedMessage } from 'react-intl';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {
  card: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 15px)'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  page: {
    marginBottom: 15
  },
  document: {
    width: '100%'
  },
  divDocument: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
};

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const relatorio = messagesLinguage['relatorio.relatorio'];
const regiao = messagesLinguage['relatorio.regiao'];
const municipio = messagesLinguage['relatorio.municipio'];
const sim = messagesLinguage['relatorio.sim'];
const nao = messagesLinguage['relatorio.nao'];
const carregando = messagesLinguage['relatorio.carregando'];
const gerandoRelatorio = messagesLinguage['relatorio.gerandoRelatorio'];
const falhaAoGerarRelatorio = messagesLinguage['relatorio.falhaAoGerarRelatorio'];
const dataLancamento = messagesLinguage['relatorio.dataLancamento'];
const tecnicoResponsavel = messagesLinguage['relatorio.tecnicoResponsavel'];
const produtor = messagesLinguage['relatorio.produtor'];

const intlProvider = new IntlProvider({
  locale: getUserLang(),
  messages: {
    relatorio,
    regiao,
    municipio,
    sim,
    nao,
    carregando,
    gerandoRelatorio,
    falhaAoGerarRelatorio,
    dataLancamento,
    tecnicoResponsavel,
    produtor
  }
});

provider = intlProvider.getChildContext();

const operationTypeString = [
  {
    label: provider.intl.formatMessage({ id: 'municipio' }),
    field: 'nmMunicipio',
    list: 'listMunicipio',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'regiao' }),
    field: 'nmRegiao',
    list: 'listRegiao',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'tecnicoResponsavel' }),
    field: 'nmTecnico',
    list: 'listTecnicos',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'produtor' }),
    field: 'nmProdutor',
    list: 'listProdutor',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'dataLancamento' }),
    field: 'dtLancamento',
    list: 'listRegiao',
    type: 'date'
  }
];

/**
 * Tela de Relatório de análise de riscos análitico
 *
 * @author Gabriela Farias
 * @class Relatorio
 * @extends {Component} - Componente React
 */
function RelatorioAnaliseRiscosAnalitico(props) {
  const [filePDF, setFilePDF] = useState(null);
  const [fileXLS, setFileXLS] = useState(null);
  const [closeCard, setCloseCard] = useState(false);
  const [errorCard, setErrorCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDados, setLoadingDados] = useState(true);
  const [listTecnicos, setListTecnicos] = useState([]);
  const [listMunicipio, setListMunicipio] = useState([]);
  const [listRegiao, setListRegiao] = useState([]);
  const [listProdutor, setListProdutor] = useState([]);
  const { classes } = props;

  useEffect(() => {
    const title = 'relatorio.analitico';
    if (props.itensState.name !== 'relatorio.analitico') {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    Promise.all([
      Usuario.findAllUsuarios()
        .then(query => {
          let data = [];
          query.data.forEach(doc => {
            if (doc.tpPerfil === 2) {
              data.push(doc);
            }
          });

          setListTecnicos(data);
        })
        .catch(err => {
          setListTecnicos([]);
        }),

      Regioes.findAllRegiao()
        .then(query => {
          setListRegiao(query.data);
        })
        .catch(() => {
          setListRegiao([]);
        }),

      Produtores.findProdutor()
        .then(query => {
          setListProdutor(query.data);
        })
        .catch(() => {
          setListProdutor([]);
        }),

      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          setListMunicipio(query.data);
        })
        .catch(err => {
          setListMunicipio([]);
        })
    ])
      .then(() => {
        setLoadingDados(false);
      })
      .catch(() => {
        setLoadingDados(false);
      });
  }, []);

  const clickGerarRelatorio = chips => {
    setErrorCard(false);
    setCloseCard(true);
    setLoading(true);
    setFilePDF(null);
    setFileXLS(null);

    let municipio = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    if (municipio !== undefined) {
      municipio = 'idMunicipio:' + municipio.value.value + ';';
    } else {
      municipio = '';
    }

    let regiao = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    if (regiao !== undefined) {
      regiao = 'idRegiao:' + regiao.value.value + ';';
    } else {
      regiao = '';
    }

    let nmTecnico = chips.find(item => {
      return item.filter.field === 'nmTecnico';
    });

    if (nmTecnico !== undefined) {
      nmTecnico = 'idTecnico:' + nmTecnico.value.value + ';';
    } else {
      nmTecnico = '';
    }

    let nmProdutor = chips.find(item => {
      return item.filter.field === 'nmProdutor';
    });

    if (nmProdutor !== undefined) {
      nmProdutor = 'idProdutor:' + nmProdutor.value.value + ';';
    } else {
      nmProdutor = '';
    }

    let dtLancamento = '';
    chips.forEach(item => {
      if (item.filter.field === 'dtLancamento') {
        let itemOperacao = item.operation;
        if (itemOperacao === '==') {
          itemOperacao = ':';
        } else if (itemOperacao === '!=') {
          itemOperacao = '<>';
        }

        dtLancamento =
          dtLancamento + 'dtLancamento' + itemOperacao + moment(item.value).format('YYYY-MM-DD') + ';';
      }
    });

    const filters = municipio + regiao + nmTecnico + nmProdutor + dtLancamento;

    Relatorios.gerarAnaliseRiscoAnalitico(filters)
      .then(doc => {
        setCloseCard(false);
        setFileXLS(doc.data.dsLinkXls);
        setFilePDF(doc.data.dsLinkPdf);
      })
      .catch(err => {
        modalErrorRelatorio();
      });
  };

  const modalErrorRelatorio = () => {
    setErrorCard(true);
    setCloseCard(false);
    setLoading(false);
    setFileXLS(null);
    setFilePDF(null);
    setErrorCard(false);

    swal(provider.intl.formatMessage({ id: 'falhaAoGerarRelatorio' }), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  };

  return (
    <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
      <div className={classes.card}>
        {(loading || loadingDados) && <Loading />}
        <CardFilters
          autoComplete={{
            listTecnicos: {
              list: listTecnicos,
              campoOp: 'nmUsuario',
              campoChave: 'idUsuario'
            },
            listMunicipio: {
              list: listMunicipio,
              campoOp: 'nmMunicipio',
              campoChave: 'idMunicipio'
            },
            listRegiao: {
              list: listRegiao,
              campoOp: 'nmRegiao',
              campoChave: 'idRegiao'
            },
            listProdutor: {
              list: listProdutor,
              campoOp: 'nmProdutor',
              campoChave: 'idProdutor'
            }
          }}
          errorCard={errorCard}
          closeCard={closeCard}
          relatorio={true}
          onClickButton={clickGerarRelatorio}
          filtros={operationTypeString}
          usePaddings={false}
          onFilter={() => {}}
          onFilterChanged={() => {}}
          viewKey='relatorio-analise-riscos-analitico'
        />

        {loading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 20,
              justifyContent: 'center'
            }}
          >
            {provider.intl.formatMessage({ id: 'gerandoRelatorio' })}
          </div>
        )}

        <PDFViewer
          loadSucess={() => {
            setLoading(false);
          }}
          errorCard={errorCard}
          fileXLS={fileXLS}
          filePDF={filePDF}
        />
      </div>
    </IntlProvider>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'relatorio-analise-riscos-analitico';
  })[0].content
});

RelatorioAnaliseRiscosAnalitico.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(RelatorioAnaliseRiscosAnalitico));
export default withRouter(connect(mapStateToProps)(enhaced));
