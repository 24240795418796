export default {
  nematicidaMicrobiologico: {
    nematicidaMicrobiologico: 'Nematicidas microbiológicas',
    cadastroDeNematicidaMicrobiologico: 'Registro de nematicidas microbiológicas',
    nematicidaMicrobiologicoRemovidoComSucesso: 'Nematicida microbiológica quitado con éxito',
    falhaAoExcluirNematicidaMicrobiologico: 'Fallo la excluir nematicida microbiológica',
    desejaExcluir: '¿Desea eliminar el nematicida microbiológica seleccionada?',
    identificacaoDaNematicidaMicrobiologico: 'Identificación',
    nematicidaMicrobiologicoCadastradoComSucesso: 'Nematicida microbiológica registrada con éxito',
    nematicidaMicrobiologicoEditadoComSucesso: 'Nematicida microbiológica editado con éxito',
    falhaAoEditarNematicidaMicrobiologico: 'Falla al editar nematicida microbiológica',
    falhaAoCadastrarNematicidaMicrobiologico: 'Error al editar nematicida microbiológica',
    falhaAoCarregarNematicidaMicrobiologico: 'Error al cargar nematicida microbiológica'
  }
};
