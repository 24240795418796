export default {
  calendar: {
    hoje: 'Today',
    mes: 'Month',
    semana: 'Week',
    dia: 'Day',
    mais: 'more',
    agenda: 'Agenda',
    diaTodo: 'All day',
    data: 'Date',
    hora: 'Time',
    evento: 'Event',
    noEvents: 'There are no events in this range',
    formatDate: 'ddd M/D',
    formatTime: 'h:mm a',
    formatDatePopUp: 'dddd, MMMM D',
    adicionar: 'Add',
    lista: 'List',
    formatMonth: 'MMM YYYY',
    adicionarEvento: 'Add event',
    adicionarVisita: 'Add visit'
  }
};
