import React from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { styles, theme } from './stylesSwipeableViewsOwn';
import classNames from 'classnames';

/**
 * Componente SwipeableViews customizado
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {Object} props - props
 * @returns {JSX.Element} Componente React
 */
function SwipeableViewsOwn(props) {
  const { classes, children = [], index, className, containerStyle, slideStyle, ...others } = props;

  /**
   * Retorna o Componente se o index da tab selecionada for igual ao index passado por parâmetro
   *
   * @param {Number} indexToValidate - Index pra ser validado
   * @param {JSX.Element} Component - Componente que vai retornar se passar pela validação
   * @returns {JSX.Element} O componente passado por parâmetro ou uma div "vazia"
   */
  function renderIfIndex(indexToValidate, Component) {
    return indexToValidate === index ? (
      <div key={indexToValidate + '-rendering'} style={{ height: '100%' }}>
        {Component}
      </div>
    ) : (
      <div key={indexToValidate + '-notRendering'} />
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      <SwipeableViews
        ignoreNativeScroll
        {...others}
        index={index}
        containerStyle={{ height: '100%', ...containerStyle }}
        slideStyle={{
          overflowY: props.withScroll ? 'scroll' : 'hidden',
          overflowX: 'hidden',
          ...slideStyle
        }}
        className={classNames(classes.swipeable, className)}
      >
        {children.map((item, i) => renderIfIndex(i, item))}
      </SwipeableViews>
    </MuiThemeProvider>
  );
}

export default withStyles(styles)(SwipeableViewsOwn);
