export default {
  inoculantes: {
    inoculantes: 'Inoculants',
    cadastroDeInoculantes: 'Registro de inoculants',
    inoculantesRemovidoComSucesso: 'Inoculant quitado con éxito',
    falhaAoExcluirInoculantes: 'Fallo la excluir inoculantes',
    desejaExcluir: '¿Desea eliminar el inoculantes seleccionada?',
    identificacaoDaInoculantes: 'Identificación',
    inoculantesCadastradoComSucesso: 'Inoculante registrada con éxito',
    inoculantesEditadoComSucesso: 'Inoculante editado con éxito',
    falhaAoEditarInoculantes: 'Falla al editar inoculante',
    falhaAoCadastrarInoculantes: 'Error al editar inoculante',
    falhaAoCarregarInoculantes: 'Error al cargar inoculante'
  }
};
