export default {
  analiseOGM: {
    analiseOGM: 'Análise OGM',
    nao: 'Não',
    desejaExcluir: 'Deseja excluir?',
    excluir: 'Excluir',
    analiseOGMRemovidoComSucesso: 'Análise OGM removida com sucesso',
    falhaAoExcluirAnaliseOGM: 'Falha ao excluir análise OGM',
    excluindo: 'Excluindo',
    cultura: 'Cultura',
    tipo: 'Tipo',
    situacao: 'Situação',
    nome: 'Nome',
    analiseOGMCadastradoComSucesso: 'Análise OGM cadastrada com sucesso',
    analiseOGMEditadoComSucesso: 'Análise OGM editada com sucesso',
    falhaAoCadastrarAnaliseOGM: 'Falha ao cadastrar análise OGM',
    falhaAoEditarAnaliseOGM: 'Falha ao editar análise OGM',
    falhaAoCarregarAnaliseOGM: 'Falha ao carregar análise OGM',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    cadastroDeAnaliseOGM: 'Cadastro de análise OGM',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    doencaSolo: 'Doença de solo',
    doencaFoliar: 'Doença foliar',
    nematoide: 'Nematóide',
    descricao: 'Descrição',
    ativo: 'Ativo',
    inativo: 'Inativo',
    pragas: 'Pragas'
  }
};
