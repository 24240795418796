import moment from 'moment';

function validarDatasCertificacao(
  values,
  certificacaoList,
  isEditing,
  idVariableName,
  tipoCertificao = true
) {
  let dataConflito = null;

  dataConflito = certificacaoList.find(item => {
    const dtInicioValues = item.dtInicio ? moment(item.dtInicio).startOf('day') : null;
    const dtTerminoValues = item.dtTermino ? moment(item.dtTermino).startOf('day') : null;

    const isBetween =
      values.dtInicio >= dtInicioValues && values.dtTermino && values.dtTermino <= dtTerminoValues;
    const isBefore = values.dtInicio <= dtInicioValues && values.dtTermino >= dtInicioValues;
    const isAfter =
      values.dtInicio != null &&
      values.dtInicio <= dtTerminoValues &&
      values.dtTermino >= dtTerminoValues;
    const isOutside =
      values.dtInicio <= dtInicioValues && dtTerminoValues && values.dtTermino >= dtTerminoValues;

    const withoutInicial = values.dtInicio === null && dtInicioValues === null;
    const itemWithoutInicial = dtInicioValues === null && values.dtInicio <= dtTerminoValues;
    const valuesWithoutInicial = values.dtInicio === null && dtInicioValues <= values.dtTermino;

    const withoutFinal = values.dtTermino === null && dtTerminoValues === null;
    const itemWithoutFinal = dtTerminoValues === null && values.dtTermino >= dtInicioValues;
    const valuesWithoutFinal = values.dtTermino === null && dtTerminoValues >= values.dtInicio;

    if (isEditing && item[idVariableName] === values[idVariableName]) {
      return false;
    }

    const tpCertificacao = tipoCertificao
      ? item.tipoCertificacao.idTipoCertificacao === values.tipoCertificacao.idTipoCertificacao
      : true;

    return (
      tpCertificacao &&
      (isBetween ||
        isBefore ||
        isAfter ||
        isOutside ||
        withoutFinal ||
        itemWithoutFinal ||
        valuesWithoutFinal ||
        withoutInicial ||
        itemWithoutInicial ||
        valuesWithoutInicial)
    );
  });

  return dataConflito;
}

export { validarDatasCertificacao };
