export default {
  plantios: {
    descartar: 'Descartar',
    salvarPlantioSair: 'Hay información sin guardar, ¿desea guardar la siembra?',
    atividadeRelacionadaDeletePlantio:
      'No se puede eliminar la siembra porque hay una actividad relacionada con ella.',
    falhaoAoExcluir: 'Se produjo un error al eliminar la plantación. Vuelva a intentarlo.',
    minimoTalhoes: 'Agregar al menos 1 campo',
    falhaAoSalvarPlantio: 'Se produjo un error al guardar la plantación. Vuelva a intentarlo.',
    plantioSalvoComSucesso: 'Siembra guardada con éxito',
    salvando: 'Guardar',
    atualizando: 'Actualización',
    alertasFitossanitarios: 'Alertas fitosanitarias',
    invasoras: 'Invasivo',
    tipo: 'Tipo',
    agenteCausal: 'Agente causal',
    data: 'Fecha',
    doencaSolo: 'Enfermedad del suelo',
    doencaFoliar: 'Enfermedad de la hoja',
    nematoide: 'Nematodo',
    pragas: 'Plagas',
    desejaLimparTalhoes:
      'Si cambia el área de cultivo, las parcelas seleccionadas se eliminarán, ¿desea continuar?',
    sim: 'Si',
    produtorSemAreaCultivo: 'El agricultor debe tener al menos un área de cultivo para crear un cultivo',
    produtorSemTalhao: 'El agricultor debe tener al menos un campo para crear un cultivo',
    convencional: 'Convencional',
    emConversao: 'En conversión',
    brasil: 'BR (Brasil)',
    comunidadeEuropeia: 'CE (Comunidad Europea)',
    eua: 'NOP (Estados Unidos)',
    certificacaoOrganica: 'Estado de certificación orgánica',
    selecionarTodos: 'Seleccionar todo',
    talhao: 'Campo',
    area: 'Area',
    produtividade: 'Productividad',
    diasEstimados: 'Días estimados',
    dataPrevista: 'Fecha prevista',
    produtividadeEstimada: 'Productividad estimada',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    quantidadePlantas: 'Número de plantas',
    metro: 'Metro',
    hectare: 'Hectárea',
    alqueire: 'Celemín',
    acre: 'Acre',
    tonelada: 'Ton',
    sacas: 'Sacos',
    sementes: 'Semillas',
    nrQuantidade: 'Cantidad',
    unidade: 'La unidad',
    unMedida: 'Un. de medida',
    espacamento: 'Espaciamiento (cm)',
    semeadura: 'Siembra',
    standFinal: 'Soporte final',
    previsaoColheita: 'Pronóstico de cosecha',
    colheita: 'Cosecha',
    localPlantio: 'Sitio de plantación',
    campoObrigatorio: 'Campo obligatorio',
    cadastroPlantio: 'Registro de plantación',
    plantioExcluido: 'Plantación eliminada con éxito',
    nao: 'No',
    excluir: 'Excluir',
    desejaExcluirPlantio: '¿Realmente quieres eliminar esta plantación?',
    dataPlantio: 'Fecha de siembra',
    areaDeCultivo: 'Área de cultivo',
    cultura: 'Cultura',
    detentor: 'Poseedor',
    cultivar: 'Cultivar',
    dataColheita: 'Fecha de cosecha',
    produtor: 'Productor',
    plantios: 'Plantaciones',
    identificacao: 'Identificación',
    barreiraVegetalSul: 'Barreira vegetal sur',
    barreiraVegetalNorte: 'Barreira vegetal norte',
    barreiraVegetalLeste: 'Barreira vegetal este',
    barreiraVegetalOeste: 'Barreira vegetal oeste',
    barreiraVegSul: 'Barreira veg. sur',
    barreiraVegNorte: 'Barreira veg. norte',
    barreiraVegLeste: 'Barreira veg. este',
    barreiraVegOeste: 'Barreira veg. oeste',
    vizinho: 'Vecino',
    entregaEfetiva: 'Entrega efectiva',
    causa: 'Porque',
    insumos: 'Entradas',
    ogm: 'OGM',
    analiseOGM: 'Análisis de OGM',
    materalPropagacao: 'Material de propagación',
    laudos: 'Informes',
    descricao: 'Descripción',
    origemSemente: 'Origem da semente',
    descricaoOrigem: 'Descripción del origen',
    sementeTratada: 'Semilla tratada',
    categoria: 'Categoría',
    marca: 'Marca',
    produto: 'Producto',
    acaricida: 'Acaricidas',
    acaricidaMicrobiologico: 'Acaricidas microbiológicas',
    agenteBiologicoControle: 'Agentes biológicos',
    bactericida: 'Agentes biológicos',
    bactericidaMicrobiologico: 'Bactericidas microbiológicos',
    herbicida: 'Herbicidas',
    fungicida: 'Fungicidas',
    fungicidaMicrobiologico: 'Fungicidas Microbiológicos',
    inseticida: 'Inseticidas',
    inseticidaMicrobiologico: 'Inseticidas microbiológicos',
    nematicida: 'Nematicidas',
    nematicidaMicrobiologico: 'Nematicidas microbiológicas',
    fertilizanteFoliar: 'Fertilizantes hoja',
    acaros: 'Ácaros',
    bacterias: 'Bacterias',
    ervasDaninhas: 'Malas hierbas',
    fungos: 'Hongos',
    insetos: 'Insectos',
    nematoides: 'Nematodos',
    foliaresAdjuvantes: 'Hoja y adyuvante',
    fertilizantes: 'Fertilizantes',
    fertilizanteQuimico: 'Fertilizantes químico',
    fertilizanteOrganico: 'Fertilizantes orgánico',
    oleosAdjuvantes: 'Aceites y adyuvantes',
    desejaExcluirInsumo: '¿Desea eliminar la entrada?',
    insumoRemovidaComSucesso: 'Entrada eliminada con éxito',
    falhaAoExcluirInsumo: 'Error al eliminar la entrada',
    operadora: 'Operador',
    propria: 'Propio',
    outros: 'Otros',
    excluirTalhao: '¿Desea eliminar la ubicación de plantación?',
    homeopaticos: 'Productos homeopáticos',
    inoculantesHomeopatia: 'Inoculantes y homeopatía',
    inoculantes: 'Inoculantes',
    m2: 'm2',
    kg: 'Kg',
    mudas: 'Plántulas',
    safra: 'Cosecha',
    adicionarLaudo: 'Agregar informe',
    erroAnexarFoto: 'Error al adjuntar archivo',
    nenhumLaudo: 'Sin reporte, puede ingresar hasta 3 reportes',
    removeArquivo: '¿Quieres eliminar el archivo?',
    insumoMaterialProgapagacao: 'Entradas y mat. de propagación',
    insumosPlantio: 'Insumos de siembra',
    possuiLaudo: 'Tiene un informe de OGM',
    formaAplic: 'Formulario de aplicación',
    talhaoRelacionado: 'El campo no se puede eliminar porque contiene una auditoría'
  }
};
