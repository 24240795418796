export default {
  herbicida: {
    herbicidaRemovidoComSucesso: 'Herbicide successfully removed',
    falhaAoExcluiHerbicida: 'Failed to exclude herbicide',
    desejaExcluir: 'Do you want to delete the selected herbicide?',
    herbicida: 'herbicide',
    cadastroDeHerbicida: 'Herbicide registration',
    identificacaoDaHerbicida: 'Identification',
    herbicidaCadastradoComSucesso: 'Herbicide successfully registered',
    herbicidaEditadoComSucesso: 'Herbicide edited successfully',
    falhaAoEditarHerbicida: 'Failed to edit herbicide',
    falhaAoCadastrarHerbicida: 'Failed to register herbicide',
    falhaAoCarregarHerbicida: 'Failed to load herbicide'
  }
};
