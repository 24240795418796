import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '@components/DataTable/DataTable';
import CardTitle from '@components/CardTitle/CardTitle';
import formatMessage from './i18n/formatMessage';
import DadosSociais from '@components/ComplementaresProdutor/identificacao/DadosSociais';
import Certificacoes from '@components/ComplementaresProdutor/identificacao/Certificacoes';

const style = theme => ({
  cadastro: {
    width: '100%',
    display: 'flex',
    marginTop: '15px',
    height: 70
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  },
  font: {
    fontSize: 14,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  form: {
    width: '100%',
    bottom: 1
  },
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    width: 'fit-content',
    cursor: 'pointer'
  }
});

/**
 * Componente que representa os cards de identicação para perfil orgânico
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const IdentificacaoOrganico = props => {
  const {
    state,
    tpPerfil,
    onBlur,
    onAddEcosocial,
    onRemoveEcosocial,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    errors,
    classes,
    onChangeCheckParticipacao,
    onChangeCheckConflitos,
    listColaboradoresResponsaveis
  } = props;

  return (
    <>
      <Certificacoes
        itensEcosocial={state.itensEcosocial}
        ecosocialListValues={state.tipoEcosocialList}
        tpPerfil={tpPerfil}
        onBlur={onBlur}
        onAddEcosocial={onAddEcosocial}
        onRemoveEcosocial={onRemoveEcosocial}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        classes={classes}
      />

      <DadosSociais
        tpPerfil={tpPerfil}
        setFieldValue={setFieldValue}
        onChangeCheckParticipacao={onChangeCheckParticipacao}
        checkParticipacao={state.checkParticipacao}
        onChangeCheckConflitos={onChangeCheckConflitos}
        checkConflitos={state.checkConflitos}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
      />

      {listColaboradoresResponsaveis.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            minHeight: 325,
            marginBottom: 15
          }}
        >
          <CardTitle
            containerStyle={{ marginBottom: 0 }}
            title={formatMessage('produtores.colaboradoresResponsaveis')}
          />
          <DataTable
            key={state.keyColaborador}
            noSelection
            noMargin={true}
            data={listColaboradoresResponsaveis}
            columns={state.headColaboradores}
            showPagination={false}
          />
        </div>
      )}
    </>
  );
};

export default withStyles(style)(IdentificacaoOrganico);
