//Autor: Gabriela Farias

import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Marca from '@resources/Marcas';
import Switch from '@material-ui/core/Switch';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});

const style = () => ({
  parentDiv: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  topDiv: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  identif_Div: {
    flex: 1,
    position: 'relative',
    maxHeight: '180px',
    minHeight: '170px'
  },
  font: {
    fontSize: 14,
    width: '100%',
    height: 15,
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 34.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class EditarMarca extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const marcaCadastradaComSucesso = messagesLinguage['marcas.marcaCadastradaComSucesso'];
    const marcaEditadoComSucesso = messagesLinguage['marcas.marcaEditadoComSucesso'];
    const falhaAoEditarMarca = messagesLinguage['marcas.falhaAoEditarMarca'];
    const falhaAoCadastrarMarca = messagesLinguage['marcas.falhaAoCadastrarMarca'];
    const falhaAoCarregarMarca = messagesLinguage['marcas.falhaAoCarregarMarca'];
    const cadastroDeMarca = messagesLinguage['marcas.cadastroDeMarca'];

    const salvar = messagesLinguage['marcas.salvar'];
    const cancelar = messagesLinguage['marcas.cancelar'];
    const salvando = messagesLinguage['marcas.salvando'];
    const atualizando = messagesLinguage['marcas.atualizando'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          marcaCadastradaComSucesso,
          marcaEditadoComSucesso,
          falhaAoCadastrarMarca,
          falhaAoEditarMarca,
          falhaAoCarregarMarca,
          salvar,
          cancelar,
          salvando,
          atualizando,
          cadastroDeMarca
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      dataAppSync: [],
      loading: this.props.match.params.id !== undefined,
      stateEditar: false
    };

    const title = 'marcas.cadastroDeMarca';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.clickCadastrarEditar = this.clickCadastrarEditar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      Marca.findMarcaById(this.props.match.params.id)
        .then(query => {
          this.setState({
            stateEditar: true,
            dataAppSync: query.data,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal(provider.intl.formatMessage({ id: 'falhaAoCarregarMarca' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    }
  }

  clickCadastrarEditar(values, { resetForm }) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: provider.intl.formatMessage({
          id: this.props.match.params.id === undefined ? 'salvando' : 'atualizando'
        })
      }
    );

    if (!this.state.stateEditar) {
      const data = {
        idCliente: this.props.infoUsuario.idCliente,
        idPais: this.props.infoUsuario.idPais,
        nmMarca: values.nmMarca,
        stMarca: values.stMarca ? 1 : 0
      };

      Marca.addMarca(data)
        .then(data => {
          swal(provider.intl.formatMessage({ id: 'marcaCadastradaComSucesso' }), {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }).then(() => {
            this.clickCancelar();
          });
        })
        .catch(error => {
          swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarMarca' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    } else {
      const data = {
        idMarca: this.props.match.params.id,
        idCliente: this.props.infoUsuario.idCliente,
        idPais: this.props.infoUsuario.idPais,
        nmMarca: values.nmMarca,
        stMarca: values.stMarca ? 1 : 0
      };

      Marca.editMarca(data)
        .then(data => {
          swal(provider.intl.formatMessage({ id: 'marcaEditadoComSucesso' }), {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }).then(() => {
            this.clickCancelar();
          });
        })
        .catch(error => {
          swal(provider.intl.formatMessage({ id: 'falhaAoEditarMarca' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    }
  }

  clickCancelar() {
    this.props.history.push('/app/marcas');
  }

  render() {
    const { classes } = this.props;
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Formik
          initialValues={
            this.state.stateEditar
              ? {
                  nmMarca: this.state.dataAppSync.nmMarca,
                  stMarca: this.state.dataAppSync.stMarca === 1
                }
              : {
                  nmMarca: '',
                  stMarca: true
                }
          }
          onSubmit={this.clickCadastrarEditar}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            nmMarca: Yup.string().required(<FormattedMessage id='marca.campoObrigatorio' />)
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleChange
          }) => (
            <MuiThemeProvider theme={theme}>
              <form autoComplete='off' className={classes.parentDiv} onSubmit={handleSubmit}>
                <div className={classes.topDiv}>
                  {this.state.loading && <Loading />}
                  <div className={classes.identif_Div}>
                    <CardIcon titulo={<FormattedMessage id='marcas.identificacaoDaMarca' />}>
                      <Grid container spacing={16} style={{ marginTop: '15px' }}>
                        <Grid item xs={4} style={{ minHeight: '75px' }}>
                          <TextField
                            label={<FormattedMessage id='marcas.descricao' />}
                            id='nmMarca'
                            name='nmMarca'
                            value={values.nmMarca}
                            onChange={event => {
                              setFieldValue('nmMarca', event.target.value);
                            }}
                            onBlur={handleBlur}
                            error={errors.nmMarca && touched.nmMarca}
                            helperText={
                              errors.nmMarca && touched.nmMarca ? (
                                <FormattedMessage id='marcas.campoObrigatorio' />
                              ) : null
                            }
                            className={classes.textField}
                            style={{ width: '100%' }}
                            inputProps={{ className: classes.font }}
                            InputLabelProps={{ className: classes.font }}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <div
                            style={{
                              width: '150px',
                              marginTop: '10px',
                              marginLeft: 15
                            }}
                          >
                            <label style={{ color: '#000000' }}>
                              <FormattedMessage id='marcas.ativo' />
                            </label>
                            <Switch
                              color='primary'
                              checked={values.stMarca}
                              onChange={() => {
                                setFieldValue('stMarca', !values.stMarca);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </CardIcon>
                  </div>
                </div>
                <div className={classes.fab}>
                  <ButtonFABMenu
                    icon={iconMenuPontos}
                    actions={[
                      {
                        icon: <Check style={{ color: 'white' }} />,
                        name: provider.intl.formatMessage({ id: 'salvar' }),
                        onClickIcon: handleSubmit,
                        color: '#42ADE8'
                      },
                      {
                        icon: <Close style={{ color: 'white' }} />,
                        name: provider.intl.formatMessage({ id: 'cancelar' }),
                        onClickIcon: this.clickCancelar,
                        color: '#F33A30'
                      }
                    ]}
                    onClickButton={this.clickAdicionar}
                  />
                </div>
              </form>
            </MuiThemeProvider>
          )}
        />
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarMarca);
export default withRouter(connect(mapStateToProps)(enhaced));
