import URL from "./URL";

const axios = require("axios");
const api = URL.API;

function findAllCotacaosHandler() {
  return axios.get(api + "/cotacao");
}

function findCotacaoById(idCotacao) {
  return axios.get(api + "/cotacao/" + idCotacao);
}

function deleteCotacaoByIdHandler(idCotacao) {
  return axios.delete(api + "/cotacao/" + idCotacao);
}

function addCotacaoHandler(values) {
  return axios.post(api + "/cotacao", values);
}

function editCotacaoHandler(values) {
  return axios.put(api + "/cotacao", values);
}

function findCotacoesGraficoHandler() {
  return axios.get(api + "/cotacao/grafico");
}

export default {
  findAllCotacao: findAllCotacaosHandler,
  findCotacaoById: findCotacaoById,
  deleteCotacaoById: deleteCotacaoByIdHandler,
  addCotacao: addCotacaoHandler,
  editCotacao: editCotacaoHandler,
  findCotacoesGrafico: findCotacoesGraficoHandler
};
