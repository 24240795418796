/* Autor: Alisson Stopassole */

import React, { Component } from 'react';
import L from 'leaflet';
import { getUserLang } from '@utils/localeUtils';

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      map: null,
      listItensMapa: [],
      status: null,
      itensUniMedida: ['acre', 'alq.', 'ha', 'm2']
    };
  }

  markers = [];
  polygons = [];
  overlays = [];

  componentDidMount() {
    this.setState(
      {
        listItensMapa: this.props.listItensMapa
      },
      () => {
        if (this.state.map) {
          this.state.map.remove();
        }
        this.initMap(this.createMap());
      }
    );
  }

  //Valida as alteracoes para recriar ou redefinir os estados do mapa
  UNSAFE_componentWillReceiveProps(props) {
    if (this.state.listItensMapa !== props.listItensMapa) {
      this.setState({
        listItensMapa: props.listItensMapa,
        status: true
      });
    } else {
      this.setState({
        status: false
      });
    }
    if (this.state.status !== (this.state.listItensMapa !== props.listItensMapa)) {
      this.initMap(null);
    }

    if (this.props.imgOverlay !== undefined) {
      if (this.state.map) {
        this.addOVerlay(this.state.map);
      }
    }
  }

  // Cria o mapa
  createMap() {
    var center = [-25.744472026704535, -53.046541213989265];
    var zoom = 5;
    var mapLink = '<a href="http://www.esri.com/">Esri</a>';
    var wholink = `Source: ${mapLink}, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community`;
    var map = L.map(document.getElementById('google-map'), {
      center: center,
      zoom: zoom,
      zoomControl: false,
      layers: [
        L.tileLayer(
          'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: wholink,
            maxZoom: 17
          }
        )
      ]
    });
    L.control
      .zoom({
        position: 'bottomright'
      })
      .addTo(map);

    return map;
  }

  //Inicia os elementos do mapa
  initMap(map) {
    if (map === null) {
      map = this.state.map;
    }
    this.polygons.forEach(p => {
      map.removeLayer(p);
    });
    this.markers.forEach(m => {
      map.removeLayer(m);
    });
    this.overlays.forEach(o => {
      map.removeLayer(o);
    });

    if (map) {
      map.setZoom(5);
    }

    var bounds = new window.google.maps.LatLngBounds();
    var listAll = [];
    this.state.listItensMapa.forEach((doc, i) => {
      var latlngs = [];

      doc.talhaoGpsList.forEach(talhao => {
        var item = {
          lat: parseFloat(talhao.nrLatitude),
          lng: parseFloat(talhao.nrLongitude)
        };
        var latlong = [parseFloat(talhao.nrLatitude), parseFloat(talhao.nrLongitude)];

        latlngs.push(latlong);
        listAll.push(latlong);
        bounds.extend(item);
      });

      var valores = {
        lat: 0,
        lng: 0
      };

      var cont = 0;

      doc.talhaoGpsList.forEach(marker => {
        valores = {
          lat: valores.lat + marker.nrLatitude,
          lng: valores.lng + marker.nrLongitude
        };
        cont = cont + 1;
      });

      var media = null;
      if (valores.lat !== 0 && cont !== 0 && valores.lng !== 0) {
        media = [valores.lat / cont, valores.lng / cont];
      }

      if (this.props.data && this.props.data.imagens && this.props.imgOverlay.tipo) {
        this.addOVerlay(map);
      }

      var p1 = L.polygon(latlngs).addTo(map);
      p1.setStyle({
        fillColor: 'transparent',
        color: doc.color
      });

      this.polygons.push(p1);

      var stringText =
        '<p><b>' +
        doc.produtor +
        '<p><b>' +
        doc.areaCultivo +
        '<p><b>' +
        doc.talhao +
        '</b></p> ' +
        '<p> ' +
        'Área' +
        ': ' +
        Intl.NumberFormat(getUserLang()).format(doc.areaTalhao) +
        ' Ha </p>';

      if (media) {
        var m = L.marker(media)
          .addTo(map)
          .bindPopup(stringText)
          .closePopup();

        m.key = i;

        this.markers.push(m);
      } else {
        if (map) {
          map.setView([-25.744472026704535, -53.046541213989265]);
          map.setZoom(5);
        }
      }

      this.setState({
        map: map
      });
    });

    if (this.state.listItensMapa.length > 0 && listAll.length) {
      map.fitBounds(listAll);
    } else {
      if (map) {
        map.setView([-25.744472026704535, -53.046541213989265]);
        map.setZoom(5);
      }
    }
  }

  //Adiciona o overlay das imagens no mapa
  addOVerlay(map) {
    if (this.props.data && this.props.data.imagens && this.props.imgOverlay.tipo) {
      var overlays = [];

      this.overlays.forEach(o => {
        map.removeLayer(o);
      });

      this.state.listItensMapa.forEach((doc, i) => {
        var latlngs = [];

        doc.talhaoGpsList.forEach(talhao => {
          var latlong = [parseFloat(talhao.nrLatitude), parseFloat(talhao.nrLongitude)];
          latlngs.push(latlong);
        });

        this.props.data.imagens.forEach(imagens => {
          imagens.forEach(img => {
            if (img.tipo === this.props.imgOverlay.tipo) {
              if (doc.idTalhao === Number(img.img.split('-')[1])) {
                overlays.push({ posicao: latlngs, img: img.img });
              }
            }
          });
        });
      });

      overlays.forEach(ov => {
        map.createPane('imagePane');
        map.getPane('imagePane').style.zIndex = 300;
        if (ov.posicao.length > 0) {
          var o = L.imageOverlay(ov.img, ov.posicao, {
            pane: 'imagePane'
          }).addTo(map);

          this.overlays.push(o);
        }
      });
    }
  }

  render() {
    return (
      <div
        style={{
          marginBottom: 15,
          height: '100%',
          width: '100%',
          zIndex: -1
        }}
      >
        <div
          id='google-map'
          style={{
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            zIndex: 1
          }}
        />
      </div>
    );
  }
}

export default Map;
