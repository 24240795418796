/*
    Autor: Gabriela Farias
*/

import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowDown from '@material-ui/icons/ArrowDownwardRounded';
import swal from '@sweetalert/with-react';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { getUserLang } from '@utils/localeUtils';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import classNames from 'classnames';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const styles = {
  iconDeleteFile: {
    position: 'relative',
    color: '#F33A30',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: 200
  },
  fileName: {
    color: '#42ADE8',
    fontSize: 14,
    fontFamily: 'Roboto',
    cursor: 'pointer'
  },
  nameResponsive: qtdLines => ({
    textAlign: 'center',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: qtdLines,
    WebkitBoxOrient: 'vertical',
    width: 190
  }),
  cardDragDrop: {
    width: 200,
    height: 150,
    marginTop: '15px !important',
    marginBottom: 0,
    margin: 15,
    border: '1px dashed #707070',
    borderRadius: 10,
    color: '#707070',
    fontFamily: 'Roboto'
  },
  cardFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: 200,
    height: 150,
    marginTop: -55
  },
  inputFile: {
    display: 'none',
    width: 0,
    height: 0
  },
  textUploadFile: {
    textAlign: 'center',
    opacity: 0.87,
    color: '#707070',
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  containerCardFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: 'calc(100vh - 450px)',
    overflowY: 'auto',
    paddingTop: 15
  },
  textField: {
    height: 150,
    width: 200,
    marginTop: 0,
    margin: 15
  },
  cardTextImage: {
    height: 200,
    width: 230
  }
};

const CardFile = props => {
  const { classes, file } = props;

  const { dsFoto } = file;

  return (
    <div className={classes.cardDragDrop} style={{ height: 113, marginBottom: 10, overflow: 'hidden' }}>
      <div style={styles.iconDeleteFile}>
        <IconButton aria-label='Excluir' color='inherit' onClick={() => props.onDeleteFile(file)}>
          <DeleteIcon />
        </IconButton>
      </div>

      <div className={classes.cardFlex}>
        <img
          id={dsFoto}
          alt={dsFoto}
          style={{
            height: 125,
            width: 110,
            objectFit: 'contain',
            overflow: 'hidden',
            cursor: file.stRegistro === 1 ? 'pointer' : 'default'
          }}
          onClick={() => {
            if (file.stRegistro === 1) {
              window.open(file.dsFoto);
            }
          }}
        />
      </div>
    </div>
  );
};

CardFile.propTypes = {
  classes: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  onDeleteFile: PropTypes.func.isRequired
};

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    if (file.size <= 20000000) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    } else {
      reject();
    }
  });
}

class UploadFiles extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const removeArquivo = messagesLinguage['uploadFiles.removeArquivo'];
    const nao = messagesLinguage['uploadFiles.nao'];
    const excluir = messagesLinguage['uploadFiles.excluir'];
    const erroSubirArquivo = messagesLinguage['uploadFiles.erroSubirArquivo'];
    const anexarArquivo = messagesLinguage['uploadFiles.anexarArquivo'];
    const arquivoMaximo = messagesLinguage['uploadFiles.arquivoMaximo'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          removeArquivo,
          nao,
          excluir,
          erroSubirArquivo,
          anexarArquivo,
          arquivoMaximo
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.inputFilesRef = null;

    this.state = {
      imagensAnexoList: []
    };

    this.onAddFiles = this.onAddFiles.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.createCanvas = this.createCanvas.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        imagensAnexoList: this.props.allFotos
      },
      () => {
        this.props.allFotos.forEach(base => {
          this.createCanvas(base.dsBase64 ? base.dsBase64 : base.dsFoto, base.dsFoto);
        });
      }
    );
  }

  createCanvas(dsBase, nmFoto) {
    const img = document.getElementById(nmFoto);
    if (!img) return;
    img.setAttribute('src', dsBase);
  }

  removeFile(i) {
    swal({
      title: provider.intl.formatMessage({ id: 'removeArquivo' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        const auxFiles = this.state.imagensAnexoList;
        var position = auxFiles.indexOf(i);

        const valueDelete = { ...auxFiles[position] };

        if (valueDelete.stRegistro === 1) {
          this.props.filesDelete(valueDelete);
        }

        auxFiles.splice(position, 1);
        this.setState({ imagensAnexoList: auxFiles });

        swal.close();
      }
    });
  }

  async onAddFiles(e, files) {
    if (files.length === 0) return;

    e.preventDefault();

    if (!this.props.accept && files[0].type !== 'image/jpeg' && files[0].type !== 'image/png') return;

    const auxFiles = this.props.hiddenCardAnexo ? this.props.allFotos : this.state.imagensAnexoList;
    let allValid = true;
    const listNewImagens = [];

    const promises = Array.from(files).map(async file => {
      return await fileToBase64(file)
        .then(dsBase64 => {
          auxFiles.push({
            dsThumb: file.name,
            dsFotoBase64: dsBase64.split(';base64,')[1],
            dsFoto: file.name,
            dsBase64: dsBase64,
            dsAtividadeFoto: null,
            stRegistro: 0
          });

          listNewImagens.push({
            dsBase64: dsBase64,
            dsFoto: file.name
          });
        })
        .catch(err => {
          allValid = false;
        });
    });

    await Promise.all(promises);

    if (allValid) {
      this.props.saveListFoto(auxFiles);
    } else {
      swal({
        title: provider.intl.formatMessage({ id: 'erroSubirArquivo' }),
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          confirm: {
            value: true,
            visible: true,
            closeModal: false,
            className: 'swal2-confirm'
          }
        }
      }).then(willDelete => {
        if (willDelete) {
          swal.close();
        }
      });
    }

    this.setState({ imagensAnexoList: auxFiles }, () => {
      listNewImagens.forEach(base => {
        this.createCanvas(base.dsBase64, base.dsFoto);
      });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classes.containerCardFlex}
        onDrop={e => !this.props.disabled && this.onAddFiles(e, e.dataTransfer.files)}
        onDragOver={e => e.preventDefault()}
      >
        <div
          className={
            !this.props.hiddenCardAnexo ? classNames(classes.cardDragDrop, classes.cardFlex) : null
          }
          style={{ cursor: 'pointer' }}
          onClick={() => !this.props.disabled && this.inputFilesRef.click()}
        >
          <input
            accept={this.props.accept || '.png, .jpg, .jpeg'}
            className={classes.inputFile}
            multiple={!this.props.disableMultiple}
            type='file'
            ref={ref => (this.inputFilesRef = ref)}
            onChange={e => !this.props.disabled && this.onAddFiles(e, e.target.files)}
          />
          {this.props.hiddenCardAnexo && this.props.children}
          {!this.props.hiddenCardAnexo && (
            <>
              <div style={{ fontSize: 65, height: 65, fontWeight: 'bold' }}>
                <ArrowDown color='inherit' fontSize='inherit' fontWeight='bold' />
              </div>
              <Typography variant='caption' className={classes.textUploadFile}>
                {provider.intl.formatMessage({ id: 'anexarArquivo' })}
                <br />
                {provider.intl.formatMessage({ id: 'arquivoMaximo' })}
              </Typography>
            </>
          )}
        </div>

        {!this.props.hiddenCardAnexo &&
          this.state.imagensAnexoList.map((file, i) => (
            <div key={file.dsFoto} className={classes.cardTextImage}>
              <CardFile classes={classes} file={file} key={file.dsFoto} onDeleteFile={this.removeFile} />

              <TextField
                value={file.dsAtividadeFoto || ''}
                placeholder={'Descrição da imagem'}
                className={classes.textField}
                onBlur={e => {
                  file.dsAtividadeFoto = e.target.value;
                }}
                onChange={e => {
                  file.dsAtividadeFoto = e.target.value;

                  this.setState({
                    imagensAnexoList: this.state.imagensAnexoList
                  });
                }}
              />
            </div>
          ))}
      </div>
    );
  }
}

export default withStyles(styles)(UploadFiles);
