import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import PDFButtonFab from '@components/PDFButtonFab/PDFButtonFab';
import { withStyles } from '@material-ui/core';
import PDFPageNavigator from '@components/PDFPageNavigator/PDFPageNavigator';
import { Document, Page, pdfjs } from 'react-pdf';
import { Element, scroller } from 'react-scroll';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {
  card: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 15px)'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  page: {
    marginBottom: 15
  },
  document: {
    width: '100%'
  },
  divDocument: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
};

/**
 * Componente para visualização de PDF
 *
 * @author Gabriela Farias
 * @class PDFViewer
 * @extends {Component} - Componente React
 */
class PDFViewer extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const ocorreuErro = messagesLinguage['pdfViewer.ocorreuErro'];
    const carregando = messagesLinguage['pdfViewer.carregando'];

    const intlProvider = new IntlProvider({
      locale: getUserLang(),
      messages: {
        ocorreuErro,
        carregando
      }
    });

    provider = intlProvider.getChildContext();

    this.state = {
      allPages: 0,
      pageNumber: 0,
      height: 0.8,
      width: null
    };

    this.pdfWrapper = 0;

    this.scrollToWithContainer = this.scrollToWithContainer.bind(this);
  }

  scrollToWithContainer(index) {
    scroller.scrollTo('index_' + (index - 1), {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: 'scroll-container'
    });
  }

  render() {
    const { classes } = this.props;
    const { pageNumber, allPages } = this.state;

    return (
      <div
        style={{
          width: '100%',
          display: 'contents',
          flexDirection: 'column'
        }}
      >
        {this.props.filePDF && (
          <PDFPageNavigator
            height={this.state.height}
            pageNumber={pageNumber}
            allPages={allPages}
            changeZoom={zoom => {
              this.setState({ height: zoom });
            }}
            clickFirst={() => {
              this.scrollToWithContainer(1);
            }}
            clickLast={() => {
              this.scrollToWithContainer(allPages);
            }}
            clickBack={() => {
              this.scrollToWithContainer(pageNumber - 1);
            }}
            clickNext={() => {
              this.scrollToWithContainer(pageNumber + 1);
            }}
          />
        )}

        <div
          style={{
            marginTop: 15,
            display: 'flex',
            overflowY: 'hidden',
            overflowX: 'hidden',
            justifyContent: 'center',
            height: '100%'
          }}
          ref={ref => {
            this.pdfWrapper = ref;
          }}
        >
          <Element
            onScroll={e => {
              var qtScroll = document.getElementById('scroll-container');

              if (allPages > 0) {
                var heightPage = (e.currentTarget.scrollHeight - 15) / allPages;
                if (qtScroll.scrollTop > heightPage * pageNumber) {
                  this.setState({ pageNumber: pageNumber + 1 });
                } else if (qtScroll.scrollTop < heightPage * (pageNumber - 1) && pageNumber > 1) {
                  this.setState({ pageNumber: pageNumber - 1 });
                }
              }
            }}
            className='element'
            id='scroll-container'
            style={{
              justifyContent: 'center',
              width: '100%',
              position: this.props.withoutPosition ? '' : 'relative',
              height: '100%',
              overflowY: 'scroll',
              overflowX: 'hidden',
              marginBottom: '10px'
            }}
          >
            <Document
              className={classes.document}
              file={this.props.filePDF}
              noData=''
              error={provider.intl.formatMessage({ id: 'ocorreuErro' })}
              loading=''
              onLoadSuccess={item => {
                this.props.loadSucess();

                this.setState({
                  height: 0.8,
                  allPages: item.numPages,
                  pageNumber: 1,
                  width: this.pdfWrapper.getBoundingClientRect().width
                });
              }}
            >
              {Array.from(new Array(allPages), (el, index) => (
                <div className={classes.divDocument} key={`page_${index + 1}`} id='pdfWrapper'>
                  <Element name={'index_' + index} key={`page_${index + 1}`}>
                    <Page
                      width={this.state.width}
                      scale={this.state.height}
                      renderAnnotationLayer={false}
                      className={classes.page}
                      key={`page_${index + 1}`}
                      pageIndex={index}
                    />
                  </Element>
                </div>
              ))}
            </Document>
          </Element>
        </div>
        <PDFButtonFab
          additionalActions={this.props.additionalActions}
          fileXLS={this.props.fileXLS}
          filePDF={this.props.filePDF}
          fileCSV={this.props.fileCSV}
          disabled={this.props.filePDF === null || this.props.errorCard}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PDFViewer);
