export default {
  listDetalhes: {
    produtosProibidos: 'Produtos proibídos',
    areaDeCultivo: 'Área de cultivo',
    detentor: 'Detentor',
    cultura: 'Cultura',
    cultivarUtilizadas: 'Cultivar utilizado',
    talhoesAdicionado: 'Talhões utilizados',
    areaTanque: 'Área por tanque',
    nrQuantidade: 'Quantidade',
    dsUnMedida: 'Un. de medida',
    dsRecomendacaoAdicional: 'Recomendação adicional',
    produtos: 'Produtos',
    tecnicoResponsavel: 'Técnico responsável',
    produtividade: 'Produtividade',
    espacamento: 'Espaçamento',
    semeadura: 'Semeadura',
    invasoras: 'Invasoras',
    alertasFitossanitarios: 'Alertas fitossanitários',
    nao: 'Não',
    sim: 'Sim',
    talhoes: 'Talhões',
    observacao: 'Observação',
    amostras: 'Amostras',
    fitossanidadesEncontradas: 'Pragas encontradas',
    quantidadeAmostras: 'Quantidade de amostras',
    standFinal: 'Stand final',
    diasEstimados: 'Dias estimados',
    dataPrevista: 'Data prevista',
    produtividadeEstimada: 'Produtividade estimada',
    previsaoColheita: 'Previsão de colheita',
    colheita: 'Colheita',
    previsto: 'Previsto',
    imagens: 'Imagens',
    descricaoRegistroClimatico: 'Descrição do registro climático',
    tipoAuditoria: 'Tipo de auditoria',
    externa: 'Externa',
    interna: 'Interna',
    situacao: 'Situação',
    emAndamento: 'Em andamento',
    finalizadaSemNaoConformidade: 'Finalizada sem não conformidade',
    finalizadaComNaoConformidade: 'Finalizada com não conformidade',
    suspensa: 'Suspensa',
    naoConformidades: 'Não conformidades',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    sancao: 'Sanção',
    limiteComprimento: 'Limite para cumprimento'
  }
};
