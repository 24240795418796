export default {
  relatorio: {
    relatorio: 'Relatório',
    analitico: 'Analítico',
    regiao: 'Região',
    municipio: 'Município',
    sim: 'Sim',
    nao: 'Não',
    carregando: 'Carregando...',
    gerandoRelatorio: 'Gerando relatório...',
    falhaAoGerarRelatorio: 'Falha ao gerar relatório',
    dataLancamento: 'Data de lançamento',
    tecnicoResponsavel: 'Técnico responsável',
    produtor: 'Produtor'
  }
};
