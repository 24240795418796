/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import iconAdd from '@images/icone_add.png';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import swal from '@sweetalert/with-react';
import ClientesHandler from '@resources/Clientes';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  }
});

const styles = {};

/*
    Tela de Listagem de Clientes customizada
*/
class Clientes extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const razaoSocial = messagesLinguage['clientes.razaoSocial'];
    const nomeFantasia = messagesLinguage['clientes.nomeFantasia'];
    const cnpj = messagesLinguage['clientes.cnpj'];
    const telefone = messagesLinguage['clientes.telefone'];
    const desejaExcluir = messagesLinguage['clientes.desejaExcluir'];
    const excluir = messagesLinguage['clientes.excluir'];
    const nao = messagesLinguage['clientes.nao'];
    const excluindo = messagesLinguage['clientes.excluindo'];
    const clienteExcluidoComSucesso = messagesLinguage['clientes.clienteExcluidoComSucesso'];
    const falhaAoExcluirCliente = messagesLinguage['clientes.falhaAoExcluirCliente'];
    const plano = messagesLinguage['clientes.plano'];
    const erroBuscarCliente = messagesLinguage['clientes.erroBuscarCliente'];

    const intlProvider = new IntlProvider({
      locale: getUserLang(),
      messages: {
        razaoSocial,
        nomeFantasia,
        cnpj,
        telefone,
        desejaExcluir,
        excluir,
        nao,
        clienteExcluidoComSucesso,
        falhaAoExcluirCliente,
        excluindo,
        plano,
        erroBuscarCliente
      }
    });

    provider = intlProvider.getChildContext();

    this.state = {
      operationTypeString: [
        {
          label: provider.intl.formatMessage({ id: 'razaoSocial' }),
          field: 'dsRazaoSocial',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'nomeFantasia' }),
          field: 'nmCliente',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'cnpj' }),
          field: 'nrDocumento',
          type: 'string'
        }
      ],
      disabledEdit: true,
      itemSelect: '',
      dataAppSync: [],
      dataInTable: [],
      loading: true,
      head: [
        {
          field: 'dsRazaoSocial',
          headerName: provider.intl.formatMessage({ id: 'razaoSocial' }),
          sort: 'asc',
          col: 3
        },
        {
          field: 'nmCliente',
          headerName: provider.intl.formatMessage({ id: 'nomeFantasia' }),
          col: 3
        },
        {
          field: 'nrDocumento',
          headerName: provider.intl.formatMessage({ id: 'cnpj' }),
          col: 2
        },
        {
          field: 'nrTelefone',
          headerName: provider.intl.formatMessage({ id: 'telefone' }),
          col: 2
        }
      ]
    };

    const title = 'clientes.clientes';
    if (this.props.itensState.name !== 'clientes.clientes') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.onFilter = this.onFilter.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
  }
  componentWillUnmount() {
    this.isCancelled = true;
  }
  componentDidMount() {
    this.onFilter(this.props.chipData);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    ClientesHandler.findCliente()
      .then(response => {
        !this.isCancelled &&
          this.setState({
            dataAppSync: response.data,
            dataInTable: FilterUtil.applyFilters(response.data, this.props.chipData),
            loading: false
          });
      })
      .catch(err => {
        swal(provider.intl.formatMessage({ id: 'erroBuscarCliente' }), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  clickAdicionar() {
    this.props.history.push('/app/clientes/novo');
  }

  clickEditar() {
    this.props.history.push('/app/clientes/editar/' + this.state.itemSelect.idCliente);
  }

  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            title: provider.intl.formatMessage({ id: 'excluindo' })
          }
        );
        ClientesHandler.deleteCliente(this.state.itemSelect.idCliente)
          .then(query => {
            swal(provider.intl.formatMessage({ id: 'clienteExcluidoComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              var data = this.state.dataInTable;

              const position = data.indexOf(this.state.itemSelect);
              data.splice(position, 1);
              data = [...data];
              this.setState({
                dataInTable: data
              });
            });
          })
          .catch(query => {
            swal(provider.intl.formatMessage({ id: 'falhaAoExcluirCliente' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    });
  }

  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'scroll' }}>
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationTypeString}
            usePaddings={false}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            viewKey='clientes'
          />
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disabledEdit}
            onClickEdit={this.clickEditar}
            onClickDelete={this.clickExcluir}
          />

          <div
            style={{
              marginTop: 30,
              marginBottom: 15,
              display: 'contents',
              width: '100%',
              flex: 1
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={this.clickAdicionar} />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'clientes';
  })[0].content
});

Clientes.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(Clientes));
export default withRouter(connect(mapStateToProps)(enhaced));
