//@author Katia Miglioli
import React from "react";
import Card from "@material-ui/core/Card";
import green from "@material-ui/core/colors/green";
import PropTypes from "prop-types";
import iconEmail from "@images/icon_email.png";
import iconSenha from "@images/icon_senha.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "../TextFieldOwn/TextFieldOwn";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import "@styles/Login.css";
import { Formik } from "formik";
import * as Yup from "yup";
import Botao from "@components/Botao/Botao";
import { flattenMessages } from "@utils/languageUtils";
import { getUserLang } from "../../utils/localeUtils";
import ptBR from "../../views/Login/i18n/ptBR";
import enUS from "../../views/Login/i18n/enUS";
import esES from "../../views/Login/i18n/esES";
import { IntlProvider } from "react-intl";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import swal from "@sweetalert/with-react";
import Authentication from "@resources/oauth/Authentication";
import Admin from "@resources/oauth/Admin";

let messages = { "pt-BR": ptBR, "en-US": enUS, "es-ES": esES };

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const falhaLogin = messagesLinguage["telaLogin.falhaLogin"];
const usuarioIncorreto = messagesLinguage["telaLogin.usuarioIncorreto"];
const falhaLoginMessage = messagesLinguage["telaLogin.falhaLoginMessage"];
const falhaRecuperarSenha = messagesLinguage["telaLogin.falhaRecuperarSenha"];
const usuarioNaoEncontrado = messagesLinguage["telaLogin.usuarioNaoEncontrado"];
const emailReenviado = messagesLinguage["telaLogin.emailReenviado"];
const falhaRecuperarSenhaMensagem =
  messagesLinguage["telaLogin.falhaRecuperarSenhaMensagem"];
const emailEnviado = messagesLinguage["telaLogin.emailEnviado"];
const emailEnviadoMessage = messagesLinguage["telaLogin.emailEnviadoMessage"];
const falhaUsuarioInativo = messagesLinguage["telaLogin.falhaUsuarioInativo"];
const falhaClienteInativo = messagesLinguage["telaLogin.falhaClienteInativo"];
const usuarioNaoConfirmado = messagesLinguage["telaLogin.usuarioNaoConfirmado"];

const intlProvider = new IntlProvider(
  {
    locale: getUserLang(),
    messages: {
      falhaLogin,
      usuarioIncorreto,
      falhaLoginMessage,
      falhaRecuperarSenha,
      usuarioNaoEncontrado,
      emailReenviado,
      falhaRecuperarSenhaMensagem,
      emailEnviado,
      emailEnviadoMessage,
      falhaClienteInativo,
      falhaUsuarioInativo,
      usuarioNaoConfirmado
    }
  },
  {}
);

const { intl } = intlProvider.getChildContext();

const styles = theme => ({
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    transition: " height 220ms"
  },
  inputEmail: {
    width: 320,
    height: 70
  },
  divInputEmail: {
    position: "relative",
    left: 20,
    top: 150
  },
  divInputSenha: {
    position: "relative",
    left: 20,
    top: 160
  },
  divLembraEsqueceuSenha: {
    display: "flex",
    width: 360,
    height: 70
  },
  esqueceuSenha: {
    marginRight: "5.5%",
    fontSize: 14,
    color: "#26B767",
    position: "relative",
    top: 177,
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  divButton: {
    position: "center",
    marginTop: 150,
    marginLeft: 110
  },
  containerCxDialogo: {
    width: 300,
    position: "relative"
  },
  buttonDialog: {
    color: "green"
  },
  divBotao: {
    margin: theme.spacing.unit,
    position: "relative"
  },
  cxDialogo: {
    position: "absolute",
    display: "flex",
    justifyContent: "center"
  },
  loginSenha: {
    textDecoration: "none",
    color: "#26B767",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    position: "relative",
    flexDirection: "row-reverse"
  }
});
const theme = createMuiTheme({
  palette: {
    primary: green,
    type: "light"
  },
  typography: {
    useNextVariants: true
  }
});

class Login extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleVoltarLogin = this.handleVoltarLogin.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleRecuperarSenha = this.handleRecuperarSenha.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBotaoProcesso = this.handleBotaoProcesso.bind(this);

    this.state = {
      estaLogando: false,
      showPassword: false,
      password: "",
      FormaLogin: false,
      mensagemDialog: "",
      loading: false,
      success: false,
      tituloDialogMensagem: ""
    };
  }

  handleLoginClick() {
    this.setState(prev => ({
      FormaLogin: !prev.FormaLogin,
      estaLogando: false
    }));
  }
  handleVoltarLogin() {
    this.setState(prev => ({
      FormaLogin: !prev.FormaLogin,
      estaLogando: true
    }));
  }
  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }));
  }

  handleBotaoProcesso() {
    this.setState(state => ({
      loading: !state.loading,
      success: !state.success
    }));
  }

  handleSubmit(values) {
    this.handleBotaoProcesso();

    var button = {
      confirm: {
        text: "Ok",
        value: true,
        visible: true,
        closeModal: true,
        className: "swal2-error"
      }
    };

    Authentication.login(values.email, values.password)
      .then(() => {
        Admin.findPerfil()
          .then(result => {
            this.handleBotaoProcesso();
            getUserLang(true);
            this.props.history.push("/app");
          })
          .catch(err => {
            if (
              err.response !== undefined &&
              err.response.data.codigo === "CLIENTE_INATIVO"
            ) {
              swal(
                intl.formatMessage({ id: "falhaLogin" }),
                intl.formatMessage({ id: "falhaClienteInativo" }),
                {
                  icon: "error",
                  buttons: button
                }
              );
            } else {
              swal(
                intl.formatMessage({ id: "falhaLogin" }),
                intl.formatMessage({ id: "falhaLoginMessage" }),
                {
                  icon: "error",
                  buttons: button
                }
              );
            }
            this.handleBotaoProcesso();
          });
      })
      .catch(err => {
        if (err.response !== undefined && err.response.status === 401) {
          swal(
            intl.formatMessage({ id: "falhaLogin" }),
            intl.formatMessage({ id: "falhaUsuarioInativo" }),
            {
              icon: "error",
              buttons: button
            }
          );
        } else if (err.response !== undefined && err.response.status === 400) {
          swal(
            intl.formatMessage({ id: "falhaLogin" }),
            intl.formatMessage({ id: "usuarioIncorreto" }),
            {
              icon: "error",
              buttons: button
            }
          );
        } else {
          swal(
            intl.formatMessage({ id: "falhaLogin" }),
            intl.formatMessage({ id: "falhaLoginMessage" }),
            {
              icon: "error",
              buttons: button
            }
          );
        }
        this.handleBotaoProcesso();
      });
  }

  handleRecuperarSenha(values) {
    this.handleBotaoProcesso();

    var buttonError = {
      confirm: {
        text: "Ok",
        value: true,
        visible: true,
        closeModal: true,
        className: "swal2-error"
      }
    };
    var buttonSucess = {
      confirm: {
        text: "Ok",
        value: true,
        visible: true,
        closeModal: true,
        className: "swal2-Ok"
      }
    };

    Authentication.recuperarSenha(values.email)
      .then(() => {
        var aux =
          intl.formatMessage({ id: "emailEnviadoMessage" }) +
          " " +
          values.email;
        swal(aux, intl.formatMessage({ id: "emailEnviado" }), {
          icon: "success",
          buttons: buttonSucess
        });
        this.handleBotaoProcesso();
      })
      .catch(err => {
        if (
          err.response !== undefined &&
          err.response.data.codigo === "USUARIO_NAO_ENCONTRADO"
        ) {
          swal(
            intl.formatMessage({ id: "falhaRecuperarSenha" }),
            intl.formatMessage({ id: "usuarioNaoEncontrado" }),
            {
              icon: "error",
              buttons: buttonError
            }
          );
        } else {
          swal(
            intl.formatMessage({ id: "falhaRecuperarSenha" }),
            intl.formatMessage({ id: "falhaRecuperarSenhaMensagem" }),
            {
              icon: "error",
              buttons: buttonError
            }
          );
        }
        this.handleBotaoProcesso();
      });
  }
  handleKeyDown = function(e, handleSubmit) {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit();
    }
  };
  render() {
    const { classes } = this.props;

    const styles = { height: this.state.FormaLogin ? "360px" : "432px" };

    const estaLogando = this.state.estaLogando;

    const { loading } = this.state;

    return (
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={
          this.state.FormaLogin ? this.handleRecuperarSenha : this.handleSubmit
        }
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(<FormattedMessage id="telaLogin.emailInvalido" />)
            .required(<FormattedMessage id="telaLogin.campoObrigatorio" />),
          password: this.state.FormaLogin
            ? Yup.string()
            : Yup.string()
                .min(6, <FormattedMessage id="telaLogin.senhaMinimo" />)
                .required(<FormattedMessage id="telaLogin.campoObrigatorio" />)
        })}
        render={({
          values,
          handleSubmit,
          errors,
          touched,
          handleChange,
          handleBlur
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            onKeyDown={e => {
              this.handleKeyDown(e, handleSubmit);
            }}
          >
            <div>
              <Card className={classes.card} style={styles}>
                <div className={classes.divInputEmail}>
                  <MuiThemeProvider theme={theme}>
                    <TextField
                      className={classes.inputEmail}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email}
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                      label="E-mail *"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              src={iconEmail}
                              alt="icone que representa email"
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </MuiThemeProvider>
                </div>
                <div className={classes.divInputSenha}>
                  <div
                    id="navbar"
                    className={this.state.FormaLogin ? "slideIn" : "slideOut"}
                  >
                    <MuiThemeProvider theme={theme}>
                      <TextField
                        className={classes.inputEmail}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.password && touched.password}
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : null
                        }
                        value={values.password}
                        label={<FormattedMessage id="telaLogin.senha" />}
                        id="adornment-password"
                        type={this.state.showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={iconSenha}
                                alt="icone que representa senha"
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </MuiThemeProvider>
                  </div>
                </div>
                <div className={classes.divLembraEsqueceuSenha}>
                  <div className={classes.esqueceuSenha}>
                    {estaLogando ? (
                      <label
                        className={classes.loginSenha}
                        onClick={this.handleLoginClick}
                      >
                        {" "}
                        <FormattedMessage id="telaLogin.retornarLogin" />
                      </label>
                    ) : (
                      <label
                        className={classes.loginSenha}
                        onClick={this.handleVoltarLogin}
                      >
                        <FormattedMessage id="telaLogin.esqueceuSenha" />
                      </label>
                    )}
                  </div>
                </div>
                <div className={classes.divButton}>
                  <Botao
                    text={
                      this.state.FormaLogin ? (
                        <FormattedMessage id="telaLogin.enviar" />
                      ) : (
                        <FormattedMessage id="telaLogin.login" />
                      )
                    }
                    onClickButton={handleSubmit}
                    loadingButton={loading}
                  />
                </div>
              </Card>
            </div>
          </form>
        )}
      />
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Login));
