import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllEspecCultivarsHandler() {
  return axios.get(api + '/cultivar-espec');
}

export default {
  findAllEspecCultivar: findAllEspecCultivarsHandler
};
