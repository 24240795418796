export default {
  certificacao: {
    descricao: 'Descrição',
    dataCadastro: 'Data de cadastro',
    erroAoBuscarDados: 'Erro ao buscar dados',
    certificacaoOrganica: 'Certificações orgânicas',
    desejaExcluirCertificacao: 'Deseja excluir esta certificação',
    nao: 'Não',
    excluir: 'Excluir',
    certificacaoExcluida: 'Certificação excluída com sucesso',
    erroAoExcluir: 'Ocorreu um erro ao excluir a certificação',
    cadastroDeCertificacao: 'Cadastro de certificação orgânica',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    certificacaoSalvoComSucesso: 'Certificação salva com sucesso',
    erroAoSalvar: 'Erro ao salvar certificação',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    produtorRelacionado: 'Não foi possível excluír esta certificação pois existe um produtor usando ela',
    status: 'Situação',
    ativo: 'Ativo',
    inativo: 'Inativo'
  }
};
