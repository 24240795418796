import React, { useState, useEffect } from 'react';
import formatMessage from './../i18n/formatMessage';
import { connect } from 'react-redux';
import CardIcon from '@components/CardIcon/CardIcon';
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './../Auditoria.styles';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import { CreateNewFolderOutlined } from '@material-ui/icons';
import Chip from '@components/Chip/Chip';

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    if (file.size <= 20000000) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    } else {
      reject();
    }
  });
}

/**
 * Grid de cadastro de auditories
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const EditarObservacao = props => {
  const {
    classes,
    usuarioList,
    loadUsuarioList,
    addObservacaoInList,
    clickToCancel,
    valueToEdit,
    historicoAuditoriaList,
    converterName,
    uploadImage,
    dtPlantio
  } = props;
  const [usuario, setUsuario] = useState(null);
  const [keyAuditorioAutoComplete, setKeyAuditorioAutoComplete] = useState(0);
  const [imagensAnexoList, setImagensAnexoList] = useState([]);
  const [initialValues, setInitialValues] = useState({
    usuario: null,
    stHistoricoAuditoria: 1,
    dhHistoricoAuditoria: null,
    dsHistoricoAuditoria: ''
  });
  let inputFilesRef = null;

  useEffect(() => {
    if (valueToEdit) {
      const initialValuesAux = { ...valueToEdit };
      setImagensAnexoList(JSON.parse(JSON.stringify(initialValuesAux.anexoAuditoriaList || [])));
      setInitialValues(initialValuesAux);
      setUsuario(initialValuesAux.usuario);
      setKeyAuditorioAutoComplete(Math.random());
    }
  }, []);

  function tratarValorAuditor() {
    if (!usuario) {
      return '';
    }

    return usuario;
  }

  function swalWarning(title, buttonOk) {
    return swal({
      title: formatMessage(title),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        confirm: {
          text: formatMessage(buttonOk),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-cancel'
        }
      }
    });
  }

  function submit(values, { resetForm }) {
    if (values.stHistoricoAuditoria === 1) {
      const historico = historicoAuditoriaList.find(
        historicoAux =>
          historicoAux.stHistoricoAuditoria === 1 &&
          historicoAux.idHistoricoAuditoria !== values.idHistoricoAuditoria
      );

      if (historico) {
        swalWarning('auditoria.statusFalseCadastrado', 'auditoria.ok').then(res => {
          swal.close();
        });
        return;
      }
    }

    values.anexoAuditoriaList = imagensAnexoList;
    values.dhHistoricoAuditoria = moment(values.dhHistoricoAuditoria).format('YYYY-MM-DDTHH:mm:ssZZ');
    addObservacaoInList(values);
    resetForm({
      usuario: null,
      stHistoricoAuditoria: 1,
      dhHistoricoAuditoria: null,
      dsHistoricoAuditoria: ''
    });
    setUsuario(null);
    setKeyAuditorioAutoComplete(Math.random());
  }

  function helperTextValidator(campoAux, touched, errors) {
    if (touched[campoAux] && errors[campoAux]) {
      return formatMessage('auditoria.campoObrigatorio');
    } else {
      return '';
    }
  }

  async function onAddFiles(e, files) {
    if (files.length === 0) return;

    e.preventDefault();

    const auxFiles = [...imagensAnexoList];
    let allValid = true;

    const promises = Array.from(files).map(async file => {
      return fileToBase64(file)
        .then(dsBase64 => {
          auxFiles.push({
            dsAnexoBase64: dsBase64.split(';base64,')[1],
            dsAnexo: file.name,
            stRegistro: 0
          });
        })
        .catch(err => {
          allValid = false;
        });
    });

    await Promise.all(promises);

    if (allValid) {
      setImagensAnexoList(auxFiles);
    } else {
      swal({
        title: formatMessage('auditoria.erroSubirArquivo'),
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          confirm: {
            value: true,
            visible: true,
            closeModal: false,
            className: 'swal2-confirm'
          }
        }
      }).then(willDelete => {
        if (willDelete) {
          swal.close();
        }
      });
    }
  }

  function deleteAnexoAuditoria(anexoAuditoria) {
    const imagensAnexoListAux = imagensAnexoList;
    const position = imagensAnexoListAux.indexOf(anexoAuditoria);
    if (anexoAuditoria.stRegistro === 0) {
      imagensAnexoListAux.splice(position, 1);
    } else {
      imagensAnexoListAux[position].stRegistro = 2;
    }

    setImagensAnexoList([...imagensAnexoListAux]);
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        usuario: Yup.object()
          .nullable()
          .required(formatMessage('auditoria.campoObrigatorio')),
        stHistoricoAuditoria: Yup.number().required(formatMessage('auditoria.campoObrigatorio')),
        dhHistoricoAuditoria: Yup.date()
          .nullable()
          .required(formatMessage('auditoria.campoObrigatorio')),
        dsHistoricoAuditoria: Yup.string().required(formatMessage('auditoria.campoObrigatorio'))
      })}
      onSubmit={submit}
      render={({ values, handleSubmit, errors, touched, setFieldValue, handleBlur, handleChange }) => (
        <div style={{ width: '100%' }}>
          <MuiThemeProvider theme={theme}>
            <CardIcon titulo={formatMessage('auditoria.observacoes')}>
              <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                <Grid item xs={6}>
                  <Autocomplete
                    itens={usuarioList}
                    campoOp='nmUsuario'
                    campoChave='idUsuario'
                    label={formatMessage('auditoria.nomeAuditor')}
                    name='usuario'
                    error={!usuario && touched.usuario}
                    helperText={
                      touched.usuario && !usuario ? formatMessage('auditoria.campoObrigatorio') : null
                    }
                    onChangeOwn={value => {
                      setFieldValue('usuario', value);
                      setUsuario(value);
                    }}
                    onChangeAutoComplete={loadUsuarioList}
                    value={tratarValorAuditor()}
                    valueAutoComplete={tratarValorAuditor()}
                    valueSelect={tratarValorAuditor().nmUsuario}
                    maxHeight='calc(100vh - 250px)'
                    key={keyAuditorioAutoComplete}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    error={errors.stHistoricoAuditoria && touched.stHistoricoAuditoria}
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      htmlFor='stHistoricoAuditoria'
                      error={errors.stHistoricoAuditoria && touched.stHistoricoAuditoria}
                    >
                      {formatMessage('auditoria.situacao')}
                    </InputLabel>

                    <Select
                      value={values.stHistoricoAuditoria}
                      error={errors.stHistoricoAuditoria && touched.stHistoricoAuditoria}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='stHistoricoAuditoria'
                      id='stHistoricoAuditoria'
                    >
                      {[formatMessage('auditoria.aberto'), formatMessage('auditoria.fechado')].map(
                        (item, i) => (
                          <MenuItem key={i} value={i}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    </Select>

                    {errors.stHistoricoAuditoria && touched.stHistoricoAuditoria && (
                      <FormHelperText>{errors.stHistoricoAuditoria}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <DatePicker
                    minDate={dtPlantio}
                    locale={getUserLang()}
                    style={{ height: 70 }}
                    className={classes.font}
                    label={formatMessage('auditoria.dataDeObservacao')}
                    valueDate={values.dhHistoricoAuditoria}
                    errorTouched={errors.dhHistoricoAuditoria && touched.dhHistoricoAuditoria}
                    helperText={helperTextValidator('dhHistoricoAuditoria', touched, errors)}
                    onChangeDatePicker={value => {
                      setFieldValue('dhHistoricoAuditoria', value);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                <Grid item xs={12}>
                  <TextField
                    label={formatMessage('auditoria.observacao')}
                    name='dsHistoricoAuditoria'
                    inputProps={{ maxLength: 60 }}
                    value={values.dsHistoricoAuditoria}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.dsHistoricoAuditoria && touched.dsHistoricoAuditoria}
                    helperText={
                      errors.dsHistoricoAuditoria && touched.dsHistoricoAuditoria
                        ? errors.dsHistoricoAuditoria
                        : null
                    }
                  />
                </Grid>
              </Grid>

              <div className={classes.cardFolder}>
                <div
                  className={classes.cardUploadClick}
                  onDrop={e => onAddFiles(e, e.dataTransfer.files)}
                  onDragOver={e => e.preventDefault()}
                  onClick={() => inputFilesRef.click()}
                >
                  <input
                    className={classes.inputFile}
                    multiple
                    type='file'
                    ref={ref => (inputFilesRef = ref)}
                    onChange={e => onAddFiles(e, e.target.files)}
                  />
                  <CreateNewFolderOutlined className={classes.iconFolder} />
                </div>
                <div className={classes.cardChips}>
                  {imagensAnexoList
                    .filter(item => item.stRegistro !== 2)
                    .map((anexoAuditoria, i) => (
                      <div key={anexoAuditoria.idAnexoAuditoria} style={{ cursor: 'pointer' }}>
                        <Chip
                          onClickLabel={() =>
                            uploadImage(anexoAuditoria.dsAnexo, converterName(anexoAuditoria.dsAnexo))
                          }
                          key={i}
                          text={converterName(anexoAuditoria.dsAnexo)}
                          onRemove={() => deleteAnexoAuditoria(anexoAuditoria)}
                        />
                      </div>
                    ))}
                </div>
              </div>

              <Grid container>
                <Grid item xs className={classes.containerButtons}>
                  <div style={{ color: '#463766' }}>
                    <Button variant='contained' color='inherit' onClick={clickToCancel}>
                      {formatMessage('auditoria.cancelar')}
                    </Button>
                  </div>

                  <div>
                    <Button color='secondary' variant='contained' onClick={handleSubmit}>
                      {formatMessage('auditoria.salvar')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardIcon>
          </MuiThemeProvider>
        </div>
      )}
    />
  );
};

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(EditarObservacao);
