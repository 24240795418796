import React, { Fragment } from 'react';
import { MuiPickersUtilsProvider, InlineTimePicker } from 'material-ui-pickers';
import { getUserLang } from '@utils/localeUtils';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AccessTime from '@material-ui/icons/AccessTime';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInputAdornment: {
            positionEnd: {
                marginRight: '-12px'
            },
        },
        MuiFormControl: {
            root: {
                width: '100%'
            }
        },
        MuiInput: {
            input: {
                fontSize: 14,
                fontFamily: "'Roboto', sans-serif",
                width: '100%',
                writeSpace: 'noWrap',
                textOverflow: 'ellipsis'
            },
            underline: {
                '&:hover:not($disabled):not($error):not($focused):before': {
                    borderBottom: '2px solid green',
                },
            },
        }
    },
    palette: {
        primary: {
            light: '#00622B',
            main: '#00622B',
            dark: '#00622B',
            contrastText: '#fff',
        }
    },
});

function TimePicker(props) {

    return (
        <Fragment>
            <MuiThemeProvider theme={theme} >
                <MuiPickersUtilsProvider utils={MomentUtils} locale={getUserLang()}>
                    <div className='picker' style={{ width: '100%' }} >
                        <InlineTimePicker label={props.label}
                            keyboard
                            ampm={getUserLang() === 'en-Us' ? true : false}
                            value={props.value}
                            error={props.errorTouched}
                            helperText={props.errorTouched ? props.helperText : null}
                            keyboardIcon={<AccessTime />}
                            onBlur={props.onBlur}
                            disabled={props.disabled}
                            onChange={props.onChange} />
                    </div>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </Fragment>
    );
}

export default (TimePicker);