//  Autor: Kátia miglioli
//MODO DE USAR: Vide OnFilter em Planos

const filterUtil = {
  applyFilters(dataTable, chips) {
    var tempData = dataTable;
    if (!(chips.length === undefined)) {
      for (var i = 0; i < chips.length; i++) {
        tempData = this.filtering(tempData, chips[i]);
      }
    }
    return tempData;
  },
  filtering(dataTable, firstChip) {
    return dataTable.filter(data => {
      if (firstChip.filter.type === 'number') {
        return this.returnResultOperationNumber(data[firstChip.filter.field], firstChip);
      }
      if (firstChip.filter.type === 'enum') {
        return (
          data[firstChip.filter.field].toString().toUpperCase() === firstChip.value.value.toUpperCase()
        );
      }
      if (firstChip.filter.type === 'date') {
        return this.compareDates(data[firstChip.filter.field], firstChip);
      }

      if (firstChip.filter.type === 'autocomplete') {
        return (
          data[firstChip.filter.field][firstChip.filter.id]
            .toString()
            .toUpperCase()
            .match(firstChip.value.value.toString().toUpperCase()) !== null
        );
      }

      return (
        data[firstChip.filter.field]
          .toString()
          .toUpperCase()
          .match(firstChip.value.toUpperCase()) !== null
      );
    });
  },

  compareDates(register, chip) {
    var dataInRegister = new Date(chip.value).valueOf();
    var dataInChip = new Date(register).valueOf();

    switch (chip.operation) {
      case '==':
        return dataInRegister - dataInChip === 0;
      case '>=':
        return dataInChip >= dataInRegister;
      case '>':
        return dataInChip > dataInRegister;
      case '<=':
        return dataInChip <= dataInRegister;
      case '<':
        return dataInChip < dataInRegister;
      case '!=':
        return dataInChip !== dataInRegister;
      default:
        return false;
    }
  },
  returnResultOperationNumber(data, chip) {
    switch (chip.operation) {
      case '==':
        return Number(data) === Number(chip.value);
      case '>=':
        return Number(data) >= Number(chip.value);
      case '>':
        return Number(data) > Number(chip.value);
      case '<=':
        return Number(data) <= Number(chip.value);
      case '<':
        return Number(data) < Number(chip.value);
      case '!=':
        return Number(data) !== Number(chip.value);
      default:
        return false;
    }
  }
};
export default filterUtil;
