import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import formatMessage from './../i18n/formatMessage';
import Navigation from '@components/Navigation/Navigation';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import moment from 'moment';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './../Auditoria.styles';
import CardIcon from '@components/CardIcon/CardIcon';
import Button from '@material-ui/core/Button';
import MedidaCorretiva from './MedidaCorretiva';
import Talhao from './Talhao';
import Observacao from '../Observacao/Observacao';

/**
 * Screen de cadastro de auditoria não conformidade
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarNaoConformidade(props) {
  const {
    classes,
    naoConformidadeList,
    loadNaoConformidade,
    clickToCancel,
    clickSaveAuditoriaNaoConformidade,
    valuesToEdit,
    talhaoListAll,
    usuarioList,
    loadUsuarioList,
    dtPlantio,
    clickAgendar,
    isTecnico
  } = props;
  let setFieldValueAux = null;
  const [talhaoList, setTalhaoList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [naoConformidade, setNaoConformidade] = useState(null);
  const [sancaoCategoria, setSancaoCategoria] = useState(null);
  const [tipoCertificacao, setTipoCertificacao] = useState(null);
  const [keyAutoComplete, setKeyAutoComplete] = useState(0);
  const [sancaoList, setSancaoList] = useState([]);
  const [certificacaoList, setCertificacaoList] = useState([]);
  const [medidaCorretivaList, setMedidaCorretivaList] = useState([]);
  const [talhaoNaoConformidadeList, setTalhaoNaoConformidadeList] = useState([]);
  const [historicoAuditoriaList, setHistoricoAuditoriaList] = useState([]);
  const [dtComprimento, setDtComprimento] = useState(null);
  const [initialValues, setInitialValues] = useState({
    naoConformidade: null,
    sancaoCategoria: null,
    tipoCertificacao: null,
    dhResolucaoMedidaCorretiva: null,
    talhaoNaoConformidadeList: [],
    historicoAuditoriaList: [],
    naoConformidadeSancao: {}
  });
  const [itensTabs, setItensTabs] = useState([
    {
      name: formatMessage('auditoria.identificacao'),
      key: 0,
      select: true
    },
    {
      name: formatMessage('auditoria.observacoes'),
      key: 1,
      select: false
    }
  ]);

  useEffect(() => {
    const historico = historicoAuditoriaList.find(
      historicoAux => historicoAux.stHistoricoAuditoria === 1
    );

    if (historico) {
      setDtComprimento(historico.dhHistoricoAuditoria);
    } else {
      setDtComprimento(null);
    }
  }, [historicoAuditoriaList]);

  useEffect(() => {
    if (valuesToEdit) {
      const talhaoNaoConformidadeListAux = [];
      talhaoListAll.forEach(item => {
        const exists = valuesToEdit.talhaoNaoConformidadeList.find(
          talhao =>
            talhao.plantioTalhao.talhao.idTalhao === item.talhao.idTalhao && talhao.stRegistro !== 2
        );

        if (!exists) {
          talhaoNaoConformidadeListAux.push(item);
        }
      });

      setTalhaoList([...talhaoNaoConformidadeListAux]);
      setTalhaoNaoConformidadeList(JSON.parse(JSON.stringify(valuesToEdit.talhaoNaoConformidadeList)));
      setHistoricoAuditoriaList(valuesToEdit.historicoAuditoriaList);
      setNaoConformidade(valuesToEdit.naoConformidade);
      setSancaoCategoria(valuesToEdit.sancaoCategoria);
      loadCertificacao(
        {
          tipoCertificacao: valuesToEdit.tipoCertificacao,
          sancaoCategoria: valuesToEdit.sancaoCategoria
        },
        valuesToEdit.naoConformidade
      );

      if (!valuesToEdit.tipoCertificacao) {
        const tipoCertificacaoGeral = {
          idNaoConformidadeSancao: valuesToEdit.naoConformidadeSancao.idNaoConformidadeSancao,
          idTipoCertificacao: -99,
          nmTipoCertificacao: formatMessage('auditoria.geral')
        };
        setTipoCertificacao(tipoCertificacaoGeral);
        valuesToEdit.tipoCertificacao = tipoCertificacaoGeral;
      } else {
        setTipoCertificacao(valuesToEdit.tipoCertificacao);
      }

      setInitialValues(valuesToEdit);
      setMedidaCorretivaList(valuesToEdit.naoConformidadeSancao.sancaoMedidaCorretivaList);
      setKeyAutoComplete(Math.random());
      loadSancao(valuesToEdit.naoConformidade);
    } else {
      setTalhaoList([...talhaoListAll]);
    }
  }, [valuesToEdit]);

  function onChangeNaoConformidade(value) {
    setNaoConformidade(value);
    setSancaoCategoria(null);
    setTipoCertificacao(null);
    setMedidaCorretivaList([]);
    setKeyAutoComplete(Math.random());
    loadSancao(value);
  }

  function onChangeSancao(value) {
    setSancaoCategoria(value);
    setTipoCertificacao(null);
    setMedidaCorretivaList([]);
    setKeyAutoComplete(Math.random());
    loadCertificacao(value);
  }

  function onChangeCertificacao(value, naoConformidadeAux) {
    setTipoCertificacao(value);
    setKeyAutoComplete(Math.random());

    const naoConformidadeSancaoList = !naoConformidadeAux
      ? naoConformidade.naoConformidadeSancaoList
      : naoConformidadeAux.naoConformidadeSancaoList;

    const naoConformidadeSancao = naoConformidadeSancaoList.find(
      naoConformidadeSancaoAux =>
        naoConformidadeSancaoAux.idNaoConformidadeSancao === value.idNaoConformidadeSancao
    );

    setMedidaCorretivaList(naoConformidadeSancao.sancaoMedidaCorretivaList);
  }

  function loadSancao(value) {
    const sancaoListAux = [];
    value.naoConformidadeSancaoList.forEach(naoConformidadeSancao => {
      naoConformidadeSancao.nmSancaoCategoria = naoConformidadeSancao.sancaoCategoria.nmSancaoCategoria;
      naoConformidadeSancao.idSancaoCategoria = naoConformidadeSancao.sancaoCategoria.idSancaoCategoria;

      const sancaoExists = sancaoListAux.find(
        naoConformidadeSancaoAux =>
          naoConformidadeSancao.sancaoCategoria.idSancaoCategoria ===
          naoConformidadeSancaoAux.sancaoCategoria.idSancaoCategoria
      );

      if (!sancaoExists) sancaoListAux.push(naoConformidadeSancao);
    });

    setSancaoList(sancaoListAux);

    if (sancaoListAux.length === 1) {
      setFieldValueAux('sancaoCategoria', sancaoListAux[0]);
      setSancaoCategoria(sancaoListAux[0]);
      setKeyAutoComplete(Math.random());
      loadCertificacao(sancaoListAux[0], value);
    }
  }

  function loadCertificacao(value, naoConformidadeAux) {
    const certificacaoListAux = [];
    const naoConformidadeSancaoList = !naoConformidadeAux
      ? naoConformidade.naoConformidadeSancaoList
      : naoConformidadeAux.naoConformidadeSancaoList;

    naoConformidadeSancaoList.forEach(naoConformidadeSancao => {
      if (!value.tipoCertificacao && !naoConformidadeSancao.tipoCertificacao) {
        certificacaoListAux.push({
          idNaoConformidadeSancao: naoConformidadeSancao.idNaoConformidadeSancao,
          idTipoCertificacao: -99,
          nmTipoCertificacao: formatMessage('auditoria.geral')
        });
      } else {
        if (!value.tipoCertificacao || !naoConformidadeSancao.tipoCertificacao) return;

        if (
          value.sancaoCategoria.idSancaoCategoria ===
          naoConformidadeSancao.sancaoCategoria.idSancaoCategoria
        ) {
          certificacaoListAux.push({
            idNaoConformidadeSancao: naoConformidadeSancao.idNaoConformidadeSancao,
            ...naoConformidadeSancao.tipoCertificacao
          });
        }
      }
    });

    setCertificacaoList(certificacaoListAux);

    if (certificacaoListAux.length === 1) {
      setFieldValueAux('tipoCertificacao', certificacaoListAux[0]);
      onChangeCertificacao(certificacaoListAux[0], naoConformidadeAux);
    }
  }

  function tratarValorNaoConformidade() {
    if (!naoConformidade || naoConformidade.nmNaoConformidade === undefined) {
      return '';
    }

    return naoConformidade;
  }

  function tratarValorSancao() {
    if (!sancaoCategoria || sancaoCategoria.nmSancaoCategoria === undefined) {
      return '';
    }

    return sancaoCategoria;
  }

  function tratarValorCertificacao() {
    if (!tipoCertificacao || tipoCertificacao.nmTipoCertificacao === undefined) {
      return '';
    }

    return tipoCertificacao;
  }

  function handleChangeIndex(index) {
    const itensTabAux = [...itensTabs];
    itensTabAux.forEach(doc => {
      if (index === doc.key) {
        doc.select = true;
      } else {
        doc.select = false;
      }
    });

    setTabIndex(index);
    setItensTabs(itensTabAux);
  }

  function helperTextValidator(campoAux, touched, value) {
    if (touched[campoAux] && !value) {
      return formatMessage('auditoria.campoObrigatorio');
    } else {
      return '';
    }
  }

  function clickSubmit(values) {
    clickSaveAuditoriaNaoConformidade(mountSaveValues(values));
  }

  function mountSaveValues(values) {
    const idNaoConformidadeSancao = values.idAuditoriaPlantioNaoConformidade
      ? values.naoConformidadeSancao.idNaoConformidadeSancao
      : values.tipoCertificacao.idNaoConformidadeSancao;
    const naoConformidadeSancao = naoConformidade.naoConformidadeSancaoList.find(
      naoConformidadeSancaoAux =>
        naoConformidadeSancaoAux.idNaoConformidadeSancao === idNaoConformidadeSancao
    );

    return {
      ...values,
      historicoAuditoriaList: historicoAuditoriaList,
      talhaoNaoConformidadeList: talhaoNaoConformidadeList,
      naoConformidadeSancao: naoConformidadeSancao,
      dhResolucaoMedidaCorretiva: values.dhResolucaoMedidaCorretiva
        ? moment(values.dhResolucaoMedidaCorretiva).format('YYYY-MM-DDTHH:mm:ssZZ')
        : null
    };
  }

  function clickAgenda(values) {
    clickAgendar(
      medidaCorretivaList.map(item => item.medidaCorretiva.nmMedidaCorretiva).toString(),
      mountSaveValues(values)
    );
  }

  function deleteTalhaoInList(talhaoNaoConformidade) {
    const talhaoNaoConformidadeListAux = [...talhaoNaoConformidadeList];

    const talhaoAux = talhaoNaoConformidadeListAux.find(
      item => item.plantioTalhao.talhao.idTalhao === talhaoNaoConformidade.plantioTalhao.idTalhao
    );
    const position = talhaoNaoConformidadeListAux.indexOf(talhaoAux);

    if (talhaoNaoConformidade.stRegistro === 0) {
      talhaoNaoConformidadeListAux.splice(position, 1);
    } else {
      talhaoNaoConformidade.stRegistro = 2;
      talhaoNaoConformidadeListAux[position] = talhaoNaoConformidade;
    }

    talhaoList.push({
      idTalhao: talhaoNaoConformidade.plantioTalhao.talhao.idTalhao,
      dsTalhao: talhaoNaoConformidade.plantioTalhao.talhao.dsTalhao,
      ...talhaoNaoConformidade.plantioTalhao
    });
    setTalhaoNaoConformidadeList(talhaoNaoConformidadeListAux);
  }

  function addTalhaoInList(talhao) {
    const talhaoNaoConformidadeListAux = [...talhaoNaoConformidadeList];
    const exists = talhao.stRegistro === 2;

    if (!exists) {
      talhaoNaoConformidadeListAux.push({
        stRegistro: 0,
        plantioTalhao: talhao
      });
    }

    const talhaoAux = talhaoList.find(item => item.idTalhao === talhao.idTalhao);
    const position = talhaoList.indexOf(talhaoAux);

    talhaoList.splice(position, 1);
    setTalhaoNaoConformidadeList(talhaoNaoConformidadeListAux);
  }

  function changeHistoricoAuditoriaList(newHistoricoAuditoriaList) {
    setHistoricoAuditoriaList([...newHistoricoAuditoriaList]);
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        naoConformidade: Yup.object()
          .nullable()
          .required(formatMessage('auditoria.campoObrigatorio')),
        sancaoCategoria: Yup.object()
          .nullable()
          .required(formatMessage('auditoria.campoObrigatorio')),
        tipoCertificacao: Yup.object()
          .nullable()
          .required(formatMessage('auditoria.campoObrigatorio')),
        dhResolucaoMedidaCorretiva: Yup.string()
          .nullable()
          .required(formatMessage('auditoria.campoObrigatorio'))
      })}
      onSubmit={clickSubmit}
      render={({ values, handleSubmit, errors, touched, handleBlur, handleChange, setFieldValue }) => {
        setFieldValueAux = setFieldValue;
        return (
          <div style={{ height: 'calc(100vh - 95px)' }}>
            <Navigation index={tabIndex} handleChangeIndex={handleChangeIndex} buttons={itensTabs}>
              {[
                tabIndex === 0 ? (
                  <div key={0} className={classes.cardScroll}>
                    <MuiThemeProvider theme={theme}>
                      <div style={{ width: '100%' }}>
                        <CardIcon titulo={formatMessage('auditoria.naoConformidade')}>
                          <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                            <Grid item xs={12} style={{ paddingRight: 15 }}>
                              <Autocomplete
                                disabled={isTecnico}
                                itens={naoConformidadeList}
                                campoOp='nmNaoConformidade'
                                campoChave='idNaoConformidade'
                                label={formatMessage('auditoria.naoConformidade')}
                                name='naoConformidade'
                                error={!naoConformidade && touched.naoConformidade}
                                helperText={helperTextValidator(
                                  'naoConformidade',
                                  touched,
                                  naoConformidade
                                )}
                                onChangeOwn={value => {
                                  setFieldValue('naoConformidade', value);
                                  setFieldValue('sancaoCategoria', null);
                                  setFieldValue('tipoCertificacao', null);
                                  onChangeNaoConformidade(value);
                                }}
                                onChangeAutoComplete={loadNaoConformidade}
                                value={tratarValorNaoConformidade()}
                                key={keyAutoComplete}
                                valueAutoComplete={tratarValorNaoConformidade()}
                                valueSelect={tratarValorNaoConformidade().nmNaoConformidade}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                            <Grid item xs={6} style={{ paddingRight: 15, height: 70 }}>
                              <Autocomplete
                                itens={sancaoList}
                                campoOp='nmSancaoCategoria'
                                campoChave='idSancaoCategoria'
                                label={formatMessage('auditoria.sancao')}
                                name='sancaoCategoria'
                                error={!sancaoCategoria && touched.sancaoCategoria}
                                helperText={helperTextValidator(
                                  'sancaoCategoria',
                                  touched,
                                  sancaoCategoria
                                )}
                                onChangeOwn={value => {
                                  setFieldValue('sancaoCategoria', value);
                                  setFieldValue('tipoCertificacao', null);
                                  onChangeSancao(value);
                                }}
                                onChangeAutoComplete={loadSancao}
                                value={tratarValorSancao()}
                                key={keyAutoComplete}
                                valueAutoComplete={tratarValorSancao()}
                                valueSelect={tratarValorSancao().nmSancaoCategoria}
                                disabled={!naoConformidade || sancaoList.length === 1 || isTecnico}
                              />
                            </Grid>

                            <Grid item xs={6} style={{ paddingRight: 15, height: 70 }}>
                              <Autocomplete
                                itens={certificacaoList}
                                campoOp='nmTipoCertificacao'
                                campoChave='idTipoCertificacao'
                                label={formatMessage('auditoria.certificacao')}
                                name='tipoCertificacao'
                                error={!tipoCertificacao && touched.tipoCertificacao}
                                helperText={helperTextValidator(
                                  'tipoCertificacao',
                                  touched,
                                  tipoCertificacao
                                )}
                                onChangeOwn={value => {
                                  setFieldValue('tipoCertificacao', value);
                                  onChangeCertificacao(value);
                                }}
                                onChangeAutoComplete={loadCertificacao}
                                value={tratarValorCertificacao()}
                                key={keyAutoComplete}
                                valueAutoComplete={tratarValorCertificacao()}
                                valueSelect={tratarValorCertificacao().nmTipoCertificacao}
                                disabled={!sancaoCategoria || certificacaoList.length === 1 || isTecnico}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                            <Grid item xs={3}>
                              <DatePicker
                                disabled={isTecnico}
                                minDate={dtPlantio}
                                locale={getUserLang()}
                                style={{ height: 70 }}
                                className={classes.font}
                                label={formatMessage('auditoria.limiteCumprimento')}
                                valueDate={values.dhResolucaoMedidaCorretiva}
                                errorTouched={
                                  errors.dhResolucaoMedidaCorretiva && touched.dhResolucaoMedidaCorretiva
                                }
                                helperText={helperTextValidator(
                                  'dhResolucaoMedidaCorretiva',
                                  touched,
                                  values.dhResolucaoMedidaCorretiva
                                )}
                                onChangeDatePicker={value => {
                                  setFieldValue('dhResolucaoMedidaCorretiva', value);
                                }}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DatePicker
                                disabled={true}
                                locale={getUserLang()}
                                style={{ height: 70 }}
                                className={classes.font}
                                label={formatMessage('auditoria.dataComprimento')}
                                valueDate={dtComprimento}
                              />
                            </Grid>
                          </Grid>

                            <Talhao
                              isTecnico={isTecnico}
                              classes={classes}
                              talhaoList={talhaoList}
                              loadTalhaoList={() => talhaoList}
                              addTalhaoInList={addTalhaoInList}
                              talhaoNaoConformidadeList={talhaoNaoConformidadeList}
                              deleteTalhaoPlantio={deleteTalhaoInList}
                            />

                          {isTecnico && <div style={{height: 40}} />}

                          {!isTecnico && <Grid container>
                            <Grid item xs className={classes.containerButtons}>
                              <div style={{ color: '#463766' }}>
                                <Button variant='contained' color='inherit' onClick={clickToCancel}>
                                  {formatMessage('auditoria.cancelar')}
                                </Button>
                              </div>

                              <div>
                                <Button color='secondary' variant='contained' onClick={handleSubmit}>
                                  {formatMessage('auditoria.salvar')}
                                </Button>
                              </div>
                            </Grid>
                          </Grid>}
                        </CardIcon>

                        <div style={{ width: '100%', marginTop: 30 }}>
                          <CardIcon titulo={formatMessage('auditoria.medidasCorretivas')}>
                            {medidaCorretivaList.length > 0 && (
                              <>
                                <div style={{ height: 30 }} />
                                {medidaCorretivaList.map(sancaoMedidaCorretiva => (
                                  <div
                                    key={sancaoMedidaCorretiva.idSancaoMedidaCorretiva}
                                    className={classes.cardEdit}
                                  >
                                    <MedidaCorretiva sancaoMedidaCorretiva={sancaoMedidaCorretiva} />
                                  </div>
                                ))}

                               {!isTecnico && <div className={classes.cardAgendar}>
                                  <div className={classes.labelAgendar}>
                                    {formatMessage('auditoria.desejaAgendarMedidaCorretiva')}
                                  </div>
                                  <div
                                    className={classes.buttonAgendar}
                                    onClick={() => clickAgenda(values)}
                                  >
                                    {formatMessage('auditoria.agendar').toUpperCase()}
                                  </div>
                                </div>}
                              </>
                            )}
                          </CardIcon>
                        </div>
                      </div>
                    </MuiThemeProvider>
                  </div>
                ) : (
                  <div key={0} />
                ),
                tabIndex === 1 ? (
                  <div key={1} style={{ width: '100%' }}>
                    <Observacao
                      isTecnico={isTecnico}
                      dtPlantio={dtPlantio}
                      classes={classes}
                      usuarioList={usuarioList}
                      loadUsuarioList={loadUsuarioList}
                      historicoAuditoriaList={historicoAuditoriaList}
                      changeHistoricoAuditoriaList={changeHistoricoAuditoriaList}
                    />
                  </div>
                ) : (
                  <div key={1} />
                )
              ]}
            </Navigation>
          </div>
        );
      }}
    />
  );
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(EditarNaoConformidade);
