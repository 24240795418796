export default {
  regiao: {
    regiao: 'Región',
    regioes: 'Regiones',
    desejaExcluir: '¿Desea eliminar la región?',
    excluir: 'Eliminar',
    nao: 'No',
    excluindo: 'Excluyendo',
    regiaoRemovidoComSucesso: 'Región excluida con éxito',
    falhaAoExcluirRegiao: 'Error al eliminar la región',
    cadastroDeRegiao: 'Registro de región',
    falhaAoCarregarRegiao: 'Error al cargar la región',
    salvando: 'Guardar',
    atualizando: 'Actualización',
    regiaoCadastradaComSucesso: 'Región registrada con éxito',
    falhaAoCadastrarRegiao: 'Error al registrar la región',
    regiaoEditadaComSucesso: 'Región editada con éxito',
    falhaAoEditarRegiao: 'Error al editar la región',
    campoObrigatorio: 'campo obligatorio',
    identificacao: 'Identificación',
    descricao: 'Descripción',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    municipio: 'Municipio',
    municipioRemovidoComSucesso: 'Municipio eliminado con éxito',
    estado: 'Estado',
    confirmar: 'Confirmar',
    municipioJaCadastrado: 'El municipio ya está registrado',
    municipiosJáRelacinados: 'Los siguientes municipios ya están listados en otras regiones'
  }
};
