import React from 'react';
import Map from '@components/Map/Map';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import formatMessage from './../i18n/formatMessage';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const style = theme => ({
  cadastro: {
    marginTop: '15px'
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  }
});

/**
 * Componente de tap de mapa em talhões
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {Object} props - props
 * @returns Componente React
 */
function MapaTalhao(props) {
  const {
    itemSelect,
    label,
    dataTalhoes,
    navigatorPosition,
    talhaoGpsList,
    onClickMap,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    infoUsuario,
    changeStateItemSelect,
    changeMap,
    classes
  } = props;
  const stOrganica = infoUsuario.stOrganica === 1;
  const itensUniMedida = [
    { id: 0, name: formatMessage('produtores.acre') },
    { id: 1, name: formatMessage('produtores.alqueire') },
    { id: 2, name: formatMessage('produtores.hectare') },
    { id: 3, name: formatMessage('produtores.m2') }
  ];

  return (
    <>
      <Grid container spacing={16}>
        <Grid item xs={3} style={{ marginTop: stOrganica ? 0 : -1 }}>
          <TextFieldWithMask
            disabled={itemSelect.talhaoGpsList && itemSelect.talhaoGpsList.length > 0}
            tipoMascara={3}
            numberDecimalScale={3}
            showThousandSeparator={true}
            id='nrAreaTalhao'
            name='nrAreaTalhao'
            onChange={value => {
              const item = itemSelect;
              if (item.talhaoGpsList.length === 0) {
                item.nrAreaTalhao = value.target.value;
              }

              changeStateItemSelect(item);

              setFieldValue('nrAreaTalhao', parseFloat(value.target.value));
            }}
            value={values.nrAreaTalhao}
            label={formatMessage('produtores.areaCultivo')}
            className={classes.textField}
            error={errors.nrAreaTalhao && touched.nrAreaTalhao}
            helperText={touched.nrAreaTalhao ? errors.nrAreaTalhao : ''}
          />
        </Grid>

        <Grid item xs={3} style={{ marginTop: -1 }}>
          <FormControl className={classes.form} error={errors.tpUnidade && touched.tpUnidade}>
            <InputLabel htmlFor='tpUnidade-helper' error={errors.tpUnidade && touched.tpUnidade}>
              {formatMessage('produtores.dsUnMedida')}
            </InputLabel>
            <Select
              disabled={talhaoGpsList.length > 0}
              style={{
                marginLeft: 0,
                fontSize: 14,
                width: '100%',
                position: 'relative'
              }}
              SelectDisplayProps={{
                style: {
                  width: '100%',
                  backgroundColor: 'transparent'
                }
              }}
              value={
                talhaoGpsList.length > 0 && values.tpUnidade !== 3
                  ? itensUniMedida[2].id
                  : values.tpUnidade
              }
              onChange={event => {
                setFieldValue('tpUnidade', event.target.value);
                var item = itemSelect;
                item.tpUnidade = event.target.value;
              }}
              onBlur={handleBlur}
              error={errors.tpUnidade && touched.tpUnidade}
              input={<Input id='tpUnidade-error' />}
              inputProps={{
                name: 'tpUnidade',
                id: 'tpUnidade-helper'
              }}
            >
              {itensUniMedida.map(item => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {errors.tpUnidade && touched.tpUnidade && (
              <FormHelperText> {errors.tpUnidade} </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <TextFieldWithMask
            disabled={talhaoGpsList.length > 0}
            tipoMascara={3}
            numberDecimalScale={8}
            separator={true}
            showThousandSeparator={true}
            id='nrLatitude'
            name='nrLatitude'
            onChange={value => {
              setFieldValue('nrLatitude', value.target.value);

              var item = itemSelect;
              item.nrLatitude = value.target.value;
            }}
            onBlur={value => {
              if (value.target.value !== '') {
                setFieldValue('nrLatitude', value.target.value);
              } else {
                setFieldValue('nrLatitude', '');
              }

              var item = itemSelect;
              item.nrLatitude = value.target.value;
              changeStateItemSelect(item);

              if (
                item.nrLongitude &&
                !(parseFloat(value.target.value) < -90 || parseFloat(value.target.value) > 90)
              ) {
                if (item.areaCultivo.idAreaCultivo) {
                  changeMap(item.areaCultivo.idAreaCultivo);
                } else {
                  changeMap(value.target.value);
                }
              }
            }}
            value={values.nrLatitude}
            label={formatMessage('produtores.latitude')}
            className={classes.textField}
            InputLabelProps={{
              className: classes.font
            }}
            error={errors.nrLatitude && touched.nrLatitude}
            helperText={touched.nrLatitude ? errors.nrLatitude : ''}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldWithMask
            disabled={talhaoGpsList.length > 0}
            tipoMascara={3}
            numberDecimalScale={8}
            separator={true}
            showThousandSeparator={true}
            id='nrLongitude'
            name='nrLongitude'
            onChange={value => {
              setFieldValue('nrLongitude', value.target.value);

              var item = itemSelect;
              item.nrLongitude = value.target.value;
            }}
            onBlur={value => {
              if (value.target.value !== '') {
                setFieldValue('nrLongitude', value.target.value);
              } else {
                setFieldValue('nrLongitude', '');
              }

              var item = itemSelect;
              item.nrLongitude = value.target.value;

              changeStateItemSelect(item);

              if (
                item.nrLatitude &&
                !(parseFloat(value.target.value) < -180 || parseFloat(value.target.value) > 180)
              ) {
                if (item.areaCultivo.idAreaCultivo) {
                  changeMap(item.areaCultivo.idAreaCultivo);
                } else {
                  changeMap(value.target.value);
                }
              }
            }}
            value={values.nrLongitude}
            label={formatMessage('produtores.longitude')}
            className={classes.textField}
            InputLabelProps={{
              className: classes.font
            }}
            error={errors.nrLongitude && touched.nrLongitude}
            helperText={touched.nrLongitude ? errors.nrLongitude : ''}
          />
        </Grid>
      </Grid>

      <div key={talhaoGpsList.length} style={{ height: 'calc(100% - 192px)' }}>
        <Map
          latLong={{
            nrLatitude: itemSelect.nrLatitude,
            nrLongitude: itemSelect.nrLongitude
          }}
          areaCoord={itemSelect.areaCultivo}
          label={label}
          selectTalhao={itemSelect}
          data={dataTalhoes}
          navigatorPosition={navigatorPosition}
          valueCoords={talhaoGpsList}
          onClickMap={onClickMap}
        />
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(MapaTalhao);
export default connect(mapStateToProps)(enhaced);
