import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllFormaAplicacao() {
  return axios.get(api + '/forma-aplicacao');
}

function findAllFormaAplicacaoAtivo() {
  return axios.get(api + '/forma-aplicacao/active');
}

function findFormaAplicacaoById(idFormaAplicacao) {
  return axios.get(api + '/forma-aplicacao/' + idFormaAplicacao);
}

function deleteFormaAplicacaoById(idFormaAplicacao) {
  return axios.delete(api + '/forma-aplicacao/' + idFormaAplicacao);
}

function addFormaAplicacao(values) {
  return axios.post(api + '/forma-aplicacao', values);
}

function editFormaAplicacao(values) {
  return axios.put(api + '/forma-aplicacao', values);
}

export default {
  findAllFormaAplicacao,
  findFormaAplicacaoById,
  deleteFormaAplicacaoById,
  addFormaAplicacao,
  editFormaAplicacao,
  findAllFormaAplicacaoAtivo
};
