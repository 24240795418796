import AtividadeUrlEnum from '../enumerations/AtividadeUrlEnum';
import formatMessage from '../i18n/formatMessage';

function LabelDescricaoCadastro(tpLancamento) {
  switch (tpLancamento) {
    case AtividadeUrlEnum.ALERTA_FITOSSANITARIO:
      return formatMessage('acompanhamentoProdutor.observacao');
    case AtividadeUrlEnum.REGISTRO_FOTOGRAFICO:
      return formatMessage('acompanhamentoProdutor.observacao');
    case AtividadeUrlEnum.REGISTRO_CLIMATICO:
      return formatMessage('acompanhamentoProdutor.descricaoRegistroClimatico');
    default:
      return formatMessage('acompanhamentoProdutor.dsRecomendacaoAdicional');
  }
}

export default LabelDescricaoCadastro;
