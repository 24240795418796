/*
    Autor: Kátia miglioli
*/
import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconAdd from '@images/icone_add.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import '@styles/Alerts.css';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import Cultura from '@resources/Cultura';
import { trataErroEntidade } from '@exception/cultura/cultura';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const style = themeStyle => ({
  fab: {
    position: 'relative',
    bottom: themeStyle.spacing.unit * 9.5,
    right: themeStyle.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '10%',
    zIndex: 1,
    height: '10%',
    marginBottom: 0,
    marginLeft: '90%'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  }
});

class Culturas extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const descricao = messagesLinguage['cultura.descricao'];
    const numeroDeAplicacoes = messagesLinguage['cultura.numeroDeAplicacoes'];
    const sim = messagesLinguage['cultura.sim'];
    const nao = messagesLinguage['cultura.nao'];
    const desejaExcluir = messagesLinguage['cultura.desejaExcluir'];
    const excluir = messagesLinguage['cultura.excluir'];
    const culturaRemovidaComSucesso = messagesLinguage['cultura.culturaRemovidaComSucesso'];
    const falhaAoExcluirCultura = messagesLinguage['cultura.falhaAoExcluirCultura'];
    const excluindo = messagesLinguage['cultura.excluindo'];
    const safraRelacionada = messagesLinguage['cultura.safraRelacionada'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          descricao,
          numeroDeAplicacoes,
          sim,
          nao,
          desejaExcluir,
          excluir,
          culturaRemovidaComSucesso,
          falhaAoExcluirCultura,
          excluindo,
          safraRelacionada
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      itemSelect: '',
      disabledEdit: true,
      disableDelete: true,
      key: '',
      dataInTable: [],
      dataAppSync: [],
      statusAlert: false,
      loading: true,
      head: [
        {
          field: 'nmCultura',
          headerName: provider.intl.formatMessage({ id: 'descricao' }),
          sort: 'asc',
          col: 12
        }
      ],
      operationValues: [
        {
          label: provider.intl.formatMessage({ id: 'descricao' }),
          field: 'nmCultura',
          type: 'string'
        }
      ]
    };

    const title = 'cultura.culturas';
    if (this.props.itensState.name !== 'cultura.culturas') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false,
        disableDelete: select.idCliente === null
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true,
        disableDelete: true
      });
    }
  }

  clickAdicionar() {
    this.props.history.push('/app/culturas/novo');
  }
  clickEditar() {
    this.props.history.push('/app/culturas/editar/' + this.state.itemSelect.idCultura);
  }
  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            title: provider.intl.formatMessage({ id: 'excluindo' })
          }
        );
        Cultura.deleteCulturaById(this.state.itemSelect.idCultura)
          .then(() => {
            swal(provider.intl.formatMessage({ id: 'culturaRemovidaComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });
            this.atualizarRegistros(this.state.itemSelect.idCultura);
          })
          .catch(error => {
            if (error.response && error.response.data.codigo === 'ENTIDADE_RELACIONADA') {
              swal({
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                },
                closeOnClickOutside: false,
                closeOnEsc: false,
                icon: 'warning',
                title: trataErroEntidade(error.response.data.data)
              });
            } else {
              swal(provider.intl.formatMessage({ id: 'falhaAoExcluirCultura' }), {
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-error'
                  }
                }
              });
            }
          });
      }
    });
  }

  atualizarRegistros(dataCultura) {
    var index = -1;
    for (var i = 0; i < this.state.dataAppSync.length; i++) {
      if (this.state.dataAppSync[i].idCultura === dataCultura) {
        index = i;
        break;
      }
    }
    this.state.dataAppSync.splice(index, 1);
    this.setState({
      dataAppSync: this.state.dataAppSync,
      dataInTable: this.state.dataAppSync,
      key: Math.random()
    });

    this.onFilterChanged(this.props.chipData);
  }

  componentDidMount() {
    this.onFilter(this.props.chipData);
  }
  onFilterChanged(dataChips) {
    //this.state.dataAppSync => Contem todos os registros da tabela
    //dataInTable => objeto passado para a tabela
    // onFilterChanged={} em CardFilters deve receber essa funcao
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }
  onFilter() {
    Cultura.findAllCultura().then(query => {
      !this.isCancelled &&
        this.setState({
          dataAppSync: query.data,
          dataInTable: FilterUtil.applyFilters(query.data, this.props.chipData),
          loading: false
        });
    });
  }

  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'scroll'
          }}
        >
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='culturas'
          />
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disableDelete}
            onClickEdit={this.clickEditar}
            onClickDelete={this.clickExcluir}
          />

          <div
            style={{
              marginTop: 30,
              marginBottom: 15,
              display: 'contents',
              width: '100%',
              height: '100%'
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              key={this.state.key}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={() => this.clickAdicionar()} />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'culturas';
  })[0].content
});

let enhaced = withStyles(style)(Culturas);
export default withRouter(connect(mapStateToProps)(enhaced));
