export default {
  inoculantes: {
    inoculantesRemovidoComSucesso: 'Inoculants successfully removed',
    falhaAoExcluiInoculantes: 'Failed to exclude inoculants',
    desejaExcluir: 'Do you want to delete the selected inoculants?',
    inoculantes: 'Inoculants',
    cadastroDeInoculantes: 'Inoculants registration',
    identificacaoDaInoculantes: 'Identification',
    inoculantesCadastradoComSucesso: 'Inoculants successfully registered',
    inoculantesEditadoComSucesso: 'Inoculants edited successfully',
    falhaAoEditarInoculantes: 'Failed to edit inoculants',
    falhaAoCadastrarInoculantes: 'Failed to register inoculants',
    falhaAoCarregarInoculantes: 'Failed to load inoculants'
  }
};
