import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllPesticidasHandler() {
  return axios.get(api + '/insumo');
}

function findAllAtivosPesticidasHandler() {
  return axios.get(api + '/insumo/ativo');
}

function findPesticidasByTpPesticidaHandler(tpPesticida) {
  return axios.get(api + '/insumo/tpinsumo/' + tpPesticida);
}

function findAtivosPesticidasByTpPesticidaHandler(tpPesticida) {
  return axios.get(api + '/insumo/ativo/tpinsumo/' + tpPesticida);
}

function deletePesticidaByIdHandler(idPesticida) {
  return axios.delete(api + '/insumo/' + idPesticida);
}

function findPesticidaByIdHandler(idCliente) {
  return axios.get(api + '/insumo/' + idCliente);
}

function addPesticidaHandler(values) {
  return axios.post(api + '/insumo', values);
}

function editPesticidasHandler(values) {
  return axios.put(api + '/insumo', values);
}

export default {
  findAllPesticidas: findAllPesticidasHandler,
  deletePesticidaById: deletePesticidaByIdHandler,
  findPesticidaById: findPesticidaByIdHandler,
  addPesticida: addPesticidaHandler,
  editPesticidas: editPesticidasHandler,
  findPesticidasByTpPesticida: findPesticidasByTpPesticidaHandler,
  findAllAtivosPesticidas: findAllAtivosPesticidasHandler,
  findAtivosPesticidasByTpPesticida: findAtivosPesticidasByTpPesticidaHandler
};
