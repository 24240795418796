import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Card from '@components/Card/Card';
import formatMessage from './../i18n/formatMessage';

const style = theme => ({
  cadastro: {
    marginTop: '15px'
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  },
  label: {
    width: 270,
    height: 20,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    cursor: 'pointer'
  }
});

/**
 * Componente que representa o Card `Dados Sociais` na tab `Identificação`
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
const DadosSociais = props => {
  const {
    classes,
    setFieldValue,
    onChangeCheckParticipacao,
    checkParticipacao,
    onChangeCheckConflitos,
    checkConflitos,
    handleBlur,
    handleChange,
    values,
    tpPerfil
  } = props;

  return (
    <Card
      marginBottom
      withOutBorderRadius
      title={formatMessage('produtores.dadosSociais')}
      height='auto'
      style={{ minHeight: 305 }}
    >
      <form autoComplete='off'>
        <Grid container spacing={16}>
          <Grid item xs={4}>
            <div
              disabled={tpPerfil === 3}
              onClick={() => {
                setFieldValue('dsAssociacaoCooperativa', '');
                onChangeCheckParticipacao();
              }}
              className={classes.switch}
            >
              <Checkbox
                disabled={tpPerfil === 3}
                checked={checkParticipacao}
                value='checkedB'
                color='primary'
              />

              <label style={{ color: '#000000', cursor: 'pointer' }}>
                {formatMessage('produtores.participacaoAssociacoes')}
              </label>
            </div>
          </Grid>

          <Grid item xs>
            <TextField
              disabled={!checkParticipacao || tpPerfil === 3}
              id='dsAssociacaoCooperativa'
              name='dsAssociacaoCooperativa'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.dsAssociacaoCooperativa}
              label={formatMessage('produtores.descricao')}
              className={classes.textField}
              inputProps={{
                maxLength: 100
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} className={classes.cadastro}>
          <Grid item xs={4}>
            <div
              disabled={tpPerfil === 3}
              onClick={() => {
                setFieldValue('dsConflitoLimitrofe', '');
                onChangeCheckConflitos();
              }}
              className={classes.switch}
            >
              <Checkbox
                disabled={tpPerfil === 3}
                checked={checkConflitos}
                value='checkedB'
                color='primary'
              />

              <label style={{ color: '#000000', cursor: 'pointer' }}>
                {formatMessage('produtores.conflitoLimites')}
              </label>
            </div>
          </Grid>

          <Grid item xs>
            <TextField
              disabled={!checkConflitos || tpPerfil === 3}
              id='dsConflitoLimitrofe'
              name='dsConflitoLimitrofe'
              onBlur={handleBlur}
              inputProps={{
                maxLength: 100
              }}
              onChange={handleChange}
              value={values.dsConflitoLimitrofe}
              label={formatMessage('produtores.descricao')}
              className={classes.textField}
            />
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default withStyles(style)(DadosSociais);
