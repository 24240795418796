//@author Gabriela Farias
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ptBR from "./i18n/ptBR";
import enUS from "./i18n/enUS";
import esES from "./i18n/esES";
import { flattenMessages } from "@utils/languageUtils";
import { IntlProvider } from "react-intl";
import { getUserLang } from "@utils/localeUtils";
import classNames from "classnames";

let messages = { "pt-BR": ptBR, "en-US": enUS, "es-ES": esES };
var { provider } = new IntlProvider({});

const styles = theme => ({
  card: {
    height: 175,
    width: 223,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    display: "flex",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    marginRight: 15,
    marginBottom: 15
  },
  cardHover: {
    "&:hover": {
      backgroundColor: "rgba(0, 98, 43, 0.12) !important",
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.16) !important"
    }
  },
  label: {
    width: "100%",
    fontWeight: "500 !important",
    display: "flex",
    justifyContent: "center"
  }
});

class CardButton extends Component {
  constructor(props) {
    super(props);
    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const clickVisualizar = messagesLinguage["cardButton.clickVisualizar"];
    const rs = messagesLinguage["cardButton.rs"];

    const intlProvider = new IntlProvider({
      locale: getUserLang(),
      messages: {
        clickVisualizar,
        rs
      }
    });

    provider = intlProvider.getChildContext();

    this.clickItem = this.clickItem.bind(this);
  }

  clickItem() {
    this.props.clickItem(this.props.index, this.props.item);
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className={
          !this.props.select
            ? classNames(classes.cardHover, classes.card)
            : classes.card
        }
        id={"click"}
        style={{
          backgroundColor: !this.props.select
            ? "rgb(0, 98, 43, 0.1)"
            : "#00622B",
          cursor: !this.props.select ? "pointer" : "default"
        }}
        onClick={this.clickItem}
      >
        <div className={classes.itens}>
          <div
            className={classes.label}
            style={{
              fontSize: 20,
              color: !this.props.select
                ? "rgb(0, 0, 0, 0.8)"
                : "rgb(255, 255, 255, 0.8)"
            }}
          >
            {this.props.name}
          </div>
          <p
            className={classes.label}
            style={{
              fontSize: 16,
              color: !this.props.select
                ? "rgb(0, 0, 0, 0.7)"
                : "rgb(255, 255, 255, 0.7)"
            }}
          >
            {provider.intl.formatMessage({ id: "rs" }) + " " + this.props.valor}
          </p>
          <p
            className={classes.label}
            style={{
              fontSize: 12,
              color: !this.props.select
                ? "rgb(0, 0, 0, 0.7)"
                : "rgb(255, 255, 255, 0.7)"
            }}
          >
            {provider.intl.formatMessage({ id: "clickVisualizar" })}
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CardButton);
