import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Grid from '@material-ui/core/Grid';
import formatMessage from '../i18n/formatMessage';
import { withStyles } from '@material-ui/core/styles';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import Insumos from './Insumo';
import UploadFiles from '@components/UploadFiles/UploadFiles';
import { ArrowDownward, Delete } from '@material-ui/icons';
import Autocomplete from '@components/Autocomplete/Autocomplete';

const style = () => ({
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  },
  laudosCard: {
    height: 100,
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  cardUpload: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonLaudos: {
    color: '#FFFFFF',
    width: 155,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    cursor: 'pointer',
    marginTop: -15
  },
  labelLaudo: {
    color: '#000000',
    width: '100%',
    marginLeft: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  labelUpload: {
    height: 20
  },
  icon: {
    height: 20,
    width: 20,
    color: '#929292',
    cursor: 'pointer'
  },
  cardFile: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex'
  },
  cardIconsFile: {
    width: 85,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

/**
 * Componente com os campos de cadastro de Material de propagação
 * @returns
 */
function Identificacao(props) {
  const {
    analiseOgmList,
    values,
    errors,
    touched,
    classes,
    setFieldValue,
    handleBlurAndPersist,
    clickSaveInsumos,
    plantioInsumoList,
    saveFile,
    arquivoList,
    deleteFile,
    tratarValorAnaliseOgm,
    loadAnaliseOgm,
    persistTipoAnalise,
    formaAplicacaoList
  } = props;
  const [keyAnalise, setKeyAnalise] = useState(0);
  const [tabIndex, settTabIndex] = useState(0);
  const naoSimArray = [
    { key: 1, label: formatMessage('plantios.sim') },
    { key: 0, label: formatMessage('plantios.nao') }
  ];

  const arrayOrigemSemente = [
    { key: 0, label: formatMessage('plantios.operadora') },
    { key: 1, label: formatMessage('plantios.propria') },
    { key: 2, label: formatMessage('plantios.outros') }
  ];

  useEffect(() => {
    if (values.analiseOGM) {
      setKeyAnalise(Math.random());
    }
  }, []);

  function saveListUpload(uploadList) {
    saveFile(uploadList);
  }

  return (
    <div>
      <CardWithTabs
        selectedIndex={tabIndex}
        onChangeTabIndex={(e, tabIndexAux) => settTabIndex(tabIndexAux)}
        title={formatMessage('plantios.insumoMaterialProgapagacao')}
        withoutHidden
        tabs={[
          {
            label: formatMessage('plantios.identificacao'),
            component: (
              <form autoComplete='off'>
                <Grid container spacing={16}>
                  <Grid item xs={3}>
                    <FormControl
                      error={errors.tpOrigemSemente && touched.tpOrigemSemente}
                      style={{ width: '100%' }}
                    >
                      <InputLabel
                        className={classes.textField}
                        htmlFor='tpOrigemSemente'
                        error={errors.tpOrigemSemente && touched.tpOrigemSemente}
                      >
                        {formatMessage('plantios.origemSemente')}
                      </InputLabel>

                      <Select
                        value={values.tpOrigemSemente}
                        error={errors.tpOrigemSemente && touched.tpOrigemSemente}
                        onChange={e =>
                          setFieldValue(
                            'tpOrigemSemente',
                            e.target.value !== '' ? +e.target.value : e.target.value
                          )
                        }
                        onBlur={e => handleBlurAndPersist(e, 'tpOrigemSemente')}
                        name='tpOrigemSemente'
                        id='tpOrigemSemente'
                      >
                        {arrayOrigemSemente.map((item, i) => (
                          <MenuItem key={item.key} value={item.key}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl
                      error={errors.stPossuiLaudoOgm && touched.stPossuiLaudoOgm}
                      style={{ width: '100%' }}
                    >
                      <InputLabel
                        className={classes.textField}
                        htmlFor='stPossuiLaudoOgm'
                        error={errors.stPossuiLaudoOgm && touched.stPossuiLaudoOgm}
                      >
                        {formatMessage('plantios.possuiLaudo')}
                      </InputLabel>

                      <Select
                        value={values.stPossuiLaudoOgm}
                        error={errors.stPossuiLaudoOgm && touched.stPossuiLaudoOgm}
                        onChange={e => {
                          setFieldValue(
                            'stPossuiLaudoOgm',
                            e.target.value !== '' ? +e.target.value : e.target.value
                          );

                          if (e.target.value === 0) {
                            setFieldValue('dsAnaliseOgm', '');
                          }
                        }}
                        onBlur={e => handleBlurAndPersist(e, 'stPossuiLaudoOgm')}
                        name='stPossuiLaudoOgm'
                        id='stPossuiLaudoOgm'
                      >
                        {naoSimArray.map((item, i) => (
                          <MenuItem key={item.key} value={item.key}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id='dsOrigemSemente'
                      name='dsOrigemSemente'
                      value={'' + values.dsOrigemSemente}
                      label={formatMessage('plantios.descricaoOrigem')}
                      className={classes.textField}
                      onBlur={e => handleBlurAndPersist(e, 'dsOrigemSemente')}
                      onChange={e => setFieldValue('dsOrigemSemente', e.target.value)}
                      error={!values.dsOrigemSemente && touched.dsOrigemSemente}
                      helperText={
                        touched.dsOrigemSemente && !values.dsOrigemSemente ? errors.dsOrigemSemente : ''
                      }
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Autocomplete
                      itens={analiseOgmList}
                      campoOp='dsTipoAnaliseOgm'
                      campoChave='idTipoAnaliseOgm'
                      label={formatMessage('plantios.analiseOGM')}
                      name='analiseOgm'
                      onChangeOwn={onChangeAnaliseOGM}
                      onBlur
                      value={tratarValorAnaliseOgm()}
                      key={keyAnalise}
                      valueAutoComplete={tratarValorAnaliseOgm()}
                      valueSelect={tratarValorAnaliseOgm().dsTipoAnaliseOgm}
                    />
                  </Grid>
                </Grid>
              </form>
            )
          },
          {
            label: `${formatMessage('plantios.laudos')} (${arquivoList.length}/3)`,
            component: (
              <div className={classes.laudosCard}>
                <div className={classes.cardUpload}>
                  <div style={{ width: 155 }}>
                    <UploadFiles
                      disableMultiple={true}
                      accept='*'
                      hiddenCardAnexo={true}
                      allFotos={arquivoList}
                      saveListFoto={saveListUpload}
                      filesDelete={() => {}}
                      disabled={arquivoList.length >= 3}
                    >
                      <div
                        className={classes.buttonLaudos}
                        style={{
                          backgroundColor: arquivoList.length < 3 ? '#46A770' : '#929292',
                          cursor: arquivoList.length < 3 ? 'pointer' : 'default'
                        }}
                      >
                        {formatMessage('plantios.adicionarLaudo')}
                      </div>
                    </UploadFiles>
                  </div>
                  {arquivoList.length === 0 ? (
                    <div className={classes.labelLaudo} style={{ height: 20 }}>
                      {arquivoList.length === 0 && formatMessage('plantios.nenhumLaudo')}
                    </div>
                  ) : (
                    <div className={classes.labelLaudo} style={{ height: 75 }}>
                      {cardUploadFile(0)}
                      {cardUploadFile(1)}
                      {cardUploadFile(2)}
                    </div>
                  )}
                </div>
              </div>
            )
          }
        ]}
      />
      <div style={{ height: 20 }} />
      {tabIndex === 0 && (
        <Insumos
          formaAplicacaoList={formaAplicacaoList}
          clickSave={clickSaveInsumos}
          plantioInsumoList={plantioInsumoList}
        />
      )}
    </div>
  );

  function onChangeAnaliseOGM(e) {
    loadAnaliseOgm();
    setFieldValue('tipoAnaliseOgm', e);
    persistTipoAnalise(e);
  }

  function cardUploadFile(index) {
    return (
      <div className={classes.labelUpload}>
        {arquivoList.length >= index + 1 && (
          <div className={classes.cardFile}>
            {arquivoList[index].dsFoto}
            <div className={classes.cardIconsFile}>
              {index + 1}/3
              <ArrowDownward
                className={classes.icon}
                style={{
                  color: !arquivoList[index].dsLink ? 'rgba(146, 146, 146, 0.5)' : 'rgba(0, 0, 0, 0.54)',
                  cursor: !arquivoList[index].dsLink ? 'default' : 'pointer'
                }}
                onClick={() => arquivoList[index].dsLink && downloadFile(arquivoList[index])}
              />
              <Delete className={classes.icon} onClick={() => deleteFile(index)} />
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * Faz o download do arquivo
   * @param {*} file - arquivo para realizar download
   */
  async function downloadFile(file) {
    var res = await fetch(file.dsLink);
    var data = await res.blob();

    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(data);
    anchor.download = file.dsFoto;
    anchor.click();
  }
}

export default withStyles(style)(Identificacao);
