import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import Card from '@components/Card/Card';
import UploadFoto from '@components/UploadFoto/UploadFoto';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';
import * as moment from 'moment';
import formatMessage from './../i18n/formatMessage';

const style = theme => ({
  cadastro: {
    width: '100%',
    display: 'flex',
    marginTop: '15px',
    height: 70
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  },
  font: {
    fontSize: 14,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  form: {
    width: '100%',
    bottom: 1
  },
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    width: 'fit-content',
    cursor: 'pointer'
  }
});

/**
 * Componente que representa o Card principal da tab `Identificação`
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
const Identificacao = props => {
  const {
    classes,
    onBlur,
    tpPerfil,
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    onChangeAutocompleteEstado,
    onChangeAutocompleteCidade,
    onChangeAutocompleteComunidade,
    estados,
    keyEstado,
    validarInicializacaoAutoCompleteEstado,
    tratarValorSelectInitialEstado,
    valueAutoCompleteEstado,
    municipios,
    handleBlurOwnMunicipio,
    validarInicializacaoAutoCompleteMunicipio,
    tratarValorSelectInitialMunicipio,
    valueAutoCompleteMunicipio,
    keyMunicipio,
    itensComunidades,
    keyComunidade,
    handleBlurOwnComunidade,
    tratarValorComunidade,
    tratarValorSelectInitialComunidade,
    valueAutoCompleteComunidade,
    itensNivel,
    agriculturaFamiliarList,
    tecnicos,
    handleBlurOwn,
    valueAutoCompleteTecnico,
    onChangeOwn,
    validarInicializacaoAutoComplete,
    keyTecnico,
    checkSt,
    stylesTecnico,
    tratarValorSelectInitial,
    onChangeCheckSt,
    onChangeCheckProducaoParalela,
    stProducaoParalela,
    itemFoto,
    saveFoto,
    onBlurDataCadastro,
    handleBlur,
    disabledDtCadastro
  } = props;

  return (
    <Card
      withOutBorderRadius
      marginBottom
      title={formatMessage('produtores.identificacao')}
      height='auto'
    >
      <form autoComplete='off'>
        <Grid container spacing={16}>
          <Grid item xs={4}>
            <TextField
              disabled={tpPerfil === 3}
              id='nmProdutor'
              name='nmProdutor'
              value={values.nmProdutor}
              label={formatMessage('produtores.nome')}
              className={classes.textField}
              InputLabelProps={{ className: classes.font }}
              error={errors.nmProdutor && touched.nmProdutor}
              helperText={touched.nmProdutor ? errors.nmProdutor : ''}
              onBlur={value => onBlur(value, 'nmProdutor')}
              onChange={value => setFieldValue('nmProdutor', value.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextFieldWithMask
              disabled={tpPerfil === 3}
              tipoMascara={1}
              id='nrTelefone'
              name='nrTelefone'
              value={values.nrTelefone}
              label={formatMessage('produtores.telefone')}
              className={classes.textField}
              InputLabelProps={{ className: classes.font }}
              error={errors.nrTelefone && touched.nrTelefone}
              helperText={touched.nrTelefone ? errors.nrTelefone : ''}
              onBlur={value => onBlur(value, 'nrTelefone')}
              onChange={value => setFieldValue('nrTelefone', value.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id='dsEmail'
              name='dsEmail'
              value={values.dsEmail}
              label={formatMessage('produtores.email')}
              className={classes.textField}
              margin='normal'
              InputLabelProps={{ className: classes.font }}
              multiline={false}
              error={errors.dsEmail && touched.dsEmail}
              helperText={touched.dsEmail ? errors.dsEmail : ''}
              onBlur={value => onBlur(value, 'dsEmail')}
              onChange={value => setFieldValue('dsEmail', value.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <div className={classes.switch} onClick={onChangeCheckSt}>
              <Checkbox disabled={tpPerfil === 3} checked={checkSt} value='checkedB' color='primary' />
              <label style={{ color: '#000000', cursor: 'pointer' }}>
                {formatMessage('produtores.ativo')}
              </label>
            </div>
          </Grid>
        </Grid>

        <div className={classes.cadastro}>
          <Grid container spacing={16}>
            <Grid item xs={2}>
              <Autocomplete
                disabled={tpPerfil === 3}
                itens={estados}
                campoOp='nmUf'
                campoInput='nmUf'
                campoChave='idUf'
                label={formatMessage('produtores.estado')}
                name='estado'
                error={errors.estado && touched.estado}
                helperText={touched.estado ? errors.estado : ''}
                onChangeOwn={onChangeAutocompleteEstado}
                onChangeAutoComplete={validarInicializacaoAutoCompleteEstado}
                value={tratarValorSelectInitialEstado(valueAutoCompleteEstado)}
                key={keyEstado}
                valueAutoComplete={tratarValorSelectInitialEstado(valueAutoCompleteEstado)}
                valueSelect={tratarValorSelectInitialEstado(valueAutoCompleteEstado).nmUf}
              />
            </Grid>

            <Grid item xs={2}>
              <Autocomplete
                disabled={
                  valueAutoCompleteEstado === '' || valueAutoCompleteEstado === null || tpPerfil === 3
                }
                itens={municipios}
                campoOp='nmMunicipio'
                campoInput='nmMunicipio'
                campoChave='idMunicipio'
                label={formatMessage('produtores.municipio')}
                name='municipio'
                error={errors.municipio && touched.municipio}
                helperText={touched.municipio ? errors.municipio : ''}
                onBlurOwn={handleBlurOwnMunicipio}
                onChangeOwn={onChangeAutocompleteCidade}
                onChangeAutoComplete={validarInicializacaoAutoCompleteMunicipio}
                value={tratarValorSelectInitialMunicipio(valueAutoCompleteMunicipio)}
                key={keyMunicipio}
                valueAutoComplete={tratarValorSelectInitialMunicipio(valueAutoCompleteMunicipio)}
                valueSelect={tratarValorSelectInitialMunicipio(valueAutoCompleteMunicipio).nmMunicipio}
              />
            </Grid>

            <Grid item xs={2}>
              <Autocomplete
                disabled={
                  valueAutoCompleteMunicipio === '' ||
                  valueAutoCompleteMunicipio === null ||
                  tpPerfil === 3
                }
                itens={itensComunidades}
                campoOp='nmComunidade'
                campoInput='nmComunidade'
                campoChave='idComunidade'
                label={formatMessage('produtores.comunidade')}
                name='comunidade'
                onBlurOwn={handleBlurOwnComunidade}
                onChangeOwn={onChangeAutocompleteComunidade}
                onChangeAutoComplete={tratarValorComunidade}
                value={tratarValorSelectInitialComunidade(valueAutoCompleteComunidade)}
                key={keyComunidade}
                valueAutoComplete={tratarValorSelectInitialComunidade(valueAutoCompleteComunidade)}
                valueSelect={
                  tratarValorSelectInitialComunidade(valueAutoCompleteComunidade).nmComunidade
                }
              />
            </Grid>

            <Grid item xs={4}>
              <UploadFoto
                erroArquivo={formatMessage('produtores.erroAnexarFoto')}
                edit
                organico={true}
                itemFoto={itemFoto}
                saveFoto={saveFoto}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.cadastro}>
          <Grid container spacing={16}>
            <Grid item xs={2}>
              <TextFieldWithMask
                disabled={tpPerfil === 3}
                tipoMascara={6}
                id='nrDocumento'
                name='nrDocumento'
                value={values.nrDocumento}
                label={getUserLang() === 'pt-BR' ? 'CPF/CNPJ' : formatMessage('produtores.cpf')}
                className={classes.textField}
                InputLabelProps={{
                  className: classes.font
                }}
                error={errors.nrDocumento && touched.nrDocumento}
                helperText={touched.nrDocumento ? errors.nrDocumento : ''}
                onBlur={handleBlur}
                onChange={value => {
                  setFieldValue('nrDocumento', value.target.value);
                  onBlur(value, 'nrDocumento');
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                disabled={tpPerfil === 3}
                inputProps={{
                  maxLength: 41
                }}
                id='nrCar'
                name='nrCar'
                value={values.nrCar}
                label={formatMessage('produtores.car')}
                className={classes.textField}
                margin='normal'
                InputLabelProps={{ className: classes.font }}
                multiline={false}
                error={errors.nrCar && touched.nrCar}
                helperText={touched.nrCar ? errors.nrCar : ''}
                onBlur={value => onBlur(value, 'nrCar')}
                onChange={value => setFieldValue('nrCar', value.target.value)}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                disabled={tpPerfil === 3}
                inputProps={{
                  maxLength: 25
                }}
                id='nrDap'
                name='nrDap'
                value={values.nrDap}
                label={formatMessage('produtores.dap')}
                className={classes.textField}
                margin='normal'
                InputLabelProps={{ className: classes.font }}
                multiline={false}
                error={errors.nrDap && touched.nrDap}
                helperText={touched.nrDap ? errors.nrDap : ''}
                onBlur={value => onBlur(value, 'nrDap')}
                onChange={value => setFieldValue('nrDap', value.target.value)}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.cadastro}>
          <Grid container spacing={16}>
            <Grid item xs={2}>
              <FormControl
                disabled={tpPerfil === 3}
                className={classes.form}
                error={errors.tpTecnologico && touched.tpTecnologico}
              >
                <InputLabel
                  htmlFor='nivel-tecnologico'
                  error={errors.tpTecnologico && touched.tpTecnologico}
                  className={classes.font}
                >
                  {formatMessage('produtores.nivel')}
                </InputLabel>

                <Select
                  style={{ marginLeft: 0, fontSize: 14, width: '100%' }}
                  SelectDisplayProps={{ style: { width: '100%', backgroundColor: 'transparent' } }}
                  value={values.tpTecnologico}
                  error={errors.tpTecnologico && touched.tpTecnologico}
                  onChange={handleChange}
                  onBlur={value => onBlur(value, 'tpTecnologico')}
                  displayEmpty
                  inputProps={{ name: 'tpTecnologico', id: 'nivel-tecnologico' }}
                >
                  {itensNivel.map((nivel, i) => (
                    <MenuItem key={i} value={nivel.id} className={classes.font}>
                      {nivel.name}
                    </MenuItem>
                  ))}
                </Select>

                {errors.tpTecnologico && touched.tpTecnologico && (
                  <FormHelperText> {errors.tpTecnologico} </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl
                disabled={tpPerfil === 3}
                className={classes.form}
                error={errors.stAgriculturaFamiliar && touched.stAgriculturaFamiliar}
              >
                <InputLabel
                  htmlFor='agricultura-familiar'
                  error={errors.stAgriculturaFamiliar && touched.stAgriculturaFamiliar}
                  className={classes.font}
                >
                  {formatMessage('produtores.agriculturaFamiliar')}
                </InputLabel>

                <Select
                  style={{ marginLeft: 0, fontSize: 14, width: '100%' }}
                  SelectDisplayProps={{ style: { width: '100%', backgroundColor: 'transparent' } }}
                  value={values.stAgriculturaFamiliar}
                  error={errors.stAgriculturaFamiliar && touched.stAgriculturaFamiliar}
                  onChange={handleChange}
                  onBlur={value => onBlur(value, 'stAgriculturaFamiliar')}
                  displayEmpty
                  inputProps={{ name: 'stAgriculturaFamiliar', id: 'agricultura-familiar' }}
                >
                  {agriculturaFamiliarList.map((nivel, i) => (
                    <MenuItem key={i} value={nivel.id} className={classes.font}>
                      {nivel.name}
                    </MenuItem>
                  ))}
                </Select>

                {errors.stAgriculturaFamiliar && touched.stAgriculturaFamiliar && (
                  <FormHelperText> {errors.stAgriculturaFamiliar} </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={2} style={stylesTecnico}>
              <Autocomplete
                itens={tecnicos}
                campoOp='nmUsuario'
                campoChave='idUsuario'
                label={formatMessage('produtores.tecnicoResponsavel')}
                name='tecnicoResponsavel'
                onBlurOwn={handleBlurOwn}
                error={valueAutoCompleteTecnico === '' && touched.tecnicoResponsavel}
                helperText={
                  touched.tecnicoResponsavel && valueAutoCompleteTecnico === ''
                    ? formatMessage('produtores.campoObrigatorio')
                    : null
                }
                onChangeOwn={onChangeOwn}
                onChangeAutoComplete={validarInicializacaoAutoComplete}
                value={tratarValorSelectInitial(valueAutoCompleteTecnico)}
                key={keyTecnico}
                valueAutoComplete={tratarValorSelectInitial(valueAutoCompleteTecnico)}
                valueSelect={tratarValorSelectInitial(valueAutoCompleteTecnico).nmUsuario}
              />
            </Grid>

            <Grid item xs={4}>
              <div className={classes.switch} onClick={onChangeCheckProducaoParalela}>
                <Checkbox
                  disabled={tpPerfil === 3}
                  checked={stProducaoParalela}
                  value='checkedB'
                  color='primary'
                />
                <label style={{ color: '#000000', cursor: 'pointer' }}>
                  {formatMessage('produtores.producaoParalela')}
                </label>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.cadastro}>
          <Grid container spacing={16}>
            <Grid item xs={2}>
              <DatePicker
                disabled={disabledDtCadastro || tpPerfil === 3}
                locale={getUserLang()}
                maxDate={new Date()}
                style={{ height: 70 }}
                className={classes.font}
                label={formatMessage('produtores.dataCadastro')}
                valueDate={values.dhCadastro}
                onChangeDatePicker={date => {
                  onBlurDataCadastro(moment(date).format('YYYY-MM-DDTHH:mm:ssZZ'), 'dhCadastro');
                }}
                onBlurDatePicker={date => {
                  if (date.target.value === '') {
                    onBlurDataCadastro(null, 'dhCadastro');
                  }

                  let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();
                  if (!validation) {
                    setFieldValue('dhCadastro', null);
                  }
                }}
                errorTouched={errors.dhCadastro && touched.dhCadastro ? true : false}
                helperText={formatMessage('produtores.campoObrigatorio')}
              />
            </Grid>

            <Grid item xs={2}>
              <DatePicker
                disabled={tpPerfil === 3}
                locale={getUserLang()}
                style={{ height: 70 }}
                className={classes.font}
                label={formatMessage('produtores.dataPrimeiraVisita')}
                valueDate={values.dhPrimeiraVisita}
                onChangeDatePicker={date => {
                  onBlurDataCadastro(moment(date).format('YYYY-MM-DDTHH:mm:ssZZ'), 'dhPrimeiraVisita');
                }}
                onBlurDatePicker={date => {
                  if (date.target.value === '') {
                    onBlurDataCadastro(null, 'dhPrimeiraVisita');
                  }

                  let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();
                  if (!validation) {
                    setFieldValue('dhPrimeiraVisita', null);
                  }
                }}
              />
            </Grid>
          </Grid>
        </div>
      </form>
    </Card>
  );
};

export default withStyles(style)(Identificacao);
