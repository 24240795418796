import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from '@components/Card/Card';
import UploadFiles from '@components/UploadFiles/UploadFiles';
import Talhoes from './Cadastros/Talhoes';
import AtividadeUrlEnum from './enumerations/AtividadeUrlEnum';
import { style } from './AcompanhamentoProdutor.styles';
import { AlertaData, ProdutoData, FertilizanteData } from './Header/DataTableHeader';
import { swalOptions, swalSucess } from './utils/SwalOptions';
import EditarProdutosAcompanhamento from './Cadastros/EditarProdutosAcompanhamento';
import SwipeableViews from 'react-swipeable-views';
import EditarAlertasFitossanitarios from './Cadastros/EditarAlertasFitossanitarios';
import { titleTab } from './Header/TabLateralList';
import formatMessage from './i18n/formatMessage';
import CardTitle from '@components/CardTitle/CardTitle';

/**
 * Grid de produtos na tela de lançamento
 */
function ProdutosAcompanhamento(props) {
  const {
    classes,
    itensGridList,
    onClickDeleteProduto,
    onRowDragEnd,
    listTalhoes,
    rowsSelect,
    itensTalhoes,
    onSelectTalhoes,
    tpLancamento,
    clickShowButton,
    keyDataTable,
    typeFertilizante,
    filesDelete,
    allFotos,
    saveListFoto,
    valuePlantio,
    onClickAddProdutoSeveridade,
    onClickAddProduto,
    index
  } = props;
  const [disabledEdit, setDisabledEdit] = useState(true);
  const [itemSelect, setItemSelect] = useState(null);

  return (
    <SwipeableViews
      className={classes.swipeableViews}
      containerStyle={{ flex: 1 }}
      index={index}
      slideStyle={{ height: index === 0 ? 'auto' : '100%' }}
      ignoreNativeScroll={false}
    >
      {[
        <div key={0} className={classes.swipeableViews}>
          <CardTitle title={titleTab()} />
          {index === 0 && showDataTable()}
        </div>,
        <div key={1} className={classes.tabSwiapeable}>
          {index === 1 && tpLancamento <= AtividadeUrlEnum.APLICACAO && (
            <EditarProdutosAcompanhamento
              typeFertilizante={typeFertilizante}
              clickShowButton={clickBack}
              titleProdutos={formatMessage('acompanhamentoProdutor.produtos')}
              itensGridList={itensGridList}
              onRowDragEnd={onRowDragEnd}
              onClickDeleteProduto={position => onClickDeleteProduto(position)}
              onClickAddProduto={addProdutosAcompanhamento}
              itemSelectProdutos={itemSelect}
            />
          )}
          {index === 1 && tpLancamento === AtividadeUrlEnum.ALERTA_FITOSSANITARIO && (
            <EditarAlertasFitossanitarios
              plantio={valuePlantio}
              clickShowButton={clickBack}
              titleProdutos={formatMessage('acompanhamentoProdutor.produtos')}
              data={itensGridList}
              onRowDragEnd={onRowDragEnd}
              onClickDeleteProduto={position =>
                onClickDeleteProduto(position, AtividadeUrlEnum.ALERTA_FITOSSANITARIO)
              }
              onClickAddProduto={onClickAddProdutoSeveridade}
              itemSelectProdutos={itemSelect}
            />
          )}
        </div>
      ]}
    </SwipeableViews>
  );

  function addProdutosAcompanhamento(produto, position) {
    onClickAddProduto(produto, position);
    setItemSelect(null);
    setDisabledEdit(true);
  }

  /**
   * Trata os valores quando clicar em voltar
   */
  function clickBack(show) {
    setDisabledEdit(true);
    clickShowButton(show);
  }
  /**
   * Mostra a data tbale de acordo com o tipo de lançamento
   * @returns
   */
  function showDataTable() {
    if (tpLancamento === AtividadeUrlEnum.REGISTRO_CLIMATICO) {
      return dataTableTalhao();
    } else {
      return dataTableProdutos();
    }
  }

  /**
   * Retorna a grid de talhões
   * @returns
   */
  function dataTableTalhao() {
    return (
      <div className={classes.gridTableTalhoes}>
        <Talhoes
          validationGrid={true}
          listTalhoes={listTalhoes}
          rowsSelect={rowsSelect}
          itensTalhoes={itensTalhoes}
          onSelectTalhoes={onSelectTalhoes}
        />
      </div>
    );
  }

  /**
   * Retorna grid de acordo com o tipo de atividade
   * @returns - grid
   */
  function dataTableProdutos() {
    return (
      <div className={classes.gridTableProdutos}>
        {showGridButtons()}
        {returnComponentByTipoAcompanhamento()}
      </div>
    );
  }

  /**
   * Valida se os botões da grid devem aparecer
   * @returns - buttoes da grid
   */
  function showGridButtons() {
    if (tpLancamento !== AtividadeUrlEnum.REGISTRO_FOTOGRAFICO) {
      return (
        <GroupButtonGrid
          showAdd={true}
          showEdit={true}
          showDelete={true}
          disableEdit={disabledEdit}
          disableDelete={disabledEdit}
          onClickAdd={() => onClickAddInGrid()}
          onClickEdit={() => clickShowButton(false, itemSelect)}
          onClickDelete={clickExcluir}
        />
      );
    }
  }

  /**
   * Leva para a tela de adicionar item
   */
  function onClickAddInGrid() {
    selectChange();
    clickShowButton(false);
  }

  /**
   * Retorna o componente correspondete ao acompanhamento
   * @returns - componente
   */
  function returnComponentByTipoAcompanhamento() {
    switch (tpLancamento) {
      case AtividadeUrlEnum.REGISTRO_FOTOGRAFICO:
        return (
          <div style={{ marginTop: 35, height: '100%' }}>
            <Card
              disableHeader
              withOutBorderRadius={true}
              disablePadding
              childrenStyle={{
                display: 'flex',
                width: '100%',
                height: '100%'
              }}
            >
              <UploadFiles filesDelete={filesDelete} allFotos={allFotos} saveListFoto={saveListFoto} />
            </Card>
          </div>
        );
      case AtividadeUrlEnum.ALERTA_FITOSSANITARIO:
        return (
          <DataTable
            data={itensGridList}
            columns={AlertaData}
            selectChange={selectChange}
            showPagination={false}
          />
        );
      case AtividadeUrlEnum.APLICACAO:
        return dataToAplicacaoRecomendacao();
      case AtividadeUrlEnum.RECOMENDACAO:
        return dataToAplicacaoRecomendacao();
      default:
        return <div />;
    }
  }

  /**
   * Data se for caso de aplicação / recomendação
   * @returns
   */
  function dataToAplicacaoRecomendacao() {
    return (
      <DataTable
        data={itensGridList}
        key={keyDataTable}
        columns={typeFertilizante ? FertilizanteData : ProdutoData}
        selectChange={selectChange}
        sortable={true}
        onRowDragEnd={onRowDragEnd}
        showPagination={false}
      />
    );
  }
  /**
   * Ação ao clicar em excluir
   */
  function clickExcluir() {
    const position = itensGridList.indexOf(itemSelect);
    swalOptions('acompanhamentoProdutor.desejaExcluirProduto').then(willDelete => {
      if (willDelete) {
        onClickDeleteProduto(position, tpLancamento);
        swalSucess('acompanhamentoProdutor.produtoRemovidoComSucesso');
      } else {
        setDisabledEdit(false);
      }
    });
  }

  /**
   * Ação ao selecionar item na grid
   * @param {*} select - item selecionado
   */
  function selectChange(select) {
    if (select) {
      setItemSelect(select);
      setDisabledEdit(false);
    } else {
      setItemSelect(null);
      setDisabledEdit(true);
    }
  }
}

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(ProdutosAcompanhamento);
export default withRouter(connect(mapStateToProps)(enhaced));
