export default {
  acaricida: {
    acaricidaRemovidoComSucesso: 'Acaricide successfully removed',
    falhaAoExcluiAcaricida: 'Failed to exclude acaricide',
    desejaExcluir: 'Do you want to delete the selected acaricide?',
    acaricida: 'Acaricide',
    cadastroDeAcaricida: 'Acaricide registration',
    identificacaoDaAcaricida: 'Identification',
    acaricidaCadastradoComSucesso: 'Acaricide successfully registered',
    acaricidaEditadoComSucesso: 'Acaricide edited successfully',
    falhaAoEditarAcaricida: 'Failed to edit acaricide',
    falhaAoCadastrarAcaricida: 'Failed to register acaricide',
    falhaAoCarregarAcaricida: 'Failed to load acaricide'
  }
};
