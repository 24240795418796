export default {
  municipio: {
    nome: 'Nome',
    uf: 'UF',
    municipio: 'Município',
    identificacaoDoMunicipio: 'Identificação',
    falha: 'Erro ao carregar município',
    moduloFiscal: 'Módulo fiscal',
    naoFoiPossivelEditar: 'Não foi possível editar',
    salvando: 'salvando',
    campoObrigatorio: 'Campo Obrigatório',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    editadoComSucesso: 'Editado com sucesso'
  }
};
