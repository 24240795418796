export default {
  bactericidasMicrobiologicos: {
    bactericidasMicrobiologicosRemovidoComSucesso: 'Microbiological bactericide successfully removed',
    falhaAoExcluiBactericidasMicrobiologicos: 'Failed to exclude microbiological bactericide',
    desejaExcluir: 'Do you want to delete the selected microbiological bactericides?',
    bactericidasMicrobiologicos: 'Microbiological bactericides',
    cadastroDeBactericidasMicrobiologicos: 'Microbiological bactericides registration',
    identificacaoDaBactericidasMicrobiologicos: 'Identification',
    bactericidasMicrobiologicosCadastradoComSucesso:
      'Microbiological bactericide successfully registered',
    bactericidasMicrobiologicosEditadoComSucesso: 'Microbiological bactericide edited successfully',
    falhaAoEditarBactericidasMicrobiologicos: 'Failed to edit microbiological bactericide',
    falhaAoCadastrarBactericidasMicrobiologicos: 'Failed to register microbiological bactericide',
    falhaAoCarregarBactericidasMicrobiologicos: 'Failed to load microbiological bactericides'
  }
};
