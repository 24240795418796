import React, { Component } from 'react';
import { Typography, Tooltip, Collapse } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import esES from './i18n/esES';
import enUS from './i18n/enUS';
import { getUserLang } from '@utils/localeUtils';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { Create, DoneAll, ExpandLess, ExpandMore } from '@material-ui/icons';
import { styles } from './Cronograma.styles';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const areaDeCultivo = messagesLinguage['cronograma.areaDeCultivo'];
const alqueires = messagesLinguage['cronograma.alqueires'];
const cultura = messagesLinguage['cronograma.cultura'];
const aplicacao = messagesLinguage['cronograma.aplicacao'];
const recomendacao = messagesLinguage['cronograma.recomendacao'];
const paraDia = messagesLinguage['cronograma.paraDia'];
const acre = messagesLinguage['cronograma.acre'];
const hectare = messagesLinguage['cronograma.hectare'];
const alqueire = messagesLinguage['cronograma.alqueire'];
const plantio = messagesLinguage['cronograma.plantio'];
const colheita = messagesLinguage['cronograma.colheita'];
const alertasFitossanitarios = messagesLinguage['cronograma.alertasFitossanitarios'];
const monitoramento = messagesLinguage['cronograma.monitoramento'];
const hoje = messagesLinguage['cronograma.hoje'];
const talhoes = messagesLinguage['cronograma.talhoes'];
const registroFotografico = messagesLinguage['cronograma.registroFotografico'];
const registroClimatico = messagesLinguage['cronograma.registroClimatico'];
const aplicacaoProdutoProibido = messagesLinguage['cronograma.aplicacaoProdutoProibido'];
const naoConformidade = messagesLinguage['cronograma.naoConformidade'];
const culturas = messagesLinguage['cronograma.culturas'];
const sazonalidade = messagesLinguage['cronograma.sazonalidade'];
const ocultarAtividades = messagesLinguage['cronograma.ocultarAtividades'];
const mostrarAtividades = messagesLinguage['cronograma.mostrarAtividades'];
const auditoria = messagesLinguage['cronograma.auditoria'];

const intlProvider = new IntlProvider(
  {
    locale: getUserLang(),
    messages: {
      culturas,
      sazonalidade,
      naoConformidade,
      areaDeCultivo,
      alqueires,
      cultura,
      aplicacao,
      recomendacao,
      paraDia,
      acre,
      hectare,
      alqueire,
      plantio,
      colheita,
      alertasFitossanitarios,
      monitoramento,
      hoje,
      talhoes,
      registroFotografico,
      registroClimatico,
      aplicacaoProdutoProibido,
      ocultarAtividades,
      mostrarAtividades,
      auditoria
    }
  },
  {}
);

provider = intlProvider.getChildContext();

function Card(props) {
  var color = '#F2F2F2';
  var colorMargin = corCard(props.value.type);
  let count = 0;

  return (
    <div
      className={props.className.card}
      style={
        props.value.type === 10
          ? {
              height: props.index !== 0 ? 95 : 150,
              paddingTop: props.index !== 0 && props.last ? 25 : ''
            }
          : { height: props.value.type === -1 ? 70 : '' }
      }
    >
      <div
        className={props.className.cardDate}
        style={{
          marginTop: props.first ? 50 : 0,
          marginBottom: props.last ? 50 : 0,
          border: props.value.type === -1 ? '1px solid rgba(0, 0, 0, 0.6)' : 'none'
        }}
      >
        <Typography className={props.className.styleDate}>
          {props.value.type !== 10 ? props.value.date : props.value.info.dsMes}
        </Typography>
      </div>

      <div
        className={props.className.margin}
        style={{
          borderTopRightRadius: props.first ? 5 : 0,
          borderTopLeftRadius: props.first ? 5 : 0,
          borderBottomRightRadius: props.last ? 5 : 0,
          borderBottomLeftRadius: props.last ? 5 : 0,
          paddingTop: props.first ? 50 : 0,
          paddingBottom: props.last ? 50 : 0,
          marginLeft: props.value.type === -1 ? -2 : 'none'
        }}
      />

      <div
        className={props.className.triangle}
        style={{
          marginTop: props.first ? 50 : 0,
          marginBottom: props.last ? 50 : 0,
          borderBottom: props.value.type === -1 ? 'none' : '20px solid' + colorMargin
        }}
      />
      <div
        style={{
          borderLeft:
            props.value.selecionado || props.value.type === -1 ? 'none' : '5px solid' + colorMargin,
          width: '41%',
          marginTop: props.first ? 50 : 0,
          borderRadius: 5,
          height: '50%',
          marginBottom: props.last ? 50 : 0,
          cursor:
            !props.value.selecionado && props.value.type !== 10 && props.value.type !== -1
              ? 'pointer'
              : 'default',
          alignItems: 'center',
          display: 'flex',
          minHeight: props.value.type === 10 ? 79 : ''
        }}
      >
        <div
          className={props.className.cardRecomendacoes}
          onClick={() => {
            props.value.type !== -1 && props.clickCard(props.value);
          }}
          style={{
            borderTopLeftRadius: props.value.selecionado ? 5 : 0,
            borderBottomLeftRadius: props.value.selecionado ? 5 : 0,
            border: props.value.selecionado ? '2px solid ' + colorMargin : '2px solid transparent',
            backgroundColor: props.value.type === -1 ? 'transparent' : color,
            height: props.value.type === -1 ? 0 : 'auto',
            marginLeft: 0,
            width: 'calc(100% - 26px)'
          }}
        >
          <div
            style={{
              width: '100%',
              marginLeft: props.value.selecionado ? 5 : 0
            }}
          >
            {props.value.type === -1 && (
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    width: 20,
                    border: '1px solid ' + colorMargin,
                    height: 0,
                    marginLeft: -12
                  }}
                />
                <div
                  style={{
                    fontWeight: '400',
                    color: colorMargin,
                    paddingRight: 5,
                    paddingLeft: 5,
                    fontSize: 15,
                    marginTop: -11
                  }}
                >
                  {provider.intl.formatMessage({ id: 'hoje' })}
                </div>
                <div
                  style={{
                    width: 'calc(100% - 20px)',
                    border: '1px solid ' + colorMargin,
                    height: 0,
                    marginRight: -10
                  }}
                />
              </div>
            )}
            {props.value.type !== -1 && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    className={props.className.styleRecomendacoes}
                    style={{ color: '#0000000' }}
                  >
                    {tituloCard(props.value.type)}
                  </Typography>

                  <div
                    style={{
                      width: props.value.type === 0 ? 60 : 50,
                      justifyContent: props.value.type === 0 ? 'space-between' : 'flex-end',
                      display:
                        props.clickItem && props.value.type !== 3 && !props.value.selecionado
                          ? 'none'
                          : 'flex'
                    }}
                  >
                    {props.value.type === 0 && (
                      <DoneAll
                        style={{
                          marginLeft: !props.showButton ? 35 : 0,
                          color: 'rgb(0, 0, 0, 0.7)',
                          cursor: 'pointer'
                        }}
                        alt='Editar recomendações'
                        onClick={() => props.clickDone(props.value)}
                      />
                    )}
                    {false &&
                      props.showButton &&
                      props.value.type === 3 &&
                      !props.value.lessInformation && (
                        <Tooltip title={provider.intl.formatMessage({ id: 'ocultarAtividades' })}>
                          <ExpandMore
                            style={{
                              color: 'rgb(0, 0, 0, 0.7)',
                              cursor: 'pointer',
                              marginRight: props.value.selecionado ? 0 : 5
                            }}
                            alt='plantio'
                            onClick={() => props.expandLessMorePlantio(props.value, false)}
                          />
                        </Tooltip>
                      )}
                    {false && props.showButton && props.value.type === 3 && props.value.lessInformation && (
                      <Tooltip title={provider.intl.formatMessage({ id: 'mostrarAtividades' })}>
                        <ExpandLess
                          style={{
                            color: 'rgb(0, 0, 0, 0.7)',
                            cursor: 'pointer',
                            marginRight: props.value.selecionado ? 0 : 5
                          }}
                          alt='plantio'
                          onClick={() => props.expandLessMorePlantio(props.value, true)}
                        />
                      </Tooltip>
                    )}
                    {props.showButton &&
                      props.value.type !== 3 &&
                      props.value.type !== 4 &&
                      props.value.type !== 8 && (
                        <Create
                          style={{
                            color: 'rgb(0, 0, 0, 0.7)',
                            cursor: 'pointer'
                          }}
                          alt='Editar recomendações'
                          onClick={() => props.clickEditar(props.value)}
                        />
                      )}
                  </div>
                </div>

                {props.value.type === 10 && (
                  <Typography
                    className={props.className.styleDosagens}
                    style={{
                      color: '#0000000',
                      marginRight: 4,
                      whiteSpace: 'nowrap',
                      width: 'calc(100% - 30px)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {provider.intl.formatMessage({ id: 'culturas' }) + ': '}

                    {(props.value.info.culturaList || []).map(doc => {
                      count = count + 1;
                      return count < props.value.info.culturaList.length
                        ? '    ' + doc.nmCultura + '    |    '
                        : doc.nmCultura;
                    })}
                  </Typography>
                )}

                {props.value.type !== 10 && (
                  <>
                    <Typography className={props.className.styleDosagens} style={{ color: '#0000000' }}>
                      {provider.intl.formatMessage({ id: 'areaDeCultivo' }) +
                        ' - ' +
                        props.value.areaCultivo}
                    </Typography>
                    {props.value.cultura.nmCultura && (
                      <>
                        <Typography
                          className={props.className.styleDosagens}
                          style={{ color: '#0000000' }}
                        >
                          {props.value.cultura.nmCultura +
                            ' - ' +
                            (props.value.cultivar ? props.value.cultivar.nmCultivarEspec : '')}
                        </Typography>
                      </>
                    )}

                    {props.value.type !== 9 && (
                      <Typography className={props.className.styleDosagens}>
                        {provider.intl.formatMessage({ id: 'talhoes' }) + ': '}

                        {(props.value.info.plantioTalhaoList || []).map(doc => {
                          count = count + 1;
                          return count < props.value.info.plantioTalhaoList.length
                            ? '    ' + doc.talhao.dsTalhao + '    |    '
                            : doc.talhao.dsTalhao;
                        })}
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function tituloCard(type) {
  switch (parseInt(type)) {
    case 0:
      return provider.intl.formatMessage({ id: 'recomendacao' });
    case 1:
      return provider.intl.formatMessage({ id: 'aplicacao' });
    case 2:
      return provider.intl.formatMessage({ id: 'alertasFitossanitarios' });
    case 3:
      return provider.intl.formatMessage({ id: 'plantio' });
    case 4:
      return provider.intl.formatMessage({ id: 'colheita' });
    case 5:
      return provider.intl.formatMessage({ id: 'monitoramento' });
    case 6:
      return provider.intl.formatMessage({ id: 'registroFotografico' });
    case 7:
      return provider.intl.formatMessage({ id: 'registroClimatico' });
    case 8:
      return provider.intl.formatMessage({ id: 'aplicacaoProdutoProibido' });
    case 9:
      return provider.intl.formatMessage({ id: 'auditoria' });
    case 10:
      return provider.intl.formatMessage({ id: 'sazonalidade' });
    default:
      break;
  }
}

function corCard(type) {
  switch (parseInt(type)) {
    case 0:
      return '#007EB6';
    case 1:
      return '#BC6969';
    case 2:
      return '#DD3B3B';
    case 3:
      return '#463766';
    case 4:
      return '#00622B';
    case 5:
      return '#E2883B';
    case 6:
      return '#7DCE87';
    case 7:
      return '#5ED7E2';
    case 8:
      return '#717171';
    case 9:
      return '#A87A44';
    case 10:
      return '#57A844';
    case -1:
      return 'rgb(0, 0, 0, 0.6)';
    default:
      break;
  }
}
/* 
    Componente de cronograma customizado
    type: -1 [ Hoje ], 0 [ Recomendação ], 1 [ Aplicação ], 2 [ Alertas Fitossanitários ], 3 [ Plantio ], 4 [ Colheita ], 5 [ Monitoramento ], 6 [ Registro fotográfico]
*/
class Cronograma extends Component {
  constructor(props) {
    super(props);

    this.itemSelecionado = false;

    this.clickCard = this.clickCard.bind(this);
  }

  clickCard(value) {
    this.itemSelecionado = true;

    if (this.props.clickItem) {
      this.props.clickCard(value);
    }
  }

  render() {
    const { classes } = this.props;
    var lista = this.props.listRecomendacoes;

    return (
      <div>
        {lista.map((value, index) => (
          <Collapse in={!value.stFilterPlantio} key={value.id} style={{ transitionDelay: '100ms' }}>
            <Card
              expandLessMorePlantio={this.props.expandLessMorePlantio}
              index={index}
              showButton={this.props.showButton}
              className={classes}
              key={Math.random()}
              clickItem={this.props.clickItem}
              value={value}
              itemSelecionado={this.itemSelecionado}
              clickCard={this.clickCard}
              clickEditar={this.props.clickEditar}
              clickDone={this.props.clickDone}
              clickVisualizar={this.props.clickVisualizar}
              first={this.props.listRecomendacoes[0] === value}
              showIcon={this.props.showIcon}
              last={this.props.listRecomendacoes[this.props.listRecomendacoes.length - 1] === value}
            />
          </Collapse>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(Cronograma);
