export default {
  relatorio: {
    relatorio: 'Report',
    analitico: 'Analysis',
    regiao: 'Region',
    municipio: 'City',
    sim: 'Yes',
    nao: 'Not',
    carregando: 'Loading...',
    gerandoRelatorio: 'Generating report ...',
    falhaAoGerarRelatorio: 'Failed to generate report',
    dataLancamento: 'Release date of',
    tecnicoResponsavel: 'Responsible technician',
    produtor: 'Producer'
  }
};
