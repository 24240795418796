import URL from "./URL";

const axios = require("axios");
const api = URL.API;

function findAllComunidadesHandler() {
  return axios.get(api + "/comunidade");
}

function findComunidadeById(idCliente) {
  return axios.get(api + "/comunidade/" + idCliente);
}

function deleteComunidadeByIdHandler(idComunidade) {
  return axios.delete(api + "/comunidade/" + idComunidade);
}

function addComunidadeHandler(values) {
  return axios.post(api + "/comunidade", values);
}

function editComunidadeHandler(values) {
  return axios.put(api + "/comunidade", values);
}

function findComunidadeByMunicipioHandler(idMunicipio) {
  return axios.get(api + "/comunidade/municipio/" + idMunicipio);
}

export default {
  findAllComunidades: findAllComunidadesHandler,
  findComunidadeById: findComunidadeById,
  deleteComunidadeById: deleteComunidadeByIdHandler,
  addComunidade: addComunidadeHandler,
  editComunidade: editComunidadeHandler,
  findComunidadeByMunicipio: findComunidadeByMunicipioHandler
};
