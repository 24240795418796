import React from 'react';
import Produtores from './Pessoas/Produtores/Produtores';
import EditarProdutor from './Pessoas/Produtores/EditarProdutor';
import EditarPreCadastro from './Pessoas/Produtores/EditarPreCadastro';
import Clientes from './Admin/Clientes/Clientes';
import EditarClientes from './Admin/Clientes/identificacao/EditarClientes';
import Cultivares from './Produtos/Cultivares/Cultivares';
import EditarCultivares from './Produtos/Cultivares/EditarCultivares';
import FertilizantesFoliares from './Insumos/FertilizantesFoliares/FertilizantesFoliares';
import EditarFertilizantesFoliares from './Insumos/FertilizantesFoliares/EditarFertilizantesFoliares';
import OleosAdjuvantes from './Insumos/OleosAdjuvantes/OleosAdjuvantes';
import EditarOleosAdjuvantes from './Insumos/OleosAdjuvantes/EditarOleosAdjuvantes';
import Comunidade from './Localizacao/Comunidade/Comunidade';
import EditarComunidade from './Localizacao/Comunidade/EditarComunidade';
import Municipios from './Localizacao/Municipios/Municipios';
import EditarMunicipios from './Localizacao/Municipios/EditarMunicipios';
import Nematicidas from './Insumos/Nematicidas/Nematicidas';
import EditarNematicidas from './Insumos/Nematicidas/EditarNematicidas';
import NematicidasMicrobiologicos from './Insumos/NematicidasMicrobiologicos/NematicidasMicrobiologicos';
import EditarNematicidasMicrobiologicos from './Insumos/NematicidasMicrobiologicos/EditarNematicidasMicrobiologicos';
import AgentesBiologicosControle from './Insumos/AgentesBiologicosControle/AgentesBiologicosControle';
import EditaraAgentesBiolgicosControle from './Insumos/AgentesBiologicosControle/EditarAgentesBiologicosControle';
import Culturas from './Produtos/Culturas/Culturas';
import EditarCulturas from './Produtos/Culturas/EditarCulturas';
import BactericidasMicrobiologicos from './Insumos/BactericidasMicrobiologicos/BactericidasMicrobiologicos';
import EditarBactericidasMicrobiologicos from './Insumos/BactericidasMicrobiologicos/EditarBactericidasMicrobiologicos';
import Bactericidas from './Insumos/Bactericidas/Bactericidas';
import EditarBactericidas from './Insumos/Bactericidas/EditarBactericidas';
import Fungicidas from './Insumos/Fungicidas/Fungicidas';
import EditarFungicidas from './Insumos/Fungicidas/EditarFungicidas';
import Inseticidas from './Insumos/Inseticidas/Inseticidas';
import EditarInseticidas from './Insumos/Inseticidas/EditarInseticidas';
import AcaricidasMicroBio from './Insumos/AcaricidasMicrobiologicos/AcaricidasMicrobiologicos';
import EditarAcaricidasMicroBio from './Insumos/AcaricidasMicrobiologicos/EditarAcaricidasMicrobiologicos';
import Herbicidas from './Insumos/Herbicidas/Herbicidas';
import EditarHerbicidas from './Insumos/Herbicidas/EditarHerbicidas';
import Acaricidas from './Insumos/Acaricidas/Acaricidas';
import EditarAcaricidas from './Insumos/Acaricidas/EditarAcaricidas';
import InseticidasMicroBio from './Insumos/InseticidasMicrobiologicos/InseticidasMicrobiologicos';
import EditarInseticidasMicroBio from './Insumos/InseticidasMicrobiologicos/EditarInseticidasMicrobiologicos';
import FungicidasMicroBio from './Insumos/FungicidasMicrobiologicos/FungicidasMicrobiologicos';
import EditarFungicidasMicroBio from './Insumos/FungicidasMicrobiologicos/EditarFungicidasMicrobiologicos';
import Tecnicos from './Pessoas/Tecnicos/Tecnicos';
import EditarTecnicos from './Pessoas/Tecnicos/EditarTecnico';
import Marcas from './Produtos/Marcas/Marcas';
import EditarMarcas from './Produtos/Marcas/EditarMarcas';
import Dashboard from './Dashboard/Dashboard';
import Agenda from './Agenda/Agenda';
import ConsultarCotacoes from './Cotacoes/ConsultarCotacoes';
import PesquisarCotacoes from './Cotacoes/PesquisaDeCotacao';
import EditarCotacoes from './Cotacoes/EditarCotacoes';
import AcompanhamentoProdutor from './Acompanhamento/AcompanhamentoProdutor/AcompanhamentoProdutor';
import LancamentoAplicacao from './Acompanhamento/AcompanhamentoProdutor/LancamentoAplicacao';
import AcompanhamentoTecnico from './Acompanhamento/AcompanhamentoTecnico/AcompanhamentoTecnico';
import EditarPerfil from './Perfil/Perfil';
import Financeiro from './Admin/Financeiro/Financeiro';
import EditarFinanceiro from './Admin/Financeiro/EditarFinanceiro';
import Revenda from './Pessoas/Gerente/Gerente';
import EditarRevenda from './Pessoas/Gerente/EditarGerente';
import Fitossanidade from './Cadastros/Fitossanidade/Fitossanidade';
import EditarFitossanidade from './Cadastros/Fitossanidade/EditarFitossanidade';
import FertilizantesQuimicos from './Insumos/FertilizantesQuimicos/FertilizantesQuimicos';
import EditarFertilizantesQuimicos from './Insumos/FertilizantesQuimicos/EditarFertilizantesQuimicos';
import FertilizantesOrganico from './Insumos/FertilizantesOrganicos/FertilizantesOrganicos';
import EditarFertilizantesOrganico from './Insumos/FertilizantesOrganicos/EditarFertilizantesOrganicos';
import Monitoramento from './Acompanhamento/AcompanhamentoProdutor/Monitoramento';
import RelatorioAgenda from './Agenda/RelatorioAgenda';
import TelaMapa from './Mapa/TelaMapa';
import TelaMapaNDVI from './MapaNdvi/MapaNDVI';
import Plantios from './Cadastros/Plantios/Plantios';
import EditarPlantio from './Cadastros/Plantios/EditarPlantio';
import Especie from './Animal/Especie/Especie';
import EditarEspecie from './Animal/Especie/EditarEspecie';
import Raca from './Animal/Raca/Raca';
import EditarRaca from './Animal/Raca/EditarRaca';
import NaoConformidade from './Cadastros/NaoConformidade/NaoConformidade';
import EditarNaoConformidade from './Cadastros/NaoConformidade/EditarNaoConformidade';
import Auditoria from './Cadastros/Auditoria/Auditoria';
import EditarAuditoria from './Cadastros/Auditoria/EditarAuditoria';
import Sazonalidades from './Cadastros/Sazonalidades/Sazonalidades';
import EditarSazonalidades from './Cadastros/Sazonalidades/EditarSazonalidades';
import Regiao from './Localizacao/Regiao/Regiao';
import EditarRegiao from './Localizacao/Regiao/EditarRegiao';
import RelatorioProdutoresPotencial from './Relatorios/RelatorioProdutoresPotencial/RelatorioProdutoresPotencial';
import RelatorioRelacaoTalhao from './Relatorios/RelatorioRelacaoTalhao/RelatorioRelacaoTalhao';
import RelatorioAnaliseRisco from './Relatorios/RelatorioAnaliseRisco/RelatorioAnaliseRisco';
import RelatorioAnaliseRiscosAnalitico from './Relatorios/RelatorioAnaliseRiscosAnalitico/RelatorioAnaliseRiscosAnalitico';
import FichaProdutor from './Relatorios/FichaProdutor/FichaProdutor';
import RelatorioListaProdutores from './Relatorios/RelatorioListaProdutores/RelatorioListaProdutores';
import RelatorioProdutividadeGraos from './Relatorios/RelatorioProdutividadeGraos/RelatorioProdutividadeGraos';
import RelatorioHistoricoProdutividade from './Relatorios/RelatorioHistoricoProdutividade/RelatorioHistoricoProdutividade';
import RelatorioVisitas from './Relatorios/RelatorioVisitas/RelatorioVisitas';
import VisitasProdutor from './Acompanhamento/VisitasProdutor/VisitasProdutor';
import Safras from './Cadastros/Safras/Safras';
import EditarSafra from './Cadastros/Safras/EditarSafra';
import TipoEcosocial from './Cadastros/Certificacoes/Ecosocial/TipoEcosocial';
import EditarTipoEcosocial from './Cadastros/Certificacoes/Ecosocial/EditarTipoEcosocial';
import Homeopaticos from './Insumos/Homeopaticos/Homeopaticos';
import EditarHomeopaticos from './Insumos/Homeopaticos/EditarHomeopaticos';
import Inoculantes from './Insumos/Inoculantes/Inoculantes';
import EditarInoculantes from './Insumos/Inoculantes/EditarInoculantes';
import Galeria from './Acompanhamento/Galeria/Galeria';
import RelatorioPlantios from './Relatorios/RelatorioPlantio/RelatorioPlantio';
import Classificacao from './Classificacao/Classificacao';
import EditarClassificacao from './Classificacao/EditarClassificacao';
import CertificacaoOrganica from './Cadastros/Certificacoes/CertificacaoOrganica/CertificacaoOrganica';
import EditarCertificacaoOrganica from './Cadastros/Certificacoes/CertificacaoOrganica/EditarCertificacaoOrganica';
import Agencia from './Agencia/Agencia';
import FormaAplicacao from './Cadastros/FormaAplicacao/FormaAplicacao';
import EditarFormaAplicacao from './Cadastros/FormaAplicacao/EditarFormaAplicacao';
import EditarAgencia from './Agencia/EditarAgencia';
import SancaoCategoria from './Cadastros/SancaoCategoria/SancaoCategoria';
import EditarSancaoCategoria from './Cadastros/SancaoCategoria/EditarSancaoCategoria';
import MedidaCorretiva from './Cadastros/MedidaCorretiva/MedidaCorretiva';
import EditarMedidaCorretiva from './Cadastros/MedidaCorretiva/EditarMedidaCorretiva';
import AgendaVisitas from './AgendaVisitas/AgendaVisitas';
import AnaliseOGM from './Cadastros/AnaliseOGM/AnaliseOGM';
import EditarAnaliseOGM from './Cadastros/AnaliseOGM/EditarAnaliseOGM';

const routes = [
  {
    path: '/app/forma-aplicacao',
    exact: true,
    main: () => <FormaAplicacao />
  },
  {
    path: '/app/forma-aplicacao/novo',
    main: () => <EditarFormaAplicacao />
  },
  {
    path: '/app/forma-aplicacao/editar/:id',
    main: () => <EditarFormaAplicacao />
  },
  {
    path: '/app/pesquisar-cotacoes/editar/:id',
    main: () => <EditarCotacoes />
  },
  {
    path: '/app/pesquisar-cotacoes/novo',
    main: () => <EditarCotacoes />
  },
  {
    path: '/app/consultar-cotacoes',
    exact: true,
    main: () => <ConsultarCotacoes />
  },
  {
    path: '/app/pesquisar-cotacoes',
    exact: true,
    main: () => <PesquisarCotacoes />
  },
  {
    path: '/app',
    exact: true,
    dashboard: 'Dashboard',
    main: () => <Dashboard />
  },
  {
    path: '/app/produtores',
    exact: true,
    main: () => <Produtores />
  },
  {
    path: '/app/produtores/novo',
    exact: true,
    main: () => <EditarProdutor />
  },
  {
    path: '/app/produtores/editar/:id',
    exact: true,
    main: () => <EditarProdutor />
  },
  {
    path: '/app/produtores/editar/:id/pre-cadastro/:pre',
    exact: true,
    main: () => <EditarProdutor />
  },
  {
    path: '/app/pre-cadastro/novo',
    exact: true,
    main: () => <EditarPreCadastro />
  },
  {
    path: '/app/pre-cadastro/editar/:id',
    exact: true,
    main: () => <EditarPreCadastro />
  },
  {
    path: '/app/produtores/usuario/:perfil',
    exact: true,
    main: () => <EditarProdutor />
  },
  {
    path: '/app/financeiro',
    exact: true,
    main: () => <Financeiro />
  },
  {
    path: '/app/financeiro/editar/:id',
    exact: true,
    main: () => <EditarFinanceiro />
  },
  {
    path: '/app/clientes',
    exact: true,
    main: () => <Clientes />
  },
  {
    path: '/app/clientes/editar/:id',
    main: () => <EditarClientes />
  },
  {
    path: '/app/clientes/novo',
    main: () => <EditarClientes />
  },
  {
    path: '/app/nematicidas-microbiologicos',
    exact: true,
    main: () => <NematicidasMicrobiologicos />
  },
  {
    path: '/app/nematicidas-microbiologicos/editar/:id',
    main: () => <EditarNematicidasMicrobiologicos />
  },
  {
    path: '/app/nematicidas-microbiologicos/novo',
    main: () => <EditarNematicidasMicrobiologicos />
  },
  {
    path: '/app/fertilizantes',
    exact: true,
    main: () => <FertilizantesFoliares />
  },
  {
    path: '/app/fertilizantes/editar/:id',
    main: () => <EditarFertilizantesFoliares />
  },
  {
    path: '/app/fertilizantes/novo',
    main: () => <EditarFertilizantesFoliares />
  },
  {
    path: '/app/oleos',
    exact: true,
    main: () => <OleosAdjuvantes />
  },
  {
    path: '/app/oleos/editar/:id',
    main: () => <EditarOleosAdjuvantes />
  },
  {
    path: '/app/oleos/novo',
    main: () => <EditarOleosAdjuvantes />
  },
  {
    path: '/app/bactericidas-microbiologicos',
    exact: true,
    main: () => <BactericidasMicrobiologicos />
  },
  {
    path: '/app/bactericidas-microbiologicos/editar/:id',
    main: () => <EditarBactericidasMicrobiologicos />
  },
  {
    path: '/app/bactericidas-microbiologicos/novo',
    main: () => <EditarBactericidasMicrobiologicos />
  },
  {
    path: '/app/inseticidas',
    exact: true,
    main: () => <Inseticidas />
  },
  {
    path: '/app/inseticidas/editar/:id',
    main: () => <EditarInseticidas />
  },
  {
    path: '/app/inseticidas/novo',
    main: () => <EditarInseticidas />
  },
  {
    path: '/app/inseticidas-microbiologicos',
    exact: true,
    main: () => <InseticidasMicroBio />
  },
  {
    path: '/app/inseticidas-microbiologicos/editar/:id',
    main: () => <EditarInseticidasMicroBio />
  },
  {
    path: '/app/inseticidas-microbiologicos/novo',
    main: () => <EditarInseticidasMicroBio />
  },
  {
    path: '/app/homeopaticos',
    exact: true,
    main: () => <Homeopaticos />
  },
  {
    path: '/app/homeopatico/editar/:id',
    main: () => <EditarHomeopaticos />
  },
  {
    path: '/app/homeopatico/novo',
    main: () => <EditarHomeopaticos />
  },
  {
    path: '/app/inoculantes',
    exact: true,
    main: () => <Inoculantes />
  },
  {
    path: '/app/inoculantes/editar/:id',
    main: () => <EditarInoculantes />
  },
  {
    path: '/app/inoculantes/novo',
    main: () => <EditarInoculantes />
  },
  {
    path: '/app/acaricidas',
    exact: true,
    main: () => <Acaricidas />
  },
  {
    path: '/app/acaricidas/editar/:id',
    main: () => <EditarAcaricidas />
  },
  {
    path: '/app/acaricidas/novo',
    main: () => <EditarAcaricidas />
  },
  {
    path: '/app/acaricidas-microbiologicos',
    exact: true,
    main: () => <AcaricidasMicroBio />
  },
  {
    path: '/app/acaricidas-microbiologicos/editar/:id',
    main: () => <EditarAcaricidasMicroBio />
  },
  {
    path: '/app/acaricidas-microbiologicos/novo',
    main: () => <EditarAcaricidasMicroBio />
  },
  {
    path: '/app/bactericidas',
    exact: true,
    main: () => <Bactericidas />
  },
  {
    path: '/app/bactericidas/editar/:id',
    main: () => <EditarBactericidas />
  },
  {
    path: '/app/bactericidas/novo',
    main: () => <EditarBactericidas />
  },
  {
    path: '/app/fungicidas',
    exact: true,
    main: () => <Fungicidas />
  },
  {
    path: '/app/fungicidas/editar/:id',
    main: () => <EditarFungicidas />
  },
  {
    path: '/app/fungicidas/novo',
    main: () => <EditarFungicidas />
  },
  {
    path: '/app/tecnicos',
    exact: true,
    main: () => <Tecnicos />
  },
  {
    path: '/app/tecnicos/editar/:id',
    main: () => <EditarTecnicos />
  },
  {
    path: '/app/tecnicos/novo',
    main: () => <EditarTecnicos />
  },
  {
    path: '/app/fungicidas-microbiologicos',
    exact: true,
    main: () => <FungicidasMicroBio />
  },
  {
    path: '/app/fungicidas-microbiologicos/editar/:id',
    main: () => <EditarFungicidasMicroBio />
  },
  {
    path: '/app/fungicidas-microbiologicos/novo',
    main: () => <EditarFungicidasMicroBio />
  },
  {
    path: '/app/herbicidas',
    exact: true,
    main: () => <Herbicidas />
  },
  {
    path: '/app/herbicidas/editar/:id',
    main: () => <EditarHerbicidas />
  },
  {
    path: '/app/herbicidas/novo',
    main: () => <EditarHerbicidas />
  },
  {
    path: '/app/cultivar',
    exact: true,
    main: () => <Cultivares />
  },
  {
    path: '/app/cultivar/editar/:id',
    main: () => <EditarCultivares />
  },
  {
    path: '/app/cultivar/novo',
    main: () => <EditarCultivares />
  },
  {
    path: '/app/comunidades',
    exact: true,
    main: () => <Comunidade />
  },
  {
    path: '/app/comunidades/novo',
    main: () => <EditarComunidade />
  },
  {
    path: '/app/comunidades/editar/:id',
    main: () => <EditarComunidade />
  },
  {
    path: '/app/gerente/editar/:id',
    main: () => <EditarRevenda />
  },
  {
    path: '/app/gerente',
    exact: true,
    main: () => <Revenda />
  },
  {
    path: '/app/gerente/novo',
    main: () => <EditarRevenda />
  },

  {
    path: '/app/municipios',
    exact: true,
    main: () => <Municipios />
  },
  {
    path: '/app/municipios/novo',
    main: () => <EditarMunicipios />
  },
  {
    path: '/app/municipios/editar/:id',
    main: () => <EditarMunicipios />
  },
  {
    path: '/app/nematicidas',
    exact: true,
    main: () => <Nematicidas />
  },
  {
    path: '/app/nematicidas/novo',
    main: () => <EditarNematicidas />
  },
  {
    path: '/app/nematicidas/editar/:id',
    main: () => <EditarNematicidas />
  },
  {
    path: '/app/agentes-biologicos-controle',
    exact: true,
    main: () => <AgentesBiologicosControle />
  },
  {
    path: '/app/agentes-biologicos-controle/novo',
    main: () => <EditaraAgentesBiolgicosControle />
  },
  {
    path: '/app/agentes-biologicos-controle/editar/:id',
    main: () => <EditaraAgentesBiolgicosControle />
  },
  {
    path: '/app/culturas',
    exact: true,
    main: () => <Culturas />
  },
  {
    path: '/app/culturas/novo',
    main: () => <EditarCulturas />
  },
  {
    path: '/app/culturas/editar/:id',
    main: () => <EditarCulturas />
  },
  {
    path: '/app/marcas',
    exact: true,
    main: () => <Marcas />
  },
  {
    path: '/app/marcas/novo',
    main: () => <EditarMarcas />
  },
  {
    path: '/app/marcas/editar/:id',
    main: () => <EditarMarcas />
  },
  {
    path: '/app/agenda',
    exact: true,
    main: () => <Agenda />
  },
  {
    path: '/app/relatorio-agenda',
    exact: true,
    main: () => <RelatorioAgenda />
  },
  {
    path: '/app/acompanhamento-produtor/editar/:id',
    exact: true,
    main: () => <AcompanhamentoProdutor />
  },
  {
    path: '/app/acompanhamento-produtor/editar/:id/:lancamento/novo/:idPlantio',
    exact: true,
    main: () => <LancamentoAplicacao />
  },
  {
    path: '/app/acompanhamento-produtor/editar/:id/:lancamento/novo',
    exact: true,
    main: () => <LancamentoAplicacao />
  },
  {
    path: '/app/acompanhamento-produtor/editar/:id/:lancamento/editar/:idAplicacao',
    exact: true,
    main: () => <LancamentoAplicacao />
  },
  {
    path: '/app/acompanhamento-produtor/monitoramento/editar/:id/novo',
    exact: true,
    main: () => <Monitoramento />
  },
  {
    path: '/app/acompanhamento-produtor/monitoramento/editar/:id/editar/:idMonitoramento',
    main: () => <Monitoramento />
  },
  {
    path: '/app/acompanhamento',
    exact: true,
    main: () => <AcompanhamentoTecnico />
  },
  {
    path: '/app/perfil',
    main: () => <EditarPerfil />
  },
  {
    path: '/app/acompanhamento-produtor/:idProdutor',
    exact: true,
    main: () => <AcompanhamentoProdutor />
  },
  {
    path: '/app/fitossanidade',
    exact: true,
    main: () => <Fitossanidade />
  },
  {
    path: '/app/fitossanidade/novo',
    main: () => <EditarFitossanidade />
  },
  {
    path: '/app/fitossanidade/editar/:id',
    main: () => <EditarFitossanidade />
  },
  {
    path: '/app/fertilizantes-organicos',
    exact: true,
    main: () => <FertilizantesOrganico />
  },
  {
    path: '/app/fertilizantes-organicos/editar/:id',
    main: () => <EditarFertilizantesOrganico />
  },
  {
    path: '/app/fertilizantes-organicos/novo',
    main: () => <EditarFertilizantesOrganico />
  },
  {
    path: '/app/fertilizantes-quimicos',
    exact: true,
    main: () => <FertilizantesQuimicos />
  },
  {
    path: '/app/fertilizantes-quimicos/editar/:id',
    main: () => <EditarFertilizantesQuimicos />
  },
  {
    path: '/app/fertilizantes-quimicos/novo',
    main: () => <EditarFertilizantesQuimicos />
  },
  {
    path: '/app/mapa',
    main: () => <TelaMapa />
  },
  {
    path: '/app/mapandvi',
    main: () => <TelaMapaNDVI />
  },
  {
    path: '/app/plantios',
    exact: true,
    main: () => <Plantios />
  },
  {
    path: '/app/plantios/:idProdutor',
    exact: true,
    main: () => <Plantios />
  },
  {
    path: '/app/plantios/:idProdutor/editar/:id',
    exact: true,
    main: () => <EditarPlantio />
  },
  {
    path: '/app/plantios/:idProdutor/novo',
    exact: true,
    main: () => <EditarPlantio />
  },
  {
    path: '/app/especie',
    exact: true,
    main: () => <Especie />
  },
  {
    path: '/app/especie/editar/:id',
    main: () => <EditarEspecie />
  },
  {
    path: '/app/especie/novo',
    main: () => <EditarEspecie />
  },
  {
    path: '/app/raca',
    exact: true,
    main: () => <Raca />
  },
  {
    path: '/app/raca/editar/:id',
    main: () => <EditarRaca />
  },
  {
    path: '/app/raca/novo',
    main: () => <EditarRaca />
  },
  {
    path: '/app/nao-conformidade',
    exact: true,
    main: () => <NaoConformidade />
  },
  {
    path: '/app/nao-conformidade/editar/:id',
    main: () => <EditarNaoConformidade />
  },
  {
    path: '/app/nao-conformidade/novo',
    main: () => <EditarNaoConformidade />
  },
  {
    path: '/app/auditoria',
    exact: true,
    main: () => <Auditoria />
  },
  {
    path: '/app/auditoria/editar/:id',
    exact: true,
    main: () => <EditarAuditoria />
  },
  {
    path: '/app/auditoria/editar/:id/:acompanhamento',
    exact: true,
    main: () => <EditarAuditoria />
  },
  {
    path: '/app/auditoria/novo',
    main: () => <EditarAuditoria />
  },
  {
    path: '/app/sazonalidade',
    exact: true,
    main: () => <Sazonalidades />
  },
  {
    path: '/app/sazonalidade/editar/:idCultura',
    main: () => <EditarSazonalidades />
  },
  {
    path: '/app/regiao',
    exact: true,
    main: () => <Regiao />
  },
  {
    path: '/app/regiao/editar/:id',
    main: () => <EditarRegiao />
  },
  {
    path: '/app/regiao/novo',
    main: () => <EditarRegiao />
  },
  {
    path: '/app/relatorio/plantio',
    exact: true,
    main: () => <RelatorioPlantios />
  },
  {
    path: '/app/relatorio/produtores-potencial',
    exact: true,
    main: () => <RelatorioProdutoresPotencial />
  },
  {
    path: '/app/relatorio/relacao-talhao',
    exact: true,
    main: () => <RelatorioRelacaoTalhao />
  },
  {
    path: '/app/relatorio/analise-risco',
    exact: true,
    main: () => <RelatorioAnaliseRisco />
  },
  {
    path: '/app/relatorio/analise-riscos-analitico',
    exact: true,
    main: () => <RelatorioAnaliseRiscosAnalitico />
  },
  {
    path: '/app/relatorio/visitas',
    exact: true,
    main: () => <RelatorioVisitas />
  },
  {
    path: '/app/ficha/produtor',
    exact: true,
    main: () => <FichaProdutor />
  },
  {
    path: '/app/relatorio/lista-produtores',
    exact: true,
    main: () => <RelatorioListaProdutores />
  },
  {
    path: '/app/relatorio/produtividade-graos',
    exact: true,
    main: () => <RelatorioProdutividadeGraos />
  },
  {
    path: '/app/relatorio/historico-produtividade',
    exact: true,
    main: () => <RelatorioHistoricoProdutividade />
  },
  {
    path: '/app/resumo-visitas',
    exact: true,
    main: () => <VisitasProdutor />
  },
  {
    path: '/app/safras',
    exact: true,
    main: () => <Safras />
  },
  {
    path: '/app/safra/novo',
    exact: true,
    main: () => <EditarSafra />
  },
  {
    path: '/app/safra/editar/:id',
    exact: true,
    main: () => <EditarSafra />
  },
  {
    path: '/app/certificacao-ecosocial',
    exact: true,
    main: () => <TipoEcosocial />
  },
  {
    path: '/app/certificacao-ecosocial/novo',
    exact: true,
    main: () => <EditarTipoEcosocial />
  },
  {
    path: '/app/certificacao-ecosocial/editar/:id',
    exact: true,
    main: () => <EditarTipoEcosocial />
  },
  {
    path: '/app/galeria',
    exact: true,
    main: () => <Galeria />
  },
  {
    path: '/app/classificacao',
    exact: true,
    main: () => <Classificacao />
  },
  {
    path: '/app/classificacao/editar/:id',
    main: () => <EditarClassificacao />
  },
  {
    path: '/app/classificacao/novo',
    main: () => <EditarClassificacao />
  },
  {
    path: '/app/certificacao-organica',
    exact: true,
    main: () => <CertificacaoOrganica />
  },
  {
    path: '/app/certificacao-organica/novo',
    exact: true,
    main: () => <EditarCertificacaoOrganica />
  },
  {
    path: '/app/certificacao-organica/editar/:id',
    exact: true,
    main: () => <EditarCertificacaoOrganica />
  },
  {
    path: '/app/agencia',
    exact: true,
    main: () => <Agencia />
  },
  {
    path: '/app/agencia/novo',
    exact: true,
    main: () => <EditarAgencia />
  },
  {
    path: '/app/agencia/editar/:id',
    exact: true,
    main: () => <EditarAgencia />
  },
  {
    path: '/app/sancao-categoria',
    exact: true,
    main: () => <SancaoCategoria />
  },
  {
    path: '/app/sancao-categoria/novo',
    main: () => <EditarSancaoCategoria />
  },
  {
    path: '/app/sancao-categoria/editar/:id',
    main: () => <EditarSancaoCategoria />
  },
  {
    path: '/app/medida-corretiva',
    exact: true,
    main: () => <MedidaCorretiva />
  },
  {
    path: '/app/medida-corretiva/novo',
    main: () => <EditarMedidaCorretiva />
  },
  {
    path: '/app/medida-corretiva/editar/:id',
    main: () => <EditarMedidaCorretiva />
  },
  {
    path: '/app/acompanhamento-visitas',
    exact: true,
    main: () => <AgendaVisitas />
  },
  {
    path: '/app/analise-ogm',
    exact: true,
    main: () => <AnaliseOGM />
  },
  {
    path: '/app/analise-ogm/novo',
    main: () => <EditarAnaliseOGM />
  },
  {
    path: '/app/analise-ogm/editar/:id',
    main: () => <EditarAnaliseOGM />
  }
];

export default routes;
