import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllProdutorHandler() {
  return axios.get(api + '/produtor');
}

function findAllProdutorWithPreHandler() {
  return axios.get(api + '/produtor/all');
}

function findProdutorByIdHandler(idProdutor) {
  return axios.get(api + '/produtor/' + idProdutor);
}

function addProdutorHandler(values) {
  return axios.post(api + '/produtor', values);
}

function addPreCadastroHandler(values) {
  return axios.post(api + '/produtor/pre-cadastro', values);
}

function updateProdutorHandler(values) {
  return axios.put(api + '/produtor', values);
}

function updatePreCadastroHandler(values) {
  return axios.put(api + '/produtor/pre-cadastro', values);
}

function efetivarPreCadastroHandler(values) {
  return axios.put(api + '/produtor/pre-cadastro/efetivar', values);
}

function deleteProdutorByIdHandler(idProdutor) {
  return axios.delete(api + '/produtor/' + idProdutor);
}

function deletePreCadastroByIdHandler(idProdutor) {
  return axios.delete(api + '/produtor/pre-cadastro/' + idProdutor);
}

function findProdutorByPerfilHandler() {
  return axios.get(api + '/produtor/perfil');
}

function findAllProdutorByUsuarioHandler() {
  return axios.get(api + '/produtor/usuario');
}

function findAllProdutoresForMapHandler() {
  return axios.get(api + '/produtor/usuario/mapa');
}

export default {
  findProdutor: findAllProdutorHandler,
  findProdutorById: findProdutorByIdHandler,
  addProdutor: addProdutorHandler,
  updateProdutor: updateProdutorHandler,
  deleteProdutorById: deleteProdutorByIdHandler,
  findProdutorByPerfil: findProdutorByPerfilHandler,
  findAllProdutorByUsuario: findAllProdutorByUsuarioHandler,
  addPreCadastro: addPreCadastroHandler,
  updatePreCadastro: updatePreCadastroHandler,
  findAllProdutorWithPre: findAllProdutorWithPreHandler,
  deletePreCadastroById: deletePreCadastroByIdHandler,
  efetivarPreCadastro: efetivarPreCadastroHandler,
  findAllProdutoresForMap: findAllProdutoresForMapHandler
};
