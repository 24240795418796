import URL from './URL';

const axios = require('axios');
const admin = URL.ADM;

function findAllClienteHandler() {
  return axios.get(admin + '/cliente');
}

function findClienteByIdHandler(idCliente) {
  return axios.get(admin + '/cliente/' + idCliente);
}

function addClienteHandler(values) {
  let data = validation(values);

  return axios.post(admin + '/cliente', data);
}

function updateClienteHandler(values) {
  const data = validation(values);
  data.idCliente = values.idCliente;
  data.nmSchema = values.nmSchema;

  return axios.put(admin + '/cliente', data);
}

function deleteClienteHandler(idCliente) {
  return axios.delete(admin + '/cliente/' + idCliente);
}

function validation(values) {
  let data = {
    clienteContatoList: values.clienteContatoList,
    clienteEnderecoList: values.clienteEnderecoList,
    clienteUsuarioList: values.usuarioList,
    dsEmail: values.dsEmail,
    dsRazaoSocial: values.dsRazaoSocial,
    nmCliente: values.dsNomeFantasia,
    nrDocumento: values.dsCnpj,
    nrTelefone: values.nrTelefone,
    pais: values.pais,
    stCliente: values.stCliente,
    stOrganica: values.stOrganica,
    stCooperativa: values.stCooperativa,
    tpCooperativa: values.tpCooperativa !== '' ? values.tpCooperativa : null,
    dsLogo: values.dsLogo,
    dsLogoBase64: values.dsLogoBase64
  };

  if (values.stCliente === 0) {
    data.clienteInativacao = {
      dsMotivo: values.dsMotivoInativacao,
      dhInativacao: values.dhInativacao
    };
  }

  return data;
}

export default {
  findCliente: findAllClienteHandler,
  findClienteById: findClienteByIdHandler,
  addCliente: addClienteHandler,
  updateCliente: updateClienteHandler,
  deleteCliente: deleteClienteHandler
};
