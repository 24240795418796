export default {
  relacaoTalhao: {
    relatorio: 'Relatório',
    relatorioRelacaoTalhao: 'Relatório de relação de talhões',
    regiao: 'Região',
    municipio: 'Município',
    carregando: 'Carregando...',
    gerandoRelatorio: 'Gerando relatório...',
    falhaAoGerarRelatorio: 'Falha ao gerar relatório',
    ecosocial: 'Ecosocial',
    tecnicoResponsavel: 'Técnico responsável',
    naoPossui: 'Não possui',
    produtor: 'Produtor',
    sim: 'Sim',
    nao: 'Não',
    statusCertificacaoOrganica: 'Status da certificação orgânica',
    convencional: 'Convencional',
    emConversao: 'Em conversão',
    brasil: 'BR (Brasil)',
    comunidadeEuropeia: 'CE (Comunidade Européia)',
    eua: 'NOP (Estados Unidos)',
    baixarCSV: 'Baixar CSV',
    status: 'Situação',
    ativo: 'Ativo',
    inativo: 'Inativo'
  }
};
