import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import formatMessage from './i18n/formatMessage';
import { findCertificacaoById, save, update } from '@resources/CertificacaoOrganica';
import { style, theme } from './CertificacaoOrganica.styles';
import Checkbox from '@material-ui/core/Checkbox';


const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Screen de edição ou cadastro de uma certificação orgânica
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarCertificacaoOrganica(props) {
  const { classes } = props;

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState({ nmTipoCertificacao: '' });
  const [isLoading, setLoading] = useState(false);
  const [stTipoCertificacao, setStTipoCertificacao] = useState(true)

  useEffect(() => {
    const title = 'certificacao.cadastroDeCertificacao';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    if (props.match.params.id !== undefined) {
      setEnableReinitialize(true);
    }
  }, []);

  useEffect(
    () => {
      if (!enableReinitialize) return;
      setLoading(true);

      findCertificacaoById(props.match.params.id)
        .then(res => setInitialValues(res.data))
        .catch(err =>
          swal(formatMessage('certificacao.erroAoBuscarDados'), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          })
        )
        .finally(() => {
          setEnableReinitialize(false);
          setLoading(false);
        });
    },
    [enableReinitialize]
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize={enableReinitialize}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        nmTipoCertificacao: Yup.string().required(formatMessage('certificacao.campoObrigatorio'))
      })}
      onSubmit={handleSubmitFormik}
      render={({ values, handleSubmit, errors, touched, handleBlur, handleChange }) => (
        <MuiThemeProvider theme={theme}>
          {isLoading && <Loading />}

          <div style={{ width: '100%' }}>
            <CardIcon titulo={formatMessage('certificacao.identificacao')}>
              <Grid container style={{ height: 70, marginTop: 30 }}>
                <Grid item xs={6}>
                  <TextField
                    label={formatMessage('certificacao.descricao')}
                    name='nmTipoCertificacao'
                    inputProps={{ maxLength: 20 }}
                    value={values.nmTipoCertificacao}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nmTipoCertificacao && touched.nmTipoCertificacao}
                    helperText={
                      errors.nmTipoCertificacao && touched.nmTipoCertificacao ? errors.nmTipoCertificacao : null
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <div
                    className={classes.switch}
                    onClick={() => setStTipoCertificacao(!stTipoCertificacao)}
                  >
                    <Checkbox
                      checked={stTipoCertificacao}
                      value='stTipoCertificacao'
                      color='primary'
                    />
                    <label style={{ color: '#000000' }}>
                      {formatMessage('certificacao.ativo')}
                    </label>
                  </div>
                </Grid>
              </Grid>
            </CardIcon>
          </div>

          <div className={classes.fab}>
            <ButtonFABMenu
              icon={iconMenuPontos}
              actions={[
                {
                  icon: <Check style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('certificacao.salvar'),
                  onClickIcon: handleSubmit,
                  color: '#42ADE8'
                },
                {
                  icon: <Close style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('certificacao.cancelar'),
                  onClickIcon: () => props.history.push('/app/certificacao-organica'),
                  color: '#F33A30'
                }
              ]}
            />
          </div>
        </MuiThemeProvider>
      )}
    />
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {*} values - Valores do submit
   */
  function handleSubmitFormik(values) {
    setLoading(true);
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(
          `certificacao.${props.match.params.id === undefined ? 'salvando' : 'atualizando'}`
        )
      }
    );

    values.idTipoCertificacao = +props.match.params.id || null;
    values.stTipoCertificacao = stTipoCertificacao ? 1 : 0;

    let request;
    if (props.match.params.id === undefined) {
      request = save(values);
    } else {
      request = update(values);
    }

    request
      .then(res => {
        swal(formatMessage('certificacao.certificacaoSalvoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
          }
        });

        props.history.push('/app/certificacao-organica');
      })
      .catch(err => {
        swal(formatMessage('certificacao.erroAoSalvar'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });

        setLoading(false);
      });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarCertificacaoOrganica)));
