export default {
  acompanhamentoProdutor: {
    descricaoNaoConformidade: 'Descrição da não conformidade',
    cadastroDeProdutor: 'Cadastro de Produtor',
    produtores: 'Produtores',
    identificacao: ' Identificação',
    nome: 'Nome',
    telefone: 'Telefone',
    comunidade: 'Comunidade',
    municipio: 'Município',
    uf: 'UF',
    email: 'E-mail',
    nivel: 'Nível tecnológico',
    tecnicoResponsavel: 'Técnico responsável',
    ativo: 'Ativo',
    codigo: 'Código',
    cidade: 'Cidade',
    estado: 'UF',
    descricao: 'Descrição',
    areaCultivo: 'Área total de cultivo(Ha)',
    areaTotaldeTalhoes: 'Área total de talhão',
    dataVisita: 'Data da visita',
    hora: 'Hora',
    tecnico: 'Técnico',
    dataPlantio: 'Data do plantio',
    culturas: 'Cultura',
    dataColheita: 'Data da colheita',
    colheita: 'Colheita',
    produtividade: 'Produtividade(Sacas/Ha)',
    areaDeCultivo: 'Área de cultivo',
    talhoes: 'Talhões',
    visitas: 'Visitas',
    plantios: 'Plantios',
    status: 'Situação',
    inativo: 'Inativo',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    gravar: 'Gravar',
    campoObrigatorio: 'Campo Obrigatório',
    desejaExcluirAreaCultivo: 'Deseja excluir a área de cultivo selecionada?',
    desejaExcluirTalhao: 'Deseja excluir o talhão selecionado?',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    areaCultivoRemovidoComSucesso: 'Área de cultivo removido com sucesso',
    talhaoRemovidoComSucesso: 'Talhão removido com sucesso',
    medio: 'Médio',
    alto: 'Alto',
    baixo: 'Baixo',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    produtorCadastradoComSucesso: 'Produtor cadastrado com sucesso',
    produtorEditadoComSucesso: 'Produtor editado com sucesso',
    falhaAoCadastrarProdutor: 'Falha ao cadastrar produtor',
    falhaAoEditarProdutor: 'Falha ao editar produtor',
    emailJaCadastrado: 'Esse email já está cadastrado',
    erroAoCarregarDados: 'Erro ao carregar os dados',
    aplicacoes: 'Aplicações',
    dataAplicacao: 'Data para aplicação',
    tipo: 'Tipo',
    marca: 'Marca',
    produto: 'Produto',
    talhao: 'Talhão',
    hectares: 'Hectares',
    acaricida: 'Acaricida',
    acaricidaMicrobiologico: 'Acaricida microbiológico',
    agenteBiologicoControle: 'Agente biológico',
    bactericida: 'Bactericida',
    bactericidaMicrobiologico: 'Bactericida microbiológico',
    herbicida: 'Herbicida',
    fungicida: 'Fungicida',
    fungicidaMicrobiologico: 'Fungicida microbiológico',
    inseticida: 'Inseticida',
    inseticidaMicrobiologico: 'Inseticida microbiológico',
    nematicida: 'Nematicida',
    nematicidaMicrobiologico: 'Nematicida microbiológico',
    fertilizanteFoliar: 'Fertilizante foliar',
    talhaoDuplicado: 'Talhão duplicado',
    plantioCadastradoComSucesso: ' Plantio cadastrado com sucesso',
    desejaExcluirAplicacao: 'Deseja excluir aplicação?',
    aplicacaoRemovidoComSucesso: 'Aplicação removida com sucesso',
    naoFoiPossivelEditar: 'Não foi possível editar',
    naoFoiPossivelInserir: 'Não foi possível inserir',
    plantioRemovidoComSucesso: 'Plantio removido com sucesso',
    erroAoRemoverPlantio: 'Erro ao remover plantio',
    desejaExcluirPlantio: 'Deseja excluir plantio?',
    selecionarTodos: 'Selecionar todos',
    plantioEditadoComSucesso: 'Plantio editado com sucesso',
    areaTotalSelecionada: 'Área total selecionada(Ha)',
    talhoesAdicionados: 'Talhões adicionados',
    areaPlantio: 'Área de plantio',
    acompanhamentoNaoCadastrado: 'Acompanhamento de plantio não cadastrado ainda',
    acompanhamentoPlantio: 'Acompanhamento de plantio',
    acompanhamentoProdutor: 'Acompanhamento ao produtor',
    areaTanque: 'Área por tanque',
    nrQuantidade: 'Quantidade',
    produtos: 'Produtos',
    lancamentoAplicacao: 'Lançamento de aplicação realizada',
    dsUnMedida: 'Un. de medida',
    dsRecomendacaoAdicional: 'Recomendação adicional',
    ordem: 'Ordem',
    dose: 'Dose por tanque',
    tipoPesticida: 'Tipo de produto',
    adicionarProdutos: 'Adicionar produtos',
    areaTotal: 'Área total(Ha)',
    lancamentoCadastrado: 'Lançamento cadastrado com sucesso',
    lancamentoAtualizado: 'Lançamento atualizado com sucesso',
    falhaAoCadastrarLancamento: 'Falha ao cadastrar lançamento',
    falhaAoAtualizarLancamento: 'Falha ao atualizar lançamento',
    unMedida: 'Un. de medida',
    recomendacoesAdicionais: 'Recomendações adicionais',
    visualizarRecomendacoes: 'Visualizar recomendações',
    tpProduto: 'Tipo de produto',
    fechar: 'Fechar',
    aplicarNosTalhoes: 'Aplicar nos talhões',
    talhoesUtilizados: 'Talhões utilizados',
    falhaAoCarregarAcompanhamento: 'Falha ao carregar acompanhamento',
    desejaExcluirProduto: 'Deseja excluir produto?',
    produtoRemovidoComSucesso: 'Produto removido com sucesso',
    lancamentoRecomendacao: 'Lançamento de recomendação de aplicação',
    cadastrarAplicacao: 'Cadastrar aplicação',
    cadastrarRecomendacao: 'Cadastrar recomendação',
    acre: 'Acre',
    hectare: 'Hectare',
    alqueire: 'Alqueire',
    kg: 'Kg',
    numeroMaximoProdutores: 'O limite de produtores desse plano foi atingido',
    desejaExcluir: 'Deseja excluir?',
    falhaAoExcluirProdutor: 'Falha ao excluir produtor',
    produtor: 'Produtor',
    cadastroPlantio: 'Cadastro de plantios',
    cadastrarAreaCultivo: 'Primeiro cadastre área de cultivo',
    cadastrarTalhao: 'Primeiro cadastre área de cultivo e talhão',
    produtorInvalidoTecnico: 'Produtor nao encontrado',
    plantio: 'Plantio',
    dataMinima: 'Data inválida',
    oleosAdjuvantes: 'Óleos e adjuvantes',
    confirmar: 'Confirmar',
    salvarLancamentoSair: 'Existem informações não salvas, deseja salvar o lançamento?',
    descartar: 'Descartar',
    sacas: 'Sacas',
    toneladas: 'Toneladas',
    m2: 'm²',
    sementes: 'Sementes',
    cadastrarAlerta: 'Cadastrar alerta fitossanitário',
    invasoras: 'Invasoras',
    agenteCausal: 'Agente causal',
    data: 'Data',
    doencaSolo: 'Doença de solo',
    doencaFoliar: 'Doença foliar',
    nematoide: 'Nematóide',
    pragas: 'Pragas',
    adicionarAlertas: 'Adicionar alertas fitossanitários',
    alertaRemovidoComSucesso: 'Alerta fitossanitário removido com sucesso',
    desejaExcluirAlerta: 'Deseja excluir alerta fitossanitário?',
    alertasFitossanitarios: 'Alertas fitossanitários',
    observacao: 'Observação',
    lancamentoAlerta: 'Lançamento de alerta fitossanitários',
    efetivarRecomendacao: 'Deseja efetivar a recomendação como realizada?',
    sim: 'Sim',
    falhaEfetivarRecomendacao: 'Falha ao efetivar recomendação',
    sucessoEfetivarRecomendacao: 'Sucesso ao efetivar recomendação',
    categoria: 'Categoria',
    acaros: 'Ácaros',
    bacterias: 'Bactérias',
    ervasDaninhas: 'Ervas daninhas',
    fungos: 'Fungos',
    insetos: 'Insetos',
    nematoides: 'Nematóides',
    foliaresAdjuvantes: 'Foliares e Adjuvantes',
    fertilizantes: 'Fertilizantes',
    fertilizanteQuimico: 'Fertilizantes químico',
    fertilizanteOrganico: 'Fertilizantes orgânico',
    litros: 'Litros',
    cadastrarMonitoramento: 'Monitoramento de pragas',
    lancamentoMonitoramento: 'Monitoramento',
    quantidadeFitossanidade: 'Quantidade de pragas',
    numero: 'Número',
    amostras: 'Amostras',
    adicionarAmostras: 'Adicionar amostras',
    praga: 'Praga',
    pragaDuplicada: 'Praga já cadastrada',
    desejaExcluirPraga: 'Deseja excluir praga?',
    pragaRemovidaComSucesso: 'Praga removida com sucesso',
    falhaAoExcluirPraga: 'Falha ao excluir praga',
    desejaExcluirAmostra: 'Deseja excluir amostra?',
    amostraRemovidaComSucesso: 'Amostra removida com sucesso',
    falhaAoExcluirAmostra: 'Falha ao excluir amostra',
    fitossanidade: 'Praga',
    monitoramentoAtualizado: 'Monitoramento atualizado com sucesso',
    monitoramentoCadastrado: 'Monitoramento cadastrado com sucesso',
    falhaAoAtualizarMonitoramento: 'Falha ao atualizar monitoramento',
    falhaAoCadastrarMonitoramento: 'Falha ao cadastrar monitoramento',
    adicionar: 'Adicionar',
    metro: 'metro',
    mediaPorTalhao: 'Média por talhão',
    nenhum: 'Nenhum',
    emAlerta: 'Em alerta',
    necessitaAplicacao: 'Necessita de aplicação',
    situacaoTalhao: 'Situação do talhão',
    nenhumaAtividadeRegistrada: 'Nenhuma atividade registrada',
    especiesIdentificadas: 'Espécies identificadas',
    cadastrarRegistroFotografico: 'Cadastrar registro fotográfico',
    lancamentoRegistroFotografico: 'Lançamento de registro fotográfico',
    imagens: 'Imagens',
    cadastrarRegistroClimatico: 'Cadastrar registro climático',
    descricaoRegistroClimatico: 'Descrição do registro climático',
    lancamentoRegistroClimatico: 'Lançamento de registro climático',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    nenhumResultadoEncontrado: 'Nenhum resultado encontrado',
    inoculantesHomeopatia: 'Inoculantes e Homeopatia',
    homeopaticos: 'Produtos Homeopáticos',
    inoculantes: 'Inoculante',
    desejaCriarRecomendacao: 'Deseja criar uma recomendação de aplicaçao?',
    mudas: 'Mudas'
  }
};
