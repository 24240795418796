export default {
  agendaHeader: {
    hoje: "Hoy",
    mes: "Mes",
    dia: "Dia",
    semana: "Semana",
    janeiro: "Enero",
    fevereiro: "Febrero",
    marco: "Marzo",
    abril: "Abril",
    maio: "Mayo",
    junho: "Junio",
    julho: "Julio",
    agosto: "Agosto",
    setembro: "Septiembre",
    outubro: "Octubre",
    novembro: "Noviembre",
    dezembro: "Diciembre",
    jan: "Ene",
    fev: "Feb",
    mar: "Mar",
    abr: "Abr",
    mai: "May",
    jun: "Jun",
    jul: "Jul",
    ago: "Ago",
    set: "Sep",
    out: "Oct",
    nov: "Nov",
    dez: "Dic"
  }
};
