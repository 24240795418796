import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import formatMessage from '../i18n/formatMessage';
import swal from '@sweetalert/with-react';
import { theme } from '../AcompanhamentoProdutor.styles';

/**
 * Swal de options
 */
function swalOptions(nmQuestion) {
  return swal({
    title: formatMessage(nmQuestion),
    icon: 'warning',
    closeOnClickOutside: false,
    closeOnEsc: true,
    buttons: {
      cancel: {
        text: formatMessage('acompanhamentoProdutor.nao'),
        value: false,
        visible: true,
        closeModal: true,
        className: 'swal2-cancel'
      },
      confirm: {
        text: formatMessage('acompanhamentoProdutor.sim'),
        value: true,
        visible: true,
        closeModal: false,
        className: 'swal2-confirm'
      }
    }
  });
}

/**
 * Swal de error
 * @param {*} title
 */
function swalError(title) {
  swal(formatMessage(title), {
    icon: 'error',
    buttons: {
      confirm: {
        text: 'Ok',
        value: true,
        visible: true,
        closeModal: true,
        className: 'swal2-error'
      }
    }
  });
}

/**
 * Swal carregando
 */
function swalLoading() {
  swal.stopLoading();
  swal(
    <div>
      <MuiThemeProvider theme={theme}>
        <CircularProgress />
      </MuiThemeProvider>
    </div>,
    {
      buttons: false,
      closeOnEsc: false,
      closeOnClickOutside: false,
      title: formatMessage('acompanhamentoProdutor.salvando')
    }
  );
}

/**
 * Swal de sucesso
 * @param {*} title - titulo
 */
function swalSucess(title) {
  swal(formatMessage(title), {
    icon: 'success',
    buttons: {
      confirm: {
        text: 'Ok',
        value: true,
        visible: true,
        closeModal: true,
        className: 'swal2-Ok'
      }
    }
  });
}

export { swalOptions, swalError, swalLoading, swalSucess };
