export default {
  oleosAdjuvantes: {
    oleosAdjuvantes: 'Aceites y adyuvantes',
    cadastroDeOleosAdjuvantes: 'Registro aceites y adyuvantes',
    oleosAdjuvantesRemovidoComSucesso: 'Aceites y adyuvantes quitado con éxito',
    falhaAoExcluirOleosAdjuvantes: 'Fallo la excluir aceites y adyuvantes',
    desejaExcluir: '¿Desea eliminar el aceites y adyuvantes seleccionada?',
    identificacaoDaOleosAdjuvantes: 'Identificación',
    oleosAdjuvantesadastradoComSucesso: 'Aceites y adyuvantes registrada con éxito',
    oleosAdjuvantesEditadoComSucesso: 'Aceites y adyuvantes editado con éxito',
    falhaAoEditarOleosAdjuvantes: 'Falha ao editar aceites y adyuvantes',
    falhaAoCadastrarOleosAdjuvantes: 'Error al editar aceites y adyuvantes',
    falhaAoCarregarOleosAdjuvantes: 'Error al cargar aceites y adyuvantes'
  }
};
