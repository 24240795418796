/*
Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUs';
import esES from './i18n/esES';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Switch from '@material-ui/core/Switch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import EditarEspecificacoes from './EditarEspecificacoes';
import SwipeableViews from 'react-swipeable-views';

import Especificacoes from './Especificacoes';
import _ from 'lodash';
import Cultivar from '@resources/Cultivar';
import Cultura from '@resources/Cultura';
import { style, theme } from './Cultivares.styles';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});
/*
   Tela de Editar Cultivares customizada
   */
class EditarCultivares extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const campoObrigatorio = messagesLinguage['cultivares.compoObrigatorio'];
    const descricao = messagesLinguage['cultivares.descricao'];
    const situacao = messagesLinguage['cultivares.situacao'];
    const ativo = messagesLinguage['cultivares.ativo'];
    const inativo = messagesLinguage['cultivares.inativo'];
    const atualizando = messagesLinguage['cultivares.atualizando'];
    const salvando = messagesLinguage['cultivares.salvando'];
    const cultivarCadastradoComSucesso = messagesLinguage['cultivares.cultivarCadastradoComSucesso'];
    const cultivarEditadoComSucesso = messagesLinguage['cultivares.cultivarEditadoComSucesso'];
    const erro = messagesLinguage['cultivares.erro'];
    const salvar = messagesLinguage['cultivares.salvar'];
    const cancelar = messagesLinguage['cultivares.cancelar'];
    const falhaAoCadastrarCultivar = messagesLinguage['cultivares.falhaAoCadastrarCultivar'];
    const salvarCultivarSair = messagesLinguage['cultivares.salvarCultivarSair'];
    const descartar = messagesLinguage['cultivares.descartar'];
    const falhaAoEditarCultivar = messagesLinguage['cultivares.falhaAoEditarCultivar'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          campoObrigatorio,
          descricao,
          situacao,
          ativo,
          inativo,
          atualizando,
          salvando,
          cultivarCadastradoComSucesso,
          cultivarEditadoComSucesso,
          erro,
          salvar,
          cancelar,
          falhaAoCadastrarCultivar,
          salvarCultivarSair,
          descartar,
          falhaAoEditarCultivar
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.initialCultivar = {
      cultivarEspecList: [],
      cultura: {},
      nmCultivar: '',
      stCultivar: 1
    };

    this.locale = '';

    this.state = {
      cultivar: { ...this.initialCultivar },
      canLeave: false,
      modalEspecificacao: false,
      selectEspecificacao: {},
      check: true,
      dataAppSync: [],
      statusEdit: false,
      loading: true,
      valueAutoComplete: '',
      validarInput: false,
      culturas: [],
      dsCultivar: '',
      key: '',
      index: 0,
      headEspecificacoes: [
        {
          field: 'nmCultivarEspec',
          headerName: provider.intl.formatMessage({ id: 'descricao' }),
          sort: 'asc',
          col: 8
        },
        {
          field: 'situacao',
          headerName: provider.intl.formatMessage({ id: 'situacao' }),
          col: 4
        }
      ]
    };

    this.isDirty = function() {
      return JSON.stringify(this.initialCultivar) !== JSON.stringify(this.state.cultivar);
    }.bind(this);

    window.onbeforeunload = function() {
      if (this.isDirty()) {
        return true;
      }
    }.bind(this);

    const title = 'cultivares.cadastroCultivar';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.handleSubmit = null;

    this.clickCancelar = this.clickCancelar.bind(this);
    this.clickSalvar = this.clickSalvar.bind(this);
    this.retornaCancelarLinguagem = this.retornaCancelarLinguagem.bind(this);
    this.retornaSalvarLinguagem = this.retornaSalvarLinguagem.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.validarInicializacaoAutoComplete = _.debounce(
      this.validarInicializacaoAutoComplete.bind(this),
      200
    );
    this.handleBlurOwn = this.handleBlurOwn.bind(this);
    this.onChangeOwn = this.onChangeOwn.bind(this);
    this.tratarValorInicialCultura = this.tratarValorInicialCultura.bind(this);
    this.onClickEspecificacao = this.onClickEspecificacao.bind(this);
    this.onClickDeleteEspecificacoes = this.onClickDeleteEspecificacoes.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.clickAdicionarEspecificacao = this.clickAdicionarEspecificacao.bind(this);
    this.onClickCancelar = this.onClickCancelar.bind(this);
    this.onTryExit = this.onTryExit.bind(this);
  }

  componentDidMount() {
    this.validarInicializacaoAutoComplete();
    if (this.props.match.params.id !== undefined) {
      Cultivar.findCultivarById(this.props.match.params.id)
        .then(query => {
          const data = query.data;
          data.cultivarEspecList.forEach(doc => {
            doc.situacao =
              doc.stCultivarEspec === 1
                ? provider.intl.formatMessage({ id: 'ativo' })
                : provider.intl.formatMessage({ id: 'inativo' });
          });

          const dataCultivar = {
            cultivarEspecList: query.data.cultivarEspecList,
            cultura: query.data.cultura,
            nmCultivar: query.data.nmCultivar,
            stCultivar: query.data.stCultivar
          };
          this.initialCultivar = { ...dataCultivar };

          this.setState({
            cultivar: dataCultivar,
            dsCultivar: query.data.nmCultivar,
            dataAppSync: query.data,
            statusEdit: true,
            check: query.data.stCultivar === 1 ? true : false,
            loading: false,
            valueAutoComplete: query.data.cultura,
            key: Math.random()
          });
        })
        .catch(() => {
          var data = this.state.dataAppSync;
          data.cultivarEspecList = [];

          this.setState({
            loading: false,
            dataAppSync: data
          });
        });
    } else {
      var data = this.state.dataAppSync;
      data.cultivarEspecList = [];

      this.setState({
        loading: false,
        dataAppSync: data
      });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  handleChangeChecked() {
    this.setState({
      check: !this.state.check
    });
  }

  clickCancelar() {
    this.props.history.push('/app/cultivar');
  }

  clickSalvar(values) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: provider.intl.formatMessage({
          id: this.state.statusEdit ? 'atualizando' : 'salvando'
        })
      }
    );

    const data = {
      nmCultivar: values.dsCultivar,
      stCultivar: this.state.check ? 1 : 0,
      cultura: {
        idCultura: this.state.valueAutoComplete.idCultura
      },
      cultivarEspecList: this.state.dataAppSync.cultivarEspecList.map(element => {
        return {
          idCultivarEspec: element.idCultivarEspec,
          nmCultivarEspec: element.nmCultivarEspec,
          stCultivarEspec: element.stCultivarEspec ? element.stCultivarEspec : 0
        };
      })
    };

    if (!this.state.statusEdit) {
      Cultivar.addCultivar(data)
        .then(() => {
          swal(provider.intl.formatMessage({ id: 'cultivarCadastradoComSucesso' }), {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }).then(() => {
            this.setState({ canLeave: true }, () => {
              if (this.locale !== '') {
                this.props.history.push(this.locale);
              } else {
                this.props.history.push('/app/cultivar');
              }
            });
          });
        })
        .catch(() => {
          swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarCultivar' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          });
        });
    } else {
      data.idCultivar = this.props.match.params.id;

      Cultivar.editCultivar(data)
        .then(query => {
          swal(provider.intl.formatMessage({ id: 'cultivarEditadoComSucesso' }), {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }).then(() => {
            this.setState({ canLeave: true }, () => {
              if (this.locale !== '') {
                this.props.history.push(this.locale);
              } else {
                this.props.history.push('/app/cultivar');
              }
            });
          });
        })
        .catch(() => {
          swal(provider.intl.formatMessage({ id: 'falhaAoEditarCultivar' }), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          });
        });
    }
  }

  retornaSalvarLinguagem() {
    return provider.intl.formatMessage({ id: 'salvar' });
  }

  retornaCancelarLinguagem() {
    return provider.intl.formatMessage({ id: 'cancelar' });
  }

  tratarValorSelectInitial(data) {
    if (!data) {
      return '';
    }

    return data;
  }

  validarInicializacaoAutoComplete() {
    Cultura.findAllCultura()
      .then(query => {
        this.setState({
          culturas: query.data
        });
      })
      .catch(error => {
        this.setState({
          culturas: []
        });
      });
  }

  handleBlurOwn(valueAutoComplete) {
    var value = false;
    if (valueAutoComplete === null) {
      value = true;
    }

    this.setState({
      validarInput: value
    });
  }

  onChangeOwn(nmCultura) {
    const aux = this.state.cultivar;
    aux['cultura'] = nmCultura;
    this.setState({
      cultivar: aux,
      valueAutoComplete: nmCultura
    });
  }

  tratarValorInicialCultura(data) {
    if (!data || data.nmCultura === undefined) {
      return '';
    }
    return data.nmCultura;
  }

  onClickEspecificacao(values, position) {
    var data = this.state.dataAppSync;

    if (position !== undefined) {
      data.cultivarEspecList[position] = values;
      data.cultivarEspecList = [...data.cultivarEspecList];
    } else {
      data.cultivarEspecList = data.cultivarEspecList.concat(values);
    }

    this.setState({
      cultivar: data,
      dataAppSync: data,
      index: 0,
      selectEspecificacao: {},
      modalEspecificacao: false
    });
  }

  onClickDeleteEspecificacoes(position) {
    const data = this.state.dataAppSync;
    data.cultivarEspecList.splice(position, 1);
    data.cultivarEspecList = [...data.cultivarEspecList];

    this.setState({
      cultivar: data,
      dataAppSync: data
    });

    this.onClickCancelar();
  }

  clickAdicionarEspecificacao(item) {
    if (item !== undefined) {
      this.setState({
        modalEspecificacao: true,
        selectEspecificacao: item,
        index: 1
      });
    } else {
      this.setState({
        modalEspecificacao: true,
        selectEspecificacao: {},
        index: 1
      });
    }
  }

  onClickCancelar() {
    this.setState({
      modalEspecificacao: false,
      index: 0
    });
  }

  handleChangeIndex(index) {
    this.setState({
      index: index
    });
  }

  modalEditarEspecificacoes() {
    return (
      <div
        key={1}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%'
        }}
      >
        <EditarEspecificacoes
          selectEspecificacao={this.state.selectEspecificacao}
          onClickCancelar={this.onClickCancelar}
          clickAdicionarEspecificacao={this.clickAdicionarEspecificacao}
          data={this.state.dataAppSync.cultivarEspecList ? this.state.dataAppSync.cultivarEspecList : []}
          onClickEspecificacao={this.onClickEspecificacao}
          itensIdentificacao={this.state.headEspecificacoes}
          onClickDeleteEspecificacoes={this.onClickDeleteEspecificacoes}
        />
      </div>
    );
  }

  modalEspecificacoes() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%'
        }}
      >
        <Especificacoes
          clickAdicionarEspecificacao={this.clickAdicionarEspecificacao}
          data={this.state.dataAppSync.cultivarEspecList ? this.state.dataAppSync.cultivarEspecList : []}
          onClickEspecificacao={this.onClickEspecificacao}
          itensIdentificacao={this.state.headEspecificacoes}
          onClickDeleteEspecificacoes={this.onClickDeleteEspecificacoes}
        />
      </div>
    );
  }

  onChangeCultivar(campo, e, callback, convertToNum = false) {
    callback(e);
    const aux = this.state.cultivar;
    aux[campo] = convertToNum ? +e.target.value : e.target.value;
    this.setState({ cultivar: aux });
  }

  onTryExit(location) {
    if (this.isDirty()) {
      swal({
        title: provider.intl.formatMessage({ id: 'salvarCultivarSair' }),
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          cancel: {
            text: provider.intl.formatMessage({ id: 'descartar' }),
            value: false,
            visible: true,
            closeModal: true,
            className: 'swal2-cancel'
          },
          confirm: {
            text: provider.intl.formatMessage({ id: 'salvar' }),
            value: true,
            visible: true,
            closeModal: false,
            className: 'swal2-confirm'
          }
        }
      }).then(res => {
        swal.close();
        if (res) {
          this.locale = location.pathname;
          this.handleSubmit && this.handleSubmit();
        } else {
          this.setState(
            { canLeave: true },
            () => this.state.canLeave && this.props.history.push(location.pathname)
          );
        }
      });
      return false;
    }

    this.setState(
      { canLeave: true },
      () => this.state.canLeave && this.props.history.push(location.pathname)
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Prompt when={!this.state.canLeave} message={this.onTryExit} />
        <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <SwipeableViews
              data={this.props.itensViews}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
              containerStyle={{ height: '100%' }}
              index={this.state.index}
              onChangeIndex={this.handleChangeIndex}
              slideStyle={{ display: 'flex', overflow: 'hidden' }}
              ignoreNativeScroll={false}
            >
              {[
                <div
                  key={0}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <Formik
                    initialValues={{
                      dsCultivar: this.state.dsCultivar,
                      cultura: this.state.valueAutoComplete ? this.state.valueAutoComplete : '',
                      stCultivarCliente: ''
                    }}
                    onSubmit={this.clickSalvar}
                    validateOnBlur
                    enableReinitialize={true}
                    validateOnChange={false}
                    validationSchema={Yup.object().shape({
                      dsCultivar: Yup.string().required(
                        <FormattedMessage id='cultivares.campoObrigatorio' />
                      ),
                      cultura: Yup.string().required(
                        <FormattedMessage id='cultivares.campoObrigatorio' />
                      )
                    })}
                    render={({
                      values,
                      handleSubmit,
                      errors,
                      touched,
                      setFieldValue,
                      submitForm,
                      handleBlur
                    }) => {
                      this.handleSubmit = submitForm;

                      return (
                        <MuiThemeProvider theme={theme}>
                          <form autoComplete='off' className={classes.form} onSubmit={handleSubmit}>
                            <div>
                              {this.state.loading && <Loading />}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%'
                                }}
                              >
                                <CardIcon
                                  titulo={<FormattedMessage id='cultivares.identificacaoDetentor' />}
                                >
                                  <Grid
                                    container
                                    spacing={16}
                                    style={{
                                      bottom: '16px',
                                      marginBottom: '2px',
                                      marginTop: 15
                                    }}
                                  >
                                    <Grid item xs={4}>
                                      <TextField
                                        label={<FormattedMessage id='cultivares.detentor' />}
                                        name='dsCultivar'
                                        value={values.dsCultivar}
                                        onChange={event => {
                                          setFieldValue('dsCultivar', event.target.value);
                                        }}
                                        onBlur={event => {
                                          this.onChangeCultivar('dsCultivar', event, handleBlur);
                                          this.setState({
                                            dsCultivar: event.target.value
                                          });
                                        }}
                                        error={errors.dsCultivar && touched.dsCultivar}
                                        helperText={touched.dsCultivar ? errors.dsCultivar : ''}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          className: classes.font
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Autocomplete
                                        itens={this.state.culturas}
                                        campoOp={'nmCultura'}
                                        campoChave={'idCultura'}
                                        name='cultura'
                                        label={<FormattedMessage id='cultivares.cultura' />}
                                        onBlurOwn={this.handleBlurOwn}
                                        error={
                                          this.state.validarInput
                                            ? true
                                            : errors.cultura && touched.cultura
                                        }
                                        helperText={
                                          (touched.cultura && errors.cultura) || this.state.validarInput
                                            ? errors.cultura
                                            : null
                                        }
                                        onChangeOwn={this.onChangeOwn}
                                        onChangeAutoComplete={this.validarInicializacaoAutoComplete}
                                        value={this.tratarValorInicialCultura(
                                          this.state.valueAutoComplete
                                        )}
                                        key={this.state.key}
                                        valueAutoComplete={this.tratarValorSelectInitial(
                                          this.state.valueAutoComplete
                                        )}
                                        valueSelect={
                                          this.tratarValorSelectInitial(this.state.valueAutoComplete)
                                            .nmCultura
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <div
                                        style={{
                                          marginTop: '10px',
                                          marginLeft: '30px'
                                        }}
                                      >
                                        <label style={{ color: '#000000' }}>
                                          <FormattedMessage id='cultivares.ativo' />
                                        </label>
                                        <Switch
                                          value='checkedAtivo'
                                          name='checkedAtivo'
                                          color='primary'
                                          checked={this.state.check}
                                          onChange={this.handleChangeChecked}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </CardIcon>
                              </div>
                            </div>

                            {this.state.index === 0 && (
                              <div className={classes.fab}>
                                <ButtonFABMenu
                                  icon={iconMenuPontos}
                                  actions={[
                                    {
                                      icon: <Check style={{ color: 'white' }} />,
                                      name: this.retornaSalvarLinguagem(),
                                      onClickIcon: handleSubmit,
                                      color: '#42ADE8',
                                      disabled: this.state.error
                                    },
                                    {
                                      icon: <Close style={{ color: 'white' }} />,
                                      name: this.retornaCancelarLinguagem(),
                                      onClickIcon: this.clickCancelar,
                                      color: '#F33A30'
                                    }
                                  ]}
                                />
                              </div>
                            )}
                          </form>
                        </MuiThemeProvider>
                      );
                    }}
                  />
                  {this.modalEspecificacoes()}
                </div>,
                this.state.modalEspecificacao ? this.modalEditarEspecificacoes() : <div key={1} />
              ]}
            </SwipeableViews>
          </div>
        </IntlProvider>
      </>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarCultivares);
export default withRouter(connect(mapStateToProps)(enhaced));
