import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findVisitasPendentes() {
  return axios.get(api + '/agenda/pendentes');
}

function findVisitasPendentesByProdutores(idProdutor) {
  return axios.get(api + '/agenda/pendentes/?idProdutor=' + idProdutor);
}

function findVisitasPendentesByUsuario(dtInicio, dtFinal, idUsuario) {
  return axios.get(api + '/agenda/quantidade/' + dtInicio + '/' + dtFinal + '?idtecnico=' + idUsuario);
}

function findVisitasPendentesByProdutor(dtInicio, dtFinal, idProdutor) {
  return axios.get(
    api + '/agenda/quantidade/' + dtInicio + '/' + dtFinal + '/?idProdutor=' + idProdutor
  );
}

function findVisitas(dtInicio, dtFinal) {
  return axios.get(api + '/agenda/quantidade/' + dtInicio + '/' + dtFinal);
}

function findAgendaPendentes(idTecnico, dtInicio, dtFinal) {
  let agendaToMap = api + `/agenda/agenda-to-map/${dtInicio}/${dtFinal}`;
  if (idTecnico) {
    agendaToMap += `?idtecnico=${idTecnico}`;
  }
  return axios.get(agendaToMap);
}

export default {
  findVisitasPendentesByProdutor,
  findVisitasPendentes,
  findVisitas,
  findVisitasPendentesByUsuario,
  findVisitasPendentesByProdutores,
  findAgendaPendentes
};
