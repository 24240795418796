export default {
  relatorio: {
    relatorio: 'Informe',
    sintetico: 'Sintético',
    regiao: 'Región',
    municipio: 'Contacto',
    sim: 'Si',
    nao: 'No',
    carregando: 'Cargando...',
    gerandoRelatorio: 'Generando informe ...',
    falhaAoGerarRelatorio: 'Error al generar informe',
    dataLancamento: 'Fecha de lanzamiento',
    tecnicoResponsavel: 'Técnico responsable',
    produtor: 'Productor',
    uf: 'UF',
    decrescente: 'Disminuyendo',
    crescente: 'Creciente',
    media: 'Promedio'
  }
};
