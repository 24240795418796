//@author Katia Miglioli
import React, { Component } from 'react';
import CabecarioLogin from '@components/CabecalhoLogin/CabecalhoLogin';
import ComponentesLogin from '@components/TelaLogin/TelaLogin';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import { IntlProvider } from 'react-intl';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Authentication from './../../resources/oauth/Authentication';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const imgFundo = require('@images/fundo.png').default;
const backStyle = {
  width: '1024',
  height: '100vh',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${imgFundo})`,
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
};

const cabStyle = {
  position: 'relative',
  left: 20,
  top: 110
};

const divStyle = {
  display: 'relative',
  marginTop: '-200px'
};

class TelaLogin extends Component {
  componentDidMount() {
    Authentication.logout();
  }

  render() {
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div className='cComponent' style={backStyle}>
          <div style={divStyle}>
            <div style={cabStyle}>
              <CabecarioLogin />
            </div>
            <div>
              <ComponentesLogin />
            </div>
          </div>
        </div>
      </IntlProvider>
    );
  }
}

export default TelaLogin;
