export default {
  cultura: {
    existeUm: 'Existe uno ',
    existeUma: 'Existe una ',
    relacionadaComCultura: ' relacionado con la cultura',
    relacionadoComCultura: ' relacionado con la cultura',
    detentor: 'Poseedor',
    plantio: 'plantar',
    fitossanidade: 'salud vegetal',
    cotacao: 'precio',
    safra: 'cosecha',
    cadastro: 'registrarse'
  }
};
