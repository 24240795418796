//Autor: Gabriela Farias

import React, { Component } from "react";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { flattenMessages } from "@utils/languageUtils";
import { IntlProvider } from "react-intl";
import { getUserLang } from "@utils/localeUtils";
import ptBR from "./i18n/ptBR";
import enUS from "./i18n/enUS";
import esES from "./i18n/esES";
import CardIcon from "@components/CardIcon/CardIcon";
import Grid from "@material-ui/core/Grid";
import TextField from "@components/TextFieldOwn/TextFieldOwn";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ButtonFABMenu from "@components/ButtonFABMenu/ButtonFABMenu";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "@sweetalert/with-react";
import Loading from "@components/Loading/Loading";
import _ from "lodash";
import Autocomplete from "@components/Autocomplete/Autocomplete";
import Fitossanidade from "@resources/Severidade";
import Cultura from "@resources/Cultura";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import SeveridadeEnum from "@resources/SeveridadeEnum";
import Switch from "@material-ui/core/Switch";

let messages = { "pt-BR": ptBR, "en-US": enUS, "es-ES": esES };

const iconMenuPontos = require("@images/icone_fab_menu.png");

let { provider } = new IntlProvider({});

const style = () => ({
  div: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  card: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  font: {
    fontSize: 14,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "noWrap",
    width: "100%",
    minHeight: 0
  },
  fab: {
    position: "relative",
    bottom: theme.spacing.unit * 27,
    right: theme.spacing.unit * 3.7,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    zIndex: 1,
    marginBottom: 0
  },
  form: {
    width: "100%",
    margin: 0,
    height: "75px"
  },
  check: {
    display: "flex",
    alignItems: "center",
    marginTop: 10
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#00622B",
      main: "#00622B",
      dark: "#00622B",
      contrastText: "#fff"
    }
  },
  typography: {
    useNextVariants: true
  }
});

class EditarFitossanidade extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const fitossanidadeCadastradoComSucesso =
      messagesLinguage["fitossanidade.fitossanidadeCadastradoComSucesso"];
    const fitossanidadeEditadoComSucesso =
      messagesLinguage["fitossanidade.fitossanidadeEditadoComSucesso"];
    const falhaAoEditarFitossanidade =
      messagesLinguage["fitossanidade.falhaAoEditarFitossanidade"];
    const falhaAoCadastrarFitossanidade =
      messagesLinguage["fitossanidade.falhaAoCadastrarFitossanidade"];
    const falhaAoCarregarFitossanidade =
      messagesLinguage["fitossanidade.falhaAoCarregarFitossanidade"];
    const cadastroDeFitossanidade =
      messagesLinguage["fitossanidade.cadastroDeFitossanidade"];
    const salvar = messagesLinguage["fitossanidade.salvar"];
    const cancelar = messagesLinguage["fitossanidade.cancelar"];
    const salvando = messagesLinguage["fitossanidade.salvando"];
    const atualizando = messagesLinguage["fitossanidade.atualizando"];
    const doencaSolo = messagesLinguage["fitossanidade.doencaSolo"];
    const doencaFoliar = messagesLinguage["fitossanidade.doencaFoliar"];
    const nematoide = messagesLinguage["fitossanidade.nematoide"];
    const fitossanidade = messagesLinguage["fitossanidade.fitossanidade"];
    const pragas = messagesLinguage["fitossanidade.pragas"];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          fitossanidadeCadastradoComSucesso,
          fitossanidadeEditadoComSucesso,
          falhaAoCadastrarFitossanidade,
          falhaAoEditarFitossanidade,
          falhaAoCarregarFitossanidade,
          salvar,
          cancelar,
          salvando,
          atualizando,
          cadastroDeFitossanidade,
          doencaSolo,
          doencaFoliar,
          nematoide,
          fitossanidade,
          pragas
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      itemSelect: {
        cultura: {},
        tpSeveridade: "",
        nmSeveridade: ""
      },
      check: true,
      valueAutoComplete: "",
      culturas: [],
      key: "",
      loading: this.props.match.params.id !== undefined,
      idSeveridade: "",
      tipos: [
        {
          id: SeveridadeEnum.DOENCA_SOLO,
          name: provider.intl.formatMessage({ id: "doencaSolo" })
        },
        {
          id: SeveridadeEnum.DOENCA_FOLIAR,
          name: provider.intl.formatMessage({ id: "doencaFoliar" })
        },
        {
          id: SeveridadeEnum.NEMATOIDE,
          name: provider.intl.formatMessage({ id: "nematoide" })
        },
        {
          id: SeveridadeEnum.PRAGAS,
          name: provider.intl.formatMessage({ id: "pragas" })
        }
      ]
    };

    const title = "fitossanidade.cadastroDeFitossanidade";
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: "UPDATE_TOOLBAR",
        toolbar: (
          <IntlProvider
            locale={getUserLang()}
            messages={flattenMessages(messages[getUserLang()])}
          >
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.checkSwitch = this.checkSwitch.bind(this);
    this.iniciarAtributosEdicao = this.iniciarAtributosEdicao.bind(this);
    this.clickCadastrarEditar = this.clickCadastrarEditar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.validarInicializacaoAutoComplete = _.debounce(
      this.validarInicializacaoAutoComplete.bind(this),
      200
    );
    this.tratarValorInicialCultura = this.tratarValorInicialCultura.bind(this);
  }

  componentDidMount() {
    this.validarInicializacaoAutoComplete();
    this.iniciarAtributosEdicao();
  }

  iniciarAtributosEdicao() {
    if (this.props.match.params.id !== undefined) {
      Fitossanidade.findSeveridadeById(this.props.match.params.id)
        .then(query => {
          this.setState({
            check: query.data.stSeveridade === 1,
            loading: false,
            itemSelect: query.data,
            valueAutoComplete: query.data.cultura,
            key: Math.random()
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal(
            provider.intl.formatMessage({
              id: "falhaAoCarregarFitossanidade"
            }),
            {
              icon: "error",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-error"
                }
              }
            }
          );
        });
    }
  }

  tratarValorSelectInitial(data) {
    if (!data) {
      return "";
    }

    return data;
  }

  validarInicializacaoAutoComplete() {
    Cultura.findAllCultura()
      .then(querySnapshot => {
        this.setState({
          culturas: querySnapshot.data
        });
      })
      .catch(error => {
        this.setState({
          culturas: []
        });
      });
  }

  tratarValorInicialCultura(data) {
    if (!data || data.nmCultura === undefined) {
      return "";
    }
    return data.nmCultura;
  }

  clickCadastrarEditar(values) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: provider.intl.formatMessage({
          id:
            this.props.match.params.id === undefined
              ? "salvando"
              : "atualizando"
        })
      }
    );

    const data = {
      cultura: this.state.valueAutoComplete,
      tpSeveridade: values.tpSeveridade,
      nmSeveridade: values.nmSeveridade,
      stSeveridade: this.state.check ? 1 : 0
    };

    if (this.props.match.params.id === undefined) {
      Fitossanidade.addSeveridade(data)
        .then(data => {
          swal(
            provider.intl.formatMessage({
              id: "fitossanidadeCadastradoComSucesso"
            }),
            {
              icon: "success",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-Ok"
                }
              }
            }
          ).then(() => {
            this.clickCancelar();
          });
        })
        .catch(error => {
          swal(
            provider.intl.formatMessage({
              id: "falhaAoCadastrarFitossanidade"
            }),
            {
              icon: "error",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-error"
                }
              }
            }
          );
        });
    } else {
      data.idSeveridade = this.state.itemSelect.idSeveridade;

      Fitossanidade.editSeveridade(data)
        .then(data => {
          swal(
            provider.intl.formatMessage({
              id: "fitossanidadeEditadoComSucesso"
            }),
            {
              icon: "success",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-Ok"
                }
              }
            }
          ).then(() => {
            this.clickCancelar();
          });
        })
        .catch(error => {
          swal(
            provider.intl.formatMessage({
              id: "falhaAoEditarFitossanidade"
            }),
            {
              icon: "error",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-error"
                }
              }
            }
          );
        });
    }
  }

  clickCancelar() {
    this.props.history.push("/app/fitossanidade");
  }

  checkSwitch() {
    this.setState({
      check: !this.state.check
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider
        locale={getUserLang()}
        messages={flattenMessages(messages[getUserLang()])}
      >
        <Formik
          key={this.state.key}
          initialValues={{
            nmSeveridade: this.state.itemSelect.nmSeveridade,
            cultura: this.state.valueAutoComplete,
            tpSeveridade: this.state.itemSelect.tpSeveridade
          }}
          onSubmit={this.clickCadastrarEditar}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            nmSeveridade: Yup.string().required(
              <FormattedMessage id="fitossanidade.campoObrigatorio" />
            ),
            cultura: Yup.string().required(
              <FormattedMessage id="fitossanidade.campoObrigatorio" />
            ),
            tpSeveridade: Yup.string().required(
              <FormattedMessage id="fitossanidade.campoObrigatorio" />
            )
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleChange
          }) => (
            <MuiThemeProvider theme={theme}>
              <form autoComplete="off" className={classes.div}>
                <div className={classes.card}>
                  {this.state.loading && <Loading />}
                  <CardIcon
                    titulo={
                      <FormattedMessage id="fitossanidade.identificacaoDeFitossanidade" />
                    }
                  >
                    <Grid container spacing={16} style={{ marginTop: 15 }}>
                      <Grid item xs={4}>
                        <Autocomplete
                          itens={this.state.culturas}
                          campoOp={"nmCultura"}
                          campoChave={"idCultura"}
                          name="cultura"
                          label={
                            <FormattedMessage id="fitossanidade.cultura" />
                          }
                          error={errors.cultura && touched.cultura}
                          helperText={
                            errors.cultura && touched.cultura
                              ? errors.cultura
                              : null
                          }
                          onChangeOwn={value => {
                            setFieldValue("cultura", value);
                            this.setState({
                              valueAutoComplete: value
                            });
                          }}
                          onChangeAutoComplete={
                            this.validarInicializacaoAutoComplete
                          }
                          value={this.tratarValorInicialCultura(
                            this.state.valueAutoComplete
                          )}
                          key={this.state.key}
                          valueAutoComplete={this.tratarValorSelectInitial(
                            this.state.valueAutoComplete
                          )}
                          valueSelect={
                            this.tratarValorSelectInitial(
                              this.state.valueAutoComplete
                            ).nmCultura
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl
                          className={classes.form}
                          error={errors.tpSeveridade && touched.tpSeveridade}
                        >
                          <InputLabel
                            htmlFor="tpSeveridade-helper"
                            error={errors.tpSeveridade && touched.tpSeveridade}
                            className={classes.font}
                          >
                            <FormattedMessage id="fitossanidade.tipo" />
                          </InputLabel>
                          <Select
                            style={{
                              marginLeft: 0,
                              fontSize: 14,
                              width: "100%"
                            }}
                            SelectDisplayProps={{
                              style: {
                                width: "100%",
                                backgroundColor: "transparent"
                              }
                            }}
                            value={values.tpSeveridade}
                            onChange={value => {
                              var itemSelect = this.state.itemSelect;
                              itemSelect.tpSeveridade = value.target.value;

                              this.setState({
                                itemSelect: itemSelect
                              });

                              setFieldValue("tpSeveridade", value.target.value);
                            }}
                            input={<Input id="tpSeveridade-error" />}
                            inputProps={{
                              className: classes.font,
                              name: "tpSeveridade",
                              id: "tpSeveridade-helper"
                            }}
                          >
                            {this.state.tipos.map(tipo => (
                              <MenuItem
                                value={tipo.id}
                                key={this.state.tipos.indexOf(tipo)}
                                className={classes.font}
                              >
                                {tipo.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.tpSeveridade && touched.tpSeveridade && (
                            <FormHelperText>
                              <FormattedMessage id="fitossanidade.campoObrigatorio" />
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label={
                            <FormattedMessage id="fitossanidade.descricao" />
                          }
                          name="nome"
                          value={values.nmSeveridade}
                          onChange={event => {
                            var itemSelect = this.state.itemSelect;
                            itemSelect.nmSeveridade = event.target.value;

                            this.setState({
                              itemSelect: itemSelect
                            });
                            setFieldValue("nmSeveridade", event.target.value);
                          }}
                          onBlur={handleBlur}
                          error={errors.nmSeveridade && touched.nmSeveridade}
                          helperText={
                            errors.nmSeveridade && touched.nmSeveridade ? (
                              <FormattedMessage id="fitossanidade.campoObrigatorio" />
                            ) : null
                          }
                          className={classes.font}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <div className={classes.check}>
                          <Switch
                            color="primary"
                            checked={this.state.check}
                            onChange={this.checkSwitch}
                          />
                          <label
                            style={{ color: "#000000" }}
                            className={classes.font}
                          >
                            <FormattedMessage id="fitossanidade.ativo" />
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  </CardIcon>
                </div>
                <div className={classes.fab}>
                  <ButtonFABMenu
                    icon={iconMenuPontos}
                    actions={[
                      {
                        icon: <Check style={{ color: "white" }} />,
                        name: provider.intl.formatMessage({ id: "salvar" }),
                        onClickIcon: handleSubmit,
                        color: "#42ADE8"
                      },
                      {
                        icon: <Close style={{ color: "white" }} />,
                        name: provider.intl.formatMessage({ id: "cancelar" }),
                        onClickIcon: this.clickCancelar,
                        color: "#F33A30"
                      }
                    ]}
                    onClickButton={this.clickAdicionar}
                  />
                </div>
              </form>
            </MuiThemeProvider>
          )}
        />
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarFitossanidade);
export default withRouter(connect(mapStateToProps)(enhaced));
