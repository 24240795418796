import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import formatMessage from './../i18n/formatMessage';
import swal from '@sweetalert/with-react';
import Identificacao from './Identificacao';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Certificacao from '@components/Certificacao/Certificacao';
import EditarCertificacao from '@components/Certificacao/EditarCertificacao';
import { connect } from 'react-redux';
import { style, theme } from './Talhao.styles';
import MapaTalhao from './MapaTalhao';
import moment from 'moment';
import DataTable from '@components/DataTable/DataTable';
import { getUserLang } from '@utils/localeUtils';
import { validarDatasCertificacao } from '@utils/validateDataCertificado';

function formatTextField() {
  switch (getUserLang()) {
    case 'pt-BR':
      return 'DD/MM/YYYY';
    case 'es-ES':
      return 'DD-MM-YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}

const headDatatable = [
  {
    valueGetter: args => moment(args.node.data.dtInicio).format(formatTextField()),
    headerName: formatMessage('produtores.dataInicio'),
    col: 2
  },
  {
    valueGetter: args =>
      args.node.data.dtTermino
        ? moment(args.node.data.dtTermino).format(formatTextField())
        : formatMessage('produtores.indefinido'),
    headerName: formatMessage('produtores.dataFinal'),
    col: 2
  },
  {
    valueGetter: args => args.node.data.dsMotivo,
    headerName: formatMessage('produtores.motivo'),
    col: 8
  }
];

class EditarTalhoes extends Component {
  constructor(props) {
    super(props);

    this.talhaoGpsList = [];
    this.valueAutoCompleteClassificacao = {};

    this.state = {
      changeStTalhao: false,
      stAtivouTalhao: false,
      initialValuesStatus: false,
      isEditing: false,
      initialValues: {
        tipoCertificacao: null,
        dtInicio: null,
        dtTermino: null
      },
      tipoCertificacaoListCheck: this.props.itemSelect.talhaoTipoCertificacaoList || [],
      tabIndex: 0,
      keyClassificacao: 0,
      classificacaoList: this.props.classificacaoList,
      certificacaoList: this.props.certificacaoList,
      modal: false,
      itemSelect: {
        areaCultivo: {
          idAreaCultivo: ''
        },
        talhaoGpsList: [],
        nrAreaTalhao: '',
        dsTalhao: '',
        tpUnidade: ''
      },
      loading: true,
      navigatorPosition: {},
      disabledEdit: true,
      openModal: false,
      itensUniMedida: [
        { id: 0, name: formatMessage('produtores.acre') },
        { id: 1, name: formatMessage('produtores.alqueire') },
        { id: 2, name: formatMessage('produtores.hectare') },
        { id: 3, name: formatMessage('produtores.m2') }
      ]
    };

    this.cardButtons = this.cardButtons.bind(this);
    this.cardIdentificacao = this.cardIdentificacao.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClickMap = this.onClickMap.bind(this);
    this.clickExcluirMarcacao = this.clickExcluirMarcacao.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChangeClassificacaoAutoComplete = this.onChangeClassificacaoAutoComplete.bind(this);
    this.tratarValorSelectInitialClassificacao = this.tratarValorSelectInitialClassificacao.bind(this);
    this.clickEditarCertificacao = this.clickEditarCertificacao.bind(this);
    this.clickChangeStTalhao = this.clickChangeStTalhao.bind(this);
    this.clickAtivarTalhao = this.clickAtivarTalhao.bind(this);
  }

  componentDidMount() {
    if (this.props.statusEdit) {
      this.valueAutoCompleteClassificacao = this.props.itemSelect.classificacao;
      this.talhaoGpsList =
        this.props.itemSelect.talhaoGpsList &&
        this.props.itemSelect.talhaoGpsList.map(doc => {
          return {
            lat: doc.nrLatitude,
            lng: doc.nrLongitude
          };
        });

      const itemSelectTemp = { ...this.props.itemSelect };

      if (itemSelectTemp.stTalhao === 0 && itemSelectTemp.talhaoSituacaoList.length > 0) {
        const lastTalhaoSituacao = itemSelectTemp.talhaoSituacaoList[0];
        itemSelectTemp.dsMotivo = lastTalhaoSituacao.dsMotivo;
        itemSelectTemp.dtInicio = lastTalhaoSituacao.dtInicio;
        itemSelectTemp.dtTermino = lastTalhaoSituacao.dtTermino
          ? moment(lastTalhaoSituacao.dtTermino)
          : '';

        this.setState({
          initialValuesStatus: true
        });
      } else {
        itemSelectTemp.dsMotivo = '';
        itemSelectTemp.dtInicio = '';
        itemSelectTemp.dtTermino = '';
      }

      this.setState({
        itemSelect: itemSelectTemp,
        loading: false,
        keyClassificacao: Math.random()
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  onChangeClassificacaoAutoComplete(event) {
    this.valueAutoCompleteClassificacao = event;

    const aux = this.state.itemSelect;
    aux['classificacao'] = event;

    this.setState({
      itemSelect: aux,
      keyClassificacao: Math.random()
    });
  }

  tratarValorSelectInitialClassificacao(data) {
    if (!data || data.dsClassificacao === undefined) {
      return '';
    }
    return data;
  }

  onSave(values) {
    if (
      this.props.infoUsuario.permissaoNdvi &&
      this.talhaoGpsList.length > 0 &&
      values.nrAreaTalhao < 1
    ) {
      this.setState({
        modal: false
      });

      swal(
        formatMessage('produtores.tamanhoMinino') + this.state.itensUniMedida[values.tpUnidade].name,
        {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        }
      );
      return;
    }

    if (values.stTalhao) {
      values.talhaoSituacaoList.forEach(talhaoSituacaoTemp => {
        if (!talhaoSituacaoTemp.dtTermino && moment(talhaoSituacaoTemp.dtInicio).isBefore(moment())) {
          talhaoSituacaoTemp.dtTermino = moment().format('YYYY-MM-DDTHH:mm:ssZZ');
        }
      });
    }

    if (this.state.stAtivouTalhao) {
      values.talhaoSituacaoList[0] = {
        ...values.talhaoSituacaoList[0],
        dtTermino: this.state.itemSelect.dtTermino
          ? moment(values.dtTermino).format('YYYY-MM-DDTHH:mm:ssZZ')
          : null
      };
    }

    if (values.dsMotivo !== '' && this.state.changeStTalhao) {
      const talhaoSituacao = {
        stRegistro: 0,
        dsMotivo: this.state.itemSelect.dsMotivo,
        dtInicio: moment(this.state.itemSelect.dtInicio),
        dtTermino: this.state.itemSelect.dtTermino ? moment(this.state.itemSelect.dtTermino) : null
      };

      const validator = validarDatasCertificacao(
        talhaoSituacao,
        values.talhaoSituacaoList,
        this.props.statusEdit,
        'idTalhaoSituacao',
        false
      );

      if (validator) {
        swal({
          title: formatMessage('produtores.periodoExistente'),
          icon: 'error',
          closeOnClickOutside: false,
          closeOnEsc: true,
          buttons: {
            cancel: {
              text: 'ok',
              value: false,
              visible: true,
              closeModal: true,
              className: 'swal2-cancel'
            }
          }
        });

        return;
      }

      talhaoSituacao.dtInicio = moment(values.dtInicio).format('YYYY-MM-DDTHH:mm:ssZZ');
      talhaoSituacao.dtTermino = values.dtTermino
        ? moment(values.dtTermino).format('YYYY-MM-DDTHH:mm:ssZZ')
        : null;

      values.talhaoSituacaoList.push(talhaoSituacao);
    }

    values.temPontosSalvos = !!this.state.itemSelect.temPontosSalvos;
    values.nrLongitude = values.nrLongitude === '' ? null : values.nrLongitude;
    values.nrLatitude = values.nrLatitude === '' ? null : values.nrLatitude;
    values.classificacao = this.valueAutoCompleteClassificacao;
    values.talhaoTipoCertificacaoList = this.state.tipoCertificacaoListCheck;

    var idAreaCultivo =
      values.areaCultivo.idAreaCultivo === undefined
        ? values.areaCultivo
        : values.areaCultivo.idAreaCultivo;

    values.areaCultivo = this.props.areaCultivo.find(areaCultivo => {
      return areaCultivo.idAreaCultivo === idAreaCultivo;
    });

    values.talhaoGpsList = this.talhaoGpsList.map(doc => ({
      nrLatitude: doc.lat,
      nrLongitude: doc.lng
    }));

    if (this.state.itemSelect.talhaoGpsList.length > 0) {
      values.nrAreaTalhao = parseFloat(this.state.itemSelect.nrAreaTalhao);
    }

    values.stTalhaoGPS = this.state.itemSelect.stTalhaoGPS || 0;

    if (this.props.statusEdit) {
      values.stRegistro = this.state.itemSelect.stRegistro;
      values.idTalhao = this.state.itemSelect.idTalhao;
      values.idPoligono = this.state.itemSelect.idPoligono;

      const position = this.props.data.indexOf(this.props.selectTalhao);

      this.props.onClickAddTalhao(values, position);
    } else {
      values.stRegistro = 0;
      values.idTalhao = this.props.maxIdTalhao;

      this.props.onClickAddTalhao(values);
    }
  }

  onClickMap(values, hectares) {
    let editouMapa = false;
    if (
      this.talhaoGpsList.length > 0 &&
      this.state.itemSelect.stRegistro !== 0 &&
      this.state.itemSelect.temPontosSalvos
    ) {
      editouMapa = true;
    }

    this.talhaoGpsList = values;

    const itemSelect = { ...this.state.itemSelect };
    itemSelect.talhaoGpsList = values;

    if (!this.props.infoUsuario.permissaoNdvi && hectares < 1) {
      itemSelect.nrAreaTalhao = hectares * 10000;
      itemSelect.tpUnidade = this.state.itensUniMedida[3].id;
    } else {
      itemSelect.nrAreaTalhao = hectares;
      itemSelect.tpUnidade = this.state.itensUniMedida[2].id;
    }

    itemSelect.stTalhaoGPS = 1;
    itemSelect.editouMapa = editouMapa;

    let somaLat = 0;
    let somaLong = 0;
    let cont = 0;

    values.forEach(doc => {
      cont += 1;
      somaLong += doc.lng;
      somaLat += doc.lat;
    });

    itemSelect.nrLongitude = somaLong / cont;
    itemSelect.nrLatitude = somaLat / cont;

    this.setState({ itemSelect });
  }

  clickExcluirMarcacao() {
    swal({
      title: formatMessage('produtores.desejaExcluirMarcacao'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('produtores.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('produtores.sim'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      this.setState({
        loading: true
      });

      if (willDelete) {
        let itemSelect = { ...this.state.itemSelect };
        itemSelect.talhaoGpsList = [];
        itemSelect.nrAreaTalhao = '0';
        itemSelect.stTalhaoGPS = 1;
        itemSelect.nrLatitude = 0;
        itemSelect.nrLongitude = 0;
        this.talhaoGpsList = [];

        this.setState({
          loading: false,
          itemSelect: itemSelect
        });

        this.props.changeMap(this.state.itemSelect.areaCultivo.idAreaCultivo);

        swal.close();
      }
    });
  }

  handleClose() {
    this.setState({
      modal: false
    });
  }

  cardIdentificacao(values, errors, touched, handleBlur, setFieldValue, handleSubmit, classes) {
    return (
      <Identificacao
        clickChangeStTalhao={this.clickChangeStTalhao}
        changeStTalhao={this.state.changeStTalhao}
        stAtivouTalhao={this.state.stAtivouTalhao}
        clickAtivarTalhao={this.clickAtivarTalhao}
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit}
        classes={classes}
        itemSelect={this.state.itemSelect}
        modal={this.state.modal}
        statusEdit={this.props.statusEdit}
        areaCultivoList={this.props.areaCultivo || []}
        changeMap={this.props.changeMap}
        talhaoGpsList={this.talhaoGpsList}
        handleClose={this.handleClose}
        onChangeClassificacaoAutoComplete={event => this.onChangeClassificacaoAutoComplete(event)}
        tratarValorSelectInitialClassificacao={this.tratarValorSelectInitialClassificacao}
        valueAutoCompleteClassificacao={this.valueAutoCompleteClassificacao}
        classificacaoList={this.state.classificacaoList}
        keyClassificacao={this.state.keyClassificacao}
        changeStateItemSelect={item => {
          this.setState({
            itemSelect: item
          });
        }}
      />
    );
  }

  cardButtons(classes, handleSubmit, values) {
    return (
      <MuiThemeProvider theme={theme}>
        <Grid
          container
          style={{ marginTop: this.state.tabIndex === 2 ? 15 : 0, backgroundColor: '#FFFFFF' }}
        >
          <Grid item xs className={classes.containerButtons}>
            <div style={{ color: '#463766', height: 35 }}>
              {this.state.tabIndex === 2 && this.state.itemSelect.talhaoGpsList.length > 0 && (
                <Button variant='contained' onClick={this.clickExcluirMarcacao}>
                  {formatMessage('produtores.excluirMarcacao')}
                </Button>
              )}
            </div>

            {this.state.tabIndex !== 1 && (
              <>
                <div>
                  <Button color='inherit' variant='contained' onClick={this.props.onCancelClear}>
                    {formatMessage('produtores.cancelar')}
                  </Button>
                </div>

                <div>
                  <Button
                    color='secondary'
                    variant='contained'
                    onClick={() => {
                      const tabIdentificacaoValidator =
                        values.areaCultivo !== '' &&
                        values.dsTalhao !== null &&
                        values.classificacao !== undefined;
                      const tabMapaValidator = values.nrAreaTalhao !== '' && values.tpUnidade !== '';
                      if (!tabIdentificacaoValidator) {
                        this.setState(
                          {
                            tabIndex: 0
                          },
                          () => {
                            handleSubmit();
                          }
                        );
                      } else if (!tabMapaValidator) {
                        this.setState(
                          {
                            tabIndex: 2
                          },
                          () => {
                            handleSubmit();
                          }
                        );
                      } else {
                        handleSubmit();
                      }
                    }}
                  >
                    {formatMessage('produtores.salvar')}
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }

  clickEditarCertificacao(item) {
    if (!item.dtTermino) {
      item.dtTermino = null;
    }

    this.setState({
      isEditing: true,
      initialValues: { ...item }
    });
  }

  clickChangeStTalhao() {
    this.setState({
      changeStTalhao: true
    });
  }

  clickAtivarTalhao() {
    var item = this.state.itemSelect;
    item.dtTermino = null;

    this.setState({
      stAtivouTalhao: item
    });
  }

  render() {
    const { classes } = this.props;
    const { itemSelect, statusEdit } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ marginBottom: 15 }}>
          <Formik
            initialValues={{
              dsTalhao: itemSelect.dsTalhao || '',
              nrAreaTalhao: itemSelect.nrAreaTalhao || '',
              areaCultivo:
                itemSelect.areaCultivo.idAreaCultivo !== undefined
                  ? itemSelect.areaCultivo.idAreaCultivo
                  : '',
              nrLatitude: itemSelect.nrLatitude || '',
              nrLongitude: itemSelect.nrLongitude || '',
              dsMotivoAlteracao: itemSelect.dsMotivoAlteracao || '',
              dsFertilidade: itemSelect.dsFertilidade || '',
              tpUnidade: itemSelect.tpUnidade !== '' ? itemSelect.tpUnidade : '',
              classificacao: itemSelect.classificacao,
              stTalhao: itemSelect.stTalhao === undefined ? 1 : itemSelect.stTalhao,
              dsMotivo:
                (this.state.changeStTalhao && itemSelect.dsMotivo) ||
                this.state.stAtivouTalhao ||
                this.state.initialValuesStatus
                  ? itemSelect.dsMotivo
                  : '',
              dtInicio:
                (this.state.changeStTalhao && itemSelect.dtInicio) ||
                this.state.stAtivouTalhao ||
                this.state.initialValuesStatus
                  ? itemSelect.dtInicio
                  : null,
              dtTermino:
                (this.state.changeStTalhao ||
                  this.state.stAtivouTalhao ||
                  this.state.initialValuesStatus) &&
                itemSelect.dtTermino
                  ? itemSelect.dtTermino
                  : null,
              talhaoSituacaoList: this.state.itemSelect.talhaoSituacaoList || []
            }}
            validateOnBlur
            validateOnChange={false}
            enableReinitialize
            onSubmit={values => {
              if (
                this.state.itemSelect.stTalhaoGPS === 1 &&
                this.state.itemSelect.editouMapa &&
                !this.state.modal &&
                this.props.statusEdit &&
                this.state.itemSelect.stRegistro !== 0
              ) {
                this.setState({ modal: true });
              } else {
                this.onSave(values);
              }
            }}
            validationSchema={Yup.object().shape({
              dsTalhao: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
              tpUnidade: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
              nrAreaTalhao: Yup.number()
                .moreThan(0, formatMessage('produtores.minino'))
                .required(formatMessage('produtores.campoObrigatorio')),
              areaCultivo: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
              classificacao:
                this.props.infoUsuario.stOrganica === 1
                  ? Yup.object().required(formatMessage('produtores.campoObrigatorio'))
                  : Yup.object(),
              nrLatitude: itemSelect.nrLongitude
                ? Yup.string()
                    .test('', formatMessage('produtores.valorInvalido'), value => {
                      return !(parseFloat(value) < -90 || parseFloat(value) > 90);
                    })
                    .required(formatMessage('produtores.campoObrigatorio'))
                : Yup.string(),
              nrLongitude: itemSelect.nrLatitude
                ? Yup.string()
                    .test('', formatMessage('produtores.valorInvalido'), value => {
                      return !(parseFloat(value) < -180 || parseFloat(value) > 180);
                    })
                    .required(formatMessage('produtores.campoObrigatorio'))
                : Yup.string(),
              dsFertilidade: Yup.string(),
              dsMotivoAlteracao:
                itemSelect.stTalhaoGPS === 1 && this.state.modal && statusEdit
                  ? Yup.string()
                      .min(10, formatMessage('produtores.mininoCampo'))
                      .required(formatMessage('produtores.campoObrigatorio'))
                  : Yup.string(),
              stTalhao: Yup.number().required(formatMessage('produtores.campoObrigatorio')),
              dsMotivo: this.state.changeStTalhao
                ? Yup.string().required(formatMessage('produtores.campoObrigatorio'))
                : Yup.string(),
              dtInicio: this.state.changeStTalhao
                ? Yup.object()
                    .nullable()
                    .required(formatMessage('produtores.campoObrigatorio'))
                : Yup.object().nullable(),
              dtTermino: this.state.stAtivouTalhao
                ? Yup.object()
                    .required(formatMessage('produtores.campoObrigatorio'))
                    .test('', formatMessage('produtores.dataMinimaHoje'), value => {
                      return moment(value).isBefore(moment());
                    })
                    .nullable()
                : Yup.object().nullable()
            })}
            render={({ values, handleSubmit, errors, touched, handleBlur, setFieldValue }) => {
              const tabViews = [
                {
                  label: formatMessage('produtores.identificacao'),
                  component: this.cardIdentificacao(
                    values,
                    errors,
                    touched,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    classes
                  )
                }
              ];
              if (this.props.infoUsuario.stOrganica === 1) {
                tabViews.push({
                  label: formatMessage('produtores.certificacao'),
                  component: (
                    <Certificacao
                      clickEditar={this.clickEditarCertificacao}
                      certificacaoList={this.state.tipoCertificacaoListCheck}
                    />
                  )
                });
              }
              tabViews.push({
                label: formatMessage('produtores.mapa'),
                component: (
                  <div style={{ height: `calc(100vh - 270px)` }}>
                    <MapaTalhao
                      changeMap={this.props.changeMap}
                      itemSelect={this.state.itemSelect}
                      label={this.props.label}
                      dataTalhoes={this.props.dataTalhoes}
                      navigatorPosition={this.props.navigatorPosition}
                      talhaoGpsList={this.talhaoGpsList}
                      onClickMap={this.onClickMap}
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      classes={classes}
                      changeStateItemSelect={item => {
                        this.setState({
                          itemSelect: item
                        });
                      }}
                    />
                    {this.cardButtons(classes, handleSubmit, values)}
                  </div>
                )
              });

              return (
                <div className={classes.cardScroll}>
                  <CardWithTabs
                    selectedIndex={this.state.tabIndex}
                    onChangeTabIndex={(e, tabIndex) => this.setState({ tabIndex })}
                    title={formatMessage(
                      this.props.statusEdit ? 'produtores.edicaoDeTalhao' : 'produtores.cadastroDeTalhao'
                    )}
                    subtitle={
                      values && values.dsTalhao && this.state.tabIndex !== 0
                        ? `- ${values.dsTalhao}`
                        : ''
                    }
                    withoutHidden={true}
                    tabs={tabViews}
                  />
                  {this.state.tabIndex === 1 && this.props.infoUsuario.stOrganica === 1 && (
                    <EditarCertificacao
                      clickEditar={this.clickEditarCertificacao}
                      clickClear={() => {
                        this.setState({
                          isEditing: false,
                          initialValues: {
                            tipoCertificacao: null,
                            dtInicio: null,
                            dtTermino: null
                          }
                        });
                      }}
                      updateCertificacaoList={certificacaoList => {
                        this.setState({
                          tipoCertificacaoListCheck: [...certificacaoList]
                        });
                      }}
                      isEditing={this.state.isEditing}
                      initialValues={this.state.initialValues}
                      certificacaoListAutoComplete={this.state.certificacaoList}
                      certificacaoList={this.state.tipoCertificacaoListCheck}
                    />
                  )}
                  {this.state.tabIndex === 0 && (
                    <div style={{ marginTop: 15 }}>
                      <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className={classes.cardTitle}>
                          {formatMessage('produtores.historicoInatividade')}
                        </div>
                      </div>

                      <div className={classes.gridTable} style={{ marginTop: 15 }}>
                        <DataTable
                          noBox={true}
                          noMargin={true}
                          data={this.state.itemSelect.talhaoSituacaoList || []}
                          columns={headDatatable}
                          selectChange={() => {}}
                          showPagination={false}
                          borderRadius={false}
                        />

                        {this.cardButtons(classes, handleSubmit, values)}
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarTalhoes);
export default connect(mapStateToProps)(enhaced);
