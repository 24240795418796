export default {
  municipio: {
    nome: 'Name',
    uf: 'State',
    municipio: 'County',
    identificacaoDoMunicipio: 'Identification',
    falha: 'Error loading county',
    moduloFiscal: 'Modulo de impuestos',
    naoFoiPossivelEditar: 'Could not edit',
    salvando: 'saving',
    campoObrigatorio: 'Required field',
    salvar: 'Salve',
    cancelar: 'Cancel',
    editadoComSucesso: 'Edited successfully'
  }
};
