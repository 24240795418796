import React from 'react';
import { theme } from './stylesSelectWithGrouping';
import { MuiThemeProvider } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

/**
 * Componente Select com agrupamento
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {{
      itens: { style: Object, childrens: any[] }[],
      name: String,
      id: *,
      value: *,
      error: Boolean,
      helperText: String,
      onChange: Function,
      onBlur: Function,
      label: String,
      itemKey: Function | String,
      itemLabel: Function | String,
      categoryLabel: Function | String,
      displayEmpty: Boolean,
      formControlProps: Object,
      inputLabelProps: Object,
      formHelperTextProps: Object
    }} props - props
 * @returns {JSX.Element} Componente React
 */
function SelectWithGrouping(props) {
  const {
    classes,
    name,
    id = `select-id-${name}`,
    value,
    error = false,
    helperText,
    onChange,
    onBlur,
    label,
    itens,
    itemKey,
    itemLabel,
    categoryLabel,
    displayEmpty = true,
    formControlProps = {},
    inputLabelProps,
    formHelperTextProps,
    ...others
  } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl
        error={error}
        {...formControlProps}
        style={{ width: '100%', ...formControlProps.style }}
      >
        <InputLabel htmlFor={id} error={error} {...inputLabelProps}>
          {label}
        </InputLabel>

        <Select
          {...others}
          displayEmpty={displayEmpty}
          inputProps={{ name, id }}
          value={`${value}`}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          id={id}
        >
          {(itens || []).map(categoria => [
            <ListSubheader style={{ paddingLeft: 15, paddingRight: 15, ...categoria.style }}>
              {getCategoryLabel(categoria)}
            </ListSubheader>,

            (categoria.childrens || []).map((item, index2) => (
              <MenuItem
                key={`item-${index2}`}
                value={`${getItemKey(item)}`}
                style={{ paddingLeft: 30, paddingRight: 30, ...item.style }}
              >
                {getItemLabel(item)}
              </MenuItem>
            ))
          ])}
        </Select>

        {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
      </FormControl>
    </MuiThemeProvider>
  );

  /**
   * Retorna a label de cada categoria
   *
   * @param {*} categoria - A categoria que a label vai ser extraida
   * @returns A label da categoria
   */
  function getCategoryLabel(categoria) {
    return typeof categoryLabel === 'function' ? categoryLabel(categoria) : categoria[categoryLabel];
  }

  /**
   * Retorna a key de cada item
   *
   * @param {*} item - O item que a key vai ser extraida
   * @returns A key do item
   */
  function getItemKey(item) {
    return typeof itemKey === 'function' ? itemKey(item) : item[itemKey];
  }

  /**
   * Retorna a label de cada item
   *
   * @param {*} item - O item que a label vai ser extraida
   * @returns {String} A label do item
   */
  function getItemLabel(item) {
    return typeof itemLabel === 'function' ? itemLabel(item) : item[itemLabel];
  }
}

SelectWithGrouping.propTypes = {
  itens: PropTypes.arrayOf(
    PropTypes.shape({
      style: PropTypes.object,
      childrens: PropTypes.array
    })
  ).isRequired,
  name: PropTypes.string,
  id: PropTypes.any,
  value: PropTypes.any.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  itemKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  itemLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  categoryLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  displayEmpty: PropTypes.bool,
  formControlProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  formHelperTextProps: PropTypes.object
};

export default SelectWithGrouping;
