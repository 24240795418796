import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUs';
import esES from './i18n/esES';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import Card from '@components/Card/Card';
import { style } from './Cultivares.styles';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

class Especificacoes extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const cultivar = messagesLinguage['cultivares.cultivar'];
    const descricao = messagesLinguage['cultivares.descricao'];
    const situacao = messagesLinguage['cultivares.situacao'];
    const campoObrigatorio = messagesLinguage['cultivares.campoObrigatorio'];
    const cancelar = messagesLinguage['cultivares.cancelar'];
    const gravar = messagesLinguage['cultivares.gravar'];
    const ativo = messagesLinguage['cultivares.ativo'];
    const inativo = messagesLinguage['cultivares.inativo'];
    const desejaExcluirCultivar = messagesLinguage['cultivares.desejaExcluirCultivar'];
    const nao = messagesLinguage['cultivares.nao'];
    const excluir = messagesLinguage['cultivares.excluir'];
    const cultivarRemovidoComSucesso = messagesLinguage['cultivares.cultivarRemovidoComSucesso'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          cultivar,
          descricao,
          situacao,
          campoObrigatorio,
          gravar,
          cancelar,
          ativo,
          inativo,
          desejaExcluirCultivar,
          nao,
          excluir,
          cultivarRemovidoComSucesso
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      check: true,
      statusEdit: false
    };

    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  clickExcluir() {
    const position = this.props.data.indexOf(this.state.itemSelect);

    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluirCultivar' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        this.props.onClickDeleteEspecificacoes(position);

        swal(provider.intl.formatMessage({ id: 'cultivarRemovidoComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      } else {
        this.setState({
          disabledEdit: false
        });
      }
    });
  }

  clickEditar() {
    this.setState({
      openModal: true,
      statusEdit: true,
      check: this.state.itemSelect.stCultivarEspec === 1
    });

    this.props.clickAdicionarEspecificacao(this.state.itemSelect);
  }

  handleClose() {
    this.setState({
      openModal: false
    });
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  clickAdicionar() {
    this.setState({
      openModal: true,
      statusEdit: false,
      check: true
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.card}>
        <GroupButtonGrid
          showAdd={true}
          showEdit={true}
          showDelete={true}
          disableAdd={this.props.itemGlobal}
          disableEdit={this.state.disabledEdit}
          disableDelete={this.state.disabledEdit || this.props.itemGlobal}
          onClickAdd={this.props.clickAdicionarEspecificacao}
          onClickEdit={this.clickEditar}
          onClickDelete={this.clickExcluir}
        />

        <Card
          title={provider.intl.formatMessage({ id: 'cultivar' })}
          cabecalho={true}
          withOutBorderRadius={true}
          disablePadding
          childrenStyle={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
          }}
        >
          <DataTable
            noMargin={true}
            noBox={true}
            usePaddings={false}
            data={this.props.data}
            columns={this.props.itensIdentificacao}
            selectChange={this.selectChange}
            showPagination={false}
          />
        </Card>
      </div>
    );
  }
}

Especificacoes.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Especificacoes);
