export default {
  cultivares: {
    cultivares: 'Cultivares',
    detentor: 'Detentor',
    cadastroCultivar: 'Cadastro de cultivar',
    codigo: 'Código',
    descricao: 'Descrição',
    cultura: 'Cultura',
    situacao: 'Situação',
    desejaExcluirCultivar: 'Deseja excluir o cultivar selecionado?',
    cultivarRemovidoComSucesso: 'Cultivar removido com sucesso',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    falhaAoExcluirCultivar: 'Falha ao excluir cultivar',
    ativo: 'Ativo',
    inativo: 'Inativo',
    campoObrigatorio: 'Campo Obrigatório',
    identificacaoDetentor: 'Identificação',
    gravar: 'Gravar',
    cancelar: 'Cancelar',
    cultivar: 'Cultivar',
    atualizando: 'Atualizando',
    salvando: 'Salvando',
    cultivarCadastradoComSucesso: 'Cultivar cadastrado com sucesso',
    cultivarEditadoComSucesso: 'Cultivar editado com sucesso',
    erro: 'Erro',
    salvar: 'Salvar',
    falhaAoCadastrarCultivar: 'Falha ao cadastrar cultivar',
    falhaAoEditarCultivar: 'Falha ao editar cultivar',
    confirmar: 'Confirmar',
    salvarCultivarSair: 'Existem informações não salvas, deseja salvar o cultivar?',
    descartar: 'Descartar'
  }
};
