export default {
    recuperarSenha: {
        email: 'Email',
        codigo: 'Código',
        senha: 'Contraseña',
        confirmacaoSenha: 'Confirmación de contraseña',
        voltarLogin: 'Volver al inicio',
        confirmar: 'CONFIRMAR',
        campoObrigatorio: 'Campo obligatorio',
        emailInvalido: 'Email inválido',
        senhaMinimo: 'La contraseña debe tener al menos 6 caracteres',
        falhaRecuperarSenha: "Error al recuperar contraseña",
        falhaConectar: 'Se produjo un error al recuperar la contraseña',
        senhaTrocada: 'Contraseña cambiada',
        codigoExpirado: 'Código caducado',
        codigoInvalido: 'Código no válido',
        usuarioNaoEncontrado: 'Usuario no encontrado',
        sucesso: 'Éxito',
        reencaminhar: 'Usted será reenviado para realizar su login'
    }
}