import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import Infraestrutura from './Infraestrutura';
import AspectosLegais from './AspectosLegais';
import Identificacao from './Identificacao';
import { connect } from 'react-redux';
import Card from '@components/Card/Card';
import formatMessage from './../i18n/formatMessage';

const style = theme => ({
  textField: {
    width: '100%',
    height: '70px',
    whiteSpace: 'nowrap'
  },
  font: {
    fontSize: 14
  },
  form: {
    marginRight: 10,
    width: '100%',
    margin: 0,
    height: '70px'
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    marginTop: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  },
  container: {
    overflow: 'hidden'
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiTypography: {
      headline: {
        color: '#555555',
        fontSize: 24
      },
      h5: {
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.7)'
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    default: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class EditarAreaCultivo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itensUniMedida: [
        { id: 0, name: formatMessage('produtores.acre') },
        { id: 1, name: formatMessage('produtores.alqueire') },
        { id: 2, name: formatMessage('produtores.hectare') },
        { id: 3, name: formatMessage('produtores.m2') }
      ],
      loading: false,
      tabIndex: 0,
      dsLink: null,
      dsArquivo: null,
      dsNomeArquivo: null,
      valueLatitude: '',
      valueLongitude: '',
      estado: null,
      municipio: null,
      comunidade: null,
      nrModuloFiscal: 0,
      nrModuloFiscalFator: 0,
      itemEditAreaCultivo: this.props.itemEditAreaCultivo
    };

    this.onSave = this.onSave.bind(this);
    this.addFile = this.addFile.bind(this);
    this.tabIdentificacao = this.tabIdentificacao.bind(this);
    this.buttonsCard = this.buttonsCard.bind(this);
    this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeModulo = this.changeModulo.bind(this);
    this.changeToNrAreaCultivo = this.changeToNrAreaCultivo.bind(this);
  }

  componentDidMount() {
    let estado = null;
    let municipio = null;

    if (
      this.state.itemEditAreaCultivo.municipio &&
      this.state.itemEditAreaCultivo.municipio.idMunicipio
    ) {
      estado = this.state.itemEditAreaCultivo.municipio.uf;
      municipio = this.state.itemEditAreaCultivo.municipio;

      this.props.validarInicializacaoAutoCompleteMunicipio(estado.idUf);
      this.props.tratarValorComunidade(municipio);
    }

    this.setState({
      dsArquivo: this.state.itemEditAreaCultivo.dsTermoManejoOrganicoBase64,
      dsLink: this.state.itemEditAreaCultivo.dsLink,
      dsNomeArquivo: this.state.itemEditAreaCultivo.dsTermoManejoOrganico,
      valueLatitude: this.state.itemEditAreaCultivo.nrLatitude,
      valueLongitude: this.state.itemEditAreaCultivo.nrLongitude,
      estado: estado,
      municipio: municipio,
      comunidade: this.state.itemEditAreaCultivo.comunidade,
      nrModuloFiscal: this.state.itemEditAreaCultivo.nrModuloFiscal || 0,
      nrModuloFiscalFator: this.state.itemEditAreaCultivo.nrModuloFiscalFator || 0
    });
  }

  addFile(dsArquivo, dsNomeArquivo, dsLink) {
    this.setState({
      dsArquivo: dsArquivo,
      dsLink: dsLink,
      dsNomeArquivo: dsNomeArquivo
    });
  }

  onSave(values) {
    values.dsTermoManejoOrganico = this.state.dsNomeArquivo;
    values.dsTermoManejoOrganicoBase64 = this.state.dsArquivo;
    values.nrLatitude =
      !values.nrLatitude || values.nrLatitude === '' ? null : parseFloat(values.nrLatitude);
    values.nrLongitude =
      !values.nrLongitude || values.nrLongitude === '' ? null : parseFloat(values.nrLongitude);
    values.dsLink = this.state.dsLink;
    values.comunidade = this.state.comunidade;
    values.uf = this.state.estado;
    values.municipio = this.state.municipio;

    if (this.props.statusEdit) {
      values.stRegistro = this.state.itemEditAreaCultivo.stRegistro;
      values.idAreaCultivo = this.state.itemEditAreaCultivo.idAreaCultivo;
      const aux = this.props.data.areaCultivoList.find(
        areaCultivo => areaCultivo.idAreaCultivo === this.state.itemEditAreaCultivo.idAreaCultivo
      );
      const position = this.props.data.areaCultivoList.indexOf(aux);

      this.props.onClickAddAreaCultivo(values, position);
    } else {
      values.stRegistro = 0;
      values.idAreaCultivo = this.props.maxIdAreaCultivo;

      this.props.onClickAddAreaCultivo(values);
    }
  }

  tabIdentificacao(values, errors, touched, handleChange, handleBlur, setFieldValue, classes) {
    return (
      <Identificacao
        changeValue={this.changeValue}
        stOrganica={this.props.infoUsuario.stOrganica}
        classes={classes}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        values={values}
        handleChange={handleChange}
        touched={touched}
        errors={errors}
        itensUniMedida={this.state.itensUniMedida}
        tpPerfil={this.props.tpPerfil}
        estadoList={this.props.estadoList}
        municipioList={this.props.municipioList}
        estado={this.state.estado}
        municipio={this.state.municipio}
        comunidade={this.state.comunidade}
        onChangeAutocomplete={this.onChangeAutocomplete}
        comunidadeList={this.props.comunidadeList}
        tratarValorComunidade={this.props.tratarValorComunidade}
        validarInicializacaoAutoCompleteEstado={this.props.validarInicializacaoAutoCompleteEstado}
        validarInicializacaoAutoCompleteMunicipio={this.props.validarInicializacaoAutoCompleteMunicipio}
        changeModulo={this.changeModulo}
        changeToNrAreaCultivo={this.changeToNrAreaCultivo}
      />
    );
  }

  changeValue(newValue, campo) {
    const itemEditAreaCultivo = { ...this.state.itemEditAreaCultivo };
    itemEditAreaCultivo[campo] = newValue;

    this.setState({
      itemEditAreaCultivo: itemEditAreaCultivo
    });
  }

  changeToNrAreaCultivo(nrAreaCultivo, tpUnidade, nrModuloFiscal) {
    const itemEditAreaCultivo = { ...this.state.itemEditAreaCultivo };
    itemEditAreaCultivo.nrAreaCultivo = nrAreaCultivo;
    itemEditAreaCultivo.tpUnidade = tpUnidade;
    itemEditAreaCultivo.nrModuloFiscal = nrModuloFiscal;

    this.setState({
      itemEditAreaCultivo: itemEditAreaCultivo,
      nrModuloFiscal: nrModuloFiscal
    });
  }

  buttonsCard(classes, handleSubmit) {
    return (
      <MuiThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs className={classes.containerButtons}>
            <div style={{ color: '#463766' }}>
              <Button variant='contained' color='inherit' onClick={this.props.onCancel}>
                {formatMessage('produtores.cancelar')}
              </Button>
            </div>

            <div>
              <Button color='secondary' variant='contained' onClick={handleSubmit}>
                {formatMessage('produtores.salvar')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }

  onChangeAutocomplete(value, campo) {
    if (campo === 'estado' && value) {
      this.props.validarInicializacaoAutoCompleteMunicipio(value.idUf);
      this.setState({
        [campo]: value,
        municipio: null,
        comunidade: null,
        nrModuloFiscal: 0,
        nrModuloFiscalFator: 0
      });

      return;
    }

    if (campo === 'municipio') {
      this.props.tratarValorComunidade(value);

      this.setState({
        [campo]: value,
        comunidade: null,
        nrModuloFiscal: parseFloat(
          this.state.itemEditAreaCultivo.nrAreaCultivo / value.nrModuloFiscal
        ).toFixed(3),
        nrModuloFiscalFator: value.nrModuloFiscal
      });

      return;
    }

    this.setState({
      [campo]: value
    });
  }

  changeModulo(nrModuloFiscal) {
    this.setState({
      nrModuloFiscal: nrModuloFiscal
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        style={{
          marginTop: 30,
          marginBottom: 15,
          display: 'contents',
          width: '100%',
          height: '100%'
        }}
      >
        <MuiThemeProvider theme={theme}>
          <Formik
            initialValues={{
              dsAreaCultivo: this.state.itemEditAreaCultivo.dsAreaCultivo || '',
              nrAreaCultivo: parseFloat(this.state.itemEditAreaCultivo.nrAreaCultivo) || 0,
              dsRelacaoPredial: this.state.itemEditAreaCultivo.dsRelacaoPredial || '',
              dsLocalArmazenamentoInsumos:
                this.state.itemEditAreaCultivo.dsLocalArmazenamentoInsumos || '',
              dsMaquinarioUtensilios: this.state.itemEditAreaCultivo.dsMaquinarioUtensilios || '',
              dsAtividadesDesenvolvidas: this.state.itemEditAreaCultivo.dsAtividadesDesenvolvidas || '',
              dsTermoManejoOrganico: this.state.itemEditAreaCultivo.dsTermoManejoOrganico,
              dtValidadeContrato: this.state.itemEditAreaCultivo.dtValidadeContrato,
              nrLongitude: this.state.itemEditAreaCultivo.nrLongitude,
              nrLatitude: this.state.itemEditAreaCultivo.nrLatitude,
              tpUnidade: this.state.itemEditAreaCultivo.tpUnidade || 0,
              estado: this.state.estado,
              municipio: this.state.municipio,
              comunidade: this.state.comunidade,
              nrModuloFiscal: this.state.nrModuloFiscal,
              nrModuloFiscalFator: this.state.nrModuloFiscalFator
            }}
            validateOnBlur
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={this.onSave}
            validationSchema={Yup.object().shape({
              dsAreaCultivo: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
              tpUnidade: Yup.number().required(formatMessage('produtores.campoObrigatorio')),
              nrAreaCultivo: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
              nrLatitude:
                this.state.valueLongitude || this.state.valueLatitude
                  ? Yup.string()
                      .nullable()
                      .test('', formatMessage('produtores.valorInvalido'), value => {
                        return !(parseFloat(value) < -90 || parseFloat(value) > 90);
                      })
                      .required(formatMessage('produtores.campoObrigatorio'))
                  : Yup.string().nullable(),
              nrLongitude:
                this.state.valueLatitude || this.state.valueLongitude
                  ? Yup.string()
                      .nullable()
                      .test('', formatMessage('produtores.valorInvalido'), value => {
                        return !(parseFloat(value) < -180 || parseFloat(value) > 180);
                      })
                      .required(formatMessage('produtores.campoObrigatorio'))
                  : Yup.string().nullable(),
              comunidade: Yup.object().nullable(),
              municipio: Yup.object()
                .nullable()
                .required(formatMessage('produtores.campoObrigatorio')),
              estado: Yup.object()
                .nullable()
                .required(formatMessage('produtores.campoObrigatorio')),
              nrModuloFiscal: Yup.number()
                .min(0, formatMessage('produtores.mininoZero'))
                .required(formatMessage('produtores.campoObrigatorio')),
              nrModuloFiscalFator: Yup.string()
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              submitForm
            }) => {
              if (this.props.infoUsuario.stOrganica === 0) {
                return (
                  <Card
                    withOutBorderRadius
                    title={formatMessage(
                      this.props.statusEdit
                        ? 'produtores.edicaoAreaCultivo'
                        : 'produtores.cadastroAreaCultivo'
                    )}
                    height='370px'
                    childrenStyle={{ overflow: 'hidden' }}
                  >
                    <div className={classes.container}>
                      {this.tabIdentificacao(
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        classes
                      )}
                      {this.buttonsCard(classes, handleSubmit)}
                    </div>
                  </Card>
                );
              }

              return (
                <CardWithTabs
                  selectedIndex={this.state.tabIndex}
                  onChangeTabIndex={(e, tabIndex) => this.setState({ tabIndex })}
                  subtitle={
                    values && values.dsAreaCultivo && this.state.tabIndex !== 0
                      ? `- ${values.dsAreaCultivo}`
                      : ''
                  }
                  title={formatMessage(
                    this.props.statusEdit
                      ? 'produtores.edicaoAreaCultivo'
                      : 'produtores.cadastroAreaCultivo'
                  )}
                  withoutHidden
                  tabs={[
                    {
                      label: formatMessage('produtores.identificacao'),
                      component: (
                        <div
                          style={{
                            height: '100%',
                            width: '100%'
                          }}
                        >
                          {this.tabIdentificacao(
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            classes
                          )}
                        </div>
                      )
                    },
                    {
                      label: formatMessage('produtores.aspectoLegal'),
                      component: (
                        <AspectosLegais
                          addFile={this.addFile}
                          arquivo={this.state.dsArquivo}
                          link={this.state.dsLink}
                          stRegistro={this.state.itemEditAreaCultivo.stRegistro}
                          nameArquivo={this.state.dsNomeArquivo}
                        />
                      )
                    },
                    {
                      label: formatMessage('produtores.infraestruturas'),
                      component: <Infraestrutura />
                    }
                  ]}
                  componentBelow={this.buttonsCard(classes, handleSubmit)}
                />
              );
            }}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarAreaCultivo);
export default connect(mapStateToProps)(enhaced);
