export default {
  filtrosDeListagem: {
    filtros: 'Filtros',
    tipoDeFiltro: 'Tipo de filtro',
    tipoDeComparacao: 'Tipo de comparação',
    pesquisa: 'Pesquisa',
    filtrosAdicionados: 'Filtros adicionados:',
    filtrar: 'Filtrar',
    igual: 'Igual',
    maiorOuIgual: 'Maior ou igual',
    maior: 'Maior',
    menorOuIgual: 'Menor ou igual',
    menor: 'Menor',
    diferente: 'Diferente',
    contendo: 'Contendo',
    campoObrigatorio: 'Campo obrigatório',
    filtroDuplicado: 'Filtro duplicado',
    gerarRelatorio: 'Gerar relatório',
    ordenarPor: 'Ordenar por:',
    tipoDeOrdem: 'Tipo de ordem',
    ordenacao: 'Ordenação',
    campo: 'Campo',
    impressao: 'Impressão'
  }
};
