const styles = theme => ({
  nameAndIcon: {
    display: 'flex',
    width: '100%'
  },
  cardSwith: {
    width: 170,
    cursor: 'pointer',
    display: 'flex',
    border: '1px solid rgb(0, 0, 0, 0.1)',
    height: 50,
    padding: 5,
    borderRadius: 5,
    margin: '5px 10px',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  cardAll: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  label: {
    cursor: 'pointer',
    color: '#000000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontWeight: 400,
    fontSize: 17
  },
  icon: {
    color: 'rgb(0, 0, 0, 0.8)',
    width: 20,
    height: 20
  },
  data: {
    color: 'rgb(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column'
  },
  dataCard: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 10
  },
  button: {
    width: 35,
    minWidth: 35,
    marginTop: 1,
    height: 30,
    padding: 0,
    marginRight: 10
  },
  cardButton: {
    display: 'flex',
    marginTop: 10,
    marginLeft: 20
  },
  iconButton: {
    height: 30,
    width: 30,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 496px)',
    minHeight: 250,
    marginTop: 15
  },
  form: {
    width: '100%',
    margin: 0,
    height: '75px'
  },
  cardTitle: {
    width: '100%',
    color: 'rgb( 0, 0, 0, 0.7)',
    height: 60,
    display: 'flex',
    fontSize: 20,
    alignItems: 'center',
    fontWeight: 500,
    paddingLeft: 30,
    backgroundColor: 'rgba(139, 200, 165, 0.1)',
    marginBottom: -36
  }
});

export { styles };
