/* 
    autor: Gabriela Farias
*/
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import './MenuLateral.css';
import { Link, withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import footer from '@images/footer_menu.png';
import logoWithWrite from '@images/logo_azagros.png';
import logo from '@images/icon_azagros.png';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ItensMenu from './itensMenu';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = theme => ({
  fundo: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  itens: {
    borderRadius: 10,
    maxHeight: 33,
    display: 'inline'
  },
  drawerPaper: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    backgroundImage: 'linear-gradient(#46A770, #00863B)',
    width: 270,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    position: 'fixed',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200
    }),
    width: 50,
    [theme.breakpoints.up('sm')]: {
      width: 50
    }
  },
  formIcon: {
    marginRight: 15,
    marginTop: 8,
    marginLeft: 0
  },
  listItem: {
    padding: 15
  },
  link: {
    color: 'transparent',
    display: 'inline',
    whiteSpace: 'nowrap'
  },
  icon: {
    backgroundColor: '#E6F8FD',
    backgroundImage: `url(${footer})`,
    backgroundPosition: 'bottom -1px center',
    backgroundRepeat: 'no-repeat',
    maxHeight: '80px !important'
  },
  logoWithWrite: {
    height: 55,
    width: 154,
    marginBottom: '23px',
    marginTop: '5px',
    marginLeft: '25px'
  },
  logo: {
    height: 30,
    width: 30,
    marginLeft: '10px',
    marginBottom: '30px'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '1px solid #EBEBEB'
      }
    }
  }
});

const url = '/app'; /*  Link inicial */

/* 
    Menu lateral customizado
*/

class MenuLateral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.itensDoMenu = this.itensDoMenu.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  animacaoSeta = '';
  corPrimeiroSubmenu = '#00622B';
  corDemaisSubmenus = '#004820';
  controleCor = 0;
  itemSelecionadoAtual = '';

  itensDoMenu(itensMenu) {
    var left = 0;
    const { classes } = this.props;
    return (
      <div className={classes.fundo}>
        {itensMenu.map((post, i) => {
          this.controleCor = 0;
          return this.celulaMenu(post, i, left);
        })}
      </div>
    );
  }
  itemSub(itensMenu) {
    var cor = '';
    var left = '';
    if (this.controleCor === 0) {
      cor = this.corPrimeiroSubmenu;
      left = '55px';
    } else if (this.controleCor > 0) {
      cor = this.corDemaisSubmenus;
      left = '70px';
    }
    return (
      <div style={{ backgroundColor: cor }}>
        {itensMenu.map((post, i) => {
          this.controleCor++;

          const validacaoOrganicos = () => {
            if (this.props.infoUsuario.stOrganica === 1) {
              return post.organico === undefined || post.organico === true;
            }
            return !post.organico;
          };

          const validacaoCooperativa = () => {
            if (this.props.infoUsuario.stCooperativa === 1) {
              return post.cooperativa === undefined || post.cooperativa === true;
            }
            return !post.cooperativa;
          };

          return (
            post.categoria >= this.props.permissaoUsuario &&
            validacaoOrganicos() &&
            validacaoCooperativa() &&
            this.celulaMenu(post, i, left)
          );
        })}
      </div>
    );
  }

  async openMenuIcone(menu) {
    await this.props.dispatch({ type: 'OPEN_MENU' });
    menu.selecionado = true;
  }

  async onSelect(menu) {
    menu.selecionado = !menu.selecionado;
    this.setState({
      open: Math.random()
    });

    if (!this.props.itensState.open) {
      if (menu.children !== undefined) {
        await this.openMenuIcone(menu);
      }
    } else {
      if (menu.children === undefined) {
        if (menu.parent !== undefined) {
          this.itemSelecionadoAtual = menu.parent;
        } else {
          this.itemSelecionadoAtual = menu.route;
        }
        this.props.dispatch({
          type: 'SELECIONAR_ITEM',
          itemSelecionado: this.itemSelecionadoAtual
        });
      }
      if (menu.children !== undefined && !this.props.itensState.open) {
        this.props.dispatch({
          type: 'OPEN_MENU'
        });
      } else if (menu.children === undefined && this.props.itensState.open) {
        this.props.dispatch({
          type: 'CLOSE_MENU'
        });
      }
    }
  }

  celulaMenu(post, i, left) {
    const { classes } = this.props;

    return (
      <List className={classes.itens} component='span' key={i}>
        <div
          onClick={() => {
            if (this.props.infoUsuario.tpPerfil === 3 && post.route === '/produtores') {
              this.props.history.push(`${url}${'/produtores/usuario/perfil'}`);
            } else {
              post.children === undefined && this.props.history.push(`${url}${post.route}`);
            }
          }}
          className={classes.link}
        >
          <ListItem
            button
            selected={false}
            onClick={() => this.onSelect(post)}
            className={classes.listItem}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div
              style={{
                width: post.icon === undefined ? '100%' : 'none',
                display: 'flex'
              }}
            >
              {post.icon !== undefined && (
                <InputAdornment className={classes.formIcon}>
                  <img src={post.icon} alt='icone' style={{ height: 24, width: 24 }} />
                </InputAdornment>
              )}
              <div
                className={this.props.itensState.open ? 'run-animation-open' : 'run-animation-close'}
                style={{
                  marginLeft: post.icon === undefined && left,
                  color: '#FFFFFF',
                  fontWeight: 400,
                  fontSize: 14,
                  fontFamily: "'Roboto', sans-serif",
                  whiteSpace: 'nowrap',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                key={i}
              >
                {post.name}
              </div>
            </div>
            {post.children !== undefined && (
              <div>
                <InputAdornment
                  style={{ textAlign: 'right' }}
                  id={post.selecionado && this.props.itensState.open ? 'iconDown' : 'iconRight'}
                >
                  <img src={require('../../assets/images/seta.png')} alt='icone' />
                </InputAdornment>
              </div>
            )}
          </ListItem>
          {post.selecionado && this.props.itensState.open && (
            <Collapse in={true} timeout='auto' unmountOnExit>
              {post.children !== undefined && this.itemSub(post.children)}
            </Collapse>
          )}
        </div>
      </List>
    );
  }
  closeMenu(e) {
    if (e.target.id !== 'buttonMenu' && e.target.id !== 'botaoMenu') {
      this.props.dispatch({
        type: 'CLOSE_MENU'
      });
    }
  }

  render() {
    const { classes } = this.props;
    const categoria = this.props.permissaoUsuario;

    const itensMenu = ItensMenu.filter(menu => {
      if (categoria === 0) {
        return menu.categoria === 0;
      } else if (
        menu.route === '/cotacoes' ||
        menu.route === '/pesquisar-cotacoes' ||
        menu.route === '/consultar-cotacoes'
      ) {
        if (categoria === 1) {
          menu.route = '/pesquisar-cotacoes';
        } else if (categoria === 2 || categoria === 3) {
          menu.route = '/consultar-cotacoes';
        }
      }

      if (menu.route && menu.route === '/mapandvi' && !this.props.permissaoNdvi) {
        return false;
      }

      return menu.categoria >= categoria && (categoria !== 0 || menu.route !== '/cotacoes');
    });

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div>
          <MuiThemeProvider theme={theme}>
            <Drawer
              variant='permanent'
              open={!this.props.itensState.open}
              classes={{
                paper: classNames(
                  classes.drawerPaper,
                  !this.props.itensState.open && classes.drawerPaperClose
                )
              }}
            >
              <Link to='/app' className={classes.icon}>
                <img
                  src={logo}
                  id={
                    this.props.itensState.open ? 'run-animation-open-logo' : 'run-animation-close-logo'
                  }
                  className={classes.logo}
                  alt='logo'
                />
                <img
                  src={logoWithWrite}
                  id={
                    this.props.itensState.open ? 'run-animation-close-logo' : 'run-animation-open-logo'
                  }
                  className={classes.logoWithWrite}
                  alt='logo'
                />
              </Link>
              {this.itensDoMenu(itensMenu)}
            </Drawer>
          </MuiThemeProvider>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.menuOpen,
  itemSelecionado: state.itemSelecionado,
  infoUsuario: state.adicionarInfoUsuario.info
});

MenuLateral.propTypes = {
  classes: PropTypes.object.isRequired
};

let EnhacedComponent = withStyles(styles)(MenuLateral);
export default withRouter(connect(mapStateToProps)(EnhacedComponent));
