import formatMessage from './../i18n/formatMessage';
import SeveridadeEnum from '@resources/SeveridadeEnum';

export default [
  {
    id: SeveridadeEnum.DOENCA_SOLO,
    name: formatMessage('acompanhamentoProdutor.doencaSolo')
  },
  {
    id: SeveridadeEnum.DOENCA_FOLIAR,
    name: formatMessage('acompanhamentoProdutor.doencaFoliar')
  },
  {
    id: SeveridadeEnum.NEMATOIDE,
    name: formatMessage('acompanhamentoProdutor.nematoide')
  },
  {
    id: SeveridadeEnum.PRAGAS,
    name: formatMessage('acompanhamentoProdutor.pragas')
  }
];
