/*
    Autor: Kátia miglioli
*/
import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconAdd from '@images/icone_add.png';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import '@styles/Alerts.css';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import Comunidade from '@resources/Comunidade';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const style = theme => ({
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 9.5,
    right: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '10%',
    zIndex: 1,
    height: '10%',
    marginBottom: 0,
    marginLeft: '90%'
  }
});
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  }
});

class Planos extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const descricao = messagesLinguage['comunidade.descricao'];
    const municipio = messagesLinguage['comunidade.municipio'];
    const desejaExcluir = messagesLinguage['comunidade.desejaExcluir'];
    const nao = messagesLinguage['comunidade.nao'];
    const excluir = messagesLinguage['comunidade.excluir'];
    const comunidadeRemovidaComSucesso = messagesLinguage['comunidade.comunidadeRemovidaComSucesso'];
    const falhaAoExcluirComunidade = messagesLinguage['comunidade.falhaAoExcluirComunidade'];
    const carregando = messagesLinguage['comunidade.carregando'];
    const uf = messagesLinguage['comunidade.uf'];
    const excluindo = messagesLinguage['comunidade.excluindo'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          descricao,
          municipio,
          desejaExcluir,
          nao,
          excluir,
          comunidadeRemovidaComSucesso,
          carregando,
          falhaAoExcluirComunidade,
          uf,
          excluindo
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      itemSelect: '',
      disabledEdit: true,
      key: '',
      dataInTable: [],
      dataAppSync: [],
      statusAlert: false,
      loading: true,
      head: [
        {
          field: 'nmComunidade',
          headerName: provider.intl.formatMessage({ id: 'descricao' }),
          sort: 'asc',
          col: 4
        },
        {
          field: 'municipio.nmMunicipio',
          headerName: provider.intl.formatMessage({ id: 'municipio' }),
          col: 2
        },
        {
          field: 'municipio.uf.nmUf',
          headerName: provider.intl.formatMessage({ id: 'uf' }),
          col: 6
        }
      ],
      operationValues: [
        {
          label: provider.intl.formatMessage({ id: 'descricao' }),
          field: 'nmComunidade',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'municipio' }),
          field: 'dsMunicipio',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'uf' }),
          field: 'dsUf',
          type: 'string'
        }
      ]
    };

    const title = 'comunidade.comunidades';
    if (this.props.itensState.name !== 'comunidade.comunidades') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  clickAdicionar() {
    this.props.history.push('/app/comunidades/novo');
  }
  clickEditar() {
    this.props.history.push('/app/comunidades/editar/' + this.state.itemSelect.idComunidade);
  }
  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnClickOutside: false,
            title: provider.intl.formatMessage({ id: 'excluindo' })
          }
        );
        Comunidade.deleteComunidadeById(this.state.itemSelect.idComunidade)
          .then(() => {
            swal(
              provider.intl.formatMessage({
                id: 'comunidadeRemovidaComSucesso'
              }),
              {
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                }
              }
            );
            this.atualizarRegistros(this.state.itemSelect.idComunidade);
          })
          .catch(() => {
            swal(provider.intl.formatMessage({ id: 'falhaAoExcluirComunidade' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    });
  }
  atualizarRegistros(dataComunidade) {
    var index = -1;
    for (var i = 0; i < this.state.dataAppSync.length; i++) {
      if (this.state.dataAppSync[i].idComunidade === dataComunidade) {
        index = i;
        break;
      }
    }
    this.state.dataAppSync.splice(index, 1);
    this.setState({
      dataAppSync: this.state.dataAppSync,
      dataInTable: this.state.dataAppSync,
      key: Math.random()
    });

    this.onFilterChanged(this.props.chipData);
  }
  componentDidMount() {
    this.onFilter(this.props.chipData);
  }
  onFilterChanged(dataChips) {
    //this.state.dataAppSync => Contem todos os registros da tabela
    //dataInTable => objeto passado para a tabela
    // onFilterChanged={} em CardFilters deve receber essa funcao
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }
  onFilter(dataChips) {
    dataChips = dataChips || [];

    Comunidade.findAllComunidades()
      .then(querySnapshot => {
        var data = querySnapshot.data;

        data.forEach(query => {
          query.dsMunicipio = query.municipio.nmMunicipio;
          query.dsUf = query.municipio.uf.nmUf;
        });

        this.setState({
          dataAppSync: querySnapshot.data,
          dataInTable: FilterUtil.applyFilters(querySnapshot.data, dataChips),
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          dataAppSync: [],
          loading: false
        });
      });
  }

  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'scroll'
          }}
        >
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='comunidades'
          />
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disabledEdit}
            onClickEdit={this.clickEditar}
            onClickDelete={this.clickExcluir}
          />

          <div
            style={{
              marginTop: 30,
              marginBottom: 15,
              display: 'contents',
              width: '100%',
              height: '100%'
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              key={this.state.key}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={() => this.clickAdicionar()} />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'comunidades';
  })[0].content
});

let enhaced = withStyles(style)(Planos);
export default withRouter(connect(mapStateToProps)(enhaced));
