export default {
  nematicidas: {
    nematicidasRemovidoComSucesso: 'Nematicida removido com sucesso',
    falhaAoExcluirNematicidas: 'Falha ao excluir nematicida',
    desejaExcluir: 'Deseja excluir o nematicida selecionado?',
    nematicidas: 'Nematicidas',
    cadastroDeNematicidas: 'Cadastro de Nematicidas',
    identificacaoDaNematicidas: 'Identificação de nematicidas',
    nematicidasCadastradoComSucesso: 'Nematicida cadastrado com sucesso',
    nematicidasEditadoComSucesso: 'Nematicida editado com sucesso',
    falhaAoEditarNematicidas: 'Falha ao editar nematicida',
    falhaAoCadastrarNematicidas: 'Falha ao cadastrar nematicida',
    falhaAoCarregarNematicidas: 'Falha ao carregar nematicida'
  }
};
