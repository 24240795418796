export default {
  relatorio: {
    relatorio: 'Relatório',
    graosProdutor: 'Grãos por produtor',
    regiao: 'Região',
    municipio: 'Município',
    sim: 'Sim',
    nao: 'Não',
    carregando: 'Carregando...',
    gerandoRelatorio: 'Gerando relatório...',
    falhaAoGerarRelatorio: 'Falha ao gerar relatório',
    dataLancamento: 'Data de lançamento',
    tecnicoResponsavel: 'Técnico responsável',
    produtor: 'Produtor',
    uf: 'UF',
    decrescente: 'Decrescente',
    crescente: 'Crescente',
    media: 'Média',
    minimoFiltro: 'Aplicar no mínimo um filtro',
    mensagemSemFiltro:
      'Sem filtro de produtor, a geração do relatório poderá demorar, continuar mesmo assim?',
    estado: 'Estado',
    comunidade: 'Comunidade',
    safra: 'Safra',
    cultura: 'Cultura',
    periodo: 'Período',
    baixarCSV: 'Baixar CSV'
  }
};
