export default {
  relatorio: {
    relatorio: 'Informe',
    cadastroDeProdutor: 'Registro de productores',
    regiao: 'Región',
    municipio: 'Contacto',
    sim: 'Si',
    nao: 'No',
    carregando: 'Cargando...',
    gerandoRelatorio: 'Generando informe ...',
    falhaAoGerarRelatorio: 'Error al generar informe',
    dataLancamento: 'Fecha de lanzamiento',
    tecnicoResponsavel: 'Técnico responsable',
    produtor: 'Productor',
    uf: 'UF',
    decrescente: 'Disminuyendo',
    crescente: 'Creciente',
    media: 'Promedio',
    minimoFiltro: 'Aplicar al menos un filtro',
    exportarPlanilha: 'Exportar a hoja de cálculo',
    mensagemSemFiltro: 'Sin un filtro de productor, el informe puede tardar mucho tiempo en continuar?'
  }
};
