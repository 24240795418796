import URL from './URL';

const axios = require('axios');
const api = URL.API;

function gerarRegistroProdutoresPotencial(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/produtores-em-potencial');
  } else {
    return axios.get(api + '/produtor/relatorio/produtores-em-potencial?filters=' + filters);
  }
}

function gerarRegistroRelacaoTalhao(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/relacao-talhoes');
  } else {
    return axios.get(api + '/produtor/relatorio/relacao-talhoes?filters=' + filters);
  }
}

function gerarAnaliseRisco(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/analise-riscos-sintetico');
  } else {
    return axios.get(api + '/produtor/relatorio/analise-riscos-sintetico?filters=' + filters);
  }
}

function gerarAnaliseRiscoAnalitico(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/analise-riscos-analitico');
  } else {
    return axios.get(api + '/produtor/relatorio/analise-riscos-analitico?filters=' + filters);
  }
}

function gerarFichaProdutor(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/ficha-produtores');
  } else {
    return axios.get(api + '/produtor/relatorio/ficha-produtores?filters=' + filters);
  }
}

function gerarListaProdutores(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/produtores');
  } else {
    return axios.get(api + '/produtor/relatorio/produtores?filters=' + filters);
  }
}

function gerarRelatorioProdutividadeGraos(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/produtividade-graos');
  } else {
    return axios.get(api + '/produtor/relatorio/produtividade-graos?filters=' + filters);
  }
}

function gerarRelatorioHistoricoProdutividade(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/historico-produtividade');
  } else {
    return axios.get(api + '/produtor/relatorio/historico-produtividade?filters=' + filters);
  }
}

function gerarRelatorioPlantios(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/plantios');
  } else {
    return axios.get(api + '/produtor/relatorio/plantios?filters=' + filters);
  }
}

function gerarRelatorioVisitas(filters) {
  if (filters === '') {
    return axios.get(api + '/produtor/relatorio/visitas');
  } else {
    return axios.get(api + '/produtor/relatorio/visitas?filters=' + filters);
  }
}

export default {
  gerarRegistroProdutoresPotencial,
  gerarRegistroRelacaoTalhao,
  gerarAnaliseRisco,
  gerarAnaliseRiscoAnalitico,
  gerarFichaProdutor,
  gerarListaProdutores,
  gerarRelatorioProdutividadeGraos,
  gerarRelatorioHistoricoProdutividade,
  gerarRelatorioPlantios,
  gerarRelatorioVisitas
};
