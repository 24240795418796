import React, { Component } from 'react';
import formatMessage from '../i18n/formatMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@components/Card/Card';

/**
 * Componente da tab de Semeadura do cadastro de Plantio
 *
 * @author Bruno Eduardo
 * @class Semeadura
 * @extends {Component} - Componente React
 */
class Semeadura extends Component {
  constructor(props) {
    super(props);

    this.handleBlurAndPersist = this.handleBlurAndPersist.bind(this);
  }

  /**
   * Faz o handleBlur do campo e persiste o dado
   *
   * @param {*} e - Blur event
   * @param {String} [campo=null] - Campo para fazer o handleBlur
   * @param {Object} additionalValues - Valores adicionais para persistir
   */
  handleBlurAndPersist(e, campo = null, additionalValues = {}) {
    this.props.persist({ ...this.values, ...additionalValues });

    if (campo) {
      this.handleBlur(campo)(e);
    } else {
      this.handleBlur(e);
    }
  }

  render() {
    const dynamicStyleVisible = this.props.isVisible ? {} : { display: 'none' };

    return (
      <Formik
        initialValues={this.props.values}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={false}
        onSubmit={() => {}}
        validationSchema={Yup.lazy(values =>
          Yup.object().shape({
            nrSemeadura: Yup.number(),
            tpSemeadura:
              values.nrSemeadura === '' || values.nrSemeadura === undefined
                ? Yup.number()
                : Yup.number().required(formatMessage('plantios.campoObrigatorio')),
            tpAreaSemeadura:
              values.nrSemeadura === '' || values.nrSemeadura === undefined
                ? Yup.number()
                : Yup.number().required(formatMessage('plantios.campoObrigatorio')),
            nrEspacamento: Yup.number()
          })
        )}
        render={formikProps => {
          const { values, errors, touched, handleBlur, setFieldValue, submitForm } = formikProps;
          this.props.getFormikProps && this.props.getFormikProps(formikProps);
          this.values = values;
          this.handleSubmit = submitForm;
          this.setFieldValue = setFieldValue;
          this.handleBlur = handleBlur;

          return (
            <Card
              withOutBorderRadius
              title={formatMessage('plantios.semeadura')}
              height='auto'
              style={{ minHeight: 190, maxHeight: 190, ...dynamicStyleVisible }}
            >
              <Grid container>
                <Grid item xs={3} style={{ paddingRight: 15 }}>
                  <TextFieldWithMask
                    tipoMascara={3}
                    numberDecimalScale={3}
                    showThousandSeparator
                    id='nrSemeadura'
                    name='nrSemeadura'
                    onChange={e =>
                      setFieldValue(
                        'nrSemeadura',
                        e.target.value !== '' ? +e.target.value : e.target.value
                      )
                    }
                    onBlur={e => {
                      const tpSemeaduraValue = values.tpSemeadura === '' ? 0 : values.tpSemeadura;
                      const tpAreaSemeaduraValues =
                        values.tpAreaSemeadura === '' ? 0 : values.tpAreaSemeadura;

                      setFieldValue('tpSemeadura', tpSemeaduraValue);
                      setFieldValue('tpAreaSemeadura', tpAreaSemeaduraValues);

                      this.handleBlurAndPersist(e, 'nrSemeadura', {
                        tpSemeadura: tpSemeaduraValue,
                        tpAreaSemeadura: tpAreaSemeaduraValues
                      });
                    }}
                    value={values.nrSemeadura}
                    label={formatMessage('plantios.nrQuantidade')}
                    error={errors.nrSemeadura && touched.nrSemeadura}
                    helperText={touched.nrSemeadura ? errors.nrSemeadura : ''}
                    disabled={this.props.infoUsuario.tpPerfil === 3}
                  />
                </Grid>

                <Grid item xs={3} style={{ paddingRight: 15 }}>
                  <FormControl
                    error={errors.tpSemeadura && touched.tpSemeadura}
                    style={{ width: '100%' }}
                  >
                    <InputLabel htmlFor='tpSemeadura' error={errors.tpSemeadura && touched.tpSemeadura}>
                      {formatMessage('plantios.unidade')}
                    </InputLabel>

                    <Select
                      value={values.nrSemeadura ? '' + values.tpSemeadura : ''}
                      error={errors.tpSemeadura && touched.tpSemeadura}
                      onChange={e =>
                        setFieldValue(
                          'tpSemeadura',
                          e.target.value !== '' ? +e.target.value : e.target.value
                        )
                      }
                      onBlur={this.handleBlurAndPersist}
                      name='tpSemeadura'
                      id='tpSemeadura'
                      disabled={!values.nrSemeadura || this.props.infoUsuario.tpPerfil === 3}
                    >
                      {[
                        formatMessage('plantios.sementes'),
                        formatMessage('plantios.tonelada'),
                        formatMessage('plantios.mudas')
                      ].map((item, i) => (
                        <MenuItem key={i} value={'' + i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.tpSemeadura && touched.tpSemeadura && (
                      <FormHelperText>{errors.tpSemeadura}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={3} style={{ paddingRight: 15 }}>
                  <FormControl
                    error={errors.tpAreaSemeadura && touched.tpAreaSemeadura}
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      htmlFor='tpAreaSemeadura'
                      error={errors.tpAreaSemeadura && touched.tpAreaSemeadura}
                    >
                      {formatMessage('plantios.unMedida')}
                    </InputLabel>

                    <Select
                      value={values.nrSemeadura ? '' + values.tpAreaSemeadura : ''}
                      error={errors.tpAreaSemeadura && touched.tpAreaSemeadura}
                      onChange={e =>
                        setFieldValue(
                          'tpAreaSemeadura',
                          e.target.value !== '' ? +e.target.value : e.target.value
                        )
                      }
                      onBlur={this.handleBlurAndPersist}
                      name='tpAreaSemeadura'
                      id='tpAreaSemeadura'
                      disabled={!values.nrSemeadura || this.props.infoUsuario.tpPerfil === 3}
                    >
                      {[
                        formatMessage('plantios.acre'),
                        formatMessage('plantios.alqueire'),
                        formatMessage('plantios.hectare'),
                        'm²',
                        formatMessage('plantios.metro')
                      ].map((item, i) => (
                        <MenuItem key={i} value={'' + i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.tpAreaSemeadura && touched.tpAreaSemeadura && (
                      <FormHelperText>{errors.tpAreaSemeadura}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <TextFieldWithMask
                    tipoMascara={3}
                    numberDecimalScale={0}
                    showThousandSeparator
                    id='nrEspacamento'
                    name='nrEspacamento'
                    onChange={e =>
                      setFieldValue(
                        'nrEspacamento',
                        e.target.value !== '' ? +e.target.value : e.target.value
                      )
                    }
                    onBlur={e => this.handleBlurAndPersist(e, 'nrEspacamento')}
                    value={values.nrSemeadura ? values.nrEspacamento : ''}
                    label={formatMessage('plantios.espacamento')}
                    error={errors.nrEspacamento && touched.nrEspacamento}
                    helperText={touched.nrEspacamento ? errors.nrEspacamento : ''}
                    disabled={!values.nrSemeadura || this.props.infoUsuario.tpPerfil === 3}
                  />
                </Grid>
              </Grid>
            </Card>
          );
        }}
      />
    );
  }
}

export default Semeadura;
