export default {
  inseticida: {
    inseticidaRemovidoComSucesso: 'Insecticide successfully removed',
    falhaAoExcluirInseticida: 'Failed to exclude insecticide',
    desejaExcluir: 'Do you want to delete the selected insecticide?',
    inseticida: 'Insecticide',
    identificacaoDaInseticida: 'Identification',
    inseticidaCadastradoComSucesso: 'Insecticide successfully registered',
    inseticidaEditadoComSucesso: 'Insecticide edited successfully',
    falhaAoEditarInseticida: 'Failed to edit insecticide',
    falhaAoCadastrarInseticida: 'Failed to register insecticide',
    falhaAoCarregarInseticida: 'Failed to load insecticide',
    cadastroDeInseticida: 'Insecticide register'
  }
};
