export default {
  inseticida: {
    inseticida: 'Insecticida',
    inseticidaRemovidoComSucesso: 'Insecticida quitado con éxito',
    falhaAoExcluirInseticida: 'Fallo la excluir insecticida',
    desejaExcluir: '¿Desea eliminar el insecticida seleccionada?',
    identificacaoDaInseticida: 'Identificación',
    inseticidaCadastradoComSucesso: 'Insecticida registrado con éxito',
    inseticidaEditadoComSucesso: 'Insecticida editado con éxito',
    falhaAoEditarInseticida: 'Falla al editar insecticida',
    falhaAoCadastrarInseticida: 'Error al editar insecticida',
    falhaAoCarregarInseticida: 'Error al cargar insecticida',
    cadastroDeInseticida: ' Registro de insecticidas'
  }
};
