export default {
  evento: {
    campoObrigatorio: 'Campo obrigatório',
    agendarVisita: 'Agendar visita',
    excluir: 'Excluir',
    cancelar: 'Cancelar',
    confirmar: 'Confirmar',
    data: 'Data',
    horario: 'Horário Inicial',
    horarioFinal: 'Horário final',
    horarioInvalido: 'Horário final deve ser maior que horário inicial',
    produtor: 'Produtor',
    tecnico: 'Técnico',
    visitaRealizada: 'Visita realizada',
    observacao: 'Observação',
    resumoVisita: 'Resumo da visita',
    voltar: 'Voltar',
    responsavel: 'Responsável',
    gerente: 'Gerente'
  }
};
