export default {
  relatorio: {
    relatorio: 'Report',
    cadastroDeProdutor: 'Producer Registration',
    regiao: 'Region',
    municipio: 'City',
    sim: 'Yes',
    nao: 'Not',
    carregando: 'Loading...',
    gerandoRelatorio: 'Generating report ...',
    falhaAoGerarRelatorio: 'Failed to generate report',
    dataLancamento: 'Release date of',
    tecnicoResponsavel: 'Responsible technician',
    produtor: 'Producer',
    uf: 'UF',
    decrescente: 'Decreasing',
    crescente: 'Crescent',
    media: 'Average',
    minimoFiltro: 'Apply at least one filter',
    exportarPlanilha: 'Export to spreadsheet',
    mensagemSemFiltro: 'Without a producer filter, the report may take a long time to continue?'
  }
};
