export default {
  fertilizantesFoliares: {
    fertilizantesFoliaresRemovidoComSucesso: 'Foliar fertilizer successfully removed',
    falhaAoExcluirFertilizantesFoliares: 'Failed to exclude foliar fertilizer',
    desejaExcluir: 'Do you want to delete the selected foliar fertilizer?',
    fertilizantesFoliares: 'Foliar fertilizers',
    cadastroDeFertilizantesFoliares: 'Foliar fertilizer record',
    identificacaoDaFertilizantesFoliares: 'Identification',
    fertilizantesFoliaresCadastradoComSucesso: 'Foliar fertilizer successfully registered',
    fertilizantesFoliaresEditadoComSucesso: 'Foliar fertilizer edited successfully',
    falhaAoEditarFertilizantesFoliares: 'Failed to edit foliar fertilizer',
    falhaAoCadastrarFertilizantesFoliares: 'Failed to register foliar fertilizer',
    falhaAoCarregarFertilizantesFoliares: 'Failed to load foliar fertilizer'
  }
};
