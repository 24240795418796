import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllRacasHandler() {
  return axios.get(api + '/raca-animal');
}

function findRacaById(idRaca) {
  return axios.get(api + '/raca-animal/' + idRaca);
}

function deleteRacaByIdHandler(idRaca) {
  return axios.delete(api + '/raca-animal/' + idRaca);
}

function addRacaHandler(values) {
  return axios.post(api + '/raca-animal', values);
}

function editRacaHandler(values) {
  return axios.put(api + '/raca-animal', values);
}

function findRacaByEspecieHandler(idEspecie) {
  return axios.get(api + '/raca-animal/especie/' + idEspecie);
}

export default {
  findAllRaca: findAllRacasHandler,
  findRacaById: findRacaById,
  deleteRacaById: deleteRacaByIdHandler,
  addRaca: addRacaHandler,
  editRaca: editRacaHandler,
  findRacaByEspecie: findRacaByEspecieHandler
};
