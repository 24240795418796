import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Raca from '@resources/Raca';
import Especie from '@resources/Especie';
import Checkbox from '@material-ui/core/Checkbox';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});

const style = () => ({
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    cursor: 'pointer'
  },
  card: {
    width: '100%',
    height: '100%'
  },
  cardGrid: {
    width: '100%',
    height: '100%',
    marginTop: 23
  },
  font: {
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente de edição de raça
 *
 * @author Gabriela Farias
 * @class EditarRaca
 * @extends {Component} - Componente React
 */
class EditarRaca extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const racaCadastradaComSucesso = messagesLinguage['raca.racaCadastradaComSucesso'];
    const racaEditadaComSucesso = messagesLinguage['raca.racaEditadaComSucesso'];
    const falhaAoEditarRaca = messagesLinguage['raca.falhaAoEditarRaca'];
    const falhaAoCadastrarRaca = messagesLinguage['raca.falhaAoCadastrarRaca'];
    const falhaAoCarregarRaca = messagesLinguage['raca.falhaAoCarregarRaca'];
    const salvar = messagesLinguage['raca.salvar'];
    const cancelar = messagesLinguage['raca.cancelar'];
    const salvando = messagesLinguage['raca.salvando'];
    const atualizando = messagesLinguage['raca.atualizando'];
    const campoObrigatorio = messagesLinguage['raca.campoObrigatorio'];
    const identificacao = messagesLinguage['raca.identificacao'];
    const descricao = messagesLinguage['raca.descricao'];
    const especie = messagesLinguage['raca.especie'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          racaCadastradaComSucesso,
          racaEditadaComSucesso,
          falhaAoEditarRaca,
          falhaAoCadastrarRaca,
          falhaAoCarregarRaca,
          salvar,
          cancelar,
          salvando,
          atualizando,
          campoObrigatorio,
          identificacao,
          descricao,
          especie
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      keyAutoComplete: Math.random(),
      especie: {},
      listEspecies: [],
      nmRaca: '',
      loading: this.props.match.params.id !== undefined,
      idRaca: '',
      checked: true
    };

    const title = 'raca.cadastroDeRaca';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.clickCadastrarEditar = this.clickCadastrarEditar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.onChangeEspecie = this.onChangeEspecie.bind(this);
    this.loadEspecies = this.loadEspecies.bind(this);
    this.tratarValorEspecie = this.tratarValorEspecie.bind(this);
    this.iniciarAtributosEdicao = this.iniciarAtributosEdicao.bind(this);
  }

  componentDidMount() {
    this.loadEspecies();

    if (this.props.match.params.id !== undefined) {
      this.iniciarAtributosEdicao();
    }
  }

  iniciarAtributosEdicao() {
    Raca.findRacaById(this.props.match.params.id)
      .then(query => {
        this.setState({
          nmRaca: query.data.dsRacaAnimal,
          idRaca: query.data.idRacaAnimal,
          especie: query.data.especieAnimal,
          checked: query.data.stRacaAnimal === 1,
          loading: false,
          keyAutoComplete: Math.random()
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        swal(provider.intl.formatMessage({ id: 'falhaAoCarregarRaca' }), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  clickCadastrarEditar(values) {
    if (!this.state.validarInputs) {
      swal(
        <div>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>,
        {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          title: provider.intl.formatMessage({
            id: this.props.match.params.id === undefined ? 'salvando' : 'atualizando'
          })
        }
      );

      const data = {
        dsRacaAnimal: values.nmRaca,
        especieAnimal: values.especie,
        stRacaAnimal: this.state.checked ? 1 : 0
      };

      if (this.props.match.params.id === undefined) {
        Raca.addRaca(data)
          .then(query => {
            swal(
              provider.intl.formatMessage({
                id: 'racaCadastradaComSucesso'
              }),
              {
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                }
              }
            ).then(() => {
              this.clickCancelar();
            });
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarRaca' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      } else {
        data.idRacaAnimal = this.props.match.params.id;

        Raca.editRaca(data)
          .then(query => {
            swal(provider.intl.formatMessage({ id: 'racaEditadaComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              this.clickCancelar();
            });
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: 'falhaAoEditarRaca' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    }
  }

  clickCancelar() {
    this.props.history.push('/app/raca');
  }

  onChangeEspecie(especie) {
    if (especie) {
      this.setState({
        especie: especie
      });
    } else {
      this.setState({ especie: [] });
    }
  }

  loadEspecies() {
    Especie.findAllEspeciesAtivo()
      .then(res => this.setState({ listEspecies: res.data }))
      .catch(() => this.setState({ listEspecies: [] }));
  }

  tratarValorEspecie() {
    if (!this.state.especie || this.state.especie.idEspecieAnimal === undefined) {
      return '';
    }

    return this.state.especie;
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Formik
          initialValues={{
            nmRaca: this.state.nmRaca,
            especie: this.state.especie
          }}
          onSubmit={this.clickCadastrarEditar}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            nmRaca: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
            especie: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleChange
          }) => (
            <MuiThemeProvider theme={theme}>
              <form className={classes.card} autoComplete='off'>
                {this.state.loading && <Loading />}
                <CardIcon titulo={provider.intl.formatMessage({ id: 'identificacao' })}>
                  <div className={classes.cardGrid}>
                    <Grid container spacing={16}>
                      <Grid item xs={4}>
                        <TextField
                          label={provider.intl.formatMessage({ id: 'descricao' })}
                          name='nmRaca'
                          value={values.nmRaca}
                          onChange={value => {
                            setFieldValue('nmRaca', value.target.value);

                            this.setState({
                              nmRaca: value.target.value
                            });
                          }}
                          onBlur={value => {
                            setFieldValue('nmRaca', value.target.value);

                            this.setState({
                              nmRaca: value.target.value
                            });
                          }}
                          error={errors.nmRaca && touched.nmRaca}
                          helperText={errors.nmRaca && touched.nmRaca ? errors.nmRaca : null}
                          style={{ width: '100%', height: 70 }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Autocomplete
                          itens={this.state.listEspecies}
                          campoOp='dsEspecieAnimal'
                          campoChave='idEspecieAnimal'
                          label={provider.intl.formatMessage({ id: 'especie' })}
                          name='especie'
                          error={errors.especie && touched.especie}
                          helperText={
                            touched.especie && errors.especie
                              ? provider.intl.formatMessage({ id: 'campoObrigatorio' })
                              : null
                          }
                          onChangeOwn={this.onChangeEspecie}
                          onChangeAutoComplete={this.loadEspecies}
                          value={this.tratarValorEspecie()}
                          key={this.state.keyAutoComplete}
                          valueAutoComplete={this.tratarValorEspecie()}
                          valueSelect={this.tratarValorEspecie().dsEspecieAnimal}
                          disabled={this.props.infoUsuario.tpPerfil === 3}
                          maxHeight='calc(100vh - 250px)'
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <div
                          className={classes.switch}
                          onClick={() => this.setState({ checked: !this.state.checked })}
                        >
                          <Checkbox
                            checked={this.state.checked}
                            value='stEspecieAnimal'
                            color='primary'
                          />
                          <label style={{ color: '#000000' }}>
                            <FormattedMessage id='raca.ativo' />
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </CardIcon>
                <div className={classes.fab}>
                  <ButtonFABMenu
                    icon={iconMenuPontos}
                    actions={[
                      {
                        icon: <Check style={{ color: '#FFFFFF' }} />,
                        name: provider.intl.formatMessage({ id: 'salvar' }),
                        onClickIcon: handleSubmit,
                        color: '#42ADE8'
                      },
                      {
                        icon: <Close style={{ color: '#FFFFFF' }} />,
                        name: provider.intl.formatMessage({ id: 'cancelar' }),
                        onClickIcon: this.clickCancelar,
                        color: '#F33A30'
                      }
                    ]}
                    onClickButton={this.clickAdicionar}
                  />
                </div>
              </form>
            </MuiThemeProvider>
          )}
        />
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarRaca);
export default withRouter(connect(mapStateToProps)(enhaced));
