import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { style } from './../Auditoria.styles';

/**
 * Screen de listagem de medida corretiva
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function MedidaCorretiva(props) {
  const { sancaoMedidaCorretiva, classes } = props;

  return (
    <div
      key={sancaoMedidaCorretiva.medidaCorretiva.idSancaoMedidaCorretiva}
      className={classes.cardCheckLabelMedidas}
    >
      <div className={classes.checkMedida} />
      <div className={classes.labelInfo}>{sancaoMedidaCorretiva.medidaCorretiva.nmMedidaCorretiva}</div>
    </div>
  );
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(MedidaCorretiva)));
