import React from 'react';
import { style } from './../InsumoRecord.styles';
import { Edit } from '@material-ui/icons';
import moment from 'moment';
import formatMessage from './../i18n/formatMessage';
import { withStyles } from '@material-ui/core/styles';

/**
 * Componente para listagem na aba de certificação
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function Certificacao(props) {
  const { classes, certificacaoList, clickUpdate, formatTextField } = props;

  /**
   * Retorna a data sem hora
   */
  function getDateWithoutHour(date) {
    return moment(date).startOf('day');
  }

  /**
   * Valida se as datas estão entre o dia atual
   */
  function validationDate(dtInicio, dtTermino) {
    const isAfterToday = !dtTermino && getDateWithoutHour(dtInicio).isBefore(getDateWithoutHour());
    const isBetweenToday =
      getDateWithoutHour(dtTermino).isAfter(getDateWithoutHour()) &&
      getDateWithoutHour(dtInicio).isBefore(getDateWithoutHour());
    const isUndefined = !dtTermino && !dtInicio;

    return isAfterToday || isBetweenToday || isUndefined;
  }

  return (
    <div style={{ height: '100%' }}>
      <div className={classes.cardScroll}>
        {certificacaoList.length === 0 ? (
          <div className={classes.nenhumaCertificacaoLabel}>
            {formatMessage('insumo.nenhumaCertificacaoAtiva')}
          </div>
        ) : (
          certificacaoList
            .filter(
              item =>
                item.stInsumoTipoCertificacao === 1 && validationDate(item.dtInicio, item.dtTermino)
            )
            .map(item => (
              <div className={classes.cardCertificacao} key={item.idInsumoTipoCertificacao}>
                <div className={classes.labelEditCertificacao}>
                  {item.tipoCertificacao.nmTipoCertificacao}
                  <Edit className={classes.cursorIcone} onClick={() => clickUpdate(item)} />
                </div>

                <div className={classes.labelDatas}>
                  {`${formatMessage('insumo.dataInicial')}: ${
                    item.dtInicio
                      ? moment(item.dtInicio).format(formatTextField())
                      : formatMessage('insumo.indefinida')
                  }`}
                  <div style={{ width: 30 }} />
                  {`${formatMessage('insumo.dataFinal')}: ${
                    item.dtTermino
                      ? moment(item.dtTermino).format(formatTextField())
                      : formatMessage('insumo.indefinida')
                  } `}
                  <div style={{ width: 30 }} />
                  {`${formatMessage('insumo.status')}: ${
                    item.stInsumoTipoCertificacao === 1
                      ? formatMessage('insumo.ativo')
                      : formatMessage('insumo.inativo')
                  } `}
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  );
}

export default withStyles(style)(Certificacao);
