export default {
  oleosAdjuvantes: {
    oleosAdjuvantesRemovidoComSucesso: 'Oils and adjuvants successfully removed',
    falhaAoExcluirOleosAdjuvantes: 'Failed to exclude oils and adjuvants',
    desejaExcluir: 'Do you want to delete the selected oils and adjuvants?',
    oleosAdjuvantes: 'Oils and adjuvants',
    cadastroDeOleosAdjuvantes: 'Oils and adjuvants record',
    identificacaoDaOleosAdjuvantes: 'Identification',
    oleosAdjuvantesCadastradoComSucesso: 'Oils and adjuvants successfully registered',
    oleosAdjuvantesEditadoComSucesso: 'Oils and adjuvants edited successfully',
    falhaAoEditarOleosAdjuvantes: 'Failed to edit oils and adjuvants',
    falhaAoCadastrarOleosAdjuvantes: 'Failed to register oils and adjuvants',
    falhaAoCarregarOleosAdjuvantes: 'Failed to load oils and adjuvants'
  }
};
