import React from 'react';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Grid from '@material-ui/core/Grid';
import Card from '@components/Card/Card';
import Chip from '@components/Chip/Chip';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import formatMessage from './../i18n/formatMessage';

/**
 * Componente que representa o Card Certificações na tab `Identificação`
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const Certificacoes = props => {
  const {
    classes,
    tpPerfil,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    itensEcosocial = [],
    ecosocialListValues = [],
    onAddEcosocial,
    onRemoveEcosocial,
    onBlur
  } = props;

  return (
    <Card
      marginBottom
      withOutBorderRadius
      title={formatMessage('produtores.certificacoes')}
      height='auto'
      style={{ minHeight: 280 }}
    >
      <Grid container spacing={16} style={{ minHeight: 70 }}>
        <Grid item xs={4}>
          <TextField
            disabled={tpPerfil === 3}
            inputProps={{
              maxLength: 10
            }}
            id='cdCertificadora'
            name='cdCertificadora'
            value={values.cdCertificadora}
            label={formatMessage('produtores.codigoCertificadora')}
            className={classes.textField}
            margin='normal'
            InputLabelProps={{ className: classes.font }}
            multiline={false}
            error={errors.cdCertificadora && touched.cdCertificadora}
            helperText={touched.cdCertificadora ? errors.cdCertificadora : ''}
            onBlur={values => onBlur(values, 'cdCertificadora')}
            onChange={value => setFieldValue('cdCertificadora', value.target.value)}
          />
        </Grid>

        <Grid item xs={4} style={{ paddingRight: 0 }}>
          <FormControl className={classes.form} error={errors.ecosocial && touched.ecosocial}>
            <InputLabel
              htmlFor='ecosocial'
              error={errors.ecosocial && touched.ecosocial}
              className={classes.font}
            >
              {formatMessage('produtores.ecosocial')}
            </InputLabel>

            <Select
              disabled={tpPerfil === 3}
              style={{ marginLeft: 0, fontSize: 14, width: '100%' }}
              SelectDisplayProps={{ style: { width: '100%', backgroundColor: 'transparent' } }}
              value={values.ecosocial}
              error={errors.ecosocial && touched.ecosocial}
              onChange={handleChange}
              onBlur={value => onBlur(value, 'ecosocial')}
              displayEmpty
              inputProps={{ name: 'ecosocial', id: 'ecosocial' }}
            >
              {itensEcosocial.map((eco, i) => (
                <MenuItem key={i} value={eco.idTipoEcosocial} className={classes.font}>
                  {eco.nmTipoEcosocial}
                </MenuItem>
              ))}
            </Select>

            {errors.ecosocial && touched.ecosocial && (
              <FormHelperText>{errors.ecosocial}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            minHeight: 70,
            paddingLeft: 15
          }}
        >
          <IconButton
            aria-label='ADD'
            style={{ height: 50, width: 50, marginTop: 5, marginRight: 15 }}
            disabled={!values.ecosocial}
            onClick={() =>
              onAddEcosocial(itensEcosocial.find(item => item.idTipoEcosocial === +values.ecosocial))
            }
          >
            <AddIcon />
          </IconButton>
        </Grid>
        <Grid container spacing={16} style={{ minHeight: 70, paddingLeft: 15 }}>
          {ecosocialListValues.map((item, i) => (
            <Chip key={i} text={item.nmTipoEcosocial} onRemove={() => onRemoveEcosocial(i)} />
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Certificacoes;
