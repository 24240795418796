//Autor: Kátia miglioli

import React, { Component } from "react";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { flattenMessages } from "@utils/languageUtils";
import { IntlProvider } from "react-intl";
import { getUserLang } from "@utils/localeUtils";
import ptBR from "./i18n/ptBR";
import enUS from "./i18n/enUS";
import esES from "./i18n/esES";
import CardIcon from "@components/CardIcon/CardIcon";
import Grid from "@material-ui/core/Grid";
import TextField from "@components/TextFieldOwn/TextFieldOwn";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ButtonFABMenu from "@components/ButtonFABMenu/ButtonFABMenu";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "@sweetalert/with-react";
import Loading from "@components/Loading/Loading";
import Switch from "@material-ui/core/Switch";
import Usuario from "@resources/Usuario";
import TextFieldWithMask from "@components/TextFieldWithMask/TextFieldWithMask";

let messages = { "pt-BR": ptBR, "en-US": enUS, "es-ES": esES };

const iconMenuPontos = require("@images/icone_fab_menu.png");

let { provider } = new IntlProvider({});

const style = () => ({
  parentDiv: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  topDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  identif_Div: {
    flex: 1,
    position: "relative",
    maxHeight: "180px",
    minHeight: "170px"
  },
  font: {
    fontSize: 14,
    width: "100%",
    height: 15,
    writeSpace: "noWrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  fab: {
    position: "relative",
    bottom: theme.spacing.unit * 34.5,
    right: theme.spacing.unit * 3.7,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#00622B",
      main: "#00622B",
      dark: "#00622B",
      contrastText: "#fff"
    }
  },
  typography: {
    useNextVariants: true
  }
});

class EditarFungicida extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const cadastradoComSucesso =
      messagesLinguage["tecnico.cadastradoComSucesso"];
    const editadoComSucesso = messagesLinguage["tecnico.editadoComSucesso"];
    const falhaAoCarregar = messagesLinguage["tecnico.falhaAoCarregar"];
    const salvar = messagesLinguage["tecnico.salvar"];
    const cancelar = messagesLinguage["tecnico.cancelar"];
    const salvando = messagesLinguage["tecnico.salvando"];
    const atualizando = messagesLinguage["tecnico.atualizando"];
    const emailDuplicado = messagesLinguage["tecnico.emailDuplicado"];
    const naoFoiPossivelInserir =
      messagesLinguage["tecnico.naoFoiPossivelInserir"];
    const naoFoiPossivelEditar =
      messagesLinguage["tecnico.naoFoiPossivelEditar"];
    const numeroMaximoTecnicos =
      messagesLinguage["tecnico.numeroMaximoTecnicos"];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          cadastradoComSucesso,
          editadoComSucesso,
          falhaAoCarregar,
          salvar,
          cancelar,
          salvando,
          atualizando,
          naoFoiPossivelEditar,
          naoFoiPossivelInserir,
          emailDuplicado,
          numeroMaximoTecnicos
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      infoTecnico: [],
      key: "",
      loading: this.props.match.params.id !== undefined,
      idPesticida: ""
    };

    const title = "tecnico.cadastro";
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: "UPDATE_TOOLBAR",
        toolbar: (
          <IntlProvider
            locale={getUserLang()}
            messages={flattenMessages(messages[getUserLang()])}
          >
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.iniciarAtributosEdicao = this.iniciarAtributosEdicao.bind(this);
    this.clickCadastrarEditar = this.clickCadastrarEditar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
  }
  tentativasDeCadastroUsuario = 0;
  tentativasDeCadastroTecnico = 0;

  componentDidMount() {
    this.iniciarAtributosEdicao();
  }

  iniciarAtributosEdicao() {
    if (this.props.match.params.id !== undefined) {
      Usuario.findUsuariosById(this.props.match.params.id)
        .then(query => {
          this.setState({
            infoTecnico: query.data,
            key: Math.random(),
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal(provider.intl.formatMessage({ id: "falhaAoCarregar" }), {
            icon: "error",
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                closeModal: true,
                className: "swal2-error"
              }
            }
          });
        });
    } else {
      var data = {};
      data.nmUsuario = "";
      data.dsTelefone = "";
      data.dsEmail = "";
      data.stUsuario = 1;
      this.setState({
        infoTecnico: data,
        key: Math.random()
      });
    }
  }

  clickCadastrarEditar(values) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: provider.intl.formatMessage({
          id:
            this.props.match.params.id === undefined
              ? "salvando"
              : "atualizando"
        })
      }
    );

    if (this.props.match.params.id === undefined) {
      const data = {
        nmUsuario: values.nome,
        dsEmail: values.email,
        tpPerfil: 2,
        stUsuario: values.situacao ? 1 : 0,
        nrTelefone: values.telefone
      };
      Usuario.addUsuario(data)
        .then(query => {
          swal(provider.intl.formatMessage({ id: "cadastradoComSucesso" }), {
            icon: "success",
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                closeModal: true,
                className: "swal2-Ok"
              }
            }
          }).then(() => {
            this.clickCancelar();
          });
        })
        .catch(err => {
          if (
            err.response !== undefined &&
            (err.response.data.codigo === "LIMITE_TECNICO" ||
              err.response.data.codigo === "LIMITE_USUARIO")
          ) {
            swal(provider.intl.formatMessage({ id: "numeroMaximoTecnicos" }), {
              icon: "error",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-error"
                }
              }
            });
          } else if (
            err.response !== undefined &&
            err.response.data.codigo === "EMAIL_DUPLICADO"
          ) {
            swal(provider.intl.formatMessage({ id: "emailDuplicado" }), {
              icon: "error",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-Ok"
                }
              }
            });
          } else {
            swal(provider.intl.formatMessage({ id: "naoFoiPossivelInserir" }), {
              icon: "error",
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: "swal2-Ok"
                }
              }
            });
          }
        });
    } else {
      const data = {
        nmUsuario: values.nome,
        dsEmail: values.email,
        tpPerfil: 2,
        stUsuario: values.situacao ? 1 : 0,
        idUsuario: this.props.match.params.id,
        nrTelefone: values.telefone
      };

      Usuario.editusuario(data)
        .then(query => {
          swal(provider.intl.formatMessage({ id: "editadoComSucesso" }), {
            icon: "success",
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                closeModal: true,
                className: "swal2-Ok"
              }
            }
          }).then(() => {
            this.clickCancelar();
          });
        })
        .catch(err => {
          swal(provider.intl.formatMessage({ id: "naoFoiPossivelEditar" }), {
            icon: "error",
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                closeModal: true,
                className: "swal2-Ok"
              }
            }
          });
        });
    }
  }

  clickCancelar() {
    this.props.history.push("/app/tecnicos");
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider
        locale={getUserLang()}
        messages={flattenMessages(messages[getUserLang()])}
      >
        <Formik
          key={this.state.key}
          initialValues={{
            nome: this.state.infoTecnico.nmUsuario || "",
            telefone: this.state.infoTecnico.nrTelefone || "",
            email: this.state.infoTecnico.dsEmail || "",
            situacao: this.state.infoTecnico.stUsuario === 1
          }}
          onSubmit={this.clickCadastrarEditar}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            nome: Yup.string().required(
              <FormattedMessage id="tecnico.campoObrigatorio" />
            ),
            email: Yup.string()
              .email(<FormattedMessage id="tecnico.emailInvalido" />)
              .required(<FormattedMessage id="tecnico.campoObrigatorio" />),
            telefone:
              getUserLang() === "pt-BR"
                ? Yup.string()
                    .required(
                      <FormattedMessage id="tecnico.campoObrigatorio" />
                    )
                    .min(14, <FormattedMessage id="tecnico.telefoneInvalido" />)
                : Yup.string().required(
                    <FormattedMessage id="tecnico.campoObrigatorio" />
                  )
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleChange
          }) => (
            <MuiThemeProvider theme={theme}>
              <form
                autoComplete="off"
                className={classes.parentDiv}
                onSubmit={handleSubmit}
              >
                <div className={classes.topDiv}>
                  {this.state.loading && <Loading />}
                  <div className={classes.identif_Div}>
                    <CardIcon
                      titulo={<FormattedMessage id="tecnico.identificacao" />}
                    >
                      <Grid
                        container
                        spacing={16}
                        style={{ marginTop: "15px" }}
                      >
                        <Grid item xs={4} style={{ height: "75px" }}>
                          <TextField
                            label={<FormattedMessage id="tecnico.nome" />}
                            name="nome"
                            value={values.nome}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.nome && touched.nome}
                            helperText={
                              errors.nome && touched.nome ? (
                                <FormattedMessage id="tecnico.campoObrigatorio" />
                              ) : null
                            }
                            className={classes.textField}
                            style={{ width: "100%" }}
                            inputProps={{ className: classes.font }}
                            InputLabelProps={{ className: classes.font }}
                          />
                        </Grid>
                        <Grid item xs={2} style={{ height: "75px" }}>
                          <TextFieldWithMask
                            id="telefone"
                            tipoMascara={1}
                            label={<FormattedMessage id="tecnico.telefone" />} //telefone
                            name="telefone"
                            value={values.telefone}
                            onChange={event => {
                              event.target.name = "telefone";
                              handleChange(event);
                            }}
                            onBlur={handleBlur}
                            error={errors.telefone && touched.telefone}
                            helperText={touched.telefone ? errors.telefone : ""}
                            className={classes.textField}
                            style={{ width: "100%" }}
                            inputProps={{ className: classes.font }}
                            InputLabelProps={{ className: classes.font }}
                          />
                        </Grid>
                        <Grid item xs={3} style={{ height: "75px" }}>
                          <TextField
                            label={<FormattedMessage id="tecnico.email" />}
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.email && touched.email}
                            helperText={
                              errors.email && touched.email
                                ? errors.email
                                : null
                            }
                            className={classes.textField}
                            style={{ width: "100%" }}
                            inputProps={{ className: classes.font }}
                            InputLabelProps={{ className: classes.font }}
                            disabled={this.props.match.params.id !== undefined}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div style={{ width: "150px", marginTop: "10px" }}>
                            <label style={{ color: "#000000" }}>
                              <FormattedMessage id="tecnico.ativo" />
                            </label>
                            <Switch
                              color="primary"
                              checked={values.situacao}
                              onChange={() => {
                                setFieldValue("situacao", !values.situacao);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </CardIcon>
                  </div>
                </div>
                <div className={classes.fab}>
                  <ButtonFABMenu
                    icon={iconMenuPontos}
                    actions={[
                      {
                        icon: <Check style={{ color: "white" }} />,
                        name: provider.intl.formatMessage({ id: "salvar" }),
                        onClickIcon: handleSubmit,
                        color: "#42ADE8"
                      },
                      {
                        icon: <Close style={{ color: "white" }} />,
                        name: provider.intl.formatMessage({ id: "cancelar" }),
                        onClickIcon: this.clickCancelar,
                        color: "#F33A30"
                      }
                    ]}
                    onClickButton={this.clickAdicionar}
                  />
                </div>
              </form>
            </MuiThemeProvider>
          )}
        />
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarFungicida);
export default withRouter(connect(mapStateToProps)(enhaced));
