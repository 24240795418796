import URL from './URL';
import axios from 'axios';

/**
 * Busca a lista de imagens
 *
 * @returns {Promise<AxiosResponse<Imagem[]>>} Uma Promise com a lista de imagens
 */
async function findAllImagens() {
  return axios.get(`${URL.API}/atividade-foto`);
}

/**
 * Busca a lista de imagens
 *
 * @returns {Promise<AxiosResponse<Imagem[]>>} Uma Promise com a lista de imagens
 */
async function downloadZip(filtros) {
  return axios({
    method: 'get',
    url: `${URL.API}/atividade-foto/zip?filters=${filtros}`,
    responseType: 'blob'
  });
}

export { findAllImagens, downloadZip };
