export default {
  fertilizantesFoliares: {
    fertilizantesFoliares: 'Fertilizantes foliares',
    cadastroDeFertilizantesFoliares: 'Registro fertilizantes foliares',
    fertilizantesFoliaresRemovidoComSucesso: 'Fertilizante foliar quitado con éxito',
    falhaAoExcluirFertilizantesFoliares: 'Fallo la excluir fertilizante foliar',
    desejaExcluir: '¿Desea eliminar el fertilizante foliar seleccionada?',
    identificacaoDaFertilizantesFoliares: 'Identificación',
    fertilizantesFoliaresadastradoComSucesso: 'Fertilizante foliar registrada con éxito',
    fertilizantesFoliaresEditadoComSucesso: 'Fertilizante foliar editado con éxito',
    falhaAoEditarFertilizantesFoliares: 'Falha ao editar Fertilizante foliar',
    falhaAoCadastrarFertilizantesFoliares: 'Error al editar Fertilizante foliar',
    falhaAoCarregarFertilizantesFoliares: 'Error al cargar Fertilizante foliar'
  }
};
