import UnidadeMedidaPlantioEnum from '../enumerations/UnidadeMedidaPlantioEnum';
import SemeaduraEnum from '../enumerations/SemeaduraEnum';
import SituacaoTalhaoEnum from '../enumerations/SituacaoTalhaoEnum';
import TipoItemTimelineEnum from '../enumerations/TipoItemTimelineEnum';
import * as moment from 'moment';
import TipoAreaEnum from '../enumerations/TipoAreaEnum';
import { getUserLang } from '@utils/localeUtils';
import formatMessage from '../i18n/formatMessage';
import SortPlantios from './SortPlantios';
import TipoItemTimelineBackendEnum from './../enumerations/TipoItemTimelineBackendEnum';

const FORMAT_DATA = 'MM-DD-YYYY';

/**
 *
 * @returns Formata a data de acordo com a linguagem
 */
function formatDataByLinguage() {
  return getUserLang() === 'en-US' ? 'MM-DD-YY' : 'DD/MM/YY';
}

function carregarSazonalidades(item, value) {
  value.type = TipoItemTimelineEnum.SAZONALIDADE;
  value.dateMoment = item.dtItem;
  value.id = 'sazonalidade-' + item.data.idSazonalidade;
  value.data = moment(item.dtItem)
    .utc()
    .format(FORMAT_DATA);
  value.date = moment(item.dtItem)
    .utc()
    .format(formatDataByLinguage());

  value.info.culturaList = item.data.culturaList;
  value.info.dsMes = item.data.dsMes;

  return value;
}

function carregarAuditoriaPlantio(item, value) {
  value.type = TipoItemTimelineEnum.AUDITORIA_PLANTIO;
  value.dateMoment = item.data.dtInicio;
  value.id = 'auditoria-plantio-' + item.data.idAuditoriaPlantio;
  value.idAuditoriaPlantio = item.data.idAuditoriaPlantio;
  value.data = moment(item.data.dtInicio)
    .utc()
    .format(FORMAT_DATA);
  value.date = moment(item.data.dtInicio)
    .utc()
    .format(formatDataByLinguage());

  if (item.plantioDTO) {
    value.cultura = item.plantioDTO.cultura;
    value.cultivar = item.plantioDTO.cultivarEspec;
    value.areaCultivo = item.plantioDTO.areaCultivo.dsAreaCultivo;
  }

  value.info.tpUnidade = '';
  value.info.dsAdicional = item.data.dsSuspensao;
  value.info.nmTecnicoResponsavel = '';

  value.info.plantioTalhaoList = [];
  value.info.atividadeTalhaoList = [];

  return value;
}

function carregarRegistros(doc, value, type) {
  value.type = type;
  value.dateMoment = doc.data.dtAtividade;
  value.id = 'produtoproibido-' + doc.data.idAtividade;
  value.data = moment(doc.data.dtAtividade)
    .utc()
    .format(FORMAT_DATA);
  value.date = moment(doc.data.dtAtividade)
    .utc()
    .format(formatDataByLinguage());

  validaInformacoesWithPlantio(doc, value);

  value.info.tpUnidade = TipoAreaEnum[doc.data.tpUnidade].name;
  value.info.plantioTalhaoList = [];
  value.info.nmTecnicoResponsavel = doc.data.tecnico.nmUsuario;

  if (type === TipoItemTimelineEnum.APLICACAO_PRODUTO_PROIBIDO) {
    value.areaCultivo = doc.data.areaCultivo.dsAreaCultivo;

    var contProibido = 1;
    value.info.typeInsumo = false;

    value.info.atividadeInsumoList = value.info.atividadeInsumoList.filter(
      item => item.insumo.insumoTipoCertificacaoList.length === 0
    );

    value.info.atividadeInsumoList.forEach(doc => {
      doc.cont = contProibido;
      contProibido = contProibido + 1;
      if (doc.insumo.tpInsumo === 14 || doc.insumo.tpInsumo === 15) {
        value.info.typeInsumo = true;
      }
    });
  } else if (doc.plantioDTO) {
    value.areaCultivo = doc.plantioDTO.areaCultivo.dsAreaCultivo;
  }

  doc.data.atividadeTalhaoList.forEach(atividadeTalhao => {
    value.info.plantioTalhaoList.push({
      talhao: atividadeTalhao.talhao
    });
  });

  return value;
}

function carregarAplicacoes(doc, value) {
  validaInformacoesWithPlantio(doc, value);

  value.type = doc.tpItem === 2 ? 1 : 0;
  if (doc.tpItem === 2) {
    value.type = TipoItemTimelineEnum.APLICACAO;
  } else if (doc.tpItem === 3) {
    value.type = TipoItemTimelineEnum.RECOMENDACAO;
  } else if (doc.tpItem === 4) {
    value.type = TipoItemTimelineEnum.ALERTA_FITOSSANITARIO;
  }
  value.dateMoment = doc.data.dtAtividade;
  value.id = 'atividade-' + doc.data.idAtividade;
  value.data = moment(doc.data.dtAtividade)
    .utc()
    .format(FORMAT_DATA);
  value.date = moment(doc.data.dtAtividade)
    .utc()
    .format(formatDataByLinguage());
  value.info.tpUnidade = TipoAreaEnum[doc.data.tpUnidade].name;
  var cont = 1;
  value.info.typeInsumo = false;
  value.info.atividadeInsumoList.forEach(doc => {
    doc.cont = cont;
    cont = cont + 1;
    if (doc.insumo.tpInsumo === 14 || doc.insumo.tpInsumo === 15) {
      value.info.typeInsumo = true;
    }
  });

  value.info.plantioTalhaoList = [];
  doc.data.atividadeTalhaoList.forEach(tal => {
    value.info.plantioTalhaoList.push({
      talhao: tal.talhao
    });
  });

  return value;
}

function carregarMonitoramento(doc, value) {
  validaInformacoesWithPlantio(doc, value);

  value.type = TipoItemTimelineEnum.MONITORAMENTO;
  value.id = doc.data.idAtividade;
  value.dateMoment = doc.data.dtAtividade;
  value.id = 'atividade-' + doc.data.idAtividade;
  value.data = moment(doc.data.dtAtividade)
    .utc()
    .format(FORMAT_DATA);
  value.date = moment(doc.data.dtAtividade)
    .utc()
    .format(formatDataByLinguage());
  value.info.tpUnidade = TipoAreaEnum[doc.data.tpUnidade].name;

  value.info.qtAmostras = value.info.atividadeAmostraList.length;

  var talhoes = [];
  var severidades = [];
  value.info.atividadeAmostraList.forEach(doc => {
    var talhao = [];
    doc.atividadeAmostraQuantidadeList.forEach(amostras => {
      var amostra = [];
      amostra = severidades.find(doc => {
        return doc.idSeveridade === amostras.severidade.idSeveridade;
      });

      if (amostra === undefined) {
        severidades.push(amostras.severidade);
      }
    });

    talhao = talhoes.find(tal => {
      return tal.idTalhao === doc.talhao.idTalhao;
    });

    if (talhao === undefined) {
      if (doc.talhao.stTalhao === undefined) {
        doc.talhao.stTalhao = doc.stTalhao;
        doc.talhao.nmStTalhao = SituacaoTalhaoEnum[doc.stTalhao];
      }
      talhoes.push(doc.talhao);
    } else {
      talhoes.forEach(item => {
        if (item.stTalhao < doc.stTalhao) {
          item.stTalhao = doc.stTalhao;
          item.nmStTalhao = SituacaoTalhaoEnum[doc.stTalhao];
        }
      });
    }
  });

  value.info.plantioTalhaoList = [];
  talhoes.forEach(tal => {
    value.info.plantioTalhaoList.push({
      talhao: tal
    });
  });
  value.info.talhoesUtilizados = talhoes;
  value.info.severidades = severidades;

  return value;
}

function validaInformacoesWithPlantio(doc, value) {
  value.areaCultivo = doc.data.areaCultivo.dsAreaCultivo;

  if (doc.plantioDTO) {
    value.cultura = doc.plantioDTO.cultura;
    value.cultivar = doc.plantioDTO.cultivarEspec;
  } else {
    value.cultura = {};
    value.cultivar = {};
  }
}

function carregarPlantio(acompanhamento, value, produtor) {
  const itensUniMedida = TipoAreaEnum;
  const plantio = acompanhamento.plantioDTO;

  if (value.info.nrDiasColheita !== undefined) {
    let datePrevista = new Date(value.info.dtPlantio);
    datePrevista.setDate(datePrevista.getDate() + value.info.nrDiasColheita);
    value.info.dtPrevista = moment(datePrevista).format(formatDataByLinguage());
  }
  value.info.nmUnMedidaEstimada = UnidadeMedidaPlantioEnum.find(medidade => {
    return medidade.id === value.info.tpUnidadeEstimada;
  });
  value.info.nmAreaEstimada = itensUniMedida.find(medidade => {
    return medidade.id === value.info.tpAreaEstimada;
  });
  value.info.nmUnMedidaStandFinal = itensUniMedida.find(medidade => {
    return medidade.id === value.info.tpUnMedidaStandFinal;
  });
  value.info.nmSemeadura = SemeaduraEnum.find(semeadura => {
    return semeadura.id === value.info.tpSemeadura;
  });
  value.info.nmAreaSemeadura = itensUniMedida.find(medida => {
    return medida.id === value.info.tpAreaSemeadura;
  });
  value.type = TipoItemTimelineEnum.PLANTIO;
  value.areaCultivo = plantio.areaCultivo.dsAreaCultivo;
  value.info.tecnico = produtor.tecnico;
  value.dateMoment = moment(plantio.dtPlantio);
  value.id = 'plantio-' + plantio.idPlantio;
  value.data = moment(plantio.dtPlantio).format(formatDataByLinguage());
  value.date = moment(plantio.dtPlantio).format(formatDataByLinguage());

  value.cultura = plantio.cultura;
  value.cultivar = plantio.cultivarEspec;

  return value;
}

function carregarColheita(doc, value, produtor) {
  const itensUniMedida = TipoAreaEnum;

  value.info.nmUnMedidaEstimada = UnidadeMedidaPlantioEnum.find(medida => {
    return medida.id === value.info.tpUnidadeEstimada;
  });
  value.info.nmAreaEstimada = itensUniMedida.find(medida => {
    return medida.id === value.info.tpAreaEstimada;
  });
  value.info.nmUnMedidaStandFinal = {};
  value.info.tecnico = produtor.tecnico;
  value.areaCultivo = doc.data.areaCultivo.dsAreaCultivo;
  value.type = TipoItemTimelineEnum.COLHEITA;
  value.info.nmUnidade = UnidadeMedidaPlantioEnum.find(medida => {
    return medida.id === value.info.tpUnidade;
  });
  value.info.nmArea = itensUniMedida.find(medida => {
    return medida.id === value.info.tpArea;
  });
  value.info.nmSemeadura = SemeaduraEnum.find(medida => {
    return medida.id === value.info.tpSemeadura;
  });
  value.info.nmAreaSemeadura = itensUniMedida.find(medida => {
    return medida.id === value.info.tpAreaSemeadura;
  });
  value.dateMoment = moment(doc.data.dtColheita);
  value.id = 'colheita-' + doc.data.idPlantio;
  value.data = moment(doc.data.dtColheita);
  value.date = moment(doc.data.dtColheita).format(formatDataByLinguage());
  value.cultura = doc.data.cultura;
  value.cultivar = doc.data.cultivarEspec;

  return value;
}

/**
 * Monta as strings correspondentes no front
 * @param {*} acompanhamentoToEdit - acompanhamento correspondente
 */
function montaStringsToScreen(acompanhamentoToEdit) {
  acompanhamentoToEdit.plantioDTO.nmCultura = acompanhamentoToEdit.plantioDTO.cultura.nmCultura;
  const dtPlantio = moment(acompanhamentoToEdit.plantioDTO.dtPlantio).format('DD/MM/YY');
  const dtColheita = acompanhamentoToEdit.plantioDTO.dtColheita
    ? `${moment(acompanhamentoToEdit.plantioDTO.dtColheita).format('DD/MM/YY')}`
    : '---';
  const cultivarEspec = acompanhamentoToEdit.plantioDTO.cultivarEspec.nmCultivarEspec;
  acompanhamentoToEdit.plantioDTO.dsPlantioCustom = `${acompanhamentoToEdit.plantioDTO.nmCultura} - ${cultivarEspec}`;
  acompanhamentoToEdit.plantioDTO.dsPlantioSelected = `${
    acompanhamentoToEdit.plantioDTO.nmCultura
  } - ${cultivarEspec} - ${formatMessage('acompanhamentoProdutor.plantio')}: ${dtPlantio}`;
  acompanhamentoToEdit.plantioDTO.dtPlantioFormat = `${formatMessage(
    'acompanhamentoProdutor.plantio'
  )}: ${dtPlantio}`;
  acompanhamentoToEdit.plantioDTO.dsSubLineCustom = `${formatMessage(
    'acompanhamentoProdutor.colheita'
  )}: ${dtColheita}`;
}

/**
 * Monta o label de plantio para ser apresentado no auto complete
 */
function trataDadosLabelPlantio(plantioList, valueAreaCultivo) {
  let plantioAutoComplete = [];

  plantioList.forEach(doc => {
    doc.nmCultura = doc.cultura.nmCultura;
    const dtPlantio = moment(doc.dtPlantio).format('DD/MM/YY');
    const dtColheita = doc.dtColheita ? `${moment(doc.dtColheita).format('DD/MM/YY')}` : '---';
    const cultivarEspec = doc.cultivarEspec.nmCultivarEspec;
    doc.dsPlantioCustom = `${doc.nmCultura} - ${cultivarEspec}`;
    doc.dsPlantioSelected = `${doc.nmCultura} - ${cultivarEspec} - ${formatMessage(
      'acompanhamentoProdutor.plantio'
    )}: ${dtPlantio}`;
    doc.dtPlantioFormat = `${formatMessage('acompanhamentoProdutor.plantio')}: ${dtPlantio}`;
    doc.dsSubLineCustom = `${formatMessage('acompanhamentoProdutor.colheita')}: ${dtColheita}`;
    const plantioTalhaoList = doc.plantioTalhaoList.map(plantioTalhao => {
      return plantioTalhao.talhao.dsTalhao;
    });

    doc.titleSubLine = plantioTalhaoList.toString();

    if (doc.areaCultivo.idAreaCultivo === valueAreaCultivo.idAreaCultivo) {
      plantioAutoComplete.push(doc);
    }
  });

  return SortPlantios(plantioAutoComplete);
}

function findItemByTimeline(acompanhamento, value, produtor) {
  return [
    {
      id: TipoItemTimelineBackendEnum.SAZONALIDADE,
      functionToLoad: () => carregarSazonalidades(acompanhamento, value)
    },
    {
      id: TipoItemTimelineBackendEnum.AUDITORIA_PLANTIO,
      functionToLoad: () => carregarAuditoriaPlantio(acompanhamento, value)
    },
    {
      id: TipoItemTimelineBackendEnum.APLICACAO_PRODUTO_PROIBIDO,
      functionToLoad: () =>
        carregarRegistros(acompanhamento, value, TipoItemTimelineEnum.APLICACAO_PRODUTO_PROIBIDO)
    },
    {
      id: TipoItemTimelineBackendEnum.REGISTRO_CLIMATICO,
      functionToLoad: () =>
        carregarRegistros(acompanhamento, value, TipoItemTimelineEnum.REGISTRO_CLIMATICO)
    },
    {
      id: TipoItemTimelineBackendEnum.REGISTRO_FOTOGRAFICO,
      functionToLoad: () =>
        carregarRegistros(acompanhamento, value, TipoItemTimelineEnum.REGISTRO_FOTOGRAFICO)
    },
    {
      id: TipoItemTimelineBackendEnum.MONITORAMENTO,
      functionToLoad: () => carregarMonitoramento(acompanhamento, value)
    },
    {
      id: TipoItemTimelineBackendEnum.PLANTIO,
      functionToLoad: () => carregarPlantio(acompanhamento, value, produtor)
    },
    {
      id: TipoItemTimelineBackendEnum.COLHEITA,
      functionToLoad: () => carregarColheita(acompanhamento, value, produtor)
    },
    {
      id: TipoItemTimelineBackendEnum.APLICACAO,
      functionToLoad: () => carregarAplicacoes(acompanhamento, value)
    },
    {
      id: TipoItemTimelineBackendEnum.RECOMENDACAO,
      functionToLoad: () => carregarAplicacoes(acompanhamento, value)
    },
    {
      id: TipoItemTimelineBackendEnum.ALERTA_FITOSSANITARIO,
      functionToLoad: () => carregarAplicacoes(acompanhamento, value)
    }
  ];
}
export { trataDadosLabelPlantio, montaStringsToScreen, formatDataByLinguage, findItemByTimeline };
