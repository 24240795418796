import React, { Component } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getUserLang } from '@utils/localeUtils';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import Localizacao from '../../../../resources/Localizacao';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import { style, theme } from './../Clientes.styles';
import formatMessage from '../i18n/formatMessage';

class EditarEnderecos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      municipios: [],
      estados: [],
      keyMunicipio: '',
      keyEstado: '',
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      listEnderecos: [],
      statusEdit: false
    };

    this.valueAutoCompleteEstado = '';
    this.valueAutoCompleteMunicipio = '';

    this.handleChange = this.handleChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.tratarValorSelectInitialUF = this.tratarValorSelectInitialUF.bind(this);
    this.tratarValorInicialMunicipio = this.tratarValorInicialMunicipio.bind(this);
    this.validarInicializacaoAutoCompleteUF = _.debounce(
      this.validarInicializacaoAutoCompleteUF.bind(this),
      200
    );
    this.validarInicializacaoAutoCompleteMunicipio = _.debounce(
      this.validarInicializacaoAutoCompleteMunicipio.bind(this),
      200
    );
  }

  componentDidMount() {
    this.validarInicializacaoAutoCompleteUF();

    if (this.props.selectEnderecos.tpEndereco !== undefined) {
      this.valueAutoCompleteEstado = this.props.selectEnderecos.uf;
      this.valueAutoCompleteMunicipio = this.props.selectEnderecos.municipio;

      this.setState({
        itemSelect: this.props.selectEnderecos,
        statusEdit: true,
        keyEstado: Math.random(),
        keyMunicipio: Math.random()
      });
    } else {
      this.setState({
        itemSelect: {},
        statusEdit: false
      });
    }
  }

  onSave(values) {
    values.uf = this.valueAutoCompleteEstado;
    values.municipio = this.valueAutoCompleteMunicipio;
    values.nrEndereco = values.dsNumero;
    values.nmBairro = values.dsBairro;

    if (this.state.statusEdit) {
      const position = this.props.data.indexOf(this.state.itemSelect);
      this.setState({
        openModal: false,
        itemSelect: '',
        disabledEdit: true,
        statusEdit: false
      });

      this.props.onClickEndereco(values, position);
    } else {
      this.setState({
        openModal: false,
        listEnderecos: this.state.listEnderecos.concat(values)
      });

      this.props.onClickEndereco(values);
    }
  }

  onClose() {
    this.props.onClickCancelar(0);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  tratarValorSelectInitialUF(data) {
    if (!data || data.nmUf === undefined) {
      return '';
    }

    return data;
  }

  tratarValorInicialMunicipio(data) {
    if (!data || data.nmMunicipio === undefined) {
      return '';
    }

    return data;
  }

  validarInicializacaoAutoCompleteMunicipio() {
    Localizacao.findMunicipiosByUf(this.valueAutoCompleteEstado.idUf).then(result => {
      this.setState({
        municipios: result.data
      });
    });
  }

  validarInicializacaoAutoCompleteUF() {
    Localizacao.findAllEstados(this.props.infoUsuario.idCliente).then(result => {
      this.setState({
        estados: result.data
      });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={
            this.state.statusEdit
              ? {
                  tpEndereco: this.state.itemSelect.tpEndereco,
                  nrCep: this.state.itemSelect.nrCep,
                  uf: this.state.itemSelect.uf,
                  municipio: this.state.itemSelect.municipio,
                  dsRua: this.state.itemSelect.dsRua,
                  dsNumero: this.state.itemSelect.nrEndereco,
                  dsComplemento: this.state.itemSelect.dsComplemento || '',
                  dsBairro: this.state.itemSelect.nmBairro || ''
                }
              : {
                  tpEndereco: '',
                  nrCep: '',
                  uf: '',
                  municipio: '',
                  dsRua: '',
                  dsNumero: '',
                  dsComplemento: '',
                  dsBairro: ''
                }
          }
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={this.onSave}
          validationSchema={Yup.object().shape({
            tpEndereco: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            nrCep:
              getUserLang() === 'pt-BR'
                ? Yup.string()
                    .min(9, formatMessage('clientes.cepInvalido'))
                    .required(formatMessage('clientes.campoObrigatorio'))
                : Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            uf: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            municipio: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            dsRua: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            dsNumero: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            dsBairro: Yup.string(),
            dsComplemento: Yup.string()
          })}
          render={({ values, handleSubmit, errors, touched, handleChange, setFieldValue }) => (
            <CardCadastro
              marginLeft={30}
              titulo={formatMessage('clientes.endereco')}
              botoes={[
                <Button variant='contained' color='inherit' onClick={this.onClose}>
                  {formatMessage('clientes.cancelar')}
                </Button>,
                <Button color='secondary' variant='contained' onClick={handleSubmit}>
                  {formatMessage('clientes.confirmar')}
                </Button>
              ]}
            >
              <div style={{ padding: '15px 15px 30px 15px' }}>
                <form autoComplete='off'>
                  <Grid className={classes.paddingGrid} container spacing={16}>
                    <Grid item xs={3}>
                      <FormControl
                        className={classes.form}
                        error={errors.tpEndereco && touched.tpEndereco}
                      >
                        <InputLabel
                          htmlFor='tpEndereco-helper'
                          error={errors.tpEndereco && touched.tpEndereco}
                          className={classes.font}
                        >
                          {formatMessage('clientes.identificacao')}
                        </InputLabel>
                        <Select
                          style={{
                            marginLeft: 0,
                            fontSize: 14,
                            width: '100%',
                            marginTop: 15
                          }}
                          SelectDisplayProps={{
                            style: {
                              width: '100%',
                              backgroundColor: 'transparent'
                            }
                          }}
                          value={values.tpEndereco}
                          onChange={handleChange}
                          error={errors.tpEndereco && touched.tpEndereco}
                          input={<Input id='tpEndereco-error' />}
                          inputProps={{
                            className: classes.font,
                            name: 'tpEndereco',
                            id: 'tpEndereco-helper'
                          }}
                        >
                          {this.props.itensIdentificacao.map(tpEndereco => (
                            <MenuItem
                              value={tpEndereco.id}
                              key={this.props.itensIdentificacao.indexOf(tpEndereco)}
                              className={classes.font}
                            >
                              {tpEndereco.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.tpEndereco && touched.tpEndereco && (
                          <FormHelperText> {errors.tpEndereco} </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <Field name='nrCep'>
                        {({ field, form }) => (
                          <TextFieldWithMask
                            {...field}
                            tipoMascara={4}
                            id='nrCep'
                            name='nrCep'
                            onBlur={form.handleBlur}
                            onChange={event => {
                              event.target.name = 'nrCep';
                              form.handleChange(event);
                            }}
                            value={values.nrCep}
                            label={formatMessage('clientes.cep')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.nrCep && form.touched.nrCep}
                            helperText={form.touched.nrCep ? form.errors.nrCep : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        itens={this.state.estados}
                        campoOp={'nmUf'}
                        campoChave={'idUf'}
                        label={formatMessage('clientes.estado')}
                        name='uf'
                        error={
                          (this.valueAutoCompleteEstado === '' ||
                            this.valueAutoCompleteEstado === null) &&
                          touched.uf
                        }
                        helperText={
                          touched.uf &&
                          (this.valueAutoCompleteEstado === '' || this.valueAutoCompleteEstado === null)
                            ? formatMessage('clientes.campoObrigatorio')
                            : null
                        }
                        onChangeOwn={event => {
                          setFieldValue('uf', event);
                          setFieldValue('municipio', '');
                          this.valueAutoCompleteEstado = event;
                          this.valueAutoCompleteMunicipio = '';

                          this.setState({
                            keyEstado: Math.random(),
                            keyMunicipio: Math.random()
                          });

                          if (event) {
                            this.validarInicializacaoAutoCompleteMunicipio();
                          }
                        }}
                        onChangeAutoComplete={this.validarInicializacaoAutoCompleteUF}
                        value={this.tratarValorSelectInitialUF(this.valueAutoCompleteEstado)}
                        key={this.state.keyEstado}
                        valueAutoComplete={this.tratarValorSelectInitialUF(this.valueAutoCompleteEstado)}
                        valueSelect={this.tratarValorSelectInitialUF(this.valueAutoCompleteEstado).nmUf}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        disabled={
                          this.valueAutoCompleteEstado === '' || this.valueAutoCompleteEstado === null
                        }
                        itens={this.state.municipios}
                        campoOp={'nmMunicipio'}
                        campoChave={'idMunicipio'}
                        label={formatMessage('clientes.cidade')}
                        name='municipio'
                        error={this.valueAutoCompleteMunicipio === '' && touched.municipio}
                        helperText={
                          touched.municipio && this.valueAutoCompleteMunicipio === ''
                            ? formatMessage('clientes.campoObrigatorio')
                            : null
                        }
                        onChangeOwn={event => {
                          this.setState({
                            keyMunicipio: Math.random()
                          });
                          setFieldValue('municipio', event);
                          this.valueAutoCompleteMunicipio = event;
                        }}
                        onChangeAutoComplete={this.validarInicializacaoAutoCompleteMunicipio}
                        value={this.tratarValorInicialMunicipio(this.valueAutoCompleteMunicipio)}
                        key={this.state.keyMunicipio}
                        valueAutoComplete={this.tratarValorInicialMunicipio(
                          this.valueAutoCompleteMunicipio
                        )}
                        valueSelect={
                          this.tratarValorInicialMunicipio(this.valueAutoCompleteMunicipio).nmMunicipio
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid className={classes.paddingGrid} container spacing={16}>
                    <Grid item xs={4}>
                      <Field name='dsRua'>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            name='dsRua'
                            id='dsRua'
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={values.dsRua}
                            label={formatMessage('clientes.rua')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.dsRua && form.touched.dsRua}
                            helperText={form.touched.dsRua ? form.errors.dsRua : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={1}>
                      <Field name='dsNumero'>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            name='dsNumero'
                            id='dsNumero'
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={values.dsNumero}
                            label={formatMessage('clientes.numero')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.dsNumero && form.touched.dsNumero}
                            helperText={form.touched.dsNumero ? form.errors.dsNumero : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name='dsBairro'>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            name='dsBairro'
                            id='dsBairro'
                            value={values.dsBairro}
                            label={formatMessage('clientes.bairro')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.dsBairro && form.touched.dsBairro}
                            helperText={form.touched.dsBairro ? form.errors.dsBairro : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name='dsComplemento'>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            name='dsComplemento'
                            id='dsComplemento'
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={values.dsComplemento}
                            label={formatMessage('clientes.complemento')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.dsComplemento && form.touched.dsComplemento}
                            helperText={form.touched.dsComplemento ? form.errors.dsComplemento : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </CardCadastro>
          )}
        />
      </MuiThemeProvider>
    );
  }
}

EditarEnderecos.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarEnderecos);
export default withRouter(connect(mapStateToProps)(enhaced));
