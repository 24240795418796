//@Autor Katia Miglioli
// Use como o componente Select do material-ui, com excessao que os
// todas as propriedades sao passadas entre chaves {}
import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Select from '@material-ui/core/Select';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '1px solid green'
        }
      }
    }
  }
});

export default class SelectOwn extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Select {...this.props}>{this.props.children}</Select>
      </MuiThemeProvider>
    );
  }
}
