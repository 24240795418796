export default {
  galeria: {
    galeria: 'Gallery',
    produtor: 'Producer',
    cidade: 'City',
    regiao: 'Region',
    comunidade: 'Community',
    resumo: 'Summary',
    cultura: 'Culture',
    cultivar: 'Grow crops',
    areaCultivo: 'Cultivation area',
    descricao: 'Description',
    talhoesUtilizados: 'Fields used',
    data: 'Date',
    safra: 'Harvest',
    baixando: 'Downloading',
    erroDownloadArquivos: 'Error downloading files',
    tecnico: 'Technician',
    erroBuscarGaleria: 'Error fetching photos from gallery',
    baixarImagens: 'Download images'
  }
};
