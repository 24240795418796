export default {
  nematicidas: {
    nematicidasRemovidoComSucesso: 'Nematicide successfully removed',
    falhaAoExcluirNematicidas: 'Failed to exclude nematicide',
    desejaExcluir: 'Do you want to delete the selected nematicide?',
    nematicidas: 'Nematicides',
    cadastroDeNematicidas: 'Nematicides record',
    identificacaoDaNematicidas: 'Nematicides identification',
    nematicidasCadastradoComSucesso: 'Nematicide successfully registered',
    nematicidasEditadoComSucesso: 'Nematicide edited successfully',
    falhaAoEditarNematicidas: 'Failed to edit nematicide',
    falhaAoCadastrarNematicidas: 'Failed to register nematicide',
    falhaAoCarregarNematicidas: 'Failed to load nematicide'
  }
};
