export default {
  evento: {
    campoObrigatorio: 'Required field',
    agendarVisita: 'Schedule visit',
    excluir: 'Delete',
    cancelar: 'Cancel',
    confirmar: 'Confirm',
    data: 'Date',
    horario: 'Start Time',
    horarioFinal: 'End time',
    horarioInvalido: 'End time must be greater than start time',
    produtor: 'Producer',
    tecnico: 'Technician',
    visitaRealizada: 'Visit carried out',
    observacao: 'Observation',
    resumoVisita: 'Visit summary',
    voltar: 'Back',
    responsavel: 'Responsible',
    gerente: 'Manager'
  }
};
