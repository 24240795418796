export default {
  acompanhamentoProdutor: {
    descricaoNaoConformidade: 'Description of non-compliance',
    cadastroDeProdutor: 'Producer Register',
    produtores: 'Producer',
    identificacao: 'Identification',
    nome: 'Name',
    telefone: 'Phone',
    comunidade: 'Comunity',
    municipio: 'County',
    uf: 'UF',
    email: 'E-mail',
    nivel: 'Technological level',
    tecnicoResponsavel: 'Responsible technician',
    ativo: 'Activo',
    codigo: 'Key',
    cidade: 'Country',
    estado: 'UF',
    descricao: 'Description',
    areaCultivo: 'Total area of cultivation(Ha)',
    areaTotaldeTalhoes: 'Total area of field',
    dataVisita: 'Visit date',
    hora: 'Hour',
    tecnico: 'Technician',
    dataPlantio: 'Planting date',
    culturas: 'Culture',
    dataColheita: 'Harvested at',
    colheita: 'Harvested',
    produtividade: 'Productivity(Bags/Ha)',
    areaDeCultivo: 'Area of cultivation',
    talhoes: 'Field',
    visitas: 'Visits',
    plantios: 'Plantations',
    status: 'Status',
    inativo: 'Inactive',
    salvar: 'Save',
    cancelar: 'Cancel',
    gravar: 'Save',
    campoObrigatorio: 'Required field',
    desejaExcluirAreaCultivo: 'Do you want to delete the selected area of cultivation?',
    desejaExcluirTalhao: 'Do you want to delete de selected field?',
    nao: 'Not',
    excluir: 'Delete',
    excluindo: 'Excluding',
    areaCultivoRemovidoComSucesso: 'Growing area successfully removed',
    talhaoRemovidoComSucesso: 'Field successfully removed',
    alto: 'High',
    medio: 'Medium',
    baixo: 'Low',
    salvando: 'Saving',
    atualizando: 'Updating',
    produtorCadastrodaComSucesso: 'Producer registered successfully',
    produtorEditadoComSucesso: 'Producer edited successfully',
    falhaAoCadastrarProdutor: 'Fail to register producer',
    falhaAoEditarProdutor: 'Failed to edit producer',
    emailJaCadastrado: 'This email is already registered',
    erroAoCarregarDados: 'Error loading data',
    aplicacoes: 'Application',
    dataAplicacao: 'Application date',
    tipo: 'Type',
    marca: 'Brand',
    produto: 'Product',
    talhao: 'Hectares',
    hectares: 'Hectares',
    acaricida: 'Acaricides',
    acaricidaMicrobiologico: 'Microbiological acaricides',
    agenteBiologicoControle: 'Biological agents',
    bactericida: 'Bactericidal',
    bactericidaMicrobiologico: 'Microbiological bactericides',
    herbicida: 'Herbicides',
    fungicida: 'Fungicidas',
    fungicidaMicrobiologico: 'Microbiological fungicides',
    inseticida: 'Inseticidas',
    inseticidaMicrobiologico: 'Microbiological insecticides',
    nematicida: 'Nematicides',
    nematicidaMicrobiologico: 'Microbiological nematicides',
    fertilizanteFoliar: 'Fertilizer foliar',
    talhaoDuplicado: 'Duplicate plot',
    plantioCadastradoComSucesso: 'Planting successfully registered',
    desejaExcluirAplicacao: 'Do you want to delete application?',
    aplicacaoRemovidaComSucesso: 'Application successfully removed',
    naoFoiPossivelEditar: 'Could not edit planting',
    naoFoiPossivelInserir: 'Could not insert planting',
    plantioEditadoComSucesso: 'Planting successfully edited',
    plantioRemovidoComSucesso: 'Plantación registrada con éxito',
    erroAoRemoverPlantio: 'Error removing planting',
    desejaExcluirPlantio: 'Do you want to exclude planting?',
    areaTotalSelecionada: 'Total area selected(Ha)',
    talhoesAdicionados: 'Stubs added',
    selecionarTodos: 'Select all',
    areaPlantio: 'Planting area',
    acompanhamentoNaoCadastrado: 'Planting monitoring not yet registered',
    acompanhamentoPlantio: 'Planting follow-up',
    acompanhamentoProdutor: 'Follow-up to the producer',
    areaTanque: 'Area by tank',
    nrQuantidade: 'Amount',
    produtos: 'Products',
    lancamentoAplicacao: 'Application launch done',
    dsUnMedida: 'Un. of measurement',
    dsRecomendacaoAdicional: 'Additional recommendation',
    ordem: 'Order',
    dose: 'Dose per tank',
    tipoPesticida: 'Product type',
    adicionarProdutos: 'Add to cart',
    areaTotal: 'Total area',
    lancamentoCadastrado: 'Successfully launched release',
    lancamentoAtualizado: 'Successfully released release',
    falhaAoCadastrarLancamento: 'Failed to register posting',
    falhaAoAtualizarLancamento: 'Failed to update posting',
    unMedida: 'Unit of measurement',
    recomendacoesAdicionais: 'Additional recommendations',
    visualizarRecomendacoes: 'View recommendations',
    tpProduto: 'Product type',
    fechar: 'Close',
    aplicarNosTalhoes: 'Apply to the plots',
    talhoesUtilizados: 'Plots used',
    falhaAoCarregarAcompanhamento: 'Failed to load tracking',
    desejaExcluirProduto: 'Do you want to delete product?',
    produtoRemovidoComSucesso: 'Product successfully removed',
    lancamentoRecomendacao: 'Application recommendation release',
    cadastrarAplicacao: 'Apply application',
    cadastrarRecomendacao: 'Apply recommendation',
    acre: 'Acre',
    hectare: 'Hectárea',
    alqueire: 'Bushel',
    kg: 'Kg',
    numeroMaximoProdutores: 'The limit of producers of this plan was reached',
    desejaExcluir: 'Do you want to delete?',
    falhaAoExcluirProdutor: 'Failed to delete producer',
    produtorExcluidoComSucesso: 'Producer deleted successfully',
    telefoneInvalido: 'Invalid phone number',
    cadastroPlantio: 'Plant registration',
    cadastrarAreaCultivo: 'First register cultivation area',
    cadastrarTalhao: 'First register cultivation area and field',
    talhaoPlantioAssociado: 'Field and planting associated with growing area',
    exclusaoAreaDeCultivoNaoPermitida: 'Exclusion of cultivation area is not allowed',
    talhaoAssociado: 'Field associated with growing area',
    plantioAssociado: 'Planting associated with growing area',
    produtor: 'Producer',
    produtorInvalidoTecnico: 'Producer not found',
    planting: 'Planting',
    dataMinima: 'Invalid date',
    oleosAdjuvantes: 'Oils and adjuvants',
    confirmar: 'Confirm',
    salvarLancamentoSair: 'There is unsaved information, would you like to save the posting?',
    descartar: 'To discard',
    sacas: 'Sacks',
    toneladas: 'Tons',
    m2: 'm²',
    sementes: 'Seeds',
    cadastrarAlerta: 'Register phytosanitary alert',
    invasoras: 'Invasive',
    agenteCausal: 'Causal agent',
    data: 'Date',
    doencaSolo: 'Soil disease',
    doencaFoliar: 'Leaf disease',
    nematoide: 'Nematode',
    pragas: 'Pests',
    adicionarAlertas: 'Add phytosanitary alerts',
    alertaRemovidoComSucesso: 'Phytosanitary alert successfully removed',
    desejaExcluirAlerta: 'Do you want to delete phytosanitary alert?',
    alertasFitossanitarios: 'Phytosanitary alerts',
    observacao: 'Note',
    lancamentoAlerta: 'Phytosanitary Alert Release',
    efetivarRecomendacao: 'Do you want to effect the recommendation as performed?',
    sim: 'Yes',
    falhaEfetivarRecomendacao: 'Failed to effect recommendation',
    sucessoEfetivarRecomendacao: 'Success in effecting recommendation',
    categoria: 'Category',
    acaros: 'Mites',
    bacterias: 'Bacteria',
    ervasDaninhas: 'Weeds',
    fungos: 'Fungi',
    insetos: 'Insects',
    nematoides: 'Nematodes',
    foliaresAdjuvantes: 'Leaf and Adjuvant',
    fertilizantes: 'Fertilizers',
    fertilizanteQuimico: 'Chemical fertilizers',
    fertilizanteOrganico: 'Organic fertilizers',
    quantidadeFitossanidade: 'Pest Amount',
    litros: 'Liters',
    cadastrarMonitoramento: 'Pest monitoring',
    lancamentoMonitoramento: 'Monitoring',
    quantidadePragas: 'Plant health Amount',
    numero: 'Number',
    amostras: 'Samples',
    adicionarAmostras: 'Add samples',
    praga: 'Pests',
    pragaDuplicada: 'Pests already registered',
    desejaExcluirPraga: 'Do you want to delete pests?',
    pragaRemovidaComSucesso: 'Pests successfully removed',
    falhaAoExcluirPraga: 'Failed to delete pests',
    desejaExcluirAmostra: 'Do you want to delete sample?',
    amostraRemovidaComSucesso: 'Sample successfully removed',
    falhaAoExcluirAmostra: 'Failed to delete sample',
    fitossanidade: 'Prague',
    monitoramentoAtualizado: 'Monitoring updated successfully',
    monitoramentoCadastrado: 'Tracking successfully registered',
    falhaAoAtualizarMonitoramento: 'Failed to update monitoring',
    falhaAoCadastrarMonitoramento: 'Failed to register monitoring',
    adicionar: 'Add',
    metro: 'meter',
    mediaPorTalhao: 'Average per field',
    nenhum: 'None',
    emAlerta: 'On alert',
    necessitaAplicacao: 'Need application',
    situacaoTalhao: 'Field situation',
    nenhumaAtividadeRegistrada: 'No activity recorded',
    especiesIdentificadas: 'Identified Species',
    cadastrarRegistroFotografico: 'Register photographic record',
    lancamentoRegistroFotografico: 'Launch of photographic record',
    imagens: 'Images',
    cadastrarRegistroClimatico: 'Register climate record',
    descricaoRegistroClimatico: 'Description of the climatic record',
    lancamentoRegistroClimatico: 'Launch of climate record',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    nenhumResultadoEncontrado: 'No results found',
    inoculantesHomeopatia: 'Inoculants and Homeopathy',
    homeopaticos: 'Homeopathic Products',
    inoculantes: 'Inoculants',
    desejaCriarRecomendacao: 'Do you want to create an application recommendation?',
    mudas: 'Seedling'
  }
};
