export default {
  visitas: {
    relatorio: 'Informe',
    relatorioVisitas: 'Informe de visita',
    regiao: 'Región',
    municipio: 'Contacto',
    sim: 'Si',
    nao: 'No',
    carregando: 'Cargando...',
    gerandoRelatorio: 'Generando informe ...',
    falhaAoGerarRelatorio: 'Error al generar informe',
    dataLancamento: 'Fecha de lanzamiento',
    tecnicoResponsavel: 'Técnico responsable',
    produtor: 'Productor',
    uf: 'UF',
    decrescente: 'Disminuyendo',
    crescente: 'Creciente',
    media: 'Promedio',
    minimoFiltro: 'Aplicar al menos un filtro',
    mensagemSemFiltro: 'Sin filtros, la generación del informe puede tomar, ¿continuar de todos modos?',
    estado: 'Estado',
    comunidade: 'Comunidad',
    safra: 'Clásico',
    cultura: 'Cultura',
    periodo: 'Período',
    baixarCSV: 'Descargar CSV',
    tecnicos: 'Técnico'
  }
};
