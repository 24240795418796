export default {
  mapandvi: {
    filtro: 'Filter by Producer',
    campoObrigatorio: 'Required field',
    produtor: 'Producer',
    talhao: 'Field',
    area: 'Area',
    produtorDuplicado: 'Duplicate Producer',
    talhaoDuplicado: 'Duplicate field',
    mapa: 'Maps',
    mapandvi: 'Maps NDVI',
    falhaBuscarImagens: 'Error searching for images',
    dia: 'Date',
    imagem: 'Image',
    formatoData: 'MM/DD/YYYY',
    talhoesSemImagemProdutor: 'The plots of this producer do not have images.',
    talhaoSemImagem: 'This plot has no image.'
  }
};
