export default {
  cultivares: {
    cultivares: 'Crop',
    detentor: 'Poseedor',
    cadastroCultivar: 'Grow crops register',
    codigo: 'Key',
    descricao: 'Description',
    cultura: 'Description',
    situacao: 'Situation',
    desejaExcluirCultivar: 'Do you want to delete the selected crop?',
    cultivarRemovidoComSucesso: 'Cultivar removed successfully',
    nao: 'Not',
    excluir: 'Delete',
    excluindo: 'Excluding',
    falhaAoExcluirCultivar: 'Fail to exclude grow crops',
    ativo: 'Active',
    inativo: 'Active',
    campoObrigatorio: 'Required field',
    identificacaoDetentor: 'Identification',
    gravar: 'Save',
    cancelar: 'Cancel',
    cultivar: 'Grow crops',
    atualizando: 'Updating',
    salvando: 'Saving',
    cultivarCadastradoComSucesso: 'Grow crops successfully registered',
    cultivarEditadoComSucesso: 'Grow crops edited successfully',
    erro: 'Error',
    salvar: 'Save',
    falhaAoCadastrarCultivar: 'Failed to register grow crops',
    falhaAoEditarCultivar: 'Failed to edit grow crops',
    confirmar: 'Confirm',
    salvarCultivarSair: 'There is unsaved information, do you want to save the grow crops?',
    descartar: 'To discard'
  }
};
