export default {
  plantios: {
    relatorio: 'Informe',
    plantios: '',
    regiao: 'Región',
    municipio: 'Contacto',
    carregando: 'Cargando...',
    gerandoRelatorio: 'Generando informe ...',
    falhaAoGerarRelatorio: 'Error al generar informe',
    ecosocial: 'Ecosocial',
    tecnicoResponsavel: 'Técnico responsable',
    naoPossui: 'No tiene',
    produtor: 'Productor',
    sim: 'Si',
    nao: 'No',
    statusCertificacaoOrganica: 'Estado de certificación orgánica',
    convencional: 'Convencional',
    emConversao: 'En conversión',
    brasil: 'BR (Brazil)',
    comunidadeEuropeia: 'CE (Comunidad Europea)',
    eua: 'NOP (Estados Unidos)',
    cultura: 'Cultura',
    comunidade: 'Comunidad',
    uf: 'Estado',
    safra: 'Cosecha',
    tecnico: 'Técnico',
    exportarPlanilha: 'Exportar a hoja de cálculo'
  }
};
