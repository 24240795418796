export default {
  agentesControle: {
    agentesControleRemovidoComSucesso: 'Agente biológico removido com sucesso',
    falhaAoExcluirAgentesControle: 'Falha ao excluir agente biológico',
    desejaExcluir: 'Deseja excluir o agente biológico selecionado?',
    agentesControle: 'Agentes biológicos',
    cadastroDaAgentesControle: 'Cadastro de agentes biológicos',
    identificacaoDaAgentesControle: 'Identificação',
    agentesControleCadastradoComSucesso: 'Agente biológico cadastrado com sucesso',
    agentesControleEditadoComSucesso: 'Agente biológico editado com sucesso',
    falhaAoEditarAgentesControle: 'Falha ao editar agente biológico',
    falhaAoCadastrarAgentesControle: 'Falha ao cadastrar agente biológico',
    falhaAoCarregarAgentesControle: 'Falha ao carregar agentes biológicos'
  }
};
