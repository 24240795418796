export default {
  inoculantes: {
    inoculantesRemovidoComSucesso: 'Inoculante removido com sucesso',
    falhaAoExcluirInoculantes: 'Falha ao excluir inoculante',
    desejaExcluir: 'Deseja excluir o inoculante selecionado?',
    inoculantes: 'Inoculantes',
    cadastroDeInoculantes: 'Cadastro de inoculante',
    identificacaoDaInoculantes: 'Identificação',
    inoculantesCadastradoComSucesso: 'Inoculante cadastrado com sucesso',
    inoculantesEditadoComSucesso: 'Inoculante editado com sucesso',
    falhaAoEditarInoculantes: 'Falha ao editar inoculante',
    falhaAoCadastrarInoculantes: 'Falha ao cadastrar inoculante',
    falhaAoCarregarInoculantes: 'Falha ao carregar inoculantes'
  }
};
