import { createMuiTheme } from '@material-ui/core/styles';

const style = themeStyle => ({
  textAtividade: {
    height: 30,
    fontSize: 15,
    color: 'rgb(0, 0, 0, 0.8)'
  },
  fab: {
    position: 'fixed',
    right: themeStyle.spacing.unit * 3.7,
    justifyContent: 'flex-end',
    zIndex: 122,
    marginBottom: 0,
    bottom: 364
  },
  card: {
    width: '100%',
    height: '100%',
    marginBottom: 15,
    justifyContent: 'center',
    display: 'flex',
    position: 'relative'
  },
  chip: {
    backgroundColor: '#E1E1E1',
    border: 'none',
    borderRadius: 10,
    fontSize: 12,
    color: '#676767',
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5
  },
  listaProdutos: {
    width: '220px',
    overflow: 'hidden',
    paddingLeft: 20,
    fontSize: 14,
    color: '#707070',
    marginTop: '5px'
  },
  listaAlertas: {
    width: 'auto',
    overflow: 'hidden',
    fontSize: 14,
    color: '#707070',
    marginTop: '5px'
  },
  font: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    width: '100%',
    minHeight: '0 !important'
  },
  form: {
    width: '100%',
    margin: 0,
    height: '75px'
  },
  textField: {
    width: '100%',
    height: '70px'
  },
  button: {
    height: 24,
    width: 24,
    marginTop: 10
  },
  grid: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between'
  },
  gridWithScroll: {
    height: 'calc( 100vh - 110px )',
    overflowY: 'scroll'
  },
  cardCadastroAlerta: {
    width: '100%',
    height: 290,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  cardAlertas: {
    paddingTop: 5,
    paddingBottom: '5px',
    borderBottom: '1px solid rgb(0, 0, 0, 0.2)'
  },
  cardMonitoramento: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: '1px solid rgb(0, 0, 0, 0.2)'
  },
  fontLabelCardsCronograma: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  selectFitossanidade: {
    marginLeft: 0,
    fontSize: 14,
    width: '100%'
  },
  fontUniMedida: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    width: '100%'
  },
  talhoesTab: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%'
  },
  swipeableViews: {
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  tabSwiapeable: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  gridSelect: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 7
  },
  containerAll: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  cardIdentificacao: {
    height: 160,
    width: '100%'
  },
  selectUnidadeMedida: {
    marginLeft: 0,
    fontSize: 14,
    width: '100%',
    position: 'relative',
    bottom: '1px'
  },
  gridTableProdutos: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: -15
  },
  gridTableTalhoes: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: 15
  },
  cardUploadFile: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  cardEditarAcompanhamento: {
    width: '100%',
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  cardTalhoes: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  cardEditarMonitoramento: {
    width: '100%',
    height: '100%'
  },
  allCardSwipeableMonitoramento: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginBottom: 15
  },
  cardDataTableMonitoramento: {
    paddingLeft: 1,
    paddingRight: 1,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 180px)'
  },
  cardAllMonitoramento: {
    width: '100%',
    height: '100%',
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  cardCadastroMonitoramento: {
    height: '100%',
    display: 'online',
    flexDirection: 'column',
    marginBottom: 15
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiSpeedDial: {
      actions: {
        marginBottom: 0,
        paddingBottom: 0
      }
    },
    MuiTypography: {
      body2: {
        color: 'rgba(0, 0, 0, 0.54)'
      }
    },
    MuiModal: {
      root: {
        width: '100%',
        height: '100%',
        position: 'absolute'
      }
    },
    MuiCardContent: {
      root: {
        paddingTop: 0,
        paddingBottom: '0 !important'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0
      }
    },
    MuiGrid: {
      'grid-xs-8': {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export { style, theme };
