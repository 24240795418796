import React, { useState, useEffect, useCallback } from 'react';
import CardFilters from '@components/CardFilters/CardFilters';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatMessage from './i18n/formatMessage';
import { findAllAgencia, deleteById } from '@resources/Agencia';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import Loading from '@components/Loading/Loading';
import swal from '@sweetalert/with-react';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconAdd from '@images/icone_add.png';
import FilterUtil from '../filterUtil';
import { style, theme } from './Agencia.styles';
import { headDatatable, filtersConfig } from './header';

/**
 * Tela de listagem de agencias
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function Agencia(props) {
  const { classes } = props;

  const [dataInTable, setDataInTable] = useState([]);
  const [dataAppSync, setDataAppSync] = useState([]);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [itemSelect, setItemSelect] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const paginacao = { length: dataInTable ? dataInTable.length : 0, page: 0 };

  const onFilter = useCallback((dataChips = []) => {
    setLoading(true);
    findAllAgencia()
      .then(res => {
        setDataInTable(FilterUtil.applyFilters(res.data, dataChips));
        setDataAppSync(res.data);
      })
      .catch(err => {
        setDataInTable([]);
        setDataAppSync([]);
        swal(formatMessage('agencia.erroAoBuscarDados'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(
    () => {
      const title = 'agencia.agencia';
      if (props.itensState.name !== title) {
        props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
      }

      onFilter();
    },
    [onFilter]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.card}>
        {isLoading && <Loading />}

        <CardFilters
          filtros={filtersConfig}
          usePaddings={false}
          onFilter={onFilter}
          onFilterChanged={onFilterChanged}
          viewKey='agencia'
        />

        <GroupButtonGrid
          withoutMargin
          showAdd={false}
          showEdit
          showDelete
          disableEdit={disabledButtons}
          disableDelete={disabledButtons}
          onClickEdit={handleEdit}
          onClickDelete={handleDelete}
        />

        <div className={classes.cardGrid}>
          <DataTable
            noMargin
            borderRadius={false}
            data={dataInTable}
            columns={headDatatable}
            selectChange={handleChangeSelected}
            showPagination
            pagination={paginacao}
          />
        </div>

        <ButtonFAB
          icon={iconAdd}
          positionDefault
          onClick={() => props.history.push(`/app/agencia/novo`)}
          disableFAB={isLoading}
        />
      </div>
    </MuiThemeProvider>
  );

  /**
   * Filtra os dados que aparecem no DataTable
   *
   * @param {*} dataChips - Lista de filtros
   */
  function onFilterChanged(dataChips) {
    setDataInTable(FilterUtil.applyFilters(dataAppSync, dataChips));
  }

  /**
   * Manipula o evento que é disparado quando uma linha do DataTable é selecionada
   *
   * @param {*} select - Objeto selecionado
   */
  function handleChangeSelected(select) {
    if (select) {
      setItemSelect(select);
      setDisabledButtons(false);
    } else {
      setItemSelect(null);
      setDisabledButtons(true);
    }
  }

  /**
   * Manipula o evento que é disparado quando é selecionado um item para editar
   */
  function handleEdit() {
    if (itemSelect) {
      props.history.push(`/app/agencia/editar/${itemSelect.idAgencia}`);
    }
  }

  /**
   * Manipula o evento que é disparado quando um item está sendo excluído
   */
  function handleDelete() {
    if (!itemSelect) return;

    swal({
      title: formatMessage('agencia.desejaExcluirAgencia'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('agencia.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('agencia.excluir'),
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-confirm'
        }
      }
    }).then(res => {
      if (!res) {
        return;
      }
      setLoading(true);

      deleteById(itemSelect.idAgencia)
        .then(res => {
          swal(formatMessage('agencia.agenciaExcluida'), {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          });

          onFilter(props.chipData);
        })
        .catch(err => {
          setLoading(false);
          swal(formatMessage('agencia.erroAoExcluir'), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          });
        });
    });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'agencia';
  })[0].content
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(Agencia)));
