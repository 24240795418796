import React from 'react';
import { Typography } from '@material-ui/core';
import { style } from './../AcompanhamentoProdutor.styles';
import { withStyles } from '@material-ui/core/styles';
import formatMessage from './../i18n/formatMessage';

/**
 * Componente de lista de produtos de um monitoramento
 */
function ProdutoDescricaoMonitoramento(props) {
  const { produtosList, atividadeAmostraList, classes } = props;

  return (
    <div>
      {produtosList.map((produto, key) => (
        <div key={key} className={classes.cardMonitoramento}>
          <div className={classes.fontLabelCardsCronograma}>{produto.nmSeveridade}</div>
          <div style={{ display: 'flex' }}>
            <Typography className={classes.listaAlertas} noWrap={true}>
              {formatMessage('acompanhamentoProdutor.mediaPorTalhao') +
                ': ' +
                calculaMediaPragasPorTalhao(produto)}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );

  /**
   * Realiza o calculo total de amostrar
   * @param {*} produto - id da severidade
   * @returns - resultado total
   */
  function calculaMediaPragasPorTalhao(produto) {
    let somatoria = 0;

    atividadeAmostraList.forEach(atividadeAmostra =>
      atividadeAmostra.atividadeAmostraQuantidadeList.forEach(atividadeAmostraQtd => {
        if (atividadeAmostraQtd.severidade.idSeveridade === produto.idSeveridade) {
          somatoria += atividadeAmostraQtd.nrQuantidade;
        }
      })
    );

    return Math.round(somatoria / atividadeAmostraList.length);
  }
}

export default withStyles(style)(ProdutoDescricaoMonitoramento);
