import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import { Grid, Checkbox } from '@material-ui/core';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import formatMessage from './i18n/formatMessage';
import {
  findMedidaCorretivaById,
  addMedidaCorretiva,
  editMedidaCorretiva
} from '@resources/MedidaCorretiva';
import { style, theme } from './MedidaCorretiva.styles';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Screen de edição ou cadastro de uma medida corretiva
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarMedidaCorretiva(props) {
  const { classes } = props;

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState({
    nmMedidaCorretiva: '',
    dsAtividade: ''
  });
  const [isLoading, setLoading] = useState(false);
  const [check, setCheck] = useState(true);

  useEffect(() => {
    const title = 'medidaCorretiva.cadastroDeMedidaCorretiva';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    if (props.match.params.id !== undefined) {
      setEnableReinitialize(true);
    }
  }, []);

  useEffect(() => {
    if (!enableReinitialize) return;
    setLoading(true);

    findMedidaCorretivaById(props.match.params.id)
      .then(res => {
        setCheck(res.data.stGeraAtividade === 1);
        setInitialValues(res.data);
      })
      .catch(err =>
        swal(formatMessage('medidaCorretiva.erroAoBuscarDados'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        })
      )
      .finally(() => {
        setEnableReinitialize(false);
        setLoading(false);
      });
  }, [enableReinitialize]);

  return (
    <Formik
    initialValues={{
      nmMedidaCorretiva: initialValues.nmMedidaCorretiva,
      dsAtividade: initialValues.dsAtividade,
    }}
      validateOnBlur
      enableReinitialize={enableReinitialize}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        nmMedidaCorretiva: Yup.string().required(formatMessage('medidaCorretiva.campoObrigatorio')),
        dsAtividade: Yup.string().required(formatMessage('medidaCorretiva.campoObrigatorio')),
      })}
      onSubmit={handleSubmitFormik}
      render={({ values, handleSubmit, errors, touched, handleBlur, handleChange, setFieldValue }) => (
        <MuiThemeProvider theme={theme}>
          {isLoading && <Loading />}

          <div style={{ width: '100%' }}>
            <CardIcon titulo={formatMessage('medidaCorretiva.identificacao')}>
              <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                <Grid item xs={7} style={{ height: 70 }}>
                  <TextField
                    label={formatMessage('medidaCorretiva.nome')}
                    name='nmMedidaCorretiva'
                    inputProps={{ maxLength: 60 }}
                    value={values.nmMedidaCorretiva}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nmMedidaCorretiva && touched.nmMedidaCorretiva}
                    helperText={
                      errors.nmMedidaCorretiva && touched.nmMedidaCorretiva
                        ? errors.nmMedidaCorretiva
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <div className={classes.switch} onClick={() => setCheck(!check)}>
                    <Checkbox checked={check} value='stGeraAtividade' color='primary' />
                    <label style={{ color: '#000000' }}>
                      {formatMessage('medidaCorretiva.geraAtividade')}
                    </label>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={16} className={classes.grid}>
                <Grid item xs={9}>
                  <TextField
                    label={formatMessage('medidaCorretiva.descricao')}
                    name='dsAtividade'
                    inputProps={{ maxLength: 200 }}
                    value={values.dsAtividade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.dsAtividade && touched.dsAtividade}
                    helperText={
                      errors.dsAtividade && touched.dsAtividade
                        ? errors.dsAtividade
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </CardIcon>
          </div>

          <div className={classes.fab}>
            <ButtonFABMenu
              icon={iconMenuPontos}
              actions={[
                {
                  icon: <Check style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('medidaCorretiva.salvar'),
                  onClickIcon: handleSubmit,
                  color: '#42ADE8'
                },
                {
                  icon: <Close style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('medidaCorretiva.cancelar'),
                  onClickIcon: () => props.history.push('/app/medida-corretiva'),
                  color: '#F33A30'
                }
              ]}
            />
          </div>
        </MuiThemeProvider>
      )}
    />
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {*} values - Valores do submit
   */
  function handleSubmitFormik(values) {
    setLoading(true);
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(
          `medidaCorretiva.${props.match.params.id === undefined ? 'salvando' : 'atualizando'}`
        )
      }
    );

    values.idMedidaCorretiva = +props.match.params.id || null;
    values.stGeraAtividade = check ? 1 : 0;

    let request;
    if (props.match.params.id === undefined) {
      request = addMedidaCorretiva(values);
    } else {
      request = editMedidaCorretiva(values);
    }

    request
      .then(res => {
        swal(formatMessage('medidaCorretiva.medidaCorretivaSalvoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
          }
        });

        props.history.push('/app/medida-corretiva');
      })
      .catch(err => {
        swal(formatMessage('medidaCorretiva.erroAoSalvar'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });

        setLoading(false);
      });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarMedidaCorretiva)));
