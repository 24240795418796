import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Chip from '@components/Chip/Chip';
import AddIcon from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import {
  findCulturaWithSazonalidades,
  updateCulturaWithSazonalidades,
  findAllSazonalidades
} from '@resources/Sazonalidade';

const iconMenuPontos = require('@images/icone_fab_menu.png');

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const style = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiCollapse: {
      container: {
        overflow: 'inherit !important'
      }
    },
    MuiFormLabel: {
      root: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 'calc(100% - 20px)'
      }
    },
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#008000'
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente para a edição de Sazonalidades
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class EditarSazonalidades
 * @extends {Component}
 */
class EditarSazonalidades extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const salvar = messagesLinguage['sazonalidade.salvar'];
    const cancelar = messagesLinguage['sazonalidade.cancelar'];
    const salvando = messagesLinguage['sazonalidade.salvando'];
    const atualizando = messagesLinguage['sazonalidade.atualizando'];
    const cadastroSazonalidade = messagesLinguage['sazonalidade.cadastroSazonalidade'];
    const minimoSazonalidades = messagesLinguage['sazonalidade.minimoSazonalidades'];
    const sazonalidadeSalvaComSucesso = messagesLinguage['sazonalidade.sazonalidadeSalvaComSucesso'];
    const erroAoSalvarSazonalidades = messagesLinguage['sazonalidade.erroAoSalvarSazonalidades'];
    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          erroAoSalvarSazonalidades,
          sazonalidadeSalvaComSucesso,
          minimoSazonalidades,
          cadastroSazonalidade,
          salvar,
          cancelar,
          salvando,
          atualizando
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    const title = 'sazonalidade.cadastroSazonalidade';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.state = {
      sazonalidadesSelecteds: [],
      allSazonalidades: [],
      sazonalidadesVisibles: [],
      cultura: { nmCultura: '' },
      enableReinitialize: true,
      loading: true
    };

    this.loadCulturaWithSazonalidades = this.loadCulturaWithSazonalidades.bind(this);
    this.loadAllSazonalidades = this.loadAllSazonalidades.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onAddSazonalidade = this.onAddSazonalidade.bind(this);
    this.onRemoveChip = this.onRemoveChip.bind(this);
    this.exit = this.exit.bind(this);
    this.syncSazonalidadesVisibles = this.syncSazonalidadesVisibles.bind(this);
  }

  async componentDidMount() {
    const promise1 = this.loadAllSazonalidades();
    const promise2 = this.loadCulturaWithSazonalidades();
    const promises = [promise1, promise2];

    await Promise.all(promises);

    this.setState({ loading: false });
    this.syncSazonalidadesVisibles();
  }

  onSave() {
    const errorConfig = {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    };

    if (this.state.sazonalidadesSelecteds.length === 0) {
      swal(provider.intl.formatMessage({ id: 'minimoSazonalidades' }), errorConfig);
      return;
    }

    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: provider.intl.formatMessage({ id: 'salvando' })
      }
    );

    const cultura = { ...this.state.cultura, sazonalidadeList: this.state.sazonalidadesSelecteds };

    updateCulturaWithSazonalidades(cultura)
      .then(res => {
        swal(provider.intl.formatMessage({ id: 'sazonalidadeSalvaComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });

        this.exit();
      })
      .catch(err => swal(provider.intl.formatMessage({ id: 'erroAoSalvarSazonalidades' }), errorConfig));
  }

  loadAllSazonalidades() {
    return findAllSazonalidades().then(res => this.setState({ allSazonalidades: res.data }));
  }

  loadCulturaWithSazonalidades() {
    return findCulturaWithSazonalidades(+this.props.match.params.idCultura).then(res =>
      this.setState({
        cultura: { ...res.data, sazonalidadeList: undefined },
        sazonalidadesSelecteds: res.data.sazonalidadeList
      })
    );
  }

  onAddSazonalidade() {
    if (!this.values.sazonalidade) return;

    const sazonalidade = this.state.allSazonalidades.find(
      item => item.idSazonalidade === +this.values.sazonalidade
    );

    const auxList = [...this.state.sazonalidadesSelecteds];
    auxList.push(sazonalidade);

    this.setFieldValue('sazonalidade', '');
    this.setState({ sazonalidadesSelecteds: auxList }, this.syncSazonalidadesVisibles);
  }

  syncSazonalidadesVisibles() {
    this.setState(state => ({
      sazonalidadesVisibles: state.allSazonalidades.filter(
        item =>
          !state.sazonalidadesSelecteds.find(subItem => subItem.idSazonalidade === item.idSazonalidade)
      )
    }));
  }

  onRemoveChip(index) {
    const auxList = [...this.state.sazonalidadesSelecteds];
    auxList.splice(index, 1);
    this.setState({ sazonalidadesSelecteds: auxList }, this.syncSazonalidadesVisibles);
  }

  exit() {
    this.props.history.push('/app/sazonalidade');
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        {this.state.loading && <Loading />}

        <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
          <Formik
            initialValues={{ sazonalidade: '' }}
            validateOnBlur
            enableReinitialize={this.state.enableReinitialize}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              sazonalidade: Yup.string().required(
                <FormattedMessage id='sazonalidade.campoObrigatorio' />
              )
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              handleChange
            }) => {
              this.setFieldValue = setFieldValue;
              this.values = values;

              return (
                <div style={{ width: '100%' }}>
                  <CardIcon titulo={<FormattedMessage id='sazonalidade.sazonalidades' />}>
                    <form style={{ paddingTop: 30 }}>
                      <Grid container style={{ height: 70 }}>
                        <Grid item xs={4}>
                          <TextField
                            label={<FormattedMessage id='sazonalidade.cultura' />}
                            name='nmCultura'
                            value={this.state.cultura.nmCultura}
                            disabled
                            readOnly
                          />
                        </Grid>
                      </Grid>

                      <Grid container style={{ height: 70, marginBottom: 15 }}>
                        <Grid item xs={4}>
                          <FormControl
                            error={errors.sazonalidade && touched.sazonalidade}
                            style={{ width: '100%' }}
                          >
                            <InputLabel
                              htmlFor='sazonalidade'
                              error={errors.sazonalidade && touched.sazonalidade}
                            >
                              {<FormattedMessage id='sazonalidade.sazonalidade' />}
                            </InputLabel>

                            <Select
                              value={'' + values.sazonalidade}
                              error={errors.sazonalidade && touched.sazonalidade}
                              onChange={e =>
                                setFieldValue(
                                  'sazonalidade',
                                  e.target.value !== '' ? +e.target.value : e.target.value
                                )
                              }
                              onBlur={handleBlur}
                              name='sazonalidade'
                              id='sazonalidade'
                              disabled={this.state.loading}
                            >
                              {this.state.sazonalidadesVisibles.map((item, i) => (
                                <MenuItem key={i} value={'' + item.idSazonalidade}>
                                  {item.dsMes}
                                </MenuItem>
                              ))}
                            </Select>

                            {errors.sazonalidade && touched.sazonalidade && (
                              <FormHelperText>{errors.sazonalidade}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={1} style={{ paddingLeft: 15 }}>
                          <IconButton
                            aria-label='ADD'
                            style={{ height: 50, width: 50, marginTop: 5 }}
                            disabled={!values.sazonalidade || this.state.loading}
                            onClick={this.onAddSazonalidade}
                          >
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container style={{ minHeight: 70 }}>
                        <Grid item xs>
                          {this.state.sazonalidadesSelecteds.map((chip, i) => (
                            <Chip
                              key={i}
                              text={chip.dsMes}
                              tpPerfil
                              onRemove={() => this.onRemoveChip(i)}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </form>
                  </CardIcon>

                  <div className={classes.fab}>
                    <ButtonFABMenu
                      icon={iconMenuPontos}
                      actions={[
                        {
                          icon: <Check style={{ color: 'white' }} />,
                          name: provider.intl.formatMessage({ id: 'salvar' }),
                          onClickIcon: this.onSave,
                          color: '#42ADE8',
                          disabled: this.state.loading
                        },
                        {
                          icon: <Close style={{ color: 'white' }} />,
                          name: provider.intl.formatMessage({ id: 'cancelar' }),
                          onClickIcon: this.exit,
                          color: '#F33A30'
                        }
                      ]}
                    />
                  </div>
                </div>
              );
            }}
          />
        </IntlProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(withStyles(style)(connect(mapStateToProps)(EditarSazonalidades)));
