export default {
  fitossanidade: {
    fitossanidade: "Salud de las plantas",
    nao: "No",
    desejaExcluir: "¿Quieres borrar?",
    excluir: "Excluir",
    fitossanidadeRemovidoComSucesso: "Salud de las plantas eliminada con éxito",
    falhaAoExcluirFitossanidade: "Error al eliminar la salud de las plantas",
    excluindo: "Excluyendo",
    cultura: "Cultura",
    tipo: "Tipo",
    situacao: "Situación",
    nome: "Nombre",
    fitossanidadeCadastradoComSucesso:
      "Salud de las plantas registrada con éxito",
    fitossanidadeEditadoComSucesso: "Salud de las plantas editada con éxito",
    falhaAoCadastrarFitossanidade:
      "No se pudo registrar la salud de las plantas",
    falhaAoEditarFitossanidade: "No se pudo registrar la salud de las plantas",
    falhaAoCarregarFitossanidade: "Error al cargar la salud de las plantas",
    salvar: "Guardar",
    cancelar: "Cancelar",
    salvando: "Salvando",
    atualizando: "Actualización",
    cadastroDeFitossanidade: "Registro de salud de las plantass",
    campoObrigatorio: "Campo obligatorio",
    identificacaoDeFitossanidade: "Identificación de salud de las plantass",
    doencaSolo: "Enfermedad del suelo",
    doencaFoliar: "Enfermedad de la hoja",
    nematoide: "Nematodo",
    descricao: "Descripción",
    ativo: "Activo",
    inativo: "Inactivo",
    pragas: "Praga"
  }
};
