import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import formatMessage from './i18n/formatMessage';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Pesticidas from '@resources/Pesticidas';
import Marca from '@resources/Marcas';
import { findAllActivesCertificacoes } from '@resources/CertificacaoOrganica';
import { theme, style } from './InsumoRecord.styles';
import Navigation from '@components/Navigation/Navigation';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import { getUserLang } from '@utils/localeUtils';
import moment from 'moment';
import { validarDatasCertificacao } from '@utils/validateDataCertificado';
import Certificacao from './Certificacao/Certificacao';
import CadastrarCertificacao from './Certificacao/CadastrarCertificacao';
import HistoricoCertificacao from './Certificacao/HistoricoCertificacao';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Componente para cadastro de insumos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function CadastrarInsumoRecord(props) {
  const {
    classes,
    id,
    insumoURL,
    insumoEnum,
    infoUsuario,
    saveMessage,
    falhaSaveMesssage,
    falhaCarregarMensagem
  } = props;
  const [descricao, setDescricao] = useState('');
  const [valueAutoComplete, setValueAutoComplete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validarInput, setValidarInput] = useState(false);
  const [key, setKey] = useState(0);
  const [certificacaoList, setCertificacaoList] = useState([]);
  const [marcaList, setMarcaList] = useState([]);
  const [index, setIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [keyCertificacao, setKeyCertificacao] = useState(0);
  const [valueAutoCompleteCertificacao, setValueAutoCompleteCertificacao] = useState(null);
  const [stCertificacao, setStCertificacao] = useState(false);
  const [initialValues, setInitialValues] = useState({
    dtInicio: null,
    dtTermino: null,
    tipoCertificacao: null
  });
  const [isEditing, setIsEditing] = useState(false);
  const [listOrganico, setListOrganico] = useState([]);
  const [selected, setSelected] = useState(null);
  const [itensTabs, setItensTabs] = useState([
    {
      name: formatMessage('insumo.identificacao'),
      key: 0,
      select: true
    },
    {
      name: formatMessage('insumo.certificacao'),
      key: 1,
      select: false
    }
  ]);

  useEffect(() => {
    validarInicializacaoAutoComplete();
    iniciarAtributosEdicao();
    inicializarCertificacao();
  }, []);

  function formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  /**
   * Busca os registros para edição
   */
  function iniciarAtributosEdicao() {
    if (id !== undefined) {
      Pesticidas.findPesticidaById(id)
        .then(query => {
          setCertificacaoList(query.data.insumoTipoCertificacaoList);

          setDescricao(query.data.nmInsumo);
          setLoading(false);
          setValueAutoComplete(query.data.marca);
          setKey(Math.random());
        })
        .catch(error => {
          setLoading(false);
          swalError(falhaCarregarMensagem);
        });
    }
  }

  /**
   * Busca os registro de classificação para auto complete do talhão
   */
  function inicializarCertificacao() {
    findAllActivesCertificacoes()
      .then(doc => {
        setListOrganico(doc.data);
      })
      .catch(() => {
        setListOrganico([]);
      });
  }

  /**
   * Retorna o swal de error
   *
   * @param {string} mensagem
   */
  function swalError(mensagem) {
    swal(mensagem, {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  /**
   * Retorna o swal de sucesso
   *
   * @param {string} mensagem
   */
  function swalSucess(mensagem) {
    return swal(mensagem, {
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    });
  }

  /**
   * Inicializa os dados para o auto complete
   */
  function validarInicializacaoAutoComplete() {
    Marca.findAllMarcaByStatus(1)
      .then(querySnapshot => {
        setMarcaList(querySnapshot.data);
      })
      .catch(error => {
        setMarcaList([]);
      });
  }

  function tratarValorSelectInitial(data) {
    if (!data) {
      return '';
    }

    return data;
  }

  function handleBlurOwn(valueAutoCompleteBlur) {
    var value = false;
    if (valueAutoCompleteBlur === null) {
      value = true;
    }

    setValidarInput(value);
  }

  function onChangeOwn(nmMarca) {
    setValueAutoComplete(nmMarca);
  }

  function tratarValorInicialMarca(data) {
    if (!data || data.nmMarca === undefined) {
      return '';
    }
    return data.nmMarca;
  }

  function clickCancelar() {
    props.history.push(`/app/${insumoURL}`);
  }

  function handleChangeIndex(indexTab) {
    itensTabs.forEach(doc => {
      if (indexTab === doc.key) {
        doc.select = true;
      } else {
        doc.select = false;
      }
    });

    setItensTabs([...itensTabs]);
    setIndex(indexTab);
  }

  function clickClear(inTab) {
    setInitialValues({
      dtInicio: null,
      dtTermino: null,
      tipoCertificacao: null
    });

    setIsEditing(false);
    setStCertificacao(false);
    setValueAutoCompleteCertificacao(null);
    setKeyCertificacao(Math.random());
    if (!inTab) {
      setTabIndex(0);
    }
  }

  function clickUpdate(value) {
    setIsEditing(true);
    setInitialValues({
      dtInicio: null,
      dtTermino: null,
      ...value
    });
    setValueAutoCompleteCertificacao(value.tipoCertificacao);
    setKeyCertificacao(Math.random());
    setStCertificacao(value.stInsumoTipoCertificacao === 1);
    setTabIndex(1);
  }

  function saveCertificacao(values, { resetForm }) {
    if (values.dtTermino === null && !stCertificacao) {
      swal({
        title: formatMessage('insumo.inativarDataFinal'),
        icon: 'error',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          cancel: {
            text: 'ok',
            value: false,
            visible: true,
            closeModal: true,
            className: 'swal2-cancel'
          }
        }
      });

      return;
    }

    const validator = validarDatasCertificacao(values, certificacaoList, isEditing, 'idTalhaoTipoCertificacao');

    if (validator) {
      swal({
        title: formatMessage('insumo.certificadoExistente'),
        icon: 'error',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          cancel: {
            text: 'ok',
            value: false,
            visible: true,
            closeModal: true,
            className: 'swal2-cancel'
          }
        }
      });

      return;
    }

    const certificacaoListAux = [...certificacaoList];

    if (values.dtInicio) {
      values.dtInicio = moment(values.dtInicio).format('YYYY-MM-DD');
    } else {
      values.dtInicio = null;
    }

    if (values.dtTermino) {
      values.dtTermino = moment(values.dtTermino).format('YYYY-MM-DD');
    } else {
      values.dtTermino = null;
    }

    values.stInsumoTipoCertificacao = stCertificacao ? 1 : 0;

    if (isEditing) {
      const item = certificacaoListAux.find(
        itemCert => itemCert.idInsumoTipoCertificacao === values.idInsumoTipoCertificacao
      );
      let position = certificacaoListAux.indexOf(item);
      certificacaoListAux[position] = values;
      values.stRegistro = initialValues.stRegistro;
    } else {
      values.idInsumoTipoCertificacao = Math.random();
      values.stRegistro = 0;
      certificacaoListAux.push(values);
    }

    setCertificacaoList(certificacaoListAux);

    resetForm({
      tipoCertificacao: null,
      dtInicio: null,
      dtTermino: null
    });
    setValueAutoCompleteCertificacao(null);
    setKeyCertificacao(Math.random());
    clickClear();
  }

  function certificacaoCard() {
    return (
      <div style={{ height: 'calc(100vh - 95px)' }}>
        <CardWithTabs
          withoutHidden={true}
          selectedIndex={tabIndex}
          onChangeTabIndex={(e, tab) => {
            clickClear(true);
            setTabIndex(tab);
            setSelected(null)
          }}
          title={formatMessage('insumo.certificacao')}
          tabs={[
            {
              label: formatMessage('insumo.certificacao'),
              component: (
                <Certificacao
                  formatTextField={formatTextField}
                  certificacaoList={certificacaoList}
                  clickUpdate={clickUpdate}
                />
              )
            },
            {
              label: formatMessage('insumo.cadastrar'),
              component: (
                <CadastrarCertificacao
                  listOrganico={listOrganico}
                  initialValues={initialValues}
                  setValueAutoCompleteCertificacao={setValueAutoCompleteCertificacao}
                  saveCertificacao={saveCertificacao}
                  keyCertificacao={keyCertificacao}
                  valueAutoCompleteCertificacao={valueAutoCompleteCertificacao}
                  setStCertificacao={setStCertificacao}
                  stCertificacao={stCertificacao}
                  setKeyCertificacao={setKeyCertificacao}
                  setKey={setKey}
                  clickClear={clickClear}
                />
              )
            },
            {
              label: formatMessage('insumo.historico'),
              component: (
                <HistoricoCertificacao
                  setSelected={setSelected}
                  selected={selected}
                  clickUpdate={clickUpdate}
                  certificacaoList={certificacaoList}
                  formatTextField={formatTextField}
                />
              )
            }
          ]}
        />
      </div>
    );
  }

  function identificacao(touched, values, setFieldValue, errors) {
    return (
      <CardIcon titulo={formatMessage('insumo.identificacao')}>
        <Grid container spacing={16} style={{ marginTop: '15px' }}>
          <Grid item xs={4} style={{ minHeight: '75px' }}>
            <TextField
              inputProps={{
                maxLength: 150
              }}
              label={formatMessage('insumo.descricao')}
              name='descricao'
              value={values.descricao}
              onChange={event => {
                setFieldValue('descricao', event.target.value);
              }}
              onBlur={event => setDescricao(event.target.value)}
              error={errors.descricao && touched.descricao}
              helperText={
                errors.descricao && touched.descricao ? formatMessage('insumo.campoObrigatorio') : null
              }
              className={classes.textField}
              style={{ width: '100%' }}
              InputLabelProps={{ className: classes.font }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              itens={marcaList}
              campoOp={'nmMarca'}
              campoChave={'idMarca'}
              name='marca'
              label={formatMessage('insumo.marca')}
              onBlurOwn={handleBlurOwn}
              error={validarInput ? true : errors.marca && touched.marca}
              helperText={(touched.marca && errors.marca) || validarInput ? errors.marca : null}
              onChangeOwn={onChangeOwn}
              onChangeAutoComplete={validarInicializacaoAutoComplete}
              value={tratarValorInicialMarca(valueAutoComplete)}
              key={key}
              valueAutoComplete={tratarValorSelectInitial(valueAutoComplete)}
              valueSelect={tratarValorSelectInitial(valueAutoComplete).nmMarca}
            />
          </Grid>
        </Grid>
      </CardIcon>
    );
  }

  function identificacaoOrganica(touched, values, setFieldValue, errors) {
    return (
      <Navigation index={index} handleChangeIndex={handleChangeIndex} buttons={itensTabs}>
        {[
          index === 0 && (
            <div key={0} style={{ width: '100%' }}>
              {identificacao(touched, values, setFieldValue, errors)}
            </div>
          ),
          index === 1 && (
            <div key={1} className={classes.cardWithScroll}>
              {certificacaoCard()}
            </div>
          )
        ]}
      </Navigation>
    );
  }

  return (
    <Formik
      initialValues={{
        descricao: descricao,
        marca: valueAutoComplete
      }}
      onSubmit={clickCadastrarEditar}
      validateOnBlur
      enableReinitialize={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        descricao: Yup.string().required(formatMessage('insumo.campoObrigatorio')),
        marca: Yup.string()
          .nullable()
          .required(formatMessage('insumo.campoObrigatorio'))
      })}
      render={({ values, handleSubmit, errors, touched, setFieldValue }) => (
        <MuiThemeProvider theme={theme}>
          <form autoComplete='off' className={classes.parentDiv} onSubmit={handleSubmit}>
            <div className={classes.topDiv}>
              {loading && <Loading />}
              <div className={classes.identif_Div}>
                {infoUsuario.stOrganica === 1
                  ? identificacaoOrganica(touched, values, setFieldValue, errors)
                  : identificacao(touched, values, setFieldValue, errors)}
              </div>
            </div>
            <div className={classes.fab}>
              <ButtonFABMenu
                icon={iconMenuPontos}
                actions={[
                  {
                    icon: <Check style={{ color: 'white' }} />,
                    name: formatMessage('insumo.salvar'),
                    onClickIcon: handleSubmit,
                    color: '#42ADE8'
                  },
                  {
                    icon: <Close style={{ color: 'white' }} />,
                    name: formatMessage('insumo.cancelar'),
                    onClickIcon: clickCancelar,
                    color: '#F33A30'
                  }
                ]}
              />
            </div>
          </form>
        </MuiThemeProvider>
      )}
    />
  );

  /**
   * Valida os dados e salva
   *
   * @param {*} values - valores para salvar
   */
  function clickCadastrarEditar(values) {
    if (!validarInput) {
      swal(
        <div>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>,
        {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          title: formatMessage(id === undefined ? 'insumo.salvando' : 'insumo.atualizando')
        }
      );

      certificacaoList.forEach(item => {
        if (item.stRegistro === 0) {
          delete item.idInsumoTipoCertificacao;
        }
      });

      if (id === undefined) {
        const data = {
          nmInsumo: values.descricao,
          tpInsumo: insumoEnum,
          marca: {
            idMarca: valueAutoComplete.idMarca
          },
          insumoTipoCertificacaoList: certificacaoList
        };

        Pesticidas.addPesticida(data)
          .then(() => {
            swalSucess(saveMessage).then(() => {
              clickCancelar();
            });
          })
          .catch(error => {
            swalError(falhaSaveMesssage);
          });
      } else {
        const data = {
          nmInsumo: values.descricao,
          tpInsumo: insumoEnum,
          marca: {
            idMarca: valueAutoComplete.idMarca
          },
          idInsumo: id,
          insumoTipoCertificacaoList: certificacaoList
        };

        Pesticidas.editPesticidas(data)
          .then(() => {
            swalSucess(saveMessage).then(() => {
              clickCancelar();
            });
          })
          .catch(error => {
            swalError(falhaSaveMesssage);
          });
      }
    }
  }
}

export default withRouter(withStyles(style)(CadastrarInsumoRecord));
