import React, { Component } from 'react';
import formatMessage from '../i18n/formatMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@components/Card/Card';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';
import moment from 'moment';
import EntregaEfetiva from './EntregaEfetiva';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
  container: {
    width: '100%',
    maxHeight: 'calc(100vh - 80px)',
    overflowY: 'auto'
  }
});

/**
 * Componente da tab de Colheita do cadastro de Plantio
 *
 * @author Bruno Eduardo
 * @class Colheita
 * @extends {Component} - Componente React
 */
class Colheita extends Component {
  constructor(props) {
    super(props);

    this.handleBlurAndPersist = this.handleBlurAndPersist.bind(this);
  }

  /**
   * Faz o handleBlur do campo e persiste o dado
   *
   * @param {*} e - Blur event
   * @param {String} [campo=null] - Campo para fazer o handleBlur
   * @param {Object} additionalValues - Valores adicionais para persistir
   */
  handleBlurAndPersist(e, campo = null, additionalValues = {}) {
    this.props.persist({ ...this.values, ...additionalValues });

    if (campo) {
      this.handleBlur(campo)(e);
    } else {
      this.handleBlur(e);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container} style={this.props.isVisible ? {} : { display: 'none' }}>
        <Formik
          initialValues={this.props.values}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={false}
          onSubmit={() => {}}
          validationSchema={Yup.lazy(values =>
            Yup.object().shape({
              nrProdutividade: !values.dtColheita
                ? Yup.number()
                : Yup.number().required(formatMessage('plantios.campoObrigatorio')),
              tpUnidade: !values.dtColheita
                ? Yup.number()
                : Yup.number().required(formatMessage('plantios.campoObrigatorio')),
              tpArea: !values.dtColheita
                ? Yup.number()
                : Yup.number().required(formatMessage('plantios.campoObrigatorio')),
              nrEntregaEfetiva: Yup.number(),
              tpUnidadeEntregaEfetiva:
                values.nrEntregaEfetiva === '' || values.nrEntregaEfetiva === undefined
                  ? Yup.number()
                  : Yup.number().required(formatMessage('plantios.campoObrigatorio')),
              dsCausaEntregaEfetiva: Yup.string()
            })
          )}
          render={formikProps => {
            const { values, errors, touched, handleBlur, setFieldValue, submitForm } = formikProps;
            this.props.getFormikProps && this.props.getFormikProps(formikProps);
            this.values = values;
            this.handleSubmit = submitForm;
            this.setFieldValue = setFieldValue;
            this.handleBlur = handleBlur;

            return (
              <>
                <Card
                  withOutBorderRadius
                  title={formatMessage('plantios.colheita')}
                  height='auto'
                  style={{ minHeight: 190, maxHeight: 190 }}
                >
                  <Grid container>
                    <Grid item xs={3} style={{ paddingRight: 15 }}>
                      <DatePicker
                        disabled={this.props.infoUsuario.tpPerfil === 3 || !this.props.dtPlantio}
                        name='dtColheita'
                        locale={getUserLang()}
                        errorTouched={errors.dtColheita && touched.dtColheita}
                        helperText={formatMessage('plantios.campoObrigatorio')}
                        label={formatMessage('plantios.dataColheita')}
                        valueDate={values.dtColheita}
                        onBlurDatePicker={e => {
                          const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                          if (validation) {
                            setFieldValue('dtColheita', e.target.value);
                          } else {
                            setFieldValue('dtColheita', null);

                            values.dtColheita = null;
                            this.props.persist(this.values);
                          }
                        }}
                        onChangeDatePicker={date => {
                          values.dtColheita = moment(date).format('YYYY-MM-DD');
                          setFieldValue('dtColheita', date);

                          this.props.persist(this.values);

                          values.tpUnidade === '' && setFieldValue('tpUnidade', 0);
                          values.tpArea === '' && setFieldValue('tpArea', 0);
                        }}
                        minDate={this.props.dtPlantio || null}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ paddingRight: 15 }}>
                      <TextFieldWithMask
                        tipoMascara={3}
                        numberDecimalScale={2}
                        showThousandSeparator
                        id='nrProdutividade'
                        name='nrProdutividade'
                        onChange={e =>
                          setFieldValue(
                            'nrProdutividade',
                            e.target.value !== '' ? +e.target.value : e.target.value
                          )
                        }
                        onBlur={e => this.handleBlurAndPersist(e, 'nrProdutividade')}
                        value={values.dtColheita ? values.nrProdutividade : ''}
                        label={formatMessage('plantios.produtividade')}
                        error={errors.nrProdutividade && touched.nrProdutividade}
                        helperText={touched.nrProdutividade ? errors.nrProdutividade : ''}
                        disabled={!values.dtColheita || this.props.infoUsuario.tpPerfil === 3}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ paddingRight: 15 }}>
                      <FormControl
                        error={errors.tpUnidade && touched.tpUnidade}
                        style={{ width: '100%' }}
                      >
                        <InputLabel htmlFor='tpUnidade' error={errors.tpUnidade && touched.tpUnidade}>
                          {formatMessage('plantios.unMedida')}
                        </InputLabel>

                        <Select
                          value={values.dtColheita ? '' + values.tpUnidade : ''}
                          error={errors.tpUnidade && touched.tpUnidade}
                          onChange={e =>
                            setFieldValue(
                              'tpUnidade',
                              e.target.value !== '' ? +e.target.value : e.target.value
                            )
                          }
                          onBlur={this.handleBlurAndPersist}
                          name='tpUnidade'
                          id='tpUnidade'
                          disabled={!values.dtColheita || this.props.infoUsuario.tpPerfil === 3}
                        >
                          {[
                            formatMessage('plantios.sacas'),
                            formatMessage('plantios.tonelada'),
                            formatMessage('plantios.kg')
                          ].map((item, i) => (
                            <MenuItem key={i} value={'' + i}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>

                        {errors.tpUnidade && touched.tpUnidade && (
                          <FormHelperText>{errors.tpUnidade}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <FormControl error={errors.tpArea && touched.tpArea} style={{ width: '100%' }}>
                        <InputLabel htmlFor='tpArea' error={errors.tpArea && touched.tpArea}>
                          {formatMessage('plantios.area')}
                        </InputLabel>

                        <Select
                          value={values.dtColheita ? '' + values.tpArea : ''}
                          error={errors.tpArea && touched.tpArea}
                          onChange={e =>
                            setFieldValue(
                              'tpArea',
                              e.target.value !== '' ? +e.target.value : e.target.value
                            )
                          }
                          onBlur={this.handleBlurAndPersist}
                          name='tpArea'
                          id='tpArea'
                          disabled={!values.dtColheita || this.props.infoUsuario.tpPerfil === 3}
                        >
                          {[
                            formatMessage('plantios.acre'),
                            formatMessage('plantios.alqueire'),
                            formatMessage('plantios.hectare'),
                            formatMessage('plantios.m2')
                          ].map((item, i) => (
                            <MenuItem key={i} value={'' + i}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>

                        {errors.tpArea && touched.tpArea && (
                          <FormHelperText>{errors.tpArea}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Card>

                {this.props.infoUsuario.stOrganica === 1 && (
                  <EntregaEfetiva
                    values={values}
                    persist={values => this.props.persist(values)}
                    infoUsuario={this.props.infoUsuario}
                    handleBlurAndPersist={this.handleBlurAndPersist}
                    {...formikProps}
                  />
                )}
              </>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(style)(Colheita);
