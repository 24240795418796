export default {
  nematicidaMicrobiologico: {
    nematicidaMicrobiologicoRemovidoComSucesso: 'Nematicida microbiológico removido com sucesso',
    falhaAoExcluirNematicidaMicrobiologico: 'Falha ao excluir nematicida microbiológico',
    desejaExcluir: 'Deseja excluir o nematicida microbiológico selecionado?',
    nematicidaMicrobiologico: 'Nematicidas microbiológicos',
    cadastroDeNematicidaMicrobiologico: 'Cadastro de nematicidas microbiológicos',
    identificacaoDaNematicidaMicrobiologico: 'Identificação',
    nematicidaMicrobiologicoCadastradoComSucesso: 'Nematicida microbiológico cadastrado com sucesso',
    nematicidaMicrobiologicoEditadoComSucesso: 'Nematicida microbiológico editado com sucesso',
    falhaAoEditarNematicidaMicrobiologico: 'Falha ao editar nematicida microbiológico',
    falhaAoCadastrarNematicidaMicrobiologico: 'Falha ao cadastrar nematicida microbiológico',
    falhaAoCarregarNematicidaMicrobiologico: 'Falha ao carregar nematicidas microbiológicos'
  }
};
