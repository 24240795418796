import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CadastrarInsumoRecord from '@components/InsumoRecord/CadastrarInsumoRecord';
import formatMessage from './i18n/formatMessage';
import Insumo from '@resources/InsumosEnum';
import { withRouter } from 'react-router-dom';

/**
 * Tela para cadastro de nematicidas microbiologicos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarNematicidasMicrobiologicos(props) {
  useEffect(() => {
    const title = 'nematicidaMicrobiologico.cadastroDeNematicidaMicrobiologico';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }
  }, []);

  return (
    <CadastrarInsumoRecord
      id={props.match.params.id}
      insumoURL={'nematicidas-microbiologicos'}
      insumoEnum={Insumo.NEMATICIDA_MICROBIOLOGICO}
      infoUsuario={props.infoUsuario}
      saveMessage={formatMessage(
        'nematicidaMicrobiologico.' +
          (props.match.params.id
            ? 'nematicidaMicrobiologicoEditadoComSucesso'
            : 'nematicidaMicrobiologicoCadastradoComSucesso')
      )}
      falhaSaveMesssage={formatMessage(
        'nematicidaMicrobiologico.' +
          (props.match.params.id
            ? 'falhaAoEditarNematicidaMicrobiologico'
            : 'falhaAoCadastrarNematicidaMicrobiologico')
      )}
      falhaCarregarMensagem={formatMessage('nematicidaMicrobiologico.falhaAoCarregarNematicidaMicrobiologico')}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(EditarNematicidasMicrobiologicos));
