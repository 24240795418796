export default {
  especies: {
    especie: 'Especie',
    descricao: 'Descripción',
    desejaExcluir: '¿Quieres eliminar especies?',
    nao: 'No',
    excluir: 'Excluir',
    excluindo: 'Excluyendo',
    falhaAoExcluirEspecie: 'Error al eliminar especies',
    especieRemovidoComSucesso: 'Especies eliminadas con éxito',
    especieCadastradaComSucesso: 'Especies registradas exitosamente',
    especieEditadaComSucesso: 'Especies editadas con éxito',
    falhaAoEditarEspecie: 'Error al editar especies',
    falhaAoCadastrarEspecie: 'Error al registrar especies',
    falhaAoCarregarEspecie: 'Error al cargar especies',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Guardar',
    atualizar: 'Actualizar',
    atualizando: 'Actualización',
    cadastroDeEspecie: 'Registro de especies',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    ativo: 'Activo',
    situacao: 'Situación',
    inativo: 'Inactivo'
  }
};
