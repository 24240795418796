export default {
  perfil: {
    atualizando: "Updating",
    salvar: "Save",
    erroAoEditarPerfil: "Failed to edit user",
    perfilEditadoComSucesso: "Profile edited successfully",
    problemaInesperado: "oThere was an unexpected problem",
    identificacao: "Identification",
    nome: "Name",
    tipoDePerfil: "Profile type",
    email: "Email",
    situacaoDaConta: "Account status",
    inativo: "Inactive",
    ativo: "Active",
    administrador: "Administrator",
    revenda: "Resale",
    tecnico: "Technician",
    produtor: "Producer",
    campoObrigatorio: "Required field",
    cancelar: "Cancel",
    perfilDoUsuario: "User Profile",
    trocarSenha: "Change Password",
    senhaAtual: "Current password",
    novaSenha: "New password",
    confNovaSenha: "Confirmation of new password",
    senhaMinino: "Password must be at least 6 characters",
    senhaIncompativel: "Incompatible Passwords",
    senhaIncorreta: "Incorrect password",
    gerente: "Manager",
    representante: "Representative"
  }
};
