import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import formatMessage from './i18n/formatMessage';
import { findAgenciaById, save, update } from '@resources/Agencia';
import { style, theme } from './Agencia.styles';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Localizacao from '@resources/Localizacao';
import Checkbox from '@material-ui/core/Checkbox';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Screen de edição ou cadastro de uma certificação orgânica
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarAgencia(props) {
  const { classes } = props;

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState({
    nmAgencia: '',
    cdAgencia: '',
    estado: null,
    cidade: null
  });
  const [isLoading, setLoading] = useState(false);
  const [estado, setEstado] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [estadoList, setEstadoList] = useState([]);
  const [cidadeList, setCidadeList] = useState([]);
  const [keyCidade, setKeyCidade] = useState(0);
  const [keyEstado, setKeyEstado] = useState(0);
  const [stAgencia, setStAgencia] = useState(true);

  useEffect(() => {
    const title = 'agencia.cadastroDeAgencia';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    loadingDados();
  }, []);

  const loadingDados = async () => {
    await inicializacaoAutoCompleteEstado();

    if (props.match.params.id !== undefined) {
      setEnableReinitialize(true);
    }
  };

  useEffect(() => {
    if (!enableReinitialize) return;
    setLoading(true);

    findAgenciaById(props.match.params.id)
      .then(res => {
        const agenciaAux = res.data;

        const estadoDados = estadoList.find(item => item.idUf === res.data.idUf);
        agenciaAux.estado = {
          idUf: res.data.idUf,
          sgUf: res.data.sgUf,
          nmUf: estadoDados.nmUf
        };
        agenciaAux.cidade = {
          idMunicipio: res.data.idMunicipio,
          nmMunicipio: res.data.nmMunicipio
        };

        setEstado(agenciaAux.estado);
        setMunicipio(agenciaAux.cidade);
        setKeyEstado(Math.random());
        setKeyCidade(Math.random());
        setInitialValues(agenciaAux);
        setStAgencia(agenciaAux.stAgencia === 1);
      })
      .catch(err =>
        swal(formatMessage('agencia.erroAoBuscarDados'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        })
      )
      .finally(() => {
        setEnableReinitialize(false);
        setLoading(false);
      });
  }, [enableReinitialize]);

  function onChangeEstado(event) {
    setEstado(event);

    event && inicializacaoAutoCompleteMunicipio(event.idUf);
  }

  function onChangeMunicipio(event) {
    setMunicipio(event);
  }

  async function inicializacaoAutoCompleteEstado() {
    await Localizacao.findAllEstados()
      .then(query => setEstadoList(query.data))
      .catch(() => setEstadoList([]));
  }

  function inicializacaoAutoCompleteMunicipio(idUf) {
    if (idUf !== undefined) {
      Localizacao.findMunicipiosByUf(idUf)
        .then(query => setCidadeList(query.data))
        .catch(() => setCidadeList([]));
    }
  }

  function tratarValorSelectInitialEstado(data) {
    if (!data || data.sgUf === undefined) {
      return '';
    }
    return data;
  }

  function tratarValorSelectInitialMunicipio(data) {
    if (!data || data.nmMunicipio === undefined) {
      return '';
    }
    return data;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize={enableReinitialize}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        nmAgencia: Yup.string().required(formatMessage('agencia.campoObrigatorio')),
        cdAgencia: Yup.string().required(formatMessage('agencia.campoObrigatorio')),
        estado: Yup.object()
          .nullable()
          .required(formatMessage('agencia.campoObrigatorio')),
        cidade: Yup.object()
          .nullable()
          .required(formatMessage('agencia.campoObrigatorio'))
      })}
      onSubmit={handleSubmitFormik}
      render={({ values, handleSubmit, errors, touched, handleBlur, handleChange, setFieldValue }) => (
        <MuiThemeProvider theme={theme}>
          {isLoading && <Loading />}

          <div style={{ width: '100%' }}>
            <CardIcon titulo={formatMessage('agencia.identificacao')}>
              <Grid container style={{ height: 70, marginTop: 30 }} spacing={16}>
                <Grid item xs={4}>
                  <TextField
                    label={formatMessage('agencia.nome')}
                    name='nmAgencia'
                    inputProps={{ maxLength: 200 }}
                    value={values.nmAgencia}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nmAgencia && touched.nmAgencia}
                    helperText={errors.nmAgencia && touched.nmAgencia ? errors.nmAgencia : null}
                  />
                </Grid>

                <Grid item xs={2} style={{ marginTop: -1 }}>
                  <TextField
                    label={formatMessage('agencia.codigo')}
                    name='cdAgencia'
                    type='number'
                    inputProps={{ maxLength: 10 }}
                    value={values.cdAgencia}
                    onChange={e => setFieldValue('cdAgencia', e.target.value)}
                    onBlur={handleBlur}
                    error={errors.cdAgencia && touched.cdAgencia}
                    helperText={errors.cdAgencia && touched.cdAgencia ? errors.cdAgencia : null}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Autocomplete
                    itens={estadoList}
                    campoOp='nmUf'
                    campoInput='nmUf'
                    campoChave='idUf'
                    label={formatMessage('agencia.estado')}
                    name='estado'
                    error={errors.estado && touched.estado}
                    helperText={touched.estado ? errors.estado : ''}
                    onChangeOwn={event => {
                      setFieldValue('cidade', '');
                      onChangeMunicipio(null);
                      setKeyCidade(Math.random());

                      if (event) {
                        setFieldValue('estado', event);
                      } else {
                        setFieldValue('estado', '');
                      }

                      onChangeEstado(event);
                    }}
                    key={keyEstado}
                    onChangeAutoComplete={inicializacaoAutoCompleteEstado}
                    value={tratarValorSelectInitialEstado(estado)}
                    valueAutoComplete={tratarValorSelectInitialEstado(estado)}
                    valueSelect={tratarValorSelectInitialEstado(estado).nmUf}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Autocomplete
                    disabled={!estado}
                    itens={cidadeList}
                    campoOp='nmMunicipio'
                    campoInput='nmMunicipio'
                    campoChave='idMunicipio'
                    label={formatMessage('agencia.cidade')}
                    name='cidade'
                    key={keyCidade}
                    error={errors.cidade && touched.cidade}
                    helperText={touched.cidade ? errors.cidade : ''}
                    onChangeOwn={event => {
                      setFieldValue('cidade', event);

                      onChangeMunicipio(event);
                    }}
                    onChangeAutoComplete={inicializacaoAutoCompleteMunicipio}
                    value={tratarValorSelectInitialMunicipio(municipio)}
                    valueAutoComplete={tratarValorSelectInitialMunicipio(municipio)}
                    valueSelect={tratarValorSelectInitialMunicipio(municipio).nmMunicipio}
                  />
                </Grid>

                <Grid item xs={2}>
                  <div className={classes.switch} onClick={() => setStAgencia(!stAgencia)}>
                    <Checkbox checked={stAgencia} value='stAgencia' color='primary' />
                    <label style={{ color: '#000000' }}>{formatMessage('agencia.ativo')}</label>
                  </div>
                </Grid>
              </Grid>
            </CardIcon>
          </div>

          <div className={classes.fab}>
            <ButtonFABMenu
              icon={iconMenuPontos}
              actions={[
                {
                  icon: <Check style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('agencia.salvar'),
                  onClickIcon: handleSubmit,
                  color: '#42ADE8'
                },
                {
                  icon: <Close style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('agencia.cancelar'),
                  onClickIcon: () => props.history.push('/app/agencia'),
                  color: '#F33A30'
                }
              ]}
            />
          </div>
        </MuiThemeProvider>
      )}
    />
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {*} values - Valores do submit
   */
  function handleSubmitFormik(values) {
    setLoading(true);
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(
          `agencia.${props.match.params.id === undefined ? 'salvando' : 'atualizando'}`
        )
      }
    );

    const agencia = {
      cdAgencia: values.cdAgencia,
      nmAgencia: values.nmAgencia,
      idMunicipio: values.cidade.idMunicipio,
      nmMunicipio: values.cidade.nmMunicipio,
      idUf: values.estado.idUf,
      sgUf: values.estado.sgUf,
      stAgencia: stAgencia ? 1 : 0
    };

    let request;
    if (props.match.params.id === undefined) {
      request = save(agencia);
    } else {
      agencia.idAgencia = props.match.params.id;
      request = update(agencia);
    }

    request
      .then(res => {
        swal(formatMessage('agencia.agenciaSalvoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
          }
        });

        props.history.push('/app/agencia');
      })
      .catch(err => {
        setLoading(false);
        if (err.response && err.response.data.codigo === 'AGENCIA_DUPLICADA') {
          errorMensagem(formatMessage('agencia.codigoExistente'));
          return;
        }

        errorMensagem(formatMessage('agencia.ocorreuErroSalvar'));
      });
  }

  function errorMensagem(title) {
    swal(formatMessage('agencia.erroAoSalvar'), title, {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarAgencia)));
