export default {
    comunidade: {
        descricao: 'Descrição',
        municipio:'Município',
        comunidades:'Comunidades',
        desejaExcluir: 'Deseja excluir a comunidade selecionada?',
        nao:'Não',
        excluir:'Excluir',
        comunidadeRemovidaComSucesso:'Comunidade removida com sucesso',
        carregando:'Aguarde...',
        falhaAoExcluirComunidade:'Error ao excluir comunidade',
        cadastroDeComunidades: 'Cadastro de comunidades',
        identificacaoDaComunidade : 'Identificação',
        campoObrigatorio: 'Campo obrigatório',
        comunidadeCadastradaComSucesso:'Comunidade cadastrada com sucesso',
        falhaAoCadastrarComunidade:'Falha ao cadastrar comunidade',
        comunidadeEditadaComSucesso:'Comunidade atualizada com sucesso',
        falhaAoEditarComunidade:'Falha ao editar comunidade',
        falhaAoCarregarComunidades:'Falha ao carregar comunidades',
        salvar:'Salvar',
        cancelar:'Cancelar',
        uf:'Estado',
        salvando: 'Salvando',
        atualizando: 'Atualizando',
        excluindo: 'Excluindo'
    }
}