import React, { useEffect, useState } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Switch from '@material-ui/core/Switch';
import { styles, theme } from './AnalieOGM.styles';
import AnaliseOgmAPI from '@resources/AnaliseOGM';
import formatMessage from './i18n/formatMessage';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Screen de cadastro de análise OGM
 */
function EditarAnaliseOGM(props) {
  const { classes, match, history, itensState } = props;
  const [itemSelect, setItemSelect] = useState({
    dsTipoAnaliseOgm: ''
  });
  const [stTipoAnaliseOgm, setStTipoAnaliseOgm] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const title = 'analiseOGM.cadastroDeAnaliseOGM';
    if (itensState.name !== title) {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(title),
        name: title
      });
    }

    iniciarAtributosEdicao();
  }, []);

  /**
   * Busca os valores iniciais em caso de edição
   */
  function iniciarAtributosEdicao() {
    if (match.params.id !== undefined) {
      setLoading(true);
      AnaliseOgmAPI.findAnaliseOGMById(match.params.id)
        .then(query => {
          setStTipoAnaliseOgm(query.data.stTipoAnaliseOgm === 1);
          setItemSelect(query.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          swalError(formatMessage('analiseOGM.falhaAoCarregarAnaliseOGM'));
        });
    }
  }

  /**
   * Swal de erro
   * @param {*} title
   */
  function swalError(title) {
    swal(title, {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  function clickCadastrarEditar(values) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(
          match.params.id === undefined ? 'analiseOGM.salvando' : 'analiseOGM.atualizando'
        )
      }
    );

    const data = {
      dsTipoAnaliseOgm: values.dsTipoAnaliseOgm,
      stTipoAnaliseOgm: stTipoAnaliseOgm ? 1 : 0
    };

    let editOrUpdate = null;
    let titleSucess = '';
    let titleError = '';

    if (match.params.id === undefined) {
      editOrUpdate = AnaliseOgmAPI.addAnaliseOGM(data);
      titleSucess = 'analiseOGMCadastradoComSucesso';
      titleError = 'falhaAoCadastrarAnaliseOGM';
    } else {
      data.idTipoAnaliseOgm = itemSelect.idTipoAnaliseOgm;

      editOrUpdate = AnaliseOgmAPI.editAnaliseOGM(data);
      titleSucess = 'analiseOGMEditadoComSucesso';
      titleError = 'falhaAoEditarAnaliseOGM';
    }

    editOrUpdate
      .then(() => {
        swal(formatMessage(`analiseOGM.${titleSucess}`), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        }).then(() => {
          clickCancelar();
        });
      })
      .catch(() => {
        swalError(formatMessage(`analiseOGM.${titleError}`));
      });
  }

  function clickCancelar() {
    history.push('/app/analise-ogm');
  }

  function checkSwitch() {
    setStTipoAnaliseOgm(!stTipoAnaliseOgm);
  }

  return (
    <Formik
      initialValues={{
        dsTipoAnaliseOgm: itemSelect.dsTipoAnaliseOgm,
        stTipoAnaliseOgm: itemSelect.stTipoAnaliseOgm
      }}
      onSubmit={clickCadastrarEditar}
      validateOnBlur
      enableReinitialize={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        dsTipoAnaliseOgm: Yup.string().required(formatMessage('analiseOGM.campoObrigatorio'))
      })}
      render={({ values, handleSubmit, errors, touched, handleBlur, handleChange }) => (
        <MuiThemeProvider theme={theme}>
          <div style={{ width: '100%' }}>
            <div className={classes.cardEditar}>
              {loading && <Loading />}
              <CardIcon titulo={formatMessage('analiseOGM.identificacao')}>
                <Grid container spacing={16} style={{ marginTop: 15 }}>
                  <Grid item xs={6}>
                    <TextField
                      label={formatMessage('analiseOGM.descricao')}
                      name='dsTipoAnaliseOgm'
                      value={values.dsTipoAnaliseOgm}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.dsTipoAnaliseOgm && touched.dsTipoAnaliseOgm}
                      helperText={
                        errors.dsTipoAnaliseOgm && touched.dsTipoAnaliseOgm
                          ? formatMessage('analiseOGM.campoObrigatorio')
                          : null
                      }
                      className={classes.font}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.check}>
                      <Switch color='primary' checked={stTipoAnaliseOgm} onChange={checkSwitch} />
                      <label style={{ color: '#000000' }} className={classes.font}>
                        {formatMessage('analiseOGM.ativo')}
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </CardIcon>
            </div>
            <div className={classes.fab}>
              <ButtonFABMenu
                icon={iconMenuPontos}
                actions={[
                  {
                    icon: <Check style={{ color: 'white' }} />,
                    name: formatMessage('analiseOGM.salvar'),
                    onClickIcon: handleSubmit,
                    color: '#42ADE8'
                  },
                  {
                    icon: <Close style={{ color: 'white' }} />,
                    name: formatMessage('analiseOGM.cancelar'),
                    onClickIcon: clickCancelar,
                    color: '#F33A30'
                  }
                ]}
              />
            </div>
          </div>
        </MuiThemeProvider>
      )}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(styles)(EditarAnaliseOGM);
export default withRouter(connect(mapStateToProps)(enhaced));
