export default {
  safras: {
    safras: 'Vintages',
    descricao: 'Description',
    desejaExcluir: 'Do you want to delete crop?',
    nao: 'No',
    excluir: 'Delete',
    excluindo: 'Excluding',
    falhaAoExcluirSafra: 'Failed to delete crop',
    safraRemovidoComSucesso: 'Crop removed successfully',
    safraCadastradaComSucesso: 'Crop registered successfully',
    safraEditadaComSucesso: 'Successfully edited crop',
    falhaAoEditarSafra: 'Failed to edit crop',
    falhaAoCadastrarSafra: 'Failed to register crop',
    falhaAoCarregarSafra: 'Failed to load crop',
    salvar: 'Save',
    atualizar: 'Update',
    cancelar: 'Cancel',
    salvando: 'Saving',
    atualizando: 'Updating',
    cadastroDeSafra: 'Crop Registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    ativo: 'Active',
    situacao: 'Situation',
    inativo: 'Inactive',
    cultura: 'Culture',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    safraCadastradaPeriodo: 'There is a crop registered in this period',
    minimoCulturas: 'Add at least 1 culture'
  }
};
