import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import { Grid, Checkbox } from '@material-ui/core';
import DatePicker from '@components/DatePicker/DatePicker';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import formatMessage from './i18n/formatMessage';
import {
  findNaoConformidadeById,
  addNaoConformidade,
  editNaoConformidade
} from '@resources/NaoConformidade';
import { style, theme } from './NaoConformidade.styles';
import { getUserLang } from '@utils/localeUtils';
import moment from 'moment';
import Sancao from './Sancao/Sancao';
import EditarSancao from './Sancao/EditarSancao';
import { Add, Edit } from '@material-ui/icons';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import { findAllSancaoCategorias } from '@resources/SancaoCategoria';
import { findAllCertificacoes } from '@resources/CertificacaoOrganica';
import { findAllMedidaCorretivas } from '@resources/MedidaCorretiva';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Screen de edição ou cadastro de uma sanção de categoria
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarNaoConformidade(props) {
  const { classes } = props;

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [stNaoConformidade, setStNaoConformidade] = useState(true);
  const [naoConformidadeSancaoList, setNaoConformidadeSancaoList] = useState([]);
  const [sancaoCategoriaList, setSancaoCategoriaList] = useState([]);
  const [tipoCertificacaoList, setTipoCertificacaoList] = useState([]);
  const [medidaCorretivaList, setMedidaCorretivaList] = useState([]);
  const [index, setIndex] = useState(0);
  const [select, setSelect] = useState({
    sancaoCategoria: null,
    tipoCertificacao: null,
    sancaoMedidaCorretivaList: []
  });
  const [initialValues, setInitialValues] = useState({
    nmNaoConformidade: '',
    dsNaoConformidade: '',
    dhInicio: null,
    dhTermino: null
  });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const title = 'naoConformidade.cadastroDeNaoConformidade';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    findAllSancaoCategorias().then(res => {
      setSancaoCategoriaList(res.data);
    });

    findAllCertificacoes().then(res => {
      const tipoCertificacaoListAux = res.data;
      tipoCertificacaoListAux.unshift({
        idTipoCertificacao: -99,
        nmTipoCertificacao: formatMessage('naoConformidade.geral')
      });
      setTipoCertificacaoList(tipoCertificacaoListAux);
    });

    findAllMedidaCorretivas().then(res => {
      setMedidaCorretivaList(res.data);
    });

    if (props.match.params.id !== undefined) {
      setEnableReinitialize(true);
    }
  }, []);

  useEffect(() => {
    if (!enableReinitialize) return;
    setLoading(true);

    findNaoConformidadeById(props.match.params.id)
      .then(res => {
        const initialValuesAux = res.data;
        setStNaoConformidade(initialValuesAux.stNaoConformidade === 1);
        if (!initialValuesAux.dhTermino) {
          initialValuesAux.dhTermino = null;
        }

        const naoConformidadeSancaoListAux = initialValuesAux.naoConformidadeSancaoList;
        naoConformidadeSancaoListAux.forEach(naoConformidadeSancao => {
          if (!naoConformidadeSancao.tipoCertificacao) {
            naoConformidadeSancao.tipoCertificacao = {
              idTipoCertificacao: -99,
              nmTipoCertificacao: formatMessage('naoConformidade.geral')
            };
          }
        });

        setNaoConformidadeSancaoList(naoConformidadeSancaoListAux);
        setNaoConformidadeSancaoList(naoConformidadeSancaoListAux);
        setInitialValues(initialValuesAux);
      })
      .catch(err =>
        swal(formatMessage('naoConformidade.erroAoBuscarDados'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        })
      )
      .finally(() => {
        setEnableReinitialize(false);
        setLoading(false);
      });
  }, [enableReinitialize]);

  return (
    <SwipeableViewsOwn index={index} onChangeIndex={index => setIndex(index)}>
      {[
        <div className={classes.tab} key={0}>
          <Formik
            initialValues={initialValues}
            validateOnBlur
            enableReinitialize={enableReinitialize}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              nmNaoConformidade: Yup.string().required(
                formatMessage('naoConformidade.campoObrigatorio')
              ),
              dsNaoConformidade: Yup.string().required(
                formatMessage('naoConformidade.campoObrigatorio')
              ),
              dhInicio: Yup.string()
                .nullable()
                .required(formatMessage('naoConformidade.campoObrigatorio')),
              dhTermino: Yup.string().nullable()
            })}
            onSubmit={handleSubmitFormik}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue
            }) => (
              <MuiThemeProvider theme={theme}>
                <div className={classes.allCard}>
                  {isLoading && <Loading />}

                  <div style={{ width: '100%' }}>
                    <CardIcon titulo={formatMessage('naoConformidade.identificacao')}>
                      <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                        <Grid item xs={8}>
                          <TextField
                            label={formatMessage('naoConformidade.nome')}
                            name='nmNaoConformidade'
                            inputProps={{ maxLength: 60 }}
                            value={values.nmNaoConformidade}
                            onChange={handleChange}
                            onBlur={e => {
                              setFieldValue('nmNaoConformidade', e.target.value);
                              initialValues.nmNaoConformidade = e.target.value;
                            }}
                            error={errors.nmNaoConformidade && touched.nmNaoConformidade}
                            helperText={
                              errors.nmNaoConformidade && touched.nmNaoConformidade
                                ? errors.nmNaoConformidade
                                : null
                            }
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <DatePicker
                            locale={getUserLang()}
                            style={{ height: 70 }}
                            className={classes.font}
                            label={formatMessage('naoConformidade.dataInicial')}
                            valueDate={values.dhInicio}
                            errorTouched={errors.dhInicio && touched.dhInicio}
                            helperText={errors.dhInicio && touched.dhInicio ? errors.dhInicio : null}
                            onChangeDatePicker={value => {
                              setFieldValue('dhInicio', value);
                              setFieldValue('dhTermino', null);
                              initialValues.dhInicio = value;
                            }}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <DatePicker
                            minDate={values.dhInicio ? values.dhInicio : '2100-01-01'}
                            locale={getUserLang()}
                            style={{ height: 70 }}
                            className={classes.font}
                            label={formatMessage('naoConformidade.dataFinal')}
                            valueDate={values.dhTermino}
                            errorTouched={errors.dhTermino && touched.dhTermino}
                            helperText={errors.dhTermino && touched.dhTermino ? errors.dhTermino : null}
                            onChangeDatePicker={value => {
                              setFieldValue('dhTermino', value);
                              initialValues.dhTermino = value;
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                        <Grid item xs={8}>
                          <TextField
                            label={formatMessage('naoConformidade.descricao')}
                            name='dsNaoConformidade'
                            inputProps={{ maxLength: 200 }}
                            value={values.dsNaoConformidade}
                            onChange={handleChange}
                            onBlur={e => {
                              setFieldValue('dsNaoConformidade', e.target.value);
                              initialValues.dsNaoConformidade = e.target.value;
                            }}
                            error={errors.dsNaoConformidade && touched.dsNaoConformidade}
                            helperText={
                              errors.dsNaoConformidade && touched.dsNaoConformidade
                                ? errors.dsNaoConformidade
                                : null
                            }
                          />
                        </Grid>

                        <Grid item xs={1}>
                          <div
                            className={classes.switch}
                            onClick={() => setStNaoConformidade(!stNaoConformidade)}
                          >
                            <Checkbox
                              checked={stNaoConformidade}
                              value='stNaoConformidade'
                              color='primary'
                            />
                            <label style={{ color: '#000000' }}>
                              {formatMessage('naoConformidade.status')}
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </CardIcon>
                  </div>

                  <div style={{ width: '100%', marginTop: 30 }}>
                    <CardIcon titulo={formatMessage('naoConformidade.sancao')}>
                      <div className={classes.cardOutsideButtom}>
                        <div className={classes.cardButtom} onClick={() => setIndex(1)}>
                          <Add />
                          {formatMessage('naoConformidade.cadastrarSancao')}
                        </div>
                      </div>

                      {naoConformidadeSancaoList.map(naoConformidadeSancao => (
                        <div
                          key={naoConformidadeSancao.idNaoConformidadeSancao}
                          className={classes.cardEdit}
                        >
                          <Sancao naoConformidadeSancao={naoConformidadeSancao} />
                          <div style={{ marginTop: 30 }}>
                            <div
                              style={{ backgroundColor: '#463766', width: 100 }}
                              className={classes.cardButtom}
                              onClick={() => {
                                setSelect(naoConformidadeSancao);
                                setIndex(1);
                              }}
                            >
                              <Edit className={classes.iconEdit} />
                              {formatMessage('naoConformidade.editar')}
                            </div>
                          </div>

                          {naoConformidadeSancaoList.length > 1 && <div className={classes.boxShadow} />}
                        </div>
                      ))}
                    </CardIcon>
                  </div>

                  <div className={classes.fab}>
                    <ButtonFABMenu
                      icon={iconMenuPontos}
                      actions={[
                        {
                          icon: <Check style={{ color: '#FFFFFF' }} />,
                          name: formatMessage('naoConformidade.salvar'),
                          onClickIcon: handleSubmit,
                          color: '#42ADE8'
                        },
                        {
                          icon: <Close style={{ color: '#FFFFFF' }} />,
                          name: formatMessage('naoConformidade.cancelar'),
                          onClickIcon: () => props.history.push('/app/nao-conformidade'),
                          color: '#F33A30'
                        }
                      ]}
                    />
                  </div>
                </div>
              </MuiThemeProvider>
            )}
          />
        </div>,
        <div className={classes.tab} key={1}>
          <EditarSancao
            swalWarning={swalWarning}
            sancaoCategoriaList={sancaoCategoriaList}
            tipoCertificacaoList={tipoCertificacaoList}
            initialValues={select}
            medidaCorretivaList={medidaCorretivaList}
            clickToCancel={clearSelect}
            clickSubmit={clickSaveSancao}
          />
        </div>
      ]}
    </SwipeableViewsOwn>
  );

  function clearSelect() {
    setIndex(0);
    setSelect({
      sancaoCategoria: null,
      tipoCertificacao: null,
      sancaoMedidaCorretivaList: []
    });
  }

  function swalWarning(title, buttonOk, buttonCancel) {
    const optionConfirm = {
      text: formatMessage(buttonOk),
      value: true,
      visible: true,
      closeModal: false,
      className: buttonCancel ? 'swal2-confirm' : 'swal2-cancel'
    };

    let options = {
      confirm: optionConfirm
    };

    if (buttonCancel) {
      const optionCancel = {
        text: formatMessage(buttonCancel),
        value: false,
        visible: true,
        closeModal: true,
        className: 'swal2-cancel'
      };

      options = {
        cancel: optionCancel,
        confirm: optionConfirm
      };
    }

    return swal({
      title: formatMessage(title),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: options
    });
  }

  function clickSaveSancao(values) {
    const naoConformidadeSancaoListAux = [...naoConformidadeSancaoList];
    const certificacaoGeral = naoConformidadeSancaoListAux.find(
      naoConformidadeSancao => naoConformidadeSancao.tipoCertificacao.idTipoCertificacao === -99
    );

    const certificacaoGeralExists =
      certificacaoGeral &&
      values.sancaoCategoria.idSancaoCategoria === certificacaoGeral.sancaoCategoria.idSancaoCategoria &&
      values.idNaoConformidadeSancao !== certificacaoGeral.idNaoConformidadeSancao;

    const otherCertificacao = naoConformidadeSancaoListAux.find(
      naoConformidadeSancao =>
        naoConformidadeSancao.sancaoCategoria.idSancaoCategoria === values.sancaoCategoria.idSancaoCategoria
    );

    const otherCertificacaoExists =
      otherCertificacao && values.tipoCertificacao.idTipoCertificacao === -99 &&
      values.idNaoConformidadeSancao !== otherCertificacao.idNaoConformidadeSancao;

    if (certificacaoGeralExists || otherCertificacaoExists) {
      swalWarning('naoConformidade.cadastroGeralExistente', 'naoConformidade.ok').then(res => {
        swal.close();
      });
      return;
    }

    if (values.idNaoConformidadeSancao) {
      const naoConformidadeSancao = naoConformidadeSancaoListAux.find(
        agencia => agencia.idNaoConformidadeSancao === values.idNaoConformidadeSancao
      );
      const position = naoConformidadeSancaoListAux.indexOf(naoConformidadeSancao);
      naoConformidadeSancaoListAux[position] = values;
    } else {
      values.stRegistro = 0;
      values.idNaoConformidadeSancao = Math.random();

      naoConformidadeSancaoListAux.push(values);
    }

    setNaoConformidadeSancaoList(naoConformidadeSancaoListAux);
    clearSelect();
  }

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {*} values - Valores do submit
   */
  function handleSubmitFormik(values) {
    setLoading(true);
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(
          `naoConformidade.${props.match.params.id === undefined ? 'salvando' : 'atualizando'}`
        )
      }
    );

    values.idNaoConformidade = +props.match.params.id || null;
    values.dhInicio = moment(values.dhInicio).format('YYYY-MM-DDTHH:mm:ssZZ');
    if (values.dhTermino) {
      values.dhTermino = moment(values.dhTermino).format('YYYY-MM-DDTHH:mm:ssZZ');
    }
    values.stNaoConformidade = stNaoConformidade ? 1 : 0;
    naoConformidadeSancaoList.forEach(naoConformidadeSancao => {
      if (naoConformidadeSancao.stRegistro === 0) {
        delete naoConformidadeSancao.idNaoConformidadeSancao;
      }

      naoConformidadeSancao.sancaoMedidaCorretivaList.forEach(sancaoMedidaCorretiva => {
        if (sancaoMedidaCorretiva.stRegistro === 0) {
          delete sancaoMedidaCorretiva.idSancaoMedidaCorretiva;
        }
      });
    });

    naoConformidadeSancaoList.forEach(naoConformidadeSancao => {
      if (naoConformidadeSancao.tipoCertificacao.idTipoCertificacao === -99) {
        naoConformidadeSancao.tipoCertificacao = null;
      }
    });

    values.naoConformidadeSancaoList = naoConformidadeSancaoList;

    let request;
    if (props.match.params.id === undefined) {
      request = addNaoConformidade(values);
    } else {
      request = editNaoConformidade(values);
    }

    request
      .then(res => {
        swal(formatMessage('naoConformidade.naoConformidadeSalvoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
          }
        });

        props.history.push('/app/nao-conformidade');
      })
      .catch(err => {
        swal(formatMessage('naoConformidade.erroAoSalvar'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });

        setLoading(false);
      });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarNaoConformidade)));
