import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Grid from '@material-ui/core/Grid';
import { FastField } from 'formik';
import formatMessage from './../i18n/formatMessage';

const style = theme => ({
  cadastro: {
    marginTop: '15px'
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  }
});

/**
 * Componente que representa o Card `Infraestrutura` na tab `Identificação`
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
const Infraestrutura = props => {
  const { classes } = props;

  return (
    <form autoComplete='off'>
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <FastField name='dsRelacaoPredial'>
            {({ field, form: { touched, errors } }) => (
              <TextField
                {...field}
                id='dsRelacaoPredial'
                label={formatMessage('produtores.relacaoPredial')}
                className={classes.textField}
                error={errors.dsRelacaoPredial && touched.dsRelacaoPredial}
                helperText={touched.dsRelacaoPredial ? errors.dsRelacaoPredial : ''}
              />
            )}
          </FastField>
        </Grid>

        <Grid item xs={6}>
          <FastField name='dsLocalArmazenamentoInsumos'>
            {({ field, form: { touched, errors } }) => (
              <TextField
                {...field}
                id='dsLocalArmazenamentoInsumos'
                label={formatMessage('produtores.localArmazenamentoInsumos')}
                className={classes.textField}
                error={errors.dsLocalArmazenamentoInsumos && touched.dsLocalArmazenamentoInsumos}
                helperText={
                  touched.dsLocalArmazenamentoInsumos ? errors.dsLocalArmazenamentoInsumos : ''
                }
              />
            )}
          </FastField>
        </Grid>
      </Grid>

      <Grid container spacing={16} className={classes.cadastro}>
        <Grid item xs={6}>
          <FastField name='dsMaquinarioUtensilios'>
            {({ field, form: { touched, errors } }) => (
              <TextField
                {...field}
                id='dsMaquinarioUtensilios'
                label={formatMessage('produtores.maquinarioUtensilios')}
                className={classes.textField}
                error={errors.dsMaquinarioUtensilios && touched.dsMaquinarioUtensilios}
                helperText={touched.dsMaquinarioUtensilios ? errors.dsMaquinarioUtensilios : ''}
              />
            )}
          </FastField>
        </Grid>

        <Grid item xs={6}>
          <FastField name='dsAtividadesDesenvolvidas'>
            {({ field, form: { touched, errors } }) => (
              <TextField
                {...field}
                id='dsAtividadesDesenvolvidas'
                label={formatMessage('produtores.atividadesDesenvolvidas')}
                className={classes.textField}
                error={errors.dsAtividadesDesenvolvidas && touched.dsAtividadesDesenvolvidas}
                helperText={touched.dsAtividadesDesenvolvidas ? errors.dsAtividadesDesenvolvidas : ''}
              />
            )}
          </FastField>
        </Grid>
      </Grid>
    </form>
  );
};

export default withStyles(style)(Infraestrutura);
