export default {
  formaAplicacao: {
    formaAplicacao: 'Application form',
    nao: 'Not',
    desejaExcluir: 'Do you want to delete?',
    excluir: 'Delete',
    formaAplicacaoRemovidoComSucesso: 'Application form successfully removed',
    falhaAoExcluirFormaAplicacao: 'Failed to delete application form',
    excluindo: 'Excluding',
    cultura: 'Culture',
    tipo: 'Type',
    situacao: 'Situation',
    nome: 'Name',
    formaAplicacaoCadastradoComSucesso: 'Application form successfully registered',
    formaAplicacaoEditadoComSucesso: 'Application form successfully edited',
    falhaAoCadastrarFormaAplicacao: 'Failed to register application form',
    falhaAoEditarFormaAplicacao: 'Failed to edit application form',
    falhaAoCarregarFormaAplicacao: 'Failed to load application form',
    salvar: 'Save',
    cancelar: 'Cancel',
    salvando: 'Saving',
    atualizando: 'Updating',
    cadastroDeFormaAplicacao: 'Application form registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    doencaSolo: 'Soil disease',
    doencaFoliar: 'Leaf disease',
    nematoide: 'Nematode',
    descricao: 'Description',
    ativo: 'Active',
    inativo: 'Inactive',
    pragas: 'Pests'
  }
};
