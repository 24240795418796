import React from 'react';
import { Formik } from 'formik';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import DatePicker from '@components/DatePicker/DatePicker';
import * as moment from 'moment';
import { getUserLang } from '@utils/localeUtils';
import TimePicker from '@components/TimePicker/TimePicker';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const agendarVisita = messagesLinguage['evento.agendarVisita'];
const data = messagesLinguage['evento.data'];
const horario = messagesLinguage['evento.horario'];
const produtor = messagesLinguage['evento.produtor'];
const observacao = messagesLinguage['evento.observacao'];
const cancelar = messagesLinguage['evento.cancelar'];
const confirmar = messagesLinguage['evento.confirmar'];
const excluir = messagesLinguage['evento.excluir'];
const horarioFinal = messagesLinguage['evento.horarioFinal'];
const horarioInvalido = messagesLinguage['evento.horarioInvalido'];
const tecnico = messagesLinguage['evento.tecnico'];
const visitaRealizada = messagesLinguage['evento.visitaRealizada'];
const resumoVisita = messagesLinguage['evento.resumoVisita'];
const campoObrigatorio = messagesLinguage['evento.campoObrigatorio'];
const voltar = messagesLinguage['evento.voltar'];
const responsavel = messagesLinguage['evento.responsavel'];
const gerente = messagesLinguage['evento.gerente'];

const intlProvider = new IntlProvider(
  {
    locale: getUserLang(),
    messages: {
      agendarVisita,
      data,
      horario,
      produtor,
      observacao,
      cancelar,
      confirmar,
      campoObrigatorio,
      excluir,
      resumoVisita,
      horarioFinal,
      horarioInvalido,
      visitaRealizada,
      tecnico,
      voltar,
      responsavel,
      gerente
    }
  },
  {}
);

provider = intlProvider.getChildContext();

function Evento(props) {
  const {
    title,
    acompanhamentoTecnico,
    check,
    keyAutoComplete,
    produtores,
    typeGerente,
    classes,
    openModal,
    statusEditar,
    initialData,
    itemSelect,
    typeProdutor,
    onChangeOwn,
    value,
    valueAutoCompleteTratado,
    valueSelect,
    onSubmit,
    clickExcluir,
    onClose,
    onChangeDatePicker,
    valueAutoComplete,
    onChangeAutoComplete,
    onChangeCheck,
    tpPerfil,
    infoUsuario,
    typeAuditoria
  } = props;

  return (
    <div
      style={{
        marginTop: '15px',
        width: '100%',
        flex: 1
      }}
      key={1}
    >
      {openModal && (
        <Formik
          initialValues={
            statusEditar
              ? {
                  data: moment(itemSelect.dtVisita),
                  horario: moment(itemSelect.dtVisita + 'T' + itemSelect.hrInicio),
                  horarioFinal: moment(itemSelect.dtVisita + 'T' + itemSelect.hrTermino),
                  produtor: itemSelect.produtor,
                  observacao: itemSelect.observacao || '',
                  dsResultadoVisita: itemSelect.dsResultadoVisita || '',
                  visitaRealizada: false
                }
              : {
                  data: initialData,
                  horario: null,
                  horarioFinal: null,
                  produtor: typeAuditoria ? itemSelect.produtor : '',
                  dsResultadoVisita: '',
                  observacao: typeAuditoria ? itemSelect.observacao : '',
                  visitaRealizada: false
                }
          }
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            data: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
            produtor: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
            horario: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
            horarioFinal: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
          })}
          render={({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldError
          }) => (
            <div
              style={{
                width: '100%',
                flex: 1,
                height: '100%',
                display: 'flex',
                marginTop: -15
              }}
            >
              <CardCadastro
                titulo={title || provider.intl.formatMessage({ id: 'agendarVisita' })}
                botoes={[
                  !typeProdutor && !typeGerente && (
                    <Button
                      style={{
                        display: itemSelect.stAgenda === 0 ? 'flex' : 'none'
                      }}
                      color='primary'
                      variant='contained'
                      onClick={clickExcluir}
                    >
                      {provider.intl.formatMessage({ id: 'excluir' })}
                    </Button>
                  ),
                  <Button style={{ width: 100 }} color='inherit' variant='contained' onClick={onClose}>
                    {provider.intl.formatMessage({ id: acompanhamentoTecnico ? 'voltar' : 'cancelar' })}
                  </Button>,
                  ((!typeProdutor && !typeGerente) || (itemSelect.stAgenda === 2 && typeGerente)) && (
                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={
                        values.horario < values.horarioFinal
                          ? handleSubmit
                          : () => {
                              setFieldError('data', values.data === null);
                              setFieldError('produtor', values.produtor === '');
                              setFieldError('horario', values.horario === null);
                            }
                      }
                    >
                      {provider.intl.formatMessage({ id: 'confirmar' })}
                    </Button>
                  )
                ]}
              >
                <form autoComplete='off'>
                  <Grid container spacing={16}>
                    <Grid item xs={2}>
                      <DatePicker
                        disabled={
                          (itemSelect.stAgenda >= 1 || typeProdutor || typeGerente) &&
                          !(itemSelect.stAgenda === 2 && typeGerente)
                        }
                        locale={getUserLang()}
                        style={{ height: 70 }}
                        className={classes.font}
                        label={provider.intl.formatMessage({
                          id: 'data'
                        })}
                        valueDate={values.data}
                        errorTouched={errors.data !== undefined && errors.data !== false}
                        helperText={provider.intl.formatMessage({
                          id: 'campoObrigatorio'
                        })}
                        onBlurDatePicker={date => {
                          let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                          if (!validation) {
                            setFieldValue('data', null);
                          }
                        }}
                        onChangeDatePicker={date => {
                          if (date > moment()) {
                            onChangeDatePicker();
                            setFieldValue('dsResultadoVisita', '');
                          }
                          setFieldValue('data', date);
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TimePicker
                        disabled={
                          (itemSelect.stAgenda >= 1 || typeProdutor || typeGerente) &&
                          !(itemSelect.stAgenda === 2 && typeGerente)
                        }
                        style={{ height: 70 }}
                        label={provider.intl.formatMessage({
                          id: 'horario'
                        })}
                        value={values.horario}
                        errorTouched={errors.horario}
                        helperText={provider.intl.formatMessage({
                          id: 'campoObrigatorio'
                        })}
                        onBlur={() => {
                          setFieldValue('horario', null);
                        }}
                        onChange={horario => {
                          const date = moment(itemSelect.dtVisita).format('YYYY-MM-DD');

                          setFieldValue('horario', horario);
                          var validation = moment(date).set({
                            hour: '12',
                            minute: '00'
                          });
                          var validationLast = moment(date).set({
                            hour: '18',
                            minute: '00'
                          });
                          errors.horarioFinal = false;
                          touched.horarioFinal = false;

                          if (horario >= validationLast) {
                            setFieldValue(
                              'horarioFinal',
                              moment(date).set({
                                hour: '23',
                                minute: '59'
                              })
                            );
                          } else if (horario > validation) {
                            setFieldValue(
                              'horarioFinal',
                              moment(date).set({
                                hour: '18',
                                minute: '00'
                              })
                            );
                          } else {
                            setFieldValue('horarioFinal', validation);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={2} style={{ height: 70 }}>
                      <TimePicker
                        disabled={
                          (!values.horario || itemSelect.stAgenda >= 1 || typeProdutor || typeGerente) &&
                          !(itemSelect.stAgenda === 2 && typeGerente)
                        }
                        label={provider.intl.formatMessage({
                          id: 'horarioFinal'
                        })}
                        value={values.horarioFinal}
                        errorTouched={errors.horarioFinal}
                        helperText={
                          values.horarioFinal < values.horario
                            ? provider.intl.formatMessage({
                                id: 'horarioInvalido'
                              })
                            : provider.intl.formatMessage({
                                id: 'campoObrigatorio'
                              })
                        }
                        onBlur={() => {
                          setFieldValue('horarioFinal', null);
                        }}
                        onChange={horario => {
                          setFieldValue('horarioFinal', horario);

                          errors.horarioFinal = horario < values.horario;
                          touched.horarioFinal = horario < values.horario;
                        }}
                      />
                    </Grid>

                    {acompanhamentoTecnico || typeAuditoria ? (
                      <Grid item xs={4}>
                        <TextField
                          disabled={true}
                          label={provider.intl.formatMessage({
                            id: 'produtor'
                          })}
                          value={itemSelect.produtor ? itemSelect.produtor.nmProdutor : ''}
                          className={classes.textField}
                          InputLabelProps={{ className: classes.font }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={4}>
                        {tpPerfil ? (
                          <TextField
                            disabled={true}
                            label={provider.intl.formatMessage({
                              id: 'tecnico'
                            })}
                            value={itemSelect.produtor.tecnico.nmUsuario}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                          />
                        ) : (
                          <Autocomplete
                            disabled={
                              (itemSelect.stAgenda >= 1 || typeProdutor || typeGerente) &&
                              !(itemSelect.stAgenda === 2 && typeGerente)
                            }
                            itens={produtores}
                            campoOp={'nmProdutor'}
                            campoChave={'idProdutor'}
                            label={provider.intl.formatMessage({
                              id: 'produtor'
                            })}
                            name='produtor'
                            error={valueAutoComplete === '' && errors.produtor}
                            helperText={
                              errors.produtor && valueAutoComplete === ''
                                ? provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                : null
                            }
                            onChangeOwn={event => {
                              setFieldValue('produtor', event);
                              onChangeOwn(event);
                            }}
                            onChangeAutoComplete={onChangeAutoComplete}
                            value={value}
                            key={keyAutoComplete}
                            valueAutoComplete={valueAutoCompleteTratado}
                            valueSelect={valueSelect}
                          />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={
                              typeAuditoria ||
                              itemSelect.stAgenda >= 1 ||
                              values.data > moment() ||
                              values.data === null ||
                              typeProdutor ||
                              typeGerente
                            }
                            checked={check}
                            name='check'
                            onChange={() => {
                              setFieldValue('dsResultadoVisita', '');
                              onChangeCheck();
                            }}
                            value='visitaRealizada'
                            color='primary'
                          />
                        }
                        label={provider.intl.formatMessage({
                          id: 'visitaRealizada'
                        })}
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: '100%',
                          marginTop: 5
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item xs={statusEditar && infoUsuario && infoUsuario.tpPerfil !== 2 ? 9 : 12}>
                      <TextField
                        disabled={
                          (itemSelect.stAgenda >= 1 || typeProdutor || typeGerente) &&
                          !(itemSelect.stAgenda === 2 && typeGerente)
                        }
                        id='observacao'
                        name='observacao'
                        onBlur={value => {
                          setFieldValue('observacao', value.target.value);
                        }}
                        onChange={value => {
                          setFieldValue('observacao', value.target.value);
                        }}
                        value={values.observacao}
                        label={provider.intl.formatMessage({
                          id: 'observacao'
                        })}
                        className={classes.textField}
                        InputLabelProps={{ className: classes.font }}
                      />
                    </Grid>

                    {statusEditar && infoUsuario && infoUsuario.tpPerfil !== 2 && (
                      <Grid item xs={3}>
                        <TextField
                          disabled={true}
                          label={provider.intl.formatMessage({
                            id: 'responsavel'
                          })}
                          value={mountLabel()}
                          className={classes.textField}
                          InputLabelProps={{ className: classes.font }}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item xs={12}>
                      <TextField
                        disabled={
                          (typeProdutor || typeGerente) && !(itemSelect.stAgenda === 2 && typeGerente)
                        }
                        id='dsResultadoVisita'
                        name='dsResultadoVisita'
                        onBlur={value => {
                          setFieldValue('dsResultadoVisita', value.target.value);
                        }}
                        style={
                          check || itemSelect.stAgenda >= 1
                            ? {
                                transition: 'opacity 0.1s linear',
                                transitionDelay: '0.1s',
                                opacity: 1
                              }
                            : {
                                transition: 'opacity 0.1s linear',
                                opacity: 0
                              }
                        }
                        onChange={value => {
                          setFieldValue('dsResultadoVisita', value.target.value);
                        }}
                        value={values.dsResultadoVisita}
                        label={provider.intl.formatMessage({
                          id: 'resumoVisita'
                        })}
                        className={classes.textField}
                        InputLabelProps={{ className: classes.font }}
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardCadastro>
            </div>
          )}
        />
      )}
    </div>
  );

  function mountLabel() {
    if (!itemSelect.tecnico) {
      return '';
    }

    let labelResposavel = itemSelect.tecnico.nmUsuario;

    if (itemSelect.tecnico.tpPerfil === 1) {
      labelResposavel =
        provider.intl.formatMessage({
          id: 'gerente'
        }) +
        ' - ' +
        labelResposavel;
    } else {
      labelResposavel =
        provider.intl.formatMessage({
          id: 'tecnico'
        }) +
        ' - ' +
        labelResposavel;
    }

    return labelResposavel;
  }
}

export default Evento;
