export default {
  visitas: {
    relatorio: 'Report',
    relatorioVisitas: 'Visit report',
    regiao: 'Region',
    municipio: 'City',
    sim: 'Yes',
    nao: 'Not',
    carregando: 'Loading...',
    gerandoRelatorio: 'Generating report ...',
    falhaAoGerarRelatorio: 'Failed to generate report',
    dataLancamento: 'Release date',
    tecnicoResponsavel: 'Responsible technician',
    produtor: 'Producer',
    uf: 'UF',
    decrescente: 'Decreasing',
    crescente: 'Crescent',
    media: 'Average',
    minimoFiltro: 'Apply at least one filter',
    mensagemSemFiltro: 'Without filters, can report generation take a long time, continue anyway?',
    estado: 'State',
    comunidade: 'Community',
    safra: 'Vintage',
    cultura: 'Culture',
    periodo: 'Period',
    baixarCSV: 'Download CSV',
    tecnicos: 'Technician'
  }
};
