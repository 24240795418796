import React from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { getUserLang } from '@utils/localeUtils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import DatePicker from '@components/DatePicker/DatePicker';
import moment from 'moment';
import Card from '@components/Card/Card';
import formatMessage from '../i18n/formatMessage';
import { style, theme } from './Temporario.styles';
import Button from '@material-ui/core/Button';

function EditarTemporarios(props) {
  const { classes } = props;

  function onSave(values) {
    values.dtMovimento = moment(values.dtMovimento).format('YYYY-MM-DD');

    if (props.atualizando) {
      values.stRegistro = props.temporario.stRegistro;
      const position = props.data.findIndex(
        item => item.idColaboradorTemporario === values.idColaboradorTemporario
      );
      props.onSave(values, position);
    } else {
      values.stRegistro = 0;
      props.onSave(values);
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Formik
        initialValues={props.temporario}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={onSave}
        validationSchema={Yup.object().shape({
          nrQuantidade: Yup.string()
            .nullable()
            .required(formatMessage('produtores.campoObrigatorio')),
          dtMovimento: Yup.string()
            .nullable()
            .required(formatMessage('produtores.campoObrigatorio'))
        })}
        render={({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit }) => {
          return (
            <Card
              withOutBorderRadius
              title={formatMessage(
                props.atualizando ? 'produtores.edicaoColaborador' : 'produtores.cadastroColaborador'
              )}
              height='300px'
              style={{ marginBottom: 35 }}
            >
              <form autoComplete='off'>
                <Grid container spacing={16}>
                  <Grid item xs={2}>
                    <DatePicker
                      locale={getUserLang()}
                      className={classes.textField}
                      label={formatMessage('produtores.data')}
                      maxDate={moment()}
                      valueDate={values.dtMovimento}
                      onBlurDatePicker={e => {
                        const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                        !validation && setFieldValue('dtMovimento', null);
                        handleBlur('dtMovimento')(e);
                      }}
                      onChangeDatePicker={value => {
                        setFieldValue('dtMovimento', value);
                      }}
                      errorTouched={
                        errors.dtMovimento && touched.dtMovimento ? touched.dtMovimento : false
                      }
                      helperText={errors.dtMovimento && touched.dtMovimento ? errors.dtMovimento : false}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      id='nrQuantidade'
                      name='nrQuantidade'
                      type='number'
                      onBlur={handleBlur}
                      onChange={e =>
                        setFieldValue('nrQuantidade', e.target.value.replace(',', '').replace('.', ''))
                      }
                      inputProps={{
                        maxLength: 100
                      }}
                      value={values.nrQuantidade}
                      label={formatMessage('produtores.nrQuantidade')}
                      className={classes.textField}
                      InputLabelProps={{ className: classes.font }}
                      error={errors.nrQuantidade && touched.nrQuantidade}
                      helperText={touched.nrQuantidade ? errors.nrQuantidade : ''}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs className={classes.containerButtons}>
                    <div style={{ color: '#463766' }}>
                      <Button variant='contained' color='inherit' onClick={props.onCancel}>
                        {formatMessage('produtores.cancelar')}
                      </Button>
                    </div>

                    <div>
                      <Button color='secondary' variant='contained' onClick={handleSubmit}>
                        {formatMessage('produtores.salvar')}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Card>
          );
        }}
      />
    </MuiThemeProvider>
  );
}

export default withStyles(style)(EditarTemporarios);
