import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CadastrarInsumoRecord from '@components/InsumoRecord/CadastrarInsumoRecord';
import formatMessage from './i18n/formatMessage';
import Insumo from '@resources/InsumosEnum';
import { withRouter } from 'react-router-dom';

/**
 * Tela para cadastro de agentes biologicos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarAgentesBiologicos(props) {
  useEffect(() => {
    const title = 'agentesControle.cadastroDaAgentesControle';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }
  }, []);

  return (
    <CadastrarInsumoRecord
      id={props.match.params.id}
      insumoURL={'agentes-biologicos-controle'}
      insumoEnum={Insumo.AGENTE_BIOLOGICO_CONTROLE}
      infoUsuario={props.infoUsuario}
      saveMessage={formatMessage(
        'agentesControle.' +
          (props.match.params.id
            ? 'agentesControleEditadoComSucesso'
            : 'agentesControleCadastradoComSucesso')
      )}
      falhaSaveMesssage={formatMessage(
        'agentesControle.' +
          (props.match.params.id
            ? 'falhaAoEditarAgentesControle'
            : 'falhaAoCadastrarAgentesControle')
      )}
      falhaCarregarMensagem={formatMessage('agentesControle.falhaAoCarregarAgentesControle')}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(EditarAgentesBiologicos));
