export default {
  cultura: {
    existeUm: "There's one ",
    existeUma: 'There is one ',
    relacionadaComCultura: ' related to culture',
    relacionadoComCultura: ' culture related',
    detentor: 'Holder',
    plantio: 'planting',
    fitossanidade: 'plant health',
    cotacao: 'price',
    safra: 'harvest',
    cadastro: 'register'
  }
};
