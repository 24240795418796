import en from "react-intl/locale-data/en";
import pt from "react-intl/locale-data/pt";
import es from "react-intl/locale-data/es";
import { addLocaleData } from "react-intl";
import Admin from "@resources/oauth/Admin";

var userLang = navigator.language || navigator.userLanguage;

function getUser() {
  Admin.findPerfil()
    .then(user => {
      if (user.cliente.pais.sgPais === "BRA") {
        userLang = "pt-BR";
      } else if (user.cliente.pais.sgPais === "ARG") {
        userLang = "es-ES";
      } else {
        userLang = "en-US";
      }
    })
    .catch(error => {});
}

getUser();

if (userLang.includes("pt")) {
  userLang = "pt-BR";
} else if (userLang.includes("es")) {
  userLang = "es-ES";
} else {
  userLang = "en-US";
}

addLocaleData([...en, ...pt, ...es]);

export function getUserLang(userChange = false) {
  if (userChange) {
    getUser();
  }

  return userLang;
}
