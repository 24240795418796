import React, { Component } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Button from '@material-ui/core/Button';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '@resources/oauth/Authentication';
import { style, theme } from './../Clientes.styles';
import formatMessage from '../i18n/formatMessage';

class EditarUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusEdit: false,
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      check: true,
      openSwal: false,
      usuariosAdicionados: [],
      key: 0,
      listUsuarios: []
    };

    this.checkSwitch = this.checkSwitch.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.reenviarCodigo = this.reenviarCodigo.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
    this.submitUsuarios = this.submitUsuarios.bind(this);
  }

  componentDidMount() {
    if (this.props.selectUsuarios.nmUsuario !== undefined) {
      this.setState({
        itemSelect: this.props.selectUsuarios,
        statusEdit: true
      });
    } else {
      this.setState({
        itemSelect: {},
        statusEdit: false
      });
    }
  }

  reenviarCodigo() {
    this.setState({
      openSwal: true
    });

    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage('clientes.carregando')
      }
    );

    //ToDo: reenviar email de confirmação
    // var functions = firebase.functions().httpsCallable("reenviarCodigo");
    // functions({ idUsuario: this.state.itemSelect.idUsuario })
    //   .then(result => {
    //     swal(provider.intl.formatMessage({ id: "codigoEnviadoComSucesso" }), {
    //       icon: "success",
    //       buttons: {
    //         confirm: {
    //           text: "Ok",
    //           value: true,
    //           visible: true,
    //           closeModal: true,
    //           className: "swal2-Ok"
    //         }
    //       }
    //     });

    //     this.setState({
    //       openSwal: false,
    //       openModal: false
    //     });
    //   })
    //   .catch(e => {
    //     swal(provider.intl.formatMessage({ id: "erroAoReenviarEmail" }), {
    //       icon: "error",
    //       buttons: {
    //         confirm: {
    //           text: "Ok",
    //           value: true,
    //           visible: true,
    //           closeModal: true,
    //           className: "swal2-Ok"
    //         }
    //       }
    //     }).then(() => {
    //       this.setState({
    //         openSwal: false
    //       });
    //     });
    //   });
  }

  onClose() {
    this.props.onClickCancelar(0);
  }

  onSave(values) {
    if (this.state.statusEdit) {
      this.submitUsuarios(values);
    } else {
      const validation = this.props.data.find(doc => {
        return doc.dsEmail === values.dsEmail;
      });
      if (validation === undefined) {
        Authentication.verificarUsuario(values.dsEmail)
          .then(doc => {
            this.submitUsuarios(values);
          })
          .catch(err => {
            swal(formatMessage('clientes.emailDuplicado'), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });
          });
      } else {
        swal(formatMessage('clientes.emailDuplicado'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      }
    }
  }

  submitUsuarios(values) {
    values.stUsuario = this.state.check ? 1 : 0;

    values.nmStUsuario = !!parseInt(values.stUsuario)
      ? formatMessage('clientes.ativo')
      : formatMessage('clientes.inativo');
    values.tpPerfil = 1;
    values.nmPerfil = formatMessage('clientes.revenda');
    values.dsEmail = values.dsEmail.toLowerCase();
    values.idClienteUsuario = this.state.itemSelect.idClienteUsuario;

    if (this.state.statusEdit) {
      values.stRegistro = 1;
      const position = this.props.data.indexOf(this.state.itemSelect);
      this.setState({
        openModal: false,
        itemSelect: '',
        disabledEdit: true,
        disabledDelete: true,
        statusEdit: false
      });

      this.props.onClickUsuarios(values, position);
    } else {
      values.stRegistro = 0;
      this.setState({
        openModal: false,
        listUsuarios: this.state.listUsuarios.concat(values)
      });

      this.props.onClickUsuarios(values);
    }
  }

  checkSwitch() {
    this.setState({
      check: !this.state.check
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  clickDelete() {
    this.props.onClickDeleteUsuarios(this.state.itemSelect);
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={
            this.state.statusEdit
              ? {
                  nmUsuario: this.state.itemSelect.nmUsuario,
                  dsEmail: this.state.itemSelect.dsEmail,
                  stUsuario: ''
                }
              : {
                  nmUsuario: '',
                  dsEmail: '',
                  stUsuario: ''
                }
          }
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={this.onSave}
          validationSchema={Yup.object().shape({
            nmUsuario: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            dsEmail: Yup.string()
              .email(formatMessage('clientes.emailInvalido'))
              .required(formatMessage('clientes.campoObrigatorio'))
          })}
          render={({ values, handleSubmit }) => (
            <CardCadastro
              marginLeft={30}
              titulo={formatMessage('clientes.usuarios')}
              botoes={[
                <Button
                  style={{ display: 'none' }} //ToDo: botão reenviar código
                  color='inherit'
                  variant='contained'
                  onClick={this.reenviarCodigo}
                >
                  {formatMessage('clientes.reenviarCodigo')}
                </Button>,
                this.state.statusEdit && (
                  <Button color='primary' variant='contained' onClick={this.clickDelete}>
                    {formatMessage('clientes.excluir')}
                  </Button>
                ),
                <Button color='inherit' variant='contained' onClick={this.onClose}>
                  {formatMessage('clientes.cancelar')}
                </Button>,
                <Button color='secondary' variant='contained' onClick={handleSubmit}>
                  {formatMessage('clientes.confirmar')}
                </Button>
              ]}
            >
              <div style={{ padding: '15px 15px 30px 15px' }}>
                <form autoComplete='off'>
                  <Grid container spacing={16}>
                    <Grid item xs={4}>
                      <Field name='nmUsuario'>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            id='nmUsuario'
                            name='nmUsuario'
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={values.nmUsuario}
                            label={formatMessage('clientes.nome')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.nmUsuario && form.touched.nmUsuario}
                            helperText={form.touched.nmUsuario ? form.errors.nmUsuario : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={5}>
                      <Field name='dsEmail'>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            id='dsEmail'
                            name='dsEmail'
                            disabled={this.state.statusEdit}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={values.dsEmail}
                            label={formatMessage('clientes.email')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.dsEmail && form.touched.dsEmail}
                            helperText={form.touched.dsEmail ? form.errors.dsEmail : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <div style={{ marginTop: 10, marginLeft: 15 }}>
                        <Typography noWrap>
                          <label style={{ color: '#000000', fontSize: 14 }}>
                            {formatMessage('clientes.ativo')}
                          </label>
                          <Switch
                            color='primary'
                            checked={this.state.check}
                            onChange={this.checkSwitch}
                          />
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </CardCadastro>
          )}
        />
      </MuiThemeProvider>
    );
  }
}

EditarUsuarios.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarUsuarios);
export default withRouter(connect(mapStateToProps)(enhaced));
