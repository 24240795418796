//@author Katia Miglioli
// Em relacao a internacionalizacao dos botoes é preciso faze-lo atraves de funcoes,
// nao aceita a internacionalizacao normalmente usada
//Use a funcoes como retornaSalvarLinguagem/retornaCancelarLinguagem em Editar Comunidade
import React, { Component } from 'react';
import { getUserLang } from '@utils/localeUtils';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import LocalizedDateUtils from '../DatePicker/LocalizedDateUtils';
import * as moment from 'moment';

const styles = theme => ({
  textField: {
    width: '100%'
  },
  text: {
    width: '100%',
    whiteSpace: 'nowrap',
    color: 'red'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInputAdornment: {
      positionEnd: {
        marginRight: '-14px',
        marginLeft: 0
      }
    },
    MuiInputLabel: {
      animated: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'calc(100% - 20px)'
      }
    },
    MuiInput: {
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '2px solid green'
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  }
});

class DatePicker extends Component {
  formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  formatMask() {
    if (getUserLang() === 'es-ES') {
      return [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    }
  }

  onChangeDatePicker(value) {
    if (!value) return;
    if (
      typeof value === 'string' &&
      value.split('_').join('').length === 10 &&
      moment(value, 'DD/MM/YYYY').isValid()
    ) {
      return moment(value, this.formatTextField());
    }
  }

  render() {
    const { classes } = this.props;
    const valueDate = this.props.valueDate;
    moment.locale(getUserLang());

    return (
      <div style={this.props.style}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={LocalizedDateUtils} locale={getUserLang()}>
            <div className={classes.textField}>
              <InlineDatePicker
                disabled={this.props.disabled}
                className={classes.text}
                label={this.props.label}
                keyboard
                name='value'
                value={valueDate}
                minDate={this.props.minDate || new Date('1900-01-01')}
                maxDate={this.props.maxDate || new Date('2100-01-01')}
                error={this.props.errorTouched}
                helperText={this.props.errorTouched ? this.props.helperText : null}
                onChange={value => {
                  this.props.onChangeDatePicker(value);
                }}
                onBlur={this.props.onBlurDatePicker}
                format={this.formatTextField()}
                mask={this.formatMask()}
                onInputChange={e => this.onChangeDatePicker(e.target.value)}
                ref={this.props.datePickerRef}
                inputProps={this.props.inputProps}
                id={this.props.id}
              />
            </div>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(DatePicker);
