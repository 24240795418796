export default {
  galeria: {
    galeria: 'Galería',
    produtor: 'Productor',
    cidade: 'Ciudad',
    regiao: 'Región',
    comunidade: 'Comunidad',
    resumo: 'Resumen',
    cultura: 'Cultura',
    cultivar: 'Cultivar',
    areaCultivo: 'Área de cultivo',
    descricao: 'Descripción',
    talhoesUtilizados: 'Campos utilizados',
    data: 'Fecha',
    safra: 'Cosecha',
    baixando: 'Descargando',
    erroDownloadArquivos: 'Error al descargar archivos',
    tecnico: 'Técnico',
    erroBuscarGaleria: 'Error al obtener fotos de la galería',
    baixarImagens: 'Descargar imágenes'
  }
};
