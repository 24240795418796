export default {
    comunidade: {
        descricao: 'Description',
        municipio:'County',
        comunidades:'Communities',
        desejaExcluir: 'Do you want to delete the selected community?',
        nao:'Not',
        excluir:'Delete',
        comunidadeRemovidaComSucesso:'Community successfully removed',
        carregando:'Wait...',
        falhaAoExcluirComunidade:'Error deleting community',
        cadastroDeComunidades: 'Community registration',
        identificacaoDaComunidade : 'Identification',
        campoObrigatorio: 'Required field',
        comunidadeCadastradaComSucesso:'Community Successfully Registered',
        falhaAoCadastrarComunidade:'Failed to register community',
        comunidadeEditadaComSucesso:'Community updated successfully',
        falhaAoEditarComunidade:'Failed to edit community',
        falhaAoCarregarComunidades:'Failed to load communities',
        salvar:'Save',
        cancelar:'Cancel',
        uf:'State',
        salvando: 'saving',
        atualizando: 'Updating',
        excluindo: 'Deleting',
    }
}