export default {
  cultura: {
    culturas: 'Culturas',
    descricao: 'Descrição',
    numeroDeAplicacoes: 'Número de aplicações',
    nao: 'Não',
    sim: 'Sim',
    culturaRemovidaComSucesso: 'Cultura removida com sucesso',
    falhaAoExcluirCultura: 'Falha ao excluir cultura',
    desejaExcluir: 'Deseja excluir a cultura selecionada?',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    cultura: 'Cultura',
    identificacaoDaCultura: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    culturaCadastradaComSucesso: 'Cultura cadastrada com sucesso',
    culturaEditadaComSucesso: 'Cultura editada com sucesso',
    falhaAoCadastrarCultura: 'Falha ao cadastrar cultura',
    falhaAoEditarCultura: 'Falha ao editar cultura',
    falhaAoCarregarCultura: 'Falha ao carregar cultura',
    campoObrigatorio: 'Campo obrigatório',
    cadastroDeCultura: 'Cadastro de culturas',
    safraRelacionada: 'Existe uma safra relacionada com a cultura'
  }
};
