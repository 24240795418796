import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { getUserLang } from "@utils/localeUtils";
import { IntlProvider } from "react-intl";
import { flattenMessages } from "@utils/languageUtils";
import ptBR from "@components/Agenda/i18n/ptBR";
import enUS from "@components/Agenda/i18n/enUS";
import esES from "@components/Agenda/i18n/esES";
import * as moment from "moment";

let messages = { "pt-BR": ptBR, "en-US": enUS, "es-ES": esES };
let { provider } = new IntlProvider({});

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const dia = messagesLinguage["agendaHeader.dia"];
const mes = messagesLinguage["agendaHeader.mes"];
const hoje = messagesLinguage["agendaHeader.hoje"];
const semana = messagesLinguage["agendaHeader.semana"];
const janeiro = messagesLinguage["agendaHeader.janeiro"];
const fevereiro = messagesLinguage["agendaHeader.fevereiro"];
const marco = messagesLinguage["agendaHeader.marco"];
const abril = messagesLinguage["agendaHeader.abril"];
const maio = messagesLinguage["agendaHeader.maio"];
const junho = messagesLinguage["agendaHeader.junho"];
const julho = messagesLinguage["agendaHeader.julho"];
const agosto = messagesLinguage["agendaHeader.agosto"];
const setembro = messagesLinguage["agendaHeader.setembro"];
const outubro = messagesLinguage["agendaHeader.outubro"];
const novembro = messagesLinguage["agendaHeader.novembro"];
const dezembro = messagesLinguage["agendaHeader.dezembro"];
const jan = messagesLinguage["agendaHeader.jan"];
const fev = messagesLinguage["agendaHeader.fev"];
const mar = messagesLinguage["agendaHeader.mar"];
const abr = messagesLinguage["agendaHeader.abr"];
const mai = messagesLinguage["agendaHeader.mai"];
const jun = messagesLinguage["agendaHeader.jun"];
const jul = messagesLinguage["agendaHeader.jul"];
const ago = messagesLinguage["agendaHeader.ago"];
const set = messagesLinguage["agendaHeader.set"];
const out = messagesLinguage["agendaHeader.out"];
const nov = messagesLinguage["agendaHeader.nov"];
const dez = messagesLinguage["agendaHeader.dez"];

const intlProvider = new IntlProvider(
  {
    locale: getUserLang(),
    messages: {
      dia,
      mes,
      hoje,
      semana,
      janeiro,
      fevereiro,
      marco,
      abril,
      maio,
      junho,
      julho,
      agosto,
      setembro,
      outubro,
      novembro,
      dezembro,
      jan,
      fev,
      mar,
      abr,
      mai,
      jun,
      jul,
      ago,
      set,
      out,
      nov,
      dez
    }
  },
  {}
);

provider = intlProvider.getChildContext();

const style = {
  button: {
    border: "1px solid #46A770",
    height: 40,
    color: "#00622B",
    fontSize: 16,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "transparent"
  },
  icon: {
    color: "#00622B"
  },
  head: {
    width: "100%",
    height: 40,
    marginBottom: 15,
    display: "flex",
    justifyContent: "space-between"
  },
  card: {
    backgroundColor: "#FFFFFF",
    padding: 30,
    marginBottom: 15,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
  },
  display: {
    display: "flex"
  },
  date: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fontDate: {
    fontSize: 20,
    fontWeight: 400,
    color: "rgb(0, 0, 0, 0.7)",
    marginLeft: 15,
    width: 270,
    display: "flex",
    justifyContent: "center"
  },
  iconCalendar: {
    color: "rgb(0, 0, 0, 0.7)"
  }
};

class AgendaHeader extends Component {
  constructor(props) {
    super(props);

    const today = new Date();
    today.setDate(today.getDate() - today.getDay());
    var dateFinal = new Date(today);
    dateFinal.setDate(dateFinal.getDate() + 6);

    this.state = {
      dateTitle: today,
      monthPresent: "",
      weekDay: dateFinal,
      buttonSelect: 1
    };

    this.monthAbrev = [
      {
        id: 0,
        name: provider.intl.formatMessage({ id: "jan" })
      },
      {
        id: 1,
        name: provider.intl.formatMessage({ id: "fev" })
      },
      {
        id: 2,
        name: provider.intl.formatMessage({ id: "mar" })
      },
      {
        id: 3,
        name: provider.intl.formatMessage({ id: "abr" })
      },
      {
        id: 4,
        name: provider.intl.formatMessage({ id: "mai" })
      },
      {
        id: 5,
        name: provider.intl.formatMessage({ id: "jun" })
      },
      {
        id: 6,
        name: provider.intl.formatMessage({ id: "jul" })
      },
      {
        id: 7,
        name: provider.intl.formatMessage({ id: "ago" })
      },
      {
        id: 8,
        name: provider.intl.formatMessage({ id: "set" })
      },
      {
        id: 9,
        name: provider.intl.formatMessage({ id: "out" })
      },
      {
        id: 10,
        name: provider.intl.formatMessage({ id: "nov" })
      },
      {
        id: 11,
        name: provider.intl.formatMessage({ id: "dez" })
      }
    ];

    this.month = [
      {
        id: 0,
        name: provider.intl.formatMessage({ id: "janeiro" })
      },
      {
        id: 1,
        name: provider.intl.formatMessage({ id: "fevereiro" })
      },
      {
        id: 2,
        name: provider.intl.formatMessage({ id: "marco" })
      },
      {
        id: 3,
        name: provider.intl.formatMessage({ id: "abril" })
      },
      {
        id: 4,
        name: provider.intl.formatMessage({ id: "maio" })
      },
      {
        id: 5,
        name: provider.intl.formatMessage({ id: "junho" })
      },
      {
        id: 6,
        name: provider.intl.formatMessage({ id: "julho" })
      },
      {
        id: 7,
        name: provider.intl.formatMessage({ id: "agosto" })
      },
      {
        id: 8,
        name: provider.intl.formatMessage({ id: "setembro" })
      },
      {
        id: 9,
        name: provider.intl.formatMessage({ id: "outubro" })
      },
      {
        id: 10,
        name: provider.intl.formatMessage({ id: "novembro" })
      },
      {
        id: 11,
        name: provider.intl.formatMessage({ id: "dezembro" })
      }
    ];

    this.clickBack = this.clickBack.bind(this);
    this.clickNext = this.clickNext.bind(this);
    this.clickToday = this.clickToday.bind(this);
    this.clickMonth = this.clickMonth.bind(this);
    this.clickWeek = this.clickWeek.bind(this);
    this.clickDay = this.clickDay.bind(this);
    this.titlePagina = this.titlePagina.bind(this);
  }

  componentDidMount() {
    if (this.state.buttonSelect === 1) {
      const today = new Date(this.state.dateTitle);
      today.setDate(today.getDate() - today.getDay());
      var dateFinal = new Date(today);
      dateFinal.setDate(dateFinal.getDate() + 6);

      this.setState({
        monthPresent: this.month[dateFinal.getMonth()].name,
        weekDay: dateFinal,
        dateTitle: today
      });
    } else {
      this.setState({
        monthPresent: this.month[this.state.weekDay.getMonth()].name
      });
    }

    this.props.getDate(
      moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ"),
      moment(this.state.weekDay).format("YYYY-MM-DDTHH:mm:ssZZ")
    );
  }

  titlePagina() {
    if (this.state.buttonSelect === 0) {
      if (getUserLang() === "en-US") {
        return this.state.monthPresent + " " + this.state.weekDay.getFullYear();
      } else {
        return (
          this.state.monthPresent + " de " + this.state.weekDay.getFullYear()
        );
      }
    } else if (this.state.buttonSelect === 1) {
      if (getUserLang() === "en-US") {
        const monthInicial = this.monthAbrev[this.state.dateTitle.getMonth()]
          .name;
        const monthPresent = this.monthAbrev[this.state.weekDay.getMonth()]
          .name;
        return (
          monthInicial +
          " " +
          this.state.dateTitle.getDate() +
          " - " +
          monthPresent +
          " " +
          this.state.weekDay.getDate() +
          ", " +
          this.state.weekDay.getFullYear()
        );
      } else {
        return (
          this.state.dateTitle.getDate() +
          " a " +
          this.state.weekDay.getDate() +
          " de " +
          this.state.monthPresent +
          " de " +
          this.state.weekDay.getFullYear()
        );
      }
    } else if (this.state.buttonSelect === 2) {
      if (getUserLang() === "en-US") {
        return (
          this.state.monthPresent +
          " " +
          this.state.dateTitle.getDate() +
          ", " +
          this.state.weekDay.getFullYear()
        );
      } else {
        return (
          this.state.dateTitle.getDate() +
          " de " +
          this.state.monthPresent +
          " de " +
          this.state.weekDay.getFullYear()
        );
      }
    }
  }

  clickBack() {
    let monthPresent = "";
    let weekDay = "";
    var date = "";

    if (this.state.buttonSelect === 0) {
      this.state.dateTitle.setMonth(this.state.dateTitle.getMonth() - 1);

      monthPresent = this.month[this.state.dateTitle.getMonth()].name;
      weekDay = this.state.dateTitle;

      date = new Date(this.state.dateTitle);
      date.setDate(1);
      var dateFinal = new Date(date);
      dateFinal.setMonth(dateFinal.getMonth() + 1);
      dateFinal.setDate(dateFinal.getDate() - 1);

      this.props.onDateChanged(
        moment(date).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(dateFinal).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    } else if (this.state.buttonSelect === 1) {
      this.state.dateTitle.setDate(this.state.dateTitle.getDate() - 7);
      date = this.state.weekDay;
      date.setDate(date.getDate() - 7);

      monthPresent = this.month[date.getMonth()].name;
      weekDay = date;

      this.props.onDateChanged(
        moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(weekDay).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    } else if (this.state.buttonSelect === 2) {
      this.state.dateTitle.setDate(this.state.dateTitle.getDate() - 1);

      monthPresent = this.month[this.state.dateTitle.getMonth()].name;
      weekDay = this.state.dateTitle;

      this.props.onDateChanged(
        moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    this.setState({
      monthPresent: monthPresent,
      weekDay: weekDay
    });
  }

  clickNext() {
    let monthPresent = "";
    let weekDay = "";
    var date = "";

    if (this.state.buttonSelect === 0) {
      this.state.dateTitle.setMonth(this.state.dateTitle.getMonth() + 1);

      monthPresent = this.month[this.state.dateTitle.getMonth()].name;
      weekDay = this.state.dateTitle;

      date = new Date(this.state.dateTitle);
      date.setDate(1);
      var dateFinal = new Date(date);
      dateFinal.setMonth(dateFinal.getMonth() + 1);
      dateFinal.setDate(dateFinal.getDate() - 1);

      this.props.onDateChanged(
        moment(date).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(dateFinal).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    } else if (this.state.buttonSelect === 1) {
      this.state.dateTitle.setDate(this.state.dateTitle.getDate() + 7);
      date = this.state.weekDay;
      date.setDate(date.getDate() + 7);

      monthPresent = this.month[date.getMonth()].name;
      weekDay = date;

      this.props.onDateChanged(
        moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(weekDay).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    } else if (this.state.buttonSelect === 2) {
      this.state.dateTitle.setDate(this.state.dateTitle.getDate() + 1);

      monthPresent = this.month[this.state.dateTitle.getMonth()].name;
      weekDay = this.state.dateTitle;

      this.props.onDateChanged(
        moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    this.setState({
      monthPresent: monthPresent,
      weekDay: weekDay
    });
  }

  clickToday() {
    const today = new Date();
    today.setDate(today.getDate() - today.getDay());
    var date = new Date(today);

    if (this.state.buttonSelect === 0) {
      var dateInitial = new Date(date);
      dateInitial.setDate(1);
      var dateFinal = new Date(dateInitial);
      dateFinal.setMonth(dateFinal.getMonth() + 1);
      dateFinal.setDate(dateFinal.getDate() - 1);
      this.props.onDateChanged(
        moment(dateInitial).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(dateFinal).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    } else if (this.state.buttonSelect === 1) {
      date.setDate(date.getDate() + 6);

      this.props.onDateChanged(
        moment(today).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(date).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    } else if (this.state.buttonSelect === 2) {
      this.props.onDateChanged(
        moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ"),
        moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    this.setState({
      dateTitle: today,
      monthPresent: this.month[today.getMonth()].name,
      weekDay: date
    });
  }

  clickMonth() {
    this.setState({
      buttonSelect: 0,
      monthPresent: this.month[this.state.dateTitle.getMonth()].name,
      weekDay: this.state.dateTitle
    });

    var monthInitial = new Date(this.state.weekDay);
    monthInitial.setDate(1);

    var monthFinal = new Date(monthInitial);
    monthFinal.setMonth(monthFinal.getMonth() + 1);
    monthFinal.setDate(monthFinal.getDate() - 1);

    this.props.onViewChanged(
      0,
      moment(monthInitial).format("YYYY-MM-DDTHH:mm:ssZZ"),
      moment(monthFinal).format("YYYY-MM-DDTHH:mm:ssZZ")
    );
  }

  clickWeek() {
    const today = new Date(this.state.dateTitle);
    today.setDate(today.getDate() - today.getDay());
    var date = new Date(today);

    date.setDate(date.getDate() + 6);

    this.setState({
      dateTitle: today,
      monthPresent: this.month[date.getMonth()].name,
      buttonSelect: 1,
      weekDay: date
    });

    this.props.onViewChanged(
      1,
      moment(today).format("YYYY-MM-DDTHH:mm:ssZZ"),
      moment(date).format("YYYY-MM-DDTHH:mm:ssZZ")
    );
  }

  clickDay() {
    this.setState({
      buttonSelect: 2,
      weekDay: this.state.dateTitle
    });

    this.props.onViewChanged(
      2,
      moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ"),
      moment(this.state.dateTitle).format("YYYY-MM-DDTHH:mm:ssZZ")
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.card}>
        <div className={classes.head}>
          <div className={classes.display}>
            <button
              className={classes.button}
              onClick={this.clickBack}
              style={{ width: 40 }}
            >
              <ChevronLeft className={classes.icon} />
            </button>
            <button
              onClick={this.clickToday}
              className={classes.button}
              style={{ width: 80, marginLeft: 5 }}
            >
              {provider.intl.formatMessage({ id: "hoje" })}
            </button>
            <button
              onClick={this.clickNext}
              className={classes.button}
              style={{ width: 40, marginLeft: 5 }}
            >
              <ChevronRight className={classes.icon} />
            </button>
          </div>
          <div className={classes.date}>
            <div className={classes.fontDate}>{this.titlePagina()}</div>
          </div>
          <div className={classes.display}>
            <button
              onClick={this.clickMonth}
              className={classes.button}
              style={{
                borderWidth: "1px 0px 1px 1px",
                borderStyle: "solid",
                width: 80,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                color: this.state.buttonSelect === 0 ? "#FFFFFF" : "#00622B",
                borderColor: "#46A770",
                backgroundColor:
                  this.state.buttonSelect === 0 ? "#00622B" : "#FFFFFF"
              }}
            >
              {provider.intl.formatMessage({ id: "mes" })}
            </button>
            <button
              onClick={this.clickWeek}
              className={classes.button}
              style={{
                borderWidth: "1px 1px 1px 1px",
                borderStyle: "solid",
                width: 80,
                borderRadius: 0,
                color: this.state.buttonSelect === 1 ? "#FFFFFF" : "#00622B",
                borderColor: "#46A770",
                borderTopColor:
                  this.state.buttonSelect === 1 ? "#00622B" : "#46A770",
                borderBottomColor:
                  this.state.buttonSelect === 1 ? "#00622B" : "#46A770",
                backgroundColor:
                  this.state.buttonSelect === 1 ? "#00622B" : "#FFFFFF"
              }}
            >
              {provider.intl.formatMessage({ id: "semana" })}
            </button>
            <button
              onClick={this.clickDay}
              className={classes.button}
              style={{
                borderWidth: "1px 1px 1px 0px",
                borderStyle: "solid",
                width: 80,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                color: this.state.buttonSelect === 2 ? "#FFFFFF" : "#00622B",
                borderColor:
                  this.state.buttonSelect === 2 ? "#00622B" : "#46A770",
                backgroundColor:
                  this.state.buttonSelect === 2 ? "#00622B" : "#FFFFFF"
              }}
            >
              {provider.intl.formatMessage({ id: "dia" })}
            </button>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(style)(AgendaHeader);
