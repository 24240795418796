export default {
  inseticidaMicroBio: {
    inseticidaMicroBioRemovidoComSucesso: 'Microbiological insecticides successfully removed',
    falhaAoExcluirInseticidaMicroBio: 'Failed to exclude microbiological insecticides',
    desejaExcluir: 'Do you want to delete the selected microbiological insecticides?',
    inseticidaMicroBio: 'Microbiological insecticides',
    identificacaoDaInseticidaMicroBio: 'Identification',
    inseticidaMicroBioCadastradoComSucesso: 'Microbiological insecticides successfully registered',
    inseticidaMicroBioEditadoComSucesso: 'Microbiological insecticides edited successfully',
    falhaAoEditarInseticidaMicroBio: 'Failed to edit microbiological insecticides',
    falhaAoCadastrarInseticidaMicroBio: 'Failed to register microbiological insecticides',
    falhaAoCarregarInseticidaMicroBio: 'Failed to load microbiological insecticides',
    campoObrigatorio: 'Required field',
    cadastroDeInseticida: 'Microbiological insecticides register'
  }
};
