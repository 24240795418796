export default {
  certificacao: {
    descricao: 'Description',
    dataCadastro: 'Registration date',
    falhaoAoBuscarDados: 'Failed to fetch data',
    certificacoes: 'Certifications ecosocial',
    desejaExcluirCertificacao: 'Do you want to delete this certification',
    nao: 'No',
    excluir: 'Delete',
    certificacaoExcluida: 'Certification successfully deleted',
    falhaoAoExcluir: 'Failed to delete certification',
    cadastroDeCertificacao: 'Ecosocial certification registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    salvar: 'Salve',
    cancelar: 'Cancel',
    certificacaoSalvoComSucesso: 'Certification successfully saved',
    falhaAoSalvar: 'Failed to save certification',
    salvando: 'Saving',
    atualizando: 'Updating',
    produtorRelacionado:
      'It was not possible to exclude this certification because there is a producer using it'
  }
};
