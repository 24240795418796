import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import formatMessage from '../i18n/formatMessage';
import swal from '@sweetalert/with-react';
import Identificacao from './Identificacao';

const style = () => ({
  cardTabIndex: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 95px)',
    width: '100%',
    overflowX: 'hidden'
  }
});

/**
 * Componente de edição de Plantio Orgânico
 *
 * @author Gabriela Farias
 * @class Componente de cadastro de OGM
 * @extends {Component} - Componente React
 */
function MaterialProgapagacao(props) {
  const {
    classes,
    clickSaveInsumos,
    plantio,
    persist,
    analiseOgmList,
    tratarValorAnaliseOgm,
    loadAnaliseOgm,
    formaAplicacaoList,
    saveFile,
    arquivoList,
    deleteUpload
  } = props;
  let valuesAux = null;
  let handleBlurAux = null;

  function handleBlurAndPersist(e, campo = null) {
    persist(valuesAux);

    if (campo) {
      handleBlurAux(campo)(e);
    } else {
      handleBlurAux(e);
    }
  }

  function deleteFile(index) {
    swal({
      title: formatMessage('plantios.removeArquivo'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('plantios.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('plantios.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        deleteUpload(index);
        swal.close();
      }
    });
  }

  return (
    <Formik
      initialValues={plantio}
      validateOnBlur
      validateOnChange={false}
      enableReinitialize={true}
      onSubmit={() => {}}
      validationSchema={Yup.object().shape({})}
      render={formikProps => {
        const { values, errors, touched, handleBlur, setFieldValue } = formikProps;
        valuesAux = values;
        handleBlurAux = handleBlur;

        return (
          <div className={classes.cardTabIndex}>
            <Identificacao
              formaAplicacaoList={formaAplicacaoList}
              persistTipoAnalise={e => persist({ ...valuesAux, tipoAnaliseOgm: e })}
              loadAnaliseOgm={loadAnaliseOgm}
              analiseOgmList={analiseOgmList}
              tratarValorAnaliseOgm={tratarValorAnaliseOgm}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              handleBlurAndPersist={handleBlurAndPersist}
              clickSaveInsumos={clickSaveInsumos}
              plantioInsumoList={plantio.plantioInsumoList}
              arquivoList={arquivoList}
              saveFile={saveFile}
              deleteFile={deleteFile}
            />
          </div>
        );
      }}
    />
  );
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'plantios';
  })[0].content
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(MaterialProgapagacao)));
