import React from 'react';
import LabelDescricaoCadastro from './../Header/LabelDescricaoCadastro';
import TipoAreaEnum from './../enumerations/TipoAreaEnum';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import DatePicker from '@components/DatePicker/DatePicker';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import {
  Grid,
  Checkbox,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Select,
  MenuItem,
  Input
} from '@material-ui/core';
import formatMessage from '../i18n/formatMessage';
import { style, theme } from '../AcompanhamentoProdutor.styles';
import { getUserLang } from '@utils/localeUtils';
import DateByLanguage from './../i18n/DateByLanguage';
import * as moment from 'moment';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import AtividadeUrlEnum from '../enumerations/AtividadeUrlEnum';

/**
 * Componente de card de cadastros de dados da aba de identificação
 */
function Identificacao(props) {
  const {
    classes,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    valueAreaCultivo,
    buscarProdutor,
    valuePlantio,
    produtor,
    keyUpdate,
    setKeyUpdate,
    plantio,
    dataAppSync,
    validarInicializacaoPlantio,
    typeFertilizante,
    tpLancamento,
    changeProdutos,
    checked,
    changeCheked,
    setNrQuantidade,
    setTpUnidade,
    setValueAreaCultivo,
    setValuePlantio,
    buscarTalhoes,
    setDtAtividade
  } = props;
  const cardSelectStyle = { width: '100%', backgroundColor: 'transparent' };

  return (
    <div style={{ marginTop: 15 }}>
      <form autoComplete='off'>
        <Grid container spacing={16}>
          <Grid item xs={3}>
            <Autocomplete
              itens={produtor.areaCultivoList}
              campoOp={'dsAreaCultivo'}
              campoChave={'idAreaCultivo'}
              label={formatMessage('acompanhamentoProdutor.areaDeCultivo')}
              name='areaCultivo'
              error={valueAreaCultivo === '' && touched.areaCultivo}
              helperText={
                touched.areaCultivo && valueAreaCultivo === ''
                  ? formatMessage('acompanhamentoProdutor.campoObrigatorio')
                  : null
              }
              onChangeOwn={event => onChangeAreaCultivo(event)}
              onChangeAutoComplete={validarInicializacaoAreaCultivo}
              value={tratarAutoComplete(valueAreaCultivo, 'dsAreaCultivo')}
              key={keyUpdate}
              valueAutoComplete={tratarAutoComplete(valueAreaCultivo, 'dsAreaCultivo')}
              valueSelect={tratarAutoComplete(valueAreaCultivo, 'dsAreaCultivo').dsAreaCultivo}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              disabled={!valueAreaCultivo}
              itens={plantio}
              campoOp={'dsPlantioSelected'}
              campoChave={'idPlantio'}
              titleSecondLine={'titleSubLine'}
              titleFirstLine={'dsPlantioCustom'}
              descriptionFirstLine={'dtPlantioFormat'}
              descriptionSecondLine={'dsSubLineCustom'}
              label={formatMessage('acompanhamentoProdutor.plantio')}
              name='plantio'
              onChangeOwn={event => onChangePlantio(event)}
              onChangeAutoComplete={validarInicializacaoPlantio}
              value={tratarAutoComplete(valuePlantio, 'nmCultura')}
              key={keyUpdate}
              valueAutoComplete={tratarAutoComplete(valuePlantio, 'nmCultura')}
              valueSelect={tratarAutoComplete(valuePlantio, 'nmCultura').dsPlantioSelected}
            />
          </Grid>

          <Grid item xs={2}>
            <DatePicker
              disabled={!valueAreaCultivo}
              minDate={minDateValidation()}
              maxDate={maxDateValidation()}
              locale={getUserLang()}
              style={{ height: 70 }}
              className={classes.font}
              label={getLabelData()}
              valueDate={values.dtAtividade}
              errorTouched={errors.dtAtividade && valueAreaCultivo && touched.dtAtividade}
              helperText={touched.dtAtividade && valueAreaCultivo && errors.dtAtividade}
              onBlurDatePicker={date => onBlurData(date)}
              onChangeDatePicker={event => onChangeData(event)}
            />
          </Grid>

          {tpLancamento === 2 && (
            <Grid item xs={2} className={classes.gridSelect}>
              <MuiThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      name='checkedUsuarios'
                      onChange={changeCheckBox}
                      value='invasoras'
                      color='primary'
                    />
                  }
                  label={formatMessage('acompanhamentoProdutor.invasoras')}
                  style={{ fontSize: 14 }}
                />
              </MuiThemeProvider>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={16}>
          <Grid item xs={tpLancamento <= 1 ? 7 : 12}>
            <TextField
              id='dsAdicional'
              name='dsAdicional'
              value={values.dsAdicional}
              label={LabelDescricaoCadastro()}
              className={classes.textField}
              InputLabelProps={{
                className: classes.font
              }}
              onBlur={e => onChangeLancamento('dsAdicional', e, handleBlur)}
              onChange={handleChange}
              error={errors.dsAdicional && touched.dsAdicional}
              helperText={touched.dsAdicional ? errors.dsAdicional : ''}
            />
          </Grid>

          {tpLancamento <= 1 && (
            <Grid item xs={2}>
              <TextFieldWithMask
                tipoMascara={3}
                numberDecimalScale={3}
                showThousandSeparator={true}
                disabled={typeFertilizante}
                id='nrQuantidade'
                name='nrQuantidade'
                value={values.nrQuantidade}
                label={formatMessage('acompanhamentoProdutor.areaTanque')}
                className={classes.textField}
                InputLabelProps={{
                  className: classes.font
                }}
                onBlur={value => changeQuantidade(value)}
                onChange={handleChange}
                error={errors.nrQuantidade && touched.nrQuantidade}
                helperText={touched.nrQuantidade ? errors.nrQuantidade : ''}
              />
            </Grid>
          )}

          {tpLancamento <= 1 && (
            <Grid item xs={2}>
              <FormControl className={classes.form} error={errors.tpUnidade && touched.tpUnidade}>
                <InputLabel
                  htmlFor='tpUnidade-helper'
                  error={errors.tpUnidade && touched.tpUnidade}
                  className={classes.font}
                >
                  {formatMessage('acompanhamentoProdutor.dsUnMedida')}
                </InputLabel>
                <Select
                  className={classes.fontUniMedida}
                  SelectDisplayProps={{
                    style: cardSelectStyle
                  }}
                  value={values.tpUnidade}
                  onChange={event => changeUnidade(event)}
                  onBlur={e => onChangeLancamento('tpUnidade', e, handleBlur)}
                  error={errors.tpUnidade && touched.tpUnidade}
                  input={<Input id='tpUnidade-error' />}
                  inputProps={{
                    className: classes.font,
                    name: 'tpUnidade',
                    id: 'tpUnidade-helper'
                  }}
                >
                  {TipoAreaEnum.map(item => (
                    <MenuItem value={item.id} key={TipoAreaEnum.indexOf(item)} className={classes.font}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.tpUnidade && touched.tpUnidade && (
                  <FormHelperText>{errors.tpUnidade}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );

  /**
   * Validação de data miníma
   * @returns validação
   */
  function minDateValidation() {
    if (valuePlantio) return valuePlantio.dtPlantio;

    return '1900-01-01';
  }

  /**
   * Validação de data maxima
   * @returns validação
   */
  function maxDateValidation() {
    if (valuePlantio && valuePlantio.dtColheita) return valuePlantio.dtColheita;

    return '2100-01-01';
  }

  /**
   * Titulo do campo de data
   * @returns titulo
   */
  function getLabelData() {
    if (tpLancamento <= AtividadeUrlEnum.APLICACAO)
      return formatMessage('acompanhamentoProdutor.dataAplicacao');
    else return formatMessage('acompanhamentoProdutor.data');
  }

  /**
   * On blur do campo de data
   */
  function onBlurData(date) {
    let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

    if (!validation) {
      setFieldValue('dtAtividade', null);
    } else {
      changeProdutos(dataAppSync, moment(date.target.value, DateByLanguage()));
    }
  }

  /**
   * on change de data
   *
   * @param {*} dtAtividade - nova data
   */
  function onChangeData(dtAtividade) {
    const aux = dataAppSync;
    aux['dtAtividade'] = dtAtividade;

    setFieldValue('dtAtividade', dtAtividade);
    changeProdutos(aux, dtAtividade);
  }

  /**
   * Altera as informações de area de cultivo e limpa proximos campos
   * @param {*} event
   */
  function onChangeAreaCultivo(event) {
    setFieldValue('areaCultivo', event);
    setValueAreaCultivo(event);
    setValuePlantio('');
    setFieldValue('plantio', event);
    setFieldValue('dtAtividade', null);
    setKeyUpdate(Math.random());
    setDtAtividade(null);
    const aux = dataAppSync;
    aux['areaCultivo'] = event;

    changeProdutos(aux, null);

    if (event !== null) {
      validarInicializacaoPlantio(event, true);
    }
  }

  /**
   * Busca a lista inicial de area de cultivo
   */
  function validarInicializacaoAreaCultivo() {
    buscarProdutor();
  }

  /**
   * Trata o valor do autocomplete
   */
  function tratarAutoComplete(data, parameter) {
    if (!data || data[parameter] === undefined) {
      return '';
    }

    return data;
  }

  /**
   * Altera campo no estado
   */
  function onChangeLancamento(campo, e, callback, convertToNum = false) {
    callback(e);
    const aux = dataAppSync;
    aux[campo] = convertToNum ? +e.target.value : e.target.value;
    changeProdutos(aux, dataAppSync.dtAtividade);
  }

  /**
   * Altera plantio e limpa proximos campos
   *
   * @param {*} plantioAux - novo plantio
   */
  function onChangePlantio(plantioAux) {
    setFieldValue('plantio', plantioAux);
    setFieldValue('dtAtividade', null);
    setDtAtividade(null);
    const aux = dataAppSync;
    aux['plantio'] = plantioAux;

    changeProdutos(aux, null);
    setValuePlantio(plantioAux);
    onChangeData('');

    if (plantioAux !== null) {
      buscarTalhoes(plantioAux.plantioTalhaoList);
    }
  }

  /**
   * Altera checkbox
   */
  function changeCheckBox() {
    setFieldValue('invasoras', 1);
    changeCheked();
  }

  /**
   * Altera campo de quantidade
   */
  function changeQuantidade(value) {
    const valuePtBr = value.target.value.replace('.', '').replace(',', '.')
    setFieldValue('nrQuantidade', valuePtBr);
    setNrQuantidade(parseFloat(valuePtBr));
  }

  /**
   * Altera campo de unidade de medida
   */
  function changeUnidade(value) {
    setFieldValue('tpUnidade', value.target.value);
    setTpUnidade(value.target.value);
  }
}

export default withStyles(style)(Identificacao);
