import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InsumoRecord from '@components/InsumoRecord/InsumoRecord';
import formatMessage from './i18n/formatMessage';
import Insumo from '@resources/InsumosEnum';

/**
 * Componente para tela de bactericidas microbiologicos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function BactericidasMicrobiologicos(props) {
  useEffect(() => {
    const title = 'bactericidasMicrobiologicos.bactericidasMicrobiologicos';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }
  }, []);

  return (
    <InsumoRecord
      chipData={props.chipData}
      infoUsuario={props.infoUsuario}
      insumoEnum={Insumo.BACTERICIDA_MICROBIOLOGICO}
      insumoURL='bactericidas-microbiologicos'
      viewKey='bactericidasMicrobiologicos'
      deleteMessageSucess={formatMessage(
        'bactericidasMicrobiologicos.bactericidasMicrobiologicosRemovidoComSucesso'
      )}
      mensagemFalhaDeletar={formatMessage('bactericidasMicrobiologicos.falhaAoExcluirBactericidasMicrobiologicos')}
      mensagemDelete={formatMessage('bactericidasMicrobiologicos.desejaExcluir')}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'bactericidasMicrobiologicos';
  })[0].content
});

export default connect(mapStateToProps)(BactericidasMicrobiologicos);
