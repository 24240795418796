import formatMessage from './../i18n/formatMessage';
import InsumosEnum from '@resources/InsumosEnum';

export default [
  {
    id: 0,
    insumo: [InsumosEnum.ACARICIDA, InsumosEnum.ACARICIDA_MICROBIOLOGICO],
    name: formatMessage('acompanhamentoProdutor.acaros')
  },
  {
    id: 1,
    insumo: [
      InsumosEnum.HERBICIDA,
      InsumosEnum.AGENTE_BIOLOGICO_CONTROLE,
      InsumosEnum.BACTERICIDA_MICROBIOLOGICO
    ],
    name: formatMessage('acompanhamentoProdutor.bacterias')
  },
  {
    id: 2,
    insumo: [InsumosEnum.HERBICIDA],
    name: formatMessage('acompanhamentoProdutor.ervasDaninhas')
  },
  {
    id: 7,
    insumo: [InsumosEnum.FERTILIZANTE_QUIMICO, InsumosEnum.FERTILIZANTE_ORGANICO],
    name: formatMessage('acompanhamentoProdutor.fertilizantes')
  },
  {
    id: 3,
    insumo: [InsumosEnum.FERTILIZANTE_FOLIAR, InsumosEnum.OLEOS_ADJUVANTES],
    name: formatMessage('acompanhamentoProdutor.foliaresAdjuvantes')
  },
  {
    id: 4,
    insumo: [InsumosEnum.FUNGICIDA, InsumosEnum.FUNGICIDA_MICROBIOLOGICO],
    name: formatMessage('acompanhamentoProdutor.fungos')
  },
  {
    id: 8,
    insumo: [InsumosEnum.HOMEOPATICO, InsumosEnum.INOCULANTE],
    name: formatMessage('acompanhamentoProdutor.inoculantesHomeopatia')
  },
  {
    id: 5,
    insumo: [InsumosEnum.INSETICIDA, InsumosEnum.INSETICIDA_MICROBIOLOGICO],
    name: formatMessage('acompanhamentoProdutor.insetos')
  },
  {
    id: 6,
    insumo: [InsumosEnum.NEMATICIDA, InsumosEnum.NEMATICIDA_MICROBIOLOGICO],
    name: formatMessage('acompanhamentoProdutor.nematoides')
  }
];
