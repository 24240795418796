export default {
  FERTILIZANTE_FOLIAR: 0,
  ACARICIDA: 1,
  ACARICIDA_MICROBIOLOGICO: 2,
  BACTERICIDA: 3,
  AGENTE_BIOLOGICO_CONTROLE: 4,
  BACTERICIDA_MICROBIOLOGICO: 5,
  HERBICIDA: 6,
  FUNGICIDA: 7,
  FUNGICIDA_MICROBIOLOGICO: 8,
  INSETICIDA: 9,
  INSETICIDA_MICROBIOLOGICO: 10,
  NEMATICIDA: 11,
  NEMATICIDA_MICROBIOLOGICO: 12,
  OLEOS_ADJUVANTES: 13,
  FERTILIZANTE_QUIMICO: 14,
  FERTILIZANTE_ORGANICO: 15,
  HOMEOPATICO: 16,
  INOCULANTE: 17
};
