export default {
  agendaVisitas: {
    agendaVisitas: 'Visit follow-up',
    visitado: 'Visited',
    situacao: 'Situation ',
    aplicProdutoProibido: 'Applic. prohibited product',
    monitoramentoPragas: 'Pest monitoring',
    atividadeAtribuida: 'Activity attributed to the visit',
    agendado: 'Scheduled',
    aplicProduto: 'Applic. product',
    recomendacaoAplic: 'Application recommendation.',
    diasSemVisita: '15 días sin Visita',
    registroClimatico: 'Climate record',
    alertaFitossanitario: 'Phytosanitary Alert',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    tecnico: 'Technician',
    registroFotografico: 'Photographic register',
    tecnicoResponsavel: 'Responsible Technician',
    produtor: 'Producer',
    qtVisitas: 'How much of Visits',
    data: 'Date',
    semAtividade: 'No activity',
    itemSemCoordenadas: 'There are records without coordinates (Latitude / Longitude)'
  }
};
