import React, { Component } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import iconAdd from '@images/icone_add.png';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import green from '@material-ui/core/colors/green';
import Safra from '@resources/Safra';
import * as moment from 'moment';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'scroll'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  }
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  }
});

/**
 * Componente de listagem de safras
 *
 * @author Gabriela Farias
 * @class Safras
 * @extends {Component} - Componente React
 */
class Safras extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const descricao = messagesLinguage['safras.descricao'];
    const cultura = messagesLinguage['safras.cultura'];
    const desejaExcluir = messagesLinguage['safras.desejaExcluir'];
    const nao = messagesLinguage['safras.nao'];
    const excluir = messagesLinguage['safras.excluir'];
    const excluindo = messagesLinguage['safras.excluindo'];
    const falhaAoExcluirSafra = messagesLinguage['safras.falhaAoExcluirSafra'];
    const safraRemovidoComSucesso = messagesLinguage['safras.safraRemovidoComSucesso'];
    const situacao = messagesLinguage['safras.situacao'];
    const ativo = messagesLinguage['safras.ativo'];
    const inativo = messagesLinguage['safras.inativo'];
    const dataInicial = messagesLinguage['safras.dataInicial'];
    const dataFinal = messagesLinguage['safras.dataFinal'];

    const intlProvider = new IntlProvider({
      locale: getUserLang(),
      messages: {
        descricao,
        cultura,
        desejaExcluir,
        nao,
        excluir,
        excluindo,
        falhaAoExcluirSafra,
        safraRemovidoComSucesso,
        situacao,
        ativo,
        inativo,
        dataInicial,
        dataFinal
      }
    });

    provider = intlProvider.getChildContext();

    this.state = {
      operationTypeString: [
        {
          label: provider.intl.formatMessage({ id: 'descricao' }),
          field: 'dsSafra',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'dataInicial' }),
          field: 'dtInicio',
          type: 'date'
        },
        {
          label: provider.intl.formatMessage({ id: 'dataFinal' }),
          field: 'dtTermino',
          type: 'date'
        },
        {
          label: provider.intl.formatMessage({ id: 'situacao' }),
          field: 'stSafra',
          type: 'enum',
          options: [
            {
              label: provider.intl.formatMessage({ id: 'ativo' }),
              field: 'Ativo',
              value: '1'
            },
            {
              label: provider.intl.formatMessage({ id: 'inativo' }),
              field: 'Inativo',
              value: '0'
            }
          ]
        }
      ],
      dataAppSync: [],
      dataInTable: [],
      head: [
        {
          field: 'dsSafra',
          headerName: provider.intl.formatMessage({ id: 'descricao' }),
          col: 6
        },
        {
          valueGetter: args => moment(args.node.data.dtInicio).format(this.formatGridData()),
          headerName: provider.intl.formatMessage({ id: 'dataInicial' }),
          col: 2
        },
        {
          valueGetter: args => moment(args.node.data.dtTermino).format(this.formatGridData()),
          headerName: provider.intl.formatMessage({ id: 'dataFinal' }),
          col: 2
        },
        {
          valueGetter: args =>
            [
              provider.intl.formatMessage({ id: 'inativo' }),
              provider.intl.formatMessage({ id: 'ativo' })
            ][args.node.data.stSafra],
          headerName: provider.intl.formatMessage({ id: 'situacao' }),
          col: 2
        }
      ],
      itemSelect: '',
      disabledEdit: true,
      loading: true
    };

    const title = 'safras.safras';
    if (this.props.itensState.name !== 'safras.safras') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.onFilter = this.onFilter.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.atualizarRegistros = this.atualizarRegistros.bind(this);
    this.formatGridData = this.formatGridData.bind(this);
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  componentDidMount() {
    this.onFilter(this.props.chipData);
  }

  formatGridData() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  onFilter(dataChips) {
    Safra.findAllSafras()
      .then(query => {
        this.setState({
          dataInTable: FilterUtil.applyFilters(query.data, dataChips),
          loading: false,
          dataAppSync: query.data
        });
      })
      .catch(() => {
        this.setState({
          dataInTable: [],
          dataAppSync: [],
          loading: false
        });
      });
  }

  clickEditar() {
    this.props.history.push('/app/safra/editar/' + this.state.itemSelect.idSafra);
  }

  clickAdicionar() {
    this.props.history.push('/app/safra/novo');
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnClickOutside: false,
            title: provider.intl.formatMessage({ id: 'excluindo' })
          }
        );
        Safra.deleteSafrasById(this.state.itemSelect.idSafra)
          .then(() => {
            swal(
              provider.intl.formatMessage({
                id: 'safraRemovidoComSucesso'
              }),
              {
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                }
              }
            ).then(() => {
              this.atualizarRegistros(this.state.itemSelect.idSafra);
            });
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: 'falhaAoExcluirSafra' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    });
  }

  atualizarRegistros(dataSafra) {
    var ajusteRegistros = this.state.dataAppSync.filter(data => {
      return dataSafra !== data.idSafra;
    });

    this.setState({
      dataAppSync: ajusteRegistros,
      dataInTable: ajusteRegistros
    });

    this.onFilterChanged(this.props.chipData);
  }

  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div className={classes.card}>
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationTypeString}
            usePaddings={false}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            viewKey='safra'
          />
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disabledEdit}
            onClickDelete={this.clickExcluir}
            onClickEdit={this.clickEditar}
          />

          <div className={classes.cardGrid}>
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={this.clickAdicionar} />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'safra';
  })[0].content
});

Safras.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(Safras));
export default withRouter(connect(mapStateToProps)(enhaced));
