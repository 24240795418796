import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import formatMessage from '../i18n/formatMessage';
import Grid from '@material-ui/core/Grid';
import Card from '@components/Card/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { formatoDataIntercionalizado } from '@utils/languageUtils';
import SeveridadeEnum from '@resources/SeveridadeEnum';
import DataTable from '@components/DataTable/DataTable';

const style = theme => ({
  container: {
    width: '100%',
    maxHeight: 'calc(100vh - 95px)'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 265px)',
    minHeight: 250,
    marginTop: 15
  }
});

const severidades = [
  {
    id: SeveridadeEnum.DOENCA_SOLO,
    name: formatMessage('plantios.doencaSolo')
  },
  {
    id: SeveridadeEnum.DOENCA_FOLIAR,
    name: formatMessage('plantios.doencaFoliar')
  },
  {
    id: SeveridadeEnum.NEMATOIDE,
    name: formatMessage('plantios.nematoide')
  },
  {
    id: SeveridadeEnum.PRAGAS,
    name: formatMessage('plantios.pragas')
  }
];

const headDatatable = [
  {
    valueGetter: args => severidades[args.node.data.severidade.tpSeveridade].name,
    headerName: formatMessage('plantios.tipo'),
    col: 4
  },
  {
    field: 'severidade.nmSeveridade',
    headerName: formatMessage('plantios.agenteCausal'),
    col: 4
  },
  {
    valueGetter: args => moment(args.node.data.dtSeveridade).format(formatoDataIntercionalizado()),
    headerName: formatMessage('plantios.data'),
    col: 4
  }
];

/**
 * Componente da tab de Alertas Fitossanitarios do cadastro de Plantio
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
const AlertasFitossanitarios = props => {
  const { classes, stInvasoras, plantioSeveridadeList } = props;

  return (
    <div key={0} className={classes.container}>
      <Card
        withOutBorderRadius
        title={formatMessage('plantios.alertasFitossanitarios')}
        height='auto'
        style={{ minHeight: 'calc(100vh - 82px)', maxHeight: 'calc(100vh - 82px)', marginBottom: 15 }}
      >
        <Grid container>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox disabled checked={stInvasoras === 1} name='checkedUsuarios' color='primary' />
              }
              label={formatMessage('plantios.invasoras')}
              style={{ fontSize: 14 }}
            />
          </Grid>
        </Grid>

        <div className={classes.grid}>
          <DataTable
            data={plantioSeveridadeList}
            columns={headDatatable}
            showPagination={false}
            borderRadius={false}
            noSelection
            noMargin
          />
        </div>
      </Card>
    </div>
  );
};

export default withStyles(style)(AlertasFitossanitarios);
