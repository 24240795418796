export default {
  bactericidasMicrobiologicos: {
    bactericidasMicrobiologicosRemovidoComSucesso: 'Bactericida microbiológico removido com sucesso',
    falhaAoExcluirBactericidasMicrobiologicos: 'Falha ao excluir bactericida microbiológico',
    desejaExcluir: 'Deseja excluir o bactericida microbiológico selecionado?',
    bactericidasMicrobiologicos: 'Bactericidas microbiológicos',
    cadastroDeBactericidasMicrobiologicos: 'Cadastro de bactericidas microbiológicos',
    identificacaoDaBactericidasMicrobiologicos: 'Identificação',
    bactericidasMicrobiologicosCadastradoComSucesso: 'Bactericida microbiológico cadastrado com sucesso',
    bactericidasMicrobiologicosEditadoComSucesso: 'Bactericida microbiológico editado com sucesso',
    falhaAoEditarBactericidasMicrobiologicos: 'Falha ao editar bactericida microbiológico',
    falhaAoCadastrarBactericidasMicrobiologicos: 'Falha ao cadastrar bactericida microbiológico',
    falhaAoCarregarBactericidasMicrobiologicos: 'Falha ao carregar bactericidas microbiológicos'
  }
};
