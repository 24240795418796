import React, { useState, useEffect } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import Usuario from '@resources/Usuario';
import Produtores from '@resources/Produtores';
import { FormattedMessage } from 'react-intl';
import CardsWithFilters from '@components/CardFilters/CardsWithFilters';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {
  card: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 15px)'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  page: {
    marginBottom: 15
  },
  document: {
    width: '100%'
  },
  divDocument: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
};

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const relatorio = messagesLinguage['relatorio.relatorio'];
const regiao = messagesLinguage['relatorio.regiao'];
const municipio = messagesLinguage['relatorio.municipio'];
const sim = messagesLinguage['relatorio.sim'];
const nao = messagesLinguage['relatorio.nao'];
const carregando = messagesLinguage['relatorio.carregando'];
const gerandoRelatorio = messagesLinguage['relatorio.gerandoRelatorio'];
const falhaAoGerarRelatorio = messagesLinguage['relatorio.falhaAoGerarRelatorio'];
const fichaProdutor = messagesLinguage['relatorio.cadastroDeProdutor'];
const tecnicoResponsavel = messagesLinguage['relatorio.tecnicoResponsavel'];
const title = messagesLinguage['relatorio.title'];
const crescente = messagesLinguage['relatorio.crescente'];
const decrescente = messagesLinguage['relatorio.decrescente'];
const produtor = messagesLinguage['relatorio.produtor'];
const uf = messagesLinguage['relatorio.uf'];
const minimoFiltro = messagesLinguage['relatorio.minimoFiltro'];
const mensagemSemFiltro = messagesLinguage['relatorio.mensagemSemFiltro'];
const exportarPlanilha = messagesLinguage['relatorio.exportarPlanilha'];

const intlProvider = new IntlProvider({
  locale: getUserLang(),
  messages: {
    relatorio,
    regiao,
    municipio,
    sim,
    nao,
    carregando,
    gerandoRelatorio,
    falhaAoGerarRelatorio,
    fichaProdutor,
    tecnicoResponsavel,
    title,
    crescente,
    decrescente,
    produtor,
    uf,
    minimoFiltro,
    mensagemSemFiltro,
    exportarPlanilha
  }
});

provider = intlProvider.getChildContext();

const listChecks = [
  { title: 'Áreas de cultivo', check: true, key: 1, field: 'areaCultivo' },
  { title: 'Talhões', check: true, key: 2, field: 'talhao', disabled: false },
  { title: 'Familiares', check: true, key: 3, field: 'familiar' },
  { title: 'Colaboradores', check: true, key: 4, field: 'colaborador' },
  { title: 'Produção animal', check: true, key: 5, field: 'producaoAnimal' },
  { title: 'Ambiental', check: true, key: 6, field: 'ambiental' }
];

const operationTypeString = [
  {
    label: provider.intl.formatMessage({ id: 'municipio' }),
    field: 'nmMunicipio',
    list: 'listMunicipio',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'regiao' }),
    field: 'nmRegiao',
    list: 'listRegiao',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'produtor' }),
    field: 'nmProdutor',
    list: 'listProdutor',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'tecnicoResponsavel' }),
    field: 'nmTecnico',
    list: 'listTecnicos',
    type: 'autocomplete'
  }
];

/**
 * Tela de Relatório de análise de riscos sumarizado
 *
 * @author Gabriela Farias
 * @class Relatorio
 * @extends {Component} - Componente React
 */
function FichaProdutor(props) {
  const [filePDF, setFilePDF] = useState(null);
  const [fileXLS, setFileXLS] = useState(null);
  const [closeCard, setCloseCard] = useState(false);
  const [errorCard, setErrorCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDados, setLoadingDados] = useState(true);
  const [listTecnicos, setListTecnicos] = useState([]);
  const [listMunicipio, setListMunicipio] = useState([]);
  const [listProdutor, setListProdutor] = useState([]);
  const [listRegiao, setListRegiao] = useState([]);
  const [fileCSV, setFileCSV] = useState(null);
  const { classes } = props;

  useEffect(() => {
    const title = 'relatorio.cadastroDeProdutor';
    if (props.itensState.name !== 'relatorio.cadastroDeProdutor') {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    listChecks.forEach(doc => {
      doc.check = true;
    });

    Promise.all([
      Usuario.findAllUsuarios()
        .then(query => {
          let data = [];
          query.data.forEach(doc => {
            if (doc.tpPerfil === 2) {
              data.push(doc);
            }
          });

          setListTecnicos(data);
        })
        .catch(err => {
          setListTecnicos([]);
        }),
      Regioes.findAllRegiao()
        .then(query => {
          setListRegiao(query.data);
        })
        .catch(() => {
          setListRegiao([]);
        }),
      Produtores.findProdutor()
        .then(query => {
          setListProdutor(query.data);
        })
        .catch(() => {
          setListProdutor([]);
        }),
      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          setListMunicipio(query.data);
        })
        .catch(err => {
          setListMunicipio([]);
        })
    ])
      .then(() => {
        setLoadingDados(false);
      })
      .catch(() => {
        setLoadingDados(false);
      });
  }, []);

  const clickGerarRelatorio = value => {
    if (value.length === 0) {
      swal({
        title: provider.intl.formatMessage({ id: 'minimoFiltro' }),
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        }
      });
    } else {
      const chips = value;

      let nmProdutor = chips.find(item => {
        return item.filter.field === 'nmProdutor';
      });

      if (nmProdutor === undefined) {
        swal({
          title: provider.intl.formatMessage({ id: 'mensagemSemFiltro' }),
          icon: 'warning',
          closeOnClickOutside: false,
          closeOnEsc: true,
          buttons: {
            cancel: {
              text: provider.intl.formatMessage({ id: 'nao' }),
              value: false,
              visible: true,
              closeModal: true,
              className: 'swal2-cancel'
            },
            confirm: {
              text: provider.intl.formatMessage({ id: 'sim' }),
              value: true,
              visible: true,
              closeModal: false,
              className: 'swal2-confirm'
            }
          }
        }).then(willDelete => {
          if (willDelete) {
            swal.close();
            gerarRelatorio(chips, nmProdutor);
          }
        });
      } else {
        gerarRelatorio(chips, nmProdutor);
      }
    }
  };

  const gerarRelatorio = (chips, nmProdutor) => {
    setErrorCard(false);
    setCloseCard(true);
    setLoading(true);
    setFilePDF(null);
    setFileXLS(null);
    setFileCSV(null);

    let municipio = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    if (municipio !== undefined) {
      municipio = 'idMunicipio:' + municipio.value.value + ';';
    } else {
      municipio = '';
    }

    let regiao = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    if (regiao !== undefined) {
      regiao = 'idRegiao:' + regiao.value.value + ';';
    } else {
      regiao = '';
    }

    let nmTecnico = chips.find(item => {
      return item.filter.field === 'nmTecnico';
    });

    if (nmTecnico !== undefined) {
      nmTecnico = 'idTecnico:' + nmTecnico.value.value + ';';
    } else {
      nmTecnico = '';
    }

    if (nmProdutor !== undefined) {
      nmProdutor = 'idProdutor:' + nmProdutor.value.value + ';';
    } else {
      nmProdutor = '';
    }

    let filters = municipio + regiao + nmTecnico + nmProdutor + '&subRelatorios=';

    listChecks.forEach(doc => {
      if (doc.check) {
        filters = filters + doc.field + ';';
      }
    });

    Relatorios.gerarFichaProdutor(filters)
      .then(doc => {
        setCloseCard(false);
        setFileXLS(doc.data.dsLinkXls);
        setFilePDF(doc.data.dsLinkPdf);
        setFileCSV(doc.data.dsLinkCsv);
      })
      .catch(err => {
        modalErrorRelatorio();
      });
  };

  const modalErrorRelatorio = () => {
    setErrorCard(true);
    setCloseCard(false);
    setLoading(false);
    setFileXLS(null);
    setFilePDF(null);
    setFileCSV(null);
    setErrorCard(false);

    swal(provider.intl.formatMessage({ id: 'falhaAoGerarRelatorio' }), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  };

  return (
    <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
      <div className={classes.card}>
        {(loading || loadingDados) && <Loading />}
        <CardsWithFilters
          errorCard={errorCard}
          closeCard={closeCard}
          usePaddings={false}
          abaWithChecks
          relatorio
          viewKey={'ficha-produtor'}
          itens={[
            {
              autoComplete: {
                listTecnicos: {
                  list: listTecnicos,
                  campoOp: 'nmUsuario',
                  campoChave: 'idUsuario'
                },
                listMunicipio: {
                  list: listMunicipio,
                  campoOp: 'nmMunicipio',
                  campoChave: 'idMunicipio'
                },
                listRegiao: {
                  list: listRegiao,
                  campoOp: 'nmRegiao',
                  campoChave: 'idRegiao'
                },
                listProdutor: {
                  list: listProdutor,
                  campoOp: 'nmProdutor',
                  campoChave: 'idProdutor'
                }
              },
              onClickButton: clickGerarRelatorio,
              filtros: operationTypeString,
              viewKey: 'ficha-produtor',
              keyCard: 1,
              onFilter: () => {},
              onFilterChanged: () => {},
              relatorio: true,
              withoutButtonFiltrar: true,
              contain: props.chipData.find(data => {
                return data.id === 'ficha-produtor';
              }).content
            },
            {
              contain: [],
              viewKey: 'ficha-produtor',
              onClickButton: clickGerarRelatorio,
              filtros: [],
              keyCard: 2,
              onFilter: () => {},
              onFilterChanged: () => {},
              relatorio: true,
              listChecks: listChecks,
              withoutLabelTipo: true
            }
          ]}
        />

        {loading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 20,
              justifyContent: 'center'
            }}
          >
            {provider.intl.formatMessage({ id: 'gerandoRelatorio' })}
          </div>
        )}

        <PDFViewer
          withoutPosition={true}
          loadSucess={() => {
            setLoading(false);
          }}
          errorCard={errorCard}
          fileXLS={fileXLS}
          filePDF={filePDF}
          additionalActions={[
            {
              icon: (
                <FontAwesomeIcon
                  style={{ color: 'rgb(255, 255, 255)', width: 18, height: 18 }}
                  icon={faFileCsv}
                />
              ),
              name: provider.intl.formatMessage({ id: 'exportarPlanilha' }),
              file: fileCSV,
              color: '#42ADE8'
            }
          ]}
        />
      </div>
    </IntlProvider>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'ficha-produtor';
  })
});

FichaProdutor.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(FichaProdutor));
export default withRouter(connect(mapStateToProps)(enhaced));
