import URL from './URL';

const axios = require('axios');
const API = URL.API + '/medida-corretiva/';

function findAllMedidaCorretivas() {
  return axios.get(API);
}

function findMedidaCorretivaById(idMedidaCorretiva) {
  return axios.get(API + idMedidaCorretiva);
}

function deleteMedidaCorretivaById(idMedidaCorretiva) {
  return axios.delete(API + idMedidaCorretiva);
}

function addMedidaCorretiva(values) {
  return axios.post(API, values);
}

function editMedidaCorretiva(values) {
  return axios.put(API, values);
}

export {
  findAllMedidaCorretivas,
  findMedidaCorretivaById,
  deleteMedidaCorretivaById,
  addMedidaCorretiva,
  editMedidaCorretiva
};
