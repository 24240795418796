import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import EditarProducaoAnimal from './EditarProducaoAnimal';
import swal from '@sweetalert/with-react';
import moment from 'moment';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import CardTitle from '@components/CardTitle/CardTitle';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = theme => ({
  root: {
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 95px)',
    marginBottom: 15
  }
});

function formatTextField() {
  switch (getUserLang()) {
    case 'pt-BR':
      return 'DD/MM/YYYY';
    case 'es-ES':
      return 'DD-MM-YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}

/**
 * Componente para listagem da tab Familiares
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
class ProducaoAnimal extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const nome = messagesLinguage['produtores.nome'];
    const sexo = messagesLinguage['produtores.sexo'];
    const dataNascimento = messagesLinguage['produtores.dataNascimento'];
    const escolaridade = messagesLinguage['produtores.escolaridade'];
    const masculino = messagesLinguage['produtores.masculino'];
    const feminino = messagesLinguage['produtores.feminino'];
    const familiares = messagesLinguage['produtores.familiares'];
    const producaoAnimal = messagesLinguage['produtores.producaoAnimal'];
    const especie = messagesLinguage['produtores.especie'];
    const quantidade = messagesLinguage['produtores.nrQuantidade'];
    const tipo = messagesLinguage['produtores.tipo'];
    const desejaExcluirProducaoAnimal = messagesLinguage['produtores.desejaExcluirProducaoAnimal'];
    const producaoAnimalRemovidoComSucesso =
      messagesLinguage['produtores.producaoAnimalRemovidoComSucesso'];
    const excluir = messagesLinguage['produtores.excluir'];
    const nao = messagesLinguage['produtores.nao'];
    const raca = messagesLinguage['produtores.raca'];
    const dataLancamento = messagesLinguage['produtores.dataLancamento'];
    const organico = messagesLinguage['produtores.organico'];
    const convencional = messagesLinguage['produtores.convencional'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          nome,
          sexo,
          dataNascimento,
          escolaridade,
          masculino,
          feminino,
          familiares,
          producaoAnimal,
          especie,
          quantidade,
          tipo,
          desejaExcluirProducaoAnimal,
          producaoAnimalRemovidoComSucesso,
          excluir,
          nao,
          raca,
          dataLancamento,
          organico,
          convencional
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      dataProducaoAnimal: this.props.data || [],
      headDatatable: [
        {
          field: 'racaAnimal.especieAnimal.dsEspecieAnimal',
          headerName: provider.intl.formatMessage({ id: 'especie' }),
          col: 3
        },
        {
          field: 'racaAnimal.dsRacaAnimal',
          headerName: provider.intl.formatMessage({ id: 'raca' }),
          col: 2
        },
        {
          field: 'nrQuantidade',
          headerName: provider.intl.formatMessage({ id: 'quantidade' }),
          col: 2
        },
        {
          field: 'nmTipo',
          headerName: provider.intl.formatMessage({ id: 'tipo' }),
          col: 2
        },
        {
          valueGetter: args => moment(args.node.data.dtLancamento).format(formatTextField()),
          headerName: provider.intl.formatMessage({ id: 'dataLancamento' }),
          col: 2,
          sort: 'desc'
        }
      ],
      disabledButtons: true,
      itemSelect: {},
      itemSelectProducao: {},
      atualizando: false,
      data: [],
      keyGrid: '',
      index: 0
    };

    this.selectChange = this.selectChange.bind(this);
    this.onSaveProducao = this.onSaveProducao.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
  }

  componentDidMount() {
    var data = this.props.data.map(doc => {
      doc.nmTipo =
        doc.tpProducao === 0
          ? provider.intl.formatMessage({
              id: 'organico'
            })
          : provider.intl.formatMessage({
              id: 'convencional'
            });

      return doc;
    });

    this.setState({
      dataProducaoAnimal: data
    });
  }

  componentWillUnmount() {
    this.props.changeLista(this.state.dataProducaoAnimal);

    if (this.state.index === 1) {
      this.props.changeFAB();
    }
  }

  selectChange(selected) {
    if (this.state.atualizando) return;

    if (selected) {
      this.setState({ itemSelect: selected, disabledButtons: false });
    } else {
      this.setState({ itemSelect: '', disabledButtons: true });
    }
  }

  /**
   * Executa ao clicar em editar uma linha da tabela
   */
  clickEditar() {
    this.setState(
      {
        itemSelectProducao: this.state.itemSelect,
        statusEdit: true,
        index: 1
      },
      this.props.changeFAB
    );
  }

  clickExcluir(item) {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluirProducaoAnimal' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        this.props.producaoAnimalDeleted(this.state.itemSelect, this.state.dataProducaoAnimal);
        let data = this.state.dataProducaoAnimal;
        let position = data.indexOf(this.state.itemSelect);

        data.splice(position, 1);
        data = [...data];

        this.setState({ dataProducaoAnimal: data });

        swal(
          provider.intl.formatMessage({
            id: 'producaoAnimalRemovidoComSucesso'
          }),
          {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          }
        );
      } else {
        this.setState({ disabledEdit: false });
      }
    });
  }

  onSaveProducao(values, position) {
    let data = this.state.dataProducaoAnimal;

    if (position !== undefined) {
      data[position] = values;
      data = [...data];
    } else {
      data = data.concat(values);
    }

    this.setState(
      {
        itemSelectProducao: {},
        dataProducaoAnimal: data,
        statusEdit: false,
        index: 0
      },
      this.selectChange('') || this.props.saveProducaoAnimal(data, 'producaoAnimal')
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <SwipeableViewsOwn index={this.state.index} onChangeIndex={index => this.setState({ index })}>
          {[
            <div className={classes.tab} key={0}>
              <CardTitle
                containerStyle={{ marginBottom: this.props.tpPerfil ? 0 : 'none' }}
                title={provider.intl.formatMessage({ id: 'producaoAnimal' })}
              />

              {!this.props.tpPerfil && (
                <GroupButtonGrid
                  hiddenMargin
                  withoutMargin
                  showEdit
                  showDelete
                  showAdd
                  disableEdit={this.state.disabledButtons || this.state.statusEdit}
                  disableDelete={this.state.disabledButtons || this.state.statusEdit}
                  onClickEdit={this.clickEditar}
                  onClickDelete={this.clickExcluir}
                  onClickAdd={() => this.setState({ index: 1 }, this.props.changeFAB)}
                />
              )}

              <DataTable
                key={this.state.keyGrid}
                noMargin={true}
                borderRadius={false}
                data={this.state.dataProducaoAnimal}
                columns={this.state.headDatatable}
                selectChange={this.selectChange}
                showPagination={false}
              />
            </div>,

            <EditarProducaoAnimal
              key={1}
              onClose={() =>
                this.setState(
                  {
                    statusEdit: false,
                    itemSelect: '',
                    disabledButtons: true,
                    keyGrid: Math.random(),
                    itemSelectProducao: {},
                    index: 0
                  },
                  this.props.changeFAB
                )
              }
              dataProducaoAnimal={this.state.dataProducaoAnimal}
              itemSelectProducao={this.state.itemSelectProducao}
              statusEdit={this.state.statusEdit}
              onSaveProducao={this.onSaveProducao}
              clickExcluir={this.clickExcluir}
            />
          ]}
        </SwipeableViewsOwn>
      </div>
    );
  }
}

export default withStyles(styles)(ProducaoAnimal);
