const style = theme => ({
  filters: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'hidden'
  },
  cardAllFotos: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: 15,
    padding: '20px 0px 20px 30px',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden scroll'
  },
  image: {
    width: 140,
    height: 140,
    border: '5px solid transparent',
    objectFit: 'contain'
  },
  cardFoto: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 170,
    color: 'rgba(0, 0, 0, 0.5)',
    height: 220,
    fontWeight: '400',
    justifyContent: 'space-between',
    marginRight: 30,
    marginBottom: 30,
    cursor: 'pointer'
  },
  cardCollapse: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 315px)',
    width: '100%'
  },
  cardDetalhesOpen: {
    position: 'absolute',
    left: '100%',
    transform: 'translate(-105%, 0%)',
    overflow: 'scroll',
    width: 335,
    height: 'calc(100% - 355px)',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    backgroundColor: '#FFFFFF',
    margin: '0px 20px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200
    })
  },
  cardDetalhesClose: {
    position: 'absolute',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200
    }),
    width: 0
  },
  textField: {
    width: '100%',
    height: 50
  },
  cardDetalhes: {
    padding: '20px 10px',
    color: 'rgba(0, 0, 0, 0.8)',
    lineHeight: 1
  },
  textDetalhes: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px dotted rgb(0, 0, 0, 0.5)',
    padding: '8px 0px',
    height: 17
  },
  fotoDetalhes: {
    height: 90,
    width: 90,
    objectFit: 'contain'
  },
  textInformation: {
    width: 'calc(100% - 80px)',
    justifyContent: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textLabel: {
    fontSize: 12,
    width: 100
  },
  cardImageDetalhes: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15
  },
  cardLabelChips: {
    display: 'contents',
    flexDirection: 'column',
    padding: '8px 0px'
  },
  cardChips: {
    display: 'contents',
    width: 100
  },
  chip: {
    backgroundColor: '#E1E1E1',
    border: 'none',
    borderRadius: 10,
    fontSize: 12,
    color: '#676767',
    marginRight: 5,
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 0
  },
  descricaoFoto: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
  detalhesImage: {
    display: 'block',
    width: '100%',
    marginTop: 3
  },
  textDetalhesDescricao: {
    display: 'flex',
    borderBottom: '1px dotted #000000',
    padding: '8px 0px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 17
  },
  fotoSelected: {
    position: 'absolute',
    backgroundColor: 'rgb(0, 0, 0, 0.8)',
    width: 'calc(100% - 383px)',
    height: 'calc(100% - 355px)'
  },
  cardFotoSelected: {
    padding: 30,
    width: 'calc(100% - 60px)',
    height: 'calc(100% - 60px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -45
  },
  fotoDestaque: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  closeFotoDestaque: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 10px'
  },
  icon: {
    cursor: 'pointer',
    color: '#FFFFFF'
  },
  iconZip: {
    width: 'calc(100% - 80px)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -35
  }
});

export { style };
