import React from 'react';
import { BorderColor, SaveAlt, BugReport, EventNote, CameraAlt, Toys } from '@material-ui/icons';
import TipoAcompanhamentoEnum from '../enumerations/TipoAcompanhamentoEnum';

/**
 * Lista de todos os buttons do FAB para cadastro
 */
export default [
  {
    name: 'cadastrarAplicacao',
    tpAcompanhamento: TipoAcompanhamentoEnum.APLICACAO,
    icon: <BorderColor style={{ color: 'white' }} />
  },
  {
    name: 'cadastrarRecomendacao',
    tpAcompanhamento: TipoAcompanhamentoEnum.RECOMENDACAO,
    icon: <SaveAlt style={{ color: 'white' }} />
  },
  {
    name: 'cadastrarAlerta',
    tpAcompanhamento: TipoAcompanhamentoEnum.ALERTA_FITOSSANITARIO,
    icon: <BugReport style={{ color: 'white' }} />
  },
  {
    name: 'cadastrarMonitoramento',
    tpAcompanhamento: TipoAcompanhamentoEnum.MONITORAMENTO,
    icon: <EventNote style={{ color: 'white' }} />
  },
  {
    name: 'cadastrarRegistroFotografico',
    tpAcompanhamento: TipoAcompanhamentoEnum.REGISTRO_FOTOGRAFICO,
    icon: <CameraAlt style={{ color: 'white' }} />
  },
  {
    name: 'cadastrarRegistroClimatico',
    tpAcompanhamento: TipoAcompanhamentoEnum.REGISTRO_CLIMATICO,
    icon: <Toys style={{ color: 'white' }} />
  }
];
