export default {
  medidaCorretiva: {
    descricao: 'Descripción',
    dataCadastro: 'Fecha de Registro',
    erroAoBuscarDados: 'No se pudieron recuperar los datos',
    medidaCorretiva: 'Medida correctiva',
    desejaExcluirMedidaCorretiva: '¿Quieres eliminar esta medida correctiva?',
    nao: 'No',
    sim: 'Si',
    excluir: 'Eliminar',
    medidaCorretivaExcluida: 'Medida correctiva eliminada correctamente',
    erroAoExcluir: 'No se pudo borrar la medida correctiva',
    cadastroDeMedidaCorretiva: 'Registro de medida correctiva orgánico',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    salvar: 'Ahorrar',
    cancelar: 'Cancelar',
    medidaCorretivaSalvoComSucesso: 'Medida correctiva guardada con éxito',
    erroAoSalvar: 'No se pudo guardar la medida correctiva',
    salvando: 'Saving',
    atualizando: 'Actualizando',
    produtorRelacionado:
      'No fue posible excluir esta medida correctiva porque hay un productor usándola',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nombre',
    sancaoCategoria: 'Categoría sansón',
    situacao: 'Situación',
    geraAtividade: 'Genera actividad',
    naoConfirmidade: 'Incumplimiento'
  }
};
