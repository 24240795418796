import React from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatMessage from '../i18n/formatMessage';
import { style } from '../Clientes.styles';
import CardTitle from '@components/CardTitle/CardTitle';

const headProdutor = [
  {
    field: 'nmUsuario',
    headerName: formatMessage('clientes.nome'),
    col: 6
  },
  {
    field: 'dsEmail',
    headerName: formatMessage('clientes.email'),
    col: 6
  }
];

/**
 * Tab da lista de produtores
 * @author: Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function Produtor(props) {
  const { classes, produtorList } = props;

  return (
    <div className={classes.card}>
      <CardTitle containerStyle={{ marginBottom: -2 }} title={formatMessage('clientes.produtoresComAcesso')} />

      <DataTable
        usePaddings={false}
        data={produtorList}
        columns={headProdutor}
        selectChange={() => {}}
        showPagination={true}
        pagination={{
          length: produtorList.length,
          page: 0
        }}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(Produtor);
export default withRouter(connect(mapStateToProps)(enhaced));
