// Autor: Gabriela Farias
import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core';

const styles = () => ({
  card: {
    overflow: 'visible',
    width: '100%',
    marginBottom: 15,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  rodaPe: {
    padding: 15,
    paddingRight: 30,
    justifyContent: 'flex-end',
    display: 'flex',
    height: 60,
    borderTopColor: 'rgb(0, 0, 0, 0.1)',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    alignItems: 'center'
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiTypography: {
      headline: {
        color: '#555555',
        fontSize: 24
      },
      h5: {
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.7)'
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    default: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class CardCadastro extends React.Component {
  render() {
    var { titulo, classes, children, botoes, disablePaddingRight = false } = this.props;
    var listaBotoes = botoes || [];
    return (
      <MuiThemeProvider theme={theme}>
        <Card style={{ borderRadius: this.props.borderRadius ? 5 : 0 }} className={classes.card}>
          <div
            style={{
              height: this.props.height || '80%',
              display: this.props.height ? 'flex' : '',
              flexDirection: this.props.height ? 'column' : '',
              ...this.props.styleHeader
            }}
          >
            <CardHeader
              style={{
                paddingLeft: this.props.marginLeft,
                display: this.props.hiddenTitle ? 'none' : 'flex',
                backgroundColor: 'rgb(139, 200, 165, 0.1)'
              }}
              title={titulo}
            />

            <CardContent
              style={{
                marginTop: 5,
                height: '100%',
                paddingBottom: 0,
                display: 'flex',
                flexDirection: 'column',
                ...(disablePaddingRight ? { paddingRight: 0 } : {})
              }}
              id='cardContent'
            >
              {children}
            </CardContent>
          </div>

          <div className={classes.rodaPe}>
            {listaBotoes.map((botao, key) => (
              <div key={key}>{botao}</div>
            ))}
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}
export default withStyles(styles)(CardCadastro);
