export default {
  fungicida: {
    fungicidaRemovidoComSucesso: 'Fungicida removido com sucesso',
    falhaAoExcluirFungicida: 'Falha ao excluir fungicida',
    desejaExcluir: 'Deseja excluir o fungicida selecionado?',
    fungicida: 'Fungicida',
    identificacaoDaFungicida: 'Identificação',
    fungicidaCadastradoComSucesso: 'Fungicida cadastrado com sucesso',
    fungicidaEditadoComSucesso: 'Fungicida editado com sucesso',
    falhaAoEditarFungicida: 'Falha ao editar fungicida',
    falhaAoCadastrarFungicida: 'Falha ao cadastrar fungicida',
    falhaAoCarregarFungicida: 'Falha ao carregar fungicida',
    campoObrigatorio: 'Campo obrigatório',
    cadastroDeFungicida: 'Cadastro de fungicidas'
  }
};
