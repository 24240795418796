import React from 'react';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import { Formik } from 'formik';
import { TextField, Grid } from '@material-ui/core';
import TimePicker from '@components/TimePicker/TimePicker';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import DatePicker from '@components/DatePicker/DatePicker';
import Chip from '@components/Chip/Chip';
import * as moment from 'moment';
import formatMessage from './i18n/formatMessage';
import { getUserLang } from '@utils/localeUtils';
import Autocomplete from '@components/Autocomplete/Autocomplete';

const icone_adicionarTalhao = require('@images/adicionar_talhao.png');

function CadastrarAgenda(props) {
  const {
    classes,
    openModal,
    index,
    statusEditar,
    itemSelect,
    agendaDay,
    viewDate,
    typeProdutor,
    keyParticipante,
    listParticipantes,
    onSalvar,
    infoUsuario,
    clickExcluir,
    filterParticipantesToSelect,
    handleAddChip,
    valueAutoCompleteParticipante,
    handleDeleteChip,
    onChangeParticipante,
    tratarValorSelectInitialParticipante,
    onClose
  } = props;

  function onChangeTextField(textField, value, setFieldValue) {
    setFieldValue(textField, value.target.value);
    var itemSelectAux = itemSelect;
    itemSelectAux[textField] = value.target.value;
  }

  return (
    <div
      style={{
        marginTop: '15px',
        width: '100%',
        flex: 1
      }}
      key={2}
    >
      {openModal && index === 2 && (
        <Formik
          initialValues={
            statusEditar
              ? {
                  data: moment(itemSelect.dtVisita),
                  horario: moment(itemSelect.dtVisita + 'T' + itemSelect.hrInicio),
                  horarioFinal: moment(itemSelect.dtVisita + 'T' + itemSelect.hrTermino),
                  dsObjetivo: itemSelect.dsObjetivo,
                  dsLocal: itemSelect.dsLocal,
                  dsEvento: itemSelect.dsEvento
                }
              : {
                  data: agendaDay ? viewDate : null,
                  horario: null,
                  horarioFinal: null,
                  dsObjetivo: '',
                  dsLocal: '',
                  dsEvento: ''
                }
          }
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={values => onSalvar(values, true)}
          validationSchema={Yup.object().shape({
            data: Yup.string().required(formatMessage('agenda.campoObrigatorio')),
            horario: Yup.string().required(formatMessage('agenda.campoObrigatorio')),
            horarioFinal: Yup.string().required(formatMessage('agenda.campoObrigatorio')),
            dsObjetivo: Yup.string().required(formatMessage('agenda.campoObrigatorio')),
            dsLocal: Yup.string().required(formatMessage('agenda.campoObrigatorio')),
            dsEvento: Yup.string().required(formatMessage('agenda.campoObrigatorio'))
          })}
          render={({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldError
          }) => (
            <div
              style={{
                width: '100%',
                flex: 1,
                height: '100%',
                display: 'flex',
                marginTop: -15
              }}
            >
              <CardCadastro
                titulo={formatMessage('agenda.agendarEvento')}
                botoes={[
                  !typeProdutor && statusEditar && itemSelect.tecnico.dsEmail === infoUsuario.dsEmail && (
                    <Button
                      style={{
                        display: statusEditar ? 'flex' : 'none'
                      }}
                      color='primary'
                      variant='contained'
                      onClick={clickExcluir}
                    >
                      {formatMessage('agenda.excluir')}
                    </Button>
                  ),
                  <Button style={{ width: 100 }} color='inherit' variant='contained' onClick={onClose}>
                    {formatMessage('agenda.cancelar')}
                  </Button>,
                  !typeProdutor && (itemSelect.tecnico.dsEmail === infoUsuario.dsEmail || !statusEditar) && (
                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={
                        values.horario < values.horarioFinal
                          ? handleSubmit
                          : () => {
                              setFieldError('data', values.data === null);
                              setFieldError('produtor', values.produtor === '');
                              setFieldError('horario', values.horario === null);
                              setFieldError('dsEvento', values.dsEvento === '');
                              setFieldError('dsLocal', values.dsLocal === '');
                              setFieldError('dsObjetivo', values.dsObjetivo === '');
                            }
                      }
                    >
                      {formatMessage('agenda.confirmar')}
                    </Button>
                  )
                ]}
              >
                <form autoComplete='off'>
                  <Grid container spacing={16}>
                    <Grid item xs={2}>
                      <DatePicker
                        disabled={statusEditar && itemSelect.tecnico.dsEmail !== infoUsuario.dsEmail}
                        locale={getUserLang()}
                        style={{ height: 70 }}
                        className={classes.font}
                        label={formatMessage('agenda.data')}
                        valueDate={values.data}
                        errorTouched={errors.data && touched.data ? true : false}
                        helperText={formatMessage('agenda.campoObrigatorio')}
                        onBlurDatePicker={date => {
                          let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                          if (!validation) {
                            setFieldValue('data', null);
                          }
                        }}
                        onChangeDatePicker={date => {
                          setFieldValue('data', date);
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TimePicker
                        disabled={itemSelect.tecnico.dsEmail !== infoUsuario.dsEmail && statusEditar}
                        style={{ height: 70 }}
                        label={formatMessage('agenda.horario')}
                        value={values.horario}
                        errorTouched={errors.horario && touched.horario ? true : false}
                        helperText={formatMessage('agenda.campoObrigatorio')}
                        onBlur={() => {
                          setFieldValue('horario', null);
                        }}
                        onChange={horario => {
                          const date = moment(itemSelect.dtVisita).format('YYYY-MM-DD');

                          setFieldValue('horario', horario);
                          var validation = moment(date).set({
                            hour: '12',
                            minute: '00'
                          });
                          var validationLast = moment(date).set({
                            hour: '18',
                            minute: '00'
                          });
                          errors.horarioFinal = false;
                          touched.horarioFinal = false;

                          if (horario >= validationLast) {
                            setFieldValue(
                              'horarioFinal',
                              moment(date).set({
                                hour: '23',
                                minute: '59'
                              })
                            );
                          } else if (horario > validation) {
                            setFieldValue(
                              'horarioFinal',
                              moment(date).set({
                                hour: '18',
                                minute: '00'
                              })
                            );
                          } else {
                            setFieldValue('horarioFinal', validation);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={2} style={{ height: 70 }}>
                      <TimePicker
                        disabled={itemSelect.tecnico.dsEmail !== infoUsuario.dsEmail && statusEditar}
                        label={formatMessage('agenda.horarioFinal')}
                        value={values.horarioFinal}
                        errorTouched={errors.horarioFinal && touched.horarioFinal ? true : false}
                        helperText={
                          values.horarioFinal < values.horario
                            ? formatMessage('agenda.horarioInvalido')
                            : formatMessage('agenda.campoObrigatorio')
                        }
                        onBlur={() => {
                          setFieldValue('horarioFinal', null);
                        }}
                        onChange={horario => {
                          setFieldValue('horarioFinal', horario);

                          errors.horarioFinal = horario < values.horario;
                          touched.horarioFinal = horario < values.horario;
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        disabled={itemSelect.tecnico.dsEmail !== infoUsuario.dsEmail && statusEditar}
                        label={formatMessage('agenda.descricao')}
                        inputProps={{
                          maxLength: 100
                        }}
                        id='dsEvento'
                        name='dsEvento'
                        value={values.dsEvento}
                        className={classes.textField}
                        InputLabelProps={{ className: classes.font }}
                        onBlur={value => onChangeTextField('dsEvento', value, setFieldValue)}
                        onChange={value => onChangeTextField('dsEvento', value, setFieldValue)}
                        helperText={touched.dsEvento ? errors.dsEvento : ''}
                        error={errors.dsEvento && touched.dsEvento ? true : false}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item xs={6}>
                      <TextField
                        disabled={itemSelect.tecnico.dsEmail !== infoUsuario.dsEmail && statusEditar}
                        inputProps={{
                          maxLength: 100
                        }}
                        id='dsLocal'
                        name='dsLocal'
                        label={formatMessage('agenda.local')}
                        value={values.dsLocal}
                        className={classes.textField}
                        InputLabelProps={{ className: classes.font }}
                        onBlur={value => onChangeTextField('dsLocal', value, setFieldValue)}
                        onChange={value => onChangeTextField('dsLocal', value, setFieldValue)}
                        helperText={touched.dsLocal ? errors.dsLocal : ''}
                        error={errors.dsLocal && touched.dsLocal ? true : false}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        disabled={itemSelect.tecnico.dsEmail !== infoUsuario.dsEmail && statusEditar}
                        id='dsObjetivo'
                        name='dsObjetivo'
                        label={formatMessage('agenda.objetivo')}
                        value={values.dsObjetivo}
                        className={classes.textField}
                        InputLabelProps={{ className: classes.font }}
                        onBlur={value => onChangeTextField('dsObjetivo', value, setFieldValue)}
                        onChange={value => onChangeTextField('dsObjetivo', value, setFieldValue)}
                        helperText={touched.dsObjetivo ? errors.dsObjetivo : ''}
                        error={errors.dsObjetivo && touched.dsObjetivo ? true : false}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item xs={6}>
                      <Autocomplete
                        disabled={itemSelect.tecnico.dsEmail !== infoUsuario.dsEmail && statusEditar}
                        itens={filterParticipantesToSelect()}
                        campoOp={'nmUsuario'}
                        campoChave={'idUsuario'}
                        label={formatMessage('agenda.participante')}
                        name='participante'
                        onChangeOwn={onChangeParticipante}
                        value={tratarValorSelectInitialParticipante(valueAutoCompleteParticipante)}
                        key={keyParticipante}
                        valueAutoComplete={tratarValorSelectInitialParticipante(
                          valueAutoCompleteParticipante
                        )}
                        valueSelect={
                          tratarValorSelectInitialParticipante(valueAutoCompleteParticipante).nmUsuario
                        }
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <Button
                        disabled={
                          !valueAutoCompleteParticipante &&
                          itemSelect.tecnico.dsEmail !== infoUsuario.dsEmail &&
                          statusEditar
                        }
                        className={classes.button}
                        onClick={e => {
                          handleAddChip('listParticipantes');
                        }}
                      >
                        <img className={classes.iconButton} src={icone_adicionarTalhao} alt=' ' />
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs>
                      {listParticipantes.map((chip, i) => (
                        <Chip
                          key={i}
                          text={chip.nmUsuario}
                          onRemove={() => {
                            if (itemSelect.tecnico.dsEmail === infoUsuario.dsEmail) {
                              handleDeleteChip('listParticipantes', i);
                            }
                          }}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </form>
              </CardCadastro>
            </div>
          )}
        />
      )}
    </div>
  );
}

export default CadastrarAgenda;
