export default {
  sazonalidade: {
    sazonalidade: 'Sazonalidade',
    cadastroSazonalidade: 'Cadastro de sazonalidades',
    minimoSazonalidades: 'Adicione pelo menos 1 sazonalidade',
    sazonalidadeSalvaComSucesso: 'Sazonalidades salvas com sucesso',
    erroAoSalvarSazonalidades: 'Ocorreu um erro ao salvar as sazonalidades',
    sazonalidades: 'Sazonalidades',
    culturas: 'Culturas',
    descricao: 'Descrição',
    cultura: 'Cultura',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    campoObrigatorio: 'Campo obrigatório'
  }
};
