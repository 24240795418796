export default {
  tecnico: {
    tecnico: "Técnico",
    nome: "Nombre",
    email: "E-mail",
    telefone: "Teléfono",
    situacao: "Situación",
    ativo: "Activo",
    inativo: "Inactivo",
    campoObrigatorio: "Campo obligatorio",
    identificacao: "Identificación",
    cadastro: "Registro de Técnico",
    salvar: "Guardar",
    cancelar: "Cancelar",
    emailInvalido: "E-mail no válido",
    naoFoiPossivelInserir: "No se pudo insertar el técnico",
    salvando: "Salvando",
    atualizando: "Actualizando",
    cadastradoComSucesso: "Técnico registrado correctamente",
    editadoComSucesso: "Técnico editado con éxito",
    falhaAoCarregar: "Técnico editado con éxito",
    naoFoiPossivelEditar: "No se pudo editar técnico",
    emailDuplicado: "Este correo electrónico ya está en uso",
    numeroMaximoTecnicos: "Se ha alcanzado el límite técnico de tu plan",
    falhaAoExcluirTecnico: "Fallo al excluir técnico",
    agendaRelacionadaTecnico: "Hay uno o más horarios relacionados con el técnico",
    tecnicoExcluidoComSucesso: "Técnico eliminado con éxito",
    excluir: "Borrar",
    excluindo: "Excluyendo",
    desejaExcluir: "¿Desea eliminar?",
    nao: "No",
    telefoneInvalido: "Teléfono no válido"
  }
};
