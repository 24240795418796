import React, { Component } from 'react';
import formatMessage from './i18n/formatMessage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardFilters from '@components/CardFilters/CardFilters';
import { withStyles } from '@material-ui/core';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import Comunidade from '@resources/Comunidade';
import Cultura from '@resources/Cultura';
import Produtores from '@resources/Produtores';
import { styles } from './RelatorioHistoricoProdutividade.styles';

/**
 * Tela de Relatório de relação de talhões
 *
 * @author Gabriela Farias
 * @class RelatorioRelacaoTalhao
 * @extends {Component} - Componente React
 */
class RelatorioRelacaoTalhao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filePDF: null,
      fileXLS: null,
      closeCard: false,
      errorCard: false,
      loading: false,
      loadingDados: true,
      tecnicosList: [],
      municipioList: [],
      regiaoList: [],
      produtorList: [],
      ufList: [],
      comunidadeList: [],
      culturaList: [],
      operationTypeString: [
        {
          label: formatMessage('historicoProdutividade.produtor'),
          field: 'nmProdutor',
          list: 'produtorList',
          type: 'autocomplete'
        },
        {
          label: formatMessage('historicoProdutividade.regiao'),
          field: 'nmRegiao',
          list: 'regiaoList',
          type: 'autocomplete'
        },
        {
          label: formatMessage('historicoProdutividade.uf'),
          field: 'nmUf',
          list: 'ufList',
          type: 'autocomplete'
        },
        {
          label: formatMessage('historicoProdutividade.municipio'),
          field: 'nmMunicipio',
          list: 'municipioList',
          type: 'autocomplete'
        },
        {
          label: formatMessage('historicoProdutividade.comunidade'),
          field: 'nmComunidade',
          list: 'comunidadeList',
          type: 'autocomplete'
        },
        {
          label: formatMessage('historicoProdutividade.cultura'),
          field: 'nmCultura',
          list: 'culturaList',
          type: 'autocomplete'
        }
      ]
    };

    const title = 'historicoProdutividade.historicoProdutividade';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    this.clickGerarRelatorio = this.clickGerarRelatorio.bind(this);
    this.modalErrorRelatorio = this.modalErrorRelatorio.bind(this);
  }

  componentDidMount() {
    Promise.all([
      Regioes.findAllRegiao()
        .then(query => {
          this.setState({
            regiaoList: query.data
          });
        })
        .catch(() => {
          this.setState({
            regiaoList: []
          });
        }),

      Localizacao.findAllEstados()
        .then(query => {
          this.setState({
            ufList: query.data
          });
        })
        .catch(err => {
          this.setState({
            ufList: []
          });
        }),

      Comunidade.findAllComunidades()
        .then(query => {
          this.setState({
            comunidadeList: query.data
          });
        })
        .catch(err => {
          this.setState({
            comunidadeList: []
          });
        }),

      Cultura.findAllCultura()
        .then(query => {
          this.setState({
            culturaList: query.data
          });
        })
        .catch(err => {
          this.setState({
            culturaList: []
          });
        }),

      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          this.setState({
            municipioList: query.data
          });
        })
        .catch(err => {
          this.setState({
            municipioList: []
          });
        }),

      Produtores.findProdutor()
        .then(query => {
          this.setState({
            produtorList: query.data
          });
        })
        .catch(err => {
          this.setState({
            produtorList: []
          });
        })
    ])
      .then(() => {
        this.setState({
          loadingDados: false
        });
      })
      .catch(() => {
        this.setState({
          loadingDados: false
        });
      });
  }

  clickGerarRelatorio(chips) {
    this.setState({
      errorCard: false,
      closeCard: true,
      loading: true,
      fileXLS: null,
      filePDF: null
    });

    let municipio = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    let regiao = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    let nmProdutor = chips.find(item => {
      return item.filter.field === 'nmProdutor';
    });

    let nmComunidade = chips.find(item => {
      return item.filter.field === 'nmComunidade';
    });

    let nmUf = chips.find(item => {
      return item.filter.field === 'nmUf';
    });

    let nmCultura = chips.find(item => {
      return item.filter.field === 'nmCultura';
    });

    nmProdutor = nmProdutor !== undefined ? 'idProdutor:' + nmProdutor.value.value + ';' : '';
    regiao = regiao !== undefined ? 'idRegiao:' + regiao.value.value + ';' : '';
    municipio = municipio !== undefined ? 'idMunicipio:' + municipio.value.value + ';' : '';
    nmComunidade = nmComunidade !== undefined ? 'idComunidade:' + nmComunidade.value.value + ';' : '';
    nmUf = nmUf !== undefined ? 'idUf:' + nmUf.value.value + ';' : '';
    nmCultura = nmCultura !== undefined ? 'idCultura:' + nmCultura.value.value + ';' : '';

    const filters = municipio + regiao + nmProdutor + nmComunidade + nmUf + nmCultura;

    Relatorios.gerarRelatorioHistoricoProdutividade(filters)
      .then(doc => {
        this.setState({
          closeCard: false,
          fileXLS: doc.data.dsLinkXls,
          filePDF: doc.data.dsLinkPdf
        });
      })
      .catch(err => {
        this.modalErrorRelatorio();
      });
  }

  modalErrorRelatorio() {
    this.setState(
      {
        closeCard: false,
        errorCard: true,
        loading: false,
        fileXLS: null,
        filePDF: null
      },
      this.setState({ errorCard: false })
    );

    swal(formatMessage('historicoProdutividade.falhaAoGerarRelatorio'), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.card}>
        {(this.state.loading || this.state.loadingDados) && <Loading />}
        <CardFilters
          autoComplete={{
            municipioList: {
              list: this.state.municipioList,
              campoOp: 'nmMunicipio',
              campoChave: 'idMunicipio'
            },
            regiaoList: {
              list: this.state.regiaoList,
              campoOp: 'nmRegiao',
              campoChave: 'idRegiao'
            },
            produtorList: {
              list: this.state.produtorList,
              campoOp: 'nmProdutor',
              campoChave: 'idProdutor'
            },
            ufList: {
              list: this.state.ufList,
              campoOp: 'nmUf',
              campoChave: 'idUf'
            },
            comunidadeList: {
              list: this.state.comunidadeList,
              campoOp: 'nmComunidade',
              campoChave: 'idComunidade'
            },
            culturaList: {
              list: this.state.culturaList,
              campoOp: 'nmCultura',
              campoChave: 'idCultura'
            }
          }}
          onFilter={() => {}}
          onFilterChanged={() => {}}
          errorCard={this.state.errorCard}
          closeCard={this.state.closeCard}
          relatorio={true}
          onClickButton={this.clickGerarRelatorio}
          filtros={this.state.operationTypeString}
          usePaddings={false}
          viewKey='relatorio-historico-produtividade'
        />

        {this.state.loading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 20,
              justifyContent: 'center'
            }}
          >
            {formatMessage('historicoProdutividade.gerandoRelatorio')}
          </div>
        )}

        <PDFViewer
          loadSucess={() => {
            this.setState({
              loading: false
            });
          }}
          errorCard={this.state.errorCard}
          fileXLS={this.state.fileXLS}
          filePDF={this.state.filePDF}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'relatorio-historico-produtividade';
  })[0].content
});

let enhaced = withStyles(styles)(RelatorioRelacaoTalhao);
export default withRouter(connect(mapStateToProps)(enhaced));
