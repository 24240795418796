export default {
  acaricidaMicroBio: {
    acaricidaMicroBioRemovidoComSucesso: 'Microbiological acaricide successfully removed',
    falhaAoExcluiAcaricidaMicroBio: 'Failed to exclude microbiological acaricide',
    desejaExcluir: 'Do you want to delete the selected microbiological acaricide?',
    acaricidaMicroBio: 'Microbiological acaricide',
    cadastroDeAcaricidaMicroBio: 'Microbiological acaricide registration',
    identificacaoDaAcaricidaMicroBio: 'Identification',
    acaricidaMicroBioCadastradoComSucesso: 'Microbiological Acaricide successfully registered',
    acaricidaMicroBioEditadoComSucesso: 'Microbiological acaricide edited successfully',
    falhaAoEditarAcaricidaMicroBio: 'Failed to edit microbiological acaricide',
    falhaAoCadastrarAcaricidaMicroBio: 'Failed to register microbiological acaricide',
    falhaAoCarregarAcaricidaMicroBio: 'Failed to load microbiological acaricide'
  }
};
