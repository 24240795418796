import { createMuiTheme } from '@material-ui/core/styles';

const style = theme => ({
  labelCertificacao: {
    color: 'rgb(0, 0, 0, 0.7)',
    fontSize: 13,
    margin: '0px 0px 10px 10px'
  },
  textField: {
    width: '100%',
    height: '70px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  font: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  form: {
    marginRight: 10,
    width: '100%',
    margin: 0,
    height: '70px'
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  divModal: {
    padding: 30,
    height: 130,
    width: 350,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    paddingTop: 40
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    marginTop: 30,
    marginBottom: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  },
  container: {
    overflow: 'hidden'
  },
  cardScroll: {
    width: '100%',
    maxHeight: 'calc(100vh - 95px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    width: 'fit-content',
    cursor: 'pointer'
  },
  historico: {
    color: '#42ADE8',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  cardTitle: {
    width: '100%',
    color: 'rgb( 0, 0, 0, 0.7)',
    height: 60,
    display: 'flex',
    fontSize: 20,
    alignItems: 'center',
    fontWeight: 500,
    paddingLeft: 30,
    backgroundColor: 'rgba(139, 200, 165, 0.1)',
    marginBottom: -16
  },
  gridTable: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 490px)',
    minHeight: 250
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiSelect: {
      selectMenu: {
        minHeight: 10
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiInput: {
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        writeSpace: 'noWrap',
        textOverflow: 'ellipsis',
        fontStyle: 'normal !important'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.4
        }
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { style, theme };
