export default {
  cotacao: {
    filtro: "Filter",
    cotacoes: "Quotations",
    cultura: "Culture",
    filtrar: "Filter",
    cotacao: "Quotation",
    variacaoDePreco: "Price variation",
    selecione: "Select a culture",
    data: "Date",
    valor: "Value",
    campoObrigatorio: "Required field",
    cancelar: "Cancel",
    salvar: "Save",
    identificacaoDaCotacao: "Identification",
    cadastroDeCotacoes: "Quote record",
    falhaAoCarregarCotacao: "Failed charge fail registration",
    salvando: "Saving",
    atualizando: "Updating",
    cotacaoCadastradaComSucesso: "Quotation successfully registered",
    falhaAoCadastrarCotacao: "Failed to register quote",
    cotacaoEditadaComSucesso: "Quotation edited successfully",
    desejaExcluir: "Do you want to exclude quotation?",
    excluir: "Delete",
    nao: "No",
    excluindo: "Excluding",
    cotacaoRemovidaComSucesso: "Quotation removed successfully",
    falhaAoExcluirCotacao: "Failed to delete quote",
    rs: "US$"
  }
};
