import React from 'react';
import Grid from '@material-ui/core/Grid';
import formatMessage from './i18n/formatMessage';
import Card from '@components/Card/Card';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';

function LocalPlantio(props) {
  const {
    areasDeCultivo,
    handleChangeAreaCultivo,
    infoUsuario,
    filterTalhoesToSelect,
    closeTalhao,
    addTalhao,
    values,
    handleBlur,
    errors,
    touched,
    classes,
    setFieldValue
  } = props;
  const height = infoUsuario.stOrganica === 1 ? 260 : 190;

  return (
    <Card
      withOutBorderRadius
      title={formatMessage('plantios.localPlantio')}
      height='auto'
      style={{ minHeight: height, maxHeight: height, marginBottom: 15 }}
    >
      <form autoComplete='off'>
        <Grid container>
          <Grid item xs={infoUsuario.stOrganica === 1 ? 3 : 4} style={{ paddingRight: 15 }}>
            <FormControl error={errors.areaDeCultivo && touched.areaDeCultivo} style={{ width: '100%' }}>
              <InputLabel htmlFor='areaDeCultivo' error={errors.areaDeCultivo && touched.areaDeCultivo}>
                {formatMessage('plantios.areaDeCultivo')}
              </InputLabel>

              <Select
                value={'' + values.areaDeCultivo}
                error={errors.areaDeCultivo && touched.areaDeCultivo}
                onChange={handleChangeAreaCultivo}
                onBlur={handleBlur}
                name='areaDeCultivo'
                id='areaDeCultivo'
              >
                {areasDeCultivo.map((item, i) => (
                  <MenuItem key={i} value={'' + item.idAreaCultivo}>
                    {item.dsAreaCultivo}
                  </MenuItem>
                ))}
              </Select>

              {errors.areaDeCultivo && touched.areaDeCultivo && (
                <FormHelperText>{errors.areaDeCultivo}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={infoUsuario.stOrganica === 1 ? 3 : 4} style={{ paddingRight: 15 }}>
            <FormControl error={errors.talhao && touched.talhao} style={{ width: '100%' }}>
              <InputLabel htmlFor='talhao' error={errors.talhao && touched.talhao}>
                {formatMessage('plantios.talhao')}
              </InputLabel>

              <Select
                value={'' + values.talhao}
                error={errors.talhao && touched.talhao}
                onChange={e =>
                  setFieldValue('talhao', e.target.value !== '' ? +e.target.value : e.target.value)
                }
                onBlur={handleBlur}
                name='talhao'
                id='talhao'
              >
                {infoUsuario.stOrganica === 0 &&
                  (filterTalhoesToSelect().length > 1 || values.talhao === 0) && (
                    <MenuItem value={'0'}>{formatMessage('plantios.selecionarTodos')}</MenuItem>
                  )}

                {filterTalhoesToSelect().map((item, i) => (
                  <MenuItem key={i} value={'' + item.idTalhao}>
                    {item.dsTalhao}
                  </MenuItem>
                ))}
              </Select>

              {errors.talhao && touched.talhao && <FormHelperText>{errors.talhao}</FormHelperText>}
            </FormControl>
          </Grid>
          {infoUsuario.stOrganica === 1 && (
            <>
              <Grid item xs={3} style={{ paddingRight: 15 }}>
                <TextField
                  id='dsVizinho'
                  name='dsVizinho'
                  value={'' + values.dsVizinho}
                  label={formatMessage('plantios.vizinho')}
                  className={classes.textField}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('dsVizinho', e.target.value)}
                  error={errors.dsVizinho && touched.dsVizinho}
                  helperText={errors.dsVizinho && touched.dsVizinho ? errors.dsVizinho : ''}
                />
              </Grid>

              <Grid item xs={3} style={{ paddingRight: 15 }}>
                <TextField
                  inputProps={{
                    maxLength: 100
                  }}
                  id='dsBarreiraVegetalNorte'
                  name='dsBarreiraVegetalNorte'
                  value={'' + values.dsBarreiraVegetalNorte}
                  label={formatMessage('plantios.barreiraVegetalNorte')}
                  className={classes.textField}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('dsBarreiraVegetalNorte', e.target.value)}
                  error={errors.dsBarreiraVegetalNorte && touched.dsBarreiraVegetalNorte}
                  helperText={
                    errors.dsBarreiraVegetalNorte && touched.dsBarreiraVegetalNorte
                      ? errors.dsBarreiraVegetalNorte
                      : ''
                  }
                />
              </Grid>

              <Grid item xs={3} style={{ paddingRight: 15 }}>
                <TextField
                  inputProps={{
                    maxLength: 100
                  }}
                  id='dsBarreiraVegetalSul'
                  name='dsBarreiraVegetalSul'
                  value={'' + values.dsBarreiraVegetalSul}
                  label={formatMessage('plantios.barreiraVegetalSul')}
                  className={classes.textField}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('dsBarreiraVegetalSul', e.target.value)}
                  error={errors.dsBarreiraVegetalSul && touched.dsBarreiraVegetalSul}
                  helperText={
                    errors.dsBarreiraVegetalSul && touched.dsBarreiraVegetalSul
                      ? errors.dsBarreiraVegetalSul
                      : ''
                  }
                />
              </Grid>

              <Grid item xs={3} style={{ paddingRight: 15 }}>
                <TextField
                  inputProps={{
                    maxLength: 100
                  }}
                  id='dsBarreiraVegetalLeste'
                  name='dsBarreiraVegetalLeste'
                  value={'' + values.dsBarreiraVegetalLeste}
                  label={formatMessage('plantios.barreiraVegetalLeste')}
                  className={classes.textField}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('dsBarreiraVegetalLeste', e.target.value)}
                  error={errors.dsBarreiraVegetalLeste && touched.dsBarreiraVegetalLeste}
                  helperText={
                    errors.dsBarreiraVegetalLeste && touched.dsBarreiraVegetalLeste
                      ? errors.dsBarreiraVegetalLeste
                      : ''
                  }
                />
              </Grid>

              <Grid item xs={2} style={{ paddingRight: 15 }}>
                <TextField
                  inputProps={{
                    maxLength: 100
                  }}
                  id='dsBarreiraVegetalOeste'
                  name='dsBarreiraVegetalOeste'
                  value={'' + values.dsBarreiraVegetalOeste}
                  label={formatMessage('plantios.barreiraVegetalOeste')}
                  className={classes.textField}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('dsBarreiraVegetalOeste', e.target.value)}
                  error={errors.dsBarreiraVegetalOeste && touched.dsBarreiraVegetalOeste}
                  helperText={
                    errors.dsBarreiraVegetalOeste && touched.dsBarreiraVegetalOeste
                      ? errors.dsBarreiraVegetalOeste
                      : ''
                  }
                />
              </Grid>
            </>
          )}

          <Grid item xs={1} className={classes.cardButton}>
            <Button disabled={infoUsuario.tpPerfil === 3} className={classes.button} onClick={addTalhao}>
              <Add className={classes.iconButton} />
            </Button>
            <Button
              disabled={infoUsuario.tpPerfil === 3}
              className={classes.button}
              onClick={closeTalhao}
            >
              <Close className={classes.iconButton} />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}

export default LocalPlantio;
