export default {
  relacaoTalhao: {
    relatorio: 'Informe',
    relatorioRelacaoTalhao: 'Informe de lista de campo',
    regiao: 'Región',
    municipio: 'Contacto',
    carregando: 'Cargando...',
    gerandoRelatorio: 'Generando informe ...',
    falhaAoGerarRelatorio: 'Error al generar informe',
    ecosocial: 'Ecosocial',
    tecnicoResponsavel: 'Técnico responsable',
    naoPossui: 'No tiene',
    produtor: 'Productor',
    sim: 'Si',
    nao: 'No',
    statusCertificacaoOrganica: 'Estado de certificación orgánica',
    convencional: 'Convencional',
    emConversao: 'En conversión',
    brasil: 'BR (Brazil)',
    comunidadeEuropeia: 'CE (Comunidad Europea)',
    eua: 'NOP (Estados Unidos)',
    baixarCSV: 'Descargar CSV',
    status: 'Situación',
    ativo: 'Ativo',
    inativo: 'Inativo'
  }
};
