export default {
  clientes: {
    clientes: 'Clientes',
    razaoSocial: 'Razón social',
    nomeFantasia: 'Nombre fantasía',
    cnpj: 'CNPJ',
    telefone: 'Teléfono',
    email: 'E-mail',
    planosDeAcesso: 'Plano de acceso',
    produtoresAtivos: 'Productores activos',
    motivo: 'Motivo de la inactivación',
    dataInativacao: 'Fecha de inactividad',
    dataCadastro: 'Fecha de registro',
    ativo: 'Activo',
    inativo: 'Inactivo',
    endereco: 'Dirección',
    contatosAdicionais: 'Contactos adicionales',
    usuarios: 'Usuarios',
    identificacao: 'Identificación',
    cep: 'CEP',
    estado: 'Estado',
    cidade: 'Ciudad',
    rua: 'Calle',
    numero: 'Número',
    complemento: 'Complemento',
    bairro: 'Barrio',
    departamento: 'Departamento',
    nome: 'Nombre',
    perfil: 'Perfil',
    statusConta: 'Estado de cuenta',
    situacao: 'Situación',
    identificacaoCliente: 'Identificación',
    cadastroCliente: 'Registro de clientes',
    gravar: 'Registro',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    reenviarCodigo: 'Reenviar el correo electrónico de activación',
    principal: 'Principal',
    financeiro: 'Financiero',
    entregas: 'Entregas',
    administrativo: 'Administrativo',
    gerencia: 'Administración',
    ti: 'T.I.',
    tecnico: 'Técnico',
    revenda: 'Reventa',
    campoObrigatorio: 'Campo obligatorio',
    emailInvalido: 'Correo electrónico no válido',
    carregando: 'Carga',
    clienteCadastrado: 'Cliente registrado con éxito',
    clienteEditado: 'Cliente actualizado con éxito',
    sucesso: 'Éxito',
    erro: 'Error',
    usuarioJaCadastrado: 'Usuario ya registrado',
    registrado: 'Registrado',
    confirmado: 'Confirmado',
    desabilitado: 'Discapacitado',
    admin: 'Administrador',
    salvando: 'Ahorro',
    atualizando: 'Actualización',
    usuarioJaCadastradoTabela: 'No se pudo insertar el usuario',
    naoFoiPossivelInserirUsuario: 'Este usuario ya ha sido registrado en el sistema',
    naoFoiPossivelInserirEditar: 'Este usuario ya ha sido editado en el sistema',
    erroCadastroUsuario: 'Error al registrar usuario',
    usuarioCadastradoComSucesso: 'Usuario registrado con éxito',
    clienteCadastradoComSucesso: 'Cliente registrado con éxito',
    usuariosNaoCadastrados: 'Lamento, no fue posible registrar los siguientes usuarios:',
    clienteEditadoComSucesso: 'Cliente editado con éxito',
    preRegistro: 'Pre-registro',
    produtor: 'Produtor',
    falhaAoCarregarClientes: 'Error al cargar cliente',
    numeroMaximoUsuarios: 'Se ha alcanzado el límite de usuarios de este plan',
    excluir: 'Borrar',
    excluindo: 'Excluyendo',
    desejaExcluir: '¿Desea eliminar?',
    nao: 'No',
    clienteExcluidoComSucesso: 'Cliente excluido con éxito',
    falhaAoExcluirCliente: 'Error al excluir cliente',
    usuarioExcluidoComSucesso: 'Usuario eliminado con éxito',
    plano: 'Plan',
    telefoneInvalido: 'Teléfono no válido',
    cnpjInvalido: 'CNPJ no válido',
    cepInvalido: 'CEP no válido',
    desejaExcluirEndereco: '¿Desea eliminar la dirección?',
    desejaExcluirContatosAdicionais: '¿Desea eliminar contacto adicional?',
    erroAoReenviarEmail: 'Error al reenviar correo electrónico',
    codigoEnviadoComSucesso: 'Código enviado con éxito',
    pais: 'País',
    cadastrarPais: 'Primer registro del país',
    cnpjDuplicado: 'CNPJ ya registrado',
    erroAoEditar: 'Se produjo un error al modificar el cliente',
    erroAoCadastrar: 'Error al registrar al cliente',
    erroBuscarCliente: 'Se produjo un error al buscar clientes',
    emailDuplicado: 'Correo electrónico ya está registrado',
    gerente: 'Gerente',
    representante: 'Representante',
    confirmar: 'Confirmar',
    salvarClienteSair: 'Hay información no guardada, ¿desea guardar el cliente?',
    descartar: 'Descartar',
    agriculturaOrganica: 'Agricultura orgánica',
    cooperativa: 'Cooperativa',
    cooperado: 'Cooperativa',
    tipoCooperativa: 'Tipo cooperativo',
    central: 'Central',
    produtores: 'Productores',
    produtoresComAcesso: 'Productores con acceso al sistema',
    erroAnexarFoto: 'Error al anexar foto'
  }
};
