import formatMessage from './i18n/formatMessage';

/**
 * Trata o código da entidade e retorna a mensagem relacionada
 *
 * @author Gabriela Farias
 * @returns {String} String de código
 */
export function trataErroEntidade(codeEntidade) {
  switch (codeEntidade) {
    case 'rel_azacultura_azacultivar':
      return mountStringToReturnMas(formatMessage('cultura.detentor'));
    case 'rel_azacultura_azaplantio':
      return mountStringToReturnMas(formatMessage('cultura.plantio'));
    case 'rel_azacultura_azadoencasolo':
      return mountStringToReturnFem(formatMessage('cultura.fitossanidade'));
    case 'rel_azacultura_azacotacao':
      return mountStringToReturnFem(formatMessage('cultura.cotacao'));
    case 'rel_azacultura_azasafra':
      return mountStringToReturnFem(formatMessage('cultura.safra'));
    case 'rel_azacultura_azasafracultura':
      return mountStringToReturnFem(formatMessage('cultura.safra'));
    default:
      return mountStringToReturnMas(formatMessage('cultura.cadastro'));
  }
}

/**
 * Monta a string no gênero feminino
 * @param {dsEntidade} - entidade
 * @returns - string montada
 */
function mountStringToReturnFem(dsEntidade) {
  return (
    formatMessage('cultura.existeUma') + dsEntidade + formatMessage('cultura.relacionadaComCultura')
  );
}

/**
 * Monta a string no gênero masculino
 * @param {dsEntidade} - entidade
 * @returns - string montada
 */
function mountStringToReturnMas(dsEntidade) {
  return formatMessage('cultura.existeUm') + dsEntidade + formatMessage('cultura.relacionadoComCultura');
}
