export default {
  safras: {
    safras: 'Safras',
    descricao: 'Descrição',
    desejaExcluir: 'Deseja excluir safra?',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    falhaAoExcluirSafra: 'Falha ao excluir safra',
    safraRemovidoComSucesso: 'Safra removida com sucesso',
    safraCadastradaComSucesso: 'Safra cadastrada com sucesso',
    safraEditadaComSucesso: 'Safra editada com sucesso',
    falhaAoEditarSafra: 'Falha ao editar safra',
    falhaAoCadastrarSafra: 'Falha ao cadastrar safra',
    falhaAoCarregarSafra: 'Falha ao carregar safra',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    cadastroDeSafra: 'Cadastro de safra',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    ativo: 'Ativo',
    situacao: 'Situação',
    inativo: 'Inativo',
    cultura: 'Cultura',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    safraCadastradaPeriodo: 'Existe uma safra cadastrada neste período',
    minimoCulturas: 'Adicione pelo menos 1 cultura'
  }
};
