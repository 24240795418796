import React, { Component } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getUserLang } from '@utils/localeUtils';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { style, theme } from './../Clientes.styles';
import formatMessage from '../i18n/formatMessage';

class EditarContatosAdicionais extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      listContatos: [],
      stateEdit: false
    };

    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.selectContatosAdicionais.tpContato !== undefined) {
      this.setState({
        itemSelect: this.props.selectContatosAdicionais,
        stateEdit: true
      });
    } else {
      this.setState({
        itemSelect: {},
        stateEdit: false
      });
    }
  }

  onSave(values) {
    if (this.state.stateEdit) {
      const position = this.props.clienteContatoList.indexOf(this.state.itemSelect);

      this.setState({
        openModal: false,
        itemSelect: '',
        disabledEdit: true,
        stateEdit: false
      });

      this.props.onClickContatosAdicionais(values, position);
    } else {
      this.setState({
        openModal: false,
        listContatos: this.state.listContatos.concat(values)
      });

      this.props.onClickContatosAdicionais(values);
    }
  }

  onClose() {
    this.props.onClickCancelar(0);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={
            this.state.stateEdit
              ? {
                  tpContato: this.state.itemSelect.tpContato,
                  nmContato: this.state.itemSelect.nmContato,
                  dsEmail: this.state.itemSelect.dsEmail,
                  nrTelefone: this.state.itemSelect.nrTelefone
                }
              : {
                  tpContato: '',
                  nmContato: '',
                  dsEmail: '',
                  nrTelefone: ''
                }
          }
          onSubmit={this.onSave}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            tpContato: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            nmContato: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            dsEmail: Yup.string().email(formatMessage('clientes.emailInvalido')),
            nrTelefone:
              getUserLang() === 'pt-BR'
                ? Yup.string().min(14, formatMessage('clientes.telefoneInvalido'))
                : Yup.string()
          })}
          render={({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
            <CardCadastro
              marginLeft={30}
              titulo={formatMessage('clientes.contatosAdicionais')}
              botoes={[
                <Button variant='contained' color='inherit' onClick={this.onClose}>
                  {formatMessage('clientes.cancelar')}
                </Button>,
                <Button color='secondary' variant='contained' onClick={handleSubmit}>
                  {formatMessage('clientes.confirmar')}
                </Button>
              ]}
            >
              <div style={{ padding: '15px 15px 30px 15px' }}>
                <form autoComplete='off'>
                  <Grid container spacing={16}>
                    <Grid item xs={2}>
                      <FormControl
                        className={classes.form}
                        error={errors.tpContato && touched.tpContato}
                      >
                        <InputLabel
                          htmlFor='tpContato-error'
                          error={errors.tpContato && touched.tpContato}
                          className={classes.font}
                        >
                          {formatMessage('clientes.departamento')}
                        </InputLabel>
                        <Select
                          style={{
                            marginLeft: 0,
                            fontSize: 14,
                            width: '100%',
                            marginTop: 15
                          }}
                          SelectDisplayProps={{
                            style: {
                              width: '100%',
                              backgroundColor: 'transparent'
                            }
                          }}
                          value={values.tpContato}
                          onChange={handleChange}
                          error={errors.tpContato && touched.tpContato}
                          input={<Input id='tpContato-error' />}
                          inputProps={{
                            className: classes.font,
                            name: 'tpContato',
                            id: 'tpContato-helper'
                          }}
                        >
                          {this.props.itensDepartamento.map(tpContato => (
                            <MenuItem
                              value={tpContato.id}
                              key={this.props.itensDepartamento.indexOf(tpContato)}
                              className={classes.font}
                            >
                              {tpContato.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.tpContato && touched.tpContato && (
                          <FormHelperText> {errors.tpContato} </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name='nmContato'>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            id='nmContato'
                            name='nmContato'
                            value={values.nmContato}
                            label={formatMessage('clientes.nome')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.nmContato && form.touched.nmContato}
                            helperText={form.touched.nmContato ? form.errors.nmContato : ''}
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name='dsEmail'>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            id='dsEmail'
                            name='dsEmail'
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={values.dsEmail}
                            label={formatMessage('clientes.email')}
                            className={classes.textField}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.dsEmail && form.touched.dsEmail}
                            helperText={form.touched.dsEmail ? form.errors.dsEmail : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Field name='nrTelefone'>
                        {({ field, form }) => (
                          <TextFieldWithMask
                            {...field}
                            tipoMascara={1}
                            id='nrTelefone'
                            name='nrTelefone'
                            onBlur={form.handleBlur}
                            onChange={event => {
                              event.target.name = 'nrTelefone';
                              form.handleChange(event);
                            }}
                            value={values.nrTelefone}
                            label={formatMessage('clientes.telefone')}
                            InputLabelProps={{ className: classes.font }}
                            error={form.errors.nrTelefone && form.touched.nrTelefone}
                            helperText={form.touched.nrTelefone ? form.errors.nrTelefone : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </CardCadastro>
          )}
        />
      </MuiThemeProvider>
    );
  }
}

EditarContatosAdicionais.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarContatosAdicionais);
export default withRouter(connect(mapStateToProps)(enhaced));
