export default {
    recuperarSenha: {
        email: 'Email',
        codigo: 'Code',
        senha: 'Password',
        confirmacaoSenha: 'Password Confirm',
        voltarLogin: 'Back Login',
        confirmar: 'CHANGE',
        campoObrigatorio: 'Required field',
        emailInvalido: 'Invalid email',
        senhaMinimo: 'Password must be at least 6 characters',
        falhaRecuperarSenha: 'Failed to retrieve password',
        falhaConectar: 'Failed to retrieve password',
        senhaTrocada: 'Password already changed',
        codigoExpirado: 'Expired code',
        codigoInvalido: 'Invalid code',
        usuarioNaoEncontrado: 'User not found',
        sucesso: 'Success',
        reencaminhar: 'You will be redirected to sign in'
    }
}