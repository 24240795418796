export default {
  auditoria: {
    descricao: 'Descrição',
    dataCadastro: 'Data de cadastro',
    erroAoBuscarDados: 'Erro ao buscar dados',
    auditoria: 'Auditoria',
    desejaExcluirAuditoria: 'Deseja excluir esta auditoria',
    desejaExcluirMedidaCorretiva: 'Deseja excluir esta medida corretiva?',
    nao: 'Não',
    excluir: 'Excluir',
    auditoriaExcluida: 'Auditoria excluída com sucesso',
    erroAoExcluir: 'Ocorreu um erro ao excluir a auditoria',
    cadastroDeAuditoria: 'Cadastro de auditoria',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    auditoriaSalvoComSucesso: 'Auditoria salva com sucesso',
    erroAoSalvar: 'Erro ao salvar auditoria',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    status: 'Situação',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nome',
    diasResolucao: 'Dias para resolução',
    sigla: 'Sigla',
    tipoPenalidade: 'Tipo de penalidade',
    nenhum: 'Nenhum',
    desclassificacaoSafra: 'Desclassificação da safra',
    desclassificacaoTalhao: 'Desclassificação do talhão',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    sancao: 'Sanção',
    certificacao: 'Certificação',
    cadastrarSancao: 'Cadastrar sanção',
    editar: 'Editar',
    medidasCorretivas: 'Medidas corretivas',
    medidaCorretiva: 'Medida corretiva',
    geral: 'Geral',
    cadastroGeralExistente: 'Só é possível ter uma sanção geral, ou então ela deve ser por certificação',
    ok: 'Ok',
    produtor: 'Produtor',
    areaCultivo: 'Área de cultivo',
    motivoSuspensao: 'Motivo da suspensão',
    plantio: 'Plantio',
    colheita: 'Colheita',
    tipoAuditoria: 'Tipo de auditoria',
    interna: 'Interna',
    externa: 'Externa',
    situacao: 'Situação',
    emAndamento: 'Em andamento',
    finalizadaSemNaoConformidade: 'Finalizada sem não conformidade',
    finalizadaComNaoConformidade: 'Finalizada com não conformidade',
    suspensa: 'Suspensa',
    auditores: 'Auditores',
    nomeAuditor: 'Nome do auditor',
    naoConformidade: 'Não conformidade',
    dataComprimento: 'Data de cumprimento',
    limiteCumprimento: 'Limite para cumprimento',
    observacoes: 'Observações',
    desejaAgendarMedidaCorretiva: 'Deseja agendar a realização das medidas corretivas?',
    agendar: 'Agendar',
    talhao: 'Talhão',
    cadastrarNaoConformidade: 'Cadastrar não conformidade',
    aberto: 'Aberto',
    fechado: 'Fechado',
    dataDeObservacao: 'Data da observação',
    observacao: 'Observação',
    adicionarObservacao: 'Adicionar observação',
    statusFalseCadastrado: 'Já existe uma observação com situação fechada',
    erroSubirArquivo: 'Ocorreu um erro ao fazer upload de foto',
    insiraPlantio: 'Insira um plantio para cadastrar não conformidade',
    alterandoPlantioLimpaNaoConformidade:
      'Ao alterar o plantio, será limpado todas as não conformidades, deseja continuar?',
    agendaVisita: 'Agendar visita',
    erroAoCadastrar: 'Erro ao cadastrar agenda',
    visitaCadastradaComSucesso: 'Visita cadastrada com sucesso',
    mesmoHorario: 'Existe uma visita agendada no mesmo horário para ',
    talhoes: 'Talhões',
    possuiInfoComplementar: 'possui informações complementares'
  }
};
