export default {
  nematicidaMicrobiologico: {
    nematicidaMicrobiologicoRemovidoComSucesso: 'Microbiological nematicide successfully removed',
    falhaAoExcluiNematicidaMicrobiologico: 'Failed to exclude microbiological nematicide',
    desejaExcluir: 'Do you want to delete the selected microbiological nematicide?',
    nematicidaMicrobiologico: 'Microbiological nematicides',
    cadastroDeNematicidaMicrobiologico: 'Microbiological nematicides registration',
    identificacaoDaNematicidaMicrobiologico: 'Identification',
    nematicidaMicrobiologicoCadastradoComSucesso: 'Microbiological nematicide successfully registered',
    nematicidaMicrobiologicoEditadoComSucesso: 'Microbiological nematicide edited successfully',
    falhaAoEditarNematicidaMicrobiologico: 'Failed to edit microbiological nematicide',
    falhaAoCadastrarNematicidaMicrobiologico: 'Failed to register microbiological nematicide',
    falhaAoCarregarNematicidaMicrobiologico: 'Failed to load microbiological nematicides'
  }
};
