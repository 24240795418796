export default {
  relatorio: {
    relatorio: 'Informe',
    relatorioListaProdutores: 'Listado de productores',
    regiao: 'Región',
    municipio: 'Contacto',
    ativo: 'Activo',
    inativo: 'Inactivo',
    carregando: 'Cargando...',
    gerandoRelatorio: 'Generando informe ...',
    falhaAoGerarRelatorio: 'Error al generar informe',
    tecnicoResponsavel: 'Técnico responsable',
    produtor: 'Productor',
    situacao: 'Situación',
    uf: 'UF',
    decrescente: 'Disminuyendo',
    crescente: 'Creciente'
  }
};
