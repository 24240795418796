/*
Ao adicionar subMenus ->
Os subitens deve ser listado em children, onde o pai de todos os itens, que é exibido quando o menu é fechado
deve conter um id, id esse que sera usado para associa-los a seus filhos, os ultimos itens de um submenu, 
os que direcionam a pagina para alguma rota deve conter o atributo parent que contem o id do seu pai.
Itens sem submenu nao necessitam nem de id, nem de parent
 */
import React from 'react';
import iconAcompanhamento from '@images/icon_acompanhamento_tecnico.png';
import iconFungicidas from '@images/icon_fungicidas.png';
import iconInseticidas from '@images/icon_inseticidas.png';
import iconCotacoes from '@images/icon_cotacoes.png';
import iconCalendar from '@images/icon_calendar.png';
import iconeCadastro from '@images/icone_cadastro.png';
import iconAdmin from '@images/icone-administracao.png';
import { FormattedMessage } from 'react-intl';
import iconFertilizantes from '@images/icon_fertilizantes.png';
import iconRelatorio from '@images/icone_relatorio.png';
import iconMapa from '@images/icon_mapa.png';
import itemLayer from '@images/layers.png';
import IconPlanta from '@images/icon_planta.png';

const itensMenu = [
  {
    name: <FormattedMessage id='menu.cadastro' />,
    icon: iconeCadastro,
    selecionado: false,
    categoria: 3,
    id: 'CADASTRO',
    children: [
      {
        name: <FormattedMessage id='menu.pessoas' />,
        selecionado: false,
        categoria: 3,
        id: 'PESSOA',
        children: [
          {
            name: <FormattedMessage id='menu.produtores' />,
            selecionado: false,
            categoria: 3,
            parent: ['CADASTRO', 'PESSOA'],
            route: '/produtores'
          },
          {
            name: <FormattedMessage id='menu.tecnicos' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'PESSOA'],
            route: '/tecnicos'
          },
          {
            name: <FormattedMessage id='menu.revenda' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'PESSOA'],
            route: '/gerente'
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.localizacao' />,
        selecionado: false,
        categoria: 2,
        id: 'LOCALIZACAO',
        children: [
          {
            name: <FormattedMessage id='menu.municipio' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'LOCALIZACAO'],
            route: '/municipios'
          },
          {
            name: <FormattedMessage id='menu.comunidades' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'LOCALIZACAO'],
            route: '/comunidades'
          },
          {
            name: <FormattedMessage id='menu.regiao' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'LOCALIZACAO'],
            route: '/regiao'
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.produtos' />,
        selecionado: false,
        categoria: 1,
        id: 'PRODUTO',
        children: [
          {
            name: <FormattedMessage id='menu.formaAplicacao' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'PRODUTO'],
            route: '/forma-aplicacao'
          },
          {
            name: <FormattedMessage id='menu.analiseOGM' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'PRODUTO'],
            route: '/analise-ogm'
          },
          {
            name: <FormattedMessage id='menu.marcas' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'PRODUTO'],
            route: '/marcas'
          },
          {
            name: <FormattedMessage id='menu.culturas' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'PRODUTO'],
            route: '/culturas'
          },
          {
            name: <FormattedMessage id='menu.cultivares' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'PRODUTO'],
            route: '/cultivar'
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.animal' />,
        selecionado: false,
        categoria: 2,
        id: 'ANIMAL',
        organico: true,
        children: [
          {
            name: <FormattedMessage id='menu.especies' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'ANIMAL'],
            route: '/especie'
          },
          {
            name: <FormattedMessage id='menu.racas' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'ANIMAL'],
            route: '/raca'
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.certificacoes' />,
        selecionado: false,
        categoria: 3,
        id: 'CERTIFICACOES',
        organico: true,
        children: [
          {
            name: <FormattedMessage id='menu.ecosocial' />,
            selecionado: false,
            categoria: 3,
            parent: ['CADASRO', 'CERTIFICACOES'],
            route: '/certificacao-ecosocial'
          },
          {
            name: <FormattedMessage id='menu.certificacaoOrganica' />,
            selecionado: false,
            categoria: 3,
            parent: ['CADASRO', 'CERTIFICACOES'],
            route: '/certificacao-organica'
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.producao' />,
        selecionado: false,
        categoria: 3,
        id: 'PRODUCAO',
        children: [
          {
            name: <FormattedMessage id='menu.plantios' />,
            selecionado: false,
            categoria: 3,
            parent: ['CADASTRO', 'PLANTIO'],
            route: '/plantios'
          },
          {
            name: <FormattedMessage id='menu.safras' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'SAFRAS'],
            route: '/safras',
            organico: true
          },
          {
            name: <FormattedMessage id='menu.sazonalidades' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'SAZONALIDADE'],
            route: '/sazonalidade',
            organico: true
          },
          {
            name: <FormattedMessage id='menu.classificacao' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'CLASSIFICACAO'],
            route: '/classificacao',
            organico: true
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.adversidades' />,
        selecionado: false,
        categoria: 2,
        id: 'ADVERSIDADE',
        children: [
          {
            name: <FormattedMessage id='menu.fitossanidade' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'FITOSSANIDADE'],
            route: '/fitossanidade'
          },
          {
            name: <FormattedMessage id='menu.naoConformidade' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'REGISTRO'],
            route: '/nao-conformidade',
            organico: true
          },
          {
            name: <FormattedMessage id='menu.sancaoCategoria' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'SANCAO_CATEGORIA'],
            route: '/sancao-categoria',
            organico: true
          },
          {
            name: <FormattedMessage id='menu.medidaCorretiva' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'MEDIDA-CORRETIVA'],
            route: '/medida-corretiva',
            organico: true
          },
          {
            name: <FormattedMessage id='menu.auditoria' />,
            selecionado: false,
            categoria: 2,
            parent: ['CADASTRO', 'AUDITORIA'],
            route: '/auditoria',
            organico: true
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.cooperativismo' />,
        selecionado: false,
        categoria: 1,
        id: 'COOPERATIVISMO',
        organico: true,
        cooperativa: true,
        children: [
          {
            name: <FormattedMessage id='menu.agencia' />,
            selecionado: false,
            categoria: 1,
            parent: ['CADASTRO', 'AGENCIA'],
            route: '/agencia',
            organico: true,
            cooperativa: true
          }
        ]
      }
    ]
  },
  {
    name: <FormattedMessage id='menu.fertilizantes' />,
    icon: iconInseticidas,
    selecionado: false,
    categoria: 1,
    id: 'FERTILIZANTE',
    children: [
      {
        name: <FormattedMessage id='menu.foliares' />,
        selecionado: false,
        categoria: 1,
        parent: 'FERTILIZANTE',
        route: '/fertilizantes'
      },
      {
        name: <FormattedMessage id='menu.oleosAdjuvantes' />,
        selecionado: false,
        categoria: 1,
        parent: 'FERTILIZANTE',
        route: '/oleos'
      }
    ]
  },
  {
    name: <FormattedMessage id='menu.fertilizantesFoliares' />,
    icon: iconFertilizantes,
    selecionado: false,
    categoria: 1,
    id: 'FERTILIZANTE_FOLIAR',
    children: [
      {
        name: <FormattedMessage id='menu.quimico' />,
        selecionado: false,
        categoria: 1,
        parent: 'FERTILIZANTE_FOLIAR',
        route: '/fertilizantes-quimicos'
      },
      {
        name: <FormattedMessage id='menu.organico' />,
        selecionado: false,
        categoria: 1,
        parent: 'FERTILIZANTE_FOLIAR',
        route: '/fertilizantes-organicos'
      }
    ]
  },
  {
    name: <FormattedMessage id='menu.pesticidas' />,
    icon: iconFungicidas,
    selecionado: false,
    categoria: 1,
    id: 'PESTICIDA',
    children: [
      {
        name: <FormattedMessage id='menu.acaros' />,
        selecionado: false,
        categoria: 1,
        id: 'ACARO',
        children: [
          {
            name: <FormattedMessage id='menu.acaricidas' />,
            selecionado: false,
            categoria: 1,
            route: '/acaricidas',
            parent: ['PESTICIDA', 'ACARO']
          },
          {
            name: <FormattedMessage id='menu.acaricidasMicrobio' />,
            selecionado: false,
            categoria: 1,
            route: '/acaricidas-microbiologicos',
            parent: ['PESTICIDA', 'ACARO']
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.bacterias' />,
        selecionado: false,
        categoria: 1,
        id: 'BACTERIA',
        children: [
          {
            name: <FormattedMessage id='menu.agentesBiologicosControle' />,
            selecionado: false,
            categoria: 1,
            route: '/agentes-biologicos-controle',
            parent: ['PESTICIDA', 'BACTERIA']
          },
          {
            name: <FormattedMessage id='menu.bactericidas' />,
            selecionado: false,
            categoria: 1,
            route: '/bactericidas',
            parent: ['PESTICIDA', 'BACTERIA']
          },
          {
            name: <FormattedMessage id='menu.bactericidasMicrobiologicos' />,
            selecionado: false,
            categoria: 1,
            route: '/bactericidas-microbiologicos',
            parent: ['PESTICIDA', 'BACTERIA']
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.ervasDaninhas' />,
        selecionado: false,
        categoria: 1,
        id: 'ERVA',
        children: [
          {
            name: <FormattedMessage id='menu.herbicidas' />,
            selecionado: false,
            categoria: 1,
            route: '/herbicidas',
            parent: ['PESTICIDA', 'ERVA']
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.fungos' />,
        selecionado: false,
        id: 'FUNGO',
        categoria: 1,
        children: [
          {
            name: <FormattedMessage id='menu.fungicidas' />,
            selecionado: false,
            categoria: 1,
            route: '/fungicidas',
            parent: ['PESTICIDA', 'FUNGO']
          },
          {
            name: <FormattedMessage id='menu.fungicidasMicroBio' />,
            selecionado: false,
            categoria: 1,
            route: '/fungicidas-microbiologicos',
            parent: ['PESTICIDA', 'FUNGO']
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.insetos' />,
        selecionado: false,
        categoria: 1,
        id: 'INSETO',
        children: [
          {
            name: <FormattedMessage id='menu.inseticidas' />,
            selecionado: false,
            categoria: 1,
            route: '/inseticidas',
            parent: ['PESTICIDA', 'INSETO']
          },
          {
            name: <FormattedMessage id='menu.inseticidasMicroBio' />,
            selecionado: false,
            categoria: 1,
            route: '/inseticidas-microbiologicos',
            parent: ['PESTICIDA', 'INSETO']
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.nematoides' />,
        selecionado: false,
        categoria: 1,
        id: 'NEMATOIDE',
        children: [
          {
            name: <FormattedMessage id='menu.nematicidas' />,
            selecionado: false,
            categoria: 1,
            route: '/nematicidas',
            parent: ['PESTICIDA', 'NEMATOIDE']
          },
          {
            name: <FormattedMessage id='menu.nematicidasMicrobiologicos' />,
            selecionado: false,
            categoria: 1,
            route: '/nematicidas-microbiologicos',
            parent: ['PESTICIDA', 'NEMATOIDE']
          }
        ]
      }
    ]
  },
  {
    name: <FormattedMessage id='menu.inoculantesHomeopatia' />,
    icon: IconPlanta,
    selecionado: false,
    categoria: 1,
    id: 'INOCULANTESHOMEOPATIA',
    children: [
      {
        name: <FormattedMessage id='menu.homeopaticos' />,
        selecionado: false,
        categoria: 1,
        id: 'INOCULANTESHOMEOPATIA',
        route: '/homeopaticos'
      },
      {
        name: <FormattedMessage id='menu.inoculantes' />,
        selecionado: false,
        categoria: 1,
        id: 'INOCULANTESHOMEOPATIA',
        route: '/inoculantes'
      }
    ]
  },
  {
    name: <FormattedMessage id='menu.acompanhamento' />,
    icon: iconAcompanhamento,
    selecionado: false,
    categoria: 3,
    id: 'ANALISE',
    children: [
      {
        name: <FormattedMessage id='menu.visitas' />,
        selecionado: false,
        categoria: 2,
        id: 'VISITAS',
        children: [
          {
            name: <FormattedMessage id='menu.tecnico' />,
            selecionado: false,
            categoria: 2,
            parent: 'VISITAS',
            route: '/acompanhamento'
          },
          {
            name: <FormattedMessage id='menu.produtor' />,
            selecionado: false,
            categoria: 3,
            parent: 'VISITAS',
            route: '/resumo-visitas'
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.produtor' />,
        selecionado: false,
        categoria: 3,
        parent: 'ANALISE',
        route: '/acompanhamento-produtor/novo'
      },
      {
        name: <FormattedMessage id='menu.galeria' />,
        selecionado: false,
        categoria: 3,
        parent: 'ANALISE',
        route: '/galeria'
      },
      {
        name: <FormattedMessage id='menu.acompanhamentoVisitas' />,
        selecionado: false,
        categoria: 1,
        route: '/acompanhamento-visitas'
      }
    ]
  },

  {
    name: <FormattedMessage id='menu.cotacoes' />,
    icon: iconCotacoes,
    selecionado: false,
    categoria: 3,
    route: '/cotacoes'
  },

  {
    name: <FormattedMessage id='menu.admin' />,
    icon: iconAdmin,
    selecionado: false,
    categoria: 0,
    id: 'ADMIN',
    children: [
      {
        name: <FormattedMessage id='menu.clientes' />,
        selecionado: false,
        categoria: 0,
        parent: ['CLIENTE', 'ADMIN'],
        route: '/clientes'
      },
      {
        name: <FormattedMessage id='menu.financeiro' />,
        selecionado: false,
        categoria: 0,
        parent: ['FINANCEIRO', 'ADMIN'],
        route: '/financeiro'
      }
    ]
  },

  {
    name: <FormattedMessage id='menu.municipio' />,
    icon: iconeCadastro,
    selecionado: false,
    categoria: 0,
    id: 'MUNICIPIO',
    route: '/municipios'
  },

  {
    name: <FormattedMessage id='menu.agenda' />,
    icon: iconCalendar,
    selecionado: false,
    categoria: 3,
    route: '/agenda'
  },

  {
    name: <FormattedMessage id='menu.mapa' />,
    icon: iconMapa,
    selecionado: false,
    categoria: 3,
    route: '/mapa'
  },
  {
    name: <FormattedMessage id='menu.mapandvi' />,
    icon: itemLayer,
    selecionado: false,
    categoria: 3,
    route: '/mapandvi'
  },

  {
    name: <FormattedMessage id='menu.relatorio' />,
    icon: iconRelatorio,
    selecionado: false,
    categoria: 2,
    id: 'RELATORIO',
    children: [
      {
        name: <FormattedMessage id='menu.produtores' />,
        selecionado: false,
        categoria: 3,
        id: 'PRODUTOR',
        children: [
          {
            name: <FormattedMessage id='menu.cadastroDeProdutor' />,
            selecionado: false,
            categoria: 2,
            parent: 'PRODUTOR',
            route: '/ficha/produtor',
            organico: true
          },
          {
            name: <FormattedMessage id='menu.listaProdutores' />,
            selecionado: false,
            categoria: 2,
            parent: 'PRODUTOR',
            route: '/relatorio/lista-produtores'
          },
          {
            name: <FormattedMessage id='menu.produtorPotencial' />,
            selecionado: false,
            categoria: 2,
            parent: 'PRODUTOR',
            route: '/relatorio/produtores-potencial'
          },
          {
            name: <FormattedMessage id='menu.relacaoTalhao' />,
            selecionado: false,
            categoria: 2,
            parent: 'PRODUTOR',
            route: '/relatorio/relacao-talhao'
          },
          {
            name: <FormattedMessage id='menu.plantios' />,
            selecionado: false,
            categoria: 2,
            parent: 'PRODUTOR',
            route: '/relatorio/plantio'
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.produtividade' />,
        selecionado: false,
        categoria: 3,
        id: 'PRODUTIVIDADE',
        children: [
          {
            name: <FormattedMessage id='menu.graosProdutor' />,
            selecionado: false,
            categoria: 2,
            parent: 'PRODUTOR',
            route: '/relatorio/produtividade-graos'
          },
          {
            name: <FormattedMessage id='menu.historicoProdutividade' />,
            selecionado: false,
            categoria: 2,
            parent: 'PRODUTOR',
            route: '/relatorio/historico-produtividade'
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.analisesRiscos' />,
        selecionado: false,
        categoria: 3,
        id: 'ANALISERISCO',
        organico: true,
        children: [
          {
            name: <FormattedMessage id='menu.sintetico' />,
            selecionado: false,
            categoria: 2,
            parent: 'ANALISERISCO',
            route: '/relatorio/analise-risco',
            organico: true
          },
          {
            name: <FormattedMessage id='menu.analitico' />,
            selecionado: false,
            categoria: 2,
            parent: 'ANALISERISCO',
            route: '/relatorio/analise-riscos-analitico',
            organico: true
          }
        ]
      },
      {
        name: <FormattedMessage id='menu.visitas' />,
        selecionado: false,
        categoria: 2,
        parent: 'RELATORIO',
        route: '/relatorio/visitas'
      }
    ]
  }
];
export default itensMenu;
