export default {
  galeria: {
    galeria: 'Galeria',
    produtor: 'Produtor',
    cidade: 'Cidade',
    regiao: 'Região',
    comunidade: 'Comunidade',
    resumo: 'Resumo',
    cultura: 'Cultura',
    cultivar: 'Cultivar',
    areaCultivo: 'Área de cultivo',
    descricao: 'Descrição',
    talhoesUtilizados: 'Talhões utilizados',
    data: 'Data',
    safra: 'Safra',
    baixando: 'Baixando',
    erroDownloadArquivos: 'Erro ao baixar arquivos',
    tecnico: 'Técnico',
    erroBuscarGaleria: 'Erro ao buscar fotos da galeria',
    baixarImagens: 'Baixar imagens'
  }
};
