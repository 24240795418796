import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconEdit from '@images/icone_editar.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import '@styles/Alerts.css';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import Cultura from '@resources/Cultura';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

/**
 * Componente de listagem de Culturas com Sazonalidades
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class Sazonalidades
 * @extends {Component}
 */
class Sazonalidades extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const descricao = messagesLinguage['sazonalidade.descricao'];
    const numeroDeAplicacoes = messagesLinguage['sazonalidade.numeroDeAplicacoes'];
    const sim = messagesLinguage['sazonalidade.sim'];
    const nao = messagesLinguage['sazonalidade.nao'];
    const desejaExcluir = messagesLinguage['sazonalidade.desejaExcluir'];
    const excluir = messagesLinguage['sazonalidade.excluir'];
    const culturaRemovidaComSucesso = messagesLinguage['sazonalidade.culturaRemovidaComSucesso'];
    const falhaAoExcluirCultura = messagesLinguage['sazonalidade.falhaAoExcluirCultura'];
    const excluindo = messagesLinguage['sazonalidade.excluindo'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          descricao,
          numeroDeAplicacoes,
          sim,
          nao,
          desejaExcluir,
          excluir,
          culturaRemovidaComSucesso,
          falhaAoExcluirCultura,
          excluindo
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      itemSelect: '',
      key: '',
      dataInTable: [],
      dataAppSync: [],
      loading: true,
      head: [
        {
          field: 'nmCultura',
          headerName: provider.intl.formatMessage({ id: 'descricao' }),
          sort: 'asc',
          col: 12
        }
      ],
      operationValues: [
        {
          label: provider.intl.formatMessage({ id: 'descricao' }),
          field: 'nmCultura',
          type: 'string'
        }
      ]
    };

    const title = 'sazonalidade.sazonalidades';
    if (this.props.itensState.name !== 'cultura.sazonalidades') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.selectChange = this.selectChange.bind(this);
    this.clickEdit = this.clickEdit.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  selectChange(select) {
    this.setState({ itemSelect: select ? select : '' });
  }

  clickEdit() {
    this.props.history.push(`/app/sazonalidade/editar/${this.state.itemSelect.idCultura}`);
  }

  componentDidMount() {
    this.onFilter(this.props.chipData);
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    Cultura.findAllCultura().then(query => {
      !this.isCancelled &&
        this.setState({
          dataAppSync: query.data,
          dataInTable: FilterUtil.applyFilters(query.data, this.props.chipData),
          loading: false
        });
    });
  }

  render() {
    const pagination = { length: this.state.dataInTable.length, page: 0 };

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'scroll'
          }}
        >
          {this.state.loading && <Loading />}

          <div style={{ marginBottom: 30 }}>
            <CardFilters
              filtros={this.state.operationValues}
              onFilter={this.onFilter}
              onFilterChanged={this.onFilterChanged}
              usePaddings={false}
              viewKey='culturas'
            />
          </div>

          <div
            style={{
              marginBottom: 15,
              display: 'contents',
              width: '100%',
              height: '100%'
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              key={this.state.key}
            />
          </div>

          <ButtonFAB
            icon={iconEdit}
            positionDefault
            disableFAB={!this.state.itemSelect}
            onClick={this.clickEdit}
          />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'culturas';
  })[0].content
});

export default withRouter(connect(mapStateToProps)(Sazonalidades));
