export default {
  bactericidas: {
    bactericidasRemovidoComSucesso: 'Bactericidal successfully removed',
    falhaAoExcluirbactericidas: 'Failed to exclude bactericidal',
    desejaExcluir: 'Do you want to delete the selected bactericidal?',
    bactericidas: 'Bactericidal',
    cadastroDeBactericidas: 'bactericidal record',
    identificacaoDaBactericidas: 'Identification',
    bactericidasCadastradoComSucesso: 'Bactericidal successfully registered',
    bactericidasEditadoComSucesso: 'Bactericidal edited successfully',
    falhaAoEditarBactericidas: 'Failed to edit bactericidal',
    falhaAoCadastrarBactericidas: 'Failed to register bactericidal',
    falhaAoCarregarBactericidas: 'Failed to load bactericidal'
  }
};
