export default {
  acompanhamento: {
    visitasTecnico: 'Visits by coach',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    tecnico: 'Technician',
    identificacao: 'Identification',
    campoObrigatorio: 'Required field',
    consultar: 'Consult',
    visitasPendentes: 'Pending visits',
    visitasRealizadas: 'Visits made',
    visitasAgendadas: 'Scheduled Visits',
    produtor: 'Producer',
    ultimaVisita: 'Last visit',
    falhaAoPesquisarVisita: 'Search tracking failed',
    falhaAoCarregar: 'Failed to load visits',
    dataMinima: 'Invalid date',
    horaFinal: 'End time',
    horaInicio: 'Start time',
    pendentes: 'Pending',
    agendadas: 'Scheduled',
    realizadas: 'Accomplished',
    filtros: 'Filters',
    dadosAgenda: 'Calendar data',
    dadosVisita: 'Visit details',
    semVisitas: 'No visits in 14 days',
    a: 'of'
  }
};
