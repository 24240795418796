import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@components/Card/Card';
import formatMessage from './i18n/formatMessage';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Produtores from '@resources/Produtores';
import moment from 'moment';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import Loading from '@components/Loading/Loading';
import swal from '@sweetalert/with-react';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconAdd from '@images/icone_add.png';
import { deletePlantioById } from '@resources/Plantio';

const style = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.35
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      contained: {
        height: '46px',
        width: '100px',
        marginLeft: '15px'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        }
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente de listagem de Plantios
 *
 * @author Bruno Eduardo
 * @class Plantios
 * @extends {Component} - Componente React
 */
class Plantios extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    const title = 'plantios.plantios';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    this.state = {
      produtor: '',
      produtores: [],
      keyAutocomplete: '',
      data: [],
      disabledButtons: true,
      itemSelect: '',
      loading: !!this.props.match.params.idProdutor
    };

    this.headDatatable = [
      {
        valueGetter: args =>
          args.node.data.dtPlantio
            ? moment(args.node.data.dtPlantio)
                .utc()
                .format('DD/MM/YY')
            : null,
        headerName: formatMessage('plantios.dataPlantio'),
        col: 1
      },
      {
        valueGetter: args => (args.node.data.safra ? args.node.data.safra.dsSafra : ''),
        headerName: formatMessage('plantios.safra'),
        col: 2
      },
      {
        field: 'areaCultivo.dsAreaCultivo',
        headerName: formatMessage('plantios.areaDeCultivo'),
        col: 2
      },
      {
        valueGetter: args =>
          args.node.data.plantioTalhaoList.map(plantioTalhao => plantioTalhao.talhao.dsTalhao),
        headerName: formatMessage('plantios.talhao'),
        col: 3
      },
      {
        valueGetter: args => args.node.data.cultura.nmCultura,
        headerName: formatMessage('plantios.cultura'),
        col: 1
      },
      {
        valueGetter: args => args.node.data.cultivarEspec.nmCultivarEspec,
        headerName: formatMessage('plantios.cultivar'),
        col: 1
      },
      {
        valueGetter: args =>
          args.node.data.dtColheita
            ? moment(args.node.data.dtColheita)
                .utc()
                .format('DD/MM/YY')
            : null,
        headerName: formatMessage('plantios.dataColheita'),
        col: 2
      }
    ];

    this.safeSetState = this.safeSetState.bind(this);
    this.tratarValorAutocomplete = this.tratarValorAutocomplete.bind(this);
    this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
    this.loadSuggestionsAutocomplete = this.loadSuggestionsAutocomplete.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.loadDataOfProdutor = this.loadDataOfProdutor.bind(this);
  }

  /**
   * Marca o componente como montado e carrega os dados do Autocomplete e do Produtor
   */
  componentDidMount() {
    this._isMounted = true;
    this.loadSuggestionsAutocomplete();

    if (this.props.match.params.idProdutor) {
      this.loadDataOfProdutor(this.props.match.params.idProdutor);
    }
  }

  /**
   * Marca o componente como desmontado
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Executa um `setState` somente se o componente estiver montado
   *
   * @param {Object | Function} values - Valores que vão ser mudados no state
   * @param {Function} [callback=() => {}] - Função que é executada depois que o setState é finalizado
   */
  safeSetState(values, callback = () => {}) {
    this._isMounted && this.setState(values, callback);
  }

  /**
   * Executa quando é clickado numa linha da lista
   *
   * @param {Object} selected - Linha selecionada
   */
  selectChange(select) {
    if (select) {
      this.safeSetState({ itemSelect: select, disabledButtons: false });
    } else {
      this.safeSetState({ itemSelect: '', disabledButtons: true });
    }
  }

  /**
   * Trata o valor do Autocomplete
   *
   * @returns O valor tratado
   */
  tratarValorAutocomplete() {
    if (!this.state.produtor || this.state.produtor.nmProdutor === undefined) {
      return '';
    }

    return this.state.produtor;
  }

  /**
   * Manipula o evento de change do Autocomplete
   *
   * @param {any} [value=''] - Valor que vai ser atribuído ao state, ou uma string vazia
   */
  onChangeAutocomplete(value = '') {
    if (!value) {
      this.safeSetState({ data: [], produtor: '', keyAutocomplete: Math.random(), loading: false });
      return;
    }

    this.props.history.push(`/app/plantios/${value.idProdutor}`);

    this.safeSetState({ loading: true });
    this.loadDataOfProdutor(value.idProdutor);
  }

  /**
   * Carrega os dados do Produtor
   *
   * @param {Number} idProdutor - ID do Produtor
   */
  loadDataOfProdutor(idProdutor = this.props.match.params.idProdutor) {
    Produtores.findProdutorById(idProdutor)
      .then(res => {
        this.safeSetState({
          produtor: res.data,
          data: res.data.plantioList,
          keyAutocomplete: Math.random(),
          loading: false
        });
      })
      .catch(err => this.safeSetState({ data: [], produtor: '', keyAutocomplete: Math.random() }));
  }

  /**
   * Carrega as sugestões do Autocomplete da base de dados
   */
  loadSuggestionsAutocomplete() {
    this.safeSetState({ produtores: [] });

    Produtores.findProdutor()
      .then(res => {
        const produtores = res.data;

        if (this.props.infoUsuario.tpPerfil === 3) {
          const dsEmail = this.props.infoUsuario.dsEmail;
          const produtorSelecionado = produtores.find(item => dsEmail === item.dsEmail) || '';
          if (produtorSelecionado) {
            this.onChangeAutocomplete(produtorSelecionado);
            return;
          }
        }

        if (!this.props.match.params.idProdutor) {
          this.safeSetState({ keyAutocomplete: Math.random(), produtor: '', produtores });
        } else {
          this.safeSetState({ produtores });
        }
      })
      .catch(err => this.safeSetState({ produtor: '', produtores: [], keyAutocomplete: Math.random() }));
  }

  /**
   * Deleta um plantio
   */
  onDelete() {
    if (!this.state.itemSelect) return;

    const msgErroConfig = {
      icon: 'error',
      buttons: {
        confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-error' }
      }
    };

    swal({
      title: formatMessage('plantios.desejaExcluirPlantio'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('plantios.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('plantios.excluir'),
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-confirm'
        }
      }
    }).then(res => {
      if (res) {
        this.safeSetState({ loading: true });

        deletePlantioById(this.state.itemSelect.idPlantio)
          .then(res => {
            swal(formatMessage('plantios.plantioExcluido'), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });

            this.loadDataOfProdutor();
          })
          .catch(err => {
            if (err.response && err.response.data.codigo === 'ATIVIDADE_RELACIONADA') {
              swal(formatMessage('plantios.atividadeRelacionadaDeletePlantio'), msgErroConfig);
            } else {
              swal(formatMessage('plantios.falhaoAoExcluir'), msgErroConfig);
            }

            this.safeSetState({ loading: false });
          });
      }
    });
  }

  /**
   * Manda o usuário para a tela de edição de plantio
   */
  onClickEdit() {
    if (this.state.itemSelect) {
      this.props.history.push(
        `/app/plantios/${this.state.produtor.idProdutor}/editar/${this.state.itemSelect.idPlantio}`
      );
    }
  }

  /**
   * Executa ao clickar no FAB de Add
   *
   * @returns Mostra um erro ao Usuário caso o Produtor nao tenha Area de Cultivos ou Talhões
   */
  onClickAdd() {
    const configMsg = {
      icon: 'error',
      buttons: {
        confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
      }
    };

    if (this.state.produtor.areaCultivoList.length === 0) {
      swal(formatMessage('plantios.produtorSemAreaCultivo'), configMsg);
      return;
    }

    if (this.state.produtor.talhaoList.length === 0) {
      swal(formatMessage('plantios.produtorSemTalhao'), configMsg);
      return;
    }

    this.props.history.push(`/app/plantios/${this.state.produtor.idProdutor}/novo`);
  }

  render() {
    const { classes } = this.props;
    const paginacao = { length: this.state.data ? this.state.data.length : 0, page: 0 };

    return (
      <MuiThemeProvider theme={theme}>
        {this.state.loading && <Loading />}

        <div className={classes.root}>
          <Card
            withOutBorderRadius
            title={formatMessage('plantios.identificacao')}
            height='auto'
            style={{ marginBottom: 30, minHeight: 190, maxHeight: 190 }}
          >
            <Grid container style={{ height: 70 }}>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                <Autocomplete
                  itens={this.state.produtores}
                  campoOp='nmProdutor'
                  campoChave='idProdutor'
                  label={formatMessage('plantios.produtor')}
                  name='produtor'
                  onChangeOwn={this.onChangeAutocomplete}
                  value={this.tratarValorAutocomplete()}
                  key={this.state.keyAutocomplete}
                  valueAutoComplete={this.tratarValorAutocomplete()}
                  valueSelect={this.tratarValorAutocomplete().nmProdutor}
                  maxHeight='calc(100vh - 300px)'
                  disabled={this.props.infoUsuario.tpPerfil === 3 || this.state.loading}
                />
              </Grid>
            </Grid>
          </Card>

          <div style={{ display: 'contents' }}>
            <GroupButtonGrid
              hiddenMargin
              withoutMargin
              showEdit
              showDelete
              disableEdit={this.state.disabledButtons}
              disableDelete={this.state.disabledButtons || this.props.infoUsuario.tpPerfil === 3}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onDelete}
            />

            <DataTable
              noMargin
              borderRadius={false}
              data={this.state.data}
              columns={this.headDatatable}
              selectChange={this.selectChange}
              showPagination
              pagination={paginacao}
            />
          </div>

          {this.props.infoUsuario.tpPerfil !== 3 && (
            <ButtonFAB
              icon={iconAdd}
              positionDefault
              onClick={this.onClickAdd}
              disableFAB={!this.state.produtor || this.state.loading}
            />
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'plantios';
  })[0].content
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(Plantios)));
