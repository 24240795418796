export default {
  herbicida: {
    herbicidaRemovidoComSucesso: 'Herbicida removido com sucesso',
    falhaAoExcluirHerbicida: 'Falha ao excluir herbicida',
    desejaExcluir: 'Deseja excluir o herbicida selecionado?',
    herbicida: 'Herbicida',
    cadastroDeHerbicida: 'Cadastro de herbicida',
    identificacaoDaHerbicida: 'Identificação',
    herbicidaCadastradoComSucesso: 'Herbicida cadastrado com sucesso',
    herbicidaEditadoComSucesso: 'Herbicida editado com sucesso',
    falhaAoEditarHerbicida: 'Falha ao editar herbicida',
    falhaAoCadastrarHerbicida: 'Falha ao cadastrar herbicida',
    falhaAoCarregarHerbicida: 'Falha ao carregar herbicida'
  }
};
