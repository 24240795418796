import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatMessage from '../i18n/formatMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Card from '@components/Card/Card';
import Button from '@material-ui/core/Button';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import InsumosEnum from '@resources/InsumosEnum';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Marca from '@resources/Marcas';
import Pesticida from '@resources/Pesticidas';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import swal from '@sweetalert/with-react';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';

const style = theme => ({
  button: {
    width: 35,
    minWidth: 10,
    marginTop: 1,
    height: 30,
    padding: 0,
    marginRight: 10
  },
  cardButton: {
    display: 'flex',
    marginTop: 10
  },
  iconButton: {
    height: 30,
    width: 30,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 545px)',
    minHeight: 250,
    marginTop: 15
  },
  form: {
    width: '100%',
    margin: 0,
    height: '75px'
  },
  font: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    minHeight: '0 !important'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiSelect: {
      selectMenu: {
        minHeight: 'none'
      }
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '2px solid #00622B'
        }
      }
    }
  }
});

/**
 * Componente de edição de Plantio Orgânico
 *
 * @author Gabriela Farias
 * @class Componente de cadastro de Insumo
 * @extends {Component} - Componente React
 */
class Insumo extends Component {
  constructor(props) {
    super(props);

    this.headDatatable = [
      {
        valueGetter: args => this.insumos[args.node.data.tpPesticida],
        headerName: formatMessage('plantios.tipo'),
        col: 2
      },
      {
        field: 'marca.nmMarca',
        headerName: formatMessage('plantios.marca'),
        col: 2
      },
      {
        field: 'insumo.nmInsumo',
        headerName: formatMessage('plantios.produto'),
        col: 4
      },
      {
        field: 'formaAplicacao.dsFormaAplicacao',
        headerName: formatMessage('plantios.formaAplic'),
        col: 4
      }
    ];
    this.valueAutoCompleteMarca = '';
    this.valueAutoCompleteDescricao = '';
    this.valueAutoCompleteFormaAplicacao = '';

    this.insumos = [
      formatMessage('plantios.fertilizanteFoliar'),
      formatMessage('plantios.acaricida'),
      formatMessage('plantios.acaricidaMicrobiologico'),
      formatMessage('plantios.bactericida'),
      formatMessage('plantios.agenteBiologicoControle'),
      formatMessage('plantios.bactericidaMicrobiologico'),
      formatMessage('plantios.herbicida'),
      formatMessage('plantios.fungicida'),
      formatMessage('plantios.fungicidaMicrobiologico'),
      formatMessage('plantios.inseticida'),
      formatMessage('plantios.inseticidaMicrobiologico'),
      formatMessage('plantios.nematicida'),
      formatMessage('plantios.nematicidaMicrobiologico'),
      formatMessage('plantios.oleosAdjuvantes'),
      formatMessage('plantios.fertilizanteQuimico'),
      formatMessage('plantios.fertilizanteOrganico'),
      formatMessage('plantios.homeopaticos'),
      formatMessage('plantios.inoculantes')
    ];

    this.categorias = [
      {
        id: 0,
        name: formatMessage('plantios.acaros')
      },
      {
        id: 1,
        name: formatMessage('plantios.bacterias')
      },
      {
        id: 2,
        name: formatMessage('plantios.ervasDaninhas')
      },
      {
        id: 7,
        name: formatMessage('plantios.fertilizantes')
      },
      {
        id: 3,
        name: formatMessage('plantios.foliaresAdjuvantes')
      },
      {
        id: 4,
        name: formatMessage('plantios.fungos')
      },
      {
        id: 8,
        name: formatMessage('plantios.inoculantesHomeopatia')
      },
      {
        id: 5,
        name: formatMessage('plantios.insetos')
      },
      {
        id: 6,
        name: formatMessage('plantios.nematoides')
      }
    ];

    this.state = {
      itemSelecEdit: {
        tpPesticida: '',
        tpCategoria: ''
      },
      itemSelect: {},
      editing: false,
      statusEdit: true,
      keyData: Math.random(),
      allInsumos: [],
      listInsumos: [],
      insumo: [],
      itensMarca: [],
      itensDescricao: [],
      key: Math.random()
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.valueTipo = this.valueTipo.bind(this);
    this.pesquisaDescricao = this.pesquisaDescricao.bind(this);
    this.tratarValorSelectInitialMarca = this.tratarValorSelectInitialMarca.bind(this);
    this.validarInicializacaoAutoCompleteMarca = this.validarInicializacaoAutoCompleteMarca.bind(this);
    this.tratarValorSelectInitialDescricao = this.tratarValorSelectInitialDescricao.bind(this);
    this.clickEdit = this.clickEdit.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
    this.clickClear = this.clickClear.bind(this);
    this.valueCategoria = this.valueCategoria.bind(this);
  }

  /**
   * Marca o componente como montado e carrega os dados da base de dados
   */
  async componentDidMount() {
    var allInsumos = this.props.plantioInsumoList;
    allInsumos.forEach(value => {
      value.tpCategoria = this.valueCategoria(value.tpPesticida);
    });

    this.setState({
      allInsumos: allInsumos,
      keyData: Math.random()
    });
  }

  valueCategoria(value) {
    var categoria = '';

    if (value === 1 || value === 2) {
      categoria = 0;
    } else if (value === 3 || value === 4 || value === 5) {
      categoria = 1;
    } else if (value === 6) {
      categoria = 2;
    } else if (value === 0 || value === 13) {
      categoria = 3;
    } else if (value === 7 || value === 8) {
      categoria = 4;
    } else if (value === 9 || value === 10) {
      categoria = 5;
    } else if (value === 11 || value === 12) {
      categoria = 6;
    } else if (value === 14 || value === 15) {
      categoria = 7;
    } else if (value === 16 || value === 17) {
      categoria = 8;
    }

    return categoria;
  }

  /**
   * Salva os dados
   */
  onSubmit(values, { resetForm }) {
    values.marca = { ...this.valueAutoCompleteMarca };
    values.insumo = { ...this.valueAutoCompleteDescricao };
    values.formaAplicacao = { ...this.valueAutoCompleteFormaAplicacao };

    var allInsumos = this.state.allInsumos;
    if (this.state.editing) {
      const position = this.state.allInsumos.indexOf(this.state.itemSelect);

      allInsumos[position] = values;
      allInsumos = [...allInsumos];
    } else {
      allInsumos.push(values);
    }

    this.valueAutoCompleteMarca = '';
    this.valueAutoCompleteDescricao = '';
    this.valueAutoCompleteFormaAplicacao = '';

    this.props.clickSave(allInsumos);

    this.setState({
      statusEdit: true,
      key: Math.random(),
      allInsumos: allInsumos,
      editing: false,
      keyData: Math.random(),
      itemSelecEdit: {
        tpCategoria: '',
        tpPesticida: ''
      }
    });

    resetForm({
      marca: '',
      tpCategoria: '',
      tpPesticida: '',
      descricao: ''
    });
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        statusEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        statusEdit: true
      });
    }
  }

  clickEdit() {
    this.valueAutoCompleteMarca = { ...this.state.itemSelect.marca };
    this.valueAutoCompleteDescricao = { ...this.state.itemSelect.insumo };
    this.valueAutoCompleteFormaAplicacao = { ...this.state.itemSelect.formaAplicacao };

    this.valueTipo(this.state.itemSelect.tpCategoria);

    this.setState(
      {
        editing: true,
        key: Math.random(),
        itemSelecEdit: { ...this.state.itemSelect }
      },
      () => {
        this.validarInicializacaoAutoCompleteMarca();
        this.pesquisaDescricao(this.state.itemSelect.marca, this.state.itemSelect.tpPesticida);
      }
    );
  }

  clickDelete() {
    swal({
      title: formatMessage('plantios.desejaExcluirInsumo'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('plantios.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('plantios.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          const position = this.state.allInsumos.indexOf(this.state.itemSelect);

          let allInsumos = this.state.allInsumos;
          allInsumos.splice(position, 1);
          allInsumos = [...allInsumos];

          this.valueAutoCompleteDescricao = '';
          this.valueAutoCompleteMarca = '';
          this.valueAutoCompleteFormaAplicacao = '';

          this.setState({
            keyData: Math.random(),
            key: Math.random(),
            allInsumos: allInsumos,
            disabledEdit: false,
            itemSelecEdit: {
              tpCategoria: '',
              tpPesticida: ''
            },
            itemSelect: {},
            editing: false,
            statusEdit: true
          });
          swal(formatMessage('plantios.insumoRemovidaComSucesso'), {
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          });
        } else {
          this.setState({
            disabledEdit: false,
            itemSelecEdit: {},
            itemSelect: {},
            editing: false
          });
        }
      })
      .catch(() => {
        swal(formatMessage('plantios.falhaAoExcluirInsumo'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  valueTipo(value) {
    var insumo = [];

    if (value === 0) {
      insumo = [
        {
          id: InsumosEnum.ACARICIDA,
          name: formatMessage('plantios.acaricida')
        },
        {
          id: InsumosEnum.ACARICIDA_MICROBIOLOGICO,
          name: formatMessage('plantios.acaricidaMicrobiologico')
        }
      ];
    } else if (value === 1) {
      insumo = [
        {
          id: InsumosEnum.AGENTE_BIOLOGICO_CONTROLE,
          name: formatMessage('plantios.agenteBiologicoControle')
        },
        {
          id: InsumosEnum.BACTERICIDA,
          name: formatMessage('plantios.bactericida')
        },
        {
          id: InsumosEnum.BACTERICIDA_MICROBIOLOGICO,
          name: formatMessage('plantios.bactericidaMicrobiologico')
        }
      ];
    } else if (value === 2) {
      insumo = [
        {
          id: InsumosEnum.HERBICIDA,
          name: formatMessage('plantios.herbicida')
        }
      ];
    } else if (value === 3) {
      insumo = [
        {
          id: InsumosEnum.FERTILIZANTE_FOLIAR,
          name: formatMessage('plantios.fertilizanteFoliar')
        },
        {
          id: InsumosEnum.OLEOS_ADJUVANTES,
          name: formatMessage('plantios.oleosAdjuvantes')
        }
      ];
    } else if (value === 4) {
      insumo = [
        {
          id: InsumosEnum.FUNGICIDA,
          name: formatMessage('plantios.fungicida')
        },
        {
          id: InsumosEnum.FUNGICIDA_MICROBIOLOGICO,
          name: formatMessage('plantios.fungicidaMicrobiologico')
        }
      ];
    } else if (value === 5) {
      insumo = [
        {
          id: InsumosEnum.INSETICIDA,
          name: formatMessage('plantios.inseticida')
        },
        {
          id: InsumosEnum.INSETICIDA_MICROBIOLOGICO,
          name: formatMessage('plantios.inseticidaMicrobiologico')
        }
      ];
    } else if (value === 6) {
      insumo = [
        {
          id: InsumosEnum.NEMATICIDA,
          name: formatMessage('plantios.nematicida')
        },
        {
          id: InsumosEnum.NEMATICIDA_MICROBIOLOGICO,
          name: formatMessage('plantios.nematicidaMicrobiologico')
        }
      ];
    } else if (value === 7) {
      insumo = [
        {
          id: InsumosEnum.FERTILIZANTE_QUIMICO,
          name: formatMessage('plantios.fertilizanteQuimico')
        },
        {
          id: InsumosEnum.FERTILIZANTE_ORGANICO,
          name: formatMessage('plantios.fertilizanteOrganico')
        }
      ];
    } else if (value === 8) {
      insumo = [
        {
          id: InsumosEnum.HOMEOPATICO,
          name: formatMessage('plantios.homeopaticos')
        },
        {
          id: InsumosEnum.INOCULANTE,
          name: formatMessage('plantios.inoculantes')
        }
      ];
    }

    this.setState({
      insumo: insumo
    });
  }

  validarInicializacaoAutoCompleteMarca() {
    if (this.state.itemSelecEdit.tpPesticida) {
      Marca.findMarcaByTpPesticida(this.state.itemSelecEdit.tpPesticida).then(query => {
        this.setState({
          itensMarca: query.data
        });
      });
    } else {
      this.setState({
        itensMarca: []
      });
    }
  }

  pesquisaDescricao(marca, pesticida) {
    Pesticida.findPesticidasByTpPesticida(pesticida).then(query => {
      var data = [];
      query.data.forEach(doc => {
        if (doc.marca.idMarca === marca.idMarca) {
          data.push(doc);
        }
      });
      this.setState({
        itensDescricao: data
      });
    });
  }

  tratarValorSelectInitialMarca(data) {
    if (!data || data.nmMarca === undefined) {
      return '';
    }

    return data;
  }

  tratarValorSelectInitialDescricao(data) {
    if (!data || data.nmInsumo === undefined) {
      return '';
    }

    return data;
  }

  tratarValorFormaAplicacao(data) {
    if (!data || data.dsFormaAplicacao === undefined) {
      return '';
    }

    return data;
  }

  clickClear() {
    this.valueAutoCompleteDescricao = '';
    this.valueAutoCompleteMarca = '';
    this.valueAutoCompleteFormaAplicacao = '';

    this.setState({
      statusEdit: true,
      key: Math.random(),
      disabledEdit: false,
      itemSelecEdit: {
        tpCategoria: '',
        tpPesticida: ''
      },
      itemSelect: {},
      editing: false,
      keyData: Math.random()
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <Formik
          initialValues={{
            tpCategoria: this.state.itemSelecEdit.tpCategoria,
            tpPesticida: this.state.itemSelecEdit.tpPesticida,
            marca: this.valueAutoCompleteMarca,
            descricao: this.valueAutoCompleteDescricao,
            formaAplicacao: this.valueAutoCompleteFormaAplicacao
          }}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={this.onSubmit}
          validationSchema={Yup.lazy(values =>
            Yup.object().shape({
              tpCategoria: Yup.string().required(formatMessage('plantios.campoObrigatorio')),
              tpPesticida: Yup.string().required(formatMessage('plantios.campoObrigatorio')),
              marca: Yup.string().required(formatMessage('plantios.campoObrigatorio')),
              descricao: Yup.string().required(formatMessage('plantios.campoObrigatorio'))
            })
          )}
          render={({ values, handleSubmit, errors, touched, setFieldValue }) => {
            return (
              <MuiThemeProvider theme={theme}>
                <Card
                  withOutBorderRadius
                  title={formatMessage('plantios.insumosPlantio')}
                  height='auto'
                  style={{ minHeight: 190, maxHeight: 190, marginBottom: 15 }}
                >
                  <form autoComplete='off'>
                    <Grid container>
                      <Grid item xs={2} style={{ paddingRight: 15 }}>
                        <FormControl
                          className={classes.form}
                          error={errors.tpCategoria && touched.tpCategoria}
                        >
                          <InputLabel
                            htmlFor='tpCategoria-helper'
                            error={errors.tpCategoria && touched.tpCategoria}
                          >
                            {formatMessage('plantios.categoria')}
                          </InputLabel>
                          <Select
                            value={values.tpCategoria}
                            onChange={event => {
                              setFieldValue('tpCategoria', event.target.value);
                              setFieldValue('tpPesticida', '');
                              setFieldValue('marca', '');
                              this.valueAutoCompleteMarca = '';
                              var itemSelecEdit = this.state.itemSelecEdit;
                              itemSelecEdit.tpCategoria = event.target.value;
                              itemSelecEdit.tpPesticida = '';

                              this.setState({
                                key: Math.random()
                              });

                              this.valueTipo(event.target.value);
                            }}
                            error={errors.tpCategoria && touched.tpCategoria}
                          >
                            {this.categorias.map(pesticida => (
                              <MenuItem
                                value={pesticida.id}
                                key={this.categorias.indexOf(pesticida)}
                                className={classes.font}
                              >
                                {pesticida.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.tpCategoria && touched.tpCategoria && (
                            <FormHelperText>{errors.tpCategoria}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={2} style={{ paddingRight: 15 }}>
                        <FormControl
                          disabled={values.tpCategoria === ''}
                          className={classes.form}
                          error={errors.tpPesticida && touched.tpPesticida}
                        >
                          <InputLabel
                            htmlFor='tpPesticida-helper'
                            error={errors.tpPesticida && touched.tpPesticida}
                          >
                            {formatMessage('plantios.tipo')}
                          </InputLabel>
                          <Select
                            value={values.tpPesticida}
                            onChange={event => {
                              setFieldValue('tpPesticida', event.target.value);
                              setFieldValue('marca', '');
                              setFieldValue('descricao', '');
                              this.valueAutoCompleteMarca = '';
                              this.valueAutoCompleteDescricao = '';
                              var itemSelecEdit = this.state.itemSelecEdit;
                              itemSelecEdit.tpPesticida = event.target.value;

                              this.setState(
                                {
                                  key: Math.random()
                                },
                                () => {
                                  this.validarInicializacaoAutoCompleteMarca();
                                }
                              );
                            }}
                            error={errors.tpPesticida && touched.tpPesticida}
                            inputProps={{
                              className: classes.font,
                              name: 'tpPesticida',
                              id: 'tpPesticida-helper'
                            }}
                          >
                            {this.state.insumo.map(pesticida => (
                              <MenuItem
                                value={pesticida.id}
                                key={this.state.insumo.indexOf(pesticida)}
                                className={classes.font}
                              >
                                {pesticida.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.tpPesticida && touched.tpPesticida && (
                            <FormHelperText> {errors.tpPesticida} </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={2} style={{ paddingRight: 15 }}>
                        <Autocomplete
                          disabled={values.tpPesticida === ''}
                          itens={this.state.itensMarca}
                          campoOp={'nmMarca'}
                          campoChave={'idMarca'}
                          label={formatMessage('plantios.marca')}
                          name='marca'
                          error={this.valueAutoCompleteMarca === '' && touched.marca}
                          helperText={
                            touched.marca && this.valueAutoCompleteMarca === ''
                              ? formatMessage('plantios.campoObrigatorio')
                              : null
                          }
                          onChangeOwn={event => {
                            this.setState({
                              key: Math.random()
                            });
                            setFieldValue('marca', event);
                            if (values.tpPesticida !== '' && event !== null) {
                              setFieldValue('descricao', '');
                              this.pesquisaDescricao(event, values.tpPesticida);
                            }
                            if (!event) {
                              setFieldValue('descricao', '');
                              this.valueAutoCompleteMarca = '';
                              this.valueAutoCompleteDescricao = '';
                            } else {
                              this.valueAutoCompleteDescricao = '';
                              this.valueAutoCompleteMarca = event;
                            }
                          }}
                          onChangeAutoComplete={this.validarInicializacaoAutoCompleteMarca}
                          value={this.tratarValorSelectInitialMarca(this.valueAutoCompleteMarca)}
                          key={this.state.key}
                          valueAutoComplete={this.tratarValorSelectInitialMarca(
                            this.valueAutoCompleteMarca
                          )}
                          valueSelect={
                            this.tratarValorSelectInitialMarca(this.valueAutoCompleteMarca).nmMarca
                          }
                        />
                      </Grid>

                      <Grid item xs={3} style={{ paddingRight: 15 }}>
                        <Autocomplete
                          disabled={
                            this.valueAutoCompleteDescricao === '' && this.valueAutoCompleteMarca === ''
                          }
                          itens={this.state.itensDescricao}
                          campoOp={'nmInsumo'}
                          campoChave={'idInsumo'}
                          label={formatMessage('plantios.produto')}
                          name='nmInsumo'
                          error={this.valueAutoCompleteDescricao === '' && touched.descricao}
                          helperText={
                            touched.descricao && this.valueAutoCompleteDescricao === ''
                              ? formatMessage('plantios.campoObrigatorio')
                              : null
                          }
                          onChangeOwn={event => {
                            this.setState({
                              key: Math.random()
                            });
                            setFieldValue('descricao', event);
                            this.valueAutoCompleteDescricao = event;
                          }}
                          onChangeAutoComplete={() => {
                            this.pesquisaDescricao(this.valueAutoCompleteMarca, values.tpPesticida);
                          }}
                          value={this.tratarValorSelectInitialDescricao(this.valueAutoCompleteDescricao)}
                          key={this.state.key}
                          valueAutoComplete={this.tratarValorSelectInitialDescricao(
                            this.valueAutoCompleteDescricao
                          )}
                          valueSelect={
                            this.tratarValorSelectInitialDescricao(this.valueAutoCompleteDescricao)
                              .nmInsumo
                          }
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Autocomplete
                          itens={this.props.formaAplicacaoList}
                          campoOp='dsFormaAplicacao'
                          campoChave='idFormaAplicacao'
                          label={formatMessage('plantios.formaAplic')}
                          name='formaAplicacoa'
                          onChangeOwn={event => {
                            this.setState({
                              key: Math.random()
                            });
                            setFieldValue('descricao', event);
                            this.valueAutoCompleteFormaAplicacao = event;
                          }}
                          value={this.tratarValorFormaAplicacao(this.valueAutoCompleteFormaAplicacao)}
                          key={this.state.key}
                          valueAutoComplete={this.tratarValorFormaAplicacao(
                            this.valueAutoCompleteFormaAplicacao
                          )}
                          valueSelect={
                            this.tratarValorFormaAplicacao(this.valueAutoCompleteFormaAplicacao)
                              .dsFormaAplicacao
                          }
                        />
                      </Grid>

                      <Grid item xs={1} className={classes.cardButton}>
                        <Button className={classes.button} onClick={handleSubmit}>
                          <Add className={classes.iconButton} />
                        </Button>
                        <Button className={classes.button} onClick={this.clickClear}>
                          <Close className={classes.iconButton} />
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Card>

                <div className={classes.grid}>
                  <GroupButtonGrid
                    hiddenMargin
                    withoutMargin={false}
                    showDelete
                    showEdit
                    disableDelete={this.state.statusEdit}
                    disableEdit={this.state.statusEdit}
                    onClickEdit={this.clickEdit}
                    onClickDelete={this.clickDelete}
                  />

                  <DataTable
                    key={this.state.keyData}
                    data={this.state.allInsumos}
                    columns={this.headDatatable}
                    selectChange={this.selectChange}
                    showPagination={false}
                    borderRadius={false}
                    noMargin
                  />
                </div>
              </MuiThemeProvider>
            );
          }}
        />
      </div>
    );
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'plantios';
  })[0].content
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(Insumo)));
