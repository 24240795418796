import AtividadeUrlEnum from '../enumerations/AtividadeUrlEnum';

function TitleDashboard(tpLancamento) {
  switch (tpLancamento) {
    case AtividadeUrlEnum.ALERTA_FITOSSANITARIO:
      return 'acompanhamentoProdutor.lancamentoAlerta';
    case AtividadeUrlEnum.APLICACAO:
      return 'acompanhamentoProdutor.lancamentoAplicacao';
    case AtividadeUrlEnum.REGISTRO_FOTOGRAFICO:
      return 'acompanhamentoProdutor.lancamentoRegistroFotografico';
    case AtividadeUrlEnum.REGISTRO_CLIMATICO:
      return 'acompanhamentoProdutor.lancamentoRegistroClimatico';
    default:
      return 'acompanhamentoProdutor.lancamentoRecomendacao';
  }
}

export default TitleDashboard;