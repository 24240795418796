/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loading from '@components/Loading/Loading';
import FilterUtil from '../../filterUtil';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Produtor from '@resources/Produtores';
import Add from '@material-ui/icons/Add';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import DoneAll from '@material-ui/icons/DoneAll';
import ButtonGrid from '@components/ButtonGrid/ButtonGrid';
import formatMessage from './i18n/formatMessage';

const iconMenuPontos = require('@images/icone_fab_menu.png');

const style = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/*
    Tela de Produtores customizada
*/
class Produtores extends Component {
  constructor(props) {
    super(props);

    const operationTypeString = [
      {
        label: formatMessage('produtores.nome'),
        field: 'nmProdutor',
        type: 'string'
      },
      {
        label: formatMessage('produtores.municipio'),
        field: 'nmMunicipio',
        type: 'string'
      },
      {
        label: formatMessage('produtores.comunidade'),
        field: 'nmComunidade',
        type: 'string'
      },
      {
        label: formatMessage('produtores.status'),
        field: 'stProdutor',
        type: 'enum',
        options: [
          {
            label: formatMessage('produtores.ativo'),
            field: 'ativo',
            value: '1'
          },
          {
            label: formatMessage('produtores.inativo'),
            field: 'inativo',
            value: '0'
          }
        ]
      }
    ];

    const head = [
      {
        field: 'nmProdutor',
        headerName: formatMessage('produtores.nome'),
        col: 3
      },
      {
        field: 'nrTelefone',
        headerName: formatMessage('produtores.telefone'),
        col: 2
      },
      {
        field: 'nmMunicipio',
        headerName: formatMessage('produtores.municipio'),
        col: 2
      },
      {
        field: 'comunidade.nmComunidade',
        headerName: formatMessage('produtores.comunidade'),
        col: 2
      },
      {
        valueGetter: args =>
          [formatMessage('produtores.inativo'), formatMessage('produtores.ativo')][
            args.node.data.stProdutor
          ],
        headerName: formatMessage('produtores.status'),
        col: 1
      }
    ];

    this.state = {
      dataInTable: [],
      dataAppSync: [],
      itemSelect: '',
      tpPerfil: 2,
      disabledEdit: true,
      loading: true,
      operationTypeStringTecnico: operationTypeString.concat({
        label: formatMessage('produtores.tipo'),
        field: 'tpCadastro',
        type: 'enum',
        options: [
          {
            label: formatMessage('produtores.preCadastro'),
            field: 'preCadastro',
            value: '1'
          },
          {
            label: formatMessage('produtores.completo'),
            field: 'completo',
            value: '0'
          }
        ]
      }),
      operationTypeStringRevenda: operationTypeString.concat(
        {
          label: formatMessage('produtores.tecnico'),
          field: 'nmTecnico',
          type: 'string'
        },
        {
          label: formatMessage('produtores.tipo'),
          field: 'tpCadastro',
          type: 'enum',
          options: [
            {
              label: formatMessage('produtores.preCadastro'),
              field: 'preCadastro',
              value: '1'
            },
            {
              label: formatMessage('produtores.completo'),
              field: 'completo',
              value: '0'
            }
          ]
        }
      ),

      headTecnico: head.concat({
        field: 'nmTpCadastro',
        headerName: formatMessage('produtores.tipo'),
        col: 1
      }),
      headRevenda: head.concat(
        {
          field: 'tecnico.nmUsuario',
          headerName: formatMessage('produtores.tecnico'),
          col: 1
        },
        {
          field: 'nmTpCadastro',
          headerName: formatMessage('produtores.tipo'),
          col: 1
        }
      )
    };

    const title = 'produtores.produtores';
    if (this.props.itensState.name !== 'produtores.produtores') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(title),
        name: title
      });
    }

    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.retornarClickAdicionar = this.retornarClickAdicionar.bind(this);
    this.retornarClickPreAdicionar = this.retornarClickPreAdicionar.bind(this);
    this.clickPreCadastro = this.clickPreCadastro.bind(this);
    this.onClickEfetivar = this.onClickEfetivar.bind(this);
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  componentDidMount() {
    this.onFilter(this.props.chipData);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    this.setState({
      loading: true
    });
    dataChips = dataChips || [];

    Produtor.findAllProdutorWithPre()
      .then(query => {
        var data = query.data;

        data.forEach(doc => {
          if (doc.comunidade !== undefined) {
            doc.nmComunidade = doc.comunidade.nmComunidade;
          } else {
            doc.nmComunidade = '';
          }

          doc.nmMunicipio = doc.municipio.nmMunicipio + ' - ' + doc.municipio.uf.sgUf;
          doc.nmUf = doc.municipio.uf.nmUf;

          doc.nmTpCadastro =
            doc.tpCadastro === 1
              ? formatMessage('produtores.preCadastro')
              : formatMessage('produtores.completo');
          doc.nmTecnico = doc.tecnico !== undefined ? doc.tecnico.nmUsuario : '';
        });

        !this.isCancelled &&
          this.setState({
            dataAppSync: data,
            dataInTable: FilterUtil.applyFilters(data, this.props.chipData),
            loading: false
          });
      })
      .catch(err => {
        this.setState({
          dataAppSync: [],
          dataInTable: [],
          loading: false
        });
      });
  }

  retornarClickAdicionar() {
    return formatMessage('produtores.cadastroCompleto');
  }

  retornarClickPreAdicionar() {
    return formatMessage('produtores.cadastroPreProdutor');
  }

  clickAdicionar() {
    this.props.history.push('/app/produtores/novo');
  }

  clickPreCadastro() {
    this.props.history.push('/app/pre-cadastro/novo');
  }

  onClickEfetivar() {
    this.props.history.push(
      '/app/produtores/editar/' + this.state.itemSelect.idProdutor + '/pre-cadastro/1'
    );
  }

  clickEditar() {
    if (this.state.itemSelect.tpCadastro === 0) {
      this.props.history.push('/app/produtores/editar/' + this.state.itemSelect.idProdutor);
    } else {
      this.props.history.push('/app/pre-cadastro/editar/' + this.state.itemSelect.idProdutor);
    }
  }

  clickExcluir() {
    swal({
      title: formatMessage('produtores.desejaExcluir') + this.state.itemSelect.nmProdutor + '?',
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('produtores.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('produtores.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            title: formatMessage('produtores.excluindo')
          }
        );

        var itemDelete = Produtor.deleteProdutorById(this.state.itemSelect.idProdutor);

        if (this.state.itemSelect.tpCadastro === 1) {
          itemDelete = Produtor.deletePreCadastroById(this.state.itemSelect.idProdutor);
        }

        itemDelete
          .then(result => {
            swal(formatMessage('produtores.produtorExcluidoComSucesso'), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              var data = [...this.state.dataInTable];
              var allData = [...this.state.dataAppSync];

              const position = data.indexOf(this.state.itemSelect);
              const positionAllData = allData.indexOf(this.state.dataAppSync);
              data.splice(position, 1);
              allData.splice(positionAllData, 1);
              data = [...data];
              allData = [...allData];

              this.setState({
                dataInTable: data,
                dataAppSync: allData
              });
            });
          })
          .catch(err => {
            if (err.response !== undefined && err.response.data.codigo === 'AGENDA_RELACIONADA') {
              this.swalError(formatMessage('produtores.agendaRelacionada'));
            } else if (
              err.response !== undefined &&
              err.response.data.codigo === 'ENTIDADE_RELACIONADA'
            ) {
              this.swalError(formatMessage('produtores.registrosRelacionados'));
            } else if (err.response !== undefined) {
              this.swalError(err.response.data.mensagem);
            } else {
              this.swalError(formatMessage('produtores.ocorreuErroExcluirProdutor'));
            }
          });
      }
    });
  }

  swalError(title) {
    swal(formatMessage('produtores.falhaAoExcluirProdutor'), title, {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  render() {
    const paginacao = {
      length: this.state.dataInTable.length,
      page: 0
    };
    const { classes } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflowY: 'scroll'
        }}
      >
        {this.state.loading && <Loading />}
        <CardFilters
          filtros={
            parseInt(this.props.infoUsuario.tpPerfil) === 2
              ? this.state.operationTypeStringTecnico
              : this.state.operationTypeStringRevenda
          }
          onFilter={this.onFilter}
          usePaddings={false}
          onFilterChanged={this.onFilterChanged}
          viewKey='produtores'
        />
        <GroupButtonGrid
          customButtons={[
            <ButtonGrid
              withoutMargin={this.props.withoutMargin}
              show={true}
              icon={<DoneAll style={{ color: '#FFFFFF' }} />}
              iconSVG={true}
              color='#463766'
              disabled={this.state.itemSelect.tpCadastro !== 1}
              onClick={this.onClickEfetivar}
            />
          ]}
          showAdd={false}
          showEdit={true}
          showDelete={true}
          disableEdit={this.state.disabledEdit}
          disableDelete={this.state.disabledEdit}
          onClickDelete={this.clickExcluir}
          onClickEdit={this.clickEditar}
        />

        <div
          style={{
            marginTop: 30,
            marginBottom: 15,
            display: 'contents',
            width: '100%',
            flex: 1
          }}
        >
          {parseInt(this.props.infoUsuario.tpPerfil) === 2 && (
            <DataTable
              usePaddings={false}
              data={this.state.dataInTable}
              columns={this.state.headTecnico}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={paginacao}
            />
          )}
          {(parseInt(this.props.infoUsuario.tpPerfil) === 0 ||
            parseInt(this.props.infoUsuario.tpPerfil) === 1) && (
            <DataTable
              usePaddings={false}
              data={this.state.dataInTable}
              columns={this.state.headRevenda}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={paginacao}
            />
          )}
        </div>

        <div className={classes.fab}>
          <ButtonFABMenu
            icon={iconMenuPontos}
            actions={[
              {
                icon: <Add style={{ color: 'white' }} />,
                name: this.retornarClickAdicionar(),
                onClickIcon: this.clickAdicionar,
                color: '#42ADE8'
              },
              {
                icon: <InsertDriveFile style={{ color: 'white' }} />,
                name: this.retornarClickPreAdicionar(),
                onClickIcon: this.clickPreCadastro,
                color: '#42ADE8',
                disabled: this.state.error
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'produtores';
  })[0].content
});

let enhaced = withStyles(style)(Produtores);
export default withRouter(connect(mapStateToProps)(enhaced));
