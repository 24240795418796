export default {
  fertilizantesOrganicos: {
    fertilizantesOrganicosRemovidoComSucesso: 'Organic fertilizer successfully removed',
    falhaAoExcluirFertilizantesOrganicos: 'Failed to exclude organic fertilizer',
    desejaExcluir: 'Do you want to delete the selected organic fertilizer?',
    fertilizantesOrganicos: 'Organic fertilizers',
    cadastroDeFertilizantesOrganicos: 'Organic fertilizer record',
    identificacaoDaFertilizantesOrganicos: 'Identification',
    fertilizantesOrganicosCadastradoComSucesso: 'Organic fertilizer successfully registered',
    fertilizantesOrganicosEditadoComSucesso: 'Organic fertilizer edited successfully',
    falhaAoEditarFertilizantesOrganicos: 'Failed to edit organic fertilizer',
    falhaAoCadastrarFertilizantesOrganicos: 'Failed to register organic fertilizer',
    falhaAoCarregarFertilizantesOrganicos: 'Failed to load organic fertilizer'
  }
};
