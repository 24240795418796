export default {
  especies: {
    especies: 'Species',
    descricao: 'Description',
    desejaExcluir: 'Do you want to delete species?',
    nao: 'No',
    excluir: 'Delete',
    excluindo: 'Excluding',
    falhaAoExcluirEspecie: 'Failed to delete species',
    especieRemovidoComSucesso: 'Species successfully removed',
    especieCadastradaComSucesso: 'Successfully registered species',
    especieEditadaComSucesso: 'Successfully edited species',
    falhaAoEditarEspecie: 'Failed to edit species',
    falhaAoCadastrarEspecie: 'Failed to register species',
    falhaAoCarregarEspecie: 'Failed to load species',
    salvar: 'Save',
    atualizar: 'Update',
    cancelar: 'Cancel',
    salvando: 'Saving',
    atualizando: 'Updating',
    cadastroDeEspecie: 'Species Registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    ativo: 'Active',
    situacao: 'Situation',
    inativo: 'Inactive'
  }
};
