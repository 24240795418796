import React, { useEffect, useState } from 'react';
import formatMessage from './i18n/formatMessage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { style, theme } from './AgendaVisitas.styles';
import { withStyles, Grid, Checkbox, MuiThemeProvider } from '@material-ui/core';
import L from 'leaflet';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import AcompanhamentoTecnicos from '@resources/AcompanhamentoTecnico';
import MapaIconRed from '@images/icon_mapa_red.svg';
import MapaIconBlue from '@images/icon_mapa_blue.svg';
import MapaIconGreen from '@images/icon_mapa_green.svg';
import MapaIconWarning from '@images/icon_mapa_warning.svg';
import ListagemIcon from '@images/icon_detalhes_agenda.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Usuario from '@resources/Usuario';
import { Search } from '@material-ui/icons';
import AtividadeEnum from './AtividadeEnum';
import * as moment from 'moment';
import DataTable from '@components/DataTable/DataTable';
import swal from '@sweetalert/with-react';

const headDatatable = [
  {
    valueGetter: args => args.node.data.tecnico.nmUsuario,
    headerName: formatMessage('agendaVisitas.tecnicoResponsavel'),
    col: 2
  },
  {
    valueGetter: args => args.node.data.produtor.nmProdutor,
    headerName: formatMessage('agendaVisitas.produtor'),
    col: 2
  },
  {
    valueGetter: args => args.node.data.dsSituacao,
    headerName: formatMessage('agendaVisitas.situacao'),
    col: 2
  },
  {
    valueGetter: args => args.node.data.dsInicioVisita,
    headerName: formatMessage('agendaVisitas.data'),
    col: 1
  },
  {
    valueGetter: args => args.node.data.dsAtividade,
    headerName: formatMessage('agendaVisitas.atividadeAtribuida'),
    col: 5
  }
];

function AgendaVisitas(props) {
  const initialNrAtividade = {
    nrAplicacaoProduto: 0,
    nrRecomendacaoAplicacao: 0,
    nrAlertaFitossanitario: 0,
    nrMonitoramento: 0,
    nrRegistroClimatico: 0,
    nrProdutoProibido: 0,
    nrSemAtividade: 0
  };
  const { classes } = props;
  const [stAgendado, setStAgendado] = useState(false);
  const [stAplicacao, setStAplicacao] = useState(true);
  const [diasSemVisita, setDiasSemVisita] = useState(false);
  const [stProdutoProibido, setStProdutoProibido] = useState(true);
  const [stMonitoramento, setStMonitoramento] = useState(true);
  const [stVisitado, setStVisitado] = useState(true);
  const [stRecomendacao, setStRecomendacao] = useState(true);
  const [stClimatico, setStClimatico] = useState(true);
  const [stFotografico, setStFotografico] = useState(true);
  const [stAlerta, setStAlerta] = useState(true);
  const [semVisitasList, setSemVisitasList] = useState([]);
  const [agendadoList, setAgendadoList] = useState([]);
  const [visitadoList, setVisitadoList] = useState([]);
  const [visitadoListFiltered, setVisitadoListFiltered] = useState([]);
  const [allMarkersAgendado, setAllMarkersAgendado] = useState([]);
  const [allMarkersVisitado, setAllMarkersVisitado] = useState([]);
  const [allMarkersSemVisita, setAllMarkersSemVisita] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usuarioList, setUsuarioList] = useState([]);
  const [map, setMap] = useState(null);
  const [tecnico, setTecnico] = useState(null);
  const date = new Date();
  const [dtInicial, setDtInicial] = useState(date.setDate(date.getDate() - 15));
  const [dtFinal, setDtFinal] = useState(new Date());
  const [nrAtividadesAtribuidas, setNrAtividadesAtribuidas] = useState({ ...initialNrAtividade });
  const [qtProdutoreVisitasList, setQtProdutoreVisitasList] = useState({});
  const [stGridView, setStGridView] = useState(false);
  const [stSemAtividade, setStSemAtividade] = useState(true);
  const setProdutor = new Set();

  useEffect(() => {
    require('./AgendaVisitas.css');

    const title = 'agendaVisitas.agendaVisitas';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    createMap();
    findAllTecnicos();
  }, []);

  useEffect(() => {
    if (map) {
      buscarVisitasPendentes();
    }
  }, [map]);

  function findAllTecnicos() {
    Usuario.findAllUsuarios().then(query => {
      setUsuarioList(query.data.filter(usuario => usuario.tpPerfil === 2));
    });
  }

  /**
   * Consulta os dados na API
   */
  function buscarVisitasPendentes() {
    AcompanhamentoTecnicos.findAgendaPendentes(
      tecnico ? tecnico.idUsuario : null,
      moment(dtInicial).format('YYYY-MM-DD'),
      moment(dtFinal).format('YYYY-MM-DD')
    )
      .then(resultado => {
        let result = resultado.data;

        let findWithoutLatLgn = false;
        findWithoutLatLgn = result.visitadoList.find((item) => item.nrLatitude === 0 && item.nrLongitude === 0 )

        if (!findWithoutLatLgn) {
          findWithoutLatLgn = result.agendadoList.find((item) => item.nrLatitude === 0 && item.nrLongitude === 0 )
        }

        if (!findWithoutLatLgn) {
          findWithoutLatLgn = result.visitaPendenteList.find((item) => item.nrLatitude === 0 && item.nrLongitude === 0 )
        }
        
        if (findWithoutLatLgn) {
          swal(formatMessage('agendaVisitas.itemSemCoordenadas'), {
            icon: 'warning',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-Ok'
              }
            }
          });
        }

        result.visitadoList.forEach(item => {
          item.idVisitado = Math.random();
          if (validationSemAtividade(item)) {
            item.nrSemAtividade = 1;
          }
        });
        uploadCheckVisitas();
        setAgendadoList(result.agendadoList);
        setVisitadoList(result.visitadoList);
        setVisitadoListFiltered(result.visitadoList);
        setSemVisitasList(result.visitaPendenteList);
        addInMapVisitado(result.visitadoList);
      })
      .catch(() => {
        setSemVisitasList([]);
        setAgendadoList([]);
        setVisitadoList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function validationSemAtividade(item) {
    const recomendacao = item['nrRecomendacaoAplicacao'] === 0;
    const prodProibido = item['nrProdutoProibido'] === 0;
    const climatico = item['nrRegistroClimatico'] === 0;
    const fotografico = item['nrRegistroFotografico'] === 0;
    const monitoramento = item['nrMonitoramento'] === 0;
    const nrAlertaFitossanitario = item['nrAlertaFitossanitario'] === 0;
    const aplicacao = item['nrAplicacaoProduto'] === 0;

    return (
      recomendacao &&
      prodProibido &&
      climatico &&
      fotografico &&
      monitoramento &&
      nrAlertaFitossanitario &&
      aplicacao
    );
  }

  function countNrVisitado(visitadoListAux) {
    const qtProdutoreVisitasListAux = [];
    const produtorList = visitadoListAux.map(visita => visita.produtor);
    const filterProdutor = produtorList.filter(produtor => {
      const duplicatedProdutor = setProdutor.has(produtor.idProdutor);
      setProdutor.add(produtor.idProdutor);
      return !duplicatedProdutor;
    });

    filterProdutor.forEach(produtor => {
      const qtProdutor = produtorList.filter(
        produtorAux => produtorAux.idProdutor === produtor.idProdutor
      ).length;
      qtProdutoreVisitasListAux.push({ idProdutor: produtor.idProdutor, qtProdutor: qtProdutor });
    });

    setQtProdutoreVisitasList(qtProdutoreVisitasListAux);
    return qtProdutoreVisitasListAux;
  }

  /**
   * Adiciona o pin de agendado no mapa
   */
  function addInMapAgendado(result) {
    const qtProdutoreVisitasListAux = countNrVisitado(result);
    const agendadoListAux = filterWithoutCoordenadas(result);

    let greenIcon = L.icon({
      iconUrl: MapaIconBlue,
      iconSize: [25, 29]
    });

    const allMarkersAgendadoAux = [];

    agendadoListAux.forEach(agendado => {
      const marker = addMarkerToMap(agendado, greenIcon, qtProdutoreVisitasListAux);
      marker.key = agendado.idAgenda;
      allMarkersAgendadoAux.push(marker);
    });

    setAllMarkersAgendado(allMarkersAgendadoAux);
  }

  function getGreenIcon(stWarning) {
    return L.icon({
      iconUrl: stWarning ? MapaIconWarning : MapaIconGreen,
      iconSize: [25, 29]
    });
  }

  /**
   *  Adiciona o pin de visitado no mapa
   */
  function addInMapVisitado(result) {
    const qtProdutoreVisitasListAux = countNrVisitado(result);
    const visitadoListAux = filterWithoutCoordenadas(result);

    let greenIcon = getGreenIcon();
    let warningIcon = getGreenIcon(true);

    const allMarkersVisitadoAux = [];

    visitadoListAux.forEach(visitado => {
      const stWarning = markerWarning(visitado, visitadoListAux);
      const marker = addMarkerToMap(
        visitado,
        stWarning ? warningIcon : greenIcon,
        qtProdutoreVisitasListAux
      );
      marker.key = visitado.idAgenda;
      allMarkersVisitadoAux.push(marker);
    });

    countAtividades(visitadoListAux);
    setAllMarkersVisitado(allMarkersVisitadoAux);
  }

  function markerWarning(visitado, visitadoListAux) {
    return (
      visitado.nrProdutoProibido > 0 ||
      visitadoListAux.find(
        item => item.nrProdutoProibido > 0 && item.produtor.idProdutor === visitado.produtor.idProdutor
      )
    );
  }

  function countAtividades(visitadoListAux) {
    const countAtividade = { ...initialNrAtividade };

    visitadoListAux.forEach(atividade => {
      if (atividade.nrAplicacaoProduto > 0) {
        countAtividade.nrAplicacaoProduto += atividade.nrAplicacaoProduto;
      }

      if (atividade.nrAlertaFitossanitario > 0) {
        countAtividade.nrAlertaFitossanitario += atividade.nrAlertaFitossanitario;
      }

      if (atividade.nrMonitoramento > 0) {
        countAtividade.nrMonitoramento += atividade.nrMonitorament;
      }

      if (atividade.nrRecomendacaoAplicacao > 0) {
        countAtividade.nrRecomendacaoAplicacao += atividade.nrRecomendacaoAplicacao;
      }

      if (atividade.nrRegistroClimatico > 0) {
        countAtividade.nrRegistroClimatico += atividade.nrRegistroClimatico;
      }

      if (atividade.nrProdutoProibido > 0) {
        countAtividade.nrProdutoProibido += atividade.nrProdutoProibido;
      }

      if (atividade.nrSemAtividade > 0) {
        countAtividade.nrSemAtividade += atividade.nrSemAtividade;
      }
    });

    setNrAtividadesAtribuidas(countAtividade);
  }

  /**
   * Adiciona o pin de sem visitas no mapa
   */
  function addInMapSemVisitas(result) {
    const semVisitaListAux = filterWithoutCoordenadas(result);

    let greenIcon = L.icon({
      iconUrl: MapaIconRed,
      iconSize: [25, 29]
    });

    const allMarkersSemVisitaAux = [];
    semVisitaListAux.forEach((semVisita, i) => {
      const marker = addMarkerToMap(semVisita, greenIcon, qtProdutoreVisitasList);
      marker.key = i;
      allMarkersSemVisitaAux.push(marker);
    });

    setAllMarkersSemVisita(allMarkersSemVisitaAux);
  }

  /**
   * filtra apenas pelas coordenadas diferentes de 0
   */
  function filterWithoutCoordenadas(coordenadasList) {
    return coordenadasList.filter(obj => obj.nrLatitude !== 0 && obj.nrLongitude !== 0);
  }

  /**
   * Monta componente que vai aparecer no pop up
   */
  function mountStringToPopUp(semVisita, qtProdutoreVisitasListAux) {
    const qtVisitas = qtProdutoreVisitasListAux.find(
      produtor => produtor.idProdutor === semVisita.produtor.idProdutor
    );

    let labelQt = '';
    if (qtVisitas) {
      labelQt = stVisitado ? 'realizada' : 'agendada';
      if (qtVisitas.qtProdutor > 1) {
        labelQt = ' visitas ' + labelQt + 's';
      } else {
        labelQt = ' visita ' + labelQt;
      }
    }

    return (
      '<div class="card-all"><p class="card-icon"><i class="material-icons">person</i><b>' +
      semVisita.produtor.nmProdutor +
      '</b></p>' +
      '<p class="card-icon"> <i class="material-icons">nature_people</i> <b>' +
      semVisita.tecnico.nmUsuario +
      '</b></p>' +
      (qtVisitas
        ? '<p class="card-icon"><i class="material-icons">event</i><b>' +
          qtVisitas.qtProdutor +
          labelQt +
          '</b></p>'
        : '') +
      '</div>'
    );
  }

  /**
   * Adiciona marker no mapa
   */
  function addMarkerToMap(semVisita, greenIcon, qtProdutoreVisitasListAux) {
    return L.marker([semVisita.nrLatitude, semVisita.nrLongitude], { icon: greenIcon })
      .addTo(map)
      .bindPopup(mountStringToPopUp(semVisita, qtProdutoreVisitasListAux))
      .closePopup();
  }

  function valorInicialAutoComplete(data) {
    if (!data || data.nmUsuario === undefined) {
      return '';
    }
    return data;
  }

  /**
   * Cria o mapa
   */
  function createMap() {
    let center = [-21.1986118, -50.5312323];
    let zoom = 5;
    let mapLink = '<a href="http://www.esri.com/">Esri</a>';
    let wholink = `Source: ${mapLink}, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community`;
    const mapAux = L.map(document.getElementById('google-map-agenda'), {
      center: center,
      zoom: zoom,
      zoomControl: false,
      layers: [
        L.tileLayer(
          'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: wholink,
            maxZoom: 17
          }
        )
      ]
    });
    L.control
      .zoom({
        position: 'bottomright'
      })
      .addTo(mapAux);

    setMap(mapAux);
  }

  return (
    <MuiThemeProvider theme={theme}>
      {loading && (
        <div className={classes.cardCarregando}>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>
      )}

      <div className={classes.cardAll}>
        {filtrosTecnicoData()}

        {stGridView && (
          <div
            style={{
              opacity: loading ? 0.5 : 1,
              height: 'calc(100% - 260px)',
              position: 'fixed',
              zIndex: 100
            }}
            className={classes.cardMap}
          >
            <div className={classes.grid}>
              <DataTable
                data={ajustListToTable()}
                columns={headDatatable}
                showPagination={false}
                borderRadius={false}
                noSelection
                noMargin
              />
            </div>
          </div>
        )}

        <div style={{ opacity: loading ? 0.5 : 1 }} className={classes.cardMap}>
          <div id='google-map-agenda' className={classes.mapa} />
        </div>

        {filtrosCheck()}
      </div>
    </MuiThemeProvider>
  );

  function ajustListToTable() {
    const gridList = [];

    if (stVisitado) {
      visitadoListFiltered.forEach(item => {
        item.dsSituacao = formatMessage('agendaVisitas.visitado');
        item.dsAtividade = mountLabelAtividade(item);
        item.dsInicioVisita = moment(item.dhInicioVisita).format('DD/MM/YYYY');
        gridList.push(item);
      });
    }

    if (stAgendado) {
      agendadoList.forEach(item => {
        item.dsSituacao = formatMessage('agendaVisitas.agendado');
        item.dsInicioVisita = moment(item.dhInicioVisita).format('DD/MM/YYYY');
        item.dsAtividade = '-';
        gridList.push(item);
      });
    }

    if (diasSemVisita) {
      semVisitasList.forEach(item => {
        item.dsSituacao = formatMessage('agendaVisitas.diasSemVisita');
        item.dsAtividade = '-';
        item.dsInicioVisita = '-';
        gridList.push(item);
      });
    }

    return gridList;
  }

  function mountLabelAtividade(item) {
    let dsAtividade = '';

    if (item.nrAlertaFitossanitario > 0)
      dsAtividade += ` ${formatMessage('agendaVisitas.alertaFitossanitario')},`;
    if (item.nrAplicacaoProduto > 0) dsAtividade += ` ${formatMessage('agendaVisitas.aplicProduto')},`;
    if (item.nrMonitoramento > 0)
      dsAtividade += ` ${formatMessage('agendaVisitas.monitoramentoPragas')},`;
    if (item.nrProdutoProibido > 0)
      dsAtividade += ` ${formatMessage('agendaVisitas.aplicProdutoProibido')},`;
    if (item.nrRecomendacaoAplicacao > 0)
      dsAtividade += ` ${formatMessage('agendaVisitas.recomendacaoAplic')},`;
    if (item.nrRegistroClimatico > 0)
      dsAtividade += ` ${formatMessage('agendaVisitas.registroClimatico')},`;
    if (item.nrRegistroFotografico > 0)
      dsAtividade += ` ${formatMessage('agendaVisitas.registroFotografico')},`;

    return dsAtividade.replace(/.$/, '.');
  }

  /**
   * Componente de filtros de técnico e data
   * @returns - componente
   */
  function filtrosTecnicoData() {
    return (
      <div className={classes.cardFiltros}>
        <Grid container spacing={16} className={classes.gridFiltros}>
          <Grid item xs={4}>
            <Autocomplete
              itens={usuarioList}
              campoOp='nmUsuario'
              campoInput='nmUsuario'
              campoChave='idUsuario'
              label={formatMessage('agendaVisitas.tecnico')}
              name='tecnico'
              onChangeOwn={e => setTecnico(e)}
              onChangeAutoComplete={e => setTecnico(e)}
              value={valorInicialAutoComplete(tecnico)}
              valueAutoComplete={valorInicialAutoComplete(tecnico)}
              valueSelect={valorInicialAutoComplete(tecnico).nmUsuario}
              color={'black'}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              locale={getUserLang()}
              className={classes.textField}
              maxDate={dtFinal}
              label={formatMessage('agendaVisitas.dataInicial')}
              valueDate={dtInicial}
              onBlurDatePicker={e => setDtInicial(e)}
              onChangeDatePicker={e => setDtInicial(e)}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              locale={getUserLang()}
              minDate={dtInicial}
              className={classes.textField}
              label={formatMessage('agendaVisitas.dataFinal')}
              valueDate={dtFinal}
              onBlurDatePicker={e => setDtFinal(e)}
              onChangeDatePicker={e => setDtFinal(e)}
            />
          </Grid>

          <Grid item xs={1} className={classes.cardImageData}>
            <Search className={classes.search} onClick={clickFiltros} />
          </Grid>
          <Grid className={classes.cardImageData} item xs={1}>
            <img className={classes.image} src={ListagemIcon} onClick={onClickGrid} alt='listagem' />
          </Grid>
        </Grid>
      </div>
    );
  }

  /**
   * Muda o view entre grid e mapa
   */
  function onClickGrid() {
    setStGridView(!stGridView);
  }

  /**
   * Recarrega pagina ao aplicar filtros
   */
  function clickFiltros() {
    allMarkersVisitado.forEach(marker => {
      map.removeLayer(marker);
    });
    setLoading(true);
    buscarVisitasPendentes();
  }

  /**
   * Componente de filtros aplicados
   * @returns - componente de filtros
   */
  function filtrosCheck() {
    return (
      <div className={classes.cardCheckFiltros}>
        <Grid container spacing={16} style={{ fontWeight: 400 }}>
          <Grid item xs={4}>
            {formatMessage('agendaVisitas.situacao') + ': '}
          </Grid>
          <Grid item xs={4}>
            {formatMessage('agendaVisitas.atividadeAtribuida') + ': '}
          </Grid>
        </Grid>

        <Grid container spacing={16}>
          <Grid item xs={4} className={classes.cardCheck} onClick={onClickVisitado}>
            <Checkbox
              checked={stVisitado}
              className={classes.checkBox}
              value='stVisitado'
              style={{ color: '#00AB4A' }}
            />
            {labelAtividade(formatMessage('agendaVisitas.visitado'), visitadoList.length)}
          </Grid>
          {props.infoUsuario.stOrganica === 0 && gridRegistroFotografico()}
          {props.infoUsuario.stOrganica === 1 && (
            <Grid
              item
              xs={4}
              className={classes.cardCheck}
              onClick={() => filtrarByAtividade(AtividadeEnum.PRODUTO_PROIBIDO)}
            >
              <Checkbox
                checked={stProdutoProibido}
                disabled={!stVisitado}
                className={classes.checkBox}
                value='stProdutoProibido'
                color='primary'
              />
              {labelAtividade(
                formatMessage('agendaVisitas.aplicProdutoProibido'),
                nrAtividadesAtribuidas.nrProdutoProibido
              )}
            </Grid>
          )}
          <Grid
            item
            xs={4}
            className={classes.cardCheck}
            onClick={() => filtrarByAtividade(AtividadeEnum.MONITORAMENTO)}
          >
            <Checkbox
              checked={stMonitoramento}
              disabled={!stVisitado}
              className={classes.checkBox}
              value='stMonitoramento'
              color='primary'
            />

            {labelAtividade(
              formatMessage('agendaVisitas.monitoramentoPragas'),
              nrAtividadesAtribuidas.nrMonitoramento
            )}
          </Grid>
        </Grid>

        <Grid container spacing={16}>
          <Grid item xs={4} className={classes.cardCheck} onClick={onClickAgendado}>
            <Checkbox
              checked={stAgendado}
              className={classes.checkBox}
              value='stAgendado'
              style={{ color: '#00D3FF' }}
            />
            {labelAtividade(formatMessage('agendaVisitas.agendado'), agendadoList.length)}
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.cardCheck}
            onClick={() => filtrarByAtividade(AtividadeEnum.APLICACAO)}
          >
            <Checkbox
              checked={stAplicacao}
              disabled={!stVisitado}
              className={classes.checkBox}
              value='stAplicacao'
              color='primary'
            />
            {labelAtividade(
              formatMessage('agendaVisitas.aplicProduto'),
              nrAtividadesAtribuidas.nrAplicacaoProduto
            )}
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.cardCheck}
            onClick={() => filtrarByAtividade(AtividadeEnum.RECOMENDACAO)}
          >
            <Checkbox
              checked={stRecomendacao}
              disabled={!stVisitado}
              className={classes.checkBox}
              value='stRecomendacao'
              color='primary'
            />
            {labelAtividade(
              formatMessage('agendaVisitas.recomendacaoAplic'),
              nrAtividadesAtribuidas.nrRecomendacaoAplicacao
            )}
          </Grid>
        </Grid>

        <Grid container spacing={16}>
          <Grid item xs={4} className={classes.cardCheck} onClick={onClickSemVisita}>
            <Checkbox
              checked={diasSemVisita}
              className={classes.checkBox}
              value='diasSemVisita'
              style={{ color: '#FF5858' }}
            />
            {labelAtividade(formatMessage('agendaVisitas.diasSemVisita'), semVisitasList.length)}
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.cardCheck}
            onClick={() => filtrarByAtividade(AtividadeEnum.REGISTRO_CLIMATICO)}
          >
            <Checkbox
              checked={stClimatico}
              className={classes.checkBox}
              disabled={!stVisitado}
              value='stClimatico'
              color='primary'
            />
            {labelAtividade(
              formatMessage('agendaVisitas.registroClimatico'),
              nrAtividadesAtribuidas.nrRegistroClimatico
            )}
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.cardCheck}
            onClick={() => filtrarByAtividade(AtividadeEnum.ALERTA_FITOSSANITARIO)}
          >
            <Checkbox
              checked={stAlerta}
              disabled={!stVisitado}
              className={classes.checkBox}
              value='stAlerta'
              color='primary'
            />
            {labelAtividade(
              formatMessage('agendaVisitas.alertaFitossanitario'),
              nrAtividadesAtribuidas.nrAlertaFitossanitario
            )}
          </Grid>
        </Grid>

        <Grid container spacing={16}>
          <Grid item xs={4} />
          {props.infoUsuario.stOrganica === 1 && gridRegistroFotografico()}
          <Grid
            item
            xs={4}
            className={classes.cardCheck}
            onClick={() => filtrarByAtividade(AtividadeEnum.SEM_ATIVIDADE)}
          >
            <Checkbox
              checked={stSemAtividade}
              disabled={!stVisitado}
              className={classes.checkBox}
              value='stSemAtividade'
              color='primary'
            />
            {labelAtividade(
              formatMessage('agendaVisitas.semAtividade'),
              nrAtividadesAtribuidas.nrSemAtividade
            )}
          </Grid>
        </Grid>
      </div>
    );
  }

  function gridRegistroFotografico() {
    return (
      <Grid
        item
        xs={4}
        className={classes.cardCheck}
        onClick={() => filtrarByAtividade(AtividadeEnum.REGISTRO_FOTOGRAFICO)}
      >
        <Checkbox
          checked={stFotografico}
          className={classes.checkBox}
          disabled={!stVisitado}
          value='stFotografico'
          color='primary'
        />
        {labelAtividade(
          formatMessage('agendaVisitas.registroFotografico'),
          nrAtividadesAtribuidas.nrRegistroFotografico
        )}
      </Grid>
    );
  }

  /**
   * label do componente de atividade
   */
  function labelAtividade(label, qtAtividade) {
    return (
      <div className={classes.labelCheck}>
        <div>{label}</div>
        <div>{`(${qtAtividade || 0})`}</div>
      </div>
    );
  }

  /**
   * Remove itens da mapa após filtro
   */
  function applyFiltroToRemove(stAtividade) {
    allMarkersVisitado.forEach(marker => {
      map.removeLayer(marker);
    });

    const allMarkersVisitadoAux = [];
    let greenIcon = getGreenIcon();
    let warningIcon = getGreenIcon(true);
    const visitadoListFilteredAux = [...visitadoListFiltered];
    const newList = visitadoListFilteredAux.filter(
      item => item[stAtividade] === 0 || validationOtherMarkes(item, stAtividade)
    );
    const qtProdutoreVisitasListAux = countNrVisitado(newList);

    newList.forEach(visitado => {
      const stWarning = markerWarning(visitado, visitadoListFilteredAux);
      const marker = addMarkerToMap(
        visitado,
        stWarning && stAtividade !== 'nrProdutoProibido' && stProdutoProibido ? warningIcon : greenIcon,
        qtProdutoreVisitasListAux
      );
      marker.key = visitado.idAgenda;
      allMarkersVisitadoAux.push(marker);
    });

    setVisitadoListFiltered(newList);
    setAllMarkersVisitado(allMarkersVisitadoAux);
  }

  function validationOtherMarkes(item, stAtividade) {
    const recomendacao =
      stAtividade !== 'nrRecomendacaoAplicacao' && item['nrRecomendacaoAplicacao'] > 0 && stRecomendacao;
    const prodProibido =
      stAtividade !== 'nrProdutoProibido' && item['nrProdutoProibido'] > 0 && stProdutoProibido;
    const climatico =
      stAtividade !== 'nrRegistroClimatico' && item['nrRegistroClimatico'] > 0 && stClimatico;
    const fotografico =
      stAtividade !== 'nrRegistroFotografico' && item['nrRegistroFotografico'] > 0 && stFotografico;
    const monitoramento =
      stAtividade !== 'nrMonitoramento' && item['nrMonitoramento'] > 0 && stMonitoramento;
    const nrAlertaFitossanitario =
      stAtividade !== 'nrAlertaFitossanitario' && item['nrAlertaFitossanitario'] > 0 && stAlerta;
    const aplicacao =
      stAtividade !== 'nrAplicacaoProduto' && item['nrAplicacaoProduto'] > 0 && stAplicacao;

    return (
      recomendacao ||
      prodProibido ||
      climatico ||
      fotografico ||
      monitoramento ||
      nrAlertaFitossanitario ||
      aplicacao
    );
  }

  /**
   * Adiciona itens da mapa após filtro
   */
  function applyFiltroToAdd(stAtividade) {
    const itensToAdd = visitadoList.filter(item => item[stAtividade] > 0);

    const visitadoListFilteredAux = [...visitadoListFiltered];
    const allMarkersVisitadoAux = [...allMarkersVisitado];
    const filterVisitadoListAux = [...itensToAdd, ...visitadoListFilteredAux].filter(visitado => {
      const duplicatedVisitado = setProdutor.has(visitado.idVisitado);
      setProdutor.add(visitado.idVisitado);
      return !duplicatedVisitado;
    });

    const qtProdutoreVisitasListAux = countNrVisitado(filterVisitadoListAux);
    let greenIcon = getGreenIcon();
    let warningIcon = getGreenIcon(true);
    itensToAdd.forEach(item => {
      const exists = visitadoListFilteredAux.find(visitado => visitado.idVisitado === item.idVisitado);
      if (!exists) {
        visitadoListFilteredAux.push(item);
      }

      const stWarning = markerWarning(item, visitadoListFilteredAux);
      const marker = addMarkerToMap(
        item,
        stWarning && (stAtividade === 'nrProdutoProibido' || stProdutoProibido)
          ? warningIcon
          : greenIcon,
        qtProdutoreVisitasListAux
      );
      marker.key = item.idAgenda;
      allMarkersVisitadoAux.push(marker);
    });

    setVisitadoListFiltered(visitadoListFilteredAux);
    setAllMarkersVisitado(allMarkersVisitadoAux);
  }

  function validaFiltrosByAtividade(stAtividade, dsAtividade) {
    if (stAtividade) {
      applyFiltroToRemove(dsAtividade);
    } else {
      applyFiltroToAdd(dsAtividade);
    }
  }

  /**
   * Aplica o filtro de acordo com a atividade
   * @param {*} tpAtividade - tipo da atividade
   */
  function filtrarByAtividade(tpAtividade) {
    if (tpAtividade === AtividadeEnum.APLICACAO) {
      validaFiltrosByAtividade(stAplicacao, 'nrAplicacaoProduto');
      setStAplicacao(!stAplicacao);
    }

    if (tpAtividade === AtividadeEnum.REGISTRO_CLIMATICO) {
      validaFiltrosByAtividade(stClimatico, 'nrRegistroClimatico');
      setStClimatico(!stClimatico);
    }

    if (tpAtividade === AtividadeEnum.REGISTRO_FOTOGRAFICO) {
      validaFiltrosByAtividade(stFotografico, 'nrRegistroFotografico');
      setStFotografico(!stFotografico);
    }

    if (tpAtividade === AtividadeEnum.MONITORAMENTO) {
      validaFiltrosByAtividade(stMonitoramento, 'nrMonitoramento');
      setStMonitoramento(!stMonitoramento);
    }

    if (tpAtividade === AtividadeEnum.RECOMENDACAO) {
      validaFiltrosByAtividade(stRecomendacao, 'nrRecomendacaoAplicacao');
      setStRecomendacao(!stRecomendacao);
    }

    if (tpAtividade === AtividadeEnum.ALERTA_FITOSSANITARIO) {
      validaFiltrosByAtividade(stAlerta, 'nrAlertaFitossanitario');
      setStAlerta(!stAlerta);
    }

    if (tpAtividade === AtividadeEnum.PRODUTO_PROIBIDO) {
      validaFiltrosByAtividade(stProdutoProibido, 'nrProdutoProibido');
      setStProdutoProibido(!stProdutoProibido);
    }

    if (tpAtividade === AtividadeEnum.SEM_ATIVIDADE) {
      validaFiltrosByAtividade(stSemAtividade, 'nrSemAtividade');
      setStSemAtividade(!stSemAtividade);
    }
  }

  /**
   * Popula mapa com a lista de visitados
   */
  function onClickVisitado() {
    setStAgendado(false);
    setDiasSemVisita(false);
    cleanAgendado();
    cleanSemVisita();
    if (stVisitado) {
      cleanVisitado();
    } else {
      uploadCheckVisitas();
      addInMapVisitado(visitadoList);
    }

    setStVisitado(!stVisitado);
  }

  function uploadCheckVisitas() {
    setStAplicacao(true);
    setStProdutoProibido(true);
    setStClimatico(true);
    setStMonitoramento(true);
    setStRecomendacao(true);
    setStAlerta(true);
    setStFotografico(true);
    setStSemAtividade(true)
  }

  function cleanVisitado() {
    allMarkersVisitado.forEach(marker => {
      map.removeLayer(marker);
    });
    setStAplicacao(false);
    setStProdutoProibido(false);
    setStClimatico(false);
    setStMonitoramento(false);
    setStRecomendacao(false);
    setStAlerta(false);
    setStFotografico(false);
    setStSemAtividade(false)
    setNrAtividadesAtribuidas({ ...initialNrAtividade });
    setVisitadoListFiltered([...visitadoList]);
  }

  /**
   * Popula mapa com a lista de agendados
   */
  function onClickAgendado() {
    if (stAgendado) {
      cleanAgendado();
    } else {
      cleanVisitado();
      setStVisitado(false);
      addInMapAgendado(agendadoList);
    }

    setStAgendado(!stAgendado);
  }

  function cleanAgendado() {
    allMarkersAgendado.forEach(marker => {
      map.removeLayer(marker);
    });
  }

  /**
   * Popula mapa com a lista de sem visitas
   */
  function onClickSemVisita() {
    if (diasSemVisita) {
      cleanSemVisita();
    } else {
      cleanVisitado();
      setStVisitado(false);
      addInMapSemVisitas(semVisitasList);
    }

    setDiasSemVisita(!diasSemVisita);
  }

  function cleanSemVisita() {
    allMarkersSemVisita.forEach(marker => {
      map.removeLayer(marker);
    });
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'agenda-visitas';
  })
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(AgendaVisitas)));
