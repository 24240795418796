const styles = {
  card: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 15px)'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  page: {
    marginBottom: 15
  },
  document: {
    width: '100%'
  },
  divDocument: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
};

export { styles };
