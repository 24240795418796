export default {
  produtores: {
    areaTotalNdviExcedida: 'The total area available for NDVI monitoring has been exceeded',
    erroCriarPoligono: 'An error occurred while creating the polygon',
    tamanhoMinino: 'The minimum area of a polygon is 1 ',
    emailInvalido: 'Invalid email',
    ecosocialObrigatorio: 'Add at least one ecosystem type',
    descFertilidade: 'Fertility',
    selecioneUmTalhaoPeloMenos: 'Add at least one field',
    edicaoDeTalhao: 'Field editing',
    cadastroDeTalhao: 'Field registration',
    detinacaoAdequadaResiduos: 'Proper disposal of waste',
    nomeCompleto: 'Enter full name',
    nascimento: 'Birth',
    idade: 'Age',
    cadastroAmbiental: 'Environmental register',
    edicaoAmbiental: 'Environmental edition',
    cadastroProducaoAnimal: 'Livestock register',
    edicaoProducaoAnimal: 'Livestock edition',
    cadastroColaborador: 'Employee registration',
    edicaoColaborador: 'Contributor edition',
    cadastroFamiliar: 'Family register',
    edicaoFamiliar: 'Family edition',
    cadastroAreaCultivo: 'Cultivation area register',
    edicaoAreaCultivo: 'Cultivation area edition',
    cadastroProduto: 'Product registration',
    edicaoProduto: 'Product edition',
    cadastroAnaliseRisco: 'Risk Analysis Master Data',
    edicaoAnaliseRisco: 'Risk Analysis Edition',
    solo: 'Ground',
    agua: 'Water',
    residual: 'Residual',
    desejaRemoverLancamento: 'Do you want to remove this release?',
    lancamentoExcluido: 'Release removed',
    dataLancamento: 'Release date of',
    destinacaoAdequadaResiduos: 'Proper waste disposal',
    producaoFamiliar: 'Family production',
    riscosAdicionaisRelevantes: 'Relevant additional risks',
    analiseDeRisco: 'Risk analysis',
    experienciaProdutor: 'Organic farmer producer experience',
    resultadosAnteriores: 'History, past results',
    pressaoUsoDePesticidas: 'Pressure of pesticide use in the region',
    proximidadesLavouras: 'Nearby/Barriers with conventional tillage',
    suspeitaAplicacaoDireta: 'Suspected direct application',
    tamanhoSafra: 'Crop size',
    riscoTrocaSafra: 'Risk of crop replacement by conventional product',
    somaRiscos: 'Sum of risks',
    derivaVentoOuAgua: 'Wind or water/rain drift',
    transitoVeiculos: 'Vehicle traffic (road)',
    cultivosTransgenicos: 'GM crops from neighbors',
    utilizacaoImplementosAgricolas: 'Use of shared farm implements',
    comentariosGerais: 'General comments',
    nota: 'Grade',
    desejaExcluirFamiliar: 'Do you want to delete family member?',
    familiarExcluido: 'Family successfully deleted!',
    desejaExcluirProduto: 'Do you want to delete product?',
    produtoExcluido: 'Product deleted successfully!',
    categoria: 'Category',
    acaros: 'Mites',
    bacterias: 'Bacteria',
    ervasDaninhas: 'Weeds',
    fungos: 'Fungi',
    insetos: 'Insects',
    nematoides: 'Nematodes',
    foliaresAdjuvantes: 'Leaf and Adjuvant',
    fertilizantes: 'Fertilizers',
    fertilizanteQuimico: 'Chemical fertilizers',
    fertilizanteOrganico: 'Organic fertilizers',
    naoSeAplica: 'Not applicable',
    oleosAdjuvantes: 'Adjuvant oils',
    segregacaoInsumos: 'Segregation of inputs',
    cadastroDeProdutor: 'Producer Register',
    produtores: 'Producer',
    identificacao: 'Identification',
    nome: 'Name',
    sexo: 'Gender',
    dataNascimento: 'Date of birth',
    escolaridade: 'Schooling',
    familiares: 'Relatives',
    editarFamiliar: 'Edit familiar',
    novoFamiliar: 'Family register',
    infraestruturas: 'Infrastructure',
    tpArea: 'Area type',
    anexoContrato: 'Contract annex and registration',
    validadeContrato: 'Contract validity',
    termoCompromisso: 'Organic management commitment term',
    areaPropria: 'Own area',
    arrendada: 'Leased',
    ambas: 'Both',
    aspectoLegal: 'Legal aspects',
    masculino: 'Male',
    feminino: 'Female',
    relacaoPredial: 'Building relationship',
    localArmazenamentoInsumos: 'Input storage location',
    maquinarioUtensilios: 'Machinery and utensils',
    propriedade: 'Property management',
    tomadaDecisao: '',
    atividadesDesenvolvidas: 'Developed activities',
    telefone: 'Phone',
    comunidade: 'Comunity',
    municipio: 'County',
    uf: 'UF',
    email: 'E-mail',
    nivel: 'Technological level',
    tecnicoResponsavel: 'Responsible technician',
    ativo: 'Activo',
    codigo: 'Key',
    cidade: 'Country',
    estado: 'State',
    descricao: 'Description',
    areaCultivo: 'Total area of cultivation',
    areaTotaldeTalhoes: 'Total area of field',
    dataVisita: 'Visit date',
    hora: 'Hour',
    tecnico: 'Technician',
    dataPlantio: 'Planting date',
    culturas: 'Culture',
    dataColheita: 'Harvested at',
    produtividade: 'Productivity(Bags/Ha)',
    areaDeCultivo: 'Area of cultivation',
    talhoes: 'Field',
    visitas: 'Visits',
    plantios: 'Plantations',
    status: 'Status',
    inativo: 'Inactive',
    salvar: 'Save',
    cancelar: 'Cancel',
    gravar: 'Save',
    campoObrigatorio: 'Required field',
    desejaExcluirAreaCultivo: 'Do you want to delete the selected area of cultivation?',
    desejaExcluirTalhao: 'Do you want to delete de selected field?',
    nao: 'Not',
    excluir: 'Delete',
    excluindo: 'Excluding',
    areaCultivoRemovidoComSucesso: 'Growing area successfully removed',
    talhaoRemovidoComSucesso: 'Field successfully removed',
    alto: 'High',
    medio: 'Medium',
    baixo: 'Low',
    salvando: 'Saving',
    atualizando: 'Updating',
    produtorCadastrodaComSucesso: 'Producer registered successfully',
    produtorEditadoComSucesso: 'Producer edited successfully',
    falhaAoCadastrarProdutor: 'Fail to register producer',
    falhaAoEditarProdutor: 'Failed to edit producer',
    emailJaCadastrado: 'This email is already in use',
    erroAoCarregarDados: 'Error loading data',
    aplicacoes: 'Application',
    dataAplicacao: 'Application date',
    tipo: 'Type',
    marca: 'Brand',
    produto: 'Product',
    talhao: 'Field',
    hectares: 'Hectares',
    acaricida: 'Acaricides',
    acaricidaMicrobiologico: 'Microbiological acaricides',
    agenteBiologicoControle: 'Biological agents',
    bactericida: 'Bactericidal',
    bactericidaMicrobiologico: 'Microbiological bactericides',
    herbicida: 'Herbicides',
    fungicida: 'Fungicidas',
    fungicidaMicrobiologico: 'Microbiological fungicides',
    inseticida: 'Inseticidas',
    inseticidaMicrobiologico: 'Microbiological insecticides',
    nematicida: 'Nematicides',
    nematicidaMicrobiologico: 'Microbiological nematicides',
    fertilizanteFoliar: 'Fertilizer foliar',
    talhaoDuplicado: 'Duplicate plot',
    plantioCadastradoComSucesso: 'Planting successfully registered',
    desejaExcluirAplicacao: 'Do you want to delete application?',
    aplicacaoRemovidaComSucesso: 'Application successfully removed',
    naoFoiPossivelEditar: 'Could not edit planting',
    naoFoiPossivelInserir: 'Could not insert planting',
    plantioEditadoComSucesso: 'Planting successfully edited',
    plantioRemovidoComSucesso: 'Plantación registrada con éxito',
    erroAoRemoverPlantio: 'Error removing planting',
    desejaExcluirPlantio: 'Do you want to exclude planting?',
    areaTotalSelecionada: 'Total area selected(Ha)',
    talhoesAdicionados: 'Stubs added',
    selecionarTodos: 'Select all',
    areaPlantio: 'Planting area',
    acompanhamentoNaoCadastrado: 'Planting monitoring not yet registered',
    acompanhamentoPlantio: 'Planting follow-up',
    acompanhamentoProdutor: 'Follow-up to the producer',
    nrQuantidade: 'Amount',
    produtos: 'Products',
    lancamentoAplicacao: 'Application Launch',
    dsUnMedida: 'Un. of measurement',
    dsRecomendacaoAdicional: 'Additional recommendation',
    ordem: 'Order',
    dose: 'Dose(L)',
    adicionarProdutos: 'Add to cart',
    areaTotal: 'Total area',
    lancamentoCadastrado: 'Successfully launched release',
    lancamentoAtualizado: 'Successfully released release',
    falhaAoCadastrarLancamento: 'Failed to register posting',
    falhaAoAtualizarLancamento: 'Failed to update posting',
    unMedida: 'Unit of measurement',
    recomendacoesAdicionais: 'Additional recommendations',
    visualizarRecomendacoes: 'View recommendations',
    tpProduto: 'Product type',
    fechar: 'Close',
    aplicarNosTalhoes: 'Apply to the plots',
    talhoesUtilizados: 'Plots used',
    falhaAoCarregarAcompanhamento: 'Failed to load tracking',
    produtoRemovidoComSucesso: 'Product successfully removed',
    lancamentoRecomendacao: 'Release of recommendation',
    cadastrarAplicacao: 'Apply application',
    cadastrarRecomendacao: 'Apply recommendation',
    acre: 'Acre',
    hectare: 'Hectárea',
    alqueire: 'Bushel',
    numeroMaximoProdutores: "Your plan's producer limit has been reached",
    desejaExcluir: 'Do you want to delete ',
    falhaAoExcluirProdutor: 'Failed to delete producer',
    ocorreuErroExcluirProdutor: 'Failed to delete producer',
    produtorExcluidoComSucesso: 'Producer deleted successfully',
    telefoneInvalido: 'Invalid phone number',
    cadastroPlantio: 'Plant registration',
    cadastrarAreaCultivo: 'First register cultivation area',
    cadastrarTalhao: 'First register cultivation area and field',
    talhaoPlantioAssociado: 'Field and planting associated with growing area',
    exclusaoAreaDeCultivoNaoPermitida: 'Exclusion of cultivation area is not allowed',
    talhaoAssociado: 'Field associated with growing area',
    plantioAssociado: 'Planting associated with growing area',
    produtor: 'Producer',
    adicionarTalhao: 'Add field',
    editarTalhao: 'Edit field',
    exclusaoTalhaoNaoPermitida: 'Deletion of not allowed field',
    adicionarAreaCultivo: 'Add cultivation area',
    editarAreaCultivo: 'Edit cultivation area',
    adicionarPlantio: 'Add planting',
    editarPlantio: 'Edit planting',
    alertasFitossanitarios: 'Phytosanitary Alerts',
    doencaSolo: 'Soil disease',
    doencaFoliar: 'Leaf disease',
    nematoide: 'Nematode',
    pragas: 'Pests',
    agenteCausal: 'Causal agent',
    invasoras: 'Invasive',
    agenteCausalAdicionadas: 'Causal agents added',
    minimoUm: 'One field minimum',
    dataMinima: 'Invalid date',
    voltar: 'Return',
    agendaRelacionada: 'There are one or more producer related calendars',
    atividadeRelacionada: 'There are activities related to the producer',
    registrosRelacionados: 'There are records related to the field',
    confirmar: 'Confirm',
    salvarProdutorSair: 'There is unsaved information, do you want to save the producer?',
    descartar: 'Descartar',
    produtividades: 'Productivity',
    area: 'Area',
    sacas: 'Sacks',
    toneladas: 'Tons',
    semeadura: 'Seeding',
    unidade: 'Unity',
    espacamento: 'Spacing (cm)',
    areaSemeadura: 'Sowing Area',
    m2: 'm²',
    metro: 'Meter',
    standFinal: 'Stand final',
    previsaoColheita: 'Harvest Forecast',
    diasEstimados: 'Estimated days',
    produtividadeEstimada: 'Estimated Productivity',
    quantidadePlantas: 'Number of plants',
    tonelada: 'Ton',
    colheita: 'Harvest',
    localPlantio: 'Planting Site',
    sementes: 'Seeds',
    data: 'Date',
    certificacaoOrganica: 'Organic Certification Status',
    convencional: 'Conventional',
    emConversao: 'In conversion',
    brasil: 'BR (Brazil)',
    comunidadeEuropeia: 'CE (European Community)',
    eua: 'NOP (United States)',
    producaoParalela: 'Parallel production',
    ecosocial: 'Ecosocial certification',
    naoPossui: "Don't have",
    dataPrevista: 'Expected date',
    informacoes: 'Informations',
    mapas: 'Maps',
    mapa: 'Map',
    excluirMarcacao: 'Clear tag',
    desejaExcluirMarcacao: 'Do you want to clear tags?',
    sim: 'Yes',
    minino: 'Total area of field should be greater than 0',
    cpf: 'Document',
    conhecimentoOrganica: 'Knowledge of organic production',
    atividadeGraos: 'Grain Activity',
    areaProdutiva: 'Grain production area',
    cadastroCompleto: 'Complete Registration',
    completo: 'Complete',
    preCadastro: 'Pre registration',
    cadastroPreProdutor: 'Producer Pre-Registration',
    cpfInvalido: 'Invalid document',
    efetivarProdutor: 'Make producer',
    efetivarPreCadastro: 'Pre-register',
    contrato: 'Contract',
    colaboradores: 'Contributors',
    efetivo: 'Effective',
    temporario: 'Temporary',
    desejaExcluirColaborador: 'Do you want to delete collaborator?',
    colaboradorRemovidoComSucesso: 'Contributor successfully removed',
    dadosSociais: 'Social data',
    participacaoAssociacoes: 'Participa de associações ou cooperativas',
    conflitoLimites: 'Conflict with bordering properties',
    ambiental: 'Environmental',
    localizacaoSede: 'Headquarters Location',
    informacoesClima: 'Weather Information',
    informacoesSolo: 'Solo Info',
    terracentamento: 'Terracing',
    ruim: 'Bad',
    satisfatorio: 'Satisfactory',
    razoavel: 'Reasonable',
    plantioNivel: 'Level planting',
    usoPlantasCobertura: 'Use of cover plants',
    plantioDireto: 'No tillage',
    protecaoFontes: 'Font Protection',
    protecaoLeitosAgua: 'Protection of water beds',
    analisePortabilidadeAgua: 'Water Potability Analysis',
    usoIrrigacao: 'Irrigation Use',
    organicosDomisanitarios: 'Household Organic',
    organicosAnimais: 'Organic animals',
    reciclaveis: 'Recyclable',
    embalagensAgrotoxicos: 'Pesticide Packaging',
    especie: 'Species',
    organico: 'Organic',
    producaoAnimalRemovidoComSucesso: 'Animal production successfully removed',
    desejaExcluirProducaoAnimal: 'Do you want to exclude animal production?',
    frango: 'Chicken',
    gado: 'Cattle',
    adicionarFamiliar: 'Add family',
    adicionarColaborador: 'Add collaborator',
    editarColaborador: 'Edit Contributor',
    editarPMO: 'Edit PMO',
    adicionarPMO: 'Add PMO',
    producaoAnimal: 'Animal production',
    editarProducaoAnimal: 'Edit animal production',
    adicionarProducaoAnimal: 'Add animal production',
    nenhumMapaCadastrado: 'No map registered',
    propriedades: 'Propertie',
    dataCadastro: 'Registration date',
    dataAdmissao: 'Admission date',
    dataDemissao: 'Resignation Date',
    raca: 'Breed',
    qtda: 'Amount',
    analiseRiscoRemovidoComSucesso: 'Risk analysis successfully removed',
    desejaExcluirAnaliseRisco: 'Do you want to delete risk analysis?',
    funcaoExercida: 'Function performed',
    conhecimentoNormas: 'Knowledge of labor standards',
    nenhumTalhaoDemarcado: 'No plot demarcated',
    riscosAdicionais: 'Additional risks',
    fundamentalIncompleto: 'Incomplete Elementary',
    fundamentalCompleto: 'Complete Elementary',
    medioIncompleto: 'Incomplete high school',
    medioCompleto: 'Completado la escuela secundaria',
    superiorIncompleto: 'Incomplete higher education',
    superiorCompleto: 'Higher Education',
    longitude: 'Longitude',
    latitude: 'Latitude',
    selecioneArquivo: 'Select the file',
    nenhumArquivoSelecionado: 'No file selected',
    baixar: 'Download',
    colaboradoresResponsaveis: 'Employees responsible for decision-making',
    valorInvalido: 'Invalid value',
    motivoAlteraçao: 'Reason for change',
    mininoCampo: 'Minimum 10 letter',
    erroAnexarFoto: 'There was an error attaching the photo',
    numeroRegistroCLT: 'Registry Number (CLT)',
    numeroContrato: 'Contact number',
    moraPropriedade: 'Lives on property',
    possuiRendaExtra: 'Has extra income',
    descricaoRendaExtra: 'Description of extra income',
    car: 'CAR',
    dap: 'DAP',
    descricaoDejetoAnimal: 'Description of animal waste',
    dejetoAnimal: 'Animal waste',
    descricaoCarcacaAnimal: 'Description of animal carcasses',
    carcacaAnimal: 'Animal carcasses',
    periodoCompostagem: 'Composting',
    descricaoPeriodoCompostagem: 'Composting period',
    descricaoPeriodoArmazenamentoUrina: 'Prior storage period',
    periodoArmazenamentoUrina: 'Animal urine',
    descricaoOrganicosDomissanitarios: 'Description of household cleaning products',
    descricaoReciclaveis: 'Description of recyclables',
    descricaoEmbalagensAgrotoxicas: 'Description of pesticide packaging',
    abastecimentoPulverizacao: 'Supply of sprayers',
    descricaoAbastecimentoPulverizacao: 'Spray supply description',
    fontes: 'Sources',
    abastecimentoComunitario: 'Community supply',
    rioLagos: 'River/Lakes',
    codigoCertificadora: 'Organic certification code',
    homeopaticos: 'Homeopathic Products',
    inoculantes: 'Inoculants',
    dataPrimeiraVisita: 'Date of first visit',
    cnpjInvalido: 'Invalid CNPJ',
    certificacoes: 'Certifications',
    classificacao: 'Land use and occupation',
    certificacao: 'Certification',
    statusCertificacaoOrganica: 'Organic certification status',
    agencia: 'Agency',
    temporarios: 'Temporary'
  }
};
