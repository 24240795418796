export default {
  evento: {
    campoObrigatorio: 'Campo obligatorio',
    agendarVisita: 'Programar visita',
    excluir: 'Eliminar',
    cancelar: 'Cancelar',
    confirmar: 'Confirmar',
    data: 'Fecha',
    horario: 'Hora de inicio',
    horarioFinal: 'Hora de finalización',
    horarioInvalido: 'La hora de finalización debe ser mayor que la hora de inicio',
    produtor: 'Productor',
    tecnico: 'Técnico',
    visitaRealizada: 'Visita realizada',
    observacao: 'Observación',
    resumoVisita: 'Resumen de la visita',
    voltar: 'Vuelve',
    responsavel: 'Responsable',
    gerente: 'Gerente'
  }
};
