export default {
  marcas: {
    marcas: 'Brands',
    descricao: 'Description',
    desejaExcluir: 'Do you want to delete the selected brand?',
    nao: 'Not',
    excluir: 'Delete',
    excluindo: 'Excluding',
    marcaRemovidaComSucesso: 'Brand excluded successfully',
    falhaAoExcluirMarca: 'Fail to exclude brand',
    marcaEditadoComSucesso: 'Brand edited successfully',
    falhaAoEditarMarca: 'Failed to edit brand',
    falhaAoCadastrarMarca: 'Failed to register brand',
    falhaAoCarregarMarca: 'Failed to load brand',
    cadastroDeMarca: 'Brands record',
    salvar: 'Save',
    cancelar: 'Cancel',
    salvando: 'Saving',
    atualizando: 'Updating',
    identificacaoDaMarca: 'Identification',
    campoObrigatorio: 'Required field',
    marcaCadastradaComSucesso: 'Successfully Marked',
    situacao: 'Situation',
    ativo: 'Active',
    inativo: 'Inactive'
  }
};
