import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InsumoRecord from '@components/InsumoRecord/InsumoRecord';
import formatMessage from './i18n/formatMessage';
import Insumo from '@resources/InsumosEnum';

/**
 * Componente para tela de fertilizantes organicos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function FertilizantesOrganicos(props) {
  useEffect(() => {
    const title = 'fertilizantesOrganicos.fertilizantesOrganicos';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }
  }, []);

  return (
    <InsumoRecord
      chipData={props.chipData}
      infoUsuario={props.infoUsuario}
      insumoEnum={Insumo.FERTILIZANTE_ORGANICO}
      insumoURL='fertilizantes-organicos'
      viewKey='fertilizantesOrganicos'
      deleteMessageSucess={formatMessage(
        'fertilizantesOrganicos.fertilizantesOrganicosRemovidoComSucesso'
      )}
      mensagemFalhaDeletar={formatMessage('fertilizantesOrganicos.falhaAoExcluirFertilizantesOrganicos')}
      mensagemDelete={formatMessage('fertilizantesOrganicos.desejaExcluir')}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'fertilizantesOrganicos';
  })[0].content
});

export default connect(mapStateToProps)(FertilizantesOrganicos);
