import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Card from '@components/Card/Card';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import formatMessage from './../i18n/formatMessage';
import { Formik } from 'formik';
import DatePicker from '@components/DatePicker/DatePicker';
import * as Yup from 'yup';
import { getUserLang } from '@utils/localeUtils';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import swal from '@sweetalert/with-react';

/**
 * Componente que representa a listagem de Agencia dentro do produtor
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const Agencia = props => {
  const initialValues = {
    agencia: null,
    dtInicio: null,
    dtTermino: null
  };
  const { classes, theme, agenciaList, onCancel } = props;
  const [itemSelect, setItemSelect] = useState({ ...props.itemSelectAgencia } || initialValues);
  const [agencia, setAgencia] = useState(null);
  const [keyAutoCompleteAgenda, setKeyAutoCompleteAgenca] = useState(0);

  useEffect(() => {
    if (props.itemSelectAgencia) {
      setAgencia(props.itemSelectAgencia.agencia);
      setKeyAutoCompleteAgenca(Math.random());
    }
  }, []);

  function tratarValorAgencia() {
    if (!agencia) {
      return '';
    }

    return agencia;
  }

  function loadAgencia() {
    return agenciaList;
  }

  function onChangeAgencia(value = '') {
    setAgencia(value);
  }

  function onSave(values, { resetForm }) {
    let dataBetween = false;

    for (let produtorAgencia of props.dataAgenciaList) {
      const dhInicio = moment(produtorAgencia.dtInicio);
      const dhTermino =
        !produtorAgencia.dtTermino && dhInicio.isAfter(moment())
          ? dhInicio
          : moment(produtorAgencia.dtTermino);
      let dhInicioValue = moment(values.dtInicio);
      let dhTerminoValue = values.dtTermino === null ? null : moment(values.dtTermino);
      if (values.dtTermino === null && dhInicioValue.isBefore(moment())) {
        dhTerminoValue = moment();
      }

      if (itemSelect.idProdutorAgencia === produtorAgencia.idProdutorAgencia) {
        continue;
      }

      if (!produtorAgencia.dtTermino && !values.dtTermino) {
        dataBetween = true;
        break;
      }

      if (
        dhInicioValue.isBetween(dhInicio, dhTermino) ||
        (dhTerminoValue && dhTerminoValue.isBetween(dhInicio, dhTermino))
      ) {
        dataBetween = true;
        break;
      }

      if (dhInicioValue.isBefore(dhInicio) && dhTerminoValue && dhTerminoValue.isAfter(dhTermino)) {
        dataBetween = true;
        break;
      }

      if (!dhTerminoValue && dhTermino.isAfter(dhInicioValue)) {
        dataBetween = true;
        break;
      }
    }

    if (dataBetween) {
      swal(formatMessage('produtores.periodoJaCadastrado'), {
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-error'
          }
        }
      });
      return;
    }

    if (props.itemSelectAgencia) {
      values.stRegistro = itemSelect.stRegistro;
      values.idProdutorAgencia = itemSelect.idProdutorAgencia;
      props.onUpdate(values);
    } else {
      values.idProdutorAgencia = Math.random();
      values.stRegistro = 0;
      props.onNew(values);
    }
  }

  return (
    <Formik
      initialValues={{
        agencia: agencia || null,
        dtInicio: itemSelect.dtInicio || null,
        dtTermino: itemSelect.dtTermino || null
      }}
      validateOnBlur
      validateOnChange={false}
      enableReinitialize={true}
      onSubmit={onSave}
      validationSchema={Yup.object().shape({
        agencia: Yup.object()
          .nullable()
          .required(formatMessage('produtores.campoObrigatorio')),
        dtInicio: Yup.string()
          .nullable()
          .required(formatMessage('produtores.campoObrigatorio')),
        dtTermino: Yup.string().nullable()
      })}
      render={({ values, handleSubmit, errors, touched, handleChange, handleBlur, setFieldValue }) => (
        <MuiThemeProvider theme={theme}>
          <Card
            withOutBorderRadius
            title={formatMessage(
              props.itemSelectAgencia ? 'produtores.edicaoAgencia' : 'produtores.cadastroAgencia'
            )}
            height='auto'
            childrenStyle={{}}
            style={{ marginBottom: 35, height: '290px' }}
          >
            <div
              style={{
                marginBottom: 15,
                display: 'contents',
                width: '100%',
                height: '100%'
              }}
            >
              <form autoComplete='off' style={{ height: 69 }}>
                <Grid container spacing={16}>
                  <Grid item xs={4} style={{ paddingRight: 15 }}>
                    <Autocomplete
                      itens={agenciaList}
                      campoOp='nmAgencia'
                      campoChave='idAgencia'
                      label={formatMessage('produtores.agencia')}
                      name='agencia'
                      error={!agencia && touched.agencia}
                      helperText={touched.agencia && !agencia ? formatMessage('campoObrigatorio') : null}
                      onChangeOwn={onChangeAgencia}
                      onChangeAutoComplete={loadAgencia}
                      value={tratarValorAgencia()}
                      key={keyAutoCompleteAgenda}
                      valueAutoComplete={tratarValorAgencia()}
                      valueSelect={tratarValorAgencia().nmAgencia}
                      maxHeight='calc(100vh - 250px)'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <DatePicker
                      locale={getUserLang()}
                      className={classes.textField}
                      label={formatMessage('produtores.periodoInicial')}
                      valueDate={values.dtInicio}
                      onBlurDatePicker={value => {
                        setFieldValue('dtInicio', value);
                        setFieldValue('dtTermino', null);
                        var item = itemSelect;
                        item.dtInicio = value;
                        item.dtTermino = null;

                        setItemSelect(item);
                      }}
                      onChangeDatePicker={value => {
                        setFieldValue('dtInicio', value);
                        setFieldValue('dtTermino', null);
                        var item = itemSelect;
                        item.dtInicio = value;
                        item.dtTermino = null;

                        setItemSelect(item);
                      }}
                      errorTouched={errors.dtInicio && touched.dtInicio ? true : false}
                      helperText={errors.dtInicio && touched.dtInicio ? errors.dtInicio : ''}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <DatePicker
                      disabled={!values.dtInicio}
                      minDate={values.dtInicio}
                      locale={getUserLang()}
                      className={classes.textField}
                      label={formatMessage('produtores.periodoFinal')}
                      valueDate={values.dtTermino}
                      onBlurDatePicker={e => {
                        setFieldValue('dtTermino', e.target.value || null);
                        var item = itemSelect;
                        item.dtTermino = e.target.value || null;

                        setItemSelect(item);
                      }}
                      onChangeDatePicker={value => {
                        setFieldValue('dtTermino', value);
                        var item = itemSelect;
                        item.dtTermino = value;

                        setItemSelect(item);
                      }}
                      errorTouched={errors.dtTermino && touched.dtTermino ? true : false}
                      helperText={errors.dtTermino && touched.dtTermino ? errors.dtTermino : ''}
                    />
                  </Grid>
                </Grid>
              </form>

              <Grid container>
                <Grid item xs className={classes.containerButtons}>
                  <div style={{ color: '#463766' }}>
                    <Button variant='contained' color='inherit' onClick={onCancel}>
                      {formatMessage('produtores.cancelar')}
                    </Button>
                  </div>

                  <div>
                    <Button color='secondary' variant='contained' onClick={handleSubmit}>
                      {formatMessage('produtores.salvar')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </MuiThemeProvider>
      )}
    />
  );
};

export default Agencia;
