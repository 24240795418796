import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CadastrarInsumoRecord from '@components/InsumoRecord/CadastrarInsumoRecord';
import formatMessage from './i18n/formatMessage';
import Insumo from '@resources/InsumosEnum';
import { withRouter } from 'react-router-dom';

/**
 * Tela para cadastro de Herbicida
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarHerbicida(props) {
  useEffect(() => {
    const title = 'herbicida.cadastroDeHerbicida';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }
  }, []);

  return (
    <CadastrarInsumoRecord
      id={props.match.params.id}
      insumoURL={'herbicidas'}
      insumoEnum={Insumo.HERBICIDA}
      infoUsuario={props.infoUsuario}
      saveMessage={formatMessage(
        'herbicida.' +
          (props.match.params.id
            ? 'herbicidaEditadoComSucesso'
            : 'herbicidaCadastradoComSucesso')
      )}
      falhaSaveMesssage={formatMessage(
        'herbicida.' +
          (props.match.params.id
            ? 'falhaAoEditarHerbicida'
            : 'falhaAoCadastrarHerbicida')
      )}
      falhaCarregarMensagem={formatMessage('herbicida.falhaAoCarregarHerbicida')}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(EditarHerbicida));
