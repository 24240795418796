export default {
  acaricida: {
    acaricida: 'Acaricida',
    cadastroDeAcaricida: 'Registro de acaricida',
    acaricidaRemovidoComSucesso: 'Acaricida quitado con éxito',
    falhaAoExcluirAcaricida: 'Fallo la excluir acaricida',
    desejaExcluir: '¿Desea eliminar el acaricida seleccionada?',
    identificacaoDaAcaricida: 'Identificación',
    acaricidaCadastradoComSucesso: 'Acaricida registrada con éxito',
    acaricidaEditadoComSucesso: 'Acaricida editado con éxito',
    falhaAoEditarAcaricida: 'Falla al editar acaricida',
    falhaAoCadastrarAcaricida: 'Error al editar acaricida',
    falhaAoCarregarAcaricida: 'Error al cargar acaricida'
  }
};
