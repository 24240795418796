export default {
  relatorio: {
    relatorio: 'Informe',
    analitico: 'Analítico',
    regiao: 'Región',
    municipio: 'Contacto',
    sim: 'Si',
    nao: 'No',
    carregando: 'Cargando...',
    gerandoRelatorio: 'Generando informe ...',
    falhaAoGerarRelatorio: 'Error al generar informe',
    dataLancamento: 'Fecha de lanzamiento',
    tecnicoResponsavel: 'Técnico responsable',
    produtor: 'Productor'
  }
};
