import React, { useState, useEffect } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import formatMessage from './i18n/formatMessage';
import { findCertificacaoById, saveCertificacao, updateCertificacao } from '@resources/Certificacao';

const iconMenuPontos = require('@images/icone_fab_menu.png');

const style = () => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Screen de edição ou cadastro de uma certificação
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarCertificacao(props) {
  const { classes } = props;

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState({ nmTipoEcosocial: '' });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const title = 'certificacao.cadastroDeCertificacao';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    if (props.match.params.id !== undefined) {
      setEnableReinitialize(true);
    }
  }, []);

  useEffect(
    () => {
      if (!enableReinitialize) return;
      setLoading(true);

      findCertificacaoById(props.match.params.id)
        .then(res => setInitialValues(res.data))
        .catch(err =>
          swal(formatMessage('certificacao.falhaoAoBuscarDados'), {
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                closeModal: true,
                className: 'swal2-error'
              }
            }
          })
        )
        .finally(() => {
          setEnableReinitialize(false);
          setLoading(false);
        });
    },
    [enableReinitialize]
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize={enableReinitialize}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        nmTipoEcosocial: Yup.string().required(formatMessage('certificacao.campoObrigatorio'))
      })}
      onSubmit={handleSubmitFormik}
      render={({ values, handleSubmit, errors, touched, handleBlur, handleChange }) => (
        <MuiThemeProvider theme={theme}>
          {isLoading && <Loading />}

          <div style={{ width: '100%' }}>
            <CardIcon titulo={formatMessage('certificacao.identificacao')}>
              <Grid container style={{ height: 70, marginTop: 30 }}>
                <Grid item xs={6}>
                  <TextField
                    label={formatMessage('certificacao.descricao')}
                    name='nmTipoEcosocial'
                    inputProps={{ maxLength: 60 }}
                    value={values.nmTipoEcosocial}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nmTipoEcosocial && touched.nmTipoEcosocial}
                    helperText={
                      errors.nmTipoEcosocial && touched.nmTipoEcosocial ? errors.nmTipoEcosocial : null
                    }
                  />
                </Grid>
              </Grid>
            </CardIcon>
          </div>

          <div className={classes.fab}>
            <ButtonFABMenu
              icon={iconMenuPontos}
              actions={[
                {
                  icon: <Check style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('certificacao.salvar'),
                  onClickIcon: handleSubmit,
                  color: '#42ADE8'
                },
                {
                  icon: <Close style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('certificacao.cancelar'),
                  onClickIcon: () => props.history.push('/app/certificacao-ecosocial'),
                  color: '#F33A30'
                }
              ]}
            />
          </div>
        </MuiThemeProvider>
      )}
    />
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {*} values - Valores do submit
   */
  function handleSubmitFormik(values) {
    setLoading(true);
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(
          `certificacao.${props.match.params.id === undefined ? 'salvando' : 'atualizando'}`
        )
      }
    );

    values.idTipoEcosocial = +props.match.params.id || null;

    let request;
    if (props.match.params.id === undefined) {
      request = saveCertificacao(values);
    } else {
      request = updateCertificacao(values);
    }

    request
      .then(res => {
        swal(formatMessage('certificacao.certificacaoSalvoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
          }
        });

        props.history.push('/app/certificacao-ecosocial');
      })
      .catch(err => {
        swal(formatMessage('certificacao.falhaAoSalvar'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });

        setLoading(false);
      });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarCertificacao)));
