export default {
  cronograma: {
    culturas: 'Culturas',
    sazonalidade: 'Estacionalidad',
    naoConformidade: 'Incumplimiento',
    dosePorTanque: 'Dosis por tanque para',
    alqueires: 'bushels',
    aplicarNosTalhoes: 'Aplicar en los lotes:',
    paraDia: 'para el día',
    recomendacao: 'Recomendación de aplicación',
    aplicacao: 'Solicitud realizada',
    acre: 'Acre',
    hectare: 'Hectárea',
    alqueire: 'Alqueire',
    areaDeCultivo: 'Área de cultivo',
    cultura: 'Cultura',
    plantio: 'Plantación',
    colheita: 'Cosecha',
    alertasFitossanitarios: 'Alertas Fitosanitarias',
    monitoramento: 'Monitoreo de plagas',
    hoje: 'Hoy',
    talhoes: 'Lotes',
    registroFotografico: 'Registro fotográfico',
    registroClimatico: 'Registro climático',
    aplicacaoProdutoProibido: 'Aplicación de producto prohibida',
    ocultarAtividades: 'Ocultar actividades de plantación',
    mostrarAtividades: 'Mostrar actividades de plantación',
    auditoria: 'Auditoría'
  }
};
