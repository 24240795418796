import React, { useState } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import EditarTemporarios from './EditarTemporarios';
import swal from '@sweetalert/with-react';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import CardTitle from '@components/CardTitle/CardTitle';
import moment from 'moment';
import formatMessage from '../i18n/formatMessage';
import { style } from './Temporario.styles';
import { getUserLang } from '@utils/localeUtils';

function formatTextField() {
  switch (getUserLang()) {
    case 'pt-BR':
      return 'DD/MM/YYYY';
    case 'es-ES':
      return 'DD-MM-YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}

const headDatatable = [
  {
    valueGetter: args => moment(args.node.data.dtMovimento).format(formatTextField()),
    headerName: formatMessage('produtores.data'),
    col: 6
  },
  {
    field: 'nrQuantidade',
    headerName: formatMessage('produtores.nrQuantidade'),
    col: 6
  }
];

/**
 * Componente para listagem da tab temporários
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
function Temporarios(props) {
  const { classes, tpPerfil, data } = props;
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [itemSelect, setItemSelect] = useState(null);
  const [atualizando, setAtualizando] = useState(false);
  const [index, setIndex] = useState(0);

  /**
   * Executa ao adicionar um novo temporario
   *
   * @param {Object} values - Objeto Temporario novo
   * @param {Number} position - Posição do Temporario na lista
   */
  function onSave(values, position) {
    const dtMovimentoExists = data.find(
      temporario =>
        temporario.dtMovimento === values.dtMovimento &&
        temporario.idColaboradorTemporario !== values.idColaboradorTemporario
    );

    if (dtMovimentoExists) {
      swal({
        title: formatMessage('produtores.dataExistente'),
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true
      });
      return;
    }

    props.onSave(values, position);
    backPageListagem();
  }

  function backPageListagem() {
    setIndex(0);
    setAtualizando(false);
    setItemSelect(null);
    setDisabledButtons(true);
    props.changeFAB();
  }

  /**
   * Executa ao clicar em excluir uma linha da tabela
   */
  function clickExcluir() {
    swal({
      title: formatMessage('produtores.desejaExcluirTemporario'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('produtores.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('produtores.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(res => {
      if (res) {
        props.onDelete(itemSelect);

        swal(formatMessage('produtores.temporarioRemovidoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      }
    });
  }

  /**
   * Executa quando é clickado numa linha da lista
   *
   * @param {Object} selected - Linha selecionada
   */
  function selectChange(select) {
    if (atualizando) return;

    if (select) {
      setItemSelect(select);
      setDisabledButtons(false);
    } else {
      setItemSelect(null);
      setDisabledButtons(true);
    }
  }

  function changePageEdit(stAtualizar) {
    setAtualizando(stAtualizar);
    setIndex(1);
    props.changeFAB();
  }

  return (
    <div className={classes.root}>
      <SwipeableViewsOwn index={index} onChangeIndex={index => setIndex(index)}>
        {[
          <div className={classes.tab} key={0}>
            <CardTitle
              containerStyle={{ marginBottom: tpPerfil ? 0 : 'none' }}
              title={formatMessage('produtores.temporarios')}
            />

            {!tpPerfil && (
              <GroupButtonGrid
                hiddenMargin
                withoutMargin
                showEdit
                showDelete
                showAdd
                disableEdit={disabledButtons || atualizando}
                disableDelete={disabledButtons || atualizando}
                onClickEdit={() => changePageEdit(true)}
                onClickDelete={clickExcluir}
                onClickAdd={() => changePageEdit(false)}
              />
            )}

            <DataTable
              noMargin
              borderRadius={false}
              data={data}
              columns={headDatatable}
              selectChange={selectChange}
              showPagination={false}
            />
          </div>,

          <EditarTemporarios
            onCancel={backPageListagem}
            onSave={onSave}
            temporario={
              atualizando && itemSelect ? itemSelect : { dtMovimento: new Date(), nrQuantidade: null }
            }
            atualizando={atualizando}
            data={data}
          />
        ]}
      </SwipeableViewsOwn>
    </div>
  );
}

export default withStyles(style)(Temporarios);
