import { createMuiTheme } from '@material-ui/core';

const styles = theme => ({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 30,
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    width: '100%',
    height: 70
  },
  font: {
    fontSize: 14,
    width: '100%',
    height: 70
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '45px'
  },
  icon: {
    height: '36px',
    width: '36px',
    color: '#007EB6'
  },
  fontTitulo: {
    fontSize: 30,
    margin: 0,
    marginLeft: '30px',
    color: '#555555'
  },
  button: {
    height: 24,
    width: 24,
    marginTop: 10
  },
  iconButton: {
    width: 15
  },
  iconClose: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.6)',
    height: 30,
    width: 30,
    padding: 2,
    borderRadius: 5,
    marginBottom: -5,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  gridIconClose: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  swipeable: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginBottom: 15
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        height: '46px',
        width: '100px',
        marginLeft: '15px'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        }
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        color: '#fff',
        backgroundColor: '#463766',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    inherit: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { styles, theme };
