import URL from './URL';

const axios = require('axios');
const API = `${URL.API}/sancao-categoria/`;

function findAllSancaoCategorias() {
  return axios.get(API);
}

function findSancaoCategoriaById(idSancaoCategoria) {
  return axios.get(API + idSancaoCategoria);
}

function deleteSancaoCategoriaById(idSancaoCategoria) {
  return axios.delete(API + idSancaoCategoria);
}

function addSancaoCategoria(values) {
  return axios.post(API, values);
}

function editSancaoCategoria(values) {
  return axios.put(API, values);
}

export {
  findAllSancaoCategorias,
  findSancaoCategoriaById,
  deleteSancaoCategoriaById,
  addSancaoCategoria,
  editSancaoCategoria
};
