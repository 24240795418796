export default {
  agenda: {
    agenda: 'Agenda',
    agendarVisita: 'Horario de visitas',
    agendarEvento: 'Programar evento',
    data: 'Fecha',
    horario: 'Horario inicial',
    produtor: 'Productor',
    observacao: 'Observación',
    gravar: 'Registro',
    cancelar: 'Cancelar',
    campoObrigatorio: 'Campo obligatorio',
    atualizando: 'Actualización',
    salvando: 'Ahorro',
    visitaCadastradaComSucesso: 'Visita catastrada con éxito',
    visitaEditadaComSucesso: 'Visita editada con éxito',
    falhaAoCadastrarVisita: 'Falla al registrarse visita',
    falhaAoEditarVisita: 'Error al editar la visita',
    excluir: 'Borrar',
    desejaExcluir: '¿Desea eliminar?',
    nao: 'No',
    visitaRemovidoComSucesso: 'Visita eliminada con éxito',
    eventoRemovidoComSucesso: 'Evento eliminada con éxito',
    falhaAoExcluirVisita: 'Error al excluir la visita',
    falhaAoExcluirEvento: 'Error al excluir la evento',
    excluindo: 'Excluyendo',
    resumoVisita: 'Resumen de la visita',
    desejaMarcar: '¿Desea programar la visita?',
    sim: 'Sí',
    mesmoHorario: 'Hay una visita programada al mismo tiempo para',
    mesmoHorarioEvento: 'Hay un evento programado al mismo tiempo para',
    falhaAoCarregarAgenda: 'Error al cargar la programación',
    horarioFinal: 'Horario final',
    horarioInvalido: 'El horario final debe ser mayor que horario inicial',
    confirmar: 'Confirmar',
    visitaRealizada: 'Visita realizada',
    visitasAgendadas: 'Visitas programadas',
    visitasSemAgenda: 'Visitas realizadas sin agenda',
    produtoresPendentesVisita: 'Productores visitantes pendientes',
    totalVisitasAgendadas: 'Total de visitas programadas',
    totalVisitasSemAgenda: 'Total de visitas sin horario',
    produtoresMaiorIntervalo: 'Productores con mayor intervalo entre visitas',
    visitasAgendadasTecnico: 'Visitas programadas por técnico',
    visitasRealizadasTecnico: 'Visitas realizadas sin horario por técnico',
    visitasMaisProdutoresPendentes: 'Técnicos con productores más destacados',
    tecnico: 'Técnico',
    selecioneColaborador: 'Seleccione un colaborador',
    descricao: 'Descripción',
    local: 'Ubicación',
    objetivo: 'Objetivo',
    participante: 'Participante',
    eventoEditadaComSucesso: 'Evento editado com sucesso',
    eventoCadastradaComSucesso: 'Evento registrado con éxito',
    falhaAoEditarEvento: 'Error al editar evento',
    falhaAoCadastrarEvento: 'Error al registrar el evento',
    inativo: 'Inactivo'
  }
};
