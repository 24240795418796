//@author Katia Miglioli
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppMainToolbar from '@components/AppMainToolbar/AppMainToolbar';
import MenuLateral from '@components/MenuLateral/MenuLateral';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Carregando from '@components/Carregando/Carregando';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../Rotas';
import Authentication from '@resources/oauth/Authentication';
import Admin from '@resources/oauth/Admin';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = theme => ({
  divAppMainToolbar: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 9.5,
    right: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    height: '10%',
    marginBottom: 0
  }
});

class AppMainLayout extends Component {
  constructor(props) {
    super(props);
    this.layout = this.layout.bind(this);
    this.carregando = this.carregando.bind(this);
    this.getInfoUsuario = this.getInfoUsuario.bind(this);

    this.state = {
      statusCarregando: true,
      location: '',
      tpPerfil: ''
    };
  }

  shouldComponentUpdate(newProps, newState) {
    return this.state.location !== window.location.pathname;
  }

  componentDidUpdate(prevProps) {
    if (this.state.location !== window.location.pathname) {
      this.setState({
        location: window.location.pathname
      });
    }
  }

  componentDidMount() {
    Authentication.refreshToken()
      .then(() => {
        Admin.findPerfil()
          .then(result => {
            this.getInfoUsuario(result);
          })
          .catch(err => {
            this.props.history.push('/login');
          });
      })
      .catch(err => {
        this.props.history.push('/login');
      });
  }

  getInfoUsuario(user) {
    const infoUsuarioGeral = {
      idCliente: user.cliente.idCliente,
      idUsuario: user.idUsuario,
      dsEmail: user.dsEmail,
      nmUsuario: user.nmUsuario,
      stUsuario: user.stUsuario,
      tpPerfil: user.tpPerfil,
      idPais: user.cliente.pais.idPais,
      pais: user.cliente.pais,
      stOrganica: user.cliente.stOrganica,
      permissaoNdvi: user.permissaoNdvi,
      stCooperativa: user.cliente.stCooperativa,
      tpCooperativa: user.cliente.tpCooperativa
    };

    this.setState({
      tpPerfil: user.tpPerfil,
      permissaoNdvi: user.permissaoNdvi
    });

    this.props.dispatch({
      type: 'ADD_INFOUSUARIO',
      info: infoUsuarioGeral
    });
  }
  carregando() {
    return <Carregando />;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.infoUsuario.idPais !== '') {
      this.setState({
        statusCarregando: false
      });
      this.forceUpdate();
    }
  }

  layout(classes) {
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div className={classes.divAppMainToolbar}>
          <AppMainToolbar>
            <div
              style={{
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
                marginTop: 20
              }}
            >
              <MenuLateral
                permissaoUsuario={this.state.tpPerfil}
                permissaoNdvi={this.state.permissaoNdvi}
                localization={this.state.location}
              />
              {routes.map((route, index) =>
                route.path === '/app/mapandvi' ? (
                  this.state.permissaoNdvi ? (
                    <Route key={index} path={route.path} exact={route.exact} component={route.main} />
                  ) : null
                ) : (
                  <Route key={index} path={route.path} exact={route.exact} component={route.main} />
                )
              )}
            </div>
          </AppMainToolbar>
        </div>
      </IntlProvider>
    );
  }

  render() {
    const { classes } = this.props;

    return this.state.statusCarregando ? this.carregando() : this.layout(classes);
  }
}

const mapStateToProps = state => ({
  itensState: state,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(styles)(AppMainLayout);
export default withRouter(connect(mapStateToProps)(enhaced));
