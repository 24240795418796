import { createMuiTheme } from '@material-ui/core/styles';

const styles = theme => ({});

const theme = createMuiTheme({
  overrides: {
    MuiCollapse: {
      container: {
        overflow: 'inherit !important'
      }
    },
    MuiFormLabel: {
      root: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 'calc(100% - 20px)'
      }
    },
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#008000'
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { styles, theme };
