import URL from './URL';
import axios from 'axios';

const API = `${URL.API}/agencia`;

/**
 * Chama o endpoint para retornar todas as agencia
 */
export async function findAllAgencia() {
  return axios.get(`${API}/`);
}

/**
 * Chama o endpoint para retornar todas as agencia
 */
export async function findAllActiveAgencia() {
  return axios.get(`${API}/ativo`);
}

/**
 * Chama o endpoint para retornar o agencia pelo ID
 *
 * @param {Number} id - ID da Agencia
 */
export async function findAgenciaById(id) {
  return axios.get(`${API}/${id}`);
}

/**
 * Chama o endpoint para salvar um nova agencia
 *
 * @param {agencia} agencia - agencia que vai ser salvo
 */
export async function save(agencia) {
  return axios.post(API, agencia);
}

/**
 * Chama o endpoint para atualizar umaagencia
 *
 * @param {agencia} agencia - agencia que vai ser atualizada
 * @returns {Promise<AxiosResponse<agencia>>} Uma Promise com a agencia
 */
export async function update(agencia) {
  return axios.put(API, agencia);
}

/**
 * Chama o endpoint para deletar um agencia pelo ID
 *
 * @param {Number} id - ID da agencia
 * @returns {Promise<AxiosResponse<void>>} Uma Promise
 */
export async function deleteById(id) {
  return axios.delete(`${API}/${id}`);
}
