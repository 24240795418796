export default {
  menu: {
    ecosocial: 'Ecosocial',
    certificacoes: 'Certificaciones',
    sazonalidades: 'Estacionalidad',
    animal: 'Animal',
    plantios: 'Plantaciones',
    municipio: 'Municípios',
    comunidades: 'Comunidades',
    nivel: 'Nível tecnologico',
    tecnicos: 'Técnicos',
    tecnico: 'Técnico',
    produtores: 'Produtores',
    nematicidasMicrobiologicos: 'Nematicidas microbiológicas',
    foliares: 'Hoja',
    comparacao: 'Comparación entre técnicos',
    acompanhamento: 'Acompañamiento',
    culturas: 'Culturas',
    cultivares: 'Cultivares',
    fungicidas: 'Fungicidas',
    inseticidasMicroBio: 'Insecticidas microbiológicas',
    fungicidasMicroBio: 'Fungicidas Microbiológicos',
    inseticidas: 'Insecticidas',
    cotacoes: 'Cotações',
    bactericidasMicrobiologicos: 'Bactericidas microbiológicos',
    nematicidas: 'Nematicidas',
    agentesBiologicosControle: 'Agentes biológicos',
    clientes: 'Clientes',
    herbicidas: 'Herbicidas',
    acaricidas: 'Acaricidas',
    bactericidas: 'Bactericidas',
    acaricidasMicrobio: 'Acaricidas microbiológicas',
    marcas: 'Marcas',
    cadastro: 'Catastro',
    fertilizantes: 'Hoja y adyuvante',
    analise: 'Análisis',
    pessoas: 'Personas',
    localizacao: 'Ubicación',
    produtos: 'Productos',
    acaros: 'Ácaros',
    bacterias: 'Bacterias',
    ervasDaninhas: 'Malas hierbas',
    fungos: 'Hongos',
    insetos: 'Insectos',
    nematoides: 'Nematodos',
    agenda: 'Orden del día',
    admin: 'Administración',
    pesticidas: 'Pesticidas',
    acompanhamentoProdutor: 'Acompañamiento al productor',
    produtor: 'Productor',
    financeiro: 'Financeiro',
    revenda: 'Gerente',
    oleosAdjuvantes: 'Aceites y Adyuvantes',
    agendaVisitas: 'Horario de visitas',
    fitossanidade: 'Salud de las plantas',
    fertilizantesFoliares: 'Fertilizantes',
    quimico: 'Químico',
    organico: 'Orgánico',
    mapa: 'Mapas',
    mapandvi: 'Mapas NDVI',
    especies: 'Especies',
    racas: 'Carreras',
    naoConformidade: 'Incumplimiento',
    regiao: 'Región',
    relatorio: 'Informes',
    produtorPotencial: 'Productores potenciales',
    relacaoTalhao: 'Relación de trama',
    analisesRiscos: 'Análisis de riesgos',
    analitico: 'Analítico',
    sintetico: 'Sintético',
    cadastroDeProdutor: 'Registro de productores',
    listaProdutores: 'Listado de productores',
    resumoVisitas: 'Visitas por productor',
    visitas: 'Visitas',
    safras: 'Añadas',
    produtividade: 'Productividad',
    graosProdutor: 'Granos por productor',
    inoculantesHomeopatia: 'Inoculantes y homeopatía',
    homeopaticos: 'Productos homeopáticos',
    inoculantes: 'Inoculantes',
    historicoProdutividade: 'Historial de productividad',
    galeria: 'Galería',
    classificacao: 'Uso y ocupación de la tierra',
    certificacaoOrganica: 'Certificaciones orgánicas',
    agencia: 'Agencia',
    sancaoCategoria: 'Sanción',
    medidaCorretiva: 'Medida correctiva',
    producao: 'Producción',
    adversidades: 'Infortunios',
    cooperativismo: 'Cooperativismo',
    auditoria: 'Auditoría',
    acompanhamentoVisitas: 'Visita de seguimiento',
    analiseOGM: 'Analizar OGM',
    formaAplicacao: 'Formulario de aplicación'
  }
};
