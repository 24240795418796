export default {
  fungicidaMicroBio: {
    fungicidaMicroBioRemovidoComSucesso: 'Fungicida microbiológico removido com sucesso',
    falhaAoExcluirFungicidaMicroBio: 'Falha ao excluir fungicida microbiológico',
    desejaExcluir: 'Deseja excluir o fungicida microbiológico selecionado?',
    fungicidaMicroBio: 'Fungicida microbiológico',
    identificacaoDaFungicidaMicroBio: 'Identificação',
    fungicidaMicroBioCadastradoComSucesso: 'Fungicida microbiológico cadastrado com sucesso',
    fungicidaMicroBioEditadoComSucesso: 'Fungicida microbiológico editado com sucesso',
    falhaAoEditarFungicidaMicroBio: 'Falha ao editar fungicida microbiológico',
    falhaAoCadastrarFungicidaMicroBio: 'Falha ao cadastrar fungicida microbiológico',
    falhaAoCarregarFungicidaMicroBio: 'Falha ao carregar fungicida microbiológico',
    cadastroDeFungicidaMicroBio: 'Cadastro de fungicidas microbiológico'
  }
};
