export default {
  classificacao: {
    descricao: 'Descrição',
    situacao: 'Situação',
    ativo: 'Ativo',
    inativo: 'Inativo',
    preservacaoAmbiental: 'Preservação ambiental',
    sim: 'Sim',
    nao: 'Não',
    produtivo: 'Produtivo',
    classificacao: 'Uso e Ocupação do Solo',
    erroBuscarListaClassificacao: 'Erro ao buscar lista de uso e ocupação do solo',
    erroDeletarClassificacao: 'Erro ao deletar uso e ocupação do solo',
    classificacaoRemovidoComSucesso: 'Uso e ocupação do solo removido com sucesso',
    cadastroClassificacao: 'Cadastro de uso e ocupação do solo',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    erroBuscarClassificacao: 'Erro ao buscar uso e ocupação do solo',
    classificacaoEditadaComSucesso: 'Uso e ocupação do solo editava com sucesso',
    classificacaoSalvaComSucesso: 'Uso e ocupação do solo salva com sucesso',
    erroEditarClassificacao: 'Erro ao editar uso e ocupação do solo',
    erroSalvarClassificacao: 'Erro ao salvar uso e ocupação do solo',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    desejaExcluir: 'Deseja excluir o uso e ocupação do solo?',
    excluir: 'Excluir',
    excluindo: 'Excluindo'
  }
};
