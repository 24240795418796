//Autor: Kátia miglioli
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../AppMainLayout/i18n/ptBR';
import enUS from '../AppMainLayout/i18n/enUS';
import esES from '../AppMainLayout/i18n/esES';
import RelatorioAgenda from './../Agenda/RelatorioAgenda';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const title = 'toolbar.toolbar';
    if (this.props.itensState.name !== 'toolbar.toolbar') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider
            locale={getUserLang()}
            messages={flattenMessages(messages[getUserLang()])}
          >
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }
  }

  componentDidMount() {
    if (window.location.pathname === '/app') {
      if (this.props.infoUsuario.tpPerfil === 3) {
        this.props.history.push('/app/acompanhamento-produtor/novo');
      } else if (this.props.infoUsuario.tpPerfil === 2) {
        this.props.history.push('/app/acompanhamento');
      }
    }
  }

  render() {
    if (this.props.infoUsuario.tpPerfil === 1) {
      return <RelatorioAgenda />;
    } else {
      return <div />;
    }
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(Dashboard));
