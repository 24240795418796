import React from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import { style } from './../AcompanhamentoProdutor.styles';
import { TalhaoData } from './../Header/DataTableHeader';

function Talhoes(props) {
  const { key, listTalhoes, validationGrid, itensTalhoes, onSelectTalhoes, rowsSelect, classes } = props;

  return (
    <div className={classes.cardTalhoes}>
      <div style={{ height: validationGrid ? 0 : 15 }} />

      <DataTable
        key={key}
        data={listTalhoes}
        columns={TalhaoData}
        rowSelection={true}
        noMargin={validationGrid}
        itensSelect={itensTalhoes}
        selectChange={onSelectTalhoes}
        showPagination={false}
        rowsSelect={rowsSelect}
      />
    </div>
  );
}

export default withStyles(style)(Talhoes);
