import React from 'react';
import { getUserLang } from '@utils/localeUtils';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import DatePicker from '@components/DatePicker/DatePicker';
import Grid from '@material-ui/core/Grid';
import formatMessage from './i18n/formatMessage';
import Card from '@components/Card/Card';
import moment from 'moment';

function Identificacao(props) {
  const {
    perfilProdutor,
    plantio,
    culturas,
    keyAutocompleteCultura,
    cultivares,
    keyAutocompleteCultivares,
    especificacoes,
    keyAutocompleteEspecificacao,
    onChangeDataPlantio,
    onChangeCultura,
    loadCultivares,
    loadCulturas,
    tratarValorCultura,
    tratarValorCultivares,
    tratarValorEspecificacoes,
    onChangeCultivares,
    onChangeEspecificacoes,
    touched,
    errors,
    values,
    setFieldValue,
    loadEspecificacoes,
    handleBlur
  } = props;

  return (
    <Card
      withOutBorderRadius
      title={formatMessage('plantios.identificacao')}
      height='auto'
      style={{ minHeight: 190, maxHeight: 190, marginBottom: 35 }}
    >
      <form autoComplete='off'>
        <Grid container>
          <Grid item xs={2} style={{ paddingRight: 15 }}>
            <DatePicker
              disabled={perfilProdutor}
              locale={getUserLang()}
              errorTouched={errors.dtPlantio && touched.dtPlantio}
              helperText={formatMessage('plantios.campoObrigatorio')}
              label={formatMessage('plantios.dataPlantio')}
              valueDate={values.dtPlantio}
              onBlurDatePicker={e => {
                const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                !validation && setFieldValue('dtPlantio', null);
                handleBlur('dtPlantio')(e);
              }}
              onChangeDatePicker={date => {
                onChangeDataPlantio(date);
              }}
              maxDate={plantio.colheita.dtColheita || null}
            />
          </Grid>

          <Grid item xs={2} style={{ paddingRight: 15 }}>
            <Autocomplete
              itens={culturas}
              campoOp='nmCultura'
              campoChave='idCultura'
              label={formatMessage('plantios.cultura')}
              name='cultura'
              error={!plantio.cultura && touched.cultura}
              helperText={
                touched.cultura && !plantio.cultura ? formatMessage('plantios.campoObrigatorio') : null
              }
              onChangeOwn={onChangeCultura}
              onChangeAutoComplete={loadCulturas}
              value={tratarValorCultura()}
              key={keyAutocompleteCultura}
              valueAutoComplete={tratarValorCultura()}
              valueSelect={tratarValorCultura().nmCultura}
              disabled={perfilProdutor}
              maxHeight='calc(100vh - 250px)'
            />
          </Grid>

          <Grid item xs={4} style={{ paddingRight: 15 }}>
            <Autocomplete
              itens={cultivares}
              campoOp='nmCultivar'
              campoChave='idCultivar'
              label={formatMessage('plantios.detentor')}
              name='cultivar'
              error={!plantio.cultivar && touched.cultivar}
              helperText={
                touched.cultivar && !plantio.cultivar ? formatMessage('plantios.campoObrigatorio') : null
              }
              onChangeOwn={onChangeCultivares}
              onChangeAutoComplete={() => loadCultivares(values.cultura)}
              value={tratarValorCultivares()}
              key={keyAutocompleteCultivares}
              valueAutoComplete={tratarValorCultivares()}
              valueSelect={tratarValorCultivares().nmCultivar}
              disabled={perfilProdutor}
              maxHeight='calc(100vh - 250px)'
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              itens={especificacoes}
              campoOp='nmCultivarEspec'
              campoChave='idCultivarEspec'
              label={formatMessage('plantios.cultivar')}
              name='cultivarEspec'
              error={!plantio.cultivarEspec && touched.cultivarEspec}
              helperText={
                touched.cultivarEspec && !plantio.cultivarEspec
                  ? formatMessage('plantios.campoObrigatorio')
                  : null
              }
              onChangeOwn={onChangeEspecificacoes}
              onChangeAutoComplete={() => loadEspecificacoes(values.cultivar)}
              value={tratarValorEspecificacoes()}
              key={keyAutocompleteEspecificacao}
              valueAutoComplete={tratarValorEspecificacoes()}
              valueSelect={tratarValorEspecificacoes().nmCultivarEspec}
              disabled={perfilProdutor}
              maxHeight='calc(100vh - 250px)'
            />
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}

export default Identificacao;
