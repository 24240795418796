import { createMuiTheme } from '@material-ui/core/styles';

const style = theme => ({
  parentDiv: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  topDiv: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  identif_Div: {
    flex: 1,
    position: 'relative',
    maxHeight: '180px',
    minHeight: '170px'
  },
  font: {
    fontSize: 14,
    width: '100%',
    height: 15,
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 37,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  form: {
    marginRight: 10,
    width: '100%',
    margin: 0,
    height: '70px'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'scroll'
  },
  dataTable: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  cardWithScroll: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: 'calc(100vh - 95px)'
  },
  cardDataTable: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 95px)',
    marginBottom: 15
  },
  marginGrid: {
    marginRight: -30,
    marginLeft: -30
  },
  cardCertificacao: {
    backgroundColor: '#F5F5F5',
    padding: 20,
    color: 'rgb(0, 0, 0)',
    fontWeight: 400,
    fontSize: 25,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 30%)',
    marginBottom: 40
  },
  labelEditCertificacao: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  labelDatas: {
    fontSize: 12,
    marginTop: 20,
    display: 'flex'
  },
  label: {
    cursor: 'pointer',
    color: '#000000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontWeight: 400,
    fontSize: 17
  },
  cardScroll: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 279px)',
    marginBottom: 15
  },
  cursorIcone: {
    cursor: 'pointer'
  },
  button: {
    width: 35,
    minWidth: 35,
    marginTop: 1,
    height: 30,
    padding: 0,
    marginRight: 10
  },
  cardButton: {
    display: 'flex',
    marginTop: 16,
    marginLeft: 20
  },
  iconButton: {
    height: 30,
    width: 30,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  gridSwitch: {
    display: 'flex',
    alignItems: 'center'
  },
  nenhumaCertificacaoLabel: {
    color: 'rgb(0, 0, 0, 0.8)',
    fontSize: 18,
    marginBottom: 20,
    marginLeft: 24,
    fontWeight: 400
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    type: 'light',
    typography: {
      useNextVariants: true
    }
  }
});

export { theme, style };
