import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Certificacao.styles';
import { Edit } from '@material-ui/icons';
import formatMessage from './i18n/formatMessage';
import moment from 'moment';
import { getUserLang } from '@utils/localeUtils';

/**
 * Componente de certificação
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {Object} props - props
 * @returns Componente React
 */
const Certificacao = props => {
  const { classes, certificacaoList, clickEditar } = props;

  function formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  return (
    <div className={classes.cardAll}>
      {certificacaoList
        .filter(
          item =>
            moment(item.dtInicio) <= moment() &&
            (!item.dtTermino || moment(item.dtTermino) >= moment().startOf('day'))
        )
        .map(item => (
          <div className={classes.cardSwith} key={item.tipoCertificacao.idTipoCertificacao}>
            <div className={classes.nameAndIcon}>
              <label className={classes.label}>{item.tipoCertificacao.nmTipoCertificacao}</label>
              <Edit className={classes.icon} onClick={() => clickEditar(item)} />
            </div>

            <div className={classes.dataCard}>
              <div className={classes.data}>
                <div> {formatMessage('certificado.dataInicial')}</div>
                <div>{moment(item.dtInicio).format(formatTextField())}</div>
              </div>

              <div className={classes.data}>
                <div> {formatMessage('certificado.dataFinal')}</div>
                <div>
                  {' '}
                  {item.dtTermino
                    ? moment(item.dtTermino).format(formatTextField())
                    : formatMessage('certificado.indefinida')}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default withStyles(styles)(Certificacao);
