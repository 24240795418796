import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import TextField from '@material-ui/core/TextField';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  },
  overrides: {
    MuiInputLabel: {
      root: {
        whiteSpace: 'nowrap',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.4
        },
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '1px solid green'
        },
        input: {
          fontSize: 14,
          fontFamily: "'Roboto', sans-serif",
          width: '100%',
          height: 14,
          writeSpace: 'noWrap',
          textOverflow: 'ellipsis',
          fontStyle: 'normal !important'
        }
      }
    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    }
  }
});

export default class TextFieldOwn extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <TextField {...this.props} />
      </MuiThemeProvider>
    );
  }
}
