export default {
  certificacao: {
    descricao: 'Descripción',
    dataCadastro: 'Fecha de Registro',
    erroAoBuscarDados: 'No se pudieron recuperar los datos',
    certificacoes: 'Certificaciones orgánico',
    desejaExcluirCertificacao: '¿Quieres eliminar esta certificación?',
    nao: 'No',
    excluir: 'Eliminar',
    certificacaoExcluida: 'Certificación eliminada correctamente',
    erroAoExcluir: 'No se pudo borrar la certificación',
    cadastroDeCertificacao: 'Registro de certificación orgánico',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    salvar: 'Ahorrar',
    cancelar: 'Cancelar',
    certificacaoSalvoComSucesso: 'Certificación guardada con éxito',
    erroAoSalvar: 'No se pudo guardar la certificación',
    salvando: 'Saving',
    atualizando: 'Actualizando',
    produtorRelacionado: 'No fue posible excluir esta certificación porque hay un productor usándola',
    status: 'Situación',
    ativo: 'Ativo',
    inativo: 'Inativo'
  }
};
