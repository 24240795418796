//Autor: Kátia Miglioli

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUs';
import esES from './i18n/esES';
import Card from '@components/Card/Card';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2';
import * as moment from 'moment';
import './Cotacoes.css';
import Cotacoes from '@resources/Cotacao';
import CardButton from '@components/CardButton/CardButton';

import PanelHeader from '@components/PanelHeader/PanelHeader';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
var { provider } = new IntlProvider({});

const style = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  card: {
    height: '100%',
    borderRadius: 0,
    marginBottom: 20
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

class ConsultarCotacoes extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const cotacao = messagesLinguage['cotacao.cotacao'];
    const rs = messagesLinguage['cotacao.rs'];

    const intlProvider = new IntlProvider({
      locale: getUserLang(),
      messages: {
        cotacao,
        rs
      }
    });
    provider = intlProvider.getChildContext();

    const itemSelect = {
      cultura: {
        nmCultura: '',
        idCultura: ''
      }
    };

    this.state = {
      expanded: true,
      cotacoes: [],
      infoLabel: [],
      infoData: [],
      erro: false,
      index: 0,
      itemSelect: itemSelect
    };
    const title = 'cotacao.cotacoes';
    if (this.props.itensState.name !== 'cotacao.cotacoes') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }
    this.changeExpanded = this.changeExpanded.bind(this);
    this.separarInfoCotacoes = this.separarInfoCotacoes.bind(this);
    this.clickItem = this.clickItem.bind(this);
  }
  changeExpanded() {
    this.setState({
      expanded: !this.state.expanded
    });
  }
  componentDidMount() {
    this.iniciarCotacoes();
  }
  iniciarCotacoes() {
    Cotacoes.findCotacoesGrafico().then(query => {
      var data = query.data;

      this.setState({
        cotacoes: data
      });
    });
  }

  separarInfoCotacoes(infoCotacoes) {
    var data = [];
    var valor = [];
    infoCotacoes.cotacaoList.forEach(info => {
      data.push(this.retornaDataFormatada(info.dtCotacao));
      valor.push(info.vlCotacao);
    });

    this.setState({
      infoLabel: data,
      infoData: valor
    });
  }

  retornaDataFormatada(dataIntegra) {
    switch (getUserLang()) {
      case 'pt-BR':
        return moment(dataIntegra).format('DD/MM');
      case 'en-US':
        return moment(dataIntegra).format('MMM/DD');
      case 'es-ES':
        return moment(dataIntegra).format('DD-MM');
      default:
        break;
    }
  }

  clickItem(index, item) {
    this.setState({
      index: index,
      itemSelect: item
    });

    this.separarInfoCotacoes(item);
  }

  dashboardPanelChart = {
    data: canvas => {
      var chartColor = 'rgb(0, 98, 43, 1)';
      var label = this.state.infoLabel;
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 510);
      gradient.addColorStop(0, 'rgb(139, 200, 165, 1)');
      gradient.addColorStop(1, 'rgb(139, 200, 165, 0)');

      var datasets = [
        {
          label: provider.intl.formatMessage({ id: 'cotacao' }),
          borderColor: chartColor,
          pointBorderColor: 'white',
          pointBackgroundColor: 'rgb(0, 98, 43, 1)',
          pointHoverBackgroundColor: '#178410',
          pointHoverBorderColor: 'white',
          pointBorderWidth: 3,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
          pointRadius: 6,
          fill: true,
          borderWidth: 2,
          data: this.state.infoData,
          backgroundColor: gradient
        }
      ];
      return {
        labels: label,
        datasets: datasets
      };
    },
    options: {
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 0,
          bottom: 0
        }
      },
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: '#fff',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        intersect: 0,
        position: 'nearest',
        mode: 'label',
        callbacks: {
          label: function(tooltipItem, data) {
            let datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return (
              provider.intl.formatMessage({ id: 'rs' }) +
              ' ' +
              datasetLabel.toLocaleString(getUserLang(), {
                minimumFractionDigits: 2
              })
            );
          }
        }
      },
      legend: {
        position: 'bottom',
        fillStyle: '#FFF',
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function(value, index, values) {
                return value.toLocaleString(getUserLang(), {
                  minimumFractionDigits: 2
                });
              },
              fontColor: '#747474',
              beginAtZero: true,
              padding: 10
            },
            gridLines: {
              drawTicks: true,
              display: true,
              color: 'rgb(85, 85, 85, 0.05)',
              zeroLineColor: 'transparent'
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              drawTicks: true,
              display: true,
              color: 'rgb(85, 85, 85, 0.05)',
              zeroLineColor: 'transparent'
            },
            ticks: {
              padding: 10,
              fontColor: '#747474'
            }
          }
        ]
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <MuiThemeProvider theme={theme}>
          <div
            className={classes.main}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              margin: 15,
              marginTop: 0
            }}
          >
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
              {this.state.cotacoes.map(doc => (
                <div key={doc.cultura.idCultura}>
                  <CardButton
                    item={doc}
                    valor={currencyFormatter(doc.vlUltimaCotacao)}
                    name={doc.cultura.nmCultura}
                    index={doc.cultura.idCultura}
                    select={this.state.index === doc.cultura.idCultura}
                    clickItem={this.clickItem}
                  />
                </div>
              ))}
            </div>

            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginBottom: 15
              }}
            >
              <Card className={classes.card} title={this.state.itemSelect.cultura.nmCultura}>
                <div style={{ height: '100%' }}>
                  <PanelHeader
                    size='lg'
                    content={
                      <Line
                        data={this.dashboardPanelChart.data}
                        options={this.dashboardPanelChart.options}
                      />
                    }
                  />
                </div>
              </Card>
            </div>
          </div>
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}

function currencyFormatter(params) {
  return params.toLocaleString(getUserLang(), {
    minimumFractionDigits: 2
  });
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(ConsultarCotacoes);
export default connect(mapStateToProps)(enhaced);
