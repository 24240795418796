export default {
  bactericidasMicrobiologicos: {
    bactericidasMicrobiologicos: 'Bactericidas microbiológicos',
    cadastroDeBactericidasMicrobiologicos: 'Registro de bactericidas microbiológicos',
    bactericidasMicrobiologicosRemovidoComSucesso: 'Bactericida microbiológico quitado con éxito',
    falhaAoExcluirBactericidasMicrobiologicos: 'Fallo la excluir bactericida microbiológico',
    desejaExcluir: '¿Desea eliminar el bactericida microbiológico seleccionada?',
    identificacaoDaBactericidasMicrobiologicos: 'Identificación',
    bactericidasMicrobiologicosCadastradoComSucesso: 'Bactericida microbiológico registrada con éxito',
    bactericidasMicrobiologicosEditadoComSucesso: 'Bactericida microbiológico editado con éxito',
    falhaAoEditarBactericidasMicrobiologicos: 'Falla al editar bactericida microbiológico',
    falhaAoCadastrarBactericidasMicrobiologicos: 'Error al editar bactericida microbiológico',
    falhaAoCarregarBactericidasMicrobiologicos: 'Error al cargar bactericidas microbiológicos'
  }
};
