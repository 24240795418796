import React, { Component } from 'react';
import PanelHeader from '@components/PanelHeader/PanelHeader';
import { Line } from 'react-chartjs-2';

class LineChart extends Component {
  colorGraph() {
    switch (this.props.color) {
      case 2:
        return 'rgb(243, 58, 48)';
      case 1:
        return 'rgb(70, 55, 102)';
      default:
        return 'rgb(0, 98, 43)';
    }
  }

  dashboardPanelChart = {
    data: canvas => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 0);
      return {
        backgroundColor: gradient
      };
    },
    options: {
      elements: {
        line: {
          tension: 0
        }
      },
      curvature: 1,
      layout: {
        padding: {
          left: 10,
          right: 1,
          top: 10,
          bottom: 10
        }
      },
      maintainAspectRatio: false,
      legend: {
        position: 'top',
        display: false,
        labels: {
          boxWidth: 17,
          fontFamily: '"Roboto", sans-serif',
          fontSize: 12,
          fontColor: '#757575',
          padding: 20
        }
      },
      scales: {
        xAxes: [
          {
            minBarLength: 20,
            maxBarThickness: 20,
            barPercentage: 0.9,
            barThickness: 30,
            gridLines: {
              drawTicks: true,
              display: true,
              color: 'transparent',
              zeroLineColor: 'transparent'
            },
            ticks: {
              padding: 20,
              fontColor: '#747474'
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              drawTicks: false,
              display: true,
              color: '#CCCCCC',
              zeroLineColor: '#000000'
            },
            ticks: {
              padding: 10,
              fontColor: '#747474',
              fontSize: 16
            }
          }
        ]
      }
    }
  };

  render() {
    const data = {
      labels: this.props.label,
      datasets: [
        {
          pointBackgroundColor: '#FFFFFF',
          fill: false,
          pointRadius: 5,
          pointBorderWidth: 2,
          pointBorderColor: this.colorGraph(),
          pointHoverBackgroundColor: '#FFFFFF',
          pointHoverBorderColor: this.colorGraph(),
          label: 'Agendado',
          backgroundColor: this.colorGraph(),
          borderColor: this.colorGraph(),
          data: this.props.data
        }
      ]
    };

    return (
      <div
        style={{
          height: 230
        }}
      >
        <PanelHeader
          width={'100%'}
          height={'100%'}
          size='lg'
          content={<Line data={data} options={this.dashboardPanelChart.options} />}
        />
      </div>
    );
  }
}

export default LineChart;
