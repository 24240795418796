export default {
  homeopatico: {
    homeopaticoRemovidoComSucesso: 'Produto homeopático removido com sucesso',
    falhaAoExcluirHomeopatico: 'Falha ao excluir produto homeopático',
    desejaExcluir: 'Deseja excluir o produto homeopático selecionado?',
    homeopatico: 'Produtos homeopáticos',
    cadastroDeHomeopatico: 'Cadastro de produto homeopático',
    identificacaoDaHomeopatico: 'Identificação',
    homeopaticoCadastradoComSucesso: 'Produto homeopático cadastrado com sucesso',
    homeopaticoEditadoComSucesso: 'Produto homeopático editado com sucesso',
    falhaAoEditarHomeopatico: 'Falha ao editar homeopático',
    falhaAoCadastrarHomeopatico: 'Falha ao cadastrar produto homeopático',
    falhaAoCarregarHomeopatico: 'Falha ao carregar produto homeopático'
  }
};
