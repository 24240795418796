/* Autor: Kátia Miglioli
CampoInput: Usado em caso do texto que se apresenta no input e na lista sao diferentes.
*/
import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import green from '@material-ui/core/colors/green';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <MuiThemeProvider theme={createTheme(inputProps.color)}>
      <TextField
        disabled={inputProps.disabled}
        InputProps={{
          inputRef: ref,
          ...InputProps
        }}
        {...other}
      />
    </MuiThemeProvider>
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  campoOp,
  campoChave,
  titleSecondLine = undefined,
  descriptionFirstLine = '',
  descriptionSecondLine = '',
  titleFirstLine = '',
  classes
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem + '' || '').indexOf(suggestion[campoOp]) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion[campoChave]}
      selected={isHighlighted}
      component='div'
      className={classes.itemMenu}
      style={{
        fontWeight: isSelected ? 500 : 400,
        height: titleSecondLine && 40
      }}
    >
      {!titleSecondLine && suggestion[campoOp]}
      {titleSecondLine && (
        <>
          <div className={classes.firstLine}>
            <div className={classes.textOverflow}>{suggestion[titleFirstLine]}</div>
            <div className={classes.descriptionFirstLine}>{suggestion[descriptionFirstLine]}</div>
          </div>
          <div className={classes.secondLineStyle}>
            <div className={classes.textOverflow}>{suggestion[titleSecondLine]}</div>
            <div>{suggestion[descriptionSecondLine]}</div>
          </div>
        </>
      )}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {};

function getSuggestions(value, suggestions, campoOp, valueAutoComplete) {
  if (valueAutoComplete === value) {
    value = '';
  }
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return suggestions
    .filter(suggestion => !!suggestion)
    .filter(suggestion => {
      const keep =
        count < 30 && deburr(suggestion[campoOp].slice(0, inputLength)).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
}
const styles = theme => ({
  firstLine: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  root: {
    flexGrow: 1
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0
  },
  itemMenu: {
    fontSize: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  secondLineStyle: {
    fontSize: 13,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  descriptionFirstLine: {
    marginLeft: 5,
    fontSize: 13,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

function createTheme(colorTheme) {
  return createMuiTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: green,
      type: 'light'
    },
    overrides: {
      MuiInput: {
        underline: {
          '&:hover:not($disabled):not($error):not($focused):before': {
            borderBottom: `1px solid ${colorTheme ? colorTheme : 'green'}`
          },
          '&:before': colorTheme
            ? { borderBottom: `1px solid ${colorTheme}` }
            : { borderBottom: `1px solid`, color: 'rgb(0, 0, 0, 0.45)' }
        }
      },
      MuiInputLabel: {
        root: {
          fontSize: 14,
          writeSpace: 'noWrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          color: colorTheme ? colorTheme : 'rgb(0, 0, 0, 0.45)'
        },
        animated: {
          color: colorTheme ? colorTheme : 'rgb(0, 0, 0, 0.45)'
        }
      },
      MuiInputBase: {
        input: {
          color: colorTheme ? colorTheme : 'rgb(0, 0, 0)'
        }
      },
      MuiFormControl: {
        root: {
          width: '100%'
        }
      },
      MuiIconButton: {
        root: {
          padding: '5px',
          color: colorTheme ? colorTheme : 'rgb(0, 0, 0, 0.5)'
        }
      },
      MuiPaper: {
        root: {
          overflowX: 'hidden',
          overflow: 'scroll',
          maxHeight: '300px'
        }
      }
    }
  });
}

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: false
    };

    this.changeIsOpen = this.changeIsOpen.bind(this);
    this.eventKeyPressDown = this.eventKeyPressDown.bind(this);
  }

  changeIsOpen() {
    this.setState({ menuIsOpen: this.state.menuIsOpen ? false : true });
  }
  eventKeyPressDown(event) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      this.setState({ menuIsOpen: false });
    } else {
      this.setState({ menuIsOpen: true });
    }
  }

  render() {
    const { classes } = this.props;
    const suggestions = this.props.itens;
    const campoOp = this.props.campoOp;
    const campoChave = this.props.campoChave;
    const campoInput = this.props.campoInput === undefined ? campoOp : this.props.campoInput;
    const titleSecondLine = this.props.titleSecondLine;
    const descriptionSecondLine = this.props.descriptionSecondLine;
    const descriptionFirstLine = this.props.descriptionFirstLine;
    const titleFirstLine = this.props.titleFirstLine;

    return (
      <div className={classes.root} style={this.props.style}>
        <Downshift
          onOuterClick={() => this.setState({ menuIsOpen: false })}
          key={this.props.key}
          onChange={selection => {
            if (this.props.onChangeOwn) {
              this.props.onChangeOwn(selection);
            }
            this.setState({ menuIsOpen: false });
          }}
          initialSelectedItem={this.props.valueAutoComplete === '' ? null : this.props.valueAutoComplete}
          style={{ width: 'auto' }}
          itemToString={item => (item !== null ? `${item[campoInput]}` : '')}
          isOpen={this.state.menuIsOpen}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
            clearSelection
          }) => (
            <div className={classes.container}>
              {renderInput({
                color: this.props.color ? this.props.color : null,
                disabled: this.props.disabled,
                classes,
                fullWidth: true,

                InputProps: getInputProps({
                  onChange: event => {
                    if (this.props.onChangeAutoComplete) {
                      this.props.onChangeAutoComplete();
                    }
                    if (event.target.value === '') {
                      clearSelection();
                    }
                  },
                  onBlur: event => {
                    if (this.props.onBlurAutoComplete) {
                      this.props.onBlurAutoComplete(event);
                    }
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton disabled={this.props.disabled} onClick={this.changeIsOpen}>
                        <ExpandMoreIcon
                          style={{
                            transform: this.state.menuIsOpen ? 'rotateX(180deg)' : ''
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  onKeyDown: this.eventKeyPressDown
                }),
                label: this.props.label,
                error: this.props.error && !this.props.disabled ? true : false,
                helperText: !this.props.disabled && !this.state.menuIsOpen && this.props.helperText
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <MuiThemeProvider theme={createTheme(this.props.color ? this.props.color : null)}>
                    <Paper
                      className={classes.paper}
                      style={{ maxHeight: this.props.maxHeight || 300 }}
                      square
                    >
                      {getSuggestions(inputValue, suggestions, campoOp, this.props.valueSelect).map(
                        (suggestion, index) =>
                          renderSuggestion({
                            classes,
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion }),
                            highlightedIndex,
                            selectedItem,
                            campoOp,
                            campoChave,
                            titleSecondLine,
                            descriptionSecondLine,
                            descriptionFirstLine,
                            titleFirstLine
                          })
                      )}
                    </Paper>
                  </MuiThemeProvider>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutoComplete);
