export default {
  confirmarCadastro: {
    email: "Email",
    codigo: "Código",
    senha: "Contraseña",
    confirmacaoSenha: "Confirmación de contraseña",
    confirmar: "CONFIRMAR REGISTRO",
    campoObrigatorio: "Campo obligatorio",
    emailInvalido: "Email inválido",
    senhaMinimo: "La contraseña debe tener al menos 6 caracteres",
    falhaConectar: "No fue posible validar su registro, inténtelo de nuevo",
    codigoExpirado: "Código de liberación caducado",
    usuarioNaoEncontrado: "Usuario no encontrado",
    sucesso: "Éxito",
    codigoInvalido: "Código invalido o desactualizado",
    falhaValidarCadastro: "Error al validar registro",
    cadastroJaConfirmado: "Registro ya confirmado",
    cadastroConfirmado: "El registro confirmado con éxito"
  }
};
