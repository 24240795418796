export default {
  especie: {
    especies: 'Espécies',
    descricao: 'Descrição',
    desejaExcluir: 'Deseja excluir espécie?',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    falhaAoExcluirEspecie: 'Falha ao excluir espécie',
    especieRemovidoComSucesso: 'Espécie removida com sucesso',
    especieCadastradaComSucesso: 'Espécie cadastrada com sucesso',
    especieEditadaComSucesso: 'Espécie editada com sucesso',
    falhaAoEditarEspecie: 'Falha ao editar espécie',
    falhaAoCadastrarEspecie: 'Falha ao cadastrar espécie',
    falhaAoCarregarEspecie: 'Falha ao carregar espécie',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    cadastroDeEspecie: 'Cadastro de espécie',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    ativo: 'Ativo',
    situacao: 'Situação',
    inativo: 'Inativo'
  }
};
