import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { getUserLang } from '@utils/localeUtils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DatePicker from '@components/DatePicker/DatePicker';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@components/Card/Card';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import formatMessage from './../i18n/formatMessage';

const style = theme => ({
  button: {
    height: 24,
    width: 24,
    marginTop: 10,
    marginLeft: 15
  },
  containerButton: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    marginTop: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  },
  textField: {
    width: '100%',
    height: 70
  },
  font: {
    fontSize: 14,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cadastro: {
    marginTop: '15px'
  },
  labelCheckbox: {
    cursor: 'pointer',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#000000'
  },
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    width: 'fit-content',
    cursor: 'pointer'
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiTypography: {
      headline: {
        color: '#555555',
        fontSize: 24
      },
      h5: {
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.7)'
      }
    },
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#008000'
        }
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    },
    default: {
      light: '#463766',
      main: '#463766',
      dark: '#463766',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class EditarColaboradores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colaborador: {
        idColaboradorProdutor: null,
        nmColaborador: '',
        tpSexo: '',
        dtNascimento: null,
        tpContrato: '',
        dtAdmissao: null,
        dtDemissao: null,
        dtCadastro: new Date(),
        stTomadaDecisao: '',
        stColaboradorProdutor: true,
        stConhecimentoOrganico: '',
        dsFuncaoExercida: '',
        stNormaTrabalhista: '',
        nrTelefone: '',
        nrClt: ''
      }
    };

    this.onSave = this.onSave.bind(this);
    this.formatTextField = this.formatTextField.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
  }

  formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  onSave(values) {
    values.nmSexo =
      values.tpSexo === 0 ? formatMessage('produtores.feminino') : formatMessage('produtores.masculino');
    values.nmContrato =
      values.tpContrato === 0
        ? formatMessage('produtores.efetivo')
        : formatMessage('produtores.temporario');
    values.dtNascimento = moment(values.dtNascimento).format('YYYY-MM-DD');
    values.dtAdmissao = moment(values.dtAdmissao).format('YYYY-MM-DD');
    values.dtDemissao = values.dtDemissao ? moment(values.dtDemissao).format('YYYY-MM-DD') : null;
    values.nmData = moment(values.dtNascimento).format(this.formatTextField());
    values.dataInicial = moment(values.dtAdmissao).format(this.formatTextField());

    if (values.stTomadaDecisao === '' || values.stTomadaDecisao === undefined) {
      values.stTomadaDecisao = 0;
    }

    values.stColaboradorProdutor = values.stColaboradorProdutor ? 1 : 0;

    if (values.stNormaTrabalhista === '' || values.stNormaTrabalhista === undefined) {
      values.stNormaTrabalhista = 0;
    }

    if (values.stConhecimentoOrganico === '' || values.stConhecimentoOrganico === undefined) {
      values.stConhecimentoOrganico = 0;
    }

    if (this.props.atualizando) {
      values.dtCadastro = this.props.colaborador.dtCadastro;
      values.stRegistro = this.props.colaborador.stRegistro;
      const position = this.props.data.findIndex(
        item => item.idColaboradorProdutor === values.idColaboradorProdutor
      );
      this.props.onSave(values, position);
    } else {
      values.dtCadastro = moment().format('YYYY-MM-DDTHH:mm:ssZZ');
      values.stRegistro = 0;
      this.props.onSave(values);
    }
  }

  onChangeSwitch(propName) {
    this.setState({ [propName]: !this.state[propName] });
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={this.props.atualizando ? this.props.colaborador : this.state.colaborador}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={this.onSave}
          validationSchema={Yup.object().shape({
            nmColaborador: Yup.string()
              .required(formatMessage('produtores.campoObrigatorio'))
              .test('test-name', ' ', function(value) {
                if (value && value.trim() && value.trim().split(' ').length < 2) {
                  const { path, createError } = this;
                  return createError({
                    path,
                    message: formatMessage('produtores.nomeCompleto')
                  });
                } else {
                  return true;
                }
              }),
            tpSexo: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
            nrTelefone: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
            nrClt: Yup.string(),
            dtNascimento: Yup.string()
              .nullable()
              .required(formatMessage('produtores.campoObrigatorio')),
            dtAdmissao: Yup.string()
              .nullable()
              .required(formatMessage('produtores.campoObrigatorio')),
            dtDemissao: Yup.string().nullable(),
            tpContrato: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
            dsFuncaoExercida: Yup.string().required(formatMessage('produtores.campoObrigatorio'))
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleReset
          }) => {
            this.handleReset = handleReset;

            return (
              <Card
                withOutBorderRadius
                title={formatMessage(
                  this.props.atualizando
                    ? 'produtores.edicaoColaborador'
                    : 'produtores.cadastroColaborador'
                )}
                height='473px'
                style={{ marginBottom: 35 }}
              >
                <form autoComplete='off'>
                  <Grid container spacing={16}>
                    <Grid item xs={4}>
                      <TextField
                        id='nmColaborador'
                        name='nmColaborador'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 100
                        }}
                        value={values.nmColaborador}
                        label={formatMessage('produtores.nome')}
                        className={classes.textField}
                        InputLabelProps={{ className: classes.font }}
                        error={errors.nmColaborador && touched.nmColaborador}
                        helperText={touched.nmColaborador ? errors.nmColaborador : ''}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextFieldWithMask
                        tipoMascara={1}
                        id='nrTelefone'
                        name='nrTelefone'
                        value={values.nrTelefone || ''}
                        label={formatMessage('produtores.telefone')}
                        className={classes.textField}
                        InputLabelProps={{ className: classes.font }}
                        error={errors.nrTelefone && touched.nrTelefone}
                        helperText={touched.nrTelefone ? errors.nrTelefone : ''}
                        onBlur={handleBlur}
                        onChange={value => setFieldValue('nrTelefone', value.target.value)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <DatePicker
                        locale={getUserLang()}
                        className={classes.textField}
                        label={formatMessage('produtores.dataNascimento')}
                        maxDate={moment()}
                        valueDate={values.dtNascimento}
                        onBlurDatePicker={e => {
                          const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                          !validation && setFieldValue('dtNascimento', null);
                          handleBlur('dtNascimento')(e);
                        }}
                        onChangeDatePicker={value => {
                          setFieldValue('dtNascimento', value);
                        }}
                        errorTouched={
                          errors.dtNascimento && touched.dtNascimento ? touched.dtNascimento : false
                        }
                        helperText={
                          errors.dtNascimento && touched.dtNascimento ? errors.dtNascimento : false
                        }
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <MuiThemeProvider theme={theme}>
                        <FormControl
                          className={classes.textField}
                          error={errors.tpSexo && touched.tpSexo}
                          style={{ width: '100%' }}
                        >
                          <InputLabel
                            className={classes.textField}
                            htmlFor='tpSexo'
                            error={errors.tpSexo && touched.tpSexo}
                          >
                            {formatMessage('produtores.sexo')}
                          </InputLabel>

                          <Select
                            value={values.tpSexo}
                            error={errors.tpSexo && touched.tpSexo}
                            onChange={e => setFieldValue('tpSexo', e.target.value)}
                            onBlur={handleBlur}
                            name='tpSexo'
                            id='tpSexo'
                          >
                            {[
                              formatMessage('produtores.feminino'),
                              formatMessage('produtores.masculino')
                            ].map((item, i) => (
                              <MenuItem key={i} value={i}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors.tpSexo && touched.tpSexo && (
                            <FormHelperText>{errors.tpSexo}</FormHelperText>
                          )}
                        </FormControl>
                      </MuiThemeProvider>
                    </Grid>

                    <Grid item xs={2}>
                      <div
                        onClick={() =>
                          setFieldValue(
                            'stColaboradorProdutor',
                            +values.stColaboradorProdutor === 1 ? 0 : 1
                          )
                        }
                        className={classes.switch}
                      >
                        <Checkbox
                          checked={+values.stColaboradorProdutor === 1}
                          value='checkedB'
                          color='primary'
                          size='small'
                        />
                        <label className={classes.labelCheckbox}>
                          {formatMessage('produtores.status')}
                        </label>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={16} className={classes.cadastro}>
                    <Grid item xs={4} style={{ height: 70 }}>
                      <TextField
                        value={values.dsFuncaoExercida}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id='dsFuncaoExercida'
                        inputProps={{
                          maxLength: 100
                        }}
                        label={formatMessage('produtores.funcaoExercida')}
                        className={classes.textField}
                        error={errors.dsFuncaoExercida && touched.dsFuncaoExercida}
                        helperText={touched.dsFuncaoExercida ? errors.dsFuncaoExercida : ''}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <MuiThemeProvider theme={theme}>
                        <FormControl
                          error={errors.tpContrato && touched.tpContrato}
                          style={{ width: '100%' }}
                        >
                          <InputLabel
                            htmlFor='tpContrato'
                            error={errors.tpContrato && touched.tpContrato}
                          >
                            {formatMessage('produtores.contrato')}
                          </InputLabel>

                          <Select
                            value={values.tpContrato}
                            error={errors.tpContrato && touched.tpContrato}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='tpContrato'
                            id='tpContrato'
                          >
                            {[
                              formatMessage('produtores.efetivo'),
                              formatMessage('produtores.temporario')
                            ].map((item, i) => (
                              <MenuItem key={i} value={i}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors.tpContrato && touched.tpContrato && (
                            <FormHelperText>{errors.tpContrato}</FormHelperText>
                          )}
                        </FormControl>
                      </MuiThemeProvider>
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        inputProps={{
                          maxLength: 20
                        }}
                        id='nrClt'
                        name='nrClt'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nrClt}
                        label={
                          values.tpContrato
                            ? formatMessage('produtores.numeroContrato')
                            : formatMessage('produtores.numeroRegistroCLT')
                        }
                        className={classes.textField}
                        InputLabelProps={{ className: classes.font }}
                        error={errors.nrClt && touched.nrClt}
                        helperText={touched.nrClt ? errors.nrClt : ''}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <DatePicker
                        locale={getUserLang()}
                        className={classes.textField}
                        label={formatMessage('produtores.dataAdmissao')}
                        maxDate={values.dtDemissao}
                        valueDate={values.dtAdmissao}
                        onBlurDatePicker={e => {
                          const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                          !validation && setFieldValue('dtAdmissao', null);
                          handleBlur('dtAdmissao')(e);
                        }}
                        onChangeDatePicker={value => {
                          setFieldValue('dtAdmissao', value);
                        }}
                        errorTouched={errors.dtAdmissao && touched.dtAdmissao ? true : false}
                        helperText={errors.dtAdmissao && touched.dtAdmissao ? errors.dtAdmissao : false}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <DatePicker
                        style={{ height: 70 }}
                        locale={getUserLang()}
                        className={classes.textField}
                        label={formatMessage('produtores.dataDemissao')}
                        minDate={values.dtAdmissao}
                        valueDate={values.dtDemissao}
                        onBlurDatePicker={e => {
                          const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                          !validation && setFieldValue('dtDemissao', null);
                          handleBlur('dtDemissao')(e);
                        }}
                        onChangeDatePicker={value => {
                          setFieldValue('dtDemissao', value);
                        }}
                        errorTouched={errors.dtDemissao && touched.dtDemissao ? true : false}
                        helperText={errors.dtDemissao && touched.dtDemissao ? errors.dtDemissao : false}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item xs={4}>
                      <div
                        className={classes.switch}
                        onClick={() =>
                          setFieldValue('stNormaTrabalhista', +values.stNormaTrabalhista === 1 ? 0 : 1)
                        }
                      >
                        <Checkbox
                          checked={+values.stNormaTrabalhista === 1}
                          value='checkedB'
                          color='primary'
                        />
                        <label className={classes.labelCheckbox}>
                          {formatMessage('produtores.conhecimentoNormas')}
                        </label>
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        onClick={() =>
                          setFieldValue('stTomadaDecisao', +values.stTomadaDecisao === 1 ? 0 : 1)
                        }
                        className={classes.switch}
                      >
                        <Checkbox
                          checked={+values.stTomadaDecisao === 1}
                          value='checkedB'
                          color='primary'
                          size='small'
                        />
                        <label className={classes.labelCheckbox}>
                          {formatMessage('produtores.tomadaDecisao')}
                        </label>
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        className={classes.switch}
                        onClick={() =>
                          setFieldValue(
                            'stConhecimentoOrganico',
                            +values.stConhecimentoOrganico === 1 ? 0 : 1
                          )
                        }
                      >
                        <Checkbox
                          checked={+values.stConhecimentoOrganico === 1}
                          value='checkedB'
                          color='primary'
                          size='small'
                        />
                        <label className={classes.labelCheckbox}>
                          {formatMessage('produtores.conhecimentoOrganica')}
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                </form>

                <Grid container>
                  <Grid item xs className={classes.containerButtons}>
                    <div style={{ color: '#463766' }}>
                      <Button variant='contained' color='inherit' onClick={this.props.onCancel}>
                        {formatMessage('produtores.cancelar')}
                      </Button>
                    </div>

                    <div>
                      <Button color='secondary' variant='contained' onClick={handleSubmit}>
                        {formatMessage('produtores.salvar')}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            );
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default withStyles(style)(EditarColaboradores);
