import { createMuiTheme } from '@material-ui/core/styles';

const style = themeStyle => ({
  fab: {
    position: 'fixed',
    bottom: themeStyle.spacing.unit * 27.5,
    right: themeStyle.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  form: {
    width: '100%'
  },
  textField: {
    width: '100%',
    height: '70px'
  },
  font: {
    height: 15,
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    textOverflow: 'ellipsis'
  },
  card: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  }
});

export { style, theme };
