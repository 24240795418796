import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import SelectWithGrouping from '@components/SelectWithGrouping/SelectWithGrouping';
import DatePicker from '@components/DatePicker/DatePicker';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getUserLang } from '@utils/localeUtils';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { style } from './ListDetalhes.styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

class DrawerProdutores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dtInicial: null,
      dtFinal: null,
      talhaoSelected: null,
      plantioSelected: null
    };

    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.onChangeSelectTalhao = this.onChangeSelectTalhao.bind(this);
    this.onChangeSelectPlantio = this.onChangeSelectPlantio.bind(this);
  }

  tratarValorSelectInitial(data) {
    if (!data) {
      return '';
    }

    return data;
  }

  onChangeSelectPlantio(e) {
    if (!e.target.value) return;

    let plantio = null;
    const plantioSelected = this.props.listPlantiosProdutor.find(
      doc => doc.idPlantio === +e.target.value
    );
    if (plantioSelected !== undefined) {
      plantio = plantioSelected;
    }

    this.setState({
      plantioSelect: plantio
    });

    this.props.onChangePlantio(plantio, this.state.dtInicial, this.state.dtFinal);
  }

  onChangeSelectTalhao(e) {
    if (!e.target.value) return;

    let talhao = null;
    for (const areaCultivo of this.props.listTalhoesProdutor) {
      const talhaoSelected = areaCultivo.childrens.find(item => item.idTalhao === +e.target.value);
      if (talhaoSelected !== undefined) {
        talhao = talhaoSelected;
        break;
      }
    }

    this.setState({
      talhaoSelected: talhao
    });

    this.props.onChangeTalhao(talhao, this.state.dtInicial, this.state.dtFinal);
  }

  render() {
    const { classes } = this.props;

    return (
      <Drawer
        anchor='right'
        variant='permanent'
        open={true}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: this.props.open,
            [classes.drawerClose]: !this.props.open,
            [classes.drawerMenuOpen]: !this.props.menuOpen.open && this.props.open,
            [classes.drawerMenuClose]: this.props.menuOpen.open && this.props.open
          })
        }}
      >
        <Grid
          container
          spacing={0}
          id='autoComplete'
          style={{
            padding: this.props.open ? 20 : 15,
            paddingBottom: 0,
            paddingRight: this.props.open ? 30 : 15,
            width: this.props.open ? 500 : '100%',
            minHeight: 330
          }}
        >
          <Grid item xs={12} style={{ height: 55 }}>
            <Autocomplete
              itens={this.props.produtores}
              campoOp={'nmProdutor'}
              campoChave={'idProdutor'}
              name='produtor'
              label={this.props.label}
              disabled={this.props.disabledProdutor}
              onBlurAutoComplete={this.props.onBlur}
              onChangeOwn={produtor => {
                this.props.timeLine(produtor);
                this.setState({
                  dtInicial: null,
                  dtFinal: null,
                  talhaoSelected: null
                });
              }}
              value={this.tratarValorSelectInitial(this.props.valueAutoComplete)}
              valueAutoComplete={this.tratarValorSelectInitial(this.props.valueAutoComplete)}
              valueSelect={this.tratarValorSelectInitial(this.props.valueAutoComplete).nmProdutor}
              key={this.props.key}
            />
          </Grid>

          {this.props.valueAutoComplete && (
            <Grid item xs={12} className={classes.select}>
              <SelectWithGrouping
                itens={this.props.listTalhoesProdutor}
                itemKey='idTalhao'
                itemLabel='dsTalhao'
                categoryLabel='dsAreaCultivo'
                label={this.props.labelTalhao}
                name='talhao'
                value={this.props.valueTalhaoProdutor ? this.props.valueTalhaoProdutor.idTalhao : ''}
                onChange={this.onChangeSelectTalhao}
                onBlur={this.props.onBlur}
                renderValue={value =>
                  this.props.valueTalhaoProdutor
                    ? `${this.props.valueTalhaoProdutor.areaCultivo.dsAreaCultivo} - ${this.props.valueTalhaoProdutor.dsTalhao}`
                    : ''
                }
              />

              <IconButton
                aria-label='X'
                className={classes.clearSelect}
                disabled={!this.props.valueTalhaoProdutor}
                onClick={() => {
                  this.props.onChangeTalhao('', this.state.dtInicial, this.state.dtFinal);

                  this.setState({
                    talhaoSelected: null
                  });
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}

          {this.props.valueAutoComplete && (
            <Grid item xs={12} className={classes.select}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel>{this.props.labelPlantio}</InputLabel>
                <Select
                  style={{
                    width: '100%'
                  }}
                  value={
                    this.props.valuePlantioProdutor ? this.props.valuePlantioProdutor.idPlantio : ''
                  }
                  onChange={this.onChangeSelectPlantio}
                  onBlur={this.props.onBlur}
                >
                  {this.props.listPlantiosProdutor.map(categoria => (
                    <MenuItem
                      key={categoria.idPlantio}
                      value={categoria.idPlantio}
                      style={{ paddingLeft: 30, paddingRight: 30 }}
                    >
                      {categoria.dsPlantio}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <IconButton
                aria-label='X'
                className={classes.clearSelect}
                disabled={!this.props.valuePlantioProdutor}
                onClick={() => {
                  this.props.onChangePlantio('', this.state.dtInicial, this.state.dtFinal);

                  this.setState({
                    talhaoSelected: null
                  });
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}

          {this.props.valueAutoComplete && (
            <Grid
              item
              xs={12}
              style={{ height: 55, marginTop: 10, display: 'flex', flexDirection: 'row' }}
            >
              <DatePicker
                maxDate={this.state.dtFinal || new Date('2100-01-01')}
                style={{ width: '100%' }}
                locale={getUserLang()}
                label={this.props.dataInicialLabel}
                valueDate={this.state.dtInicial}
                onBlurDatePicker={date => {
                  if (!date.target.value) {
                    this.props.onChangeDate(null, this.state.dtFinal, this.state.talhaoSelected);
                    this.setState({
                      dtInicial: null
                    });
                  }
                }}
                onChangeDatePicker={date => {
                  this.props.onChangeDate(date, this.state.dtFinal, this.state.talhaoSelected);
                  this.setState({
                    dtInicial: date
                  });
                }}
              />

              <IconButton
                aria-label='X'
                style={{ height: 50, width: 50, marginTop: 5 }}
                disabled={!this.state.dtInicial}
                onClick={() => {
                  this.setState({ dtInicial: null });
                  this.props.onChangeDate(null, this.state.dtFinal, this.state.talhaoSelected);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}

          {this.props.valueAutoComplete && (
            <Grid
              item
              xs={12}
              style={{ height: 55, marginTop: 10, display: 'flex', flexDirection: 'row' }}
            >
              <DatePicker
                minDate={this.state.dtInicial || new Date('1900-01-01')}
                style={{ width: '100%' }}
                locale={getUserLang()}
                label={this.props.dataFinalLabel}
                valueDate={this.state.dtFinal}
                onBlurDatePicker={date => {
                  if (!date.target.value) {
                    this.props.onChangeDate(this.state.dtInicial, null, this.state.talhaoSelected);
                    this.setState({
                      dtFinal: null
                    });
                  }
                }}
                onChangeDatePicker={date => {
                  this.props.onChangeDate(this.state.dtInicial, date, this.state.talhaoSelected);
                  this.setState({
                    dtFinal: date
                  });
                }}
              />

              <IconButton
                aria-label='X'
                style={{ height: 50, width: 50, marginTop: 5 }}
                disabled={!this.state.dtFinal}
                onClick={() => {
                  this.setState({ dtFinal: null });
                  this.props.onChangeDate(this.state.dtInicial, null, this.state.talhaoSelected);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>

        {this.props.children}
      </Drawer>
    );
  }
}

const mapStateToProps = state => ({
  menuOpen: state.menuOpen,
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(DrawerProdutores);
export default withRouter(connect(mapStateToProps)(enhaced));
