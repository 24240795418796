import React, { Component } from 'react';
import TextFieldAcompanhamento from './TextFieldAcompanhamento';
import Grid from '@material-ui/core/Grid';
import { getUserLang } from '@utils/localeUtils';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { withStyles } from '@material-ui/core/styles';
import { style } from './ListDetalhes.styles';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

class ListDetalhesRegistros extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const talhoesAdicionado = messagesLinguage['listDetalhes.talhoesAdicionado'];
    const observacao = messagesLinguage['listDetalhes.observacao'];
    const talhoes = messagesLinguage['listDetalhes.talhoes'];
    const imagens = messagesLinguage['listDetalhes.imagens'];
    const descricaoRegistroClimatico = messagesLinguage['listDetalhes.descricaoRegistroClimatico'];
    const tecnicoResponsavel = messagesLinguage['listDetalhes.tecnicoResponsavel'];
    const areaDeCultivo = messagesLinguage['listDetalhes.areaDeCultivo'];
    const cultura = messagesLinguage['listDetalhes.cultura'];
    const cultivarUtilizadas = messagesLinguage['listDetalhes.cultivarUtilizadas'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          tecnicoResponsavel,
          talhoesAdicionado,
          observacao,
          talhoes,
          imagens,
          descricaoRegistroClimatico,
          areaDeCultivo,
          cultura,
          cultivarUtilizadas
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();
  }

  render() {
    const { classes } = this.props;
    var timeline = this.props.timeLine[this.props.position];
    var info = timeline.info;

    return (
      <Grid container style={{ padding: 15 }} spancing={24}>
        {(timeline.type === 9 || timeline.type === 7 || timeline.type === 6) && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={info.nmTecnicoResponsavel}
              label={provider.intl.formatMessage({ id: 'tecnicoResponsavel' })}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.plantio.areaCultivo.dsAreaCultivo}
            label={provider.intl.formatMessage({ id: 'areaDeCultivo' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.plantio.cultura.nmCultura}
            label={provider.intl.formatMessage({ id: 'cultura' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.plantio.cultivarEspec.nmCultivarEspec}
            label={provider.intl.formatMessage({ id: 'cultivarUtilizadas' })}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.dsAdicional}
            label={this.props.labelTextFieldAcompanhamento}
          />
        </Grid>

        {timeline.type === 6 && (
          <Grid item xs={12}>
            <div
              style={{
                fontSize: 12,
                color: 'rgb(0, 0, 0, 0.54)',
                marginBottom: 10
              }}
            >
              {provider.intl.formatMessage({ id: 'imagens' })}
            </div>
            {info.atividadeFotoList.map(doc => {
              return (
                <div
                  key={doc.idAtividadeFoto}
                  style={{
                    height: 100,
                    display: 'flex',
                    paddingBottom: 10,
                    paddingTop: 10,
                    borderBottom: '1px solid rgb(112, 112, 112, 0.5)'
                  }}
                >
                  <img
                    onClick={() => {
                      window.open(doc.dsFoto);
                    }}
                    style={{
                      objectFit: 'contain',
                      cursor: 'pointer'
                    }}
                    src={doc.dsThumb}
                    alt='Imagem'
                  />
                  <div key={doc.idAtividadeFoto} className={classes.textImage}>
                    {doc.dsAtividadeFoto}
                  </div>
                </div>
              );
            })}
          </Grid>
        )}
        <Grid item xs={12} style={{ paddingTop: 10 }}>
          <div
            style={{
              fontSize: 12,
              color: 'rgb(0, 0, 0, 0.54)',
              marginTop: 15
            }}
          >
            {provider.intl.formatMessage({ id: 'talhoesAdicionado' })}
          </div>
          {this.props.chipsTalhoes(info.atividadeTalhaoList)}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(style)(ListDetalhesRegistros);
