export default {
  calendar: {
    hoje: 'Hoje',
    mes: 'Mês',
    semana: 'Semana',
    dia: 'Dia',
    mais: 'mais',
    agenda: 'Agenda',
    diaTodo: 'Dia todo',
    data: 'Data',
    hora: 'Hora',
    evento: 'Evento',
    noEvents: 'Não há eventos nessas datas',
    formatDate: 'ddd D/M',
    formatTime: 'H:mm',
    formatDatePopUp: 'dddd, D - MMMM',
    adicionar: 'Adicionar',
    list: 'Lista',
    formatMonth: 'MMMM [de] YYYY',
    adicionarEvento: 'Adicionar evento',
    adicionarVisita: 'Adicionar visita'
  }
};
