import formatMessage from './i18n/formatMessage';

const head = [
  {
    field: 'nmInsumo',
    headerName: formatMessage('insumo.descricao'),
    sort: 'asc',
    col: 4
  },
  {
    field: 'nmMarca',
    headerName: formatMessage('insumo.marca'),
    col: 4
  }
];

const headOrganico = [
  ...head,
  {
    field: 'nmOrganica',
    headerName: formatMessage('insumo.certificacaoOrganica'),
    col: 4
  }
];

const operationValues = [
  {
    label: formatMessage('insumo.descricao'),
    field: 'nmInsumo',
    type: 'string'
  },
  {
    label: formatMessage('insumo.marca'),
    field: 'nmMarca',
    type: 'string'
  }
];
const operationValuesOrganic = [
  ...operationValues,
  {
    label: formatMessage('insumo.certificacaoOrganica'),
    field: 'nmOrganica',
    type: 'string'
  }
];

export { head, headOrganico, operationValues, operationValuesOrganic };
