import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { style } from '../AcompanhamentoProdutor.styles';
import { withStyles } from '@material-ui/core/styles';
import { Warning, Info } from '@material-ui/icons';

/**
 * Componente de lista de chips de descrição de talhão
 */
function ChipTalhaoMonitoramento(props) {
  const { classes, chipsList } = props;

  return (
    <div>
      {chipsList.map((tal, i) => {
        let border = 'none';
        let icon = null;
        let tooltip = '';

        if (tal.stTalhao === 1) {
          border = '1px solid rgb(226, 136, 59)';
          icon = <Info style={{ height: 15, width: 15 }} />;
          tooltip = tal.nmStTalhao;
        } else if (tal.stTalhao === 2) {
          border = '1px solid #F33A30';
          icon = <Warning style={{ height: 15, width: 15 }} />;
          tooltip = tal.nmStTalhao;
        }

        return (
          <Tooltip title={tooltip} key={i}>
            <Chip
              icon={icon}
              style={{
                border: border
              }}
              label={tal.dsTalhao}
              variant='outlined'
              key={i}
              className={classes.chip}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}

export default withStyles(style)(ChipTalhaoMonitoramento);
