import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import { Field } from 'formik';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import DatePicker from '@components/DatePicker/DatePicker';
import IconeFile from '@material-ui/icons/AttachFile';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    if (file.size <= 20000000) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    } else {
      reject();
    }
  });
}

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = theme => ({
  root: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  swipeable: {
    width: '100%',
    height: '100%'
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.35
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      contained: {
        height: '46px',
        width: '100px',
        marginLeft: '15px'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        }
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Tab Asplectos Legais
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
class AspectosLegais extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const tpArea = messagesLinguage['produtores.tpArea'];
    const validadeContrato = messagesLinguage['produtores.validadeContrato'];
    const termoCompromisso = messagesLinguage['produtores.termoCompromisso'];
    const areaPropria = messagesLinguage['produtores.areaPropria'];
    const arrendada = messagesLinguage['produtores.arrendada'];
    const ambas = messagesLinguage['produtores.ambas'];
    const campoObrigatorio = messagesLinguage['produtores.campoObrigatorio'];
    const aspectoLegal = messagesLinguage['produtores.aspectoLegal'];
    const selecioneArquivo = messagesLinguage['produtores.selecioneArquivo'];
    const nenhumArquivoSelecionado = messagesLinguage['produtores.nenhumArquivoSelecionado'];
    const baixar = messagesLinguage['produtores.baixar'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          tpArea,
          validadeContrato,
          termoCompromisso,
          areaPropria,
          arrendada,
          ambas,
          campoObrigatorio,
          aspectoLegal,
          selecioneArquivo,
          nenhumArquivoSelecionado,
          baixar
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      inputkey: Math.random(),
      error: false,
      dsArquivo: null,
      dsNomeArquivo: null,
      dsLink: null
    };

    this.inputFilesRef = null;

    this.onAddFiles = this.onAddFiles.bind(this);
    this.nameArquivo = this.nameArquivo.bind(this);
    this.converterName = this.converterName.bind(this);
  }

  componentDidMount() {
    this.setState({
      dsArquivo: this.props.arquivo,
      dsNomeArquivo: this.props.nameArquivo,
      dsLink: this.props.link
    });
  }

  converterName(item) {
    var firstIndex = item.indexOf('-');
    var firstValue = item.slice(firstIndex + 1, item.length);
    var index = firstValue.indexOf('-');
    var value = firstValue.slice(index + 1, firstValue.length);

    return value;
  }

  nameArquivo() {
    if (this.state.dsLink) {
      return this.converterName(this.state.dsNomeArquivo);
    } else {
      return this.state.dsNomeArquivo;
    }
  }

  async onAddFiles(e, files) {
    return await fileToBase64(files[0]).then(dsArquivoBase64 => {
      this.setState(
        {
          dsArquivo: dsArquivoBase64.split(';base64,')[1],
          dsNomeArquivo: files[0].name,
          dsLink: null
        },
        () => {
          this.props.addFile(this.state.dsArquivo, this.state.dsNomeArquivo, this.state.dsLink);
        }
      );
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <form autoComplete='off'>
            <Grid container style={{ height: 70 }}>
              <Grid item xs={3} style={{ paddingRight: 15 }}>
                <Field name='tpArea'>
                  {({ field, form: { touched, errors, setFieldValue, values } }) => (
                    <FormControl error={errors.tpArea && touched.tpArea} style={{ width: '100%' }}>
                      <InputLabel htmlFor='tpArea' error={errors.tpArea && touched.tpArea}>
                        {provider.intl.formatMessage({ id: 'tpArea' })}
                      </InputLabel>

                      <Select
                        {...field}
                        error={errors.tpArea && touched.tpArea}
                        name='tpArea'
                        id='tpArea'
                        onChange={value => {
                          setFieldValue('tpArea', value.target.value);
                          if (value.target.value === 0) {
                            values.dtValidadeContrato = null;
                          }
                        }}
                        onBlur={value => {
                          setFieldValue('tpArea', value.target.value);
                          if (value.target.value === 0) {
                            values.dtValidadeContrato = null;
                          }
                        }}
                      >
                        {[
                          provider.intl.formatMessage({
                            id: 'areaPropria'
                          }),
                          provider.intl.formatMessage({ id: 'arrendada' }),
                          provider.intl.formatMessage({ id: 'ambas' })
                        ].map((item, i) => (
                          <MenuItem key={i} value={i}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>

                      {errors.tpArea && touched.tpArea && (
                        <FormHelperText>{errors.tpArea}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Grid>

              <Grid item xs={2} style={{ paddingRight: 15 }}>
                <Field name='dtValidadeContrato'>
                  {({ field, form: { touched, errors, values, setFieldValue } }) => (
                    <DatePicker
                      {...field}
                      locale={getUserLang()}
                      style={{ height: 70 }}
                      label={provider.intl.formatMessage({
                        id: 'validadeContrato'
                      })}
                      valueDate={values.dtValidadeContrato}
                      errorTouched={errors.dtValidadeContrato && touched.dtValidadeContrato}
                      helperText={provider.intl.formatMessage({
                        id: 'campoObrigatorio'
                      })}
                      onChangeDatePicker={date => setFieldValue('dtValidadeContrato', date)}
                      onBlurDatePicker={e => {
                        let validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                        !validation && setFieldValue('dtValidadeContrato', null);
                      }}
                      disabled={values.tpArea < 1}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={7} style={{ marginTop: -3 }}>
                <div style={{ float: 'left', width: '100%', height: '100%', display: 'flex' }}>
                  <div
                    style={{ width: 'calc(100% - 50px)', float: 'left' }}
                    onDrop={e => this.onAddFiles(e, e.dataTransfer.files)}
                    onDragOver={e => e.preventDefault()}
                    onClick={() => {
                      this.inputFilesRef.click();
                    }}
                  >
                    <input
                      key={this.state.inputKey}
                      style={{
                        display: 'none',
                        cursor: 'pointer',
                        float: 'left'
                      }}
                      type='file'
                      ref={ref => {
                        this.inputFilesRef = ref;
                      }}
                      onChange={e => {
                        this.onAddFiles(e, e.target.files);
                      }}
                    />

                    <div
                      style={{
                        display: 'flex',
                        borderBottomWidth: this.state.error ? 2 : 1,
                        borderColor: this.state.error ? 'red' : 'rgba(0, 0, 0, 0.42)',
                        height: this.state.error ? 46 : 47,
                        borderBottomStyle: 'solid',
                        fontFamily: 'Roboto, Helvetica ,Arial ,sans-serif',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        color: 'rgba(0, 0, 0, 0.54)'
                      }}
                    >
                      <div
                        style={{
                          marginTop: 'auto',
                          marginBottom: 5,
                          whiteSpace: 'nowrap',
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {this.state.dsNomeArquivo
                          ? this.nameArquivo()
                          : provider.intl.formatMessage({
                              id: 'nenhumArquivoSelecionado'
                            })}
                      </div>

                      <div style={{ textAlign: 'end', paddingTop: 9 }}>
                        <Button color='primary' style={{ minWidth: 36 }}>
                          <Tooltip
                            title={provider.intl.formatMessage({
                              id: 'selecioneArquivo'
                            })}
                          >
                            <IconeFile />
                          </Tooltip>
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Button
                    disabled={!this.state.dsLink}
                    color='primary'
                    style={{ minWidth: 36, height: 41, marginTop: 9 }}
                  >
                    <Tooltip
                      title={provider.intl.formatMessage({
                        id: 'baixar'
                      })}
                    >
                      <ArrowDownward
                        style={{
                          color: this.state.dsLink ? '#00622B' : 'rgba(0, 0, 0, 0.54)'
                        }}
                        disabled={!this.state.dsLink}
                        onClick={async () => {
                          var res = await fetch(this.state.dsLink);
                          var data = await res.blob();

                          const anchor = document.createElement('a');
                          anchor.href = window.URL.createObjectURL(data);
                          anchor.download = this.converterName(this.state.dsNomeArquivo);
                          anchor.click();
                        }}
                      />
                    </Tooltip>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(AspectosLegais);
