export default {
  acompanhamentoProdutor: {
    descricaoNaoConformidade: 'Descripción del incumplimiento',
    cadastroDeProdutor: 'Cadastro de Procdutor',
    produtores: 'Produtores',
    identificacao: ' Identificación',
    nome: 'Nombre',
    telefone: 'Teléfono',
    comunidade: 'Comunidad',
    municipio: 'Condado',
    uf: 'UF',
    email: 'E-mail',
    nivel: 'Nível tecnológico',
    tecnicoResponsavel: 'Tecnico responsable',
    ativo: 'Activo',
    codigo: 'Código',
    cidade: 'Country',
    estado: 'UF',
    descricao: 'Description',
    areaCultivo: 'Area total de cultivo(Ha)',
    areaTotaldeTalhoes: 'Superficie total de terreno',
    dataVisita: 'Fecha de la visita',
    hora: 'Tiempo',
    tecnico: 'Técnico',
    dataPlantio: 'Fecha de la siembra',
    culturas: 'Cultura',
    dataColheita: 'Fecha de cosecha',
    colheita: 'Cosecha',
    produtividade: 'Productividad(Bolsas/Ha)',
    areaDeCultivo: 'Área de cultivo',
    talhoes: 'Parcelas',
    visitas: 'Visitas',
    plantios: 'Plantaciones',
    campoObrigatorio: 'Campo obligatorio',
    status: 'Estatus',
    inativo: 'Inactivo',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    gravar: 'Registro',
    desejaExcluirAreaCultivo: '¿Desea eliminar el área de cultivo seleccionado?',
    desejaExcluirTalhao: '¿Desea eliminar el parcela seleccionado?',
    nao: 'No',
    excluir: 'Borrar',
    excluindo: 'Excluyendo',
    areaCultivoRemovidoComSucesso: 'Área de cultivo removido con éxito',
    talhaoRemovidoComSucesso: 'Parcelas removido con éxito',
    medio: 'Promedio',
    alto: 'Alto',
    baixo: 'Bajo',
    salvando: 'Verano',
    atualizando: 'Actualizando',
    produtorCadastradoComSucesso: 'Productor registrado correctamente',
    produtorEditadoComSucesso: 'Productor editado con éxito',
    falhaAoCadastrarProdutor: 'Error al registrar productor',
    falhaAoEditarProdutor: 'Error al editar productor',
    emailJaCadastrado: 'Ese correo electrónico ya está registrado',
    erroAoCarregarDados: 'Error al cargar los datos',
    aplicacoes: 'Aplicaciones',
    dataAplicacao: 'Fecha de la aplicación',
    tipo: 'Tipo',
    marca: 'Marca',
    produto: 'Producto',
    talhao: 'Parcela',
    hectares: 'Hectáreas',
    acaricida: 'Acaricidas',
    acaricidaMicrobiologico: 'Acaricidas microbiológicas',
    agenteBiologicoControle: 'Agentes biológicos',
    bactericida: 'Agentes biológicos',
    bactericidaMicrobiologico: 'Bactericidas microbiológicos',
    herbicida: 'Herbicidas',
    fungicida: 'Fungicidas',
    fungicidaMicrobiologico: 'Fungicidas Microbiológicos',
    inseticida: 'Inseticidas',
    inseticidaMicrobiologico: 'Inseticidas microbiológicos',
    nematicida: 'Nematicidas',
    nematicidaMicrobiologico: 'Nematicidas microbiológicas',
    fertilizanteFoliar: 'Fertilizantes hoja',
    talhaoDuplicado: 'de la madera',
    plantioCadastradoComSucesso: 'plantación registrada con éxito',
    desejaExcluirAplicacao: 'desea excluir la aplicación?',
    aplicacaoRemovidaComSucesso: 'Aplicación removida con éxito',
    naoFoiPossivelEditar: 'No se pudo editar la plantación',
    naoFoiPossivelInserir: 'No se pudo insertar plantación',
    plantioEditadoComSucesso: 'Plantación editado con éxito',
    plantioRemovidoComSucesso: 'Plantación registrada con éxito',
    erroAoRemoverPlantio: 'Error al quitar plantación',
    desejaExcluirPlantio: 'Desea eliminar la plantación?',
    areaTotalSelecionada: 'Área total seleccionada(Ha)',
    talhoesAdicionados: 'Taladros añadidos',
    selecionarTodos: 'Select all',
    areaPlantio: 'Área de plantación',
    acompanhamentoNaoCadastrado: 'Acompañamiento de plantío sin registrar todavía',
    acompanhamentoPlantio: 'Acompañamiento de plantación',
    acompanhamentoProdutor: 'Acompañamiento al productor',
    areaTanque: 'Área por tanque',
    nrQuantidade: 'Cantidad',
    produtos: 'Produtos',
    lancamentoAplicacao: 'Lanzamiento de la aplicación hecho',
    dsUnMedida: 'Un. de medida',
    dsRecomendacaoAdicional: 'Recomendación adicional',
    ordem: 'Orden',
    dose: 'Dosis por tanque',
    tipoPesticida: 'Tipo de producto',
    adicionarProdutos: 'Añadir productos',
    areaTotal: 'Área total',
    lancamentoCadastrado: 'Lanzamiento registrado con éxito',
    lancamentoAtualizado: 'Lanzamiento editado con éxito',
    falhaAoCadastrarLancamento: 'Error al registrar la contabilización',
    falhaAoAtualizarLancamento: 'Error al actualizar la versión',
    unMedida: 'Unidad de medida',
    recomendacoesAdicionais: 'Recomendaciones adicionales',
    visualizarRecomendacoes: 'Ver recomendaciones',
    tpProduto: 'Tipo de producto',
    fechar: 'Cerca',
    aplicarNosTalhoes: 'Aplicar en los lotes',
    talhoesUtilizados: 'Taladros utilizados',
    falhaAoCarregarAcompanhamento: 'Error al cargar el seguimiento',
    desejaExcluirProduto: '¿Desea eliminar el producto?',
    produtoRemovidoComSucesso: 'Producto eliminado con éxito',
    lancamentoRecomendacao: 'Lanzamiento de recomendación de aplicación',
    cadastrarAplicacao: 'Inscripción de aplicación',
    cadastrarRecomendacao: 'Inscripción recomendada',
    acre: 'Acre',
    hectare: 'Hectárea',
    alqueire: 'Alqueire',
    kg: 'Kg',
    numeroMaximoProdutores: 'El límite de productores de dicho plan se alcanzó',
    desejaExcluir: '¿Desea eliminar?',
    falhaAoExcluirProdutor: 'Fallo al excluir el productor',
    produtorExcluidoComSucesso: 'Productor excluido con éxito',
    telefoneInvalido: 'Teléfono no válido',
    cadastroPlantio: 'Registro de plantación',
    cadastrarAreaCultivo: 'Primer registro de área de cultivo',
    cadastrarTalhao: 'Primer registro área de cultivo y lote',
    talhaoPlantioAssociado: 'Talón y siembra asociados al área de cultivo',
    exclusaoAreaDeCultivoNaoPermitida: 'La exclusión del área de cultivo no está permitida',
    talhaoAssociado: 'Taladro asociado al área de cultivo',
    plantioAssociado: 'Plantio asociado al área de cultivo',
    produtor: 'Productor',
    produtorInvalidoTecnico: 'Productor no encontrado',
    plantio: 'Plantación',
    dataMinima: 'Fecha inválida',
    oleosAdjuvantes: 'Aceites y adyuvantes',
    confirmar: 'Confirmar',
    salvarLancamentoSair: 'Hay información no guardada, ¿le gustaría guardar la publicación?',
    descartar: 'Descartar',
    sacas: 'Sacos',
    toneladas: 'Toneladas',
    m2: 'm²',
    sementes: 'Semillas',
    cadastrarAlerta: 'Registrar alerta fitosanitaria',
    invasoras: 'Invasivo',
    agenteCausal: 'Agente causal',
    data: 'Fecha',
    doencaSolo: 'Enfermedad del suelo',
    doencaFoliar: 'Enfermedad de la hoja',
    nematoide: 'Nematodo',
    pragas: 'Plagas',
    adicionarAlertas: 'Agregar alertas fitosanitarias',
    alertaRemovidoComSucesso: 'Alerta fitosanitaria eliminada con éxito',
    desejaExcluirAlerta: '¿Quieres eliminar la alerta fitosanitaria?',
    alertasFitossanitarios: 'Alertas fitosanitarias',
    observacao: 'Nota',
    lancamentoAlerta: 'Liberación de alerta fitosanitaria',
    efetivarRecomendacao: '¿Desea realizar la recomendación tal como se realiza?',
    sim: 'Si',
    falhaEfetivarRecomendacao: 'Error al efectuar la recomendación',
    sucessoEfetivarRecomendacao: 'Éxito en efectuar la recomendación',
    categoria: 'Categoría',
    acaros: 'Ácaros',
    bacterias: 'Bacterias',
    ervasDaninhas: 'Malas hierbas',
    fungos: 'Hongos',
    insetos: 'Insectos',
    nematoides: 'Nematodos',
    foliaresAdjuvantes: 'Hoja y adyuvante',
    fertilizantes: 'Fertilizantes',
    fertilizanteQuimico: 'Fertilizantes químico',
    fertilizanteOrganico: 'Fertilizantes orgánico',
    litros: 'Litros',
    cadastrarMonitoramento: 'Monitoreo de plagas',
    lancamentoMonitoramento: 'Monitoreo',
    quantidadeFitossanidade: 'Cantidad de plagas',
    numero: 'Numero',
    amostras: 'Muestras',
    adicionarAmostras: 'Agregar muestras',
    praga: 'Praga',
    pragaDuplicada: 'Praga ya registrada',
    desejaExcluirpraga: '¿Quieres eliminar praga?',
    pragaRemovidaComSucesso: 'Praga eliminada con éxito',
    falhaAoExcluirPraga: 'Error al eliminar praga',
    desejaExcluirAmostra: '¿Quieres eliminar la muestra?',
    amostraRemovidaComSucesso: 'Muestra eliminada con éxito',
    falhaAoExcluirAmostra: 'Error al eliminar la muestra',
    fitossanidade: 'Praga',
    monitoramentoAtualizado: 'Monitoreo actualizado exitosamente',
    monitoramentoCadastrado: 'Seguimiento registrado con éxito',
    falhaAoAtualizarMonitoramento: 'Error al actualizar la supervisión',
    falhaAoCadastrarMonitoramento: 'Error al registrar el monitoreo',
    adicionar: 'Agregar',
    metro: 'metro',
    mediaPorTalhao: 'Promedio por campo',
    nenhum: 'Ninguno',
    emAlerta: 'En alerta',
    necessitaAplicacao: 'Necesita aplicación',
    situacaoTalhao: 'Situación de campo',
    nenhumaAtividadeRegistrada: 'Ninguna actividad registrada',
    especiesIdentificadas: 'Especies identificadas',
    cadastrarRegistroFotografico: 'Registrar registro fotográfico',
    lancamentoRegistroFotografico: 'Lanzamiento del registro fotográfico',
    imagens: 'Imágenes',
    cadastrarRegistroClimatico: 'Registrar registro climático',
    descricaoRegistroClimatico: 'Descripción del registro climático',
    lancamentoRegistroClimatico: 'Lanzamiento de registro climático',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    nenhumResultadoEncontrado: 'Ningún resultado encontrado',
    inoculantesHomeopatia: 'Inoculantes y homeopatía',
    homeopaticos: 'Productos homeopáticos',
    inoculantes: 'Inoculantes',
    desejaCriarRecomendacao: '¿Quieres crear una recomendación de aplicación?',
    mudas: 'Plántulas'
  }
};
