/*  
    Autor : Gabriela Farias 
*/
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Card from '@material-ui/core/Card';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { getUserLang } from '@utils/localeUtils';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './DataTable.css';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = theme => ({
  card: {
    backgroundColor: '#FFFFFF',
    paddingLeft: 15,
    paddingRight: 15,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: 250
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiTablePagination: {
      toolbar: {
        paddingRight: 0,
        paddingLeft: 0
      },
      caption: {
        color: '#000000',
        fontSize: 12
      },
      selectRoot: {
        color: '#000000',
        fontSize: 12,
        marginLeft: 8,
        marginRight: 15
      },
      actions: {
        marginLeft: 0
      },
      select: {
        paddingRight: 20,
        paddingLeft: 0
      }
    },
    MuiInputBase: {
      input: {
        padding: '7px 0 7px'
      }
    },
    MuiSelect: {
      selectMenu: {
        minHeight: 0
      }
    },
    MuiTableCell: {
      root: {
        padding: 0
      }
    }
  }
});

/*
    Componente de Tabela customizado
    Métodos:
    data: registro da tabela
    orderChange: método chamado ao solicitar ordenaçao das colunas
    selectChange: método chamado ao clicar em um registro
    columns: atributo com o nome das colunas, 
    deve conter field( nome da coluna ), headerName( nome apresentavel da coluna ), 
    sort ( para a coluna conter desc/asc ao iniciar ), pinned ( coluna fixa left/right ), 
    cellClass, headerClass, suppressSizeToFit ( tamanho da grid fixo )
    showPagination: apresentar o rodapé (true ou false )
    pagination: objeto contendo dados da paginação da tabela,
    deve conter length, rowsPerPage, page, rowsPerPageOptions, onChangePage( row ) => row.rowsPerPage, row.page), 
  */

class DataTable extends Component {
  constructor(props) {
    super(props);

    this.props.columns.map(column => {
      column.cellClass = 'col-grid-' + column.col + ' grid-padding-left';
      column.headerClass = 'col-grid-' + column.col + ' grid-padding-left';
      return column;
    });

    this.newOrder = [];

    this.state = {
      currentSelection: [],
      sortColumn: [],
      page: this.props.pagination ? this.props.pagination.page : 0,
      rowsPerPage: 25,
      defaultColDef: {
        resizable: true,
        sortable: !this.props.sortable
      },
      gridOptions: {
        headerHeight: 56,
        columnDefs: this.props.columns,
        colResizeDefault: 'shift',
        autoHeight: true,
        accentedSort: true,
        suppressMovableColumns: true,
        suppressPropertyNamesCheck: true,
        rowSelection: !this.props.rowSelection ? 'single' : 'multiple',
        rowDeselection: true,
        rowMultiSelectWithClick: true,
        suppressCellSelection: true,
        suppressClickEdit: true,
        suppressMultiSort: true,
        suppressColumnVirtualisation: true,
        localeText: { noRowsToShow: ' ' },
        onGridSizeChanged: () => {
          this.state.gridOptions.api && this.state.gridOptions.api.sizeColumnsToFit();
        },
        onSelectionChanged: () => this.onSelect(),
        onSortChanged: event => {
          this.sort(event.api.getSortModel());
          this.state.gridOptions.api.setRowData(this.props.data);
          this.onSelect(this.state.currentSelection);
        },
        onRowDragMove: event => {
          if (!event.overNode) return;
          let movingNode = event.node || '';
          let overNode = event.overNode || '';
          let rowNeedsToMove = movingNode !== overNode;
          if (rowNeedsToMove) {
            let movingData = movingNode.data;
            let fromIndex = getIndex(this.props.data, movingData);
            let toIndex = event.overIndex;
            let newStore = [...this.props.data];
            moveInArray(newStore, fromIndex, toIndex);
            this.newOrder = newStore;
          }

          function getIndex(array, data) {
            let index = -1;
            array.find((value, i) => {
              if (JSON.stringify(value) === JSON.stringify(data)) {
                index = i;
                return true;
              }
              return false;
            });
            return index;
          }

          function moveInArray(arr, fromIndex, toIndex) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
          }
        },
        onDragStopped: event => {
          if (this.newOrder.length !== 0) {
            this.props.onRowDragEnd(this.newOrder);
          }
        }
      }
    };

    this.onSelect = this.onSelect.bind(this);
    this.sort = this.sort.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    this.state.gridOptions.onGridSizeChanged();

    if (this.props.rowSelection) {
      let list = this.props.itensSelect;
      this.state.gridOptions.api.forEachNode(function(node) {
        list.forEach(function(value) {
          if (value.id === node.data.id) {
            node.setSelected(true);
          }
        });
      });
    }
  }

  handleChangePage(event, page) {
    this.state.gridOptions.api.setRowData(this.props.data);
    this.onSelect(this.state.currentSelection);
    if (this.state.page < page) {
      this.state.gridOptions.api.paginationGoToNextPage();
    } else if (this.state.page > page) {
      this.state.gridOptions.api.paginationGoToPage(page);
    }
    this.setState({ page: page });
  }

  handleChangeRowsPerPage(event) {
    this.state.gridOptions.api.setRowData(this.props.data);
    this.onSelect(this.state.currentSelection);
    this.setState({ rowsPerPage: event.target.value });
    this.state.gridOptions.api.paginationSetPageSize(event.target.value);
  }

  sort(select) {
    /*  aqui será ajustado a ordenação, será feito no backend */
    if (this.props.orderChange) {
      this.props.orderChange(select);
    }
  }

  onSelect(item = false) {
    if (!this.gridApi && item === false) return;

    if (this.props.rowSelection) {
      this.props.rowsSelect(this.gridApi.getSelectedRows());
      return;
    }

    const selected = item === false ? this.gridApi.getSelectedRows()[0] : item;

    const isEqualsPrevious = this.state.currentSelection === selected;
    if (isEqualsPrevious) {
      this.setState({
        currentSelection: undefined
      });
    } else {
      this.setState({
        currentSelection: selected
      });
    }

    if (this.props.selectChange) {
      this.props.selectChange(isEqualsPrevious ? undefined : selected);
    }
  }

  shouldComponentUpdate(newProps, newState) {
    if (newProps.data !== this.props.data) {
      this.state.gridOptions.api.setRowData(this.props.data);
      this.onSelect(undefined);
      if (this.props.showPagination) {
        this.handleChangePage(null, 0);
      }
      return false;
    }

    return true;
  }

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page } = this.state;
    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const de = messagesLinguage['table.de'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          de
        }
      },
      {}
    );
    const { intl } = intlProvider.getChildContext();
    const usePaddings = this.props.usePaddings ? 15 : 0;
    const borderRadius = this.props.usePaddings || this.props.borderRadius ? 5 : 0;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Card
          style={{
            marginLeft: usePaddings,
            marginRight: usePaddings,
            borderRadius: borderRadius,
            marginBottom: this.props.noMargin ? 0 : 15,
            marginTop: this.props.marginTop ? 15 : 0,
            minHeight: 250,
            boxShadow: this.props.noBox
              ? 'none'
              : '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important',
            ...this.props.style
          }}
          className={classes.card}
        >
          <div className={'ag-theme-material'} style={{ flex: 1, minHeight: 250 }}>
            <AgGridReact
              gridOptions={this.state.gridOptions}
              rowData={this.props.data}
              defaultColDef={this.state.defaultColDef}
              pagination={this.props.showPagination}
              paginationPageSize={rowsPerPage}
              rowDragManaged={true}
              animateRows={true}
              onGridReady={params => {
                this.gridApi = params.api;
                this.gridColumnApi = params.columnApi;
              }}
              rowStyle={{
                cursor: this.props.noSelection ? 'default' : 'pointer',
                overflow: 'hidden',
                backgroundColor:
                  this.props.rowSelection || this.props.noSelection ? 'transparent' : null,
                borderStyle: this.props.rowSelection || this.props.noSelection ? 'none' : null,
                borderBottom:
                  this.props.rowSelection || this.props.noSelection
                    ? ' 0.5px solid rgb(226, 226, 226) !important '
                    : null
              }}
            />
          </div>

          {!this.props.showPagination && (
            <div
              style={{
                height: '15px',
                borderStyle: 'solid',
                borderColor: 'rgba( 0, 0, 0 , 0.1 )',
                boxSizing: 'border-box',
                borderWidth: this.props.noBorderWidth ? '0px 0px 0px' : '2px 0px 0px'
              }}
            />
          )}

          {this.props.showPagination && (
            <Table style={{ display: 'flex', alignItems: 'center', height: 50 }}>
              <MuiThemeProvider theme={theme}>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      className={classes.rodape}
                      count={this.props.pagination.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={<FormattedMessage id='table.itensPagina' />}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from} - ${to} ${intl.formatMessage({
                          id: 'de'
                        })} ${count} `
                      }
                      rowsPerPageOptions={[25, 50, 100]}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </MuiThemeProvider>
            </Table>
          )}
        </Card>
      </IntlProvider>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles)(DataTable));
