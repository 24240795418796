import URL from "./URL";

const axios = require("axios");
const api = URL.API;

function findAllSeveridadeHandler() {
  return axios.get(api + "/severidade");
}

function findSeveridadeByTipoHandler(tpTipo) {
  return axios.get(api + "/severidade/tpseveridade/" + tpTipo);
}

function findSeveridadeByIdHandler(idSeveridade) {
  return axios.get(api + "/severidade/" + idSeveridade);
}

function deleteSeveridadeByIdHandler(idSeveridade) {
  return axios.delete(api + "/severidade/" + idSeveridade);
}

function addSeveridadeHandler(values) {
  return axios.post(api + "/severidade", values);
}

function editSeveridadeHandler(values) {
  return axios.put(api + "/severidade", values);
}

export default {
  findAllSeveridade: findAllSeveridadeHandler,
  findSeveridadeByTipo: findSeveridadeByTipoHandler,
  findSeveridadeById: findSeveridadeByIdHandler,
  deleteSeveridadeById: deleteSeveridadeByIdHandler,
  addSeveridade: addSeveridadeHandler,
  editSeveridade: editSeveridadeHandler
};
