//@author Katia Miglioli
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import iconProfile from '@images/icon_profile.png';
import ExitToApp from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import { BrowserRouter, withRouter } from 'react-router-dom';
import '@styles/AppMainToolbar.css';
import { connect } from 'react-redux';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ItensMenu from '../MenuLateral/itensMenu';
import IconeMenuFechado from '@material-ui/icons/Toc';
import IconeMenuAberto from '@material-ui/icons/MoreVert';
import Authentication from '@resources/oauth/Authentication';
import Admin from '@resources/oauth/Admin';
import { Fab } from '@material-ui/core';

const drawerWidth = 270;
const drawerWidthAppBar = 50;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    display: 'inline',
    float: 'right',
    width: `calc(100% - ${drawerWidthAppBar}px)`,
    background: 'transparent',
    boxShadow: 'none',
    height: '60px'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: '20px',
    marginRight: 10,
    backgroundColor: '#FFFFFF',
    height: 30,
    width: 35,
    margin: theme.spacing.unit
  },
  hide: {
    marginLeft: '0px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: drawerWidthAppBar
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    zIndex: -1,
    minHeight: '60px !important'
  },
  content: {
    overflowY: 'auto',
    flexGrow: 1,
    backgroundColor: '#EBEBEB',
    display: 'flex',
    flexDirection: 'column'
  },
  titulo: {
    color: 'rgb(0, 0, 0, 0.6)',
    fontFamily: "'Roboto', sans-serif",
    fontSize: 24,
    fontWeight: '400 !important',
    display: 'flex'
  },
  tituloDescricao: {
    marginLeft: 8,
    fontWeight: '200 !important'
  },
  toolBarLeft: {
    float: 'left'
  },
  toolBarRight: {
    float: 'right'
  },
  divMenuIcone: {
    height: 18,
    width: 18
  },
  icone: {
    height: 18,
    width: 18,
    color: '#95989A'
  },
  avatar: {
    height: '30px',
    width: '35px',
    marginRight: 15,
    backgroundColor: 'white'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

class AppMainToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrawer = this.handleDrawer.bind(this);
    this.logout = this.logout.bind(this);
    this.redirecionarPerfil = this.redirecionarPerfil.bind(this);

    this.state = {
      key: ''
    };
  }

  handleDrawer() {
    this.props.itensState.open
      ? this.props.dispatch({
          type: 'CLOSE_MENU'
        })
      : this.props.dispatch({
          type: 'OPEN_MENU'
        });
  }

  logout() {
    Authentication.logout();

    Admin.resetPerfil();
    this.props.history.push('/login');
  }
  componentDidUpdate() {
    var selecionado =
      typeof this.props.itemSelecionado.item === 'string'
        ? this.props.itemSelecionado.item
        : this.props.itemSelecionado.item[0];
    if (selecionado !== 'selecionado') {
      ItensMenu.map(item => {
        if (item.id === selecionado) {
          this.anularItensMenusInternos(item);
        } else {
          item.selecionado = false;
          this.anularDemaisItensDoMenus(item.children);
        }
        return item;
      });
    } else {
      this.fecharTodosMenus(ItensMenu);
    }
  }
  fecharTodosMenus(ItensDoMenu) {
    ItensDoMenu.map(item => {
      item.selecionado = false;
      this.anularDemaisItensDoMenus(item.children);
      return item;
    });
  }
  anularDemaisItensDoMenus(children) {
    if (children !== undefined) {
      children.map(item => {
        item.selecionado = false;
        this.anularDemaisItensDoMenus(item.children);
        return item;
      });
    }
  }
  anularItensMenusInternos(item) {
    item.children.map(itemChildren => {
      if (itemChildren.route === undefined) {
        if (itemChildren.id !== this.props.itemSelecionado.item[1]) {
          itemChildren.selecionado = false;
        }
        this.anularItensMenusInternos(itemChildren);
      }
      return itemChildren;
    });
  }

  redirecionarPerfil() {
    this.props.history.push('/app/perfil');
    this.setState({
      key: Math.random()
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar
          position='absolute'
          className={classNames(classes.appBar, this.props.itensState.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.props.itensState.open} className={classes.toolBarLeft}>
            <Fab
              onClick={this.handleDrawer}
              className={classes.avatar}
              style={{ marginLeft: this.props.itensState.open ? 0 : 20 }}
            >
              <span
                id='buttonMenu'
                className={classNames(
                  classes.divMenuIcone,
                  this.props.itensState.open ? '' : 'menuOpen'
                )}
              >
                {this.props.itensState.open ? (
                  <IconeMenuAberto className={classes.icone} />
                ) : (
                  <IconeMenuFechado className={classes.icone} />
                )}
              </span>
            </Fab>
            <div variant='title' className={classes.titulo}>
              <MuiThemeProvider theme={theme}>
                <Typography className={classes.titulo} noWrap>
                  {this.props.nameToolbar.label}
                  <div className={classes.tituloDescricao}> {this.props.descricao.label}</div>
                </Typography>
              </MuiThemeProvider>
            </div>
          </Toolbar>
          <Toolbar className={classes.toolBarRight}>
            <IconButton onClick={this.redirecionarPerfil}>
              <img src={iconProfile} alt='Icone de perfil' />
            </IconButton>
            <IconButton onClick={this.logout}>
              <ExitToApp />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.props.itensState.open && classes.drawerPaperClose
            )
          }}
          open={this.props.itensState.open}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <BrowserRouter key={this.state.key}>{this.props.children}</BrowserRouter>
        </main>
      </div>
    );
  }
}

AppMainToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  itensState: state.menuOpen,
  nameToolbar: state.toolbar,
  descricao: state.descricao,
  itemSelecionado: state.itemSelecionado
});

let EnhacedComponent = withStyles(styles, { withTheme: true })(AppMainToolbar);
export default withRouter(connect(mapStateToProps)(EnhacedComponent));
