import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import iconeClip from '@images/icone_chip.png';

const styles = theme => ({
  chip: {
    backgroundColor: '#EAEAEA',
    borderRadius: 10,
    padding: 10,
    display: 'inline-flex',
    height: 17,
    marginRight: 10,
    position: 'relative',
    marginTop: 5
  },
  chipContent: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  chipFiltro: {
    fontSize: 13,
    color: '#727272',
    marginLeft: '2px',
    whiteSpace: 'nowrap',
    display: 'flex'
  }
});

/**
 * Componente Chip
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React que representa o Chip
 */
const Chip = props => {
  const { classes } = props;
  const stylesImg = {
    cursor: !props.tpPerfil ? 'pointer' : 'default',
    marginLeft: '5px',
    position: 'relative',
    bottom: props.marginBottomImg ? '4px' : 0
  };

  return (
    <div className={classes.chip}>
      <div className={classes.chipContent}>
        <div className={classes.chipFiltro} onClick={props.onClickLabel}>
          {props.text}
        </div>

        <div>
          {!props.hiddenClose && (
            <img src={iconeClip} alt=' ' onClick={props.onRemove} style={stylesImg} />
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Chip);
