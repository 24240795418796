export default {
  gerente: {
    titulo: "Manager",
    nome: "Name",
    email: "Email",
    telefone: "Telephone",
    situacao: "Situation",
    ativo: "Active",
    inativo: "Inactive",
    campoObrigatorio: "Required field",
    identificacao: "Identification",
    cadastro: "Manager registration",
    salvar: "Save",
    cancelar: "Cancel",
    emailInvalido: "Invalid e-mail",
    naoFoiPossivelInserir: "Could not insert manager",
    salvando: "Saving",
    atualizando: "Updating",
    cadastradoComSucesso: "Successfully enrolled manager",
    editadoComSucesso: "Successfully edited manager",
    falhaAoCarregar: "Failed to load technical",
    naoFoiPossivelEditar: "Could not edit manager",
    emailDuplicado: "This email is already in use",
    numeroMaximoGerente: "User limit for this plan has been reached",
    falhaAoExcluirGerente: "Failed to delete manager",
    gerenteExcluidoComSucesso: "Successfully deleted manager",
    excluir: "Delete",
    excluindo: "Deleting",
    desejaExcluir: "Do you want to delete?",
    nao: "Not",
    telefoneInvalido: "Invalid phone number"
  }
};
