import React, { useState, useEffect } from 'react';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import { style } from '../AcompanhamentoProdutor.styles';
import Button from '@material-ui/core/Button';
import Severidade from '@resources/Severidade';
import { Formik } from 'formik';
import * as Yup from 'yup';
import formatMessage from '../i18n/formatMessage';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SeveridadeEnum from '@resources/SeveridadeEnum';

const iconeAdd = require('@images/adicionar_talhao.png');

/**
 * Componente de cadastro de pragas
 */
function Pragas(props) {
  const { classes, valueEditar, onSave, plantio } = props;
  const [severidadeList, setSeveridadeList] = useState([]);
  const [severidade, setSeveridade] = useState(null);
  const [keyUpdate, setKeyUpdate] = useState(0);

  useEffect(() => {
    findSeveridadeList();
  }, []);

  return (
    <Formik
      initialValues={{
        severidade: valueEditar.severidade,
        nrQuantidade: valueEditar.nrQuantidade
      }}
      validateOnBlur
      validateOnChange={false}
      enableReinitialize={true}
      onSubmit={saveAndReset}
      validationSchema={Yup.object().shape({
        severidade: Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio')),
        nrQuantidade: Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio'))
      })}
      render={({ values, handleSubmit, errors, touched, setFieldValue, handleBlur }) => (
        <div style={{ width: '100%' }}>
          <Grid container spacing={16}>
            <Grid item xs={3}>
              <Autocomplete
                itens={severidadeList}
                campoOp={'nmSeveridade'}
                campoChave={'idSeveridade'}
                label={formatMessage('acompanhamentoProdutor.pragas')}
                name='severidade'
                error={errors.severidade && touched.severidade}
                helperText={
                  errors.severidade &&
                  touched.severidade &&
                  formatMessage('acompanhamentoProdutor.campoObrigatorio')
                }
                onChangeOwn={nmSeveridade => changeSeveridade(nmSeveridade, setFieldValue)}
                onChangeAutoComplete={() => findSeveridadeList()}
                value={onChangeValueSeveridade(severidade)}
                key={keyUpdate}
                valueAutoComplete={onChangeValueSeveridade(severidade)}
                valueSelect={onChangeValueSeveridade(severidade).nmSeveridade}
              />
            </Grid>

            <Grid item xs={2}>
              <TextFieldWithMask
                tipoMascara={3}
                numberDecimalScale={0}
                showThousandSeparator={true}
                id='nrQuantidade'
                name='nrQuantidade'
                onChange={value => setFieldValue('nrQuantidade', value.target.value)}
                onBlur={handleBlur}
                value={values.nrQuantidade}
                label={formatMessage('acompanhamentoProdutor.nrQuantidade')}
                className={classes.textField}
                InputLabelProps={{
                  className: classes.font
                }}
                error={errors.nrQuantidade && touched.nrQuantidade}
                helperText={touched.nrQuantidade ? errors.nrQuantidade : ''}
              />
            </Grid>

            <Grid item xs={2}>
              <Button className={classes.button} id='button' onClick={handleSubmit}>
                <img src={iconeAdd} alt=' ' />
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    />
  );

  /**
   * Save and trata retorno de sucesso ou erro
   */
  function saveAndReset(values, {resetForm}){
    const sucess = onSave(values)

    if (sucess) {
      resetForm({
        severidade: '',
        nrQuantidade: ''
      });

      setSeveridade(null);
      setKeyUpdate(Math.random());
    }


  }

  /**
   * Ação ao alterar autocomplete de severidade
   */
  function onChangeValueSeveridade(data) {
    if (!data || data.nmSeveridade === undefined) {
      return '';
    }

    return data;
  }

  /**
   * Ação ao clicar no auto complete de severidade
   */
  function changeSeveridade(nmSeveridade, setFieldValue) {
    setFieldValue('severidade', nmSeveridade);
    setSeveridade(nmSeveridade);
    setKeyUpdate(Math.random());
  }

  /**
   * Busca a lista de severidades
   */
  function findSeveridadeList() {
    if (plantio) {
      Severidade.findSeveridadeByTipo(SeveridadeEnum.PRAGAS)
        .then(query => {
          let dataFilter = [];
          query.data.forEach(doc => {
            if (doc.cultura.idCultura === plantio.cultura.idCultura && doc.stSeveridade === 1) {
              dataFilter.push(doc);
            }
          });

          setSeveridadeList(dataFilter);
        })
        .catch(() => {
          setSeveridadeList([]);
        });
    }
  }
}

export default withStyles(style)(Pragas);
