import { createMuiTheme } from '@material-ui/core/styles';

const style = theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'scroll',
    paddingBottom: 15
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    cursor: 'pointer',
    marginTop: -5
  },
  allCard: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    overflowY: 'scroll',
    paddingBottom: 15
  },
  iconEdit: {
    width: 18,
    height: 18
  },
  cardEdit: {
    padding: '10px 0 00px 0px'
  },
  boxShadow: {
    borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
    marginTop: 40,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 20%)'
  },
  cardButtom: {
    backgroundColor: '#BF4B20',
    height: 33,
    width: 230,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    display: 'flex',
    borderRadius: 5,
    fontWeight: 400
  },
  cardOutsideButtom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -45
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 80px)'
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    marginTop: 45,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 95px)',
    marginBottom: 15,
    maxHeight: 350
  },
  button: {
    marginRight: 10,
    width: 35,
    minWidth: 10,
    marginTop: 1,
    height: 30,
    padding: 0
  },
  cardButton: {
    display: 'flex',
    marginTop: 16
  },
  iconButton: {
    height: 30,
    width: 30,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  cardMedidaCorretiva: {
    color: '#000000',
    marginTop: 10
  },
  labelTitle: {
    paddingBottom: 10,
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 300
  },
  checkMedida: {
    backgroundColor: '#00622B',
    height: 12,
    width: 12,
    borderRadius: '50%',
    marginRight: 12
  },
  cardCheckLabelMedidas: {
    display: 'flex',
    alignItems: 'center',
    height: 30
  },
  labelInfo: {
    fontSize: 16,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.87)'
  },
  cardCertificacao: {
    height: 70
  },
  switchAtivo: {
    color: '#000000',
    cursor: 'pointer'
  },
  chip: {
    backgroundColor: '#E1E1E1',
    border: 'none',
    borderRadius: 10,
    fontSize: 12,
    color: '#676767',
    marginRight: 5,
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 0
  },
  cardChips: {
    display: 'flex',
    marginTop: 10,
    minHeight: 50,
    width: 100
  },
  cardWithScroll: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  buttonAgendar: {
    height: 35,
    width: 100,
    backgroundColor: '#BF4B20',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginTop: 20,
    cursor: 'pointer'
  },
  labelAgendar: {
    color: 'rgb(0, 0, 0, 0.8)',
    fontSize: 14,
    fontWeight: 400
  },
  cardAgendar: {
    marginTop: 55,
    display: 'flex',
    flexDirection: 'column'
  },
  cardScroll: {
    width: '100%',
    overflowY: 'scroll',
    height: 'calc(100vh - 95px)'
  },
  iconFile: {
    height: 85,
    width: 85,
    color: 'rgb(112, 112, 112, 0.7)'
  },
  iconFolder: {
    width: 84,
    height: 84,
    color: 'rgb(112, 112, 112, 0.5)'
  },
  cardFolder: {
    marginTop: 40,
    width: '100%'
  },
  inputFile: {
    display: 'none',
    width: 0,
    height: 0
  },
  cardUploadClick: {
    width: 85,
    cursor: 'pointer'
  },
  screenAgendar: {
    width: '100%',
    flex: 1,
    height: '100%',
    display: 'flex'
  },
  textField: {
    width: '100%',
    height: 70
  },
  font: {
    fontSize: 14,
    width: '100%',
    height: 70
  },
  fieldTalhao: {
    fontSize: 11,
    color: 'rgb(0, 0, 0, 0.5)',
    marginTop: 25
  },
  cardEditarNaoConformidade: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.35
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { style, theme };
