export default {
  cronograma: {
    culturas: 'Cultures',
    sazonalidade: 'Seasonality',
    naoConformidade: 'Non-compliance',
    dosePorTanque: 'Dose per tank for',
    alqueires: 'bushels',
    aplicarNosTalhoes: 'Apply to the plots:',
    paraDia: 'for day',
    recomendacao: 'Application Recommendation',
    aplicacao: 'Application performed',
    acre: 'Acre',
    hectare: 'Hectárea',
    alqueire: 'Bushel',
    areaDeCultivo: 'Area of cultivation',
    cultura: 'Culture',
    plantio: 'Planting',
    colheita: 'Harvest',
    alertasFitossanitarios: 'Phytosanitary Alerts',
    monitoramento: 'Pest monitoring',
    hoje: 'Today',
    talhoes: 'Plots',
    registroFotografico: 'Photographic register',
    registroClimatico: 'Climate record',
    aplicacaoProdutoProibido: 'Prohibited product application',
    ocultarAtividades: 'Hide planting activities',
    mostrarAtividades: 'Show planting activities',
    auditoria: 'Audit'
  }
};
