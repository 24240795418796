export default {
  mapandvi: {
    filtro: 'Filtrar por produtor',
    campoObrigatorio: 'Campo obrigatório',
    produtor: 'Produtor',
    talhao: 'Talhão',
    area: 'Área',
    produtorDuplicado: 'Produtor duplicado',
    talhaoDuplicado: 'Talhão duplicado',
    mapa: 'Mapas',
    mapandvi: 'Mapas NDVI',
    falhaBuscarImagens: 'Falha ao buscar as Imagens',
    dia: 'Data',
    imagem: 'Imagem',
    formatoData: 'DD/MM/YYYY',
    talhoesSemImagemProdutor: 'Os talhões deste produtor não possuem imagens.',
    talhaoSemImagem: 'Este talhão não possui imagem.'
  }
};
