export default {
  financeiro: {
    financeiro: 'Financial',
    razaoSocial: 'Social name',
    nomeFantasia: 'Fantasy name',
    cnpj: 'CNPJ',
    telefone: 'Telephone',
    email: 'E-mail',
    planosDeAcesso: 'Access plan',
    produtoresAtivos: 'Active producers',
    motivo: 'Reason for inactivation',
    dataInativacao: 'Inactivation date',
    dataCadastro: 'Registration date',
    ativo: 'Active',
    inativo: 'Inactive',
    endereco: 'Address',
    contatosAdicionais: 'Additional contacts',
    usuarios: 'User',
    identificacao: 'Identification',
    cep: 'Zip code',
    estado: 'Country',
    cidade: 'City',
    rua: 'Street',
    numero: 'Number',
    complemento: 'Complement',
    bairro: 'District',
    departamento: 'Department',
    nome: 'Name',
    perfil: 'Profile',
    statusConta: 'Account status',
    situacao: 'Situation',
    identificacaoFinanceiro: 'Identification',
    cadastroFinanceiro: 'Customer base',
    gravar: 'Save',
    salvar: 'Save',
    cancelar: 'Cancel',
    reenviarCodigo: 'Resend activation email',
    principal: 'Main',
    entregas: 'Deliveries',
    administrativo: 'Administrative',
    gerencia: 'Management',
    ti: 'T.I.',
    tecnico: 'Technician',
    revenda: 'Resale',
    campoObrigatorio: 'Required field',
    emailInvalido: 'Invalid Email',
    carregando: 'Loading',
    financeiroCadastrado: 'Customer successfully registered',
    financeiroEditado: 'Customer updated successfully',
    sucesso: 'Success',
    erro: 'Error',
    usuarioJaCadastrado: 'User already registered',
    registrado: 'Registered',
    confirmado: 'Confirmed',
    desabilitado: 'Disabled',
    admin: 'Administrator',
    salvando: 'saving',
    atualizando: 'Updating',
    usuarioJaCadastradoTabela:
      'This user has already been added to the user list',
    naoFoiPossivelInserirUsuario: 'Unable to insert user',
    naoFoiPossivelInserirEditar: 'Unable to update user',
    erroCadastroUsuario: 'Error registering user',
    usuarioCadastradoComSucesso: 'User successfully registered',
    financeiroCadastradoComSucesso: 'Customer successfully registered',
    usuariosNaoCadastrados:
      'Sorry, we were unable to register the following users:',
    financeiroEditadoComSucesso: 'Customer edited successfully',
    preRegistro: 'before registration',
    produtor: 'Producer',
    falhaAoCarregarFinanceiro: 'Failed to load financial',
    numeroMaximoUsuarios: 'User limit for this plan has been reached',
    excluir: 'Delete',
    excluindo: 'Deleting',
    desejaExcluir: 'Do you want to delete?',
    nao: 'Not',
    financeiroExcluidoComSucesso: 'Customer deleted successfully',
    falhaAoExcluirFinanceiro: 'Failed to delete client',
    usuarioExcluidoComSucesso: 'User deleted successfully',
    plano: 'Plan',
    telefoneInvalido: 'Invalid phone number',
    cnpjInvalido: 'Invalid CNPJ',
    cepInvalido: 'Invalid CEP',
    desejaExcluirEndereco: 'Do you want to delete address?',
    desejaExcluirContatosAdicionais:
      'Do you want to delete additional contact?',
    erroAoReenviarEmail: 'Failed to resend email',
    codigoEnviadoComSucesso: 'Code successfully sent',
    pais: 'Country',
    cadastrarPais: 'First sign up country',
    cnpjDuplicado: 'CNPJ already registered',
    erroAoEditar: 'There was an error editing client',
    erroBuscarFinanceiro: 'There was an error fetching customers',
    emailDuplicado: 'Email is already registered',
    valorImplantacao: 'Deployment value',
    valorLicenciamento: 'Licensing Value',
    terminoVigencia: 'Term of validity',
    inicioVigencia: 'Start date',
    vlMensalidade: 'Monthly fee',
    recorencia: 'Recurrence',
    recorrenciaRemovidoComSucesso: 'Recurrence successfully removed',
    falhaAoCadastrarFinanceiro: 'Failed to edit financial',
    dataRecorrenciaInvalida: 'Recurrence date unavailable',
    nrProdutor: 'Number of producers',
    nrTecnico: 'Number of technicians',
    ilimitado: 'Unlimited',
    quantidadeMinima: 'Minimum quantity is 1',
    dataMinima: 'Invalid date',
    confirmar: 'Confirm',
    salvarFinanceiroSair:
      'There is unsaved information, do you want to save the financial?',
    descartar: 'To discard',
    nrNdvi: 'NDVI hectares area',
    habilitarNdvi: 'Habilitar NDVI'
  }
};
