import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllMarcaHandler() {
  return axios.get(api + '/marca');
}

function findAllMarcaByStatusHandler(stMarca) {
  return axios.get(api + '/marca?stMarca=' + stMarca);
}

function findMarcaById(idMarca) {
  return axios.get(api + '/marca/' + idMarca);
}

function deleteMarcaByIdHandler(idMarca) {
  return axios.delete(api + '/marca/' + idMarca);
}

function addMarcaHandler(values) {
  return axios.post(api + '/marca', values);
}

function editMarcaHandler(values) {
  return axios.put(api + '/marca', values);
}

function findMarcaByTpPesticidaHandler(tpPesticida) {
  return axios.get(api + '/marca/tipo/' + tpPesticida);
}

export default {
  findAllMarca: findAllMarcaHandler,
  findAllMarcaByStatus: findAllMarcaByStatusHandler,
  findMarcaById: findMarcaById,
  deleteMarcaById: deleteMarcaByIdHandler,
  addMarca: addMarcaHandler,
  editMarca: editMarcaHandler,
  findMarcaByTpPesticida: findMarcaByTpPesticidaHandler
};
