import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditarEnderecos from './EditarEnderecos';
import SwipeableViews from 'react-swipeable-views';
import { style } from './../Clientes.styles';
import formatMessage from '../i18n/formatMessage';
import CardTitle from '@components/CardTitle/CardTitle';

class Enderecos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      municipios: [],
      estados: [],
      keyMunicipio: '',
      keyEstado: '',
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      listEnderecos: [],
      statusEdit: false,
      headEnderecos: [
        {
          field: 'nmIdentificacao',
          headerName: formatMessage('clientes.identificacao'),
          sort: 'asc',
          pinned: 'left',
          col: 4,
          suppressSizeToFit: true
        },
        {
          valueGetter: args => args.node.data.municipio.nmMunicipio + ' / ' + args.node.data.uf.nmUf,
          headerName: formatMessage('clientes.cidade') + ' - ' + formatMessage('clientes.estado'),
          pinned: 'left',
          suppressSizeToFit: true,
          col: 4
        },
        {
          valueGetter: args => args.node.data.dsRua + ', ' + args.node.data.nrEndereco,
          headerName: formatMessage('clientes.endereco'),
          col: 4,
          pinned: 'left',
          suppressSizeToFit: true,
          cellStyle: {
            borderRight: '0.5px solid rgb(226, 226, 226) !important '
          }
        },
        {
          field: 'nmBairro',
          headerName: formatMessage('clientes.bairro'),
          col: 5,
          suppressSizeToFit: true
        },
        {
          field: 'nrCep',
          headerName: formatMessage('clientes.cep'),
          suppressSizeToFit: true,
          col: 3
        },
        {
          field: 'dsComplemento',
          headerName: formatMessage('clientes.complemento'),
          col: 4
        }
      ]
    };

    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderIfIndex = this.renderIfIndex.bind(this);
  }

  clickExcluir() {
    const position = this.props.dataAppSync.indexOf(this.state.itemSelect);
    this.props.onClickDeleteEndereco(position);
  }

  clickEditar() {
    this.setState({
      index: 1,
      openModal: true,
      statusEdit: true,
      keyEstado: Math.random(),
      keyMunicipio: Math.random()
    });

    this.props.clickAdicionarEndereco(this.state.itemSelect);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  clickAdicionar() {
    this.setState({
      index: 1,
      openModal: true,
      statusEdit: false,
      keyEstado: Math.random(),
      keyMunicipio: Math.random()
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  renderIfIndex(index, Component) {
    return index === this.state.index ? (
      <div key={index} style={{ height: '100%' }}>
        {Component}
      </div>
    ) : (
      <div key={index} />
    );
  }

  handleChangeIndex(index) {
    this.setState({
      index: index
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <SwipeableViews
        className={classes.swipeable}
        index={this.state.index}
        onChangeIndex={this.handleChangeIndex}
        ignoreNativeScroll={true}
        containerStyle={{ height: '100%' }}
        slideStyle={{ overflow: 'hidden' }}
      >
        {[
          this.renderIfIndex(
            0,
            <div key={0} className={classes.card}>
              <CardTitle
                containerStyle={{ marginBottom: -37 }}
                title={formatMessage('clientes.endereco')}
              />

              <GroupButtonGrid
                showAdd={true}
                showEdit={true}
                showDelete={true}
                disableAdd={!this.props.status}
                disableEdit={this.props.status ? this.state.disabledEdit : true}
                disableDelete={this.props.status ? this.state.disabledEdit : true}
                onClickAdd={() => {
                  this.setState({
                    index: 1
                  });
                  this.props.clickAdicionarEndereco();
                }}
                onClickEdit={this.clickEditar}
                onClickDelete={this.clickExcluir}
              />

              <DataTable
                keyData={this.props.keyData}
                usePaddings={false}
                data={this.props.dataAppSync}
                columns={this.state.headEnderecos}
                selectChange={this.selectChange}
                showPagination={false}
              />
            </div>
          ),
          this.renderIfIndex(
            1,
            <div
              key={1}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
            >
              <EditarEnderecos
                selectEnderecos={this.props.selectEnderecos}
                onClickCancelar={() => {
                  this.setState({
                    index: 0
                  });
                  this.props.onClickCancelar();
                }}
                data={this.props.dataAppSync}
                status={this.props.status}
                onClickEndereco={(values, position) => {
                  this.setState({
                    index: 0
                  });
                  this.props.onClickEndereco(values, position);
                }}
                itensIdentificacao={this.props.itensIdentificacao}
              />
            </div>
          )
        ]}
      </SwipeableViews>
    );
  }
}

Enderecos.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(Enderecos);
export default withRouter(connect(mapStateToProps)(enhaced));
