import { getUserLang } from '@utils/localeUtils';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

class LocalizedDateUtils extends MomentUtils {
        getWeekdays() {
            return [0, 1, 2, 3, 4, 5, 6].map(dayOfWeek =>
                this.moment()
                .weekday(dayOfWeek)
                .format('ddd').substring(0,1)
            );
        }
        getDatePickerHeaderText(date) {
            switch(getUserLang()) {
                case 'pt-BR':
                    return date.format('DD/MM/YYYY');
                case 'en-US':
                    return date.format('MMM DD,YYYY');
                case 'es-ES':
                    return date.format('DD-MM-YYYY');
                default:
            }
        }
    }
export default LocalizedDateUtils;
