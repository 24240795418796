export default {
  raca: {
    racas: 'Carrera',
    descricao: 'Descripción',
    desejaExcluir: '¿Quieres eliminar la carrera?',
    nao: 'No',
    excluir: 'Excluir',
    excluindo: 'Excluyendo',
    falhaAoExcluirRaca: 'Error al eliminar la carrera',
    racaRemovidoComSucesso: 'Carrera eliminada con éxito',
    racaCadastradaComSucesso: 'Carrera registrada exitosa',
    racaEditadaComSucesso: 'Carrera editada con éxito',
    falhaAoEditarRaca: 'Error al editar la carrera',
    falhaAoCadastrarRaca: 'No se pudo registrar la carrera',
    falhaAoCarregarRaca: 'Error al cargar la carrera',
    especie: 'Especie',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Guardar',
    atualizar: 'Actualizar',
    atualizando: 'Actualización',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    ativo: 'Activo',
    situacao: 'Situación',
    inativo: 'Inactivo'
  }
};
