export default {
    telaLogin: {
        login: 'Entrar',
        esqueceuSenha: 'Esqueceu sua senha?',
        enviar: 'Enviar',
        campoObrigatorio: 'Campo obrigatório',
        emailInvalido: 'Email inválido',
        senhaMinimo: 'A senha deve ter no mínimo 6 caracteres',
        retornarLogin: 'Retornar para o Login',
        senha: 'Senha *',
        usuarioIncorreto: 'Usuário ou senha incorretos',
        falhaLogin: 'Falha ao realizar login',
        falhaLoginMessage: 'Ocorreu uma falha ao realizar o login',
        emailEnviado: 'Foi enviado um E-mail com um link para você trocar sua senha',
        emailEnviadoMessage: 'E-mail enviado para ',
        usuarioNaoEncontrado: 'Usuário não encontrado',
        falhaRecuperarSenha: 'Falha ao recuperar senha',
        emailReenviado: 'Já foi enviado um email de recuperação de senha',
        falhaRecuperarSenhaMensagem: 'Ocorreu uma falha ao recuperar a senha',
        falhaUsuarioInativo: 'Usuário está inativo',
        falhaClienteInativo: 'Cliente está inativo',
        usuarioNaoConfirmado: 'Usuário não confirmado'
    }
}