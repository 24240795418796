import { createMuiTheme } from '@material-ui/core/styles';

const style = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  cardAll: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'scroll'
  },
  cardDataTable: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  topDiv: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  identif_Div: {
    flex: 1,
    position: 'relative',
    maxHeight: '180px',
    minHeight: '170px'
  },
  font: {
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fontMensalidade: {
    fontSize: 14,
    width: '85%',
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textField: {
    width: '100%',
    position: 'relative'
  },
  cardCadastro: {
    position: 'relative',
    marginBottom: '2px',
    marginTop: 15,
    height: 70
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { style, theme };
