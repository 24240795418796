import URL from './URL';

const axios = require('axios');
const admin = URL.ADM;

function findAllPais() {
  return axios.get(admin + '/pais');
}

function findAllEstados() {
  return axios.get(admin + '/uf');
}

function findAllMunicipios() {
  return axios.get(admin + '/municipio');
}

function updateMunicipio(values) {
  return axios.put(admin + '/municipio', values);
}

function findMunicipiosByUf(idUf) {
  return axios.get(admin + '/municipio/uf/' + idUf);
}

function findMunicipioById(idMunicipio) {
  return axios.get(admin + '/municipio/' + idMunicipio);
}

function findAllMunicipiosConcatenado() {
  return axios.get(admin + '/municipio/uf-concatenado');
}

export default {
  findAllPais,
  findAllEstados,
  findAllMunicipios,
  findMunicipioById,
  findMunicipiosByUf,
  findAllMunicipiosConcatenado,
  updateMunicipio
};
