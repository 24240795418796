import { createMuiTheme } from '@material-ui/core/styles';

const style = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  button: {
    marginRight: 10,
    width: 35,
    minWidth: 10,
    marginTop: 1,
    height: 30,
    padding: 0
  },
  cardButton: {
    display: 'flex',
    marginTop: 10
  },
  iconButton: {
    height: 30,
    width: 30,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  container: {
    width: '100%',
    maxHeight: 'calc(100vh - 95px)',
    overflowY: 'auto'
  },
  grid: {
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 496px)',
    minHeight: 250,
    marginTop: 15
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        whiteSpace: 'nowrap',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.35
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      contained: {
        height: '46px',
        width: '100px',
        marginLeft: '15px'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        }
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { style, theme };
