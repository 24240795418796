export default {
  homeopatico: {
    homeopatico: 'Productos homeopáticos',
    cadastroDeHomeopatico: 'Registro de productos homeopático',
    homeopaticoRemovidoComSucesso: 'Productos homeopático quitado con éxito',
    falhaAoExcluirHomeopatico: 'Fallo la excluir productos homeopático',
    desejaExcluir: '¿Desea eliminar el productos homeopático seleccionada?',
    identificacaoDaHomeopatico: 'Identificación',
    homeopaticoCadastradoComSucesso: 'Productos homeopático registrada con éxito',
    homeopaticoEditadoComSucesso: 'Productos homeopático editado con éxito',
    falhaAoEditarHomeopatico: 'Falla al editar productos homeopático',
    falhaAoCadastrarHomeopatico: 'Error al editar productos homeopático',
    falhaAoCarregarHomeopatico: 'Error al cargar productos homeopático'
  }
};
