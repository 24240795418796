import React, { useState, useEffect } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { MuiThemeProvider } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import CardTitle from '@components/CardTitle/CardTitle';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import formatMessage from './../i18n/formatMessage';
import { formatDate, formatoDataIntercionalizado } from '@utils/languageUtils';
import EditarAgencia from './EditarAgencia';
import { connect } from 'react-redux';

/**
 * Componente que representa a listagem de Agencia dentro do produtor
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const Agencia = props => {
  const { classes, tpPerfil, onDelete, changeFAB, theme, keyAgencia } = props;
  const [itemSelect, setItemSelect] = useState(null);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [data, setData] = useState(props.data.filter(item => item.stRegistro !== 2));
  const [index, setIndex] = useState(0);
  const headDataTable = [
    {
      field: 'agencia.nmAgencia',
      headerName: formatMessage('produtores.nome'),
      col: 2
    },
    {
      field: 'agencia.cdAgencia',
      headerName: formatMessage('produtores.codigo'),
      col: 2
    },
    {
      field: 'agencia.sgUf',
      headerName: formatMessage('produtores.estado'),
      col: 2
    },
    {
      field: 'agencia.nmMunicipio',
      headerName: formatMessage('produtores.municipio'),
      col: 2
    },
    {
      valueGetter: args => formatDate(args.node.data.dtInicio, formatoDataIntercionalizado()),
      headerName: formatMessage('produtores.periodoInicial'),
      col: 2
    },
    {
      valueGetter: args => formatDate(args.node.data.dtTermino, formatoDataIntercionalizado()),
      headerName: formatMessage('produtores.periodoFinal'),
      col: 2
    }
  ];

  useEffect(() => {
    updateList();
  }, [props.data]);

  /**
   * Executa quando é clickado numa linha da lista
   *
   * @param {Object} selected - Linha selecionada
   */
  function selectChange(selected) {
    if (selected) {
      setItemSelect(selected);
      setDisabledButtons(false);
    } else {
      setItemSelect(null);
      setDisabledButtons(true);
    }
  }

  function updateList() {
    setData(props.data.filter(item => item.stRegistro !== 2));
  }

  function onNew(values) {
    props.onNew(values);
    setIndex(0);
    selectChange(null);
  }

  function onUpdate(values) {
    props.onUpdate(values);
    setIndex(0);
    selectChange(null);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <SwipeableViewsOwn index={index} onChangeIndex={index => setIndex(index)}>
          {[
            <div className={classes.grid} key={0}>
              <CardTitle
                containerStyle={{ marginBottom: tpPerfil ? 0 : 'none' }}
                title={formatMessage('produtores.agencia')}
              />

              {!tpPerfil && (
                <GroupButtonGrid
                  hiddenMargin
                  withoutMargin
                  showEdit
                  showDelete
                  showAdd
                  disableEdit={disabledButtons}
                  disableDelete={disabledButtons}
                  onClickEdit={() => {
                    setIndex(1);
                    changeFAB();
                  }}
                  onClickDelete={() => itemSelect && onDelete(itemSelect, updateList)}
                  onClickAdd={() => {
                    setItemSelect(null)
                    setIndex(1);
                    changeFAB();
                  }}
                />
              )}

              {index === 0 && (
                <DataTable
                  key={keyAgencia}
                  data={data}
                  columns={headDataTable}
                  selectChange={selectChange}
                  showPagination={false}
                  borderRadius={false}
                  noMargin
                />
              )}
            </div>,

            <EditarAgencia
              dataAgenciaList={data}
              agenciaList={props.agenciaList}
              classes={classes}
              theme={theme}
              infoUsuario={props.infoUsuario}
              tpPerfilTecnico={props.tpPerfilTecnico}
              key={1}
              onCancel={() => {
                setIndex(0);
                changeFAB();
                setItemSelect(null)
                setDisabledButtons(true);
              }}
              itemSelectAgencia={itemSelect}
              ambiental={itemSelect}
              onNew={onNew}
              onUpdate={onUpdate}
            />
          ]}
        </SwipeableViewsOwn>
      </div>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(Agencia);
