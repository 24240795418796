export default {
  relatorio: {
    relatorio: 'Report',
    relatorioListaProdutores: 'List of producers',
    regiao: 'Region',
    municipio: 'City',
    ativo: 'Active',
    inativo: 'Inactive',
    carregando: 'Loading...',
    gerandoRelatorio: 'Generating report ...',
    falhaAoGerarRelatorio: 'Failed to generate report',
    tecnicoResponsavel: 'Responsible technician',
    produtor: 'Producer',
    situacao: 'Situation',
    uf: 'UF',
    decrescente: 'Decreasing',
    crescente: 'Crescent'
  }
};
