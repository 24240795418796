import React from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import { style } from './../AcompanhamentoProdutor.styles';
import { withStyles } from '@material-ui/core/styles';
import formatMessage from './../i18n/formatMessage';
import InsumoEnum from './../enumerations/InsumosEnum';
import { getUserLang } from '@utils/localeUtils';
import UnidadeMedidaEnum from './../enumerations/UnidadeMedidaEnum';
import InsumosEnum from '@resources/InsumosEnum';

/**
 * Lista os produtos na descrição de cada acompanhamento
 */
function ProdutoDescricao(props) {
  const { produtoList, stProdutor, classes } = props;

  return (
    <div>
      {produtoList.map((prod, key) => (
        <div key={key} className={classes.cardAlertas}>
          <div className={classes.fontLabelCardsCronograma}>
            {`${prod.cont} - ${prod.insumo.nmInsumo}`}
          </div>
          {stProdutor && (
            <div style={{ display: 'flex' }}>
              <Tooltip title={InsumoEnum[prod.insumo.tpInsumo].name}>
                <Typography className={classes.listaProdutos} noWrap={true}>
                  {`${formatMessage('acompanhamentoProdutor.tipo')}:
                    ${InsumoEnum[prod.insumo.tpInsumo].name}`}
                </Typography>
              </Tooltip>
              <Typography className={classes.listaProdutos}>
                {`${formatMessage('acompanhamentoProdutor.marca')}: ${prod.insumo.marca.nmMarca}`}
              </Typography>
            </div>
          )}
          <div className={classes.listaProdutos}>
            {labelDose(prod.insumo.tpInsumo, prod.tpUnidadeMedida, prod.vlDose)}
          </div>
        </div>
      ))}
    </div>
  );


  /**
   * Monta o label de dose da descrição do acompanhamento
   * @param {*} tpInsumo - tipo do insumo
   * @param {*} tpUnidadeMedida - unidade de medida
   * @param {*} vlDose - dose
   * @returns
   */
   function labelDose(tpInsumo, tpUnidadeMedida, vlDose) {
    if (tpInsumo === InsumosEnum.FERTILIZANTE_ORGANICO || tpInsumo === InsumosEnum.FERTILIZANTE_QUIMICO) {
      const dsUnMedida = UnidadeMedidaEnum.find((unMedida) => unMedida.id === tpUnidadeMedida)
      return `${formatMessage('acompanhamentoProdutor.nrQuantidade')}:
              ${Intl.NumberFormat(getUserLang()).format(vlDose)}/
              ${dsUnMedida.name}`;
    } else {
      return `${formatMessage('acompanhamentoProdutor.dose')}:
              ${Intl.NumberFormat(getUserLang()).format(vlDose)} L/kg `;
    }
  }
}

export default withStyles(style)(ProdutoDescricao);
