import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import createRoutes from './views/routes';
import React from 'react';
import { Provider } from 'react-redux';
import Store from './states/store';
import { createStore } from 'redux';
import { unregister } from './registerServiceWorker';

unregister();

const routes = createRoutes();
const store = createStore(Store);
ReactDOM.render(<Provider store={store}>{routes}</Provider>, document.getElementById('root'));

registerServiceWorker();
