export default {
  regiao: {
    regiao: 'Região',
    regioes: 'Regiões',
    desejaExcluir: 'Deseja excluir região?',
    excluir: 'Excluir',
    nao: 'Não',
    excluindo: 'Excluindo',
    regiaoRemovidoComSucesso: 'Região excluida com sucesso',
    falhaAoExcluirRegiao: 'Falha ao excluir região',
    cadastroDeRegiao: 'Cadastro de região',
    falhaAoCarregarRegiao: 'Falha ao carregar região',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    regiaoCadastradaComSucesso: 'Região cadastrada com sucesso',
    falhaAoCadastrarRegiao: 'Falha ao cadastrar região',
    regiaoEditadaComSucesso: 'Região editada com sucesso',
    falhaAoEditarRegiao: 'Falha ao editar região',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    descricao: 'Descrição',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    municipio: 'Município',
    municipioRemovidoComSucesso: 'Município removido com sucesso',
    estado: 'Estado',
    confirmar: 'Confirmar',
    municipioJaCadastrado: 'Município já está cadastrado',
    municipiosJáRelacinados: 'Os seguintes municípios já estão relacionados em outras regiões:'
  }
};
