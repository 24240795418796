import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Loading from '@components/Loading/Loading';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Financeiro from '@resources/Financeiro';
import Cliente from '@resources/Clientes';
import Recorencia from './Recorencia';
import * as moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import DataTable from '@components/DataTable/DataTable';
import Card from '@components/Card/Card';
import SwipeableViews from 'react-swipeable-views';
import { Prompt } from 'react-router-dom';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});

const style = theme => ({
  textField: {
    width: '100%',
    height: '70px'
  },
  font: {
    height: 15,
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    textOverflow: 'ellipsis'
  },
  form: {
    width: '100%'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  fontMensalidade: {
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  }
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

class EditarFinanceiro extends Component {
  constructor(props) {
    super(props);
    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const cadastroFinanceiro = messagesLinguage['financeiro.cadastroFinanceiro'];
    const razaoSocial = messagesLinguage['financeiro.razaoSocial'];
    const nomeFantasia = messagesLinguage['financeiro.nomeFantasia'];
    const cnpj = messagesLinguage['financeiro.cnpj'];
    const salvar = messagesLinguage['financeiro.salvar'];
    const cancelar = messagesLinguage['financeiro.cancelar'];
    const vlMensalidade = messagesLinguage['financeiro.vlMensalidade'];
    const inicioVigencia = messagesLinguage['financeiro.inicioVigencia'];
    const terminoVigencia = messagesLinguage['financeiro.terminoVigencia'];
    const campoObrigatorio = messagesLinguage['financeiro.campoObrigatorio'];
    const salvando = messagesLinguage['financeiro.salvando'];
    const financeiroCadastradoComSucesso = messagesLinguage['financeiro.financeiroCadastradoComSucesso'];
    const falhaAoCadastrarFinanceiro = messagesLinguage['financeiro.falhaAoCadastrarFinanceiro'];
    const ilimitado = messagesLinguage['financeiro.ilimitado'];
    const nrProdutor = messagesLinguage['financeiro.nrProdutor'];
    const nrTecnico = messagesLinguage['financeiro.nrTecnico'];
    const recorrencia = messagesLinguage['financeiro.recorrencia'];
    const recorrenciaRemovidoComSucesso = messagesLinguage['financeiro.recorrenciaRemovidoComSucesso'];
    const excluir = messagesLinguage['financeiro.excluir'];
    const nao = messagesLinguage['financeiro.nao'];
    const desejaExcluir = messagesLinguage['financeiro.desejaExcluir'];
    const salvarFinanceiroSair = messagesLinguage['financeiro.salvarFinanceiroSair'];
    const descartar = messagesLinguage['financeiro.descartar'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          cadastroFinanceiro,
          razaoSocial,
          nomeFantasia,
          cnpj,
          salvar,
          cancelar,
          vlMensalidade,
          inicioVigencia,
          terminoVigencia,
          campoObrigatorio,
          salvando,
          financeiroCadastradoComSucesso,
          falhaAoCadastrarFinanceiro,
          ilimitado,
          nrProdutor,
          nrTecnico,
          recorrencia,
          recorrenciaRemovidoComSucesso,
          excluir,
          nao,
          desejaExcluir,
          salvarFinanceiroSair,
          descartar
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    const data = {
      dsRazaoSocial: '',
      nmCliente: '',
      nrDocumento: '',
      dsEmail: '',
      nrTelefone: '',
      vlImplantacao: '',
      vlLicenca: ''
    };

    this.initialFinanceiro = data;

    this.state = {
      financeiro: { ...this.initialFinanceiro },
      canLeave: false,
      editarRecorrencia: false,
      index: 0,
      indexEditar: true,
      loading: true,
      data: data,
      vlImplantacao: '',
      vlLicenca: '',
      headHistorico: [
        {
          field: 'vlMensalidade',
          headerName: provider.intl.formatMessage({ id: 'vlMensalidade' }),
          col: 3,
          valueFormatter: currencyFormatter
        },
        {
          field: 'dtInicioFormat',
          headerName: provider.intl.formatMessage({ id: 'inicioVigencia' }),
          col: 2
        },
        {
          field: 'dtTerminoFormat',
          headerName: provider.intl.formatMessage({ id: 'terminoVigencia' }),
          col: 2
        },
        {
          field: 'nrProdutorFormat',
          headerName: provider.intl.formatMessage({ id: 'nrProdutor' }),
          col: 2
        },
        {
          field: 'nrTecnicoFormat',
          headerName: provider.intl.formatMessage({ id: 'nrTecnico' }),
          col: 3
        }
      ]
    };

    this.isDirty = function() {
      return JSON.stringify(this.initialFinanceiro) !== JSON.stringify(this.state.financeiro);
    }.bind(this);

    window.onbeforeunload = function() {
      if (this.isDirty()) {
        return true;
      }
    }.bind(this);

    this.handleSubmit = null;
    this.locale = '';

    this.clickSalvar = this.clickSalvar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.retornaCancelarLinguagem = this.retornaCancelarLinguagem.bind(this);
    this.retornaSalvarLinguagem = this.retornaSalvarLinguagem.bind(this);
    this.onClickHistorico = this.onClickHistorico.bind(this);
    this.onClickDeleteHistorico = this.onClickDeleteHistorico.bind(this);
    this.editarItens = this.editarItens.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onTryExit = this.onTryExit.bind(this);
  }

  componentDidMount() {
    Financeiro.findFinanceiroByCliente(this.props.match.params.id)
      .then(query => {
        Cliente.findClienteById(this.props.match.params.id)
          .then(queryCliente => {
            const data = {
              idClienteFinanceiro: query.data.idClienteFinanceiro,
              dsRazaoSocial: queryCliente.data.dsRazaoSocial,
              nmCliente: queryCliente.data.nmCliente,
              nrDocumento: queryCliente.data.nrDocumento,
              dsEmail: queryCliente.data.dsEmail,
              nrTelefone: query.data.nrTelefone || '',
              vlImplantacao: query.data.vlImplantacao,
              vlLicenca: query.data.vlLicenca,
              historicoFinanceiroList: query.data.historicoFinanceiroList
            };
            data.historicoFinanceiroList.forEach(doc => {
              doc.dtInicioFormat = moment(doc.dtInicio).format('DD/MM/YYYY');
              doc.dtTerminoFormat = moment(doc.dtTermino).format('DD/MM/YYYY');
              doc.nrProdutorFormat =
                doc.nrProdutor === -1
                  ? provider.intl.formatMessage({ id: 'ilimitado' })
                  : doc.nrProdutor;
              doc.nrTecnicoFormat =
                doc.nrTecnico === -1 ? provider.intl.formatMessage({ id: 'ilimitado' }) : doc.nrTecnico;
              doc.nrNdvi = parseFloat(doc.nrNdvi.toFixed(3));
            });

            const dataCliente = {
              dhInclusao: queryCliente.data.dhInclusao,
              dsEmail: queryCliente.data.dsEmail,
              dsRazaoSocial: queryCliente.data.dsRazaoSocial,
              idCliente: queryCliente.data.idCliente,
              nmCliente: queryCliente.data.nmCliente,
              nrDocumento: queryCliente.data.nrDocumento,
              nrTelefone: queryCliente.data.nrTelefone,
              stCliente: queryCliente.data.stCliente
            };

            this.initialFinanceiro = { ...dataCliente };

            this.setState({
              loading: false,
              data: data,
              cliente: dataCliente,
              financeiro: dataCliente
            });
          })
          .catch(err => {
            this.setState({
              loading: false,
              data: {
                historicoFinanceiroList: []
              }
            });
          });
      })
      .catch(err => {
        this.setState({
          loading: false,
          data: {
            historicoFinanceiroList: []
          }
        });
      });
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  clickSalvar(values) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: provider.intl.formatMessage({
          id: 'salvando'
        })
      }
    );

    var clienteHistoricoLast = {};
    var clienteHistoricoToday = {};

    const clienteHistorico = this.state.data.historicoFinanceiroList.map(doc => {
      if (clienteHistoricoLast.dtInicio < doc.dtInicio || clienteHistoricoLast.dtInicio === undefined) {
        clienteHistoricoLast = doc;
      }
      if (
        moment(doc.dtInicio).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') &&
        moment(doc.dtTermino).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
      ) {
        clienteHistoricoToday = doc;
      }
      return {
        nrProdutor: doc.nrProdutor,
        nrTecnico: doc.nrTecnico,
        nrNdvi: doc.stNdvi === 0 ? 0 : doc.nrNdvi,
        stNdvi: doc.stNdvi,
        dtInicio: moment(doc.dtInicio).format('YYYY-MM-DD'),
        dtTermino: moment(doc.dtTermino).format('YYYY-MM-DD'),
        vlMensalidade: doc.vlMensalidade
      };
    });

    const recorrenciaVigor =
      clienteHistoricoToday.dtInicio !== undefined ? clienteHistoricoToday : clienteHistoricoLast;

    const data = {
      cliente: this.state.cliente,
      historicoFinanceiroList: clienteHistorico,
      idClienteFinanceiro: this.state.data.idClienteFinanceiro,
      vlImplantacao: values.vlImplantacao,
      vlLicenca: values.vlLicenca,
      dtInicio: moment(recorrenciaVigor.dtInicio).format('YYYY-MM-DD'),
      dtTermino: moment(recorrenciaVigor.dtTermino).format('YYYY-MM-DD'),
      vlMensalidade: recorrenciaVigor.vlMensalidade || 0
    };

    Financeiro.editFinanceiro(data)
      .then(query => {
        swal(provider.intl.formatMessage({ id: 'financeiroCadastradoComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        }).then(() => {
          this.setState({ canLeave: true }, () => {
            if (this.locale !== '') {
              this.props.history.push(this.locale);
            } else {
              this.props.history.push('/app/financeiro');
            }
          });
        });
      })
      .catch(err => {
        swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarFinanceiro' }), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      });
  }

  clickCancelar() {
    this.props.history.push('/app/financeiro');
  }

  retornaSalvarLinguagem() {
    return provider.intl.formatMessage({ id: 'salvar' });
  }

  retornaCancelarLinguagem() {
    return provider.intl.formatMessage({ id: 'cancelar' });
  }

  onClickHistorico(values, position) {
    var data = this.state.data;

    if (position !== undefined) {
      data.historicoFinanceiroList[position] = values;
      data.historicoFinanceiroList = [...data.historicoFinanceiroList];
    } else {
      data.historicoFinanceiroList = data.historicoFinanceiroList.concat(values);
    }

    this.setState({
      financeiro: data,
      data: data
    });

    this.onClose();
  }

  onClickDeleteHistorico(position) {
    const data = this.state.data;
    data.historicoFinanceiroList.splice(position, 1);
    data.historicoFinanceiroList = [...data.historicoFinanceiroList];

    this.setState({
      financeiro: data,
      data: data
    });

    this.onClose();
  }

  handleChangeIndex(index) {
    this.setState({
      index: index
    });
  }

  clickAdicionar() {
    this.setState({
      index: 1,
      editarRecorrencia: false
    });

    this.editarItens(false);
  }

  clickEditar() {
    this.setState({
      index: 1,
      editarRecorrencia: true
    });

    this.editarItens(false);
  }

  clickExcluir() {
    const position = this.state.data.historicoFinanceiroList.indexOf(this.state.itemSelect);

    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        this.onClickDeleteHistorico(position);

        swal(provider.intl.formatMessage({ id: 'recorrenciaRemovidoComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      }
    });
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false,
        checkProdutores: select.nrProdutor === -1,
        checkTecnicos: select.nrTecnico === -1
      });
    } else {
      this.setState({
        nrProdutor: '',
        nrTecnico: '',
        itemSelect: '',
        disabledEdit: true,
        checkProdutores: false,
        checkTecnicos: false
      });
    }
  }

  editarItens(value) {
    this.setState({
      indexEditar: value
    });
  }

  onClose() {
    this.setState({
      openModal: false,
      index: 0,
      indexEditar: true,
      editarRecorrencia: false
    });
  }

  onChangeFinanciero(campo, e, callback, convertToNum = false) {
    callback(e);
    const aux = this.state.financeiro;
    aux[campo] = convertToNum ? +e.target.value : e.target.value;
    this.setState({ financeiro: aux });
  }

  onTryExit(location) {
    if (this.isDirty()) {
      swal({
        title: provider.intl.formatMessage({ id: 'salvarFinanceiroSair' }),
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          cancel: {
            text: provider.intl.formatMessage({ id: 'descartar' }),
            value: false,
            visible: true,
            closeModal: true,
            className: 'swal2-cancel'
          },
          confirm: {
            text: provider.intl.formatMessage({ id: 'salvar' }),
            value: true,
            visible: true,
            closeModal: false,
            className: 'swal2-confirm'
          }
        }
      }).then(res => {
        swal.close();
        if (res) {
          this.locale = location.pathname;
          this.handleSubmit && this.handleSubmit();
        } else {
          this.setState(
            { canLeave: true },
            () => this.state.canLeave && this.props.history.push(location.pathname)
          );
        }
      });
      return false;
    }

    this.setState(
      { canLeave: true },
      () => this.state.canLeave && this.props.history.push(location.pathname)
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Prompt when={!this.state.canLeave} message={this.onTryExit} />
        <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <SwipeableViews
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
              containerStyle={{ flex: 1 }}
              index={this.state.index}
              onChangeIndex={this.handleChangeIndex}
              slideStyle={{
                display: 'flex',
                overflowY: 'hidden',
                height: this.state.index === 0 ? 'auto' : '100%'
              }}
              ignoreNativeScroll={false}
            >
              {[
                <div
                  key={0}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    flex: 1,
                    marginBottom: 15
                  }}
                >
                  <Formik
                    initialValues={{
                      dsRazaoSocial: this.state.data.dsRazaoSocial,
                      nmCliente: this.state.data.nmCliente,
                      cnpj: this.state.data.nrDocumento,
                      dsEmail: this.state.data.dsEmail,
                      nrTelefone: this.state.data.nrTelefone,
                      vlImplantacao: this.state.data.vlImplantacao,
                      vlLicenca: this.state.data.vlLicenca
                    }}
                    onSubmit={this.clickSalvar}
                    validateOnBlur
                    enableReinitialize={true}
                    validateOnChange={false}
                    validationSchema={Yup.object().shape({
                      vlImplantacao: Yup.string().required(
                        <FormattedMessage id='financeiro.campoObrigatorio' />
                      ),
                      vlLicenca: Yup.string().required(
                        <FormattedMessage id='financeiro.campoObrigatorio' />
                      )
                    })}
                    render={({
                      values,
                      handleSubmit,
                      handleChange,
                      errors,
                      touched,
                      setFieldValue,
                      submitForm,
                      handleBlur
                    }) => {
                      this.handleSubmit = submitForm;

                      return (
                        <MuiThemeProvider theme={theme}>
                          <form autoComplete='off' className={classes.form} onSubmit={handleSubmit}>
                            <div>
                              {this.state.loading && <Loading />}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%'
                                }}
                              >
                                <CardIcon titulo={<FormattedMessage id='financeiro.identificacao' />}>
                                  <Grid container spacing={16} style={{ marginTop: 15 }}>
                                    <Grid item xs={5}>
                                      <TextField
                                        disabled={true}
                                        label={<FormattedMessage id='financeiro.razaoSocial' />}
                                        name='dsRazaoSocial'
                                        value={values.dsRazaoSocial}
                                        onChange={event => {
                                          setFieldValue('dsRazaoSocial', event.target.value);
                                        }}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          className: classes.font
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={5}>
                                      <TextField
                                        disabled={true}
                                        label={<FormattedMessage id='financeiro.nomeFantasia' />}
                                        name='nmCliente'
                                        value={values.nmCliente}
                                        onChange={handleChange}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          className: classes.font
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <TextField
                                        disabled={true}
                                        label={<FormattedMessage id='financeiro.cnpj' />}
                                        name='cnpj'
                                        value={values.cnpj}
                                        onChange={handleChange}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          className: classes.font
                                        }}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={16}>
                                    <Grid item xs={5}>
                                      <TextField
                                        disabled={true}
                                        label={<FormattedMessage id='financeiro.email' />}
                                        name='dsEmail'
                                        value={values.dsEmail}
                                        onChange={handleChange}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          className: classes.font
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                        disabled={true}
                                        label={<FormattedMessage id='financeiro.telefone' />}
                                        name='nrTelefone'
                                        value={values.nrTelefone}
                                        onChange={handleChange}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          className: classes.font
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={2}>
                                      <NumberFormat
                                        allowNegative={false}
                                        value={values.vlImplantacao}
                                        thousandSeparator={getUserLang() === 'en-US' ? ',' : '.'}
                                        decimalSeparator={getUserLang() === 'en-US' ? '.' : ','}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        label={<FormattedMessage id='financeiro.valorImplantacao' />}
                                        name='vlImplantacao'
                                        onChange={handleChange}
                                        onBlur={event => {
                                          handleBlur(event);
                                          this.onChangeFinanciero('vlImplantacao', event, handleBlur);
                                          if (this.state.vlImplantacao !== undefined) {
                                            setFieldValue('vlImplantacao', this.state.vlImplantacao);
                                          }
                                        }}
                                        error={
                                          touched.vlImplantacao && errors.vlImplantacao ? true : false
                                        }
                                        helperText={
                                          errors.vlImplantacao && touched.vlImplantacao ? (
                                            <FormattedMessage id='financeiro.campoObrigatorio' />
                                          ) : null
                                        }
                                        className={classes.textField}
                                        InputProps={{
                                          className: classes.fontMensalidade
                                        }}
                                        InputLabelProps={{
                                          className: classes.fontMensalidade
                                        }}
                                        customInput={TextField}
                                        onValueChange={values => {
                                          const { floatValue } = values;
                                          this.setState({
                                            vlImplantacao: floatValue
                                          });
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={2}>
                                      <NumberFormat
                                        allowNegative={false}
                                        value={values.vlLicenca}
                                        thousandSeparator={getUserLang() === 'en-US' ? ',' : '.'}
                                        decimalSeparator={getUserLang() === 'en-US' ? '.' : ','}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        label={<FormattedMessage id='financeiro.valorLicenciamento' />}
                                        name='vlLicenca'
                                        onChange={handleChange}
                                        onBlur={event => {
                                          this.onChangeFinanciero('vlLicenca', event, handleBlur);
                                          handleBlur(event);
                                          if (this.state.vlLicenca !== undefined) {
                                            setFieldValue('vlLicenca', this.state.vlLicenca);
                                          }
                                        }}
                                        error={touched.vlLicenca && errors.vlLicenca ? true : false}
                                        helperText={
                                          errors.vlLicenca && touched.vlLicenca ? (
                                            <FormattedMessage id='financeiro.campoObrigatorio' />
                                          ) : null
                                        }
                                        className={classes.textField}
                                        InputProps={{
                                          className: classes.fontMensalidade
                                        }}
                                        InputLabelProps={{
                                          className: classes.fontMensalidade
                                        }}
                                        customInput={TextField}
                                        onValueChange={values => {
                                          const { floatValue } = values;
                                          this.setState({
                                            vlLicenca: floatValue
                                          });
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </CardIcon>
                              </div>
                            </div>

                            {this.state.indexEditar && (
                              <div className={classes.fab}>
                                <ButtonFABMenu
                                  icon={iconMenuPontos}
                                  actions={[
                                    {
                                      icon: <Check style={{ color: 'white' }} />,
                                      name: this.retornaSalvarLinguagem(),
                                      onClickIcon: handleSubmit,
                                      color: '#42ADE8',
                                      disabled: this.state.error
                                    },
                                    {
                                      icon: <Close style={{ color: 'white' }} />,
                                      name: this.retornaCancelarLinguagem(),
                                      onClickIcon: this.clickCancelar,
                                      color: '#F33A30'
                                    }
                                  ]}
                                />
                              </div>
                            )}
                          </form>
                        </MuiThemeProvider>
                      );
                    }}
                  />

                  <GroupButtonGrid
                    showAdd={true}
                    showEdit={true}
                    showDelete={true}
                    disableEdit={this.state.disabledEdit}
                    disableDelete={this.state.disabledEdit}
                    onClickAdd={this.clickAdicionar}
                    onClickEdit={this.clickEditar}
                    onClickDelete={this.clickExcluir}
                  />

                  <Card
                    withOutBorderRadius={true}
                    title={provider.intl.formatMessage({ id: 'recorrencia' })}
                    cabecalho={true}
                    disablePadding
                    childrenStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <DataTable
                      noMargin={true}
                      marginTop={true}
                      noBox={true}
                      usePaddings={false}
                      data={this.state.data.historicoFinanceiroList || []}
                      columns={this.state.headHistorico}
                      selectChange={this.selectChange}
                      showPagination={false}
                    />
                  </Card>
                </div>,

                <div
                  key={1}
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                >
                  {this.state.index === 1 && (
                    <Recorencia
                      editarItens={this.editarItens}
                      data={this.state.data.historicoFinanceiroList || []}
                      onClickHistorico={this.onClickHistorico}
                      itensIdentificacao={this.state.headHistorico}
                      onClickDeleteHistorico={this.onClickDeleteHistorico}
                      itemSelect={this.state.editarRecorrencia ? this.state.itemSelect || null : null}
                      statusEdit={this.state.editarRecorrencia}
                      onClose={this.onClose}
                      onExcluir={this.clickExcluir}
                    />
                  )}
                </div>
              ]}
            </SwipeableViews>
          </div>
        </IntlProvider>
      </>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

function currencyFormatter(params) {
  return params.value.toLocaleString(getUserLang(), {
    minimumFractionDigits: 2
  });
}

let enhaced = withStyles(style)(EditarFinanceiro);
export default withRouter(connect(mapStateToProps)(enhaced));
