export default {
  auditoria: {
    descricao: 'Descripción',
    dataCadastro: 'Fecha de Registro',
    erroAoBuscarDados: 'No se pudieron recuperar los datos',
    auditoria: 'Auditoría',
    desejaExcluirAuditoria: '¿Quieres eliminar esta auditoría?',
    desejaExcluirMedidaCorretiva: '',
    nao: 'No',
    excluir: 'Eliminar',
    auditoriaExcluida: 'Auditoría eliminada correctamente',
    erroAoExcluir: 'No se pudo borrar la auditoría',
    cadastroDeAuditoria: 'Registro de auditoría orgánico',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    salvar: 'Ahorrar',
    cancelar: 'Cancelar',
    auditoriaSalvoComSucesso: 'Auditoría guardada con éxito',
    erroAoSalvar: 'No se pudo guardar la auditoría',
    salvando: 'Saving',
    atualizando: 'Actualizando',
    status: 'Situación',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nombre',
    diasResolucao: 'Dias para resolucion',
    sigla: 'Iniciales',
    tipoPenalidade: 'Tipo de penalización',
    nenhum: 'Ninguno',
    desclassificacaoSafra: 'Descalificación de cultivos',
    desclassificacaoTalhao: 'Desclasificación de campo',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    sancao: 'Sanción',
    certificacao: 'Certificación',
    cadastrarSancao: 'registrar sanción',
    editar: 'Editar',
    medidasCorretivas: 'Medidas correctivas',
    medidaCorretiva: 'Medida correctiva',
    geral: 'General',
    cadastroGeralExistente:
      'Solo es posible tener una sanción general, o bien debe ser por certificación',
    ok: 'Ok',
    produtor: 'Productor',
    areaCultivo: 'Área de cultivo',
    motivoSuspensao: 'Motivo de suspensión',
    plantio: 'Plantación',
    colheita: 'Cosecha',
    tipoAuditoria: 'Tipo de auditoría',
    interna: 'Interno',
    externa: 'Externo',
    situacao: 'Situación',
    emAndamento: 'En proceso',
    finalizadaSemNaoConformidade: 'Terminado sin incumplimiento',
    finalizadaComNaoConformidade: 'Terminado con el incumplimiento',
    suspensa: 'Suspendido',
    auditores: 'Auditores',
    nomeAuditor: 'Nombre del auditor',
    naoConformidade: 'Incumplimiento',
    dataComprimento: 'Fecha de cumplimiento',
    limiteCumprimento: 'Límite para el cumplimiento',
    observacoes: 'Comentarios',
    desejaAgendarMedidaCorretiva: '¿Quiere programar las medidas correctivas a tomar?',
    agendar: 'Para programar',
    talhao: 'Campo',
    cadastrarNaoConformidade: 'Registrar incumplimiento',
    aberto: 'Abierto',
    fechado: 'Closed',
    dataDeObservacao: 'Fecha de observación',
    observacao: 'Observación',
    adicionarObservacao: 'Añadir la nota',
    statusFalseCadastrado: 'Ya existe una observación con situación cerrada',
    erroSubirArquivo: 'Hubo un error al subir una foto',
    insiraPlantio: 'Inserte una plantación para registrar el incumplimiento',
    alterandoPlantioLimpaNaoConformidade:
      'Al cambiar la plantación, se borrarán todas las no conformidades, ¿quieres continuar?',
    agendaVisita: 'Programar visita',
    erroAoCadastrar: 'Error al registrar horario',
    visitaCadastradaComSucesso: 'Visita registrada con éxito',
    mesmoHorario: 'Hay una visita programada al mismo tiempo para',
    talhoes: 'Campos',
    possuiInfoComplementar: 'tiene información adicional'
  }
};
