export default {
  municipio: {
    nome: 'Nombre',
    uf: 'UF',
    municipio: 'Condado',
    identificacaoDoMunicipio: 'Identificación',
    falha: 'Error al cargar municipio',
    moduloFiscal: 'Tax module',
    naoFoiPossivelEditar: 'No pude editar',
    salvando: 'ahorro',
    campoObrigatorio: 'Campo obligatorio',
    salvar: 'Ahorrar',
    cancelar: 'Cancelar',
    editadoComSucesso: 'Editado con éxito'
  }
};
