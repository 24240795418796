import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import formatMessage from './../i18n/formatMessage';
import { style } from './../NaoConformidade.styles';

/**
 * Screen de listagem de sanção
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function Sancao(props) {
  const { naoConformidadeSancao, classes } = props;

  return (
    <>
      <Grid container spacing={16} style={{ marginTop: 30 }}>
        <Grid item xs={4}>
          <div className={classes.cardCertificacao}>
            <div className={classes.labelTitle}>{formatMessage('naoConformidade.sancao')}</div>
            <div className={classes.labelInfo}>
              {naoConformidadeSancao.sancaoCategoria.nmSancaoCategoria}
            </div>
          </div>
        </Grid>

        <Grid item xs={2}>
          <div className={classes.cardCertificacao}>
            <div className={classes.labelTitle}>{formatMessage('naoConformidade.certificacao')}</div>
            <div className={classes.labelInfo}>
              {naoConformidadeSancao.tipoCertificacao
                ? naoConformidadeSancao.tipoCertificacao.nmTipoCertificacao
                : ''}
            </div>
          </div>
        </Grid>
      </Grid>

      <div className={classes.cardMedidaCorretiva}>
        <div className={classes.labelTitle}>{formatMessage('naoConformidade.medidasCorretivas')}</div>
        {naoConformidadeSancao.sancaoMedidaCorretivaList
          .filter(item => item.stRegistro !== 2)
          .map(sancaoMedidaCorretiva => (
            <div
              key={sancaoMedidaCorretiva.idSancaoMedidaCorretiva}
              className={classes.cardCheckLabelMedidas}
            >
              <div className={classes.checkMedida} />
              <div className={classes.labelInfo}>
                {sancaoMedidaCorretiva.medidaCorretiva.nmMedidaCorretiva}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(Sancao)));
