export default {
  confirmarCadastro: {
    email: "Email",
    codigo: "Code",
    senha: "Password",
    confirmacaoSenha: "Password Confirm",
    confirmar: "CONFIRM REGISTRATION",
    campoObrigatorio: "Required field",
    emailInvalido: "Invalid email",
    senhaMinimo: "Password must be at least 6 characters",
    falhaConectar: "Your registration could not be verified, please try again.",
    codigoExpirado: "Expired Release Code",
    usuarioNaoEncontrado: "User not found",
    sucesso: "Success",
    codigoInvalido: "Invalid or outdated code",
    falhaValidarCadastro: "Failed to validate registration",
    cadastroJaConfirmado: "Register already confirmed",
    cadastroConfirmado: "Registration successfully confirmed"
  }
};
