import React, { Component } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import CardsWithFilters from '@components/CardFilters/CardsWithFilters';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const relatorio = messagesLinguage['relatorio.relatorio'];
const regiao = messagesLinguage['relatorio.regiao'];
const municipio = messagesLinguage['relatorio.municipio'];
const conhecimentoOrganico = messagesLinguage['relatorio.conhecimentoOrganico'];
const sim = messagesLinguage['relatorio.sim'];
const nao = messagesLinguage['relatorio.nao'];
const atividadeGraos = messagesLinguage['relatorio.atividadeGraos'];
const carregando = messagesLinguage['relatorio.carregando'];
const gerandoRelatorio = messagesLinguage['relatorio.gerandoRelatorio'];
const falhaAoGerarRelatorio = messagesLinguage['relatorio.falhaAoGerarRelatorio'];
const relatorioProdutoresPotencial = messagesLinguage['relatorio.relatorioProdutoresPotencial'];
const crescente = messagesLinguage['relatorio.crescente'];
const decrescente = messagesLinguage['relatorio.decrescente'];
const produtor = messagesLinguage['relatorio.produtor'];
const uf = messagesLinguage['relatorio.uf'];

const intlProvider = new IntlProvider({
  locale: getUserLang(),
  messages: {
    relatorio,
    regiao,
    municipio,
    conhecimentoOrganico,
    sim,
    nao,
    atividadeGraos,
    carregando,
    gerandoRelatorio,
    falhaAoGerarRelatorio,
    relatorioProdutoresPotencial,
    crescente,
    decrescente,
    produtor,
    uf
  }
});

provider = intlProvider.getChildContext();

const styles = {
  card: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 15px)'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  page: {
    marginBottom: 15
  },
  document: {
    width: '100%'
  },
  divDocument: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
};

const options = [
  {
    label: provider.intl.formatMessage({ id: 'crescente' }),
    field: 'asc',
    value: 0
  },
  {
    label: provider.intl.formatMessage({ id: 'decrescente' }),
    field: 'desc',
    value: 1
  }
];

const operationTypeStringOrdenacao = [
  {
    label: provider.intl.formatMessage({ id: 'produtor' }),
    field: 'nmProdutor',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'municipio' }),
    field: 'nmMunicipio',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'uf' }),
    field: 'sgUF',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'regiao' }),
    field: 'nmRegiao',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'conhecimentoOrganico' }),
    field: 'tpProducaoOrganica',
    type: 'enum',
    options: options
  },
  {
    label: provider.intl.formatMessage({ id: 'atividadeGraos' }),
    field: 'tpAtividadeGrao',
    type: 'enum',
    options: options
  }
];

const operationTypeString = [
  {
    label: provider.intl.formatMessage({ id: 'municipio' }),
    field: 'nmMunicipio',
    list: 'listMunicipio',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'regiao' }),
    field: 'nmRegiao',
    list: 'listRegiao',
    type: 'autocomplete'
  },
  {
    label: provider.intl.formatMessage({ id: 'conhecimentoOrganico' }),
    field: 'tpProducaoOrganica',
    type: 'enum',
    options: [
      {
        label: provider.intl.formatMessage({ id: 'sim' }),
        field: 'sim',
        value: 1
      },
      {
        label: provider.intl.formatMessage({ id: 'nao' }),
        field: 'nao',
        value: 0
      }
    ]
  },
  {
    label: provider.intl.formatMessage({ id: 'atividadeGraos' }),
    field: 'tpAtividadeGrao',
    type: 'enum',
    options: [
      {
        label: provider.intl.formatMessage({ id: 'sim' }),
        field: 'sim',
        value: 1
      },
      {
        label: provider.intl.formatMessage({ id: 'nao' }),
        field: 'nao',
        value: 0
      }
    ]
  }
];

/**
 * Tela de Relatório
 *
 * @author Gabriela Farias
 * @class Relatorio
 * @extends {Component} - Componente React
 */
class RelatorioProdutoresPotencial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filePDF: null,
      fileXLS: null,
      closeCard: false,
      errorCard: false,
      loading: false,
      loadingDados: true,
      listMunicipio: [],
      listRegiao: []
    };

    const title = 'relatorio.relatorioProdutoresPotencial';
    if (this.props.itensState.name !== 'relatorio.relatorioProdutoresPotencial') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.clickGerarRelatorio = this.clickGerarRelatorio.bind(this);
    this.modalErrorRelatorio = this.modalErrorRelatorio.bind(this);
  }

  componentDidMount() {
    Promise.all([
      Regioes.findAllRegiao()
        .then(query => {
          this.setState({
            listRegiao: query.data
          });
        })
        .catch(() => {
          this.setState({
            listRegiao: []
          });
        }),

      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          this.setState({
            listMunicipio: query.data
          });
        })
        .catch(err => {
          this.setState({
            listMunicipio: []
          });
        })
    ])
      .then(() => {
        this.setState({ loadingDados: false });
      })
      .catch(() => {
        this.setState({ loadingDados: false });
      });
  }

  clickGerarRelatorio(value, secondValue) {
    this.setState({
      closeCard: true,
      loading: true,
      errorCard: false,
      fileXLS: null,
      filePDF: null
    });

    let order = '';
    value.forEach(doc => {
      order = order + doc.filter.field + ':' + doc.value.field + ';';
    });

    const chips = secondValue;

    var municipio = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    if (municipio !== undefined) {
      municipio = 'idMunicipio:' + municipio.value.value + ';';
    } else {
      municipio = '';
    }

    var regiao = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    if (regiao !== undefined) {
      regiao = 'idRegiao:' + regiao.value.value + ';';
    } else {
      regiao = '';
    }

    var tpProducaoOrganica = chips.find(item => {
      return item.filter.field === 'tpProducaoOrganica';
    });

    if (tpProducaoOrganica !== undefined) {
      tpProducaoOrganica = 'tpProducaoOrganica:' + tpProducaoOrganica.value.value + ';';
    } else {
      tpProducaoOrganica = '';
    }

    var tpAtividadeGrao = chips.find(item => {
      return item.filter.field === 'tpAtividadeGrao';
    });

    if (tpAtividadeGrao !== undefined) {
      tpAtividadeGrao = 'tpAtividadeGrao:' + tpAtividadeGrao.value.value + ';';
    } else {
      tpAtividadeGrao = '';
    }

    const filters = municipio + regiao + tpProducaoOrganica + tpAtividadeGrao + '&order=' + order;

    Relatorios.gerarRegistroProdutoresPotencial(filters)
      .then(doc => {
        this.setState({
          closeCard: false,
          fileXLS: doc.data.dsLinkXls,
          filePDF: doc.data.dsLinkPdf
        });
      })
      .catch(err => {
        this.modalErrorRelatorio();
      });
  }

  modalErrorRelatorio() {
    this.setState(
      {
        closeCard: false,
        errorCard: true,
        loading: false,
        fileXLS: null,
        filePDF: null
      },
      this.setState({ errorCard: false })
    );

    swal(provider.intl.formatMessage({ id: 'falhaAoGerarRelatorio' }), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div className={classes.card}>
          {(this.state.loading || this.state.loadingDados) && <Loading />}
          <CardsWithFilters
            errorCard={this.state.errorCard}
            closeCard={this.state.closeCard}
            usePaddings={false}
            relatorio
            viewKey='relatorio-produtores-potencial'
            itens={[
              {
                autoComplete: {
                  listMunicipio: {
                    list: this.state.listMunicipio,
                    campoOp: 'nmMunicipio',
                    campoChave: 'idMunicipio'
                  },
                  listRegiao: {
                    list: this.state.listRegiao,
                    campoOp: 'nmRegiao',
                    campoChave: 'idRegiao'
                  }
                },
                allViewKey: 'ordenacao-produtores-potencial',
                onClickButton: this.clickGerarRelatorio,
                filtros: operationTypeString,
                viewKey: 'relatorio-produtores-potencial',
                keyCard: 1,
                onFilter: () => {},
                onFilterChanged: () => {},
                relatorio: true,
                withoutButtonFiltrar: true,
                contain: this.props.chipData.find(data => {
                  return data.id === 'relatorio-produtores-potencial';
                }).content
              },
              {
                allViewKey: 'relatorio-produtores-potencial',
                onClickButton: this.clickGerarRelatorio,
                filtros: operationTypeStringOrdenacao,
                viewKey: 'ordenacao-produtores-potencial',
                keyCard: 2,
                onFilter: () => {},
                onFilterChanged: () => {},
                relatorio: true,
                withoutLabelTipo: true,
                contain: this.props.chipData.find(data => {
                  return data.id === 'ordenacao-produtores-potencial';
                }).content
              }
            ]}
          />

          {this.state.loading && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginTop: 20,
                justifyContent: 'center'
              }}
            >
              {provider.intl.formatMessage({ id: 'gerandoRelatorio' })}
            </div>
          )}

          <PDFViewer
            withoutPosition={true}
            loadSucess={() => {
              this.setState({
                loading: false
              });
            }}
            errorCard={this.state.errorCard}
            fileXLS={this.state.fileXLS}
            filePDF={this.state.filePDF}
          />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'relatorio-produtores-potencial' || date.id === 'ordenacao-produtores-potencial';
  })
});

RelatorioProdutoresPotencial.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(RelatorioProdutoresPotencial));
export default withRouter(connect(mapStateToProps)(enhaced));
