import { createMuiTheme } from '@material-ui/core/styles';

const style = theme => ({
  filters: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'scroll'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  card: {
    width: '100%',
    height: '100%'
  },
  cardInside: {
    width: '100%',
    height: '100%',
    marginTop: 23
  },
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    cursor: 'pointer',
    width: 'fit-content'
  },
  label: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#000000',
    cursor: 'pointer'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { style, theme };
