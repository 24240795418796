export default {
  safras: {
    safras: 'Añadas',
    descricao: 'Descripción',
    desejaExcluir: '¿Quieres eliminar el cultivo?',
    nao: 'No',
    excluir: 'Excluir',
    excluindo: 'Excluyendo',
    falhaAoExcluirSafra: 'Error al eliminar recorte',
    safraRemovidoComSucesso: 'Cultivo eliminado con éxito',
    safraCadastradaComSucesso: 'Cultivo registrado con éxito',
    safraEditadaComSucesso: 'Cultivo editado exitosamente',
    falhaAoEditarSafra: 'Error al editar recorte',
    falhaAoCadastrarSafra: 'Error al registrar cultivo',
    falhaAoCarregarSafra: 'Error al cargar el cultivo',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Guardar',
    atualizar: 'Actualizar',
    atualizando: 'Actualización',
    cadastroDeSafra: 'Registro de cultivos',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    ativo: 'Activo',
    situacao: 'Situación',
    inativo: 'Inactivo',
    cultura: 'Cultura',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    safraCadastradaPeriodo: 'Hay un cultivo registrado en este período.',
    minimoCulturas: 'Agrega al menos 1 cultura'
  }
};
