import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InsumoRecord from '@components/InsumoRecord/InsumoRecord';
import formatMessage from './i18n/formatMessage';
import Insumo from '@resources/InsumosEnum';

/**
 * Componente para tela de herbicida
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function Herbicidas(props) {
  useEffect(() => {
    const title = 'herbicida.herbicida';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }
  }, []);

  return (
    <InsumoRecord
      chipData={props.chipData}
      infoUsuario={props.infoUsuario}
      insumoEnum={Insumo.HERBICIDA}
      insumoURL='herbicidas'
      viewKey='herbicidas'
      deleteMessageSucess={formatMessage('herbicida.herbicidaRemovidoComSucesso')}
      mensagemFalhaDeletar={formatMessage('herbicida.falhaAoExcluirHerbicida')}
      mensagemDelete={formatMessage('herbicida.desejaExcluir')}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'herbicidas';
  })[0].content
});

export default connect(mapStateToProps)(Herbicidas);
