export default {
  sazonalidade: {
    sazonalidade: 'Seasonality',
    cadastroSazonalidade: 'Registration of seasonality',
    minimoSazonalidades: 'Add at least 1 seasonality',
    sazonalidadeSalvaComSucesso: 'Seasonals successfully saved',
    erroAoSalvarSazonalidades: 'There was an error saving seasonality',
    sazonalidades: 'Seasonality',
    culturas: 'Tilth',
    descricao: 'Description',
    cultura: 'Tilth',
    salvar: 'Save',
    cancelar: 'Cancel',
    campoObrigatorio: 'Required field'
  }
};
