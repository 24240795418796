//@author Katia Miglioli
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { FormattedMessage } from 'react-intl';
import Chips from './FilterChip';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = () => ({
  divChips: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  divChipsText: {
    whiteSpace: 'nowrap',
    width: 170,
    overflow: 'hidden',
    marginLeft: '-3px',
    color: '#7F7F7F',
    fontSize: 18
  },
  divChipsArray: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 14
  },
  chip: {
    backgroundColor: '#EAEAEA',
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    height: 17,
    margin: '5px',
    marginTop: 0
  },
  chipFiltro: {
    color: 'black',
    fontSize: 12,
    fontWeight: 900
  },
  chipComparacao: {
    fontSize: 12,
    color: '#727272',
    marginLeft: 6
  },
  chipPesquisa: {
    fontSize: 12,
    color: '#727272',
    marginLeft: 4
  },
  chipIcone: {
    marginTop: '-11px',
    marginLeft: '8px'
  }
});

class CardFilterClosed extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div style={{ height: '100%', width: '100%' }}>
          <div className={classes.divChips} style={{ position: 'relative', display: 'flex' }}>
            <div
              className={classes.divChipsText}
              style={{ width: this.props.withoutLabelTipo ? 100 : 174 }}
            >
              {this.props.withoutLabelTipo ? (
                <FormattedMessage id='filtrosDeListagem.ordenarPor' />
              ) : (
                <FormattedMessage id='filtrosDeListagem.filtrosAdicionados' />
              )}
            </div>
            <Chips
              withoutLabelTipo={this.props.withoutLabelTipo}
              marginBottomImg={true}
              identData={this.props.identData}
              viewKey={this.props.viewKey}
              onFilterChanged={this.props.onFilterChanged}
            />
          </div>
        </div>
      </IntlProvider>
    );
  }
}

CardFilterClosed.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardFilterClosed);
