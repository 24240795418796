export default {
  historicoProdutividade: {
    relatorio: 'Report',
    historicoProdutividade: 'Productivity history report',
    regiao: 'Region',
    municipio: 'City',
    carregando: 'Loading...',
    gerandoRelatorio: 'Generating report ...',
    falhaAoGerarRelatorio: 'Failed to generate report',
    ecosocial: 'Ecosocial',
    tecnicoResponsavel: 'Responsible technician',
    naoPossui: 'Does not have',
    produtor: 'Producer',
    sim: 'Yes',
    nao: 'Not',
    statusCertificacaoOrganica: 'Organic certification status',
    convencional: 'Conventional',
    emConversao: 'En conversión',
    brasil: 'BR (Brazil)',
    comunidadeEuropeia: 'CE (European Community)',
    eua: 'NOP (United States)',
    cultura: 'Culture',
    comunidade: 'Community',
    uf: 'Country'
  }
};
