import formatMessage from './i18n/formatMessage';
import moment from 'moment';
import { getUserLang } from '@utils/localeUtils';

function formatTextField() {
  switch (getUserLang()) {
    case 'pt-BR':
      return 'DD/MM/YY';
    case 'es-ES':
      return 'DD-MM-YY';
    default:
      return 'MM/DD/YY';
  }
}

const headDatatable = [
  {
    field: 'nmProdutor',
    headerName: formatMessage('auditoria.produtor'),
    col: 3
  },
  {
    field: 'dsAreaCultivo',
    headerName: formatMessage('auditoria.areaCultivo'),
    col: 2
  },
  {
    headerName: formatMessage('auditoria.dataInicial'),
    col: 1,
    valueGetter: args => moment(args.node.data.dtInicio).format(formatTextField())
  },
  {
    headerName: formatMessage('auditoria.dataFinal'),
    col: 1,
    valueGetter: args =>
      args.node.data.dtTermino ? moment(args.node.data.dtTermino).format(formatTextField()) : ''
  },
  {
    headerName: formatMessage('auditoria.tipoAuditoria'),
    col: 2,
    valueGetter: args =>
      args.node.data.tpAuditoria === 0
        ? formatMessage('auditoria.interna')
        : formatMessage('auditoria.externa')
  },
  {
    headerName: formatMessage('auditoria.situacao'),
    col: 3,
    valueGetter: args => {
      if (args.node.data.stAuditoriaPlantio === 0) {
        return formatMessage('auditoria.emAndamento');
      } else if (args.node.data.stAuditoriaPlantio === 1) {
        return formatMessage('auditoria.finalizadaSemNaoConformidade');
      } else if (args.node.data.stAuditoriaPlantio === 2) {
        return formatMessage('auditoria.finalizadaComNaoConformidade');
      } else {
        return formatMessage('auditoria.suspensa');
      }
    }
  }
];

const filtersConfig = [
  {
    label: formatMessage('auditoria.produtor'),
    field: 'nmProdutor',
    type: 'string'
  },
  {
    label: formatMessage('auditoria.areaCultivo'),
    field: 'dsAreaCultivo',
    type: 'string'
  },
  {
    label: formatMessage('auditoria.dataInicial'),
    field: 'dtInicio',
    type: 'date'
  },
  {
    label: formatMessage('auditoria.dataFinal'),
    field: 'dtTermino',
    type: 'date'
  },
  {
    label: formatMessage('auditoria.tipoAuditoria'),
    field: 'tpAuditoria',
    type: 'enum',
    options: [
      {
        label: formatMessage('auditoria.interna'),
        field: 'interna',
        value: '0'
      },
      {
        label: formatMessage('auditoria.externa'),
        field: 'externa',
        value: '1'
      }
    ]
  },
  {
    label: formatMessage('auditoria.status'),
    field: 'stAuditoriaPlantio',
    type: 'enum',
    options: [
      {
        label: formatMessage('auditoria.emAndamento'),
        field: 'emAndamento',
        value: '0'
      },
      {
        label: formatMessage('auditoria.finalizadaSemNaoConformidade'),
        field: 'finalizadaSemNaoConformidade',
        value: '1'
      },
      {
        label: formatMessage('auditoria.finalizadaComNaoConformidade'),
        field: 'finalizadaComNaoConformidade',
        value: '2'
      },
      {
        label: formatMessage('auditoria.suspensa'),
        field: 'suspensa',
        value: '3'
      }
    ]
  }
];

export { headDatatable, filtersConfig, formatTextField };
