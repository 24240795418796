import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextFieldAcompanhamento from './TextFieldAcompanhamento';
import Chip from '@material-ui/core/Chip';
import { getUserLang } from '@utils/localeUtils';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = {
  cardLine: {
    height: 13,
    width: '100%',
    display: 'flex',
    color: 'rgb(0, 0, 0, 0.54)',
    marginTop: 15,
    marginBottom: 20,
    alignItems: 'center'
  },
  label: {
    margin: 0,
    marginBottom: 3,
    whiteSpace: 'nowrap',
    fontWeight: '400 !important',
    color: 'rgb(0, 0, 0, 1)'
  },
  lineRight: {
    borderBottom: '1px solid rgb(0, 0, 0, 1)',
    marginRight: 10,
    width: 20
  },
  lineLeft: {
    borderBottom: '1px solid rgb(0, 0, 0, 1)',
    width: '100%',
    marginLeft: 10
  }
};

class ListDetalhes extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const areaDeCultivo = messagesLinguage['listDetalhes.areaDeCultivo'];
    const cultura = messagesLinguage['listDetalhes.cultura'];
    const detentor = messagesLinguage['listDetalhes.detentor'];
    const cultivarUtilizadas = messagesLinguage['listDetalhes.cultivarUtilizadas'];
    const talhoesAdicionado = messagesLinguage['listDetalhes.talhoesAdicionado'];
    const tecnicoResponsavel = messagesLinguage['listDetalhes.tecnicoResponsavel'];
    const produtividade = messagesLinguage['listDetalhes.produtividade'];
    const espacamento = messagesLinguage['listDetalhes.espacamento'];
    const semeadura = messagesLinguage['listDetalhes.semeadura'];
    const standFinal = messagesLinguage['listDetalhes.standFinal'];
    const diasEstimados = messagesLinguage['listDetalhes.diasEstimados'];
    const dataPrevista = messagesLinguage['listDetalhes.dataPrevista'];
    const produtividadeEstimada = messagesLinguage['listDetalhes.produtividadeEstimada'];
    const previsaoColheita = messagesLinguage['listDetalhes.previsaoColheita'];
    const colheita = messagesLinguage['listDetalhes.colheita'];
    const previsto = messagesLinguage['listDetalhes.previsto'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          areaDeCultivo,
          cultura,
          detentor,
          cultivarUtilizadas,
          talhoesAdicionado,
          tecnicoResponsavel,
          produtividade,
          espacamento,
          semeadura,
          standFinal,
          diasEstimados,
          dataPrevista,
          produtividadeEstimada,
          previsaoColheita,
          colheita,
          previsto
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();
  }

  chipsTalhoes(listaChip) {
    var chips = this.props.limpar ? [] : listaChip;

    return (
      <div>
        {chips.map((tal, i) => (
          <Chip
            label={tal.talhao.dsTalhao}
            variant='outlined'
            key={i}
            style={{
              backgroundColor: '#E1E1E1',
              border: 'none',
              borderRadius: 10,
              fontSize: 12,
              color: '#676767',
              marginRight: 5,
              marginTop: 10,
              marginBottom: 5,
              marginLeft: 0
            }}
          />
        ))}
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    const dados = {
      areaCultivo: [],
      cultura: [],
      cultivar: [],
      cultivarEspec: []
    };
    var data = this.props.timeLine.length > 0 ? this.props.timeLine[this.props.position] : dados;

    if (!data || !data.info) {
      return <div />;
    }

    var info = data.info;

    const type = data.type;

    var produtividade = info.nrProdutividade || '';
    var unidade = info.nmUnidade ? info.nmUnidade.name : '';
    var area = info.nmArea ? info.nmArea.name : '';
    var semeadura = info.nmSemeadura ? info.nmSemeadura.name : '';
    var areaSemeadura = info.nmAreaSemeadura ? info.nmAreaSemeadura.name : '';

    const valueProdutividade = produtividade + ' ' + unidade + '/' + area;
    const valueSemeadura = info.nrSemeadura + ' ' + semeadura + '/' + areaSemeadura;
    const valueEspacamento = info.nrEspacamento + ' cm';
    const valueStandFinal = info.nrQuantidadeStandFinal + '/' + info.nmUnMedidaStandFinal.name;
    const produtividadeEstimada =
      info.nrProdutividadeEstimada + ' ' + info.nmUnMedidaEstimada.name + '/' + info.nmAreaEstimada.name;
    info.nrEspacamento = info.nrEspacamento ? info.nrEspacamento : undefined;

    return (
      <Grid container style={{ padding: 15 }} spancing={24}>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.tecnico.nmUsuario}
            label={provider.intl.formatMessage({ id: 'tecnicoResponsavel' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.areaCultivo.dsAreaCultivo}
            label={provider.intl.formatMessage({ id: 'areaDeCultivo' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.cultura.nmCultura}
            label={provider.intl.formatMessage({ id: 'cultura' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.cultivar.nmCultivar}
            label={provider.intl.formatMessage({ id: 'detentor' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldAcompanhamento
            limpar={this.props.limpar}
            value={info.cultivarEspec.nmCultivarEspec}
            label={provider.intl.formatMessage({ id: 'cultivarUtilizadas' })}
          />
        </Grid>
        {type === 4 &&
          (info.nrProdutividade !== undefined || info.nrProdutividadeEstimada !== undefined) && (
            <div className={classes.cardLine}>
              <div className={classes.lineRight} />
              <p className={classes.label}>
                {provider.intl.formatMessage({
                  id: 'colheita'
                })}
              </p>
              <div className={classes.lineLeft} />
            </div>
          )}
        {type === 4 && info.nrProdutividade !== undefined && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={valueProdutividade}
              label={provider.intl.formatMessage({ id: 'produtividade' })}
            />
          </Grid>
        )}
        {type === 3 && (info.nrSemeadura !== undefined || info.nrEspacamento) && (
          <div className={classes.cardLine}>
            <div className={classes.lineRight} />
            <p className={classes.label}>
              {provider.intl.formatMessage({
                id: 'semeadura'
              })}
            </p>
            <div className={classes.lineLeft} />
          </div>
        )}
        {type === 3 && info.nrSemeadura !== undefined && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={valueSemeadura}
              label={provider.intl.formatMessage({ id: 'semeadura' })}
            />
          </Grid>
        )}
        {type === 3 && info.nrEspacamento && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={valueEspacamento}
              label={provider.intl.formatMessage({ id: 'espacamento' })}
            />
          </Grid>
        )}
        {type === 3 && info.nrQuantidadeStandFinal !== undefined && (
          <Grid item xs={12}>
            <div className={classes.cardLine}>
              <div className={classes.lineRight} />
              <p className={classes.label}>
                {provider.intl.formatMessage({
                  id: 'standFinal'
                })}
              </p>
              <div className={classes.lineLeft} />
            </div>

            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={valueStandFinal}
              label={provider.intl.formatMessage({ id: 'standFinal' })}
            />
          </Grid>
        )}
        {type === 3 &&
          (info.nrDiasColheita !== undefined ||
            info.dtPrevista !== undefined ||
            info.nrProdutividadeEstimada !== undefined) && (
            <Grid item xs={12}>
              <div className={classes.cardLine}>
                <div className={classes.lineRight} />
                <p className={classes.label}>
                  {provider.intl.formatMessage({
                    id: 'previsaoColheita'
                  })}
                </p>
                <div className={classes.lineLeft} />
              </div>
            </Grid>
          )}
        {type === 3 && info.nrDiasColheita !== undefined && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={info.nrDiasColheita}
              label={provider.intl.formatMessage({ id: 'diasEstimados' })}
            />
          </Grid>
        )}
        {type === 3 && info.dtPrevista !== undefined && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={info.dtPrevista}
              label={provider.intl.formatMessage({ id: 'dataPrevista' })}
            />
          </Grid>
        )}
        {info.nrProdutividadeEstimada !== undefined && (
          <Grid item xs={12}>
            <TextFieldAcompanhamento
              limpar={this.props.limpar}
              value={produtividadeEstimada}
              label={
                type === 3
                  ? provider.intl.formatMessage({
                      id: 'produtividadeEstimada'
                    })
                  : provider.intl.formatMessage({
                      id: 'previsto'
                    })
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <div style={{ fontSize: 12, color: 'rgb(0, 0, 0, 0.54)', marginTop: 15 }}>
            {provider.intl.formatMessage({ id: 'talhoesAdicionado' })}
          </div>
          {this.chipsTalhoes(info.plantioTalhaoList)}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListDetalhes);
