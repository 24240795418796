import React from 'react';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import Input from '@material-ui/core/Input';
import { FastField } from 'formik';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import formatMessage from './../i18n/formatMessage';

/**
 * Componente que representa a tab de identificação da área de cultivo
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const Identificacao = props => {
  const {
    classes,
    setFieldValue,
    values,
    touched,
    errors,
    itensUniMedida,
    tpPerfil,
    municipioList,
    estadoList,
    onChangeAutocomplete,
    comunidade,
    estado,
    municipio,
    comunidadeList,
    tratarValorComunidade,
    validarInicializacaoAutoCompleteEstado,
    validarInicializacaoAutoCompleteMunicipio,
    changeValue,
    changeModulo,
    changeToNrAreaCultivo
  } = props;

  return (
    <form autoComplete='off'>
      <Grid container spacing={16}>
        <Grid item xs={3} style={{ marginTop: 1 }}>
          <TextField
            id='dsAreaCultivo'
            name='dsAreaCultivo'
            onBlur={e => changeValue(e.target.value, 'dsAreaCultivo')}
            onChange={e => setFieldValue('dsAreaCultivo', e.target.value)}
            value={values.dsAreaCultivo}
            label={formatMessage('produtores.descricao')}
            inputProps={{
              maxLength: 60
            }}
            className={classes.textField}
            InputLabelProps={{
              className: classes.font
            }}
            error={errors.dsAreaCultivo && touched.dsAreaCultivo}
            helperText={touched.dsAreaCultivo ? errors.dsAreaCultivo : ''}
          />
        </Grid>

        <Grid item xs={2}>
          <TextFieldWithMask
            tipoMascara={3}
            numberDecimalScale={3}
            showThousandSeparator={true}
            id='nrAreaCultivo'
            name='nrAreaCultivo'
            onChange={value => {
              setFieldValue('nrAreaCultivo', value.target.value);
            }}
            onBlur={e => {
              const nrAreaCultivo = e.target.value.replace('.', '').replace(',', '.');
              changeValue(nrAreaCultivo, 'nrAreaCultivo');
              changeModulo(parseFloat(nrAreaCultivo / values.nrModuloFiscalFator).toFixed(3));
            }}
            value={values.nrAreaCultivo}
            label={formatMessage('produtores.areaCultivo')}
            className={classes.textField}
            InputLabelProps={{
              className: classes.font
            }}
            error={
              (errors.nrAreaCultivo && touched.nrAreaCultivo) ||
              (!values.nrAreaCultivo && errors.nrAreaCultivo)
            }
            helperText={touched.nrAreaCultivo ? errors.nrAreaCultivo : ''}
          />
        </Grid>

        <Grid item xs={2}>
          <FormControl className={classes.form} error={errors.tpUnidade && touched.tpUnidade}>
            <InputLabel htmlFor='tpUnidade-helper' error={errors.tpUnidade && touched.tpUnidade}>
              {formatMessage('produtores.dsUnMedida')}
            </InputLabel>
            <Select
              style={{
                marginLeft: 0,
                fontSize: 14,
                width: '100%',
                position: 'relative',
                bottom: '1px'
              }}
              SelectDisplayProps={{
                style: {
                  width: '100%',
                  backgroundColor: 'transparent'
                }
              }}
              value={values.tpUnidade}
              onChange={event => setFieldValue('tpUnidade', event.target.value)}
              onBlur={event =>
                changeToNrAreaCultivo(values.nrAreaCultivo, event.target.value, values.nrModuloFiscal)
              }
              error={errors.tpUnidade && touched.tpUnidade}
              input={<Input id='tpUnidade-error' />}
              inputProps={{
                name: 'tpUnidade',
                id: 'tpUnidade-helper'
              }}
            >
              {itensUniMedida.map(item => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {errors.tpUnidade && touched.tpUnidade && (
              <FormHelperText> {errors.tpUnidade} </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FastField name='nrLatitude'>
            {({ field, form: { touched, errors } }) => (
              <TextFieldWithMask
                {...field}
                tipoMascara={3}
                numberDecimalScale={8}
                separator={true}
                showThousandSeparator={true}
                id='nrLatitude'
                name='nrLatitude'
                onChange={value => {
                  setFieldValue('nrLatitude', value.target.value);
                }}
                onBlur={e => changeValue(e.target.value, 'nrLatitude')}
                value={values.nrLatitude}
                label={formatMessage('produtores.latitude')}
                className={classes.textField}
                InputLabelProps={{
                  className: classes.font
                }}
                error={
                  (errors.nrLatitude && touched.nrLatitude) || (!values.nrLongitude && errors.nrLatitude)
                }
                helperText={touched.nrLatitude ? errors.nrLatitude : ''}
              />
            )}
          </FastField>
        </Grid>

        <Grid item xs={2}>
          <FastField name='nrLongitude'>
            {({ field, form: { touched, errors } }) => (
              <TextFieldWithMask
                tipoMascara={3}
                separator={true}
                numberDecimalScale={8}
                showThousandSeparator={true}
                id='nrLongitude'
                name='nrLongitude'
                onChange={value => {
                  setFieldValue('nrLongitude', value.target.value);
                }}
                onBlur={e => changeValue(e.target.value, 'nrLongitude')}
                value={values.nrLongitude}
                label={formatMessage('produtores.longitude')}
                className={classes.textField}
                InputLabelProps={{
                  className: classes.font
                }}
                error={errors.nrLongitude && touched.nrLongitude}
                helperText={touched.nrLongitude ? errors.nrLongitude : ''}
              />
            )}
          </FastField>
        </Grid>
      </Grid>

      <div style={{ height: 70 }}>
        <Grid container spacing={16}>
          <Grid item xs={3}>
            <Autocomplete
              disabled={tpPerfil === 3}
              itens={estadoList}
              campoOp='nmUf'
              campoInput='nmUf'
              campoChave='idUf'
              label={formatMessage('produtores.estado')}
              name='estado'
              error={errors.estado && touched.estado}
              helperText={touched.estado ? errors.estado : ''}
              onChangeOwn={value => onChangeAutocomplete(value, 'estado')}
              onChangeAutoComplete={validarInicializacaoAutoCompleteEstado}
              value={estado}
              key={estado ? estado.idUf : 0}
              valueAutoComplete={estado}
              valueSelect={estado ? estado.nmUf : null}
            />
          </Grid>

          <Grid item xs={2}>
            <Autocomplete
              disabled={!estado || tpPerfil === 3}
              itens={municipioList}
              campoOp='nmMunicipio'
              campoInput='nmMunicipio'
              campoChave='idMunicipio'
              label={formatMessage('produtores.municipio')}
              name='municipio'
              error={errors.municipio && touched.municipio}
              helperText={touched.municipio ? errors.municipio : ''}
              key={municipio ? municipio.idMunicipio : 0}
              onBlurOwn={() => {}}
              onChangeOwn={value => onChangeAutocomplete(value, 'municipio')}
              onChangeAutoComplete={validarInicializacaoAutoCompleteMunicipio}
              value={municipio}
              valueAutoComplete={municipio}
              valueSelect={municipio ? municipio.nmMunicipio : null}
            />
          </Grid>

          <Grid item xs={2}>
            <Autocomplete
              disabled={!municipio || tpPerfil === 3}
              itens={comunidadeList}
              campoOp='nmComunidade'
              campoInput='nmComunidade'
              campoChave='idComunidade'
              label={formatMessage('produtores.comunidade')}
              name='comunidade'
              onBlurOwn={() => {}}
              key={comunidade ? comunidade.idComunidade : 0}
              onChangeOwn={value => onChangeAutocomplete(value, 'comunidade')}
              onChangeAutoComplete={tratarValorComunidade}
              value={comunidade}
              valueAutoComplete={comunidade}
              valueSelect={comunidade ? comunidade.nmComunidade : null}
            />
          </Grid>

          <Grid item xs={2}>
            <TextFieldWithMask
              tipoMascara={3}
              numberDecimalScale={8}
              separator={true}
              showThousandSeparator={true}
              id='nrModuloFiscal'
              name='nrModuloFiscal'
              onChange={value => {
                setFieldValue('nrModuloFiscal', value.target.value);
              }}
              value={values.nrModuloFiscal}
              label={formatMessage('produtores.moduloFiscal')}
              className={classes.textField}
              InputLabelProps={{
                className: classes.font
              }}
              error={
                (errors.nrModuloFiscal && touched.nrModuloFiscal) ||
                (!values.nrModuloFiscal && errors.nrModuloFiscal)
              }
              helperText={touched.nrModuloFiscal ? errors.nrModuloFiscal : ''}
            />
          </Grid>

          <Grid item xs={2}>
            <TextFieldWithMask
              tipoMascara={3}
              disabled={true}
              numberDecimalScale={8}
              separator={true}
              showThousandSeparator={true}
              id='nrModuloFiscalFator'
              name='nrModuloFiscalFator'
              onChange={value => {
                setFieldValue('nrModuloFiscalFator', value.target.value);
              }}
              value={values.nrModuloFiscalFator}
              label={formatMessage('produtores.fator')}
              className={classes.textField}
              InputLabelProps={{
                className: classes.font
              }}
              error={
                (errors.nrModuloFiscalFator && touched.nrModuloFiscalFator) ||
                (!values.nrModuloFiscalFator && errors.nrModuloFiscalFator)
              }
              helperText={touched.nrModuloFiscalFator ? errors.nrModuloFiscalFator : ''}
            />
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default Identificacao;
