export default {
  uploadFiles: {
    removeArquivo: 'Do you want to delete the selected file?',
    nao: 'No',
    excluir: 'Delete',
    erroSubirArquivo: 'One or more files were not sent because they are larger than 20MB',
    anexarArquivo: 'Drop the files or click here to attach them',
    arquivoMaximo: '(files up to 20MB)'
  }
};
