import formatMessage from './../i18n/formatMessage';
import InsumosEnum from '@resources/InsumosEnum';

export default [
  {
    id: InsumosEnum.FERTILIZANTE_FOLIAR,
    name: formatMessage('acompanhamentoProdutor.fertilizanteFoliar')
  },
  {
    id: InsumosEnum.ACARICIDA,
    name: formatMessage('acompanhamentoProdutor.acaricida')
  },
  {
    id: InsumosEnum.ACARICIDA_MICROBIOLOGICO,
    name: formatMessage('acompanhamentoProdutor.acaricidaMicrobiologico')
  },
  {
    id: InsumosEnum.BACTERICIDA,
    name: formatMessage('acompanhamentoProdutor.bactericida')
  },
  {
    id: InsumosEnum.AGENTE_BIOLOGICO_CONTROLE,
    name: formatMessage('acompanhamentoProdutor.agenteBiologicoControle')
  },
  {
    id: InsumosEnum.BACTERICIDA_MICROBIOLOGICO,
    name: formatMessage('acompanhamentoProdutor.bactericidaMicrobiologico')
  },
  {
    id: InsumosEnum.HERBICIDA,
    name: formatMessage('acompanhamentoProdutor.herbicida')
  },
  {
    id: InsumosEnum.FUNGICIDA,
    name: formatMessage('acompanhamentoProdutor.fungicida')
  },
  {
    id: InsumosEnum.FUNGICIDA_MICROBIOLOGICO,
    name: formatMessage('acompanhamentoProdutor.fungicidaMicrobiologico')
  },
  {
    id: InsumosEnum.INSETICIDA,
    name: formatMessage('acompanhamentoProdutor.inseticida')
  },
  {
    id: InsumosEnum.INSETICIDA_MICROBIOLOGICO,
    name: formatMessage('acompanhamentoProdutor.inseticidaMicrobiologico')
  },
  {
    id: InsumosEnum.NEMATICIDA,
    name: formatMessage('acompanhamentoProdutor.nematicida')
  },
  {
    id: InsumosEnum.NEMATICIDA_MICROBIOLOGICO,
    name: formatMessage('acompanhamentoProdutor.nematicidaMicrobiologico')
  },
  {
    id: InsumosEnum.OLEOS_ADJUVANTES,
    name: formatMessage('acompanhamentoProdutor.oleosAdjuvantes')
  },
  {
    id: InsumosEnum.FERTILIZANTE_QUIMICO,
    name: formatMessage('acompanhamentoProdutor.fertilizanteQuimico')
  },
  {
    id: InsumosEnum.FERTILIZANTE_ORGANICO,
    name: formatMessage('acompanhamentoProdutor.fertilizanteOrganico')
  },
  {
    id: InsumosEnum.HOMEOPATICO,
    name: formatMessage('acompanhamentoProdutor.homeopaticos')
  },
  {
    id: InsumosEnum.INOCULANTE,
    name: formatMessage('acompanhamentoProdutor.inoculantes')
  }
];
