export default {
  visitasProdutor: {
    resumoVisitas: 'Visitas por productor',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    tecnico: 'Técnico',
    identificacao: 'Identificación',
    campoObrigatorio: 'Campo obligatorio',
    consultar: 'Consultar',
    visitasPendentes: 'Visitas pendientes',
    visitasRealizadas: 'Visitas realizadas',
    visitasAgendadas: 'Visitas programadas',
    produtor: 'Productor',
    ultimaVisita: 'Última visita',
    falhaAoPesquisarVisita: 'Falla al buscar seguimiento',
    falhaAoCarregar: 'Falla al cargar visitas',
    dataMinima: 'Fecha inválida',
    horaFinal: 'Hora de finalización',
    horaInicio: 'Hora de inicio',
    pendentes: 'Pendiente',
    agendadas: 'Programado',
    realizadas: 'Consumado',
    filtros: 'Filtros',
    data: 'Fecha',
    dadosAgenda: 'Datos del calendario',
    dadosVisita: 'Detalles de la visita',
    semVisitas: 'No hay visitas en 14 días',
    a: 'a'
  }
};
