import URL from './URL';
import axios from 'axios';

async function findTalhaoByIdHandler(idProdutor) {
  return await axios.get(`${URL.API}/talhao/infosatelite-relacionado/${idProdutor}/`);
}

async function findAllTalhaoByProdutorHandler(idProdutor) {
  return await axios.get(`${URL.API}//talhao/produtor/${idProdutor}`);
}

export default {
  findTalhaoById: findTalhaoByIdHandler,
  findAllTalhaoByProdutor: findAllTalhaoByProdutorHandler
};
