export default {
  tecnico: {
    tecnico: "Técnico",
    nome: "Nome",
    email: "E-mail",
    telefone: "Telefone",
    situacao: "Situação",
    ativo: "Ativo",
    inativo: "Inativo",
    campoObrigatorio: "Campo obrigatório",
    identificacao: "Identificação",
    cadastro: "Cadastro de técnicos",
    salvar: "Salvar",
    cancelar: "Cancelar",
    emailInvalido: "E-mail inválido",
    naoFoiPossivelInserir: "Não foi possível inserir o técnico",
    salvando: "Salvando",
    atualizando: "Atualizando",
    cadastradoComSucesso: "Técnico cadastrado com sucesso",
    editadoComSucesso: "Técnico editado com sucesso",
    falhaAoCarregar: "Falha ao carregar técnico",
    naoFoiPossivelEditar: "Não foi possivel editar técnico",
    emailDuplicado: "Este email já está em uso",
    numeroMaximoTecnicos: "O limite de técnicos do seu plano foi atingido",
    falhaAoExcluirTecnico: "Falha ao excluir técnico",
    agendaRelacionadaTecnico: "Existem uma ou mais agendas relacionadas ao técnico",
    tecnicoExcluidoComSucesso: "Técnico excluido com sucesso",
    excluir: "Excluir",
    excluindo: "Excluindo",
    desejaExcluir: "Deseja excluir?",
    nao: "Não",
    telefoneInvalido: "Telefone inválido"
  }
};
