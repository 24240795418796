import formatMessage from './i18n/formatMessage';

const headDatatable = [
  {
    field: 'nmAgencia',
    headerName: formatMessage('agencia.nome'),
    col: 3,
    sort: 'asc'
  },
  {
    field: 'cdAgencia',
    headerName: formatMessage('agencia.codigo'),
    col: 3
  },
  {
    field: 'sgUf',
    headerName: formatMessage('agencia.estado'),
    col: 2
  },
  {
    field: 'nmMunicipio',
    headerName: formatMessage('agencia.cidade'),
    col: 2
  },
  {
    valueGetter: args =>
      args.node.data.stAgencia === 1 ? formatMessage('agencia.ativo') : formatMessage('agencia.inativo'),
    headerName: formatMessage('agencia.status'),
    col: 2
  }
];

const filtersConfig = [
  {
    label: formatMessage('agencia.nome'),
    field: 'nmAgencia',
    type: 'string'
  },
  {
    label: formatMessage('agencia.codigo'),
    field: 'cdAgencia',
    type: 'string'
  },
  {
    label: formatMessage('agencia.estado'),
    field: 'sgUf',
    type: 'string'
  },
  {
    label: formatMessage('agencia.cidade'),
    field: 'nmMunicipio',
    type: 'string'
  },
  {
    label: formatMessage('agencia.status'),
    field: 'stAgencia',
    type: 'enum',
    options: [
      {
        label: formatMessage('agencia.ativo'),
        field: 'Ativo',
        value: '1'
      },
      {
        label: formatMessage('agencia.inativo'),
        field: 'Inativo',
        value: '0'
      }
    ]
  }
];

export { headDatatable, filtersConfig };
