export default {
  sazonalidade: {
    sazonalidade: 'Estacionalidad',
    cadastroSazonalidade: 'Registro de estacionalidad',
    minimoSazonalidades: 'Agregue al menos 1 estacionalidad',
    sazonalidadeSalvaComSucesso: 'Estacionales guardados con éxito',
    erroAoSalvarSazonalidades: 'Se produjo un error al guardar la estacionalidad',
    sazonalidades: 'Estacionalidad',
    culturas: 'Culturas',
    descricao: 'Descripción',
    cultura: 'Cultura',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    campoObrigatorio: 'Campo obligatorio'
  }
};
