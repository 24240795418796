import URL from './URL';
import axios from 'axios';

async function findCulturaWithSazonalidades(idCultura) {
  return await axios.get(`${URL.API}/sazonalidade/cultura/${idCultura}`);
}

async function updateCulturaWithSazonalidades(cultura) {
  return await axios.put(`${URL.API}/sazonalidade`, cultura);
}

async function findAllSazonalidades() {
  return await axios.get(`${URL.API}/sazonalidade`);
}

export { findCulturaWithSazonalidades, updateCulturaWithSazonalidades, findAllSazonalidades };
