import React, { useState, useEffect } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '@styles/Alerts.css';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import Localizacao from '@resources/Localizacao';
import { style } from './Municipios.styles';
import formatMessage from './i18n/formatMessage';

const headDataTable = [
  {
    field: 'nmMunicipio',
    headerName: formatMessage('municipio.nome'),
    sort: 'asc',
    col: 6
  },
  {
    field: 'uf.nmUf',
    headerName: formatMessage('municipio.uf'),
    col: 3
  },
  {
    headerName: formatMessage('municipio.moduloFiscal'),
    valueGetter: args => {
      if (args.node.data.nrModuloFiscal)
        return `${args.node.data.nrModuloFiscal.toLocaleString('pt-br', { minimumFractionDigits: 0 })}`;

      return '';
    },
    col: 3
  }
];

const filtrosCard = [
  {
    label: formatMessage('municipio.nome'),
    field: 'nmMunicipio',
    type: 'string'
  },
  {
    label: formatMessage('municipio.uf'),
    field: 'estado',
    type: 'string'
  },
  {
    label: formatMessage('municipio.moduloFiscal'),
    field: 'nrModuloFiscal',
    type: 'string'
  }
];

/*
  Tela de listagem de municipios
*/
function Municipios(props) {
  const { chipData, itensState, dispatch, classes } = props;
  const [itemSelect, setItemSelect] = useState(null);
  const [disabledEdit, setDisabledEdit] = useState(true);
  const [dataInTable, setDataInTable] = useState([]);
  const [dataAppSync, setDataAppSync] = useState([]);
  const [loading, setLoading] = useState(true);
  const pagination = {
    length: dataInTable.length,
    page: 0
  };

  useEffect(() => {
    const title = 'municipio.municipio';
    if (itensState.name !== title) {
      dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    onFilter(chipData);
  }, []);

  function selectChange(select) {
    if (select) {
      setItemSelect(select);
      setDisabledEdit(false);
    } else {
      setItemSelect(null);
      setDisabledEdit(true);
    }
  }

  function clickEditar() {
    props.history.push('/app/municipios/editar/' + itemSelect.idMunicipio);
  }

  function onFilterChanged(dataChips) {
    setDataInTable(FilterUtil.applyFilters(dataAppSync, dataChips));
  }

  function onFilter(dataChips) {
    dataChips = dataChips || [];

    Localizacao.findAllMunicipios()
      .then(querySnapshot => {
        var data = querySnapshot.data;

        data.forEach(query => {
          query.estado = query.uf.nmUf;
        });

        setDataAppSync(data);
        setDataInTable(FilterUtil.applyFilters(data, dataChips));
        setLoading(false);
      })
      .catch(() => {
        setDataAppSync([]);
        setLoading(false);
      });
  }

  return (
    <div className={classes.cardAll}>
      {loading && <Loading />}
      <CardFilters
        filtros={filtrosCard}
        onFilter={onFilter}
        onFilterChanged={onFilterChanged}
        usePaddings={false}
        viewKey='municipios'
      />
      <GroupButtonGrid
        showAdd={false}
        showEdit={true}
        disableEdit={disabledEdit}
        onClickEdit={clickEditar}
      />
      <div className={classes.cardDataTable}>
        <DataTable
          data={dataInTable}
          columns={headDataTable}
          selectChange={selectChange}
          showPagination={true}
          pagination={pagination}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'municipios';
  })[0].content
});

let enhaced = withStyles(style)(Municipios);
export default withRouter(connect(mapStateToProps)(enhaced));
