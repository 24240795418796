import React, { Component } from 'react';
import formatMessage from '../i18n/formatMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@components/Card/Card';

/**
 * Componente da tab de Stand Final do cadastro de Plantio
 *
 * @author Bruno Eduardo
 * @class StandFinal
 * @extends {Component} - Componente React
 */
class StandFinal extends Component {
  constructor(props) {
    super(props);

    this.handleBlurAndPersist = this.handleBlurAndPersist.bind(this);
  }

  /**
   * Faz o handleBlur do campo e persiste o dado
   *
   * @param {*} e - Blur event
   * @param {String} [campo=null] - Campo para fazer o handleBlur
   * @param {Object} additionalValues - Valores adicionais para persistir
   */
  handleBlurAndPersist(e, campo = null, additionalValues = {}) {
    this.props.persist({ ...this.values, ...additionalValues });

    if (campo) {
      this.handleBlur(campo)(e);
    } else {
      this.handleBlur(e);
    }
  }

  render() {
    return (
      <Formik
        initialValues={this.props.values}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={false}
        onSubmit={() => {}}
        validationSchema={Yup.object().shape({
          nrQuantidadeStandFinal: Yup.number(),
          tpUnMedidaStandFinal: Yup.number()
        })}
        render={({
          values,
          handleSubmit,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          submitForm
        }) => {
          this.values = values;
          this.handleSubmit = submitForm;
          this.setFieldValue = setFieldValue;
          this.handleBlur = handleBlur;

          return (
            <Card
              withOutBorderRadius
              title={formatMessage('plantios.standFinal')}
              height='auto'
              style={{ minHeight: 190, maxHeight: 190 }}
            >
              <Grid container>
                <Grid item xs={3} style={{ paddingRight: 15 }}>
                  <TextFieldWithMask
                    tipoMascara={3}
                    numberDecimalScale={3}
                    showThousandSeparator
                    id='nrQuantidadeStandFinal'
                    name='nrQuantidadeStandFinal'
                    onChange={e =>
                      setFieldValue(
                        'nrQuantidadeStandFinal',
                        e.target.value !== '' ? +e.target.value : e.target.value
                      )
                    }
                    onBlur={e => {
                      if (
                        values.tpUnMedidaStandFinal === undefined ||
                        values.tpUnMedidaStandFinal === ''
                      ) {
                        setFieldValue('tpUnMedidaStandFinal', 0);
                        this.handleBlurAndPersist(e, 'nrQuantidadeStandFinal', {
                          tpUnMedidaStandFinal: 0
                        });
                      } else {
                        this.handleBlurAndPersist(e, 'nrQuantidadeStandFinal');
                      }
                    }}
                    value={values.nrQuantidadeStandFinal}
                    label={formatMessage('plantios.quantidadePlantas')}
                    error={errors.nrQuantidadeStandFinal && touched.nrQuantidadeStandFinal}
                    helperText={touched.nrQuantidadeStandFinal ? errors.nrQuantidadeStandFinal : ''}
                    disabled={this.props.infoUsuario.tpPerfil === 3}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl
                    error={errors.tpUnMedidaStandFinal && touched.tpUnMedidaStandFinal}
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      htmlFor='tpUnMedidaStandFinal'
                      error={errors.tpUnMedidaStandFinal && touched.tpUnMedidaStandFinal}
                    >
                      {formatMessage('plantios.unMedida')}
                    </InputLabel>

                    <Select
                      value={values.nrQuantidadeStandFinal ? '' + values.tpUnMedidaStandFinal : ''}
                      error={errors.tpUnMedidaStandFinal && touched.tpUnMedidaStandFinal}
                      onChange={e =>
                        setFieldValue(
                          'tpUnMedidaStandFinal',
                          e.target.value !== '' ? +e.target.value : e.target.value
                        )
                      }
                      onBlur={this.handleBlurAndPersist}
                      name='tpUnMedidaStandFinal'
                      id='tpUnMedidaStandFinal'
                      disabled={!values.nrQuantidadeStandFinal || this.props.infoUsuario.tpPerfil === 3}
                    >
                      {[
                        formatMessage('plantios.acre'),
                        formatMessage('plantios.alqueire'),
                        formatMessage('plantios.hectare'),
                        'm²',
                        formatMessage('plantios.metro')
                      ].map((item, i) => (
                        <MenuItem key={i} value={'' + i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.tpUnMedidaStandFinal && touched.tpUnMedidaStandFinal && (
                      <FormHelperText>{errors.tpUnMedidaStandFinal}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
          );
        }}
      />
    );
  }
}

export default StandFinal;
