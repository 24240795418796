export default {
    telaLogin : {
        login: 'Login',
        esqueceuSenha: 'Forget your password?',
        enviar: 'send',
        campoObrigatorio: 'Required Field',
        emailInvalido: 'Invalid Email',
        senhaMinimo: 'The password must be least 6 character long',
        retornarLogin: 'Back for Login',
        senha: 'Password *',
        usuarioIncorreto: 'User or Password incorrect',
        falhaLogin: 'Failed to Login',
        falhaLoginMessage: 'Failed to Login',
        emailEnviado: 'An email has been sent to you to change your password',
        emailEnviadoMessage: 'E-mail send',
        usuarioNaoEncontrado: 'User not found',
        falhaRecuperarSenha: 'Failed to retrieve password',
        emailReenviado: 'A password recovery email has already been sent',
        falhaRecuperarSenhaMensagem: 'Failed to retrieve password',
        falhaUsuarioInativo: 'User is inactive',
        falhaClienteInativo: 'Client is inactive',
        usuarioNaoConfirmado: 'Unconfirmed user'
    }
}