import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import formatMessage from '../i18n/formatMessage';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import * as Yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import EditarPragas from './EditarPragas';
import { style, theme } from '../AcompanhamentoProdutor.styles';
import SeveridadeEnum from './../enumerations/SeveridadeEnum';
import { PragasData } from './../Header/DataTableHeader';
import { swalError, swalOptions, swalSucess } from './../utils/SwalOptions';

function LancamentoAplicacao(props) {
  const {
    classes,
    itemSelectProdutos,
    lastNumber,
    clickShowButton,
    onSalvarAmostras,
    talhaoList,
    plantio
  } = props;
  const [idAtividadeAmostra, setIdAtividadeAmostra] = useState('');
  const [tpTalhao, setTpTalhao] = useState('');
  const [editPragas, setEditPragas] = useState(false);
  const [valueEditar, setValueEditar] = useState({
    severidade: '',
    nrQuantidade: ''
  });
  const [itemSelect, setItemSelect] = useState(null);
  const [disabledEdit, setDisabledEdit] = useState(true);
  const [amostraList, setAmostraList] = useState([]);

  useEffect(() => {
    if (itemSelectProdutos !== undefined) {
      setIdAtividadeAmostra(itemSelectProdutos.idAtividadeAmostra + 1);
      setTpTalhao(itemSelectProdutos.talhao.idTalhao);
      setAmostraList(itemSelectProdutos.atividadeAmostraQuantidadeList);
    } else {
      setIdAtividadeAmostra(lastNumber + 1);
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Formik
        initialValues={{
          tpTalhao: tpTalhao || ''
        }}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={onSave}
        validationSchema={Yup.object().shape({
          tpTalhao: Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio'))
        })}
        render={({ values, handleSubmit, errors, touched, setFieldValue }) => (
          <div className={classes.cardAllMonitoramento}>
            <CardCadastro
              height={'100%'}
              titulo={getTitleCard()}
              styleHeader={{ minHeight: 60 }}
              botoes={[
                <Button variant='contained' color='inherit' onClick={() => clickShowButton(true)}>
                  {formatMessage('acompanhamentoProdutor.cancelar')}
                </Button>,
                <Button color='secondary' variant='contained' onClick={handleSubmit}>
                  {formatMessage('acompanhamentoProdutor.confirmar')}
                </Button>
              ]}
            >
              <div className={classes.cardCadastroMonitoramento}>
                <form autoComplete='off'>
                  <Grid container spacing={16}>
                    <Grid item xs={3}>
                      <FormControl className={classes.form} error={errors.tpTalhao && touched.tpTalhao}>
                        <InputLabel
                          htmlFor='tpTalhao-helper'
                          error={errors.tpTalhao && touched.tpTalhao}
                          className={classes.font}
                        >
                          {formatMessage('acompanhamentoProdutor.talhao')}
                        </InputLabel>
                        <Select
                          className={classes.selectFitossanidade}
                          SelectDisplayProps={{
                            style: {
                              width: '100%',
                              backgroundColor: 'transparent'
                            }
                          }}
                          value={values.tpTalhao}
                          onChange={value => {
                            setFieldValue('tpTalhao', value.target.value);
                            setTpTalhao(value.target.value);
                          }}
                          input={<Input id='tpTalhao-error' />}
                          inputProps={{
                            className: classes.font,
                            name: 'tpTalhao',
                            id: 'tpTalhao-helper'
                          }}
                        >
                          {talhaoList.map(talhao => (
                            <MenuItem
                              value={talhao.idTalhao}
                              key={talhao.idTalhao}
                              className={classes.font}
                            >
                              {talhao.dsTalhao}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.tpTalhao && touched.tpTalhao && (
                          <FormHelperText>{errors.tpTalhao}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <EditarPragas plantio={plantio} valueEditar={valueEditar} onSave={savePragas} />
                </form>

                <div className={classes.cardDataTableMonitoramento}>
                  <GroupButtonGrid
                    withoutMargin={true}
                    showAdd={false}
                    showEdit={true}
                    showDelete={true}
                    disableEdit={disabledEdit}
                    disableDelete={disabledEdit}
                    onClickEdit={clickEditar}
                    onClickDelete={confirmacaoExcluirPraga}
                  />

                  <DataTable
                    noBorderWidth={true}
                    noMargin={true}
                    data={amostraList}
                    columns={PragasData}
                    selectChange={selectChange}
                    sortable={true}
                    showPagination={false}
                  />
                </div>
              </div>
            </CardCadastro>
          </div>
        )}
      />
    </MuiThemeProvider>
  );

  /**
   * Busca o título do card de monitoramento
   * @returns título
   */
  function getTitleCard() {
    return formatMessage('acompanhamentoProdutor.adicionarAmostras') + ' #' + idAtividadeAmostra;
  }

  /**
   * Configura o item clicado pra editar
   */
  function clickEditar() {
    setEditPragas(true);
    setValueEditar(itemSelect);
  }

  /**
   * Swal de confirmação de delete
   */
  function confirmacaoExcluirPraga() {
    swalOptions('acompanhamentoProdutor.desejaExcluirPraga')
      .then(willDelete => {
        if (willDelete) {
          deletaPraga();
        } else {
          setDisabledEdit(false);
        }
      })
      .catch(() => {
        swalError('acompanhamentoProdutor.falhaAoExcluirPraga');
      });
  }

  /**
   * Exclui a praga selecionada
   */
  function deletaPraga() {
    const position = amostraList.indexOf(itemSelect);

    let newData = amostraList;
    newData.splice(position, 1);
    newData = [...newData];

    setAmostraList(newData);
    swalSucess('acompanhamentoProdutor.pragaRemovidaComSucesso');
  }

  /**
   * Item selecionado
   */
  function selectChange(select) {
    if (select) {
      setItemSelect(select);
      setDisabledEdit(false);
    } else {
      setItemSelect(null);
      setDisabledEdit(true);
    }
  }

  /**
   * Salva uma nova praga
   * @param {*} values valores a serem salvos
   */
  function savePragas(values) {
    const validation = amostraList.find(doc => {
      return doc.severidade.idSeveridade === values.severidade.idSeveridade;
    });

    const validationIdSeveridade =
      (itemSelect && itemSelect.severidade.idSeveridade) === values.severidade.idSeveridade;

    if (validation === undefined || validationIdSeveridade) {
      trataValoresSalvarPraga(values);
      return true;
    } else {
      swalError(formatMessage('acompanhamentoProdutor.pragaDuplicada'));
      return false;
    }
  }

  /**
   * Trata os valores para salvar uma praga
   * @param {*} values
   */
  function trataValoresSalvarPraga(values) {
    const nmTipo = SeveridadeEnum.find(doc => {
      return doc.id === values.severidade.tpSeveridade;
    });
    values.nmTipo = nmTipo.name;

    let dataAux = [];

    if (editPragas) {
      const position = dataAux.indexOf(valueEditar);

      dataAux[position] = values;
      dataAux = [...amostraList];
    } else {
      dataAux = amostraList.concat(values);
    }

    setAmostraList(dataAux);
    setValueEditar({
      severidade: '',
      nrQuantidade: ''
    });
    setEditPragas(false);
    setItemSelect(null);
  }

  /**
   * Salva uma nova amostra
   * @param {*} values
   */
  function onSave(values) {
    values.atividadeAmostraQuantidadeList = amostraList;
    values.talhao = talhaoList.find(doc => {
      return doc.idTalhao === tpTalhao;
    });
    values.stTalhao = 0;

    onSalvarAmostras(values, itemSelectProdutos !== undefined);
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(LancamentoAplicacao);
export default withRouter(connect(mapStateToProps)(enhaced));
