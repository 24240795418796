export default {
  analiseOGM: {
    analiseOGM: 'Analyze GMO',
    nao: 'Not',
    desejaExcluir: 'Do you want to delete?',
    excluir: 'Delete',
    analiseOGMRemovidoComSucesso: 'Analyze GMO successfully removed',
    falhaAoExcluirAnaliseOGM: 'Failed to delete analyze GMO',
    excluindo: 'Excluding',
    cultura: 'Culture',
    tipo: 'Type',
    situacao: 'Situation',
    nome: 'Name',
    analiseOGMCadastradoComSucesso: 'Analyze GMO successfully registered',
    analiseOGMEditadoComSucesso: 'Analyze GMO successfully edited',
    falhaAoCadastrarAnaliseOGM: 'Failed to register analyze GMO',
    falhaAoEditarAnaliseOGM: 'Failed to edit analyze GMO',
    falhaAoCarregarAnaliseOGM: 'Failed to load analyze GMO',
    salvar: 'Save',
    cancelar: 'Cancel',
    salvando: 'Saving',
    atualizando: 'Updating',
    cadastroDeAnaliseOGM: 'Analyze GMO registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    doencaSolo: 'Soil disease',
    doencaFoliar: 'Leaf disease',
    nematoide: 'Nematode',
    descricao: 'Description',
    ativo: 'Active',
    inativo: 'Inactive',
    pragas: 'Pests'
  }
};
