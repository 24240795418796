export default {
  clientes: {
    clientes: 'Clientes',
    razaoSocial: 'Razão social',
    nomeFantasia: 'Nome fantasia',
    cnpj: 'CNPJ',
    telefone: 'Telefone',
    email: 'E-mail',
    planosDeAcesso: 'Plano de acesso',
    produtoresAtivos: 'Produtores Ativos',
    motivo: 'Motivo da inativação',
    dataInativacao: 'Data da inativação',
    dataCadastro: 'Data de cadastro',
    ativo: 'Ativo',
    inativo: 'Inativo',
    endereco: 'Endereço',
    contatosAdicionais: 'Contatos Adicionais',
    usuarios: 'Usuários',
    identificacao: 'Identificação',
    cep: 'CEP',
    estado: 'Estado',
    cidade: 'Cidade',
    rua: 'Rua',
    numero: 'Número',
    complemento: 'Complemento',
    bairro: 'Bairro',
    departamento: 'Departamento',
    nome: 'Nome',
    perfil: 'Perfil',
    statusConta: 'Status da conta',
    situacao: 'Situação',
    identificacaoCliente: 'Identificação',
    cadastroCliente: 'Cadastro de clientes',
    gravar: 'Gravar',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    reenviarCodigo: 'Reenviar e-mail de ativação',
    principal: 'Principal',
    financeiro: 'Financeiro',
    entregas: 'Entregas',
    administrativo: 'Administrativo',
    gerencia: 'Gerência',
    ti: 'T.I.',
    tecnico: 'Técnico',
    revenda: 'Revenda',
    campoObrigatorio: 'Campo Obrigatório',
    emailInvalido: 'Email inválido',
    carregando: 'Carregando',
    clienteCadastrado: 'Cliente cadastrado com sucesso',
    clienteEditado: 'Cliente atualizado com sucesso',
    sucesso: 'Sucesso',
    erro: 'Erro',
    usuarioJaCadastradoTabela: 'Esse usuário já foi adicionado a lista de usuários',
    naoFoiPossivelInserirUsuario: 'Não foi possível inserir o usuário',
    naoFoiPossivelInserirEditar: 'Não foi possível editar o usuário',
    erroCadastroUsuario: 'Erro ao cadastrar usuário',
    usuarioCadastradoComSucesso: 'Usuário cadastrado com sucesso',
    clienteCadastradoComSucesso: 'Cliente cadastrado com sucesso',
    registrado: 'Registrado',
    confirmado: 'Confirmado',
    desabilitado: 'Desabilitado',
    admin: 'Administrador',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    usuariosNaoCadastrados: 'Lamento, não foi possível registrar os seguintes usuários:',
    clienteEditadoComSucesso: 'Cliente editado com sucesso',
    preRegistro: 'Pré-registro',
    produtor: 'Produtor',
    falhaAoCarregarClientes: 'Falha ao carregar clientes',
    numeroMaximoUsuarios: 'O limite de usuários desse plano foi atingido',
    desejaExcluir: 'Deseja excluir?',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    clienteExcluidoComSucesso: 'Cliente excluído com sucesso',
    falhaAoExcluirCliente: 'Falha ao excluir cliente',
    usuarioExcluidoComSucesso: 'Usuário excluído com sucesso',
    plano: 'Plano',
    telefoneInvalido: 'Telefone inválido',
    cnpjInvalido: 'CNPJ inválido',
    cepInvalido: 'CEP inválido',
    desejaExcluirEndereco: 'Deseja excluir endereço?',
    desejaExcluirContatosAdicionais: 'Deseja excluir contato adicional?',
    erroAoReenviarEmail: 'Falha ao reenviar email',
    codigoEnviadoComSucesso: 'Código enviado com sucesso',
    pais: 'País',
    cadastrarPais: 'Primeiro cadastre o país',
    cnpjDuplicado: 'CNPJ já cadastrado',
    erroAoEditar: 'Ocorreu um erro ao editar cliente',
    erroAoCadastrar: 'Ocorreu um erro ao cadastrar cliente',
    erroBuscarCliente: 'Ocorreu um erro ao buscar clientes',
    emailDuplicado: 'Email já está cadastrado',
    gerente: 'Gerente',
    representante: 'Representante',
    confirmar: 'Confirmar',
    salvarClienteSair: 'Existem informações não salvas, deseja salvar o cliente?',
    descartar: 'Descartar',
    agriculturaOrganica: 'Agricultura orgânica',
    cooperativa: 'Cooperativa',
    cooperado: 'Cooperado',
    tipoCooperativa: 'Tipo de cooperativa',
    central: 'Central',
    produtores: 'Produtores',
    produtoresComAcesso: 'Produtores com acesso ao sistema',
    erroAnexarFoto: 'Erro ao anexar foto'
  }
};
