import React, { Component } from 'react';
import formatMessage from '../i18n/formatMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@components/Card/Card';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';

/**
 * Componente da tab de Previsão de Colheita do cadastro de Plantio
 *
 * @author Bruno Eduardo
 * @class PrevisaoColheita
 * @extends {Component} - Componente React
 */
class PrevisaoColheita extends Component {
  constructor(props) {
    super(props);

    this.handleBlurAndPersist = this.handleBlurAndPersist.bind(this);
  }

  /**
   * Faz o handleBlur do campo e persiste o dado
   *
   * @param {*} e - Blur event
   * @param {String} [campo=null] - Campo para fazer o handleBlur
   * @param {Object} additionalValues - Valores adicionais para persistir
   */
  handleBlurAndPersist(e, campo = null, additionalValues = {}) {
    this.props.persist({ ...this.values, ...additionalValues });

    if (campo) {
      this.handleBlur(campo)(e);
    } else {
      this.handleBlur(e);
    }
  }

  render() {
    const dynamicStyleVisible = this.props.isVisible ? {} : { display: 'none' };

    return (
      <Formik
        initialValues={this.props.values}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={false}
        onSubmit={() => {}}
        validationSchema={Yup.lazy(values =>
          Yup.object().shape({
            nrDiasColheita: Yup.number(),
            nrProdutividadeEstimada:
              values.nrDiasColheita === undefined || values.nrDiasColheita === ''
                ? Yup.number()
                : Yup.number().required(formatMessage('plantios.campoObrigatorio')),
            tpUnidadeEstimada:
              values.nrDiasColheita === undefined || values.nrDiasColheita === ''
                ? Yup.number()
                : Yup.number().required(formatMessage('plantios.campoObrigatorio')),
            tpAreaEstimada:
              values.nrDiasColheita === undefined || values.nrDiasColheita === ''
                ? Yup.number()
                : Yup.number().required(formatMessage('plantios.campoObrigatorio'))
          })
        )}
        render={formikProps => {
          const { values, errors, touched, handleBlur, setFieldValue, submitForm } = formikProps;
          this.props.getFormikProps && this.props.getFormikProps(formikProps);
          this.values = values;
          this.handleSubmit = submitForm;
          this.setFieldValue = setFieldValue;
          this.handleBlur = handleBlur;

          return (
            <Card
              withOutBorderRadius
              title={formatMessage('plantios.previsaoColheita')}
              height='auto'
              style={{ minHeight: 190, maxHeight: 190, ...dynamicStyleVisible }}
            >
              <Grid container>
                <Grid item xs={2} style={{ paddingRight: 15 }}>
                  <TextFieldWithMask
                    tipoMascara={3}
                    numberDecimalScale={3}
                    showThousandSeparator
                    id='nrDiasColheita'
                    name='nrDiasColheita'
                    onChange={e =>
                      setFieldValue(
                        'nrDiasColheita',
                        e.target.value !== '' ? +e.target.value : e.target.value
                      )
                    }
                    onBlur={e => {
                      let dataEstimada = null;
                      if (this.props.dtPlantio) {
                        dataEstimada = new Date(this.props.dtPlantio);
                        dataEstimada.setDate(dataEstimada.getDate() + parseFloat(e.target.value));
                      }

                      const tpUnidadeEstimadaValue =
                        values.tpUnidadeEstimada === '' ? 0 : values.tpUnidadeEstimada;
                      const tpAreaEstimadaValue =
                        values.tpAreaEstimada === '' ? 0 : values.tpAreaEstimada;

                      setFieldValue('dtEstimada', dataEstimada);
                      setFieldValue('tpUnidadeEstimada', tpUnidadeEstimadaValue);
                      setFieldValue('tpAreaEstimada', tpAreaEstimadaValue);

                      this.handleBlurAndPersist(e, 'nrDiasColheita', {
                        dtEstimada: dataEstimada,
                        tpUnidadeEstimada: tpUnidadeEstimadaValue,
                        tpAreaEstimada: tpAreaEstimadaValue
                      });
                    }}
                    value={values.nrDiasColheita}
                    label={formatMessage('plantios.diasEstimados')}
                    error={errors.nrDiasColheita && touched.nrDiasColheita}
                    helperText={touched.nrDiasColheita ? errors.nrDiasColheita : ''}
                    disabled={this.props.infoUsuario.tpPerfil === 3}
                  />
                </Grid>

                <Grid item xs={2} style={{ paddingRight: 15 }}>
                  <DatePicker
                    disabled
                    locale={getUserLang()}
                    label={formatMessage('plantios.dataPrevista')}
                    valueDate={values.nrDiasColheita ? values.dtEstimada : null}
                    onChangeDatePicker={date => {}}
                  />
                </Grid>

                <Grid item xs={3} style={{ paddingRight: 15 }}>
                  <TextFieldWithMask
                    tipoMascara={3}
                    numberDecimalScale={3}
                    showThousandSeparator
                    id='nrProdutividadeEstimada'
                    name='nrProdutividadeEstimada'
                    onChange={e =>
                      setFieldValue(
                        'nrProdutividadeEstimada',
                        e.target.value !== '' ? +e.target.value : e.target.value
                      )
                    }
                    onBlur={e => this.handleBlurAndPersist(e, 'nrProdutividadeEstimada')}
                    value={values.nrDiasColheita ? values.nrProdutividadeEstimada : ''}
                    label={formatMessage('plantios.produtividadeEstimada')}
                    error={errors.nrProdutividadeEstimada && touched.nrProdutividadeEstimada}
                    helperText={touched.nrProdutividadeEstimada ? errors.nrProdutividadeEstimada : ''}
                    disabled={!values.nrDiasColheita || this.props.infoUsuario.tpPerfil === 3}
                  />
                </Grid>

                <Grid item xs={3} style={{ paddingRight: 15 }}>
                  <FormControl
                    error={errors.tpUnidadeEstimada && touched.tpUnidadeEstimada}
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      htmlFor='tpUnidadeEstimada'
                      error={errors.tpUnidadeEstimada && touched.tpUnidadeEstimada}
                    >
                      {formatMessage('plantios.unMedida')}
                    </InputLabel>

                    <Select
                      value={values.nrDiasColheita ? '' + values.tpUnidadeEstimada : ''}
                      error={errors.tpUnidadeEstimada && touched.tpUnidadeEstimada}
                      onChange={e =>
                        setFieldValue(
                          'tpUnidadeEstimada',
                          e.target.value !== '' ? +e.target.value : e.target.value
                        )
                      }
                      onBlur={this.handleBlurAndPersist}
                      name='tpUnidadeEstimada'
                      id='tpUnidadeEstimada'
                      disabled={!values.nrDiasColheita || this.props.infoUsuario.tpPerfil === 3}
                    >
                      {[
                        formatMessage('plantios.sacas'),
                        formatMessage('plantios.tonelada'),
                        formatMessage('plantios.kg')
                      ].map((item, i) => (
                        <MenuItem key={i} value={'' + i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.tpUnidadeEstimada && touched.tpUnidadeEstimada && (
                      <FormHelperText>{errors.tpUnidadeEstimada}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <FormControl
                    error={errors.tpAreaEstimada && touched.tpAreaEstimada}
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      htmlFor='tpAreaEstimada'
                      error={errors.tpAreaEstimada && touched.tpAreaEstimada}
                    >
                      {formatMessage('plantios.area')}
                    </InputLabel>

                    <Select
                      value={values.nrDiasColheita ? '' + values.tpAreaEstimada : ''}
                      error={errors.tpAreaEstimada && touched.tpAreaEstimada}
                      onChange={e =>
                        setFieldValue(
                          'tpAreaEstimada',
                          e.target.value !== '' ? +e.target.value : e.target.value
                        )
                      }
                      onBlur={this.handleBlurAndPersist}
                      name='tpAreaEstimada'
                      id='tpAreaEstimada'
                      disabled={!values.nrDiasColheita || this.props.infoUsuario.tpPerfil === 3}
                    >
                      {[
                        formatMessage('plantios.acre'),
                        formatMessage('plantios.alqueire'),
                        formatMessage('plantios.hectare'),
                        formatMessage('plantios.m2')
                      ].map((item, i) => (
                        <MenuItem key={i} value={'' + i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.tpAreaEstimada && touched.tpAreaEstimada && (
                      <FormHelperText>{errors.tpAreaEstimada}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
          );
        }}
      />
    );
  }
}

export default PrevisaoColheita;
