import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllRegiaosHandler() {
  return axios.get(api + '/regiao');
}

function findRegiaoById(idRegiao) {
  return axios.get(api + '/regiao/' + idRegiao);
}

function deleteRegiaoByIdHandler(idRegiao) {
  return axios.delete(api + '/regiao/' + idRegiao);
}

function addRegiaoHandler(values) {
  return axios.post(api + '/regiao', values);
}

function editRegiaoHandler(values) {
  return axios.put(api + '/regiao', values);
}

export default {
  findAllRegiao: findAllRegiaosHandler,
  findRegiaoById: findRegiaoById,
  deleteRegiaoById: deleteRegiaoByIdHandler,
  addRegiao: addRegiaoHandler,
  editRegiao: editRegiaoHandler
};
