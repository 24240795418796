export default {
  relatorio: {
    relatorio: 'Relatório',
    sintetico: 'Sintético',
    regiao: 'Região',
    municipio: 'Município',
    sim: 'Sim',
    nao: 'Não',
    carregando: 'Carregando...',
    gerandoRelatorio: 'Gerando relatório...',
    falhaAoGerarRelatorio: 'Falha ao gerar relatório',
    dataLancamento: 'Data de lançamento',
    tecnicoResponsavel: 'Técnico responsável',
    produtor: 'Produtor',
    uf: 'UF',
    decrescente: 'Decrescente',
    crescente: 'Crescente',
    media: 'Média'
  }
};
