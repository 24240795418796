export default {
  sancaoCategoria: {
    descricao: 'Descripción',
    dataCadastro: 'Fecha de Registro',
    erroAoBuscarDados: 'No se pudieron recuperar los datos',
    sancaoCategoria: 'Sansón',
    desejaExcluirSancaoCategoria: '¿Quieres eliminar esta sansón?',
    nao: 'No',
    excluir: 'Eliminar',
    sancaoCategoriaExcluida: 'Sansón eliminada correctamente',
    erroAoExcluir: 'No se pudo borrar la sansón',
    cadastroDeSancaoCategoria: 'Registro de sansón orgánico',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    salvar: 'Ahorrar',
    cancelar: 'Cancelar',
    sancaoCategoriaSalvoComSucesso: 'Sansón guardada con éxito',
    erroAoSalvar: 'No se pudo guardar la sansón',
    salvando: 'Saving',
    atualizando: 'Actualizando',
    produtorRelacionado: 'No fue posible excluir esta sansón porque hay un productor usándola',
    status: 'Situación',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nombre',
    diasResolucao: 'Dias para resolucion',
    sigla: 'Iniciales',
    tipoPenalidade: 'Tipo de penalización',
    nenhum: 'Ninguno',
    desclassificacaoSafra: 'Descalificación de cultivos',
    desclassificacaoTalhao: 'Desclasificación de campo'
  }
};
