export default {
  uploadFiles: {
    removeArquivo: '¿Desea eliminar el archivo seleccionado?',
    nao: 'No',
    excluir: 'Eliminar',
    erroSubirArquivo: 'No se enviaron uno o más archivos porque son más grandes que 20 MB',
    anexarArquivo: 'Suelta los archivos o haz clic aquí para adjuntarlos',
    arquivoMaximo: '(archivos de hasta 20 MB)'
  }
};
