export default {
  fertilizantesFoliares: {
    fertilizantesFoliaresRemovidoComSucesso: 'Fertilizante foliar removido com sucesso',
    falhaAoExcluirFertilizantesFoliares: 'Falha ao excluir fertilizante foliar',
    desejaExcluir: 'Deseja excluir o fertilizante foliar selecionado?',
    fertilizantesFoliares: 'Fertilizante foliar',
    cadastroDeFertilizantesFoliares: 'Cadastro de fertilizantes foliares',
    identificacaoDaFertilizantesFoliares: 'Identificação',
    fertilizantesFoliaresCadastradoComSucesso: 'Fertilizante foliar cadastrado com sucesso',
    fertilizantesFoliaresEditadoComSucesso: 'Fertilizante foliar editado com sucesso',
    falhaAoEditarFertilizantesFoliares: 'Falha ao editar fertilizante foliar',
    falhaAoCadastrarFertilizantesFoliares: 'Falha ao cadastrar fertilizante foliar',
    falhaAoCarregarFertilizantesFoliares: 'Falha ao carregar fertilizante foliar'
  }
};
