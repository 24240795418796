import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  button: {
    minHeight: 34,
    maxHeight: 34,
    minWidth: 34,
    maxWidth: 34,
    zIndex: 1
  },
  buttonGrid: {
    marginBottom: 9
  }
});
function ButtonGrid(props) {
  const { classes } = props;
  return props.show ? (
    <div
      className={classes.buttonGrid}
      style={{
        marginLeft: props.withoutMargin ? 0 : 15,
        marginTop: props.hiddenMargin ? 0 : 15
      }}
    >
      <Button
        variant='contained'
        className={classes.button}
        style={{
          backgroundColor: props.disabled ? '#95989A' : props.color,
          cursor: 'pointer',
          padding: 0
        }}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.iconSVG ? props.icon : <img src={props.icon} alt=' ' />}
      </Button>
    </div>
  ) : null;
}

ButtonGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ButtonGrid);
