import React from 'react';
import formatMessage from './../i18n/formatMessage';
import Grid from '@material-ui/core/Grid';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@components/Card/Card';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  }
});

/**
 * Componente da tab de Entrega efetiva do cadastro de Plantio
 *
 * @author Gabriela Farias
 * @class EntregaEfetiva
 * @extends {Component} - Componente React
 */
function EntregaEfetiva(props) {
  const { classes } = props;

  return (
    <Card
      withOutBorderRadius
      title={formatMessage('plantios.entregaEfetiva')}
      height='auto'
      style={{ minHeight: 190, maxHeight: 190, marginTop: 15 }}
    >
      <Grid container>
        <Grid item xs={4} style={{ paddingRight: 15 }}>
          <TextFieldWithMask
            tipoMascara={3}
            numberDecimalScale={2}
            showThousandSeparator
            id='nrEntregaEfetiva'
            name='nrEntregaEfetiva'
            onChange={e =>
              props.setFieldValue(
                'nrEntregaEfetiva',
                e.target.value !== '' ? +e.target.value : e.target.value
              )
            }
            onBlur={e => {
              const tpUnidadeEntregaEfetivaValue =
                props.values.tpUnidadeEntregaEfetiva === '' ? 0 : props.values.tpUnidadeEntregaEfetiva;

              props.setFieldValue('tpUnidadeEntregaEfetiva', tpUnidadeEntregaEfetivaValue);
              props.handleBlurAndPersist(e, 'nrEntregaEfetiva', {
                tpUnidadeEntregaEfetiva: tpUnidadeEntregaEfetivaValue
              });
            }}
            value={props.values.nrEntregaEfetiva}
            label={formatMessage('plantios.nrQuantidade')}
            error={props.errors.nrEntregaEfetiva && props.touched.nrEntregaEfetiva}
            helperText={props.touched.nrEntregaEfetiva ? props.errors.nrEntregaEfetiva : ''}
          />
        </Grid>

        <Grid item xs={4} style={{ paddingRight: 15 }}>
          <FormControl
            error={props.errors.tpUnidadeEntregaEfetiva && props.touched.tpUnidadeEntregaEfetiva}
            style={{ width: '100%' }}
          >
            <InputLabel
              htmlFor='tpUnidadeEntregaEfetiva'
              error={props.errors.tpUnidadeEntregaEfetiva && props.touched.tpUnidadeEntregaEfetiva}
            >
              {formatMessage('plantios.unMedida')}
            </InputLabel>

            <Select
              value={props.values.nrEntregaEfetiva ? '' + props.values.tpUnidadeEntregaEfetiva : ''}
              error={props.errors.tpUnidadeEntregaEfetiva && props.touched.tpUnidadeEntregaEfetiva}
              onChange={e =>
                props.setFieldValue(
                  'tpUnidadeEntregaEfetiva',
                  e.target.value !== '' ? +e.target.value : e.target.value
                )
              }
              onBlur={props.handleBlurAndPersist}
              name='tpUnidadeEntregaEfetiva'
              id='tpUnidadeEntregaEfetiva'
              disabled={!props.values.nrEntregaEfetiva || props.infoUsuario.tpPerfil === 3}
            >
              {[
                formatMessage('plantios.sacas'),
                formatMessage('plantios.tonelada'),
                formatMessage('plantios.kg')
              ].map((item, i) => (
                <MenuItem key={i} value={'' + i}>
                  {item}
                </MenuItem>
              ))}
            </Select>

            {props.errors.tpUnidadeEntregaEfetiva && props.touched.tpUnidadeEntregaEfetiva && (
              <FormHelperText>{props.errors.tpUnidadeEntregaEfetiva}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} style={{ paddingRight: 15 }}>
          <TextField
            id='dsCausaEntregaEfetiva'
            name='dsCausaEntregaEfetiva'
            value={props.values.dsCausaEntregaEfetiva}
            label={formatMessage('plantios.causa')}
            className={classes.textField}
            onBlur={props.handleBlurAndPersist}
            onChange={e => props.setFieldValue('dsCausaEntregaEfetiva', e.target.value)}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default withStyles(style)(EntregaEfetiva);
