import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllAcompanhamentoHandler(idAcompanhamento) {
  return axios.get(api + '/atividade/timeline/' + idAcompanhamento);
}

function addAcompanhamentoHandler(values) {
  return axios.post(api + '/atividade', values);
}

function editAcompanhamentoHandler(values) {
  return axios.put(api + '/atividade', values);
}

export default {
  findAllAcompanhamento: findAllAcompanhamentoHandler,
  addAcompanhamento: addAcompanhamentoHandler,
  editAcompanhamento: editAcompanhamentoHandler
};
