export default {
  produtores: {
    areaTotalNdviExcedida: 'A área total disponível para monitoramento NDVI foi excedida',
    erroCriarPoligono: 'Ocorreu um erro ao criar o polígono',
    tamanhoMinino: 'A área mínima de um polígono é 1 ',
    emailInvalido: 'E-mail inválido',
    ecosocialObrigatorio: 'Adicione no mínimo um tipo ecosocial',
    descFertilidade: 'Fertilidade',
    selecioneUmTalhaoPeloMenos: 'Adicione ao menos um talhão',
    edicaoDeTalhao: 'Edição de talhão',
    cadastroDeTalhao: 'Cadastro de talhão',
    detinacaoAdequadaResiduos: 'Destinação adequada de resíduos',
    nomeCompleto: 'Insira o nome completo',
    nascimento: 'Nascimento',
    idade: 'Idade',
    cadastroAmbiental: 'Cadastro ambiental',
    edicaoAmbiental: 'Edição ambiental',
    cadastroProducaoAnimal: 'Cadastro de produção animal',
    edicaoProducaoAnimal: 'Edição de produção animal',
    cadastroColaborador: 'Cadastro de colaborador',
    edicaoColaborador: 'Edição de colaborador',
    cadastroFamiliar: 'Cadastro de familiar',
    edicaoFamiliar: 'Edição de familiar',
    cadastroAreaCultivo: 'Cadastro de área de cultivo',
    edicaoAreaCultivo: 'Edição de área de cultivo',
    cadastroProduto: 'Cadastro de produto',
    cadastroAnaliseRisco: 'Cadastro de análise de risco',
    edicaoAnaliseRisco: 'Edição de análise de risco',
    edicaoProduto: 'Edição de produto',
    solo: 'Solo',
    agua: 'Água',
    residual: 'Residual',
    desejaRemoverLancamento: 'Deseja remover este lançamento?',
    lancamentoExcluido: 'Lançamento removido',
    dataLancamento: 'Data de lançamento',
    destinacaoAdequadaResiduos: 'Destinação Adequada de resíduos',
    producaoFamiliar: 'Produção familiar',
    riscosAdicionaisRelevantes: 'Riscos adicionais relevantes',
    analiseDeRisco: 'Análise de risco',
    experienciaProdutor: 'Experiência do produtor da agricultura orgânica',
    resultadosAnteriores: 'Histórico, resultados anteriores',
    pressaoUsoDePesticidas: 'Pressão do uso de pesticidas na região',
    proximidadesLavouras: 'Proximidades/Barreiras com lavouras convencionais',
    suspeitaAplicacaoDireta: 'Suspeita de aplicação direta',
    tamanhoSafra: 'Tamanho da safra/colheita',
    riscoTrocaSafra: 'Risco de troca da safra por produto convencional',
    somaRiscos: 'Soma de riscos',
    derivaVentoOuAgua: 'Deriva por vento ou água/chuva',
    transitoVeiculos: 'Trânsito de veículos(estrada)',
    cultivosTransgenicos: 'Cultivos transgênicos de vizinhos',
    utilizacaoImplementosAgricolas: 'Utilização de implementos agrícolas compartilhados',
    comentariosGerais: 'Comentários gerais',
    nota: 'Nota',
    desejaExcluirFamiliar: 'Deseja excluir familiar?',
    familiarExcluido: 'Familiar excluído com sucesso!',
    desejaExcluirProduto: 'Deseja excluir produto?',
    produtoExcluido: 'Produto excluído com sucesso!',
    categoria: 'Categoria',
    acaros: 'Ácaros',
    bacterias: 'Bactérias',
    ervasDaninhas: 'Ervas daninhas',
    fungos: 'Fungos',
    insetos: 'Insetos',
    nematoides: 'Nematóides',
    foliaresAdjuvantes: 'Foliares e Adjuvantes',
    fertilizantes: 'Fertilizantes',
    fertilizanteQuimico: 'Fertilizantes químico',
    fertilizanteOrganico: 'Fertilizantes orgânico',
    naoSeAplica: 'Não se aplica',
    oleosAdjuvantes: 'Óleos adjuvantes',
    segregacaoInsumos: 'Segregação de insumos',
    cadastroDeProdutor: 'Cadastro de Produtor',
    produtores: 'Produtores',
    identificacao: ' Identificação',
    nome: 'Nome',
    sexo: 'Sexo',
    dataNascimento: 'Data de nascimento',
    escolaridade: 'Escolaridade',
    familiares: 'Familiares',
    editarFamiliar: 'Editar familiar',
    novoFamiliar: 'Cadastro de familiar',
    infraestruturas: 'Infraestrutura',
    tpArea: 'Tipo da área',
    anexoContrato: 'Anexo do contrato e registro',
    validadeContrato: 'Validade do contrato',
    termoCompromisso: 'Termo de compromisso de manejo orgânico',
    areaPropria: 'Área própria',
    arrendada: 'Arrendada',
    ambas: 'Ambas',
    aspectoLegal: 'Aspectos legais',
    masculino: 'Masculino',
    feminino: 'Feminino',
    relacaoPredial: 'Relação predial',
    localArmazenamentoInsumos: 'Local de armazenamento de insumos',
    maquinarioUtensilios: 'Maquinários e utensílios',
    propriedade: 'Gerenciamento de propriedade',
    tomadaDecisao: 'Responsável por tomada de decisão',
    atividadesDesenvolvidas: 'Atividades desenvolvidas',
    telefone: 'Telefone',
    comunidade: 'Comunidade',
    municipio: 'Município',
    uf: 'UF',
    email: 'E-mail',
    nivel: 'Nível tecnológico',
    tecnicoResponsavel: 'Técnico responsável',
    ativo: 'Ativo',
    codigo: 'Código',
    cidade: 'Cidade',
    estado: 'Estado',
    descricao: 'Descrição',
    areaCultivo: 'Área total de cultivo',
    areaTotaldeTalhoes: 'Área total de talhão',
    dataVisita: 'Data da visita',
    hora: 'Hora',
    tecnico: 'Técnico',
    dataPlantio: 'Data do plantio',
    culturas: 'Cultura',
    dataColheita: 'Data da colheita',
    produtividade: 'Produtividade(Sacas/Ha)',
    areaDeCultivo: 'Área de cultivo',
    talhoes: 'Talhões',
    visitas: 'Visitas',
    plantios: 'Plantios',
    status: 'Situação',
    inativo: 'Inativo',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    gravar: 'Gravar',
    campoObrigatorio: 'Campo Obrigatório',
    desejaExcluirAreaCultivo: 'Deseja excluir a área de cultivo selecionada?',
    desejaExcluirTalhao: 'Deseja excluir o talhão selecionado?',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    areaCultivoRemovidoComSucesso: 'Área de cultivo removido com sucesso',
    talhaoRemovidoComSucesso: 'Talhão removido com sucesso',
    medio: 'Médio',
    alto: 'Alto',
    baixo: 'Baixo',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    produtorCadastradoComSucesso: 'Produtor cadastrado com sucesso',
    produtorEditadoComSucesso: 'Produtor editado com sucesso',
    falhaAoCadastrarProdutor: 'Falha ao cadastrar produtor',
    falhaAoEditarProdutor: 'Falha ao editar produtor',
    emailJaCadastrado: 'Este email já está em uso',
    erroAoCarregarDados: 'Erro ao carregar os dados',
    aplicacoes: 'Aplicações',
    dataAplicacao: 'Data aplicação',
    tipo: 'Tipo',
    marca: 'Marca',
    produto: 'Produto',
    talhao: 'Talhão',
    hectares: 'Hectares',
    acaricida: 'Acaricida',
    acaricidaMicrobiologico: 'Acaricida microbiológico',
    agenteBiologicoControle: 'Agente biológico',
    bactericida: 'Bactericida',
    bactericidaMicrobiologico: 'Bactericida microbiológico',
    herbicida: 'Herbicida',
    fungicida: 'Fungicida',
    fungicidaMicrobiologico: 'Fungicida microbiológico',
    inseticida: 'Inseticida',
    inseticidaMicrobiologico: 'Inseticida microbiológico',
    nematicida: 'Nematicida',
    nematicidaMicrobiologico: 'Nematicida microbiológico',
    fertilizanteFoliar: 'Fertilizante foliar',
    talhaoDuplicado: 'Talhão duplicado',
    plantioCadastradoComSucesso: ' Plantio cadastrado com sucesso',
    desejaExcluirAplicacao: 'Deseja excluir aplicação?',
    aplicacaoRemovidoComSucesso: 'Aplicação removida com sucesso',
    naoFoiPossivelEditar: 'Não foi possível editar',
    naoFoiPossivelInserir: 'Não foi possível inserir',
    plantioRemovidoComSucesso: 'Plantio removido com sucesso',
    erroAoRemoverPlantio: 'Erro ao remover plantio',
    desejaExcluirPlantio: 'Deseja excluir plantio?',
    selecionarTodos: 'Selecionar todos',
    plantioEditadoComSucesso: 'Plantio editado com sucesso',
    areaTotalSelecionada: 'Área total selecionada(Ha)',
    talhoesAdicionados: 'Talhões adicionados',
    areaPlantio: 'Área de plantio',
    acompanhamentoNaoCadastrado: 'Acompanhamento de plantio não cadastrado ainda',
    acompanhamentoPlantio: 'Acompanhamento de plantio',
    acompanhamentoProdutor: 'Acompanhamento ao produtor',
    nrQuantidade: 'Quantidade',
    produtos: 'Produtos',
    lancamentoAplicacao: 'Lançamento de aplicação',
    dsUnMedida: 'Un. de medida',
    dsRecomendacaoAdicional: 'Recomendação adicional',
    ordem: 'Ordem',
    dose: 'Dose(L)',
    adicionarProdutos: 'Adicionar produtos',
    areaTotal: 'Área total(Ha)',
    lancamentoCadastrado: 'Lançamento cadastrado com sucesso',
    lancamentoAtualizado: 'Lançamento atualizado com sucesso',
    falhaAoCadastrarLancamento: 'Falha ao cadastrar lançamento',
    falhaAoAtualizarLancamento: 'Falha ao atualizar lançamento',
    unMedida: 'Un. de medida',
    recomendacoesAdicionais: 'Recomendações adicionais',
    visualizarRecomendacoes: 'Visualizar recomendações',
    fechar: 'Fechar',
    aplicarNosTalhoes: 'Aplicar nos talhões',
    talhoesUtilizados: 'Talhões utilizados',
    falhaAoCarregarAcompanhamento: 'Falha ao carregar acompanhamento',
    produtoRemovidoComSucesso: 'Produto removido com sucesso',
    lancamentoRecomendacao: 'Lançamento de recomendação',
    cadastrarAplicacao: 'Cadastrar aplicação',
    cadastrarRecomendacao: 'Cadastrar recomendação',
    acre: 'Acre',
    hectare: 'Hectare',
    alqueire: 'Alqueire',
    numeroMaximoProdutores: 'O limite de produtores do seu plano foi atingido',
    desejaExcluir: 'Deseja excluir ',
    falhaAoExcluirProdutor: 'Falha ao excluir produtor',
    ocorreuErroExcluirProdutor: 'Ocorreu uma falha ao excluir produtor',
    produtor: 'Produtor',
    cadastroPlantio: 'Cadastro de plantios',
    cadastrarAreaCultivo: 'Primeiro cadastre área de cultivo',
    cadastrarTalhao: 'Primeiro cadastre área de cultivo e talhão',
    adicionarTalhao: 'Adicionar talhão',
    editarTalhao: 'Editar talhão',
    exclusaoAreaDeCultivoNaoPermitida: 'Exclusão de área de cultivo não permitida',
    talhaoPlantioAssociado: 'Talhão e plantio associados a área de cultivo',
    telefoneInvalido: 'Telefone inválido',
    plantioAssociado: 'Plantio associados ao talhão',
    exclusaoTalhaoNaoPermitida: 'Exclusão de talhão não permitida',
    produtorExcluidoComSucesso: 'Produtor excluído com sucesso',
    adicionarAreaCultivo: 'Adicionar área de cultivo',
    editarAreaCultivo: 'Editar área de cultivo',
    adicionarPlantio: 'Adicionar plantio',
    editarPlantio: 'Editar plantio',
    alertasFitossanitarios: 'Alertas Fitossanitários',
    doencaSolo: 'Doença de solo',
    doencaFoliar: 'Doença foliar',
    nematoide: 'Nematóide',
    pragas: 'Pragas',
    agenteCausal: 'Agente causal',
    invasoras: 'Invasoras',
    agenteCausalAdicionadas: 'Agentes causais adicionadas',
    minimoUm: 'Mínimo de 1 talhão',
    dataMinima: 'Data inválida',
    voltar: 'Voltar',
    agendaRelacionada: 'Existem uma ou mais agendas relacionadas ao produtor',
    atividadeRelacionada: 'Existem atividades relacionadas ao produtor',
    registrosRelacionados: 'Existem registros relacionadas ao talhão',
    confirmar: 'Confirmar',
    salvarProdutorSair: 'Existem informações não salvas, deseja salvar o produtor?',
    descartar: 'Descartar',
    produtividades: 'Produtividade',
    area: 'Área',
    sacas: 'Sacas',
    toneladas: 'Toneladas',
    semeadura: 'Semeadura',
    unidade: 'Unidade',
    espacamento: 'Espaçamento (cm)',
    areaSemeadura: 'Área de semeadura',
    m2: 'm²',
    metro: 'Metro',
    standFinal: 'Stand final',
    previsaoColheita: 'Previsão de Colheita',
    diasEstimados: 'Dias estimados',
    produtividadeEstimada: 'Produtividade Estimada',
    quantidadePlantas: 'Quantidade de plantas',
    tonelada: 'Tonelada',
    colheita: 'Colheita',
    localPlantio: 'Local de plantio',
    sementes: 'Sementes',
    data: 'Data',
    certificacaoOrganica: 'Status da certificação orgânica',
    convencional: 'Convencional',
    emConversao: 'Em conversão',
    brasil: 'BR (Brasil)',
    comunidadeEuropeia: 'CE (Comunidade Européia)',
    eua: 'NOP (Estados Unidos)',
    producaoParalela: 'Produção paralela',
    ecosocial: 'Certificação ecosocial',
    naoPossui: 'Não possui',
    dataPrevista: 'Data prevista',
    informacoes: 'Informações',
    mapas: 'Mapas',
    mapa: 'Mapa',
    excluirMarcacao: 'Limpar marcação',
    desejaExcluirMarcacao: 'Deseja limpar marcações?',
    sim: 'Sim',
    minino: 'A área total do talhao deve ser maior que 0',
    cpf: 'CPF',
    conhecimentoOrganica: 'Conhecimento da produção orgânica',
    atividadeGraos: 'Atividade de grãos',
    areaProdutiva: 'Área produtiva de grãos',
    cadastroCompleto: 'Cadastro completo',
    completo: 'Completo',
    preCadastro: 'Pré-cadastro',
    cadastroPreProdutor: 'Pré-cadastro de produtor',
    cpfInvalido: 'CPF Inválido',
    efetivarProdutor: 'Efetivar produtor',
    efetivarPreCadastro: 'Efetivar pré-cadastro',
    contrato: 'Contrato',
    colaboradores: 'Colaboradores',
    efetivo: 'Efetivo',
    temporario: 'Temporário',
    desejaExcluirColaborador: 'Deseja excluir colaborador?',
    colaboradorRemovidoComSucesso: 'Colaborador removido com sucesso',
    dadosSociais: 'Dados sociais',
    participacaoAssociacoes: 'Participa de associações ou cooperativas',
    conflitoLimites: 'Conflito com propriedades limítrofes',
    ambiental: 'Ambiental',
    localizacaoSede: 'Localização da sede',
    informacoesClima: 'Informações de clima',
    informacoesSolo: 'Informações de solo',
    terracentamento: 'Terraceamento',
    ruim: 'Ruim',
    satisfatorio: 'Satisfatório',
    razoavel: 'Razoável',
    plantioNivel: 'Plantio em nível',
    usoPlantasCobertura: 'Uso de plantas de cobertura',
    plantioDireto: 'Plantio direto',
    protecaoFontes: 'Proteção de fontes',
    protecaoLeitosAgua: 'Proteção de leitos d`água',
    analisePotabilidadeAgua: 'Análise de potabilidade de agua',
    usoIrrigacao: 'Uso de irrigação',
    organicosDomisanitarios: 'Orgânicos domissanitários',
    organicosAnimais: 'Orgânicos animais',
    reciclaveis: 'Recicláveis',
    embalagensAgrotoxicos: 'Embalagens de agrotóxicos',
    especie: 'Espécie',
    organico: 'Orgânico',
    producaoAnimalRemovidoComSucesso: 'Produção animal removida com sucesso',
    desejaExcluirProducaoAnimal: 'Deseja excluir produção animal?',
    frango: 'Frango',
    gado: 'Gado',
    adicionarFamiliar: 'Adicionar familiar',
    adicionarColaborador: 'Adicionar colaborador',
    editarColaborador: 'Editar colaborador',
    editarPMO: 'Editar PMO',
    adicionarPMO: 'Adicionar PMO',
    producaoAnimal: 'Produção animal',
    editarProducaoAnimal: 'Editar produção animal',
    adicionarProducaoAnimal: 'Adicionar produção animal',
    nenhumMapaCadastrado: 'Nenhum mapa cadastrado',
    propriedades: 'Propriedade',
    dataCadastro: 'Data de cadastro',
    dataAdmissao: 'Data admissão',
    dataDemissao: 'Data demissão',
    raca: 'Raça',
    qtda: 'Qtde',
    analiseRiscoRemovidoComSucesso: 'Análise de risco removida com sucesso',
    desejaExcluirAnaliseRisco: 'Deseja excluir análise de risco?',
    funcaoExercida: 'Função exercida',
    conhecimentoNormas: 'Conhecimentos das normas trabalhistas',
    nenhumTalhaoDemarcado: 'Nenhum talhão demarcado',
    riscosAdicionais: 'Riscos adicionais',
    fundamentalIncompleto: 'Fundamental incompleto',
    fundamentalCompleto: 'Fundamental completo',
    medioIncompleto: 'Ensino médio incompleto',
    medioCompleto: 'Ensino médio completo',
    superiorIncompleto: 'Ensino superior incompleto',
    superiorCompleto: 'Ensino superior completo',
    longitude: 'Longitude',
    latitude: 'Latitude',
    selecioneArquivo: 'Selecione o arquivo',
    nenhumArquivoSelecionado: 'Nenhum arquivo selecionado',
    baixar: 'Baixar',
    colaboradoresResponsaveis: 'Colaboradores responsáveis por tomada de decisão',
    valorInvalido: 'Valor inválido',
    motivoAlteraçao: 'Motivo da alteração',
    mininoCampo: 'Mínimo 10 letras',
    erroAnexarFoto: 'Ocorreu um erro ao anexar a foto',
    numeroRegistroCLT: 'Nº do Registro (CLT)',
    numeroContrato: 'Número do contrato',
    moraPropriedade: 'Mora na propriedade',
    possuiRendaExtra: 'Possui renda extra',
    descricaoRendaExtra: 'Descrição da renda extra',
    car: 'CAR',
    dap: 'DAP',
    descricaoDejetoAnimal: 'Descrição de dejetos animais',
    dejetoAnimal: 'Dejetos animais',
    descricaoCarcacaAnimal: 'Descrição de carcaças de animais',
    carcacaAnimal: 'Carcaças de animais',
    periodoCompostagem: 'Compostagem',
    descricaoPeriodoCompostagem: 'Período de compostagem',
    descricaoPeriodoArmazenamentoUrina: 'Período de armazenamento prévio',
    periodoArmazenamentoUrina: 'Urina animal',
    descricaoOrganicosDomissanitarios: 'Descrição de orgânicos domissanitários',
    descricaoReciclaveis: 'Descrição de recicláveis',
    descricaoEmbalagensAgrotoxicas: 'Descrição de embalagens de agrotóxicos',
    abastecimentoPulverizacao: 'Abastecimento de pulverizadores',
    descricaoAbastecimentoPulverizacao: 'Descrição de abastecimento de pulverizadores',
    fontes: 'Fontes',
    abastecimentoComunitario: 'Abastecedor comunitário',
    rioLagos: 'Rio/Lagos',
    codigoCertificadora: 'Cód. certificação orgânica',
    homeopaticos: 'Produtos Homeopáticos',
    inoculantes: 'Inoculantes',
    dataPrimeiraVisita: 'Data da primeira visita',
    cnpjInvalido: 'CNPJ inválido',
    certificacoes: 'Certificações',
    classificacao: 'Uso e ocupação do solo',
    certificacao: 'Certificação',
    statusCertificacaoOrganica: 'Status de certificação orgânica',
    agencia: 'Agência',
    temporarios: 'Temporários'
  }
};
