import React from 'react';
import classNames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import CardIcon from '@components/CardIcon/CardIcon';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { getUserLang } from '@utils/localeUtils';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { withStyles } from '@material-ui/core/styles';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const semVisitas = messagesLinguage['containerVisitas.semVisitas'];
const visitasAgendadas = messagesLinguage['containerVisitas.visitasAgendadas'];
const visitasRealizadas = messagesLinguage['containerVisitas.visitasRealizadas'];

const intlProvider = new IntlProvider(
  {
    locale: getUserLang(),
    messages: {
      semVisitas,
      visitasAgendadas,
      visitasRealizadas
    }
  },
  {}
);

provider = intlProvider.getChildContext();

const style = {
  card: {
    marginTop: 15
  },
  visitas: {
    height: '100%',
    justifyContent: 'space-between',
    borderRadius: 4,
    display: 'flex',
    fontSize: 20,
    flexDirection: 'column'
  },
  cardVisitasPendentes: {
    minHeight: 350,
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    height: '100%',
    marginBottom: 15
  },
  labelVisitas: {
    minHeight: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 400
  },
  collapse: {
    minHeight: '185px !important'
  }
};

function ContainerVisitas(props) {
  const { expanded, classes, vlVisitasPendentes, vlVisitasAgendadas, vlVisitasRealizadas } = props;

  return (
    <Collapse
      in={expanded}
      style={{ minHeight: '185px !important' }}
      className={expanded ? classNames('run-animation-open', classes.collapse) : 'run-animation-close'}
      timeout='auto'
      unmountOnExit
    >
      <div className={classes.card}>
        <CardIcon disableHeader style={{ borderRadius: 0 }}>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              <div
                className={classes.visitas}
                style={{
                  backgroundColor: 'rgb(188, 105, 105, 0.25)',
                  color: 'rgb(188, 105, 105)'
                }}
              >
                <div
                  className={classes.labelVisitas}
                  style={{
                    padding: 10,
                    paddingBottom: 0,
                    width: 'calc(100% - 20px)',
                    paddingTop: 15,
                    textAlign: 'center'
                  }}
                >
                  {provider.intl.formatMessage({ id: 'semVisitas' }).toUpperCase()}
                </div>
                <div
                  className={classes.labelVisitas}
                  style={{ fontSize: 35, paddingBottom: 15, paddingTop: 10 }}
                >
                  {vlVisitasPendentes}
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div
                className={classes.visitas}
                style={{
                  backgroundColor: 'rgb(0, 188, 212, 0.25)',
                  color: 'rgb(0, 188, 212)'
                }}
              >
                <div
                  className={classes.labelVisitas}
                  style={{
                    padding: 10,
                    paddingBottom: 0,
                    width: 'calc(100% - 20px)',
                    paddingTop: 15,
                    textAlign: 'center'
                  }}
                >
                  {provider.intl.formatMessage({ id: 'visitasAgendadas' }).toUpperCase()}
                </div>
                <div
                  className={classes.labelVisitas}
                  style={{ fontSize: 35, paddingBottom: 15, paddingTop: 10 }}
                >
                  {vlVisitasAgendadas}
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div
                className={classes.visitas}
                style={{
                  backgroundColor: 'rgb(70, 167, 112, 0.25)',
                  color: 'rgb(70, 167, 112)'
                }}
              >
                <div
                  className={classes.labelVisitas}
                  style={{
                    padding: 10,
                    paddingBottom: 0,
                    width: 'calc(100% - 20px)',
                    paddingTop: 15,
                    textAlign: 'center'
                  }}
                >
                  {provider.intl.formatMessage({ id: 'visitasRealizadas' }).toUpperCase()}
                </div>
                <div
                  className={classes.labelVisitas}
                  style={{ fontSize: 35, paddingBottom: 15, paddingTop: 10 }}
                >
                  {vlVisitasRealizadas}
                </div>
              </div>
            </Grid>
          </Grid>
        </CardIcon>
      </div>
    </Collapse>
  );
}

export default withStyles(style)(ContainerVisitas);
