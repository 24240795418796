import React from "react";
import { withStyles } from "@material-ui/core/styles";

const style = {
  card: {
    width: "calc(calc(100% - 30px) / 3)",
    borderRadius: 5,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
  },
  valor: {
    height: 55,
    color: "#FFFFFF",
    fontSize: 50,
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: 10
  },
  visitas: {
    height: 35,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF",
    fontSize: 12,
    paddingBottom: 10
  }
};

function DashboardCard(props) {
  const { classes } = props;

  return (
    <div
      className={classes.card}
      style={{
        boxShadow: props.select ? "0px 5px 10px rgba(0, 0, 0, 0.6)" : "",
        backgroundColor: props.color,
        marginRight: props.last ? 0 : 15
      }}
      onClick={props.onClickCard}
    >
      <div className={classes.valor}>{props.value}</div>
      <div className={classes.visitas}>{props.title}</div>
    </div>
  );
}

export default withStyles(style)(DashboardCard);
