import React, { Component } from 'react';
import formatMessage from './i18n/formatMessage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardsWithFilters from '@components/CardFilters/CardsWithFilters';
import { withStyles } from '@material-ui/core';
import Loading from '@components/Loading/Loading';
import PDFViewer from '@components/PDFViewer/PDFViewer';
import swal from '@sweetalert/with-react';
import Relatorios from '@resources/Relatorio';
import Regioes from '@resources/Regiao';
import Localizacao from '@resources/Localizacao';
import Comunidade from '@resources/Comunidade';
import Safra from '@resources/Safra';
import Produtores from '@resources/Produtores';
import Cultura from '@resources/Cultura';
import Usuario from '@resources/Usuario';
import { styles } from './RelatorioPlantio.styles';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let listChecks = [
  { title: 'Stand final', check: true, key: 2, field: 'standFinal', grid: 2 },
  { title: 'Previsão de colheita', check: true, key: 3, field: 'previsaoColheita', grid: 3 },
  { title: 'Alertas fitossanitários', check: true, key: 4, field: 'alertas', grid: 3 },
  { title: 'Recomendação de aplicação', check: true, key: 5, field: 'recomendacao', grid: 2 },
  { title: 'Colheita', check: true, key: 7, field: 'colheita', grid: 2 },
  { title: 'Semeadura', check: true, key: 1, field: 'semeadura', grid: 2, notDepends: true },
  { title: 'Monitoramento de pragas', check: true, key: 8, field: 'monitoramento', grid: 3 },
  { title: 'Registros climáticos', check: true, key: 9, field: 'registroClimatico', grid: 3 },
  { title: 'Aplicação realizada', check: true, key: 10, field: 'aplicacao', grid: 2 }
];

/**
 * Tela de Relatório de plantios
 *
 * @author Gabriela Farias
 * @class RelatorioPlantio
 * @extends {Component} - Componente React
 */
class RelatorioPlantio extends Component {
  constructor(props) {
    super(props);

    const filtersList = [
      {
        label: formatMessage('plantios.regiao'),
        field: 'nmRegiao',
        list: 'regiaoList',
        type: 'autocomplete'
      },
      {
        label: formatMessage('plantios.uf'),
        field: 'nmUf',
        list: 'ufList',
        type: 'autocomplete'
      },
      {
        label: formatMessage('plantios.municipio'),
        field: 'nmMunicipio',
        list: 'municipioList',
        type: 'autocomplete'
      },
      {
        label: formatMessage('plantios.comunidade'),
        field: 'nmComunidade',
        list: 'comunidadeList',
        type: 'autocomplete'
      },
      {
        label: formatMessage('plantios.produtor'),
        field: 'nmProdutor',
        list: 'produtorList',
        type: 'autocomplete'
      },

      {
        label: formatMessage('plantios.cultura'),
        field: 'nmCultura',
        list: 'culturaList',
        type: 'autocomplete'
      }
    ];

    if (this.props.infoUsuario.tpPerfil !== 2) {
      filtersList.push({
        label: formatMessage('plantios.tecnico'),
        field: 'nmUsuario',
        list: 'tecnicoList',
        type: 'autocomplete'
      });
    }

    if (this.props.infoUsuario.stOrganica === 1) {
      filtersList.push({
        label: formatMessage('plantios.safra'),
        field: 'dsSafra',
        list: 'safraList',
        type: 'autocomplete'
      });
    }

    this.state = {
      fileCSV: null,
      filePDF: null,
      fileXLS: null,
      closeCard: false,
      errorCard: false,
      loading: false,
      loadingDados: true,
      tecnicosList: [],
      municipioList: [],
      regiaoList: [],
      produtorList: [],
      ufList: [],
      comunidadeList: [],
      safraList: [],
      culturaList: [],
      tecnicoList: [],
      operationTypeString: filtersList
    };

    const title = 'plantios.plantios';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    this.clickGerarRelatorio = this.clickGerarRelatorio.bind(this);
    this.modalErrorRelatorio = this.modalErrorRelatorio.bind(this);
  }

  componentDidMount() {
    if (this.props.infoUsuario.stOrganica === 1) {
      listChecks.push({ title: 'OGM', check: true, key: 6, field: 'ogm', grid: 2 });
    }

    Promise.all([
      Regioes.findAllRegiao()
        .then(query => {
          this.setState({
            regiaoList: query.data
          });
        })
        .catch(() => {
          this.setState({
            regiaoList: []
          });
        }),

      Localizacao.findAllEstados()
        .then(query => {
          this.setState({
            ufList: query.data
          });
        })
        .catch(err => {
          this.setState({
            ufList: []
          });
        }),

      Comunidade.findAllComunidades()
        .then(query => {
          this.setState({
            comunidadeList: query.data
          });
        })
        .catch(err => {
          this.setState({
            comunidadeList: []
          });
        }),

      Localizacao.findAllMunicipiosConcatenado()
        .then(query => {
          this.setState({
            municipioList: query.data
          });
        })
        .catch(err => {
          this.setState({
            municipioList: []
          });
        }),

      Produtores.findProdutor()
        .then(query => {
          this.setState({
            produtorList: query.data
          });
        })
        .catch(err => {
          this.setState({
            produtorList: []
          });
        }),

      Safra.findAllSafras()
        .then(query => {
          this.setState({
            safraList: query.data
          });
        })
        .catch(err => {
          this.setState({
            safraList: []
          });
        }),

      Cultura.findAllCultura()
        .then(query => {
          this.setState({
            culturaList: query.data
          });
        })
        .catch(err => {
          this.setState({
            culturaList: []
          });
        }),

      Usuario.findAllUsuarios()
        .then(query => {
          this.setState({
            tecnicoList: query.data.filter(usuario => usuario.tpPerfil === 2)
          });
        })
        .catch(err => {
          this.setState({
            tecnicoList: []
          });
        })
    ])
      .then(() => {
        this.setState({
          loadingDados: false
        });
      })
      .catch(() => {
        this.setState({
          loadingDados: false
        });
      });
  }

  clickGerarRelatorio(chips) {
    this.setState({
      errorCard: false,
      closeCard: true,
      loading: true,
      fileXLS: null,
      filePDF: null,
      fileCSV: null
    });

    let municipio = chips.find(item => {
      return item.filter.field === 'nmMunicipio';
    });

    let regiao = chips.find(item => {
      return item.filter.field === 'nmRegiao';
    });

    let nmProdutor = chips.find(item => {
      return item.filter.field === 'nmProdutor';
    });

    let nmComunidade = chips.find(item => {
      return item.filter.field === 'nmComunidade';
    });

    let nmUf = chips.find(item => {
      return item.filter.field === 'nmUf';
    });

    let idSafra = chips.find(item => {
      return item.filter.field === 'dsSafra';
    });

    let idCultura = chips.find(item => {
      return item.filter.field === 'nmCultura';
    });

    let idUsuario = chips.find(item => {
      return item.filter.field === 'nmUsuario';
    });

    nmProdutor = nmProdutor !== undefined ? 'idProdutor:' + nmProdutor.value.value + ';' : '';
    regiao = regiao !== undefined ? 'idRegiao:' + regiao.value.value + ';' : '';
    municipio = municipio !== undefined ? 'idMunicipio:' + municipio.value.value + ';' : '';
    nmComunidade = nmComunidade !== undefined ? 'idComunidade:' + nmComunidade.value.value + ';' : '';
    nmUf = nmUf !== undefined ? 'idUf:' + nmUf.value.value + ';' : '';
    idSafra = idSafra !== undefined ? 'idSafra:' + idSafra.value.value + ';' : '';
    idCultura = idCultura !== undefined ? 'idCultura:' + idCultura.value.value + ';' : '';
    idUsuario = idUsuario !== undefined ? 'idUsuario:' + idUsuario.value.value + ';' : '';

    let filters =
      municipio +
      regiao +
      nmProdutor +
      nmComunidade +
      nmUf +
      idSafra +
      idCultura +
      idUsuario +
      '&subRelatorios=';

    listChecks.forEach(doc => {
      if (doc.check) {
        filters += doc.field + ';';
      }
    });

    Relatorios.gerarRelatorioPlantios(filters)
      .then(doc => {
        this.setState({
          closeCard: false,
          fileXLS: doc.data.dsLinkXls,
          filePDF: doc.data.dsLinkPdf,
          fileCSV: doc.data.dsLinkCsv
        });
      })
      .catch(err => {
        this.modalErrorRelatorio();
      });
  }

  modalErrorRelatorio() {
    this.setState(
      {
        closeCard: false,
        errorCard: true,
        loading: false,
        fileXLS: null,
        filePDF: null,
        fileCSV: null
      },
      this.setState({ errorCard: false })
    );

    swal(formatMessage('plantios.falhaAoGerarRelatorio'), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.card}>
        {(this.state.loading || this.state.loadingDados) && <Loading />}
        <CardsWithFilters
          errorCard={this.state.errorCard}
          closeCard={this.state.closeCard}
          usePaddings={false}
          abaWithChecks
          relatorio
          grid={2}
          viewKey={'ficha-produtor'}
          itens={[
            {
              autoComplete: {
                municipioList: {
                  list: this.state.municipioList,
                  campoOp: 'nmMunicipio',
                  campoChave: 'idMunicipio'
                },
                regiaoList: {
                  list: this.state.regiaoList,
                  campoOp: 'nmRegiao',
                  campoChave: 'idRegiao'
                },
                produtorList: {
                  list: this.state.produtorList,
                  campoOp: 'nmProdutor',
                  campoChave: 'idProdutor'
                },
                ufList: {
                  list: this.state.ufList,
                  campoOp: 'nmUf',
                  campoChave: 'idUf'
                },
                comunidadeList: {
                  list: this.state.comunidadeList,
                  campoOp: 'nmComunidade',
                  campoChave: 'idComunidade'
                },
                safraList: {
                  list: this.state.safraList,
                  campoOp: 'dsSafra',
                  campoChave: 'idSafra'
                },
                culturaList: {
                  list: this.state.culturaList,
                  campoOp: 'nmCultura',
                  campoChave: 'idCultura'
                },
                tecnicoList: {
                  list: this.state.tecnicoList,
                  campoOp: 'nmUsuario',
                  campoChave: 'idUsuario'
                }
              },
              onClickButton: this.clickGerarRelatorio,
              filtros: this.state.operationTypeString,
              viewKey: 'relatorio-plantio',
              keyCard: 1,
              onFilter: () => {},
              onFilterChanged: () => {},
              relatorio: true,
              withoutButtonFiltrar: true,
              contain: this.props.chipData
            },
            {
              grid: 2,
              contain: [],
              viewKey: 'relatorio-plantio',
              onClickButton: this.clickGerarRelatorio,
              filtros: [],
              keyCard: 2,
              onFilter: () => {},
              onFilterChanged: () => {},
              relatorio: true,
              listChecks: listChecks,
              withoutLabelTipo: true
            }
          ]}
        />

        {this.state.loading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 20,
              justifyContent: 'center'
            }}
          >
            {formatMessage('plantios.gerandoRelatorio')}
          </div>
        )}

        <PDFViewer
          loadSucess={() => {
            this.setState({
              loading: false
            });
          }}
          errorCard={this.state.errorCard}
          fileXLS={this.state.fileXLS}
          filePDF={this.state.filePDF}
          additionalActions={[
            {
              icon: (
                <FontAwesomeIcon
                  style={{ color: 'rgb(255, 255, 255)', width: 18, height: 18 }}
                  icon={faFileCsv}
                />
              ),
              name: formatMessage('plantios.exportarPlanilha'),
              file: this.state.fileCSV,
              color: '#42ADE8'
            }
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'relatorio-plantio';
  })[0].content
});

let enhaced = withStyles(styles)(RelatorioPlantio);
export default withRouter(connect(mapStateToProps)(enhaced));
