import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  }
});

const styles = {
  card: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  cardEditar: {
    width: '100%',
    height: '100%'
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    flex: 1
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  font: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    width: '100%',
    minHeight: 0
  },
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 27,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  form: {
    width: '100%',
    margin: 0,
    height: '75px'
  },
  check: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10
  }
};

export { theme, styles };
