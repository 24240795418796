export default {
  agenda: {
    agenda: 'Schedule',
    agendarVisita: 'Visit schedule',
    agendarEvento: 'Schedule event',
    data: 'Date',
    horario: 'Initial time',
    produtor: 'Producer',
    observacao: 'Note',
    gravar: 'Save',
    cancelar: 'Cancel',
    campoObrigatorio: 'Required field',
    atualizando: 'Updating',
    salvando: 'Saving',
    visitaCadastradaComSucesso: 'Visit successfully registered',
    visitaEditadaComSucesso: 'Successfully edited tour',
    falhaAoCadastrarVisita: 'Failed to register visit',
    falhaAoEditarVisita: 'Failed to edit visit',
    excluir: 'Delete',
    desejaExcluir: 'Do you want to delete?',
    nao: 'Not',
    visitaRemovidoComSucesso: 'Successfully deleted visit',
    eventoRemovidoComSucesso: 'Successfully deleted event',
    falhaAoExcluirVisita: 'Fail to delete visit',
    falhaAoExcluirEvento: 'Fail to delete event',
    excluindo: 'Excluding',
    resumoVisita: 'Summary of the visit',
    desejaMarcar: 'Do you want to schedule a visit?',
    sim: 'Yes',
    mesmoHorario: 'There is a scheduled visit at the same time for',
    mesmoHorarioEvento: 'There is an event scheduled at the same time to',
    falhaAoCarregarAgenda: 'Failed to load schedule',
    horarioFinal: 'End time',
    horarioInvalido: 'Final time must be greater than opening time',
    confirmar: 'Confirm',
    visitaRealizada: 'Visit made',
    visitasAgendadas: 'Scheduled Visits',
    visitasSemAgenda: 'Visits made without agenda',
    produtoresPendentesVisita: 'Pending visiting Producers',
    totalVisitasAgendadas: 'Total visits scheduled',
    totalVisitasSemAgenda: 'Total visits without schedule',
    produtoresMaiorIntervalo: 'Producers with longer interval between visits',
    visitasAgendadasTecnico: 'Scheduled Visits by Technician',
    visitasRealizadasTecnico: 'Visits made without schedule by technician',
    visitasMaisProdutoresPendentes: 'Technicians with more outstanding producers',
    tecnico: 'Technician',
    selecioneColaborador: 'Select a contributor',
    descricao: 'Description',
    local: 'Place',
    objetivo: 'Objetivo',
    participante: 'Competitor',
    eventoEditadaComSucesso: 'Event edited with event',
    eventoCadastradaComSucesso: 'Event successfully registered',
    falhaAoEditarEvento: 'Failed to edit event',
    falhaAoCadastrarEvento: 'Failed to register event',
    inativo: 'Inactive'
  }
};
