const style = theme => ({
  select: {
    height: 55,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  clearSelect: {
    height: 50,
    width: 50,
    marginTop: 5
  },
  drawerMenuClose: {
    left: 270,
    width: '100',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  drawerMenuOpen: {
    left: 50,
    width: '100%',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  drawerOpen: {
    zIndex: 100,
    position: 'fixed',
    marginTop: 80,
    boxShadow: 'inset 0px 0px 2px 2px #EEEEEE',
    backgroundColor: '#F2F2F2'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: '0.5s'
    }),
    width: 315,
    zIndex: 100,
    position: 'absolute',
    boxShadow: 'inset 0px 0px 2px 2px #EEEEEE',
    backgroundColor: '#F2F2F2',
    borderLeft: 'none'
  },
  textImage: {
    fontSize: 14,
    color: 'rgb(0, 0, 0, 0.54)',
    marginLeft: 10,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export { style };
