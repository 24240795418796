export default {
  agentesControle: {
    agentesControleRemovidoComSucesso: 'Biological agent successfully removed',
    falhaAoExcluiAgentesControle: 'Failed to exclude biological agent',
    desejaExcluir: 'Do you want to delete the selected biological agent?',
    agentesControle: 'Biological agents',
    cadastroDaAgentesControle: 'Biological agents registration',
    identificacaoDaAgentesControle: 'Identification',
    agentesControleCadastradoComSucesso: 'Biological agent successfully registered',
    agentesControleEditadoComSucesso: 'Biological agent edited successfully',
    falhaAoEditarAgentesControle: 'Failed to edit biological agent',
    falhaAoCadastrarAgentesControle: 'Failed to register biological agent',
    falhaAoCarregarAgentesControle: 'Failed to load biological agents'
  }
};
