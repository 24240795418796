export default {
  formaAplicacao: {
    formaAplicacao: 'Formulario de aplicación',
    nao: 'No',
    desejaExcluir: '¿Quieres borrar?',
    excluir: 'Excluir',
    formaAplicacaoRemovidoComSucesso: 'Formulario de aplicación eliminada con éxito',
    falhaAoExcluirFormaAplicacao: 'Error al eliminar la formulario de aplicación',
    excluindo: 'Excluyendo',
    cultura: 'Cultura',
    tipo: 'Tipo',
    situacao: 'Situación',
    nome: 'Nombre',
    formaAplicacaoCadastradoComSucesso: 'Formulario de aplicación registrada con éxito',
    formaAplicacaoEditadoComSucesso: 'Formulario de aplicación editada con éxito',
    falhaAoCadastrarFormaAplicacao: 'No se pudo registrar la formulario de aplicación',
    falhaAoEditarFormaAplicacao: 'No se pudo registrar la formulario de aplicación',
    falhaAoCarregarFormaAplicacao: 'Error al cargar la formulario de aplicación',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Actualización',
    cadastroDeFormaAplicacao: 'Registro de formulario de aplicación',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    doencaSolo: 'Enfermedad del suelo',
    doencaFoliar: 'Enfermedad de la hoja',
    nematoide: 'Nematodo',
    descricao: 'Descripción',
    ativo: 'Activo',
    inativo: 'Inactivo',
    pragas: 'Praga'
  }
};
