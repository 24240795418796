import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatMessage from './i18n/formatMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Card from '@components/Card/Card';
import Button from '@material-ui/core/Button';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import { styles } from './Certificacao.styles';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import { validarDatasCertificacao } from '@utils/validateDataCertificado';

/**
 * Componente de edição de certificação dentro de talhão
 *
 * @author Gabriela Farias
 * @class Componente de cadastro de certificação
 * @extends {Component} - Componente React
 */
function EditarCertificacao(props) {
  const { classes, isEditing, initialValues, clickEditar, clickClear, updateCertificacaoList } = props;
  const listOrganico = props.certificacaoListAutoComplete;

  const [certificacaoList, setCertificacaoList] = useState([...props.certificacaoList]);
  const [select, setSelect] = useState(null);
  const [keyCertificacao, setKeyCertificacao] = useState(0);
  const [key, setKey] = useState(0);
  const [valueAutoCompleteCertificacao, setValueAutoCompleteCertificacao] = useState(null);

  useEffect(() => {
    if (initialValues && initialValues.tipoCertificacao) {
      setValueAutoCompleteCertificacao(initialValues.tipoCertificacao);
      setKeyCertificacao(Math.random());
      setSelect(null);
    }
  }, [initialValues]);

  const headDatatable = [
    {
      valueGetter: args => args.node.data.tipoCertificacao.nmTipoCertificacao,
      headerName: formatMessage('certificado.certificacaoOrganica'),
      col: 4
    },
    {
      valueGetter: args => moment(args.node.data.dtInicio).format(formatTextField()),
      headerName: formatMessage('certificado.dataInicial'),
      col: 3
    },
    {
      valueGetter: args =>
        args.node.data.dtTermino
          ? moment(args.node.data.dtTermino).format(formatTextField())
          : formatMessage('certificado.indefinida'),
      headerName: formatMessage('certificado.dataFinal'),
      col: 3
    }
  ];

  function formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  function saveCertificacao(values, { resetForm }) {
    const validator = validarDatasCertificacao(
      values,
      certificacaoList,
      isEditing,
      'idTalhaoTipoCertificacao'
    );

    if (validator) {
      swal({
        title: formatMessage('certificado.certificadoExistente'),
        icon: 'error',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          cancel: {
            text: 'ok',
            value: false,
            visible: true,
            closeModal: true,
            className: 'swal2-cancel'
          }
        }
      });

      return;
    }

    const certificacaoListAux = [...certificacaoList];
    values.dtInicio = moment(values.dtInicio).format('YYYY-MM-DD');
    if (values.dtTermino) {
      values.dtTermino = moment(values.dtTermino).format('YYYY-MM-DD');
    }

    if (isEditing) {
      const item = certificacaoListAux.find(
        item => item.idTalhaoTipoCertificacao === values.idTalhaoTipoCertificacao
      );
      let position = certificacaoListAux.indexOf(item);
      certificacaoListAux[position] = values;
    } else {
      values.idTalhaoTipoCertificacao = Math.random();
      values.stRegistro = 0;
      certificacaoListAux.push(values);
    }

    setCertificacaoList(certificacaoListAux);
    updateCertificacaoList(certificacaoListAux);

    resetForm({
      tipoCertificacao: null,
      dtInicio: null,
      dtTermino: null
    });
    setValueAutoCompleteCertificacao(null);
    setKeyCertificacao(Math.random());
    clickClear();
  }

  function selectChange(select) {
    if (select) {
      setSelect({ ...select });
    } else {
      setSelect(null);
    }
  }

  function onChangeCertificacaoAutoComplete(event) {
    setValueAutoCompleteCertificacao(event);
  }

  function tratarValorSelectInitialCertificacao(data) {
    if (!data || data.nmTipoCertificacao === undefined) {
      return '';
    }
    return data;
  }

  return (
    <div style={{ width: '100%' }}>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={saveCertificacao}
        validationSchema={Yup.lazy(values =>
          Yup.object().shape({
            tipoCertificacao: Yup.object()
              .nullable()
              .required(formatMessage('certificado.campoObrigatorio')),
            dtInicio: Yup.string().required(formatMessage('certificado.campoObrigatorio')),
            dhTermino: Yup.string().nullable()
          })
        )}
        render={({ values, handleSubmit, errors, touched, setFieldValue }) => {
          return (
            <>
              <Card
                withOutBorderRadius
                title={formatMessage('certificado.adicionarCertificacao')}
                height='auto'
                style={{ minHeight: 190, maxHeight: 190, marginBottom: 15, marginTop: 15 }}
              >
                <form autoComplete='off'>
                  <Grid container>
                    <Grid item xs={3} style={{ marginRight: 15 }}>
                      <Autocomplete
                        itens={listOrganico}
                        campoOp='nmTipoCertificacao'
                        campoInput='nmTipoCertificacao'
                        campoChave='idTipoCertificacao'
                        label={formatMessage('certificado.certificacaoOrganica')}
                        name='tipoCertificacao'
                        error={errors.tipoCertificacao && touched.tipoCertificacao}
                        helperText={touched.tipoCertificacao ? errors.tipoCertificacao : ''}
                        onChangeOwn={event => {
                          setFieldValue('tipoCertificacao', event);
                          onChangeCertificacaoAutoComplete(event);
                        }}
                        onChangeAutoComplete={onChangeCertificacaoAutoComplete}
                        value={tratarValorSelectInitialCertificacao(valueAutoCompleteCertificacao)}
                        key={keyCertificacao}
                        valueAutoComplete={tratarValorSelectInitialCertificacao(
                          valueAutoCompleteCertificacao
                        )}
                        valueSelect={
                          tratarValorSelectInitialCertificacao(valueAutoCompleteCertificacao)
                            .nmTipoCertificacao
                        }
                      />
                    </Grid>

                    <Grid item xs={3} style={{ marginRight: 15 }}>
                      <DatePicker
                        name='dtInicio'
                        locale={getUserLang()}
                        errorTouched={errors.dtInicio && touched.dtInicio}
                        helperText={formatMessage('certificado.campoObrigatorio')}
                        label={formatMessage('certificado.dataInicial')}
                        valueDate={values.dtInicio}
                        onBlurDatePicker={e => {
                          const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                          if (validation) {
                            setFieldValue('dtInicio', e.target.value);
                          } else {
                            setFieldValue('dtInicio', null);

                            values.dtInicio = null;
                          }
                        }}
                        onChangeDatePicker={date => {
                          values.dtInicio = moment(date).format('YYYY-MM-DD');
                          setFieldValue('dtInicio', date);
                          setFieldValue('dtTermino', null);
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ marginRight: 15 }}>
                      <DatePicker
                        name='dtTermino'
                        locale={getUserLang()}
                        errorTouched={errors.dtTermino && touched.dtTermino}
                        helperText={formatMessage('certificado.campoObrigatorio')}
                        label={formatMessage('certificado.dataFinal')}
                        valueDate={values.dtTermino}
                        onBlurDatePicker={e => {
                          const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                          if (validation) {
                            setFieldValue('dtTermino', e.target.value);
                          } else {
                            setFieldValue('dtTermino', null);

                            values.dtTermino = null;
                          }
                        }}
                        onChangeDatePicker={date => {
                          values.dtTermino = moment(date).format('YYYY-MM-DD');
                          setFieldValue('dtTermino', date);
                        }}
                        minDate={values.dtInicio || null}
                      />
                    </Grid>

                    <Grid item xs={1} className={classes.cardButton}>
                      <Button className={classes.button} onClick={handleSubmit}>
                        <Add className={classes.iconButton} />
                      </Button>
                      <Button
                        className={classes.button}
                        onClick={() => {
                          setFieldValue('tipoCertificacao', null);
                          setFieldValue('dtInicio', null);
                          setFieldValue('dtTermino', null);
                          setValueAutoCompleteCertificacao(null);
                          setKeyCertificacao(Math.random());
                          setKey(Math.random());
                          clickClear();
                        }}
                      >
                        <Close className={classes.iconButton} />
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Card>

              <div style={{ backgroundColor: '#FFFFFF' }}>
                <div className={classes.cardTitle}>
                  {formatMessage('certificado.historicoCertificacao')}
                </div>
              </div>

              <div className={classes.grid}>
                <GroupButtonGrid
                  hiddenMargin
                  withoutMargin={true}
                  showDelete={false}
                  showEdit
                  disableEdit={!select}
                  onClickEdit={() => clickEditar(select)}
                />

                <DataTable
                  key={key}
                  data={certificacaoList}
                  columns={headDatatable}
                  selectChange={selectChange}
                  showPagination={false}
                  borderRadius={false}
                  noMargin
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(EditarCertificacao)));
