/*
Autor: Katia Miglioli
Props especificas:
tipoMascara = {1/2/3/4/5/6}-(telefone/cnpj/dinheiro/CEP/CPF/CPF E CNPJ)
&&
mascaraPropria = {{Funcao-mascara(Vide 'mascaraTelefone')}/nao declaracao}
demais props sao passados normalmente
*/
import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { getUserLang } from '@utils/localeUtils';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        whiteSpace: 'nowrap',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '1px solid green'
        }
      }
    }
  }
});

class FormattedInputs extends React.Component {
  constructor(props) {
    super(props);

    this.controleMascara = this.controleMascara.bind(this);
    this.mascaraTelefone = this.mascaraTelefone.bind(this);
    this.mascaraDinheiro = this.mascaraDinheiro.bind(this);
    this.mascaraCEP = this.mascaraCEP.bind(this);
    this.mascaraCpfCpnj = this.mascaraCpfCpnj.bind(this);
  }

  mascaraTelefone(props) {
    const { inputRef, onChange, ...other } = props;

    const maskTelefone = props.value.length === 15 ? '(##) #####-####' : '(##) ####-#####';

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          });
        }}
        format={maskTelefone}
      />
    );
  }

  mascaraCPF(props) {
    const { inputRef, onChange, ...other } = props;
    var format = getUserLang() === 'pt-BR' ? '###.###.###-##' : null;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          });
        }}
        format={format}
      />
    );
  }

  mascaraCNPJ(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          });
        }}
        format='##.###.###/####-##'
      />
    );
  }

  mascaraDinheiro(props) {
    const { inputRef, onChange, ...other } = props;
    var decimalSeparator = getUserLang() === 'en-US' || this.props.separator ? '.' : ',';
    var thousandSeparator = getUserLang() === 'en-US' || this.props.separator ? ',' : '.';

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        isNumericString={true}
        thousandSeparator={this.props.showThousandSeparator ? thousandSeparator : null}
        decimalSeparator={decimalSeparator}
        decimalScale={this.props.numberDecimalScale !== undefined ? this.props.numberDecimalScale : 2}
      />
    );
  }

  mascaraCEP(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          });
        }}
        format='#####-###'
      />
    );
  }

  /**
   * Mascara para CPF/CNPJ de acordo com a quantidade de caracteres
   */
  mascaraCpfCpnj(props) {
    const { inputRef, onChange, value, ...other } = props;
    let maskCpfCpnj =
      value.replaceAll(' ', '').replaceAll('/', '').length <= 14
        ? '###.###.###-###'
        : '##.###.###/####-##';

    return (
      <NumberFormat
        {...other}
        value={value}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          });
        }}
        format={getUserLang() === 'pt-BR' ? maskCpfCpnj : null}
      />
    );
  }

  controleMascara(props) {
    switch (parseInt(this.props.tipoMascara)) {
      case 1:
        return this.mascaraTelefone(props);
      case 2:
        return this.mascaraCNPJ(props);
      case 3:
        return this.mascaraDinheiro(props);
      case 4:
        return this.mascaraCEP(props);
      case 5:
        return this.mascaraCPF(props);
      case 6:
        return this.mascaraCpfCpnj(props);
      default:
        //OPCAO INDISPONIVEL(VIDE "mascaraPropria"
        break;
    }

    return this.mascaraTelefone(props);
  }

  render() {
    const {
      tipoMascara,
      mascaraPropria,
      numberDecimalScale,
      showThousandSeparator,
      separator,
      ...other
    } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <TextField
          style={{ width: '100%', position: 'relative' }}
          {...other}
          InputProps={{
            inputComponent:
              getUserLang() === 'pt-BR' && mascaraPropria === undefined
                ? this.controleMascara
                : mascaraPropria
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default FormattedInputs;
