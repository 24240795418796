export default {
  financeiro: {
    financeiro: 'Financeiro',
    razaoSocial: 'Razão social',
    nomeFantasia: 'Nome fantasia',
    cnpj: 'CNPJ',
    telefone: 'Telefone',
    email: 'E-mail',
    planosDeAcesso: 'Plano de acesso',
    produtoresAtivos: 'Produtores Ativos',
    motivo: 'Motivo da inativação',
    dataInativacao: 'Data da inativação',
    dataCadastro: 'Data de cadastro',
    ativo: 'Ativo',
    inativo: 'Inativo',
    endereco: 'Endereço',
    contatosAdicionais: 'Contatos Adicionais',
    usuarios: 'Usuários',
    identificacao: 'Identificação',
    cep: 'CEP',
    estado: 'Estado',
    cidade: 'Cidade',
    rua: 'Rua',
    numero: 'Número',
    complemento: 'Complemento',
    bairro: 'Bairro',
    departamento: 'Departamento',
    nome: 'Nome',
    perfil: 'Perfil',
    statusConta: 'Status da conta',
    situacao: 'Situação',
    identificacaoFinanceiro: 'Identificação',
    cadastroFinanceiro: 'Cadastro de financeiro',
    gravar: 'Gravar',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    reenviarCodigo: 'Reenviar e-mail de ativação',
    principal: 'Principal',
    entregas: 'Entregas',
    administrativo: 'Administrativo',
    gerencia: 'Gerência',
    ti: 'T.I.',
    tecnico: 'Técnico',
    revenda: 'Revenda',
    campoObrigatorio: 'Campo Obrigatório',
    emailInvalido: 'Email inválido',
    carregando: 'Carregando',
    financeiroCadastrado: 'Financeiro cadastrado com sucesso',
    financeiroEditado: 'Financeiro atualizado com sucesso',
    sucesso: 'Sucesso',
    erro: 'Erro',
    usuarioJaCadastradoTabela:
      'Esse usuário já foi adicionado a lista de usuários',
    naoFoiPossivelInserirUsuario: 'Não foi possível inserir o usuário',
    naoFoiPossivelInserirEditar: 'Não foi possível editar o usuário',
    erroCadastroUsuario: 'Erro ao cadastrar usuário',
    usuarioCadastradoComSucesso: 'Usuário cadastrado com sucesso',
    financeiroCadastradoComSucesso: 'Financeiro cadastrado com sucesso',
    registrado: 'Registrado',
    confirmado: 'Confirmado',
    desabilitado: 'Desabilitado',
    admin: 'Administrador',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    usuariosNaoCadastrados:
      'Lamento, não foi possível registrar os seguintes usuários:',
    financeiroEditadoComSucesso: 'Financeiro editado com sucesso',
    preRegistro: 'Pré-registro',
    produtor: 'Produtor',
    falhaAoCarregarFinanceiro: 'Falha ao carregar financeiro',
    numeroMaximoUsuarios: 'O limite de usuários desse plano foi atingido',
    desejaExcluir: 'Deseja excluir?',
    nao: 'Não',
    excluir: 'Excluir',
    excluindo: 'Excluindo',
    financeiroExcluidoComSucesso: 'Financeiro excluído com sucesso',
    falhaAoExcluirFinanceiro: 'Falha ao excluir financeiro',
    usuarioExcluidoComSucesso: 'Usuário excluído com sucesso',
    plano: 'Plano',
    telefoneInvalido: 'Telefone inválido',
    cnpjInvalido: 'CNPJ inválido',
    cepInvalido: 'CEP inválido',
    desejaExcluirEndereco: 'Deseja excluir endereço?',
    desejaExcluirContatosAdicionais: 'Deseja excluir contato adicional?',
    erroAoReenviarEmail: 'Falha ao reenviar email',
    codigoEnviadoComSucesso: 'Código enviado com sucesso',
    pais: 'País',
    cadastrarPais: 'Primeiro cadastre o país',
    cnpjDuplicado: 'CNPJ já cadastrado',
    erroAoEditar: 'Ocorreu um erro ao editar financeiro',
    erroBuscarFinanceiro: 'Ocorreu um erro ao buscar financeiro',
    emailDuplicado: 'Email já está cadastrado',
    valorImplantacao: 'Valor da implantação',
    valorLicenciamento: 'Valor de licenciamento',
    terminoVigencia: 'Término de vigência',
    inicioVigencia: 'Inicio de vigência',
    vlMensalidade: 'Valor da mensalidade',
    recorrencia: 'Recorrência',
    recorrenciaRemovidoComSucesso: 'Recorrência removida com sucesso',
    falhaAoCadastrarFinanceiro: 'Falha ao editar financeiro',
    dataRecorrenciaInvalida: 'Data da recorrência indisponível',
    nrProdutor: 'Número de produtores',
    nrTecnico: 'Número de técnicos',
    ilimitado: 'Ilimitado',
    quantidadeMinima: 'Quantidade mínima é 1',
    dataMinima: 'Data inválida',
    confirmar: 'Confirmar',
    salvarFinanceiroSair:
      'Existem informações não salvas, deseja salvar o financeiro?',
    descartar: 'Descartar',
    nrNdvi: 'Área de hectares NDVI',
    habilitarNdvi: 'Habilitar NDVI'
  }
};
