export default {
    comunidade: {
        descricao: 'Descripción',
        municipio:'Condado',
        comunidades:'Comunidades',
        desejaExcluir: '¿Desea eliminar la comunidad seleccionada?',
        nao:'No',
        excluir:'Borrar',
        comunidadeRemovidaComSucesso:'Comunidad removida con éxito',
        carregando:'Espere...',
        falhaAoExcluirComunidade:'Error al excluir comunidad',
        cadastroDeComunidades: 'Cadastro de comunidades',
        identificacaoDaComunidade : 'Identificación',
        campoObrigatorio:'Campo obligatorio',
        comunidadeCadastradaComSucesso:'Comunidad registrada con éxito',
        falhaAoCadastrarComunidade:'Falla al registrar comunidad',
        comunidadeEditadaComSucesso:'Comunidad actualizado con éxito',
        falhaAoEditarComunidade:'Error al editar comunidad',
        falhaAoCarregarComunidades:'Error al cargar comunidades',
        salvar:'Guardar',
        cancelar:'Cancelar',
        uf:'Estado',
        salvando: 'Ahorro',
        atualizanod: 'Actualización',
        excluindo: 'Borrado'
    }
}