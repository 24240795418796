export default {
  certificado: {
    dataFinal: 'Final date',
    dataInicial: 'Initial date',
    adicionarCertificacao: 'Add certification',
    certificacaoOrganica: 'Organic certification',
    campoObrigatorio: 'Required field',
    desejaExcluirCertificacao: 'Do you want to delete certification?',
    nao: 'No',
    excluir: 'Delete',
    certificacaoExcluidaSucesso: 'Certification successfully deleted',
    historicoCertificacao: 'Certification history',
    indefinida: 'undefined',
    certificadoExistente: 'There is already a certificate in this period.'
  }
};
