import {BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import App from '../App';
import React from 'react';
import AppMainLayout from '../views/AppMainLayout/AppMainLayout';
import RecuperarSenha from './../views/RecuperarSenha/RecuperarSenha';
import ConfirmarCadastro from './../views/ConfirmarCadastro/ConfirmarCadastro';
import ConfirmarConta from './ConfirmarConta/ConfirmarConta';

const createRoutes = () => (
    <BrowserRouter>
        <Switch> 
            <Route exact path="/" render={() => ( <Redirect to="/app"/> ) } /> />
            <Route path="/login" component={App} />
            <Route path="/resetar-senha" component={RecuperarSenha} />
            <Route path="/confirmar-cadastro" component={ConfirmarCadastro} />
            <Route path="/app" component={AppMainLayout} />
            <Route path="/confirmar-conta/:id" component={ConfirmarConta} />
        </Switch>
    </BrowserRouter>
);



export default createRoutes;