export default {
  relatorio: {
    relatorio: 'Informe',
    relatorioProdutoresPotencial: 'Informe productores potenciales',
    regiao: 'Región',
    municipio: 'Contacto',
    conhecimentoOrganico: 'Conocimiento de producción orgánica',
    sim: 'Si',
    nao: 'No',
    atividadeGraos: 'Actividad de granos',
    carregando: 'Cargando...',
    gerandoRelatorio: 'Generando informe ...',
    falhaAoGerarRelatorio: 'Error al generar informe',
    decrescente: 'Disminuyendo',
    crescente: 'Creciente',
    produtor: 'Productor',
    uf: 'UF'
  }
};
