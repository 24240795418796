export default {
  inseticidaMicroBio: {
    inseticidaMicroBio: 'Insecticida microbiológico',
    inseticidaMicroBioRemovidoComSucesso: 'Insecticida microbiológico quitado con éxito',
    falhaAoExcluirInseticidaMicroBio: 'Fallo la excluir insecticida microbiológico',
    desejaExcluir: '¿Desea eliminar el insecticida microbiológico seleccionada?',
    identificacaoDaInseticidaMicroBio: 'Identificación',
    inseticidaMicroBioCadastradoComSucesso: 'Insecticida microbiológico registradao con éxito',
    inseticidaMicroBioEditadoComSucesso: 'Insecticida microbiológico editado con éxito',
    falhaAoEditarInseticidaMicroBio: 'Falla al editar insecticida microbiológico',
    falhaAoCadastrarInseticidaMicroBio: 'Error al editar insecticida microbiológico',
    falhaAoCarregarInseticidaMicroBio: 'Error al cargar insecticida microbiológico',
    cadastroDeInseticida: 'Registro de insecticidas microbiológico'
  }
};
