// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import logger from 'redux-logger';
// import reducer from './reducer';

// const store = createStore(reducer, {}, applyMiddleware(thunk, logger));
// export default store;
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { combineReducers } from 'redux';
import itensMenu from '../components/MenuLateral/itensMenu';

const estadoInicial = {
  chipData: [
    { id: 'produtores', content: [] },
    { id: 'clientes', content: [] },
    { id: 'nematicidaMicrobiologico', content: [] },
    { id: 'comunidades', content: [] },
    { id: 'fertilizantesFoliares', content: [] },
    { id: 'municipios', content: [] },
    { id: 'agentesBiologicosControle', content: [] },
    { id: 'cultivar', content: [] },
    { id: 'culturas', content: [] },
    { id: 'tecnicos', content: [] },
    { id: 'bactericidasMicrobiologicos', content: [] },
    { id: 'bactericidas', content: [] },
    { id: 'nematicidas', content: [] },
    { id: 'inseticidas', content: [] },
    { id: 'inseticidasMicroBio', content: [] },
    { id: 'acaricidas', content: [] },
    { id: 'acaricidasMicroBio', content: [] },
    { id: 'fungicidas', content: [] },
    { id: 'cotacoes', content: [] },
    { id: 'fungicidasMicroBio', content: [] },
    { id: 'herbicidas', content: [] },
    { id: 'marcas', content: [] },
    { id: 'financeiro', content: [] },
    { id: 'gerente', content: [] },
    { id: 'oleosAdjuvantes', content: [] },
    { id: 'fitossanidade', content: [] },
    { id: 'fertilizantesQuimicos', content: [] },
    { id: 'fertilizantesOrganicos', content: [] },
    { id: 'plantios', content: [] },
    { id: 'especie', content: [] },
    { id: 'raca', content: [] },
    { id: 'regiao', content: [] },
    { id: 'relatorio-analise-risco', content: [] },
    { id: 'relatorio-produtores-potencial', content: [] },
    { id: 'relatorio-relacao-talhao', content: [] },
    { id: 'relatorio-analise-riscos-analitico', content: [] },
    { id: 'ordenacao-analise-riscos-analitico', content: [] },
    { id: 'ordenacao-produtores-potencial', content: [] },
    { id: 'ordenacao-analise-risco', content: [] },
    { id: 'ficha-produtor', content: [] },
    { id: 'relatorio-lista-produtores', content: [] },
    { id: 'ordenacao-lista-produtores', content: [] },
    { id: 'safra', content: [] },
    { id: 'relatorio-produtividade-graos', content: [] },
    { id: 'certificacao', content: [] },
    { id: 'homeopatico', content: [] },
    { id: 'inoculantes', content: [] },
    { id: 'relatorio-historico-produtividade', content: [] },
    { id: 'galeria', content: [] },
    { id: 'relatorio-plantio', content: [] },
    { id: 'classificacao', content: [] },
    { id: 'certificacao-organica', content: [] },
    { id: 'agencia', content: [] },
    { id: 'sancao-categoria', content: [] },
    { id: 'relatorio-visitas', content: [] },
    { id: 'medida-corretiva', content: [] },
    { id: 'nao-conformidade', content: [] },
    { id: 'forma-aplicacao', content: [] },
    { id: 'auditoria', content: [] },
    { id: 'agenda-visitas', content: [] },
    { id: 'analise-ogm', content: [] }
  ]
};
const histPaginas = {
  paginaAnterior: 'paginaAnterior'
};
let menuInicial = {
  open: false
};
const itemMenuSelecionado = {
  item: 'selecionado'
};
const infoUsuario = {
  info: {
    idCliente: '',
    idUsuario: '',
    dsEmail: '',
    nmUsuario: '',
    stContaStatus: '',
    stUsuario: '',
    tpPerfil: '',
    dsRazaoSocial: '',
    dsCnpj: '',
    dsNomeFantasia: '',
    idPlano: '',
    dsPlano: '',
    idPais: '',
    pais: ''
  }
};
const adicionarInfoUsuario = (state = infoUsuario, action) => {
  switch (action.type) {
    case 'ADD_INFOUSUARIO':
      let infoUser = state.info;
      infoUser.idCliente = action.info.idCliente;
      infoUser.idUsuario = action.info.idUsuario;
      infoUser.dsEmail = action.info.dsEmail;
      infoUser.nmUsuario = action.info.nmUsuario;
      infoUser.stContaStatus = action.info.stContaStatus;
      infoUser.stUsuario = action.info.stUsuario;
      infoUser.tpPerfil = action.info.tpPerfil;
      infoUser.dsRazaoSocial = action.info.dsRazaoSocial;
      infoUser.dsCnpj = action.info.dsCnpj;
      infoUser.dsNomeFantasia = action.info.dsNomeFantasia;
      infoUser.idPlano = action.info.idPlano;
      infoUser.dsPlano = action.info.dsPlano;
      infoUser.idPais = action.info.idPais;
      infoUser.stOrganica = action.info.stOrganica;
      infoUser.pais = action.info.pais;
      infoUser.permissaoNdvi = action.info.permissaoNdvi;
      infoUser.stCooperativa = action.info.stCooperativa;
      infoUser.tpCooperativa = action.info.tpCooperativa;
      return {
        ...state
      };
    default:
      return state;
  }
};
const menuOpen = (state = menuInicial, action) => {
  switch (action.type) {
    case 'OPEN_MENU':
      return { open: true };
    case 'CLOSE_MENU':
      resetarMenu(itensMenu);
      return { open: false };
    default:
      return state;
  }
};

const resetarMenu = itens => {
  for (var i = 0; i < itens.length; i++) {
    if (itens[i].selecionado) {
      itens[i].selecionado = false;
      if (itens[i].children !== undefined) {
        resetarMenu(itens[i].children);
      }
    }
  }
};

const itemSelecionado = (state = itemMenuSelecionado, action) => {
  switch (action.type) {
    case 'SELECIONAR_ITEM':
      return { item: action.itemSelecionado };
    default:
      return state;
  }
};
const previousPage = (state = histPaginas, action) => {
  switch (action.type) {
    case 'UPDATE_PREVIOUS_PAGE':
      return { paginaAnterior: action.idClean };
    default:
      return state;
  }
};

let toolbarTitle = {
  label: <FormattedMessage id={'toolbar.toolbar'} />,
  name: 'toolbar'
};
const toolbar = (state = toolbarTitle, action) => {
  switch (action.type) {
    case 'UPDATE_TOOLBAR':
      return { label: action.toolbar, name: action.name };
    default:
      return state;
  }
};

const descricao = (state, action) => {
  if (action.type === 'UPDATE_DESCRICAO') {
    return { label: action.descricao };
  } else {
    return {
      label: ''
    };
  }
};

const chips = (state = estadoInicial, action) => {
  switch (action.type) {
    case 'ADD_CHIPS':
      const updatedItems = state.chipData.map(item => {
        if (item.id === action.id) {
          return { ...item, content: [...item.content, action.newChip] };
        }
        return item;
      });
      return {
        ...state,
        chipData: updatedItems
      };
    case 'DELETE_CHIP':
      const deleteItems = state.chipData.map(item => {
        if (item.id === action.idOrigin) {
          return {
            ...item,
            content: item.content.filter((item, index) => action.index !== index)
          };
        }
        return item;
      });
      return {
        ...state,
        chipData: deleteItems
      };
    case 'CLEAN_CHIPS':
      const cleanItems = state.chipData.map(item => {
        if (item.idClean === action.idOrigin) {
          return { ...item, content: [] };
        }
        return item;
      });
      return {
        ...state,
        chipData: cleanItems
      };

    default:
      return state;
  }
};

export default combineReducers({
  itemSelecionado,
  menuOpen,
  toolbar,
  descricao,
  chips,
  previousPage,
  adicionarInfoUsuario
});
