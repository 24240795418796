import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { flattenMessages, formatDate, formatoDataIntercionalizado } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import EditarAmbiental from './EditarAmbiental';
import CardTitle from '@components/CardTitle/CardTitle';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = theme => ({
  root: {
    overflowY: 'scroll',
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 95px)',
    marginBottom: 15
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.35
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      contained: {
        height: '46px',
        width: '100px',
        marginLeft: '15px'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        }
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente para listagem da tab Ambiental
 *
 * @author Bruno Eduardo
 * @param {Object} props - props
 * @returns Componente React
 */
class Ambiental extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const participacaoAssociacoes = messagesLinguage['produtores.participacaoAssociacoes'];
    const conflitoLimites = messagesLinguage['produtores.conflitoLimites'];
    const campoObrigatorio = messagesLinguage['produtores.campoObrigatorio'];
    const descricao = messagesLinguage['produtores.descricao'];
    const localizacaoSede = messagesLinguage['produtores.localizacaoSede'];
    const informacoesClima = messagesLinguage['produtores.informacoesClima'];
    const informacoesSolo = messagesLinguage['produtores.informacoesSolo'];
    const terracentamento = messagesLinguage['produtores.terracentamento'];
    const ruim = messagesLinguage['produtores.ruim'];
    const satisfatorio = messagesLinguage['produtores.satisfatorio'];
    const razoavel = messagesLinguage['produtores.razoavel'];
    const plantioNivel = messagesLinguage['produtores.plantioNivel'];
    const usoPlantasCobertura = messagesLinguage['produtores.usoPlantasCobertura'];
    const plantioDireto = messagesLinguage['produtores.plantioDireto'];
    const naoSeAplica = messagesLinguage['produtores.naoSeAplica'];
    const sim = messagesLinguage['produtores.sim'];
    const nao = messagesLinguage['produtores.nao'];
    const protecaoFontes = messagesLinguage['produtores.protecaoFontes'];
    const protecaoLeitosAgua = messagesLinguage['produtores.protecaoLeitosAgua'];
    const analisePotabilidadeAgua = messagesLinguage['produtores.analisePotabilidadeAgua'];
    const usoIrrigacao = messagesLinguage['produtores.usoIrrigacao'];
    const organicosDomisanitarios = messagesLinguage['produtores.organicosDomisanitarios'];
    const organicosAnimais = messagesLinguage['produtores.organicosAnimais'];
    const reciclaveis = messagesLinguage['produtores.reciclaveis'];
    const embalagensAgrotoxicos = messagesLinguage['produtores.embalagensAgrotoxicos'];
    const destinacaoAdequadaResiduos = messagesLinguage['produtores.destinacaoAdequadaResiduos'];
    const ambiental = messagesLinguage['produtores.ambiental'];
    const data = messagesLinguage['produtores.data'];
    const dataLancamento = messagesLinguage['produtores.dataLancamento'];
    const salvar = messagesLinguage['produtores.salvar'];
    const cancelar = messagesLinguage['produtores.cancelar'];
    const identificacao = messagesLinguage['produtores.identificacao'];
    const residual = messagesLinguage['produtores.residual'];
    const solo = messagesLinguage['produtores.solo'];
    const agua = messagesLinguage['produtores.agua'];
    const cadastroAmbiental = messagesLinguage['produtores.cadastroAmbiental'];
    const edicaoAmbiental = messagesLinguage['produtores.edicaoAmbiental'];
    const tecnico = messagesLinguage['produtores.tecnico'];
    const dejetoAnimal = messagesLinguage['produtores.dejetoAnimal'];
    const descricaoDejetoAnimal = messagesLinguage['produtores.descricaoDejetoAnimal'];
    const carcacaAnimal = messagesLinguage['produtores.carcacaAnimal'];
    const descricaoCarcacaAnimal = messagesLinguage['produtores.descricaoCarcacaAnimal'];
    const periodoCompostagem = messagesLinguage['produtores.periodoCompostagem'];
    const descricaoPeriodoCompostagem = messagesLinguage['produtores.descricaoPeriodoCompostagem'];
    const periodoArmazenamentoUrina = messagesLinguage['produtores.periodoArmazenamentoUrina'];
    const descricaoPeriodoArmazenamentoUrina =
      messagesLinguage['produtores.descricaoPeriodoArmazenamentoUrina'];
    const descricaoOrganicosDomissanitarios =
      messagesLinguage['produtores.descricaoOrganicosDomissanitarios'];
    const descricaoReciclaveis = messagesLinguage['produtores.descricaoReciclaveis'];
    const descricaoEmbalagensAgrotoxicas = messagesLinguage['produtores.descricaoEmbalagensAgrotoxicas'];
    const abastecimentoPulverizacao = messagesLinguage['produtores.abastecimentoPulverizacao'];
    const descricaoAbastecimentoPulverizacao =
      messagesLinguage['produtores.descricaoAbastecimentoPulverizacao'];
    const fontes = messagesLinguage['produtores.fontes'];
    const abastecimentoComunitario = messagesLinguage['produtores.abastecimentoComunitario'];
    const rioLagos = messagesLinguage['produtores.rioLagos'];
    const detinacaoAdequadaResiduos = messagesLinguage['produtores.detinacaoAdequadaResiduos'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          cadastroAmbiental,
          edicaoAmbiental,
          solo,
          agua,
          residual,
          identificacao,
          salvar,
          cancelar,
          dataLancamento,
          data,
          destinacaoAdequadaResiduos,
          participacaoAssociacoes,
          conflitoLimites,
          campoObrigatorio,
          descricao,
          localizacaoSede,
          informacoesClima,
          informacoesSolo,
          terracentamento,
          ruim,
          satisfatorio,
          razoavel,
          plantioNivel,
          usoPlantasCobertura,
          plantioDireto,
          naoSeAplica,
          sim,
          nao,
          protecaoFontes,
          protecaoLeitosAgua,
          analisePotabilidadeAgua,
          usoIrrigacao,
          organicosDomisanitarios,
          organicosAnimais,
          reciclaveis,
          embalagensAgrotoxicos,
          ambiental,
          tecnico,
          descricaoDejetoAnimal,
          dejetoAnimal,
          descricaoCarcacaAnimal,
          carcacaAnimal,
          periodoCompostagem,
          descricaoPeriodoCompostagem,
          descricaoPeriodoArmazenamentoUrina,
          periodoArmazenamentoUrina,
          descricaoOrganicosDomissanitarios,
          descricaoReciclaveis,
          descricaoEmbalagensAgrotoxicas,
          abastecimentoPulverizacao,
          descricaoAbastecimentoPulverizacao,
          fontes,
          abastecimentoComunitario,
          rioLagos,
          detinacaoAdequadaResiduos
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.headDatatable = [
      {
        valueGetter: args => formatDate(args.node.data.dtLancamento, formatoDataIntercionalizado()),
        headerName: provider.intl.formatMessage({ id: 'dataLancamento' }),
        col: 6
      },
      {
        field: 'tecnico.nmUsuario',
        headerName: provider.intl.formatMessage({ id: 'tecnico' }),
        col: 6
      }
    ];

    this.state = {
      disabledButtons: true,
      itemSelect: '',
      data: this.props.data.filter(item => item.stRegistro !== 2),
      dsDescricao: '',
      tpSegregacaoInsumos: '',
      keyGrid: '',
      index: 0
    };

    this.onNew = this.onNew.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.updateList = this.updateList.bind(this);
  }

  /**
   * Mantem a lista do componente pai e do filho sincronizadas
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.state.data)) {
      this.setState({ data: nextProps.data.filter(item => item.stRegistro !== 2) });
    }
  }

  componentWillUnmount() {
    if (this.state.index === 1) {
      this.props.changeFAB();
    }
  }

  /**
   * Executa quando é clickado numa linha da lista
   *
   * @param {Object} selected - Linha selecionada
   */
  selectChange(selected) {
    if (this.state.atualizando) return;

    if (selected) {
      this.setState({ itemSelect: selected, disabledButtons: false });
    } else {
      this.setState({ itemSelect: '', disabledButtons: true });
    }
  }

  /**
   * Executa ao adicionar um novo Insumo
   *
   * @param {Object} values - Objeto Insumo novo
   */
  onNew(values) {
    this.setState({ atualizando: false, index: 0 }, () => {
      values.idAmbiental = this.props.maxIdFake;
      this.props.onNew(values);
      this.selectChange('');
      this.setState({
        keyGrid: Math.random()
      });
    });
  }

  /**
   * Executa ao atualizar um Insumo
   *
   * @param {Object} values - Objeto Insumo atualizado
   */
  onUpdate(values) {
    this.setState({ atualizando: false, index: 0 }, () => {
      this.props.onUpdate(values, this.state.itemSelect);
      this.selectChange('');
      this.setState({
        keyGrid: Math.random()
      });
    });
  }

  updateList() {
    this.setState({
      data: this.props.data.filter(item => item.stRegistro !== 2)
    });
  }

  render() {
    const { classes, tpPerfil, onDelete } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <SwipeableViewsOwn index={this.state.index} onChangeIndex={index => this.setState({ index })}>
            {[
              <div className={classes.grid} key={0}>
                <CardTitle
                  containerStyle={{ marginBottom: tpPerfil ? 0 : 'none' }}
                  title={provider.intl.formatMessage({ id: 'ambiental' })}
                />

                {!tpPerfil && (
                  <GroupButtonGrid
                    hiddenMargin
                    withoutMargin
                    showEdit
                    showDelete
                    showAdd
                    disableEdit={this.state.disabledButtons || this.state.atualizando}
                    disableDelete={this.state.disabledButtons || this.state.atualizando}
                    onClickEdit={() =>
                      this.setState(
                        { atualizando: true, cardOpen: true, index: 1 },
                        this.props.changeFAB
                      )
                    }
                    onClickDelete={() =>
                      this.state.itemSelect && onDelete(this.state.itemSelect, this.updateList)
                    }
                    onClickAdd={() => {
                      this.setState({ index: 1 }, this.props.changeFAB);
                    }}
                  />
                )}

                <DataTable
                  key={this.state.keyGrid}
                  data={this.state.data}
                  columns={this.headDatatable}
                  selectChange={this.selectChange}
                  showPagination={false}
                  borderRadius={false}
                  noMargin
                />
              </div>,

              <EditarAmbiental
                infoUsuario={this.props.infoUsuario}
                tpPerfilTecnico={this.props.tpPerfilTecnico}
                key={1}
                onCancel={() =>
                  this.setState(
                    { atualizando: false, keyGrid: Math.random(), index: 0 },
                    () => this.selectChange('') || this.props.changeFAB()
                  )
                }
                ambiental={this.state.itemSelect}
                onNew={this.onNew}
                onUpdate={this.onUpdate}
                atualizando={this.state.atualizando}
                provider={provider}
              />
            ]}
          </SwipeableViewsOwn>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Ambiental);
