export default {
  certificado: {
    dataFinal: 'Data final',
    dataInicial: 'Data inicial',
    adicionarCertificacao: 'Adicionar certificação',
    certificacaoOrganica: 'Certificação orgânica',
    campoObrigatorio: 'Campo obrigatório',
    desejaExcluirCertificacao: 'Deseja excluir certificação?',
    nao: 'Não',
    excluir: 'Excluir',
    certificacaoExcluidaSucesso: 'Certificação excluída com sucesso',
    historicoCertificacao: 'Histórico de certificação',
    indefinida: 'Indefinida',
    certificadoExistente: 'Já existe um certificado nesse período'
  }
};
