//@author Katia Miglioli
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ButtonGrid from '@components/ButtonGrid/ButtonGrid';
import iconAdd from '@images/icone_add.png';
import iconEdit from '@images/icone_editar.png';
import iconDelete from '@images/icone_excluir.png';
import iconAplicacoes from '@images/icon_aplicacoes.png';

const styles = theme => ({
  divButtonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    width: '1024',
    marginBottom: '-22px',
    marginRight: '20px'
  }
});

class GroupButtonGrid extends Component {
  render() {
    const { classes } = this.props;
    const customButtons = this.props.customButtons || [];
    const listButtons = customButtons.map(customButton => (
      <div key={customButton.toString()}>{customButton}</div>
    ));

    return (
      <div className={classes.divButtonGrid}>
        {listButtons}
        <ButtonGrid
          hiddenMargin={this.props.hiddenMargin}
          withoutMargin={this.props.withoutMargin}
          show={this.props.showAplicacoes}
          icon={iconAplicacoes}
          color='#463766'
          disabled={this.props.disableAplicacoes}
          onClick={this.props.onClickAplicacoes}
        />
        <ButtonGrid
          hiddenMargin={this.props.hiddenMargin}
          withoutMargin={this.props.withoutMargin}
          show={this.props.showAdd}
          icon={iconAdd}
          color='#00863B'
          disabled={this.props.disableAdd}
          onClick={this.props.onClickAdd}
        />
        <ButtonGrid
          hiddenMargin={this.props.hiddenMargin}
          withoutMargin={this.props.withoutMargin}
          show={this.props.showEdit}
          icon={iconEdit}
          color='#42ADE8'
          disabled={this.props.disableEdit}
          onClick={this.props.onClickEdit}
        />
        <ButtonGrid
          hiddenMargin={this.props.hiddenMargin}
          withoutMargin={this.props.withoutMargin}
          show={this.props.showDelete}
          icon={iconDelete}
          color='#F33A30'
          disabled={this.props.disableDelete}
          onClick={this.props.onClickDelete}
        />
      </div>
    );
  }
}

export default withStyles(styles)(GroupButtonGrid);
