export default {
  cotacao: {
    filtro: "Filtro",
    cotacoes: "Cotações",
    cultura: "Cultura",
    filtrar: "Filtrar",
    cotacao: "Cotação",
    variacaoDePreco: "Variação de preço",
    selecione: "Selecione uma cultura",
    data: "Data",
    valor: "Valor",
    campoObrigatorio: "Campo obrigatório",
    cancelar: "Cancelar",
    salvar: "Salvar",
    identificacaoDaCotacao: "Identificação",
    cadastroDeCotacoes: "Cadastro de cotações",
    falhaAoCarregarCotacao: "Falha ao carregar cotação",
    salvando: "Salvando",
    atualizando: "Atualizando",
    cotacaoCadastradaComSucesso: "Cotação salva com sucesso",
    falhaAoCadastrarCotacao: "Falha ao cadastrar cotação",
    cotacaoEditadaComSucesso: "Cotação editada com sucesso",
    desejaExcluir: "Deseja excluir cotação?",
    excluir: "Excluir",
    nao: "Não",
    excluindo: "Excluindo",
    cotacaoRemovidaComSucesso: "Cotação removida com sucesso",
    falhaAoExcluirCotacao: "Falha ao excluir cotação",
    rs: "R$"
  }
};
