import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import formatMessage from '../i18n/formatMessage';
import * as Yup from 'yup';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import SeveridadeEnum from './../enumerations/SeveridadeEnum';
import Severidade from '@resources/Severidade';
import * as moment from 'moment';
import { style, theme } from '../AcompanhamentoProdutor.styles';
import { swalOptions, swalSucess } from './../utils/SwalOptions';

/**
 * Componente de editar itens de alertas fitossanitarios
 */
function EditarAlertasFitossanitarios(props) {
  const {
    onClickAddProduto,
    clickShowButton,
    itemSelectProdutos,
    data,
    plantios,
    plantio,
    onClickDeleteProduto,
    classes
  } = props;
  const [tpSeveridade, setTpSeveridade] = useState('');
  const [statusEdit, setStatusEdit] = useState(false);
  const [itemSelect, setItemSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [keySeveridade, setKeySeveridade] = useState('');
  const [severidadeList, setSeveridadeList] = useState([]);
  const [valueAutoCompleteSeveridade, setValueAutoCompleteSeveridade] = useState('');

  useEffect(() => {
    if (itemSelectProdutos !== null) {
      validarInicializacaoAutoCompleteSeveridade(itemSelectProdutos.severidade.tpSeveridade);
      setValueAutoCompleteSeveridade(itemSelectProdutos.severidade);

      setOpenModal(true);
      setStatusEdit(true);
      setTpSeveridade(itemSelectProdutos.severidade.tpSeveridade);
      setItemSelect(itemSelectProdutos);
    } else {
      setValueAutoCompleteSeveridade('');

      setOpenModal(true);
      setStatusEdit(false);
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      {openModal && (
        <Formik
          initialValues={
            statusEdit
              ? {
                  tpSeveridade: tpSeveridade,
                  severidade: valueAutoCompleteSeveridade || ''
                }
              : {
                  tpSeveridade: '',
                  severidade: ''
                }
          }
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={onSave}
          validationSchema={Yup.object().shape({
            tpSeveridade: Yup.string().required(
              formatMessage('acompanhamentoProdutor.campoObrigatorio')
            ),
            severidade: Yup.string().required(formatMessage('acompanhamentoProdutor.campoObrigatorio'))
          })}
          render={({ values, handleSubmit, errors, touched, setFieldValue }) => (
            <div className={classes.cardCadastroAlerta}>
              <CardCadastro
                marginLeft={30}
                titulo={formatMessage('acompanhamentoProdutor.adicionarAlertas')}
                botoes={[
                  statusEdit && (
                    <Button color='primary' variant='contained' onClick={clickExcluir}>
                      {formatMessage('acompanhamentoProdutor.excluir')}
                    </Button>
                  ),
                  <Button variant='contained' color='inherit' onClick={onCancelar}>
                    {formatMessage('acompanhamentoProdutor.cancelar')}
                  </Button>,
                  <Button color='secondary' variant='contained' onClick={handleSubmit}>
                    {formatMessage('acompanhamentoProdutor.confirmar')}
                  </Button>
                ]}
              >
                <div style={{ padding: 16 }}>
                  <form autoComplete='off'>
                    <Grid container spacing={16} style={{ height: 80 }}>
                      <Grid item xs={2}>
                        <MuiThemeProvider theme={theme}>
                          <FormControl
                            className={classes.form}
                            error={errors.tpSeveridade && touched.tpSeveridade}
                          >
                            <InputLabel
                              htmlFor='areaCultivo-helper'
                              error={errors.tpSeveridade && touched.tpSeveridade}
                              className={classes.font}
                            >
                              {formatMessage('acompanhamentoProdutor.tipo')}
                            </InputLabel>
                            <Select
                              className={classes.selectFitossanidade}
                              SelectDisplayProps={{
                                style: {
                                  width: '100%',
                                  backgroundColor: 'transparent'
                                }
                              }}
                              value={values.tpSeveridade}
                              onChange={value => onChangeTpSeveridade(value, setFieldValue)}
                              input={<Input id='tpSeveridade-error' />}
                              inputProps={{
                                className: classes.font,
                                name: 'tpSeveridade',
                                id: 'tpSeveridade-helper'
                              }}
                            >
                              {SeveridadeEnum.map(sev => (
                                <MenuItem
                                  value={sev.id}
                                  key={SeveridadeEnum.indexOf(sev)}
                                  className={classes.font}
                                >
                                  {sev.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.tpSeveridade && touched.tpSeveridade && (
                              <FormHelperText>
                                {formatMessage('acompanhamentoProdutor.campoObrigatorio')}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </MuiThemeProvider>
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          disabled={values.tpSeveridade === ''}
                          itens={severidadeList}
                          campoOp={'nmSeveridade'}
                          campoChave={'idSeveridade'}
                          label={formatMessage('acompanhamentoProdutor.agenteCausal')}
                          name='severidade'
                          error={errors.severidade && touched.severidade}
                          helperText={
                            errors.severidade &&
                            touched.severidade &&
                            formatMessage('acompanhamentoProdutor.campoObrigatorio')
                          }
                          onChangeOwn={nmSeveridade => {
                            setKeySeveridade(Math.random());
                            setValueAutoCompleteSeveridade(nmSeveridade);
                            setFieldValue('severidade', nmSeveridade);
                          }}
                          onChangeAutoComplete={validarInicializacaoAutoCompleteSeveridade}
                          value={tratarValorSelectInicialSeveridade(valueAutoCompleteSeveridade)}
                          key={keySeveridade}
                          valueAutoComplete={tratarValorSelectInicialSeveridade(
                            valueAutoCompleteSeveridade
                          )}
                          valueSelect={
                            tratarValorSelectInicialSeveridade(valueAutoCompleteSeveridade).nmSeveridade
                          }
                        />
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </CardCadastro>
            </div>
          )}
        />
      )}
    </MuiThemeProvider>
  );

  /**
   * Trata os dados quando o select é alterado
   * @param {*} value - novo dado
   * @param {*} setFieldValue - função do formik de alterar dado
   */
  function onChangeTpSeveridade(value, setFieldValue) {
    setFieldValue('tpSeveridade', value.target.value);
    setFieldValue('severidade', null);
    setValueAutoCompleteSeveridade(null);
    validarInicializacaoAutoCompleteSeveridade(value.target.value);

    setTpSeveridade(value.target.value);
    setKeySeveridade(Math.random());
  }

  /**
   * Ação ao clicar em cancelar na tela de cadastro de alertas fitossanitário
   */
  function onCancelar() {
    setValueAutoCompleteSeveridade('');
    setOpenModal(false);
    clickShowButton(true);
  }

  /**
   * Ação ao clicar em excluir na grid
   */
  function clickExcluir() {
    const position = data.indexOf(itemSelect);

    swalOptions(formatMessage('acompanhamentoProdutor.desejaExcluirAlerta')).then(willDelete => {
      if (willDelete) {
        onClickDeleteProduto(position);
        swalSucess('acompanhamentoProdutor.alertaRemovidoComSucesso');
      }
    });
  }

  /**
   * Ação ao salvar um novo alerta fitossanitário
   * @param {*} values - dados cadastrados
   */
  function onSave(values) {
    const valueFinal = {
      severidade: valueAutoCompleteSeveridade,
      tpSeveridade: values.tpSeveridade,
      dtSeveridade: moment()
        .utc()
        .format('YYYY-MM-DDTHH:mm:ssZZ'),
      tipoSeveridade: SeveridadeEnum[values.tpSeveridade]
    };

    if (statusEdit) {
      clearDados(valueFinal, data.indexOf(itemSelect));
    } else {
      clearDados(valueFinal, null);
    }
  }

  /**
   * Limpa os dados inseridos pelo usuarios
   */
  function clearDados(valueFinal, position) {
    setOpenModal(false);
    setItemSelect('');
    setValueAutoCompleteSeveridade('');

    onClickAddProduto(valueFinal, position);
  }

  /**
   * Busca a severidade de acordo com o tipo selecionado
   * @param {*} tpSeveridadeSelected - tipo da severidade selecionada
   */
  function validarInicializacaoAutoCompleteSeveridade(tpSeveridadeSelected) {
    if (tpSeveridadeSelected !== undefined) {
      if (plantio !== null && plantios !== '') {
        Severidade.findSeveridadeByTipo(tpSeveridadeSelected)
          .then(query => {
            let severidadeListAux = query.data;

            let severidadeListFilter = [];
            severidadeListAux.forEach(doc => {
              if (doc.cultura.idCultura === props.plantio.cultura.idCultura && doc.stSeveridade === 1) {
                severidadeListFilter.push(doc);
              }
            });

            setSeveridadeList(severidadeListFilter);
          })
          .catch(() => {
            setSeveridadeList([]);
          });
      } else {
        setSeveridadeList([]);
      }
    }
  }

  /**
   * Trata os valores no autocomplete de severidade
   */
  function tratarValorSelectInicialSeveridade(severidade) {
    if (!severidade || severidade.nmSeveridade === undefined) {
      return '';
    }

    return severidade;
  }
}

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarAlertasFitossanitarios);
export default withRouter(connect(mapStateToProps)(enhaced));
