export default {
  bactericidas: {
    bactericidasRemovidoComSucesso: 'Bactericida removido com sucesso',
    falhaAoExcluirBactericidas: 'Falha ao excluir bactericida',
    desejaExcluir: 'Deseja excluir o bactericida selecionado?',
    bactericidas: 'Bactericidas',
    cadastroDeBactericidas: 'Cadastro de Bactericidas',
    identificacaoDaBactericidas: 'Identificação',
    bactericidasCadastradoComSucesso: 'Bactericida cadastrado com sucesso',
    bactericidasEditadoComSucesso: 'Bactericida editado com sucesso',
    falhaAoEditarBactericidas: 'Falha ao editar bactericida',
    falhaAoCadastrarBactericidas: 'Falha ao cadastrar bactericida',
    falhaAoCarregarBactericidas: 'Falha ao carregar bactericida'
  }
};
