export default {
  regiao: {
    regiao: 'Region',
    regioes: 'Regions',
    desejaExcluir: 'Do you want to delete region?',
    excluir: 'Delete',
    nao: 'No',
    excluindo: 'Excluding',
    regiaoRemovidoComSucesso: 'Region successfully excluded',
    falhaAoExcluirRegiao: 'Failed to delete region',
    cadastroDeRegiao: 'Region Register',
    falhaAoCarregarRegiao: 'Failed to load region',
    salvando: 'Saving',
    atualizando: 'Updating',
    regiaoCadastradaComSucesso: 'Region successfully registered',
    falhaAoCadastrarRegiao: 'Failed to register region',
    regiaoEditadaComSucesso: 'Region successfully edited',
    falhaAoEditarRegiao: 'Failed to edit region',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    descricao: 'Description',
    salvar: 'Save',
    cancelar: 'Cancel',
    municipio: 'City',
    municipioRemovidoComSucesso: 'City removed successfully',
    estado: 'State',
    confirmar: 'Confirm',
    municipioJaCadastrado: 'City is already registered',
    municipiosJáRelacinados: 'The following municipalities are already listed in other regions:'
  }
};
