export default {
  fertilizantesOrganicos: {
    fertilizantesOrganicosRemovidoComSucesso: 'Fertilizante orgânico removido com sucesso',
    falhaAoExcluirFertilizantesOrganicos: 'Falha ao excluir fertilizante orgânico',
    desejaExcluir: 'Deseja excluir o fertilizante orgânico selecionado?',
    fertilizantesOrganicos: 'Fertilizante orgânico',
    cadastroDeFertilizantesOrganicos: 'Cadastro de fertilizantes orgânicos',
    identificacaoDaFertilizantesOrganicos: 'Identificação',
    fertilizantesOrganicosCadastradoComSucesso: 'Fertilizante orgânico cadastrado com sucesso',
    fertilizantesOrganicosEditadoComSucesso: 'Fertilizante orgânico editado com sucesso',
    falhaAoEditarFertilizantesOrganicos: 'Falha ao editar fertilizante orgânico',
    falhaAoCadastrarFertilizantesOrganicos: 'Falha ao cadastrar fertilizante orgânico',
    falhaAoCarregarFertilizantesOrganicos: 'Falha ao carregar fertilizante orgânico'
  }
};
