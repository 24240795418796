import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import formatMessage from './i18n/formatMessage';
import { findAuditoriaById, addAuditoria, editAuditoria } from '@resources/AuditoriaPlantio';
import { findPlantioByAreaCultivo } from '@resources/Plantio';
import Produtor from '@resources/Produtores';
import { findAreaCultivoByProdutor } from '@resources/AreaCultivo';
import { style, theme } from './Auditoria.styles';
import moment from 'moment';
import { formatTextField } from './header';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import EditarAuditoriaNaoConformidade from './AuditoriaNaoConformidade/EditarAuditoriaNaoConformidade';
import { findAllNaoConformidade } from '@resources/NaoConformidade';
import Usuario from '@resources/Usuario';
import Visita from './../../Visita/Visita';
import Agendas from '@resources/Agenda';
import Identificacao from './Identificacao';

/**
 * Screen de edição ou cadastro de uma auditoria
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarAuditoria(props) {
  const { classes } = props;

  const isTecnico = props.infoUsuario.tpPerfil === 2;
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [produtor, setProdutor] = useState(null);
  const [produtorList, setProdutorList] = useState([]);
  const [areaCultivoList, setAreaCultivoList] = useState([]);
  const [areaCultivo, setAreaCultivo] = useState(null);
  const [plantioList, setPlantioList] = useState([]);
  const [plantio, setPlantio] = useState(null);
  const [keyAutoComplete, setKeyAutoComplete] = useState(0);
  const [naoConformidadeSelect, setNaoConformidadeSelect] = useState(null);
  const [stBackAgenda, setStBackAgenda] = useState(false);
  const [usuarioList, setUsuarioList] = useState([]);
  const [dsMedidaCorretiva, setDsMedidaCorretiva] = useState('');
  const [initialValues, setInitialValues] = useState({
    dsSuspensao: '',
    produtor: null,
    dtInicio: null,
    dtTermino: null,
    areaCultivo: null,
    plantio: null,
    tpAuditoria: 0,
    stAuditoriaPlantio: 0
  });
  const [isLoading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [naoConformidadeList, setNaoConformidadeList] = useState([]);
  const [auditoriaPlantioNaoConformidadeList, setAuditoriaPlantioNaoConformidadeList] = useState([]);
  const [talhaoList, setTalhaoList] = useState([]);
  const [stVisitaRealizada, setStVisitaRealizada] = useState(false);
  const [initialValuesAuditoria, setInitialValuesAuditoria] = useState(null);

  useEffect(() => {
    const title = 'auditoria.cadastroDeAuditoria';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    loadUsuario();
    loadProdutor();
    loadNaoConformidadeSancao();

    if (props.match.params.id !== undefined) {
      setEnableReinitialize(true);
    }
  }, []);

  useEffect(() => {
    if (!enableReinitialize) return;
    setLoading(true);

    findAuditoriaById(props.match.params.id)
      .then(res => {
        const initialValuesAux = res.data;
        setProdutor(initialValuesAux.plantio.produtor);
        setAreaCultivo(initialValuesAux.plantio.areaCultivo);
        setPlantio(mountTitleAutoCompletePlantio(initialValuesAux.plantio));
        loadTalhao(initialValuesAux.plantio.plantioTalhaoList);

        if (!initialValuesAux.dtTermino) {
          initialValuesAux.dtTermino = null;
        }

        initialValuesAux.produtor = initialValuesAux.plantio.produtor;
        initialValuesAux.areaCultivo = initialValuesAux.plantio.areaCultivo;
        initialValuesAux.plantio = mountTitleAutoCompletePlantio(initialValuesAux.plantio);
        const auditoriaPlantioNaoConformidadeListAux = [];
        initialValuesAux.auditoriaPlantioNaoConformidadeList.forEach(item => {
          item.naoConformidade = item.naoConformidadeSancao.naoConformidade;
          item.sancaoCategoria = item.naoConformidadeSancao.sancaoCategoria;
          item.tipoCertificacao = item.naoConformidadeSancao.tipoCertificacao;
          auditoriaPlantioNaoConformidadeListAux.push(item);
        });
        setAuditoriaPlantioNaoConformidadeList(auditoriaPlantioNaoConformidadeListAux);
        setInitialValues(initialValuesAux);

        setKeyAutoComplete(Math.random());

        loadAreaCultivo(initialValuesAux.plantio.produtor);
        loadPlantio(initialValuesAux.plantio.areaCultivo);
      })
      .catch(err => swalError('auditoria.erroAoBuscarDados'))
      .finally(() => {
        setEnableReinitialize(false);
        setLoading(false);
      });
  }, [enableReinitialize]);

  function loadTalhao(plantioTalhaoList) {
    const talhaoListAux = [];
    plantioTalhaoList.forEach(item => {
      talhaoListAux.push({
        idTalhao: item.talhao.idTalhao,
        dsTalhao: item.talhao.dsTalhao,
        ...item
      });
    });

    setTalhaoList(talhaoListAux);
  }

  function loadUsuario() {
    Usuario.findAllUsuarios()
      .then(doc => setUsuarioList(doc.data.filter(usuario => usuario.tpPerfil !== 3)))
      .catch(() => setUsuarioList([]));
  }

  function loadProdutor() {
    Produtor.findProdutor()
      .then(doc => setProdutorList(doc.data))
      .catch(() => setProdutorList([]));
  }

  function loadAreaCultivo(produtorAux) {
    if (produtorAux) {
      findAreaCultivoByProdutor(produtorAux.idProdutor)
        .then(doc => setAreaCultivoList(doc.data))
        .catch(() => setAreaCultivoList([]));
    } else {
      setAreaCultivoList([]);
    }
  }

  function mountTitleAutoCompletePlantio(plantioAux) {
    const cultivarEspec = plantioAux.cultivarEspec.nmCultivarEspec;
    const dtPlantio = moment(plantioAux.dtPlantio).format(formatTextField());
    const dtColheita = plantioAux.dtColheita
      ? moment(plantioAux.dtColheita).format(formatTextField())
      : '---';

    plantioAux.dsPlantioSelected = `${plantioAux.cultura.nmCultura} - ${cultivarEspec} - ${formatMessage(
      'auditoria.plantio'
    )}: ${dtPlantio}`;
    plantioAux.titleSubLine = '';
    plantioAux.dsPlantioCustom = `${plantioAux.cultura.nmCultura} - ${cultivarEspec}`;
    plantioAux.dtPlantioFormat = `${formatMessage('auditoria.plantio')}: ${dtPlantio}`;
    plantioAux.dsSubLineCustom = `${formatMessage('auditoria.colheita')}: ${dtColheita}`;

    return plantioAux;
  }

  function loadNaoConformidadeSancao() {
    findAllNaoConformidade()
      .then(doc => setNaoConformidadeList(doc.data))
      .catch(() => setNaoConformidadeList([]));
  }

  function loadPlantio(areaCultivoAux) {
    if (areaCultivoAux) {
      findPlantioByAreaCultivo(areaCultivoAux.idAreaCultivo)
        .then(doc => {
          const plantioListAux = [];

          doc.data.forEach(plantioAux => {
            plantioListAux.push(mountTitleAutoCompletePlantio(plantioAux));
          });

          setPlantioList(plantioListAux);
        })
        .catch(() => setPlantioList([]));
    } else {
      setPlantioList([]);
    }
  }

  function onChangeProdutor(value) {
    setProdutor(value);
    setAreaCultivo(null);
    setPlantio(null);
    setKeyAutoComplete(Math.random());
    loadAreaCultivo(value);
    initialValues.produtor = value;
    initialValues.areaCultivo = null;
    initialValues.plantio = null;
  }

  function onChangeAreaCultivo(value) {
    setAreaCultivo(value);
    setPlantio(null);
    setKeyAutoComplete(Math.random());
    loadPlantio(value);
    initialValues.areaCultivo = value;
    initialValues.plantio = null;
  }

  function onChangePlantio(value) {
    setPlantio(value);
    loadTalhao(value.plantioTalhaoList.filter((plantioTalhao) => plantioTalhao.talhao.stTalhao === 1));
    setKeyAutoComplete(Math.random());
    initialValues.plantio = value;
  }

  function tratarValorProdutor() {
    if (!produtor || produtor.nmProdutor === undefined) {
      return '';
    }

    return produtor;
  }

  function tratarValorAreaCultivo() {
    if (!areaCultivo || areaCultivo.dsAreaCultivo === undefined) {
      return '';
    }

    return areaCultivo;
  }

  function tratarValorPlantio() {
    if (!plantio) {
      return '';
    }

    return plantio;
  }

  function clickSaveAuditoriaNaoConformidade(auditoriaNaoConformidade) {
    const auditoriaPlantioNaoConformidadeListAux = [...auditoriaPlantioNaoConformidadeList];

    if (naoConformidadeSelect) {
      const auditoriaPlantioNaoConformidade = auditoriaPlantioNaoConformidadeListAux.find(
        auditoriaPlantioNaoConformidadeAux =>
          auditoriaPlantioNaoConformidadeAux.idAuditoriaPlantioNaoConformidade ===
          auditoriaNaoConformidade.idAuditoriaPlantioNaoConformidade
      );
      const position = auditoriaPlantioNaoConformidadeListAux.indexOf(auditoriaPlantioNaoConformidade);
      auditoriaPlantioNaoConformidadeListAux[position] = auditoriaNaoConformidade;
    } else {
      auditoriaNaoConformidade.idAuditoriaPlantioNaoConformidade = Math.random();
      auditoriaNaoConformidade.stRegistro = 0;
      auditoriaPlantioNaoConformidadeListAux.push(auditoriaNaoConformidade);
    }

    setAuditoriaPlantioNaoConformidadeList(auditoriaPlantioNaoConformidadeListAux);
    setIndex(0);
    setStBackAgenda(false);
  }

  function swalError(title) {
    swal(formatMessage(title), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  function swalWarning(title, buttonOk, buttonCancel, dsDescricao) {
    const optionConfirm = {
      text: formatMessage(buttonOk),
      value: true,
      visible: true,
      closeModal: false,
      className: buttonCancel ? 'swal2-confirm' : 'swal2-cancel'
    };

    let options = {
      confirm: optionConfirm
    };

    if (buttonCancel) {
      const optionCancel = {
        text: formatMessage(buttonCancel),
        value: false,
        visible: true,
        closeModal: true,
        className: 'swal2-cancel'
      };

      options = {
        cancel: optionCancel,
        confirm: optionConfirm
      };
    }

    return swal(dsDescricao, {
      title: formatMessage(title),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: options
    });
  }

  function cleanNaoConformidades(setFieldValue, value) {
    if (plantio) {
      swalWarning(
        'auditoria.alterandoPlantioLimpaNaoConformidade',
        'auditoria.ok',
        'auditoria.cancelar'
      ).then(res => {
        if (res) {
          setFieldValue('plantio', value);
          onChangePlantio(value);
        } else {
          setFieldValue('plantio', initialValues.plantio);
          onChangePlantio(initialValues.plantio);
          setPlantio(initialValues.plantio);
          setKeyAutoComplete(Math.random());
        }

        swal.close();
      });
    } else {
      setFieldValue('plantio', value);
      onChangePlantio(value);
    }
  }

  function clickAddOrEditNaoConformidadeSancao(value, plantioAux) {
    if (!plantioAux) {
      swalWarning('auditoria.insiraPlantio', 'auditoria.ok').then(res => {
        swal.close();
      });
      return;
    }

    setIndex(1);
    if (value) {
      setNaoConformidadeSelect({ ...value });
    } else {
      setNaoConformidadeSelect(null);
    }
  }

  function salvarAgenda(values, evento) {
    swalSalvando();

    const time = {
      timeInicio: moment(values.horario).format('HH:mm'),
      timeFim: moment(values.horarioFinal).format('HH:mm'),
      dtVisita: moment(values.data).format('YYYY-MM-DD')
    };

    const dado = {
      dsObservacao: values.observacao,
      dhInicioVisita: moment(time.dtVisita + 'T' + time.timeInicio).format('YYYY-MM-DDTHH:mm:ssZZ'),
      dhTerminoVisita: moment(time.dtVisita + 'T' + time.timeFim).format('YYYY-MM-DDTHH:mm:ssZZ'),
      stAgenda: 0,
      dsResultadoVisita: values.dsResultadoVisita || null,
      produtor: values.produtor
    };

    dado.tecnico = props.infoUsuario;

    let shortTime = [];

    const dataInicio = dado.dhInicioVisita;
    const dataFinal = dado.dhTerminoVisita;

    Agendas.findAgendaByData(
      moment(dado.dhInicioVisita).format('YYYY-MM-DD'),
      moment(dado.dhTerminoVisita).format('YYYY-MM-DD')
    )
      .then(query => {
        query.data.forEach(doc => {
          var dataItem = doc;

          if (dataItem.tecnico.dsEmail === dado.tecnico.dsEmail || doc.tpRegistro === 1) {
            const itemHorarioInicio = moment(dataItem.dhInicioVisita).format('YYYY-MM-DDTHH:mm:ssZZ');
            const itemHorarioFim = moment(dataItem.dhTerminoVisita).format('YYYY-MM-DDTHH:mm:ssZZ');

            if (
              (dataInicio > itemHorarioInicio &&
                dataInicio < itemHorarioFim &&
                dado.idAgenda !== dataItem.idAgenda) ||
              (dataFinal > itemHorarioInicio &&
                dataFinal < itemHorarioFim &&
                dado.idAgenda !== dataItem.idAgenda) ||
              (dataInicio < itemHorarioInicio &&
                dataFinal > itemHorarioFim &&
                dado.idAgenda !== dataItem.idAgenda)
            ) {
              shortTime.push(dataItem);
            }
          }
        });

        if (shortTime.length > 0) {
          swalWarning(
            'auditoria.alterandoPlantioLimpaNaoConformidade',
            'auditoria.ok',
            'auditoria.cancelar',
            <div>
              {formatMessage('auditoria.mesmoHorario')}
              {shortTime.map(
                item =>
                  item.tpRegistro === 0 && <div key={item.idAgenda}> {item.produtor.nmProdutor} </div>
              )}
            </div>
          ).then(willDelete => {
            if (willDelete) {
              onGravar(values, dado);
            }
          });
        } else {
          onGravar(values, dado);
        }
      })
      .catch(() => {
        swalError('auditoria.erroAoCadastrar');
      });
  }

  function onGravar(values, dado) {
    dado.tpRegistro = 0;
    values.stAgenda = 0;
    values.dsResultadoVisita = '';
    dado.stAgenda = stVisitaRealizada ? 2 : 0;

    dado.stAgendaPrevia = stVisitaRealizada ? 0 : 1;

    Agendas.addAgenda(dado)
      .then(query => {
        swal(formatMessage('auditoria.visitaCadastradaComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        }).then(() => {
          setIndex(1);
        });
      })
      .catch(erro => {
        swalError('auditoria.erroAoCadastrar');
      });
  }

  function clickBack(){
    if (props.match.params.acompanhamento) {
      props.history.push(`/app/acompanhamento-produtor/${props.match.params.acompanhamento}`)
    } else {
      props.history.push('/app/auditoria')
    }
  }

  return (
    <SwipeableViewsOwn index={index} onChangeIndex={indexAux => setIndex(indexAux)}>
      {[
        <div key={0}>
          <Formik
            initialValues={initialValues}
            validateOnBlur
            enableReinitialize={enableReinitialize}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              produtor: Yup.object()
                .nullable()
                .required(formatMessage('auditoria.campoObrigatorio')),
              plantio: Yup.object()
                .nullable()
                .required(formatMessage('auditoria.campoObrigatorio')),
              areaCultivo: Yup.object()
                .nullable()
                .required(formatMessage('auditoria.campoObrigatorio')),
              dtInicio: Yup.string()
                .nullable()
                .required(formatMessage('auditoria.campoObrigatorio')),
              dtTermino: Yup.string().nullable(),
              dsSuspensao: Yup.string(),
              tpAuditoria: Yup.number()
                .nullable()
                .required(formatMessage('auditoria.campoObrigatorio')),
              stAuditoriaPlantio: Yup.number()
                .nullable()
                .required(formatMessage('auditoria.campoObrigatorio'))
            })}
            onSubmit={handleSubmitFormik}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue
            }) => (
              <Identificacao
                isTecnico={isTecnico}
                classes={classes}
                values={values}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                produtor={produtor}
                produtorList={produtorList}
                loadProdutor={loadProdutor}
                areaCultivo={areaCultivo}
                loadAreaCultivo={loadAreaCultivo}
                areaCultivoList={areaCultivoList}
                plantio={plantio}
                plantioList={plantioList}
                loadPlantio={loadPlantio}
                isLoading={isLoading}
                onChangeProdutor={onChangeProdutor}
                onChangeAreaCultivo={onChangeAreaCultivo}
                keyAutoComplete={keyAutoComplete}
                tratarValorProdutor={tratarValorProdutor}
                initialValues={initialValues}
                tratarValorAreaCultivo={tratarValorAreaCultivo}
                cleanNaoConformidades={cleanNaoConformidades}
                tratarValorPlantio={tratarValorPlantio}
                auditoriaPlantioNaoConformidadeList={auditoriaPlantioNaoConformidadeList}
                clickAddOrEditNaoConformidadeSancao={clickAddOrEditNaoConformidadeSancao}
                isEditing={props.match.params.id !== undefined}
                clickFabBack={() => clickBack()}
              />
            )}
          />
        </div>,
        <div key={1}>
          <EditarAuditoriaNaoConformidade
            isTecnico={isTecnico}
            dtPlantio={initialValues.plantio ? moment(initialValues.plantio.dtPlantio) : null}
            usuarioList={usuarioList}
            loadUsuarioList={loadUsuario}
            talhaoListAll={talhaoList}
            classes={classes}
            naoConformidadeList={naoConformidadeList}
            loadNaoConformidade={loadNaoConformidadeSancao}
            clickToCancel={() => {
              setIndex(0);
              setStBackAgenda(false);
            }}
            valuesToEdit={stBackAgenda ? initialValuesAuditoria : naoConformidadeSelect}
            clickSaveAuditoriaNaoConformidade={clickSaveAuditoriaNaoConformidade}
            clickAgendar={(dsMedidaCorretivaAux, initialValuesAuditoriaAux) => {
              setDsMedidaCorretiva(dsMedidaCorretivaAux);
              setInitialValuesAuditoria(initialValuesAuditoriaAux);
              setStBackAgenda(true);
              setIndex(2);
            }}
          />
        </div>,
        <div key={2} className={classes.screenAgendar}>
          <Visita
            check={stVisitaRealizada}
            onChangeCheck={() => setStVisitaRealizada(!stVisitaRealizada)}
            title={formatMessage('auditoria.agendaVisita')}
            produtores={[]}
            typeAuditoria={true}
            classes={classes}
            openModal={true}
            statusEditar={false}
            itemSelect={{
              dtVisita: moment(),
              produtor: produtor,
              observacao: dsMedidaCorretiva
            }}
            initialData={moment()}
            typeProdutor={false}
            onSubmit={salvarAgenda}
            clickExcluir={() => {}}
            onClose={() => {
              setIndex(1);
            }}
            onChangeDatePicker={() => {}}
            valueAutoComplete={''}
            onChangeOwn={() => {}}
            value={''}
            valueAutoCompleteTratado={() => {}}
            valueSelect={() => {}}
            tpPerfil={props.infoUsuario.tpPerfil === 3}
            acompanhamentoTecnico={false}
          />
        </div>
      ]}
    </SwipeableViewsOwn>
  );

  function swalSalvando() {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(
          `auditoria.${props.match.params.id === undefined ? 'salvando' : 'atualizando'}`
        )
      }
    );
  }

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {*} values - Valores do submit
   */
  function handleSubmitFormik(values) {
    setLoading(true);
    swalSalvando();

    values.idAuditoriaPlantio = +props.match.params.id || null;
    values.dtInicio = moment(values.dtInicio).format('YYYY-MM-DDTHH:mm:ssZZ');
    if (values.dtTermino) {
      values.dtTermino = moment(values.dtTermino).format('YYYY-MM-DDTHH:mm:ssZZ');
    }

    auditoriaPlantioNaoConformidadeList.forEach(item => {
      if (item.stRegistro === 0) {
        delete item.idAuditoriaPlantioNaoConformidade;
      }
      item.historicoAuditoriaList.forEach(historicoAuditoria => {
        if (historicoAuditoria.stRegistro === 0) {
          delete historicoAuditoria.idHistoricoAuditoria;
        }
      });
    });

    values.auditoriaPlantioNaoConformidadeList = auditoriaPlantioNaoConformidadeList;

    let request;
    if (props.match.params.id === undefined) {
      request = addAuditoria(values);
    } else {
      request = editAuditoria(values);
    }

    request
      .then(res => {
        swal(formatMessage('auditoria.auditoriaSalvoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
          }
        });

        clickBack()
      })
      .catch(err => {
        swalError('auditoria.erroAoSalvar');

        setLoading(false);
      });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarAuditoria)));
