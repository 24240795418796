import { createMuiTheme } from '@material-ui/core/styles';

const style = theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'scroll',
    paddingBottom: 15
  },
  cardGrid: {
    marginTop: 30,
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
    cursor: 'pointer'
  },
  grid: {
    height: 70,
    marginTop: 15,
    marginBottom: 15
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: '15px !important'
      },
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.35
        }
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '0px !important'
      }
    },
    MuiButton: {
      contained: {
        height: '46px',
        width: '100px',
        marginLeft: '15px'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        }
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export { style, theme };
