export default {
  certificacao: {
    erroAoBuscarDados: 'No se pudieron recuperar los datos',
    agencia: 'Agencia',
    desejaExcluirAgencia: '¿Quieres eliminar esta agencia?',
    nao: 'No',
    excluir: 'Borrar',
    agenciaExcluida: 'Agencia eliminada correctamente',
    erroAoExcluir: 'Se produjo un error al eliminar una agencia.',
    cadastroDeAgencia: 'Registro de agencia',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    salvar: 'Ahorrar',
    cancelar: 'Cancelar',
    agenciaSalvoComSucesso: 'Agencia guardada correctamente',
    erroAoSalvar: 'Error al guardar la agencia',
    salvando: 'Ahorro',
    atualizando: 'Actualizando',
    nome: 'Nombre',
    codigo: 'Código',
    estado: 'Estado',
    cidade: 'Ciudad',
    ativo: 'Activo',
    inativo: 'Inactivo',
    status: 'Situación',
    codigoExistente: 'Código de agencia existente',
    ocorreuErroSalvar: 'Hubo un error al guardar'
  }
};
