export default {
  cotacao: {
    filtro: "Filtro",
    cotacoes: "Citas",
    cultura: "Cultura",
    filtrar: "Filtro",
    cotacao: "Cotación",
    variacaoDePreco: "Variación de precio",
    selecione: "Seleccione una cultura",
    data: "Fecha",
    valor: "Valor",
    campoObrigatorio: "Campo obligatorio",
    cancelar: "Cancelar",
    salvar: "Guardar",
    identificacaoDaCotacao: "Identificación",
    cadastroDeCotacoes: "Cadastro de cotación",
    falhaAoCarregarCotacao: "Error al cargar cotización",
    salvando: "Guardando",
    atualizando: "Actualizando",
    cotacaoCadastradaComSucesso: "La cotización se salvó con éxito",
    falhaAoCadastrarCotacao: "Falla al registrar cotización",
    cotacaoEditadaComSucesso: "cotización editada con éxito",
    desejaExcluir: "Desea excluir cotización?",
    excluir: "Eliminar",
    nao: "No",
    excluindo: "Excluyendo",
    cotacaoRemovidaComSucesso: "Cotización removida con éxito",
    falhaAoExcluirCotacao: "Error al excluir cotización",
    rs: "US$"
  }
};
