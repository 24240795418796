import React from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextFieldWithMask from '@components/TextFieldWithMask/TextFieldWithMask';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';
import { style, theme } from './../Clientes.styles';
import Switch from '@material-ui/core/Switch';
import formatMessage from './../i18n/formatMessage';
import { Formik, Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import CNPJValidator from '@validators/CNPJValidator';
import { Prompt } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import * as moment from 'moment';
import UploadFoto from '@components/UploadFoto/UploadFoto';

/**
 * Componente que representa o Card principal da tab `Identificação`
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const Identificacao = props => {
  const {
    key,
    dataAppSync,
    check,
    enableReinitialize,
    cooperativa,
    organica,
    classes,
    clickSalvar,
    onTryExit,
    canLeave,
    tratarSubmit,
    onChangeCampo,
    onChangeDate,
    checkSwitch,
    handleChangeChecked,
    changeCooperativa,
    changeReinitialize,
    saveFoto,
    itemFoto
  } = props;

  const cooperativaList = [
    {
      id: 0,
      name: formatMessage('clientes.central')
    },
    {
      id: 1,
      name: formatMessage('clientes.cooperativa')
    }
  ];

  return (
    <div style={{ height: 400 }}>
      <Prompt when={!canLeave} message={onTryExit} />
      <CardIcon key={key} titulo={formatMessage('clientes.identificacaoCliente')} usePaddings={false}>
        <Formik
          initialValues={{
            dsRazaoSocial: dataAppSync.dsRazaoSocial || '',
            dsNomeFantasia: dataAppSync.dsNomeFantasia || '',
            dsCnpj: dataAppSync.dsCnpj || '',
            dsEmail: dataAppSync.dsEmail || '',
            nrTelefone: dataAppSync.nrTelefone || '',
            tpCooperativa: dataAppSync.tpCooperativa !== undefined ? dataAppSync.tpCooperativa : '',
            dsMotivoInativacao: check ? '' : dataAppSync.dsMotivoInativacao,
            dtCadastro: moment(dataAppSync.dhInclusao),
            dhInativacao: check ? null : moment(dataAppSync.dhInativacao),
            stCliente: '',
            clienteEnderecoList: dataAppSync.clienteEnderecoList,
            clienteContatoList: dataAppSync.clienteContatoList
          }}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={enableReinitialize}
          onSubmit={clickSalvar}
          validationSchema={Yup.object().shape({
            dsRazaoSocial: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            dsNomeFantasia: Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            dsCnpj:
              getUserLang() === 'pt-BR'
                ? Yup.string()
                    .test('', formatMessage('clientes.cnpjInvalido'), function(value) {
                      if (value !== undefined) {
                        return CNPJValidator.validate(value);
                      }
                      return true;
                    })
                    .min(18, formatMessage('clientes.cnpjInvalido'))
                    .required(formatMessage('clientes.campoObrigatorio'))
                : Yup.string().required(formatMessage('clientes.campoObrigatorio')),
            dsEmail: Yup.string()
              .email(formatMessage('clientes.emailInvalido'))
              .required(formatMessage('clientes.campoObrigatorio')),
            nrTelefone:
              getUserLang() === 'pt-BR'
                ? Yup.string().min(14, formatMessage('clientes.telefoneInvalido'))
                : Yup.string(),
            nrProdutoresAtivos: Yup.string(),
            tpCooperativa: cooperativa
              ? Yup.number()
                  .nullable()
                  .required(formatMessage('clientes.campoObrigatorio'))
              : Yup.number().nullable(),
            dsMotivoInativacao: check
              ? Yup.string()
              : Yup.string().required(formatMessage('clientes.campoObrigatorio'))
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            submitForm
          }) => {
            tratarSubmit(submitForm);

            return (
              <MuiThemeProvider theme={theme}>
                <form autoComplete='off' style={{ marginTop: 30 }}>
                  <div className={classes.cadastro}>
                    <Grid container spacing={16}>
                      <Grid item xs={5}>
                        <Field name='dsRazaoSocial'>
                          {({ field, form }) => (
                            <TextField
                              {...field}
                              id='dsRazaoSocial'
                              name='dsRazaoSocial'
                              value={values.dsRazaoSocial}
                              label={formatMessage('clientes.razaoSocial')}
                              className={classes.textField}
                              InputLabelProps={{
                                className: classes.font
                              }}
                              error={form.errors.dsRazaoSocial && form.touched.dsRazaoSocial}
                              helperText={form.touched.dsRazaoSocial ? form.errors.dsRazaoSocial : ''}
                              onChange={value => {
                                form.setFieldValue('dsRazaoSocial', value.target.value);
                              }}
                              onBlur={e => onChangeCampo('dsRazaoSocial', e, form.handleBlur)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={5}>
                        <Field name='dsNomeFantasia'>
                          {({ field, form }) => (
                            <TextField
                              {...field}
                              id='dsNomeFantasia'
                              name='dsNomeFantasia'
                              value={values.dsNomeFantasia}
                              label={formatMessage('clientes.nomeFantasia')}
                              className={classes.textField}
                              InputLabelProps={{
                                className: classes.font
                              }}
                              error={form.errors.dsNomeFantasia && form.touched.dsNomeFantasia}
                              helperText={form.touched.dsNomeFantasia ? form.errors.dsNomeFantasia : ''}
                              onBlur={e => onChangeCampo('dsNomeFantasia', e, form.handleBlur)}
                              onChange={value => {
                                form.setFieldValue('dsNomeFantasia', value.target.value);
                              }}
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={2}>
                        <div>
                          <Field name='dtCadastro'>
                            {({ field, form }) => (
                              <DatePicker
                                {...field}
                                locale={getUserLang()}
                                disabled={true}
                                className={classes.font}
                                label={formatMessage('clientes.dataCadastro')}
                                valueDate={values.dtCadastro}
                                onChangeDatePicker={date => {
                                  form.setFieldValue('dtCadastro', date);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.cadastro} style={{ marginTop: 5, marginBottom: 8 }}>
                    <Grid container spacing={16}>
                      <Grid item xs={3}>
                        <Field name='dsCnpj'>
                          {({ field, form }) => (
                            <TextFieldWithMask
                              {...field}
                              tipoMascara={2}
                              id='dsCnpj'
                              name='dsCnpj'
                              value={values.dsCnpj}
                              label={formatMessage('clientes.cnpj')}
                              className={classes.textField}
                              InputLabelProps={{
                                className: classes.font
                              }}
                              error={form.errors.dsCnpj && form.touched.dsCnpj}
                              helperText={form.touched.dsCnpj ? form.errors.dsCnpj : ''}
                              onBlur={e => onChangeCampo('dsCnpj', e, form.handleBlur)}
                              onChange={value => {
                                form.setFieldValue('dsCnpj', value.target.value);
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={4}>
                        <Field name='dsEmail'>
                          {({ field, form }) => (
                            <TextField
                              {...field}
                              id='dsEmail'
                              name='dsEmail'
                              value={values.dsEmail}
                              label={formatMessage('clientes.email')}
                              className={classes.textField}
                              InputLabelProps={{
                                className: classes.font
                              }}
                              error={form.errors.dsEmail && form.touched.dsEmail}
                              helperText={form.touched.dsEmail ? form.errors.dsEmail : ''}
                              onBlur={e => onChangeCampo('dsEmail', e, form.handleBlur)}
                              onChange={value => {
                                form.setFieldValue('dsEmail', value.target.value);
                              }}
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={2}>
                        <Field name='nrTelefone'>
                          {({ field, form }) => (
                            <TextFieldWithMask
                              {...field}
                              tipoMascara={1}
                              id='nrTelefone'
                              name='nrTelefone'
                              value={values.nrTelefone}
                              label={formatMessage('clientes.telefone')}
                              className={classes.textField}
                              InputLabelProps={{
                                className: classes.font
                              }}
                              error={form.errors.nrTelefone && form.touched.nrTelefone}
                              helperText={form.touched.nrTelefone ? form.errors.nrTelefone : ''}
                              onBlur={e => onChangeCampo('nrTelefone', e, form.handleBlur)}
                              onChange={value => {
                                form.setFieldValue('nrTelefone', value.target.value);
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={classes.cadastro} style={{ marginTop: 5 }}>
                    <Grid container spacing={16}>
                      <Grid item xs={3}>
                        <div
                          onClick={e => {
                            if (organica) {
                              changeCooperativa();
                              setFieldValue('tpCooperativa', '');
                            }

                            handleChangeChecked('organica');
                          }}
                          style={{ marginTop: 10, cursor: 'pointer', marginLeft: -12 }}
                        >
                          <Typography noWrap>
                            <Switch color='primary' checked={organica} />
                            <label style={{ color: '#000000' }}>
                              {formatMessage('clientes.agriculturaOrganica')}
                            </label>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          onClick={e => {
                            if (!organica) return;

                            if (cooperativa) {
                              setFieldValue('tpCooperativa', '');
                            }

                            handleChangeChecked('cooperativa');
                          }}
                          style={{ marginTop: 10, cursor: 'pointer', marginLeft: -12 }}
                        >
                          <Typography noWrap>
                            <Switch disabled={!organica} color='primary' checked={cooperativa} />
                            <label style={{ color: '#000000' }}>
                              {formatMessage('clientes.cooperativa')}
                            </label>
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item xs={3}>
                        <FormControl
                          disabled={!cooperativa}
                          error={errors.tpCooperativa && touched.tpCooperativa}
                          className={classes.form}
                        >
                          <InputLabel
                            error={errors.tpCooperativa && touched.tpCooperativa}
                            className={classes.font}
                          >
                            {formatMessage('clientes.tipoCooperativa')}
                          </InputLabel>

                          <Select
                            name={'id'}
                            style={{ marginLeft: 0, fontSize: 14, width: '100%' }}
                            SelectDisplayProps={{
                              style: { width: '100%', backgroundColor: 'transparent' }
                            }}
                            error={errors.tpCooperativa && touched.tpCooperativa}
                            value={values.tpCooperativa}
                            onChange={e => {
                              onChangeCampo('tpCooperativa', e, handleBlur);
                              setFieldValue('tpCooperativa', e.target.value);
                            }}
                            displayEmpty
                          >
                            {cooperativaList.map((nivel, i) => (
                              <MenuItem key={i} value={nivel.id} className={classes.font}>
                                {nivel.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.tpCooperativa && touched.tpCooperativa && (
                            <FormHelperText> {errors.tpCooperativa} </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={classes.cadastro} style={{ marginTop: 5 }}>
                    <Grid container spacing={16}>
                      <Grid item xs={6} style={{ marginLeft: -12 }}>
                        <Field name='stCliente'>
                          {({ field, form }) => (
                            <div style={{ marginTop: 10 }}>
                              <Typography noWrap>
                                <Switch
                                  color='primary'
                                  checked={check}
                                  onChange={() => {
                                    changeReinitialize();
                                    checkSwitch();
                                    if (check) {
                                      form.setFieldValue(
                                        'dsMotivoInativacao',
                                        dataAppSync.dsMotivoInativacao || ''
                                      );
                                      form.setFieldValue('dhInativacao', new Date());
                                    } else {
                                      form.setFieldValue('dsMotivoInativacao', '');
                                      form.setFieldValue('dhInativacao', null);
                                    }
                                  }}
                                />
                                <label style={{ color: '#000000' }}>
                                  {formatMessage('clientes.ativo')}
                                </label>
                              </Typography>
                            </div>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={classes.cadastro} style={{ marginTop: 5 }}>
                    <Grid container spacing={16}>
                      <Grid item xs={3}>
                        <div noValidate>
                          <Field name='dhInativacao'>
                            {({ field, form }) => (
                              <DatePicker
                                {...field}
                                locale={getUserLang()}
                                disabled={check}
                                className={classes.font}
                                label={formatMessage('clientes.dataInativacao')}
                                valueDate={values.dhInativacao}
                                errorTouched={form.errors.value && form.touched.value}
                                helperText={formatMessage('clientes.campoObrigatorio')}
                                onChangeDatePicker={date => {
                                  form.setFieldValue('dhInativacao', date);
                                  onChangeDate('dhInativacao', date);
                                }}
                                onBlurDatePicker={date => {
                                  let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                                  if (!validation) {
                                    setFieldValue('dhInativacao', values.dhInativacao);

                                    form.setFieldValue('dhInativacao', values.dhInativacao);
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </Grid>

                      <Grid item xs={6}>
                        <Field name='dsMotivoInativacao'>
                          {({ field, form }) => (
                            <div>
                              <TextField
                                {...field}
                                id='dsMotivoInativacao'
                                name='dsMotivoInativacao'
                                value={values.dsMotivoInativacao}
                                disabled={check}
                                label={formatMessage('clientes.motivo')}
                                className={classes.textField}
                                InputLabelProps={{
                                  className: classes.font
                                }}
                                error={
                                  !check &&
                                  form.errors.dsMotivoInativacao &&
                                  form.touched.dsMotivoInativacao
                                }
                                helperText={
                                  !check && form.touched.dsMotivoInativacao
                                    ? form.errors.dsMotivoInativacao
                                    : ''
                                }
                                onBlur={e => onChangeCampo('dsMotivoInativacao', e, form.handleBlur)}
                                onChange={value => {
                                  form.setFieldValue('dsMotivoInativacao', value.target.value);
                                  onChangeCampo('dsMotivoInativacao', value, form.handleBlur);
                                }}
                              />
                            </div>
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={3}>
                        <UploadFoto
                          erroArquivo={formatMessage('clientes.erroAnexarFoto')}
                          edit
                          organico={true}
                          itemFoto={itemFoto}
                          saveFoto={saveFoto}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </MuiThemeProvider>
            );
          }}
        />
      </CardIcon>
    </div>
  );
};

export default withStyles(style)(Identificacao);
