/*
    Autor: Gabriela Farias
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CameraAlt from '@material-ui/icons/CameraAlt';
import swal from '@sweetalert/with-react';
import classNames from 'classnames';
import iconEdit from '@images/icone_editar.png';
import ButtonGrid from '@components/ButtonGrid/ButtonGrid';

const styles = {
  iconDeleteFile: {
    position: 'relative',
    color: '#F33A30',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: 200
  },
  fileName: {
    color: '#42ADE8',
    fontSize: 14,
    fontFamily: 'Roboto',
    cursor: 'pointer'
  },
  nameResponsive: qtdLines => ({
    textAlign: 'center',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: qtdLines,
    WebkitBoxOrient: 'vertical',
    width: 190
  }),
  cardDragDrop: {
    marginBottom: 0,
    border: '1px dashed #707070',
    borderRadius: 10,
    color: '#707070',
    fontFamily: 'Roboto'
  },
  cardFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 124,
    height: 124,
    top: 150,
    position: 'absolute'
  },
  inputFile: {
    display: 'none',
    width: 0,
    height: 0
  },
  textUploadFile: {
    textAlign: 'center',
    opacity: 0.87,
    color: '#707070',
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  containerCardFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: 'calc(100vh - 450px)',
    overflowY: 'auto',
    paddingTop: 15,
    justifyContent: 'flex-end'
  },
  textField: {
    height: 150,
    width: 200,
    marginTop: 0,
    margin: 15
  },
  cardTextImage: {
    height: 200,
    width: 230
  },
  divButtonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    marginBottom: -43,
    marginRight: -107
  }
};

const CardFile = props => {
  const { file } = props;

  const { dsFotoBase64, dsFoto, stRegistro, dsFotoBase64New } = file;

  return (
    <img
      id={dsFoto}
      alt={dsFotoBase64}
      src={stRegistro === 0 ? dsFotoBase64New : dsFotoBase64}
      style={{
        height: 130,
        width: 124,
        borderRadius: 10,
        objectFit: 'cover'
      }}
    />
  );
};

CardFile.propTypes = {
  classes: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired
};

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    if (file.size <= 20000000) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    } else {
      reject();
    }
  });
}

class UploadFiles extends Component {
  constructor(props) {
    super(props);

    this.inputFilesRef = null;

    this.state = {
      imagemAnexo: {},
      showButtom: false
    };

    this.onAddFiles = this.onAddFiles.bind(this);
    this.changeHover = this.changeHover.bind(this);
  }

  componentDidMount() {
    this.setState({
      imagemAnexo: this.props.itemFoto
    });
  }

  async onAddFiles(e, files) {
    if (files.length === 0) return;

    e.preventDefault();

    if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/png') return;

    let allValid = true;

    const promises = Array.from(files).map(async file => {
      return await fileToBase64(file)
        .then(dsFotoBase64 => {
          this.setState({
            imagemAnexo: {
              dsThumb: file.name,
              dsFotoBase64: dsFotoBase64.split(';base64,')[1],
              dsFotoBase64New: dsFotoBase64,
              dsFoto: file.name,
              dsAtividadeFoto: null,
              stRegistro: 0
            }
          });
        })
        .catch(err => {
          allValid = false;
        });
    });

    await Promise.all(promises);

    if (allValid) {
      this.props.saveFoto(this.state.imagemAnexo);
    } else {
      swal({
        title: this.props.erroArquivo,
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: {
          confirm: {
            value: true,
            visible: true,
            closeModal: false,
            className: 'swal2-confirm'
          }
        }
      }).then(willDelete => {
        if (willDelete) {
          swal.close();
        }
      });
    }
  }

  changeHover(show) {
    this.setState({
      showButtom: show
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classes.containerCardFlex}
        onMouseLeave={() => {
          this.changeHover(false);
        }}
        onMouseEnter={() => {
          this.changeHover(true);
        }}
        onDrop={e => {
          if (this.props.edit) {
            this.onAddFiles(e, e.dataTransfer.files);
          }
        }}
        onDragOver={e => {
          if (this.props.edit) {
            e.preventDefault();
          }
        }}
      >
        <div
          className={classNames(classes.cardDragDrop, classes.cardFlex)}
          style={{
            cursor: this.props.edit ? 'pointer' : 'initial',
            height: 130,
            top: this.props.organico ? 180 : 150
          }}
          onClick={() => {
            if (this.props.edit) {
              this.inputFilesRef.click();
            }
          }}
        >
          <input
            accept='.png, .jpg, .jpeg'
            className={classes.inputFile}
            multiple
            type='file'
            ref={ref => (this.inputFilesRef = ref)}
            onChange={e => {
              if (this.props.edit) {
                this.onAddFiles(e, e.target.files);
              }
            }}
          />
          {this.state.showButtom && this.props.edit && (
            <div
              className={classes.divButtonGrid}
              style={{ bottom: this.props.itemFoto.dsFotoBase64 !== undefined ? 10 : 40 }}
            >
              <ButtonGrid
                hiddenMargin={true}
                withoutMargin={true}
                show={true}
                icon={iconEdit}
                color='#42ADE8'
                disabled={false}
                onClick={() => {}}
              />
            </div>
          )}

          {this.props.itemFoto.dsFotoBase64 !== undefined ? (
            <CardFile classes={classes} file={this.props.itemFoto} />
          ) : (
            <CameraAlt
              color='inherit'
              style={{ height: 70, width: 70 }}
              fontSize='inherit'
              fontWeight='bold'
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UploadFiles);
