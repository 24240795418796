import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import formatMessage from './i18n/formatMessage';
import { style, theme } from './Classificacao.styles';
import CardFilters from '@components/CardFilters/CardFilters';
import Loading from '@components/Loading/Loading';
import FilterUtil from '../filterUtil';
import { findAllClassificacao, deleteById } from '@resources/Classificacao';
import { operationTypeString, headDataGrid } from './headClassificacao';
import swal from '@sweetalert/with-react';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import DataTable from '@components/DataTable/DataTable';
import iconAdd from '@images/icone_add.png';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * Tela de Classificação
 *
 * @author Gabriela Farias
 * @class Classificacao
 * @extends {Component} - Componente React
 */
function Classificacao(props) {
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [disabledEdit, setDisabledEdit] = useState(true);
  const [itemSelect, setItemSelect] = useState(null);
  const { classes } = props;

  useEffect(() => {
    const title = 'classificacao.classificacao';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    onFilter([]);
  }, []);

  function onFilter(dataChips) {
    return findAllClassificacao()
      .then(query => {
        setAllData(query.data);
        setFilteredData(FilterUtil.applyFilters(query.data, dataChips || []));
      })
      .catch(() => {
        errorMensagem('classificacao.erroBuscarListaClassificacao');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function errorMensagem(title) {
    swal(formatMessage(title), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  function onFilterChanged(dataChips) {
    setFilteredData(FilterUtil.applyFilters(allData, dataChips || []));
  }

  function deleteClassificacao() {
    swal({
      title: formatMessage('classificacao.desejaExcluir'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        cancel: {
          text: formatMessage('classificacao.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('classificacao.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnClickOutside: false,
            title: formatMessage('classificacao.excluindo')
          }
        );

        deleteById(itemSelect.idClassificacao)
          .then(query => {
            swal(formatMessage('classificacao.classificacaoRemovidoComSucesso'), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              setAllData(allData.filter(item => item.idClassificacao !== itemSelect.idClassificacao));
              setFilteredData(
                filteredData.filter(item => item.idClassificacao !== itemSelect.idClassificacao)
              );
            });
          })
          .catch(() => {
            errorMensagem('classificacao.erroDeletarClassificacao');
          });
      }
    });
  }

  function clickAdd() {
    props.history.push('/app/classificacao/novo');
  }

  function clickEditar() {
    props.history.push('/app/classificacao/editar/' + itemSelect.idClassificacao);
  }

  function selectChange(select) {
    if (select) {
      setDisabledEdit(false);
      setItemSelect(select);
    } else {
      setItemSelect(null);
      setDisabledEdit(true);
    }
  }

  const pagination = {
    length: filteredData.length,
    page: 0
  };

  return (
    <div className={classes.filters}>
      {loading && <Loading />}

      <CardFilters
        filtros={operationTypeString}
        usePaddings={false}
        onFilter={onFilter}
        onFilterChanged={onFilterChanged}
        viewKey='classificacao'
      />
      <GroupButtonGrid
        showAdd={false}
        showEdit={true}
        showDelete={true}
        disableEdit={disabledEdit}
        disableDelete={disabledEdit}
        onClickDelete={deleteClassificacao}
        onClickEdit={clickEditar}
      />

      <div className={classes.cardGrid}>
        <DataTable
          data={filteredData}
          columns={headDataGrid}
          selectChange={selectChange}
          showPagination={true}
          pagination={pagination}
        />
      </div>

      <ButtonFAB icon={iconAdd} positionDefault={true} onClick={clickAdd} />
    </div>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'classificacao';
  })
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(Classificacao)));
