import React from 'react';
import formatMessage from './i18n/formatMessage';
import { connect } from 'react-redux';
import CardIcon from '@components/CardIcon/CardIcon';
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import Loading from '@components/Loading/Loading';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import DatePicker from '@components/DatePicker/DatePicker';
import { getUserLang } from '@utils/localeUtils';
import AuditoriaNaoConformidade from './AuditoriaNaoConformidade/AuditoriaNaoConformidade';
import { theme } from './Auditoria.styles';
import { MuiThemeProvider } from '@material-ui/core/styles';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Card de cadastro de auditores
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const Identificacao = props => {
  const {
    isTecnico,
    classes,
    values,
    handleSubmit,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    produtor,
    produtorList,
    loadProdutor,
    areaCultivo,
    areaCultivoList,
    loadAreaCultivo,
    plantio,
    plantioList,
    loadPlantio,
    isLoading,
    onChangeProdutor,
    onChangeAreaCultivo,
    keyAutoComplete,
    tratarValorProdutor,
    initialValues,
    tratarValorAreaCultivo,
    cleanNaoConformidades,
    tratarValorPlantio,
    auditoriaPlantioNaoConformidadeList,
    clickAddOrEditNaoConformidadeSancao,
    isEditing,
    clickFabBack
  } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <>
        {isLoading && <Loading />}

        <div className={classes.cardScroll}>
          <CardIcon titulo={formatMessage('auditoria.identificacao')}>
            <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                <Autocomplete
                  disabled={isEditing || plantio || isTecnico}
                  itens={produtorList}
                  campoOp='nmProdutor'
                  campoChave='idProdutor'
                  label={formatMessage('auditoria.produtor')}
                  name='produtor'
                  error={!produtor && touched.produtor}
                  helperText={
                    touched.produtor && !produtor ? formatMessage('auditoria.campoObrigatorio') : null
                  }
                  onChangeOwn={value => {
                    setFieldValue('produtor', value);
                    setFieldValue('areaCultivo', null);
                    setFieldValue('plantio', null);
                    onChangeProdutor(value);
                  }}
                  onChangeAutoComplete={loadProdutor}
                  value={tratarValorProdutor()}
                  key={keyAutoComplete}
                  valueAutoComplete={tratarValorProdutor()}
                  valueSelect={tratarValorProdutor().nmProdutor}
                />
              </Grid>

              <Grid item xs={4} style={{ paddingRight: 15, height: 70 }}>
                <Autocomplete
                  itens={areaCultivoList || isTecnico}
                  campoOp='dsAreaCultivo'
                  campoChave='idAreaCultivo'
                  label={formatMessage('auditoria.areaCultivo')}
                  name='areaCultivo'
                  error={!areaCultivo && touched.areaCultivo}
                  helperText={
                    touched.areaCultivo && !areaCultivo
                      ? formatMessage('auditoria.campoObrigatorio')
                      : null
                  }
                  onChangeOwn={value => {
                    setFieldValue('areaCultivo', value);
                    setFieldValue('plantio', null);
                    onChangeAreaCultivo(value);
                  }}
                  onChangeAutoComplete={loadAreaCultivo}
                  value={tratarValorAreaCultivo()}
                  key={keyAutoComplete}
                  valueAutoComplete={tratarValorAreaCultivo()}
                  valueSelect={tratarValorAreaCultivo().dsAreaCultivo}
                  disabled={!produtor || isEditing || plantio}
                />
              </Grid>

              <Grid item xs={4} style={{ paddingRight: 15, height: 70 }}>
                <Autocomplete
                  disabled={!areaCultivo || isEditing || isTecnico}
                  itens={plantioList}
                  campoOp={'dsPlantioSelected'}
                  campoChave={'idPlantio'}
                  titleSecondLine={'titleSubLine'}
                  titleFirstLine={'dsPlantioCustom'}
                  descriptionFirstLine={'dtPlantioFormat'}
                  descriptionSecondLine={'dsSubLineCustom'}
                  label={formatMessage('auditoria.plantio')}
                  name='plantio'
                  error={!plantio && touched.plantio}
                  helperText={
                    touched.plantio && !plantio ? formatMessage('auditoria.campoObrigatorio') : null
                  }
                  onChangeOwn={value => {
                    cleanNaoConformidades(setFieldValue, value);
                  }}
                  onChangeAutoComplete={loadPlantio}
                  value={tratarValorPlantio()}
                  key={keyAutoComplete}
                  valueAutoComplete={tratarValorPlantio()}
                  valueSelect={tratarValorPlantio().dsPlantioSelected}
                />
              </Grid>
            </Grid>

            <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
              <Grid item xs={4}>
                <FormControl error={errors.tpAuditoria && touched.tpAuditoria} style={{ width: '100%' }}>
                  <InputLabel htmlFor='tpAuditoria' error={errors.tpAuditoria && touched.tpAuditoria}>
                    {formatMessage('auditoria.tipoAuditoria')}
                  </InputLabel>

                  <Select
                    disabled={isTecnico}
                    value={values.tpAuditoria}
                    error={errors.tpAuditoria && touched.tpAuditoria}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name='tpAuditoria'
                    id='tpAuditoria'
                  >
                    {[formatMessage('auditoria.interna'), formatMessage('auditoria.externa')].map(
                      (item, i) => (
                        <MenuItem key={i} value={i}>
                          {item}
                        </MenuItem>
                      )
                    )}
                  </Select>

                  {errors.tpAuditoria && touched.tpAuditoria && (
                    <FormHelperText>{errors.tpAuditoria}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl
                  error={errors.stAuditoriaPlantio && touched.stAuditoriaPlantio}
                  style={{ width: '100%' }}
                >
                  <InputLabel
                    htmlFor='stAuditoriaPlantio'
                    error={errors.stAuditoriaPlantio && touched.stAuditoriaPlantio}
                  >
                    {formatMessage('auditoria.situacao')}
                  </InputLabel>

                  <Select
                    disabled={isTecnico}
                    value={values.stAuditoriaPlantio}
                    error={errors.stAuditoriaPlantio && touched.stAuditoriaPlantio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name='stAuditoriaPlantio'
                    id='stAuditoriaPlantio'
                  >
                    {[
                      formatMessage('auditoria.emAndamento'),
                      formatMessage('auditoria.finalizadaSemNaoConformidade'),
                      formatMessage('auditoria.finalizadaComNaoConformidade'),
                      formatMessage('auditoria.suspensa')
                    ].map((item, i) => (
                      <MenuItem key={i} value={i}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>

                  {errors.stAuditoriaPlantio && touched.stAuditoriaPlantio && (
                    <FormHelperText>{errors.stAuditoriaPlantio}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  disabled={!initialValues.plantio || isTecnico}
                  minDate={initialValues.plantio ? initialValues.plantio.dtPlantio : '1900-01-01'}
                  locale={getUserLang()}
                  style={{ height: 70 }}
                  className={classes.font}
                  label={formatMessage('auditoria.dataInicial')}
                  valueDate={values.dtInicio}
                  errorTouched={errors.dtInicio && touched.dtInicio}
                  helperText={errors.dtInicio && touched.dtInicio ? errors.dtInicio : null}
                  onChangeDatePicker={value => {
                    setFieldValue('dtInicio', value);
                    setFieldValue('dtTermino', null);
                    initialValues.dtInicio = value;
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  disabled={!values.dtInicio || isTecnico}
                  minDate={values.dtInicio ? values.dtInicio : '1900-01-01'}
                  locale={getUserLang()}
                  style={{ height: 70 }}
                  className={classes.font}
                  label={formatMessage('auditoria.dataFinal')}
                  valueDate={values.dtTermino}
                  errorTouched={errors.dtTermino && touched.dtTermino}
                  helperText={errors.dtTermino && touched.dtTermino ? errors.dtTermino : null}
                  onChangeDatePicker={value => {
                    setFieldValue('dtTermino', value);
                    initialValues.dtTermino = value;
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
              <Grid item xs={12}>
                <TextField
                  label={formatMessage('auditoria.motivoSuspensao')}
                  disabled={isTecnico}
                  name='dsSuspensao'
                  inputProps={{ maxLength: 60 }}
                  value={values.dsSuspensao}
                  onChange={e => {
                    setFieldValue('dsSuspensao', e.target.value);
                    initialValues.dsSuspensao = e.target.value;
                  }}
                  onBlur={handleBlur}
                  error={errors.dsSuspensao && touched.dsSuspensao}
                  helperText={errors.dsSuspensao && touched.dsSuspensao ? errors.dsSuspensao : null}
                />
              </Grid>
            </Grid>
          </CardIcon>

          <AuditoriaNaoConformidade
            isTecnico={isTecnico}
            classes={classes}
            naoConformidadeSancaoList={auditoriaPlantioNaoConformidadeList}
            clickAddOrEditNaoConformidadeSancao={value =>
              clickAddOrEditNaoConformidadeSancao(value, values.plantio)
            }
          />
        </div>

        {!isTecnico && (
          <div className={classes.fab}>
            <ButtonFABMenu
              icon={iconMenuPontos}
              actions={[
                {
                  icon: <Check style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('auditoria.salvar'),
                  onClickIcon: handleSubmit,
                  color: '#42ADE8'
                },
                {
                  icon: <Close style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('auditoria.cancelar'),
                  onClickIcon: clickFabBack,
                  color: '#F33A30'
                }
              ]}
            />
          </div>
        )}
      </>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(Identificacao);
