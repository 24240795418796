export default {
  raca: {
    racas: 'Races',
    descricao: 'Description',
    desejaExcluir: 'Do you want to delete race?',
    nao: 'No',
    excluir: 'Delete',
    excluindo: 'Excluding',
    falhaAoExcluirRaca: 'Failed to delete race',
    racaRemovidoComSucesso: 'Race successfully removed',
    racaCadastradaComSucesso: 'Successful registered race',
    racaEditadaComSucesso: 'Successfully edited race',
    falhaAoEditarRaca: 'Failed to edit race',
    falhaAoCadastrarRaca: 'Failed to register race',
    falhaAoCarregarRaca: 'Failed to load race',
    especie: 'Species',
    salvar: 'Save',
    atualizar: 'Update',
    cancelar: 'Cancel',
    salvando: 'Saving',
    atualizando: 'Updating',
    cadastroDeEspecie: 'Species Registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    ativo: 'Active',
    situacao: 'Situation',
    inativo: 'Inactive'
  }
};
