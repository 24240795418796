export default {
  confirmarCadastro: {
    email: "Email",
    codigo: "Código",
    senha: "Senha",
    confirmacaoSenha: "Confirmação de Senha",
    confirmar: "CONFIRMAR CADASTRO",
    campoObrigatorio: "Campo obrigatório",
    emailInvalido: "Email inválido",
    senhaMinimo: "A senha deve ter no mínimo 6 caracteres",
    codigoExpirado: "Código de liberação expirado",
    usuarioNaoEncontrado: "Usuário não encontrado",
    falhaConectar: "Não foi possível validar seu cadastro, tente novamente",
    sucesso: "Sucesso",
    codigoInvalido: "Código invalido ou desatualizado",
    falhaValidarCadastro: "Falha ao validar cadastro",
    cadastroJaConfirmado: "Cadastro já confirmado",
    cadastroConfirmado: "Cadastro confirmado com sucesso"
  }
};
