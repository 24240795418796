export default {
  analiseOGM: {
    analiseOGM: 'Analizar OGM',
    nao: 'No',
    desejaExcluir: '¿Quieres borrar?',
    excluir: 'Excluir',
    analiseOGMRemovidoComSucesso: 'Analizar OGM eliminada con éxito',
    falhaAoExcluirAnaliseOGM: 'Error al eliminar la analizar OGM',
    excluindo: 'Excluyendo',
    cultura: 'Cultura',
    tipo: 'Tipo',
    situacao: 'Situación',
    nome: 'Nombre',
    analiseOGMCadastradoComSucesso: 'Analizar OGM registrada con éxito',
    analiseOGMEditadoComSucesso: 'Analizar OGM editada con éxito',
    falhaAoCadastrarAnaliseOGM: 'No se pudo registrar la analizar OGM',
    falhaAoEditarAnaliseOGM: 'No se pudo registrar la analizar OGM',
    falhaAoCarregarAnaliseOGM: 'Error al cargar la analizar OGM',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Salvando',
    atualizando: 'Actualización',
    cadastroDeAnaliseOGM: 'Registro de analizar OGM',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    doencaSolo: 'Enfermedad del suelo',
    doencaFoliar: 'Enfermedad de la hoja',
    nematoide: 'Nematodo',
    descricao: 'Descripción',
    ativo: 'Activo',
    inativo: 'Inactivo',
    pragas: 'Praga'
  }
};
