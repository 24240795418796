import React, { Component } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import formatMessage from './i18n/formatMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Cultura from '@resources/Cultura';
import Cultivar from '@resources/Cultivar';
import CultivarEspec from '@resources/CultivarEspec';
import Navigation from '@components/Navigation/Navigation';
import Semeadura from './semeadura/Semeadura';
import StandFinal from './stand-final/StandFinal';
import MaterialPropagacao from './material-propagacao/MaterialPropagacao';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import PrevisaoColheita from './previsao-colheita/PrevisaoColheita';
import Colheita from './colheita/Colheita';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { savePlantio, updatePlantio, findPlantioById } from '@resources/Plantio';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import AlertasFitossanitarios from './alertas-fitossanitarios/AlertasFitossanitarios';
import { formatDate } from '@utils/languageUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { findAreaCultivoByProdutor } from '@resources/AreaCultivo';
import { style, theme } from './Plantio.styles';
import Identificacao from './Identificacao';
import LocalPlantio from './LocalPlantio';
import AnaliseOGMAPI from '@resources/AnaliseOGM';
import FormaAplicacaoAPI from '@resources/FormaAplicacao';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Componente de edição de Plantio
 *
 * @author Bruno Eduardo
 * @class EditarPlantio
 * @extends {Component} - Componente React
 */
class EditarPlantio extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    const title = 'plantios.cadastroPlantio';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    this.itensButtons = [
      {
        name: formatMessage('plantios.identificacao'),
        key: 0,
        select: true
      }
    ];

    this.headDatatableTalhoes = [
      {
        field: 'talhao.dsTalhao',
        headerName: formatMessage('plantios.talhao'),
        col: this.props.infoUsuario.stOrganica === 1 ? 4 : 6
      },
      {
        field: 'talhao.areaCultivo.dsAreaCultivo',
        headerName: formatMessage('plantios.areaDeCultivo'),
        col: this.props.infoUsuario.stOrganica === 1 ? 4 : 6
      }
    ];

    if (this.props.infoUsuario.stOrganica === 1) {
      this.headDatatableTalhoes[0] = {
        ...this.headDatatableTalhoes[0],
        pinned: 'left',
        suppressSizeToFit: true
      };

      this.headDatatableTalhoes[1] = {
        ...this.headDatatableTalhoes[1],
        pinned: 'left',
        suppressSizeToFit: true
      };

      this.headDatatableTalhoes.push(
        {
          valueGetter: args => {
            let nmOrganica = args.node.data.talhao.talhaoTipoCertificacaoList.map(
              organica => ' ' + organica.tipoCertificacao.nmTipoCertificacao
            );
            return nmOrganica.length > 0 ? nmOrganica + ';' : '';
          },
          headerName: formatMessage('plantios.certificacaoOrganica'),
          col: 4,
          pinned: 'left',
          suppressSizeToFit: true,
          cellStyle: {
            borderRight: '0.5px solid rgb(226, 226, 226) !important '
          }
        },
        {
          field: 'dsVizinho',
          headerName: formatMessage('plantios.vizinho'),
          col: 2,
          suppressSizeToFit: true
        },
        {
          field: 'dsBarreiraVegetalNorte',
          headerName: formatMessage('plantios.barreiraVegNorte'),
          col: 2,
          suppressSizeToFit: true
        },
        {
          field: 'dsBarreiraVegetalSul',
          headerName: formatMessage('plantios.barreiraVegSul'),
          col: 2,
          suppressSizeToFit: true
        },
        {
          field: 'dsBarreiraVegetalLeste',
          headerName: formatMessage('plantios.barreiraVegLeste'),
          col: 2,
          suppressSizeToFit: true
        },
        {
          field: 'dsBarreiraVegetalOeste',
          headerName: formatMessage('plantios.barreiraVegOeste'),
          col: 2,
          suppressSizeToFit: true
        }
      );

      this.itensButtons.push({
        name: formatMessage('plantios.materalPropagacao'),
        key: 1,
        select: false
      });
    }
    this.itensButtons.push(
      {
        name: formatMessage('plantios.semeadura'),
        key: 2,
        select: false
      },
      {
        name: formatMessage('plantios.standFinal'),
        key: 3,
        select: false
      },
      {
        name: formatMessage('plantios.previsaoColheita'),
        key: 4,
        select: false
      },
      {
        name: formatMessage('plantios.colheita'),
        key: 5,
        select: false
      },
      {
        name: formatMessage('plantios.alertasFitossanitarios'),
        key: 6,
        select: false
      }
    );

    this.initialPlantio = {
      idPlantio: +this.props.match.params.id || null,
      dtPlantio: null,
      cultura: '',
      cultivar: '',
      cultivarEspec: '',
      plantioSeveridadeList: [],
      stInvasoras: 0,
      areaCultivo: '',
      laudoPlantioList: [],
      produtor: { idProdutor: +this.props.match.params.idProdutor },
      semeadura: {
        nrSemeadura: '',
        tpSemeadura: '',
        tpAreaSemeadura: '',
        nrEspacamento: ''
      },
      standFinal: {
        nrQuantidadeStandFinal: '',
        tpUnMedidaStandFinal: ''
      },
      previsaoColheita: {
        nrDiasColheita: '',
        dtEstimada: null,
        nrProdutividadeEstimada: '',
        tpUnidadeEstimada: '',
        tpAreaEstimada: ''
      },
      colheita: {
        dtColheita: null,
        nrProdutividade: '',
        tpUnidade: '',
        tpArea: '',
        nrEntregaEfetiva: '',
        tpUnidadeEntregaEfetiva: '',
        dsCausaEntregaEfetiva: ''
      },
      ogm: {
        stPossuiLaudoOgm: '',
        tpOrigemSemente: '',
        dsOrigemSemente: '',
        plantioInsumoList: [],
        tipoAnaliseOgm: null
      },
      talhaoList: []
    };

    this.state = {
      plantiosTalhoesDeletedList: [],
      laudoPlantioListDeleted: [],
      laudoPlantioList: [],
      formaAplicacaoList: [],
      analiseOgmList: [],
      keyData: Math.random(),
      itemSelect: {},
      statusEdit: false,
      plantio: { ...this.initialPlantio },
      loading: !!this.props.match.params.id,
      enableReinitialize: !!this.props.match.params.id,
      culturas: [],
      cultivares: [],
      especificacoes: [],
      keyAutocompleteCultura: '',
      keyAutocompleteCultivares: '',
      keyAutocompleteEspecificacao: '',
      index: 0,
      areasDeCultivo: [],
      talhoes: [],
      talhoesSelecteds: [],
      talhoesVisibles: [],
      disabledDeleteButtonTalhoes: true,
      talhaoSelected: '',
      canLeave: false
    };

    this.isDirty = this.isDirty.bind(this);
    this.onTryExit = this.onTryExit.bind(this);
    this.safeSetState = this.safeSetState.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.tratarValorCultura = this.tratarValorCultura.bind(this);
    this.tratarValorAnaliseOgm = this.tratarValorAnaliseOgm.bind(this);
    this.onChangeCultura = this.onChangeCultura.bind(this);
    this.loadCulturas = this.loadCulturas.bind(this);
    this.loadAnaliseOgm = this.loadAnaliseOgm.bind(this);
    this.loadFormaAplicacao = this.loadFormaAplicacao.bind(this);
    this.tratarValorCultivares = this.tratarValorCultivares.bind(this);
    this.onChangeCultivares = this.onChangeCultivares.bind(this);
    this.loadCultivares = this.loadCultivares.bind(this);
    this.tratarValorEspecificacoes = this.tratarValorEspecificacoes.bind(this);
    this.onChangeEspecificacoes = this.onChangeEspecificacoes.bind(this);
    this.loadEspecificacoes = this.loadEspecificacoes.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.persistValues = this.persistValues.bind(this);
    this.onChangeDataPlantio = this.onChangeDataPlantio.bind(this);
    this.addTalhao = this.addTalhao.bind(this);
    this.closeTalhao = this.closeTalhao.bind(this);
    this.selectChangeTalhao = this.selectChangeTalhao.bind(this);
    this.loadAreasDeCultivo = this.loadAreasDeCultivo.bind(this);
    this.handleChangeAreaCultivo = this.handleChangeAreaCultivo.bind(this);
    this.removeTalhao = this.removeTalhao.bind(this);
    this.editTalhao = this.editTalhao.bind(this);
    this.mountObjectToSave = this.mountObjectToSave.bind(this);
    this.exit = this.exit.bind(this);
    this.updateTalhoesSelecteds = this.updateTalhoesSelecteds.bind(this);
    this.filterTalhoesToSelect = this.filterTalhoesToSelect.bind(this);
    this.clickSaveInsumos = this.clickSaveInsumos.bind(this);
    this.swalQuestion = this.swalQuestion.bind(this);
    this.clearLocaisOrganico = this.clearLocaisOrganico.bind(this);
    this.touchedLocaisOrganico = this.touchedLocaisOrganico.bind(this);
    this.getLabelTalhaoDashboard = this.getLabelTalhaoDashboard.bind(this);
    this.saveLaudoInsumo = this.saveLaudoInsumo.bind(this);
    this.deleteUpload = this.deleteUpload.bind(this);

    window.onbeforeunload = function() {
      if (this.isDirty()) {
        return true;
      }
    }.bind(this);
  }

  /**
   * Marca o componente como montado e carrega os dados da base de dados
   */
  async componentDidMount() {
    this._isMounted = true;

    await this.loadAreasDeCultivo();

    if (this.state.plantio.idPlantio) {
      this.loadPlantio();
    }

    this.loadAnaliseOgm();
    this.loadCulturas();
    this.loadCultivares();
    this.loadEspecificacoes();
    this.loadFormaAplicacao();
  }

  /**
   * Marca o componente como desmontado
   */
  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = undefined;
  }

  /**
   * Executa um `setState` somente se o componente estiver montado
   *
   * @param {Object | Function} values - Valores que vão ser mudados no state
   * @param {Function} [callback=() => {}] - Função que é executada depois que o state é alterado
   */
  safeSetState(values, callback = () => {}) {
    this._isMounted && this.setState(values, callback);
  }

  /**
   * Salva os dados
   */
  async onSubmit() {
    if (this.semeaduraFormikProps) {
      const errors = await this.semeaduraFormikProps.validateForm();
      if (Object.keys(errors).length !== 0) {
        this.handleChangeIndex(1);
        this.semeaduraFormikProps.handleSubmit();
        return;
      }
    }

    if (this.previsaoColheitaFormikProps) {
      const errors = await this.previsaoColheitaFormikProps.validateForm();
      if (Object.keys(errors).length !== 0) {
        this.handleChangeIndex(3);
        this.previsaoColheitaFormikProps.handleSubmit();
        return;
      }
    }

    if (this.colheitaFormikProps) {
      const errors = await this.colheitaFormikProps.validateForm();
      if (Object.keys(errors).length !== 0) {
        this.handleChangeIndex(4);
        this.colheitaFormikProps.handleSubmit();
        return;
      }
    }

    this.handleChangeIndex(0);

    const plantio = this.mountObjectToSave();

    const configMsgError = {
      icon: 'error',
      buttons: {
        confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-error' }
      }
    };

    if (plantio.plantioTalhaoList.filter(item => item.stRegistro !== 2).length === 0) {
      swal(formatMessage('plantios.minimoTalhoes'), configMsgError);
      return;
    }

    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: true,
        title: formatMessage(`plantios.${this.props.match.params.id ? 'atualizando' : 'salvando'}`)
      }
    );
    plantio.idPlantio = +this.props.match.params.id || null;

    let request;
    if (plantio.idPlantio) {
      request = updatePlantio(plantio);
    } else {
      request = savePlantio(plantio);
    }

    request
      .then(res => {
        swal(formatMessage('plantios.plantioSalvoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
          }
        });

        this.exit(true);
      })
      .catch(err => swal(formatMessage('plantios.falhaAoSalvarPlantio'), configMsgError));
  }

  /**
   * Monta um objeto para a request de save ou update
   *
   * @returns {Plantio} O objeto que vai ser enviado
   */
  mountObjectToSave() {
    const plantio = {
      ...this.state.plantio,
      ...this.state.plantio.semeadura,
      ...this.state.plantio.standFinal,
      ...this.state.plantio.previsaoColheita,
      ...this.state.plantio.colheita,
      ...this.state.plantio.ogm,
      plantioTalhaoList: [...this.state.talhoesSelecteds, ...this.state.plantiosTalhoesDeletedList]
    };
    delete plantio.semeadura;
    delete plantio.standFinal;
    delete plantio.previsaoColheita;
    delete plantio.ogm;
    delete plantio.colheita;
    delete plantio.dtEstimada;

    plantio.dtPlantio = formatDate(plantio.dtPlantio, 'YYYY-MM-DD');
    plantio.dtColheita = formatDate(plantio.dtColheita, 'YYYY-MM-DD');
    plantio.areaCultivo = this.state.areasDeCultivo.find(
      item => item.idAreaCultivo === this.values.areaDeCultivo
    );
    if (!plantio.dtColheita) {
      plantio.tpUnidade = 0;
      plantio.tpArea = 0;
      plantio.nrProdutividade = 0;
    }

    if (!plantio.nrSemeadura) {
      plantio.tpSemeadura = 0;
      plantio.tpAreaSemeadura = 0;
    }

    if (!plantio.nrQuantidadeStandFinal) {
      plantio.tpUnMedidaStandFinal = 0;
    }

    if (!plantio.nrDiasColheita) {
      plantio.nrProdutividadeEstimada = 0;
      plantio.tpUnidadeEstimada = 0;
      plantio.tpAreaEstimada = 0;
    }

    for (const prop in plantio) {
      if (plantio[prop] === '') {
        plantio[prop] = null;
      }
    }

    plantio.laudoPlantioList = this.state.laudoPlantioList
      .filter(file => file.stRegistro !== 1)
      .map(file => {
        return {
          nmArquivo: file.dsFoto,
          nmArquivoBase64: file.dsFotoBase64,
          stRegistro: 0
        };
      });

    this.state.laudoPlantioListDeleted.forEach(deleted => {
      plantio.laudoPlantioList.push({
        idLaudoPlantio: deleted.idLaudoPlantio,
        nmArquivo: deleted.dsFoto,
        dsS3: deleted.dsLink,
        stRegistro: 2
      });
    });

    return plantio;
  }

  /**
   * Carrega os dados do Plantio da base de dados
   */
  loadPlantio() {
    findPlantioById(this.props.match.params.id)
      .then(res => {
        const plantio = res.data;
        plantio.plantioTalhaoList.forEach(item => (item.stRegistro = 1));

        this.loadCultivares(plantio.cultura);
        this.loadEspecificacoes(plantio.cultivar);

        const plantioToState = {
          areaCultivo: plantio.areaCultivo.idAreaCultivo,
          cultura: plantio.cultura,
          cultivar: plantio.cultivar,
          cultivarEspec: plantio.cultivarEspec,
          dtPlantio: formatDate(plantio.dtPlantio, null),
          plantioSeveridadeList: optionalValue(plantio.plantioSeveridadeList, []),
          stInvasoras: optionalValue(plantio.stInvasoras, 0),
          produtor: { idProdutor: +this.props.match.params.idProdutor },
          semeadura: {
            nrSemeadura: optionalValue(plantio.nrSemeadura),
            tpSemeadura: optionalValue(plantio.tpSemeadura),
            tpAreaSemeadura: optionalValue(plantio.tpAreaSemeadura),
            nrEspacamento: optionalValue(plantio.nrEspacamento)
          },
          standFinal: {
            nrQuantidadeStandFinal: optionalValue(plantio.nrQuantidadeStandFinal),
            tpUnMedidaStandFinal: optionalValue(plantio.tpUnMedidaStandFinal)
          },
          previsaoColheita: {
            nrDiasColheita: optionalValue(plantio.nrDiasColheita),
            dtEstimada: plantio.nrDiasColheita
              ? formatDate(moment(plantio.dtPlantio).add(plantio.nrDiasColheita, 'days'))
              : null,
            nrProdutividadeEstimada: optionalValue(plantio.nrProdutividadeEstimada),
            tpUnidadeEstimada: optionalValue(plantio.tpUnidadeEstimada),
            tpAreaEstimada: optionalValue(plantio.tpAreaEstimada)
          },
          colheita: {
            dtColheita: formatDate(plantio.dtColheita, null),
            nrProdutividade: optionalValue(plantio.nrProdutividade),
            tpUnidade: optionalValue(plantio.tpUnidade),
            tpArea: optionalValue(plantio.tpArea),
            nrEntregaEfetiva: optionalValue(plantio.nrEntregaEfetiva),
            tpUnidadeEntregaEfetiva: optionalValue(plantio.tpUnidadeEntregaEfetiva),
            dsCausaEntregaEfetiva: optionalValue(plantio.dsCausaEntregaEfetiva)
          },
          ogm: {
            stPossuiLaudoOgm: optionalValue(plantio.stPossuiLaudoOgm),
            tpOrigemSemente: optionalValue(plantio.tpOrigemSemente),
            dsOrigemSemente: optionalValue(plantio.dsOrigemSemente),
            tipoAnaliseOgm: optionalValue(plantio.tipoAnaliseOgm),
            plantioInsumoList: plantio.plantioInsumoList.map(item => {
              return {
                marca: item.insumo.marca,
                insumo: item.insumo,
                tpPesticida: item.insumo.tpInsumo,
                formaAplicacao: item.formaAplicacao
              };
            })
          },
          talhaoList: [...plantio.plantioTalhaoList]
        };

        this.initialPlantio = { ...plantioToState };

        this.props.dispatch({
          type: 'UPDATE_DESCRICAO',
          descricao: ` - ${plantioToState.cultura.nmCultura} - ${
            plantioToState.cultivarEspec.nmCultivarEspec
          } - ${this.getLabelTalhaoDashboard(plantioToState.talhaoList)}`
        });

        this.safeSetState(
          {
            laudoPlantioList: plantio.laudoPlantioList.map(file => {
              return {
                dsFoto: file.nmArquivo,
                dsLink: file.dsS3,
                stRegistro: 1,
                idLaudoPlantio: file.idLaudoPlantio
              };
            }),
            talhoesSelecteds: plantio.plantioTalhaoList.map(doc => {
              doc.stRegistro = 1;
              doc.talhao.areaCultivo = plantio.areaCultivo;
              return doc;
            }),
            talhoesVisibles: plantio.plantioTalhaoList.map(doc => {
              doc.stRegistro = 1;
              doc.talhao.areaCultivo = plantio.areaCultivo;
              return doc;
            }),
            keyAutocompleteCultura: Math.random(),
            keyAutocompleteCultivares: Math.random(),
            keyAutocompleteEspecificacao: Math.random(),
            plantio: { ...plantioToState }
          },
          () => {
            const areaCultivo = this.state.areasDeCultivo.find(
              item => item.idAreaCultivo === plantio.areaCultivo.idAreaCultivo
            );

            this.safeSetState(
              { talhoes: areaCultivo.talhaoList, enableReinitialize: false, loading: false },
              () => this.setFieldValue('areaDeCultivo', plantio.areaCultivo.idAreaCultivo)
            );
          }
        );
      })
      .catch(err => this.safeSetState({ enableReinitialize: false, loading: false }));

    /**
     * Valida o valor recebido como parâmetro e retorna o mesmo se for válido,
     * se não, retorna uma string vazia ou o segundo parâmetro, se for passado
     *
     * @param {*} value - Valor que vai ser validado
     * @param {*} [defaultValue=''] - Valor que retorna caso o primeiro parâmetro seja inválido, se não for passado assume uma string vazia
     * @returns {*} O parâmetro recebido, se for válido, se não, retorna o segundo parâmetro ou uma string vazia
     */
    function optionalValue(value, defaultValue = '') {
      return value === undefined || value === null ? defaultValue : value;
    }
  }

  /**
   * Monta o label de talhões da dashboard
   * @param {*} talhaoList - lista de talhoes
   * @returns - string da lista de talhões
   */
  getLabelTalhaoDashboard(talhaoList) {
    const lengthTalhaoList = talhaoList.length;
    let labelTalhao = '';

    if (lengthTalhaoList > 0) {
      labelTalhao = talhaoList[0].talhao.dsTalhao;
      if (lengthTalhaoList > 1) {
        labelTalhao += `... +${lengthTalhaoList - 1}`;
      }
    }

    return labelTalhao;
  }

  /**
   * Carrega os Talhões e as Áreas de Cultivo da base de dados para seleção
   */
  async loadAreasDeCultivo() {
    await findAreaCultivoByProdutor(this.props.match.params.idProdutor)
      .then(res => {
        res.data.forEach(areaCultivo =>
          areaCultivo.talhaoList.forEach(
            talhao => (talhao.areaCultivo = { ...areaCultivo, talhaoList: undefined })
          )
        );

        this.safeSetState({ areasDeCultivo: res.data });
      })
      .catch(err => this.safeSetState({ areasDeCultivo: [] }));
  }

  /**
   * Trata o valor do Autocomplete da Cultura
   *
   * @returns O valor tratado
   */
  tratarValorCultura() {
    if (!this.state.plantio.cultura || this.state.plantio.cultura.nmCultura === undefined) {
      return '';
    }

    return this.state.plantio.cultura;
  }

  /**
   * Trata o valor do Autocomplete da Cultura
   *
   * @returns O valor tratado
   */
  tratarValorAnaliseOgm() {
    if (
      !this.state.plantio.ogm.tipoAnaliseOgm ||
      this.state.plantio.ogm.tipoAnaliseOgm.dsTipoAnaliseOgm === undefined
    ) {
      return '';
    }

    return this.state.plantio.ogm.tipoAnaliseOgm;
  }

  /**
   * Manipula o evento de change do Autocomplete da Cultura
   *
   * @param {any} [value=''] - Valor que vai ser atribuído ao state, ou uma string vazia
   */
  onChangeCultura(value = '') {
    this.loadCultivares(value);
    this.loadEspecificacoes();

    this.safeSetState(prevState => ({
      keyAutocompleteCultura: Math.random(),
      keyAutocompleteCultivares: Math.random(),
      keyAutocompleteEspecificacao: Math.random(),
      plantio: { ...prevState.plantio, cultura: value || '', cultivarEspec: '', cultivar: '' }
    }));

    this.setFieldValue('cultura', value);
    this.setFieldValue('cultivarEspec', '');
    this.setFieldValue('cultivar', '');
  }

  /**
   * Carrega as Culturas da base de dados
   */
  loadCulturas() {
    Cultura.findAllCultura()
      .then(res => this.safeSetState({ culturas: res.data }))
      .catch(() => this.safeSetState({ culturas: [] }));
  }

  loadAnaliseOgm() {
    AnaliseOGMAPI.findAllAnaliseOGMAtivo()
      .then(res => this.safeSetState({ analiseOgmList: res.data }))
      .catch(() => this.safeSetState({ analiseOgmList: [] }));
  }

  loadFormaAplicacao() {
    FormaAplicacaoAPI.findAllFormaAplicacaoAtivo()
      .then(res => this.safeSetState({ formaAplicacaoList: res.data }))
      .catch(() => this.safeSetState({ formaAplicacaoList: [] }));
  }

  /**
   * Trata o valor do Autocomplete de Cultivares
   *
   * @returns O valor tratado
   */
  tratarValorCultivares() {
    if (!this.state.plantio.cultivar || this.state.plantio.cultivar.nmCultivar === undefined) {
      return '';
    }

    return this.state.plantio.cultivar;
  }

  /**
   * Manipula o evento de change do Autocomplete de Cultivares
   *
   * @param {any} [value=''] - Valor que vai ser atribuído ao state, ou uma string vazia
   */
  onChangeCultivares(value = '') {
    this.loadEspecificacoes(value);

    this.safeSetState(prevState => ({
      keyAutocompleteCultivares: Math.random(),
      keyAutocompleteEspecificacao: Math.random(),
      plantio: { ...prevState.plantio, cultivar: value || '', cultivarEspec: '' }
    }));

    this.setFieldValue('cultivar', value);
    this.setFieldValue('cultivarEspec', '');

    if (!this.values.cultura && value) {
      this.setFieldValue('cultura', value.cultura);

      this.safeSetState(prevState => ({
        keyAutocompleteCultura: Math.random(),
        plantio: { ...prevState.plantio, cultura: value.cultura }
      }));

      this.loadCultivares(value.cultura);
    }
  }

  /**
   * Carrega os Cultivares da base de dados
   *
   * @param {Cultura} cultura - Cultura para filtrar os Cultivares
   */
  loadCultivares(cultura) {
    if (cultura) {
      Cultivar.findCultivarByCultura(cultura.idCultura)
        .then(res =>
          this.safeSetState({
            cultivares: res.data.filter(
              item => item.cultura.idCultura === cultura.idCultura && item.stCultivar === 1
            )
          })
        )
        .catch(() => this.safeSetState({ cultivares: [] }));
    } else {
      Cultivar.findAllCultivar()
        .then(res =>
          this.safeSetState({
            cultivares: res.data.filter(item => item.stCultivar === 1)
          })
        )
        .catch(() => this.safeSetState({ cultivares: [] }));
    }
  }

  /**
   * Trata o valor do Autocomplete de Especificações
   *
   * @returns O valor tratado
   */
  tratarValorEspecificacoes() {
    if (
      !this.state.plantio.cultivarEspec ||
      this.state.plantio.cultivarEspec.nmCultivarEspec === undefined
    ) {
      return '';
    }

    return this.state.plantio.cultivarEspec;
  }

  /**
   * Manipula o evento de change do Autocomplete de Especificações
   *
   * @param {any} [value=''] - Valor que vai ser atribuído ao state, ou uma string vazia
   */
  onChangeEspecificacoes(value = '') {
    this.safeSetState(prevState => ({
      keyAutocompleteEspecificacao: Math.random(),
      plantio: { ...prevState.plantio, cultivarEspec: value || '' }
    }));

    this.setFieldValue('cultivarEspec', value);
    if (!this.values.cultivar) {
      this.setFieldValue('cultivar', value.cultivar);
      this.setFieldValue('cultura', value.cultivar.cultura);

      this.safeSetState(prevState => ({
        keyAutocompleteCultivares: Math.random(),
        keyAutocompleteCultura: Math.random(),
        plantio: { ...prevState.plantio, cultivar: value.cultivar, cultura: value.cultivar.cultura }
      }));

      this.loadEspecificacoes(value.cultivar);
      this.loadCultivares(value.cultivar.cultura);
    }
  }

  /**
   * Carrega as Especificações da base de dados
   *
   * @param {Cultivar} cultivar - Cultivar para filtrar as Especificações
   */
  loadEspecificacoes(cultivar) {
    if (cultivar) {
      Cultivar.findCultivarById(cultivar.idCultivar)
        .then(query => {
          const data = query.data.cultivarEspecList.filter(item => item.stCultivarEspec === 1);
          this.safeSetState({ especificacoes: data });
        })
        .catch(err => this.safeSetState({ especificacoes: [] }));
    } else {
      CultivarEspec.findAllEspecCultivar()
        .then(query => {
          this.safeSetState({ especificacoes: query.data });
        })
        .catch(err => this.safeSetState({ especificacoes: [] }));
    }
  }

  /**
   * Manipula a mudança de index das tabs
   *
   * @param {Number} index - Index selecionado
   */
  handleChangeIndex(index, values) {
    this.itensButtons.forEach(item => {
      if (index === item.key) {
        item.select = true;
      } else {
        item.select = false;
      }
    });

    this.safeSetState({ index });

    if (values) {
      this.props.dispatch({
        type: 'UPDATE_DESCRICAO',
        descricao: ` - ${values.cultura.nmCultura || ''} - ${values.cultivarEspec.nmCultivarEspec ||
          ''} - ${this.getLabelTalhaoDashboard(this.state.talhoesSelecteds) || ''}`
      });
    }
  }

  clearLocaisOrganico() {
    this.setFieldValue('talhao', '');
    this.setFieldValue('dsVizinho', '');
    this.setFieldValue('dsBarreiraVegetalNorte', '');
    this.setFieldValue('dsBarreiraVegetalSul', '');
    this.setFieldValue('dsBarreiraVegetalLeste', '');
    this.setFieldValue('dsBarreiraVegetalOeste', '');
  }

  /**
   * Persiste os valores das outras tabs no state
   *
   * @param {String} propName - Nome da prop no state
   * @param {*} values - Valores que vão ser persistidos
   */
  persistValues(propName, values) {
    this.safeSetState(prevState => ({
      plantio: { ...prevState.plantio, [propName]: values }
    }));
  }

  touchedLocaisOrganico() {
    this.setFieldTouched('talhao', true);
    this.setFieldTouched('dsBarreiraVegetalNorte', true);
    this.setFieldTouched('dsBarreiraVegetalSul', true);
    this.setFieldTouched('dsBarreiraVegetalLeste', true);
    this.setFieldTouched('dsBarreiraVegetalOeste', true);
    this.setFieldTouched('dsVizinho', true);
  }

  closeTalhao() {
    this.touchedLocaisOrganico();

    this.state.talhoesSelecteds.forEach(subItem => {
      subItem.edit = false;
    });

    this.safeSetState({
      statusEdit: false,
      enableReinitialize: false,
      itemSelect: {},
      keyData: Math.random(),
      talhaoSelected: '',
      disabledDeleteButtonTalhoes: true
    });

    this.clearLocaisOrganico();
  }

  /**
   * Adiciona um novo Talhão na grid
   */
  addTalhao() {
    this.touchedLocaisOrganico();

    if (this.values.talhao === '') return;
    const { talhoes } = this.state;

    const auxTalhoesSelecteds = [...this.state.talhoesSelecteds];

    if (this.values.talhao === 0) {
      const allTalhoesFiltrados = [...this.state.talhoes];
      allTalhoesFiltrados.forEach(item => addOrActive(item.idTalhao));

      const talhoesList = [];
      allTalhoesFiltrados.forEach(item => {
        talhoesList.push({
          talhao: item,
          stRegistro: 0
        });
      });

      this.updateTalhoesSelecteds(talhoesList);

      this.setFieldValue('talhao', '');
      return;
    }

    const talhao = talhoes.find(item => item.idTalhao === this.values.talhao);

    if (this.state.statusEdit) {
      const index = auxTalhoesSelecteds.findIndex(
        item => item.talhao.idTalhao === this.state.talhaoSelected.talhao.idTalhao
      );

      let itemSelect = {
        talhao: { ...talhao },
        dsBarreiraVegetalNorte: this.values.dsBarreiraVegetalNorte,
        dsBarreiraVegetalSul: this.values.dsBarreiraVegetalSul,
        dsBarreiraVegetalLeste: this.values.dsBarreiraVegetalLeste,
        dsBarreiraVegetalOeste: this.values.dsBarreiraVegetalOeste,
        dsVizinho: this.values.dsVizinho,
        stRegistro: this.state.talhaoSelected.stRegistro,
        idPlantioTalhao: this.state.itemSelect.idPlantioTalhao
      };

      auxTalhoesSelecteds[index] = itemSelect;

      this.setState({
        statusEdit: false,
        enableReinitialize: false,
        itemSelect: {}
      });
    } else {
      auxTalhoesSelecteds.push({
        talhao: { ...talhao },
        dsBarreiraVegetalNorte: this.values.dsBarreiraVegetalNorte,
        dsBarreiraVegetalSul: this.values.dsBarreiraVegetalSul,
        dsBarreiraVegetalLeste: this.values.dsBarreiraVegetalLeste,
        dsBarreiraVegetalOeste: this.values.dsBarreiraVegetalOeste,
        dsVizinho: this.values.dsVizinho,
        stRegistro: 0
      });
    }

    this.updateTalhoesSelecteds(auxTalhoesSelecteds);
    this.clearLocaisOrganico();

    /**
     * Valida se o Talhão já existe na lista de selecionados mas marcado como "excluído",
     * se sim, marca como "atualizando", se não, adiciona como um novo
     *
     * @param {Number} idTalhao - ID do Talhão que vai ser adicionado
     */
    function addOrActive(talhaoList) {
      const talhao = talhoes.find(item => item.idTalhao === talhaoList.idTalhao);
      const index = auxTalhoesSelecteds.findIndex(
        item => item.stRegistro === 2 && item.idTalhao === talhao.idTalhao
      );

      if (index !== -1) {
        auxTalhoesSelecteds[index].stRegistro = 1;
      } else {
        auxTalhoesSelecteds.push({
          talhao: { ...talhao },
          stRegistro: 0
        });
      }
    }
  }

  /**
   * Filtra a lista de Talhões para o select
   *
   * @returns {Talhao[]} Lista de Talhões filtrada
   */
  filterTalhoesToSelect() {
    if (!this.values.areaDeCultivo) return [];

    return this.state.talhoes.filter(
      item =>
        !this.state.talhoesSelecteds.find(
          subItem =>
            subItem.stRegistro !== 2 && subItem.talhao.idTalhao === item.idTalhao && !subItem.edit
        )
    );
  }

  /**
   * Manipula o evento de mudança do select de Área de Cultivo
   *
   * @param {*} e - Change event
   */
  handleChangeAreaCultivo(e) {
    const value = e.target.value;

    if (this.state.talhoesSelecteds.length > 0 && this.values.areaDeCultivo !== +value) {
      this.swalQuestion(formatMessage('plantios.desejaLimparTalhoes')).then(res => {
        if (res) {
          this.updateTalhoesSelecteds([]);

          this.setFieldValue('areaDeCultivo', value !== '' ? +value : value);

          if (value) {
            const areaCultivo = this.state.areasDeCultivo.find(item => item.idAreaCultivo === +value);
            this.safeSetState({ talhoes: areaCultivo.talhaoList });
          }

          this.safeSetState(prevState => ({
            plantio: { ...prevState.plantio, areaCultivo: value !== '' ? +value : value },
            itemSelect: {},
            statusEdit: false
          }));
        }
      });
    } else {
      this.setFieldValue('areaDeCultivo', value !== '' ? +value : value);

      if (value) {
        const areaCultivo = this.state.areasDeCultivo.find(item => item.idAreaCultivo === +value);
        this.safeSetState({ talhoes: areaCultivo.talhaoList });
      }

      this.safeSetState(prevState => ({
        plantio: { ...prevState.plantio, areaCultivo: value !== '' ? +value : value }
      }));
    }
  }

  /**
   * Manipula o evento de seleção de linha da grid de Talhões
   *
   * @param {*} value - Linha selecionada
   */
  selectChangeTalhao(value) {
    if (value) {
      this.safeSetState({ talhaoSelected: value, disabledDeleteButtonTalhoes: false });
    } else {
      this.safeSetState({ talhaoSelected: '', disabledDeleteButtonTalhoes: true });
    }
  }

  /**
   * Remove um Talhão da lista ou marca ele como excluído caso ja exista na base de dados
   */
  removeTalhao() {
    if (!this.state.talhaoSelected) return;

    if (this.state.talhaoSelected.stAuditoria === 1) {
      const configMsgError = {
        icon: 'warning',
        buttons: {
          confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-error' }
        }
      };
      swal(formatMessage('plantios.talhaoRelacionado'), configMsgError);
      return;
    }

    this.swalQuestion(formatMessage('plantios.excluirTalhao')).then(res => {
      if (res) {
        const auxTalhoesSelecteds = [...this.state.talhoesSelecteds];
        const index = auxTalhoesSelecteds.findIndex(
          item => item.talhao.idTalhao === this.state.talhaoSelected.talhao.idTalhao
        );

        const plantiosTalhoesDeletedList = [...this.state.plantiosTalhoesDeletedList];
        plantiosTalhoesDeletedList.push({ ...auxTalhoesSelecteds[index], stRegistro: 2 });
        auxTalhoesSelecteds.splice(index, 1);

        this.setState({
          plantiosTalhoesDeletedList: plantiosTalhoesDeletedList,
          itemSelect: {},
          statusEdit: false
        });

        this.updateTalhoesSelecteds(auxTalhoesSelecteds);
      }
    });
  }

  /**
   * Organiza o registro da grid para editar
   */
  editTalhao() {
    if (!this.state.talhaoSelected) return;

    const auxTalhoesSelecteds = this.state.talhoesSelecteds;
    const index = auxTalhoesSelecteds.findIndex(
      item => item.talhao.idTalhao === this.state.talhaoSelected.talhao.idTalhao
    );

    let item = auxTalhoesSelecteds[index];
    item.edit = true;

    this.setState(
      {
        enableReinitialize: true
      },
      () => {
        this.setState({
          itemSelect: item,
          statusEdit: true
        });
      }
    );
  }

  /**
   * Atualiza a lista de Talhões selecionados e visíveis
   */
  updateTalhoesSelecteds(talhoesSelecteds) {
    this.safeSetState({
      talhoesSelecteds,
      talhoesVisibles: talhoesSelecteds.filter(item => item.stRegistro !== 2)
    });

    this.props.dispatch({
      type: 'UPDATE_DESCRICAO',
      descricao: ` - ${this.values.cultura.nmCultura} - ${
        this.values.cultivarEspec.nmCultivarEspec
      } - ${this.getLabelTalhaoDashboard(talhoesSelecteds)}`
    });
  }

  /**
   * Manipula o evento de mudança do campo de Data do plantio
   *
   * @param {*} date - Data selecionada
   */
  onChangeDataPlantio(date) {
    let dataEstimada = null;
    const { nrDiasColheita } = this.state.plantio.previsaoColheita;

    if (nrDiasColheita !== '' && nrDiasColheita !== null && nrDiasColheita !== undefined) {
      dataEstimada = new Date(date);
      dataEstimada.setDate(dataEstimada.getDate() + parseFloat(nrDiasColheita));
    }

    this.safeSetState(prevState => ({
      plantio: {
        ...prevState.plantio,
        dtPlantio: date,
        previsaoColheita: {
          ...prevState.plantio.previsaoColheita,
          dtEstimada: dataEstimada
        }
      }
    }));

    this.setFieldValue('dtPlantio', date);
  }

  /**
   * Volta para a tela de listagem de Plantios do Produtor
   *
   * @param {Boolean} [forceExit=false] - Se deve fazer a validação ao sair
   */
  exit(forceExit = false) {
    this.safeSetState({ index: 0, canLeave: forceExit }, () =>
      this.props.history.push(`/app/plantios/${this.props.match.params.idProdutor}`)
    );
  }

  /**
   * Valida se o Usuário fez alguma mudança nos campos
   *
   * @returns {Boolean} true - se ele fez alguma mudança; false - se não fez;
   */
  isDirty() {
    return (
      JSON.stringify(this.initialPlantio) !==
      JSON.stringify({ ...this.state.plantio, talhaoList: this.state.talhoesSelecteds })
    );
  }

  /**
   * Faz a confirmação se o Usuário quer mesmo cancelar o cadastro
   *
   * @param {*} location - Informação de rota
   */
  onTryExit(location) {
    if (this.isDirty()) {
      this.swalQuestion(formatMessage('plantios.salvarPlantioSair')).then(res => {
        swal.close();

        if (res) {
          this.handleSubmit && this.handleSubmit();
        } else {
          this.setState(
            { canLeave: true },
            () => this.state.canLeave && this.props.history.push(location.pathname)
          );
        }
      });

      return false;
    }

    this.setState(
      { canLeave: true },
      () => this.state.canLeave && this.props.history.push(location.pathname)
    );
  }

  clickSaveInsumos(list) {
    var OGM = this.state.plantio.ogm;
    OGM.plantioInsumoList = list;
  }

  swalQuestion(title) {
    return swal({
      title: title,
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage('plantios.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('plantios.sim'),
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-confirm'
        }
      }
    });
  }

  saveLaudoInsumo(dsArquivoList) {
    this.setState({
      laudoPlantioList: dsArquivoList
    });
  }

  deleteUpload(index) {
    const laudoPlantioListAux = [...this.state.laudoPlantioList];
    const laudoPlantioListDeletedAux = [...this.state.laudoPlantioListDeleted];
    const laudoDeleted = { ...laudoPlantioListAux[index] };
    laudoPlantioListAux.splice(index, 1);

    if (laudoDeleted.stRegistro === 1) {
      laudoPlantioListDeletedAux.push(laudoDeleted);
    }

    this.setState({
      laudoPlantioListDeleted: laudoPlantioListDeletedAux,
      laudoPlantioList: laudoPlantioListAux
    });
  }

  render() {
    const { classes } = this.props;
    const { index } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        {this.state.loading && <Loading />}

        <Prompt when={!this.state.canLeave} message={this.onTryExit} />

        <div style={{ width: '100%' }}>
          <Formik
            initialValues={{
              dtPlantio: this.state.plantio.dtPlantio,
              cultura: this.state.plantio.cultura,
              cultivar: this.state.plantio.cultivar,
              cultivarEspec: this.state.plantio.cultivarEspec,
              talhao:
                this.state.itemSelect.talhao !== undefined ? this.state.itemSelect.talhao.idTalhao : '',
              areaDeCultivo: this.state.plantio.areaCultivo ? this.state.plantio.areaCultivo : '',
              dsBarreiraVegetalSul: this.state.itemSelect.dsBarreiraVegetalSul || '',
              dsBarreiraVegetalNorte: this.state.itemSelect.dsBarreiraVegetalNorte || '',
              dsBarreiraVegetalLeste: this.state.itemSelect.dsBarreiraVegetalLeste || '',
              dsBarreiraVegetalOeste: this.state.itemSelect.dsBarreiraVegetalOeste || '',
              dsVizinho: this.state.itemSelect.dsVizinho || '',
              stPossuiLaudoOgm: this.state.itemSelect.stPossuiLaudoOgm || ''
            }}
            validateOnBlur
            validateOnChange={false}
            enableReinitialize={this.state.enableReinitialize}
            onSubmit={this.onSubmit}
            validationSchema={Yup.lazy(values =>
              Yup.object().shape({
                dtPlantio: Yup.string().required(formatMessage('plantios.campoObrigatorio')),
                cultura: Yup.string().required(formatMessage('plantios.campoObrigatorio')),
                cultivar: Yup.string().required(formatMessage('plantios.campoObrigatorio')),
                cultivarEspec: Yup.string().required(formatMessage('plantios.campoObrigatorio')),
                talhao: Yup.string(),
                areaDeCultivo: Yup.string().required(formatMessage('plantios.campoObrigatorio'))
              })
            )}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              submitForm,
              setFieldTouched
            }) => {
              this.handleSubmit = submitForm;
              this.values = values;
              this.setFieldValue = setFieldValue;
              this.errors = errors;
              this.setFieldTouched = setFieldTouched;

              return (
                <>
                  <Navigation
                    index={index}
                    handleChangeIndex={index => this.handleChangeIndex(index, values)}
                    buttons={this.itensButtons}
                    disabled={this.state.loading}
                  >
                    {[
                      index === 0 ? (
                        <div key={0} className={classes.container}>
                          <Identificacao
                            perfilProdutor={this.props.infoUsuario.tpPerfil === 3}
                            plantio={this.state.plantio}
                            culturas={this.state.culturas}
                            keyAutocompleteCultura={this.state.keyAutocompleteCultura}
                            cultivares={this.state.cultivares}
                            keyAutocompleteCultivares={this.state.keyAutocompleteCultivares}
                            especificacoes={this.state.especificacoes}
                            keyAutocompleteEspecificacao={this.state.keyAutocompleteEspecificacao}
                            onChangeDataPlantio={this.onChangeDataPlantio}
                            onChangeCultura={this.onChangeCultura}
                            loadCultivares={this.loadCultivares}
                            loadCulturas={this.loadCulturas}
                            tratarValorCultura={this.tratarValorCultura}
                            tratarValorCultivares={this.tratarValorCultivares}
                            tratarValorEspecificacoes={this.tratarValorEspecificacoes}
                            onChangeCultivares={this.onChangeCultivares}
                            loadEspecificacoes={this.loadEspecificacoes}
                            onChangeEspecificacoes={this.onChangeEspecificacoes}
                            touched={touched}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />

                          <LocalPlantio
                            areasDeCultivo={this.state.areasDeCultivo}
                            handleChangeAreaCultivo={this.handleChangeAreaCultivo}
                            infoUsuario={this.props.infoUsuario}
                            filterTalhoesToSelect={this.filterTalhoesToSelect}
                            addTalhao={this.addTalhao}
                            closeTalhao={this.closeTalhao}
                            values={values}
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            classes={classes}
                            setFieldValue={setFieldValue}
                          />

                          <div className={classes.grid}>
                            {this.props.infoUsuario.tpPerfil !== 3 && (
                              <GroupButtonGrid
                                hiddenMargin
                                withoutMargin
                                showDelete
                                showEdit
                                disableEdit={this.state.disabledDeleteButtonTalhoes}
                                disableDelete={this.state.disabledDeleteButtonTalhoes}
                                onClickDelete={this.removeTalhao}
                                onClickEdit={this.editTalhao}
                              />
                            )}

                            <DataTable
                              data={this.state.talhoesVisibles}
                              columns={this.headDatatableTalhoes}
                              selectChange={this.selectChangeTalhao}
                              key={this.state.keyData}
                              showPagination={false}
                              borderRadius={false}
                              noMargin
                            />
                          </div>
                        </div>
                      ) : (
                        <div key={0} />
                      ),
                      index === 1 && this.props.infoUsuario.stOrganica === 1 ? (
                        <MaterialPropagacao
                          deleteUpload={this.deleteUpload}
                          arquivoList={this.state.laudoPlantioList}
                          saveFile={this.saveLaudoInsumo}
                          formaAplicacaoList={this.state.formaAplicacaoList}
                          loadAnaliseOgm={this.loadAnaliseOgm}
                          tratarValorAnaliseOgm={this.tratarValorAnaliseOgm}
                          clickSaveInsumos={this.clickSaveInsumos}
                          key={1}
                          analiseOgmList={this.state.analiseOgmList}
                          plantio={this.state.plantio.ogm}
                          errors={errors}
                          touched={touched}
                          persist={values => this.persistValues('ogm', values)}
                          infoUsuario={this.props.infoUsuario}
                        />
                      ) : (
                        <div key={1} />
                      ),
                      <Semeadura
                        key={2}
                        isVisible={index === 2}
                        getFormikProps={formikProps => (this.semeaduraFormikProps = formikProps)}
                        values={this.state.plantio.semeadura}
                        persist={values => this.persistValues('semeadura', values)}
                        infoUsuario={this.props.infoUsuario}
                      />,
                      index === 3 ? (
                        <StandFinal
                          key={3}
                          values={this.state.plantio.standFinal}
                          persist={values => this.persistValues('standFinal', values)}
                          infoUsuario={this.props.infoUsuario}
                        />
                      ) : (
                        <div key={3} />
                      ),
                      <PrevisaoColheita
                        key={4}
                        isVisible={index === 4}
                        getFormikProps={formikProps => (this.previsaoColheitaFormikProps = formikProps)}
                        values={this.state.plantio.previsaoColheita}
                        persist={values => this.persistValues('previsaoColheita', values)}
                        infoUsuario={this.props.infoUsuario}
                        dtPlantio={values.dtPlantio}
                      />,
                      <Colheita
                        key={5}
                        isVisible={index === 5}
                        getFormikProps={formikProps => (this.colheitaFormikProps = formikProps)}
                        values={this.state.plantio.colheita}
                        persist={values => this.persistValues('colheita', values)}
                        dtPlantio={values.dtPlantio}
                        infoUsuario={this.props.infoUsuario}
                      />,
                      index === 6 ? (
                        <AlertasFitossanitarios
                          key={6}
                          plantioSeveridadeList={this.state.plantio.plantioSeveridadeList}
                          stInvasoras={this.state.plantio.stInvasoras}
                        />
                      ) : (
                        <div key={6} />
                      )
                    ]}
                  </Navigation>
                  {this.props.infoUsuario.tpPerfil !== 3 && (
                    <div className={classes.fab}>
                      <ButtonFABMenu
                        icon={iconMenuPontos}
                        actions={[
                          {
                            icon: <Check style={{ color: 'white' }} />,
                            name: formatMessage('plantios.salvar'),
                            onClickIcon: handleSubmit,
                            color: '#42ADE8'
                          },
                          {
                            icon: <Close style={{ color: 'white' }} />,
                            name: formatMessage('plantios.cancelar'),
                            onClickIcon: this.exit,
                            color: '#F33A30'
                          }
                        ]}
                      />
                    </div>
                  )}
                </>
              );
            }}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'plantios';
  })[0].content
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarPlantio)));
