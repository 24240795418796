import React, { Component } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import AreasCultivo from '@components/ComplementaresProdutor/area-cultivo/AreasCultivo';
import Talhoes from '@components/ComplementaresProdutor/talhao/Talhoes';
import { getUserLang } from '@utils/localeUtils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import formatMessage from './i18n/formatMessage';
import _ from 'lodash';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Comunidade from '@resources/Comunidade';
import Localizacao from '@resources/Localizacao';
import Produtor from '@resources/Produtores';
import Usuarios from '@resources/Usuario';
import { findAllActiveAgencia } from '@resources/Agencia';
import Familiares from '@components/ComplementaresProdutor/familiares/Familiares';
import Navigation from '@components/Navigation/Navigation';
import Colaboradores from '@components/ComplementaresProdutor/colaboradores/Colaboradores';
import Ambiental from '@components/ComplementaresProdutor/ambiental/Ambiental';
import AnaliseDeRisco from '@components/ComplementaresProdutor/analise-de-risco/AnaliseDeRisco';
import Agencia from '@components/ComplementaresProdutor/agencia/Agencia';
import ProducaoAnimal from '@components/ComplementaresProdutor/producao-animal/ProducaoAnimal';
import Identificacao from '@components/ComplementaresProdutor/identificacao/Identificacao';
import * as moment from 'moment';
import Talhao from '@resources/Talhao';
import CPFValidator from '@validators/CPFValidator';
import CNPJValidator from '@validators/CNPJValidator';
import { findAllCertificacoes } from '@resources/Certificacao';
import { findActivesClassificacao } from '@resources/Classificacao';
import { findAllActivesCertificacoes } from '@resources/CertificacaoOrganica';
import { style, theme } from './Produtores.styles';
import IdentificacaoOrganico from './IdentificacaoOrganico';
import Temporarios from '@components/ComplementaresProdutor/efetivos/Temporarios';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Componente principal da edição do Produtor
 *
 * @author Bruno Eduardo - Gabriela Farias
 * @class EditarProdutor
 * @extends {Component} - Componente React
 */
class EditarProdutor extends Component {
  constructor(props) {
    super(props);

    let tabConvencional = [
      {
        name: formatMessage('produtores.identificacao'),
        key: 0,
        select: true
      },
      {
        name: formatMessage('produtores.areaDeCultivo'),
        key: 1,
        select: false
      },
      {
        name: formatMessage('produtores.talhoes'),
        key: 2,
        select: false
      }
    ];

    const tabOrganica = [
      {
        name: formatMessage('produtores.familiares'),
        key: 3,
        select: false
      },
      {
        name: formatMessage('produtores.colaboradores'),
        key: 4,
        select: false
      },
      {
        name: formatMessage('produtores.temporarios'),
        key: 5,
        select: false
      },
      {
        name: formatMessage('produtores.producaoAnimal'),
        key: 6,
        select: false
      },
      {
        name: formatMessage('produtores.ambiental'),
        key: 7,
        select: false
      },
      {
        name: formatMessage('produtores.analiseDeRisco'),
        key: 8,
        select: false
      }
    ];

    const tabCooperativa = {
      name: formatMessage('produtores.agencia'),
      key: 8,
      select: false
    };
    if (this.props.infoUsuario.stOrganica === 1) {
      tabConvencional = [...tabConvencional, ...tabOrganica];

      if (this.props.infoUsuario.stCooperativa === 1) {
        tabConvencional.push(tabCooperativa);
      }
    }

    const data = {
      dhCadastro: moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
      produtoList: [],
      areaCultivoList: [],
      talhaoList: [],
      familiarList: [],
      colaboradorList: [],
      colaboradorTemporarioList: [],
      cadastroAmbientalList: [],
      agenciaList: [],
      produtorAgenciaList: [],
      analiseRiscoList: [],
      producaoAnimalList: [],
      comunidade: {
        idComunidade: '',
        municipio: {
          nmMunicipio: '',
          uf: {
            sgUf: ''
          }
        }
      }
    };

    this.initialProdutor = {
      produtoList: [],
      areaCultivoList: [],
      familiarList: [],
      cadastroAmbientalList: [],
      agenciaList: [],
      comunidade: {},
      dsEmail: null,
      nrDocumento: '',
      nrCar: '',
      nrDap: '',
      dhPrimeiraVisita: null,
      dhCadastro: moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
      cdCertificadora: '',
      nmProdutor: '',
      nrTelefone: '',
      colaboradorList: [],
      colaboradorTemporarioList: [],
      producaoAnimalList: [],
      analiseRiscoList: [],
      plantioList: [],
      stProdutor: 1,
      talhaoList: [],
      tecnico: {},
      tpTecnologico: '',
      stAgriculturaFamiliar: '',
      ecosocial: ''
    };
    this.locale = '';

    this.initialEcosocialItens = [];

    this.state = {
      itensTabs: tabConvencional,
      certificacaoList: [],
      classificacaoList: [],
      itemFoto: {},
      listFamiliares: [],
      listProducaoAnimal: [],
      listAmbiental: [],
      listAgencia: [],
      listAnaliseRisco: [],
      listFamiliaresDeleted: [],
      listColaboradoresDeleted: [],
      listTemporariosDeleted: [],
      listProducaoAnimalDeleted: [],
      listAnaliseRiscoDeleted: [],
      checkParticipacao: false,
      checkConflitos: false,
      disabledDtCadastro: false,
      produtor: { ...this.initialProdutor },
      canLeave: false,
      lastIndex: 0,
      index: 0,
      selectAreaCultivo: {},
      selectTalhao: {},
      showFabButton: true,
      selectColaboradores: {},
      estados: [],
      listAreaCultivoExcluidas: [],
      listTalhoesExcluidos: [],
      acompanhamentoProdutor: false,
      error: false,
      key: '',
      keyMunicipio: '',
      keyComunidade: '',
      keyAgencia: 0,
      keyEstado: '',
      municipios: [],
      uf: '',
      check: true,
      stProducaoParalela: false,
      dataAppSync: data,
      comunidade: '',
      nivelTecnologico: '',
      itemSelect: '',
      disabledEdit: true,
      editar: false,
      itensComunidades: [],
      comunidadeListAreaCultivo: [],
      municipioListAreaCultivo: [],
      tecnico: [],
      dataUser: [],
      enableReinitialize: true,
      loading: this.props.match.params.id !== undefined,
      validarInputs: false,
      validarInputsMunicipio: false,
      validarInputsComunidade: false,
      headColaboradores: [
        {
          field: 'nmColaborador',
          headerName: formatMessage('produtores.nome'),
          col: 6
        },
        {
          field: 'nrTelefone',
          headerName: formatMessage('produtores.telefone'),
          col: 6
        }
      ],
      itensNivel: [
        { id: 0, name: formatMessage('produtores.baixo') },
        { id: 1, name: formatMessage('produtores.medio') },
        { id: 2, name: formatMessage('produtores.alto') }
      ],
      agriculturaFamiliarList: [
        { id: 0, name: formatMessage('produtores.nao') },
        { id: 1, name: formatMessage('produtores.sim') }
      ],
      maxIdTalhao: 0,
      maxIdColaborador: 0,
      maxIdAreaCultivo: 0,
      maxIdFake: 1,
      itemComb: '',
      itensViews: {},
      tipoEcosocialList: [],
      itensEcosocial: [...this.initialEcosocialItens]
    };

    this.isDirty = function() {
      return JSON.stringify(this.initialProdutor) !== JSON.stringify(this.state.produtor);
    }.bind(this);

    window.onbeforeunload = function() {
      if (this.isDirty()) {
        return true;
      }
    }.bind(this);

    var title = 'produtores.cadastroDeProdutor';
    if (parseInt(this.props.match.params.pre) === 1) {
      title = 'produtores.efetivarPreCadastro';
    }

    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(title),
        name: title
      });
    }

    this.handleSubmit = null;

    this.validarInicializacaoAutoCompleteMunicipio = _.debounce(
      this.validarInicializacaoAutoCompleteMunicipio.bind(this),
      200
    );
    this.tratarValorSelectInitial = this.tratarValorSelectInitial.bind(this);
    this.onChangeOwn = this.onChangeOwn.bind(this);
    this.handleBlurOwn = this.handleBlurOwn.bind(this);
    this.onClickDeleteAreaCultivo = this.onClickDeleteAreaCultivo.bind(this);
    this.onClickAddAreaCultivo = this.onClickAddAreaCultivo.bind(this);
    this.onClickDeleteTalhao = this.onClickDeleteTalhao.bind(this);
    this.onClickAddTalhao = this.onClickAddTalhao.bind(this);
    this.clickAdicionarAreaCultivo = this.clickAdicionarAreaCultivo.bind(this);
    this.clickAdicionarTalhao = this.clickAdicionarTalhao.bind(this);
    this.retornaCancelarLinguagem = this.retornaCancelarLinguagem.bind(this);
    this.retornaSalvarLinguagem = this.retornaSalvarLinguagem.bind(this);
    this.clickSalvar = this.clickSalvar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.handleBlurOwnMunicipio = this.handleBlurOwnMunicipio.bind(this);
    this.handleBlurOwnComunidade = this.handleBlurOwnComunidade.bind(this);
    this.onChangeOwnMunicipio = this.onChangeOwnMunicipio.bind(this);
    this.onChangeOwnComunidade = this.onChangeOwnComunidade.bind(this);
    this.tratarValorSelectInitialMunicipio = this.tratarValorSelectInitialMunicipio.bind(this);
    this.tratarValorSelectInitialComunidade = this.tratarValorSelectInitialComunidade.bind(this);
    this.concatenarMunicipiosComEstados = this.concatenarMunicipiosComEstados.bind(this);
    this.tratarValorComunidade = _.debounce(this.tratarValorComunidade.bind(this), 200);
    this.validarExcluirTalhao = this.validarExcluirTalhao.bind(this);
    this.tratarValorSelectInitialEstado = this.tratarValorSelectInitialEstado.bind(this);
    this.validarInicializacaoAutoComplete = this.validarInicializacaoAutoComplete.bind(this);
    this.validarInicializacaoAutoCompleteEstado = this.validarInicializacaoAutoCompleteEstado.bind(this);
    this.onClickCancelar = this.onClickCancelar.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.onTryExit = this.onTryExit.bind(this);
    this.validarErros = this.validarErros.bind(this);
    this.onNewFamiliar = this.onNewFamiliar.bind(this);
    this.onUpdateFamiliar = this.onUpdateFamiliar.bind(this);
    this.onDeleteFamiliar = this.onDeleteFamiliar.bind(this);
    this.onClickAddColaborador = this.onClickAddColaborador.bind(this);
    this.onClickAddTemporario = this.onClickAddTemporario.bind(this);
    this.onClickDeleteColaborador = this.onClickDeleteColaborador.bind(this);
    this.onClickDeleteTemporario = this.onClickDeleteTemporario.bind(this);
    this.clickAdicionarColaboradores = this.clickAdicionarColaboradores.bind(this);
    this.onNewAmbiental = this.onNewAmbiental.bind(this);
    this.onNewAgencia = this.onNewAgencia.bind(this);
    this.onDeleteAgencia = this.onDeleteAgencia.bind(this);
    this.onUpdateAmbiental = this.onUpdateAmbiental.bind(this);
    this.onUpdateAgencia = this.onUpdateAgencia.bind(this);
    this.onDeleteAmbiental = this.onDeleteAmbiental.bind(this);
    this.clickAdicionarColaboradores = this.clickAdicionarColaboradores.bind(this);
    this.changeFAB = this.changeFAB.bind(this);
    this.saveTabItem = this.saveTabItem.bind(this);
    this.saveAmbiental = this.saveAmbiental.bind(this);
    this.producaoAnimalDeleted = this.producaoAnimalDeleted.bind(this);
    this.analiseRiscoDeleted = this.analiseRiscoDeleted.bind(this);
    this.formatTextField = this.formatTextField.bind(this);
    this.deleteTalhao = this.deleteTalhao.bind(this);
    this.saveFoto = this.saveFoto.bind(this);
    this.onAddEcosocial = this.onAddEcosocial.bind(this);
    this.onRemoveEcosocial = this.onRemoveEcosocial.bind(this);
    this.inicializarClassificacao = this.inicializarClassificacao.bind(this);
    this.inicializarAgencia = this.inicializarAgencia.bind(this);
    this.converterTpUnidadeToMetros = this.converterTpUnidadeToMetros.bind(this);
    this.converterMetrosToTpUnidade = this.converterMetrosToTpUnidade.bind(this);
    this.validarAutoCompleteMunicioToAreaCultivo = this.validarAutoCompleteMunicioToAreaCultivo.bind(
      this
    );
    this.tratarValorComunidadeAreaCultivo = this.tratarValorComunidadeAreaCultivo.bind(this);
    this.findSomaAntigaAreaCultivo = this.findSomaAntigaAreaCultivo.bind(this);
  }

  resetFormGlobal;
  valueAutoComplete = '';
  valueAutoCompleteMunicipio = '';
  valueAutoCompleteComunidade = '';
  valueAutoCompleteEstado = '';

  async componentDidMount() {
    this.validarInicializacaoAutoComplete();
    this.validarInicializacaoAutoCompleteEstado();
    this.inicializarClassificacao();
    this.inicializarCertificacao();
    this.inicializarAgencia();

    const dataUser = {
      tpPerfil: this.props.infoUsuario.tpPerfil,
      idUsuario: this.props.infoUsuario.idUsuario,
      idCliente: this.props.infoUsuario.idCliente
    };

    var data = this.state.dataAppSync;
    data.areaCultivoList = [];
    data.talhaoList = [];
    data.plantioList = [];
    data.comunidade = {
      idComunidade: '',
      municipio: {
        nmMunicipio: '',
        uf: {
          sgUf: ''
        }
      }
    };

    if (this.props.match.params.id !== undefined || this.props.match.params.perfil === 'perfil') {
      var ref = '';

      if (this.props.match.params.perfil === 'perfil') {
        ref = Produtor.findProdutorByPerfil();
      } else {
        ref = Produtor.findProdutorById(this.props.match.params.id);
      }

      await ref
        .then(query => {
          data = query.data;
          this.valueAutoComplete = data.tecnico;
          this.valueAutoCompleteEstado = data.municipio.uf;
          this.valueAutoCompleteMunicipio = data.municipio;

          this.validarInicializacaoAutoCompleteMunicipio(data.municipio.uf.idUf);
          this.tratarValorComunidade(data.municipio);

          this.valueAutoCompleteComunidade = data.comunidade || null;

          var maxIdAreaCultivo = 0;
          data.areaCultivoList.forEach(doc => {
            if (doc.idAreaCultivo > maxIdAreaCultivo) {
              maxIdAreaCultivo = doc.idAreaCultivo;
            }
            doc.dtValidadeContrato =
              doc.dtValidadeContrato === undefined ? null : doc.dtValidadeContrato;
            doc.stRegistro = 1;
            doc.dsLink = doc.dsTermoManejoOrganicoBase64 || null;
            doc.dsTermoManejoOrganicoBase64 = null;
          });

          var maxIdColaborador = 0;

          if (data.familiarList === undefined) {
            data.familiarList = [];
          }

          if (data.cadastroAmbientalList === undefined) {
            data.cadastroAmbientalList = [];
          }

          if (data.agenciaList === undefined) {
            data.agenciaList = [];
          }

          if (data.produtoList === undefined) {
            data.produtoList = [];
          }

          if (data.analiseRiscoList === undefined) {
            data.analiseRiscoList = [];
          }

          if (data.colaboradorList === undefined) {
            data.colaboradorList = [];
          }

          if (data.colaboradorTemporarioList === undefined) {
            data.colaboradorTemporarioList = [];
          }

          data.familiarList.forEach(doc => {
            doc.stRegistro = 1;
          });

          data.colaboradorList.forEach(doc => {
            doc.nmData = moment(doc.dtNascimento).format(this.formatTextField());
            doc.dataInicial = moment(doc.dtAdmissao).format(this.formatTextField());
            doc.stRegistro = 1;
            if (doc.nrClt === undefined) {
              doc.nrClt = '';
            }
          });

          data.colaboradorTemporarioList.forEach(doc => {
            doc.stRegistro = 1;
          });

          data.producaoAnimalList.forEach(doc => {
            doc.stRegistro = 1;
          });

          data.cadastroAmbientalList.forEach(doc => {
            if (
              doc.stAbastecimentoPulverizador === undefined ||
              doc.stAbastecimentoPulverizador === null
            ) {
              doc.stAbastecimentoPulverizador = null;
            }
            if (doc.dsAbastecimentoPulverizador === undefined) {
              doc.dsAbastecimentoPulverizador = null;
            }
            doc.stRegistro = 1;
          });

          data.analiseRiscoList.map(doc => {
            doc.stRegistro = 1;
            doc.dataLancamento = moment(doc.dtLancamento).format(this.formatTextField());
            doc.nrRiscos =
              parseInt(doc.nrExperienciaOrganica) +
              parseInt(doc.nrResultadoAnterior) +
              parseInt(doc.nrPressaoPesticida) +
              parseInt(doc.nrBarreiraLavoura) +
              parseInt(doc.nrSuspeitaAplicacao) +
              parseInt(doc.nrSafraColheita) +
              parseInt(doc.nrRiscoTrocaProduto);

            doc.riscosAdicionaisList.forEach(item => item.talhao && (item.talhao.stRegistro = 1));

            const chipsDerivaPorVentoOuAgua = doc.riscosAdicionaisList.filter(
              risco => risco.tpRiscoAdicional === 0
            );
            const chipsTransitoVeiculos = doc.riscosAdicionaisList.filter(
              risco => risco.tpRiscoAdicional === 1
            );
            const chipsCultivosTransgenicos = doc.riscosAdicionaisList.filter(
              risco => risco.tpRiscoAdicional === 2
            );
            const chipsUtilizacaoImplementos = doc.riscosAdicionaisList.filter(
              risco => risco.tpRiscoAdicional === 3
            );

            doc.chipsDerivaPorVentoOuAgua =
              (chipsDerivaPorVentoOuAgua &&
                chipsDerivaPorVentoOuAgua.map(item => {
                  return item.talhao;
                })) ||
              [];
            doc.chipsTransitoVeiculos =
              (chipsTransitoVeiculos &&
                chipsTransitoVeiculos.map(item => {
                  return item.talhao;
                })) ||
              [];
            doc.chipsCultivosTransgenicos =
              (chipsCultivosTransgenicos &&
                chipsCultivosTransgenicos.map(item => {
                  return item.talhao;
                })) ||
              [];
            doc.chipsUtilizacaoImplementos =
              chipsUtilizacaoImplementos &&
              chipsUtilizacaoImplementos.map(item => {
                return item.talhao;
              });

            return doc;
          });

          data.colaboradorList.forEach(doc => {
            if (doc.idColaboradorProdutor > maxIdColaborador) {
              maxIdColaborador = doc.idColaboradorProdutor;
            }
            doc.nmSexo =
              doc.tpSexo === 0
                ? formatMessage('produtores.feminino')
                : formatMessage('produtores.masculino');
            doc.nmContrato =
              doc.tpContrato === 0
                ? formatMessage('produtores.efetivo')
                : formatMessage('produtores.temporario');
            doc.stRegistro = 1;
            if (doc.dtDemissao === undefined) {
              doc.dtDemissao = null;
            }
          });

          var maxIdTalhao = 0;
          data.talhaoList.forEach(doc => {
            if (doc.idTalhao > maxIdTalhao) {
              maxIdTalhao = doc.idTalhao;
            }

            let nmOrganica = doc.talhaoTipoCertificacaoList
              .filter(
                item =>
                  moment(item.dtInicio) <= moment() &&
                  (!item.dtTermino || moment(item.dtTermino) >= moment().startOf('day'))
              )
              .map(organica => ' ' + organica.tipoCertificacao.nmTipoCertificacao);

            doc.nmCertificacaoOrganica = nmOrganica.length > 0 ? nmOrganica + ';' : '';
            doc.stRegistro = 1;
            doc.stTalhaoGPS = 0;
            doc.temPontosSalvos = doc.talhaoGpsList.length > 0;
          });

          if (parseInt(this.props.match.params.pre) === 1) {
            data.tpTecnologico = '';
            data.stAgriculturaFamiliar = '';
            data.ecosocial = '';
          }

          const dataProdutor = {
            tipoEcosocialList: data.tipoEcosocialList || [],
            areaCultivoList: data.areaCultivoList,
            comunidade: data.comunidade,
            dsEmail: data.dsEmail || null,
            nrDocumento: data.nrDocumento,
            nrCar: data.nrCar,
            nrDap: data.nrDap,
            dhPrimeiraVisita: data.dhPrimeiraVisita,
            dhCadastro: data.dhCadastro,
            cdCertificadora: data.cdCertificadora,
            nmProdutor: data.nmProdutor,
            nrTelefone: data.nrTelefone,
            plantioList: data.plantioList,
            stProdutor: data.stProdutor,
            talhaoList: data.talhaoList,
            tecnico: data.tecnico,
            tpTecnologico: data.tpTecnologico,
            stAgriculturaFamiliar: data.stAgriculturaFamiliar
          };

          this.initialProdutor = { ...dataProdutor };

          data.agenciaList = data.produtorAgenciaList;

          this.props.dispatch({
            type: 'UPDATE_DESCRICAO',
            descricao: ` - ${data.nmProdutor}`
          });

          this.setState({
            disabledDtCadastro: dataProdutor.dhCadastro && true,
            itemFoto: {
              dsFotoBase64: data.dsFoto,
              dsFoto: data.dsFoto,
              stRegistro: 1
            },
            stProducaoParalela: data.stProducaoParalela === 1,
            produtor: dataProdutor,
            tipoEcosocialList: [...dataProdutor.tipoEcosocialList],
            dataAppSync: data,
            editar: true,
            loading: false,
            key: Math.random(),
            keyMunicipio: Math.random(),
            keyComunidade: Math.random(),
            keyEstado: Math.random(),
            uf: this.valueAutoCompleteMunicipio.uf.sgUf,
            check: data.stProdutor === 1 ? true : false,
            maxIdAreaCultivo: maxIdAreaCultivo + 1,
            maxIdTalhao: maxIdTalhao + 1,
            maxIdColaborador: maxIdColaborador + 1,
            dataUser: dataUser,
            itemComb: '',
            acompanhamentoProdutor: this.props.match.params.idAcompanhamento !== undefined,
            checkConflitos: data.stConflitoLimitrofe === 1,
            checkParticipacao: data.stAssociacaoCooperativa === 1
          });
        })
        .catch(err => {
          this.swalError('produtores.erroAoCarregarDados');

          this.setState({
            dataAppSync: data,
            error: true,
            loading: false
          });
        });
    } else {
      this.setState({
        dataAppSync: data,
        error: false,
        dataUser: dataUser,
        itemComb: '',
        acompanhamentoProdutor: this.props.match.params.idAcompanhamento !== undefined
      });
    }

    this.findCertificacoes();
  }

  /**
   * Busca os registro de classificação para auto complete do talhão
   */
  inicializarCertificacao() {
    findAllActivesCertificacoes()
      .then(doc => {
        this.setState({
          certificacaoList: doc.data
        });
      })
      .catch(() => {
        this.setState({
          certificacaoList: []
        });
      });
  }

  /**
   * Busca os registro de agencia para auto complete do agencia
   */
  inicializarAgencia() {
    findAllActiveAgencia()
      .then(doc => {
        this.setState({
          agenciaList: doc.data
        });
      })
      .catch(() => {
        this.setState({
          agenciaList: []
        });
      });
  }

  /**
   * Busca os registro de classificação para auto complete do talhão
   */
  inicializarClassificacao() {
    findActivesClassificacao()
      .then(doc => {
        this.setState({
          classificacaoList: doc.data
        });
      })
      .catch(() => {
        this.setState({
          classificacaoList: []
        });
      });
  }

  /**
   * Busca as certificações da API
   */
  async findCertificacoes() {
    return findAllCertificacoes()
      .then(res => {
        res.data.sort((a, b) => a.nmTipoEcosocial.localeCompare(b.nmTipoEcosocial));
        this.initialEcosocialItens = [...res.data];
        this.setState({
          itensEcosocial: [
            ...res.data.filter(item => {
              if (!this.props.match.params.id && this.props.match.params.perfil !== 'perfil') {
                return true;
              }
              return !this.state.produtor.tipoEcosocialList.find(
                subItem => subItem.idTipoEcosocial === item.idTipoEcosocial
              );
            })
          ]
        });
      })
      .catch(err => {
        this.swalError('produtores.erroAoCarregarDados');
      });
  }

  formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validarInicializacaoAutoComplete() {
    Usuarios.findAllAtivos()
      .then(query => {
        let data = [];

        query.data.forEach(doc => {
          if (doc.tpPerfil === 2) {
            data.push(doc);
          }
        });

        this.setState({ tecnicos: data });
      })
      .catch(err => {
        this.setState({ tecnicos: [] });
      });
  }

  validarInicializacaoAutoCompleteEstado() {
    Localizacao.findAllEstados()
      .then(query => this.setState({ estados: query.data }))
      .catch(() => this.setState({ estados: [] }));
  }

  validarInicializacaoAutoCompleteMunicipio(idUf) {
    if (idUf !== undefined) {
      Localizacao.findMunicipiosByUf(idUf)
        .then(query => this.setState({ municipios: this.concatenarMunicipiosComEstados(query.data) }))
        .catch(() => this.setState({ municipios: [] }));
    }
  }

  validarAutoCompleteMunicioToAreaCultivo(idUf) {
    if (idUf !== undefined) {
      Localizacao.findMunicipiosByUf(idUf)
        .then(query =>
          this.setState({ municipioListAreaCultivo: this.concatenarMunicipiosComEstados(query.data) })
        )
        .catch(() => this.setState({ municipioListAreaCultivo: [] }));
    }
  }

  concatenarMunicipiosComEstados(getMunicipios) {
    getMunicipios.map(data => {
      data.municipioEstado = data.nmMunicipio.concat(' - ').concat(data.uf.sgUf);
      return data;
    });

    return getMunicipios;
  }

  tratarValorSelectInitial(data) {
    if (!data || data.nmUsuario === undefined) {
      return '';
    }
    return data;
  }

  tratarValorSelectInitialMunicipio(data) {
    if (!data || (data.nmMunicipio === undefined && data.uf === undefined)) {
      return '';
    }
    return data;
  }

  tratarValorSelectInitialComunidade(data) {
    if (!data || data.nmComunidade === undefined) {
      return '';
    }
    return data;
  }

  tratarValorSelectInitialEstado(data) {
    if (!data || data.nmUf === undefined) {
      return '';
    }
    return data;
  }

  onChangeOwn(dsTecnico) {
    const aux = this.state.produtor;
    aux['tecnicoResponsavel'] = dsTecnico;

    this.setState({ produtor: aux, key: Math.random() });

    this.valueAutoComplete = dsTecnico;
  }

  /**
   * Executado ao submitar uma nova agencia
   *
   * @param {Object} values - Objeto agencia novo
   */
  onNewAgencia(values) {
    const data = this.state.dataAppSync;

    data.agenciaList = data.agenciaList.concat(values);

    this.setState(
      {
        produtor: data,
        dataAppSync: data,
        maxIdFake: this.state.maxIdFake + 1
      },
      () => {
        this.setState({
          keyAgencia: Math.random()
        });
      }
    );

    this.changeFAB();
  }

  /**
   * Executado ao submitar uma agencia para atualizar
   *
   * @param {Object} values - Objeto de agencia atualizado
   */
  onUpdateAgencia(values, item) {
    const data = this.state.dataAppSync;

    const itemAgencia = data.agenciaList.find(
      agencia => agencia.idProdutorAgencia === values.idProdutorAgencia
    );
    const index = data.agenciaList.indexOf(itemAgencia);
    data.agenciaList[index] = values;
    data.agenciaList = [...data.agenciaList];

    this.setState({ produtor: data, dataAppSync: data, keyAgencia: Math.random() });

    this.changeFAB();
  }

  /**
   * Executado ao deletar uma agencia
   *
   * @param {Object} produto - agencia que vai ser deletada
   * @memberof EditarProdutor
   */
  onDeleteAgencia(agenciaToDelete, callback) {
    this.swalWarning('produtores.desejaRemoverLancamento', 'produtores.excluir', 'produtores.nao').then(
      res => {
        if (res) {
          const data = this.state.dataAppSync;
          const itemAgencia = data.agenciaList.find(
            agencia => agencia.idProdutorAgencia === agenciaToDelete.idProdutorAgencia
          );

          const index = data.agenciaList.indexOf(itemAgencia);
          if (data.agenciaList[index].stRegistro === 1) {
            data.agenciaList[index].stRegistro = 2;
          } else {
            data.agenciaList.splice(index, 1);
          }

          data.agenciaList = [...data.agenciaList];

          this.setState(
            {
              produtor: data,
              dataAppSync: data,
              showFabButton: true
            },
            () => callback() || this.swalSucess('produtores.lancamentoExcluido')
          );
        }
      }
    );
  }

  /**
   * Executado ao submitar um novo Ambiental
   *
   * @param {Object} values - Objeto Ambiental novo
   */
  onNewAmbiental(values) {
    const data = this.state.dataAppSync;

    data.cadastroAmbientalList = data.cadastroAmbientalList.concat(values);

    this.setState({
      produtor: data,
      dataAppSync: data,
      maxIdFake: this.state.maxIdFake + 1,
      showFabButton: true
    });

    this.saveAmbiental(data.cadastroAmbientalList);
  }

  /**
   * Executado ao submitar um Ambiental para atualizar
   *
   * @param {Object} values - Objeto Ambiental atualizado
   */
  onUpdateAmbiental(values, item) {
    const data = this.state.dataAppSync;

    const index = data.cadastroAmbientalList.indexOf(item);
    data.cadastroAmbientalList[index] = values;
    data.cadastroAmbientalList = [...data.cadastroAmbientalList];

    this.setState({ produtor: data, dataAppSync: data, showFabButton: true });

    this.saveAmbiental(data.cadastroAmbientalList);
  }

  /**
   * Executado ao deletar um Ambiental
   *
   * @param {Object} produto - Ambiental que vai ser deletado
   * @memberof EditarProdutor
   */
  onDeleteAmbiental(produto, callback) {
    this.swalWarning('produtores.desejaRemoverLancamento', 'produtores.excluir', 'produtores.nao').then(
      res => {
        if (res) {
          const data = this.state.dataAppSync;

          const index = data.cadastroAmbientalList.indexOf(produto);
          if (data.cadastroAmbientalList[index].stRegistro === 1) {
            data.cadastroAmbientalList[index].stRegistro = 2;
          } else {
            data.cadastroAmbientalList.splice(index, 1);
          }

          data.cadastroAmbientalList = [...data.cadastroAmbientalList];

          this.setState(
            {
              produtor: data,
              dataAppSync: data,
              showFabButton: true
            },
            () => callback() || this.swalSucess('produtores.lancamentoExcluido')
          );
        }
      }
    );
  }

  onChangeOwnMunicipio(nmMunicipio) {
    if (nmMunicipio) {
      this.valueAutoCompleteComunidade = '';
      this.valueAutoCompleteMunicipio = nmMunicipio;

      this.tratarValorComunidade(nmMunicipio);
    } else {
      this.valueAutoCompleteMunicipio = '';
    }

    this.setState({ keyMunicipio: Math.random() });
  }

  onChangeOwnComunidade(nmComunidade) {
    this.setState({ keyComunidade: Math.random() });

    this.valueAutoCompleteComunidade = nmComunidade;
  }

  tratarValorComunidade(dado) {
    if (dado !== undefined) {
      Comunidade.findComunidadeByMunicipio(dado.idMunicipio)
        .then(query => {
          const data = [];

          query.data.forEach(doc => {
            if (doc.municipio.idMunicipio === dado.idMunicipio) {
              data.push(doc);
            }
          });

          this.setState({ itensComunidades: data });
        })
        .catch(err => this.setState({ itensComunidades: [] }));
    }
  }

  tratarValorComunidadeAreaCultivo(dado) {
    if (dado !== undefined) {
      Comunidade.findComunidadeByMunicipio(dado.idMunicipio)
        .then(query => {
          const data = [];

          query.data.forEach(doc => {
            if (doc.municipio.idMunicipio === dado.idMunicipio) {
              data.push(doc);
            }
          });

          this.setState({ comunidadeListAreaCultivo: data });
        })
        .catch(err => this.setState({ comunidadeListAreaCultivo: [] }));
    }
  }

  handleBlurOwn(valueAutoComplete) {
    let value = false;
    if (valueAutoComplete === null) {
      value = true;
    }

    this.setState({ validarInputs: value });
  }

  handleBlurOwnMunicipio(valueAutoComplete) {
    let value = false;
    if (valueAutoComplete === null) {
      value = true;
    }

    this.setState({ validarInputsMunicipio: value });
  }

  handleBlurOwnComunidade(valueAutoComplete) {
    let value = false;
    if (valueAutoComplete === null) {
      value = true;
    }

    this.setState({ validarInputsComunidade: value });
  }

  onClickDeleteAreaCultivo(position) {
    const excluidos = this.state.listAreaCultivoExcluidas;
    const data = this.state.dataAppSync;
    excluidos.push(data.areaCultivoList[position]);
    data.areaCultivoList.splice(position, 1);
    data.areaCultivoList = [...data.areaCultivoList];

    this.onClickCancelar();
    this.setState({ produtor: data, dataAppSync: data, listAreaCultivoExcluidas: excluidos });
  }

  onClickDeleteColaborador(values) {
    const excluidos = this.state.listColaboradoresDeleted;
    const data = this.state.dataAppSync;
    const position = data.colaboradorList.indexOf(values);
    excluidos.push(data.colaboradorList[position]);
    data.colaboradorList.splice(position, 1);
    data.colaboradorList = [...data.colaboradorList];

    this.onClickCancelar();
    this.setState({ produtor: data, dataAppSync: data, listColaboradoresDeleted: excluidos });
  }

  onClickDeleteTemporario(values) {
    const excluidos = this.state.listTemporariosDeleted;
    const data = this.state.dataAppSync;
    const position = data.colaboradorTemporarioList.indexOf(values);
    excluidos.push(data.colaboradorTemporarioList[position]);
    data.colaboradorTemporarioList.splice(position, 1);
    data.colaboradorTemporarioList = [...data.colaboradorTemporarioList];

    this.onClickCancelar();
    this.setState({ produtor: data, dataAppSync: data, listTemporariosDeleted: excluidos });
  }

  validarExcluirTalhao(value) {
    let validation = false;
    if (this.state.dataAppSync.plantioList) {
      this.state.dataAppSync.plantioList.forEach(doc => {
        doc.plantioTalhaoList.forEach(query => {
          if (query.talhao.idTalhao === value.idTalhao) {
            validation = true;
          }
        });
      });
    }

    return validation;
  }

  deleteTalhao(position) {
    this.swalWarning('produtores.desejaExcluirTalhao', 'produtores.excluir', 'produtores.nao').then(
      willDelete => {
        if (willDelete) {
          const excluidos = this.state.listTalhoesExcluidos;
          const data = this.state.dataAppSync;
          const somaAntiga = this.findSomaAntigaAreaCultivo(
            [...this.state.dataAppSync.talhaoList],
            data.talhaoList[position]
          );
          if (this.state.dataAppSync.talhaoList[position].stRegistro !== 0) {
            excluidos.push(data.talhaoList[position]);
          }

          const talhao = data.talhaoList.splice(position, 1);
          data.talhaoList = [...data.talhaoList];

          this.excluirAreaTalhaoEmAreaDeCultivo(talhao[0], somaAntiga);
          this.onClickCancelar();

          data.areaCultivoList.forEach(doc => {
            if (doc.idAreaCultivo === talhao[0].areaCultivo.idAreaCultivo) {
              doc.nrAreaCultivo = talhao[0].areaCultivo.nrAreaCultivo;
            }
          });

          this.setState({ produtor: data, dataAppSync: data, listTalhoesExcluidos: excluidos });

          this.swalSucess('produtores.talhaoRemovidoComSucesso');
        } else {
          this.setState({ disabledEdit: false });
        }
      }
    );
  }

  onClickDeleteTalhao(position) {
    const validation = this.validarExcluirTalhao(this.state.dataAppSync.talhaoList[position]);

    if (!validation) {
      swal(
        <div>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>,
        {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: true,
          title: formatMessage('produtores.excluindo')
        }
      );

      Talhao.findTalhaoById(this.state.dataAppSync.talhaoList[position].idTalhao)
        .then(doc => {
          this.deleteTalhao(position);
        })
        .catch(err => {
          if (err.response.data.codigo === 'INFOSATELITE_RELACIONADO') {
            swal(
              formatMessage('produtores.exclusaoTalhaoNaoPermitida'),
              formatMessage('produtores.registrosRelacionados'),
              {
                icon: 'warning',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                }
              }
            );
          } else {
            this.deleteTalhao(position);
          }
        });
    } else {
      swal(
        formatMessage('produtores.exclusaoTalhaoNaoPermitida'),
        formatMessage('produtores.plantioAssociado'),
        {
          icon: 'warning',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        }
      );
    }
  }

  excluirAreaTalhaoEmAreaDeCultivo(talhao, somaAntiga) {
    this.adicionarEditarAreaTalhaoEmAreaDeCultivo(talhao, somaAntiga);
  }

  clickAdicionarAreaCultivo(item) {
    if (item !== undefined) {
      this.setState({ selectAreaCultivo: item });
    } else {
      this.setState({ selectAreaCultivo: {} });
    }
  }

  clickAdicionarTalhao(item) {
    if (item !== undefined) {
      this.setState({
        selectTalhao: item
      });
    } else {
      this.setState({
        selectTalhao: {}
      });
    }
  }

  clickAdicionarColaboradores(item) {
    if (item !== undefined) {
      this.setState({ showFabButton: false, selectColaboradores: item });
    } else {
      this.setState({ showFabButton: false, selectColaboradores: {} });
    }
  }

  onClickCancelar(itemComb) {
    this.setState({ showFabButton: true, itemComb: itemComb });
  }

  onClickAddColaborador(values, position) {
    const data = this.state.dataAppSync;

    if (position !== undefined) {
      data.colaboradorList[position] = values;
      data.colaboradorList = [...data.colaboradorList];
    } else {
      values.idColaboradorProdutor = this.state.maxIdFake;
      data.colaboradorList = data.colaboradorList.concat(values);
    }

    this.setState({
      produtor: data,
      dataAppSync: data,
      maxIdFake: this.state.maxIdFake + 1,
      showFabButton: true
    });

    this.changeFAB();
  }

  onClickAddTemporario(values, position) {
    const data = this.state.dataAppSync;

    if (position !== undefined) {
      data.colaboradorTemporarioList[position] = values;
      data.colaboradorTemporarioList = [...data.colaboradorTemporarioList];
    } else {
      values.idColaboradorTemporario = this.state.maxIdFake;
      data.colaboradorTemporarioList = data.colaboradorTemporarioList.concat(values);
    }

    this.setState({
      produtor: data,
      dataAppSync: data,
      maxIdFake: this.state.maxIdFake + 1,
      showFabButton: true
    });

    this.changeFAB();
  }

  onClickAddAreaCultivo(values, position) {
    let data = this.state.dataAppSync;

    if (position !== undefined) {
      data.areaCultivoList[position] = values;
      data.areaCultivoList = [...data.areaCultivoList];
    } else {
      data.areaCultivoList = data.areaCultivoList.concat(values);
    }

    this.setState({
      produtor: data,
      dataAppSync: data,
      maxIdAreaCultivo: this.state.maxIdAreaCultivo + 1
    });
  }

  /**
   * Executado ao submitar um novo Familiar
   *
   * @param {Object} values - Objeto Familiar novo
   * @memberof EditarProdutor
   */
  onNewFamiliar(values) {
    const data = this.state.dataAppSync;

    data.familiarList = data.familiarList.concat(values);

    this.setState({
      produtor: data,
      dataAppSync: data,
      maxIdFake: this.state.maxIdFake + 1,
      showFabButton: true,
      listFamiliares: data.familiarList
    });

    this.changeFAB();
  }

  /**
   * Executado ao submitar um Familiar para atualizar
   *
   * @param {Object} values - Objeto Familiar atualizado
   * @memberof EditarProdutor
   */
  onUpdateFamiliar(values) {
    const data = this.state.dataAppSync;
    data.familiarList = values;

    this.setState({
      produtor: data,
      dataAppSync: data,
      showFabButton: true,
      listFamiliares: data.familiarList
    });

    this.changeFAB();
  }

  /**
   * Executado ao deletar um familiar
   *
   * @param {Object} familiar - Familiar que vai ser deletado
   * @memberof EditarProdutor
   */
  onDeleteFamiliar(familiar, callback) {
    this.swalWarning('produtores.desejaExcluirFamiliar', 'produtores.excluir', 'produtores.nao').then(
      res => {
        if (res) {
          const data = this.state.dataAppSync;

          const index = data.familiarList.indexOf(familiar);
          if (data.familiarList[index].stRegistro === 1) {
            data.familiarList[index].stRegistro = 2;
          } else {
            data.familiarList.splice(index, 1);
          }

          data.familiarList = [...data.familiarList];

          this.setState(
            {
              produtor: data,
              dataAppSync: data,
              showFabButton: true
            },
            () => callback() || this.swalSucess('produtores.familiarExcluido')
          );
        }
      }
    );
  }

  onClickAddTalhao(values, position) {
    var data = this.state.dataAppSync;
    let nmOrganica = values.talhaoTipoCertificacaoList.map(
      organica => ' ' + organica.tipoCertificacao.nmTipoCertificacao
    );

    values.nmCertificacaoOrganica = nmOrganica.length > 0 ? nmOrganica + ';' : '';

    const talhaoAntigo = [...data.talhaoList];
    const somaAntiga = this.findSomaAntigaAreaCultivo(talhaoAntigo, values);

    if (position !== undefined) {
      data.talhaoList[position] = values;
      data.talhaoList = [...data.talhaoList];
      this.adicionarEditarAreaTalhaoEmAreaDeCultivo(values, somaAntiga);

      if (talhaoAntigo[position].areaCultivo.idAreaCultivo !== values.areaCultivo.idAreaCultivo) {
        this.adicionarEditarAreaTalhaoEmAreaDeCultivo(talhaoAntigo[position], somaAntiga);
      }
    } else {
      data.talhaoList = data.talhaoList.concat(values);
      this.adicionarEditarAreaTalhaoEmAreaDeCultivo(values, somaAntiga);
    }

    this.setState({
      produtor: data,
      dataAppSync: data,
      maxIdTalhao: this.state.maxIdTalhao + 1,
      showFabButton: true,
      itemComb: 1
    });
  }

  findSomaAntigaAreaCultivo(talhaoList, talhao) {
    let somaAntiga = 0;
    talhaoList
      .filter(
        doc =>
          talhao.areaCultivo.idAreaCultivo === doc.areaCultivo.idAreaCultivo && talhao.stRegistro !== 2
      )
      .forEach(doc => (somaAntiga += this.converterTpUnidadeToMetros(doc.tpUnidade, doc.nrAreaTalhao)));

    return this.converterMetrosToTpUnidade(talhao.areaCultivo.tpUnidade, somaAntiga);
  }

  adicionarEditarAreaTalhaoEmAreaDeCultivo(talhao, totalAntigo) {
    if (totalAntigo !== talhao.areaCultivo.nrAreaCultivo) {
      return;
    }

    let total = parseFloat(0);

    this.state.dataAppSync.talhaoList.forEach(doc => {
      if (talhao.areaCultivo.idAreaCultivo === doc.areaCultivo.idAreaCultivo) {
        if (doc.stTalhao === 1) {
          const areaMetros = this.converterTpUnidadeToMetros(doc.tpUnidade, doc.nrAreaTalhao);
          total = parseFloat(total) + parseFloat(areaMetros);
        }
      }
    });

    var data = this.state.dataAppSync.areaCultivoList.find(doc => {
      return doc.idAreaCultivo === talhao.areaCultivo.idAreaCultivo;
    });

    const totalConvertido = this.converterMetrosToTpUnidade(data.tpUnidade, total);
    if (totalConvertido < 1 && data.tpUnidade === 2) {
      const nrAreaCultivo = parseFloat(total).toFixed(0);
      data.tpUnidade = 3;
      data.nrAreaCultivo = nrAreaCultivo;
      data.nrModuloFiscal = nrAreaCultivo / data.nrModuloFiscalFator;
    } else if (totalConvertido > 10000 && data.tpUnidade === 3) {
      const nrAreaCultivo = parseFloat((total / 10000).toFixed(3));
      data.tpUnidade = 2;
      data.nrAreaCultivo = nrAreaCultivo;
      data.nrModuloFiscal = nrAreaCultivo / data.nrModuloFiscalFator;
    } else {
      const nrAreaCultivo = parseFloat((total / 10000).toFixed(3));
      data.nrAreaCultivo = nrAreaCultivo;
      data.nrModuloFiscal = nrAreaCultivo / data.nrModuloFiscalFator;
    }
  }

  converterMetrosToTpUnidade(tpUnidade, nrAreaTalhao) {
    switch (tpUnidade) {
      case 0:
        return nrAreaTalhao / 4047;
      case 1:
        return nrAreaTalhao / 27.225;
      case 2:
        return nrAreaTalhao / 10000;
      default:
        return nrAreaTalhao;
    }
  }

  converterTpUnidadeToMetros(tpUnidade, nrAreaTalhao) {
    switch (tpUnidade) {
      case 0:
        return nrAreaTalhao * 4047;
      case 1:
        return nrAreaTalhao * 27.225;
      case 2:
        return nrAreaTalhao * 10000;
      default:
        return nrAreaTalhao;
    }
  }

  retornaSalvarLinguagem() {
    return formatMessage('produtores.salvar');
  }

  retornaCancelarLinguagem() {
    return formatMessage('produtores.cancelar');
  }

  clickCancelar() {
    this.props.history.push('/app/produtores');
  }

  clickSalvar(dados) {
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: true,
        title: formatMessage(
          this.props.match.params.id === undefined ? 'produtores.salvando' : 'produtores.atualizando'
        )
      }
    );
    let values = {};

    let listAreaCultivoExcluidas = this.state.listAreaCultivoExcluidas;
    let listTalhoesExcluidos = this.state.listTalhoesExcluidos;
    let listColaboradoresDeleted = this.state.listColaboradoresDeleted;
    let listTemporariosDeleted = this.state.listTemporariosDeleted;
    let listProducaoAnimalDeleted = this.state.listProducaoAnimalDeleted;
    let listAnaliseRiscoDeleted = this.state.listAnaliseRiscoDeleted;

    values.tipoEcosocialList = this.state.tipoEcosocialList;

    if (dados.dsEmail) {
      values.dsEmail = dados.dsEmail.toLowerCase();
    } else {
      values.dsEmail = null;
    }

    values.nrDocumento = dados.nrDocumento;
    values.nrCar = dados.nrCar;
    values.nrDap = dados.nrDap;
    values.dhPrimeiraVisita = dados.dhPrimeiraVisita;
    values.dhCadastro = dados.dhCadastro;
    values.cdCertificadora = dados.cdCertificadora;
    values.areaCultivoList = this.state.dataAppSync.areaCultivoList.map(doc => {
      if (doc.tpArea === '') {
        doc.tpArea = null;
      }
      doc.dtValidadeContrato =
        doc.dtValidadeContrato === null
          ? doc.dtValidadeContrato
          : moment(doc.dtValidadeContrato).format('YYYY-MM-DD');
      return doc;
    });

    values.analiseRiscoList = this.state.dataAppSync.analiseRiscoList.map(doc => {
      doc.nrExperienciaOrganica = doc.nrExperienciaOrganica === '' ? 0 : +doc.nrExperienciaOrganica;
      doc.nrResultadoAnterior = doc.nrResultadoAnterior === '' ? 0 : +doc.nrResultadoAnterior;
      doc.nrPressaoPesticida = doc.nrPressaoPesticida === '' ? 0 : +doc.nrPressaoPesticida;
      doc.nrBarreiraLavoura = doc.nrBarreiraLavoura === '' ? 0 : +doc.nrBarreiraLavoura;
      doc.nrSuspeitaAplicacao = doc.nrSuspeitaAplicacao === '' ? 0 : +doc.nrSuspeitaAplicacao;
      doc.nrSafraColheita = doc.nrSafraColheita === '' ? 0 : +doc.nrSafraColheita;
      doc.nrRiscoTrocaProduto = doc.nrRiscoTrocaProduto === '' ? 0 : +doc.nrRiscoTrocaProduto;

      return doc;
    });
    values.colaboradorList = this.state.dataAppSync.colaboradorList || [];
    values.colaboradorTemporarioList = this.state.dataAppSync.colaboradorTemporarioList || [];
    values.producaoAnimalList = this.state.dataAppSync.producaoAnimalList.map(doc => {
      doc.dhLancamento = doc.dtLancamento;
      return doc;
    });

    values.talhaoList = this.state.dataAppSync.talhaoList.map(doc => {
      doc.talhaoTipoCertificacaoList.forEach(item => {
        if (item.stRegistro === 0) delete item.idTalhaoTipoCertificacao;
      });
      return {
        nrLatitude: doc.nrLatitude,
        nrLongitude: doc.nrLongitude,
        talhaoGpsList: doc.talhaoGpsList,
        areaCultivo: doc.areaCultivo,
        dsTalhao: doc.dsTalhao,
        nrAreaTalhao: doc.nrAreaTalhao,
        stRegistro: doc.stRegistro,
        idTalhao: doc.idTalhao,
        idPoligono: doc.idPoligono,
        dsFertilidade: doc.dsFertilidade,
        stTalhao: doc.stTalhao,
        stTalhaoGPS: doc.stTalhaoGPS,
        dsMotivoAlteracao: doc.dsMotivoAlteracao,
        tpCertificacaoOrganica: doc.tpCertificacaoOrganica || 0,
        tpUnidade: doc.tpUnidade,
        classificacao: this.props.infoUsuario.stOrganica === 1 ? doc.classificacao : null,
        talhaoTipoCertificacaoList: doc.talhaoTipoCertificacaoList || [],
        talhaoSituacaoList: doc.talhaoSituacaoList || []
      };
    });

    if (this.state.editar) {
      listColaboradoresDeleted.forEach(doc => {
        doc.stRegistro = 2;
        values.colaboradorList.push(doc);
      });

      listTemporariosDeleted.forEach(doc => {
        doc.stRegistro = 2;
        values.colaboradorTemporarioList.push(doc);
      });

      listTalhoesExcluidos.forEach(doc => {
        doc.stRegistro = 2;
        values.talhaoList.push(doc);
      });

      listAreaCultivoExcluidas.forEach(doc => {
        doc.stRegistro = 2;
        doc.dtValidadeContrato = doc.dtValidadeContrato
          ? moment(doc.dtValidadeContrato).format('YYYY-MM-DD')
          : null;
        values.areaCultivoList.push(doc);
      });

      listProducaoAnimalDeleted.forEach(doc => {
        values.producaoAnimalList.push(doc);
      });

      listAnaliseRiscoDeleted.forEach(doc => {
        values.analiseRiscoList.push(doc);
      });
    }

    values.cadastroAmbientalList = this.state.dataAppSync.cadastroAmbientalList;
    values.produtorAgenciaList = this.state.dataAppSync.agenciaList.map(item => {
      return {
        idProdutorAgencia: item.stRegistro === 0 ? null : item.idProdutorAgencia,
        agencia: item.agencia,
        dtInicio: moment(item.dtInicio).format('YYYY-MM-DD'),
        dtTermino: item.dtTermino ? moment(item.dtTermino).format('YYYY-MM-DD') : null,
        stRegistro: item.stRegistro
      };
    });

    values.familiarList = this.state.dataAppSync.familiarList;

    values.stProdutor = this.state.check === true ? 1 : 0;

    if (parseInt(this.state.dataUser.tpPerfil) === 2) {
      values.tecnico = this.state.tecnicos.find(tecnico => {
        return this.props.infoUsuario.dsEmail === tecnico.dsEmail;
      });
    } else {
      values.tecnico = this.valueAutoComplete;
    }

    values.municipio = this.valueAutoCompleteMunicipio;

    if (this.valueAutoCompleteComunidade !== '' && this.valueAutoCompleteComunidade !== null) {
      values.comunidade = this.state.itensComunidades.find(comunidade => {
        return comunidade.idComunidade === this.valueAutoCompleteComunidade.idComunidade;
      });
    } else {
      values.comunidade = null;
    }

    values.stProducaoParalela = this.state.stProducaoParalela ? 1 : 0;

    values.tpTecnologico = dados.tpTecnologico;
    values.stAgriculturaFamiliar = dados.stAgriculturaFamiliar;
    values.nmProdutor = dados.nmProdutor;
    if (dados.nrTelefone !== '') {
      values.nrTelefone = dados.nrTelefone;
    } else {
      values.nrTelefone = '';
    }

    values.stAssociacaoCooperativa = this.state.checkParticipacao ? 1 : 0;
    values.stConflitoLimitrofe = this.state.checkConflitos ? 1 : 0;
    values.dsAssociacaoCooperativa = dados.dsAssociacaoCooperativa;
    values.dsConflitoLimitrofe = dados.dsConflitoLimitrofe;
    values.dsFotoBase64 = this.state.itemFoto.stRegistro === 0 ? this.state.itemFoto.dsFotoBase64 : null;
    values.dsFoto = this.state.itemFoto.dsFoto;

    if (parseInt(this.props.match.params.pre) === 1) {
      values.idProdutor = parseInt(this.props.match.params.id);
      Produtor.efetivarPreCadastro(values)
        .then(() => {
          this.swalSucess('produtores.produtorEditadoComSucesso').then(() => {
            this.setState({ canLeave: true }, () => {
              if (this.locale !== '') {
                this.props.history.push(this.locale);
              } else {
                this.props.history.push('/app/produtores');
              }
            });
          });
        })
        .catch(err => {
          this.validarErros(err);
        });
    } else {
      if (this.state.editar) {
        values.idProdutor = this.state.dataAppSync.idProdutor;

        Produtor.updateProdutor(values)
          .then(() => {
            this.swalSucess('produtores.produtorEditadoComSucesso').then(() => {
              this.setState({ canLeave: true }, () => {
                if (this.locale !== '') {
                  this.props.history.push(this.locale);
                } else {
                  this.props.history.push('/app/produtores');
                }
              });
            });
          })
          .catch(err => {
            this.validarErros(err);
          });
      } else {
        Produtor.addProdutor(values)
          .then(query => {
            this.swalSucess('produtores.produtorCadastradoComSucesso').then(() => {
              this.setState({ canLeave: true }, () => {
                if (this.locale !== '') {
                  this.props.history.push(this.locale);
                } else {
                  this.props.history.push('/app/produtores');
                }
              });
            });
          })
          .catch(err => {
            this.validarErros(err);
          });
      }
    }
  }

  validarErros(err) {
    if (err.response !== undefined && err.response.data.codigo === 'AREA_TOTAL_NDVI_EXCEDIDA') {
      this.swalError('produtores.erroCriarPoligono');
      return;
    }

    if (err.response !== undefined && err.response.data.codigo === 'ERRO_CRIAR_POLIGONO') {
      this.swalError('produtores.erroCriarPoligono');
      return;
    }

    if (
      err.response !== undefined &&
      (err.response.data.codigo === 'LIMITE_PRODUTOR' || err.response.data.codigo === 'LIMITE_USUARIO')
    ) {
      this.swalError('produtores.numeroMaximoProdutores');
    } else if (err.response !== undefined && err.response.data.codigo === 'EMAIL_DUPLICADO') {
      this.swalError('produtores.emailJaCadastrado');
    } else {
      this.swalError('produtores.falhaAoCadastrarProdutor');
    }
  }

  swalSucess(title) {
    return swal(formatMessage(title), {
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    });
  }

  swalWarning(title, buttonOk, buttonCancel) {
    return swal({
      title: formatMessage(title),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: formatMessage(buttonCancel),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage(buttonOk),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    });
  }

  swalError(title) {
    swal(formatMessage(title), {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  handleChangeIndex(index, values) {
    this.state.itensTabs.forEach(doc => {
      if (index === doc.key) {
        doc.select = true;
      } else {
        doc.select = false;
      }
    });

    this.setState({ index });

    if (values && values.nmProdutor) {
      this.props.dispatch({
        type: 'UPDATE_DESCRICAO',
        descricao: ` - ${values.nmProdutor}`
      });
    }
  }

  onChangeProdutor(campo, e, callback, convertToNum = false) {
    callback(e);
    const aux = this.state.produtor;
    aux[campo] = convertToNum ? +e.target.value : e.target.value;
    this.setState({ produtor: aux });
  }

  onTryExit(location) {
    if (this.isDirty()) {
      this.swalWarning(
        'produtores.salvarProdutorSair',
        'produtores.salvar',
        'produtores.descartar'
      ).then(res => {
        swal.close();
        if (res) {
          this.locale = location.pathname;
          this.handleSubmit && this.handleSubmit();
        } else {
          this.setState(
            { canLeave: true },
            () => this.state.canLeave && this.props.history.push(location.pathname)
          );
        }
      });
      return false;
    }
    this.setState(
      { canLeave: true },
      () => this.state.canLeave && this.props.history.push(location.pathname)
    );
  }

  changeFAB() {
    this.setState({ showFabButton: !this.state.showFabButton });
  }

  saveTabItem(values, tabName) {
    var data = this.state.dataAppSync;
    data[tabName] = values;

    this.setState({
      dataAppSync: data
    });

    this.changeFAB();
  }

  saveAmbiental(values) {
    this.setState({
      listAmbiental: values
    });

    this.changeFAB();
  }

  producaoAnimalDeleted(item, data) {
    item.stRegistro = 2;

    var list = this.state.listProducaoAnimalDeleted;
    list.push(item);
    var dataAppSync = this.state.dataAppSync;
    dataAppSync.producaoAnimalList = data;

    this.setState({
      dataAppSync: dataAppSync,
      listProducaoAnimalDeleted: list
    });
  }

  analiseRiscoDeleted(item, data) {
    item.stRegistro = 2;

    var list = this.state.listAnaliseRiscoDeleted;
    list.push(item);
    var dataAppSync = this.state.dataAppSync;
    dataAppSync.analiseRiscoList = data;

    this.setState({
      listAnaliseRiscoDeleted: list,
      dataAppSync: dataAppSync
    });
  }

  saveFoto(item) {
    this.setState({
      itemFoto: item
    });
  }

  /**
   * Adiciona uma certifição orgânica
   *
   * @param {*} item - Objeto que vai ser adicionado
   */
  onAddEcosocial(item) {
    const newItensEcosocial = this.state.itensEcosocial.filter(
      ecosocial => ecosocial.idTipoEcosocial !== item.idTipoEcosocial
    );
    this.setState({
      tipoEcosocialList: [...this.state.tipoEcosocialList, item],
      itensEcosocial: newItensEcosocial
    });
    this.setFieldValue('ecosocial', '');
  }

  /**
   * Remove uma certificação orgânica
   *
   * @param {*} index - Index do item que vai ser removido
   */
  onRemoveEcosocial(index) {
    const newItensEcosocial = [
      ...this.state.itensEcosocial,
      this.initialEcosocialItens.find(
        item => item.idTipoEcosocial === this.state.tipoEcosocialList[index].idTipoEcosocial
      )
    ];
    newItensEcosocial.sort((a, b) => a.nmTipoEcosocial.localeCompare(b.nmTipoEcosocial));

    const ecosocialListValuesAux = [...this.state.tipoEcosocialList];
    ecosocialListValuesAux.splice(index, 1);

    this.setState({
      tipoEcosocialList: ecosocialListValuesAux,
      itensEcosocial: newItensEcosocial
    });
  }

  render() {
    const { classes } = this.props;
    var listColaboradoresResponsaveis = this.state.dataAppSync.colaboradorList.filter(doc => {
      return doc.stTomadaDecisao === 1;
    });

    return (
      <>
        <Prompt when={!this.state.canLeave} message={this.onTryExit} />
        <Formik
          initialValues={{
            estado: this.valueAutoCompleteEstado,
            nmProdutor: this.state.dataAppSync.nmProdutor || '',
            nrTelefone: this.state.dataAppSync.nrTelefone || '',
            comunidade: this.valueAutoCompleteComunidade,
            municipio: this.valueAutoCompleteMunicipio,
            uf:
              this.state.dataAppSync.comunidade !== undefined
                ? this.state.dataAppSync.comunidade.municipio.uf.sgUf
                : '',
            dsEmail: this.state.dataAppSync.dsEmail || '',
            nrDocumento: this.state.dataAppSync.nrDocumento || '',
            nrCar: this.state.dataAppSync.nrCar || '',
            nrDap: this.state.dataAppSync.nrDap || '',
            dhPrimeiraVisita: this.state.dataAppSync.dhPrimeiraVisita || null,
            dhCadastro: this.state.dataAppSync.dhCadastro || null,
            cdCertificadora: this.state.dataAppSync.cdCertificadora || '',
            tpTecnologico:
              this.state.dataAppSync.tpTecnologico !== undefined
                ? this.state.dataAppSync.tpTecnologico
                : '',
            stAgriculturaFamiliar:
              this.state.dataAppSync.stAgriculturaFamiliar !== undefined
                ? this.state.dataAppSync.stAgriculturaFamiliar
                : '',
            tecnicoResponsavel:
              this.valueAutoComplete !== '' &&
              this.valueAutoComplete !== undefined &&
              this.valueAutoComplete
                ? this.valueAutoComplete.nmUsuario
                : '',
            stProdutor: '',
            ecosocial:
              this.state.dataAppSync.ecosocial !== undefined ? this.state.dataAppSync.ecosocial : '',
            relacaoPredial: '',
            localArmazenamentoInsumos: '',
            maquinarioUtensilios: '',
            tomadaDecisao: '',
            atividadesDesenvolvidas: '',
            dsAssociacaoCooperativa: this.state.dataAppSync.dsAssociacaoCooperativa || '',
            dsConflitoLimitrofe: this.state.dataAppSync.dsConflitoLimitrofe || ''
          }}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={this.clickSalvar}
          validationSchema={Yup.object().shape({
            nmProdutor: Yup.string()
              .required(formatMessage('produtores.campoObrigatorio'))
              .test('test-name', ' ', function(value) {
                if (value && value.trim() && value.trim().split(' ').length < 2) {
                  const { path, createError } = this;
                  return createError({
                    path,
                    message: formatMessage('produtores.nomeCompleto')
                  });
                } else {
                  return true;
                }
              }),
            nrTelefone:
              getUserLang() === 'pt-BR'
                ? Yup.string()
                    .min(14, formatMessage('produtores.telefoneInvalido'))
                    .required(formatMessage('produtores.campoObrigatorio'))
                : Yup.string().required(formatMessage('produtores.campoObrigatorio')),
            municipio: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
            dsEmail: this.initialProdutor.dsEmail
              ? Yup.string()
                  .email(formatMessage('produtores.emailInvalido'))
                  .required(formatMessage('produtores.campoObrigatorio'))
              : Yup.string().email(formatMessage('produtores.emailInvalido')),
            nrCar: Yup.string(),
            nrDap: Yup.string(),
            dhPrimeiraVisita: Yup.date().nullable(),
            cdCertificadora: Yup.string(),
            dhCadastro: Yup.date()
              .nullable()
              .required(formatMessage('produtores.campoObrigatorio')),
            nrDocumento:
              getUserLang() === 'pt-BR'
                ? Yup.string().test(
                    '',
                    formatMessage(
                      this.state.dataAppSync.nrDocumento &&
                        this.state.dataAppSync.nrDocumento.length <= 14
                        ? 'produtores.cpfInvalido'
                        : 'produtores.cnpjInvalido'
                    ),
                    function(value) {
                      if (value !== undefined) {
                        if (value.length <= 14) {
                          return CPFValidator.validate(value);
                        } else {
                          return CNPJValidator.validate(value);
                        }
                      }

                      return true;
                    }
                  )
                : Yup.string(),
            tpTecnologico: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
            stAgriculturaFamiliar: Yup.string().required(formatMessage('produtores.campoObrigatorio')),
            estado: Yup.string()
              .nullable()
              .required(formatMessage('produtores.campoObrigatorio')),
            tecnicoResponsavel:
              parseInt(this.state.dataUser.tpPerfil) === 0 ||
              parseInt(this.state.dataUser.tpPerfil) === 1
                ? Yup.string().required(formatMessage('produtores.campoObrigatorio'))
                : Yup.string()
          })}
          render={({ values, errors, touched, handleChange, handleBlur, setFieldValue, submitForm }) => {
            this.handleSubmit = submitForm;
            this.setFieldValue = setFieldValue;

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                {this.state.loading && <Loading />}
                <Navigation
                  index={this.state.index}
                  handleChangeIndex={index => this.handleChangeIndex(index, values)}
                  buttons={this.state.itensTabs}
                >
                  {[
                    this.state.index === 0 ? (
                      <div key={0} className={classes.cardWithScroll}>
                        <MuiThemeProvider theme={theme}>
                          <Identificacao
                            stProducaoParalela={this.state.stProducaoParalela}
                            onChangeCheckProducaoParalela={() =>
                              this.setState({ stProducaoParalela: !this.state.stProducaoParalela })
                            }
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            estados={this.state.estados}
                            keyEstado={this.state.keyEstado}
                            validarInicializacaoAutoCompleteEstado={
                              this.validarInicializacaoAutoCompleteEstado
                            }
                            tratarValorSelectInitialEstado={this.tratarValorSelectInitialEstado}
                            valueAutoCompleteEstado={this.valueAutoCompleteEstado}
                            municipios={this.state.municipios}
                            handleBlurOwnMunicipio={this.handleBlurOwnMunicipio}
                            validarInicializacaoAutoCompleteMunicipio={
                              this.validarInicializacaoAutoCompleteMunicipio
                            }
                            tratarValorSelectInitialMunicipio={this.tratarValorSelectInitialMunicipio}
                            valueAutoCompleteMunicipio={this.valueAutoCompleteMunicipio}
                            keyMunicipio={this.state.keyMunicipio}
                            itensComunidades={this.state.itensComunidades}
                            handleBlurOwnComunidade={this.handleBlurOwnComunidade}
                            tratarValorComunidade={this.tratarValorComunidade}
                            tratarValorSelectInitialComunidade={this.tratarValorSelectInitialComunidade}
                            valueAutoCompleteComunidade={this.valueAutoCompleteComunidade}
                            tpPerfil={this.props.infoUsuario.tpPerfil}
                            disableEmail={
                              this.initialProdutor.dsEmail !== null &&
                              parseInt(this.props.match.params.pre) !== 1
                            }
                            itensNivel={this.state.itensNivel}
                            agriculturaFamiliarList={this.state.agriculturaFamiliarList}
                            stylesTecnico={{
                              display:
                                parseInt(this.state.dataUser.tpPerfil) === 0 ||
                                parseInt(this.state.dataUser.tpPerfil) === 1
                                  ? 'flex'
                                  : 'none'
                            }}
                            tecnicos={this.state.tecnicos}
                            handleBlurOwn={this.handleBlurOwn}
                            valueAutoCompleteTecnico={this.valueAutoComplete}
                            onChangeOwn={this.onChangeOwn}
                            validarInicializacaoAutoComplete={this.validarInicializacaoAutoComplete}
                            tratarValorSelectInitial={this.tratarValorSelectInitial}
                            keyTecnico={this.state.key}
                            checkSt={this.state.check}
                            onBlur={(e, item) => {
                              const data = this.state.dataAppSync;
                              data[item] = e.target.value;
                              this.setState({ dataAppSync: data });
                              this.onChangeProdutor(item, e, handleBlur);
                            }}
                            onChangeAutocompleteEstado={event => {
                              if (event) {
                                setFieldValue('estado', event.nmUf);
                              } else {
                                setFieldValue('estado', '');
                              }

                              this.valueAutoCompleteEstado = event;

                              event && this.validarInicializacaoAutoCompleteMunicipio(event.idUf);

                              const aux = this.state.produtor;
                              aux['estado'] = event;

                              this.setState({
                                produtor: aux,
                                keyEstado: Math.random(),
                                keyMunicipio: Math.random(),
                                keyComunidade: Math.random()
                              });
                              this.valueAutoCompleteMunicipio = '';
                              this.valueAutoCompleteComunidade = '';
                            }}
                            onChangeAutocompleteCidade={event => {
                              if (event) {
                                setFieldValue('municipio', event.nmMunicipio);
                              } else {
                                setFieldValue('municipio', '');
                              }

                              setFieldValue('comunidade', '');
                              this.valueAutoCompleteComunidade = '';

                              const aux = this.state.produtor;
                              aux['municipio'] = event;

                              this.setState({
                                produtor: aux,
                                keyComunidade: Math.random(),
                                itensComunidades: []
                              });
                              if (event) {
                                this.onChangeOwnMunicipio(event);
                              } else {
                                this.valueAutoCompleteMunicipio = '';
                              }
                            }}
                            onChangeAutocompleteComunidade={event => {
                              if (event) {
                                setFieldValue('comunidade', event.nmComunidade);
                              } else {
                                setFieldValue('comunidade', '');
                              }

                              const aux = this.state.produtor;
                              aux['municipio'] = event;

                              this.setState({ produtor: aux, keyComunidade: Math.random() });
                              this.onChangeOwnComunidade(event);
                            }}
                            onBlurDataCadastro={(value, dataToChange) => {
                              const data = this.state.dataAppSync;
                              data[dataToChange] = value;
                              this.setState({ dataAppSync: data });
                            }}
                            disabledDtCadastro={this.state.disabledDtCadastro}
                            onChangeCheckSt={() => this.setState({ check: !this.state.check })}
                            keyComunidade={this.state.keyComunidade}
                            edit={this.props.infoUsuario.tpPerfil === 2}
                            itemFoto={this.state.itemFoto}
                            saveFoto={this.saveFoto}
                            handleBlur={handleBlur}
                          />

                          {this.props.infoUsuario.stOrganica === 1 && (
                            <IdentificacaoOrganico
                              state={this.state}
                              tpPerfil={this.props.infoUsuario.tpPerfil}
                              onBlur={(value, item) => {
                                const data = this.state.dataAppSync;
                                data[item] = value.target.value;
                                this.setState({ dataAppSync: data });
                                this.onChangeProdutor(item, value, handleBlur);
                              }}
                              onAddEcosocial={this.onAddEcosocial}
                              onRemoveEcosocial={this.onRemoveEcosocial}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              errors={errors}
                              classes={classes}
                              handleBlur={handleBlur}
                              onChangeCheckParticipacao={() =>
                                this.setState({ checkParticipacao: !this.state.checkParticipacao })
                              }
                              checkParticipacao={this.state.checkParticipacao}
                              onChangeCheckConflitos={() =>
                                this.setState({ checkConflitos: !this.state.checkConflitos })
                              }
                              listColaboradoresResponsaveis={listColaboradoresResponsaveis}
                            />
                          )}
                        </MuiThemeProvider>
                      </div>
                    ) : (
                      <div key={0} />
                    ),
                    this.state.index === 1 ? (
                      <div key={1} className={classes.cardTabs}>
                        <AreasCultivo
                          converterMetrosToTpUnidade={this.converterMetrosToTpUnidade}
                          converterTpUnidadeToMetros={this.converterTpUnidadeToMetros}
                          changeFAB={this.changeFAB}
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          data={this.state.dataAppSync.areaCultivoList ? this.state.dataAppSync : []}
                          onClickDeleteAreaCultivo={this.onClickDeleteAreaCultivo}
                          onClickAddAreaCultivo={this.onClickAddAreaCultivo}
                          clickAdicionarAreaCultivo={this.clickAdicionarAreaCultivo}
                          maxIdAreaCultivo={this.state.maxIdAreaCultivo}
                          pais={this.props.infoUsuario.pais}
                          idProdutor={this.props.match.params.id}
                          municipio={this.valueAutoCompleteMunicipio || ''}
                          onClickCancelar={this.onClickCancelar}
                          selectAreaCultivo={this.state.selectAreaCultivo}
                          talhoesList={
                            this.state.dataAppSync.talhaoList ? this.state.dataAppSync.talhaoList : []
                          }
                          estadoList={this.state.estados}
                          municipioList={this.state.municipioListAreaCultivo}
                          comunidadeList={this.state.comunidadeListAreaCultivo}
                          tratarValorComunidade={this.tratarValorComunidadeAreaCultivo}
                          validarInicializacaoAutoCompleteEstado={
                            this.validarInicializacaoAutoCompleteEstado
                          }
                          validarInicializacaoAutoCompleteMunicipio={
                            this.validarAutoCompleteMunicioToAreaCultivo
                          }
                        />
                      </div>
                    ) : (
                      <div key={1} />
                    ),
                    this.state.index === 2 ? (
                      <div key={2} className={classes.cardTabs}>
                        <Talhoes
                          classificacaoList={this.state.classificacaoList}
                          certificacaoList={this.state.certificacaoList}
                          changeFAB={this.changeFAB}
                          onClickCancelar={this.onClickCancelar}
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          data={
                            this.state.dataAppSync.talhaoList ? this.state.dataAppSync.talhaoList : []
                          }
                          onClickDeleteTalhao={this.onClickDeleteTalhao}
                          areaCultivo={this.state.dataAppSync.areaCultivoList}
                          onClickAddTalhao={this.onClickAddTalhao}
                          maxIdTalhao={this.state.maxIdTalhao}
                          clickAdicionarTalhao={this.clickAdicionarTalhao}
                          pais={this.props.infoUsuario.pais}
                          municipio={this.valueAutoCompleteMunicipio}
                          idProdutor={this.props.match.params.id}
                          selectTalhao={this.state.selectTalhao}
                        />
                      </div>
                    ) : (
                      <div key={2} />
                    ),
                    this.state.index === 3 ? (
                      <div key={3} className={classes.cardTabs}>
                        <Familiares
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          data={this.state.dataAppSync.familiarList}
                          maxIdFake={this.state.maxIdFake}
                          onNew={this.onNewFamiliar}
                          onUpdate={this.onUpdateFamiliar}
                          onDelete={this.onDeleteFamiliar}
                          changeFAB={this.changeFAB}
                        />
                      </div>
                    ) : (
                      <div key={3} />
                    ),
                    this.state.index === 4 ? (
                      <div key={4} className={classes.cardTabs}>
                        <Colaboradores
                          changeFAB={this.changeFAB}
                          onSave={this.onClickAddColaborador}
                          data={this.state.dataAppSync.colaboradorList}
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          onDelete={this.onClickDeleteColaborador}
                        />
                      </div>
                    ) : (
                      <div key={4} />
                    ),
                    this.state.index === 5 ? (
                      <div key={5} className={classes.cardTabs}>
                        <Temporarios
                          changeFAB={this.changeFAB}
                          onSave={this.onClickAddTemporario}
                          data={this.state.dataAppSync.colaboradorTemporarioList}
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          onDelete={this.onClickDeleteTemporario}
                        />
                      </div>
                    ) : (
                      <div key={5} />
                    ),
                    this.state.index === 6 ? (
                      <div key={6} className={classes.cardTabs}>
                        <ProducaoAnimal
                          producaoAnimalDeleted={this.producaoAnimalDeleted}
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          saveProducaoAnimal={this.saveTabItem}
                          changeFAB={this.changeFAB}
                          data={this.state.dataAppSync.producaoAnimalList}
                          changeLista={newLista => {
                            const data = this.state.dataAppSync;
                            data.producaoAnimalList = newLista;
                            this.setState({ dataAppSync: data });
                          }}
                        />
                      </div>
                    ) : (
                      <div key={6} />
                    ),
                    this.state.index === 7 ? (
                      <div key={7} className={classes.cardTabs}>
                        <Ambiental
                          infoUsuario={this.props.infoUsuario}
                          tpPerfilTecnico={this.state.dataUser}
                          saveAmbiental={this.saveAmbiental}
                          changeFAB={this.changeFAB}
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          data={this.state.dataAppSync.cadastroAmbientalList}
                          onClickAddOrEdit={() => this.setState({ showFabButton: false })}
                          onCancel={() => this.setState({ showFabButton: true })}
                          maxIdFake={this.state.maxIdFake}
                          onNew={this.onNewAmbiental}
                          onUpdate={this.onUpdateAmbiental}
                          onDelete={this.onDeleteAmbiental}
                        />
                      </div>
                    ) : (
                      <div key={7} />
                    ),
                    this.state.index === 8 ? (
                      <div key={8} className={classes.cardTabs}>
                        <AnaliseDeRisco
                          infoUsuario={this.props.infoUsuario}
                          tpPerfilTecnico={this.state.dataUser}
                          newProdutor={this.props.match.params.id === undefined}
                          data={this.state.dataAppSync.analiseRiscoList}
                          analiseRiscoDeleted={this.analiseRiscoDeleted}
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          changeFAB={this.changeFAB}
                          talhoes={this.state.dataAppSync.talhaoList}
                          saveAnaliseRisco={this.saveTabItem}
                        />
                      </div>
                    ) : (
                      <div key={8} />
                    ),
                    this.state.index === 9 ? (
                      <div key={9} className={classes.cardTabs}>
                        <Agencia
                          keyAgencia={this.state.keyAgencia}
                          agenciaList={this.state.agenciaList || []}
                          theme={theme}
                          tpPerfil={this.props.infoUsuario.tpPerfil === 3}
                          classes={classes}
                          changeFAB={this.changeFAB}
                          data={this.state.dataAppSync.agenciaList || []}
                          onNew={this.onNewAgencia}
                          onUpdate={this.onUpdateAgencia}
                          onDelete={this.onDeleteAgencia}
                        />
                      </div>
                    ) : (
                      <div key={9} />
                    )
                  ]}
                </Navigation>

                {this.props.infoUsuario.tpPerfil !== 3 && this.state.showFabButton && (
                  <div className={classes.fab}>
                    <ButtonFABMenu
                      icon={iconMenuPontos}
                      actions={[
                        {
                          icon: <Check style={{ color: 'white' }} />,
                          name: this.retornaSalvarLinguagem(),
                          onClickIcon: values => {
                            this.handleChangeIndex(0);
                            this.setState(
                              {
                                index: 0
                              },
                              () => {
                                this.handleSubmit(values);
                              }
                            );
                          },
                          color: '#42ADE8',
                          disabled: this.state.error
                        },
                        {
                          icon: <Close style={{ color: 'white' }} />,
                          name: this.retornaCancelarLinguagem(),
                          onClickIcon: this.clickCancelar,
                          color: '#F33A30'
                        }
                      ]}
                    />
                  </div>
                )}
              </div>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarProdutor);
export default withRouter(connect(mapStateToProps)(enhaced));
