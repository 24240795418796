import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllCultivarsHandler() {
  return axios.get(api + '/cultivar');
}

function findCultivarById(idCultivar) {
  return axios.get(api + '/cultivar/' + idCultivar);
}

function deleteCultivarByIdHandler(idCultivar) {
  return axios.delete(api + '/cultivar/' + idCultivar);
}

function addCultivarHandler(values) {
  return axios.post(api + '/cultivar', values);
}

function editCultivarHandler(values) {
  return axios.put(api + '/cultivar', values);
}

function findCultivarByCulturaHandler(idCultivar) {
  return axios.get(api + '/cultivar/cultura/' + idCultivar);
}

export default {
  findAllCultivar: findAllCultivarsHandler,
  findCultivarById: findCultivarById,
  deleteCultivarById: deleteCultivarByIdHandler,
  addCultivar: addCultivarHandler,
  editCultivar: editCultivarHandler,
  findCultivarByCultura: findCultivarByCulturaHandler
};
