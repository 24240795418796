import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '@components/CardIcon/CardIcon';
import {Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText} from '@material-ui/core';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import formatMessage from './i18n/formatMessage';
import {
  findSancaoCategoriaById,
  addSancaoCategoria,
  editSancaoCategoria
} from '@resources/SancaoCategoria';
import { style, theme } from './SancaoCategoria.styles';

const iconMenuPontos = require('@images/icone_fab_menu.png');

/**
 * Screen de edição ou cadastro de uma sanção de categoria
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarSancaoCategoria(props) {
  const { classes } = props;

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState({
    nmSancaoCategoria: '',
    dsSancaoCategoria: '',
    sgSancaoCategoria: '',
    nrDiasMedidaCorretiva: 0,
    tpPenalidade: 0
  });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const title = 'sancaoCategoria.cadastroDeSancaoCategoria';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }

    if (props.match.params.id !== undefined) {
      setEnableReinitialize(true);
    }
  }, []);

  useEffect(() => {
    if (!enableReinitialize) return;
    setLoading(true);

    findSancaoCategoriaById(props.match.params.id)
      .then(res => setInitialValues(res.data))
      .catch(err =>
        swal(formatMessage('sancaoCategoria.erroAoBuscarDados'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        })
      )
      .finally(() => {
        setEnableReinitialize(false);
        setLoading(false);
      });
  }, [enableReinitialize]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize={enableReinitialize}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        nmSancaoCategoria: Yup.string().required(formatMessage('sancaoCategoria.campoObrigatorio')),
        dsSancaoCategoria: Yup.string().required(formatMessage('sancaoCategoria.campoObrigatorio')),
        nrDiasMedidaCorretiva: Yup.number().nullable().required(formatMessage('sancaoCategoria.campoObrigatorio')),
        sgSancaoCategoria: Yup.string().required(formatMessage('sancaoCategoria.campoObrigatorio')),
        tpPenalidade: Yup.number().nullable().required(formatMessage('sancaoCategoria.campoObrigatorio'))
      })}
      onSubmit={handleSubmitFormik}
      render={({ values, handleSubmit, errors, touched, handleBlur, handleChange }) => (
        <MuiThemeProvider theme={theme}>
          {isLoading && <Loading />}

          <div style={{ width: '100%' }}>
            <CardIcon titulo={formatMessage('sancaoCategoria.identificacao')}>
              <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
                <Grid item xs={4}>
                  <TextField
                    label={formatMessage('sancaoCategoria.nome')}
                    name='nmSancaoCategoria'
                    inputProps={{ maxLength: 60 }}
                    value={values.nmSancaoCategoria}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nmSancaoCategoria && touched.nmSancaoCategoria}
                    helperText={
                      errors.nmSancaoCategoria && touched.nmSancaoCategoria
                        ? errors.nmSancaoCategoria
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    label={formatMessage('sancaoCategoria.descricao')}
                    name='dsSancaoCategoria'
                    inputProps={{ maxLength: 200 }}
                    value={values.dsSancaoCategoria}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.dsSancaoCategoria && touched.dsSancaoCategoria}
                    helperText={
                      errors.dsSancaoCategoria && touched.dsSancaoCategoria
                        ? errors.dsSancaoCategoria
                        : null
                    }
                  />
                </Grid>
              </Grid>

              <Grid container  spacing={16} style={{ height: 70, marginTop: 30 }}>
                <Grid item xs={4}>
                  <TextField
                    label={formatMessage('sancaoCategoria.diasResolucao')}
                    name='nrDiasMedidaCorretiva'
                    type='number'
                    value={values.nrDiasMedidaCorretiva}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nrDiasMedidaCorretiva && touched.nrDiasMedidaCorretiva}
                    helperText={
                      errors.nrDiasMedidaCorretiva && touched.nrDiasMedidaCorretiva
                        ? errors.nrDiasMedidaCorretiva
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    label={formatMessage('sancaoCategoria.sigla')}
                    name='sgSancaoCategoria'
                    inputProps={{ maxLength: 2 }}
                    value={values.sgSancaoCategoria}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.sgSancaoCategoria && touched.sgSancaoCategoria}
                    helperText={
                      errors.sgSancaoCategoria && touched.sgSancaoCategoria
                        ? errors.sgSancaoCategoria
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    error={errors.tpPenalidade && touched.tpPenalidade}
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      htmlFor='tpPenalidade'
                      error={errors.tpPenalidade && touched.tpPenalidade}
                    >
                      {formatMessage('sancaoCategoria.tipoPenalidade')}
                    </InputLabel>

                    <Select
                      value={values.tpPenalidade}
                      error={errors.tpPenalidade && touched.tpPenalidade}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='tpPenalidade'
                      id='tpPenalidade'
                    >
                      {[
                        formatMessage('sancaoCategoria.nenhum'),
                        formatMessage('sancaoCategoria.desclassificacaoSafra'),
                        formatMessage('sancaoCategoria.desclassificacaoTalhao')
                      ].map((item, i) => (
                        <MenuItem key={i} value={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.tpPenalidade && touched.tpPenalidade && (
                      <FormHelperText>{errors.tpPenalidade}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </CardIcon>
          </div>

          <div className={classes.fab}>
            <ButtonFABMenu
              icon={iconMenuPontos}
              actions={[
                {
                  icon: <Check style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('sancaoCategoria.salvar'),
                  onClickIcon: handleSubmit,
                  color: '#42ADE8'
                },
                {
                  icon: <Close style={{ color: '#FFFFFF' }} />,
                  name: formatMessage('sancaoCategoria.cancelar'),
                  onClickIcon: () => props.history.push('/app/sancao-categoria'),
                  color: '#F33A30'
                }
              ]}
            />
          </div>
        </MuiThemeProvider>
      )}
    />
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {*} values - Valores do submit
   */
  function handleSubmitFormik(values) {
    setLoading(true);
    swal(
      <div>
        <MuiThemeProvider theme={theme}>
          <CircularProgress />
        </MuiThemeProvider>
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: formatMessage(
          `sancaoCategoria.${props.match.params.id === undefined ? 'salvando' : 'atualizando'}`
        )
      }
    );

    values.idSancaoCategoria = +props.match.params.id || null;

    let request;
    if (props.match.params.id === undefined) {
      request = addSancaoCategoria(values);
    } else {
      request = editSancaoCategoria(values);
    }

    request
      .then(res => {
        swal(formatMessage('sancaoCategoria.sancaoCategoriaSalvoComSucesso'), {
          icon: 'success',
          buttons: {
            confirm: { text: 'Ok', value: true, visible: true, closeModal: true, className: 'swal2-Ok' }
          }
        });

        props.history.push('/app/sancao-categoria');
      })
      .catch(err => {
        swal(formatMessage('sancaoCategoria.erroAoSalvar'), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });

        setLoading(false);
      });
  }
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(withStyles(style)(EditarSancaoCategoria)));
