export default {
  cronograma: {
    culturas: 'Culturas',
    sazonalidade: 'Sazonalidade',
    naoConformidade: 'Não conformidade',
    dosePorTanque: 'Dose por tanque para',
    alqueires: 'alqueires',
    aplicarNosTalhoes: 'Aplicar nos talhões:',
    paraDia: 'para dia',
    recomendacao: 'Recomendação de aplicação',
    aplicacao: 'Aplicação realizada',
    acre: 'Acre',
    hectare: 'Hectare',
    alqueire: 'Alqueire',
    areaDeCultivo: 'Área de cultivo',
    cultura: 'Cultura',
    plantio: 'Plantio',
    colheita: 'Colheita',
    alertasFitossanitarios: 'Alertas fitossanitários',
    monitoramento: 'Monitoramento de pragas',
    hoje: 'Hoje',
    talhoes: 'Talhões',
    registroFotografico: 'Registro fotográfico',
    registroClimatico: 'Registro climático',
    aplicacaoProdutoProibido: 'Aplicação de produto proibido',
    ocultarAtividades: 'Ocultar atividades do plantio',
    mostrarAtividades: 'Mostrar atividades do plantio',
    auditoria: 'Auditoria'
  }
};
