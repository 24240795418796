const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cardDate: {
    height: 40,
    width: 90,
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: 15,
    display: 'flex',
    paddingRight: 10,
    paddingLeft: 10
  },
  triangle: {
    marginRight: '-2px',
    width: 1,
    height: 1,
    position: 'relative',
    borderTop: 'none',
    borderRight: '10px solid transparent',
    borderLeft: '10px solid transparent',
    transform: 'rotate(270deg)'
  },
  styleDate: {
    fontSize: 16,
    color: '#AAAAAA',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  cardRecomendacoes: {
    width: '100%',
    borderRadius: 5,
    display: 'flex',
    padding: 10,
    justifyContent: 'center',
    flexDirection: 'row-reverse'
  },
  styleRecomendacoes: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: '500 !important',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  styleDosagens: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  margin: {
    width: 10,
    backgroundColor: '#BBDBFF',
    height: 140,
    position: 'inherit',
    display: 'flex'
  },
  icon: {
    display: 'flex',
    width: 18,
    height: 18,
    cursor: 'pointer'
  }
};

export { styles };
