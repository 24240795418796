//@author Katia Miglioli
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import iconeFABMenuClose from '@images/icone_fab_menu.png';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import '@styles/ButtonFABMenu.css';
import iconeFABMenuOpen from '@images/icone_add.png';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#00622B'
    }
  }
});

const styles = theme => ({
  controls: {
    margin: theme.spacing.unit * 3
  },
  exampleWrapper: {
    position: 'relative',
    height: 0
  },
  radioGroup: {
    margin: `${theme.spacing.unit}px 0`
  }
});

class ButtonFABMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleClickIcon = this.handleClickIcon.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.state = {
      open: false,
      hidden: false
    };
  }
  handleClickMenu() {
    this.setState(state => ({
      open: !state.open
    }));
  }
  handleClickIcon(action) {
    this.setState(state => ({
      open: !state.open
    }));
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    const { classes } = this.props;
    const { hidden, open } = this.state;
    const direction = this.props.direction;
    const actions = this.props.actions || [];
    const speedDialClassName = classNames(classes.speedDial);

    return (
      <div>
        <div className={classes.exampleWrapper} style={this.props.style}>
          <MuiThemeProvider theme={theme}>
            <SpeedDial
              ariaLabel='SpeedDial example'
              className={speedDialClassName}
              hidden={hidden}
              icon={
                <SpeedDialIcon
                  icon={<img src={iconeFABMenuClose} alt='' style={{ marginTop: '5px' }} />}
                  openIcon={<img src={iconeFABMenuOpen} alt='' id='openIcone' />}
                />
              }
              onBlur={this.handleClose}
              onClick={this.handleClickMenu}
              onClose={this.handleClose}
              onFocus={this.handleOpen}
              onMouseEnter={this.handleOpen}
              onMouseLeave={this.handleClose}
              open={open}
              direction={direction}
              style={{ width: '56px' }}
              ButtonProps={{
                disabled: this.props.disabled,
                color: 'primary'
              }}
            >
              {actions.map(action => (
                <SpeedDialAction
                  ButtonProps={{
                    style: action.disabled
                      ? {
                          display: action.hide ? 'none' : 'flex',
                          backgroundColor: '#95989A',
                          cursor: 'default'
                        }
                      : {
                          backgroundColor: action.color,
                          display: action.hide ? 'none' : 'flex'
                        },
                    disableRipple: action.disabled,
                    disableFocusRipple: action.disabled
                  }}
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.disabled ? '' : action.name}
                  onClick={() => {
                    if (!action.disabled) {
                      this.handleClickMenu();
                      action.onClickIcon();
                    }
                  }}
                />
              ))}
            </SpeedDial>
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

ButtonFABMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ButtonFABMenu);
