export default {
  inseticidaMicroBio: {
    inseticidaMicroBioRemovidoComSucesso: 'Inseticida microbiológico removido com sucesso',
    falhaAoExcluirInseticidaMicroBio: 'Falha ao excluir inseticida microbiológico',
    desejaExcluir: 'Deseja excluir o inseticida microbiológico selecionado?',
    inseticidaMicroBio: 'Inseticida microbiológico',
    identificacaoDaInseticidaMicroBio: 'Identificação',
    inseticidaMicroBioCadastradoComSucesso: 'Inseticida microbiológico cadastrado com sucesso',
    inseticidaMicroBioEditadoComSucesso: 'Inseticida microbiológico editado com sucesso',
    falhaAoEditarInseticidaMicroBio: 'Falha ao editar inseticida microbiológico',
    falhaAoCadastrarInseticidaMicroBio: 'Falha ao cadastrar inseticida microbiológico',
    falhaAoCarregarInseticidaMicroBio: 'Falha ao carregar inseticida microbiológico',
    cadastroDeInseticidaMicroBio: 'Cadastro de inseticida microbiológico'
  }
};
