export default {
  oleosAdjuvantes: {
    oleosAdjuvantesRemovidoComSucesso: 'Óleos e adjuvantes removido com sucesso',
    falhaAoExcluirOleosAdjuvantes: 'Falha ao excluir óleos e adjuvantes',
    desejaExcluir: 'Deseja excluir óleos e adjuvantes selecionado?',
    oleosAdjuvantes: 'Óleos e adjuvantes',
    cadastroDeOleosAdjuvantes: 'Cadastro de óleos e adjuvantes',
    identificacaoDaOleosAdjuvantes: 'Identificação',
    oleosAdjuvantesCadastradoComSucesso: 'Óleos e adjuvantes cadastrado com sucesso',
    oleosAdjuvantesEditadoComSucesso: 'Óleos e adjuvantes editado com sucesso',
    falhaAoEditarOleosAdjuvantes: 'Falha ao editar óleos e adjuvantes',
    falhaAoCadastrarOleosAdjuvantes: 'Falha ao cadastrar óleos e adjuvantes',
    falhaAoCarregarOleosAdjuvantes: 'Falha ao carregar óleos e adjuvantes'
  }
};
