import URL from './URL';
// eslint-disable-next-line
import axios, { AxiosResponse } from 'axios';

/**
 * Chama o endpoint para retornar uma lista de Área de Cultivo filtrando pelo ID do Produtor
 *
 * @param {Number} idProdutor - ID do Produtor
 * @returns {Promise<AxiosResponse<AreaCultivo[]>>} Uma Promise com a lista de Área de Cultivo
 */
async function findAreaCultivoByProdutor(idProdutor) {
  return await axios.get(`${URL.API}/produtor/area-cultivo/${idProdutor}`);
}

export { findAreaCultivoByProdutor };
