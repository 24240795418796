import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import CardIcon from '@components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Safra from '@resources/Safra';
import Cultura from '@resources/Cultura';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import DatePicker from '@components/DatePicker/DatePicker';
import * as moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@components/Chip/Chip';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});

const style = () => ({
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  card: {
    width: '100%',
    height: '100%'
  },
  cardGrid: {
    width: '100%',
    height: '100%',
    marginTop: 23
  },
  font: {
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente de edição de safra
 *
 * @author Gabriela Farias
 * @class EditarSafra
 * @extends {Component} - Componente React
 */
class EditarSafra extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const safraCadastradaComSucesso = messagesLinguage['safras.safraCadastradaComSucesso'];
    const safraEditadaComSucesso = messagesLinguage['safras.safraEditadaComSucesso'];
    const falhaAoEditarSafra = messagesLinguage['safras.falhaAoEditarSafra'];
    const falhaAoCadastrarSafra = messagesLinguage['safras.falhaAoCadastrarSafra'];
    const falhaAoCarregarSafra = messagesLinguage['safras.falhaAoCarregarSafra'];
    const salvar = messagesLinguage['safras.salvar'];
    const cancelar = messagesLinguage['safras.cancelar'];
    const salvando = messagesLinguage['safras.salvando'];
    const atualizando = messagesLinguage['safras.atualizando'];
    const campoObrigatorio = messagesLinguage['safras.campoObrigatorio'];
    const identificacao = messagesLinguage['safras.identificacao'];
    const descricao = messagesLinguage['safras.descricao'];
    const cultura = messagesLinguage['safras.cultura'];
    const dataInicial = messagesLinguage['safras.dataInicial'];
    const dataFinal = messagesLinguage['safras.dataFinal'];
    const safraCadastradaPeriodo = messagesLinguage['safras.safraCadastradaPeriodo'];
    const minimoCulturas = messagesLinguage['safras.minimoCulturas'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          safraCadastradaComSucesso,
          safraEditadaComSucesso,
          falhaAoEditarSafra,
          falhaAoCadastrarSafra,
          falhaAoCarregarSafra,
          salvar,
          cancelar,
          salvando,
          atualizando,
          campoObrigatorio,
          identificacao,
          descricao,
          cultura,
          dataInicial,
          dataFinal,
          safraCadastradaPeriodo,
          minimoCulturas
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      culturasSelecteds: [],
      allCulturas: [],
      listCultura: [],
      keyAutoComplete: Math.random(),
      cultura: null,
      checked: true,
      dataAppSync: {
        idSafra: '',
        dsSafra: '',
        cultura: {},
        dtInicio: null,
        dtTermino: null,
        stSafra: true
      },
      loading: this.props.match.params.id !== undefined,
      idSafra: ''
    };

    const title = 'safras.cadastroDeSafra';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.clickCadastrarEditar = this.clickCadastrarEditar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.iniciarAtributosEdicao = this.iniciarAtributosEdicao.bind(this);
    this.iniciarAtributosCultura = this.iniciarAtributosCultura.bind(this);
    this.onChangeCultura = this.onChangeCultura.bind(this);
    this.tratarValorCultura = this.tratarValorCultura.bind(this);
    this.onAddCulturas = this.onAddCulturas.bind(this);
    this.syncCulturasVisibles = this.syncCulturasVisibles.bind(this);
    this.onRemoveChip = this.onRemoveChip.bind(this);
  }

  componentDidMount() {
    this.iniciarAtributosCultura();

    if (this.props.match.params.id !== undefined) {
      this.iniciarAtributosEdicao();
    }
  }

  iniciarAtributosCultura() {
    Cultura.findAllCultura()
      .then(query => {
        this.setState({
          listCultura: query.data,
          allCulturas: query.data
        });
      })
      .catch(() => this.setState({ listCultura: [] }));
  }

  iniciarAtributosEdicao() {
    Safra.findSafrasById(this.props.match.params.id)
      .then(query => {
        this.setState(
          {
            loading: false,
            dataAppSync: query.data,
            culturasSelecteds: query.data.culturaList,
            keyAutoComplete: Math.random(),
            idSafra: query.data.idSafra,
            checked: query.data.stSafra === 1
          },
          this.syncCulturasVisibles
        );
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        swal(provider.intl.formatMessage({ id: 'falhaAoCarregarSafra' }), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  clickCadastrarEditar(values) {
    if (!this.state.validarInputs) {
      const errorConfig = {
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-error'
          }
        }
      };

      if (this.state.culturasSelecteds.length === 0) {
        swal(provider.intl.formatMessage({ id: 'minimoCulturas' }), errorConfig);
        return;
      }

      swal(
        <div>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>,
        {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          title: provider.intl.formatMessage({
            id: this.props.match.params.id === undefined ? 'salvando' : 'atualizando'
          })
        }
      );

      const data = {
        idSafra: values.idSafra,
        dsSafra: values.dsSafra,
        culturaList: this.state.culturasSelecteds,
        dtInicio: moment(values.dtInicio).format('YYYY-MM-DD'),
        dtTermino: moment(values.dtTermino).format('YYYY-MM-DD'),
        stSafra: this.state.checked ? 1 : 0
      };

      if (this.props.match.params.id === undefined) {
        Safra.addSafras(data)
          .then(query => {
            swal(
              provider.intl.formatMessage({
                id: 'safraCadastradaComSucesso'
              }),
              {
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                }
              }
            ).then(() => {
              this.clickCancelar();
            });
          })
          .catch(err => {
            if (err.response && err.response.data.codigo === 'SAFRA_DUPLICADA') {
              swal({
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                },
                closeOnClickOutside: false,
                closeOnEsc: false,
                icon: 'warning',
                title: provider.intl.formatMessage({ id: 'safraCadastradaPeriodo' })
              });
            } else {
              swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarSafra' }), {
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-error'
                  }
                }
              });
            }
          });
      } else {
        data.idSafra = this.props.match.params.id;

        Safra.editSafras(data)
          .then(query => {
            swal(provider.intl.formatMessage({ id: 'safraEditadaComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              this.clickCancelar();
            });
          })
          .catch(err => {
            if (err.response && err.response.data.codigo === 'SAFRA_DUPLICADA') {
              swal({
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                },
                closeOnClickOutside: false,
                closeOnEsc: false,
                icon: 'warning',
                title: provider.intl.formatMessage({ id: 'safraCadastradaPeriodo' })
              });
            } else {
              swal(provider.intl.formatMessage({ id: 'falhaAoEditarSafra' }), {
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-error'
                  }
                }
              });
            }
          });
      }
    }
  }

  clickCancelar() {
    this.props.history.push('/app/safras');
  }

  onChangeCultura(cultura) {
    if (cultura) {
      this.setState({
        cultura: cultura
      });
    } else {
      this.setState({ cultura: {} });
    }
  }

  tratarValorCultura() {
    if (!this.state.cultura || this.state.cultura.idCultura === undefined) {
      return '';
    }

    return this.state.cultura;
  }

  onAddCulturas() {
    if (!this.state.cultura) return;

    const cultura = this.state.listCultura.find(item => item.idCultura === this.state.cultura.idCultura);

    const auxList = [...this.state.culturasSelecteds];
    auxList.push(cultura);

    this.setState(
      { culturasSelecteds: auxList },
      this.syncCulturasVisibles,
      this.setState({
        cultura: '',
        keyAutoComplete: Math.random()
      })
    );
  }

  syncCulturasVisibles() {
    this.setState(state => ({
      listCultura: state.allCulturas.filter(
        item => !state.culturasSelecteds.find(subItem => subItem.idCultura === item.idCultura)
      )
    }));
  }

  onRemoveChip(index) {
    const auxList = [...this.state.culturasSelecteds];
    auxList.splice(index, 1);
    this.setState({ culturasSelecteds: auxList }, this.syncCulturasVisibles);
  }

  render() {
    const { classes } = this.props;
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Formik
          initialValues={{
            dsSafra: this.state.dataAppSync.dsSafra,
            dtInicio: this.state.dataAppSync.dtInicio,
            dtTermino: this.state.dataAppSync.dtTermino
          }}
          onSubmit={this.clickCadastrarEditar}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            dsSafra: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
            dtInicio: Yup.string()
              .nullable()
              .required(provider.intl.formatMessage({ id: 'campoObrigatorio' })),
            dtTermino: Yup.string()
              .nullable()
              .required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleChange
          }) => (
            <MuiThemeProvider theme={theme}>
              <form className={classes.card} autoComplete='off'>
                {this.state.loading && <Loading />}
                <CardIcon titulo={provider.intl.formatMessage({ id: 'identificacao' })}>
                  <div className={classes.cardGrid}>
                    <Grid container spacing={16}>
                      <Grid item xs={3}>
                        <TextField
                          label={provider.intl.formatMessage({ id: 'descricao' })}
                          name='dsSafra'
                          value={values.dsSafra}
                          onChange={handleChange}
                          onBlur={value => {
                            setFieldValue('dsSafra', value.target.value);
                            let data = this.state.dataAppSync;
                            data.dsSafra = value.target.value;
                          }}
                          error={errors.dsSafra && touched.dsSafra}
                          helperText={errors.dsSafra && touched.dsSafra ? errors.dsSafra : null}
                          style={{ width: '100%', height: 70 }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <DatePicker
                          maxDate={values.dtTermino ? values.dtTermino : '2100-01-01'}
                          locale={getUserLang()}
                          style={{ height: 70 }}
                          className={classes.font}
                          label={provider.intl.formatMessage({
                            id: 'dataInicial'
                          })}
                          valueDate={values.dtInicio}
                          errorTouched={errors.dtInicio && touched.dtInicio}
                          helperText={errors.dtInicio && touched.dtInicio ? errors.dtInicio : null}
                          onBlurDatePicker={value => {
                            setFieldValue('dtInicio', value);
                          }}
                          onChangeDatePicker={value => {
                            setFieldValue('dtInicio', value);

                            let data = this.state.dataAppSync;
                            data.dtInicio = value;
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <DatePicker
                          minDate={values.dtInicio ? values.dtInicio : '1900-01-01'}
                          locale={getUserLang()}
                          style={{ height: 70 }}
                          className={classes.font}
                          label={provider.intl.formatMessage({
                            id: 'dataFinal'
                          })}
                          valueDate={values.dtTermino}
                          errorTouched={errors.dtTermino && touched.dtTermino}
                          helperText={errors.dtTermino && touched.dtTermino ? errors.dtTermino : null}
                          onBlurDatePicker={value => {
                            setFieldValue('dtTermino', value);
                          }}
                          onChangeDatePicker={value => {
                            setFieldValue('dtTermino', value);

                            let data = this.state.dataAppSync;
                            data.dtTermino = value;
                          }}
                        />
                      </Grid>

                      <Grid item xs={1}>
                        <div
                          className={classes.switch}
                          onClick={() => this.setState({ checked: !this.state.checked })}
                        >
                          <Checkbox checked={this.state.checked} value='stSafra' color='primary' />
                          <label style={{ color: '#000000' }}>
                            <FormattedMessage id='safras.ativo' />
                          </label>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={16}>
                      <Grid item xs={3}>
                        <Autocomplete
                          itens={this.state.listCultura}
                          campoOp='nmCultura'
                          campoChave='idCultura'
                          label={provider.intl.formatMessage({ id: 'cultura' })}
                          name='cultura'
                          error={errors.cultura && touched.cultura}
                          helperText={errors.cultura && touched.cultura ? errors.cultura : null}
                          onChangeOwn={this.onChangeCultura}
                          value={this.tratarValorCultura()}
                          key={this.state.keyAutoComplete}
                          valueAutoComplete={this.tratarValorCultura()}
                          valueSelect={this.tratarValorCultura().nmCultura}
                        />
                      </Grid>

                      <Grid item xs={1} style={{ paddingLeft: 15 }}>
                        <IconButton
                          aria-label='ADD'
                          style={{ height: 50, width: 50, marginTop: 5 }}
                          disabled={this.state.loading || !this.state.cultura}
                          onClick={this.onAddCulturas}
                        >
                          <AddIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid container style={{ minHeight: 70 }}>
                      <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
                        {this.state.culturasSelecteds.map((chip, i) => (
                          <Chip
                            key={i}
                            text={chip.nmCultura}
                            tpPerfil
                            onRemove={() => this.onRemoveChip(i)}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </div>
                </CardIcon>
                <div className={classes.fab}>
                  <ButtonFABMenu
                    icon={iconMenuPontos}
                    actions={[
                      {
                        icon: <Check style={{ color: '#FFFFFF' }} />,
                        name: provider.intl.formatMessage({ id: 'salvar' }),
                        onClickIcon: handleSubmit,
                        color: '#42ADE8'
                      },
                      {
                        icon: <Close style={{ color: '#FFFFFF' }} />,
                        name: provider.intl.formatMessage({ id: 'cancelar' }),
                        onClickIcon: this.clickCancelar,
                        color: '#F33A30'
                      }
                    ]}
                    onClickButton={this.clickAdicionar}
                  />
                </div>
              </form>
            </MuiThemeProvider>
          )}
        />
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarSafra);
export default withRouter(connect(mapStateToProps)(enhaced));
