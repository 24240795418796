export default {
  insumo: {
    descricao: 'Descripción',
    nao: 'No',
    excluir: 'Borrar',
    excluindo: 'Borrado',
    salvar: 'Guardar',
    cancelar: 'Cancelar',
    salvando: 'Guardalización',
    atualizando: 'Actualización',
    campoObrigatorio: 'Campo obligatorio',
    marca: 'Marca',
    naoOrganico: 'No orgánico',
    certificacaoOrganica: 'Tipo de certificación orgánica',
    identificacao: 'Identificación',
    certificacao: 'Certificación',
    historico: 'Histórico',
    historicoCertificacao: 'Historial de certificaciones',
    validadeDe: 'Validez de',
    validadeAte: 'Vigencia hasta',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    cadastrar: 'Registrar',
    certificadoExistente: 'Ya hay certificado en ese periodo',
    indefinida: 'Indefinido',
    ativo: 'Activo',
    inativo: 'Inactivo',
    status: 'Situación',
    inativarDataFinal: 'Los certificados inactivos deben contener una fecha de finalización',
    nenhumaCertificacaoAtiva: 'Sin certificación activa'
  }
};
