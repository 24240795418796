export default {
  naoConformidade: {
    descricao: 'Description',
    dataCadastro: 'Registration date',
    erroAoBuscarDados: 'Error to fetch data',
    naoConformidade: 'Non-compliance',
    desejaExcluirNaoConformidade: 'Do you want to delete this non-compliance',
    desejaExcluirMedidaCorretiva: '',
    nao: 'No',
    excluir: 'Delete',
    naoConformidadeExcluida: 'Non-compliance successfully deleted',
    erroAoExcluir: 'Error to delete non-compliance',
    cadastroDeNaoConformidade: 'Organic non-compliance registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    salvar: 'Salve',
    cancelar: 'Cancel',
    naoConformidadeSalvoComSucesso: 'Non-compliance successfully saved',
    erroAoSalvar: 'Error to save non-compliance',
    salvando: 'Saving',
    atualizando: 'Updating',
    produtorRelacionado:
      'It was not possible to exclude this non-compliance because there is a producer using it',
    status: 'Status',
    ativo: 'Active',
    inativo: 'Inative',
    nome: 'Name',
    diasResolucao: 'Days for resolution',
    sigla: 'Initials',
    tipoPenalidade: 'Penalty type',
    nenhum: 'None',
    desclassificacaoSafra: 'Crop disqualification',
    desclassificacaoTalhao: 'Field declassification',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    sancao: 'Sanction',
    certificacao: 'Certification',
    cadastrarSancao: 'Register sanction',
    editar: 'Edit',
    medidasCorretivas: 'Corrective measures',
    medidaCorretiva: 'Corrective measure',
    geral: 'General',
    cadastroGeralExistente:
      'It is only possible to have a general sanction, or else it must be by certification',
    ok: 'Ok'
  }
};
