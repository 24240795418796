import { createMuiTheme } from '@material-ui/core/styles';

const style = themeStyle => ({
  divTitulo: {
    height: '100%',
    fontSize: 18,
    color: '#666666',
    marginLeft: 65,
    flex: 25,
    marginBottom: '20px'
  },
  textField: {
    width: '100%',
    height: '70px'
  },
  cadastro: {
    width: '100%',
    display: 'flex'
  },
  fab: {
    position: 'fixed',
    bottom: themeStyle.spacing.unit * 27.5,
    right: themeStyle.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1000,
    marginBottom: 0
  },
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 30
  },
  font: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  form: {
    width: '100%',
    margin: 0,
    height: 70
  },
  cardTabs: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 80px)'
  },
  paddingGrid: {
    padding: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.8)'
      }
    },
    MuiInput: {
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        writeSpace: 'noWrap',
        textOverflow: 'ellipsis'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.4
        }
      }
    },
    MuiInputBase: {
      input: {
        fontSize: 14
      }
    },
    MuiInputLabel: {
      animated: {
        fontSize: 14
      }
    }
  }
});

export { style, theme };
