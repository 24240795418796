export default {
  homeopatico: {
    homeopaticoRemovidoComSucesso: 'Homeopathic products successfully removed',
    falhaAoExcluiHomeopatico: 'Failed to exclude homeopathic products',
    desejaExcluir: 'Do you want to delete the selected homeopathic products?',
    homeopatico: 'Homeopathic products',
    cadastroDeHomeopatico: 'Homeopathic products registration',
    identificacaoDaHomeopatico: 'Identification',
    homeopaticoCadastradoComSucesso: 'Homeopathic products successfully registered',
    homeopaticoEditadoComSucesso: 'Homeopathic products edited successfully',
    falhaAoEditarHomeopatico: 'Failed to edit homeopathic products',
    falhaAoCadastrarHomeopatico: 'Failed to register homeopathic products',
    falhaAoCarregarHomeopatico: 'Failed to load homeopathic products'
  }
};
