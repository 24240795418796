//@author Katia Miglioli
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Logo from '@images/logo_azagros.png';
import Rodape from '@images/borda_inferior_logo.png';
import CardContent from '@material-ui/core/CardContent';

const Styles = theme => ({
  card: {
    display: 'flex',
    width: 320,
    height: 147,
    backgroundColor:'#E6F8FD',
    flex:1,
    flexDirection: 'column',
    borderRadius:10
  },
  rodape:{
    marginLeft:'-25px'
  },
  logo:{
    marginLeft:'15px',
    width: 250,
    height: 98,
  }
});

function CabecalhoLogin(props) {
  const { classes} = props;
  return (
    <Card className={classes.card}>
      <CardContent>
        <img src={Logo} className={classes.logo} alt="Logomarca Azagros"/>
        <img src={Rodape} className={classes.rodape} alt="Logomarca Azagros"/>
      </CardContent>
    </Card>
  );
}

export default withStyles(Styles, { withTheme: true })(CabecalhoLogin);
