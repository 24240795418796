export default {
  cultivares: {
    cultivares: 'Cultivares',
    detentor: 'Holder',
    cadastroCultivar: 'Registro de cultivar',
    codigo: 'Código',
    descricao: 'Descripción',
    cultura: 'Cultura',
    situacao: 'Situación',
    desejaExcluirCultivar: '¿Desea eliminar el cultivar seleccionado?',
    cultivarRemovidoComSucesso: 'Cultivar eliminado con éxito',
    nao: 'No',
    excluir: 'Borrar',
    excluindo: 'Excluyendo',
    falhaAoExcluirCultivar: 'No se puede eliminar el cultivar',
    ativo: 'Activo',
    inativo: 'Inactivo',
    campoObrigatorio: 'Campo obligatorio',
    identificacaoDetentor: 'Identificación',
    gravar: 'Registro',
    cancelar: 'Cancelar',
    cultivar: 'Cultivar',
    atualizando: 'Actualización',
    salvando: 'Ahorro',
    cultivarCadastradoComSucesso: 'Cultivar el registro con éxito',
    cultivarEditadoComSucesso: 'Cultivar editado con éxito',
    erro: 'Error',
    salvar: 'Guardar',
    falhaAoCadastrarCultivar: 'Falla al registrar cultivar',
    falhaAoEditarCultivar: 'Falla al editar el cultivar',
    confirmar: 'Confirmar',
    salvarCultivarSair: 'Hay información no guardada, ¿quieres salvar el cultivar?',
    descartar: 'Descartar'
  }
};
