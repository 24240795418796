export default {
  relatorio: {
    relatorio: 'Report',
    relatorioProdutoresPotencial: 'Potential producers report',
    regiao: 'Region',
    municipio: 'City',
    conhecimentoOrganico: 'Knowledge of organic production',
    sim: 'Yes',
    nao: 'Not',
    atividadeGraos: 'Grain activity',
    carregando: 'Loading...',
    gerandoRelatorio: 'Generating report ...',
    falhaAoGerarRelatorio: 'Failed to generate report',
    decrescente: 'Decreasing',
    crescente: 'Crescent',
    produtor: 'Producer',
    uf: 'UF'
  }
};
