import ptBR from './ptBR';
import enUS from './enUS';
import esES from './esES';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { flattenMessages } from '@utils/languageUtils';

/**
 * Retorna o INTL Provider com todas as mensagens de todas as traduções
 *
 * @author Bruno Eduardo
 * @returns {IntlProvider} INTL Provider
 */
function getIntlProvider() {
  const messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

  const messagesObject = flattenMessages(messages[getUserLang()]);

  const intlProvider = new IntlProvider({ locale: getUserLang(), messages: messagesObject }, {});

  return intlProvider.getChildContext();
}

/**
 * Retorna uma mensagem pré-definida intercionalizada de acordo com o ID
 *
 * @author Bruno Eduardo
 * @param {String} idMessage - ID da mensagem
 * @returns {String} Mensagem intercionalizada
 */
function formatMessage(idMessage) {
  const provider = getIntlProvider();
  return provider.intl.formatMessage({ id: idMessage });
}

export default formatMessage;
