export default {
  bactericidas: {
    bactericidas: 'Bactericidas',
    cadastroDeBactericidas: 'Registro bactericidas',
    bactericidasRemovidoComSucesso: 'Bactericida quitado con éxito',
    falhaAoExcluirbactericidas: 'Fallo la excluir bactericida',
    desejaExcluir: '¿Desea eliminar el bactericida seleccionada?',
    identificacaoDaBactericidas: 'Identificación',
    bactericidasCadastradoComSucesso: 'Bactericida registrada con éxito',
    bactericidasEditadoComSucesso: 'Bactericida editado con éxito',
    falhaAoEditarBactericidas: 'Falha ao editar bactericida',
    falhaAoCadastrarBactericidas: 'Error al editar bactericida',
    falhaAoCarregarBactericidas: 'Error al cargar bactericida'
  }
};
