import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import formatMessage from './../i18n/formatMessage';
import Modal from '@material-ui/core/Modal';
import CardTitle from '@components/CardTitle/CardTitle';
import Input from '@material-ui/core/Input';
import { connect } from 'react-redux';
import DatePicker from '@components/DatePicker/DatePicker';
import moment from 'moment';
import { getUserLang } from '@utils/localeUtils';
import Autocomplete from '@components/Autocomplete/Autocomplete';

const style = theme => ({
  cadastro: {
    marginTop: '15px'
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  }
});

/**
 * Componente de identificação de talhões
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {Object} props - props
 * @returns Componente React
 */
const Identificacao = props => {
  const {
    classes,
    itemSelect,
    modal,
    areaCultivoList,
    changeMap,
    handleClose,
    changeStateItemSelect,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    handleSubmit,
    clickChangeStTalhao,
    changeStTalhao,
    onChangeClassificacaoAutoComplete,
    tratarValorSelectInitialClassificacao,
    valueAutoCompleteClassificacao,
    classificacaoList,
    keyClassificacao,
    clickAtivarTalhao,
    stAtivouTalhao
  } = props;
  const situacaoEnum = [
    { id: 0, name: formatMessage('produtores.inativo') },
    { id: 1, name: formatMessage('produtores.ativo') }
  ];

  const stOrganica = props.infoUsuario.stOrganica === 1;

  return (
    <form autoComplete='off'>
      <Grid container spacing={16}>
        <Grid item xs={3} style={{ marginTop: stOrganica ? 1 : 0 }}>
          <FormControl className={classes.form} error={errors.areaCultivo && touched.areaCultivo}>
            <InputLabel
              htmlFor='areaCultivo-helper'
              error={errors.areaCultivo && touched.areaCultivo}
              className={classes.font}
            >
              {formatMessage('produtores.areaDeCultivo')}
            </InputLabel>

            <Select
              style={{
                marginLeft: 0,
                fontSize: 14,
                width: '100%',
                marginTop: 14
              }}
              SelectDisplayProps={{
                style: {
                  backgroundColor: 'transparent'
                }
              }}
              value={values.areaCultivo}
              onChange={value => {
                itemSelect.areaCultivo = areaCultivoList.find(area => {
                  return value.target.value === area.idAreaCultivo;
                });

                setFieldValue('areaCultivo', value.target.value);

                changeMap(value.target.value);
              }}
              error={errors.areaCultivo && touched.areaCultivo}
              displayEmpty
              inputProps={{
                name: 'areaCultivo',
                id: 'areaCultivo-helper'
              }}
            >
              {areaCultivoList.map(area => (
                <MenuItem
                  value={area.idAreaCultivo}
                  key={areaCultivoList.indexOf(area)}
                  className={classes.font}
                >
                  {area.dsAreaCultivo}
                </MenuItem>
              ))}
            </Select>

            {errors.areaCultivo && touched.areaCultivo && (
              <FormHelperText> {errors.areaCultivo} </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={stOrganica ? 5 : 3}>
          <TextField
            id='dsTalhao'
            name='dsTalhao'
            onBlur={handleBlur}
            onChange={value => {
              itemSelect.dsTalhao = value.target.value;

              setFieldValue('dsTalhao', value.target.value);
            }}
            inputProps={{
              maxLength: 60
            }}
            value={values.dsTalhao}
            label={formatMessage('produtores.descricao')}
            className={classes.textField}
            error={errors.dsTalhao && touched.dsTalhao}
            helperText={touched.dsTalhao ? errors.dsTalhao : ''}
          />
        </Grid>

        {stOrganica && (
          <Grid item xs={2}>
            <TextField
              id='dsFertilidade'
              name='dsFertilidade'
              onBlur={handleBlur}
              onChange={value => {
                itemSelect.dsFertilidade = value.target.value;
                setFieldValue('dsFertilidade', value.target.value);
              }}
              inputProps={{
                maxLength: 100
              }}
              value={values.dsFertilidade}
              label={formatMessage('produtores.descFertilidade')}
              className={classes.textField}
              error={errors.dsFertilidade && touched.dsFertilidade}
              helperText={touched.dsFertilidade ? errors.dsFertilidade : ''}
            />
          </Grid>
        )}

        {stOrganica && (
          <Grid item xs={2}>
            <Autocomplete
              itens={classificacaoList}
              campoOp='dsClassificacao'
              campoInput='dsClassificacao'
              campoChave='idClassificacao'
              label={formatMessage('produtores.classificacao')}
              name='classificacao'
              error={errors.classificacao && touched.classificacao}
              helperText={touched.classificacao ? errors.classificacao : ''}
              onChangeOwn={event => {
                setFieldValue('classificacao', event);
                onChangeClassificacaoAutoComplete(event);
              }}
              onChangeAutoComplete={onChangeClassificacaoAutoComplete}
              value={tratarValorSelectInitialClassificacao(valueAutoCompleteClassificacao)}
              key={keyClassificacao}
              valueAutoComplete={tratarValorSelectInitialClassificacao(valueAutoCompleteClassificacao)}
              valueSelect={
                tratarValorSelectInitialClassificacao(valueAutoCompleteClassificacao).dsClassificacao
              }
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <FormControl className={classes.form} error={errors.stTalhao && touched.stTalhao}>
            <InputLabel htmlFor='stTalhao-helper' error={errors.stTalhao && touched.stTalhao}>
              {formatMessage('produtores.status')}
            </InputLabel>
            <Select
              style={{
                marginLeft: 0,
                fontSize: 14,
                width: '100%',
                position: 'relative'
              }}
              SelectDisplayProps={{
                style: {
                  width: '100%',
                  backgroundColor: 'transparent'
                }
              }}
              value={values.stTalhao}
              onChange={event => {
                if (values.stTalhao !== event.target.value && event.target.value === 0) {
                  clickChangeStTalhao();
                } else if (values.stTalhao !== event.target.value && event.target.value === 1) {
                  clickAtivarTalhao();
                }
                setFieldValue('stTalhao', event.target.value);
                var item = itemSelect;
                item.stTalhao = event.target.value;
              }}
              onBlur={handleBlur}
              error={errors.stTalhao && touched.stTalhao}
              input={<Input id='stTalhao-error' />}
              inputProps={{
                name: 'stTalhao',
                id: 'stTalhao-helper'
              }}
            >
              {situacaoEnum.map(item => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {errors.stTalhao && touched.stTalhao && <FormHelperText> {errors.stTalhao} </FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={5}>
          <TextField
            disabled={!changeStTalhao || values.stTalhao === 1}
            id='dsMotivo'
            name='dsMotivo'
            onBlur={handleBlur}
            onChange={value => {
              setFieldValue('dsMotivo', value.target.value);
              var item = itemSelect;
              item.dsMotivo = value.target.value;
              changeStateItemSelect(item);
            }}
            inputProps={{
              maxLength: 100
            }}
            value={values.dsMotivo}
            label={formatMessage('produtores.motivo')}
            className={classes.textField}
            error={errors.dsMotivo && touched.dsMotivo}
            helperText={touched.dsMotivo ? errors.dsMotivo : ''}
          />
        </Grid>

        <Grid item xs={2}>
          <DatePicker
            disabled={!changeStTalhao || values.stTalhao === 1}
            name='dtInicio'
            locale={getUserLang()}
            errorTouched={errors.dtInicio && touched.dtInicio}
            helperText={formatMessage('produtores.campoObrigatorio')}
            label={formatMessage('produtores.dataInicio')}
            valueDate={values.dtInicio}
            maxDate={new Date()}
            onBlurDatePicker={e => {
              const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
              if (validation) {
                setFieldValue('dtInicio', e.target.value);
              } else {
                setFieldValue('dtInicio', null);
              }
            }}
            onChangeDatePicker={date => {
              setFieldValue('dtInicio', date);
              setFieldValue('dtTermino', null);
              var item = itemSelect;
              item.dtInicio = date;
              changeStateItemSelect(item);
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <DatePicker
            disabled={values.stTalhao === 0 ? !changeStTalhao : !stAtivouTalhao}
            name='dtTermino'
            locale={getUserLang()}
            errorTouched={errors.dtTermino && touched.dtTermino}
            helperText={touched.dtTermino ? errors.dtTermino : ''}
            label={formatMessage('produtores.dataFinal')}
            valueDate={values.dtTermino}
            onBlurDatePicker={e => {
              const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
              if (validation) {
                setFieldValue('dtTermino', e.target.value);
              } else {
                setFieldValue('dtTermino', null);
              }
            }}
            onChangeDatePicker={date => {
              setFieldValue('dtTermino', date);
              var item = itemSelect;
              item.dtTermino = date;
              changeStateItemSelect(item);
            }}
            minDate={values.dtInicio || null}
          />
        </Grid>
      </Grid>

      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={modal}
        onClose={handleClose}
        className={classes.modal}
      >
        <form autoComplete='off'>
          <CardTitle
            containerStyle={{
              marginBottom: 0,
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5
            }}
            title={formatMessage('produtores.edicaoDeTalhao')}
          />
          <div className={classes.divModal}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <TextField
                  id='dsMotivoAlteracao'
                  name='dsMotivoAlteracao'
                  onBlur={handleBlur}
                  onChange={value => {
                    itemSelect.dsMotivoAlteracao = value.target.value;
                    setFieldValue('dsMotivoAlteracao', value.target.value);
                  }}
                  value={values.dsMotivoAlteracao}
                  label={formatMessage('produtores.motivoAlteraçao')}
                  className={classes.textField}
                  error={errors.dsMotivoAlteracao && touched.dsMotivoAlteracao}
                  helperText={touched.dsMotivoAlteracao ? errors.dsMotivoAlteracao : ''}
                  onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : true)}
                />
              </Grid>
            </Grid>
            <Button color='secondary' variant='contained' onClick={handleSubmit}>
              {formatMessage('produtores.salvar')}
            </Button>
          </div>
        </form>
      </Modal>
    </form>
  );
};

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(Identificacao);
export default connect(mapStateToProps)(enhaced);
