export default {
  mapa: {
    filtro: 'Filter by Producer',
    campoObrigatorio: 'Required field',
    produtor: 'Producer',
    talhao: 'Field',
    area: 'Area',
    produtorDuplicado: 'Duplicate Producer',
    talhaoDuplicado: 'Duplicate field',
    mapa: 'Maps'
  }
};
