import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const style = theme => ({
  card: {
    width: '100%',
    height: 'calc(100vh - 475px)',
    display: 'flex',
    minHeight: 300,
    flexDirection: 'column',
    marginBottom: 15
  }
});

class Municipio extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const municipio = messagesLinguage['regiao.municipio'];
    const desejaExcluir = messagesLinguage['regiao.desejaExcluir'];
    const excluir = messagesLinguage['regiao.excluir'];
    const nao = messagesLinguage['regiao.nao'];
    const municipioRemovidoComSucesso = messagesLinguage['regiao.municipioRemovidoComSucesso'];
    const estado = messagesLinguage['regiao.estado'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          municipio,
          desejaExcluir,
          excluir,
          nao,
          municipioRemovidoComSucesso,
          estado
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      check: true,
      statusEdit: false
    };

    this.clickExcluir = this.clickExcluir.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  clickExcluir() {
    const position = this.props.data.indexOf(this.state.itemSelect);

    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        this.props.onClickDeleteMunicipio(position);

        swal(provider.intl.formatMessage({ id: 'municipioRemovidoComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      } else {
        this.setState({
          disabledEdit: false
        });
      }
    });
  }

  handleClose() {
    this.setState({
      openModal: false
    });
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  clickAdicionar() {
    this.setState({
      openModal: true,
      statusEdit: false,
      check: true
    });
  }

  render() {
    const { classes } = this.props;
    const itensIdentificacao = [
      {
        field: 'nmUf',
        headerName: provider.intl.formatMessage({ id: 'estado' }),
        col: 4
      },
      {
        field: 'nmMunicipio',
        headerName: provider.intl.formatMessage({ id: 'municipio' }),
        sort: 'asc',
        col: 8
      }
    ];

    return (
      <div className={classes.card}>
        <GroupButtonGrid
          showAdd={false}
          showEdit={false}
          showDelete={true}
          disableDelete={this.state.disabledEdit}
          onClickDelete={this.clickExcluir}
        />

        <DataTable
          key={this.props.dataKey}
          noMargin
          data={this.props.data}
          columns={itensIdentificacao}
          selectChange={this.selectChange}
          showPagination={false}
          borderRadius={false}
        />
      </div>
    );
  }
}

Municipio.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Municipio);
