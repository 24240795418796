import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllAgendaHandler() {
  return axios.get(api + '/agenda');
}

function findAgendaByIdHandler(idAgenda) {
  return axios.get(api + '/agenda/' + idAgenda);
}

function addAgendaHandler(values) {
  return axios.post(api + '/agenda', values);
}

function updateAgendaHandler(values) {
  return axios.put(api + '/agenda', values);
}

function addEventoHandler(values) {
  return axios.post(api + '/agenda/evento', values);
}

function updateEventoHandler(values) {
  return axios.put(api + '/agenda/evento', values);
}

function deleteAgendaByIdHandler(idAgenda) {
  return axios.delete(api + '/agenda/' + idAgenda);
}

function findAgendaByDataHandler(dhInicio, dhFim) {
  return axios.get(api + '/agenda?datainicio=' + dhInicio + '&datafim=' + dhFim);
}

function findUltimaAgendaProdutorHandler(idProdutor) {
  return axios.get(api + '/agenda/produtor/ultima/' + idProdutor);
}

function findAgendaByTecnicoHandler(idTecnico, dhInicio, dhFim) {
  return axios.get(
    api + '/agenda?datainicio=' + dhInicio + '&datafim=' + dhFim + '&idTecnico=' + idTecnico
  );
}

export default {
  findAgenda: findAllAgendaHandler,
  findAgendaById: findAgendaByIdHandler,
  addAgenda: addAgendaHandler,
  updateAgenda: updateAgendaHandler,
  deleteAgendaById: deleteAgendaByIdHandler,
  findAgendaByData: findAgendaByDataHandler,
  findUltimaAgendaProdutor: findUltimaAgendaProdutorHandler,
  findAgendaByTecnico: findAgendaByTecnicoHandler,
  updateEvento: updateEventoHandler,
  addEvento: addEventoHandler
};
