export default {
  fertilizantesQuimicos: {
    fertilizantesQuimicosRemovidoComSucesso: 'Chemical fertilizer successfully removed',
    falhaAoExcluirFertilizantesQuimicos: 'Failed to exclude chemical fertilizer',
    desejaExcluir: 'Do you want to delete the selected chemical fertilizer?',
    fertilizantesQuimicos: 'Chemical fertilizerss',
    cadastroDeFertilizantesQuimicos: 'Chemical fertilizers record',
    identificacaoDaFertilizantesQuimicos: 'Identification',
    fertilizantesQuimicosCadastradoComSucesso: 'Chemical fertilizer successfully registered',
    fertilizantesQuimicosEditadoComSucesso: 'Chemical fertilizer edited successfully',
    falhaAoEditarFertilizantesQuimicos: 'Failed to edit chemical fertilizer',
    falhaAoCadastrarFertilizantesQuimicos: 'Failed to register chemical fertilizer',
    falhaAoCarregarFertilizantesQuimicos: 'Failed to load chemical fertilizer'
  }
};
