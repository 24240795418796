//@author Katia Miglioli
//string,number,date
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '../SelectOwn/SelectOwn';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import green from '@material-ui/core/colors/green';
import TextField from '../TextFieldOwn/TextFieldOwn';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import iconeAddFiltro from '@images/icone_add_filtro.png';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import '@styles/CardFilter.css';
import Chips from './FilterChip';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import DatePicker from '../DatePicker/DatePicker';
import Autocomplete from '@components/Autocomplete/Autocomplete';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 15
  },
  formControl: {
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: 204
  },
  divTitulo: {
    fontSize: 18,
    color: '#666666',
    position: 'relative',
    marginLeft: '65px'
  },
  divSelects: {
    display: 'flex'
  },
  divChips: {
    display: 'flex',
    minHeight: 50
  },
  button: {
    marginTop: 20,
    margin: theme.spacing.unit,
    marginBottom: 0,
    minWight: 34,
    maxWight: 34,
    minHeight: 34,
    maxHeight: 34,
    marginRight: '25px',
    zIndex: 1
  },
  iconButton: {
    marginLeft: 0,
    marginRight: 0
  },
  divChipsText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#7F7F7F',
    fontSize: 14,
    display: 'flex',
    width: 120,
    marginTop: 18
  },
  divButtonFiltrar: {
    position: 'relative',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 83,
    width: 190,
    left: '25px',
    alignItems: 'flex-end'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: '300 !important'
  },
  textField: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginTop: 0,
    width: 204
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    secondary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '1px solid green'
        }
      }
    }
  }
});

/**
 * Valida se o objeto passado por parametro tem pelo menos uma propriedade valida
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {object} values - Objeto com as propriedades pra serem validadas
 * @returns {boolean} se for valido = true; se não = false;
 */
function atLeastOneValid(values) {
  for (const prop in values) {
    const value = values[prop];

    if (prop === 'isSecondButton' && value === false) {
      return true;
    } else if (prop === 'isSecondButton' && value === true) {
      continue;
    }

    if (value !== undefined && value !== null && value !== '') {
      return true;
    }
  }

  return false;
}

class CardFiltersOpen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      autoComplete: '',
      keyAutoComplete: Math.random(),
      mensagemChip: false,
      typeTextField: 'string',
      listName: '',
      valueDate: null,
      filterDefault: true,
      relationshipSelects: 0,
      selectValue: '',
      selectFilter: '',
      currentEvent: '',
      operationTypeNumbers: [
        {
          label: <FormattedMessage id='filtrosDeListagem.igual' />,
          field: 'Igual',
          type: '=='
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.maiorOuIgual' />,
          field: 'Maior ou igual',
          type: '>='
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.maior' />,
          field: 'Maior',
          type: '>'
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.menorOuIgual' />,
          field: 'Menor ou igual',
          type: '<='
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.menor' />,
          field: 'Menor',
          type: '<'
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.diferente' />,
          field: 'Diferente',
          type: '!='
        }
      ],
      operationTypeString: [
        {
          label: <FormattedMessage id='filtrosDeListagem.contendo' />,
          field: 'Contendo',
          type: '%%'
        }
      ],
      operationTypeEnum: [
        {
          label: <FormattedMessage id='filtrosDeListagem.igual' />,
          field: 'Igual',
          type: '=='
        }
      ]
    };
    this.updateStatePreviousPage();

    this.tratarValorAutoComplete = this.tratarValorAutoComplete.bind(this);
  }
  validarEntrada(newChip) {
    var i;
    var a = 0;
    var chipDataSelf = this.treatChipsOutput(this.props.chipData);
    if (chipDataSelf[0] !== undefined) {
      for (i = 0; i < chipDataSelf.length; i++) {
        if (
          chipDataSelf[i].operation === newChip.operation &&
          chipDataSelf[i].filter.label === newChip.filter.label
        )
          a++;
      }
    }
    return a;
  }

  handleSubmit(values, { resetForm }) {
    if (!atLeastOneValid(values)) {
      this.props.onFilter(this.treatChipsOutput(this.props.chipData));
      return;
    }

    var newChip = {};

    if (this.state.typeTextField === 'autocomplete') {
      newChip = {
        filter: values.filter,
        operation: values.operation,
        value: {
          value: values.value[this.props.autoComplete[values.filter.list].campoChave],
          label: values.value[this.props.autoComplete[values.filter.list].campoOp],
          field: values.value[this.props.autoComplete[values.filter.list].campoOp]
        }
      };
    } else {
      newChip = {
        filter: values.filter,
        operation: values.operation,
        value: values.value
      };
    }

    var a = this.validarEntrada(newChip);
    if (a === 0) {
      this.setState(state => ({
        mensagemChip: false
      }));
      this.props.dispatch({
        type: 'ADD_CHIPS',
        id: this.props.viewKey,
        newChip
      });
    } else {
      this.setState(() => ({
        mensagemChip: true
      }));
    }
    if (!this.state.mensagemChip) {
      resetForm({
        value: '',
        filter: '',
        operation: ''
      });
      this.setState(state => ({
        valueDate: null,
        selectValue: '',
        selectFilter: '',
        typeTextField: 'string',
        autoComplete: '',
        keyAutoComplete: Math.random()
      }));
    }

    if (!this.props.onClickButton || this.props.radioButton) {
      this.props.onFilterChanged(this.treatChipsOutput(this.props.chipData));
    }
  }

  labelTypeDate(label, verificar) {
    const tempTipoDeFiltro = this.props.filtros;
    var i;
    for (i = 0; i < this.props.filtros.length; i++) {
      if (tempTipoDeFiltro[i].field === label) {
        if (tempTipoDeFiltro[i].type === 'autocomplete' && verificar) {
          return {
            type: tempTipoDeFiltro[i].type,
            list: tempTipoDeFiltro[i].list
          };
        } else {
          return tempTipoDeFiltro[i].type;
        }
      }
    }
  }

  dateSelects(label) {
    var type = this.labelTypeDate(label);
    if (type === 'number' || type === 'date') {
      this.TypeSelect = 1;
    } else if (type === 'string') {
      this.TypeSelect = 0;
    } else if (type === 'enum' || type === 'autocomplete') {
      this.TypeSelect = 2;
    }
    this.setState(() => ({
      relationshipSelects: this.TypeSelect
    }));
    return this.TypeSelect;
  }

  verificarInput(label) {
    // INSERIR O SELECT PARA ENUM
    var value = this.labelTypeDate(label, true);

    if (value.type === 'autocomplete') {
      this.setState(() => ({
        autoComplete: '',
        keyAutoComplete: Math.random(),
        typeTextField: value.type,
        listName: value.list
      }));
    } else {
      this.setState(() => ({
        typeTextField: value
      }));
    }
  }

  operationType() {
    switch (this.state.relationshipSelects) {
      case 1:
        return this.state.operationTypeNumbers;
      case 2:
        return this.state.operationTypeEnum;
      default:
        return this.state.operationTypeString;
    }
  }

  selectOptionsEnum() {
    var tempFiltros = this.props.filtros;
    var i = 0;
    for (i; i < tempFiltros.length; i++) {
      if (tempFiltros[i].field === this.state.currentEvent) {
        return tempFiltros[i].options;
      }
    }
    return null;
  }

  validarEnum(nameEnum) {
    //retorna o obj options a partir do field do select
    var i;
    var tempSelectOptionsEnum = this.selectOptionsEnum();
    for (i = 0; i < tempSelectOptionsEnum.length; i++) {
      if (tempSelectOptionsEnum[i].field === nameEnum) {
        return tempSelectOptionsEnum[i];
      }
    }
    return null;
  }

  validarFilter(filter) {
    var i;
    var tempFiltros = this.props.filtros;
    for (i = 0; i < tempFiltros.length; i++) {
      if (tempFiltros[i].field === filter) {
        return tempFiltros[i];
      }
    }
    return null;
  }

  updateStatePreviousPage() {
    //alterar a pagina anterior no redux
    const pagAnterior = this.props.paginaAnterior;
    const allViewKey = this.props.allViewKey || '';

    if (
      !(
        pagAnterior === this.props.viewKey ||
        pagAnterior === 'paginaAnterior' ||
        allViewKey.includes(pagAnterior)
      )
    ) {
      this.props.dispatch({
        type: 'CLEAN_CHIPS',
        id: this.props.paginaAnterior
      });
    }
    this.props.dispatch({
      type: 'UPDATE_PREVIOUS_PAGE',
      idClean: this.props.viewKey
    });
  }

  treatChipsOutput(dataChips, secondViewKey) {
    var filterChips = [];
    for (var i = 0; i < dataChips.length; i++) {
      if (secondViewKey !== undefined) {
        if (dataChips[i].id === secondViewKey) {
          //ALTERA PARA O ID DOS FILTROS DA TELA
          filterChips = dataChips[i].content;
        }
      } else {
        if (dataChips[i].id === this.props.viewKey) {
          //ALTERA PARA O ID DOS FILTROS DA TELA
          filterChips = dataChips[i].content;
        }
      }
    }
    return filterChips;
  }

  tratarValorAutoComplete() {
    if (!this.state.autoComplete) {
      return '';
    }

    return this.state.autoComplete;
  }

  render() {
    const { classes } = this.props;
    const filtros = this.props.filtros;
    const typeTextField = this.state.typeTextField;

    return (
      <MuiThemeProvider theme={theme}>
        <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
          <Formik
            initialValues={{
              filter: '',
              operation: '',
              value: '',
              isSecondButton: false
            }}
            onSubmit={(e, { resetForm }) => {
              this.props.onClickButton && !atLeastOneValid(e)
                ? this.props.onClickButton(this.treatChipsOutput(this.props.chipData))
                : this.handleSubmit(e, { resetForm });
            }}
            validationSchema={Yup.lazy(values =>
              Yup.object().shape({
                value: atLeastOneValid(values)
                  ? Yup.string().required(<FormattedMessage id='filtrosDeListagem.campoObrigatorio' />)
                  : Yup.string(),
                filter: atLeastOneValid(values)
                  ? Yup.string().required(<FormattedMessage id='filtrosDeListagem.campoObrigatorio' />)
                  : Yup.string(),
                operation: atLeastOneValid(values)
                  ? Yup.string().required(<FormattedMessage id='filtrosDeListagem.campoObrigatorio' />)
                  : Yup.string()
              })
            )}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              validateForm
            }) => (
              <div
                key={this.props.keyCard}
                style={{ display: 'flex', justifyContent: 'space-between', overflor: 'hidden' }}
              >
                {this.props.children}
                {!this.props.children && (
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                      <div className={classes.divSelects}>
                        <div className={classes.divSelectsText}>
                          <form className={classes.root} autoComplete='off' onSubmit={handleSubmit}>
                            <FormControl
                              className={classes.formControl}
                              error={errors.filter && touched.filter}
                            >
                              <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-filtro'>
                                {this.props.withoutLabelTipo ? (
                                  <FormattedMessage id='filtrosDeListagem.campo' />
                                ) : (
                                  <FormattedMessage id='filtrosDeListagem.tipoDeFiltro' />
                                )}
                              </InputLabel>
                              <Select
                                SelectDisplayProps={{
                                  style: { backgroundColor: 'transparent' }
                                }}
                                input={<Input id='tipo-filtro' />}
                                value={this.state.selectFilter}
                                name='filter'
                                onChange={event => {
                                  handleChange(event);
                                  this.verificarInput(event.target.value);
                                  if (this.dateSelects(event.target.value) === 1) {
                                    setFieldValue('operation', '');
                                  } else if (this.dateSelects(event.target.value) === 0) {
                                    setFieldValue('operation', '%%');
                                  } else if (this.dateSelects(event.target.value) === 2) {
                                    setFieldValue('operation', '==');
                                  }
                                  setFieldValue('filter', this.validarFilter(event.target.value));
                                  setFieldValue('value', '');
                                  this.setState(() => ({
                                    mensagemChip: false,
                                    valueDate: null,
                                    currentEvent: event.target.value,
                                    selectFilter: event.target.value,
                                    selectValue: ''
                                  }));
                                }}
                                onBlur={handleBlur}
                              >
                                {filtros.map(filtro => (
                                  <MenuItem key={filtros.indexOf(filtro)} value={filtro.field}>
                                    {filtro.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText
                                error={true}
                                style={{
                                  position: 'absolute',
                                  marginTop: '30%',
                                  display: this.state.mensagemChip ? 'flex' : 'none'
                                }}
                              >
                                <FormattedMessage id='filtrosDeListagem.filtroDuplicado' />
                              </FormHelperText>
                              <FormHelperText>
                                {errors.filter && touched.filter ? errors.filter : null}
                              </FormHelperText>
                            </FormControl>

                            {!this.props.withoutLabelTipo && (
                              <FormControl
                                className={classes.formControl}
                                error={errors.operation && touched.operation}
                              >
                                <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-comparacao'>
                                  <FormattedMessage id='filtrosDeListagem.tipoDeComparacao' />
                                </InputLabel>
                                <Select
                                  SelectDisplayProps={{
                                    style: { backgroundColor: 'transparent' }
                                  }}
                                  name='operation'
                                  value={values.operation}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  input={<Input id='tipo-comparacao' />}
                                >
                                  {this.operationType().map(operation => (
                                    <MenuItem
                                      key={this.operationType().indexOf(operation)}
                                      value={operation.type}
                                    >
                                      {operation.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errors.operation && touched.operation ? errors.operation : null}
                                </FormHelperText>
                              </FormControl>
                            )}
                            <div className={classes.container} noValidate>
                              {(typeTextField === 'date' && (
                                <DatePicker
                                  locale={getUserLang()}
                                  style={{ marginTop: 1 }}
                                  label={<FormattedMessage id='filtrosDeListagem.pesquisa' />}
                                  valueDate={this.state.valueDate}
                                  errorTouched={errors.value && touched.value}
                                  helperText={
                                    <FormattedMessage id='filtrosDeListagem.campoObrigatorio' />
                                  }
                                  onBlurDatePicker={date => {
                                    let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                                    if (!validation) {
                                      setFieldValue('value', null);
                                    }
                                  }}
                                  onChangeDatePicker={date => {
                                    setFieldValue('value', date._d);
                                    this.setState(() => ({
                                      valueDate: date._d
                                    }));
                                  }}
                                />
                              )) ||
                                (typeTextField === 'enum' && (
                                  <FormControl
                                    className={classes.formControl}
                                    error={errors.value && touched.value}
                                  >
                                    <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-pesquisa'>
                                      {this.props.withoutLabelTipo ? (
                                        <FormattedMessage id='filtrosDeListagem.tipoDeOrdem' />
                                      ) : (
                                        <FormattedMessage id='filtrosDeListagem.pesquisa' />
                                      )}
                                    </InputLabel>
                                    <Select
                                      SelectDisplayProps={{
                                        style: { backgroundColor: 'transparent' }
                                      }}
                                      name='value'
                                      value={this.state.selectValue}
                                      onChange={event => {
                                        handleChange(event);

                                        this.setState(state => ({
                                          selectValue: event.target.value
                                        }));

                                        setFieldValue('value', this.validarEnum(event.target.value));
                                      }}
                                      onBlur={handleBlur}
                                      input={<Input id='tipo-pesquisa' />}
                                    >
                                      {this.selectOptionsEnum().map(select => (
                                        <MenuItem
                                          key={this.selectOptionsEnum().indexOf(select)}
                                          value={select.field}
                                        >
                                          {select.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <FormHelperText>
                                      {errors.value && touched.value ? errors.value : null}
                                    </FormHelperText>
                                  </FormControl>
                                )) ||
                                ((typeTextField === 'string' || typeTextField === 'number') && (
                                  <TextField
                                    id='date'
                                    disabled={this.props.withoutLabelTipo}
                                    label={
                                      this.props.withoutLabelTipo ? (
                                        <FormattedMessage id='filtrosDeListagem.tipoDeOrdem' />
                                      ) : (
                                        <FormattedMessage id='filtrosDeListagem.pesquisa' />
                                      )
                                    }
                                    type={typeTextField}
                                    name='value'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.value}
                                    error={errors.value && touched.value}
                                    helperText={
                                      errors.value && touched.value ? (
                                        <FormattedMessage id='filtrosDeListagem.campoObrigatorio' />
                                      ) : null
                                    }
                                    margin='normal'
                                    className={classes.textField}
                                  />
                                ))}
                              {typeTextField === 'autocomplete' && (
                                <div style={{ width: 204, position: 'absolute' }}>
                                  <Autocomplete
                                    itens={this.props.autoComplete[this.state.listName].list}
                                    campoOp={this.props.autoComplete[this.state.listName].campoOp}
                                    campoChave={this.props.autoComplete[this.state.listName].campoChave}
                                    label={<FormattedMessage id='filtrosDeListagem.pesquisa' />}
                                    name='pesquisar'
                                    error={errors.value && touched.value}
                                    helperText={
                                      errors.value && touched.value ? (
                                        <FormattedMessage id='filtrosDeListagem.campoObrigatorio' />
                                      ) : null
                                    }
                                    onChangeOwn={value => {
                                      setFieldValue('value', value);
                                      if (value) {
                                        this.setState({
                                          autoComplete: value,
                                          keyAutoComplete: Math.random()
                                        });
                                      } else {
                                        this.setState({
                                          autoComplete: [],
                                          keyAutoComplete: Math.random()
                                        });
                                      }
                                    }}
                                    value={this.tratarValorAutoComplete()}
                                    key={this.state.keyAutoComplete}
                                    valueAutoComplete={this.tratarValorAutoComplete()}
                                    valueSelect={
                                      this.tratarValorAutoComplete()[
                                        this.props.autoComplete[this.state.listName].campoOp
                                      ]
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <Button
                              className={classes.button}
                              style={{ marginLeft: typeTextField === 'autocomplete' ? 220 : 8 }}
                              id='button'
                              onClick={e => {
                                setFieldValue('isSecondButton', false);
                                validateForm(values).then(() => {
                                  handleSubmit(values);
                                });
                              }}
                            >
                              <img className={classes.iconButton} src={iconeAddFiltro} alt=' ' />
                            </Button>
                          </form>
                        </div>
                      </div>
                      <div className={classes.divChips}>
                        <div
                          className={classes.divChipsText}
                          style={{ width: this.props.withoutLabelTipo ? 80 : 120 }}
                        >
                          {this.props.withoutLabelTipo ? (
                            <FormattedMessage id='filtrosDeListagem.ordenarPor' />
                          ) : (
                            <FormattedMessage id='filtrosDeListagem.filtrosAdicionados' />
                          )}
                        </div>
                        <Chips
                          withoutLabelTipo={this.props.withoutLabelTipo}
                          marginBottomImg={false}
                          viewKey={this.props.viewKey}
                          onFilterChanged={this.props.onFilterChanged}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className={classes.divButtonFiltrar}>
                  <div>
                    <Button
                      color='primary'
                      style={{ width: this.props.relatorio ? 145 : 90 }}
                      className={classes.button}
                      onClick={e => {
                        setFieldValue('isSecondButton', true);

                        const chipData = this.props.chipData.find(item => {
                          return this.props.viewKey === item.id;
                        }).content;

                        if (
                          values.filter === '' &&
                          values.operation === '' &&
                          values.value === '' &&
                          chipData.length > 0 &&
                          !this.props.onClickButton
                        ) {
                          this.props.onFilter(this.treatChipsOutput(this.props.chipData));
                        } else {
                          if (this.props.onClickButton) {
                            if (this.props.withoutButtonFiltrar) {
                              this.props.onClickButton(
                                this.treatChipsOutput(this.props.chipData, this.props.allViewKey),
                                this.treatChipsOutput(this.props.chipData)
                              );
                            } else if (this.props.allViewKey) {
                              this.props.onClickButton(
                                this.treatChipsOutput(this.props.chipData),
                                this.treatChipsOutput(this.props.chipData, this.props.allViewKey)
                              );
                            } else {
                              this.props.onClickButton(this.treatChipsOutput(this.props.chipData));
                            }

                            this.props.expandedButton && this.props.expandedButton(false);
                          } else {
                            validateForm(values).then(() => {
                              handleSubmit(values);
                            });
                          }
                        }
                      }}
                    >
                      <div style={{ position: 'relative' }}>
                        {this.props.relatorio ? (
                          <FormattedMessage id='filtrosDeListagem.gerarRelatorio' />
                        ) : (
                          <FormattedMessage id='filtrosDeListagem.filtrar' />
                        )}
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          />
        </IntlProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  chipData: state.chips.chipData,
  paginaAnterior: state.previousPage.paginaAnterior
});

CardFiltersOpen.propTypes = {
  classes: PropTypes.object.isRequired
};

let EnhacedComponent = withStyles(styles)(CardFiltersOpen);
export default connect(mapStateToProps)(EnhacedComponent);
