export default {
  fitossanidade: {
    fitossanidade: "Plant health",
    nao: "Not",
    desejaExcluir: "Do you want to delete?",
    excluir: "Delete",
    fitossanidadeRemovidoComSucesso: "Plant health successfully removed",
    falhaAoExcluirFitossanidade: "Failed to delete plant health",
    excluindo: "Excluding",
    cultura: "Culture",
    tipo: "Type",
    situacao: "Situation",
    nome: "Name",
    fitossanidadeCadastradoComSucesso: "Plant health successfully registered",
    fitossanidadeEditadoComSucesso: "Plant health successfully edited",
    falhaAoCadastrarFitossanidade: "Failed to register plant health",
    falhaAoEditarFitossanidade: "Failed to edit plant health",
    falhaAoCarregarFitossanidade: "Failed to load plant health",
    salvar: "Save",
    cancelar: "Cancel",
    salvando: "Saving",
    atualizando: "Updating",
    cadastroDeFitossanidade: "Plant health registration",
    campoObrigatorio: "Required field",
    identificacaoDeFitossanidade: "Plant health Identification",
    doencaSolo: "Soil disease",
    doencaFoliar: "Leaf disease",
    nematoide: "Nematode",
    descricao: "Description",
    ativo: "Active",
    inativo: "Inactive",
    pragas: "Pests"
  }
};
