import URL from './URL';
// eslint-disable-next-line
import axios, { AxiosResponse } from 'axios';

const API = `${URL.API}/certificacao-ecosocial`;

/**
 * Chama o endpoint para retornar todas as certificações
 *
 * @returns {Promise<AxiosResponse<Certificacao[]>>} Promise com a lista de todas as certificações
 */
export async function findAllCertificacoes() {
  return axios.get(`${API}/`);
}

/**
 * Chama o endpoint para retornar o Certificação pelo ID
 *
 * @param {Number} id - ID do Certificação
 * @returns {Promise<AxiosResponse<Certificacao>>} Uma Promise com o Certificação
 */
export async function findCertificacaoById(id) {
  return axios.get(`${API}/${id}`);
}

/**
 * Chama o endpoint para salvar um novo Certificação
 *
 * @param {Certificacao} certificacao - Certificação que vai ser salvo
 * @returns {Promise<AxiosResponse<Certificacao>>} Uma Promise com o Certificação
 */
export async function saveCertificacao(certificacao) {
  return axios.post(API, certificacao);
}

/**
 * Chama o endpoint para atualizar um Certificação
 *
 * @param {Certificacao} certificacao - Certificação que vai ser atualizado
 * @returns {Promise<AxiosResponse<Certificacao>>} Uma Promise com o Certificação
 */
export async function updateCertificacao(certificacao) {
  return axios.put(API, certificacao);
}

/**
 * Chama o endpoint para deletar um Certificação pelo ID
 *
 * @param {Number} id - ID do Certificação
 * @returns {Promise<AxiosResponse<void>>} Uma Promise
 */
export async function deleteCertificacaoById(id) {
  return axios.delete(`${API}/${id}`);
}
