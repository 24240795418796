import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CadastrarInsumoRecord from '@components/InsumoRecord/CadastrarInsumoRecord';
import formatMessage from './i18n/formatMessage';
import Insumo from '@resources/InsumosEnum';
import { withRouter } from 'react-router-dom';

/**
 * Tela para cadastro de fertilizantes químicos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function EditarFertilizantesQuimicos(props) {
  useEffect(() => {
    const title = 'fertilizantesQuimicos.cadastroDeFertilizantesQuimicos';
    if (props.itensState.name !== title) {
      props.dispatch({ type: 'UPDATE_TOOLBAR', toolbar: formatMessage(title), name: title });
    }
  }, []);

  return (
    <CadastrarInsumoRecord
      id={props.match.params.id}
      insumoURL={'fertilizantes-quimicos'}
      insumoEnum={Insumo.FERTILIZANTE_QUIMICO}
      infoUsuario={props.infoUsuario}
      saveMessage={formatMessage(
        'fertilizantesQuimicos.' +
          (props.match.params.id
            ? 'fertilizantesQuimicosEditadoComSucesso'
            : 'fertilizantesQuimicosCadastradoComSucesso')
      )}
      falhaSaveMesssage={formatMessage(
        'fertilizantesQuimicos.' +
          (props.match.params.id
            ? 'falhaAoEditarFertilizantesQuimicos'
            : 'falhaAoCadastrarFertilizantesQuimicos')
      )}
      falhaCarregarMensagem={formatMessage('fertilizantesQuimicos.falhaAoCarregarFertilizantesQuimicos')}
    />
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default withRouter(connect(mapStateToProps)(EditarFertilizantesQuimicos));
