export default {
  certificacao: {
    descricao: 'Descrição',
    dataCadastro: 'Data de cadastro',
    falhaoAoBuscarDados: 'Falha ao buscar dados',
    certificacoes: 'Certificações ecosocial',
    desejaExcluirCertificacao: 'Deseja excluir esta certificação',
    nao: 'Não',
    excluir: 'Excluir',
    certificacaoExcluida: 'Certificação excluída com sucesso',
    falhaoAoExcluir: 'Ocorreu uma falha ao excluir a certificação',
    cadastroDeCertificacao: 'Cadastro de certificação ecosocial',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    certificacaoSalvoComSucesso: 'Certificação salva com sucesso',
    falhaAoSalvar: 'Falha ao salvar certificação',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    produtorRelacionado: 'Não foi possível excluír esta certificação pois existe um produtor usando ela'
  }
};
