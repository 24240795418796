import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider } from '@material-ui/core/styles';
import iconAdd from '@images/icone_add.png';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import FormaAplicacaoAPI from '@resources/FormaAplicacao';
import { head, operationTypeString } from './headers';
import formatMessage from './i18n/formatMessage';
import { styles, theme } from './FormaAplicacao.styles';

/**
 * Componente de listagem de forma de aplicação
 *
 * @author Gabriela Farias
 * @class Forma aplicação
 * @extends {Component} - Componente React
 */
function FormaAplicacao(props) {
  const { classes, history, chipData, itensState } = props;
  const [dataInTable, setDataInTable] = useState([]);
  const [dataAppSync, setDataAppSync] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemSelect, setItemSelect] = useState(null);
  const [disabledEdit, setDisabledEdit] = useState(false);

  useEffect(() => {
    const title = 'formaAplicacao.formaAplicacao';
    if (itensState.name !== title) {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(title),
        name: title
      });
    }

    onFilter(chipData);
  }, []);

  /**
   * Busca todos os cadastro para mostrar na listagem
   * @param {*} dataChips
   */
  function onFilter(dataChips) {
    FormaAplicacaoAPI.findAllFormaAplicacao()
      .then(query => {
        setDataInTable(FilterUtil.applyFilters(query.data, dataChips));
        setLoading(false);
        setDataAppSync(query.data);
      })
      .catch(() => {
        setDataInTable([]);
        setLoading(false);
        setDataAppSync([]);
      });
  }

  /**
   * Ação ao clicar em editar
   */
  function clickEditar() {
    history.push('/app/forma-aplicacao/editar/' + itemSelect.idFormaAplicacao);
  }

  /**
   * Açao ao clicar em adicionar
   */
  function clickAdicionar() {
    history.push('/app/forma-aplicacao/novo');
  }

  /**
   * Açao ao selecionar item na grid
   * @param {*} select - item selecionado
   */
  function selectChange(select) {
    if (select) {
      setItemSelect(select);
      setDisabledEdit(false);
    } else {
      setItemSelect(null);
      setDisabledEdit(true);
    }
  }

  /**
   * Ação ao aplicar filtro
   * @param {*} dataChips - filtro aplicado
   */
  function onFilterChanged(dataChips) {
    setDataInTable(FilterUtil.applyFilters(dataAppSync, dataChips));
  }

  /**
   * Deleta um item selecionado
   */
  function clickExcluir() {
    swal({
      title: formatMessage('formaAplicacao.desejaExcluir'),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        cancel: {
          text: formatMessage('formaAplicacao.nao'),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: formatMessage('formaAplicacao.excluir'),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnClickOutside: false,
            title: formatMessage('formaAplicacao.excluindo')
          }
        );
        FormaAplicacaoAPI.deleteFormaAplicacaoById(itemSelect.idFormaAplicacao)
          .then(() => {
            swal(formatMessage('formaAplicacao.formaAplicacaoRemovidoComSucesso'), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              atualizarRegistros(itemSelect.idFormaAplicacao);
            });
          })
          .catch(() => {
            swal(formatMessage('formaAplicacao.falhaAoExcluirFormaAplicacao'), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    });
  }

  /**
   * Atualiza a grid após deletar item
   * @param {*} idFormaAplicacaoDeleted
   */
  function atualizarRegistros(idFormaAplicacaoDeleted) {
    let ajusteRegistros = dataAppSync.filter(data => {
      return idFormaAplicacaoDeleted !== data.idFormaAplicacao;
    });

    setDataAppSync([...ajusteRegistros]);
    setDataInTable(FilterUtil.applyFilters(ajusteRegistros, chipData));
  }

  return (
    <div className={classes.card}>
      {loading && <Loading />}
      <CardFilters
        filtros={operationTypeString}
        usePaddings={false}
        onFilter={onFilter}
        onFilterChanged={onFilterChanged}
        viewKey='forma-aplicacao'
      />
      <GroupButtonGrid
        showAdd={false}
        showEdit={true}
        showDelete={true}
        disableEdit={disabledEdit}
        disableDelete={disabledEdit}
        onClickDelete={clickExcluir}
        onClickEdit={clickEditar}
      />

      <div className={classes.cardGrid}>
        <DataTable
          data={dataInTable}
          columns={head}
          selectChange={selectChange}
          showPagination={true}
          pagination={{ length: dataInTable.length, page: 0 }}
        />
      </div>

      <ButtonFAB icon={iconAdd} positionDefault={true} onClick={clickAdicionar} />
    </div>
  );
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'forma-aplicacao';
  })[0].content
});

let enhaced = withStyles(styles)(FormaAplicacao);
export default withRouter(connect(mapStateToProps)(enhaced));
