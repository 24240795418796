export default {
  fertilizantesOrganicos: {
    fertilizantesOrganicos: 'Fertilizantes orgánicos',
    cadastroDeFertilizantesOrganicos: 'Registro fertilizantes orgánicos',
    fertilizantesOrganicosRemovidoComSucesso: 'Fertilizante orgánico quitado con éxito',
    falhaAoExcluirFertilizantesOrganicos: 'Fallo la excluir fertilizante orgánico',
    desejaExcluir: '¿Desea eliminar el fertilizante orgánico seleccionada?',
    identificacaoDaFertilizantesOrganicos: 'Identificación',
    fertilizantesOrganicosadastradoComSucesso: 'Fertilizante orgánico registrada con éxito',
    fertilizantesOrganicosEditadoComSucesso: 'Fertilizante orgánico editado con éxito',
    falhaAoEditarFertilizantesOrganicos: 'Falha ao editar fertilizante orgánico',
    falhaAoCadastrarFertilizantesOrganicos: 'Error al editar fertilizante orgánico',
    falhaAoCarregarFertilizantesOrganicos: 'Error al cargar fertilizante orgánico'
  }
};
