import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './stylesCardTitle';

/**
 * Um componente com o título do Card separado
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {Object} props - props
 * @returns {JSX.Element} Componente React
 */
function CardTitle(props) {
  const { classes, title, style, containerStyle, subtitle } = props;

  return (
    <div className={classes.containerTitle} style={{ ...containerStyle }}>
      <div className={classes.cardLabel}>
        <div className={classes.title} style={{ ...style }}>
          {title}
        </div>
        {subtitle && (
          <div className={classes.subtitle} style={{ ...style }}>
            <div className={classes.separator}>-</div>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(CardTitle);
