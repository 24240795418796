export default {
  gerente: {
    titulo: "Gerente",
    nome: "Nombre",
    email: "E-mail",
    telefone: "Teléfono",
    situacao: "Situación",
    ativo: "Activo",
    inativo: "Inactivo",
    campoObrigatorio: "Campo obligatorio",
    identificacao: "Identificación",
    cadastro: "Registro de gerente",
    salvar: "Guardar",
    cancelar: "Cancelar",
    emailInvalido: "E-mail no válido",
    naoFoiPossivelInserir: "No se pudo insertar el gerente",
    salvando: "Salvando",
    atualizando: "Actualizando",
    cadastradoComSucesso: "Gerente registrado correctamente",
    editadoComSucesso: "Gerente editado con éxito",
    falhaAoCarregar: "Gerente editado con éxito",
    naoFoiPossivelEditar: "No se pudo editar gerente",
    emailDuplicado: "Este correo electrónico ya está en uso",
    numeroMaximoGerente: "Se ha alcanzado el límite de usuarios de este plan",
    falhaAoExcluirGerente: "Fallo al excluir gerente",
    gerenteExcluidoComSucesso: "Gerente eliminado con éxito",
    excluir: "Borrar",
    excluindo: "Excluyendo",
    desejaExcluir: "¿Desea eliminar?",
    nao: "No",
    telefoneInvalido: "Teléfono no válido"
  }
};
