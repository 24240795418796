import React, { useState } from 'react';
import formatMessage from './../i18n/formatMessage';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Add from '@material-ui/icons/Add';
import Chip from '@components/Chip/Chip';

/**
 * Grid de cadastro e listagem de talhões
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const Talhao = props => {
  const {
    classes,
    talhaoList,
    loadTalhaoList,
    addTalhaoInList,
    talhaoNaoConformidadeList,
    deleteTalhaoPlantio,
    isTecnico
  } = props;
  const [talhaoPlantio, setTalhaoPlantio] = useState(null);
  const [keyTalhaoAutoComplete, setkeyTalhaoAutoComplete] = useState(0);

  function tratarValorTalhaoPlantio() {
    if (!talhaoPlantio) {
      return '';
    }

    return talhaoPlantio;
  }

  function submit(values, { resetForm }) {
    addTalhaoInList(values.talhaoPlantio);

    resetForm({
      talhaoPlantio: null
    });
    setTalhaoPlantio(null);
    setkeyTalhaoAutoComplete(Math.random());
  }

  return (
    <Formik
      initialValues={{
        talhaoPlantio: null
      }}
      validateOnBlur
      enableReinitialize={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        talhaoPlantio: Yup.object()
          .nullable()
          .required(formatMessage('auditoria.campoObrigatorio'))
      })}
      onSubmit={submit}
      render={({ values, handleSubmit, errors, touched, setFieldValue }) => (
        <div style={{ width: '100%', marginTop: 30 }}>
          <Grid container spacing={16} style={{ height: 70, marginTop: 30 }}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={isTecnico}
                itens={talhaoList}
                campoOp='dsTalhao'
                campoChave='idTalhao'
                label={formatMessage('auditoria.talhao')}
                name='talhaoPlantio'
                error={!talhaoPlantio && touched.talhaoPlantio}
                helperText={
                  touched.talhaoPlantio && !talhaoPlantio
                    ? formatMessage('auditoria.campoObrigatorio')
                    : null
                }
                onChangeOwn={value => {
                  setFieldValue('talhaoPlantio', value);
                  setTalhaoPlantio(value);
                }}
                onChangeAutoComplete={loadTalhaoList}
                value={tratarValorTalhaoPlantio()}
                valueAutoComplete={tratarValorTalhaoPlantio()}
                valueSelect={tratarValorTalhaoPlantio().dsTalhao}
                maxHeight='calc(100vh - 250px)'
                key={keyTalhaoAutoComplete}
              />
            </Grid>

            {!isTecnico && (
              <Grid item xs={1} className={classes.cardButton}>
                <Button className={classes.button} onClick={handleSubmit}>
                  <Add className={classes.iconButton} />
                </Button>
              </Grid>
            )}
          </Grid>

          <div className={classes.fieldTalhao}>{formatMessage('auditoria.talhoes')}</div>

          <div className={classes.cardChips}>
            {talhaoNaoConformidadeList
              .filter(talhaoNaoConformidade => talhaoNaoConformidade.stRegistro !== 2)
              .map((talhaoNaoConformidade, i) => (
                <Chip
                  key={i}
                  hiddenClose={isTecnico}
                  text={talhaoNaoConformidade.plantioTalhao.talhao.dsTalhao}
                  onRemove={() => deleteTalhaoPlantio(talhaoNaoConformidade)}
                />
              ))}
          </div>
        </div>
      )}
    />
  );
};

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(Talhao);
