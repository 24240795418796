import React from 'react';
import { Chip } from '@material-ui/core';
import { style } from '../AcompanhamentoProdutor.styles';
import { withStyles } from '@material-ui/core/styles';

/**
 * Componente de lista de chips de descrição de talhão
 */
function ChipsTalhao(props) {
  const { classes, chipsList } = props;

  return (
    <div>
      {chipsList.map((tal, i) => (
        <Chip label={tal.talhao.dsTalhao} variant='outlined' key={i} className={classes.chip} />
      ))}
    </div>
  );
}

export default withStyles(style)(ChipsTalhao);
