/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import { IntlProvider, intlShape, injectIntl } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import iconAdd from '@images/icone_add.png';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Marca from '@resources/Marcas';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  }
});

/*
    Tela de Listagem de Marcas customizada
*/
class Marcas extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const descricao = messagesLinguage['marcas.descricao'];
    const desejaExcluir = messagesLinguage['marcas.desejaExcluir'];
    const nao = messagesLinguage['marcas.nao'];
    const excluindo = messagesLinguage['marcas.excluindo'];
    const excluir = messagesLinguage['marcas.excluir'];
    const falhaAoExcluirMarca = messagesLinguage['marcas.falhaAoExcluirMarca'];
    const marcaRemovidaComSucesso = messagesLinguage['marcas.marcaRemovidaComSucesso'];
    const situacao = messagesLinguage['marcas.situacao'];
    const ativo = messagesLinguage['marcas.ativo'];
    const inativo = messagesLinguage['marcas.inativo'];

    const intlProvider = new IntlProvider({
      locale: getUserLang(),
      messages: {
        descricao,
        desejaExcluir,
        nao,
        excluindo,
        excluir,
        falhaAoExcluirMarca,
        marcaRemovidaComSucesso,
        situacao,
        ativo,
        inativo
      }
    });

    provider = intlProvider.getChildContext();

    this.state = {
      operationTypeString: [
        {
          label: provider.intl.formatMessage({ id: 'descricao' }),
          field: 'nmMarca',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'situacao' }),
          field: 'stMarca',
          type: 'enum',
          options: [
            {
              label: provider.intl.formatMessage({ id: 'ativo' }),
              field: 'ativo',
              value: '1'
            },
            {
              label: provider.intl.formatMessage({ id: 'inativo' }),
              field: 'inativo',
              value: '0'
            }
          ]
        }
      ],
      disabledEdit: true,
      itemSelect: '',
      dataAppSync: [],
      dataInTable: [],
      loading: true,
      disableMarca: true,
      head: [
        {
          field: 'nmMarca',
          headerName: provider.intl.formatMessage({ id: 'descricao' }),
          sort: 'asc',
          col: 6
        },
        {
          field: 'nmStMarca',
          headerName: provider.intl.formatMessage({ id: 'situacao' }),
          col: 6
        }
      ]
    };

    const title = 'marcas.marcas';
    if (this.props.itensState.name !== 'marcas.marcas') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.onFilter = this.onFilter.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
  }
  componentWillUnmount() {
    this.isCancelled = true;
  }
  componentDidMount() {
    this.onFilter(this.props.chipData);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false,
        disableMarca: select.idCliente === null
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true,
        disableMarca: true
      });
    }
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    Marca.findAllMarca()
      .then(query => {
        var data = query.data.map(doc => {
          return {
            idMarca: doc.idMarca,
            nmMarca: doc.nmMarca,
            stMarca: doc.stMarca,
            nmStMarca:
              doc.stMarca === 1
                ? provider.intl.formatMessage({ id: 'ativo' })
                : provider.intl.formatMessage({ id: 'inativo' })
          };
        });

        !this.isCancelled &&
          this.setState({
            dataAppSync: data,
            dataInTable: FilterUtil.applyFilters(data, this.props.chipData),
            loading: false
          });
      })
      .catch(() => {
        !this.isCancelled &&
          this.setState({
            dataAppSync: [],
            loading: false
          });
      });
  }

  clickAdicionar() {
    this.props.history.push('/app/marcas/novo');
  }

  clickEditar() {
    this.props.history.push('/app/marcas/editar/' + this.state.itemSelect.idMarca);
  }

  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnClickOutside: false,
            title: provider.intl.formatMessage({ id: 'excluindo' })
          }
        );

        Marca.deleteMarcaById(this.state.itemSelect.idMarca)
          .then(data => {
            swal(provider.intl.formatMessage({ id: 'marcaRemovidaComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });

            var ajusteRegistros = this.state.dataAppSync.filter(marca => {
              return this.state.itemSelect.idMarca !== marca.idMarca;
            });

            this.setState({
              dataAppSync: ajusteRegistros,
              dataInTable: ajusteRegistros
            });

            this.onFilterChanged(this.props.chipData);
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: 'falhaAoExcluirMarca' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    });
  }

  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'scroll'
          }}
        >
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationTypeString}
            usePaddings={false}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            viewKey='marcas'
          />
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disableMarca}
            onClickEdit={this.clickEditar}
            onClickDelete={this.clickExcluir}
          />

          <div
            style={{
              marginTop: 30,
              marginBottom: 15,
              display: 'contents',
              width: '100%',
              flex: 1
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={this.clickAdicionar} />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'marcas';
  })[0].content
});

Marcas.propTypes = {
  intl: intlShape.isRequired
};

let enhaced = injectIntl(withStyles(styles)(Marcas));
export default withRouter(connect(mapStateToProps)(enhaced));
