export default {
  menu: {
    ecosocial: 'Ecosocial',
    certificacoes: 'Certificações',
    sazonalidades: 'Sazonalidades',
    animal: 'Animal',
    plantios: 'Plantios',
    municipio: 'Municípios',
    comunidades: 'Comunidades',
    nivel: 'Nível tecnologico',
    tecnicos: 'Técnicos',
    tecnico: 'Técnico',
    produtores: 'Produtores',
    nematicidasMicrobiologicos: 'Nematicidas Microbiológicos',
    foliares: 'Foliares',
    comparacao: 'Comparação entre técnicos',
    acompanhamento: 'Acompanhamento',
    culturas: 'Culturas',
    cultivares: 'Cultivar',
    fungicidas: 'Fungicidas',
    fungicidasMicroBio: 'Fungicidas Microbiológicos',
    inseticidas: 'Inseticidas',
    inseticidasMicroBio: 'Inseticidas microbiológicos',
    cotacoes: 'Cotações',
    bactericidasMicrobiologicos: 'Bactericidas microbiológicos',
    nematicidas: 'Nematicidas',
    agentesBiologicosControle: 'Agentes biológicos',
    clientes: 'Clientes',
    herbicidas: 'Herbicidas',
    acaricidas: 'Acaricidas',
    bactericidas: 'Bactericidas',
    acaricidasMicrobio: 'Acaricidas microbiológicos',
    marcas: 'Marcas',
    cadastro: 'Cadastros',
    fertilizantes: 'Foliares e Adjuvantes',
    analise: 'Análises',
    pessoas: 'Pessoas',
    localizacao: 'Localização',
    produtos: 'Produtos',
    acaros: 'Ácaros',
    bacterias: 'Bactérias',
    ervasDaninhas: 'Ervas Daninhas',
    fungos: 'Fungos',
    insetos: 'Insetos',
    nematoides: 'Nematóides',
    agenda: 'Agenda',
    admin: 'Administração',
    pesticidas: 'Pesticidas',
    acompanhamentoProdutor: 'Acompanhamento ao produtor',
    produtor: 'Produtor',
    financeiro: 'Financeiro',
    revenda: 'Gerente',
    oleosAdjuvantes: 'Óleos e Adjuvantes',
    agendaVisitas: 'Agenda de Visitas',
    fitossanidade: 'Fitossanidade',
    fertilizantesFoliares: 'Fertilizantes',
    quimico: 'Químico',
    organico: 'Orgânico',
    mapa: 'Mapas',
    mapandvi: 'Mapas NDVI',
    especies: 'Espécies',
    racas: 'Raças',
    naoConformidade: 'Não conformidade',
    regiao: 'Região',
    relatorio: 'Relatórios',
    produtorPotencial: 'Produtores em potencial',
    relacaoTalhao: 'Relação de talhões',
    analisesRiscos: 'Análises de riscos',
    analitico: 'Analítico',
    sintetico: 'Sintético',
    cadastroDeProdutor: 'Cadastro do Produtor',
    listaProdutores: 'Lista de produtores',
    resumoVisitas: 'Visitas por produtor',
    visitas: 'Visitas',
    safras: 'Safras',
    produtividade: 'Produtividade',
    graosProdutor: 'Grãos por produtor',
    inoculantesHomeopatia: 'Inoculantes e Homeopatia',
    homeopaticos: 'Produtos Homeopáticos',
    inoculantes: 'Inoculantes',
    historicoProdutividade: 'Histórico de produtividade',
    galeria: 'Galeria',
    classificacao: 'Uso e Ocupação do Solo',
    certificacaoOrganica: 'Certificações orgânicas',
    agencia: 'Agência',
    sancaoCategoria: 'Sanção',
    producao: 'Produção',
    medidaCorretiva: 'Medida corretiva',
    adversidades: 'Adversidades',
    cooperativismo: 'Cooperativismo',
    auditoria: 'Auditoria',
    acompanhamentoVisitas: 'Acompanhamento de visitas',
    analiseOGM: 'Análise OGM',
    formaAplicacao: 'Forma de aplicação'
  }
};
