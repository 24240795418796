import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatMessage from '../i18n/formatMessage';
import EditarUsuarios from './EditarUsuarios';
import SwipeableViews from 'react-swipeable-views';
import { style } from './../Clientes.styles';
import CardTitle from '@components/CardTitle/CardTitle';

class Usuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      statusEdit: true,
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      check: false,
      openSwal: false,
      usuariosAdicionados: [],
      key: 0,
      listUsuarios: [],
      headUsuarios: [
        {
          field: 'nmPerfil',
          headerName: formatMessage('clientes.perfil'),
          sort: 'asc',
          col: 2
        },
        {
          field: 'nmUsuario',
          headerName: formatMessage('clientes.nome'),
          col: 4
        },
        {
          field: 'dsEmail',
          headerName: formatMessage('clientes.email'),
          col: 4
        },
        {
          field: 'nmStUsuario',
          headerName: formatMessage('clientes.situacao'),
          col: 2
        }
      ]
    };

    this.clickEditar = this.clickEditar.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
    this.renderIfIndex = this.renderIfIndex.bind(this);
  }

  clickEditar() {
    this.setState({
      index: 1,
      openModal: true,
      statusEdit: true,
      check: this.state.itemSelect.stUsuario === 1 ? true : false
    });

    this.props.clickAdicionarUsuarios(this.state.itemSelect);
  }

  selectChange(select) {
    if (select !== undefined) {
      if (select) {
        this.setState({
          itemSelect: select,
          disabledEdit: false
        });
      }
    } else {
      this.setState({
        disabledEdit: true
      });
    }
  }

  clickAdicionar() {
    this.setState({
      openModal: true,
      statusEdit: false,
      check: true
    });
  }

  clickDelete() {
    this.props.onClickDeleteUsuarios(this.state.itemSelect);
  }

  renderIfIndex(index, Component) {
    return index === this.state.index ? (
      <div key={index} style={{ height: '100%' }}>
        {Component}
      </div>
    ) : (
      <div key={index} />
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <SwipeableViews
        className={classes.swipeable}
        index={this.state.index}
        onChangeIndex={this.handleChangeIndex}
        ignoreNativeScroll={true}
        containerStyle={{ height: '100%' }}
        slideStyle={{ overflow: 'hidden' }}
      >
        {[
          this.renderIfIndex(
            0,
            <div key={0} className={classes.card}>
              <CardTitle
                containerStyle={{ marginBottom: -37 }}
                title={formatMessage('clientes.usuarios')}
              />

              <GroupButtonGrid
                showAdd={true}
                showEdit={true}
                showDelete={true}
                disableAdd={!this.props.status}
                disableEdit={
                  this.state.itemSelect.tpPerfil === 2 || !this.props.status
                    ? true
                    : this.state.disabledEdit
                }
                disableDelete={
                  this.state.itemSelect.tpPerfil === 2 || !this.props.status
                    ? true
                    : this.state.disabledEdit
                }
                onClickAdd={() => {
                  this.setState({
                    index: 1
                  });
                  this.props.clickAdicionarUsuarios();
                }}
                onClickEdit={this.clickEditar}
                onClickDelete={this.clickDelete}
              />

              <DataTable
                keyData={this.props.keyData}
                usePaddings={false}
                data={this.props.data}
                columns={this.state.headUsuarios}
                selectChange={this.selectChange}
                showPagination={false}
                key={this.state.key}
              />
            </div>
          ),
          this.renderIfIndex(
            1,
            <div
              key={1}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
            >
              <EditarUsuarios
                selectUsuarios={this.props.selectUsuarios}
                onClickCancelar={() => {
                  this.setState({
                    index: 0
                  });
                  this.props.onClickCancelar();
                }}
                data={this.props.data || []}
                status={this.state.check}
                onClickUsuarios={(values, position) => {
                  this.setState({
                    index: 0
                  });
                  this.props.onClickUsuarios(values, position);
                }}
                itensPerfil={this.props.itensPerfil}
                idCliente={this.props.idCliente}
                onClickDeleteUsuarios={(values, position) => {
                  this.setState({
                    index: 0
                  });
                  this.props.onClickDeleteUsuarios(values, position);
                }}
              />
            </div>
          )
        ]}
      </SwipeableViews>
    );
  }
}

Usuarios.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(Usuarios);
export default withRouter(connect(mapStateToProps)(enhaced));
