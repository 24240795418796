import React, { useState, useEffect } from 'react';
import { CardContent, Grid } from '@material-ui/core';
import formatMessage from './i18n/formatMessage';
import Cronograma from '@components/Cronograma/Cronograma';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Card from '@components/Card/Card';
import iconAplicacoes from '@images/icon_aplicacoes.png';
import { withRouter } from 'react-router-dom';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import { connect } from 'react-redux';
import * as moment from 'moment';
import '@styles/Acompanhamento.css';
import DrawerProdutores from '@components/ListDetalhes/DrawerProdutores';
import Loading from '@components/Loading/Loading';
import AcompanhamentoProdutores from '@resources/AcompanhamentoProdutor';
import Produtores from '@resources/Produtores';
import Talhao from '@resources/Talhao';
import { style, theme } from './AcompanhamentoProdutor.styles';
import TipoAreaEnum from './enumerations/TipoAreaEnum';
import TipoItemTimelineEnum from './enumerations/TipoItemTimelineEnum';
import TipoItemTimelineBackendEnum from './enumerations/TipoItemTimelineBackendEnum';
import TipoDetalhesEnum from './enumerations/TipoDetalhesEnum';
import AtividadeURLEnum from './enumerations/AtividadeUrlEnum';
import { findItemByTimeline, formatDataByLinguage } from './utils/TratarDadosCard';
import { swalOptions, swalError, swalLoading, swalSucess } from './utils/SwalOptions';
import TipoFiltroEnum from './enumerations/TipoFiltroEnum';
import CardDescricao from './CardDescricao/CardDescricao';
import TipoAcompanhamentoEnum from './enumerations/TipoAcompanhamentoEnum';
import FabButtonList from './utils/FabButtonList';

const iconMenuPontos = require('@images/icone_fab_menu.png');

function AcompanhamentoProdutor(props) {
  const { classes } = props;
  const idProdutorURL = props.match.params.idProdutor;
  const [allListFilterDate, setAllListFilterDate] = useState([]);
  const [allTimeline, setAllTimeline] = useState([]);
  const [talhaoProdutorSelecionadoList, setTalhaoProdutorSelecionadoList] = useState([]);
  const [plantioProdutorSelecionadoList, setPlantioProdutorSelecionadoList] = useState([]);
  const [talhaoProdutorSelect, setTalhaoProdutorSelect] = useState('');
  const [plantioProdutorSelect, setPlantioProdutorSelect] = useState('');
  const [error, setError] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [produtorList, setProdutorList] = useState([]);
  const [position, setPosition] = useState('');
  const [timelineList, setTimelineList] = useState([]);
  const [tipoInfo, setTipoInfo] = useState(0);
  const [mostrarInfo, setMostrarInfo] = useState(false);
  const [produtorSelecionado, setProdutorSelecionado] = useState(null);
  const [limpar, setLimpar] = useState(false);
  const [keyProdutorSelecionado, setKeyProdutorSelecionado] = useState('');
  const [loading, setLoading] = useState(true);
  const buttonsActions = loadButtonsAndRedirect();
    
  useEffect(() => {
    const title = 'acompanhamentoProdutor.acompanhamentoProdutor';
    if (props.itensState.name !== title) {
      props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: formatMessage(title),
        name: title
      });
    }

    getProdutores();
  }, []);

  useEffect(() => {
    if (idProdutorURL === 'novo' && (produtorSelecionado !== null || !openDrawer)) {
      setOpenDrawer(true);
      setProdutorSelecionado(null);
      setMostrarInfo(false);
      setKeyProdutorSelecionado(Math.random());
    }
  }, [produtorSelecionado, idProdutorURL]);

  /**
   * Carrega os botões do FAB com as rotas correspondentes
   */
  function loadButtonsAndRedirect() {
    let buttonsActionsAux = [];

    FabButtonList.forEach(button => {
      buttonsActionsAux.push({
        icon: button.icon,
        name: formatMessage(`acompanhamentoProdutor.${button.name}`),
        onClickIcon: () => props.history.push(getUrlToRedirect(button.tpAcompanhamento)),
        color: '#42ADE8',
        disabled: error
      });
    });

    return buttonsActionsAux;
  }

  /**
   * Monta a URL que o front redirecionará ao clicar no FAB da listagem de acompanhamentos
   *
   * @param {*} tpAcompanhamento - Tipo do acompanhamento
   * @returns - Rota a ser redirecionado
   */
  function getUrlToRedirect(tpAcompanhamento) {
    if (tpAcompanhamento === TipoAcompanhamentoEnum.MONITORAMENTO) {
      return `/app/acompanhamento-produtor/monitoramento/editar/${idProdutorURL}/novo`;
    }
    return `/app/acompanhamento-produtor/editar/${idProdutorURL}/${tpAcompanhamento}/novo`;
  }

  /**
   * Busca a lista de todos os produtores e verifica se há um produtor selecionado
   */
  function getProdutores() {
    Produtores.findProdutor()
      .then(query => {
        let produtorListAux = query.data;
        tratarProdutorSelecionado(produtorListAux);
      })
      .catch(() => {
        setKeyProdutorSelecionado(Math.random());
        setProdutorList([]);
        if (idProdutorURL === 'novo') {
          setLoading(false);
        }
        setError(true);
      });
  }

  /**
   * Verifica o tipo do perfil e realiza o tratamento dos dados
   *
   * @param {*} produtorListAux - lista de todos os produtores
   * @param {*} idProdutorURL - id do produtor selecionado
   */
  function tratarProdutorSelecionado(produtorListAux) {
    let produtorSelecionadoAux = null;

    if (props.infoUsuario.tpPerfil === 3) {
      produtorSelecionadoAux = produtorListAux.find(doc => {
        return props.infoUsuario.dsEmail === doc.dsEmail;
      });

      produtorListAux = [produtorSelecionadoAux];
    } else if (idProdutorURL !== 'novo') {
      produtorSelecionadoAux = produtorListAux.find(doc => {
        return parseInt(idProdutorURL) === doc.idProdutor;
      });

      redirectToProdutorSelecionado(produtorSelecionadoAux);
    } else {
      setLoading(false);
    }

    setProdutorSelecionado({ ...produtorSelecionadoAux });
    setKeyProdutorSelecionado(Math.random());
    setProdutorList(produtorListAux);
  }

  /**
   * Redireciona para a rota do produtor selecionado e solicita as informaçoes do plantio
   *
   * @param {*} produtor
   */
  function redirectToProdutorSelecionado(produtor) {
    if (produtor) {
      setAllTimeline([]);
      setAllListFilterDate([]);
      setTimelineList([]);
      setLoading(true)
      props.history.push('/app/acompanhamento-produtor/' + produtor.idProdutor);
      getPlantio(produtor);
    }

    setPosition('');
    setMostrarInfo(false);
    setLimpar(true);
    setOpenDrawer(false);
    setProdutorSelecionado(produtor)
    setTalhaoProdutorSelect('');
    setPlantioProdutorSelect('');
  }

  /**
   * Busca as informações correspondentes de acordo com o plantio selecionado
   *
   * @param {*} produtor - produtor selecionado
   */
  function getPlantio(produtor) {
    findAllTalhaoByProdutor(produtor.idProdutor);
    findAcompanhamentoByIdProdutor(produtor);
  }

  /**
   * Busca a lista de todos os talhões do produtor selecionado
   *
   * @param {*} idProdutor - id do produtor selecionado
   */
  function findAllTalhaoByProdutor(idProdutor) {
    Talhao.findAllTalhaoByProdutor(idProdutor)
      .then(query => {
        const talhaoList = query.data;
        const areaCultivoList = [];

        talhaoList.forEach(talhao => {
          const indexAreaCultivo = areaCultivoList.findIndex(
            areaCultivo => areaCultivo.idAreaCultivo === talhao.areaCultivo.idAreaCultivo
          );

          if (indexAreaCultivo !== -1) {
            areaCultivoList[indexAreaCultivo].childrens.push({ ...talhao });
          } else {
            areaCultivoList.push({ ...talhao.areaCultivo, childrens: [{ ...talhao }] });
          }
        });

        setTalhaoProdutorSelecionadoList(areaCultivoList);
      })
      .catch(() => {
        setTalhaoProdutorSelecionadoList([]);
      });
  }

  /**
   * Busca as informações do acompanhamento de acordo com o produtor selecionado
   *
   * @param {*} produtor - produtor selecionado
   */
  function findAcompanhamentoByIdProdutor(produtor) {
    AcompanhamentoProdutores.findAllAcompanhamento(produtor.idProdutor)
      .then(query => {
        const acompanhamentoList = agruparInformacoesToTimeline(query.data, produtor);
        createItemTodayInAcompanhamentoList(acompanhamentoList);

        setAllTimeline(acompanhamentoList);
        setAllListFilterDate(acompanhamentoList);
        setTimelineList(acompanhamentoList);
        setOpenDrawer(false);
        setLoading(false);
      })
      .catch(() => {
        setAllTimeline([]);
        setAllListFilterDate([]);
        setTimelineList([]);
        setLoading(false);
        setKeyProdutorSelecionado(Math.random());
        setPlantioProdutorSelecionadoList([]);
      });
  }

  /**
   * Separa cada tipo para inserir as informações correspondentes
   *
   * @param {*} acompanhamentoList - lista de todos os acompanhamentos
   * @param {*} produtor - produtor selecionado
   * @returns retorna o acompanhamento agrupado para ser inserido na timeline
   */
  function agruparInformacoesToTimeline(acompanhamentoList, produtor) {
    const acompanhamentoListAgrupado = [];
    let plantioList = [];

    acompanhamentoList.forEach(acompanhamento => {
      let value = {};
      value.info = acompanhamento.data;

      if (acompanhamento.tpItem === TipoItemTimelineBackendEnum.PLANTIO) {
        plantioList.push({
          dsPlantio: montarLabelPlantio(acompanhamento.plantioDTO),
          ...acompanhamento.plantioDTO
        });
      }

      value = findItemByTimeline(acompanhamento, value, produtor).find(
        itemTimeline => itemTimeline.id === acompanhamento.tpItem
      );

      acompanhamentoListAgrupado.push(value.functionToLoad());
    });

    setPlantioProdutorSelecionadoList(plantioList);
    return acompanhamentoListAgrupado;
  }

  /**
   * Monta o label de plantio
   *
   * @param {*} plantio - plantio
   * @returns - label para autocomplete
   */
  function montarLabelPlantio(plantio) {
    const nmCultura = plantio.cultura.nmCultura;
    const nmCultivarEspec = plantio.cultivarEspec.nmCultivarEspec;
    const nmDtPlantio = moment(plantio.dtPlantio).format(formatDataByLinguage());
    const labelPlantio = formatMessage('acompanhamentoProdutor.plantio');
    return `${nmCultura} - ${nmCultivarEspec} - ${labelPlantio}: ${nmDtPlantio}`;
  }

  /**
   * Cria o objeto referente a hoje na lista de acompanhamentos
   *
   * @param {*} acompanhamentoList
   */
  function createItemTodayInAcompanhamentoList(acompanhamentoList) {
    var dataLength = acompanhamentoList.length - 1;
    for (var i = 0; i < acompanhamentoList.length; i++) {
      if (moment(acompanhamentoList[i].dateMoment) < moment()) {
        acompanhamentoList.splice(i, 0, {
          type: -1,
          id: 'today-' + 0,
          dateMoment: moment().utc(),
          date: moment()
            .utc()
            .format(formatDataByLinguage())
        });
        break;
      } else if (i === dataLength) {
        acompanhamentoList.push({
          type: -1,
          date: moment()
            .utc()
            .format(formatDataByLinguage())
        });
        break;
      }
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      {loading && <Loading />}
      <div className={classes.card}>
        <Card
          height='auto'
          cabecalho
          withoutColor
          icon={iconAplicacoes}
          withOutBorderRadius
          disableHeader
          childrenStyle={{ paddingRight: 15, paddingLeft: 15 }}
        >
          <Grid container spacing={16} className={classes.grid}>
            <Grid item xs={8} className={classes.gridWithScroll}>
              <CardContent>
                {timelineList.length > 0 ? (
                  <Cronograma
                    clickDone={SwalEfetivarRecomendacao}
                    showButton={props.infoUsuario.tpPerfil !== 3}
                    listRecomendacoes={timelineList}
                    clickEditar={onClickEditar}
                    clickItem={true}
                    clickCard={clickCard}
                  />
                ) : (
                  !loading && (
                    <div className={classes.textAtividade}>{titleByQuantidadeRegistrosTimeline()}</div>
                  )
                )}
              </CardContent>
            </Grid>
          </Grid>
        </Card>
        <DrawerProdutores
          listPlantiosProdutor={plantioProdutorSelecionadoList}
          listTalhoesProdutor={talhaoProdutorSelecionadoList}
          chipsTalhoes={position !== '' ? timelineList[position] : {}}
          open={openDrawer}
          label={formatMessage('acompanhamentoProdutor.produtor')}
          labelTalhao={formatMessage('acompanhamentoProdutor.talhao')}
          labelPlantio={formatMessage('acompanhamentoProdutor.plantio')}
          dataInicialLabel={formatMessage('acompanhamentoProdutor.dataInicial')}
          dataFinalLabel={formatMessage('acompanhamentoProdutor.dataFinal')}
          timeLine={redirectToProdutorSelecionado}
          prod={produtorSelecionado}
          produtores={produtorList}
          key={keyProdutorSelecionado}
          onChangePlantio={changePlantioSelected}
          onChangeTalhao={changeTalhaoSelected}
          onChangeDate={(dtInicial, dtFinal) =>  filterDate(dtInicial, dtFinal)}
          valueTalhaoProdutor={talhaoProdutorSelect}
          valuePlantioProdutor={plantioProdutorSelect}
          valueAutoComplete={produtorSelecionado}
          disabledProdutor={false}
        >
          {mostrarInfo ? (
            <CardDescricao
              limpar={limpar}
              tipoInfo={tipoInfo}
              timelineList={timelineList}
              position={position}
              stProdutor={props.infoUsuario.tpPerfil !== 3}
            />
          ) : (
            <div />
          )}
        </DrawerProdutores>
      </div>
      {props.infoUsuario.tpPerfil !== 3 && (
        <div
          className={classes.fab}
          style={{
            display: openDrawer ? 'none' : 'flex'
          }}
        >
          <ButtonFABMenu style={{ top: -80 }} icon={iconMenuPontos} actions={buttonsActions} />
        </div>
      )}
    </MuiThemeProvider>
  );

  /**
   * Mensagem de confirmação para efetivar uma recomendação
   * @param {*} recomendacao - recomendação para ser efetivada
   */
  function SwalEfetivarRecomendacao(recomendacao) {
    swalOptions('acompanhamentoProdutor.efetivarRecomendacao')
      .then(willDelete => {
        if (willDelete) {
          swalLoading();
          efetivarRecomendacao(recomendacao);
        }
      })
      .catch(() => {
        swalError('acompanhamentoProdutor.falhaEfetivarRecomendacao');
      });
  }

  /**
   * Realiza o processo do efetivar uma recomendação
   * @param {*} recomendacao
   */
  function efetivarRecomendacao(recomendacao) {
    var recomendacaoToAplicacao = recomendacao.info;
    recomendacaoToAplicacao.tpAtividade = TipoItemTimelineEnum.APLICACAO;
    const unidade = TipoAreaEnum.find(doc => {
      return doc.name === recomendacaoToAplicacao.tpUnidade;
    });
    recomendacaoToAplicacao.tpUnidade = unidade.id;

    AcompanhamentoProdutores.editAcompanhamento(recomendacaoToAplicacao)
      .then(() => {
        swalSucess('sucessoEfetivarRecomendacao');
        updateTimilineAposEfetivar(recomendacao, recomendacaoToAplicacao);
      })
      .cacth(() => {
        swalError('falhaEfetivarRecomendacao');
      });
  }

  /**
   * Atualiza a timeline após efetivar uma recomendação para aplicação
   *
   * @param {*} recomendacao - recomendação a ser efetivada
   * @param {*} recomendacaoToAplicacao - nova aplicação
   */
  function updateTimilineAposEfetivar(recomendacao, recomendacaoToAplicacao) {
    const timelineListAux = [...timelineList];

    const positionAux = timelineListAux.indexOf(recomendacao);

    timelineListAux[positionAux].info = recomendacaoToAplicacao;
    timelineListAux[positionAux].type = TipoItemTimelineEnum.APLICACAO;

    setTimelineList(timelineListAux);
  }

  /**
   * Redireciona para a tela de editar de acordo com o tipo
   *
   * @param {*} itemTimeline
   */
  function onClickEditar(itemTimeline) {
    const initialURL = `/app/acompanhamento-produtor/editar/${idProdutorURL}/`;
    const auditoriaURL = `/app/auditoria/editar/`;
    const monitoriamentoURL = `/app/acompanhamento-produtor/monitoramento/editar/${idProdutorURL}`;
    const idAtividade = itemTimeline.info.idAtividade;

    if (itemTimeline.type <= TipoItemTimelineEnum.ALERTA_FITOSSANITARIO) {
      props.history.push(`${initialURL}${itemTimeline.type}/editar/${idAtividade}`);
    } else if (itemTimeline.type === TipoItemTimelineEnum.MONITORAMENTO) {
      props.history.push(monitoriamentoURL + '/editar/' + idAtividade);
    } else if (itemTimeline.type === TipoItemTimelineEnum.REGISTRO_FOTOGRAFICO) {
      props.history.push(initialURL + AtividadeURLEnum.REGISTRO_FOTOGRAFICO + '/editar/' + idAtividade);
    } else if (itemTimeline.type === TipoItemTimelineEnum.REGISTRO_CLIMATICO) {
      props.history.push(initialURL + AtividadeURLEnum.REGISTRO_CLIMATICO + '/editar/' + idAtividade);
    } else if (itemTimeline.type === TipoItemTimelineEnum.AUDITORIA_PLANTIO) {
      props.history.push(`${auditoriaURL}${itemTimeline.info.idAuditoriaPlantio}/${idProdutorURL}`);
    }
  }

  /**
   * Seleciona o item que o usuário clicou na timeline
   *
   * @param {*} item
   */
  function clickCard(item) {
    let timelineListAux = timelineList;
    let tpCronograma = TipoItemTimelineEnum.RECOMENDACAO;

    if (position !== '') {
      timelineListAux[position].selecionado = false;
    }

    let positionAux = timelineListAux.indexOf(item);
    const atividade = timelineListAux[positionAux];
    if (atividade.type !== TipoItemTimelineEnum.SAZONALIDADE) {
      atividade.selecionado = true;
    }

    if (
      atividade.type === TipoItemTimelineEnum.REGISTRO_CLIMATICO ||
      atividade.type === TipoItemTimelineEnum.REGISTRO_FOTOGRAFICO
    ) {
      tpCronograma = TipoDetalhesEnum.REGISTROS;
    } else if (atividade.type === TipoItemTimelineEnum.AUDITORIA_PLANTIO) {
      tpCronograma = TipoDetalhesEnum.AUDITORIA;
    } else if (atividade.type === TipoItemTimelineEnum.SAZONALIDADE) {
      tpCronograma = null;
    } else if (
      atividade.type > TipoItemTimelineEnum.ALERTA_FITOSSANITARIO &&
      atividade.type !== 5 &&
      atividade.type !== TipoItemTimelineEnum.APLICACAO_PRODUTO_PROIBIDO
    ) {
      tpCronograma = TipoDetalhesEnum.PLANTIO;
    }

    timelineListAux[positionAux] = atividade;

    setTimelineList(timelineListAux);
    setPosition(positionAux);
    setTipoInfo(tpCronograma);
    setMostrarInfo(true);
    setLimpar(false);
  }

  /**
   * Titulo da listagem de produtor se tiver filtro ou se não tiver
   *
   * @returns - retorna o título
   */
  function titleByQuantidadeRegistrosTimeline() {
    if (allTimeline.length === 0) {
      return formatMessage('acompanhamentoProdutor.nenhumaAtividadeRegistrada');
    } else {
      return formatMessage('acompanhamentoProdutor.nenhumResultadoEncontrado');
    }
  }

  /**
   * Trata os dados ao selecionar um plantio
   */
  function changePlantioSelected(plantio, dtInicial, dtFinal) {
    let listPlantio = [];
    if (dtInicial || dtFinal) {
      listPlantio = allListFilterDate;
    } else if (!plantio) {
      listPlantio = allTimeline;
    } else {
      listPlantio = timelineList;
    }

    ajustaListaPlantioInTimeline(plantio, listPlantio);

    const condition = dtInicial || dtFinal ? allListFilterDate : allTimeline;
    if (plantio) {
      aplicarFiltro(condition, TipoFiltroEnum.PLANTIO, plantio);
    } else if (talhaoProdutorSelect.idTalhao) {
      aplicarFiltro(condition, TipoFiltroEnum.TALHAO, talhaoProdutorSelect, true);
    } else {
      clearItemSelecionadoTimeline();
    }
  }

  /**
   * Limpa os dados do plantio selecionado
   */
  function clearItemSelecionadoTimeline() {
    allTimeline.forEach(doc => {
      doc.selecionado = false;
      doc.lessInformation = false;
      doc.stFilterPlantio = false;
    });
  }

  /**
   * Atribui variave para filtro de plantio
   *
   * @param {*} plantio - plantio selecionado
   * @param {*} listPlantio  - lista de todos os plantios
   */
  function ajustaListaPlantioInTimeline(plantio, listPlantio) {
    setPlantioProdutorSelect(plantio);
    setPosition('');
    setMostrarInfo(false);
    setLoading(!plantio ? false : true);
    setTimelineList(listPlantio);
  }

  /**
   * Aplica o filtro de plantio ou talhão
   *
   * @param {*} allList - lista inteira a ser filtrada
   * @param {*} tpFiltro - tipo do filtro - Plantio: 0 / Talhao: 1
   * @param {*} byOther - Se há valor em outros filtros
   */
  function aplicarFiltro(allList, tpFiltro, valueAntigo, byOther = false) {
    setLoading(true);

    var timelineListAux = [];
    allList.forEach(doc => {
      doc.selecionado = false;
      doc.lessInformation = false;
      doc.stFilterPlantio = false;
      if (doc.info && doc.info.plantioTalhaoList && doc.type !== 10) {
        if (tpFiltro === TipoFiltroEnum.PLANTIO) {
          filterPlantio(doc, timelineListAux, valueAntigo);
        } else {
          filtrarTalhao(doc, timelineListAux, valueAntigo);
        }
      } else if (doc.type === -1) {
        timelineListAux.push(doc);
      }
    });

    validationReaplicarFiltro(timelineListAux, tpFiltro, byOther);
    setLoading(false);
  }

  /**
   * Valida se existe outros filtros para serem aplicados
   *
   * @param {*} timelineListAux - lista da timeline
   * @param {*} tpFiltro - tipo do filtro
   * @param {*} byOther - existe outro filtro
   */
  function validationReaplicarFiltro(timelineListAux, tpFiltro, byOther) {
    if (timelineListAux.length === 1 && timelineListAux[0].type === -1) {
      setTimelineList([]);
    } else if (talhaoProdutorSelect.idTalhao && !byOther && tpFiltro === TipoFiltroEnum.PLANTIO) {
      aplicarFiltro(timelineListAux, TipoFiltroEnum.TALHAO, talhaoProdutorSelect, true);
    } else if (plantioProdutorSelect.idPlantio && !byOther && tpFiltro === TipoFiltroEnum.TALHAO) {
      aplicarFiltro(timelineListAux, TipoFiltroEnum.PLANTIO, plantioProdutorSelect, true);
    } else {
      setTimelineList(timelineListAux);
    }
  }

  /**
   * Aplica a condição de filtro para plantio
   *
   * @param {*} acompanhamento - objeto do acompanhamento
   * @param {*} timelineListAux - lista de dados filtrados
   */
  function filterPlantio(acompanhamento, timelineListAux, plantioAntigo) {
    if (acompanhamento.info.plantio) {
      if (acompanhamento.info.plantio.idPlantio === plantioAntigo.idPlantio) {
        timelineListAux.push(acompanhamento);
      }
    } else {
      if (acompanhamento.info.idPlantio === plantioAntigo.idPlantio) {
        timelineListAux.push(acompanhamento);
      }
    }
  }

  /**
   * Aplica a condição de filtro para talhão
   *
   * @param {*} acompanhamento - objeto do acompanhamento
   * @param {*} timelineListAux - lista de dados filtrados
   */
  function filtrarTalhao(acompanhamento, timelineListAux, talhaoAntigo) {
    acompanhamento.info.plantioTalhaoList.forEach(tal => {
      if (tal.talhao.idTalhao === talhaoAntigo.idTalhao) {
        timelineListAux.push(acompanhamento);
      }
    });
  }

  /**
   * Trata as datas a serem filtradas
   */
  function filterDate(dtInicial, dtFinal) {
    let timelineFilteredList = allTimeline;

    if (dtInicial) {
      timelineFilteredList = verificaData(dtInicial, verificaDataInicial, timelineFilteredList);
    }

    if (dtFinal) {
      timelineFilteredList = verificaData(dtFinal, verificaDataFinal, timelineFilteredList);
    }

    setPosition('');
    setMostrarInfo(false);
    setTimelineList(timelineFilteredList);
    setAllListFilterDate(timelineFilteredList);

    allTimeline.forEach(doc => {
      doc.selecionado = false;
    });

    if (talhaoProdutorSelect) {
      aplicarFiltro(timelineFilteredList, TipoFiltroEnum.Talhao, talhaoProdutorSelect);
    } else if (plantioProdutorSelect) {
      aplicarFiltro(timelineFilteredList, TipoFiltroEnum.PLANTIO, plantioProdutorSelect);
    }
  }

  /**
   * Verifica os dados de data para serem filtrados
   *
   * @param {*} dtFiltrada - data que o filtro foi aplicado
   * @param {*} verificaDataFunction - função a ser utlizada
   * @param {*} timelineFilteredList - lista para ser filtrada
   */
  function verificaData(dtFiltrada, verificaDataFunction, timelineFilteredList) {
    return timelineFilteredList.filter(itemTimeline => {
      if (itemTimeline.type === -1) {
        return true;
      }

      return verificaDataFunction(itemTimeline, dtFiltrada);
    });
  }

  /**
   * Condição de filtro de data final
   */
  function verificaDataInicial(itemTimeline, dtFiltrada) {
    return moment(itemTimeline.dateMoment) >= dtFiltrada;
  }

  /**
   * Condição de filtro de data inicial
   */
  function verificaDataFinal(itemTimeline, dtFiltrada) {
    return moment(itemTimeline.dateMoment) <= dtFiltrada;
  }

  /**
   * Trata os dados ao selecionar um talhão
   */
  function changeTalhaoSelected(talhao, dtInicial, dtFinal) {
    let listTalhao = [];
    if (dtInicial || dtFinal) {
      listTalhao = allListFilterDate;
    } else {
      if (!talhao) {
        listTalhao = allTimeline;
      } else {
        listTalhao = timelineList;
      }
    }

    setTalhaoProdutorSelect(talhao);
    setPosition('');
    setMostrarInfo(false);
    setLoading(!talhao ? false : true);
    setTimelineList(listTalhao);

    const condition = dtInicial || dtFinal ? allListFilterDate : allTimeline;
    if (talhao) {
      aplicarFiltro(condition, TipoFiltroEnum.TALHAO, talhao);
    } else if (plantioProdutorSelect.idPlantio) {
      aplicarFiltro(condition, TipoFiltroEnum.PLANTIO, plantioProdutorSelect, true);
    } else {
      clearItemSelecionadoTimeline();
    }
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(AcompanhamentoProdutor);
export default withRouter(connect(mapStateToProps)(enhaced));
