import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import EditarTalhoes from './EditarTalhoes';
import CardTitle from '@components/CardTitle/CardTitle';
import Agenda from '@resources/Agenda';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import swal from '@sweetalert/with-react';
import { connect } from 'react-redux';
import ButtonGrid from '@components/ButtonGrid/ButtonGrid';
import IconMap from '@material-ui/icons/Map';
import Map from '@components/Map/Map';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import Button from '@material-ui/core/Button';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const style = () => ({
  swipeable: {
    width: '100%',
    height: '100%'
  },
  root: {
    overflowY: 'scroll',
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 95px)',
    marginBottom: 15
  }
});

/**
 * Componente de edição de talhão
 *
 * @author Gabriela Farias
 * @class EditarProdutor
 * @extends {Component} - Componente React
 */
class Talhoes extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const descricao = messagesLinguage['produtores.descricao'];
    const area = messagesLinguage['produtores.areaTotaldeTalhoes'];
    const areaCultivo = messagesLinguage['produtores.areaCultivo'];
    const areaDeCultivo = messagesLinguage['produtores.areaDeCultivo'];
    const cadastrarAreaCultivo = messagesLinguage['produtores.cadastrarAreaCultivo'];
    const mapa = messagesLinguage['produtores.mapa'];
    const cancelar = messagesLinguage['produtores.cancelar'];
    const areaHa = messagesLinguage['produtores.area'];
    const salvar = messagesLinguage['produtores.salvar'];
    const nao = messagesLinguage['produtores.nao'];
    const talhao = messagesLinguage['produtores.talhao'];
    const certificacaoOrganica = messagesLinguage['produtores.certificacaoOrganica'];
    const nenhumTalhaoDemarcado = messagesLinguage['produtores.nenhumTalhaoDemarcado'];
    const inativo = messagesLinguage['produtores.inativo'];
    const ativo = messagesLinguage['produtores.ativo'];
    const status = messagesLinguage['produtores.status'];
    const certOrganica = messagesLinguage['produtores.certOrganica'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          descricao,
          area,
          areaCultivo,
          areaDeCultivo,
          cadastrarAreaCultivo,
          mapa,
          cancelar,
          areaHa,
          salvar,
          nao,
          talhao,
          certificacaoOrganica,
          nenhumTalhaoDemarcado,
          inativo,
          ativo,
          status,
          certOrganica
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    var itemSelectTalhao = {
      nrAreaTalhao: 0,
      tpCertificacaoOrganica: '',
      areaCultivo: {
        idAreaCultivo: ''
      },
      talhaoGpsList: []
    };

    const itensUniMedida = ['acre', 'alq.', 'ha', 'm2'];

    let dataHeader = [
      {
        headerName: provider.intl.formatMessage({ id: 'descricao' }),
        field: 'dsTalhao',
        col: 3
      },
      {
        headerName: provider.intl.formatMessage({ id: 'area' }),
        valueGetter: args => {
          if (args.node.data.talhaoGpsList.length > 0 && args.node.data.tpUnidade !== 3) {
            return `${parseFloat(args.node.data.nrAreaTalhao).toLocaleString('pt-br', {
              minimumFractionDigits: 0
            })} ${itensUniMedida[2]}`;
          } else {
            return `${parseFloat(args.node.data.nrAreaTalhao).toLocaleString('pt-br', {
              minimumFractionDigits: 0
            })} ${itensUniMedida[args.node.data.tpUnidade]}`;
          }
        },
        col: 3,
        valueFormatter: valueFormatter
      },
      {
        headerName: provider.intl.formatMessage({
          id: 'areaDeCultivo'
        }),
        field: 'areaCultivo.dsAreaCultivo',
        col: 2
      },
      {
        headerName: provider.intl.formatMessage({
          id: 'status'
        }),
        valueGetter: args =>
          args.node.data.stTalhao === 1
            ? provider.intl.formatMessage({
                id: 'ativo'
              })
            : provider.intl.formatMessage({
                id: 'inativo'
              }),
        col: 1
      }
    ];

    if (this.props.infoUsuario.stOrganica === 1) {
      dataHeader.push({
        headerName: provider.intl.formatMessage({
          id: 'certOrganica'
        }),
        field: 'nmCertificacaoOrganica',
        col: 3
      });
    }

    this.state = {
      editMap: false,
      listTalhoes: [],
      key: Math.random(),
      loading: false,
      index: 0,
      disabledEdit: true,
      itemSelect: itemSelectTalhao,
      openModal: false,
      statusEdit: false,
      itemSelectTalhao: itemSelectTalhao,
      navigatorPosition: {},
      headTalhoes: dataHeader
    };

    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.onClickCancelar = this.onClickCancelar.bind(this);
    this.onClickAddTalhao = this.onClickAddTalhao.bind(this);
    this.onClickDeleteTalhao = this.onClickDeleteTalhao.bind(this);
    this.changeMaps = this.changeMaps.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.onCancelClear = this.onCancelClear.bind(this);
  }

  componentDidMount() {
    this.props.data.forEach(doc => {
      this.props.areaCultivo.forEach(area => {
        if (area.idAreaCultivo === doc.areaCultivo.idAreaCultivo) {
          doc.areaCultivo = area;
        }
      });
    });

    this.setState({
      key: Math.random()
    });
  }

  componentWillUnmount() {
    if (this.state.index === 1 || this.state.index === 2) {
      this.props.changeFAB();
    }
  }

  clickExcluir() {
    const position = this.props.data.indexOf(this.state.itemSelect);

    this.props.onClickDeleteTalhao(position);
  }

  onClickDeleteTalhao(position) {
    this.setState({
      index: 0
    });

    this.props.onClickDeleteTalhao(position);
  }

  clickEditar(map) {
    this.setState(
      {
        index: 1,
        statusEdit: true,
        loading: false,
        itemSelectTalhao: this.state.itemSelect
      },
      () => {
        this.changeMaps(false);

        this.props.changeFAB();

        this.props.clickAdicionarTalhao(this.state.itemSelect);
      }
    );

    if (!map) {
      this.setState({
        index: 1
      });
    }
  }

  changeMaps(idAreaCultivo, map) {
    this.setState({
      loading: false
    });

    if (
      this.state.itemSelectTalhao.talhaoGpsList === undefined ||
      this.state.itemSelectTalhao.talhaoGpsList.length === 0
    ) {
      if (this.props.idProdutor !== undefined) {
        Agenda.findUltimaAgendaProdutor(this.props.idProdutor).then(doc => {
          if (doc.data && doc.data.nrLongitude !== undefined && doc.data.nrLatitude !== undefined) {
            this.setState(
              {
                navigatorPosition: {
                  latitude: doc.data.nrLatitude,
                  longitude: doc.data.nrLongitude
                }
              },
              () => {
                this.setState({
                  loading: true
                });
              }
            );
          }
        });
      }
    }

    var listTalhoes = [];
    var areaCultivo =
      idAreaCultivo !== undefined && idAreaCultivo !== false
        ? idAreaCultivo
        : this.state.itemSelectTalhao.areaCultivo.idAreaCultivo;

    this.props.data.forEach(doc => {
      if (doc.areaCultivo.idAreaCultivo === areaCultivo) {
        listTalhoes.push(doc);
      }
    });

    if (map) {
      if (
        this.state.itemSelect.talhaoGpsList === undefined ||
        this.state.itemSelect.talhaoGpsList.length === 0
      ) {
        swal({
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          },
          icon: 'warning',
          text: provider.intl.formatMessage({ id: 'nenhumTalhaoDemarcado' })
        });
      } else {
        this.props.changeFAB();
        this.setState({
          index: 2
        });
      }
    }

    this.setState(
      {
        listTalhoes: listTalhoes
      },
      () => {
        this.getPosition();
      }
    );
  }

  getPosition() {
    if (this.props.municipio !== null && this.props.municipio !== '') {
      var geoLocation = new window.google.maps.Geocoder();
      geoLocation.geocode(
        {
          address:
            this.props.municipio.nmMunicipio +
            ', ' +
            this.props.municipio.uf.nmUf +
            ', ' +
            this.props.pais.nmPais
        },
        event => {
          if (event && event.length > 0) {
            this.setState({
              loading: true,
              navigatorPosition: {
                latitude: event[0].geometry.location.lat(),
                longitude: event[0].geometry.location.lng()
              }
            });
          }
        }
      );
    } else {
      this.setState({
        loading: true
      });
    }
  }

  handleChangeIndex(index) {
    this.setState({
      index: index
    });
  }

  selectChange(select) {
    if (select) {
      select.talhaoGpsListConvert = select.talhaoGpsList.map(doc => {
        return {
          lat: doc.nrLatitude,
          lng: doc.nrLongitude
        };
      });

      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: [],
        disabledEdit: true
      });
    }
  }

  onClickCancelar() {
    this.setState({
      index: 0
    });

    this.props.onClickCancelar(0);
  }

  onClickAddTalhao(values, position) {
    this.setState(
      {
        editMap: false,
        index: 0,
        statusEdit: false,
        itemSelect: {},
        itemSelectTalhao: {
          nrAreaTalhao: 0,
          tpCertificacaoOrganica: '',
          areaCultivo: {
            idAreaCultivo: ''
          },
          talhaoGpsList: []
        }
      },
      () => {
        this.props.onClickAddTalhao(values, position);

        this.setState({
          key: Math.random()
        });

        this.onCancelClear();
      }
    );
  }

  onCancelClear() {
    this.props.changeFAB();

    this.setState({
      editMap: false,
      index: 0,
      itemSelect: {},
      itemSelectTalhao: {
        nrAreaTalhao: '',
        tpCertificacaoOrganica: '',
        areaCultivo: {
          idAreaCultivo: ''
        },
        talhaoGpsList: []
      },
      key: Math.random(),
      disabledEdit: true,
      statusEdit: false
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <SwipeableViewsOwn index={this.state.index} onChangeIndex={index => this.setState({ index })}>
          {[
            <div key={0} className={classes.card}>
              <CardTitle
                containerStyle={{ marginBottom: this.props.tpPerfil ? 0 : 'none' }}
                title={provider.intl.formatMessage({ id: 'talhao' })}
              />

              {!this.props.tpPerfil && (
                <GroupButtonGrid
                  hiddenMargin
                  withoutMargin
                  showAdd
                  showEdit={true}
                  showDelete={true}
                  disableEdit={this.state.disabledEdit}
                  disableDelete={this.state.disabledEdit}
                  customButtons={[
                    <ButtonGrid
                      hiddenMargin
                      withoutMargin={false}
                      show={true}
                      iconSVG={true}
                      icon={<IconMap style={{ color: '#FFFFFF' }} />}
                      color='#463766'
                      disabled={this.state.disabledEdit}
                      onClick={() => {
                        this.changeMaps(undefined, true);
                      }}
                    />
                  ]}
                  onClickAdd={() => {
                    this.setState({ index: 1 });
                    this.props.changeFAB();
                    this.changeMaps();
                  }}
                  onClickEdit={this.clickEditar}
                  onClickDelete={this.clickExcluir}
                />
              )}

              <DataTable
                key={this.state.key}
                noMargin={true}
                borderRadius={false}
                data={this.props.data !== undefined ? this.props.data : []}
                columns={this.state.headTalhoes}
                selectChange={this.selectChange}
                showPagination={false}
              />
            </div>,
            <div key={1} className={classes.card} style={{ height: '100%' }}>
              {this.state.index === 1 && (
                <EditarTalhoes
                  classificacaoList={this.props.classificacaoList}
                  certificacaoList={this.props.certificacaoList}
                  changeMap={this.changeMaps}
                  changeFAB={this.props.changeFAB}
                  onCancelClear={this.onCancelClear}
                  statusEdit={this.state.statusEdit}
                  onClickDeleteTalhao={this.onClickDeleteTalhao}
                  onClickAddTalhao={this.onClickAddTalhao}
                  maxIdTalhao={this.props.maxIdTalhao}
                  onClickCancelar={this.onClickCancelar}
                  clickAdicionarTalhao={this.props.clickAdicionarTalhao}
                  areaCultivo={this.props.areaCultivo}
                  selectTalhao={this.props.selectTalhao}
                  data={this.props.data}
                  itemSelect={this.state.itemSelectTalhao}
                  label={provider.intl.formatMessage({
                    id: 'areaHa'
                  })}
                  dataTalhoes={this.state.listTalhoes}
                  navigatorPosition={this.state.navigatorPosition}
                  loading={this.state.loading}
                />
              )}
            </div>,
            <div key={2} className={classes.card} style={{ height: '100%' }}>
              <CardCadastro
                height={'100%'}
                borderRadius={false}
                hiddenTitle={false}
                titulo={provider.intl.formatMessage({ id: 'mapa' })}
                botoes={[
                  <Button color='inherit' variant='contained' onClick={this.onCancelClear}>
                    {provider.intl.formatMessage({ id: 'cancelar' })}
                  </Button>
                ]}
              >
                {this.state.loading && this.state.index === 2 && (
                  <div style={{ width: '100%', height: '100%', marginBottom: 10 }}>
                    <Map
                      latLong={{
                        nrLatitude: this.state.itemSelect.nrLatitude,
                        nrLongitude: this.state.itemSelect.nrLongitude
                      }}
                      label={provider.intl.formatMessage({
                        id: 'areaHa'
                      })}
                      selectTalhao={[]}
                      data={[this.state.itemSelect]}
                      navigatorPosition={this.state.navigatorPosition}
                      valueCoords={this.state.itemSelect.talhaoGpsListConvert || []}
                      valueCoordsAreaCultivo={this.state.itemSelect}
                      withoutChangeMap={true}
                      onClickMap={() => {}}
                    />
                  </div>
                )}
              </CardCadastro>
            </div>
          ]}
        </SwipeableViewsOwn>
      </div>
    );
  }
}

function valueFormatter(params) {
  return params.value.toLocaleString(getUserLang(), {});
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(Talhoes);
export default withRouter(connect(mapStateToProps)(enhaced));
