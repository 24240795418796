export default {
  uploadFiles: {
    removeArquivo: 'Deseja exclir o arquivo selecionado?',
    nao: 'Não',
    excluir: 'Excluir',
    erroSubirArquivo: 'Um ou mais arquivos não foram enviados pois são maiores que 20MB',
    anexarArquivo: 'Solte os arquivos ou clique aqui para anexá-los',
    arquivoMaximo: '(arquivos de até 20MB)'
  }
};
