export default {
  medidaCorretiva: {
    descricao: 'Descrição',
    dataCadastro: 'Data de cadastro',
    erroAoBuscarDados: 'Erro ao buscar dados',
    medidaCorretiva: 'Medida corretiva',
    desejaExcluirMedidaCorretiva: 'Deseja excluir esta medida corretiva?',
    nao: 'Não',
    sim: 'Sim',
    excluir: 'Excluir',
    medidaCorretivaExcluida: 'Medida corretiva excluída com sucesso',
    erroAoExcluir: 'Ocorreu um erro ao excluir a medida corretiva',
    cadastroDeMedidaCorretiva: 'Cadastro de medida corretiva',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    medidaCorretivaSalvoComSucesso: 'Medida corretiva salva com sucesso',
    erroAoSalvar: 'Erro ao salvar medida corretiva',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    produtorRelacionado:
      'Não foi possível excluír esta medida corretiva pois existe um produtor usando ela',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nome',
    sancaoCategoria: 'Sanção categoria',
    situacao: 'Situação',
    geraAtividade: 'Gera atividade',
    naoConfirmidade: 'Não confirmidade'
  }
};
