export default {
  naoConformidade: {
    descricao: 'Descripción',
    dataCadastro: 'Fecha de Registro',
    erroAoBuscarDados: 'No se pudieron recuperar los datos',
    naoConformidade: 'Incumplimiento',
    desejaExcluirNaoConformidade: '¿Quieres eliminar esta incumplimiento?',
    desejaExcluirMedidaCorretiva: '',
    nao: 'No',
    excluir: 'Eliminar',
    naoConformidadeExcluida: 'Incumplimiento eliminada correctamente',
    erroAoExcluir: 'No se pudo borrar la incumplimiento',
    cadastroDeNaoConformidade: 'Registro de incumplimiento orgánico',
    campoObrigatorio: 'Campo obligatorio',
    identificacao: 'Identificación',
    salvar: 'Ahorrar',
    cancelar: 'Cancelar',
    naoConformidadeSalvoComSucesso: 'Incumplimiento guardada con éxito',
    erroAoSalvar: 'No se pudo guardar la incumplimiento',
    salvando: 'Saving',
    atualizando: 'Actualizando',
    produtorRelacionado: 'No fue posible excluir esta incumplimiento porque hay un productor usándola',
    status: 'Situación',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nombre',
    diasResolucao: 'Dias para resolucion',
    sigla: 'Iniciales',
    tipoPenalidade: 'Tipo de penalización',
    nenhum: 'Ninguno',
    desclassificacaoSafra: 'Descalificación de cultivos',
    desclassificacaoTalhao: 'Desclasificación de campo',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    sancao: 'Sanción',
    certificacao: 'Certificación',
    cadastrarSancao: 'registrar sanción',
    editar: 'Editar',
    medidasCorretivas: 'Medidas correctivas',
    medidaCorretiva: 'Medida correctiva',
    geral: 'General',
    cadastroGeralExistente:
      'Solo es posible tener una sanción general, o bien debe ser por certificación',
    ok: 'Ok'
  }
};
