import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllAnaliseOGM() {
  return axios.get(api + '/tipo-analise-ogm');
}

function findAllAnaliseOGMAtivo() {
  return axios.get(api + '/tipo-analise-ogm/active');
}

function findAnaliseOGMById(idAnaliseOGM) {
  return axios.get(api + '/tipo-analise-ogm/' + idAnaliseOGM);
}

function deleteAnaliseOGMById(idAnaliseOGM) {
  return axios.delete(api + '/tipo-analise-ogm/' + idAnaliseOGM);
}

function addAnaliseOGM(values) {
  return axios.post(api + '/tipo-analise-ogm', values);
}

function editAnaliseOGM(values) {
  return axios.put(api + '/tipo-analise-ogm', values);
}

export default {
  findAllAnaliseOGM,
  findAnaliseOGMById,
  deleteAnaliseOGMById,
  addAnaliseOGM,
  editAnaliseOGM,
  findAllAnaliseOGMAtivo
};
