import React from 'react';
import { style } from './../InsumoRecord.styles';
import moment from 'moment';
import formatMessage from './../i18n/formatMessage';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from '@components/DatePicker/DatePicker';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Add } from '@material-ui/icons';
import Autocomplete from '@components/Autocomplete/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { getUserLang } from '@utils/localeUtils';
import Close from '@material-ui/icons/Close';

function CadastrarCertificacao(props) {
  const {
    classes,
    listOrganico,
    initialValues,
    saveCertificacao,
    setValueAutoCompleteCertificacao,
    keyCertificacao,
    valueAutoCompleteCertificacao,
    setStCertificacao,
    stCertificacao,
    setKeyCertificacao,
    setKey,
    clickClear
  } = props;

  function onChangeCertificacaoAutoComplete(event) {
    setValueAutoCompleteCertificacao(event);
  }

  function tratarValorSelectInitialCertificacao(data) {
    if (!data || data.nmTipoCertificacao === undefined) {
      return '';
    }
    return data;
  }

  return (
    <div style={{ marginBottom: 30 }}>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={saveCertificacao}
        validationSchema={Yup.lazy(values =>
          Yup.object().shape({
            tipoCertificacao: Yup.object()
              .nullable()
              .required(formatMessage('insumo.campoObrigatorio')),
            dtInicio: Yup.string().nullable(),
            dhTermino: Yup.string().nullable()
          })
        )}
        render={({ values, handleSubmit, errors, touched, setFieldValue }) => {
          return (
            <Grid container spacing={16}>
              <Grid item xs={3} style={{ height: 70 }}>
                <Autocomplete
                  itens={listOrganico}
                  campoOp='nmTipoCertificacao'
                  campoInput='nmTipoCertificacao'
                  campoChave='idTipoCertificacao'
                  label={formatMessage('insumo.certificacaoOrganica')}
                  name='tipoCertificacao'
                  error={errors.tipoCertificacao && touched.tipoCertificacao}
                  helperText={touched.tipoCertificacao ? errors.tipoCertificacao : ''}
                  onChangeOwn={event => {
                    setFieldValue('tipoCertificacao', event);
                    onChangeCertificacaoAutoComplete(event);
                  }}
                  onChangeAutoComplete={onChangeCertificacaoAutoComplete}
                  value={tratarValorSelectInitialCertificacao(valueAutoCompleteCertificacao)}
                  key={keyCertificacao}
                  valueAutoComplete={tratarValorSelectInitialCertificacao(valueAutoCompleteCertificacao)}
                  valueSelect={
                    tratarValorSelectInitialCertificacao(valueAutoCompleteCertificacao)
                      .nmTipoCertificacao
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  name='dtInicio'
                  locale={getUserLang()}
                  errorTouched={errors.dtInicio && touched.dtInicio}
                  helperText={formatMessage('insumo.campoObrigatorio')}
                  label={formatMessage('insumo.dataInicial')}
                  valueDate={values.dtInicio}
                  onBlurDatePicker={e => {
                    const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                    if (validation) {
                      setFieldValue('dtInicio', e.target.value);
                    } else {
                      setFieldValue('dtInicio', null);

                      values.dtInicio = null;
                    }
                  }}
                  onChangeDatePicker={date => {
                    values.dtInicio = moment(date).format('YYYY-MM-DD');
                    setFieldValue('dtInicio', date);
                    setFieldValue('dtTermino', null);
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  name='dtTermino'
                  locale={getUserLang()}
                  errorTouched={errors.dtTermino && touched.dtTermino}
                  helperText={formatMessage('insumo.campoObrigatorio')}
                  label={formatMessage('insumo.dataFinal')}
                  valueDate={values.dtTermino}
                  onBlurDatePicker={e => {
                    const validation = moment(e.target.value, 'DD/MM/YYYY').isValid();
                    if (validation) {
                      setFieldValue('dtTermino', e.target.value);
                    } else {
                      setFieldValue('dtTermino', null);

                      values.dtTermino = null;
                    }
                  }}
                  onChangeDatePicker={date => {
                    values.dtTermino = moment(date).format('YYYY-MM-DD');
                    setFieldValue('dtTermino', date);
                  }}
                  minDate={values.dtInicio || null}
                />
              </Grid>

              <Grid item xs={2} className={classes.gridSwitch}>
                <div onClick={() => setStCertificacao(!stCertificacao)}>
                  {stCertificacao}
                  <Switch
                    value={stCertificacao}
                    name='stInsumoTipoCertificacao'
                    color='primary'
                    checked={stCertificacao}
                  />
                  <label className={classes.label}>{formatMessage('insumo.ativo')}</label>
                </div>
              </Grid>

              <Grid item xs={1} className={classes.cardButton}>
                <Button className={classes.button} onClick={handleSubmit}>
                  <Add className={classes.iconButton} />
                </Button>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setFieldValue('tipoCertificacao', null);
                    setFieldValue('dtInicio', null);
                    setFieldValue('dtTermino', null);
                    setValueAutoCompleteCertificacao(null);
                    setKeyCertificacao(Math.random());
                    setKey(Math.random());
                    clickClear();
                  }}
                >
                  <Close className={classes.iconButton} />
                </Button>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
}

export default withStyles(style)(CadastrarCertificacao);
