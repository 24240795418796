import URL from "./URL";

const axios = require("axios");
const api = URL.API;

function findAllCulturasHandler() {
  return axios.get(api + "/cultura");
}

function findCulturaById(idCultura) {
  return axios.get(api + "/cultura/" + idCultura);
}

function deleteCulturaByIdHandler(idCultura) {
  return axios.delete(api + "/cultura/" + idCultura);
}

function addCulturaHandler(values) {
  return axios.post(api + "/cultura", values);
}

function editCulturaHandler(values) {
  return axios.put(api + "/cultura", values);
}

export default {
  findAllCultura: findAllCulturasHandler,
  findCulturaById: findCulturaById,
  deleteCulturaById: deleteCulturaByIdHandler,
  addCultura: addCulturaHandler,
  editCultura: editCulturaHandler
};
