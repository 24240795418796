export default {
  naoConformidade: {
    descricao: 'Descrição',
    dataCadastro: 'Data de cadastro',
    erroAoBuscarDados: 'Erro ao buscar dados',
    naoConformidade: 'Não conformidade',
    desejaExcluirNaoConformidade: 'Deseja excluir esta não conformidade',
    desejaExcluirMedidaCorretiva: 'Deseja excluir esta medida corretiva?',
    nao: 'Não',
    excluir: 'Excluir',
    naoConformidadeExcluida: 'Não conformidade excluída com sucesso',
    erroAoExcluir: 'Ocorreu um erro ao excluir a não conformidade',
    cadastroDeNaoConformidade: 'Cadastro de não conformidade',
    campoObrigatorio: 'Campo obrigatório',
    identificacao: 'Identificação',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    naoConformidadeSalvoComSucesso: 'Não conformidade salva com sucesso',
    erroAoSalvar: 'Erro ao salvar não conformidade',
    salvando: 'Salvando',
    atualizando: 'Atualizando',
    produtorRelacionado:
      'Não foi possível excluír esta não conformidade pois existe um produtor usando ela',
    status: 'Situação',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nome',
    diasResolucao: 'Dias para resolução',
    sigla: 'Sigla',
    tipoPenalidade: 'Tipo de penalidade',
    nenhum: 'Nenhum',
    desclassificacaoSafra: 'Desclassificação da safra',
    desclassificacaoTalhao: 'Desclassificação do talhão',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    sancao: 'Sanção',
    certificacao: 'Certificação',
    cadastrarSancao: 'Cadastrar sanção',
    editar: 'Editar',
    medidasCorretivas: 'Medidas corretivas',
    medidaCorretiva: 'Medida corretiva',
    geral: 'Geral',
    cadastroGeralExistente: 'Só é possível ter uma sanção geral, ou então ela deve ser por certificação',
    ok: 'Ok'
  }
};
