import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from '../i18n/ptBR';
import enUS from '../i18n/enUS';
import esES from '../i18n/esES';
import EditarColaboradores from './EditarColaboradores';
import swal from '@sweetalert/with-react';
import SwipeableViewsOwn from '@components/SwipeableViewsOwn/SwipeableViewsOwn';
import CardTitle from '@components/CardTitle/CardTitle';
import moment from 'moment';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };
let { provider } = new IntlProvider({});

const styles = theme => ({
  root: {
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  swipeable: {
    width: '100%',
    height: '100%'
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 95px)',
    marginBottom: 15
  }
});

/**
 * Componente para listagem da tab Colaboradores
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
class Colaboradores extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const nome = messagesLinguage['produtores.nome'];
    const sexo = messagesLinguage['produtores.sexo'];
    const contrato = messagesLinguage['produtores.contrato'];
    const dataNascimento = messagesLinguage['produtores.dataNascimento'];
    const desejaExcluirColaborador = messagesLinguage['produtores.desejaExcluirColaborador'];
    const nao = messagesLinguage['produtores.nao'];
    const excluir = messagesLinguage['produtores.excluir'];
    const colaboradorRemovidoComSucesso = messagesLinguage['produtores.colaboradorRemovidoComSucesso'];
    const colaboradores = messagesLinguage['produtores.colaboradores'];
    const dataAdmissao = messagesLinguage['produtores.dataAdmissao'];
    const dataCadastro = messagesLinguage['produtores.dataCadastro'];
    const idade = messagesLinguage['produtores.idade'];
    const ativo = messagesLinguage['produtores.ativo'];
    const inativo = messagesLinguage['produtores.inativo'];
    const status = messagesLinguage['produtores.status'];
    const ativos = messagesLinguage['produtores.ativos'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          idade,
          dataCadastro,
          colaboradores,
          nome,
          sexo,
          dataNascimento,
          contrato,
          desejaExcluirColaborador,
          nao,
          excluir,
          colaboradorRemovidoComSucesso,
          dataAdmissao,
          ativo,
          inativo,
          status,
          ativos
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.headDatatable = [
      {
        field: 'nmColaborador',
        headerName: provider.intl.formatMessage({ id: 'nome' }),
        col: 2
      },
      {
        field: 'nmSexo',
        headerName: provider.intl.formatMessage({ id: 'sexo' }),
        col: 1
      },
      {
        field: 'nmData',
        headerName: provider.intl.formatMessage({ id: 'dataNascimento' }),
        col: 2
      },
      {
        valueGetter: args => moment().diff(moment(args.node.data.dtNascimento), 'years'),
        headerName: provider.intl.formatMessage({ id: 'idade' }),
        col: 1
      },
      {
        field: 'nmContrato',
        headerName: provider.intl.formatMessage({ id: 'contrato' }),
        col: 1
      },
      {
        field: 'dataInicial',
        headerName: provider.intl.formatMessage({ id: 'dataAdmissao' }),
        col: 2
      },
      {
        valueGetter: args => moment(args.node.data.dtCadastro).format('DD/MM/YYYY'),
        headerName: provider.intl.formatMessage({ id: 'dataCadastro' }),
        col: 2
      },
      {
        valueGetter: args =>
          args.node.data.stColaboradorProdutor === 1
            ? provider.intl.formatMessage({ id: 'ativo' })
            : provider.intl.formatMessage({ id: 'inativo' }),
        headerName: provider.intl.formatMessage({ id: 'status' }),
        col: 1
      }
    ];

    this.state = {
      qtAtivos: 0,
      disabledButtons: true,
      itemSelect: '',
      atualizando: false,
      data: this.props.data.filter(item => item.stRegistro !== 2),
      keyGrid: '',
      index: 0
    };

    this.clickExcluir = this.clickExcluir.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.countColaboraresAtivos = this.countColaboraresAtivos.bind(this);
  }

  componentDidMount() {
    this.countColaboraresAtivos();
  }

  countColaboraresAtivos() {
    this.setState({
      qtAtivos: this.state.data.filter(item => item.stColaboradorProdutor === 1).length
    });
  }

  /**
   * Mantem a lista do componente pai e do filho sincronizadas
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.state.data)) {
      this.setState({
        data: nextProps.data.filter(item => item.stRegistro !== 2)
      });
    }
  }

  componentWillUnmount() {
    if (this.state.index === 1) {
      this.props.changeFAB();
    }
  }

  /**
   * Executa ao adicionar um novo Colaborador
   *
   * @param {Object} values - Objeto Colaborador novo
   * @param {Number} position - Posição do Colaborador na lista
   */
  onSave(values, position) {
    this.setState({ atualizando: false, index: 0 }, () => {
      this.props.onSave(values, position);
      this.selectChange('');

      this.setState(
        {
          keyGrid: Math.random()
        },
        () => this.countColaboraresAtivos()
      );
    });
  }

  /**
   * Executa ao clicar em excluir uma linha da tabela
   */
  clickExcluir(position) {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluirColaborador' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(res => {
      if (res) {
        this.props.onDelete(this.state.itemSelect);
        this.countColaboraresAtivos();

        swal(provider.intl.formatMessage({ id: 'colaboradorRemovidoComSucesso' }), {
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      }
    });
  }

  /**
   * Executa quando é clickado numa linha da lista
   *
   * @param {Object} selected - Linha selecionada
   */
  selectChange(select) {
    if (this.state.atualizando) return;

    if (select) {
      this.setState({ itemSelect: select, disabledButtons: false });
    } else {
      this.setState({ itemSelect: '', disabledButtons: true });
    }
  }

  render() {
    const { classes, tpPerfil } = this.props;

    return (
      <div className={classes.root}>
        <SwipeableViewsOwn index={this.state.index} onChangeIndex={index => this.setState({ index })}>
          {[
            <div className={classes.tab} key={0}>
              <CardTitle
                containerStyle={{ marginBottom: tpPerfil ? 0 : 'none' }}
                title={provider.intl.formatMessage({ id: 'colaboradores' })}
                subtitle={`${this.state.qtAtivos} ${provider.intl.formatMessage({ id: 'ativos' })}`}
              />

              {!tpPerfil && (
                <GroupButtonGrid
                  hiddenMargin
                  withoutMargin
                  showEdit
                  showDelete
                  showAdd
                  disableEdit={this.state.disabledButtons || this.state.atualizando}
                  disableDelete={this.state.disabledButtons || this.state.atualizando}
                  onClickEdit={() =>
                    this.setState({ atualizando: true, index: 1 }, this.props.changeFAB)
                  }
                  onClickDelete={this.clickExcluir}
                  onClickAdd={() =>
                    this.setState({ index: 1, atualizando: false }, this.props.changeFAB)
                  }
                />
              )}

              <DataTable
                key={this.state.keyGrid}
                noMargin
                borderRadius={false}
                data={this.state.data}
                columns={this.headDatatable}
                selectChange={this.selectChange}
                showPagination={false}
              />
            </div>,

            <EditarColaboradores
              key={1}
              onCancel={() => {
                this.setState({ atualizando: false, keyGrid: Math.random(), index: 0 }, () =>
                  this.selectChange('')
                );
                this.props.changeFAB();
              }}
              onSave={this.onSave}
              colaborador={this.state.atualizando ? this.state.itemSelect : []}
              atualizando={this.state.atualizando}
              data={this.state.data}
            />
          ]}
        </SwipeableViewsOwn>
      </div>
    );
  }
}

export default withStyles(styles)(Colaboradores);
