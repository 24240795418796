import AtividadeUrlEnum from '../enumerations/AtividadeUrlEnum';
import formatMessage from '../i18n/formatMessage';

function TabLateralList(tpLancamento) {
  const listTab = [
    {
      name: formatMessage('acompanhamentoProdutor.identificacao'),
      key: 0,
      select: true
    },
    {
      name: titleTab(tpLancamento),
      key: 1,
      select: false
    }
  ];

  if (tpLancamento !== 5) {
    listTab.push({
      name: formatMessage('acompanhamentoProdutor.talhoes'),
      key: 2,
      select: false
    });
  }

  return listTab;
}

function titleTab(tpLancamento) {
  switch (tpLancamento) {
    case AtividadeUrlEnum.ALERTA_FITOSSANITARIO:
      return formatMessage('acompanhamentoProdutor.alertasFitossanitarios');
    case AtividadeUrlEnum.REGISTRO_FOTOGRAFICO:
      return formatMessage('acompanhamentoProdutor.imagens');
    case AtividadeUrlEnum.REGISTRO_CLIMATICO:
      return formatMessage('acompanhamentoProdutor.talhao');
    default:
      return formatMessage('acompanhamentoProdutor.produtos');
  }
}

export { TabLateralList, titleTab };
