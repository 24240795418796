export default {
  fertilizantesQuimicos: {
    fertilizantesQuimicosRemovidoComSucesso: 'Fertilizante químico removido com sucesso',
    falhaAoExcluirFertilizantesQuimicos: 'Falha ao excluir fertilizante químico',
    desejaExcluir: 'Deseja excluir o fertilizante químico selecionado?',
    fertilizantesQuimicos: 'Fertilizantes químicos',
    cadastroDeFertilizantesQuimicos: 'Cadastro de fertilizantes químicos',
    identificacaoDaFertilizantesQuimicos: 'Identificação',
    fertilizantesQuimicosCadastradoComSucesso: 'Fertilizante químico cadastrado com sucesso',
    fertilizantesQuimicosEditadoComSucesso: 'Fertilizante químico editado com sucesso',
    falhaAoEditarFertilizantesQuimicos: 'Falha ao editar fertilizante químico',
    falhaAoCadastrarFertilizantesQuimicos: 'Falha ao cadastrar fertilizante químico',
    falhaAoCarregarFertilizantesQuimicos: 'Falha ao carregar fertilizante químico'
  }
};
