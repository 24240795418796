/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import Botao from '@components/Botao/Botao';
import InputAdornment from '@material-ui/core/InputAdornment';
import swal from '@sweetalert/with-react';
import { withRouter } from 'react-router-dom';
import Authentication from '@resources/oauth/Authentication';

import iconEmail from '@images/icon_email.png';
import iconSenha from '@images/icon_senha.png';
import iconKey from '@images/icon_key.png';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const messagesLinguage = flattenMessages(messages[getUserLang()]);
const falhaRecuperarSenha = messagesLinguage['recuperarSenha.falhaRecuperarSenha'];
const senhaTrocada = messagesLinguage['recuperarSenha.senhaTrocada'];
const codigoExpirado = messagesLinguage['recuperarSenha.codigoExpirado'];
const falhaConectar = messagesLinguage['recuperarSenha.falhaConectar'];
const reencaminhar = messagesLinguage['recuperarSenha.reencaminhar'];
const sucesso = messagesLinguage['recuperarSenha.sucesso'];
const codigoInvalido = messagesLinguage['recuperarSenha.codigoInvalido'];

const intlProvider = new IntlProvider(
  {
    locale: getUserLang(),
    messages: {
      falhaRecuperarSenha,
      senhaTrocada,
      codigoExpirado,
      falhaConectar,
      reencaminhar,
      sucesso,
      codigoInvalido
    }
  },
  {}
);

const { intl } = intlProvider.getChildContext();

const styles = {
  card: {
    width: 330,
    height: 510,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'center',
    padding: 20,
    borderRadius: 10
  },
  label: {
    height: 70,
    display: 'flex'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'center'
  },
  cxDialogo: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center'
  },
  containerCxDialogo: {
    width: 300,
    position: 'relative'
  },
  link: {
    textDecoration: 'none',
    color: '#26B767',
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row-reverse',
    fontSize: 14,
    marginTop: 8,
    marginBottom: 10
  },
  divLink: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row-reverse'
  }
};

const themeFocus = createMuiTheme({
  palette: {
    primary: green,
    type: 'light'
  },
  typography: {
    useNextVariants: true
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: null
        }
      }
    }
  }
});

const inputProps = {
  disabled: true,
  style: { opacity: 0.75 }
};

/*
    Tela de Recuperar Senha Customizada
*/
class TelaRecuperarSenha extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUrl = this.getUrl.bind(this);
    this.backLogin = this.backLogin.bind(this);

    var data = {};
    this.getUrl(data);
    this.state = {
      loading: false,
      key: data.code,
      email: data.email
    };
  }

  backLogin() {
    this.props.history.push('/login');
  }

  getUrl(data) {
    var query = window.location.search.slice(1);
    var partes = query.split('&');
    partes.forEach(function(parte) {
      var chaveValor = parte.split('=');
      var chave = chaveValor[0];
      var valor = chaveValor[1];
      data[chave] = valor;
    });
  }
  handleSubmit(values) {
    var buttonSucess = {
      confirm: {
        text: 'Ok',
        value: true,
        visible: true,
        closeModal: true,
        className: 'swal2-Ok'
      }
    };

    var buttonError = {
      confirm: {
        text: 'Ok',
        value: true,
        visible: true,
        closeModal: true,
        className: 'swal2-error'
      }
    };
    this.setState({
      loading: true
    });

    Authentication.resetarSenha(values.email, values.password, values.key)
      .then(() => {
        swal(intl.formatMessage({ id: 'sucesso' }), intl.formatMessage({ id: 'reencaminhar' }), {
          icon: 'success',
          buttons: buttonSucess
        }).then(willDelete => {
          this.props.history.push('/login');
        });

        this.setState({
          loading: false
        });
      })
      .catch(err => {
        if (err.response !== undefined && err.response.data.codigo === 'CODIGO_INVALIDO') {
          swal(
            intl.formatMessage({ id: 'falhaRecuperarSenha' }),
            intl.formatMessage({ id: 'codigoInvalido' }),
            {
              icon: 'error',
              buttons: buttonError
            }
          );
        } else if (err.response !== undefined && err.response.data.codigo === 'CODIGO_EXPIRADO') {
          swal(
            intl.formatMessage({ id: 'falhaRecuperarSenha' }),
            intl.formatMessage({ id: 'codigoExpirado' }),
            {
              icon: 'error',
              buttons: buttonError
            }
          );
        } else {
          swal(
            intl.formatMessage({ id: 'falhaRecuperarSenha' }),
            intl.formatMessage({ id: 'falhaConectar' }),
            {
              icon: 'error',
              buttons: buttonError
            }
          );
        }

        this.setState({
          loading: false
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Formik
        initialValues={{
          email: this.state.email,
          password: '',
          confirmPassword: '',
          key: this.state.key
        }}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(<FormattedMessage id='recuperarSenha.emailInvalido' />)
            .required(<FormattedMessage id='recuperarSenha.campoObrigatorio' />),
          password: Yup.string()
            .min(6, <FormattedMessage id='recuperarSenha.senhaMinimo' />)
            .required(<FormattedMessage id='recuperarSenha.campoObrigatorio' />),
          confirmPassword: Yup.string()
            .min(6, <FormattedMessage id={'recuperarSenha.senhaMinino'} />)
            .oneOf([Yup.ref('password')], 'Senhas Incompativeis')
            .required(<FormattedMessage id={'recuperarSenha.campoObrigatorio'} />),
          key: Yup.string().required(<FormattedMessage id={'recuperarSenha.campoObrigatorio'} />)
        })}
        render={({ values, handleSubmit, errors, touched, handleChange }) => (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <Card className={classes.card}>
              <MuiThemeProvider theme={theme}>
                <TextField
                  label={<FormattedMessage id={'recuperarSenha.email'} />}
                  value={values.email}
                  error={errors.email && touched.email}
                  name='email'
                  style={{ marginTop: '40%' }}
                  className={classes.label}
                  onChange={handleChange}
                  helperText={errors.email && touched.email ? errors.email : null}
                  InputProps={{
                    inputProps,
                    endAdornment: (
                      <InputAdornment position='end'>
                        <img src={iconEmail} alt='icone que representa senha' />
                      </InputAdornment>
                    )
                  }}
                />
              </MuiThemeProvider>

              <MuiThemeProvider theme={theme}>
                <TextField
                  label={<FormattedMessage id={'recuperarSenha.codigo'} />}
                  value={this.state.key}
                  error={errors.key && touched.key}
                  name='key'
                  className={classes.label}
                  onChange={handleChange}
                  helperText={errors.key && touched.key ? errors.key : null}
                  InputProps={{
                    inputProps,
                    endAdornment: (
                      <InputAdornment position='end'>
                        <img src={iconKey} alt='icone que representa senha' />
                      </InputAdornment>
                    )
                  }}
                />
              </MuiThemeProvider>

              <MuiThemeProvider theme={themeFocus}>
                <TextField
                  label={<FormattedMessage id={'recuperarSenha.senha'} />}
                  value={values.password}
                  error={errors.password && touched.password}
                  name='password'
                  className={classes.label}
                  onChange={handleChange}
                  type='password'
                  helperText={errors.password && touched.password ? errors.password : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <img src={iconSenha} alt='icone que representa senha' />
                      </InputAdornment>
                    )
                  }}
                />
              </MuiThemeProvider>

              <MuiThemeProvider theme={themeFocus}>
                <TextField
                  label={<FormattedMessage id={'recuperarSenha.confirmacaoSenha'} />}
                  value={values.confirmPassword}
                  error={errors.confirmPassword && touched.confirmPassword}
                  name='confirmPassword'
                  className={classes.label}
                  onChange={handleChange}
                  type='password'
                  helperText={
                    errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <img src={iconSenha} alt='icone que representa senha' />
                      </InputAdornment>
                    )
                  }}
                />
              </MuiThemeProvider>

              <div className={classes.divLink}>
                <label onClick={this.backLogin} className={classes.link}>
                  <FormattedMessage id={'recuperarSenha.voltarLogin'} />
                </label>
              </div>

              <div className={classes.button}>
                <Botao
                  onClickButton={handleSubmit}
                  loadingButton={this.state.loading}
                  text={<FormattedMessage id={'recuperarSenha.confirmar'} />}
                />
              </div>
            </Card>
          </IntlProvider>
        )}
      />
    );
  }
}

TelaRecuperarSenha.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(TelaRecuperarSenha));
