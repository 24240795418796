export default {
  medidaCorretiva: {
    descricao: 'Description',
    dataCadastro: 'Registration date',
    erroAoBuscarDados: 'Error to fetch data',
    medidaCorretiva: 'Corrective measure',
    desejaExcluirMedidaCorretiva: 'Do you want to delete this corrective measure',
    nao: 'No',
    sim: 'Yes',
    excluir: 'Delete',
    medidaCorretivaExcluida: 'Corrective measure successfully deleted',
    erroAoExcluir: 'Error to delete corrective measure',
    cadastroDeMedidaCorretiva: 'Organic corrective measure registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    salvar: 'Salve',
    cancelar: 'Cancel',
    medidaCorretivaSalvoComSucesso: 'Corrective measure successfully saved',
    erroAoSalvar: 'Error to save corrective measure',
    salvando: 'Saving',
    atualizando: 'Updating',
    produtorRelacionado:
      'It was not possible to exclude this corrective measure because there is a producer using it',
    ativo: 'Active',
    inativo: 'Inative',
    nome: 'Name',
    sancaoCategoria: 'Samson category',
    situacao: 'Status',
    geraAtividade: 'Generates activity',
    naoConfirmidade: 'Non-compliance'
  }
};
