import URL from "./../URL";

const axios = require("axios");
const oauth = URL.OAUTH;
const client_id = "azagros";
const client_secret = "azagros-secret";
const code = "1234";

function refreshTokenHandler() {
  const auth = JSON.parse(localStorage.getItem("token"));

  if (auth) {
    const param = new URLSearchParams();
    param.append("grant_type", "refresh_token");
    param.append("client_id", client_id);
    param.append("client_secret", client_secret);
    param.append("code", code);
    param.append("username", auth.email);
    param.append("refresh_token", auth.refresh_token);

    return axios
      .post(oauth + "/login", param)
      .then(response => {
        localStorage.setItem(
          "token",
          JSON.stringify({
            refresh_token: response.data.refresh_token,
            access_token: response.data.access_token,
            expires_in: response.data.expires_in,
            email: auth.email
          })
        );
      })
      .catch(err => {
        localStorage.setItem("token", null);

        throw err;
      });
  } else {
    return Promise.reject();
  }
}

function loginHandler(mail, password) {
  const param = new URLSearchParams();
  param.append("grant_type", "password");
  param.append("client_id", client_id);
  param.append("client_secret", client_secret);
  param.append("code", code);
  param.append("username", mail);
  param.append("password", password);

  return axios
    .post(oauth + "/login", param)
    .then(response => {
      localStorage.setItem(
        "token",
        JSON.stringify({
          refresh_token: response.data.refresh_token,
          access_token: response.data.access_token,
          expires_in: response.data.expires_in,
          email: mail
        })
      );
    })
    .catch(err => {
      throw err;
    });
}

function logoutHandler() {
  localStorage.setItem("token", null);
}

function recuperarSenhaHandler(mail) {
  return axios.put(oauth + "/usuario/resetarsenha", {
    dsEmail: mail
  });
}

function resetarSenhaHandler(mail, senha, token) {
  return axios.put(oauth + "/usuario/recuperarsenha", {
    dsEmail: mail,
    dsSenha: senha,
    dsToken: token
  });
}

function confirmarCadastroHandler(mail, senha, token) {
  return axios.put(oauth + "/usuario/confirmarcadastro", {
    dsEmail: mail,
    dsSenha: senha,
    dsToken: token
  });
}

function verificarUsuarioHandler(email) {
  const auth = JSON.parse(localStorage.getItem("token"));

  if (auth) {
    return axios
      .get(oauth + "/usuario/verificar-email/" + email)
      .then(response => {
        return response;
      })
      .catch(err => {
        throw err.response;
      });
  }
}

function inicializarInterceptosHandler() {
  axios.interceptors.request.use(async query => {
    const auth = JSON.parse(localStorage.getItem("token"));

    if (auth) {
      query.headers.Authorization = "Bearer " + auth.access_token;
    }

    return query;
  });
}

function findAllUsuariosHandler() {
  return axios.get(oauth + "/usuario");
}

function alterarSenhaHandler(values) {
  return axios.put(oauth + "/usuario/alterarsenha", values);
}

export default {
  logout: logoutHandler,
  login: loginHandler,
  refreshToken: refreshTokenHandler,
  confirmarCadastro: confirmarCadastroHandler,
  recuperarSenha: recuperarSenhaHandler,
  resetarSenha: resetarSenhaHandler,
  verificarUsuario: verificarUsuarioHandler,
  inicializarInterceptos: inicializarInterceptosHandler,
  findAllUsuarios: findAllUsuariosHandler,
  alterarSenha: alterarSenhaHandler
};
