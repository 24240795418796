import URL from './URL';
// eslint-disable-next-line
import axios, { AxiosResponse } from 'axios';

const API = `${URL.API}/plantio`;

/**
 * Chama o endpoint para retornar o Plantio pelo ID
 *
 * @param {Number} id - ID do Plantio
 * @returns {Promise<AxiosResponse<Plantio>>} Uma Promise com o Plantio
 */
async function findPlantioById(id) {
  return await axios.get(`${API}/${id}`);
}

/**
 * Chama o endpoint para retornar o Plantio pelo ID
 *
 * @param {Number} id - ID do Plantio
 * @returns {Promise<AxiosResponse<Plantio>>} Uma Promise com o Plantio
 */
async function findPlantioByAreaCultivo(id) {
  return await axios.get(`${API}/area-cultivo/${id}`);
}

/**
 * Chama o endpoint para salvar um novo Plantio
 *
 * @param {Plantio} plantio - Plantio que vai ser salvo
 * @returns {Promise<AxiosResponse<Plantio>>} Uma Promise com o Plantio
 */
async function savePlantio(plantio) {
  return await axios.post(API, plantio);
}

/**
 * Chama o endpoint para atualizar um Plantio
 *
 * @param {Plantio} plantio - Plantio que vai ser atualizado
 * @returns {Promise<AxiosResponse<Plantio>>} Uma Promise com o Plantio
 */
async function updatePlantio(plantio) {
  return await axios.put(API, plantio);
}

/**
 * Chama o endpoint para deletar um Plantio pelo ID
 *
 * @param {Number} id - ID do Plantio
 * @returns {Promise<AxiosResponse<void>>} Uma Promise
 */
async function deletePlantioById(id) {
  return await axios.delete(`${API}/${id}`);
}

export { findPlantioById, savePlantio, updatePlantio, deletePlantioById, findPlantioByAreaCultivo };
