export default {
  relatorio: {
    relatorio: 'Relatório',
    relatorioProdutoresPotencial: 'Relatório de produtores em potencial',
    regiao: 'Região',
    municipio: 'Município',
    conhecimentoOrganico: 'Conhecimento da produção orgânica',
    sim: 'Sim',
    nao: 'Não',
    atividadeGraos: 'Atividade de grãos',
    carregando: 'Carregando...',
    gerandoRelatorio: 'Gerando relatório...',
    falhaAoGerarRelatorio: 'Falha ao gerar relatório',
    decrescente: 'Decrescente',
    crescente: 'Crescente',
    produtor: 'Produtor',
    uf: 'UF'
  }
};
