import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllNaoConformidade() {
  return axios.get(api + '/nao-conformidade');
}

function findNaoConformidadeById(idNaoConformidade) {
  return axios.get(api + '/nao-conformidade/' + idNaoConformidade);
}

function deleteNaoConformidadeById(idNaoConformidade) {
  return axios.delete(api + '/nao-conformidade/' + idNaoConformidade);
}

function addNaoConformidade(values) {
  return axios.post(api + '/nao-conformidade', values);
}

function editNaoConformidade(values) {
  return axios.put(api + '/nao-conformidade', values);
}

export {
  findAllNaoConformidade,
  findNaoConformidadeById,
  deleteNaoConformidadeById,
  addNaoConformidade,
  editNaoConformidade
};
