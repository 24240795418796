//Autor: Kátia Miglioli

import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconAdd from '@images/icone_add.png';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUs';
import esES from './i18n/esES';
import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import '@styles/Alerts.css';
import FilterUtil from '../filterUtil';
import Loading from '@components/Loading/Loading';
import * as moment from 'moment';
import Cotacao from '@resources/Cotacao';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const style = theme => ({
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 9.5,
    right: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '10%',
    zIndex: 1,
    height: '10%',
    marginBottom: 0,
    marginLeft: '90%'
  }
});
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green,
    type: 'light'
  }
});

class PesquisarCotacoes extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const data = messagesLinguage['cotacao.data'];
    const cultura = messagesLinguage['cotacao.cultura'];
    const valor = messagesLinguage['cotacao.valor'];
    const nao = messagesLinguage['cotacao.nao'];
    const desejaExcluir = messagesLinguage['cotacao.desejaExcluir'];
    const excluir = messagesLinguage['cotacao.excluir'];
    const cotacaoRemovidaComSucesso = messagesLinguage['cotacao.cotacaoRemovidaComSucesso'];
    const falhaAoExcluirCotacao = messagesLinguage['cotacao.falhaAoExcluirCotacao'];
    const excluindo = messagesLinguage['cotacao.excluindo'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          data,
          cultura,
          valor,
          nao,
          desejaExcluir,
          excluir,
          cotacaoRemovidaComSucesso,
          falhaAoExcluirCotacao,
          excluindo
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      itemSelect: '',
      disabledEdit: true,
      disableDelete: true,
      key: '',
      dataInTable: [],
      dataAppSync: [],
      statusAlert: false,
      loading: true,
      head: [
        {
          field: 'dataFormatada',
          headerName: provider.intl.formatMessage({ id: 'data' }),
          sort: 'asc',
          col: 2
        },
        {
          field: 'dsCultura',
          headerName: provider.intl.formatMessage({ id: 'cultura' }),
          col: 3
        },
        {
          field: 'vlCotacao',
          headerName: provider.intl.formatMessage({ id: 'valor' }),
          col: 2,
          valueFormatter: currencyFormatter
        }
      ],
      operationValues: [
        {
          label: provider.intl.formatMessage({ id: 'cultura' }),
          field: 'dsCultura',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'data' }),
          field: 'dtCotacao',
          type: 'date'
        }
      ]
    };

    const title = 'cotacao.cotacoes';
    if (this.props.itensState.name !== 'cotacao.cotacoes') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false,
        disableDelete: select.idCliente === null
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true,
        disableDelete: true
      });
    }
  }

  clickAdicionar() {
    this.props.history.push('/app/pesquisar-cotacoes/novo');
  }
  clickEditar() {
    this.props.history.push('/app/pesquisar-cotacoes/editar/' + this.state.itemSelect.idCotacao);
  }
  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            title: provider.intl.formatMessage({ id: 'excluindo' })
          }
        );
        Cotacao.deleteCotacaoById(this.state.itemSelect.idCotacao)
          .then(data => {
            swal(provider.intl.formatMessage({ id: 'cotacaoRemovidaComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });
            this.atualizarRegistros(this.state.itemSelect.idCotacao);
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: 'falhaAoExcluirCotacao' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    });
  }

  atualizarRegistros(data) {
    var index = -1;
    for (var i = 0; i < this.state.dataAppSync.length; i++) {
      if (this.state.dataAppSync[i].idPesticida === data.idPesticida) {
        index = i;
        break;
      }
    }
    this.state.dataAppSync.splice(index, 1);
    this.setState({
      dataAppSync: this.state.dataAppSync,
      dataInTable: this.state.dataAppSync,
      key: Math.random()
    });

    this.onFilterChanged(this.props.chipData);
  }

  componentDidMount() {
    this.onFilter(this.props.chipData);
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    Cotacao.findAllCotacao().then(query => {
      var value = query.data;
      value.forEach(cot => {
        cot.dsCultura = cot.cultura.nmCultura;
        cot.dataFormatada = this.formataData(cot.dtCotacao);
      });

      this.setState({
        dataAppSync: value,
        dataInTable: FilterUtil.applyFilters(value, dataChips),
        loading: false
      });
    });
  }

  formataData(dataIntegra) {
    switch (getUserLang()) {
      case 'pt-BR':
        return moment(dataIntegra).format('DD/MM/YYYY');
      case 'en-US':
        return moment(dataIntegra).format('MMM/DD/YYYY');
      case 'es-ES':
        return moment(dataIntegra).format('DD-MM-YYYY');
      default:
        break;
    }
  }
  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'scroll'
          }}
        >
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='cotacoes'
          />
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disableDelete}
            onClickEdit={this.clickEditar}
            onClickDelete={this.clickExcluir}
          />

          <div
            style={{
              marginTop: 30,
              marginBottom: 15,
              display: 'contents',
              width: '100%',
              height: '100%'
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              key={this.state.key}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={() => this.clickAdicionar()} />
        </div>
      </IntlProvider>
    );
  }
}
function currencyFormatter(params) {
  return params.value.toLocaleString(getUserLang(), {
    minimumFractionDigits: 2
  });
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'cotacoes';
  })[0].content
});

let enhaced = withStyles(style)(PesquisarCotacoes);
export default withRouter(connect(mapStateToProps)(enhaced));
