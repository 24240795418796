const styles = theme => ({
  containerTitle: {
    backgroundColor: '#FFFFFF',
    marginBottom: -21,
    overflow: 'hidden'
  },
  cardLabel: {
    backgroundColor: 'rgba(139, 200, 165, 0.1)',
    width: '100%',
    display: 'flex'
  },
  title: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
    fontSize: 20,
    fontWeight: '500',
    color: 'rgb( 0, 0, 0, 0.7)'
  },
  separator: {
    margin: '0px 10px'
  },
  subtitle: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: '300',
    color: 'rgb( 0, 0, 0, 0.7)'
  }
});

export { styles };
