import { getUserLang } from './localeUtils';
import moment from 'moment';

/**
 * Retorna a string do formato da data intercionalizado
 *
 * @author Bruno Eduardo
 * @returns {String} String do formato da data intercionalizado
 */
function formatoDataIntercionalizado() {
  switch (getUserLang()) {
    case 'pt-BR':
      return 'DD/MM/YYYY';
    case 'es-ES':
      return 'DD-MM-YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}

/**
 * Formata uma data para o formato passado por parâmetro ou YYYY-MM-DDTHH:mm:ssZZ
 *
 * @author Bruno Eduardo
 * @param {moment.MomentInput} date - Data que vai ser formatada
 * @param {String} format - Formato da data
 * @returns {String} - A data em string formatada ou null se o parâmetro não for válido
 */
function formatDate(date, format) {
  return date ? moment(date).format(format || 'YYYY-MM-DDTHH:mm:ssZZ') : null;
}

function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];

    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

export { formatoDataIntercionalizado, flattenMessages, formatDate };
