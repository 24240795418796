import React, { useState } from 'react';
import DataTable from '@components/DataTable/DataTable';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import { connect } from 'react-redux';
import { headMedidaCorretiva } from './../header';

/**
 * Grid de listagem de medida corretiva
 *
 * @author Gabriela Farias
 * @param {Object} props - props
 * @returns Componente React
 */
const MedidaCorretiva = props => {
  const { classes, onDelete, keyMedidaCorretiva, setSelectMedidaCorretiva, data } = props;
  const [itemSelect, setItemSelect] = useState(null);
  const [disabledButtons, setDisabledButtons] = useState(true);

  /**
   * Executa quando é clickado numa linha da lista
   *
   * @param {Object} selected - Linha selecionada
   */
  function selectChange(selected) {
    if (selected) {
      setItemSelect(selected);
      setDisabledButtons(false);
    } else {
      setItemSelect(null);
      setDisabledButtons(true);
    }
  }

  return (
    <div className={classes.grid}>
      <GroupButtonGrid
        hiddenMargin
        withoutMargin
        showEdit
        showDelete
        showAdd={false}
        disableEdit={disabledButtons}
        disableDelete={disabledButtons}
        onClickEdit={() => setSelectMedidaCorretiva(itemSelect)}
        onClickDelete={() => onDelete(itemSelect)}
      />

      <DataTable
        key={keyMedidaCorretiva}
        data={data}
        columns={headMedidaCorretiva}
        selectChange={selectChange}
        showPagination={false}
        borderRadius={false}
        noMargin
      />
    </div>
  );
};

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(MedidaCorretiva);
