export default {
  RECOMENDACAO: 0,
  APLICACAO: 1,
  ALERTA_FITOSSANITARIO: 2,
  MONITORAMENTO: 3,
  REGISTRO_FOTOGRAFICO: 4,
  REGISTRO_CLIMATICO: 5,
  PRODUTO_PROIBIDO: 6,
  SEM_ATIVIDADE: 7
};
