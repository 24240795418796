import { createMuiTheme } from '@material-ui/core/styles';

const style = theme => ({
  cardWithScroll: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  cadastro: {
    marginTop: '15px',
    height: 70
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 70
  },
  font: {
    fontSize: 14,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  divTitulo: {
    height: '100%',
    fontSize: 18,
    color: '#666666',
    marginLeft: 65,
    marginTop: 13,
    flex: 25,
    minHeight: 42
  },
  form: {
    width: '100%',
    bottom: 1
  },
  titulo: {
    height: 50,
    fontWeight: 500,
    fontSize: 20,
    color: 'rgb( 0, 0, 0, 0.7)'
  },
  label: {
    width: 270,
    height: 20,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cardTabs: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  root: {
    overflowY: 'scroll',
    marginBottom: 15,
    display: 'contents',
    width: '100%',
    height: '100%'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 95px)',
    marginBottom: 15
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    marginTop: 30,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.8)'
      }
    },
    MuiInput: {
      input: {
        fontSize: 14,
        fontFamily: "'Roboto', sans-serif",
        width: '100%',
        height: 14,
        writeSpace: 'noWrap',
        textOverflow: 'ellipsis',
        fontStyle: 'normal !important'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #000000',
          opacity: 0.4
        }
      }
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        fontStyle: 'normal !important'
      }
    },
    MuiInputLabel: {
      animated: {
        fontSize: 14,
        fontStyle: 'normal !important'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0
      }
    },
    MuiButton: {
      root: {
        width: 96,
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14
      },
      contained: {
        height: '36px',
        marginLeft: '15px',
        color: '#FFFFFF',
        backgroundColor: '#42ADE8',
        '&:hover': {
          backgroundColor: '#42ADE8',
          '@media (hover: none)': {
            backgroundColor: '#42ADE8'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedPrimary: {
        backgroundColor: '#F33A30',
        '&:hover': {
          backgroundColor: '#F33A30',
          '@media (hover: none)': {
            backgroundColor: '#F33A30'
          }
        },
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto'
      },
      containedSecondary: {
        backgroundColor: '#00622B',
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        '&:hover': {
          backgroundColor: '#00622B',
          '@media (hover: none)': {
            backgroundColor: '#00622B'
          }
        }
      },
      colorInherit: {
        margin: 0,
        fontSize: 14,
        borderRadius: 5,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        width: 'auto',
        marginLeft: '15px',
        height: '36px',
        backgroundColor: '#463766',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#463766',
          '@media (hover: none)': {
            backgroundColor: '#463766'
          }
        }
      }
    }
  }
});

export { style, theme };
