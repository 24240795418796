export default {
  relatorio: {
    relatorio: 'Relatório',
    cadastroDeProdutor: 'Cadastro do Produtor',
    regiao: 'Região',
    municipio: 'Município',
    sim: 'Sim',
    nao: 'Não',
    carregando: 'Carregando...',
    gerandoRelatorio: 'Gerando relatório...',
    falhaAoGerarRelatorio: 'Falha ao gerar relatório',
    dataLancamento: 'Data de lançamento',
    tecnicoResponsavel: 'Técnico responsável',
    produtor: 'Produtor',
    uf: 'UF',
    decrescente: 'Decrescente',
    crescente: 'Crescente',
    media: 'Média',
    minimoFiltro: 'Aplicar no mínimo um filtro',
    exportarPlanilha: 'Exportar em planilha',
    mensagemSemFiltro:
      'Sem filtro de produtor, a geração do relatório poderá demorar, continuar mesmo assim?'
  }
};
