import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import { withStyles } from '@material-ui/core/styles';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditarContatosAdicionais from './EditarContatosAdicionais';
import SwipeableViews from 'react-swipeable-views';
import { style } from './../Clientes.styles';
import formatMessage from '../i18n/formatMessage';
import CardTitle from '@components/CardTitle/CardTitle';

class ContatosAdicionais extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      listContatos: [],
      stateEdit: false,
      headContatosAdicionais: [
        {
          field: 'nmDepartamento',
          headerName: formatMessage('clientes.departamento'),
          sort: 'asc',
          col: 2
        },
        {
          field: 'nmContato',
          headerName: formatMessage('clientes.nome'),
          col: 4
        },
        {
          field: 'dsEmail',
          headerName: formatMessage('clientes.email'),
          col: 4
        },
        {
          field: 'nrTelefone',
          headerName: formatMessage('clientes.telefone'),
          col: 2
        }
      ]
    };

    this.clickEditar = this.clickEditar.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderIfIndex = this.renderIfIndex.bind(this);
  }

  clickExcluir() {
    const position = this.props.clienteContatoList.indexOf(this.state.itemSelect);
    this.props.onClickDeleteContatosAdicionais(position);
  }

  clickEditar() {
    this.setState({
      index: 1,
      openModal: true,
      stateEdit: true
    });

    this.props.clickAdicionarContatos(this.state.itemSelect);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  clickAdicionar() {
    this.setState({
      openModal: true,
      stateEdit: false
    });
  }

  renderIfIndex(index, Component) {
    return index === this.state.index ? (
      <div key={index} style={{ height: '100%' }}>
        {Component}
      </div>
    ) : (
      <div key={index} />
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <SwipeableViews
        className={classes.swipeable}
        index={this.state.index}
        onChangeIndex={this.handleChangeIndex}
        ignoreNativeScroll={true}
        containerStyle={{ height: '100%' }}
        slideStyle={{ overflow: 'hidden' }}
      >
        {[
          this.renderIfIndex(
            0,
            <div key={0} className={classes.card}>
              <CardTitle
                containerStyle={{ marginBottom: -37 }}
                title={formatMessage('clientes.contatosAdicionais')}
              />

              <GroupButtonGrid
                showAdd={true}
                showEdit={true}
                showDelete={true}
                disableAdd={!this.props.status}
                disableEdit={this.props.status ? this.state.disabledEdit : true}
                disableDelete={this.props.status ? this.state.disabledEdit : true}
                onClickAdd={() => {
                  this.setState({
                    index: 1
                  });
                  this.props.clickAdicionarContatos();
                }}
                onClickEdit={this.clickEditar}
                onClickDelete={this.clickExcluir}
              />

              <DataTable
                keyData={this.props.keyData}
                usePaddings={false}
                data={this.props.clienteContatoList}
                columns={this.state.headContatosAdicionais}
                selectChange={this.selectChange}
                showPagination={false}
              />
            </div>
          ),
          this.renderIfIndex(
            1,
            <div
              key={1}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
            >
              <EditarContatosAdicionais
                selectContatosAdicionais={this.props.selectContatosAdicionais}
                onClickCancelar={() => {
                  this.setState({
                    index: 0
                  });
                  this.props.onClickCancelar();
                }}
                clienteContatoList={this.props.clienteContatoList}
                status={this.props.status}
                onClickContatosAdicionais={(values, position) => {
                  this.setState({
                    index: 0
                  });
                  this.props.onClickContatosAdicionais(values, position);
                }}
                itensDepartamento={this.props.itensDepartamento}
              />
            </div>
          )
        ]}
      </SwipeableViews>
    );
  }
}

ContatosAdicionais.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(ContatosAdicionais);
export default withRouter(connect(mapStateToProps)(enhaced));
