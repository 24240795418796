export default {
  auditoria: {
    descricao: 'Description',
    dataCadastro: 'Registration date',
    erroAoBuscarDados: 'Error to fetch data',
    auditoria: 'Audit',
    desejaExcluirAuditoria: 'Do you want to delete this audit',
    desejaExcluirMedidaCorretiva: '',
    nao: 'No',
    excluir: 'Delete',
    auditoriaExcluida: 'Audit successfully deleted',
    erroAoExcluir: 'Error to delete audit',
    cadastroDeAuditoria: 'Organic audit registration',
    campoObrigatorio: 'Required field',
    identificacao: 'Identification',
    salvar: 'Salve',
    cancelar: 'Cancel',
    auditoriaSalvoComSucesso: 'Audit successfully saved',
    erroAoSalvar: 'Error to save audit',
    salvando: 'Saving',
    atualizando: 'Updating',
    status: 'Status',
    ativo: 'Active',
    inativo: 'Inative',
    nome: 'Name',
    diasResolucao: 'Days for resolution',
    sigla: 'Initials',
    tipoPenalidade: 'Penalty type',
    nenhum: 'None',
    desclassificacaoSafra: 'Crop disqualification',
    desclassificacaoTalhao: 'Field declassification',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    sancao: 'Sanction',
    certificacao: 'Certification',
    cadastrarSancao: 'Register sanction',
    editar: 'Edit',
    medidasCorretivas: 'Corrective measures',
    medidaCorretiva: 'Corrective measure',
    geral: 'General',
    cadastroGeralExistente:
      'It is only possible to have a general sanction, or else it must be by certification',
    ok: 'Ok',
    produtor: 'Producer',
    areaCultivo: 'Cultivation area',
    motivoSuspensao: 'Reason for suspension',
    plantio: 'Planting',
    colheita: 'Harvest',
    tipoAuditoria: 'Audit type',
    interna: 'Internal',
    externa: 'External',
    situacao: 'Status',
    emAndamento: 'In progress',
    finalizadaSemNaoConformidade: 'Finished without non-compliance',
    finalizadaComNaoConformidade: 'Finished with non-compliance',
    suspensa: 'Suspended',
    auditores: 'Auditors',
    nomeAuditor: `Auditor's name`,
    naoConformidade: 'Non-compliance',
    dataComprimento: 'Compliance date',
    limiteCumprimento: 'Limit for compliance',
    observacoes: 'Comments',
    desejaAgendarMedidaCorretiva: 'Do you want to schedule corrective measures to be taken?',
    agendar: 'To schedule',
    talhao: 'Field',
    cadastrarNaoConformidade: 'Register non-compliance',
    aberto: 'Open',
    fechado: 'Cerrado',
    dataDeObservacao: 'Observation date',
    observacao: 'Observation',
    adicionarObservacao: 'Add note',
    statusFalseCadastrado: 'An observation with closed situation already exists',
    erroSubirArquivo: 'There was an error uploading a photo',
    insiraPlantio: 'Insert a plantation to register non-compliance',
    alterandoPlantioLimpaNaoConformidade:
      'When changing the planting, all non-conformities will be cleared, do you want to continue?',
    agendaVisita: 'Schedule visit',
    erroAoCadastrar: 'Error when registering schedule',
    visitaCadastradaComSucesso: 'Visit successfully registered',
    mesmoHorario: 'There is a scheduled visit at the same time for',
    talhoes: 'Fields',
    possuiInfoComplementar: 'has additional information'
  }
};
