import React from 'react';
import { connect } from 'react-redux';
import CardIcon from '@components/CardIcon/CardIcon';
import { Add, Edit } from '@material-ui/icons';
import formatMessage from './../i18n/formatMessage';
import { Grid } from '@material-ui/core';
import { formatTextField } from './../header';
import moment from 'moment';
import Chip from '@components/Chip/Chip';

/**
 * Screen de cards de não conformidade
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {*} props
 * @returns {JSX.Element}
 */
function AuditoriaNaoConformidade(props) {
  const { classes, naoConformidadeSancaoList, clickAddOrEditNaoConformidadeSancao, isTecnico } = props;

  function findDtComprimento(naoConformidadeSancao) {
    const historico = naoConformidadeSancao.historicoAuditoriaList.find(
      historicoAux => historicoAux.stHistoricoAuditoria === 1
    );

    if (historico) {
      return moment(historico.dhHistoricoAuditoria).format(formatTextField());
    } else {
      return '';
    }
  }

  return (
    <div style={{ width: '100%', marginTop: 30 }}>
      <CardIcon titulo={formatMessage('auditoria.naoConformidade')}>
        {!isTecnico && (
          <div className={classes.cardOutsideButtom}>
            <div
              className={classes.cardButtom}
              onClick={() => clickAddOrEditNaoConformidadeSancao(null)}
            >
              <Add />
              {formatMessage('auditoria.cadastrarNaoConformidade')}
            </div>
          </div>
        )}

        {naoConformidadeSancaoList.map(naoConformidadeSancao => (
          <div
            key={naoConformidadeSancao.idAuditoriaPlantioNaoConformidade}
            style={{ marginTop: 30 }}
            className={classes.cardEdit}
          >
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <div className={classes.cardCertificacao}>
                  <div className={classes.labelTitle}>{formatMessage('auditoria.naoConformidade')}</div>
                  <div className={classes.labelInfo}>
                    {naoConformidadeSancao.naoConformidade
                      ? naoConformidadeSancao.naoConformidade.nmNaoConformidade
                      : ''}
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className={classes.cardCertificacao}>
                  <div className={classes.labelTitle}>{formatMessage('auditoria.sancao')}</div>
                  <div className={classes.labelInfo}>
                    {naoConformidadeSancao.sancaoCategoria
                      ? naoConformidadeSancao.sancaoCategoria.nmSancaoCategoria
                      : ''}
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={16}>
              <Grid item xs={6}>
                <div className={classes.cardCertificacao}>
                  <div className={classes.labelTitle}>
                    {formatMessage('auditoria.limiteCumprimento')}
                  </div>
                  <div className={classes.labelInfo}>
                    {naoConformidadeSancao.dhResolucaoMedidaCorretiva
                      ? moment(naoConformidadeSancao.dhResolucaoMedidaCorretiva).format(
                          formatTextField()
                        )
                      : ''}
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className={classes.cardCertificacao}>
                  <div className={classes.labelTitle}>{formatMessage('auditoria.dataComprimento')}</div>
                  <div className={classes.labelInfo}>{findDtComprimento(naoConformidadeSancao)}</div>
                </div>
              </Grid>
            </Grid>

            <div className={classes.cardChips}>
              {naoConformidadeSancao.talhaoNaoConformidadeList
                .filter(talhaoNaoConformidade => talhaoNaoConformidade.stRegistro !== 2)
                .map((talhaoNaoConformidade, i) => (
                  <Chip
                    hiddenClose={true}
                    key={i}
                    text={talhaoNaoConformidade.plantioTalhao.talhao.dsTalhao}
                  />
                ))}
            </div>

            <div className={classes.cardEditarNaoConformidade}>
              <div
                style={{ backgroundColor: '#463766', width: 100 }}
                className={classes.cardButtom}
                onClick={() => clickAddOrEditNaoConformidadeSancao(naoConformidadeSancao)}
              >
                <Edit className={classes.iconEdit} />
                {formatMessage('auditoria.editar')}
              </div>

              {naoConformidadeSancao.historicoAuditoriaList.length > 0 && (
                <div className={classes.labelTitle} style={{ paddingBottom: 0 }}>
                  {`***${formatMessage('auditoria.possuiInfoComplementar')}`}
                </div>
              )}
            </div>

            {naoConformidadeSancaoList.length > 1 && <div className={classes.boxShadow} />}
          </div>
        ))}
      </CardIcon>
    </div>
  );
}

/**
 * Mapeia as props da store para o componente
 *
 * @param {*} state - State da store
 */
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

export default connect(mapStateToProps)(AuditoriaNaoConformidade);
