import URL from './URL';

const axios = require('axios');
const api = URL.API;

function findAllSafrasHandler() {
  return axios.get(api + '/safra');
}

function findSafrasById(idSafras) {
  return axios.get(api + '/safra/' + idSafras);
}

function deleteSafrasByIdHandler(idSafras) {
  return axios.delete(api + '/safra/' + idSafras);
}

function addSafrasHandler(values) {
  return axios.post(api + '/safra', values);
}

function editSafrasHandler(values) {
  return axios.put(api + '/safra', values);
}

export default {
  findAllSafras: findAllSafrasHandler,
  findSafrasById: findSafrasById,
  deleteSafrasById: deleteSafrasByIdHandler,
  addSafras: addSafrasHandler,
  editSafras: editSafrasHandler
};
