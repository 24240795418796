import React, { Component } from 'react';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import DashboardCard from '@components/DashboardCard/DashboardCard';
import AgendaHeader from '@components/Agenda/AgendaHeader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LineChart from '@components/Charts/LineChart';
import BarChart from '@components/Charts/BarChart';
import Card from '@components/Card/Card';
import { withStyles } from '@material-ui/core/styles';
import AgendaVisitas from '@resources/AgendaVisitas';
import * as moment from 'moment';
import Loading from '@components/Loading/Loading';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const style = theme => ({
  card: {
    height: 'auto',
    borderRadius: 0,
    marginBottom: 20,
    flex: 'none'
  }
});

class RelatorioAgenda extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      visitasAgendadas: 0,
      visitasSemAgenda: 0,
      prodVisitasPendentes: 0,
      listTotalVisitasAgendadas: [],
      listTotalVisitasSemAgenda: [],
      listVisitasAgendadas: {
        labelFirst: [],
        labelSecond: [],
        itemFirst: [],
        itemSecond: []
      },
      listVisitasSemAgenda: {
        labelFirst: [],
        labelSecond: [],
        itemFirst: [],
        itemSecond: []
      },
      listProdVisitasPendentes: {
        labelFirst: [],
        labelSecond: [],
        itemFirst: [],
        itemSecond: []
      },
      labelFirst: [],
      labelSecond: [],
      itemFirst: [],
      itemSecond: [],
      typeSelect: 0,
      dayInitial: '',
      dayFinal: '',
      labelSeta: '10 +',
      seta: true
    };
    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const agenda = messagesLinguage['agenda.agenda'];
    const visitasAgendadas = messagesLinguage['agenda.visitasAgendadas'];
    const visitasSemAgenda = messagesLinguage['agenda.visitasSemAgenda'];
    const produtoresPendentesVisita =
      messagesLinguage['agenda.produtoresPendentesVisita'];
    const totalVisitasAgendadas =
      messagesLinguage['agenda.totalVisitasAgendadas'];
    const totalVisitasSemAgenda =
      messagesLinguage['agenda.totalVisitasSemAgenda'];
    const produtoresMaiorIntervalo =
      messagesLinguage['agenda.produtoresMaiorIntervalo'];
    const visitasAgendadasTecnico =
      messagesLinguage['agenda.visitasAgendadasTecnico'];
    const visitasRealizadasTecnico =
      messagesLinguage['agenda.visitasRealizadasTecnico'];
    const visitasMaisProdutoresPendentes =
      messagesLinguage['agenda.visitasMaisProdutoresPendentes'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          agenda,
          visitasAgendadas,
          visitasSemAgenda,
          produtoresPendentesVisita,
          totalVisitasAgendadas,
          totalVisitasSemAgenda,
          produtoresMaiorIntervalo,
          visitasAgendadasTecnico,
          visitasRealizadasTecnico,
          visitasMaisProdutoresPendentes
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    const title = 'agenda.agenda';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: 'Dashboard',
        name: title
      });
    }

    this.getDate = this.getDate.bind(this);
    this.getVisitasAgendadas = this.getVisitasAgendadas.bind(this);
    this.getVisitasSemAgendas = this.getVisitasSemAgendas.bind(this);
    this.getProdutoresVisitasPendentes = this.getProdutoresVisitasPendentes.bind(
      this
    );
    this.titleFirstCard = this.titleFirstCard.bind(this);
    this.titleSecondCard = this.titleSecondCard.bind(this);
    this.carregarValores = this.carregarValores.bind(this);
    this.changeOrdemTopDez = this.changeOrdemTopDez.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.showTopDez = this.showTopDez.bind(this);
    this.updateDados = this.updateDados.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
      typeSelect: 0,
      labelSeta: '10 +',
      seta: true,
      listTotalVisitasAgendadas: [],
      listTotalVisitasSemAgenda: []
    });
  }

  getDate(dayInitial, dayFinal) {
    this.getVisitasAgendadas(dayInitial, dayFinal, this.state.typeSelect === 0);
    this.getVisitasSemAgendas(
      dayInitial,
      dayFinal,
      this.state.typeSelect === 1
    );
    this.getProdutoresVisitasPendentes(
      dayInitial,
      dayFinal,
      this.state.typeSelect === 2
    );
    AgendaVisitas.findAgendaCard(dayInitial, dayFinal)
      .then(doc => {
        this.setState({
          visitasAgendadas: doc.data.totalAgendados,
          visitasSemAgenda: doc.data.totalVisitasSemAgenda,
          prodVisitasPendentes: doc.data.totalProdutoresPendentes,
          dayInitial: dayInitial,
          dayFinal: dayFinal,
          loading: false
        });

        this.updateDados();
      })
      .catch(err => {
        this.setState({
          visitasAgendadas: 0,
          visitasSemAgenda: 0,
          prodVisitasPendentes: 0,
          dayInitial: [],
          dayFinal: [],
          loading: false
        });
      });
  }

  updateDados() {
    var itens = {};
    if (this.state.typeSelect === 0) {
      itens = this.state.listVisitasAgendadas;
    } else if (this.state.typeSelect === 1) {
      itens = this.state.listVisitasSemAgenda;
    } else if (this.state.typeSelect === 2) {
      itens = this.state.listProdVisitasPendentes;
    }

    this.setState({
      itemFirst: itens.itemFirst,
      labelFirst: itens.labelFirst,
      itemSecond: itens.itemSecond,
      labelSecond: itens.labelSecond
    });
  }

  getVisitasAgendadas(dayInitial, dayFinal, update) {
    AgendaVisitas.findVisitasAgendadas(dayInitial, dayFinal)
      .then(doc => {
        var values = [];
        var label = [];
        doc.data.quantitativoDiario.forEach(quant => {
          label.push(
            moment(quant.data)
              .utc()
              .format(this.formatDate())
          );
          values.push(quant.total);
        });

        var name = [];
        var total = [];
        var contador = 0;
        var listTotalVisitasAgendadas = [];
        doc.data.quantidadePorTecnico.forEach(quant => {
          if (contador < 10) {
            name.push(quant.nome);
            total.push(quant.total);
            contador = contador + 1;
          }
          listTotalVisitasAgendadas.push(quant);
        });

        const result = {
          itemFirst: values,
          labelFirst: label,
          itemSecond: total,
          labelSecond: name
        };

        this.setState({
          listVisitasAgendadas: result,
          listTotalVisitasAgendadas: listTotalVisitasAgendadas
        });

        if (update) {
          this.updateDados();
        }
      })
      .catch(() => {
        this.setState({
          itemFirst: [],
          labelFirst: [],
          itemSecond: [],
          labelSecond: []
        });
      });
  }

  getVisitasSemAgendas(dayInitial, dayFinal, update) {
    AgendaVisitas.findVisitasSemAgendas(dayInitial, dayFinal)
      .then(doc => {
        var values = [];
        var label = [];

        doc.data.quantitativoDiario.forEach(quant => {
          label.push(
            moment(quant.data)
              .utc()
              .format(this.formatDate())
          );
          values.push(quant.total);
        });

        var nome = [];
        var total = [];
        var contador = 0;
        var listTotalVisitasSemAgenda = [];
        doc.data.quantidadePorTecnico.forEach(quant => {
          if (contador < 10) {
            nome.push(quant.nome);
            total.push(quant.total);
            contador = contador + 1;
          }
          listTotalVisitasSemAgenda.push(quant);
        });

        const result = {
          itemFirst: values,
          labelFirst: label,
          itemSecond: total,
          labelSecond: nome
        };

        this.setState({
          listVisitasSemAgenda: result,
          listTotalVisitasSemAgenda: listTotalVisitasSemAgenda
        });

        if (update) {
          this.updateDados();
        }
      })
      .catch(() => {
        this.setState({
          itemFirst: [],
          labelFirst: [],
          itemSecond: [],
          labelSecond: []
        });
      });
  }

  getProdutoresVisitasPendentes(dayInitial, dayFinal, update) {
    AgendaVisitas.findProdutoresVisitasPendentes(dayInitial, dayFinal)
      .then(doc => {
        var values = [];
        var label = [];
        var cont = 0;

        doc.data.produtoresPendentes.forEach(quant => {
          if (cont < 10) {
            label.push(quant.nome);
            values.push(quant.total);
            cont = cont + 1;
          }
        });

        var name = [];
        var total = [];
        var contador = 0;
        doc.data.tecnicosComPendencias.forEach(quant => {
          if (contador < 10) {
            name.push(quant.nome);
            total.push(quant.total);
            contador = contador + 1;
          }
        });

        const result = {
          itemFirst: values,
          labelFirst: label,
          itemSecond: total,
          labelSecond: name
        };

        this.setState({
          listProdVisitasPendentes: result
        });

        if (update) {
          this.updateDados();
        }
      })
      .catch(err => {
        this.setState({
          itemFirst: [],
          labelFirst: [],
          itemSecond: [],
          labelSecond: []
        });
      });
  }

  titleFirstCard() {
    switch (this.state.typeSelect) {
      case 0:
        return provider.intl.formatMessage({ id: 'totalVisitasAgendadas' });
      case 1:
        return provider.intl.formatMessage({ id: 'totalVisitasSemAgenda' });
      default:
        return provider.intl.formatMessage({ id: 'produtoresMaiorIntervalo' });
    }
  }

  formatDate() {
    if (getUserLang() === 'en-US') {
      return 'MM-DD';
    } else {
      return 'DD/MM';
    }
  }

  titleSecondCard() {
    switch (this.state.typeSelect) {
      case 0:
        return provider.intl.formatMessage({ id: 'visitasAgendadasTecnico' });
      case 1:
        return provider.intl.formatMessage({ id: 'visitasRealizadasTecnico' });
      default:
        return provider.intl.formatMessage({
          id: 'visitasMaisProdutoresPendentes'
        });
    }
  }

  carregarValores(dayInitial, dayFinal) {
    this.getDate(dayInitial, dayFinal);

    this.setState({
      dayInitial: dayInitial,
      dayFinal: dayFinal
    });
  }

  changeOrdemTopDez() {
    var list = [];
    var listSemVisitas = [];
    if (!this.state.seta) {
      list = this.state.listTotalVisitasAgendadas.slice(0, 10);
      listSemVisitas = this.state.listTotalVisitasSemAgenda.slice(0, 10);
    } else {
      list = this.state.listTotalVisitasAgendadas.slice(
        this.state.listTotalVisitasAgendadas.length - 10,
        this.state.listTotalVisitasAgendadas.length
      );
      listSemVisitas = this.state.listTotalVisitasSemAgenda.slice(
        this.state.listTotalVisitasSemAgenda.length - 10,
        this.state.listTotalVisitasSemAgenda.length
      );
    }

    const result = {
      itemFirst: this.state.listVisitasAgendadas.itemFirst,
      labelFirst: this.state.listVisitasAgendadas.labelFirst,
      itemSecond: list.map(doc => {
        return doc.total;
      }),
      labelSecond: list.map(doc => {
        return doc.nome;
      })
    };

    const resultSemVisitas = {
      itemFirst: this.state.listVisitasSemAgenda.itemFirst,
      labelFirst: this.state.listVisitasSemAgenda.labelFirst,
      itemSecond: listSemVisitas.map(doc => {
        return doc.total;
      }),
      labelSecond: listSemVisitas.map(doc => {
        return doc.nome;
      })
    };

    if (this.state.typeSelect === 0) {
      this.setState({
        itemSecond: list.map(doc => {
          return doc.total;
        }),
        labelSecond: list.map(doc => {
          return doc.nome;
        }),
        listVisitasAgendadas: result,
        listVisitasSemAgenda: resultSemVisitas
      });
    } else if (this.state.typeSelect === 1) {
      this.setState({
        itemSecond: listSemVisitas.map(doc => {
          return doc.total;
        }),
        labelSecond: listSemVisitas.map(doc => {
          return doc.nome;
        }),
        listVisitasAgendadas: result,
        listVisitasSemAgenda: resultSemVisitas
      });
    }
  }

  showTopDez() {
    switch (this.state.typeSelect) {
      case 0:
        return (
          this.state.listTotalVisitasAgendadas &&
          this.state.listTotalVisitasAgendadas.length > 10
        );
      case 1:
        return (
          this.state.listTotalVisitasSemAgenda &&
          this.state.listTotalVisitasSemAgenda.length > 10
        );
      default:
        return (
          this.state.listProdVisitasPendentes &&
          this.state.listProdVisitasPendentes.itemSecond.length > 10
        );
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
        key={0}
      >
        {this.state.loading && <Loading />}
        <AgendaHeader
          getDate={this.getDate}
          onViewChanged={(value, dayInitial, dayFinal) => {
            this.setState({
              loading: true
            });
            this.carregarValores(dayInitial, dayFinal);
          }}
          onDateChanged={(dayInitial, dayFinal) => {
            this.setState({
              loading: true
            });
            this.carregarValores(dayInitial, dayFinal);
          }}
        >
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
            <DashboardCard
              select={this.state.typeSelect === 0}
              onClickCard={() => {
                const itens = this.state.listVisitasAgendadas;

                this.setState({
                  typeSelect: 0,
                  itemFirst: itens.itemFirst,
                  labelFirst: itens.labelFirst,
                  itemSecond: itens.itemSecond,
                  labelSecond: itens.labelSecond
                });
              }}
              color='#00622B'
              mediaPositiva={true}
              value={this.state.visitasAgendadas}
              labelMedia={this.state.labelSelect}
              title={provider.intl.formatMessage({ id: 'visitasAgendadas' })}
            />
            <DashboardCard
              select={this.state.typeSelect === 1}
              onClickCard={() => {
                const itens = this.state.listVisitasSemAgenda;

                this.setState({
                  typeSelect: 1,
                  itemFirst: itens.itemFirst,
                  labelFirst: itens.labelFirst,
                  itemSecond: itens.itemSecond,
                  labelSecond: itens.labelSecond
                });
              }}
              color='#463766'
              value={this.state.visitasSemAgenda}
              labelMedia={this.state.labelSelect}
              title={provider.intl.formatMessage({ id: 'visitasSemAgenda' })}
            />
            <DashboardCard
              select={this.state.typeSelect === 2}
              last={true}
              onClickCard={() => {
                const itens = this.state.listProdVisitasPendentes;

                this.setState({
                  typeSelect: 2,
                  itemFirst: itens.itemFirst,
                  labelFirst: itens.labelFirst,
                  itemSecond: itens.itemSecond,
                  labelSecond: itens.labelSecond
                });
              }}
              color='#F33A30'
              value={this.state.prodVisitasPendentes}
              labelMedia={this.state.labelSelect}
              title={provider.intl.formatMessage({
                id: 'produtoresPendentesVisita'
              })}
            />
          </div>
        </AgendaHeader>
        <Card
          marginBottom={true}
          withOutBorderRadius={true}
          className={classes.card}
          title={this.titleFirstCard()}
          height={'auto'}
          showLabel={
            this.state.typeSelect === 2 &&
            this.state.listProdVisitasPendentes &&
            this.state.listProdVisitasPendentes.itemFirst.length > 10
          }
        >
          <div>
            {(this.state.typeSelect === 0 || this.state.typeSelect === 1) && (
              <LineChart
                color={this.state.typeSelect}
                label={this.state.labelFirst}
                data={this.state.itemFirst}
              />
            )}
            {this.state.typeSelect === 2 && (
              <BarChart
                color={this.state.typeSelect}
                label={this.state.labelFirst}
                data={this.state.itemFirst}
              />
            )}
          </div>
        </Card>
        <Card
          marginBottom={true}
          withOutBorderRadius={true}
          className={classes.card}
          title={this.titleSecondCard()}
          height={'auto'}
          showLabel={this.showTopDez()}
          button={this.showTopDez()}
          labelButton={this.state.labelSeta}
          directionSeta={this.state.seta}
          clickSeta={() => {
            if (this.state.listTotalVisitasAgendadas.length > 10) {
              this.changeOrdemTopDez();
              this.setState({
                labelSeta: !this.state.seta ? '10 +' : '10 -',
                seta: !this.state.seta
              });
            }
          }}
        >
          <div>
            <BarChart
              color={this.state.typeSelect}
              label={this.state.labelSecond}
              data={this.state.itemSecond}
            />
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(RelatorioAgenda);
export default withRouter(connect(mapStateToProps)(enhaced));
