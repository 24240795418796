import React, { Component } from "react";
import Login from "./views/Login/Login";
import "./App.css";
import Authentication from "@resources/oauth/Authentication";

Authentication.inicializarInterceptos();

class App extends Component {
  render() {
    return (
      <div>
        <Login />
      </div>
    );
  }
}
export default App;
