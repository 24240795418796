export default {
  plantios: {
    descartar: 'To discard',
    salvarPlantioSair: 'There is unsaved information, do you want to save the planting?',
    atividadeRelacionadaDeletePlantio:
      'Cannot delete planting because there is an activity related to it',
    falhaoAoExcluir: 'There was an error deleting the planting, please try again.',
    minimoTalhoes: 'Add at least 1 field',
    falhaAoSalvarPlantio: 'There was an error saving the planting, please try again.',
    plantioSalvoComSucesso: 'Planting saved successfully',
    salvando: 'Saving',
    atualizando: 'Updating',
    alertasFitossanitarios: 'Phytosanitary alerts',
    invasoras: 'Invasive',
    tipo: 'Type',
    agenteCausal: 'Causal agent',
    data: 'Date',
    doencaSolo: 'Soil disease',
    doencaFoliar: 'Leaf disease',
    nematoide: 'Nematode',
    pragas: 'Pests',
    desejaLimparTalhoes:
      'If you change the cultivation area the selected plots will be deleted, do you want to continue?',
    sim: 'Yes',
    produtorSemAreaCultivo: 'The farmer must have at least one crop area to create a crop',
    produtorSemTalhao: 'The farmer must have at least one field to create a crop',
    convencional: 'Conventional',
    emConversao: 'In conversion',
    brasil: 'BR (Brasil)',
    comunidadeEuropeia: 'EC (European Community)',
    eua: 'NOP (United States)',
    certificacaoOrganica: 'Organic certification status',
    selecionarTodos: 'Select all',
    talhao: 'Field',
    area: 'Area',
    produtividade: 'Productivity',
    diasEstimados: 'Estimated Days',
    dataPrevista: 'Expected date',
    produtividadeEstimada: 'Estimated productivity',
    salvar: 'Save',
    cancelar: 'Cancel',
    quantidadePlantas: 'Number of plants',
    metro: 'Meter',
    hectare: 'Hectare',
    alqueire: 'Bushel',
    acre: 'Acre',
    tonelada: 'Tonne',
    sacas: 'Sacks',
    sementes: 'Seeds',
    nrQuantidade: 'Amount',
    unidade: 'Unity',
    unMedida: 'Un. of measure',
    espacamento: 'Spacing (cm)',
    semeadura: 'Seeding',
    standFinal: 'Final stand',
    previsaoColheita: 'Harvest forecast',
    colheita: 'Harvest',
    localPlantio: 'Planting dite',
    campoObrigatorio: 'Required field',
    cadastroPlantio: 'Planting register',
    plantioExcluido: 'Planting successfully deleted',
    nao: 'No',
    excluir: 'Delete',
    desejaExcluirPlantio: 'Do you really want to delete this planting?',
    dataPlantio: 'Date of planting',
    areaDeCultivo: 'Cultivation area',
    cultura: 'Culture',
    detentor: 'Holder',
    cultivar: 'Grow crops',
    dataColheita: 'Harvest date',
    produtor: 'Grower',
    plantios: 'Plantings',
    identificacao: 'Identification',
    barreiraVegetalSul: 'Barreira vegetal south',
    barreiraVegetalNorte: 'Barreira vegetal north',
    barreiraVegetalLeste: 'Barreira vegetal east',
    barreiraVegetalOeste: 'Barreira vegetal west',
    barreiraVegSul: 'Barreira veg. south',
    barreiraVegNorte: 'Barreira veg. north',
    barreiraVegLeste: 'Barreira veg. east',
    barreiraVegOeste: 'Barreira veg. west',
    vizinho: 'Neighbor',
    entregaEfetiva: 'Effective delivery',
    causa: 'Cause',
    insumos: 'Inputs',
    ogm: 'OGM',
    analiseOGM: 'GMO Analysis',
    materalPropagacao: 'Propagation material',
    laudos: 'Reports',
    descricao: 'Description',
    origemSemente: 'Seed Origin',
    descricaoOrigem: 'Description of origin',
    sementeTratada: 'Seed treated',
    categoria: 'Category',
    marca: 'Brand',
    produto: 'Product',
    acaricida: 'Acaricides',
    acaricidaMicrobiologico: 'Microbiological acaricides',
    agenteBiologicoControle: 'Biological agents',
    bactericida: 'Bactericidal',
    bactericidaMicrobiologico: 'Microbiological bactericides',
    herbicida: 'Herbicides',
    fungicida: 'Fungicidas',
    fungicidaMicrobiologico: 'Microbiological fungicides',
    inseticida: 'Inseticidas',
    inseticidaMicrobiologico: 'Microbiological insecticides',
    nematicida: 'Nematicides',
    nematicidaMicrobiologico: 'Microbiological nematicides',
    fertilizanteFoliar: 'Fertilizer foliar',
    acaros: 'Mites',
    bacterias: 'Bacteria',
    ervasDaninhas: 'Weeds',
    fungos: 'Fungi',
    insetos: 'Insects',
    nematoides: 'Nematodes',
    foliaresAdjuvantes: 'Leaf and Adjuvant',
    fertilizantes: 'Fertilizers',
    fertilizanteQuimico: 'Chemical fertilizers',
    fertilizanteOrganico: 'Organic fertilizers',
    oleosAdjuvantes: 'Oils and adjuvants',
    desejaExcluirInsumo: 'Do you want to delete input?',
    insumoRemovidaComSucesso: 'Input removed successfully',
    falhaAoExcluirInsumo: 'Failed to delete input',
    operadora: 'Operator',
    propria: 'Own',
    outros: 'Others',
    excluirTalhao: 'Do you want to delete the planting location?',
    homeopaticos: 'Homeopathic Products',
    inoculantesHomeopatia: 'Inoculants and Homeopathy',
    inoculantes: 'Inoculants',
    m2: 'm2',
    kg: 'Kg',
    mudas: 'Seedling',
    safra: 'Harvest',
    adicionarLaudo: 'Add report',
    erroAnexarFoto: 'Error attaching file',
    nenhumLaudo: 'No report, you can enter up to 3 reports',
    removeArquivo: 'Do you want to remove file?',
    insumoMaterialProgapagacao: 'Inputs and mat. of propagation',
    insumosPlantio: 'Planting inputs',
    possuiLaudo: 'Has a GMO report',
    formaAplic: 'Application form',
    talhaoRelacionado: 'Field cannot be deleted as it contains an audit'
  }
};
