import React, { Component } from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Card from '@components/Card/Card';
import Grid from '@material-ui/core/Grid';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ButtonFABMenu from '@components/ButtonFABMenu/ButtonFABMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Loading from '@components/Loading/Loading';
import Regiao from '@resources/Regiao';
import Municipio from './Municipio';
import EditarMunicpio from './EditarMunicipio';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const iconMenuPontos = require('@images/icone_fab_menu.png');

let { provider } = new IntlProvider({});

const style = () => ({
  switch: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    cursor: 'pointer'
  },
  card: {
    width: '100%'
  },
  cardGrid: {
    width: '100%',
    height: '100%'
  },
  font: {
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 27.5,
    right: theme.spacing.unit * 3.7,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

/**
 * Componente de edição e cadastro de Região
 *
 * @author Gabriela Farias
 * @class EditarRegiao
 * @extends {Component} - Componente React
 */
class EditarRegiao extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const cadastroDeRegiao = messagesLinguage['regiao.cadastroDeRegiao'];
    const falhaAoCarregarRegiao = messagesLinguage['regiao.falhaAoCarregarRegiao'];
    const atualizando = messagesLinguage['regiao.atualizando'];
    const salvando = messagesLinguage['regiao.salvando'];
    const regiaoCadastradaComSucesso = messagesLinguage['regiao.regiaoCadastradaComSucesso'];
    const falhaAoCadastrarRegiao = messagesLinguage['reigao.falhaAoCadastrarRegiao'];
    const regiaoEditadaComSucesso = messagesLinguage['regiao.regiaoEditadaComSucesso'];
    const falhaAoEditarRegiao = messagesLinguage['regiao.falhaAoEditarRegiao'];
    const campoObrigatorio = messagesLinguage['regiao.campoObrigatorio'];
    const identificacao = messagesLinguage['regiao.identificacao'];
    const descricao = messagesLinguage['regiao.descricao'];
    const salvar = messagesLinguage['regiao.salvar'];
    const cancelar = messagesLinguage['regiao.cancelar'];
    const pertenceRegiao = messagesLinguage['regiao.pertenceRegiao'];
    const oMunicipio = messagesLinguage['regiao.oMunicipio'];
    const municipiosJáRelacinados = messagesLinguage['regiao.municipiosJáRelacinados'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          cadastroDeRegiao,
          falhaAoCarregarRegiao,
          atualizando,
          salvando,
          regiaoCadastradaComSucesso,
          falhaAoCadastrarRegiao,
          regiaoEditadaComSucesso,
          falhaAoEditarRegiao,
          campoObrigatorio,
          identificacao,
          descricao,
          salvar,
          cancelar,
          pertenceRegiao,
          oMunicipio,
          municipiosJáRelacinados
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      keyAutoComplete: Math.random(),
      itemRegiao: {
        nmRegiao: '',
        regiaoMunicipioList: []
      },
      idRegiao:
        this.props.match.params.id !== undefined ? parseInt(this.props.match.params.id) : undefined,
      loading: this.props.match.params.id !== undefined,
      checked: true,
      error: false,
      dataKey: 0
    };

    const title = 'regiao.cadastroDeRegiao';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.clickCadastrarEditar = this.clickCadastrarEditar.bind(this);
    this.clickCancelar = this.clickCancelar.bind(this);
    this.iniciarAtributosEdicao = this.iniciarAtributosEdicao.bind(this);
    this.clickSalvarMunicipio = this.clickSalvarMunicipio.bind(this);
    this.onClickDeleteMunicipio = this.onClickDeleteMunicipio.bind(this);
  }

  componentDidMount() {
    if (this.state.idRegiao !== undefined) {
      this.iniciarAtributosEdicao();
    }
  }

  iniciarAtributosEdicao() {
    Regiao.findRegiaoById(this.state.idRegiao)
      .then(query => {
        this.setState({
          itemRegiao: query.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: true
        });
        swal(provider.intl.formatMessage({ id: 'falhaAoCarregarRegiao' }), {
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-error'
            }
          }
        });
      });
  }

  clickCadastrarEditar(values) {
    if (!this.state.validarInputs) {
      swal(
        <div>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>,
        {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          title: provider.intl.formatMessage({
            id: this.state.idRegiao === undefined ? 'salvando' : 'atualizando'
          })
        }
      );

      values.regiaoMunicipioList = this.state.itemRegiao.regiaoMunicipioList;

      if (this.state.idRegiao === undefined) {
        Regiao.addRegiao(values)
          .then(query => {
            swal(
              provider.intl.formatMessage({
                id: 'regiaoCadastradaComSucesso'
              }),
              {
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-Ok'
                  }
                }
              }
            ).then(() => {
              this.clickCancelar();
            });
          })
          .catch(error => {
            if (error.response && error.response.data.codigo === 'MUNICIPIO_DUPLICADO') {
              swal(
                <div>
                  {error.response.data.data.map(item => {
                    return (
                      <div
                        key={item.idRegiaoMunicipio}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <div style={{ fontWeight: 400, marginRight: 4 }}>{item.nmMunicipio + ' '} </div>
                        <div style={{ marginTop: 2 }}>{' - ' + item.nmRegiao} </div>
                      </div>
                    );
                  })}
                </div>,
                {
                  title: provider.intl.formatMessage({ id: 'municipiosJáRelacinados' }),
                  icon: 'warning',
                  closeOnClickOutside: false,
                  closeOnEsc: true,
                  buttons: {
                    confirm: {
                      text: 'Ok',
                      value: true,
                      visible: true,
                      closeModal: true,
                      className: 'swal2-error'
                    }
                  }
                }
              );
            } else {
              swal(provider.intl.formatMessage({ id: 'falhaAoCadastrarRegiao' }), {
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-error'
                  }
                }
              });
            }
          });
      } else {
        values.idRegiao = this.state.idRegiao;

        Regiao.editRegiao(values)
          .then(query => {
            swal(provider.intl.formatMessage({ id: 'regiaoEditadaComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              this.clickCancelar();
            });
          })
          .catch(error => {
            if (error.response && error.response.data.codigo === 'MUNICIPIO_DUPLICADO') {
              swal(
                <div>
                  {error.response.data.data.map(item => {
                    return (
                      <div
                        key={item.idRegiaoMunicipio}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <div style={{ fontWeight: 400, marginRight: 4 }}>{item.nmMunicipio + ' '} </div>
                        <div style={{ marginTop: 2 }}>{' - ' + item.nmRegiao} </div>
                      </div>
                    );
                  })}
                </div>,
                {
                  title: provider.intl.formatMessage({ id: 'municipiosJáRelacinados' }),
                  icon: 'warning',
                  closeOnClickOutside: false,
                  closeOnEsc: true,
                  buttons: {
                    confirm: {
                      text: 'Ok',
                      value: true,
                      visible: true,
                      closeModal: true,
                      className: 'swal2-error'
                    }
                  }
                }
              );
            } else {
              swal(provider.intl.formatMessage({ id: 'falhaAoEditarRegiao' }), {
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Ok',
                    value: true,
                    visible: true,
                    closeModal: true,
                    className: 'swal2-error'
                  }
                }
              });
            }
          });
      }
    }
  }

  clickCancelar() {
    this.props.history.push('/app/regiao');
  }

  onClickDeleteMunicipio(position) {
    const data = this.state.itemRegiao;
    data.regiaoMunicipioList.splice(position, 1);
    data.regiaoMunicipioList = [...data.regiaoMunicipioList];

    this.setState({
      itemRegiao: data
    });
  }

  clickSalvarMunicipio(values) {
    var itemRegiao = this.state.itemRegiao;

    itemRegiao.regiaoMunicipioList.push(values);

    this.setState({
      itemRegiao: itemRegiao,
      dataKey: Math.random()
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            width: '100%'
          }}
        >
          {this.state.loading && <Loading />}
          <Formik
            initialValues={{
              nmRegiao: this.state.itemRegiao.nmRegiao
            }}
            onSubmit={this.clickCadastrarEditar}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              nmRegiao: Yup.string().required(provider.intl.formatMessage({ id: 'campoObrigatorio' }))
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              handleChange
            }) => (
              <MuiThemeProvider theme={theme}>
                <Card
                  withOutBorderRadius
                  title={provider.intl.formatMessage({ id: 'identificacao' })}
                  height='auto'
                  style={{ minHeight: 190, maxHeight: 190 }}
                >
                  <form autoComplete='off'>
                    <Grid container spacing={16}>
                      <Grid item xs={4}>
                        <TextField
                          label={provider.intl.formatMessage({ id: 'descricao' })}
                          name='nmRegiao'
                          value={values.nmRegiao}
                          onBlur={handleBlur}
                          onChange={value => {
                            setFieldValue('nmRegiao', value.target.value);

                            var itemRegiao = this.state.itemRegiao;
                            itemRegiao.nmRegiao = value.target.value;
                          }}
                          error={errors.nmRegiao && touched.nmRegiao}
                          helperText={errors.nmRegiao && touched.nmRegiao ? errors.nmRegiao : null}
                          style={{ width: '100%', height: 70 }}
                        />
                      </Grid>
                    </Grid>

                    <div className={classes.fab}>
                      <ButtonFABMenu
                        icon={iconMenuPontos}
                        actions={[
                          {
                            icon: <Check style={{ color: '#FFFFFF' }} />,
                            name: provider.intl.formatMessage({ id: 'salvar' }),
                            onClickIcon: handleSubmit,
                            disabled: this.state.error,
                            color: '#42ADE8'
                          },
                          {
                            icon: <Close style={{ color: '#FFFFFF' }} />,
                            name: provider.intl.formatMessage({ id: 'cancelar' }),
                            onClickIcon: this.clickCancelar,
                            color: '#F33A30'
                          }
                        ]}
                        onClickButton={this.clickAdicionar}
                      />
                    </div>
                  </form>
                </Card>
              </MuiThemeProvider>
            )}
          />

          <EditarMunicpio
            clickSalvarMunicipio={this.clickSalvarMunicipio}
            data={
              this.state.itemRegiao.regiaoMunicipioList ? this.state.itemRegiao.regiaoMunicipioList : []
            }
          />

          <Municipio
            dataKey={this.state.dataKey}
            data={
              this.state.itemRegiao.regiaoMunicipioList ? this.state.itemRegiao.regiaoMunicipioList : []
            }
            onClickDeleteMunicipio={this.onClickDeleteMunicipio}
          />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info
});

let enhaced = withStyles(style)(EditarRegiao);
export default withRouter(connect(mapStateToProps)(enhaced));
