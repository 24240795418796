import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { flattenMessages } from '@utils/languageUtils';
import { FormattedMessage } from 'react-intl';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import swal from '@sweetalert/with-react';
import DatePicker from '@components/DatePicker/DatePicker';
import * as moment from 'moment';
import NumberFormat from 'react-number-format';
import TextField from '@components/TextFieldOwn/TextFieldOwn';
import CardCadastro from '@components/CardCadastro/CardCadastro';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

let { provider } = new IntlProvider({});

const style = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px'
  },
  button: {
    justifyContent: 'center',
    width: '100%',
    marginTop: '15px',
    display: 'flex'
  },
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 30
  },
  textField: {
    width: '100%',
    height: '70px'
  },
  font: {
    height: 15,
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    textOverflow: 'ellipsis'
  },
  fontMensalidade: {
    fontSize: 14,
    width: '100%',
    writeSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

class Especificacoes extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const recorrencia = messagesLinguage['financeiro.recorrencia'];
    const vlMensalidade = messagesLinguage['financeiro.vlMensalidade'];
    const inicioVigencia = messagesLinguage['financeiro.inicioVigencia'];
    const terminoVigencia = messagesLinguage['financeiro.terminoVigencia'];
    const confirmar = messagesLinguage['financeiro.confirmar'];
    const cancelar = messagesLinguage['financeiro.cancelar'];
    const campoObrigatorio = messagesLinguage['financeiro.campoObrigatorio'];
    const recorrenciaRemovidoComSucesso = messagesLinguage['financeiro.recorrenciaRemovidoComSucesso'];
    const excluir = messagesLinguage['financeiro.excluir'];
    const nao = messagesLinguage['financeiro.nao'];
    const desejaExcluir = messagesLinguage['financeiro.desejaExcluir'];
    const dataRecorrenciaInvalida = messagesLinguage['financeiro.dataRecorrenciaInvalida'];
    const nrTecnico = messagesLinguage['financeiro.nrTecnico'];
    const nrNdvi = messagesLinguage['financeiro.nrNdvi'];
    const nrProdutor = messagesLinguage['financeiro.nrProdutor'];
    const ilimitado = messagesLinguage['financeiro.ilimitado'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          recorrencia,
          vlMensalidade,
          inicioVigencia,
          terminoVigencia,
          confirmar,
          cancelar,
          campoObrigatorio,
          recorrenciaRemovidoComSucesso,
          desejaExcluir,
          excluir,
          nao,
          dataRecorrenciaInvalida,
          nrTecnico,
          nrProdutor,
          nrNdvi,
          ilimitado
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      nrProdutor: '',
      nrTecnico: '',
      nrNdvi: '',
      index: 0,
      disabledEdit: true,
      openModal: false,
      itemSelect: '',
      statusEdit: false,
      vlMensalidade: '',
      checkProdutores: false,
      checkTecnicos: false,
      checkNdvi: false,
      checkIlimitadoNdvi: false,
      dtInicio: null,
      dtFinal: null
    };

    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
    this.formatTextField = this.formatTextField.bind(this);
  }

  componentDidMount() {
    if (this.props.itemSelect !== null) {
      this.setState({
        openModal: true,
        statusEdit: true,
        vlMensalidade: this.props.itemSelect.vlMensalidade,
        checkProdutores: this.props.itemSelect.nrProdutor === -1,
        checkTecnicos: this.props.itemSelect.nrTecnico === -1,
        checkNdvi: this.props.itemSelect.stNdvi === 1,
        checkIlimitadoNdvi: this.props.itemSelect.nrNdvi === -1,
        nrProdutor: this.props.itemSelect.nrProdutor,
        nrTecnico: this.props.itemSelect.nrTecnico,
        nrNdvi: this.props.itemSelect.nrNdvi !== -1 ? this.props.itemSelect.nrNdvi : '',
        dtInicio: moment(this.props.itemSelect.dtInicio).utc(),
        dtFinal: moment(this.props.itemSelect.dtTermino).utc()
      });
    } else {
      this.setState({
        openModal: true,
        statusEdit: false,
        vlMensalidade: '',
        checkProdutores: false,
        checkTecnicos: false,
        checkNdvi: false,
        checkIlimitadoNdvi: false,
        nrProdutor: '',
        nrTecnico: '',
        nrNdvi: '',
        stNdvi: 0,
        dtInicio: null,
        dtFinal: null
      });
    }
  }

  handleChangeChecked(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }

  onSave(values, { resetForm }) {
    const validationDate = this.props.data.find(doc => {
      const docInicio = moment(values.dtInicio)
        .utc()
        .format('YYYY-MM-DD');
      const docTermino = moment(values.dtTermino)
        .utc()
        .format('YYYY-MM-DD');

      let idHistoricoFinanceiro = this.props.itemSelect
        ? this.props.itemSelect.idHistoricoFinanceiro
        : null;

      return (
        ((docInicio <= doc.dtInicio && docTermino >= doc.dtInicio) ||
          (docInicio >= doc.dtInicio && docTermino <= doc.dtTermino) ||
          (docInicio <= doc.dtTermino && docTermino >= doc.dtTermino)) &&
        idHistoricoFinanceiro !== doc.idHistoricoFinanceiro
      );
    });

    if (validationDate === undefined) {
      values.dtInicioFormat = moment(values.dtInicio).format('DD/MM/YYYY');
      values.dtTerminoFormat = moment(values.dtTermino).format('DD/MM/YYYY');
      values.vlMensalidade = parseFloat(values.vlMensalidade);
      values.nrProdutor = this.state.checkProdutores ? -1 : values.nrProdutor;
      values.nrProdutorFormat = this.state.checkProdutores
        ? provider.intl.formatMessage({ id: 'ilimitado' })
        : values.nrProdutor;
      values.nrTecnico = this.state.checkTecnicos ? -1 : values.nrTecnico;
      values.nrTecnicoFormat = this.state.checkTecnicos
        ? provider.intl.formatMessage({ id: 'ilimitado' })
        : values.nrTecnico;

      values.nrNdvi = this.state.checkIlimitadoNdvi ? -1 : parseFloat(values.nrNdvi);
      values.nrNdviFormat = this.state.checkIlimitadoNdvi
        ? provider.intl.formatMessage({ id: 'ilimitado' })
        : values.nrNdvi;

      values.stNdvi = this.state.checkNdvi ? 1 : 0;

      resetForm({
        nrProdutor: '',
        nrTecnico: '',
        nrNdvi: ''
      });

      if (this.state.statusEdit) {
        const position = this.props.data.indexOf(this.props.itemSelect);
        this.setState({
          openModal: false
        });

        this.props.onClickHistorico(values, position);
      } else {
        this.setState({
          openModal: false
        });

        this.props.onClickHistorico(values);
      }
    } else {
      swal(provider.intl.formatMessage({ id: 'dataRecorrenciaInvalida' }), {
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-error'
          }
        }
      });
    }
  }

  onClose() {
    this.setState({
      openModal: false
    });

    this.props.onClose(true);
  }

  formatTextField() {
    switch (getUserLang()) {
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'es-ES':
        return 'DD-MM-YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={{
            vlMensalidade: this.state.vlMensalidade,
            dtInicio: this.state.dtInicio,
            dtTermino: this.state.dtFinal,
            nrProdutor: !this.state.checkProdutores ? this.state.nrProdutor : '',
            nrTecnico: !this.state.checkTecnicos ? this.state.nrTecnico : '',
            nrNdvi: this.state.checkNdvi ? this.state.nrNdvi : '',
            checkNdvi: this.state.checkNdvi
          }}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={this.onSave}
          validationSchema={Yup.object().shape({
            vlMensalidade: Yup.string().required(<FormattedMessage id='financeiro.campoObrigatorio' />),
            dtInicio: Yup.string().required(<FormattedMessage id='financeiro.campoObrigatorio' />),
            dtTermino: Yup.string()
              .test('', <FormattedMessage id='financeiro.dataMinima' />, value => {
                return this.state.dtInicio <= this.state.dtFinal;
              })
              .required(<FormattedMessage id='financeiro.campoObrigatorio' />),
            nrProdutor: this.state.checkProdutores
              ? Yup.string()
              : Yup.number()
                  .min(1, <FormattedMessage id='financeiro.quantidadeMinima' />)
                  .required(<FormattedMessage id='financeiro.campoObrigatorio' />),
            nrTecnico: this.state.checkTecnicos
              ? Yup.string()
              : Yup.number()
                  .min(1, <FormattedMessage id='financeiro.quantidadeMinima' />)
                  .required(<FormattedMessage id='financeiro.campoObrigatorio' />),
            nrNdvi:
              (this.state.checkNdvi && this.state.checkIlimitadoNdvi) ||
              (!this.state.checkNdvi && !this.state.checkIlimitadoNdvi)
                ? Yup.string()
                : Yup.number()
                    .nullable()
                    .min(1, <FormattedMessage id='financeiro.quantidadeMinima' />)
                    .required(<FormattedMessage id='financeiro.campoObrigatorio' />)
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue
          }) => (
            <div
              style={{
                width: '100%',
                height: '100%',
                marginBottom: 15,
                display: 'flex',
                flexDirection: 'column-reverse'
              }}
            >
              <CardCadastro
                titulo={provider.intl.formatMessage({
                  id: 'recorrencia'
                })}
                botoes={[
                  this.props.itemSelect !== null && (
                    <Button color='primary' variant='contained' onClick={this.props.onExcluir}>
                      {provider.intl.formatMessage({ id: 'excluir' })}
                    </Button>
                  ),
                  <Button variant='contained' color='inherit' onClick={this.onClose}>
                    {provider.intl.formatMessage({ id: 'cancelar' })}
                  </Button>,
                  <Button color='secondary' variant='contained' onClick={handleSubmit}>
                    {provider.intl.formatMessage({ id: 'confirmar' })}
                  </Button>
                ]}
              >
                <div>
                  <form autoComplete='off'>
                    <Grid container spacing={16}>
                      <Grid item xs={2}>
                        <NumberFormat
                          allowNegative={false}
                          value={values.vlMensalidade}
                          thousandSeparator={getUserLang() === 'en-US' ? ',' : '.'}
                          decimalSeparator={getUserLang() === 'en-US' ? '.' : ','}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          label={<FormattedMessage id='financeiro.vlMensalidade' />}
                          name='vlMensalidade'
                          onChange={handleChange}
                          onBlur={event => {
                            if (this.state.vlMensalidade !== undefined) {
                              handleBlur(event);
                              setFieldValue('vlMensalidade', this.state.vlMensalidade);
                            }
                          }}
                          error={touched.vlMensalidade && errors.vlMensalidade ? true : false}
                          helperText={
                            errors.vlMensalidade && touched.vlMensalidade ? (
                              <FormattedMessage id='financeiro.campoObrigatorio' />
                            ) : null
                          }
                          className={classes.textField}
                          InputProps={{
                            className: classes.fontMensalidade
                          }}
                          InputLabelProps={{
                            className: classes.fontMensalidade
                          }}
                          customInput={TextField}
                          onValueChange={values => {
                            const { floatValue } = values;
                            this.setState({
                              vlMensalidade: floatValue
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <DatePicker
                          locale={getUserLang()}
                          style={{ height: 70 }}
                          className={classes.font}
                          label={provider.intl.formatMessage({
                            id: 'inicioVigencia'
                          })}
                          valueDate={values.dtInicio}
                          errorTouched={touched.dtInicio && errors.dtInicio ? true : false}
                          helperText={provider.intl.formatMessage({
                            id: 'campoObrigatorio'
                          })}
                          onBlurDatePicker={date => {
                            let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                            if (!validation) {
                              setFieldValue('dtTermino', null);
                              setFieldValue('dtInicio', null);
                            }
                          }}
                          onChangeDatePicker={date => {
                            setFieldValue('dtTermino', null);
                            setFieldValue('dtInicio', date);
                            this.setState({
                              dtInicio: date,
                              dtFinal: null
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <DatePicker
                          minDate={values.dtInicio}
                          disabled={values.dtInicio === null}
                          locale={getUserLang()}
                          style={{ height: 70 }}
                          className={classes.font}
                          label={provider.intl.formatMessage({
                            id: 'terminoVigencia'
                          })}
                          valueDate={values.dtTermino}
                          errorTouched={
                            touched.dtTermino && errors.dtTermino && values.dtInicio !== null
                              ? true
                              : false
                          }
                          helperText={
                            values.dtInicio !== null ? (
                              values.dtTermino === null ? (
                                <FormattedMessage id='financeiro.campoObrigatorio' />
                              ) : (
                                errors.dtTermino
                              )
                            ) : (
                              ''
                            )
                          }
                          onBlurDatePicker={date => {
                            let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                            if (!validation) {
                              setFieldValue('dtTermino', null);
                            } else {
                              const dtFinal = moment(date.target.value, this.formatTextField());
                              this.setState({
                                dtFinal: dtFinal
                              });
                            }
                          }}
                          onChangeDatePicker={date => {
                            setFieldValue('dtTermino', date);
                            this.setState({
                              dtFinal: date
                            });
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={16}>
                      <Grid item xs={2}>
                        <NumberFormat
                          disabled={this.state.checkProdutores}
                          allowNegative={false}
                          value={values.nrProdutor}
                          thousandSeparator={getUserLang() === 'en-US' ? ',' : '.'}
                          decimalSeparator={getUserLang() === 'en-US' ? '.' : ','}
                          fixedDecimalScale={true}
                          decimalScale={0}
                          label={<FormattedMessage id='financeiro.nrProdutor' />}
                          name='nrProdutor'
                          onBlur={event => {
                            handleBlur(event);
                            if (this.state.nrProdutor !== undefined) {
                              setFieldValue('nrProdutor', this.state.nrProdutor);
                            }
                          }}
                          error={touched.nrProdutor && errors.nrProdutor ? true : false}
                          helperText={errors.nrProdutor && touched.nrProdutor ? errors.nrProdutor : null}
                          className={classes.textField}
                          InputProps={{
                            className: classes.fontMensalidade
                          }}
                          InputLabelProps={{
                            className: classes.fontMensalidade
                          }}
                          customInput={TextField}
                          onValueChange={values => {
                            const { floatValue } = values;
                            if (floatValue !== undefined) {
                              setFieldValue('nrProdutor', floatValue);
                              this.setState({
                                nrProdutor: floatValue
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.checkProdutores}
                              name='checkProdutores'
                              onChange={event => {
                                if (this.state.nrProdutor === -1) {
                                  this.setState({
                                    nrProdutor: 0
                                  });
                                } else if (event.target.checked) {
                                  this.setState({
                                    nrProdutor: values.nrProdutor
                                  });
                                }
                                this.handleChangeChecked(event);
                              }}
                              value='checkProdutores'
                              color='primary'
                            />
                          }
                          label={<FormattedMessage id='financeiro.ilimitado' />}
                          style={{ fontSize: 14 }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={16}>
                      <Grid item xs={2}>
                        <NumberFormat
                          disabled={this.state.checkTecnicos}
                          allowNegative={false}
                          value={values.nrTecnico}
                          thousandSeparator={getUserLang() === 'en-US' ? ',' : '.'}
                          decimalSeparator={getUserLang() === 'en-US' ? '.' : ','}
                          fixedDecimalScale={true}
                          decimalScale={0}
                          label={<FormattedMessage id='financeiro.nrTecnico' />}
                          name='nrTecnico'
                          onBlur={event => {
                            handleBlur(event);
                            if (this.state.nrTecnico !== undefined) {
                              setFieldValue('nrTecnico', this.state.nrTecnico);
                            }
                          }}
                          error={touched.nrTecnico && errors.nrTecnico ? true : false}
                          helperText={errors.nrTecnico && touched.nrTecnico ? errors.nrTecnico : null}
                          className={classes.textField}
                          InputProps={{
                            className: classes.fontMensalidade
                          }}
                          InputLabelProps={{
                            className: classes.fontMensalidade
                          }}
                          customInput={TextField}
                          onValueChange={values => {
                            const { floatValue } = values;
                            if (floatValue !== undefined) {
                              setFieldValue('nrTecnico', floatValue);
                              this.setState({
                                nrTecnico: floatValue
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.checkTecnicos}
                              name='checkTecnicos'
                              onChange={event => {
                                if (this.state.nrTecnico === -1) {
                                  this.setState({
                                    nrTecnico: 0
                                  });
                                } else if (event.target.checked) {
                                  this.setState({
                                    nrTecnico: values.nrTecnico
                                  });
                                }
                                this.handleChangeChecked(event);
                              }}
                              value='checkTecnicos'
                              color='primary'
                            />
                          }
                          label={<FormattedMessage id='financeiro.ilimitado' />}
                          style={{ fontSize: 14 }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={16}>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.checkNdvi}
                              name='checkNdvi'
                              onChange={event => {
                                if (event.target.checked) {
                                  this.setState({
                                    nrNdvi: values.nrNdvi
                                  });
                                }
                                this.handleChangeChecked(event);
                              }}
                              value='checkNdvi'
                              color='primary'
                            />
                          }
                          label={<FormattedMessage id='financeiro.habilitarNdvi' />}
                          style={{ fontSize: 14 }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={16}>
                      <Grid item xs={2}>
                        <NumberFormat
                          disabled={
                            !this.state.checkNdvi ||
                            (this.state.checkNdvi && this.state.checkIlimitadoNdvi)
                          }
                          allowNegative={false}
                          value={values.nrNdvi === -1 ? '' : values.nrNdvi}
                          thousandSeparator={getUserLang() === 'en-US' ? ',' : '.'}
                          decimalSeparator={getUserLang() === 'en-US' ? '.' : ','}
                          decimalScale={2}
                          isAllowed={value =>
                            value.formattedValue.length === 0 ||
                            (value.floatValue >= 1 && value.floatValue <= 999999.999)
                          }
                          label={<FormattedMessage id='financeiro.nrNdvi' />}
                          name='nrNdvi'
                          onBlur={event => {
                            handleBlur(event);
                            if (this.state.nrNdvi !== undefined) {
                              setFieldValue('nrNdvi', parseFloat(this.state.nrNdvi));
                            }
                          }}
                          error={
                            touched.nrNdvi &&
                            errors.nrNdvi &&
                            this.state.checkNdvi &&
                            !this.state.checkIlimitadoNdvi
                              ? true
                              : false
                          }
                          helperText={
                            errors.nrNdvi &&
                            touched.nrNdvi &&
                            this.state.checkNdvi &&
                            !this.state.checkIlimitadoNdvi
                              ? errors.nrNdvi
                              : null
                          }
                          className={classes.textField}
                          InputProps={{
                            className: classes.fontMensalidade
                          }}
                          InputLabelProps={{
                            className: classes.fontMensalidade
                          }}
                          customInput={TextField}
                          onValueChange={values => {
                            const { floatValue } = values;
                            if (floatValue !== undefined) {
                              setFieldValue('nrNdvi', floatValue);
                              this.setState({
                                nrNdvi: floatValue
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <FormControlLabel
                          disabled={!this.state.checkNdvi}
                          control={
                            <Checkbox
                              checked={this.state.checkIlimitadoNdvi}
                              name='checkIlimitadoNdvi'
                              onChange={event => {
                                if (event.target.checked) {
                                  this.setState({
                                    nrNdvi: -1
                                  });
                                }
                                this.handleChangeChecked(event);
                              }}
                              value='checkIlimitadoNdvi'
                              color='primary'
                            />
                          }
                          label={<FormattedMessage id='financeiro.ilimitado' />}
                          style={{ fontSize: 14 }}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </CardCadastro>
            </div>
          )}
        />
      </MuiThemeProvider>
    );
  }
}

Especificacoes.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Especificacoes);
