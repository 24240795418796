export default {
  listDetalhes: {
    produtosProibidos: 'Prohibited products',
    areaDeCultivo: 'Cultivation area',
    cultura: 'Culture',
    detentor: 'holder',
    cultivarUtilizadas: 'Cultivar used',
    talhoesAdicionado: 'Plots used',
    areaTanque: 'Area by tank',
    nrQuantidade: 'Amount',
    dsUnMedida: 'Un. of measurement',
    dsRecomendacaoAdicional: 'Additional recommendation',
    produtos: 'Products',
    tecnicoResponsavel: 'Technical responsible',
    produtividade: 'Productivity',
    espacamento: 'Spacing',
    semeadura: 'Seeding',
    invasoras: 'Invasive',
    alertasFitossanitarios: 'Phytosanitary Alerts',
    nao: 'Not',
    sim: 'Yes',
    talhoes: 'Fields',
    observacao: 'Note',
    amostras: 'Samples',
    fitossanidadesEncontradas: 'Pests found',
    quantidadeAmostras: 'Sample Quantity',
    standFinal: 'Stand final',
    diasEstimados: 'Estimated Days',
    dataPrevista: 'Expected date',
    produtividadeEstimada: 'Estimated Productivity',
    previsaoColheita: 'Harvest Forecast',
    colheita: 'Harvest',
    previsto: 'Foreseen',
    imagens: 'Images',
    descricaoRegistroClimatico: 'Description of the climatic record',
    tipoAuditoria: 'Audit type',
    interna: 'Internal',
    externa: 'External',
    situacao: 'Status',
    emAndamento: 'In progress',
    finalizadaSemNaoConformidade: 'Finished without non-compliance',
    finalizadaComNaoConformidade: 'Finished with non-compliance',
    suspensa: 'Suspended',
    naoConformidades: 'No conformidades',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    sancao: 'Sanction',
    limiteComprimento: 'Limit for compliance'
  }
};
